/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { filter } from "lodash";
import { Helmet } from "react-helmet";
import logo_MyWorkSpace from "../../../../assets/images/my-workspace.png";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import {
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
  styled,
  Autocomplete,
  TextField
} from "@mui/material";
import USERLIST from "../../../../components/SuperAdmin/_user/TableUsers/_mocks/user";
import { useHistory } from "react-router";
import DatePickerEmployeeReport from "../../../SuperAdmin/Employee/EmloyeeReportSA/DatePickerEmployeeReport";
import DropdownReportEmployee from "../../../SuperAdmin/Employee/EmloyeeReportSA/DropdownReportEmployee/DropdownReportEmployee";
import UserListHead from "../../../../components/SuperAdmin/_user/TableUsers/UserListHead";
import Scrollbar from "../../../../components/SuperAdmin/_user/TableUsers/Scrollbar";
import { makeStyles } from "@mui/styles";
import ExportCSV from "../../../../components/SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import DateRangePickerComponent from "../../../../components/utils/DateRangePicker/DateRangePicker";
import moment from "moment";
import { UseCompanyName } from "../../../../components/utils/hooks";
import { useDispatch } from "react-redux";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
  },
});

const TABLE_HEAD = [
  { id: "name", label: "Employee Name", alignRight: false },
  { id: "position", label: "Position", alignRight: false },
  { id: "attend", label: "Division", alignRight: false },
  { id: "sick", label: "Staff", alignRight: false },
  { id: "leave", label: "Leader", alignRight: false },
  { id: "other", label: "Join Date", alignRight: false },
  //   { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}



export default function EmployeeReport() {
  const token = localStorage.getItem('accessToken');
  const dispatch = useDispatch();
  const getCompany = UseCompanyName();
  const classes = useStyles();
  const history = useHistory();
  const [groupValue, setGroupValue] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedGroup, setSelectedGroup] = useState("");
  const options = ["PT.Permata Indo Sejahtera"];
  const startDate = moment().format("LL");
  const endDate = moment().format("LL");
  const [valueStartDate, setValueStartDate] = useState(startDate);
  const [valueEndDate, setValueEndDate] = useState(endDate);

  useEffect(() => {
    dispatch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeStartDate = (newValue) => {
    setValueStartDate(newValue);
  };
  const handleChangeEndDate = (newValue) => {
    setValueEndDate(newValue);
  };

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );
  return (
    <div className="container-employee-report">
      <Helmet>
        <title>MyWorkSpace | Employee Report </title>
      </Helmet>
      <div className="wrapper-employee-report">
        <div className="item-top-employee-report">
          <div className="item-left-employee-report" onClick={() => history.go(-1)}>
            <img src={logo_MyWorkSpace} alt="" />
            <span>MyWorkSpace</span>
          </div>
          <div className="item-mid-employee-report">
            <span>Employee Report</span>
          </div>
          <div className="item-right-employee-report">
            <ExportCSV initial="Download" endIcon={<DownloadIcon />}/>
          </div>
        </div>
        <div className="date-picker-shown-employee-report">
          <div className="wrapper-date-picker-shown-employee-report">
            <DateRangePickerComponent
              valueStartDate={valueStartDate}
              valueEndDate={valueEndDate}
              handleChangeStartDate={handleChangeStartDate}
              handleChangeEndDate={handleChangeEndDate}
            />
            {/* <div className="date-from-employee-report">
              <DatePickerEmployeeReport />
            </div>
            <span>to</span>
            <div className="date-to-employee-report">
              <DatePickerEmployeeReport />
            </div> */}
          </div>
        </div>
        <div className="company-employee-report">
          <div className="title-company-report">
            <label htmlFor="">Group</label> 
            <Autocomplete
            disablePortal
            options={getCompany}
            value={selected}
            inputValue={selected}
            onInputChange={(e, value) => setSelected(value)}
            renderInput={(params) => (
              <MuiTextField {...params} size="small"  />
            )}
          />
            {/* <FormControl fullWidth size="small" style={{ width: "100%" }}>
              <Select
                className={classes.selectBorder}
                value={selected}
                displayEmpty
                onChange={(e) => setSelected(e.target.value)}
              >
                <MenuItem value="">Select Group</MenuItem>
                {options.map((option, el) => (
                  <MenuItem
                    key={el}
                    onClick={() => setSelectedGroup(option)}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
        </div>
        <hr />
        <div className="desc-item-employee-report">
          <div className="mini-item-left-employee-report">
            <label>Total Employee</label>
            <span>100 Employee </span>
          </div>
          <div className="border-seperator"></div>
          <div className="mini-item-middle-employee-report">
            <label>Total Division</label>
            <span>5 </span>
          </div>
          <div className="border-seperator"></div>
          <div className="mini-item-right-employee-report">
            <label>Total Position</label>
            <span>50</span>
          </div>
          <div className="border-seperator"></div>
          <div className="mini-item-right-employee-report">
            <label>Total New Employee</label>
            <span>10 Employee</span>
          </div>
        </div>
        <div className="table-employee-report">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table
                sx={{
                  width: "100%",
                  maxHeight: "100%",
                  backgroundColor: "#fafafa",
                  borderRadius: 4,
                }}
              >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {stableSort(USERLIST, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        name,
                        role,
                        avatarUrl,
                        division,
                      } = row;
                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell></TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar alt={name} src={avatarUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{role}</TableCell>
                          <TableCell align="left">{division}</TableCell>
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="column"
                              alignItems="left"
                              spacing={0}
                            >
                              <Typography noWrap>Melvin Andrean</Typography>
                              <Typography noWrap>UX Designer</Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="column"
                              alignItems="left"
                              spacing={0}
                            >
                              <Typography noWrap>Satria Wibowo</Typography>
                              <Typography noWrap>Marketing Manager</Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">12 January 2021</TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
}
