import axios from "axios";
import {API} from "../../redux/api";

const fetchInductionByIdAsync = (token, inductionId) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data: { response }}  = await axios.get(`${API}/induction/${inductionId}`, {
        headers : {
          Authorization: token
        }
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export default fetchInductionByIdAsync;
