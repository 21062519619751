/* eslint-disable no-unused-vars */

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Styles from "./EditEmployeeMenuV2.module.css";
// import EMPLOYEE_PLACEHOLDER from "../../../../assets/images/employee-placeholder.png";
// import { CalendarMonth, Style } from "@mui/icons-material";
// import PublishIcon from "@mui/icons-material/Publish";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import UPLOAD_ICON from "../../../../assets/images/upload-icon-button.png";
import {
  Autocomplete,
  Avatar,
  Button,
  FormControl,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { UseListBank } from "../../../utils/hooks";
import { useDropzone } from "react-dropzone";
// import PermataIcon from "../../../../assets/images/PermataIcon.png";
import EditIcon from "@mui/icons-material/Edit";
import ModalSuccessEmployeeV2 from "../ModalSuccessEmployee/ModalSuccessEmployeeV2";
// import { useLocation } from "react-router";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import updateEmployee from "../../../../async/employee/updateEmployee";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setSnackbar } from '../../../../redux/actions/SuperAdmin/snackbarActions'

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #d9d9d9",
      borderRadius: 8,
      width: "100%",
      height: 42,

      "&:hover": {
        borderColor: "red",
      },
    },

    "&.MuiOutlinedInput-root": {
      paddingTop: 6,
      "& > svg": {
        fontSize: 20,
        marginTop: "3px",
      },
      "& fieldset": {
        borderColor: "#d9d9d9",
      },
      "&:hover fieldset": {
        borderColor: "#d9d9d9",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #d9d9d9",
      },
    },
  },
});

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      border: "1px solid #d9d9d9",
    },
    "&:hover fieldset": {
      borderColor: "#d9d9d9",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #d9d9d9",
    },
    "& input::placeholder": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#757575",
    },
    "& input": {
      fontSize: "14px",
      fontWeight: 400,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1.5px solid #d3c9c9",
    borderColor: "#d3c9c9",
    borderRadius: 8,
  },
});

export default function EditEmployeeMenuV2(props) {
  const { data, token } = props;
  console.log(data, 'datas')
  const history = useHistory();
  // const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch()
  const getAllBank = UseListBank();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isDisable = true;
  const inputFileRefNPWP = useRef();
  const inputFileRefSaveBook = useRef();
  const imageRef = useRef();
  const [birthDate, setBirthDate] = useState(null);
  const [value, setValue] = useState({
    account: {
      bankName: "",
      number: "",
    },
    maritalStatus: "",
    image: null,
    imageNPWP: null,
    imageSaveBook: null,
    adminAccess: {
      isInternal: false,
    },
    email: "",
    phoneNumber: "",
    taxId: "",
    profilePicture: "",
  });
  const [imageArr, setImageArr] = useState({
    NPWP: null,
    saveBook: null,
  });
  const [files, setFiles] = useState([]);
  const [imageNPWP, setImageNPWP] = useState("");
  const [previewNPWP, setPreviewNPWP] = useState(null);
  const [previewSaveBook, setPreviewSaveBook] = useState(null);
  const [previewNameNPWP, setPreviewNameNPWP] = useState(null);
  const [previewNameSaveBook, setPreviewNameSaveBook] = useState(null);
  const [image, setImage] = useState(null);
  const [selected, setSelected] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const imageData = {
    PROFILEPICTURE: value?.profilePicture,
    NPWP: previewNPWP,
    BANK: previewSaveBook,
  };
  let objectName = Object.keys(imageData);
  let objectValue = Object.values(imageData);
  const listMaritalStatus = ["TK", "K0", "K1", "K2", "K3", "K4", "K5"];

  const sortedBank = useMemo(() => {
    return getAllBank.sort(function (a, b) {
      if (a < b) {
        return -1;
      }

      return 0;
    });
  }, [getAllBank]);

  const listBankName = useMemo(() => {
    return sortedBank.map((el) => el.name);
  }, [sortedBank]);

  const firstLetterName = useMemo(() => {
    return data?.name.charAt(0);
  }, [data]);

  useEffect(() => {
    setValue(data);
    setBirthDate(data?.birthDate);
  }, [data]);

  const handleChangeSwitch = (event) => {
    setValue((prev) => ({
      ...prev,
      adminAccess: {
        ...prev.adminAccess,
        isInternal: event.target.checked,
      },
    }));
  };


  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );

    if(value?.taxId !== data?.taxId) {
      setValue((prev) => ({
        ...prev,
        // eslint-disable-next-line no-useless-computed-key
        ["imageNPWP"]: acceptedFiles,
      }));
      setPreviewNPWP(URL.createObjectURL(acceptedFiles[0]));
      setPreviewNameNPWP(acceptedFiles[0].name);
    }


    if(value?.account?.number !== data?.account?.number ) {
      setValue((prev) => ({
        ...prev,
        // eslint-disable-next-line no-useless-computed-key
        ["imageSaveBook"]: acceptedFiles,
      }));
      setPreviewSaveBook(URL.createObjectURL(acceptedFiles[0]));
      setPreviewNameSaveBook(acceptedFiles[0].name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps}  = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  // const handleEditData = (e) => {
  //   setValue((prev) => ({
  //     ...prev,
  //     [e.target.name]:
  //       e.target.type === "file" ? e.target.files : e.target.value,
  //   }));

  //   if (e.target.type === "file") {
  //     let url = URL.createObjectURL(e.target.files[0]);
  //     setPreview(url);
  //     setPreviewName(e.target.files[0].name);
  //   }
  // };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files?.[0];
      setValue({
        ...value,
        profilePicture: img,
      });
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const handleChangeNPWP = (e) => {
    if (e.target.files && e.target.files[0]) {
      // let img = URL.createObjectURL(e.target.files[0]);
      let img = e.target.files?.[0];
      setValue((prev) => ({
        ...prev,
        imageNPWP: img,
      }));
      setPreviewNPWP(img);
      setPreviewNameNPWP(e.target.files[0].name);
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const handleDeleteNPWP = (e) => {
    inputFileRefNPWP.current.value = null;
    setPreviewNPWP(null);
    setPreviewNameNPWP("");
    // setImageNPWP(null);
    setValue((prev) => ({
      ...prev,
      // eslint-disable-next-line no-useless-computed-key
      ["imageNPWP"]: null,
    }));
  };

  const handleChangeSaveBook = (e) => {
    if (e.target.files && e.target.files[0]) {
      // let img = URL.createObjectURL(e.target.files[0]);
      let img = e.target.files?.[0];
      // setImageNPWP(img);
      setValue((prev) => ({
        ...prev,
        imageSaveBook: img,
      }));
      setPreviewSaveBook(img);
      setPreviewNameSaveBook(e.target.files[0].name);
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
    // setValue((prev) => ({
    //   ...prev,
    //   [e.target.name]:
    //     e.target.type === "file" ? e.target.files : e.target.value,
    // }));

    // if (e.target.type === "file") {
    //   let url = URL.createObjectURL(e.target.files[0]);
    //   setPreviewSaveBook(url);
    //   setPreviewNameSaveBook(e.target.files[0].name);
    // }
  };

  const handleDeleteSaveBook = (e) => {
    inputFileRefSaveBook.current.value = null;
    setPreviewSaveBook(null);
    setPreviewNameSaveBook("");
    setValue((prev) => ({
      ...prev,
      // eslint-disable-next-line no-useless-computed-key
      ["imageSaveBook"]: null,
    }));
  };

  const handleResetFields = (e) => {
    e.preventDefault();
    setValue((prev) => ({
      ...prev,
      account: {
        bankName: "",
        number: "",
      },
      maritalStatus: "",
      image: null,
      imageNPWP: null,
      imageSaveBook: null,
      isInternal: false,
      email: "",
      phoneNumber: "",
      taxId: "",
      profilePicture: "",
    }));
    setBirthDate(new Date().getTime());
    setPreviewNPWP(null);
    setPreviewSaveBook(null);
    // setImageNPWP(null);
  };

  const handleSelectedBank = (value) => {
    if (value) {
      setValue((prev) => ({
        ...prev,
        account: {
          ...prev.account,
          bankName: value,
        },
      }));
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const handleSelectedStatus = (value) => {
    if (value) {
      setValue((prev) => ({
        ...prev,
        maritalStatus: value,
      }));
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const onChangeValue = (e) => {
    e.preventDefault();
    if (e) {
      setValue((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const onChangeValueBankAccount = (e) => {
    if (e.target.value) {
      e.preventDefault();
      setValue((prev) => ({
        ...prev,
        account: {
          ...prev.account,
          number: e.target.value,
        },
      }));
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const handleChangeBirthDate = (newValue) => {
    if (newValue) {
      // setValue((prev) => ({
      //   ...prev,
      //   birthDate: newValue
      // }))
      setBirthDate(newValue);
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();
      formData.append("email", value?.email);
      formData.append("phoneNumber", value?.phoneNumber);
      formData.append("birthDate",moment(new Date(birthDate).getTime()).format("YYYY-MM-DD"));
      formData.append("maritalStatus", value?.maritalStatus);
      formData.append("taxId", value?.taxId);
      formData.append("bankName", value?.account?.bankName);
      formData.append("accountNumber", value?.account?.number);

      for (let i = 0; i < objectName.length; i++) {
        formData.append("types", objectName[i]);
      }

      for (let i = 0; i < objectValue.length; i++) {
        formData.append("images", objectValue[i]);
      }

      await updateEmployee(token, data?.employeeId, formData);
      for (const pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1], "dataaaaa");
      }
      await new Promise((resolve) => resolve(formData));
      setIsModalOpen(true);
      await new Promise((resolve) => setTimeout(() => resolve(true), 1500))
      history.push(`/superadmin/employee`)
    } catch (error) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: error.response.data.error,
        })
      );
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <div className={Styles.Container}>
      <form className={Styles.Wrapper} onSubmit={handleSubmit}>
        <div className={Styles.GroupControl}>
          <div className={Styles.FormControl}>
            <div className={Styles.Heading}>
              <div className={Styles.Text}>
                <span>Employee Details</span>
              </div>
              <div className={Styles.Switch}>
                {/* <span>Internal employee</span>
                <Switch
                  checked={value?.adminAccess?.isInternal}
                  size="large"
                  onChange={handleChangeSwitch}
                /> */}
              </div>
            </div>

            <div className={Styles.Value}>
              <div
                className={`${Styles.ValueControl} ${
                  isDisable === true && Styles.ValueControlDisabled
                }`}
              >
                <label>Name</label>
                <input
                  type="text"
                  value={data?.name || ""}
                  // placeholder="Satoru Iguchi Sugawara"
                  disabled
                />
              </div>
              <div
                className={`${Styles.ValueControl} ${
                  isDisable === true && Styles.ValueControlDisabled
                }`}
              >
                <label>NRK</label>
                <input type="text" value={data?.employeeId || ""} disabled />
              </div>
              <div
                className={`${Styles.ValueControl} ${
                  isDisable === true && Styles.ValueControlDisabled
                }`}
              >
                <label>KTP</label>
                <input type="text" value={data?.idNumber || ""} disabled />
              </div>
              <div className={`${Styles.ValueControl}`}>
                <label>Email</label>
                <input
                  type="text"
                  name="email"
                  value={value?.email || ""}
                  onChange={(e) => onChangeValue(e)}
                  // onChange={(e) =>
                  //   setValue((prev) => ({
                  //     ...prev,
                  //     email: e.target.value,
                  //   }))
                  // }
                />
              </div>
              <div className={`${Styles.ValueControl}`}>
                <label>Phone Number</label>
                <div className={Styles.InputContainer}>
                  <div className={Styles.InputAdornment}>
                    <span>+62</span>
                  </div>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={value?.phoneNumber || ""}
                    onChange={onChangeValue}

                    // onChange={(e) =>
                    //   setValue((prev) => ({
                    //     ...prev,
                    //     phoneNumber: e.target.value,
                    //   }))
                    // }
                  />
                </div>
              </div>
              <div className={Styles.ValueControl}>
                <label>Date of Birth</label>
                <div className={Styles.InputContainerRightDate}>
                  <FormControl size="small" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        components={{
                          OpenPickerIcon: CalendarMonthIcon,
                        }}
                        required
                        value={moment(birthDate).format("DD MMMM YYYY") || ""}
                        onChange={(newValue) => handleChangeBirthDate(newValue)}
                        // onChange={ (newValue) => setValue((prev) => ({
                        //   ...prev,
                        //   birthDate: newValue
                        // })) }
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            size="small"
                            sx={{ background: "var(--main-bg)" }}
                            required
                            placeholder="Select"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  {/* <input
                    required
                    type="text"
                    value={ moment( new Date(value?.birthDate).getTime ()).format("DD MMMM YYYY") || ""}
                    onChange={(e) =>
                      setValue((prev) => ({
                        ...prev,
                        birthDate: e.target.value,
                      }))
                    }
                  /> */}
                  {/* <div className={Styles.InputAdornmentRight}>
                    <i className="bx bxs-calendar-alt" />
                  </div> */}
                </div>
              </div>
              <div className={`${Styles.ValueControl}`}>
                <label>NPWP</label>
                <input
                  required
                  type="text"
                  name="taxId"
                  value={value?.taxId || ""}
                  onChange={onChangeValue}
                  // onChange={(e) =>
                  //   setValue((prev) => ({
                  //     ...prev,
                  //     taxId: e.target.value,
                  //   }))
                  // }
                />
              </div>
              <div className={Styles.ValueControl}>
                <label>Marital Status</label>
                <FormControl size="small" fullWidth>
                  <Autocomplete
                    disablePortal
                    options={listMaritalStatus}
                    value={value?.maritalStatus || ""}
                    sx={{ width: "100%" }}
                    onChange={(e, value) =>
                      value === null
                        ? handleSelectedStatus("")
                        : handleSelectedStatus(value)
                    }
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        size="small"
                        sx={{ background: "var(--main-bg)" }}
                        required
                        placeholder="Select"
                      />
                    )}
                  />
                  {/* <Select
                    className={classes.selectBorder}
                    name="bankName"
                    value={value.maritalStatus || ""}
                    displayEmpty
                    onChange={(e) =>
                      setValue((prev) => ({
                        ...prev,
                        maritalStatus: e.target.value,
                      }))
                    }
                    sx={{ fontSize: 14, fontWeight: 400, height: 30 }}
                  >
                    <MenuItem disabled value="">
                      Select
                    </MenuItem>
                    <MenuItem value="TK">TK</MenuItem>
                    <MenuItem value="K1">K1</MenuItem>
                    <MenuItem value="K2">K2</MenuItem>
                    <MenuItem value="K3">K3</MenuItem>
                    <MenuItem value="K4">K4</MenuItem>
                    <MenuItem value="K5">K5</MenuItem>
                  </Select> */}
                </FormControl>
              </div>
              <div className={`${Styles.ValueControl}`}>
                <label>Bank Name</label>
                <FormControl size="small" fullWidth>
                  <Autocomplete
                    disablePortal
                    options={listBankName}
                    value={value?.account?.bankName || ""}
                    sx={{ width: "100%" }}
                    placeholder="Select"
                    onChange={(e, value) =>
                      value === null
                        ? handleSelectedBank("")
                        : handleSelectedBank(value)
                    }
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        size="small"
                        sx={{ background: "var(--main-bg)" }}
                        required
                        placeholder="Select"
                      />
                    )}
                  />
                  {/* <Select
                    className={classes.selectBorder}
                    name="bankName"
                    value={value.bankName || ""}
                    displayEmpty
                    onChange={(e) =>
                      setValue((prev) => ({
                        ...prev,
                        bankName: e.target.value,
                      }))
                    }
                    sx={{ fontSize: 14, fontWeight: 400 }}
                  >
                    <MenuItem disabled value="">
                      Select
                    </MenuItem>
                    {sortedBank.map((option, el) => (
                      <MenuItem value={option.code} key={el}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select> */}
                </FormControl>
              </div>
              <div className={`${Styles.ValueControl}`}>
                <label>Bank Account Number</label>
                <input
                  type="number"
                  number="number"
                  value={value?.account?.number || ""}
                  onChange={onChangeValueBankAccount}
                  // onChange={(e) =>
                  //   setValue((prev) => ({
                  //     ...prev,
                  //     account: {
                  //       ...prev.account,
                  //       number: e.target.value,
                  //     },
                  //   }))
                  // }
                />
              </div>

              <div className={Styles.PictureContainer}>
                <div className={Styles.Text}>
                  <span>File Upload</span>
                </div>

                {value?.taxId !== data?.taxId ? (
                  <div className={Styles.PictureContainerControl}>
                    <span>
                      NPWP
                      <label>(required)</label>
                    </span>
                    <div
                      {...getRootProps()}
                      className={Styles.WrapperPictureUpload}
                    >
                      <input
                        {...getInputProps()}
                        type="file"
                        ref={inputFileRefNPWP}
                        name="imageNPWP"
                        onChange={handleChangeNPWP}
                        hidden
                      />
                      {previewNPWP ? (
                        <div className={Styles.DragAreaPicture}>
                          <img
                            // src={previewNPWP}
                            src={
                              typeof previewNPWP === "string"
                                ? previewNPWP
                                : URL.createObjectURL(previewNPWP)
                            }
                            onClick={() => inputFileRefNPWP.current.click()}
                            alt={previewNPWP}
                          />
                          <div className={Styles.TextInside}>
                            <div className={Styles.Desc}>
                              <span>{previewNameNPWP}</span>
                              <Button
                                type="button"
                                onClick={() => inputFileRefNPWP.current.click()}
                              >
                                Change File
                              </Button>
                            </div>
                            <div className={Styles.DeleteIcon}>
                              <img
                                src={DELETE_ICON}
                                onClick={handleDeleteNPWP}
                                alt="delete"
                                style={{
                                  width: "18px",
                                  height: "20px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={Styles.BoxInputImage}>
                          <span>Drag & Drop File Here</span>
                          <div className={Styles.BoxInputFile}>
                            <span>File must JPG or PNG</span>
                          </div>
                          <div className={Styles.BoxInputOr}>
                            <span>OR</span>
                          </div>
                          <div className={Styles.ContainerInputImage}>{}</div>
                          <div className={Styles.BoxInputButton}>
                            <Button
                              onClick={() => inputFileRefNPWP.current.click()}
                            >
                              <img src={UPLOAD_ICON} alt="upload-icon" />
                              <span>Browse File</span>
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}

                {value?.account?.number !== data?.account?.number ? (
                  <div className={Styles.PictureContainerControl}>
                    <span>
                      Bank Saving Book<label>(required)</label>
                    </span>
                    <div
                      {...getRootProps()}
                      className={Styles.WrapperPictureUpload}
                    >
                      <input
                        {...getInputProps()}
                        type="file"
                        ref={inputFileRefSaveBook}
                        name="imageSaveBook"
                        onChange={handleChangeSaveBook}
                        hidden
                      />
                      {previewSaveBook ? (
                        <div className={Styles.DragAreaPicture}>
                          <img
                            src={
                              typeof previewSaveBook === "string"
                                ? previewSaveBook
                                : URL.createObjectURL(previewSaveBook)
                            }
                            // src={previewSaveBook}
                            alt={previewSaveBook}
                            onClick={() => inputFileRefSaveBook.current.click()}
                          />
                          <div className={Styles.TextInside}>
                            <div className={Styles.Desc}>
                              <span>{previewNameSaveBook}</span>
                              <Button
                                type="button"
                                onClick={() =>
                                  inputFileRefSaveBook.current.click()
                                }
                              >
                                Change File
                              </Button>
                            </div>
                            <div className={Styles.DeleteIcon}>
                              <img
                                src={DELETE_ICON}
                                onClick={handleDeleteSaveBook}
                                alt="delete"
                                style={{
                                  width: "18px",
                                  height: "20px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={Styles.BoxInputImage}>
                          <span>Drag & Drop File Here</span>
                          <div className={Styles.BoxInputFile}>
                            <span>File must JPG or PNG</span>
                          </div>
                          <div className={Styles.BoxInputOr}>
                            <span>OR</span>
                          </div>
                          <div className={Styles.ContainerInputImage}>{}</div>
                          <div className={Styles.BoxInputButton}>
                            <Button
                              onClick={() =>
                                inputFileRefSaveBook.current.click()
                              }
                            >
                              <img src={UPLOAD_ICON} alt="upload-icon" />
                              <span>Browse File</span>
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className={Styles.ButtonAction}>
                <Button
                  type="submit"
                  disabled={disabledButton}
                  style={{
                    backgroundColor:
                      (disabledButton === true && "#B2C5F4") ||
                      (disabledButton === false && "#1571DE"),
                  }}
                >
                  Request Edit
                </Button>
                <Button onClick={handleResetFields}>Reset</Button>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.GroupControlImage}>
          <div
            className={Styles.DisplayImage}
            onClick={() => imageRef.current.click()}
          >
            {value?.profilePicture ? (
              <>
                <img
                  src={
                    typeof value?.profilePicture === "string"
                      ? value.profilePicture
                      : URL.createObjectURL(value?.profilePicture)
                  }
                  alt=""
                  // style={{ width: 200, height: 200, objectFit: "fill" }}
                />
                <div className={Styles.DisplayImageHover}>
                  <EditIcon sx={{ color: "#FFFFFF" }} />
                  <span>Change Profile Picture</span>
                </div>
              </>
            ) : (
              <>
                <Avatar
                  sx={{
                    width: 200,
                    height: 200,
                    opacity: 0.5,
                    // "&:hover": {
                    //   background: "rgba(0, 0, 0, 0.5)",
                    // },
                  }}
                >
                  <Typography sx={{ fontSize: 100, color: "grey" }}>
                    {firstLetterName}
                  </Typography>
                </Avatar>
                {/* <img src={PermataIcon} alt="" style={{ objectFit: 'contain' }}/> */}
                <div className={Styles.DisplayImageHover}>
                  <EditIcon sx={{ color: "#FFFFFF" }} />
                  <span>Change Profile Picture</span>
                </div>
              </>
            )}
          </div>
          {/* <img src={EMPLOYEE_PLACEHOLDER} alt="employee-pict" onClick={() => imageRef.current.click()}/> */}
        </div>

        <div style={{ display: "none" }}>
          <input
            type="file"
            name="myImage"
            ref={imageRef}
            onChange={onImageChange}
          />
        </div>
      </form>

      {isModalOpen && (
        <ModalSuccessEmployeeV2 active={isModalOpen} onOpen={setIsModalOpen} />
      )}
    </div>
  );
}
