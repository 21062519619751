import React, { useState } from "react";
import "./SalesOverviewTab.css";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { styled } from "@mui/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  YAxis,
} from "recharts";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 5,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1.5px solid #d3c9c9",
    borderColor: "#d3c9c9",
    borderRadius: 8,
    //   width: "100%",
  },
});

const YearPicker = ({ value, setValue }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} utils={AdapterDateFns}>
      <DatePicker
        views={["year"]}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        components={{
          OpenPickerIcon: KeyboardArrowDownIcon,
        }}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            helperText={null}
            size="small"
            sx={{ width: 115 }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

const SalesOverviewTab = () => {
  const [value, setValue] = useState(new Date());
  const data = [
    {
      name: "Jan",
      sales: 590,
    },
    {
      name: "Feb",
      sales: 868,
    },
    {
      name: "Mar",
      sales: 1397,
    },
    {
      name: "Apr",
      sales: 1480,
    },
    {
      name: "Mei",
      sales: 1300,
    },
    {
      name: "Jun",
      sales: 1400,
    },
    {
      name: "Jul",
      sales: 1400,
    },
    {
      name: "Agt",
      sales: 1400,
    },
    {
      name: "Sept",
      sales: 1400,
    },
    {
      name: "Okt",
      sales: 1400,
    },
    {
      name: "Nov",
      sales: 1400,
    },
    {
      name: "Des",
      sales: 1000,
    },
  ];
  return (
    <div className="ContainerSalesOverview">
      <div className="WrapperSalesOverview">
        <div className="HeaderSalesOverview">
          <span>Sales Overview</span>
          <YearPicker value={value} setValue={setValue} />
        </div>
        <ResponsiveContainer
          width="100%"
          height="75%"
        >
          <ComposedChart
            data={data}
            margin={{
              top: 10,
              right: 20,
              left: -30,
              bottom: 0,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" 
            // scale="band" 
            />
            <YAxis />
            <Tooltip />
            <Bar
              dataKey="sales"
              barSize={20}
              fill="#1571DE"
              radius={[5, 5, 0, 0]}
            />
          </ComposedChart>
        </ResponsiveContainer>
        <div className="ReportSalesOverview">
          <div className="SalesOverviewText">
            <span>December Sales</span>
          </div>
          <div className="SalesOveriewSales">
            <div className="">
              <span>25 Product Sales</span>
            </div>
            <div className="">
              <ArrowUpwardIcon sx={{ width: 18, height: 18, color: '#1B45D9' }} className="IconSalesOverview" />
              <span>41,8%</span>
            </div>
            <div className="">
              <span>From last Months</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesOverviewTab;
