/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import Geocode from "react-geocode";
import Styles from './AddLocationExternal.module.css';
import MapWithAutoComplete from "../../../_mapWithAutoComplete/MapWithAutoComplete/MapWithAutoComplete";
import InputText from "../../../utils/Input/Input";
// import FileBase from "react-file-base64";
// import { useDropzone } from "react-dropzone";
import {
  FormControl,
  MenuItem,
  Select,
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  Tooltip,
  Stack,
  Avatar,
  Button,
  Autocomplete,
  styled,
  TextField,
  Alert,
} from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import { createLocation, fetchUserByLocationExternal } from "../../../../redux/actions/External/locationActions";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import UseGroupName from "../../../utils/hooks/useGroupName";
import { makeStyles } from "@mui/styles";
import fetchClientCode from "../../../../async/client/fetchClientCode";
import { addLocation } from "../../../../redux/actions/SuperAdmin/locationActions";
import fetchClientCodeExternal from "../../../../async/client/fetchClientCodeExternal";

// import UploadImageDraggable from "../../../utils/UploadImageDraggable/UploadImageDraggable";

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
    "& .MuiSelect-select": {
      fontSize: 16,
    },
  },
});

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
      "& fieldset": {
          borderRadius: 8,
          border: '1px solid #e0e0e0',
      },
  },
  "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
  },
});

function createData(name, client, position, store, phoneNumber) {
  return {
    name,
    client,
    position,
    store,
    phoneNumber,
  };
}

const rows = [
  createData(
    "Sheila",
    "Sales Promotion Girl",
    "Indomaret Jaksel",
    "0812-9929-9299",
  ),
  createData("Anastasya", "Sales Promotion Girl", "Alfamidi", "0828-8289-9919"),
  createData("Anton Sujriwo", "MD", "Alfamidi", "0828-7372-9919"),
  createData("Alkatiri", "MD", "Alfamidi", "0828-7372-7737"),
  createData("Aisyah Dara", "Sales Promotion Girl", "Giant", "0828-7878-7737"),
  createData(
    "Aisyah Darah",
    "Sales Promotion Girl",
    "Indomaret",
    "0828-7878-7737"
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Employee Name",
  },
  {
    id: "client",
    numeric: false,
    disablePadding: false,
    label: "Client",
  },
  {
    id: "position",
    numeric: true,
    disablePadding: false,
    label: "Position",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { numSelected, setListDataEmployee, listDataEmployee, setSearchTerm } = props;

  const handleSearch = (event) => {
    setListDataEmployee(event.target.value);
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchTerm(listDataEmployee);
  };

  return (
    <Toolbar
      sx={{
        justifyContent: 'space-between',
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <SearchbarSA
              value={listDataEmployee}
              onChange={handleSearch}
              onSubmit={handleSubmitFilter}
            />
          </Typography>
        </div>
      ) : (
        // <Typography
        //   sx={{ flex: "1 1 100%" }}
        //   color="inherit"
        //   variant="subtitle1"
        //   component="div"
        // >
        //   {numSelected} selected
        // </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <SearchbarSA
              value={listDataEmployee}
              onChange={handleSearch}
              onSubmit={handleSubmitFilter}
            />
          </Typography>
        </div>
      )}

      {
        numSelected > 0 ? (
          <div style={{ display:'flex', alignItems: 'center'}}>
            <Tooltip title="Add Selected">
              <Button
                style={{
                //   width: 150,
                  height: 36,
                  backgroundColor: "#1571DE",
                  color: "#FFFFFF",
                  textTransform: "none",
                }}
                //   startIcon={<AddIcon />}
              >
                {numSelected} employee selected
              </Button>
              {/* <IconButton>
                <AddIcon />
              </IconButton> */}
            </Tooltip>
          </div>
        ) : null
        // <Tooltip title="Add Selected">
        //   <IconButton>
        //     <AddIcon />
        //   </IconButton>
        // </Tooltip>
      }
    </Toolbar>
  );
};

export default function AddLocationExternal() {
  const classes = useStyles();
  const listClient = UseGroupName();
  const token = localStorage.getItem('accessToken');
  const client = localStorage.getItem('client');
  const history = useHistory();
  const dispatch = useDispatch();
  // const { userData, isLoadingUser, errorMessageUser } = useSelector((state) => state.location)
  const [selectedDropdown, setSelectedDropdown] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  // const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listDataEmployee, setListDataEmployee] = useState("");
  // const [coordinate, setCoordinateAdd] = useState({});
  // const [Address, setAddress] = useState({});
  // const [showSearch, setShowSearch] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const [selected, setSelected] = useState("");
  const [selectedCode, setSelectedCode] = useState("");
  const [dataClientCode, setDataClientCode] = useState([]);
  const [coordinate, setCoordinateAdd] = useState({});
  const [address, setAddress] = useState({});
  const [showSearch, setShowSearch] = useState(true);
  const [alert, setAlert] = useState(null)
  //   const [files, setFiles] = useState([]);

  // const [values, setValues] = useState({
  //   locationName: '',
  //   // Address: '',
  //   phoneNumber: '',
  //   client: '',
  //   radius: 0,
  //   triggerClient: false,

  // });

  const persistClientCode = useMemo(() => {
    let persistClient = window.sessionStorage.getItem("selectedClientCode");
    let clientCode;

    if (persistClient) {
        clientCode = persistClient;
    } else {
        clientCode = selectedCode;
    }

    return clientCode;
}, [selectedCode]);

  const [form, setForm] = useState({
    name: '',
    address: '',
    longitude: '',
    latitude: '',
    clientCode: persistClientCode,
    radiusInMeter: ''
})

const handleChange = (e) => {
    setForm((prev) => ({
        ...prev,
        [e.target.name]: e.target.value
    }))
}

const clientNames = useMemo(() => {
  return dataClientCode.map((el) => el?.name_client);
}, [dataClientCode]);

  // useEffect(() => {
  //   if(values.triggerClient) {
  //     dispatch(fetchUserByLocationExternal(token,values.client))
  //   }
  // },[dispatch, client, token, values.triggerClient, values.client, listClient])

  // const handleChange = (prop) => (event) => {
  //   if(prop === 'client') {
  //     setValues({ ...values, client: event.target.value, triggerClient: true });
  //   } else {
  //     setValues({ ...values, [prop]: event.target.value });
  //   }
  // };

  const handleSelectedClient = (value) => {
    window.sessionStorage.setItem("selectedClient", value);
    setSelected(value);
    let activeCode = dataClientCode.find((item) => item.name_client === value);
    setSelectedCode(activeCode?.code_client)
    window.sessionStorage.setItem("selectedClientCode", selectedCode);
};



  useEffect(() => {
    (async () => {
        try {
            const { response } = await fetchClientCodeExternal(token);
            setDataClientCode(response);
        } catch (error) {
            console.log(error);
        }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

useEffect(() => {
  setForm((prev) => ({
      ...prev,
      "longitude": coordinate?.Longitude,
      "latitude": coordinate?.Latitude,
      "clientCode": selectedCode,
  }))
}, [coordinate?.Longitude, coordinate?.Latitude, selectedCode, address?.Address])


  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = userData
  //       .filter(
  //         (el) =>
  //           el.Name
  //             ?.toLowerCase()
  //             .includes(listDataEmployee.toLowerCase()) ||
  //           el.Position?.toLowerCase().includes(listDataEmployee.toLowerCase())
  //       )
  //       .map((n) => n?._id);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, Name) => {
  //   const selectedIndex = selected.indexOf(Name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, Name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const isSelected = (employeeName) => selected.indexOf(employeeName) !== -1;

  // const handleSave = (e) => {
  //   e.preventDefault();
  //   const data = {
  //     LocationName: values.locationName,
  //     Client: values.client,
  //     Radius: +values.radius,
  //     Longitude: coordinate?.Latitude,
  //     Latitude: coordinate?.Longitude,
  //     PhoneNumber: values.phoneNumber,
  //     Address: Address?.Address,
  //     UserId: selected
  //   };
  //   // console.log(data)
  //   dispatch(createLocation(token, data)).then(() => {
  //     history.push('/admin/location-external');
  //   });
  // };

  const query = `code=${persistClientCode}`;


  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.latitude === undefined || form.longitude === undefined) {
        setAlert("Please pin the location on the map");
    } else {
        dispatch(createLocation(token, form, query))
        history.push("/admin/location-external")
    }
}

const handleReset = (e) => {
    e.preventDefault();
    setForm((prev) => ({
        ...prev,
        "name": '',
        "address": '',
        "longitude": '',
        "latitude": '',
        "clientCode": '',
        "radiusInMeter": ''
    }))
    setSelected(null)
    setSelectedCode(null)
    setAddress(null)
    setCoordinateAdd(null)
}

useEffect(() => {

  Geocode.fromLatLng(form?.latitude, form?.longitude).then(
      (response) => {
          const address = response.results[0].formatted_address;
          setAddress({ Address: address });
      },
      (error) => {
          console.error(error);
      }
  );
}, [form?.latitude, form?.longitude])

  return (
    // <div className="add__location-container">
    //   <div className="add__location-map">
    //     <MapWithAutoComplete coordinate={coordinate} setCoordinate={setCoordinateAdd} showSearch={showSearch} setShowSearch={setShowSearch} setAddress={setAddress}/>
    //   </div>
    //   <div className="add__location-desc">
    //     <div className="add__location-wrapper-text">
    //       <div className="add__location-text-input">
    //         <label style={{ paddingBottom: 8 }}>Coordinate Point</label>
    //         <InputText name="Coordinate Point" type="text" value={Object.values(coordinate).join(", ")} disabled/>
    //       </div>
    //       <div className="add__location-text-input">
    //         <label style={{ paddingBottom: 8 }}>Location Name</label>
    //         <InputText name="Location Name" type="text" value={values.locationName} onChange={handleChange('locationName')} required/>
    //       </div>
    //     </div>
    //     <div className="add__location-wrapper-text" style={{ paddingTop: 20 }}>
    //       <div className="add__location-text-input">
    //         <label style={{ paddingBottom: 8 }}>Address</label>
    //         <InputText name="Address" type="text" value={Address?.Address || ""} 
    //         // onChange={handleChange('Address')} 
    //         required
    //          onChange={(e) => setAddress({ Address: e.target.value })}
    //          />
    //       </div>
    //       <div className="add__location-text-input">
    //         <label style={{ paddingBottom: 8 }}>Phone Number</label>
    //         <InputText name="Phone Number" type="number" value={values.phoneNumber} onChange={handleChange('phoneNumber')} required/>
    //       </div>
    //     </div>
    //     <div className="add__location-wrapper-text" style={{ paddingTop: 20 }}>
    //       <div className="add__location-text-input">
    //         <label style={{ paddingBottom: 8 }}>Client</label>
    //         <FormControl fullWidth size="small">
    //           <Select
    //           className={classes.selectBorder}
    //           value={values.client}
    //           displayEmpty
    //           onChange={(e) => handleChangeClient(e)}
    //           >
    //            {listClient.map((option, el) => (
    //             <MenuItem
    //               key={el}
    //               onClick={() => setValues({ ...values, client: option})}
    //               value={option}
    //             >
    //               {option}
    //             </MenuItem>
    //             ))}
    //             </Select>
    //           </FormControl>
    //       </div>
    //       <div className="add__location-text-input">
    //         <label style={{ paddingBottom: 8 }}>Radius / meters</label>
    //         <InputText name="Radius" type="number" value={values.radius} onChange={handleChange('radius')} required/>
    //       </div>
    //     </div>
    //     {
    //       isLoadingUser ? (
    //         <div style={{ display:'flex', alignItems: 'center', justifyContent: 'center', marginTop: 100}}>
    //         <div style={{ width: 200}}>
    //           <LoadingAnimation />
    //         </div>
    //       </div>
    //       ) : (
    //       <div className="add__location-container-table">
    //         <Box sx={{ width: "100%" }}>
    //           <Paper sx={{ width: "100%", backgroundColor: "#FAFAFA" }}>
    //             <EnhancedTableToolbar
    //               numSelected={selected.length}
    //               listDataEmployee={listDataEmployee}
    //               setListDataEmployee={setListDataEmployee}
    //               setSearchTerm={setSearchTerm}
    //             />
    //             <TableContainer>
    //               <Table
    //                 sx={{ width: "100%" }}
    //                 aria-labelledby="tableTitle"
    //               >
    //                 <EnhancedTableHead
    //                   numSelected={selected.length}
    //                   order={order}
    //                   orderBy={orderBy}
    //                   onSelectAllClick={handleSelectAllClick}
    //                   onRequestSort={handleRequestSort}
    //                   rowCount={
    //                     userData.filter(
    //                       (el) =>
    //                         el.Name
    //                           ?.toLowerCase()
    //                           .includes(listDataEmployee.toLowerCase()) ||
    //                         el.Position
    //                           ?.toLowerCase()
    //                           .includes(listDataEmployee.toLowerCase())
    //                     ).length
    //                   }
    //                 />
    //                 <TableBody>
    //                   {stableSort(userData, getComparator(order, orderBy))
    //                     .filter(
    //                       (el) =>
    //                         el.Name
    //                           ?.toLowerCase()
    //                           .includes(listDataEmployee.toLowerCase()) ||
    //                         el.Position
    //                           ?.toLowerCase()
    //                           .includes(listDataEmployee.toLowerCase())
    //                       //     &&
    //                       //   (selectedRegion
    //                       //     ? el.EmployeeArea === selectedRegion
    //                       //     : true)
    //                     )
    //                     .slice(
    //                       page * rowsPerPage,
    //                       page * rowsPerPage + rowsPerPage
    //                     )
    //                     .map((row, index) => {
    //                       const isItemSelected = isSelected(row?._id);
    //                       const labelId = `enhanced-table-checkbox-${index}`;
    //                       return (
    //                         <TableRow
    //                           hover
    //                           role="checkbox"
    //                           aria-checked={isItemSelected}
    //                           tabIndex={-1}
    //                           key={row?.Name}
    //                           selected={isItemSelected}
    //                         >
    //                           <TableCell padding="checkbox">
    //                             <Checkbox
    //                               color="primary"
    //                               checked={isItemSelected}
    //                               onClick={(event) =>handleClick(event, row?._id)}
    //                               inputProps={{
    //                                 "aria-labelledby": labelId,
    //                               }}
    //                             />
    //                           </TableCell>
    //                           <TableCell
    //                             component="th"
    //                             id={labelId}
    //                             scope="row"
    //                             // align="right"
    //                             // padding="none"
    //                           >
    //                             <Stack
    //                               direction="row"
    //                               alignItems="center"
    //                               spacing={2}
    //                             >
    //                               <Avatar
    //                                 alt={row?.Name}
    //                                 src=""
    //                                 // variant="square"
    //                               />
    //                               <Typography sx={{textTransform: 'capitalize'}}>
    //                                 {row?.Name?.toLowerCase()}
    //                               </Typography>
    //                             </Stack>
    //                           </TableCell>
    //                           <TableCell align="left" sx={{textTransform: 'capitalize'}}>{row?.Client?.toLowerCase()}</TableCell>
    //                           <TableCell align="left">{row?.Position}</TableCell>
    //                           <TableCell align="left"></TableCell>
    //                           <TableCell align="left">{row?.PhoneNumber}</TableCell>
    //                           {/* <TableCell align="right">
    //                             <Button
    //                               style={{
    //                                 width: 53,
    //                                 height: 36,
    //                                 backgroundColor: "#1571DE",
    //                                 color: "#FFFFFF",
    //                                 textTransform: "none",
    //                               }}
    //                             >
    //                               Add
    //                             </Button>
    //                           </TableCell> */}
    //                         </TableRow>
    //                       );
    //                     })}
    //                   {/* {emptyRows > 0 && (
    //                   <TableRow
    //                     style={{
    //                       height: (dense ? 33 : 53) * emptyRows,
    //                     }}
    //                   >
    //                     <TableCell colSpan={6} />
    //                   </TableRow>
    //                 )} */}
    //                 </TableBody>
    //               </Table>
    //             </TableContainer>
    //             <TablePagination
    //               rowsPerPageOptions={[ 10, 20, 30]}
    //               component="div"
    //               count={userData.filter(
    //                 (el) =>
    //                   el.Name?.toLowerCase().includes(listDataEmployee.toLowerCase()) ||
    //                   el.Position?.toLowerCase().includes(listDataEmployee.toLowerCase())
    //               ).length}
    //               rowsPerPage={rowsPerPage}
    //               page={page}
    //               onPageChange={handleChangePage}
    //               onRowsPerPageChange={handleChangeRowsPerPage}
    //             />
    //           </Paper>
    //         </Box>
    //       </div>
    //       )
    //     }
    //     <div className="add__location-button">
    //       <Button
    //         className="add__location-btn-save"
    //         style={{
    //           background: "#1571DE",
    //           color: "#FFFFFF",
    //           fontSize: 14,
    //           fontWeight: 500,
    //         }}
    //         type="submit"
    //         onClick={handleSave}
    //       >
    //         Save
    //       </Button>
    //       <Button
    //         className="add__location-btn-cancel"
    //         style={{
    //           border: "1px solid #1571DE",
    //           color: "#1571DE",
    //           fontSize: 14,
    //           fontWeight: 500,
    //         }}
    //         onClick={() => history.push("/admin/location-external")}
    //       >
    //         Cancel
    //       </Button>
    //     </div>
    //   </div>
    // </div>
    <div className={Styles.ContainerWrapper}>
    <span>Add Location Data</span>
    <div className={Styles.Container}>
        <form onSubmit={handleSubmit}>
            <div className={Styles.Top}>
                <span>Location Details</span>
                <div className={Styles.BoxForm}>
                    <label htmlFor="name">Location Name</label>
                    <input type="text" id="name" value={form.name} name="name" onChange={handleChange} required />
                </div>
                <div className={Styles.BoxForm}>
                    <label htmlFor="clientName">Client Name</label>
                    <Autocomplete
                        disablePortal
                        options={clientNames}
                        value={selected}
                        sx={{ width: '100%' }}
                        onChange={(e, value) =>
                            value === null
                                ? handleSelectedClient("")
                                : handleSelectedClient(value)
                        }
                        renderInput={(params) => <MuiTextField {...params} size="small" sx={{ background: 'var(--main-bg)' }} required />}
                    />
                </div>
                <div className={Styles.BoxForm}>
                    <label htmlFor="address">Address</label>
                    <input type="text" id="address" value={form.address} name="address" onChange={handleChange} required />
                </div>
                <div className={Styles.BoxForm}>
                    <label htmlFor="radiusInMeter">Range (in metre)</label>
                    <input type="number" id="radiusInMeter" value={form.radiusInMeter} name="radiusInMeter" onChange={handleChange} required />
                </div>
            </div>
            {alert && (
                <div className={Styles.AlertWrapper}>
                    <Alert severity="warning" onClose={() => { setAlert(null) }}>{alert}</Alert>
                </div>
            )}
            <div className={Styles.MapContainer}>
                <MapWithAutoComplete coordinate={coordinate} setCoordinate={setCoordinateAdd} showSearch={showSearch} setShowSearch={setShowSearch} setAddress={setAddress} />
            </div>
            <div className={Styles.Bottom}>
                <div className={Styles.BoxAuto}>
                    <span>Address Details</span>
                    <span>{address?.Address ? address?.Address : form.address ? form?.address : '-'}</span>
                </div>
                <div className={Styles.LastWrapper}>
                    <div className={Styles.BoxAuto}>
                        <span>Latitude</span>
                        <span>{coordinate?.Latitude ? coordinate?.Latitude : '-'}</span>
                    </div>
                    <div className={Styles.BoxAuto}>
                        <span>Longitude</span>
                        <span>{coordinate?.Longitude ? coordinate?.Longitude : '-'}</span>
                    </div>
                </div>
            </div>
            <div className={Styles.ButtonWrapper}>
                <Button type="submit" variant="contained" className={Styles.SaveButton}>Save</Button>
                <Button type="button" variant="outlined" onClick={handleReset} className={Styles.ResetButton}>Reset</Button>
            </div>
        </form>
    </div>
</div>
  );
}
