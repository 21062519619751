import React, { useState } from "react";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import logo_employee from "../../../../assets/images/jaka-image.png";
// import company_logo from "../../../../assets/images/LogoClient.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import "./ClientDirectoryMenu.css";
import {
  Autocomplete,
  // Avatar,
  // AvatarGroup,
  TextField,
  styled,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  Typography,
  // Button,
  TablePagination,
} from "@mui/material";
// import SettingsIcon from "@mui/icons-material/Settings";
// import EditIcon from "@mui/icons-material/Edit";
import Scrollbar from "../../_user/TableUsers/Scrollbar";
import UserListHead from "../../_user/TableUsers/UserListHead";
import { Link } from "react-router-dom";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const data = [
  {
    id: "1",
    Name: "Melvin Andrean",
    Position: "Ux Designer",
    Email: "melvinandrean@gmail.com",
    Division: "Product Division",
    Ext: 54533,
    City: "DKI Jakarta",
    Img: "",
    LeaderImg: "",
  },
  {
    id: "2",

    Name: "Stevi Marvelus",
    Position: "Product Designer",
    Email: "stevonmarvelus@gmail.com",
    Division: "Product Division",
    Ext: 43222,
    City: "Bandung",
    Img: "",
    LeaderImg: "",
  },
  {
    id: "3",

    Name: "Salsabila",
    Position: "Marketing SPV",
    Email: "salsabila@gmail.com",
    Division: "Marketing Division",
    Ext: 54533,
    City: "Bandung",
    Img: "",
    LeaderImg: "",
  },
  {
    id: "4",

    Name: "Christina",
    Position: "Frontend Engineer",
    Email: "christina@gmail.com",
    Division: "IT Division",
    Ext: 32324,
    City: "DKI Jakarta",
    Img: "",
    LeaderImg: "",
  },
  {
    id: "5",
    Name: "Dianan Paramita",
    Position: "Backend Engineer",
    Email: "dianaparamita@gmail.com",
    Division: "IT Division",
    Ext: 54533,
    City: "DKI Jakarta",
    Img: "",
    LeaderImg: "",
  },
  {
    id: "6",
    Name: "Dianan Paramita",
    Position: "Backend Engineer",
    Email: "dianaparamita@gmail.com",
    Division: "IT Division",
    Ext: 54533,
    City: "DKI Jakarta",
    Img: "",
    LeaderImg: "",
  },
  {
    id: "7",
    Name: "Dianan Paramita",
    Position: "Backend Engineer",
    Email: "dianaparamita@gmail.com",
    Division: "IT Division",
    Ext: 54533,
    City: "DKI Jakarta",
    Img: "",
    LeaderImg: "",
  },
  {
    id: "8",
    Name: "Dianan Paramita",
    Position: "Backend Engineer",
    Email: "dianaparamita@gmail.com",
    Division: "IT Division",
    Ext: 54533,
    City: "DKI Jakarta",
    Img: "",
    LeaderImg: "",
  },
  {
    id: "9",
    Name: "Dianan Paramita",
    Position: "Backend Engineer",
    Email: "dianaparamita@gmail.com",
    Division: "IT Division",
    Ext: 54533,
    City: "DKI Jakarta",
    Img: "",
    LeaderImg: "",
  },
  {
    id: "10",
    Name: "Dianan Paramita",
    Position: "Backend Engineer",
    Email: "dianaparamita@gmail.com",
    Division: "IT Division",
    Ext: 54533,
    City: "DKI Jakarta",
    Img: "",
    LeaderImg: "",
  },
];

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
  {
    label: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { label: "The Good, the Bad and the Ugly", year: 1966 },
  { label: "Fight Club", year: 1999 },
  {
    label: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    label: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { label: "Forrest Gump", year: 1994 },
  { label: "Inception", year: 2010 },
  {
    label: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: "Goodfellas", year: 1990 },
  { label: "The Matrix", year: 1999 },
  { label: "Seven Samurai", year: 1954 },
  {
    label: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { label: "City of God", year: 2002 },
  { label: "Se7en", year: 1995 },
  { label: "The Silence of the Lambs", year: 1991 },
  { label: "It's a Wonderful Life", year: 1946 },
  { label: "Life Is Beautiful", year: 1997 },
  { label: "The Usual Suspects", year: 1995 },
  { label: "Léon: The Professional", year: 1994 },
  { label: "Spirited Away", year: 2001 },
  { label: "Saving Private Ryan", year: 1998 },
  { label: "Once Upon a Time in the West", year: 1968 },
  { label: "American History X", year: 1998 },
  { label: "Interstellar", year: 2014 },
  { label: "Casablanca", year: 1942 },
  { label: "City Lights", year: 1931 },
  { label: "Psycho", year: 1960 },
  { label: "The Green Mile", year: 1999 },
  { label: "The Intouchables", year: 2011 },
  { label: "Modern Times", year: 1936 },
  { label: "Raiders of the Lost Ark", year: 1981 },
  { label: "Rear Window", year: 1954 },
  { label: "The Pianist", year: 2002 },
  { label: "The Departed", year: 2006 },
  { label: "Terminator 2: Judgment Day", year: 1991 },
  { label: "Back to the Future", year: 1985 },
  { label: "Whiplash", year: 2014 },
  { label: "Gladiator", year: 2000 },
  { label: "Memento", year: 2000 },
  { label: "The Prestige", year: 2006 },
  { label: "The Lion King", year: 1994 },
  { label: "Apocalypse Now", year: 1979 },
  { label: "Alien", year: 1979 },
  { label: "Sunset Boulevard", year: 1950 },
  {
    label:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { label: "The Great Dictator", year: 1940 },
  { label: "Cinema Paradiso", year: 1988 },
  { label: "The Lives of Others", year: 2006 },
  { label: "Grave of the Fireflies", year: 1988 },
  { label: "Paths of Glory", year: 1957 },
  { label: "Django Unchained", year: 2012 },
  { label: "The Shining", year: 1980 },
  { label: "WALL·E", year: 2008 },
  { label: "American Beauty", year: 1999 },
  { label: "The Dark Knight Rises", year: 2012 },
  { label: "Princess Mononoke", year: 1997 },
  { label: "Aliens", year: 1986 },
  { label: "Oldboy", year: 2003 },
  { label: "Once Upon a Time in America", year: 1984 },
  { label: "Witness for the Prosecution", year: 1957 },
  { label: "Das Boot", year: 1981 },
  { label: "Citizen Kane", year: 1941 },
  { label: "North by Northwest", year: 1959 },
  { label: "Vertigo", year: 1958 },
  {
    label: "Star Wars: Episode VI - Return of the Jedi",
    year: 1983,
  },
  { label: "Reservoir Dogs", year: 1992 },
  { label: "Braveheart", year: 1995 },
  { label: "M", year: 1931 },
  { label: "Requiem for a Dream", year: 2000 },
  { label: "Amélie", year: 2001 },
  { label: "A Clockwork Orange", year: 1971 },
  { label: "Like Stars on Earth", year: 2007 },
  { label: "Taxi Driver", year: 1976 },
  { label: "Lawrence of Arabia", year: 1962 },
  { label: "Double Indemnity", year: 1944 },
  {
    label: "Eternal Sunshine of the Spotless Mind",
    year: 2004,
  },
  { label: "Amadeus", year: 1984 },
  { label: "To Kill a Mockingbird", year: 1962 },
  { label: "Toy Story 3", year: 2010 },
  { label: "Logan", year: 2017 },
  { label: "Full Metal Jacket", year: 1987 },
  { label: "Dangal", year: 2016 },
  { label: "The Sting", year: 1973 },
  { label: "2001: A Space Odyssey", year: 1968 },
  { label: "Singin' in the Rain", year: 1952 },
  { label: "Toy Story", year: 1995 },
  { label: "Bicycle Thieves", year: 1948 },
  { label: "The Kid", year: 1921 },
  { label: "Inglourious Basterds", year: 2009 },
  { label: "Snatch", year: 2000 },
  { label: "3 Idiots", year: 2009 },
  { label: "Monty Python and the Holy Grail", year: 1975 },
];

const TABLE_HEAD = [
  { id: "Name",label: "Name", alignRight: false },
  { id: "Division",label: "Division", alignRight: false },
  { id: "Email",label: "Email", alignRight: false },
  { id: "Ext",label: "Extention", alignRight: false },
  { id: "City",label: "Location", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
export default function ClientDirectoryMenu({
  dropdownSelected,
  setDropdownSelected,
  client,
}) {
  // const [isActive, setIsActive] = useState(false);
  // const type = ["All", "Internal", "Eksternal"];
  const [selected, setSelected] = useState("");
  const [filterName, setFilterName] = useState("");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  return (
    <div className="container-client-dir">
      <div className="wrapper-client-dir">
        <div className="item-top-client-dir">
          <div className="item-left-client-dir">
            <SearchbarSA value={filterName} onChange={handleFilterByName} />
          </div>
          <div className="item-right-client-dir">
            <div>
              <Autocomplete
                disablePortal
                options={top100Films}
                value={selected}
                inputValue={selected}
                onInputChange={(e, value) => setSelected(value)}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    size="small"
                    sx={{ width: 250 }}
                    placeholder="All"
                  />
                )}
              />
            </div>
            {/* <div className="box-dropdown-user-detail-menu">
              <div
                className="dropdown-btn-user-detail"
                onClick={(e) => setIsActive(!isActive)}
              >
                <span value="All">{dropdownSelected}</span>
                <KeyboardArrowDownIcon />
              </div>
              {isActive && (
                <div className="dropdown-content-user-detail">
                  {type.map((option) => (
                    <div
                      onClick={(e) => {
                        setDropdownSelected(option);
                        setIsActive(false);
                      }}
                      className="dropdown-item-user-detail"
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div> */}
          </div>
        </div>
        <div className="wrapper-table-client-dir">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table
                sx={{
                  width: "100%",
                  maxHeight: "100%",
                  backgroundColor: "#fafafa",
                  borderRadius: 4,
                }}
              >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  rowCount={data.length}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(data, getComparator(order, orderBy))
                    .filter(
                      (el) =>
                        (el.Name?.toLowerCase().includes(
                          filterName.toLowerCase()
                        ) ||
                          el.Division?.toLowerCase().includes(
                            filterName.toLowerCase()
                          )) &&
                        (selected ? el.Name === selected : true)
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, ind) => {
                      const labelId = `enhanced-table-checkbox-${ind}`;
                      return (
                        <TableRow key={row._id} hover tabIndex={-1}>
                          <TableCell></TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            id={labelId}
                          >
                            <Link
                              to={{
                                pathname: `/superadmin/profile-clients/${row._id}`,
                                state: {
                                  id: row._id,
                                  name: row.Name,
                                  address: row.Address,
                                  contact: row.PhoneNumber,
                                  group: row.Group,
                                },
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <AccountCircleIcon
                                  style={{ width: 42, height: 42 }}
                                />
                                <Typography variant="subtitle2">
                                  {row.Name}
                                </Typography>
                              </Stack>
                            </Link>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                              {row.Division}
                            </Typography>
                          </TableCell>
                          <TableCell>{row.Email}</TableCell>
                          <TableCell>{row.Ext}</TableCell>
                          <TableCell>{row.City}</TableCell>
                          {/* <TableCell align="right">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={0}
                            >
                              <Link
                                to={{
                                  pathname: `/superadmin/client-settings/${row._id}`,
                                  state: {
                                    name: row.Name,
                                    schemaAttendance,
                                    loanSchema,
                                    workspaceSchema,
                                    schema: row.SchemaAttendance,
                                    id: row._id,
                                    schemaId: row.SchemaAttendanceId,
                                  },
                                }}
                              >
                                <Button>
                                  <SettingsIcon style={{ color: "#757575" }} />
                                </Button>
                              </Link>
                              <Link
                                to={{
                                  pathname: `/superadmin/edit-clients/${row._id}`,
                                  state: {
                                    id: row._id,
                                    name: row.Name,
                                    address: row.Address,
                                    contact: row.PhoneNumber,
                                    group: row.Group,
                                  },
                                }}
                              >
                                <Button>
                                  <EditIcon />
                                </Button>
                              </Link>
                            </Stack>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={
              data.filter(
                (el) =>
                  (el.Name?.toLowerCase().includes(filterName.toLowerCase()) ||
                    el.Division?.toLowerCase().includes(
                      filterName.toLowerCase()
                    )) &&
                  (selected ? el.Name === selected : true)
              ).length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        {/* <div className="item-main-client-dir">
          {data.map((item, index) => (
            <div className="rec-item-client-dir" key={item.id}>
              <div className="rec-item-client-dir-left">
                <img src={logo_employee} alt="Employee Pict" />
                <div className="profile-desc-dir-left">
                  <label htmlFor="">{item.name}</label>
                  <h3>{item.position}</h3>
                  <span>{item.email}</span>
                </div>
              </div>
              <div className="line-cross"></div>
              <div className="rec-item-client-dir-left-2">
                <div className="rec-item-product-division">
                  <label>{item.division}</label>
                </div>
                <div className="wrapper-item-CDM">
                <div className="rec-item-inside-left">
                  <span>Leader</span>
                  <img
                    src={logo_employee}
                    alt=""
                    style={{ width: 20, height: 20 }}
                  />
                </div>
                <div className="rec-item-inside-right">
                  <label>Staff</label>
                  <AvatarGroup max={3}>
                    <Avatar
                      src={logo_employee}
                      style={{
                        border: "none",
                        width: 20,
                        height: 20,
                      }}
                    />
                    <Avatar
                      src={logo_employee}
                      style={{
                        border: "none",
                        width: 20,
                        height: 20,
                      }}
                    />
                    <Avatar
                      src={logo_employee}
                      style={{
                        border: "none",
                        width: 20,
                        height: 20,
                      }}
                    />
                  </AvatarGroup>
                </div>
                </div>
              
              </div>
              <div className="line-cross"></div>
              <div className="rec-item-client-dir-right">
                <label>Ext</label>
                <span>{item.ext}</span>
              </div>
              <div className="line-cross"></div>
              <div className="rec-item-client-dir-right-2">
                <span>{item.city}</span>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
}
