import { Button } from "@mui/material";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";



const ExportDataExcelFMCG = ({
    data,
    filename,
    initial,
    endIcon,
    startIcon,
    className,
  }) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    // const pdfExtension = '.pdf'
  
    const exportToCSV = (csvData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };
  
    return (
      <Button
        style={{
          textTransform: "none",
          backgroundColor: "#1571de",
          color: "white",
          // width: 181,
          height: 40,
          borderRadius: 8,
        }}
        onClick={(e) => exportToCSV(data, filename)}
        startIcon={startIcon}
        className={className}
      >
        {initial}
      </Button>
    );
  };
  
  export default ExportDataExcelFMCG;