import axios from "axios";
let URL = "http://122.50.7.190:5003/api/mws";

const dataEmployeeByRangeDateId = (token , id , startDate , endDate ) => {
  return axios
    .get(
      `${URL}/absence/rangeUserId?id=${id}&start=${startDate}&end=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
};

export default dataEmployeeByRangeDateId;
