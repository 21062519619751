import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import Styles from "./ResignForm.module.css";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import COMPANY_ICON from "../../../../assets/images/employee-client.png";
import BRIEFCASE_ICON from "../../../../assets/images/employee-job.png";
import USER_ICON from "../../../../assets/images/employee-user.png";
import NRK_ICON from "../../../../assets/images/nrk-employee.png";
import PHONE_ICON from "../../../../assets/images/phone-icon.png";
import LOCATION_ICON from "../../../../assets/images/location-icon.png";
import CALENDAR_ICON from "../../../../assets/images/calendar-icon-request.png";
import ALIGN_LEFT_ICON from "../../../../assets/images/align-left-icon-request.png";
import DOWNLOAD_ICON from "../../../../assets/images/Download.png";
import PDF_ICON from "../../../../assets/images/pdf-download.png";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Tab } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";

// const datas = {
//   humanCaptial: {
//     "Exit interView": "20 Juli 2024",
//     "Debt to company": "Tidak ada",
//   },
//   companyAssets: {
//     Kendaraan: "Tidak ada",
//     "ID Card": "Tidak ada",
//     Kalkulator: "20 Juli 2024",
//     "Kunci Ruang": "Tidak ada",
//     Laptop: "Tidak ada",
//     Lainnya:
//       "Mouse, kyboard,  Mouse, kyboard, monitorMouse, kyboard, monitorMouse, kyboard, monitorMouse, kyboard, monitor",
//   },
// };

// console.log(datas.humanCaptial);

export default function ResignForm(props) {
  const {
    dataResign,
    // type,
    setIsModalOpenSuccess,
    setIsModalOpenReject,
    // setIsModalOpenNote,
  } = props;
  // const location = useLocation();
  const [value, setValue] = useState("1");

  const [storedData, setStoredData] = useState();

  const persistValue = useMemo(() => {
    let selected = window.sessionStorage.getItem("selectedValue");
    let result;

    if (selected) {
      result = selected;
    } else {
      result = value;
    }
    return result;
  }, [value]);

  useEffect(() => {
    setValue(persistValue);
  }, [persistValue]);

  useEffect(() => {
    setStoredData(dataResign);
  }, [dataResign]);

  useEffect(() => {
    setStoredData((prev) => ({
      ...prev,
      status: {
        ...prev?.status,
      },
    }));
  }, [dataResign]);

  // console.log(storedData, "nasdasd");

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    window.sessionStorage.setItem("selectedValue", newValue);
  };

  const handleReject = () => {
    setIsModalOpenReject(true);
  };

  const handleApprove = () => {
    setStoredData((prev) => ({
      ...prev,
      status: {
        ...prev.status,
        approval: true,
      },
    }));
    setIsModalOpenSuccess(true);
  };

  const onDownloadResignLetter = () => {
    fetch(dataResign?.resignLetter?.url).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `${dataResign?.resignLetter?.fileName}.pdf`;
        alink.click();
      });
    });
  };

  const onDownloadExitInterview = () => {
    fetch(dataResign?.exitInterview?.file?.url).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `${dataResign?.exitClearance?.file?.fileName}.pdf`;
        alink.click();
      });
    });
  };

  const onDownloadExitClearance = () => {
    fetch(dataResign?.exitClearance?.file?.url).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `${dataResign?.exitClearance?.file?.fileName}.pdf`;
        alink.click();
      });
    });
  };

  return (
    <div className={Styles.ContainerResign}>
      <Helmet>
        <title>MyWorkSpace | Resign Form</title>
      </Helmet>
      <div className={Styles.Background}></div>
      <div className={Styles.BoxWrapperResign}>
        <div className={Styles.WrapperResign}>
          <div className={Styles.WrapperResignForm}>
            {storedData?.status?.approval === true && (
              <div className={Styles.StatusController}>
                <div className={Styles.StatusText}>
                  <span>Approved</span>
                  <span>You have approved this request</span>
                </div>
                <CheckIcon sx={{ color: "#22970F" }} />
              </div>
            )}
            {storedData?.status?.remark ? (
              <div className={Styles.StatusControllerReject}>
                <div className={Styles.StatusTextReject}>
                  <span>Rejected</span>
                  <span>You have rejected this request</span>
                </div>
                <CloseIcon sx={{ color: "#D52701" }} />
              </div>
            ) : null}
            {/* {storedData?.status?.remark ? (
              <div className={Styles.StatusControllerReject}>
                <div className={Styles.StatusTextReject}>
                  <span>Rejected</span>
                  <span>You have rejected this request</span>
                </div>
                <CloseIcon sx={{ color: "#D52701" }} />
              </div>
            ) : null} */}
            <div className={Styles.Heading}>
              <span>Resign Form</span>
            </div>

            <div className={Styles.ValueControl}>
              <div className={Styles.Value}>
                <div className={Styles.Desc}>
                  <img src={USER_ICON} alt="user-img" />
                  <div className={Styles.DescItems}>
                    <span>Name</span>
                    <span>{storedData?.name ? storedData?.name : "-"}</span>
                  </div>
                </div>
                <div className={Styles.Desc}>
                  <img src={NRK_ICON} alt="user-img" />
                  <div className={Styles.DescItems}>
                    <span>NRK</span>
                    <span>
                      {storedData?.employeeId ? storedData?.employeeId : "-"}
                    </span>
                  </div>
                </div>
              </div>
              <div className={Styles.Value}>
                <div className={Styles.Desc}>
                  <img src={BRIEFCASE_ICON} alt="user-img" />
                  <div className={Styles.DescItems}>
                    <span>Position</span>
                    <span>
                      {storedData?.jobTitle ? storedData?.jobTitle : "-"}
                    </span>
                  </div>
                </div>
                <div className={Styles.Desc}>
                  <img src={COMPANY_ICON} alt="user-img" />
                  <div className={Styles.DescItems}>
                    <span>Division</span>
                    <span>
                      {storedData?.division ? storedData?.division : "-"}
                    </span>
                  </div>
                </div>
              </div>
              <div className={Styles.Value}>
                <div className={Styles.Desc}>
                  <img src={PHONE_ICON} alt="user-img" />
                  <div className={Styles.DescItems}>
                    <span>Phone Number</span>
                    <span>
                      <label className={Styles.Extention}>+62</label>{" "}
                      {storedData?.phoneNumber}
                    </span>
                  </div>
                </div>
                <div className={Styles.Desc}>
                  <img src={LOCATION_ICON} alt="user-img" />
                  <div className={Styles.DescItems}>
                    <span>Address</span>
                    <span>
                      {storedData?.address ? storedData?.address : "-"}
                    </span>
                  </div>
                </div>
              </div>

              <div className={Styles.Value}>
                <div className={Styles.Desc}>
                  <img src={CALENDAR_ICON} alt="user-img" />
                  <div className={Styles.DescItems}>
                    <span>Join Date</span>
                    <span>
                      {storedData?.joinDate
                        ? moment(
                            new Date(storedData?.joinDate).getTime()
                          ).format("DD MMM YYYY")
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className={Styles.Desc}>
                  <img src={CALENDAR_ICON} alt="user-img" />
                  <div className={Styles.DescItems}>
                    <span>Resignation date</span>
                    <span>
                      {storedData?.resignDate
                        ? moment(
                            new Date(storedData?.resignDate).getTime()
                          ).format("DD MMM YYYY")
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>

              <div className={Styles.Value}>
                <div className={Styles.Desc}>
                  <img src={CALENDAR_ICON} alt="user-img" />
                  <div className={Styles.DescItems}>
                    <span>Request Date</span>
                    <span>
                      {storedData?.applyDate
                        ? moment(
                            new Date(storedData?.applyDate).getTime()
                          ).format("DD MMM YYYY")
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className={Styles.Desc}>
                  <img src={ALIGN_LEFT_ICON} alt="user-img" />
                  <div className={Styles.DescItems}>
                    <span>Reasons for resigning</span>
                    <span>{storedData?.remark ? storedData?.remark : "-"}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={Styles.ContainerPDF}>
              <div className={Styles.HeadingPDF}>
                <span>Resignation Letter</span>
              </div>

              {dataResign?.resignLetter && (
                <div className={Styles.BoxPDF} onClick={onDownloadResignLetter}>
                  <div className={Styles.PDFItems}>
                    <div className={Styles.CircleImage}>
                      <img src={PDF_ICON} alt="pdf-icon" />
                    </div>
                    <span>{`${dataResign?.resignLetter?.fileName}.pdf`}</span>
                  </div>
                  <img
                    src={DOWNLOAD_ICON}
                    alt="download-icon"
                    className={Styles.DownloadIcon}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={Styles.WrapperExitForm}>
            <div className={Styles.Tab}>
              <TabContext value={value}>
                <Box
                  sx={{
                    width: "100%",
                    borderBottom: "1px  solid #E9E9E9",
                  }}
                >
                  <TabList
                    centered
                    onChange={handleChange}
                    sx={{
                      "& button:hover": {
                        color: "#1571DE",
                      },
                      "& button:focus": {
                        color: "#1571DE",
                      },
                      "& button:active": {
                        color: "#1571DE",
                      },
                      "& .MuiTabs-flexContainer": {
                        justifyContent: "space-between",
                        transitionDuration: "0.5s",
                      },
                    }}
                  >
                    <Tab
                      label={<span className={Styles.Tab}>Exit Interview</span>}
                      value="1"
                      sx={{
                        color: "#A2A9BE",
                        flex: 1,
                        width: "100%",
                      }}
                    />
                    <Tab
                      label={<span className={Styles.Tab}>Exit Clearance</span>}
                      value="2"
                      sx={{
                        color: "#A2A9BE",
                        flex: 1,
                        width: "100%",
                      }}
                    />
                  </TabList>
                </Box>
                <TabPanel value="1" sx={{ padding: 0 }}>
                  <ExitInterviewTab
                    data={storedData}
                    onDownload={onDownloadExitInterview}
                  />
                </TabPanel>
                <TabPanel value="2" sx={{ padding: 0 }}>
                  <ExitClearanceTab
                    data={storedData}
                    onDownload={onDownloadExitClearance}
                  />
                </TabPanel>
              </TabContext>
            </div>
          </div>

          {storedData?.status?.remark ||
          storedData?.status?.approval === true ? null : (
            <div className={Styles.ButtonAction}>
              <Button onClick={handleReject}>Reject</Button>
              <Button onClick={handleApprove}>Approve</Button>
            </div>
          )}

          {storedData?.status?.remark ? (
            <div className={Styles.NoteWrapper}>
              <label>Note</label>
              <div className={Styles.NoteText}>
                <span>{dataResign?.status.remark}</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

const ExitInterviewTab = (props) => {
  const { data, onDownload } = props;

  const result = useMemo(() => {
    return data?.exitInterview;
  }, [data]);

  return (
    <div className={Styles.BodyTab}>
      <div className={Styles.ValueGroup}>
        {data?.remark && (
          <div className={Styles.InputGroup}>
            <span>Alasan keluar</span>
            <textarea
              type="text"
              // placeholder="Dapat pekerjaan yang lebih baik"
              disabled
              value={data?.remark}
            />
          </div>
        )}

        {result?.inputToCompany && (
          <div className={Styles.InputGroup}>
            <span>
              Masukan bagi perusahaan (atasan, bawahan, rekan kerja,
              peraturan/kebijakan perusahaan, dll)
            </span>
            <textarea
              type="text"
              // placeholder="Semoga dapat menjadi orang yang lebih baik lagi"
              value={result?.inputToCompany}
              disabled
            />
          </div>
        )}

        {result?.afterResign && (
          <div className={Styles.InputGroup}>
            <span>
              Apa yang anda lakukan setelah keluar dari perusahaan ini?
            </span>
            <textarea
              type="text"
              // placeholder="Kerja di bidang yang sama dengan tingkat ekspektasi yang lebih baik"
              value={result?.afterResign}
              disabled
            />
          </div>
        )}

        {result?.pendingRights && (
          <div className={Styles.InputGroup}>
            <span>Hak-hak yang tertunda</span>
            <textarea
              type="text"
              value={result?.pendingRights}
              // placeholder="Invetaris kantor"
              disabled
            />
          </div>
        )}
      </div>
      <div className={Styles.ContainerPDF}>
        <div className={Styles.HeadingPDF}>
          <span>Exit Interview File</span>
        </div>

        {result?.file && (
          <div className={Styles.BoxPDF} onClick={onDownload}>
            <div className={Styles.PDFItems}>
              <div className={Styles.CircleImage}>
                <img src={PDF_ICON} alt="pdf-icon" />
              </div>
              <span>{`${result?.file?.fileName}.pdf`}</span>
            </div>
            <img
              src={DOWNLOAD_ICON}
              alt="download-icon"
              className={Styles.DownloadIcon}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ExitClearanceTab = (props) => {
  const { data, onDownload } = props;

  const assets = useMemo(() => {
    return data?.assets;
  }, [data]);
  return (
    <div className={Styles.BodyTab}>
      <div className={Styles.ValueGroup}>
        <div className={Styles.Content}>
          <span>Exit Status</span>
          <span>Pengunduran Diri</span>
        </div>

        <div className={Styles.ContentGroup}>
          <label>Human Capital</label>

          {/* {Object.keys(datas.humanCaptial).map((each, idx) => (
            <div className={Styles.Content} key={idx}>
              <span>{each}</span>
              <span>{datas.humanCaptial[each]}</span>
            </div>
          ))} */}
          <div className={Styles.Content}>
            <span>Exit Interview</span>
            <span>
              {data?.HC?.exitInterview
                ? moment(new Date(data?.date).getTime()).format("DD MMM YYYY")
                : "-"}
            </span>
          </div>
          <div className={Styles.Content}>
            <span>Debt to company</span>
            <span>
              {data?.HC?.debt
                ? moment(new Date(data?.date).getTime()).format("DD MMM YYYY")
                : "-"}
            </span>
          </div>
        </div>

        <div className={Styles.ContentGroup}>
          <label>Company Aset</label>
          {/* {Object.keys(datas.companyAssets).map((each, idx) => (
            <div className={Styles.Content} key={idx}>
              <span>{each}</span>
              <span>{datas.companyAssets[each]}</span>
            </div>
          ))} */}
          <div className={Styles.Content}>
            <span>Kendaraan</span>
            <span>
              {assets?.vehicle
                ? moment(new Date(assets?.vehicle?.date).getTime()).format(
                    "DD MMM YYYY"
                  )
                : "-"}
            </span>
          </div>
          <div className={Styles.Content}>
            <span>ID Card</span>
            <span>
              {assets?.idCard
                ? moment(new Date(assets?.idCard?.date).getTime()).format(
                    "DD MMM YYYY"
                  )
                : "-"}
            </span>
          </div>
          <div className={Styles.Content}>
            <span>Calculator</span>
            <span>
              {assets?.calculator
                ? moment(new Date(assets?.calculator?.date).getTime()).format(
                    "DD MMM YYYY"
                  )
                : "-"}
            </span>
          </div>
          <div className={Styles.Content}>
            <span>Kunci ruang/meja</span>
            <span>
              {assets?.keys
                ? moment(new Date(assets?.keys?.date).getTime()).format(
                    "DD MMM YYYY"
                  )
                : "-"}
            </span>
          </div>
          <div className={Styles.Content}>
            <span>Laptop</span>
            <span>
              {assets?.laptop
                ? moment(new Date(assets?.calculator?.date).getTime()).format(
                    "DD MMM YYYY"
                  )
                : "-"}
            </span>
          </div>
          <div className={Styles.Content}>
            <span>User ID/password</span>
            <span>
              {assets?.userId
                ? moment(new Date(assets?.userId?.date).getTime()).format(
                    "DD MMM YYYY"
                  )
                : "-"}
            </span>
          </div>
          <div className={Styles.Content}>
            <span>Serah terima jabatan</span>
            <span>
              {assets?.handoverOfOffice
                ? moment(new Date(assets?.userId?.date).getTime()).format(
                    "DD MMM YYYY"
                  )
                : "-"}
            </span>
          </div>
          <div className={Styles.Content}>
            <span>Other</span>
            <span>{assets?.others ? assets?.others : "-"}</span>
          </div>
        </div>
      </div>

      <div className={Styles.ContainerPDF}>
        <div className={Styles.HeadingPDF}>
          <span>Exit Clearance File</span>
        </div>

        {data?.exitClearance?.file && (
          <div className={Styles.BoxPDF} onClick={onDownload}>
            <div className={Styles.PDFItems}>
              <div className={Styles.CircleImage}>
                <img src={PDF_ICON} alt="pdf-icon" />
              </div>
              <span>{`${data?.exitClearance?.file?.fileName}.pdf`}</span>
            </div>
            <img
              src={DOWNLOAD_ICON}
              alt="download-icon"
              className={Styles.DownloadIcon}
            />
          </div>
        )}
      </div>
    </div>
  );
};
