import {
  Avatar,
  Button,
  Card,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./DetailsStore.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router";
import Breadcrumb from "../../../utils/Breadcrumbs";
import CompanyBG from "../../../../assets/images/Company-Background-Grey.png";
import AreaIcon from "../../../../assets/images/area-icon.png";
import StoreIcon from "../../../../assets/images/store-icon.png";
import GroupIcon from "../../../../assets/images/building-icon.png";
import LocationIcon from "../../../../assets/images/location-icon.png";
import StoreImageTable from "../../../../assets/images/Store-Image-Blue.png";
import PhoneIcon from "../../../../assets/images/phone-icon.png";
import CodeIcon from "../../../../assets/images/code-icon.png";
import EditIcon from "../../../../assets/images/new-edit-icon.png";
import TrashIcon from "../../../../assets/images/trash-2.png";
import ModalDeleteFMCG from "../../ModalDeleteFMCG/ModalDeleteFMCG";
import SearchbarFMCG from "../../SearchbarFMCG/SearchbarFMCG";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
// import { Link } from "react-router-dom";
// import dummy_image from "../../../../assets/images/anas.png";
import { alpha } from "@mui/material/styles";

const data = [
  {
    Name: "Anastasia",
    Position: "Sales Promotion Girl",
    Level: "Staff",
    Leader: "Wade Warren",
    Area: "Jakarta",
    Store: "",
    _id: "asdkakslahdlkj123123",
    Status: true,
  },
  {
    Name: "Aisyah Dara",
    Position: "Sales Promotion Girl",
    Level: "Staff",
    Leader: "Wade Warren",
    Area: "Jakarta",
    Store: "Grand Lucky SCBD",
    _id: "lkshdo1hdon1owh",
    Status: true,
  },
  {
    Name: "Alkatri",
    Position: "MD Leader",
    Level: "MD",
    Leader: "Wade Warren",
    Area: "Jakarta",
    Store: "JFM Green Ville",
    _id: "lksh12k31i231238012",
    Status: false,
  },
  {
    Name: "Anton Sujiwo ",
    Position: "MD",
    Level: "MD",
    Leader: "Wade Warren",
    Area: "Jakarta",
    Store: "Grand Lucky SCBD",
    _id: "okjdalksjk123jp12j3",
    Status: true,
  },
  {
    Name: "Sheila",
    Position: "SPG Leader",
    Level: "Leader",
    Leader: "",
    Area: "Semarang",
    Store: "Grand Lucky SCBD",
    _id: "1l2n3j12h301238098",
    Status: false,
  },
];

const dataProducts = [
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "1",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "2",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "3",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "4",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "5",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "6",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "7",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "8",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "9",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "10",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "11",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "12",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "13",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "14",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "15",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "16",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "17",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "18",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "19",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "20",
  },
];

// Table Employee //
const TABLE_HEAD = [
  { id: "Name", label: "Name", alignRight: false },
  { id: "Position", label: "Position", alignRight: false },
  { id: "Level", label: "Level", alignRight: false },
  { id: "Leader", label: "Leader", alignRight: false },
  { id: "Status", label: "Status", alignRight: false },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={
        {
          // "& th": {
          //   backgroundColor: "#F0F0F0"
          // }
        }
      }
    >
      <TableRow>
        <TableCell padding="none">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={rowCount !== values?.users?.length}
            sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
          /> */}
        </TableCell>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
// Table Employee //

// Table Product //
const TABLE_HEAD_PRODUCT = [
  { id: "ProductName", label: "Product Name", alignRight: false },
  { id: "Size", label: "Size", alignRight: false },
  { id: "Brand", label: "Brand", alignRight: false },
  { id: "Price", label: "Price", alignRight: false },
  { id: "Status", label: "Status", alignRight: false },
];
function descendingComparatorProduct(a, b, orderByProduct) {
  if (b[orderByProduct] < a[orderByProduct]) {
    return -1;
  }
  if (b[orderByProduct] > a[orderByProduct]) {
    return 1;
  }
  return 0;
}
function getComparatorProduct(orderProduct, orderByProduct) {
  return orderProduct === "desc"
    ? (a, b) => descendingComparatorProduct(a, b, orderByProduct)
    : (a, b) => -descendingComparatorProduct(a, b, orderByProduct);
}
function stableSortProduct(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHeadProduct(props) {
  const {
    // onSelectAllClick,
    orderProduct,
    orderByProduct,
    // numSelectedProduct,
    // rowCountProduct,
    onRequestSortProduct,
    // values,
  } = props;

  const createSortHandlerProduct = (property) => (event) => {
    onRequestSortProduct(event, property);
  };

  return (
    <TableHead
      sx={{
        "& th": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        <TableCell padding="none">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={rowCount !== values?.users?.length}
            sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
          /> */}
        </TableCell>
        {TABLE_HEAD_PRODUCT.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={
              orderByProduct === headCell.id ? orderProduct : false
            }
          >
            <TableSortLabel
              active={orderByProduct === headCell.id}
              direction={orderByProduct === headCell.id ? orderProduct : "asc"}
              onClick={createSortHandlerProduct(headCell.id)}
            >
              {headCell.label}
              {orderByProduct === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {orderProduct === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
// Table Product //

//
const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    setListEmployeesFMCG,
    listEmployeesFMCG,
    // setSearchTerm,
  } = props;

  const handleSearch = (event) => {
    console.log(event.target.value);
    setListEmployeesFMCG(event.target.value);
  };
  // const handleSubmitFilter = (e) => {
  //   e.preventDefault();
  //   setSearchTerm(listEmployeesFMCG);
  // };
  return (
    <Toolbar
      sx={{
        justifyContent: "space-between",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <SearchbarFMCG
            value={listEmployeesFMCG}
            onChange={handleSearch}
            // onSubmit={handleSubmitFilter}
            placeholder="Search employee"
            searchWidth="379px"
          />
        </Typography>
      </div>
      {/* {numSelected > 0 ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <Searchbar
              value={listDataEmployee}
              onChange={handleSearch}
              onSubmit={handleSubmitFilter}
            />
          </Typography>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <SearchbarFMCG
              value={listDataEmployee}
              onChange={handleSearch}
              onSubmit={handleSubmitFilter}
            />
          </Typography>
        </div>
      )} */}

      {/* {numSelected > 0 ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Add Selected">
            <Button
              style={{
                height: 36,
                backgroundColor: "#1571DE",
                color: "#FFFFFF",
                textTransform: "none",
              }}
            >
              {numSelected} employee selected
            </Button>
          </Tooltip>
        </div>
      ) : null} */}
    </Toolbar>
  );
};

const DetailsStore = ({ location }) => {
  const history = useHistory();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  // const [selected, setSelected] = useState("");
  const [orderBy, setOrderBy] = useState("Name");
  // const [selectedPosition, setSelectedPosition] = useState("");
  const [listEmployeesFMCG, setListEmployeesFMCG] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //
  const [listProductFMCG, setListProductFMCG] = useState("");
  const [pageProduct, setPageProduct] = useState(0);
  const [orderProduct, setOrderProduct] = useState("asc");
  // eslint-disable-next-line no-unused-vars
  const [selectedProduct, setSelectedProduct] = useState("");
  const [orderByProduct, setOrderByProduct] = useState("ProductName");
  const [rowsPerPageProduct, setRowsPerPageProduct] = useState(5);
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");

  // Table Employee //
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line no-unused-vars
  const handleSearch = (event) => {
    setListEmployeesFMCG(event.target.value);
  };
  // Table Employee //

  // Table Product //
  const handleRequestSortProduct = (event, property) => {
    const isAsc = orderByProduct === property && orderProduct === "asc";
    setOrderProduct(isAsc ? "desc" : "asc");
    setOrderByProduct(property);
  };

  const handleChangePageProduct = (event, newPage) => {
    setPageProduct(newPage);
  };

  const handleChangeRowsPerPageProduct = (event) => {
    setRowsPerPageProduct(parseInt(event.target.value, 5));
    setPageProduct(0);
  };

  const handleSearchProduct = (event) => {
    setListProductFMCG(event.target.value);
  };
  // Table Product //

  return (
    <div className="ContainerDetailsStore">
      <div className="WrapperDetailsStore">
        <div
          className="ButtonRouteBack"
          onClick={() => history.push("/admin-fmcg/setup/store")}
        >
          <IconButton>
            <ArrowBackIcon sx={{ color: "#202020", width: 24, height: 24 }} />
          </IconButton>
        </div>
        <div className="WrapperBreadCrumbs">
          {location === "detailsSpgStore" ? (
            <Breadcrumb>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextGrey">Store</span>
              </Button>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextBlue">Mayestik</span>
              </Button>
            </Breadcrumb>
          ) : (
            <Breadcrumb>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextGrey">Store</span>
              </Button>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextBlue">
                  Alfamidi Super Cililitan Besar
                </span>
              </Button>
            </Breadcrumb>
          )}
        </div>
        <div className="ContentDetailsStore">
          <img src={CompanyBG} alt="" />
          {location === "detailsSpgStore" ? (
            <div className="WrapperContentFloatDetailsStore">
              <div className="ContentStatusDetailsStore">
                <div className="WrapperImageCircle">
                  <img src={StoreImageTable} alt="" />
                </div>
                <div className="WrapperTextDetailsStore">
                  <span>Permata Mayestik</span>
                </div>
                <div className="BoxStatusDetailsStore">
                  <span>Active</span>
                </div>
              </div>
              <div className="ContentInfoStatusDetailsStore">
                <div className="WrapperItemsDetailsStore">
                  <div className="ItemsDetailsStore">
                    <div className="ItemsDetailsStoreLeft">
                      <img src={StoreIcon} alt="" />
                      <span>Area</span>
                    </div>
                    <div className="ItemsDetailsStoreRight">
                      <span>Permata Mayestik</span>
                    </div>
                  </div>
                  <div className="ItemsDetailsStore">
                    <div className="ItemsDetailsStoreLeft">
                      <img src={AreaIcon} alt="" />
                      <span>Alamat</span>
                    </div>
                    <div className="ItemsDetailsStoreRight">
                      <span>
                        Jl. Kyai Maja No.7, RT.4/RW.1, Kramat Pela, Kec. Kby.
                        Baru...
                      </span>
                    </div>
                  </div>
                  <div className="ItemsDetailsStore">
                    <div className="ItemsDetailsStoreLeft">
                      <img src={GroupIcon} alt="" />
                      <span>Group</span>
                    </div>
                    <div className="ItemsDetailsStoreRight">
                      <span>Permata</span>
                    </div>
                  </div>
                  <div className="ItemsDetailsStore">
                    <div className="ItemsDetailsStoreLeft">
                      <img src={LocationIcon} alt="" />
                      <span>Longitude</span>
                    </div>
                    <div className="ItemsDetailsStoreRight">
                      <span>-81.94827</span>
                    </div>
                  </div>{" "}
                  <div className="ItemsDetailsStore">
                    <div className="ItemsDetailsStoreLeft">
                      <img src={LocationIcon} alt="" />
                      <span>Longitude</span>
                    </div>
                    <div className="ItemsDetailsStoreRight">
                      <span>120.94839</span>
                    </div>
                  </div>
                </div>
                <div className="ButtonActionDetailsStore">
                  <div className="WrapperIconAction">
                    <img
                      src={EditIcon}
                      alt="EditIcon"
                      onClick={() =>
                        history.push({
                          pathname: `/admin-fmcg/setup/store/edit-spg/asdkakslahdlkj123123`,
                          state: { location: "editStoreSpg" },
                        })
                      }
                    />
                  </div>
                  <div className="WrapperIconAction">
                    <img
                      src={TrashIcon}
                      alt="TrashIcon"
                      onClick={() => setOpenModalDelete(true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="WrapperContentFloatDetailsStore">
              <div className="ContentStatusDetailsStore">
                <div className="WrapperImageCircle">
                  <img src={StoreImageTable} alt="" />
                </div>
                <div className="WrapperTextDetailsStore">
                  <span>Alfamidi Super Cililitan Besar</span>
                </div>
                <div className="BoxStatusDetailsStore">
                  <span>Active</span>
                </div>
              </div>
              <div className="ContentInfoStatusDetailsStore">
                <div className="WrapperItemsDetailsStore">
                  <div className="ItemsDetailsStore">
                    <div className="ItemsDetailsStoreLeft">
                      <img src={StoreIcon} alt="" />
                      <span>Store</span>
                    </div>
                    <div className="ItemsDetailsStoreRight">
                      <span>Alfamidi Super Cililitan Besar</span>
                    </div>
                  </div>
                  <div className="ItemsDetailsStore">
                    <div className="ItemsDetailsStoreLeft">
                      <img src={AreaIcon} alt="" />
                      <span>Alamat</span>
                    </div>
                    <div className="ItemsDetailsStoreRight">
                      <span>
                        Jl. Kyai Maja No.7, RT.4/RW.1, Kramat Pela, Kec. Kby.
                        Baru...
                      </span>
                    </div>
                  </div>
                  <div className="ItemsDetailsStore">
                    <div className="ItemsDetailsStoreLeft">
                      <img src={LocationIcon} alt="" />
                      <span>Area</span>
                    </div>
                    <div className="ItemsDetailsStoreRight">
                      <span>Jakarta</span>
                    </div>
                  </div>
                  <div className="ItemsDetailsStore">
                    <div className="ItemsDetailsStoreLeft">
                      <img src={CodeIcon} alt="" />
                      <span>Code</span>
                    </div>
                    <div className="ItemsDetailsStoreRight">
                      <span>001</span>
                    </div>
                  </div>{" "}
                  <div className="ItemsDetailsStore">
                    <div className="ItemsDetailsStoreLeft">
                      <img src={PhoneIcon} alt="" />
                      <span>Phone</span>
                    </div>
                    <div className="ItemsDetailsStoreRight">
                      <span>(684) 555-0102</span>
                    </div>
                  </div>
                </div>
                {location === "detailsSpgStore" ? (
                  <div className="ButtonActionDetailsStore">
                    <div className="WrapperIconAction">
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        onClick={() =>
                          history.push({
                            pathname: `/admin-fmcg/edit-store-spg/asdkakslahdlkj123123`,
                            state: { location: "editStoreSpg" },
                          })
                        }
                      />
                    </div>
                    <div className="WrapperIconAction">
                      <img
                        src={TrashIcon}
                        alt="TrashIcon"
                        onClick={() => setOpenModalDelete(true)}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="ButtonActionDetailsStore">
                    <div className="WrapperIconAction">
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        onClick={() =>
                          history.push({
                            pathname: `/admin-fmcg/setup/store/edit-md/asdkakslahdlkj123123`,
                            state: { location: "editStoreMD" },
                          })
                        }
                      />
                    </div>
                    <div className="WrapperIconAction">
                      <img
                        src={TrashIcon}
                        alt="TrashIcon"
                        onClick={() => setOpenModalDelete(true)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {
          location === "detailsSpgStore" ? (
            <>
              <div className="ContainerTableDetailsStore">
                <div className="SearchbarDetailsStore">
                  <SearchbarFMCG
                    value={listProductFMCG}
                    onChange={handleSearchProduct}
                    searchWidth="379px"
                    placeholder="Search Product"
                  />
                  <Button
                    style={{
                      textTransform: "none",
                      width: 107,
                      height: 40,
                      borderRadius: 5,
                      color: "#FFFFFF",
                      backgroundColor: "#1571DE",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                    onClick={() =>
                      history.push({
                        pathname: `/admin-fmcg/setup/list-set-product-fmcg`,
                        state: { location: "setProductSPG" },
                      })
                    }
                  >
                    Set Product
                  </Button>
                </div>
                <div className="TableDetailsStore">
                  <Card>
                    <Scrollbar>
                      <TableContainer>
                        <Table
                          sx={{
                            width: "100%",
                            maxHeight: "100%",
                          }}
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: 5,
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                          }}
                        >
                          <EnhancedTableHeadProduct
                            numSelectedProduct={selectedProduct.length}
                            orderProduct={orderProduct}
                            orderByProduct={orderByProduct}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSortProduct={handleRequestSortProduct}
                            // values={data}
                            rowCountProduct={
                              dataProducts?.filter(
                                (el) =>
                                  el.ProductName?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Label?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Size?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Price?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Status?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Brand?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  )
                              ).length
                            }
                          />
                          <TableBody>
                            {stableSortProduct(
                              dataProducts,
                              getComparatorProduct(orderProduct, orderByProduct)
                            )
                              .filter(
                                (el) =>
                                  el.ProductName?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Label?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Size?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Price?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Status?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Brand?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  )
                                //   &&
                                // (selectedPosition
                                //   ? el.Position === selectedPosition
                                //   : true)
                              )
                              .slice(
                                pageProduct * rowsPerPageProduct,
                                pageProduct * rowsPerPageProduct +
                                  rowsPerPageProduct
                              )
                              .map((product, ind) => {
                                // const isItemSelected = isSelected(employee?._id);
                                const labelId = `enhanced-table-checkbox-${ind}`;
                                return (
                                  <TableRow
                                    hover
                                    key={ind}
                                    tabIndex={-1}
                                    id={labelId}
                                    sx={{
                                      "&.MuiTableRow-root:hover": {
                                        backgroundColor: "#eef6ff",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      padding="none"
                                      style={{
                                        paddingLeft: 10,
                                        marginRight: 10,
                                      }}
                                    ></TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      padding="none"
                                    >
                                      {/* <Link
                                  to={{
                                    pathname: `/admin-fmcg/details-spg-store/lkshdo1hdon1owh`,
                                    state: { location: "detailsSpgStore" },
                                  }}
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Avatar
                                        src=""
                                        style={{ width: 32, height: 32 }}
                                      />
                                      <Typography
                                        variant="subtitle2"
                                        noWrap
                                        sx={{ textTransform: "capitalize" }}
                                      >
                                        {product.productName
                                          ? product.productName.toLowerCase()
                                          : "-"}
                                      </Typography>
                                    </Stack>
                                  </Link> */}
                                      <Stack
                                        direction="column"
                                        alignItems="left"
                                        spacing={0}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          noWrap
                                          sx={{
                                            textTransform: "capitalize",
                                            fontSize: 13,
                                            fontWeight: 500,
                                            color: "#333333",
                                          }}
                                          component="span"
                                        >
                                          {product.ProductName
                                            ? product.ProductName.toLowerCase()
                                            : "-"}
                                        </Typography>
                                        <Typography
                                          variant="subtitle2"
                                          noWrap
                                          sx={{
                                            textTransform: "capitalize",
                                            fontSize: 11,
                                            fontWeight: 400,
                                            color: "#979797",
                                          }}
                                          component="span"
                                        >
                                          {product.Size
                                            ? product.Size.toLowerCase()
                                            : "-"}
                                        </Typography>
                                      </Stack>
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      {product.Size ? product.Size : "-"}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      {product.Brand ? product.Brand : "-"}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      {product.Price ? product.Price : "-"}
                                    </TableCell>
                                    {/* <TableCell align="left">
                                  {product.status === "active" ? (
                                    <Typography
                                      style={{
                                        backgroundColor: "#B22A12",
                                        color: "#FBE7B3",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: 8,
                                        height: 26,
                                        width: 74,
                                      }}
                                      component="div"
                                    >
                                      <Typography
                                        component="span"
                                        style={{
                                          fontSize: 13,
                                          fontWeight: 500,
                                        }}
                                      >
                                        Not Assign
                                      </Typography>
                                    </Typography>
                                  ) : (
                                    <Avatar src={product.Leader} />
                                  )}
                                </TableCell> */}
                                    <TableCell align="left">
                                      {product.status === "active" ? (
                                        <Typography
                                          style={{
                                            backgroundColor: "#E4FFE6",
                                            color: "#17B51E",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: 8,
                                            height: 26,
                                            width: 58,
                                          }}
                                          component="div"
                                        >
                                          <Typography
                                            component="span"
                                            style={{
                                              fontSize: 14,
                                              fontWeight: 500,
                                              color: "#17B51E",
                                            }}
                                          >
                                            Active
                                          </Typography>
                                        </Typography>
                                      ) : (
                                        <Typography
                                          style={{
                                            backgroundColor: "#FFDFDD",
                                            // color: "#17B51E",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: 8,
                                            height: 26,
                                            width: 74,
                                          }}
                                          component="div"
                                        >
                                          <Typography
                                            component="span"
                                            style={{
                                              fontSize: 14,
                                              fontWeight: 500,
                                              color: "#B52117",
                                            }}
                                          >
                                            Unactive
                                          </Typography>
                                        </Typography>
                                      )}
                                      {/* <Button
                                  onClick={() => {
                                    history.push({
                                      pathname: `/admin-fmcg/edit-employee-fmcg/lkshdo1hdon1owh`,
                                      state: { location: "editEmployee" },
                                    });
                                  }}
                                >
                                  <EditIcon />
                                </Button> */}
                                    </TableCell>
                                    {/* <TableCell align="left" variant="square">
                                <Typography
                                  style={{
                                    width: 30,
                                    height: 30,
                                    backgroundColor: "#ECF5FF",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 5
                                  }}
                                  component="div"
                                >
                                  <img
                                    src={EditIcon2}
                                    alt=""
                                    style={{ width: 20, height: 20 }}
                                  />
                                </Typography>
                              </TableCell> */}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Scrollbar>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      component="div"
                      count={
                        dataProducts?.filter(
                          (el) =>
                            el.ProductName?.toLowerCase().includes(
                              listProductFMCG.toLowerCase()
                            ) ||
                            el.Label?.toLowerCase().includes(
                              listProductFMCG.toLowerCase()
                            ) ||
                            el.Size?.toLowerCase().includes(
                              listProductFMCG.toLowerCase()
                            ) ||
                            el.Price?.toLowerCase().includes(
                              listProductFMCG.toLowerCase()
                            ) ||
                            el.Status?.toLowerCase().includes(
                              listProductFMCG.toLowerCase()
                            ) ||
                            el.Brand?.toLowerCase().includes(
                              listProductFMCG.toLowerCase()
                            )

                          //   &&
                          // (selectedPosition
                          //   ? el.Position === selectedPosition
                          //   : true)
                        ).length
                      }
                      rowsPerPage={rowsPerPageProduct}
                      page={pageProduct}
                      onPageChange={handleChangePageProduct}
                      onRowsPerPageChange={handleChangeRowsPerPageProduct}
                    />
                  </Card>
                </div>
              </div>

              <div className="ContainerTableListEmployeeDetailsStore">
                <Box sx={{ width: "100%" }}>
                  <Paper sx={{ width: "100%" }}>
                    <EnhancedTableToolbar
                      // numSelected={checkedEmployees}
                      listEmployeesFMCG={listEmployeesFMCG}
                      setListEmployeesFMCG={setListEmployeesFMCG}
                      setSearchTerm={setSearchTerm}
                    />
                    <TableContainer>
                      <Table
                        sx={{ width: "100%" }}
                        // sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        // size={dense ? "small" : "medium"}
                      >
                        <EnhancedTableHead
                          // numSelected={checkedEmployees}
                          order={order}
                          orderBy={orderBy}
                          // onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          // rowCount={values.users?.length}
                          values={data}
                          rowCount={
                            data.filter(
                              (el) =>
                                el.Name?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                ) ||
                                el.Position?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                ) ||
                                el.Level?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                ) ||
                                el.Leader?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                )
                            ).length
                          }
                        />
                        <TableBody>
                          {stableSort(data, getComparator(order, orderBy))
                            .filter(
                              (el) =>
                                el.Name?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                ) ||
                                el.Position?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                ) ||
                                el.Level?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                ) ||
                                el.Leader?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                )
                            )
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              // const isItemSelected = row?.Locations.some(
                              //   (el) => el._id === id
                              // );
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row?.Name}
                                >
                                  <TableCell padding="none"></TableCell>
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    // align="right"
                                    // padding="none"
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Avatar alt={row.Name} src="" />
                                      <Typography
                                        component="span"
                                        style={{
                                          color: "#000000",
                                          fontSize: 14,
                                          fontWeight: 400,
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {row.Name}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.Position}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row?.Level}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.Leader === "" ? (
                                      <Typography
                                        style={{
                                          backgroundColor: "#B22A12",
                                          color: "#FBE7B3",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: 8,
                                          height: 26,
                                          width: 74,
                                        }}
                                        component="div"
                                      >
                                        <Typography
                                          component="span"
                                          style={{
                                            fontSize: 13,
                                            fontWeight: 500,
                                          }}
                                        >
                                          Not Assign
                                        </Typography>
                                      </Typography>
                                    ) : (
                                      // <Avatar src={row.Leader} />
                                      <Typography
                                        component="span"
                                        style={{
                                          color: "#000000",
                                          fontSize: 14,
                                          fontWeight: 400,
                                        }}
                                      >
                                        {row.Leader}
                                      </Typography>
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.Status === true ? (
                                      <Typography
                                        style={{
                                          backgroundColor: "#E4FFE6",
                                          color: "#17B51E",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: 8,
                                          height: 26,
                                          width: 58,
                                        }}
                                        component="div"
                                      >
                                        <Typography
                                          component="span"
                                          style={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            color: "#17B51E",
                                          }}
                                        >
                                          Active
                                        </Typography>
                                      </Typography>
                                    ) : (
                                      <Typography
                                        style={{
                                          backgroundColor: "#FFDFDD",
                                          // color: "#17B51E",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: 8,
                                          height: 26,
                                          width: 74,
                                        }}
                                        component="div"
                                      >
                                        <Typography
                                          component="span"
                                          style={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            color: "#B52117",
                                          }}
                                        >
                                          Unactive
                                        </Typography>
                                      </Typography>
                                    )}
                                    {/* <Button
                                onClick={() => {
                                  history.push({
                                    pathname: `/admin-fmcg/edit-employee-fmcg/lkshdo1hdon1owh`,
                                    state: { location: "editEmployee" },
                                  });
                                }}
                              >
                                <EditIcon />
                              </Button> */}
                                  </TableCell>
                                  {/* <TableCell align="right">
                                <Button
                                  style={{
                                    width: 53,
                                    height: 36,
                                    backgroundColor: "#1571DE",
                                    color: "#FFFFFF",
                                    textTransform: "none",
                                  }}
                                >
                                  Add
                                </Button>
                              </TableCell> */}
                                </TableRow>
                              );
                            })}
                          {/* {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      component="div"
                      count={
                        data?.filter(
                          (el) =>
                            el.Name?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                            el.Position?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                            el.Level?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                            el.Leader?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            )
                        )?.length
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </Box>
              </div>
            </>
          ) : (
            <>
              <div className="ContainerTableDetailsStore">
                <div className="SearchbarDetailsStore">
                  <SearchbarFMCG
                    value={listProductFMCG}
                    onChange={handleSearchProduct}
                    searchWidth="379px"
                    placeholder="Search Product"
                  />
                  <Button
                    style={{
                      textTransform: "none",
                      width: 107,
                      height: 40,
                      borderRadius: 5,
                      color: "#FFFFFF",
                      backgroundColor: "#1571DE",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                    onClick={() =>
                      history.push({
                        pathname: `/admin-fmcg/setup/list-set-product-fmcg`,
                        state: { location: "setProductMD" },
                      })
                    }
                  >
                    Set Product
                  </Button>
                </div>
                <div className="TableDetailsStore">
                  <Card>
                    <Scrollbar>
                      <TableContainer>
                        <Table
                          sx={{
                            width: "100%",
                            maxHeight: "100%",
                          }}
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: 5,
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                          }}
                        >
                          <EnhancedTableHeadProduct
                            numSelectedProduct={selectedProduct.length}
                            orderProduct={orderProduct}
                            orderByProduct={orderByProduct}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSortProduct={handleRequestSortProduct}
                            // values={data}
                            rowCountProduct={
                              dataProducts?.filter(
                                (el) =>
                                  el.ProductName?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Label?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Size?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Price?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Status?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Brand?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  )
                              ).length
                            }
                          />
                          <TableBody>
                            {stableSortProduct(
                              dataProducts,
                              getComparatorProduct(orderProduct, orderByProduct)
                            )
                              .filter(
                                (el) =>
                                  el.ProductName?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Label?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Size?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Price?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Status?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  ) ||
                                  el.Brand?.toLowerCase().includes(
                                    listProductFMCG.toLowerCase()
                                  )
                                //   &&
                                // (selectedPosition
                                //   ? el.Position === selectedPosition
                                //   : true)
                              )
                              .slice(
                                pageProduct * rowsPerPageProduct,
                                pageProduct * rowsPerPageProduct +
                                  rowsPerPageProduct
                              )
                              .map((product, ind) => {
                                // const isItemSelected = isSelected(employee?._id);
                                const labelId = `enhanced-table-checkbox-${ind}`;
                                return (
                                  <TableRow
                                    hover
                                    key={ind}
                                    tabIndex={-1}
                                    id={labelId}
                                    sx={{
                                      "&.MuiTableRow-root:hover": {
                                        backgroundColor: "#eef6ff",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      padding="none"
                                      style={{
                                        paddingLeft: 10,
                                        marginRight: 10,
                                      }}
                                    ></TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      padding="none"
                                    >
                                      {/* <Link
                              to={{
                                pathname: `/admin-fmcg/details-spg-store/lkshdo1hdon1owh`,
                                state: { location: "detailsSpgStore" },
                              }}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Avatar
                                    src=""
                                    style={{ width: 32, height: 32 }}
                                  />
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{ textTransform: "capitalize" }}
                                  >
                                    {product.productName
                                      ? product.productName.toLowerCase()
                                      : "-"}
                                  </Typography>
                                </Stack>
                              </Link> */}
                                      <Stack
                                        direction="column"
                                        alignItems="left"
                                        spacing={0}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          noWrap
                                          sx={{
                                            textTransform: "capitalize",
                                            fontSize: 13,
                                            fontWeight: 500,
                                            color: "#333333",
                                          }}
                                          component="span"
                                        >
                                          {product.ProductName
                                            ? product.ProductName.toLowerCase()
                                            : "-"}
                                        </Typography>
                                        <Typography
                                          variant="subtitle2"
                                          noWrap
                                          sx={{
                                            textTransform: "capitalize",
                                            fontSize: 11,
                                            fontWeight: 400,
                                            color: "#979797",
                                          }}
                                          component="span"
                                        >
                                          {product.Size
                                            ? product.Size.toLowerCase()
                                            : "-"}
                                        </Typography>
                                      </Stack>
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      {product.Size ? product.Size : "-"}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      {product.Brand ? product.Brand : "-"}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      {product.Price ? product.Price : "-"}
                                    </TableCell>
                                    {/* <TableCell align="left">
                              {product.status === "active" ? (
                                <Typography
                                  style={{
                                    backgroundColor: "#B22A12",
                                    color: "#FBE7B3",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: 8,
                                    height: 26,
                                    width: 74,
                                  }}
                                  component="div"
                                >
                                  <Typography
                                    component="span"
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 500,
                                    }}
                                  >
                                    Not Assign
                                  </Typography>
                                </Typography>
                              ) : (
                                <Avatar src={product.Leader} />
                              )}
                            </TableCell> */}
                                    <TableCell align="left">
                                      {product.status === "active" ? (
                                        <Typography
                                          style={{
                                            backgroundColor: "#E4FFE6",
                                            color: "#17B51E",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: 8,
                                            height: 26,
                                            width: 58,
                                          }}
                                          component="div"
                                        >
                                          <Typography
                                            component="span"
                                            style={{
                                              fontSize: 14,
                                              fontWeight: 500,
                                              color: "#17B51E",
                                            }}
                                          >
                                            Active
                                          </Typography>
                                        </Typography>
                                      ) : (
                                        <Typography
                                          style={{
                                            backgroundColor: "#FFDFDD",
                                            // color: "#17B51E",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: 8,
                                            height: 26,
                                            width: 74,
                                          }}
                                          component="div"
                                        >
                                          <Typography
                                            component="span"
                                            style={{
                                              fontSize: 14,
                                              fontWeight: 500,
                                              color: "#B52117",
                                            }}
                                          >
                                            Unactive
                                          </Typography>
                                        </Typography>
                                      )}
                                      {/* <Button
                              onClick={() => {
                                history.push({
                                  pathname: `/admin-fmcg/edit-employee-fmcg/lkshdo1hdon1owh`,
                                  state: { location: "editEmployee" },
                                });
                              }}
                            >
                              <EditIcon />
                            </Button> */}
                                    </TableCell>
                                    {/* <TableCell align="left" variant="square">
                            <Typography
                              style={{
                                width: 30,
                                height: 30,
                                backgroundColor: "#ECF5FF",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 5
                              }}
                              component="div"
                            >
                              <img
                                src={EditIcon2}
                                alt=""
                                style={{ width: 20, height: 20 }}
                              />
                            </Typography>
                          </TableCell> */}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Scrollbar>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      component="div"
                      count={
                        dataProducts?.filter(
                          (el) =>
                            el.ProductName?.toLowerCase().includes(
                              listProductFMCG.toLowerCase()
                            ) ||
                            el.Label?.toLowerCase().includes(
                              listProductFMCG.toLowerCase()
                            ) ||
                            el.Size?.toLowerCase().includes(
                              listProductFMCG.toLowerCase()
                            ) ||
                            el.Price?.toLowerCase().includes(
                              listProductFMCG.toLowerCase()
                            ) ||
                            el.Status?.toLowerCase().includes(
                              listProductFMCG.toLowerCase()
                            ) ||
                            el.Brand?.toLowerCase().includes(
                              listProductFMCG.toLowerCase()
                            )

                          //   &&
                          // (selectedPosition
                          //   ? el.Position === selectedPosition
                          //   : true)
                        ).length
                      }
                      rowsPerPage={rowsPerPageProduct}
                      page={pageProduct}
                      onPageChange={handleChangePageProduct}
                      onRowsPerPageChange={handleChangeRowsPerPageProduct}
                    />
                  </Card>
                </div>
              </div>

              <div className="ContainerTableListEmployeeDetailsStore">
                <Box sx={{ width: "100%" }}>
                  <Paper sx={{ width: "100%" }}>
                    <EnhancedTableToolbar
                      // numSelected={checkedEmployees}
                      listEmployeesFMCG={listEmployeesFMCG}
                      setListEmployeesFMCG={setListEmployeesFMCG}
                      setSearchTerm={setSearchTerm}
                    />
                    <TableContainer>
                      <Table
                        sx={{ width: "100%" }}
                        // sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        // size={dense ? "small" : "medium"}
                      >
                        <EnhancedTableHead
                          // numSelected={checkedEmployees}
                          order={order}
                          orderBy={orderBy}
                          // onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          // rowCount={values.users?.length}
                          values={data}
                          rowCount={
                            data.filter(
                              (el) =>
                                el.Name?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                ) ||
                                el.Position?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                ) ||
                                el.Level?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                ) ||
                                el.Leader?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                )
                            ).length
                          }
                        />
                        <TableBody>
                          {stableSort(data, getComparator(order, orderBy))
                            .filter(
                              (el) =>
                                el.Name?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                ) ||
                                el.Position?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                ) ||
                                el.Level?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                ) ||
                                el.Leader?.toLowerCase().includes(
                                  listEmployeesFMCG.toLowerCase()
                                )
                            )
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              // const isItemSelected = row?.Locations.some(
                              //   (el) => el._id === id
                              // );
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row?.Name}
                                >
                                  <TableCell padding="none"></TableCell>
                                  <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    // align="right"
                                    // padding="none"
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Avatar alt={row.Name} src="" />
                                      <Typography
                                        component="span"
                                        style={{
                                          color: "#000000",
                                          fontSize: 14,
                                          fontWeight: 400,
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {row.Name}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.Position}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row?.Level}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.Leader === "" ? (
                                      <Typography
                                        style={{
                                          backgroundColor: "#B22A12",
                                          color: "#FBE7B3",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: 8,
                                          height: 26,
                                          width: 74,
                                        }}
                                        component="div"
                                      >
                                        <Typography
                                          component="span"
                                          style={{
                                            fontSize: 13,
                                            fontWeight: 500,
                                          }}
                                        >
                                          Not Assign
                                        </Typography>
                                      </Typography>
                                    ) : (
                                      // <Avatar src={row.Leader} />
                                      <Typography
                                        component="span"
                                        style={{
                                          color: "#000000",
                                          fontSize: 14,
                                          fontWeight: 400,
                                        }}
                                      >
                                        {row.Leader}
                                      </Typography>
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.Status === true ? (
                                      <Typography
                                        style={{
                                          backgroundColor: "#E4FFE6",
                                          color: "#17B51E",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: 8,
                                          height: 26,
                                          width: 58,
                                        }}
                                        component="div"
                                      >
                                        <Typography
                                          component="span"
                                          style={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            color: "#17B51E",
                                          }}
                                        >
                                          Active
                                        </Typography>
                                      </Typography>
                                    ) : (
                                      <Typography
                                        style={{
                                          backgroundColor: "#FFDFDD",
                                          // color: "#17B51E",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: 8,
                                          height: 26,
                                          width: 74,
                                        }}
                                        component="div"
                                      >
                                        <Typography
                                          component="span"
                                          style={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            color: "#B52117",
                                          }}
                                        >
                                          Unactive
                                        </Typography>
                                      </Typography>
                                    )}
                                    {/* <Button
                            onClick={() => {
                              history.push({
                                pathname: `/admin-fmcg/edit-employee-fmcg/lkshdo1hdon1owh`,
                                state: { location: "editEmployee" },
                              });
                            }}
                          >
                            <EditIcon />
                          </Button> */}
                                  </TableCell>
                                  {/* <TableCell align="right">
                            <Button
                              style={{
                                width: 53,
                                height: 36,
                                backgroundColor: "#1571DE",
                                color: "#FFFFFF",
                                textTransform: "none",
                              }}
                            >
                              Add
                            </Button>
                          </TableCell> */}
                                </TableRow>
                              );
                            })}
                          {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      component="div"
                      count={
                        data?.filter(
                          (el) =>
                            el.Name?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                            el.Position?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                            el.Level?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                            el.Leader?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            )
                        )?.length
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </Box>
              </div>
            </>
          )
        }
      </div>

      {openModalDelete && (
        <ModalDeleteFMCG
          modalDelete={openModalDelete}
          setModalDelete={setOpenModalDelete}
        />
      )}
    </div>
  );
};

export default DetailsStore;
