/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import Styles from './UserTable.module.css';
import { filter } from "lodash";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SettingsIcon from "@mui/icons-material/Settings";

import DELETE_ICON from "../../../../../assets/images/delete-rules-icon.png";
import SETTING_ICON from "../../../../../assets/images/setting-dark.png";

import {
    Table,
    Stack,
    Avatar,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    FormControl,
    Select,
    MenuItem,
    styled,
    TextField,
    Autocomplete,
    TableHead,
    TableSortLabel,
    Box,
    Popover,
} from "@mui/material";
//

// import Scrollbar from "./Scrollbar";
// import ModalSettingUser from "../modalAddUserSA/modal-setting-user/ModalSettingUser";
// import ModalDeleteUser from "../modalAddUserSA/modal-delete-user/ModalDeleteUser";
import SearchbarSA from "../../../../utils/SearchbarSA/SearchbarSA";
import ModalAddUserSA from "../../modalAddUserSA/ModalAddUserSA/ModalAddUserSA";
import Scrollbar from "../Scrollbar";
import { visuallyHidden } from "@mui/utils";
import LoadingAnimation from "../../../../utils/LoadingAnimation/LoadingAnimation";
import CustomTablePagination from "../../../../_pagination/CustomTablePagination";
import DeleteUserModal from "./DeleteUserModal/DeleteUserModal";
import ModalAddUserSuccess from "./ModalAddUserSuccess/ModalAddUserSuccess";

const MuiTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: 8,
        },
    },
});

const TABLE_HEAD = [
    { id: "name", label: "Employee Name", alignRight: false },
    { id: "phoneNumber", label: "Phone Number", alignRight: false },
    { id: "client", label: "Client", alignRight: false },
    { id: "userId", label: "Admin Type", alignRight: false },
    { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(
            array,
            (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
        deleteMode
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                "& tr": {
                    backgroundColor: "#F0F0F0",
                },
            }}
        >
            <TableRow>

                {TABLE_HEAD.map((headCell, index) => (
                    <TableCell
                        key={headCell.id + index}
                        align="left"
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className={Styles.TableSortLabel}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {/* {deleteMode && (
                    <TableCell></TableCell>
                )} */}
            </TableRow>
        </TableHead>
    );
}

export default function UserTable({ users, loading }) {
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [selected, setSelected] = useState("");
    const [filterName, setFilterName] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState("");
    const [modalDeleteUser, setModalDeleteUser] = useState(false);
    const [modalSettingUser, setModalSettingUser] = useState(false);
    const [selectedAddUserModalComp, setSelectedAdduserModalComp] = useState("");
    const [statusAdmin, setStatusAdmin] = useState();
    const [isActive, setIsActive] = useState(false);
    const options = ["Admin", "Viewer"];
    const [openEditModal, setOpenEditModal] = useState(false);

    const [deleteMode, setDeleteMode] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [successAddModal, setSuccessAddModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const [openedPopover, setOpenedPopover] = useState(null);


    const [type, setType] = useState('All')

    const handlePopover = (e, id) => {
        setAnchorEl(e.currentTarget);
        setOpenedPopover(id)
    }

    const handleClose = () => {
        setAnchorEl(null);
        setOpenedPopover(null)
    }

    useEffect(() => {
        if (openModal || successAddModal || selectedUser) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [openModal, successAddModal, selectedUser])


    const persistListMode = useMemo(() => {
        let persistMode = window.sessionStorage.getItem("persistListMode");
        let mode;

        if (persistMode) {
            mode = persistMode;
        } else {
            mode = type
        }
        return mode
    }, [type])

    const handleListType = (e) => {
        setPage(1)
        if (e.target.value !== null) {
            window.sessionStorage.setItem("persistListMode", e.target.value)
            setType(e.target.value)
        } else {
            window.sessionStorage.setItem("persistListMode", "")
            setType("")
        }
    }

    useEffect(() => {
        setType(persistListMode)
    }, [persistListMode])


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    //   const handleSelectAllClick = (event) => {
    //     if (event.target.checked) {
    //       const newSelecteds = USERLIST.map((n) => n.name);
    //       setSelected(newSelecteds);
    //       return;
    //     }
    //     setSelected([]);
    //   };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //   const filteredUsers = applySortFilter(
    //     USERLIST,
    //     getComparator(order, orderBy),
    //     filterName
    //   );

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const capitalizedClientName = (clientName) => {
        return clientName?.toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
            .replace('Pt', 'PT')
            .replace('Cv', 'CV')
    }

    return (
        <div className={Styles.Container}>
            <div className={Styles.Header}>
                <div className={Styles.HeaderLeft}>
                    <div className={Styles.Searchbar}>
                        <SearchbarSA
                            value={filterName}
                            onChange={handleFilterByName}
                            searchWidth="379px"
                            placeholder="Search name"
                        />
                    </div>
                    <div className={Styles.SelectBox}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={persistListMode}
                            onChange={handleListType}
                            className={Styles.Select}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="MWS">MWS</MenuItem>
                            <MenuItem value="NON-MWS">Non-MWS</MenuItem>
                        </Select>
                    </div>
                </div>
                <div className={Styles.UserButtonWrapper}>
                    {/* <Link to="/superadmin/add-user" className="user-tables-menu-download"> */}
                    <Button
                        startIcon={<EditIcon style={{ color: "#1571DE" }} />}
                        className={Styles.EditButton}
                        onClick={() => setDeleteMode(!deleteMode)} variant="outlined">{!deleteMode ? 'Edit List' : 'Cancel'}</Button>
                    <Button
                        startIcon={<AddIcon style={{ color: "white", fontSize: '14px' }} />}
                        className={Styles.AddButton}
                        onClick={() => setOpenModal(true)}
                    >
                        <span>Add User</span>
                    </Button>
                    {/* </Link> */}
                </div>
            </div>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 50,
                    }}
                >
                    <div style={{ width: 200 }}>
                        <LoadingAnimation />
                    </div>
                </div>
            ) : (
                <div className={Styles.TableWrapper}>
                    <Scrollbar>
                        <TableContainer className={Styles.TableContainer}>
                            <Table
                                id="emp-table"
                                className={Styles.Table}
                            >
                                <EnhancedTableHead
                                    numSelected={users?.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    values={users}
                                    deleteMode={deleteMode}
                                />
                                <colgroup>
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '18%' }} />
                                    <col style={{ width: '42%' }} />
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '5%' }} />
                                </colgroup>
                                <TableBody>
                                    {users
                                        .filter((el) => el?.name?.toLowerCase().includes(filterName?.toLowerCase()) &&
                                            (persistListMode !== "All" ? el?.type?.toLowerCase() === persistListMode?.toLowerCase() : true)
                                        )
                                        .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                                        .map((each, i) => {

                                            const isItemSelected = selected.indexOf(i) !== -1;

                                            return (
                                                <TableRow hover key={'tablebody' + i} tabIndex={-1}>
                                                    <TableCell className={Styles.TableCell} component="th" scope="row">
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            spacing={1}
                                                        >
                                                            <Avatar className={Styles.Avatar} alt={each?.name} src={each?.profilePicture} />
                                                            <Typography variant="subtitle2" className={Styles.Name} noWrap>
                                                                {each?.name?.toLowerCase()}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell className={Styles.TableCell} align="left">{each?.phoneNumber}</TableCell>
                                                    <TableCell className={Styles.TableCell} align="left">

                                                        {each?.client?.slice(0, 3)?.map((c, i) => (
                                                            <React.Fragment key={i}>
                                                                {capitalizedClientName(c?.name)}
                                                                {(i !== (each?.client?.length - 1) && (i !== 2)) && <>, </>}
                                                            </React.Fragment>
                                                        ))}

                                                        {each?.client?.length > 3 && (
                                                            <span
                                                                onMouseEnter={(e) => handlePopover(e, each?._id)}
                                                                onMouseLeave={handleClose}
                                                                className={Styles.Plus}>
                                                                + {each?.client?.length - 3}
                                                            </span>
                                                        )}
                                                        <Popover
                                                            id={each?._id}
                                                            sx={{
                                                                pointerEvents: 'none'
                                                            }}
                                                            open={openedPopover === each?._id}
                                                            anchorEl={anchorEl}
                                                            onClose={handleClose}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                        >
                                                            <div className={Styles.PopoverDiv}>
                                                                {each?.client?.slice(3)?.map((c, i) => (
                                                                    <span key={i}>
                                                                        {(i === each?.client?.length - 4 && each?.client?.length > 4) && '& '}
                                                                        {c?.name?.toLowerCase()}
                                                                        {i !== each?.client?.length - 4 ? ', ' : '.'}
                                                                    </span>
                                                                ))}
                                                            </div>

                                                        </Popover>
                                                    </TableCell>
                                                    <TableCell align="left"><span className={each?.type === 'MWS' ? Styles.MWS : Styles.NonMWS}>{each?.type === "MWS" ? "MWS" : "Non-MWS"}</span></TableCell>


                                                    <TableCell align="right" className={Styles.DeleteCell}>
                                                        {!deleteMode ? (
                                                            <Link
                                                                to={{
                                                                    pathname: `/superadmin/user-detail/${each?._id}`,
                                                                    state: { data: each }
                                                                }}
                                                            >
                                                                <img className={Styles.SettingIcon} src={SETTING_ICON} alt="setting" />
                                                            </Link>
                                                        ) : (

                                                            <img onClick={() => setSelectedUser(each)} className={Styles.DeleteIcon} src={DELETE_ICON} alt="delete" />
                                                        )}

                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    {/* <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                    <CustomTablePagination
                        limit={rowsPerPage}
                        setLimit={setRowsPerPage}
                        page={page}
                        setPage={setPage}
                        totalPage={Math.ceil(users?.filter((el) => el?.name?.toLowerCase().includes(filterName?.toLowerCase())
                            &&
                            (persistListMode !== "All" ? el?.type?.toLowerCase() === persistListMode?.toLowerCase() : true)
                        )?.length / rowsPerPage)}
                    />

                </div>
            )}

            {
                openModal && (
                    <ModalAddUserSA openModal={openModal} setOpenModal={setOpenModal} setSuccessAddModal={setSuccessAddModal} description="Add User" />
                )
            }
        

            {selectedUser && (
                <DeleteUserModal
                    active={selectedUser}
                    onOpen={setSelectedUser}
                    description="Are you sure you want to delete this user?"
                    label="Delete User"
                    pathName="/superadmin/user"
                />
            )}
            {successAddModal && (
                <ModalAddUserSuccess active={successAddModal} onOpen={setSuccessAddModal} desc={"Waiting for user password verification."} />
            )}
        </div>
    );
}
