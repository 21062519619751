import React from "react";
import "./ClientSA.css";
import {  Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import { ClientMenu } from "../../../components/SuperAdmin/_client/";
import UnderMaintenance from "../../../components/_underMaintenancePage";

const ClientSA = () => {
  const isMaintenance = false;

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace| Client Settings</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          Client Settings
        </h2>
      </div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          {/* <ClientMenu /> */}
          { isMaintenance ? <UnderMaintenance /> : <ClientMenu />}
        </Grid>
        <Grid item xs={12} md={6} lg={8}></Grid>
      </Grid>
    </Container>
  );
};

export default ClientSA;
