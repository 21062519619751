import {
  Avatar,
  Button,
  FormControl,
  // MenuItem,
  // Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  Autocomplete,
  Card,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import "./index.css";
import React, { useEffect, useMemo, useState } from "react";
// import { useCalendar } from "../../../utils/hooks";
import { makeStyles } from "@mui/styles";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import fs from "fs";
import PublishIcon from "@mui/icons-material/Publish";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import EXCEL_IMAGE from "../../../../assets/images/excel-image.png";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import Scrollbar from "../../_user/TableUsers/Scrollbar";
// import UserListHead from "../../_user/TableUsers/UserListHead";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchAllPayslipByMonth } from "../../../../redux/actions/SuperAdmin/slipPaymentActions";
import ModalSuccessUpload from "../ModalSuccessUpload/ModalSuccessUpload";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import LoadingAnimation from "../../../../components/utils/LoadingAnimation/LoadingAnimation";
import {API} from "../../../../redux/api";
import ModalPassword from "../ModalPassword/ModalPassword";
import fetchClientCode from "../../../../async/client/fetchClientCode";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";


const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

// const TABLE_HEAD = [
//   { id: "Name", numeric: false, label: "Employee Name", alignRight: false },
//   { id: "Position", numeric: false, label: "Position", alignRight: false },
//   { id: "Date", numeric: false, label: "Date", alignRight: false },
//   { id: "" },
// ];

const TABLE_HEAD = [
  { id: "name", label: "Employee Name", alignRight: false },
  { id: "jobTitle", label: "Position", alignRight: false },
  { id: "client", label: "Company", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        <TableCell padding="none" style={{ paddingLeft: 0 }}>
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={rowCount !== values?.users?.length}
            sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
          /> */}
        </TableCell>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
  },
});

const downloadXLSFile = async () => {
  // https://dev.myworkspace.id/api/v3/payment-slip/template
  const token = localStorage.getItem("accessToken");
  const url = `${API}/payment-slip/template`;
  // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
  const headers = { "Content-Type": "blob", Authorization: token };
  const config = { method: "GET", url, responseType: "arraybuffer", headers };

  try {
    const response = await axios(config);
    const outputFilename = `Payslip Template.xls`;

    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", outputFilename);
    document.body.appendChild(link);
    link.click();

    // OR you can save/write file locally.
    fs.writeFileSync(outputFilename, response.data);
  } catch (error) {
    throw Error(error);
  }
};

const YearPickerPayslip = ({ value, handlePicker }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        views={["year", "month", "day"]}
        value={value}
        onChange={handlePicker}
        renderInput={(params) => (
          <MuiTextField {...params} helperText={null} size="small" />
        )}
      />
    </LocalizationProvider>
  );
};

const PayslipUploadMenu = () => {
  const { loadingPayslipByMonth, allPayslipByMonth } = useSelector(
    (state) => state.payslip
  );
  // const calendar = useCalendar();
  const token = localStorage.getItem("accessToken");
  // const clientCode = localStorage.getItem("clientCode");
  const dispatch = useDispatch();
  const history = useHistory();
  // const getCompany = UseCompanyName();
  const [selected, setSelected] = useState("");
  const [selectedInput, setSelectedInput] = useState("");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const payslipYear = moment().format("YYYY");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // eslint-disable-next-line no-unused-vars
  const [yearPicker, setYearPicker] = useState(payslipYear);
  const startDate = moment().format("LL");
  const [valueStartDate, setValueStartDate] = useState(startDate);
  const [modalUpload, setModalUpload] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");
  const [listDataPayslip, setListDataPayslip] = useState("");
  const [modal, setModal] = useState(false);
  const [arrayData, setArrayData] = useState(false);
  const [dataClientCode, setDataClientCode] = useState([]);
  // const yearFormat = moment(yearPicker).format("YYYY");

  const persistClientName = useMemo(() => {
    let persistClient = window.sessionStorage.getItem("clientNamePayslip");
    let clientName;

    if (persistClient) {
      clientName = persistClient;
    } else {
      clientName = selected;
    }

    return clientName;
  }, [selected]);

  const persistDatePayslip = useMemo(() => {
    let persistDate = window.sessionStorage.getItem("persistDate");
    let selectedDate;

    if (persistDate) {
      selectedDate = persistDate;
    } else {
      selectedDate = valueStartDate;
    }

    return selectedDate;
  }, [valueStartDate]);

  useEffect(() => {
    if (arrayData) {
      dispatch(
        fetchAllPayslipByMonth(
          token,
          moment(persistDatePayslip).format("MM"),
          moment(persistDatePayslip).format("YYYY"),
          persistClientName
        )
      );
    }
  }, [token, dispatch, arrayData, persistClientName, persistDatePayslip]);

  useEffect(() => {
    if (persistClientName === "PT. PERMATA INDO SEJAHTERA") {
      setModal(true);
    } else {
      dispatch(
        fetchAllPayslipByMonth(
          token,
          moment(persistDatePayslip).format("MM"),
          moment(persistDatePayslip).format("YYYY"),

          persistClientName
        )
      );
    }
  }, [
    token,
    valueStartDate,
    dispatch,
    selected,
    persistClientName,
    persistDatePayslip,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCode(response?.map((el) => el?.name));
      } catch (error) {
        console.log(error);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeStartDate = (newValue) => {
    setValueStartDate(newValue);
    sessionStorage.setItem("persistDate", newValue);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInput = (event) => {
    setListDataPayslip(event.target.value);
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchTerm(listDataPayslip);
  };

  const handleSelectedClient = (value) => {
    setSelected(value);
    window.sessionStorage.setItem("clientNamePayslip", value);
  };

  const handleSearch = (value) => {
    setSelectedInput(value);
  };

  // const isDataUpload = () => {
  //   setModalUpload(true);
  //   window.location.reload();
  // };

  // ------------------ //
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();
    body.append("file", file);
    const token = localStorage.getItem("accessToken");
    return {
      // https://dev.myworkspace.id/api/v3/payment-slip/admin?month=12&year=2022&code=PIS
      url: `${API}/payment-slip/admin?month=${moment(persistDatePayslip).format(
        "MM"
      )}&year=${moment(persistDatePayslip).format(
        "YYYY"
      )}&clientName=${persistClientName}`,
      // url: `${API}/epayslip/upload-epayslip?client=${persistClientName}&payrollMonth=${new Date(persistDatePayslip).getTime()}`,
      body,
      headers: {
        Authorization: token,
      },
    };
  };

  const handleChangeStatus = ({ meta }, status) => {
    console.log(status, meta);
  };

  const handleSubmit = (files, allFiles) => {
    console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };

  const uploadButton = () => {
    return (
      <Button
        endIcon={<PublishIcon />}
        style={{ textTransform: "none", color: "#FFFFFF" }}
        onClick={() => setModalUpload(true)}
      >
        Upload
      </Button>
    );
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const Preview = ({ meta }) => {
    const { name, percent, status } = meta;
    // eslint-disable-next-line no-unused-vars
    const [progressColor, setColor] = useState("#1571DE");
    return (
      <>
        <div className="preview-RDU">
          <div className="icon-RDU">
            <img src={EXCEL_IMAGE} alt="" style={{ width: 48, height: 48 }} />
            <div className="text-name-RDU">
              <span
                style={{
                  alignSelf: "flex-start",
                  margin: "10px 3%",
                  fontFamily: "Poppins",
                }}
              >
                {name}
              </span>
            </div>
          </div>
        </div>
        {status !== "done" ? (
          <div className="progress-RDU">
            <Progress
              width={100}
              percent={Math.round(percent)}
              status="success"
              theme={{ success: { symbol: "‍", color: progressColor } }}
            />
          </div>
        ) : (
          <div className="progress-RDU-done">
            <span>Done</span>
          </div>
        )}
      </>
    );
  };

  const Input = ({ accept, onFiles, files, getFilesFromEvent, extra }) => {
    const text = "Browse File";
    const isRejected = extra.reject;
    return (
      <div className="input-RDU">
        {isRejected ? (
          <>
            <div style={{ paddingTop: 60 }}>
              <span>File must .xls</span>
            </div>
          </>
        ) : (
          <>
            <div className="">
              <span>Drag and drop file here</span>
            </div>
            <div className="">
              <span>File must .xls</span>
            </div>
            <div className="">
              <span>OR</span>
            </div>
            <div className="">
              <label
                style={{
                  width: 100,
                  height: 40,
                  border: "1px solid #1571DE",
                  padding: 10,
                  cursor: "pointer",
                  borderRadius: 4,
                  textTransform: "none",
                  fontSize: 14,
                  textAlign: "center",
                  color: "#1571DE",
                }}
              >
                {text}
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept={accept}
                  onChange={(e) => {
                    getFilesFromEvent(e).then((chosenFiles) => {
                      onFiles(chosenFiles);
                    });
                  }}
                />
              </label>
            </div>
          </>
        )}
      </div>
    );
  };

  // console.log(allPayslipByMonth, 'test')

  return (
    <div className="container-payslip-upload-menu">
      <div className="item-top-payslip-upload-menu">
        <div>
          <Autocomplete
            disablePortal
            options={dataClientCode}
            value={persistClientName}
            inputValue={selectedInput}
            onInputChange={(e, value) =>
              value === null ? handleSearch("") : handleSearch(value)
            }
            onChange={(e, value) =>
              value === null
                ? handleSelectedClient("")
                : handleSelectedClient(value)
            }
            renderInput={(params) => (
              <MuiTextField
                {...params}
                size="small"
                sx={{ width: 300 }}
                placeholder="Select Client"
                required
              />
            )}
          />
        </div>
        <div>
          <FormControl>
            <YearPickerPayslip
              value={persistDatePayslip}
              handlePicker={handleChangeStartDate}
            />
          </FormControl>
        </div>
        <div>
          <Button
            startIcon={<DownloadIcon />}
            style={{ textTransform: "none", fontSize: 14, fontWeight: 500 }}
            onClick={downloadXLSFile}
          >
            Download Template
          </Button>
        </div>
      </div>
      <div className="container-upload-file-pyaslip-menu">
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          submitButtonContent={uploadButton}
          getFilesFromEvent={getFilesFromEvent}
          maxFiles={1}
          PreviewComponent={Preview}
          submitButtonDisabled={(files) => files.length < 1}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          InputComponent={Input}
          inputContent={(files, extra) =>
            extra.reject ? "File must be .XLSX" : "Drag Files .XLSX Here"
          }
          styles={{
            dropzone: {
              minHeight: 190,
              maxHeight: 190,
              border: "1px dashed #9E9E9E",
              borderRadius: 8,
              backgroundColor: "rgba(211, 226, 255, 0.1)",
            },
            dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
            inputLabel: (files, extra) =>
              extra.reject ? { color: "red" } : { fontSize: 18 },
          }}
        />
      </div>
      {modalUpload && (
        <ModalSuccessUpload
          setModalUpload={setModalUpload}
          modalUpload={modalUpload}
          title="Success Upload"
          text="You have uploaded data"
        />
      )}
      <div className="searchbar-payslip-upload-menu">
        <SearchbarSA
          value={listDataPayslip}
          onChange={handleSearchInput}
          onSubmit={handleSubmitFilter}
          searchWidth="300px"
          placeholder="Search by name"
        />
      </div>
      {loadingPayslipByMonth ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ width: 200 }}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <Card sx={{ marginTop: 2}}>
          <div className="container-table-payslip-menu">
            <Scrollbar>
              <TableContainer sx={{ minWidth: 600 }}>
                <Table
                  sx={{
                    width: "100%",
                    maxHeight: "100%",
                    backgroundColor: "#fafafa",
                    borderRadius: 4,
                  }}
                >
                  <EnhancedTableHead
                    numSelected={allPayslipByMonth.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    values={allPayslipByMonth}
                    rowCount={
                      allPayslipByMonth?.filter(
                        (el) =>
                          el?.name?.toLowerCase().includes(listDataPayslip?.toLowerCase()) ||
                          el?.jobTitle?.toLowerCase().includes(listDataPayslip?.toLowerCase())
                        // && (selectedRegion ? el.EmployeeArea === selectedRegion: true)
                      ).length
                    }
                  />
                  {/* <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={allPayslipByMonth?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                /> */}
                  <TableBody>
                    {stableSort(
                      allPayslipByMonth,
                      getComparator(order, orderBy)
                    )
                      ?.filter(
                        (el) =>
                          el.name
                            ?.toLowerCase()
                            .includes(listDataPayslip?.toLowerCase()) ||
                          el.jobTitle
                            ?.toLowerCase()
                            .includes(listDataPayslip?.toLocaleLowerCase())
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, ind) => {
                        /* {
                          /* let month =
                          calendar.months.eng.full[
                            data?.PayrollMonth?.split(" ")[0] - 1
                          ];
                        let year = data?.PayrollMonth?.split(" ")[2]; */

                        /* let date = data?.payslips?.map((el) => el?.date);
                        let month = data?.payslips?.map((el) => el?.month);
                        let year = data?.payslips?.map((el) => el?.year); */

                        return (
                          <TableRow hover key={ind} tabIndex={-1}>
                            <TableCell></TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  alt={data?.Name}
                                  src={data?.name?.substr(0, 1)}
                                />
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {data?.name?.toLowerCase()}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{ textTransform: "capitalize" }}
                              >
                                {data?.jobTitle?.toLowerCase()}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">{data.client}</TableCell>
                            <TableCell align="left">
                              {data?.payslips[0]?.date}
                              {/* {`${data.payslips.} ${month} ${year}`} */}
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  fontWeight: 500,
                                }}
                                onClick={() => {
                                  history.push({
                                    pathname: `/superadmin/payslip-person-detail/${data?.id}`,
                                    state: data,
                                  });
                                }}
                              >
                                See Details
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={
                allPayslipByMonth?.filter(
                  (el) =>
                    el.name
                      ?.toLowerCase()
                      .includes(listDataPayslip?.toLowerCase()) ||
                    el.jobTitle
                      ?.toLowerCase()
                      .includes(listDataPayslip?.toLocaleLowerCase())
                ).length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Card>
      )}

      {modal && (
        <ModalPassword
          showModal={modal}
          setModal={setModal}
          setArrayData={setArrayData}
          arrayData={arrayData}
          validPassword="epay_pis"
        />
      )}
    </div>
  );
};

export default PayslipUploadMenu;
