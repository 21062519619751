/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import Styles from "./AttendanceExternal.module.css";
import DateRangePickerComp from "../../../SuperAdmin/_attendance/date-range-picker/DateRangePicker";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
// import "./AttendanceExternal.css";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ExportCSV from "../../../SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import {
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Autocomplete,
  TextField,
  styled,
  Card,
  TableHead,
  TableSortLabel,
  Menu,
} from "@mui/material";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import UserListHead from "../../../SuperAdmin/_user/TableUsers/UserListHead";
import { filter } from "lodash";
import DateRangePickerComponent from "../../../utils/DateRangePicker/DateRangePicker";
import moment from "moment";
import {
  fetchAllDataEmployeeByRange,
  fetchAllDataEmployeeByRangeExternal,
} from "../../../../redux/actions/External/dataEmployeeExternalActions";
import { loadingButtonClasses } from "@mui/lab";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
// import { UseGroupName } from "../../../utils/hooks";
// import UseGetAllPositionExternal from "../../../utils/hooks/useGetAllPositionExternal";
import fetchGroupData from "../../../../async/client/fetchGroupData";
import useGroupData from "../../../utils/hooks/useGroupData";
import { visuallyHidden } from "@mui/utils";
import { alpha, Box } from "@mui/system";
import toHoursAndMinutes from "../../../utils/ToHoursAndMinute/toHoursAndMinute";
import fetchUserClients from "../../../../async/userClient/fetchUserClient";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const ExcelJS = require("exceljs");

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 170,
    display: "flex",
    justifyContent: "flex-start",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiList-root": {
      width: "100%",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const TABLE_HEAD = [
  { id: "name", label: "Employee Name", alignRight: false },
  { id: "position", label: "Position", alignRight: false },
  { id: "attend", numeric: true, label: "Attend", alignRight: false },
  { id: "annualLeave", numeric: true, label: "Leave", alignRight: false },
  { id: "sick", numeric: true, label: "Sick", alignRight: false },
  // { id: "permit", numeric: true, label: "Permit", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        <TableCell padding="none" style={{ paddingLeft: 0 }}>
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={rowCount !== values?.users?.length}
            sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
          /> */}
        </TableCell>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const toDataURL = (url) => {
  const promise = new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });

  return promise;
};

export default function AttendanceExternal() {
  // const getClient = UseGroupName();
  // const getPosition = UseGetAllPositionExternal();
  const clientCode = localStorage.getItem("clientCode");
  const clientName = localStorage.getItem("clientName");
  const clientUserName = localStorage.getItem("userName");
  // const groupData = useGroupData(clientCode, clientName);
  const { dataEmployeeExternalAll, loadingDataEmployee } = useSelector(
    (state) => state.employeeExternal
  );
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState(clientName);
  const [filterName, setFilterName] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listDataAttendance, setListDataAttendance] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [dataUserClient, setDataClientUser] = useState([]);
  const [valueStartDate, setValueStartDate] = useState(moment().format("L"));
  const [valueEndDate, setValueEndDate] = useState(moment().format("L"));
  const [groupData, setGroupData] = useState([]);
  const dataEmployee = dataEmployeeExternalAll;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    (async () => {
      try {
        const data = await fetchUserClients(token);
        setDataClientUser(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [token]);

  const dateValueStart = useMemo(() => {
    let sessionStartDate = window.sessionStorage.getItem("startDate");
    let firstDate;

    if (sessionStartDate) {
      firstDate = sessionStartDate;
    } else {
      firstDate = valueStartDate;
    }

    return firstDate;
  }, [valueStartDate]);

  const dateValueEnd = useMemo(() => {
    let sessionEndDate = window.sessionStorage.getItem("endDate");
    let secondDate;

    if (sessionEndDate) {
      secondDate = sessionEndDate;
    } else {
      secondDate = valueEndDate;
    }

    return secondDate;
  }, [valueEndDate]);

  const persistClientName = useMemo(() => {
    let persistClient = window.sessionStorage.getItem("selectedClient");
    let clientName;

    if (persistClient) {
      clientName = persistClient;
    } else {
      clientName = selected;
    }

    return clientName;
  }, [selected]);

  const clientArr = useMemo(() => {
    return dataUserClient.map((el) => el?.name_client);
  }, [dataUserClient]);

  const dateRange = useMemo(() => {
    let range = [];
    for (
      var d = new Date(dateValueStart);
      d <= new Date(dateValueEnd);
      d.setDate(d.getDate() + 1)
    ) {
      range.push(moment(d).format("L"));
    }
    return range;
  }, [dateValueStart, dateValueEnd]);

  const dataAbsenceRaw = () => {
    const rawArr = [];
    for (let i = 0; i < dataEmployeeExternalAll?.length; i++) {
      const absences = dataEmployeeExternalAll[i]?.Absences;
      let attendanceArr = [];
      for (let j = 0; j < dateRange.length; j++) {
        const dateExist = absences.some(
          (el) => moment(el.startTime).format("L") === dateRange[j]
        );
        const absenceIndex = absences.findIndex(
          (el) => moment(el.startTime).format("L") === dateRange[j]
        );

        attendanceArr.push({
          date: dateRange[j],
          type: dateExist
            ? (absences[absenceIndex]?.type === "ATTEND" && "Hadir") ||
              (absences[absenceIndex]?.type === "ANNUAL-LEAVE" && "Cuti") ||
              (absences[absenceIndex]?.type === "SICK-PERMIT" && "Sakit") ||
              (absences[absenceIndex]?.type === "PERMIT" && "Izin")
            : "-",
        });
      }
      rawArr.push({
        name: dataEmployeeExternalAll[i]?.name,
        attendances: attendanceArr,
      });
    }
    return rawArr;
  };

  const dataToExport2 = dataAbsenceRaw();

  // console.log(dataToExport2, "<<<,");
  // console.log(dataEmployeeExternalAll, "dataaa");

  const dataCutOff2 = useMemo(() => {
    const datas = [];
    for (let i = 0; i < dataToExport2?.length; i++) {
      const attendances = dataToExport2[i]?.attendances;
      const eachData = {
        No: i + 1,
        Name: dataToExport2[i]?.name,
      };
      for (let j = 0; j < attendances?.length; j++) {
        eachData[attendances[j].date] = attendances[j].type;
      }
      datas.push(eachData);
    }
    return datas;
  }, [dataToExport2]);

  // console.log(clientArr, 'yooo')

  const formatGetTime = (time) => {
    return new Date(time)?.getTime() - 7 * 60 * 60 * 1000;
  };

  const dataTest = () => {
    const array = [];
    for (let i = 0; i < dataEmployeeExternalAll?.length; i++) {
      const absences = dataEmployeeExternalAll[i]?.attendances;
      for (let j = 0; j < absences?.length; j++) {
        array.push({
          ...dataEmployeeExternalAll[i],
          timeIn: moment(formatGetTime(absences[j]?.startTime)).format(
            "HH:mm:ss"
          ),
          timeOut: moment(formatGetTime(absences[j]?.endTime)).format(
            "HH:mm:ss"
          ),
          dateTimeIn: moment(formatGetTime(absences[j]?.startTime)).format("L"),
          dateTimeOut: moment(formatGetTime(absences[j]?.endTime)).format("L"),
          longitude: absences[j]?.location?.longitude
            ? absences[j]?.location?.longitude
            : "-",
          latitude: absences[j]?.location?.latitude
            ? absences[j]?.location?.latitude
            : "-",
          type: absences[j]?.type,
          month: moment(absences[j].startTime).format("M"),
          year: moment(absences[j].endTime).format("YYYY"),
          status:
            (absences[j].type === "ATTEND" && "Hadir") ||
            (absences[j].type === "ANNUAL-LEAVE" && "Cuti") ||
            (absences[j].type === "SICK-PERMIT" && "Sakit"),
        });
      }

      // for(let j = 0; j < userName.length; j ++) {
      //   array.push({
      //     ...dataEmployeeExternalAll[i],
      //     name: userName[j]?.name
      //   })
      // }
    }
    return array;
  };

  const dataAttendanceArray = useMemo(() => {
    const arrayAttendances = [];
    // const arrayUserName =
    for (let i = 0; i < dataEmployeeExternalAll?.length; i++) {
      const absences = dataEmployeeExternalAll[i]?.attendances;
      for (let j = 0; j < absences?.length; j++) {
        arrayAttendances.push({
          ...dataEmployeeExternalAll[i],
          timeIn: moment(formatGetTime(absences[j]?.startTime)).format(
            "HH:mm:ss"
          ),
          timeOut: moment(formatGetTime(absences[j]?.endTime)).format(
            "HH:mm:ss"
          ),
          dateTimeIn: moment(formatGetTime(absences[j]?.startTime)).format("L"),
          dateTimeOut: moment(formatGetTime(absences[j]?.endTime)).format("L"),
          longitude: absences[j]?.location?.longitude
            ? absences[j]?.location?.longitude
            : "-",
          latitude: absences[j]?.location?.latitude
            ? absences[j]?.location?.latitude
            : "-",
          type: absences[j]?.type,
          month: moment(absences[j].startTime).format("M"),
          year: moment(absences[j].endTime).format("YYYY"),
          status:
            (absences[j].type === "ATTEND" && "Hadir") ||
            (absences[j].type === "ANNUAL-LEAVE" && "Cuti") ||
            (absences[j].type === "SICK-PERMIT" && "Sakit"),
        });
      }
    }
    return arrayAttendances;
  }, [dataEmployeeExternalAll]);

  const dataUserName = useMemo(() => {
    return dataEmployeeExternalAll.map((el) => el?.user?.name);
  }, [dataEmployeeExternalAll]);

  // console.log(dataAttendanceArray.concat(dataUserName), '<<<<')

  useEffect(() => {
    dispatch(
      fetchAllDataEmployeeByRangeExternal(
        token,
        moment(new Date(dateValueStart).getTime()).format("YYYY-MM-DD"),
        moment(new Date(dateValueEnd).getTime()).format("YYYY-MM-DD"),
        persistClientName
      )
    );
  }, [dateValueStart, dateValueEnd, token, dispatch, persistClientName]);

  useEffect(() => {
    (async () => {
      try {
        const data = await fetchGroupData(token, persistClientName);
        setGroupData(data?.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [persistClientName, token]);

  // --------------------------------
  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 80;

    sheet.getRow(1).font = {
      name: "Poppins",
      size: 16,
      bold: true,
    };

    sheet.columns = [
      {
        header: "No",
        key: "no",
        width: 10,
      },
      { header: "Nama", key: "name", width: 32 },
      {
        header: "Company",
        key: "company",
        width: 20,
      },
      {
        header: "Position",
        key: "position",
        width: 20,
      },
      {
        header: "Nrk",
        key: "nrk",
        width: 15,
      },
      {
        header: "Month",
        key: "month",
        width: 10,
      },
      {
        header: "Year",
        key: "year",
        width: 30,
      },
      {
        header: "Date In",
        key: "dateIn",
        width: 30,
      },
      {
        header: "Date Out",
        key: "dateOut",
        width: 30,
      },
      {
        header: "Time In",
        key: "timeIn",
        width: 30,
      },
      {
        header: "Time Out",
        key: "timeOut",
        width: 30,
      },
      {
        header: "Coordinate Point",
        key: "coordinatePoint",
        width: 30,
      },
      {
        header: "Late",
        key: "late",
        width: 30,
      },
      {
        header: "Total Work Hour",
        key: "workHour",
        width: 30,
      },
      {
        header: "Region",
        key: "region",
        width: 30,
      },
      {
        header: "Status Absence",
        key: "status",
        width: 30,
      },
      {
        header: "Remark",
        key: "remark",
        width: 30,
      },
      {
        header: "Photo",
        key: "photo",
        width: 30,
      },
    ];

    const promise = Promise.all(
      dataToExport.map(async (el, index) => {
        const rowNumber = index + 1;
        sheet.addRow({
          no: index + 1,
          name: el?.name,
          company: el?.client,
          position: el.jobTitle,
          nrk: el?.employeeId,
          month: el.month,
          year: el.year,
          dateIn: el.dateTimeIn,
          dateOut: el.dateTimeOut,
          timeIn: el.timeIn,
          timeOut: el.timeOut,
          coordinatePoint: `${el.latitude} ${el.longitude}`,
          late: el?.isLate,
          workHour: `${el?.totalWorkHour?.hours} Hours ${el?.totalWorkHour?.minutes} Minutes`,
          region: el?.region,
          status: el.status,
          remark: el.remark,
        });
        // console.log(el?.imageAttendance, "testtt");
        const result = await toDataURL(el?.imageAttendance);
        const splitted = el?.imageAttendance?.split(".");
        const extName = splitted[splitted?.length - 1];

        const imageId2 = workbook.addImage({
          base64: result.base64Url,
          extension: extName,
        });

        sheet.addImage(imageId2, {
          tl: { col: 17, row: rowNumber },
          ext: { width: 100, height: 100 },
        });
      })
    );

    promise.then(() => {
      const priceCol = sheet.getColumn(17);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        // if (cellValue > 50 && cellValue < 1000) {
        //   sheet.getCell(cell?.address).fill = {
        //     type: "pattern",
        //     pattern: "solid",
        //     fgColor: { argb: "FF0000" },
        //   };
        // }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `Attendance Report ${new Date().toLocaleDateString()}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  // ---------------------------------

  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  const handleSearchInput = (event) => {
    setListDataAttendance(event.target.value);
  };

  const handleSearchClient = (value) => {
    setSelectedClient(value);
  };
  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchTerm(listDataAttendance);
  };

  const handleSelectedClient = (value) => {
    window.sessionStorage.setItem("selectedClient", value);
    setSelected(value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeStartDate = (newValue) => {
    setValueStartDate(newValue);
    window.sessionStorage.setItem("startDate", newValue);
  };
  const handleChangeEndDate = (newValue) => {
    setValueEndDate(newValue);
    window.sessionStorage.setItem("endDate", newValue);
  };

  const attendanceArr = () => {
    const array = [];
    for (let i = 0; i < dataEmployeeExternalAll?.length; i++) {
      const absences = dataEmployeeExternalAll[i]?.Absences;
      for (let j = 0; j < absences?.length; j++) {
        array.push({
          ...dataEmployeeExternalAll[i],
          // timeIn: moment(formatGetTime(absences[j]?.startTime)).format(
          //   "HH:mm:ss"
          // ),
          // timeOut: moment(formatGetTime(absences[j]?.endTime)).format(
          //   "HH:mm:ss"
          // ),
          // dateTimeIn: moment(formatGetTime(absences[j]?.startTime)).format("L"),
          // dateTimeOut: moment(formatGetTime(absences[j]?.endTime)).format("L"),
          timeIn: absences[j].startTime
            ? moment(absences[j].startTime).format("HH:mm:ss")
            : "-",
          timeOut:
            absences[j].endTime && absences[j].endTime !== "-"
              ? moment(absences[j].endTime).format("HH:mm:ss")
              : "-",
          dateTimeIn: moment(absences[j].startTime).format("L"),
          dateTimeOut:
            absences[j].endTime && absences[j].endTime !== "-"
              ? moment(absences[j].endTime).format("L")
              : "-",
          longitude: absences[j]?.location?.longitude
            ? absences[j]?.location?.longitude
            : "-",
          latitude: absences[j]?.location?.latitude
            ? absences[j]?.location?.latitude
            : "-",
          type: absences[j]?.type,
          month: moment(absences[j].startTime).format("M"),
          year: moment(absences[j].startTime).format("YYYY"),
          isLate: absences[j]?.isLate,
          totalWorkHour: toHoursAndMinutes(
            absences[j]?.accumulate?.workInMinute
          ),
          region: absences[j]?.area,
          remark: absences[j]?.remark?.includes("::")
            ? capitalizeFirstLetter(absences[j]?.remark.split("::")[2])
            : capitalizeFirstLetter(absences[j]?.remark),
          status:
            (absences[j].type === "ATTEND" && "Hadir") ||
            (absences[j].type === "ANNUAL-LEAVE" && "Cuti") ||
            (absences[j].type === "SICK-PERMIT" && "Sakit"),
          imageAttendance: absences[j].images[0]?.uri
            ? absences[j].images[0]?.uri
            : "-",
          requestTime: absences[j]?.applyDate
            ? moment(absences[j].applyDate).format("HH:mm:ss")
            : "-",
          requestDate: absences[j]?.applyDate
            ? moment(absences[j].applyDate).format("L")
            : "",
        });
      }
    }
    return array;
  };
  const dataToExport = attendanceArr();

  console.log(dataToExport);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div className={Styles.Container}>
      <div className={Styles.Wrapper}>
        <div className={Styles.Searchbar}>
          <SearchbarSA
            value={listDataAttendance}
            onChange={handleSearchInput}
            onSubmit={handleSubmitFilter}
            searchWidth="100%"
            placeholder="Search by name"
          />
          <div className={Styles.Selection}>
            <Autocomplete
              disablePortal
              options={clientArr}
              value={persistClientName}
              inputValue={selectedClient}
              sx={{ width: "100%", backgroundColor: "#FFFFFF" }}
              onInputChange={(e, value) =>
                value === null
                  ? handleSearchClient("")
                  : handleSearchClient(value)
              }
              onChange={(e, value) =>
                value === null
                  ? handleSelectedClient("")
                  : handleSelectedClient(value)
              }
              renderInput={(params) => (
                <MuiTextField {...params} size="small" />
              )}
            />
            <Autocomplete
              disablePortal
              // options={groupData?.employeeArea || []}
              options={
                groupData?.employeeArea?.filter(
                  (area) => area !== null && area !== "NULL" && area !== ""
                ) || []
              }
              value={selectedRegion}
              inputValue={selectedRegion}
              sx={{ width: "100%", backgroundColor: "#FFFFFF" }}
              onInputChange={(e, value) => setSelectedRegion(value)}
              renderInput={(params) => (
                <MuiTextField {...params} size="small" placeholder="Region" />
              )}
            />
          </div>
        </div>
        <div className={Styles.Others}>
          <div className={Styles.OthersDownload}>
            <Button
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableElevation
              onClick={handleClick}
              endIcon={<ExpandMoreIcon />}
              sx={{
                border: "1px solid #E0E0E0",
                borderRadius: "8px",
                textTransform: "none",
                color: "#757575",
                fontSize: 14,
                fontWeight: 400,
                width: 170,
                height: 40,
                backgroundColor: "#FFFFFF",
              }}
            >
              Download Excel
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {persistClientName === "PT. CITRA NUSA INSAN CEMERLANG" ? (
                /* <div className={Styles.ActionDownload}> */
                <ExportCSV
                  data={dataToExport
                    .filter(
                      (el) =>
                        (el?.name
                          ?.toLowerCase()
                          .includes(listDataAttendance.toLowerCase()) ||
                          el?.jobTitle
                            ?.toLowerCase()
                            .includes(listDataAttendance.toLowerCase())) &&
                        (selectedRegion
                          ? el?.division === selectedRegion
                          : true)
                    )
                    .map((el, ind) => ({
                      "No. ": ind + 1,
                      Nama: el?.name,
                      Company: el?.client,
                      Position: el.jobTitle,
                      Nrk: el?.employeeId,
                      Month: el.month,
                      Year: el.year,
                      "Date in": el.dateTimeIn,
                      "Date out": el.dateTimeOut,
                      "Time in": el.timeIn,
                      "Time out": el.timeOut,
                      "Coordinate Point": `${el.latitude} ${el.longitude}`,
                      Late: el?.isLate,
                      "Total Work Hour": `${el?.totalWorkHour?.hours} Hours ${el?.totalWorkHour?.minutes} Minutes`,
                      Region: el?.region,
                      "Status Absence": el.status,
                      Remark: el.remark,
                    }))}
                  filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                  initial="Default"
                />
              ) : (
                /* </div> */
                /* <div className={Styles.ActionButton}> */
                <ExportCSV
                  data={dataToExport
                    .filter(
                      (el) =>
                        (el?.name
                          ?.toLowerCase()
                          .includes(listDataAttendance.toLowerCase()) ||
                          el?.jobTitle
                            ?.toLowerCase()
                            .includes(listDataAttendance.toLowerCase())) &&
                        (selectedRegion
                          ? el?.division === selectedRegion
                          : true)
                    )
                    .map((el, ind) => ({
                      "No. ": ind + 1,
                      Nama: el?.name,
                      Company: el?.client,
                      Position: el.jobTitle,
                      Nrk: el?.employeeId,
                      Month: el.month,
                      Year: el.year,
                      "Date in": el.dateTimeIn,
                      "Date out": el.dateTimeOut,
                      "Time in": el.timeIn,
                      "Time out": el.timeOut,
                      "Coordinate Point": `${el.latitude} ${el.longitude}`,
                      Region: el.region,
                      "Status Absence": el.status,
                      Remark: el.remark,
                      "Request Date": el?.requestDate,
                      "Request Time": el?.requestTime,
                    }))}
                  filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                  initial="Default"
                />
                /* </div> */
              )}
              <Button
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  textTransform: "none",
                  color: "#404040",
                  fontSize: 14,
                  fontWeight: 400,
                }}
                onClick={exportExcelFile}
              >
                Detailed
              </Button>
              <ExportCSV
                data={dataCutOff2}
                filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                initial="Summary"
              />
            </StyledMenu>
          </div>
          <div className={Styles.OthersRangeDate}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className={Styles.RangeDate}>
                <DatePicker
                  sx={{ width: "100%" }}
                  components={{
                    OpenPickerIcon: CalendarMonthIcon,
                  }}
                  required
                  value={dateValueStart}
                  onChange={handleChangeStartDate}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      size="small"
                      sx={{ width: "100%", backgroundColor: "#FFFFFF" }}
                    />
                  )}
                />
              </div>
              <div className={Styles.RangeDate}>
                <DatePicker
                  sx={{ width: "100%" }}
                  components={{
                    OpenPickerIcon: CalendarMonthIcon,
                  }}
                  required
                  value={dateValueEnd}
                  onChange={handleChangeEndDate}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      size="small"
                      sx={{ width: "100%", backgroundColor: "#FFFFFF" }}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
            {/* <DateRangePickerComponent
              valueStartDate={dateValueStart}
              valueEndDate={dateValueEnd}
              handleChangeStartDate={handleChangeStartDate}
              handleChangeEndDate={handleChangeEndDate}
            /> */}
          </div>
        </div>
        {/* <div className="">
          <Autocomplete
            disablePortal
            options={groupData?.employeeArea || []}
            // options={ groupData?.employeeArea?.filter((area) => area !== null) || [] }
            value={selectedRegion}
            inputValue={selectedRegion}
            onInputChange={(e, value) => setSelectedRegion(value)}
            renderInput={(params) => (
              <MuiTextField
                {...params}
                size="small"
                placeholder="Region"
                sx={{ width: 300 }}
              />
            )}
          />
        </div> */}
        {/* <div className="">
          <Autocomplete
            disablePortal
            options={groupData?.position || []}
            value={selectedPosition}
            inputValue={selectedPosition}
            onInputChange={(e, value) => setSelectedPosition(value)}
            renderInput={(params) => (
              <MuiTextField
                {...params}
                size="small"
                placeholder="Position"
                sx={{ width: 300 }}
              />
            )}
          />
        </div> */}
        {/* <div className="attendance__item-date">
          <DateRangePickerComponent
            valueStartDate={dateValueStart}
            valueEndDate={dateValueEnd}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
          />
        </div> */}
      </div>
      {loadingDataEmployee ? (
        <div className={Styles.LoadingContainer}>
          <div className={Styles.LoadingWidth}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <Card sx={{ marginTop: 2 }}>
          <Scrollbar>
            <TableContainer>
              <Table
                id="emp-table"
                sx={{
                  width: "100%",
                  maxHeight: "100%",
                }}
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: 5,
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                }}
              >
                <EnhancedTableHead
                  numSelected={dataEmployeeExternalAll.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  values={dataEmployeeExternalAll}
                  rowCount={
                    dataEmployeeExternalAll?.filter(
                      (el) =>
                        el?.name
                          ?.toLowerCase()
                          .includes(listDataAttendance?.toLowerCase()) ||
                        el?.jobTitle
                          ?.toLowerCase()
                          .includes(listDataAttendance?.toLowerCase())
                      // && (selectedRegion ? el.EmployeeArea === selectedRegion: true)
                    ).length
                  }
                />
                {/* <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataEmployeeExternalAll?.length}
                  onRequestSort={handleRequestSort}
                /> */}
                <TableBody>
                  {stableSort(
                    dataEmployeeExternalAll,
                    getComparator(order, orderBy)
                  )
                    .filter(
                      (el) =>
                        (el?.name
                          ?.toLowerCase()
                          .includes(listDataAttendance.toLowerCase()) ||
                          el?.jobTitle
                            ?.toLowerCase()
                            .includes(listDataAttendance.toLowerCase())) &&
                        (selectedPosition
                          ? el?.jobTitle === selectedPosition
                          : true) &&
                        (selectedRegion
                          ? el?.division === selectedRegion
                          : true)
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((employee, ind) => {
                      const labelId = `enhanced-table-checkbox-${ind}`;
                      return (
                        <TableRow hover key={ind} tabIndex={-1}>
                          <TableCell></TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            id={labelId}
                          >
                            {/* <Typography
                              noWrap
                              style={{ textTransform: "capitalize" }}
                            >
                              {employee?.user?.name}
                            </Typography> */}
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar src={employee?.profilePicture} />
                              <Typography
                                noWrap
                                style={{ textTransform: "capitalize" }}
                              >
                                {employee?.name?.toLowerCase()}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ textTransform: "capitalize" }}>
                              {employee?.jobTitle.toLowerCase()}
                            </Typography>
                          </TableCell>
                          <TableCell>{employee?.attend}</TableCell>
                          <TableCell>{employee?.annualLeave}</TableCell>
                          <TableCell>{employee?.sick}</TableCell>
                          {/* <TableCell>{ employee?.attendances?.filter( subEl => subEl.type === "ATTEND").length }</TableCell>
                          <TableCell>{ employee?.attendances?.filter( subEl => subEl.type === "ANNUAL-LEAVE").length }</TableCell>
                          <TableCell>{ employee?.attendances?.filter( subEl => subEl.type === "SICK-PERMIT").length }</TableCell> */}
                          {/* <TableCell>{employee?.permit}</TableCell> */}
                          <TableCell align="right">
                            <Button
                              id="button-see-detail"
                              style={{
                                textTransform: "none",
                                fontSize: 16,
                                fontWeight: 500,
                                color: "#1571DE",
                              }}
                              onClick={() => {
                                history.push({
                                  pathname: `/admin/attendance-details/${employee?.employeeId}`,
                                  state: {
                                    startDate: dateValueStart,
                                    endDate: dateValueEnd,
                                    summary: employee,
                                  },
                                });
                              }}
                            >
                              See Detail
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          {/* <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={
              dataEmployeeInternalAll?.filter(
                (el) =>
                  (el?.user?.name?.toLowerCase().includes(listDataAttendance?.toLowerCase()) ||
                    el?.jobTitle?.toLowerCase().includes(listDataAttendance?.toLowerCase())) &&
                  (selectedRegion ? el.EmployeeArea === selectedRegion : true)
              ).length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={
              dataEmployeeExternalAll.filter(
                (el) =>
                  (el?.name
                    ?.toLowerCase()
                    .includes(listDataAttendance.toLowerCase()) ||
                    el?.jobTitle
                      ?.toLowerCase()
                      .includes(listDataAttendance.toLowerCase())) &&
                  (selectedPosition
                    ? el?.jobTitle === selectedPosition
                    : true) &&
                  (selectedRegion ? el?.division === selectedRegion : true)
              ).length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      )}
    </div>
  );
}
