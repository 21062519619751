import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Promo from "../Promo/Promo";

export default function InformationExternal() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="product__settings-tab">
      <TabContext value={value}>
        <Box sx={{ borderColor: "#1571DE" }}>
          <TabList onChange={handleChange}>
            <Tab label={<span className="text__tabs">Promo</span>} value="1" />
            <Tab
              label={<span className="text__tabs">Competitor</span>}
              value="2"
            />
            <Tab
              label={<span className="text__tabs">Category</span>}
              value="3"
            />
          </TabList>
        </Box>
        <TabPanel value="1" className="product__tabs">
          <Promo />
        </TabPanel>
        <TabPanel value="2" className="product__tabs">
          2
        </TabPanel>
        <TabPanel value="3" className="product__tabs">
          3
        </TabPanel>
      </TabContext>
    </div>
  );
}
