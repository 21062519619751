import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { AddNewProductFMCG } from "../../../../components/FMCG";

export default function AddNewProduct() {
  const location = useLocation();
  const addNewProductPath = location?.state?.location;
  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Setup</title>
      </Helmet>
      <AddNewProductFMCG location={addNewProductPath} />
    </div>
  );
}
