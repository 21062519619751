/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
// import "./EditClients.css";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
// import { LoanDetails } from "../../../components/SuperAdmin/_loanData/LoanTabs";
import { fetchPaymentLoanById, fetchSchemaLoanByIdUser } from "../../../redux/actions/SuperAdmin/loanActions";
import { useDispatch, useSelector } from "react-redux";
import { LoanDetailsExternal } from "../../../components/External/LoanData";

export default function LoanDataDetailExternal() {
    const history = useHistory();
    const location = useLocation();
    const loanDataById = location.state?.dataLoan;
    const { id } = useParams();
  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Loan Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.go(-1)}
              style={{ border: "none", textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Loan</span>
            </Button>
            <span style={{ fontSize: 20, }}>Detail Loan</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <LoanDetailsExternal dataLoan={ loanDataById } id={ id }/>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
