import React, { useEffect, useState } from "react";
import "./DetailsPromoFMCG.css";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router";
import Breadcrumb from "../../../utils/Breadcrumbs";
import SearchbarFMCG from "../../SearchbarFMCG/SearchbarFMCG";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/styles";
// import AddIcon from "@mui/icons-material/Add";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";

const data = [
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.300",
    status: "active",
    brand: "Barla",
    id: "1",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "unactive",
    brand: "Arla",
    id: "2",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "3",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "4",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "5",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "6",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "7",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "8",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "9",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "10",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "11",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "12",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "13",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "14",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "15",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "16",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "17",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "18",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "19",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "20",
  },
];

const TABLE_HEAD = [
  { id: "productName", label: "Product", alignRight: false },
  { id: "size", label: "Size", alignRight: false },
  { id: "brand", label: "Brand", alignRight: false },
  { id: "price", label: "Prices", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& th": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        <TableCell padding="none" style={{ paddingLeft: 0 }}>
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={rowCount !== values?.users?.length}
            sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
          /> */}
        </TableCell>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const promoTypeArr = [
  "Branded",
  "Gimmick",
  "Price Cut",
  "Promo NED",
  "New Product",
];
const statusArr = ["Active", "Non Active"];

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 5,
    },
    "& .MuiInputBase-input-MuiOutlinedInput-input": {
      padding: 0,
    },
    "& input::placeholder": {
      color: "#A4A4A4",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "18.2px",
      opacity: 1,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 5,
    },
    "& .MuiSelect-select": {
      color: "black",
      fontSize: 13,
      fontWeight: 400,
    },
    // "& input::placeholder": {
    //   color: "#A4A4A4",
    //   fontSize: 13,
    //   fontWeight: 400,
    //   lineHeight: "18.2px",
    //   opacity: 1,
    // },
  },
  adorment: {
    "& .MuiTypography-root ": {
      color: "#A4A4A4",
      fontSize: 15,
      fontWeight: 400,
      lineHeight: "18.2px",
    },
  },
}));

const DetailsPromoFMCG = ({ location }) => {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState("");
  const [orderBy, setOrderBy] = useState("productName");
  // const [selectedPosition, setSelectedPosition] = useState("");
  const [listPromoFMCG, setListPromoFMCG] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [disabledButton, setDisabledButton] = useState(true);
  const [values, setValues] = useState({});
  const [selectedInput, setSelectedInput] = useState({
    promoName: "",
    promoType: "",
    status: "",
  });

  useEffect(() => {
    // if (selectedInput.promoName && selectedInput.promoType && selectedInput.status) {
    //   setDisabledButton(false);
    // }

    if (location === "detailsPromoFMCG") {
      setDisabledButton(false);
    }
  }, [selectedInput, location]);

  useEffect(() => {
    if (location === "editPromoFMCG") {
      setValues((prev) => ({
        ...prev,
        promoName: "Cheese Spread",
      }));
      setSelectedInput((prev) => ({
        ...prev,
        promoType: "Price Cut",
        status: "Active",
      }));
    }
  }, [location]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (event.target.value.length >= 1) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (location === "addPromoFMCG") {
      const data = {
        PromoName: values.promoName,
        PromoType: selectedInput.promoType,
        Status: selectedInput.status,
      };
      try {
        console.log(data, "data submitted");

        history.push(`/admin-fmcg/setup/promo`)
      } catch (error) {
        console.log(data, "data error");
      }
    }

    if (location === "editPromoFMCG") {
      const data = {
        PromoName: values.promoName,
        PromoType: selectedInput.promoType,
        Status: selectedInput.status,
      };
      try {
        console.log(data, "edited data submitted ");
        history.push(`/admin-fmcg/setup/promo`)
      } catch (error) {
        console.log(data, "data error");
      }
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setListPromoFMCG(event.target.value);
  };

  // console.log(location)

  return (
    <div className="ContainerDetailsPromoFMCG">
      <div className="WrapperDetailsPromoFMCG">
        <div className="WrapperDetailsPromoFMCG">
          <div className="ButtonRouteBack" onClick={() => history.go(-1)}>
            <IconButton>
              <ArrowBackIcon sx={{ color: "#202020", width: 24, height: 24 }} />
            </IconButton>
          </div>
          <div className="WrapperBreadCrumbs">
            {location === "addPromoFMCG" ? (
              <Breadcrumb>
                <Button disabled style={{ textTransform: "none" }}>
                  <span className="BreadCrumbsTextGrey">Promo</span>
                </Button>
                <Button disabled style={{ textTransform: "none" }}>
                  <span className="BreadCrumbsTextBlue">Add Promo</span>
                </Button>
              </Breadcrumb>
            ) : (
              <Breadcrumb>
                <Button disabled style={{ textTransform: "none" }}>
                  <span className="BreadCrumbsTextGrey">Promo</span>
                </Button>
                <Button disabled style={{ textTransform: "none" }}>
                  <span className="BreadCrumbsTextBlue">Edit Promo</span>
                </Button>
              </Breadcrumb>
            )}
          </div>
        </div>
        <div className="WrapperContentDetailsPromo">
          {location === "addPromoFMCG" ? (
            <form className="WrapperFormDetailsPromo" onSubmit={handleSubmit}>
              <div className="WrapperItemsInputDetailsPromo">
                <div className="ItemsInputDetailsPromo">
                  <div className="ItemInputDetailsPromo">
                    <span>Promo Name</span>
                    <MuiTextField
                      size="small"
                      placeholder="Promo Name"
                      value={values.promoName || ""}
                      onChange={handleChange("promoName")}
                      type="text"
                      required
                    />
                  </div>
                  <div className="ItemInputDetailsPromo">
                    <span>Promo Type</span>
                    <FormControl fullWidth size="small">
                      <Select
                        className={classes.selectBorder}
                        value={selectedInput.promoType || ""}
                        displayEmpty
                        onChange={(e) =>
                          setSelectedInput({
                            ...selectedInput,
                            promoType: e.target.value,
                          })
                        }
                        required
                      >
                        <MenuItem value="" disabled>
                          <Typography
                            component="span"
                            style={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#A4A4A4",
                            }}
                          >
                            Promo Type
                          </Typography>
                        </MenuItem>
                        {promoTypeArr.sort().map((option, el) => (
                          <MenuItem
                            key={el}
                            onClick={() =>
                              setSelectedInput({
                                ...selectedInput,
                                promoType: option,
                              })
                            }
                            value={option || ""}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="ItemsInputDetailsPromo">
                  <div className="ItemInputDetailsPromo">
                    <span>Status</span>
                    <FormControl fullWidth size="small">
                      <Select
                        className={classes.selectBorder}
                        value={selectedInput.status || ""}
                        displayEmpty
                        onChange={(e) =>
                          setSelectedInput({
                            ...selectedInput,
                            status: e.target.value,
                          })
                        }
                        required
                      >
                        <MenuItem value="" disabled>
                          <Typography
                            component="span"
                            style={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#A4A4A4",
                            }}
                          >
                            Store Status
                          </Typography>
                        </MenuItem>
                        {statusArr.sort().map((option, el) => (
                          <MenuItem
                            key={el}
                            onClick={() =>
                              setSelectedInput({
                                ...selectedInput,
                                status: option,
                              })
                            }
                            value={option || ""}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="ItemInputDetailsPromo">
                    {/* <span>Store Account Group Name</span> */}
                    {/* <MuiTextField
                      size="small"
                      placeholder="Group Name"
                      value={values.GroupName || ""}
                      onChange={handleChange("GroupName")}
                      type="text"
                      required
                    /> */}
                  </div>
                </div>
              </div>

              <div className="ButtonActionDetailsPromo">
                <Button
                  style={{
                    background: "#FFFFF",
                    borderRadius: 5,
                    border: "1px solid #818181",
                    width: 79,
                    height: 34,
                    textTransform: "none",
                    color: "#818181",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  onClick={() => history.push("/admin-fmcg/setup/promo")}
                >
                  Cancel
                </Button>
                {location === "addPromoFMCG" ? (
                  <Button
                    disabled={disabledButton}
                    style={{
                      backgroundColor:
                        (disabledButton === true && "#B2C5F4") ||
                        (disabledButton === false && "#1571DE"),
                      borderRadius: 5,
                      width: 65,
                      height: 34,
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    style={{
                      borderRadius: 5,
                      width: 128,
                      height: 34,
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                    type="submit"
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </form>
          ) : (
            <form className="WrapperFormDetailsPromo" onSubmit={handleSubmit}>
              <div className="WrapperItemsInputDetailsPromo">
                <div className="ItemsInputDetailsPromo">
                  <div className="ItemInputDetailsPromo">
                    <span>Promo Name</span>
                    <MuiTextField
                      size="small"
                      placeholder="Promo Name"
                      value={values.promoName || ""}
                      onChange={handleChange("promoName")}
                      type="text"
                      required
                    />
                  </div>
                  <div className="ItemInputDetailsPromo">
                    <span>Promo Type</span>
                    <FormControl fullWidth size="small">
                      <Select
                        className={classes.selectBorder}
                        value={selectedInput.promoType || ""}
                        displayEmpty
                        onChange={(e) =>
                          setSelectedInput({
                            ...selectedInput,
                            promoType: e.target.value,
                          })
                        }
                        required
                      >
                        <MenuItem value="" disabled>
                          <Typography
                            component="span"
                            style={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#A4A4A4",
                            }}
                          >
                            Promo Type
                          </Typography>
                        </MenuItem>
                        {promoTypeArr.sort().map((option, el) => (
                          <MenuItem
                            key={el}
                            onClick={() =>
                              setSelectedInput({
                                ...selectedInput,
                                promoType: option,
                              })
                            }
                            value={option || ""}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="ItemsInputDetailsPromo">
                  <div className="ItemInputDetailsPromo">
                    <span>Status</span>
                    <FormControl fullWidth size="small">
                      <Select
                        className={classes.selectBorder}
                        value={selectedInput.status || ""}
                        displayEmpty
                        onChange={(e) =>
                          setSelectedInput({
                            ...selectedInput,
                            status: e.target.value,
                          })
                        }
                        required
                      >
                        <MenuItem value="" disabled>
                          <Typography
                            component="span"
                            style={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#A4A4A4",
                            }}
                          >
                            Store Status
                          </Typography>
                        </MenuItem>
                        {statusArr.sort().map((option, el) => (
                          <MenuItem
                            key={el}
                            onClick={() =>
                              setSelectedInput({
                                ...selectedInput,
                                status: option,
                              })
                            }
                            value={option || ""}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="ItemInputDetailsPromo">
                    {/* <span>Store Account Group Name</span> */}
                    {/* <MuiTextField
                      size="small"
                      placeholder="Group Name"
                      value={values.GroupName || ""}
                      onChange={handleChange("GroupName")}
                      type="text"
                      required
                    /> */}
                  </div>
                </div>
              </div>

              <div className="ButtonActionDetailsPromo">
                <Button
                  style={{
                    background: "#FFFFF",
                    borderRadius: 5,
                    border: "1px solid #818181",
                    width: 79,
                    height: 34,
                    textTransform: "none",
                    color: "#818181",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  onClick={() => history.push("/admin-fmcg/setup/promo")}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: "#1571DE",
                    borderRadius: 5,
                    width: 128,
                    height: 34,
                    textTransform: "none",
                    color: "#FFFFFF",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  type="submit"
                >
                  Save Changes
                </Button>
                {/* {location === "editPromoFMCG" ? (
                  <Button
                    disabled={disabledButton}
                    style={{
                      backgroundColor:
                        (disabledButton === true && "#B2C5F4") ||
                        (disabledButton === false && "#1571DE"),
                      borderRadius: 5,
                      width: 65,
                      height: 34,
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    style={{
                      borderRadius: 5,
                      width: 128,
                      height: 34,
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                    type="submit"
                  >
                    Save Changes
                  </Button>
                )} */}
              </div>
            </form>
          )}
        </div>
        <div className="WrapperTableDetailsPromo">
          <div className="HeaderTableDetailsPromo">
            <SearchbarFMCG
              value={listPromoFMCG}
              onChange={handleSearch}
              searchWidth="379px"
              placeholder="Search Product"
            />
            {location === "addPromoFMCG" ? (
              <Button
                // startIcon={<AddIcon />}
                style={{
                  textTransform: "none",
                  backgroundColor: "#1571DE",
                  width: 113,
                  height: 40,
                  color: "#FFFFFF",
                }}
                onClick={() =>
                  history.push({
                    pathname: `/admin-fmcg/setup/promo/add-product-promo`,
                    state: { location: "addProductPromo" },
                  })
                }
              >
                Add Product
              </Button>
            ) : (
              <Button
                // startIcon={<AddIcon />}
                style={{
                  textTransform: "none",
                  backgroundColor: "#1571DE",
                  width: 113,
                  height: 40,
                  color: "#FFFFFF",
                }}
                onClick={() =>
                  history.push({
                    pathname: `/admin-fmcg/setup/promo/edit-product-promo/1/add`,
                    state: { location: "editProductPromo" },
                  })
                }
              >
                Edit Product
              </Button>
            )}
          </div>
          <div className="ContainerTableDetailsPromo">
            <Card>
              <Scrollbar>
                <TableContainer>
                  <Table
                    sx={{
                      width: "100%",
                      maxHeight: "100%",
                    }}
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: 5,
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      // onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      // values={data}
                      rowCount={
                        data?.filter(
                          (el) =>
                            el.productName
                              ?.toLowerCase()
                              .includes(listPromoFMCG.toLowerCase()) ||
                            el?.size
                              ?.toLowerCase()
                              .includes(listPromoFMCG.toLowerCase()) ||
                            el.brand
                              ?.toLowerCase()
                              .includes(listPromoFMCG.toLowerCase()) ||
                            el.price
                              ?.toLowerCase()
                              .includes(listPromoFMCG.toLowerCase()) ||
                            el.status
                              ?.toLowerCase()
                              .includes(listPromoFMCG.toLowerCase())
                        ).length
                      }
                    />
                    <TableBody>
                      {stableSort(data, getComparator(order, orderBy))
                        .filter(
                          (el) =>
                            el.productName
                              ?.toLowerCase()
                              .includes(listPromoFMCG.toLowerCase()) ||
                            el.size
                              ?.toLowerCase()
                              .includes(listPromoFMCG.toLowerCase()) ||
                            el.price
                              ?.toLowerCase()
                              .includes(listPromoFMCG.toLowerCase()) ||
                            el.status
                              ?.toLowerCase()
                              .includes(listPromoFMCG.toLowerCase())
                        )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((product, ind) => {
                          // const isItemSelected = isSelected(employee?._id);
                          const labelId = `enhanced-table-checkbox-${ind}`;
                          return (
                            <TableRow
                              hover
                              key={ind}
                              tabIndex={-1}
                              id={labelId}
                              sx={{
                                "&.MuiTableRow-root:hover": {
                                  backgroundColor: "#eef6ff",
                                },
                              }}
                            >
                              <TableCell
                                padding="none"
                                style={{ paddingLeft: 10, marginRight: 10 }}
                              ></TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                // padding="none"
                              >
                                {/* <Link
                                to={{
                                  pathname: `/admin-fmcg/details-spg-store/lkshdo1hdon1owh`,
                                  state: { location: "detailsSpgStore" },
                                }}
                              > */}
                                <Stack
                                  direction="column"
                                  alignItems="left"
                                  spacing={0}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      textTransform: "capitalize",
                                      fontSize: 13,
                                      fontWeight: 500,
                                      color: "#333333",
                                    }}
                                    component="span"
                                  >
                                    {product.productName
                                      ? product.productName.toLowerCase()
                                      : "-"}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      textTransform: "capitalize",
                                      fontSize: 11,
                                      fontWeight: 400,
                                      color: "#979797",
                                    }}
                                    component="span"
                                  >
                                    {product.label
                                      ? product.label.toLowerCase()
                                      : "-"}
                                  </Typography>
                                </Stack>
                                {/* </Link> */}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: 13,
                                  fontWeight: 400,
                                  color: "#333333",
                                }}
                                component="span"
                              >
                                {product.size ? product.size : "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: 13,
                                  fontWeight: 400,
                                  color: "#333333",
                                }}
                                component="span"
                              >
                                {product.brand ? product.brand : "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: 13,
                                  fontWeight: 400,
                                  color: "#333333",
                                }}
                                component="span"
                              >
                                {product.price ? product.price : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {product.status === "active" ? (
                                  <Typography
                                    style={{
                                      backgroundColor: "#E4FFE6",
                                      color: "#17B51E",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 8,
                                      height: 26,
                                      width: 58,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#17B51E",
                                      }}
                                    >
                                      Active
                                    </Typography>
                                  </Typography>
                                ) : (
                                  <Typography
                                    style={{
                                      backgroundColor: "#FFDFDD",
                                      // color: "#17B51E",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 8,
                                      height: 26,
                                      width: 74,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#B52117",
                                      }}
                                    >
                                      Unactive
                                    </Typography>
                                  </Typography>
                                )}
                              </TableCell>
                              {/* <TableCell
                                align="left"
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: 13,
                                  fontWeight: 400,
                                  color: "#333333",
                                }}
                                component="span"
                              >
                                {product.status ? product.status : ""}
                              </TableCell> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={
                  data?.filter(
                    (el) =>
                      el.productName
                        ?.toLowerCase()
                        .includes(listPromoFMCG.toLowerCase()) ||
                      el?.size
                        ?.toLowerCase()
                        .includes(listPromoFMCG.toLowerCase()) ||
                      el?.brand
                        ?.toLowerCase()
                        .includes(listPromoFMCG.toLowerCase()) ||
                      el?.price
                        ?.toLowerCase()
                        .includes(listPromoFMCG.toLowerCase()) ||
                      el?.status
                        ?.toLowerCase()
                        .includes(listPromoFMCG.toLowerCase())
                  ).length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPromoFMCG;
