import React from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";

import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { useHistory } from "react-router";
import { AppLocationFormSA } from "../../../../components/SuperAdmin/_database";
export default function AddLocationSA() {
  const history = useHistory();

  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Add Location Data</title>
        </Helmet>
        <Breadcrumb>
          <Button
            onClick={() => history.push("/superadmin/database")}
            style={{ textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>Master Data</span>
          </Button>
          <span style={{ fontSize: 20, color: "#255bfc" }}>Add Location Data</span>
        </Breadcrumb>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} style={{padding: '20px 0'}}>
            <AppLocationFormSA />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
