/* eslint-disable no-unused-vars */

import React, { useEffect } from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppProfileEMployeeMenu } from "../../../../components/SuperAdmin/_employee";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { fetchAllEmployeeById } from "../../../../redux/actions/SuperAdmin/employeesActions";
import { ContactSupportOutlined } from "@mui/icons-material";
import ViewRequest from "../../../../components/SuperAdmin/_employee/ProfileEmployeeMenu/ViewRequet/ViewRequest";

export default function ViewRequestSA() {
    const location = useLocation();
    const currentData = location?.state?.currentData;
    const requestData = location?.state?.requestData;
    const data = location?.state?.data;

    const token = localStorage.getItem('accessToken');
    const { id } = useParams()

    const history = useHistory();
    const dispatch = useDispatch();


    return (
        <Container maxWidth="xl">
            <div className="container-client-settings">
                <Helmet>
                    <title>MyWorkSpace | View Request Edit</title>
                </Helmet>
                <div className="top__items-settings">
                    <Breadcrumb>
                        <Button
                            onClick={() => history.push("/superadmin/employee")}
                            style={{ textTransform: "none" }}
                        >
                            <span style={{ fontSize: 20, color: "#255bfc" }}>
                                Employee
                            </span>
                        </Button>
                        <Link
                            to={{
                                pathname: `/superadmin/profile-employee/${data?.employeeId}`,
                                state: { data: data },
                            }}
                        >
                            <Button>
                                <span style={{ fontSize: 20, color: "#255bfc", textTransform: 'capitalize', cursor: 'pointer' }}>{currentData?.name?.toLowerCase()}</span>
                            </Button>
                        </Link>
                        <span style={{ fontSize: 20, color: "#255bfc" }}>View Request</span>
                    </Breadcrumb>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <ViewRequest dataInfo={data} currentData={currentData} requestData={requestData} />
                    </Grid>
                </Grid>
            </div>
        </Container >
    );
}
