import * as types from '../../type'

const initialstate = {
  dataAllFaqs: [],
  dataAllFaqById: {},
  loadingAllFaqs: false,
  errorMessageFaqs: {}
};

const faqsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_FAQS_BEGIN: {
      return {
        ...state,
        loadingAllFaqs: true
      };
    }
    case types.FETCH_ALL_FAQS_SUCCESS: {
      return {
        ...state,
        loadingAllFaqs: false,
        dataAllFaqs: action.payload,
      };
    }
    case types.FETCH_ALL_FAQS: {
      return {
        ...state,
        errorMessageFaqs: action.payload,
        loadingAllFaqs: false
      };
    }
    case types.FETCH_FAQS_BY_ID: {
      return {
        ...state,
        dataAllFaqById: action.payload,
      };
    }
    default:
      return state;
  }
};

export default faqsReducer;
