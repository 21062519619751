import React from "react";
import { Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
// import { Redirect } from "react-router";
import { HeaderIntroLayout } from "../../../components/FMCG/DashboardFMCG";
import SalesOverviewTab from "../../../components/FMCG/DashboardFMCG/SalesOverviewTab/SalesOverviewTab";
import AttendanceChartFMCG from "../../../components/FMCG/DashboardFMCG/AttendanceChart/AttendanceChartFMCG";
import NewUserChart from "../../../components/FMCG/DashboardFMCG/NewUserChart/NewUserChart";
import StoreBestSeller from "../../../components/FMCG/DashboardFMCG/StoreBestSeller/StoreBestSeller";
import ProductBestSeller from "../../../components/FMCG/DashboardFMCG/ProductBestSeller/ProductBestSeller";
import NewCustomer from "../../../components/FMCG/DashboardFMCG/NewCustomer/NewCustomer";
import BestSPG from "../../../components/FMCG/DashboardFMCG/BestSPG/BestSPG";
import RecentProductPromo from "../../../components/FMCG/DashboardFMCG/RecentProductPromo/RecentProductPromo";
const DashboardFMCG = () => {
  //   const token = localStorage.getItem('accessToken');

  //   if(!token) {
  //     return <Redirect to="/"/>
  //   }
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Dashboard</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <HeaderIntroLayout />
          {/* <ReportsTab /> */}
        </Grid>

        <Grid item xs={12} md={6} lg={8}>
          <SalesOverviewTab />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <AttendanceChartFMCG />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <NewUserChart />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StoreBestSeller />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ProductBestSeller />
        </Grid>

        <Grid item xs={12} md={6} lg={8}>
          <NewCustomer />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <BestSPG />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <RecentProductPromo />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          {/* <AppOrderTimeline /> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default DashboardFMCG;
