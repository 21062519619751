/* eslint-disable no-unused-vars */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import moment from "moment";
// moment.locale("en-gb");

const useStyles = makeStyles((theme) => ({
  ItemModalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .dummy": {
      display: "hidden",
      fontSize: 0,
    },
    "& .textAbsences": {
      color: "#404040",
      fontSize: 24,
      fontWeight: 500,
    },
    "& .icon": {
      backgroundColor: "transparent",
      border: "none",
      fontSize: 25,
      cursor: "pointer",
    },
  },
  ProfileInfo: {
    "& .date": {
      display: "flex",
      flexDirection: "column",
      //   paddingTop: 15,
    },
    "& .labelDate": {
      color: "#616161",
      fontSize: 17,
      fontWeight: 500,
    },
    "& .textDate": {
      color: "#404040",
      fontSize: 20,
      fontWeight: 500,
    },
  },
  PhotoInfo: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 24,
    rowGap: 24,
    "& .label": {
      color: "#404040",
      fontSize: 17,
      fontWeight: 500,
    },
    "& .image": {
      width: 313,
      display: "block",
      marginRight: "auto",
      marginLeft: "auto",

      // "&:hover" : {
      //   transform: 'scale(1.3)',
      // }
    },
  },
}));

export default function DialogSick({ showSick, setShowSick }) {
  const classes = useStyles();
  const handleClickOpen = () => {
    setShowSick(true);
  };
  const handleClose = () => {
    setShowSick(false);
  };

  // console.log(showSick, 'cek data sick')
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Boolean(showSick)}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className={classes.ItemModalTop}>
            <h6 className="dummy">dummy</h6>
            <div className="textAbsences">
              <span className="textAbsences">Sick Details</span>
            </div>
            <button onClick={handleClose} className="icon">
              <CloseIcon
                style={{ fontSize: 30, marginTop: 10, paddingRight: 5 }}
              />
            </button>
          </div>
          <div>
            <hr style={{ width: "100%", color: "#ECE6E6" }} />
          </div>
        </DialogTitle>

        <DialogContent>
          <div className={classes.ProfileInfo}>
            <div className="date">
              <label className="labelDate">Date</label>
              <span className="textDate">{ moment(showSick?.startTime).format("LL") }</span>
            </div>
          </div>
          <div className={classes.PhotoInfo}>
            <label className="label">Foto Surat Sakit</label>
            <img src={ showSick.Photo  ? showSick.Photo : "https://via.placeholder.com/400"} alt="" className="image" id="sickPhotoDetail"/>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
