import axios from "axios";
import {API} from "../../api";
import * as types from '../../typeExternal';


export const fetchAllEmployeesWithoutWorkOn = (token, clientCode) => {
    return (dispatch) => {
      dispatch({ type: types.FETCH_ALL_EMPLOYEE_WORK_ON_BEGIN })
      axios
        .get(`${API}/user/user-client/${clientCode}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          dispatch({
            type: types.FETCH_ALL_EMPLOYEE_WORK_ON_SUCCESS,
            payload: res.data.response,
          });
        })
        .catch((err) => {
          dispatch({ type: types.FETCH_ALL_EMPLOYEE_WORK_ON_ERROR, payload: err.response.error })
        });
    };
  };


// export const fetchWorkOnExternal = (token, )