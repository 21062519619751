import React from "react";
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
} from "recharts";
import "./AttendanceChartFMCG.css";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
];

const dataReports = [
  { name: "Presence", total: 32 },
  { name: "Sick", total: 20 },
  { name: "Permit", total: 15 },
];

const COLORS = ["#1571DE", "#D8DBE5", "#EBECF2"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const AttendanceChartFMCG = () => {
  return (
    <div className="ContainerAttendanceChart">
      <div className="WrapperAttendanceChart">
        <div className="HeaderAttendanceChart">
          <span>Attendance</span>
        </div>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Pie
              data={data}
              cx="50%"
              cy="40%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              // fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className="ReportAttendanceChart">
          {dataReports.map((el, idx) => (
            <div
              className="BorderItemReportAttendance"
              key={idx}
              style={{
                backgroundColor:
                  (el.name === "Presence" && "#1571DE") ||
                  (el.name === "Sick" && "#EBECF2") ||
                  (el.name === "Permit" && "#D8DBE5"),
              }}
            >
              <div className="ReportAttendanceText">
                <span style={{ color: '#6F6F70', fontSize: 13, fontWeight: 400}}>{el.name}</span>
                <span style={{ color: '#000000', fontSize: 13, fontWeight: 500}}>{el.total}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AttendanceChartFMCG;
