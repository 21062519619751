import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
  } from "react";
import Styles from "./FaqForm.module.css";
import { Button} from "@mui/material";
import { useDropzone } from "react-dropzone";
import VIDEO_TYPE from "../../../../../../assets/images/video-player-1.png";
import ARTICLE_TYPE from "../../../../../../assets/images/file-type.png";
import DELETE_ICON from "../../../../../../assets/images/delete-rules-icon.png";

import PublishIcon from "@mui/icons-material/Publish";
import { useHistory } from "react-router-dom";
import { addFAQ } from "../../../../../../async/faq";
import ModalSuccessFAQ from "../ModalSuccessFAQ/ModalSuccessFAQ";


export default function FaqForm() {
    const history = useHistory();
    // eslint-disable-next-line no-unused-vars
    const [selected, setSelected] = useState([]);
    // const [selectedCode, setSelectedCode] = useState([]);
    const [active, setActive] = useState("Video");
    // const [editorState, setEditorState] = useState({
    //   valueEditor: EditorState.createEmpty(),
    // });
    const [value, setValue] = useState({
      title: "",
      clientCode: "ALL",
      content: "",
      image: null,
      imageVideo: null,
      isPublish: "",
      url: "",
      description: ""
    });
    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalDraft, setModalDraft] = useState(false);
    const [files, setFiles] = useState([]);
    const [preview, setPreview] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [previewName, setPreviewName] = useState("");
  
    const inputFileRefImageVideo = useRef();
    const [previewImageVideo, setPreviewImageVideo] = useState(null);
    const [previewNameImageVideo, setPreviewNameImageVideo] = useState("");
  
  
    // useEffect(() => {
    //   if (location === "editInduction") {
    //     setValue(data);
    //     setActive(data?.type);
    //     setSelectedClients(refinedClientCode)
    //     setEditorState((prev) => ({
    //       ...prev,
    //       editorState: EditorState.createWithContent(ContentState.createFromText(data?.content))
    //     }))
    //     setEditorState((prev) => ({
    //       ...prev,
    //       valueEditor: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data?.content)))
    //     }))
    //   } else if (pathname === "addInduction"){
    //     setActive("")
    //     setValue(null);
    //     setActive(null);
    //     setSelectedClients([])
    //   } else if (data?.isPublish === false ) {
    //     setValue(data);
    //     setActive(data?.type);
    //     setSelectedClients(refinedClientCode)
    //   }
    // }, [data,refinedClientCode,pathname]);
  
    // useEffect(() => {
    //   const state = data?.content
    //     ? EditorState.createWithContent(convertFromRaw(JSON.parse(data?.content)))
    //     : EditorState.createEmpty();
    //   setEditorState(state);
    // }, [data?.content]);
  
    const onDrop = useCallback(
      (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
  
        if (active === "Article") {
          setValue((prev) => ({
            ...prev,
            // eslint-disable-next-line no-useless-computed-key
            ["image"]: acceptedFiles,
          }));
  
          setPreview(URL.createObjectURL(acceptedFiles[0]));
          setPreviewName(acceptedFiles[0].name);
        }
  
        if (active === "Video") {
          setValue((prev) => ({
            ...prev,
            // eslint-disable-next-line no-useless-computed-key
            ["imageVideo"]: acceptedFiles,
          }));
  
          setPreviewImageVideo(URL.createObjectURL(acceptedFiles[0]));
          setPreviewNameImageVideo(acceptedFiles[0].name);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );
  
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: "image/jpeg, image/png",
      multiple: false,
    });
  
    // const handleSelectedClient = (e, value) => {
    //   e.preventDefault();
    //   setSelected(value);
  
    //   const isExist = selectedClients.find((item) => item?.name === value?.name);
    //   const doesContainAll = selectedClients.some(
    //     (item) => item?.codes === "ALL"
    //   );
    //   let isAll = Boolean(value?.codes === "ALL" && value?.name === "ALL");
    //   const isEmpty = selectedClients.length === 0;
  
    //   if (!isExist && !isAll && !doesContainAll) {
    //     let temp = selectedClients.concat(value);
    //     setSelectedClients(temp);
    //   } else if (isExist) {
    //     const x = selectedClients.filter((item) => item !== value);
    //     setSelectedClients(x);
    //   } else if (isEmpty && isAll) {
    //     let temp = selectedClients.concat(value);
    //     setSelectedClients(temp);
    //   } else if (!isExist && doesContainAll) {
    //     setSelectedClients(selectedClients);
    //   }
    // };
  
    const onChangeValue = (e) => {
      setValue((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    };
  
    // const onChangeEditor = (editorState) => {
    //   setEditorState((prev) => ({
    //     ...prev,
    //     valueEditor: editorState,
    //   }));
    // };
  
    // let refinedData = `${draftToHtml(
    //   convertToRaw(editorState?.valueEditor?.getCurrentContent())
    // )}`;
  
    const handleSubmit = async (e) => {
      if (value?.url && active === "Video") {
        try {
          e.preventDefault();
          let formData = new FormData();
          formData.append("title", value?.title);
          formData.append("url", value?.url);
          formData.append("images",value?.imageVideo[0],value.imageVideo[0]?.name);
          formData.append("isPublish", true);
  
          for (const pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1], "data");
          }
  
          await addFAQ(formData);
          setModalSuccess(true);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          e.preventDefault();
          const formData = new FormData();
          formData.append("title", value?.title);
          formData.append("content", value?.description);
          // formData.append("url", value?.url);
          formData.append("isPublish", true);
          // formData.append("isDraft", false);
          // for (let i = 0; i < selectedClients.length; i++) {
          //   formData.append("clientCode", selectedClients[i].codes);
          // }
          // formData.append("type", active);
          // formData.append(
          //   "images",
          //   value?.image[0],
          //   value.image[0]?.name
          // );
  
          await addFAQ(formData);
          setModalSuccess(true);
  
          for (const pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1], "data");
          }
        } catch (error) {
          console.log(error);
        }
        // const data = {
        //   title: value?.title,
        //   content: refinedData,
        //   url: value?.url,
        //   isPublish: true,
        //   clientCode: selectedClients.map((el) => el.codes),
        //   type: active,
        // };
        // addInduction(token, data);
        // history.push(`/superadmin/induction`);
        // console.log(data, " yooo");
      }
    };
  
    const handleSaveToDraft = async (e) => {
      if (value?.url) {
        try {
          e.preventDefault();
          let formData = new FormData();
          formData.append("title", value?.title);
          formData.append("url", value?.url);
          formData.append("images",value?.imageVideo[0],value.imageVideo[0]?.name);
          formData.append("isPublish", false);
          // formData.append("isDraft", true);

          // for (let i = 0; i < selectedClients.length; i++) {
          //   formData.append("clientCode", selectedClients[i].codes);
          // }
          // formData.append(
          //   "clientCode",
          //   JSON.stringify(selectedClients.map((el) => el.codes))
          // );
          // formData.append("type", active);
  
          await addFAQ(formData);
          setModalDraft(true);
        //   await addInduction(token, formData);
          for (const pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1], "data");
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          e.preventDefault();
          const formData = new FormData();
          formData.append("title", value?.title);
          formData.append("content", value?.description);
          formData.append("isPublish", false);
          // formData.append("isDraft", true);
          // formData.append(
          //   "clientCode",
          //   JSON.stringify(selectedClients.map((el) => el.codes))
          // );
          // formData.append("type", active);
          await addFAQ(formData);
          setModalDraft(true);
          // history.push(`/superadmin/induction`);
          for (const pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1], "dataa");
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
  
    // const handleEditData = (e) => {
    //   setValue((prev) => ({
    //     ...prev,
    //     [e.target.name]: e.target.type === "file" ? e.target.files : e.target.value,
    //   }));
  
    //   if (e.target.type === "file" && active === "Article") {
    //     let url = URL.createObjectURL(e.target.files[0]);
    //     setPreview(url);
    //     setPreviewName(e.target.files[0].name);
    //   }
    //   if (e.target.type === "file" && active === "Video") {
    //     let url = URL.createObjectURL(e.target.files[0]);
    //     setPreviewImageVideo(url);
    //     setPreviewNameImageVideo(e.target.files[0].name);
    //   }
    // };
  
    const handleChangeImageVideo = (e) => {
      setValue((prev) => ({
        ...prev,
        [e.target.name]:
          e.target.type === "file" ? e.target.files : e.target.value,
      }));
      if (e.target.files && e.target.files[0]) {
        let img = URL.createObjectURL(e.target.files[0]);
        setPreviewImageVideo(img);
        setPreviewNameImageVideo(e.target.files[0].name);
      }
    };
  
    // const handleChangeImage = (e) => {
    //   setValue((prev) => ({
    //     ...prev,
    //     [e.target.name]:
    //       e.target.type === "file" ? e.target.files : e.target.value,
    //   }));
    //   if (e.target.files && e.target.files[0]) {
    //     let img = URL.createObjectURL(e.target.files[0]);
    //     setPreview(img);
    //     setPreviewName(e.target.files[0].name);
    //   }
    // };
  
    // const handleDeleteImage = (e) => {
    //   inputFileRef.current.value = null;
    //   setPreview(null);
    //   setPreviewName("");
    //   setValue((prev) => ({
    //     ...prev,
    //     // eslint-disable-next-line no-useless-computed-key
    //     ["image"]: null,
    //   }));
    // };
  
    const handleDeleteImageVideo = (e) => {
      inputFileRefImageVideo.current.value = null;
      setPreviewImageVideo(null);
      setPreviewNameImageVideo("");
      setValue((prev) => ({
        ...prev,
        // eslint-disable-next-line no-useless-computed-key
        ["imageVideo"]: null,
      }));
    };
  
    useEffect(() => {
      return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [files]);
  
    useEffect(() => {}, [active]);
  return (
    <div className={Styles.Container}>
      <form className={Styles.Content} onSubmit={handleSubmit}>
        <div className={Styles.ValueControl}>
          {/* <div className={Styles.Value}>
            <span>Client</span>
            <FormControl size="small" fullWidth>
              <Autocomplete
                disablePortal
                value={null}
                blurOnSelect={true}
                disableCloseOnSelect
                options={fixedClients}
                getOptionLabel={(option) => option?.name}
                sx={{ width: "100%" }}
                onChange={(e, value) =>
                  value === null
                    ? handleSelectedClient(e, null)
                    : handleSelectedClient(e, value)
                }
                renderOption={(props, option) => {
                  const isItemSelected = isSelected(option);
                  return (
                    <li
                      {...props}
                      onClick={(e) => e.preventDefault()}
                      key={option?.codes}
                      style={{
                        borderBottom: "1px solid #c2c2c2",
                        padding: "12px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <span className={Styles.ClientOption}>
                          {capitalizedClientName(option?.name)}
                        </span>
                        <Checkbox
                          onClick={(e) => handleSelectedClient(e, option)}
                          sx={{ margin: 0, padding: 0 }}
                          checked={isItemSelected}
                          icon={
                            <RadioButtonUnchecked sx={{ color: "#c2c2c2" }} />
                          }
                          checkedIcon={
                            <RadioButtonChecked sx={{ color: "#1571DE" }} />
                          }
                        />
                      </div>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    size="small"
                    sx={{ background: "var(--main-bg)" }}
                    placeholder="Select company"
                  />
                )}
              />
            </FormControl>
          </div> */}

          <div className={Styles.Value}>
            <span>Title</span>
            <input
              type="text"
              name="title"
              value={value?.title}
              onChange={onChangeValue}
              placeholder="Type title here"
              required
            />
          </div>
        </div>

        {/* {selectedClients?.length > 0 && (
          <div className={Styles.CompanyControl}>
            {selectedClients.map((each, idx) => (
              <div className={Styles.CompanyValue} key={idx}>
                <img src={COMPANY_ICON} alt="icon-client" />
                <span>{each?.name}</span>
                <Cancel
                  sx={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() => handleDeleteClient(each)}
                />
              </div>
            ))}
          </div>
        )} */}

        {/* <div className={Styles.ValueControlContent}>
          <div className={Styles.Value}>
            <span>Content</span>
            <textarea
              type="text"
              name="content"
              onChange={ onChangeValue }
              placeholder="Description of content"
            />
          </div>
        </div> */}

        {/* {active === "Article" && (
          <div className={Styles.ValueControlContent}>
            <div className={Styles.Value}>
              <span>Content</span>
              <textarea
                type="text"
                name="content"
                onChange={onChangeValue}
                placeholder="Description of content"
              />
            </div>
          </div>
        )} */}

        <div className={Styles.ValueInductionControl}>
          <div className={Styles.Value}>
            <span>Select Induction Type</span>
          </div>

          <div className={Styles.Selection}>
            <div
              onClick={() => setActive("Video")}
              className={`${active === "Video" ? Styles.Active : Styles.Type}`}
            >
              <img src={VIDEO_TYPE} alt="video-type" />
              <span>Create FAQ by Movie</span>
            </div>

            <div
              onClick={() => setActive("Article")}
              className={`${
                active === "Article" ? Styles.Active : Styles.Type
              }`}
            >
              <img src={ARTICLE_TYPE} alt="article-type" />
              <span>Create FAQ by Text</span>
            </div>
          </div>
        </div>
{/* 
        {active === "Article" && (
          <div className={Styles.ContainerUploadImage}>
            <div {...getRootProps()} className={Styles.WrapperUploadImage}>
              <input
                {...getInputProps()}
                type="file"
                ref={inputFileRef}
                name="image"
                onChange={handleChangeImage}
                hidden
              />
              {preview ? (
                <div
                  style={{
                    width: "100%",
                    margin: 0,
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: 30,
                  }}
                >
                  <img
                    src={preview}
                    style={{
                      width: "245px",
                      height: "158px",
                      objectFit: "cover",
                      borderRadius: 8,
                    }}
                    alt={preview}
                    onClick={() => inputFileRef.current.click()}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                        fontSize: "14px",
                      }}
                    >
                      <span>{previewName}</span>
                      <Button
                        type="button"
                        style={{
                          border: "1px solid #1571DE",
                          color: "#1571DE",
                          width: 120,
                          height: 40,
                          padding: 10,
                          borderRadius: 8,
                          fontSize: "14px",
                        }}
                        onClick={() => inputFileRef.current.click()}
                      >
                        Change File
                      </Button>
                    </span>
                    <img
                      src={DELETE_ICON}
                      onClick={handleDeleteImage}
                      alt="delete"
                      style={{
                        width: "18px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={Styles.BoxInputImage}>
                  <span>Drag & Drop File Here</span>
                  <div className={Styles.BoxInputFile}>
                    <span>File must JPG or PNG</span>
                  </div>
                  <div className={Styles.BoxInputOr}>
                    <span> OR </span>
                  </div>
                  <div className={Styles.ContainerInputImage}>{}</div>
                  <div className={Styles.BoxInputButton}>
                    <Button onClick={() => inputFileRef.current.click()}>
                      <span>Browse File</span>
                      <PublishIcon />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )} */}

        {active === "Video" && (
          <div className={Styles.ContainerUploadImage}>
            <div {...getRootProps()} className={Styles.WrapperUploadImage}>
              <input
                {...getInputProps()}
                type="file"
                ref={inputFileRefImageVideo}
                name="imageVideo"
                onChange={handleChangeImageVideo}
                hidden
              />
              {previewImageVideo ? (
                <div
                  style={{
                    width: "100%",
                    margin: 0,
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: 30,
                  }}
                >
                  <img
                    src={previewImageVideo}
                    style={{
                      width: "245px",
                      height: "158px",
                      objectFit: "cover",
                      borderRadius: 8,
                    }}
                    alt={preview}
                    onClick={() => inputFileRefImageVideo.current.click()}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                        fontSize: "14px",
                      }}
                    >
                      <span>{previewNameImageVideo}</span>
                      <Button
                        type="button"
                        style={{
                          border: "1px solid #1571DE",
                          color: "#1571DE",
                          width: 120,
                          height: 40,
                          padding: 10,
                          borderRadius: 8,
                          fontSize: "14px",
                        }}
                        onClick={() => inputFileRefImageVideo.current.click()}
                      >
                        Change File
                      </Button>
                    </span>
                    <img
                      src={DELETE_ICON}
                      onClick={handleDeleteImageVideo}
                      alt="delete"
                      style={{
                        width: "18px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={Styles.BoxInputImage}>
                  <span>Drag & Drop Thumbnail Here</span>
                  <div className={Styles.BoxInputFile}>
                    <span>File must JPG or PNG</span>
                  </div>
                  <div className={Styles.BoxInputOr}>
                    <span> OR </span>
                  </div>
                  <div className={Styles.ContainerInputImage}>{}</div>
                  <div className={Styles.BoxInputButton}>
                    <Button
                      onClick={() => inputFileRefImageVideo.current.click()}
                    >
                      <span>Browse File</span>
                      <PublishIcon />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {active === "Video" ? (
          <div className={Styles.GroupControl}>
            <span>Link URL</span>
            <input
              type="text"
              value={value?.url}
              name="url"
              onChange={onChangeValue}
              required
            />
          </div>
        ) : (
          <div className={Styles.GroupDraft}>
            {/* <Editor
              required
              editorState={editorState.valueEditor}
              defaultEditorState={editorState.valueEditor}
              onEditorStateChange={onChangeEditor}
              editorClassName={Styles.EditorClassName}
            /> */}
            {/* <textarea
              disabled
              value={draftToHtml(
                convertToRaw(editorState?.valueEditor?.getCurrentContent())
              )}
              hidden
            /> */}
            <span>Description</span>
            <textarea 
              type="text"
              required
              name="description"
              value={value?.description}
              onChange={onChangeValue}
            />
          </div>
        )}

        {/* {(active === "Article" || data?.type === "Article") && (
          <div className={Styles.GroupDraft}>
            <Editor
              defaultEditorState={editorState.valueEditor}
              wrapperClassName="demo-wrapper"
              onEditorStateChange={onChangeEditor}
              toolbarClassName="toolbarClassName"
              editorClassName={Styles.EditorClassName}
            />
            <textarea
              disabled
              value={draftToHtml(
                convertToRaw(editorState?.valueEditor?.getCurrentContent())
              )}
              hidden
            />
          </div>
        )} */}

        <div className={Styles.ActionButton}>
          <Button onClick={handleSaveToDraft}>Save to draft</Button>
          <Button type="submit">Publish</Button>
          <Button onClick={() => history.push(`/superadmin/faq`)}>
            Cancel
          </Button>
        </div>
      </form>
      {modalSuccess && (
        <ModalSuccessFAQ
          onOpen={setModalSuccess}
          history={history}
          title="Success Published"
          text="You have send one information to the client"
        />
      )}
      {modalDraft && (
        <ModalSuccessFAQ
          history={history}
          onOpen={setModalDraft}
          title="Saved to Draft"
          text="You have saved one information to draft"
        />
      )}
    </div>
  )
}
