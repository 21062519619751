import axios from "axios";
import {API} from "../../redux/api";

const fetchClientLoanScheme = (token, id) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(`${API}/staff-loan-plan/${id}`, {
        headers : {
          Authorization: token
        }
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export default fetchClientLoanScheme;
