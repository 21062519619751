import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Button, Container, Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import fetchLeavePlan from "../../../../../async/leavePlan/fetchLeavePlan";
import {
  getClientById,
  updateClient,
} from "../../../../../redux/actions/SuperAdmin/adminActions";
import Breadcrumb from "../../../../utils/Breadcrumbs";
import LoadingAnimation from "../../../../utils/LoadingAnimation/LoadingAnimation";
import { LeaveDetailModal } from "../../../_leaveSchema";
import SavedSetModal from "../../ClientProfileMenu/SavedSetModal/SavedSetModal";
import Styles from "./SetLeaveSchema.module.css";

export default function SetLeaveSchema() {
  const token = localStorage.getItem("accessToken");
  const { clientById } = useSelector((state) => state.client);
  const history = useHistory();
  const { id } = useParams();
  const [savedSet, setSavedSet] = useState(false);
  const [selectedScheme, setSelectedScheme] = useState("");
  const [leaveSchema, setLeaveSchema] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [selectedData, setSelectedData] = useState(false);

  const defaultSchemeId = useMemo(() => {
    return clientById?.feature?.leavePlanId;
  }, [clientById]);

  useEffect(() => {
    setSelectedScheme(defaultSchemeId);
  }, [defaultSchemeId]);

  const handleSave = (e) => {
    e.preventDefault();
    let body = {
      feature: {
        pawnPlan: clientById?.feature?.pawnPlan,
        attendancePlanId: clientById?.feature?.attendancePlanId,
        staffLoanPlanId: clientById?.feature?.staffLoanPlanId,
        carLoanPlanId: clientById?.feature?.carLoanPlanId,
        mdPlanId: clientById?.feature?.mdPlanId,
        spgPlanId: clientById?.feature?.spgPlanId,
        activityPlanId: clientById?.feature?.activityPlanId,
        promotorPlanId: clientById?.feature?.promotorPlanId,
        leavePlanId: selectedScheme,
      },
    };

    dispatch(updateClient(token, clientById?._id, body));
    setSavedSet(true);
  };

  const handleGetData = async () => {
    try {
      setLoading(true);
      const data = await fetchLeavePlan(token);
      setLeaveSchema(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(getClientById(token, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = (e) => {
    e.preventDefault();
    history.push("/superadmin/profile-clients/" + id);
  };

  const capitalizedClientName = (clientName) => {
    return clientName
      ?.toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .replace("Pt", "PT")
      .replace("Cv", "CV");
  };


  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Client Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb aria-label="breadcrumb">
            <Button
              onClick={() => history.push("/superadmin/client")}
              style={{ border: "none", textTransform: "none", width: "100%" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>
                Client Settings
              </span>
            </Button>
            <Button
              onClick={() => history.push("/superadmin/profile-clients/" + id)}
              style={{ border: "none", textTransform: "none", width: "100%" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>
                {capitalizedClientName(clientById?.name)}
              </span>
            </Button>
            <span style={{ fontSize: 20, color: "#255bfc" }}>Leave Scheme</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={Styles.Container}>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 50,
                  }}
                >
                  <div style={{ width: 200 }}>
                    <LoadingAnimation />
                  </div>
                </div>
              ) : (
                <>
                  <div className={Styles.CardContainer}>
                    {leaveSchema.map((each, i) => (
                      <div key={i} className={Styles.Card}>
                        <div className={Styles.CardHeader}>
                          <div className={Styles.CardLogo}>
                            <i className="bx bxs-calendar-star"></i>
                          </div>
                          <span>{each?.name}</span>
                        </div>
                        <div className={Styles.CardContent}>
                          <div className={Styles.CardDesc}>
                            {/* <p>{each?.period}</p> */}
                            <p>{`${each.periodStart} - ${each.periodNext}`}</p>
                            <p>
                              Total leave • <span>{each?.initialBalance} Days</span>
                            </p>
                          </div>
                          {selectedScheme === each?._id ? (
                            <RadioButtonChecked
                              sx={{ color: "#1571DE", cursor: "pointer" }}
                            />
                          ) : (
                            <RadioButtonUnchecked
                              onClick={() => setSelectedScheme(each?._id)}
                              sx={{ color: "#c2c2c2", cursor: "pointer" }}
                            />
                          )}
                        </div>
                        <div className={Styles.CardFooter}>
                          <span
                            // onClick={() => {
                            //   // dispatch(fetchSchemaLoanById(token, loan._id));
                            //   history.push({
                            //     pathname: `/superadmin/client-set/${client?._id}/attendance-view/${each?._id}`,
                            //     state: {
                            //       dataSchema: each,
                            //     },
                            //   });
                            // }}
                            // onClick={() => {
                            //   setOpenModal("detail");
                            //   setSelectedData({
                            //     dayoff: each.dayoff,
                            //     startPeriod: each.period.split("-")[0],
                            //     endPeriod: each.period.split("-")[1],
                            //     nextYear: each.remark === "NEXT",
                            //     id: each._id,
                            //   });
                            // }}
                            onClick={() =>
                              history.push(
                                `/superadmin/leave-scheme-view/${each._id}`
                              )
                            }
                          >
                            View Settings
                          </span>
                          <ArrowRightAltIcon
                            style={{ color: "#1571DE", marginLeft: 8 }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={Styles.ButtonWrapper}>
                    <button onClick={handleSave} className={Styles.SaveButton}>
                      Save
                    </button>
                    <button
                      onClick={handleCancel}
                      className={Styles.CancelButton}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}

              {savedSet && <SavedSetModal setSavedSet={setSavedSet} id={id} />}
            </div>
          </Grid>
        </Grid>
      </div>
      <LeaveDetailModal
        open={openModal}
        setOpen={setOpenModal}
        data={selectedData}
        isControlled={false}
      />
    </Container>
  );
}
