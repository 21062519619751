import React from "react";
import { Helmet } from "react-helmet";
import { TableProductsFMCG } from "../../../../components/FMCG";

export default function TableProducts() {
  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Setup</title>
      </Helmet>
      <TableProductsFMCG />
    </div>
  );
}
