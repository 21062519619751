import React from "react";
import { EmployeeAssignStore } from "../../../components/FMCG";

export default function EmployeesAssignStoreFMCG() {
  return (
    <div>
      <EmployeeAssignStore />
    </div>
  );
}
