/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import Styles from "./Styles.module.css";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  DialogContent,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { AddCircleOutline, Close } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export default function ModalDayOff({
  setOpen,
  open,
  value,
  setValue,
  handleSubmit,
  id,
  data,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const selectedData = useMemo(() => {
    const selected = data?.holiday?.map((item) => item)?.filter((res) => res._id === id);
    return selected?.[0];
  }, [data?.holiday, id]);
  // console.log(id, '<<<<')
  // console.log(data?.holiday?.map(item => item)?.filter( res => res._id === id ), 'data');

  useEffect(() => {
    if (data) {
      setValue(data)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close />
      </IconButton>
      <DialogTitle>Create new date</DialogTitle>
      <DialogContent dividers>
        <form className={Styles.FormControl}>
          <div className={Styles.InputControl}>
            <p>Date</p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={value.date}
                  name="date"
                  onChange={(e) =>
                    handleChange({
                      target: { value: e, name: "date" },
                    })
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" width="100%" />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div className={Styles.InputControl}>
            <p>Holiday Type</p>
            <FormControl fullWidth size="small" disabled>
              <Select
                displayEmpty
                id="demo-simple-select"
                value={value.type}
                name="type"
                onChange={handleChange}
                placeholder=""
              >
                <MenuItem disabled value={value?.type}>
                  {value?.type}
                </MenuItem>
                {/* {remarkList.map((el, idx) => (
                  <MenuItem key={idx} value={el}>
                    {el}
                  </MenuItem>
                ))} */}
              </Select>
            </FormControl>
          </div>
          <div className={Styles.InputControl}>
            <p>Descriptions</p>
            <textarea
              value={value?.description}
              name="description"
              onChange={handleChange}
              required
            />
            {/* <FormControl fullWidth size="small">
              <Select
                displayEmpty
                id="demo-simple-select"
                value={value.remark}
                name="remark"
                onChange={handleChange}
                placeholder=""
              >
                <MenuItem disabled value="">
                  Select descriptions
                </MenuItem>
                {remarkList.map((el, idx) => (
                  <MenuItem key={idx} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
          <Button
            // onClick={() => addDayoff()}
            startIcon={<AddCircleOutline />}
            variant="contained"
            className={Styles.AddButton}
            sx={{ textTransform: "capitalize", marginBottom: "20px" }}
            onClick={() => handleSubmit()}
            // disabled={!value.description}
          >
            Add Holiday
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const remarkList = ["LIBUR NASIONAL", "CUTI BERSAMA"];
