import {
  ArrowDownward,
  ArrowUpward,
  Close,
  Event,
  PinDrop,
} from "@mui/icons-material";
import { Dialog, IconButton } from "@mui/material";
import StatusIcon from "../../../../assets/images/status-icon.png";
import Styles from "./Style.module.css";
import MiniStaticMap from "../../../_mapWithAutoComplete/MiniMap/MiniMap";
import moment from "moment";

const modalStyle = {
  "@media screen and (max-width: 1000px)": {
    display: "none",
  },
};

export default function AbsenceDetailModal({
  selectedAbsence,
  setSelectedAbsence,
}) {
  const handleClose = () => {
    setSelectedAbsence(null);
  };

  const coordinate = {
    Latitude: -6.2420764,
    Longitude: 106.7893877,
  };

  // console.log(coordinate);
  return (
    <Dialog open={Boolean(selectedAbsence)} maxWidth='sm' fullWidth sx={modalStyle}>
      <div className={Styles.Container}>
        <div className={Styles.Header}>
          <span>Absence Detail</span>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        <div className={Styles.Content}>
          <div className={Styles.BoxContent}>
            <div className={Styles.BoxLeft}>
              <Event className={Styles.Icon} />
              <span>Date</span>
            </div>
            <div className={Styles.BoxRight}>
              <span>{moment(selectedAbsence?.date).format("DD MMMM YYYY")}</span>
            </div>
          </div>
          <div className={Styles.BoxContent}>
            <div className={Styles.BoxLeft}>
              <ArrowDownward className={Styles.Icon} />
              <span>Time In</span>
            </div>
            <div className={Styles.BoxRight}>
              <span>{selectedAbsence?.startTime || "-"}</span>
            </div>
          </div>
          <div className={Styles.BoxContent}>
            <div className={Styles.BoxLeft}>
              <ArrowUpward className={Styles.Icon} />
              <span>Time Out</span>
            </div>
            <div className={Styles.BoxRight}>
              <span>{selectedAbsence?.endTime || "-"}</span>
            </div>
          </div>
          <div className={Styles.BoxContent}>
            <div className={Styles.BoxLeft}>
              <img src={StatusIcon} alt="" className={Styles.IconImg} />
              <span>Status</span>
            </div>
            <div className={Styles.BoxRight}>
              <span>{selectedAbsence?.type}</span>
              {selectedAbsence?.image && (
                <div className={Styles.AbsenceImageContainer}>
                  <img src={selectedAbsence?.image} alt="absence" />
                </div>
              )}
            </div>
          </div>
          <div className={Styles.BoxContent}>
            <div className={Styles.BoxRight}></div>
          </div>
          <div className={Styles.BoxContent}>
            <div className={Styles.BoxLeft}>
              <PinDrop className={Styles.Icon} />
              <span>Location In</span>
            </div>
            <div className={Styles.BoxRight}>
              {coordinate?.Latitude !== null &&
              coordinate?.Longitude !== null ? (
                <>
                  <div className={Styles.LocationHeader}></div>
                  <div className={Styles.MapContainer}>
                    <MiniStaticMap coordinate={coordinate} />
                  </div>
                </>
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
          <div className={Styles.BoxContent}>
            <div className={Styles.BoxLeft}>
              <PinDrop className={Styles.Icon} />
              <span>Location Out</span>
            </div>
            <div className={Styles.BoxRight}>
              {coordinate?.Latitude !== null &&
              coordinate?.Longitude !== null ? (
                <>
                  <div className={Styles.LocationHeader}></div>
                  <div className={Styles.MapContainer}>
                    <MiniStaticMap coordinate={coordinate} />
                  </div>
                </>
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
