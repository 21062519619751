/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import Styles from './ClientMenu.module.css'
import { useDispatch, useSelector } from "react-redux";
// import "./ClientMenu.css";
import { useStyles } from "./styles";
import company_logo from "../../../../assets/images/LogoClient.png";
import bm_pict from "../../../../assets/images/bm-pict.png";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import {
  Typography,
  Stack,
  TablePagination,
  Autocomplete,
  TextField,
  styled,
  TableSortLabel,
  Box,
  TableHead,
  Popover,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { fetchAllClients, fetchAllClientSet } from "../../../../redux/actions/SuperAdmin/adminActions";
import UserListHead from "../../_user/TableUsers/UserListHead";
import Scrollbar from "../../_user/TableUsers/Scrollbar";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import { UseCompanyName } from "../../../utils/hooks";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import { visuallyHidden } from "@mui/utils";
import CustomTablePagination from "../../../_pagination/CustomTablePagination";


const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "businessManagers", label: "Business Manager", alignRight: false },
  { id: "regions", label: "Region", alignRight: false },
  { id: "group", label: "Group", alignRight: false },
  { id: "" },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // if (query) {
  //   return filter(
  //     array,
  //     (_user) => _user.Name.toLowerCase().indexOf(query.toLowerCase()) !== -1
  //   );
  // }
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>

        {TABLE_HEAD.map((headCell, index) => (
          <TableCell
            key={index + Math.random()}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ClientMenu() {
  const companyName = UseCompanyName();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loadingClientsData, clients } = useSelector((state) => state.client);
  const token = localStorage.getItem("accessToken");
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [selected, setSelected] = useState("");
  const [filterName, setFilterName] = useState("");
  const [filterBM, setFilterBM] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isActive, setIsActive] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopover, setOpenedPopover] = useState(null);



  const options = ["PT Permata Indo Sejahtera", "PT Apple Inc", "PIS"];
  // ----------------------------------------------------------------------
  // console.log(">>>>", client);
  useEffect(() => {
    dispatch(fetchAllClients(token));
  }, [dispatch, token]);




  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilterByName = (event) => {
    setPage(1)
    setFilterName(event.target.value);
    setFilterBM(event.target.value);
  };
  const handleSubmitFilter = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePopover = (e, id) => {
    setAnchorEl(e.currentTarget);
    setOpenedPopover(id)
  }

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopover(null)
  }

  const filteredClients = stableSort(clients, getComparator(order, orderBy));

  const capitalizedClientName = (clientName) => {
    return clientName?.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace('Pt', 'PT')
      .replace('Cv', 'CV')
  }


  return (
    <div className={Styles.Container}>
      <div className={Styles.Header}>
        <div className={Styles.Searchbar}>
          <SearchbarSA
            value={filterName}
            onChange={handleFilterByName}
            searchWidth="379px"
            placeholder="Search client"
          />
        </div>
      </div>
      {loadingClientsData ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 50,
          }}
        >
          <div style={{ width: 200 }}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={Styles.TableWrapper}>
          <Scrollbar>
            <TableContainer className={Styles.TableContainer}>
              <Table id="emp-table">
                <colgroup>
                  <col style={{ width: '25%%' }} />
                  <col style={{ width: '25%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '18%' }} />
                  <col style={{ width: '12%' }} />
                </colgroup>
                <EnhancedTableHead
                  numSelected={clients?.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  values={clients}
                />
                <TableBody>
                  {stableSort(clients, getComparator(order, orderBy))
                    .filter((el) => el?.name?.toLowerCase().includes(filterName.toLowerCase())
                    )
                    .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                    .map((data, ind) => {
                      // const client = selectedUser(data?.clientCode[0]);
                      return (
                        <TableRow
                          hover
                          id={ind}
                          key={'tablebody' + ind}
                          tabIndex={-1}
                          style={{ backgroundColor: '#fff' }}
                        >

                          <TableCell className={Styles.TableCell}>

                            {data?.name
                              ? capitalizedClientName(data?.name)
                              : ""}

                          </TableCell>
                          <TableCell className={Styles.TableCell}>

                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Avatar
                                src={
                                  data?.businessManagers?.length > 0
                                    ? data.businessManagers[0]?.profilePicture
                                    : null
                                }
                                className={Styles.Avatar}
                              />
                              <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize' }}>
                                {data?.businessManagers?.length > 0 ? data.businessManagers[0]?.name?.toLowerCase() : "-"}
                              </Typography>
                              {data?.businessManagers?.length > 1 && (
                                <span
                                  onMouseEnter={(e) => handlePopover(e, 'bm' + data?._id)}
                                  onMouseLeave={handleClose}
                                  className={Styles.Plus}>
                                  + {data?.businessManagers?.length - 1}
                                </span>
                              )}
                              <Popover
                                id={'bm' + data?._id}
                                sx={{
                                  pointerEvents: 'none',
                                }}
                                open={openedPopover === 'bm' + data?._id}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                              >
                                <div className={Styles.PopoverDiv}>
                                  {data?.businessManagers?.slice(1)?.map((bm, index) => (
                                    <span
                                      key={index}>
                                      {(index === data?.businessManagers?.length - 2 && data?.businessManagers?.length > 2) && '& '}
                                      {bm?.name?.toLowerCase()}
                                      {index !== data?.businessManagers?.length - 2 ? ', ' : '.'}
                                    </span>
                                  ))}
                                </div>
                              </Popover>
                            </Stack>

                          </TableCell>
                          <TableCell className={Styles.TableCell}>

                            {data?.regions?.length > 0
                              ? <span style={{ textTransform: 'capitalize' }}>{data?.regions[0]?.toLowerCase()}</span>
                              : "No Region"}
                            {data?.regions?.length > 1 && (
                              <span
                                onMouseEnter={(e) => handlePopover(e, 'reg' + data?._id)}
                                onMouseLeave={handleClose}
                                style={{ marginLeft: '12px' }} className={Styles.Plus}>
                                + {data?.regions?.length - 1}
                              </span>
                            )}
                            <Popover
                              id={'reg' + data?._id}
                              sx={{
                                pointerEvents: 'none',
                              }}
                              open={openedPopover === 'reg' + data?._id}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                            >
                              <div className={Styles.PopoverDiv}>
                                {data?.regions?.slice(1)?.map((each, index) => (
                                  <span key={index}>
                                    {(index === data?.regions?.length - 2 && data?.regions?.length > 2) && '& '}
                                    {each?.toLowerCase()}
                                    {index !== data?.regions?.length - 2 ? ', ' : '.'}
                                  </span>
                                ))}
                              </div>
                            </Popover>
                          </TableCell>
                          <TableCell className={Styles.TableCell}>

                            {data?.group
                              ? data?.group
                              : "No Group"}

                          </TableCell>
                          <TableCell align="right" className={Styles.TableCell}>
                            <Link
                              to={{
                                pathname: `/superadmin/profile-clients/${data._id}`,
                                state: { data: data },
                              }}
                            >
                              <span className={Styles.ViewDetails}>View Detail</span>
                            </Link>
                          </TableCell>


                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <CustomTablePagination
            limit={rowsPerPage}
            setLimit={setRowsPerPage}
            page={page}
            setPage={setPage}
            totalPage={Math.ceil(clients.filter(
              (el) => el.name?.toLowerCase().includes(filterName.toLowerCase())
            )
              .length / rowsPerPage)} />
        </div>
      )}
    </div>
  );
}

