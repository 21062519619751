import React, { useState } from "react";
import SearchbarSA from "../../../../utils/SearchbarSA/SearchbarSA";
import "./ProductList.css";
import AddIcon from "@mui/icons-material/Add";
import { visuallyHidden } from "@mui/utils";
import { useHistory } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import ModalUpload from "../ModalUpload/ModalUpload";
import {
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Stack,
  Avatar,
  Button,
  IconButton,
} from "@mui/material";
import ExportCSV from "../../../../SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import ModalDeleteProduct from "../ModalDeleteProduct/ModalDeleteProduct";
import { Link } from "react-router-dom";

function createData(productName, productCode, productPrice, stock) {
  return {
    productName,
    productCode,
    productPrice,
    stock,
  };
}

const rows = [
  createData("Tea Celup Sosro", "KD3372", "Rp. 12.000", "99 Karton"),
  createData("Tea Celup Sosro", "KD3372", "Rp. 12.000", "100 Karton"),
  createData("Tea Celup Sosro", "KD3372", "Rp. 12.000", "40 Karton"),
  createData("Tea Celup Sosro", "KD3372", "Rp. 12.000", "70 Karton"),
  createData("Tea Celup Sosro", "KD3372", "Rp. 12.000", "150 Karton"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "productName",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },
  {
    id: "productCode",
    numeric: true,
    disablePadding: false,
    label: "Product Code",
  },
  {
    id: "productPrice",
    numeric: true,
    disablePadding: false,
    label: "Product Price",
  },
  {
    id: "stock",
    numeric: true,
    disablePadding: false,
    label: "Stock",
  },
  {
    label: "",
  },
  {
    label: "",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell></TableCell> */}
        {headCells.map((headCell, ind) => (
          <TableCell
            key={ind}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const fontStyle = {
  main: {
    label: {
      fontSize: 14,
      fontWeight: 400,
      color: "#000000",
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: "#000000",
  },
  span: {
    fontSize: 12,
    fontWeight: 400,
    color: "#616161",
  },
  penjualan: {
    fontSize: 16,
    fontWeight: 500,
    color: "#000000",
  },
};

export default function ProductList() {
  const history = useHistory();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("productName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [modal, setModal] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [fileData, setFileData] = useState([])
  const [deleteProduct, setDeleteProduct] = useState(false); // change to useState({ }) for contain the id after fetching data 

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, coordinatePoint) => {
    const selectedIndex = selected.indexOf(coordinatePoint);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, coordinatePoint);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="product__list-container">
      <div className="product__list-item-top">
        <div className="product__search">
          <SearchbarSA />
        </div>
        <div className="product__upload">
          <Button
            onClick={() => {
              setModal(true);
            }}
            endIcon={<PrintIcon style={{ fontSize: 16 }} />}
            className=""
          >
            Upload
          </Button>
          {modal && <ModalUpload setModal={setModal} setFileData={setFileData}/>}
        </div>
        <div className="product__download">
          <ExportCSV initial="Download Excel" />
        </div>
        <div className="product__add">
          <Button startIcon={<AddIcon />} style={{ color: "#FAFAFA", backgroundColor: '#1571de'}} onClick={() => history.push('/admin/add-product-external')}>
            Add Product
          </Button>
        </div>
      </div>
      <div className="product__list-table">
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", backgroundColor: "#FAFAFA" }}>
            <TableContainer>
              <Table sx={{ width: "100%" }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, row.coordinatePoint)
                          }
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell component="th" scope="row">
                            <Link 
                               to={{
                                pathname: `/admin/details-product-external`
                              }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                alt={row.productName}
                                src=""
                                variant="square"
                              />
                              <Typography
                                variant="subtitle2"
                                noWrap
                                style={fontStyle.main}
                              >
                                {row.productName}
                              </Typography>
                            </Stack>
                            </Link>
                          </TableCell>
                          <TableCell align="left">{row.productCode}</TableCell>
                          <TableCell align="left">{row.productPrice}</TableCell>
                          <TableCell align="left">{row.stock}</TableCell>
                          <TableCell align="left">
                            <Typography
                              className="status__promo"
                              // style={{
                              //   backgroundColor:
                              //     (status2 === "Active" && "#498E0F") ||
                              //     (status2 === "Finished" && "#1B45D9") ||
                              //     (status2 === "Rejected" && "#D1421A"),
                              // }}
                            >
                              Sedang Promo
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Stack
                              direction={{ xs: "column", sm: "row" }}
                              spacing={{ xs: 1, sm: 2, md: 4 }}
                              alignItems="center"
                              style={{ justifyContent: "flex-end" }}
                            >
                              <IconButton onClick={() => history.push('/admin/edit-product-external')}>
                                <EditIcon style={{ color: "1571DE" }} />
                              </IconButton>
                              <IconButton onClick={() => {setDeleteProduct(true)}}>
                                <DeleteIcon style={{ color: "D1421A" }} />
                              </IconButton>    
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>
      {deleteProduct && ( <ModalDeleteProduct deleteProduct={deleteProduct} setDeleteProduct={setDeleteProduct } pathName="/admin-product-external" />)}
    </div>
  );
}
