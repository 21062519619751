import axios from "axios";
import { API } from "../../redux/api";

const fetchAttendanceOvertime = (token, clientName, startDate, endDate) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(
        `${API}/attendance/admin/overtime?clientName=${clientName}&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export default fetchAttendanceOvertime;
