import React, { useEffect } from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { ClientProfileMenu } from "../../../components/SuperAdmin/_client/";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getClientById } from "../../../redux/actions/SuperAdmin/adminActions";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
// import ClientProfileMenu from "../../../components/SuperAdmin/_client/ClientProfile/ClientProfileMenu";
export default function ClientSettings() {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { clientById, loadingGetClientById } = useSelector((state) => state.client);
  // console.log(client, "cek client <<<");
  useEffect(() => {
    dispatch(getClientById(token, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const capitalizedClientName = (clientName) => {
    return clientName?.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace('Pt', 'PT')
      .replace('Cv', 'CV')
  }

  return (
    <Container maxWidth="xl" style={{ paddingRight: 0 }}>
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Client Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/client")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Client Settings</span>
            </Button>
            {/* <Button
              onClick={() => history.go(-1)}
              style={{ textTransform: "none" }}
            > */}
              <span style={{ fontSize: 20, color: "#255bfc" }}>{capitalizedClientName(clientById?.name) || 'Loading data client...'}</span>
            {/* </Button> */}
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <ClientProfileMenu client={clientById} loadingClientById={loadingGetClientById} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
