import axios from "axios";
import {API} from "../../redux/api";

const cancelAnnualLeave = (token, attendanceId,body) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.patch(`${API}/attendance/cancel/annual-leave/${attendanceId}`, body , {
          headers: {
            Authorization: token,
          },
        }
      );
      resolve( data );
    } catch (error) {
      reject(error);
    }
  });

export default cancelAnnualLeave;