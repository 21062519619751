import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React from "react";
import Styles from "./SettingLoanMenu.module.css";

export default function ClientLoanSettingView({ data }) {
  return (
    <div className={Styles.Container}>
      <div className={Styles.GroupControl}>
        <div className={Styles.ItemTop}>
          <div className={Styles.TextHeading}>
            <span>Detail Schema</span>
          </div>
        </div>

        <div className={Styles.BoxControl}>
          <div className={Styles.TextLabelTop}>
            <span>Name & Descriptions</span>
          </div>

          <div className={Styles.ValueTop}>
            <label>Scheme Name</label>
            <span>{data?.name}</span>
          </div>

          <div className={Styles.ValueTop}>
            <label>Description</label>
            <span>{data?.remark}</span>
          </div>
        </div>

        <div className={Styles.BoxRulesContainer}>
          <div className={Styles.LabelControl}>
            <span>Loan Rules</span>
          </div>

          <div className={Styles.BoxControlGroup}>
            {data?.loanRules?.map((each, idx) => (
              <div className={Styles.BoxGroup} key={idx}>
                <div className={Styles.Textlabel}>
                  <span>Rule {idx + 1}</span>
                </div>
                <div className={Styles.ValueGroup}>
                  <div className={Styles.Value}>
                    <label>Tenor</label>
                    <span>{each?.tenor} Month</span>
                  </div>
                  <div className={Styles.Value}>
                    <label>Maximum loan amount</label>
                    <span>Rp.{each?.maxPlafond?.toLocaleString()}</span>
                  </div>
                  <div className={Styles.Value}>
                    <label>Minimum loan amount</label>
                    <span>Rp.{each?.minPlafond?.toLocaleString()}</span>
                  </div>
                  <div className={Styles.Value}>
                    <label>Rate Interest</label>
                    <span>{each?.rate} %</span>
                  </div>
                  <div className={Styles.Value}>
                    <label>DBR (Debt Burden Ratio)</label>
                    <span>{each?.dbr} %</span>
                  </div>
                  <div className={Styles.Value}>
                    <label>Minimum expiry before contract end</label>
                    <span>{each?.minContractRemainInMonth} %</span>
                  </div>
                  <div className={Styles.Value}>
                    <label>Minimum loan active after contract</label>
                    <span>{each?.minLengthOfJobInMonth} Month</span>
                  </div>

                  <div className={Styles.ValueAdminFee}>
                    <div className={Styles.Value}>
                      <label>Admin Fee</label>
                      <span></span>
                    </div>
                    <div className={Styles.Value}>
                      <div className={Styles.LeftContent}>
                        <ul>
                          <li>Baseline</li>
                          <li>Multiplier factor</li>
                          <li>Percentage</li>
                        </ul>
                      </div>
                      <div className={Styles.RightContent}>
                        <ul>
                          <li>
                            Rp.{each?.adminFee?.baseLine?.toLocaleString()}
                          </li>
                          <li>
                            Rp.
                            {each?.adminFee?.additional?.multiplyFactor?.toLocaleString()}{" "}
                            <ArrowForwardIcon
                              sx={{ width: 12, height: 12, color: "#616161" }}
                            />{" "}
                            Rp.
                            {each?.adminFee?.additional?.factorCost?.toLocaleString()}
                          </li>
                          <li>{each?.adminFee?.additional?.percentage} %</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className={Styles.Value}>
                    <label>Permitted contracts</label>
                    <span>{each?.permittedContractType?.join(",")}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={Styles.BoxControl}>
          <div className={Styles.TextLabel}>
            <span>Other Information</span>
          </div>

          <div className={Styles.Value}>
            <label>Active day before payroll</label>
            <span>{data?.activeLoanDate?.daysBeforePayroll}</span>
          </div>

          <div className={Styles.Value}>
            <label>Active day after payroll</label>
            <span>{data?.activeLoanDate?.daysAfterPayroll}</span>
          </div>
          <div className={Styles.Value}>
            <label>Maximum age</label>
            <span>{data?.maxAge}</span>
          </div>
          <div className={Styles.Value}>
            <label>Minimum age</label>
            <span>{data?.minAge}</span>
          </div>
        </div>
      </div>
    </div>

    // <div className="container-loan-add-scheme">
    //   <div className="wrapper-loan-add-scheme">
    //     <div className="row-item-loan-add-scheme">
    //       <span>Scheme Name</span>
    //       <InputText value={ loanId?.Name ? loanId?.Name : "" } readOnly/>
    //     </div>
    //     <div className="row-item-loan-add-scheme-desc">
    //       <span>Description</span>
    //       <InputText value={loanId.Description ? loanId.Description : ''} style={{ maxWidth : 600 }} readOnly/>
    //     </div>
    //     <div className="container-scheme-item">
    //       {loanId.Tenor?.map((data, ind) => {
    //         return (
    //           <div className="container-scheme-detail" key={ind}>
    //             <div className="wrapper-scheme-detail">
    //               <div className="item-scheme-detail">
    //                 <span>Tenor</span>
    //                 <label>{data.TenorMonth ? data.TenorMonth : ''} Month</label>
    //               </div>
    //               <div className="item-scheme-detail-loan-max">
    //                 <div className="wrapper-item-scheme-detail-loan-max">
    //                   <span>Loan Max Size</span>
    //                   <div className="item-sub-scheme-detail-loan-max">
    //                     <label>{data.LoanMax ? data.LoanMax : ''} %</label>
    //                     <span>x &nbsp;Basic Salary</span>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="item-scheme-detail-loan-salary">
    //                 <div className="wrapper-item-scheme-detail-loan-salary">
    //                   <span>Pay Date</span>
    //                 </div>
    //                 <div className="item-sub-scheme-deatail-loan-salary">
    //                   <span>Every date of</span>
    //                   <p>{loanId?.TanggalGajian ? loanId.TanggalGajian : ''}</p>
    //                   <label>/&nbsp;Month</label>
    //                 </div>
    //               </div>
    //               {data.RangeDate?.map((sData, sInd) => {
    //                 return (
    //                   <div className="container-box-white" key={sInd}>
    //                     <div className="wrapper-box-white">
    //                       <div className="item-box-white-left">
    //                         <div className="title-item-box-white-left">
    //                           <span>Range Date Request</span>
    //                           <div className="item-row-box-white-left">
    //                             <div className="item-row-one">
    //                               <span>From</span>
    //                               <p>{sData.From ? sData.From : ''}</p>
    //                             </div>
    //                             <div className="item-row-two">
    //                               <span>to</span>
    //                               <p>{sData.To ? sData.To : ''}</p>
    //                               <label>Due to Pay Date</label>
    //                             </div>
    //                           </div>
    //                         </div>
    //                       </div>
    //                       <div className="item-box-white-right">
    //                         <div className="title-item-box-white-right">
    //                           <span>Admin Fee</span>
    //                           <div className="item-row-box-white-right">
    //                             <div className="item-row-right-one">
    //                               <label>{sData.AdminFee ? sData.AdminFee : ''} %</label>
    //                               <span>x</span>
    //                               <label>{sData.TransferFee}</label>
    //                             </div>
    //                             <span>Transfer fee</span>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 );
    //               })}
    //             </div>
    //           </div>
    //         );
    //       })}
    //     </div>
    //   </div>
    // </div>
  );
}
