import React, { useEffect, useMemo, useState } from "react";
import Styles from './LayoutListEBPEducation.module.css'
import { Link, useHistory } from "react-router-dom";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import { Avatar, Button } from "@mui/material";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import CustomTable from "../../../CustomTable/CustomTable";
import axios from "axios";
import { API, API_IMAGE } from "../../../../redux/api";
import moment from "moment";
const ExcelJS = require('exceljs');

export default function LayoutListEBPEducation ({
    data,
    loading,
    page,
    setPage,
    limit,
    setLimit,
    searchTerm,
    setSearchTerm,
    totalPage,
    totalData,
    token,
}){
    const history = useHistory();
    const [errorText, setErrorText] = useState('')

    useEffect(() => {
        if (errorText) {
            const handler = setTimeout(() => {
                setErrorText('');
            }, 5000);

            return () => {
                clearTimeout(handler);
            };
        }
    }, [errorText]);

    const handleDownloadExcel = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet('EBP KKB');

            sheet.getRow(1).font = {
                size: 16,
                bold: true,
            };

            sheet.columns = [
                {
                    header: 'No',
                    key: 'no',
                    width: 10,
                },
                {
                    header: 'Company',
                    key: 'client',
                    width: 20,
                },
                {
                    header: 'NRK',
                    key: 'employeeId',
                    width: 15,
                },
                {
                    header: 'Name',
                    key: 'name',
                    width: 20,
                },
                {
                    header: 'Job Title',
                    key: 'jobTitle',
                    width: 20,
                },
                {
                    header: 'Division',
                    key: 'division',
                    width: 20,
                },
                {
                    header: 'Type',
                    key: 'type',
                    width: 15,
                },
                {
                    header: 'Product',
                    key: 'product',
                    width: 20,
                },
                {
                    header: 'Code',
                    key: 'code',
                    width: 15,
                },
                {
                    header: 'Apply Date',
                    key: 'applyDate',
                    width: 20,
                },
                {
                    header: 'Created At',
                    key: 'createdAt',
                    width: 20,
                },
                {
                    header: 'Status',
                    key: 'status',
                    width: 20,
                },
            ];

            const response = await axios.get(`${API}/ebp/education?page=1&limit=${totalData}&search=${searchTerm}`, {
                headers: {
                    Authorization: token,
                },
            });

            if (response?.data?.response?.data?.length > 0) {
                const promise = Promise.all(
                    response?.data?.response?.data?.map(async (el, index) => {
                        sheet.addRow({
                            no: index + 1,
                            ...el,
                            applyDate: moment(el.applyDate).format('MM/DD/YYYY hh:mm A'),
                            createdAt: moment(el.createdAt).format('MM/DD/YYYY hh:mm A'),
                        });
                    })
                );

                promise.then(() => {
                    workbook.xlsx.writeBuffer().then(function (data) {
                        const blob = new Blob([data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        });
                        const url = window.URL.createObjectURL(blob);
                        const anchor = document.createElement('a');
                        anchor.href = url;
                        anchor.download = `EBP KKB Report - ${new Date().toLocaleDateString()}.xlsx`;
                        anchor.click();
                        window.URL.revokeObjectURL(url);
                    });
                });
            } else {
                setErrorText('No Data')
            }
        } catch (error) {
            console.log('err', error?.message);
            setErrorText(error?.message || 'Download Excel Failed')
        }
    };

    const dataWithNumber = useMemo(() => {
        if (data?.length > 0) {
            return data.map((value, index) => ({
                ...value,
                no: limit * (page - 1) + (index + 1),
            }));
        } else return [];
    }, [data, page, limit]);

    const tableHead = useMemo(() => {
        return [
            { id: 'no', label: 'No', alignRight: false, width: '5%' },
            { id: 'name', label: 'Employee Name', alignRight: false, width: '20%',
                renderData: (row) => (
                    <div className={Styles.ProfileWrapper}>
                        <Avatar alt='employee' sx={{ width: 24, height: 24 }} src={row?.profilePicture?.replace(`https://myworkspace.id/api/image/`, `${API_IMAGE}`)}>
                            {row?.name?.[0] || ''}
                        </Avatar>   
                        <span>{row?.name}</span>
                    </div>
                )
            },
            { id: 'client', label: 'Company', alignRight: false, width: '20%' },
            { id: 'product', label: 'Jenis Motor', alignRight: false, width: '15%' },
            {
                id: 'applyDate',
                label: 'Apply Date',
                alignRight: false,
                width: '15%',
                renderData: (row) => <span>{row?.applyDate ? moment(row.applyDate).format('DD MMMM YYYY') : '-'}</span>,
            },
            {
                id: 'status',
                label: 'Status',
                alignRight: false,
                width: '10%',
                renderData: (row) => (
                    <div>{row?.status ? <span className={Styles[row.status]}>{row.status}</span> : <span>-</span>}</div>
                ),
            },
            {
                id: '',
                width: '15%',
                renderData: (row) => {
                    return (
                        // <div
                        //     style={{ cursor: 'pointer', textAlign: 'center' }}
                        //     onClick={() => {
                        //         history.push(`/admin-business/ebp-detail/${row?.code}`);
                        //     }}>
                        //     <span style={{ color: '#1571DE' }}>Lihat Detail</span>
                        // </div>
                        <Link to={{
                            pathname: `/admin-business/ebp-detail/${row?.code}`,
                            state: {typeDetail: 'EDUCATION'}
                        }}>
                            <span style={{ color: '#1571DE' }}>Lihat Detail</span>
                        </Link>
                    );
                },
            },
        ];
    }, []);

    return (
        <div className={Styles.Container}>
            <div className={Styles.Header}>
                <div className={Styles.Searchbar}>
                    <SearchbarSA
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        searchWidth="379px"
                        placeholder="Search employee"
                    />
                </div>
                <div>
                    <Button className={Styles.ButtonPrimary} onClick={handleDownloadExcel}>
                        <span className={Styles.PrimaryText}>Download Excel</span>
                    </Button>
                </div>
            </div>
            {errorText && (
                <div className={Styles.errorWrapper}>
                    <span>{errorText}</span>
                </div>
            )}
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 50,
                    }}>
                    <div style={{ width: 200 }}>
                        <LoadingAnimation />
                    </div>
                </div>
            ) : (
                <CustomTable
                    bodyData={dataWithNumber}
                    tableHead={tableHead}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={limit}
                    setRowsPerPage={setLimit}
                    totalPage={totalPage}
                />
            )}
        </div>
    );
}