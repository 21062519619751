import axios from "axios";
import {API} from "../../redux/api";

const deleteNationalDayOff = (token, dayOffId) =>
  new Promise(async (resolve, reject) => {
    try {
      const data  = await axios.delete(`${API}/national-day/${dayOffId}`, {
        headers : {
          Authorization: token
        }
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export default deleteNationalDayOff;



