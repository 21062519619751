/*eslint-disable no-unused-vars */

import React, { useState } from "react";
import Styles from "./ModalPawnApproval.module.css";
import CHECKED_ICON from "../../../../assets/images/approval-icon-pawn.png";
import {
  Backdrop,
  Fade,
  Modal,
  Box,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import { makeStyles, styled } from "@mui/styles";
import approvalPawn from "../../../../async/employeeeBenefit/approvalPawn";
import { useHistory } from "react-router";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      border: "1px solid #d9d9d9",
    },
    "&:hover fieldset": {
      borderColor: "#d9d9d9",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #d9d9d9",
    },
    "& input::placeholder": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#757575",
    },
    "& input": {
      fontSize: "14px",
      fontWeight: 400,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1.5px solid #d3c9c9",
    borderColor: "#d3c9c9",
    borderRadius: 8,
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "@media screen and (max-width: 478px)": {
    bottom: 0,
    top: "auto",
    transform: "translate(-50%, 0)",
  },
};

const modalStyle = {
  "@media screen and (max-width: 950px)": {
    display: "none",
  },
};

export default function ModalPawnApproval({
  active,
  onOpen,
  onClose,
  onSuccess,
  setOnSuccess,
  token,
  id,
}) {
  const history = useHistory();
  const [values, setValues] = useState({
    tenor: "",
    disburse: "",
    amount: "",
    installment: "",
    approvalDate: null,
  });

  const onChangeNumber = (e, label) => {
    e.preventDefault();
    let valueAmount = e.target.value.split(",").join("");
    setValues((prev) => ({
      ...prev,
      [label]: +valueAmount,
    }));
  };

  const handleClose = () => {
    onOpen(false);
  };

  const handleProcess = async (e) => {
    try {
      e.preventDefault();
      const datas = {
        status: "APPROVED",
        tenor: values.tenor,
        disburse: values.disburse,
        installment: values.installment,
        applyAmount: values.amount,
      };
      approvalPawn(token, id, datas);
      onOpen(false);
      setOnSuccess(true);
      await new Promise((resolve, reject) => setTimeout(() => resolve(true), 1500))
      history.push("/admin-mws/pawn");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={active}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={modalStyle}
    >
      <Fade in={active}>
        <Box sx={style}>
          <div className={Styles.Container}>
            <div className={Styles.Header}>
              <img src={CHECKED_ICON} alt="badge" />
              <span>Approve</span>
              <span>Konfirmasi data untuk melanjutkan approve</span>
            </div>
            <form className={Styles.FormValue} onSubmit={handleProcess}>
              <div className={Styles.Value}>
                <div className={Styles.ValueControl}>
                  <label>Tenor</label>
                  <input
                    required
                    type="text"
                    placeholder="0"
                    value={values.tenor}
                    name="tenor"
                    onChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        tenor: +e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className={Styles.Value}>
                <div className={Styles.ValueControl}>
                  <label>Total Disburse</label>
                  <div className={Styles.InputContainer}>
                    <div className={Styles.InputAdornment}>
                      <span>Rp</span>
                    </div>
                    <input
                      required
                      type="text"
                      placeholder="0"
                      name="disburse"
                      value={values.disburse.toLocaleString()}
                      onChange={(e) => onChangeNumber(e, "disburse")}
                    />
                  </div>
                </div>
              </div>
              <div className={Styles.Value}>
                <div className={Styles.ValueControl}>
                  <label>Apply Amount</label>
                  <div className={Styles.InputContainer}>
                    <div className={Styles.InputAdornment}>
                      <span>Rp</span>
                    </div>
                    <input
                      required
                      type="text"
                      placeholder="0"
                      name="amount"
                      value={values.amount?.toLocaleString()}
                      onChange={(e) => onChangeNumber(e, "amount")}
                    />
                  </div>
                </div>
              </div>
              <div className={Styles.Value}>
                <div className={Styles.ValueControl}>
                  <label>Monthly Installment</label>
                  <div className={Styles.InputContainer}>
                    <div className={Styles.InputAdornment}>
                      <span>Rp</span>
                    </div>
                    <input
                      required
                      type="text"
                      placeholder="0"
                      name="installment"
                      value={values?.installment?.toLocaleString()}
                      onChange={(e) => onChangeNumber(e, "installment")}
                    />
                  </div>
                </div>
              </div>
              <div className={Styles.Value}>
                <div className={Styles.ValueControl}>
                  <label>Approval Date</label>
                  <div className={Styles.InputContainerRightDate}>
                    <FormControl size="small" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          components={{
                            OpenPickerIcon: CalendarMonthIcon,
                          }}
                          disabled
                          value={
                            moment(new Date().getTime()).format(
                              "DD MMMM YYYY"
                            ) || ""
                          }
                          // onChange={(newValue) =>
                          //   handleChangeBirthDate(newValue)
                          // }
                          onChange={(newValue) =>
                            setValues((prev) => ({
                              ...prev,
                              approvalDate: newValue,
                            }))
                          }
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              size="small"
                              sx={{ background: "var(--main-bg)" }}
                              placeholder="Select"
                              disabled
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className={Styles.ActionButton}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Process</Button>
              </div>
            </form>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
