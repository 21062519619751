import React from "react";
import { Helmet } from "react-helmet";
import { SalaryAdvanceAdminMWSLayout } from "../../../../components/AdminMWS";

export default function SalaryAdvance() {
  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Salary Advance</title>
      </Helmet>
      <SalaryAdvanceAdminMWSLayout />
    </div>
  );
}
