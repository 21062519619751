import React, { useEffect, useMemo, useState } from "react";
import Styles from "./LeaveSchemaForm.module.css";
import { Button, Container, Grid, Switch } from "@mui/material";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
import { useHistory } from "react-router-dom";
import { HolidayTable } from "../../../components/SuperAdmin/_holidaySetting";
import ModalDayOff from "../../../components/SuperAdmin/_holidaySetting/ModalAddDayoff/AddDayoffModal";
import createLeavePlan from "../../../async/leavePlan/createLeavePlan";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import fetchLeavePlanById from "../../../async/leavePlan/fetchLeavePlanById";
import editLeavePlan from "../../../async/leavePlan/editLeavePlan";
import createDayOff from "../../../async/dayoff/createDayOff";
import { DeleteModalLayout } from "../../../components/SuperAdmin/_induction";
import deleteNationalDayOff from "../../../async/dayoff/deleteNationalDayOff";
import editNationalDayOff from "../../../async/dayoff/editNationalDayOff";

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function LeaveSchemaForm() {
  const { id } = useParams();
  const token = localStorage.getItem("accessToken");
  const history = useHistory();
  // const [showEndPeriod, setShowEndPeriod] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isError, setIsError] = useState();
  const [data, setData] = useState();
  // const [dataToSend, setDataToSend] = useState({
  //   date: new Date(),
  //   remark: "",
  // });

  const [dataToSend, setDataToSend] = useState({
    name: "",
    initialBalance: null,
    periodMethod: "",
    periodStart: "January",
    periodNext: "",
    nextYear: false,
    holiday: [],
  });
  const [isEdit, setIsEdit] = useState("edit");
  const [deleteHoliday, setDeleteHoliday] = useState();
  const [editHoliday, setEditHoliday] = useState();
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    const refetchData = async () => {
      try {
        setLoading(true);
        const result = await fetchLeavePlanById(token, id);
        setData(result);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setIsError("Error");
        setLoading(false);
      }
    };
    refetchData();
  }, [id, token]);

  const handleGetData = async () => {
    try {
      setLoading(true);
      const result = await fetchLeavePlanById(token, id);
      setData(result);
      setLoading(false);
      setLoading(false);
    } catch (error) {
      setIsError("Error");
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      setDataToSend(data);
    }
  }, [id, data]);

  useEffect(() => {
    setSelectedData(dataToSend);
  }, [dataToSend]);

  let leavePeriod = `${month[0]} ${new Date().getFullYear()}`;

  const handleInputChange = (name, value) => {
    setDataToSend((prev) => ({ ...prev, [name]: value }));

    window.sessionStorage.setItem("persistYearLeave", value);
  };

  // const persistYear = useMemo(() => {
  //   let year = new Date().getFullYear() + 1;
  //   let currentYear = new Date().getFullYear();
  //   let sessionValue = window.sessionStorage.getItem("persistYear");
  //   let selectedCurrentYear;

  //   if (dataToSend?.nextYear === true) {
  //     selectedCurrentYear = `${dataToSend.periodNext} ${year || ""}`;
  //   } else if (dataToSend?.nextYear === false) {
  //     selectedCurrentYear = `${dataToSend.periodNext} ${currentYear || ""}`;
  //   } else {
  //     selectedCurrentYear = `${sessionValue || ""} ${currentYear || ""}`;
  //   }
  //   return selectedCurrentYear;
  // }, [dataToSend?.periodNext, dataToSend?.nextYear]);

  const [newItem, setNewItem] = useState({
    date: new Date(),
    description: "",
    type: "CUTI BERSAMA",
    // leavePlanId: id
  });


  const newHoliday = {
    date: newItem.date,
    description: newItem.description,
    type: newItem.type,
  };
  const noHoliday = { date: "", remark: "" };

  let noIdHoliday = useMemo(() => {
    return dataToSend?.holiday?.map((item) => item).filter((res) => !res._id);
    // return item.filter((res) => !res._id);
  }, [dataToSend]);

  // const [editedData, setEditedData ] = useState([noHoliday]);
  // console.log(editedData.date === "", 'test')
  // console.log(noIdHoliday, 'datasss')

  const handleCreateDayOff = () => {
    if (!id) {
      setDataToSend((prevItems) => ({
        ...prevItems,
        holiday: [newHoliday, ...prevItems.holiday],
      }));
      setOpenModal(false);
    } else {
      const updatedHoliday = [
        newHoliday,
        ...dataToSend?.holiday.filter((item) => !item?._id),
      ];
      setDataToSend((prevItems) => ({
        ...prevItems,
        holiday: updatedHoliday,
      }));
      setOpenModal(false);
    }
  };

  // const handleCreateDayOff = async () => {
  //   try {
  //     await createDayOff(token, newItem);
  //     setOpenModal(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(error);
  //   }
  // };

  const onCreateSchema = async (e) => {
    e.preventDefault();
    if (!id) {
      try {
        await createLeavePlan(token, dataToSend);
        // setOpenModal(false);
        history.push(`/superadmin/leave`);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        if (noIdHoliday?.length < 1) {
          const data = {
            name: dataToSend?.name,
            initialBalance: dataToSend?.initialBalance,
            periodMethod: dataToSend?.periodMethod,
            periodStart: "January",
            periodNext: dataToSend?.periodNext,
            nextYear: dataToSend?.nextYear,
            holiday: [],
          };
          await editLeavePlan(token, data, id);
          history.push(`/superadmin/leave`);
          // console.log(data, "data");
        } else {
          await editLeavePlan(token, dataToSend, id);
          history.push(`/superadmin/leave`);
          // console.log(dataToSend, "dataTosend");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeleteDayOff = async (e) => {
    try {
      const selectedId = dataToSend?.holiday.map(el => el).filter(res => res?._id === deleteModal?._id );
      // console.log(selectedId[0]?._id, 'id')
      await deleteNationalDayOff(token, selectedId?.[0]?._id);
      handleGetData();
    } catch (error) {
      console.log(error);
    } 
  };

  const handleEditDayOff = async (e) => {
    try {
      const selectedId = dataToSend?.holiday.map(el => el).filter(res => res?._id === editModal?._id );
      const payload = {
        date: newItem?.date,
        remark: newItem?.description,
      }
      // console.log(selectedId[0]?._id, 'id')
      await editNationalDayOff(token, selectedId?.[0]?._id, payload);
      handleGetData();
    } catch (error) {
      console.log(error);
    } 
  };

  useEffect(() => {
    if (!id) {
      setIsEdit("add");
    }
  }, [id]);
  
  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Leave Scheme</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb aria-label="breadcrumb">
            <Button
              onClick={() => history.push("/superadmin/leave")}
              style={{ border: "none", textTransform: "none", width: "100%" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>
                Leave Scheme
              </span>
            </Button>
            <span style={{ fontSize: 20, color: "#255bfc" }}>
              {id ? "Edit Scheme" : "Add Scheme"}
            </span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <section className={Styles.Container}>
              <div className={Styles.Header}>Detail Schema</div>
              <form className={Styles.FormControl} onSubmit={onCreateSchema}>
                <div className={Styles.Value}>
                  <div className={Styles.ValueControl}>
                    <label>Scheme name</label>
                    <input
                      required
                      type="text"
                      name="name"
                      placeholder="Scheme name"
                      value={dataToSend?.name || ""}
                      onChange={(e) =>
                        handleInputChange("name", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className={Styles.Value}>
                  <div className={Styles.ValueControl}>
                    <label>Initial balance</label>
                    <input
                      required
                      placeholder="0"
                      min="1"
                      max="12"
                      type="number"
                      name="initialBalance"
                      value={dataToSend?.initialBalance}
                      onChange={(e) =>
                        handleInputChange("initialBalance", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className={Styles.Value}>
                  <div className={Styles.ValueControl}>
                    <label>Prorate method</label>
                    <select
                      // placeholder="Select"
                      required
                      value={dataToSend?.periodMethod || ""}
                      name="periodMethod"
                      onChange={(e) =>
                        handleInputChange("periodMethod", e.target.value)
                      }
                    >
                      <option disabled value="">
                        Select
                      </option>
                      <option value="DEFAULT">Default</option>
                      <option value="EARLY">Early</option>
                      <option value="CONTINUOUS">Continous</option>
                    </select>
                  </div>
                </div>
                <div className={Styles.ValueLeave}>
                  <div className={Styles.ValueControlLeave}>
                    <div className={Styles.InputControlLeave}>
                      <label>Cut Off Late</label>
                      <div className={Styles.WrapperInputLeave}>
                        <div className={Styles.InputContainer}>
                          <label htmlFor="">Leave Period</label>
                          <input type="text" disabled value="January" />
                        </div>
                        <div className={Styles.InputContainer}>
                          {/* {
                          !id ? (
                            <span>{`${leavePeriod} - ${persistYear}`}</span>
                          ) : (
                            <span>{`${leavePeriod} - ${persistYear }`}</span>
                          )
                        } */}
                          <span>{`${dataToSend?.periodStart} - ${dataToSend?.periodNext}`}</span>
                          <select
                            required
                            value={dataToSend?.periodNext || ""}
                            name="periodNext"
                            onChange={(e) =>
                              handleInputChange("periodNext", e.target.value)
                            }
                          >
                            <option value="" disabled>
                              Select Month...
                            </option>
                            {month.map((el, idx) => (
                              <option key={idx} value={el}>
                                {el}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={Styles.Value}>
                  <div className={Styles.ValueControl}>
                    <div className={Styles.SwitchInput}>
                      <p>Next Year</p>
                      <Switch
                        size="large"
                        checked={dataToSend?.nextYear}
                        onChange={(e) =>
                          handleInputChange("nextYear", e.target.checked)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </div>
                  </div>
                </div>

                <hr />

                <div className={Styles.HolidayTable}>
                  {isEdit === "edit" ? (
                    <HolidayTable
                      loading={loading}
                      datas={dataToSend?.holiday || []}
                      addDayoff={() => setOpenModal(true)}
                      newItem={newItem}
                      editHoliday={editHoliday}
                      setEditHoliday={setEditHoliday}
                      deleteHoliday={deleteHoliday}
                      setDeleteHoliday={setDeleteHoliday}
                      // onDeleteHoliday={handleDeleteDayOff}
                      // onEditHoliday={handleEditDayOff}
                      // mode="edit"
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                      setEditModal={setEditModal}
                      editModal={editModal}
                      deleteModal={deleteModal}
                      setDeleteModal={setDeleteModal}
                    />
                  ) : (
                    <HolidayTable
                      loading={loading}
                      datas={dataToSend?.holiday || []}
                      newItem={newItem}
                      addDayoff={() => setOpenModal(true)}
                      editHoliday={editHoliday}
                      setEditHoliday={setEditHoliday}
                      deleteHoliday={deleteHoliday}
                      setDeleteHoliday={setDeleteHoliday}
                      // onDeleteHoliday={handleDeleteDayOff}
                      // onEditHoliday={handleEditDayOff}
                      // mode="add"
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                    />
                  )}
                </div>

                <div className={Styles.ActionButton}>
                  <button onClick={() => history.push(`/superadmin/leave`)}>
                    Cancel
                  </button>
                  <button
                    disabled={
                      dataToSend?.name &&
                      dataToSend?.initialBalance &&
                      dataToSend?.periodMethod &&
                      dataToSend?.endPeriod
                    }
                  >
                    Save
                  </button>
                </div>
              </form>
            </section>
          </Grid>
        </Grid>
      </div>

      <ModalDayOff
        open={openModal}
        setOpen={setOpenModal}
        value={newItem}
        setValue={setNewItem}
        handleSubmit={handleCreateDayOff}
      />

      {editModal?._id && (
        <ModalDayOff
          open={editModal}
          setOpen={setEditModal}
          value={newItem}
          setValue={setNewItem}
          handleSubmit={() => {
            handleEditDayOff(dataToSend);
            setEditModal(false)
          }}
          id={editModal?._id}
          data={editModal}
        />
      )}

      {deleteModal?._id && (
        <DeleteModalLayout
          active={deleteModal}
          onOpen={setDeleteModal}
          description="Are you sure you want to delete this holiday?"
          label="Delete Holiday"
          onDeleteScheme={() => {
            handleDeleteDayOff(dataToSend);
            setDeleteModal(false);
          }}
        />
      )}
    </Container>
  );
}
