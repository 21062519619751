import React, { useState } from "react";
import "./LayoutSupervisor.css";
import { Route } from "react-router-dom";
import SnackbarComponent from "../../utils/Snackbar/SnackbarComponent";
import { Redirect } from "react-router";
import MiniScreen from "../../utils/MiniScreen";
import SidebarFMCG from "../../FMCG/SidebarFMCG/SidebarFMCG";
import TopNavFMCG from "../../FMCG/TopNavFMCG/TopNavFMCG";
import RoutesSupervisor from "../../Supervisor/Routes";
import SidebarSupervisor from "../../Supervisor/SidebarSupervisor";
import TopNavExternal from "../../topNav/TopNavExternal";

const LayoutSupervisor = () => {
  const token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);

  if (!token) {
    return <Redirect to="/" />;
  }
  return (
    <Route
      render={(props) => (
        <>
          <div
            className="LayoutFMCG"
            // style={{ height: '100vh'}}
          >
            <SidebarSupervisor
              {...props}
              onOpenSidebar={open}
              onCloseSidebar={() => setOpen(false)}
            />
            <div className={`LayoutFMCG_content ${!open && "close"}`}>
              <TopNavExternal onOpenSidebar={() => setOpen(true)} />
              <div className="LayoutFMCG_content-main">
                <SnackbarComponent />
                <RoutesSupervisor />
              </div>
            </div>
          </div>
          <div className="mini__screen-layout">
            <MiniScreen />
          </div>
        </>
      )}
    />
  );
};

export default LayoutSupervisor;
