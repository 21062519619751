import { Button } from "@mui/material";
import React from "react";
import Styles from "./Style.module.css";
import CloseIcon from "@mui/icons-material/Close";
import LEAVE_ATTENDANCE from "../../../../../assets/images/deliverable.png";
// import ExportDataExcel from "../../../../SuperAdmin/_attendance/tabs/DataAttendance/ExportDataExcel";
// import DESCRIPTION_ICON from "../../../../assets/images/modal-activity-description.png";
// import DATE_ICON from "../../../../assets/images/modal-activity-calendar.png";
// import CLOCK_ICON from "../../../../assets/images/modal-activity-clock.png";
// import COMPANY_ICON from "../../../../assets/images/modal-activity-client.png";
// import USER_ICON from "../../../../assets/images/modal-activity-user.png";
// import LOCATION_ICON from "../../../../assets/images/modal-activity-location.png";
// import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExportButton = ({
  data,
  filename,
  initial,
  endIcon,
  startIcon,
  className,
  disabled
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  // const pdfExtension = '.pdf'

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      // style={{
      //   textTransform: "none",
      //   backgroundColor: "#1571de",
      //   color: "white",
      //   maxWidth: 142,
      //   height: 40,
      //   borderRadius: 4,
      // }}
      onClick={(e) => exportToCSV(data, filename)}
      endIcon={endIcon}
      startIcon={startIcon}
      className={className}
      disabled={disabled}
    >
      {initial}
    </Button>
  );
};

export default function AnnualLeaveModal(props) {
  const { setDetailModal, data, dataAnnualLeave } = props;
  const currYear = new Date().getFullYear();

  return (
    <div className={Styles.Background}>
      <div className={Styles.Container}>
        <div className={Styles.Body}>
          <div className={Styles.Heading}>
            <span>
              Annual Leave <label>{currYear}</label>
            </span>
            <CloseIcon
              sx={{ fontSize: 24, cursor: "pointer" }}
              onClick={() => setDetailModal(false)}
            />
          </div>
          <div className={Styles.Desc}>
            <div className={Styles.ValueControl}>
              <span>
                Leaves list of <label>{data?.name}</label>
              </span>
            </div>

            <div className={Styles.BoxDesc}>
            {
              dataAnnualLeave?.map((each, idx) => (
              <div className={Styles.BoxItem} key={idx}>
                <div className={Styles.ItemLeft}>
                  <img src={LEAVE_ATTENDANCE} alt="icon" />
                  <span>Leave</span>
                </div>
                <div className={Styles.ItemCenter}>
                  <span>{each?.type}</span>
                </div>
                <div className={Styles.ItemRight}>
                  <span>{each?.total} days</span>
                </div>
              </div>
              ))
            }

            </div>

            <div className={Styles.ValueControl}>
              <span>
                Remaining leaves : <label>{data?.remainAnnualLeave} days</label>
              </span>
            </div>

            <div className={Styles.Download}>
              <ExportButton
                data={dataAnnualLeave?.map((each, idx) => ({
                  "No." : idx + 1,
                  "Employee Name" : data?.name,
                  "Keterangan" : each?.type,
                  "Total" : each.total,
                  "Sisa Cuti" : data?.remainAnnualLeave
                }))}
                filename={`Annual Leave Report ${new Date().toLocaleDateString()}`}
                initial="Excel"
                startIcon={
                  <i className="ri-download-line" style={{ fontSize: 20 }} />
                }
                disabled={ dataAnnualLeave ===  null }
                className={ `${dataAnnualLeave === null ?  Styles.Disabled  : Styles.ButtonDownload} `}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
