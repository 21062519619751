import styled from "@emotion/styled";
import { Alert, Autocomplete, Button, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Geocode from "react-geocode";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import fetchClientCode from "../../../../async/client/fetchClientCode";
import { addLocation } from "../../../../redux/actions/SuperAdmin/locationActions";
import MapWithAutoComplete from "../../../_mapWithAutoComplete/MapWithAutoComplete/MapWithAutoComplete";
import Styles from './AddLocation.module.css';

const MuiTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: 8,
            border: '1px solid #e0e0e0',
        },
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "1.5px solid #d3c9c9",
        borderColor: "#d3c9c9",
        borderRadius: 8,
        //   width: "100%",
    },
});

export default function AddLocation() {
    const token = localStorage.getItem("accessToken");
    const dispatch = useDispatch();
    const history = useHistory();

    const [selected, setSelected] = useState("");
    const [selectedCode, setSelectedCode] = useState("");
    const [dataClientCode, setDataClientCode] = useState([]);
    const [coordinate, setCoordinateAdd] = useState({});
    const [address, setAddress] = useState({});
    const [showSearch, setShowSearch] = useState(true);
    const [alert, setAlert] = useState(null)


    // const persistClientCode = useMemo(() => {
    //     let persistClient = window.sessionStorage.getItem("selectedClient");
    //     let client;
    //     if (persistClient) {
    //         client = dataClientCode.find((item) => item.name === persistClient);;
    //     } else {
    //         client = "";
    //     }

    //     return client?.codes;
    // }, [dataClientCode]);

    const persistClientCode = useMemo(() => {
        let persistClient = window.sessionStorage.getItem("selectedClientCode");
        let clientCode;

        if (persistClient) {
            clientCode = persistClient;
        } else {
            clientCode = selectedCode;
        }

        return clientCode;
    }, [selectedCode]);

    const [form, setForm] = useState({
        name: '',
        address: '',
        longitude: '',
        latitude: '',
        clientCode: persistClientCode,
        radiusInMeter: ''
    })

    const handleChange = (e) => {
        setForm((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const clientNames = useMemo(() => {
        return dataClientCode.map((el) => el?.name);
    }, [dataClientCode]);

    const handleSelectedClient = (value) => {
        window.sessionStorage.setItem("selectedClient", value);
        setSelected(value);
        let activeCode = dataClientCode.find((item) => item.name === value);
        setSelectedCode(activeCode?.codes)
        window.sessionStorage.setItem("selectedClientCode", selectedCode);
    };

    useEffect(() => {
        (async () => {
            try {
                const { response } = await fetchClientCode(token);
                setDataClientCode(response);
            } catch (error) {
                console.log(error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setForm((prev) => ({
            ...prev,
            "longitude": coordinate?.Longitude,
            "latitude": coordinate?.Latitude,
            "clientCode": selectedCode,
        }))
    }, [coordinate?.Longitude, coordinate?.Latitude, selectedCode, address?.Address])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (form.latitude === undefined || form.longitude === undefined) {
            setAlert("Please pin the location on the map");
        } else {
            dispatch(addLocation(token, form))
            history.push("/superadmin/database")
        }
    }

    const handleReset = (e) => {
        e.preventDefault();
        setForm((prev) => ({
            ...prev,
            "name": '',
            "address": '',
            "longitude": '',
            "latitude": '',
            "clientCode": '',
            "radiusInMeter": ''
        }))
        setSelected(null)
        setSelectedCode(null)
        setAddress(null)
        setCoordinateAdd(null)
    }


    useEffect(() => {

        Geocode.fromLatLng(form?.latitude, form?.longitude).then(
            (response) => {
                const address = response.results[0].formatted_address;
                setAddress({ Address: address });
            },
            (error) => {
                console.error(error);
            }
        );
    }, [form?.latitude, form?.longitude])


    console.log(address, 'address')

    return (
        <div className={Styles.ContainerWrapper}>
            <span>Add Location Data</span>
            <div className={Styles.Container}>
                <form onSubmit={handleSubmit}>
                    <div className={Styles.Top}>
                        <span>Location Details</span>
                        <div className={Styles.BoxForm}>
                            <label htmlFor="name">Location Name</label>
                            <input type="text" id="name" value={form.name} name="name" onChange={handleChange} required />
                        </div>
                        <div className={Styles.BoxForm}>
                            <label htmlFor="clientName">Client Name</label>
                            <Autocomplete
                                disablePortal
                                options={clientNames}
                                value={selected}
                                sx={{ width: '100%' }}
                                onChange={(e, value) =>
                                    value === null
                                        ? handleSelectedClient("")
                                        : handleSelectedClient(value)
                                }
                                renderInput={(params) => <MuiTextField {...params} size="small" sx={{ background: 'var(--main-bg)' }} required />}
                            />
                        </div>
                        <div className={Styles.BoxForm}>
                            <label htmlFor="address">Address</label>
                            <input type="text" id="address" value={form.address} name="address" onChange={handleChange} required />
                        </div>
                        <div className={Styles.BoxForm}>
                            <label htmlFor="radiusInMeter">Range (in metre)</label>
                            <input type="number" id="radiusInMeter" value={form.radiusInMeter} name="radiusInMeter" onChange={handleChange} required />
                        </div>
                    </div>
                    {alert && (
                        <div className={Styles.AlertWrapper}>
                            <Alert severity="warning" onClose={() => { setAlert(null) }}>{alert}</Alert>
                        </div>
                    )}
                    <div className={Styles.MapContainer}>
                        <MapWithAutoComplete coordinate={coordinate} setCoordinate={setCoordinateAdd} showSearch={showSearch} setShowSearch={setShowSearch} setAddress={setAddress} />
                    </div>
                    {/* <div className={Styles.MapDiv}>
                        <LocationMap/>
                    </div> */}
                    <div className={Styles.Bottom}>
                        <div className={Styles.BoxAuto}>
                            <span>Address Details</span>
                            <span>{address?.Address ? address?.Address : form.address ? form?.address : '-'}</span>
                        </div>
                        <div className={Styles.LastWrapper}>
                            <div className={Styles.BoxAuto}>
                                <span>Latitude</span>
                                <span>{coordinate?.Latitude ? coordinate?.Latitude : '-'}</span>
                            </div>
                            <div className={Styles.BoxAuto}>
                                <span>Longitude</span>
                                <span>{coordinate?.Longitude ? coordinate?.Longitude : '-'}</span>
                            </div>
                        </div>
                    </div>
                    <div className={Styles.ButtonWrapper}>
                        <Button type="submit" variant="contained" className={Styles.SaveButton}>Save</Button>
                        <Button type="button" variant="outlined" onClick={handleReset} className={Styles.ResetButton}>Reset</Button>
                    </div>
                </form>
            </div>
        </div>

    )
}