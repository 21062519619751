/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";

import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { useHistory, useLocation, useParams } from "react-router";
import { AppLocationDetailSA, AppLocationFormSA } from "../../../../components/SuperAdmin/_database";
import { useDispatch, useSelector } from "react-redux";
import { getLocationById } from "../../../../redux/actions/SuperAdmin/locationActions";
import getClientName from "../../../../async/client/getClientName";

export default function LocationDetailSA() {
  const token = localStorage.getItem("accessToken");
  const params = useParams()
  const history = useHistory();
  const { schemaLocation } = useSelector((state) => state.locations)

  const[clientName, setClientName] = useState("")

  useEffect(() => {
    (async () => {
      try {
        const name = await getClientName(token, schemaLocation?.clientCode);
        setClientName(name)
      } catch (error) {
        console.log()
      }
    }
    )()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaLocation])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getLocationById(token, params.id))
  }, [dispatch, token, params?.id])


  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Detail Location</title>
        </Helmet>
        <Breadcrumb>
          <Button
            onClick={() => history.push("/superadmin/database")}
            style={{ textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>Master Data</span>
          </Button>
          <span style={{ fontSize: 20, color: "#255bfc" }}>{schemaLocation?.name}</span>
        </Breadcrumb>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} style={{ padding: '20px 0' }}>
            <AppLocationDetailSA location={schemaLocation} clientName={clientName} locationId={params.id} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
