import React, { useEffect, useState } from "react";
import Styles from "./ModalNoteForm.module.css";
import { Backdrop, Button, Fade, Modal } from "@mui/material";
import { Box  } from "@mui/system";
import axios from "axios";
import {API} from "../../../../redux/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "@media screen and (max-width: 478px)": {
    bottom: 0,
    top: "auto",
    transform: "translate(-50%, 0)",
  },
};

// const modalStyle = {
//   "@media screen and (max-width: 950px)": {
//     display: "none",
//   },
// };

export default function ModalNoteForm(props) {
  const { active, onOpen, dataResign } = props;
  const [text, setText] = useState("");

  useEffect(() => {
  }, [dataResign, text]);

  const handleClose = () => {
    onOpen(false);
  };

  const handleApproveRejection = async () => {
    onOpen(false);
    await axios
      .patch(`${API}/resign/web/${dataResign?._id}`, { status: "REJECT", remark: text })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
    await new Promise((resolve) => setTimeout(() => resolve(true), 1000));
    window.location.reload(false);
  };
  return (
    <Modal
      open={active}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      //   sx={modalStyle}
    >
      <Fade in={active}>
        <Box sx={style}>
          <div className={Styles.ModalDeleteBackground}>
            <div className={Styles.ModalContainerContent}>
              <div className={Styles.WrapperItemModalSuccess}>
                {/* <div className={Styles.Image}>
                  <img src={REJECTED_ICON} alt="rejected-icon" />
                </div> */}
                <div className={Styles.Content}>
                  <span>Note</span>
                  <span>Alasan menolak permintaan ini</span>
                  <div className={Styles.TextArea}>
                    <textarea
                      type="text"
                      placeholder="Deskripsi"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                  </div>
                </div>
                <div className={Styles.ButtonAction}>
                  <Button onClick={() => onOpen(false)}>Cancel</Button>
                  <Button onClick={handleApproveRejection}>Yes</Button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
