import React, { useEffect } from "react";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getClientById } from "../../../../redux/actions/SuperAdmin/adminActions";
import { fetchAllSchemaLoan } from "../../../../redux/actions/SuperAdmin/loanActions";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import AddClientsIdLoanNotSet from "../../../../components/SuperAdmin/_client/AddClientsID/AddClientIdLoanNotSet/AddClientIdLoanNotSet";

export default function AddClientsIdLoanChoosePage() {
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const { id } = useParams();
  const client = useSelector((state) => state.client.clientById[id]);
  const schemaLoan = useSelector((state) => state.loan.loanSchema);
  const navigateBack = () => history.go(-1);

  useEffect(() => {
    dispatch(getClientById(id, token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchAllSchemaLoan(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(client?.Name, "cek data");
  
  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Client Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/client")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Client</span>
            </Button>
            <Button
              onClick={() => history.push("/superadmin/add-clients")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Add Client</span>
            </Button>
            <Button
              onClick={navigateBack}
              style={{ textTransform: "none", color: "#255bfc" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>
                {client?.Name}
              </span>
            </Button>
            <span style={{ fontSize: 20 }}>Loan Settings</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AddClientsIdLoanNotSet client={client} schemaLoan={schemaLoan}/>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
