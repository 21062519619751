/*eslint-disable no-unused-vars*/

import React, { useEffect, useMemo, useState } from "react";
import { Grid, Container, Button, Dialog, Autocomplete, TextField } from "@mui/material";
import { Helmet } from "react-helmet";
import { TableWorkOnLayout } from "../../../components/External";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEmployeesWithoutWorkOn } from "../../../redux/actions/External/workOnExternalActions";
import {
  fetchClientByCodeExternal,
} from "../../../redux/actions/External/clientExternalActions";
import fetchDetailClientExternalAsync from "../../../async/detailClientExternal/fetchDetailClientExternalAsync";
import setWorkOn from "../../../async/workOn/setWorkOn";
import deleteWorkOn from "../../../async/workOn/deleteWorkOn";
import SUCCESS_VECTOR from "../../../assets/images/data-saved-vector.png";
import Styles from "./TableWorkOn.module.css";
import fetchClientCodeExternal from "../../../async/client/fetchClientCodeExternal";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router";
import styled from "@emotion/styled";
import fetchClientCode from "../../../async/client/fetchClientCode";


const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

export default function TableWorkOn() {
  const history = useHistory();
  const isSuper = localStorage.getItem("isSuper")
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const clientCode = window.sessionStorage.getItem("selectedClientCodeInternal") || window.localStorage.getItem("clientCode");
  const { allWorkOnEmployeeExternal: attendees } = useSelector((state) => state.workOnExternal);
  const { clientExternalByCode: dataClientExternal } = useSelector((state) => state.clientExternal);
  // const attendancePlanId = dataClientExternal?.attendancePlan?._id;
  const [detailExternal, setDetailExternal] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const { pathname } = useLocation();
  const [dataClientCodes, setDataClientCodes] = useState([]);
  const defaultClientCode = localStorage.getItem('clientCode')
  const defaultClientName = localStorage.getItem('clientName')
  const [selectedClient, setSelectedClient] = useState({
    name: defaultClientName,
    code: defaultClientCode,
  })

  // useEffect(() => {
  //   dispatch(fetchDetailClientExternal(token, attendancePlanId, clientCode))
  // },[dispatch, token, attendancePlanId, clientCode])

  const attendancePlanId = useMemo(() => {
    return dataClientExternal?.attendancePlan?._id;
  }, [dataClientExternal]);

  const workHours = useMemo(() => {
    return detailExternal?.workHours;
  }, [detailExternal]);

  useEffect(() => {
    dispatch(fetchAllEmployeesWithoutWorkOn(token, clientCode));
  }, [token, clientCode, dispatch]);

  useEffect(() => {
    dispatch(fetchClientByCodeExternal(token, clientCode));
  }, [dispatch, token, clientCode]);

  const getData = async () => {
    try {
      setIsLoading(true);
      const data = await fetchDetailClientExternalAsync(
        token,
        attendancePlanId,
        clientCode
      );
      setDetailExternal(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if(clientCode) {
      getData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendancePlanId, clientCode, token]);

  const handleSetWorkOn = async (data, workId) => {
    try {
      await setWorkOn(token, { employeeIds: data, workOn: workId });
      setOpenSuccessModal(true);
      await getData();
      dispatch(fetchClientByCodeExternal(token, clientCode));
      dispatch(fetchAllEmployeesWithoutWorkOn(token, clientCode));
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id, selectedId) => {
    try {
      await deleteWorkOn(token, { employeeIds: selectedId });
      await getData();
      dispatch(fetchClientByCodeExternal(token, clientCode));
      dispatch(fetchAllEmployeesWithoutWorkOn(token, clientCode));
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const { response } = await fetchClientCodeExternal(token);
  //       console.log(response,'external')
  //       setDataClientCodes(response);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, [token]);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        // setDataClientCode(response?.map((el) => el?.name));
        // setDataClientCode(response);
        console.log(response, 'internal')
        setDataClientCodes(response);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const persistClientName = useMemo(() => {
    const temp = window.sessionStorage.getItem('selectedClientNameInternal')
    let name;

    if (temp) {
      name = temp
    } else {
      name = selectedClient.name
    }
    return name
  }, [selectedClient])


  const persistClientCode = useMemo(() => {
    const temp = window.sessionStorage.getItem('selectedClientCodeInternal')
    if (temp) {
      return temp
    } else {
      return selectedClient.code
    }
  }, [selectedClient])

  const persistClient = useMemo(() => {
    return { name: persistClientName, codes: persistClientCode }
  }, [persistClientName, persistClientCode])


  const handleSelectedClientInternal = (e, newVal) => {
    if (newVal) {
      setSelectedClient(newVal)
      window.sessionStorage.setItem('selectedClientNameInternal', newVal?.name || '')
      window.sessionStorage.setItem('selectedClientCodeInternal', newVal?.codes || '')
      history.push(pathname + `?client_code=${newVal?.codes}&client_name=${newVal?.name}`)
    } else {
      setSelectedClient(selectedClient)
    }
  }

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Attedance</title>
      </Helmet>
      <div className={ Styles.Container }>
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#000000", fontSize: 40 }}
        >
          Work On
        </h2>
      {
        isSuper === 'true' && (
        <div className={Styles.Autocomplete}>
        <Autocomplete
          disablePortal
          id="attendee"
          value={persistClient}
          defaultValue={selectedClient}
          getOptionLabel={(option) => option.name}
          options={dataClientCodes}
          onChange={(e, newVal) => newVal !== null ? handleSelectedClientInternal(e, newVal) : handleSelectedClientInternal(e, null)}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option?.code}>
                {option?.name}
              </li>
            );
          }}
          renderInput={(params) => <MuiTextField {...params} size="small" sx={{ width: 420, background: 'var(--main-bg)' }} placeholder="Client" />
          }
        />

      </div>
        ) 
      }
      </div>
      <Grid>
        <Grid item xs={12} md={12} lg={12}>
          <TableWorkOnLayout
            datas={attendees}
            attendancePlanId={attendancePlanId}
            detaiClient={detailExternal}
            workHoursData={workHours}
            loadingWorkOn={isLoading}
            handleSetWorkOn={(datas, workId) => handleSetWorkOn(datas, workId)}
            handleDelete={(id, selectedId) => handleDelete(id, selectedId)}
          />
        </Grid>
      </Grid>
      <Dialog
        onClose={() => setOpenSuccessModal(false)}
        open={openSuccessModal}
        fullWidth
        maxWidth="xs"
      >
        <div className={Styles.ModalControl}>
          <div className={Styles.ItemTopModal}>
            <img
              src={SUCCESS_VECTOR}
              alt="success"
              className={Styles.ModalIcon}
            />
          </div>
          <div className={Styles.ContentModal}>
            <div className={Styles.TextModal}>
              <span>Setting Saved</span>
              <span>Employee list successfully added</span>
            </div>
            <div className={Styles.WrapperButtonModal}>
              <Button
                style={{
                  backgroundColor: "#1571DE",
                  textTransform: "none",
                  color: "#FFFFFF",
                  width: 100,
                  height: 40,
                  fontSize: 13,
                  fontWeight: 500,
                }}
                onClick={() => {
                  setOpenSuccessModal(false);
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </Container>
  );
}
