import React from "react";
import InputText from "../../../../utils/Input/Input";
import "./AddClientsidLoanView.css";

export default function AddClientsIdLoanViewMenu({ dataLoan }) {
  console.log(dataLoan, 'data loan')
  return (
    <div className="container-loan-add-scheme">
      <div className="wrapper-loan-add-scheme">
        <div className="row-item-loan-add-scheme">
          <span>Scheme Name</span>
          <InputText value={dataLoan?.Name || ""} disabled={true}/>
        </div>
        <div className="row-item-loan-add-scheme-desc">
          <span>Description</span>
          <InputText value={dataLoan.Description || ""} style={{ maxWidth: 650 }} disabled={true}/>
        </div>
        <div className="container-scheme-item">
          { dataLoan?.Tenor.map((data, ind) => {
            return (
              <div className="container-scheme-detail" key={ ind }>
              <div className="wrapper-scheme-detail">
                <div className="item-scheme-detail">
                  <span>Tenor</span>
                  <label>{ data?.TenorMonth }</label>
                </div>
                <div className="item-scheme-detail-loan-max">
                  <div className="wrapper-item-scheme-detail-loan-max">
                    <span>Loan Max Size</span>
                    <div className="item-sub-scheme-detail-loan-max">
                      <label>{ data?.LoanMax }</label>
                      <span>x &nbsp; Gaji Pokok</span>
                    </div>
                  </div>
                </div>
                <div className="item-scheme-detail-loan-salary">
                  <div className="wrapper-item-scheme-detail-loan-salary">
                    <span>Tanggal Gajian</span>
                  </div>
                  <div className="item-sub-scheme-deatail-loan-salary">
                    <span>Setiap Tanggal</span>
                    <p>{ dataLoan?.TanggalGajian }</p>
                    <label>/ &nbsp;Bulan</label>
                  </div>
                </div>
                { data.RangeDate?.map((sData, sInd) => {
                  return (
                    <div className="container-box-white" key={ sInd }>
                    <div className="wrapper-box-white">
                      <div className="item-box-white-left">
                        <div className="title-item-box-white-left">
                          <span>Range Date Request</span>
                          <div className="item-row-box-white-left">
                            <div className="item-row-one">
                              <span>From</span>
                              <p>{ sData?.From }</p>
                            </div>
                            <div className="item-row-two">
                              <span>to</span>
                              <p>{ sData?.To }</p>
                              <label>Dari tanggal gajian</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item-box-white-right">
                        <div className="title-item-box-white-right">
                          <span>Admin Fee</span>
                          <div className="item-row-box-white-right">
                            <div className="item-row-right-one">
                              <label>{ sData?.AdminFee }</label>
                              {/* <input type="text" /> */}
                              <span>x</span>
                              <label>{ sData?.TransferFee }</label>
                              {/* <input type="text" /> */}
                            </div>
                            <span>Biaya Tf</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )
                })}
              </div>
            </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}
