import axios from "axios";
import {API} from "../../redux/api";

const deleteInduction = (token, inductionId) =>
  new Promise(async (resolve, reject) => {
    try {
      const data  = await axios.delete(`${API}/induction/${inductionId}`, {
        headers : {
          Authorization: token
        }
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export default deleteInduction;



