/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import Styles from './TopNavExternal.module.css'
import user_image from "../../assets/images/User.png";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
// import { Logout } from "../../../redux/actions/SuperAdmin/userActions";
import { IconButton, Tooltip, Menu, MenuItem, Avatar, FormControl, Select, Autocomplete, TextField } from "@mui/material";
import SearchbarSA from "../utils/SearchbarSA/SearchbarSA";
import fetchClientCodeExternal from "../../async/client/fetchClientCodeExternal";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Style } from "@mui/icons-material";

const curr_user = {
  display_name: "Emil Fitriansyah",
  position: "Admin",
  image: user_image,
};

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const renderNotificationsItem = (item, index) => (
  <div className="notification-item" key={index}>
    <i className={item.icon}></i>
    <span>{item.content}</span>
  </div>
);

// const renderUserToggle = (user) => (
//   <div className="topnav__right-user">
//     <div className="topnav__right-user__image">
//       <img src={curr_user.image} alt="" />
//     </div>
//     <div className="topnav__right-user_name">
//       <span className="topnav__right-user_name-main">
//         {user.bio.username
//           ? user.bio.username
//           : localStorage.getItem("username")}
//       </span>
//       <span className="topnav__right-user_name-sub">
//         {user.bio.role ? user.bio.role : localStorage.getItem("role")}
//       </span>
//     </div>
//   </div>
// );

// const renderUserMenu = (item, index, logout, handleClickLink) => (
//   <div
//     className="notification-item"
//     onClick={
//       item.content === "Logout" ||
//       item.content === "Profile" ||
//       item.content === "Settings"
//         ? () => handleClickLink()
//         : null
//     }
//   >
//     <i className={item.icon}></i>
//     <span>{item.content}</span>
//   </div>
// );
export default function TopNavExternal({ onOpenSidebar }) {
  const user = useSelector((state) => state.user);
  // const pksClient = JSON.parse(localStorage.getItem('clients'));
  const [anchorLogout, setAnchorLogout] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem('accessToken')
  const { pathname } = useLocation()
  const [dataClientCodes, setDataClientCodes] = useState([]);
  const defaultClientCode = localStorage.getItem('clientCode')
  const defaultClientName = localStorage.getItem('clientName')
  const [selectedClient, setSelectedClient] = useState({
    name: defaultClientName,
    code: defaultClientCode,
  })


  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCodeExternal(token);
        setDataClientCodes(response);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [token]);


  const persistClientName = useMemo(() => {
    const temp = window.sessionStorage.getItem('selectedClientNameExternal')
    let name;

    if (temp) {
      name = temp
    } else {
      name = selectedClient.name
    }
    return name
  }, [selectedClient])


  const persistClientCode = useMemo(() => {
    const temp = window.sessionStorage.getItem('selectedClientCodeExternal')
    if (temp) {
      return temp
    } else {
      return selectedClient.code
    }
  }, [selectedClient])

  // const persistClient = useMemo(() => {
  //   return { name: persistClientName, code: persistClientCode }
  // }, [persistClientName, persistClientCode])


  // const handleSelectedClient = (e, newVal) => {
  //   if (newVal) {
  //     setSelectedClient(newVal)
  //     window.sessionStorage.setItem('selectedClientNameExternal', newVal?.name || '')
  //     window.sessionStorage.setItem('selectedClientCodeExternal', newVal?.code || '')
  //     window.localStorage.setItem('clientPks', newVal?.contractNumbers || [])
  //     history.push(pathname + `?client_code=${newVal?.code}&client_name=${newVal?.name}`)
  //   } else {
  //     setSelectedClient(selectedClient)
  //   }
  // }

  // console.log(selectedClient, 'selectedClient')
  // console.log(persistClient, 'persistClient')

  const persistClient = useMemo(() => {
    return { name_client: persistClientName, code_client: persistClientCode }
  }, [persistClientName, persistClientCode])


  const handleSelectedClient = (e, newVal) => {
    if (newVal) {
      setSelectedClient(newVal)
      window.sessionStorage.setItem('selectedClientNameExternal', newVal?.name_client || '')
      window.sessionStorage.setItem('selectedClientCodeExternal', newVal?.code_client || '')
      history.push(pathname + `?client_code=${newVal?.code_client}&client_name=${newVal?.name_client}`)
    } else {
      setSelectedClient(selectedClient)
    }
  }


  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
    // history.push('/')
    dispatch({ type: "SET_AUTHENTICATED" });
  };
  const handleClickSettings = () => {
    history.push("/admin/settings-external");
  };

  const handleOpenLogoutMenu = (event) => {
    setAnchorLogout(event.currentTarget);
  };
  const handleCloseLogout = () => {
    setAnchorLogout(null);
  };



  return (
    <div className={ Styles.TopNav }>
      <div className={ Styles.TopNavIcon }>
        <i
          className="bx bx-menu"
          onClick={onOpenSidebar}
          style={{ cursor: "pointer" }}
        ></i>
      </div>

      {/* <div className="topnav__search">
        <SearchbarSA />
      </div> */}
      <div className={Styles.Autocomplete}>
        {/* <Autocomplete
          disablePortal
          id="attendee"
          value={persistClient}
          defaultValue={selectedClient}
          getOptionLabel={(option) => option.name}
          options={pksClient}
          onChange={(e, newVal) => newVal !== null ? handleSelectedClient(e, newVal) : handleSelectedClient(e, null)}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option?.code}>
                {option?.name}
              </li>
            );
          }}
          renderInput={(params) => <MuiTextField {...params} size="small" sx={{ width: 420, background: 'var(--main-bg)' }} placeholder="Client" />
          }
        /> */}
        <Autocomplete
          disablePortal
          id="attendee"
          value={persistClient}
          defaultValue={selectedClient}
          getOptionLabel={(option) => option.name_client}
          options={dataClientCodes}
          onChange={(e, newVal) => newVal !== null ? handleSelectedClient(e, newVal) : handleSelectedClient(e, null)}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option?.code_client}>
                {option?.name_client}
              </li>
            );
          }}
          renderInput={(params) => <MuiTextField {...params} size="small" sx={{ width: 420, background: 'var(--main-bg)' }} placeholder="Client" />
          }
        />

      </div>
      <div className={ Styles.TopNavRight}>
        <div className={ Styles.TopNavRightItem }>
          {/* <Dropdown
            icon="bx bx-bell"
            badge="2"
            contentData={Notifications}
            renderItems={(item, index) => renderNotificationsItem(item, index)}
            renderFooter={() => <Link to="/">View All</Link>}
          /> */}
        </div>
        <div className={ Styles.TopNavRightItem }>
          <div className={ Styles.TopNavRightUser }>
            <div className={ Styles.Image }>
              <Tooltip title="">
                <Avatar sx={{ color: "white", backgroundColor: "black", cursor: 'pointer' }} onClick={handleOpenLogoutMenu} />
              </Tooltip>
            </div>

            <Menu
              sx={{ mt: "50px" }}
              id="menu-appbar"
              anchorEl={anchorLogout}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorLogout)}
              onClose={handleCloseLogout}
            >
              {/* <div className={Styles.NotificationItem}>
                            <i className="bx bx-cog bx-sm"></i>
                            <span>Account Settings</span>
                            <i className="bx bx-log-out-circle bx-rotate-180"></i>
                            <span>Logout</span>
                        </div> */}
              {/* <MenuItem onClick={handleClickSettings}>
                <i
                  className="bx bx-cog bx-sm"
                  style={{ fontSize: 16, marginRight: 20 }}
                ></i>
                <span style={{ fontSize: 16, font: "Poppins" }}>Settings</span>
              </MenuItem> */}
              <MenuItem onClick={handleLogout}>
                <i
                  className="bx bx-log-out-circle bx-rotate-180"
                  style={{ fontSize: 24, marginRight: 20 }}
                ></i>
                <span style={{ fontSize: 16, font: "Poppins" }}>Logout</span>
              </MenuItem>
            </Menu>
            <div className={ Styles.TopNavRightUserName }>
              <span >
                {localStorage.getItem('userName')}
              </span>
              <span >
                {/* {persistClientName} */}
                {window.localStorage.getItem('clientName')}
              </span>
              {/* <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={persistClientName}
                  label="Age"
                  onChange={handleSelectedClient}
                  variant="standard"
                  className={Styles.Select}
                  disableUnderline
                >
                  {dataClientCodes?.map((item, index) => (
                    <MenuItem key={index} value={item?.name_client}>{item?.name_client}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
