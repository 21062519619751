import { ArrowDownward, ArrowUpward, Business, Close, Event, HomeWork, PinDrop } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import StatusIcon from "../../../../../assets/images/status-icon.png";
import Styles from './Style.module.css';

import moment from 'moment';
import MiniStaticMap from '../../../../_mapWithAutoComplete/MiniMap/MiniMap';

export default function AbsenceDetailModal({
    selectedAbsence,
    setSelectedAbsence
}) {
    const handleClose = () => {
        setSelectedAbsence(null);
    }

    const coordinate = {
        Latitude: selectedAbsence?.location?.latitude,
        Longitude: selectedAbsence?.location?.longitude
    }

    return (
        <div className={Styles.Background}>
            <div className={Styles.Container}>
                <div className={Styles.Header}>
                    <span>Absence Detail</span>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </div>
                <div className={Styles.Content}>
                    <div className={Styles.BoxContent}>
                        <div className={Styles.BoxLeft}>
                            <Event className={Styles.Icon} />
                            <span>Date</span>
                        </div>
                        <div className={Styles.BoxRight}>
                            <span>{moment(selectedAbsence?.date).format("LL")}</span>
                        </div>
                    </div>
                    <div className={Styles.BoxContent}>
                        <div className={Styles.BoxLeft}>
                            <ArrowDownward className={Styles.Icon} />
                            <span>Time In</span>
                        </div>
                        <div className={Styles.BoxRight}>
                            <span>{selectedAbsence?.startTime || '-'}</span>
                        </div>
                    </div>
                    <div className={Styles.BoxContent}>
                        <div className={Styles.BoxLeft}>
                            <ArrowUpward className={Styles.Icon} />
                            <span>Time Out</span>
                        </div>
                        <div className={Styles.BoxRight}>
                            <span>{selectedAbsence?.endTime || '-'}</span>
                        </div>
                    </div>
                    <div className={Styles.BoxContent}>
                        <div className={Styles.BoxLeft}>
                            <img src={StatusIcon} alt="" className={Styles.IconImg} />
                            <span>Status</span>
                        </div>
                        <div className={Styles.BoxRight}>
                            <span>{selectedAbsence?.type}</span>
                        </div>
                    </div>
                    <div className={Styles.BoxContent}>
                        <div className={Styles.BoxLeft}>
                            <img src={StatusIcon} alt="" className={Styles.IconImg} />
                            <span>Overtime</span>
                        </div>
                        <div className={Styles.BoxRight}>
                            <span>{selectedAbsence?.overtime?.approverId !== null ? 'Yes' : 'No'}</span>
                            {selectedAbsence?.image && (
                                <div className={Styles.AbsenceImageContainer}>
                                    <img src={selectedAbsence?.image} className={Styles.AbsenceImage} alt="absence" />
                                </div>
                            )}
                        </div>
                    </div>
                    {
                        selectedAbsence?.reason && (
                    <div className={Styles.BoxContent}>
                        <div className={Styles.BoxLeft}>
                            <img src={StatusIcon} alt="" className={Styles.IconImg} />
                            <span>Reason</span>
                        </div>
                        <div className={Styles.BoxRight}>
                            <span>{selectedAbsence?.reason}</span>
                        </div>
                    </div>
                        )
                    }
                    <div className={Styles.BoxContent}>
                        <div className={Styles.BoxLeft}>
                            <PinDrop className={Styles.Icon} />
                            <span>Location</span>
                        </div>
                        <div className={Styles.BoxRight}>
                            {(coordinate?.Latitude !== null && coordinate?.Longitude !== null) ? (
                                <>
                                    <div className={Styles.LocationHeader}>
                                        {selectedAbsence?.isWorkFromHome === false ? <Business className={Styles.WorkplaceIcon} /> : <HomeWork className={Styles.WorkplaceIcon} />}
                                        <span>{selectedAbsence?.isWorkFromHome === false ? 'Work from office' : 'Work from home'}</span>
                                    </div>
                                    <div className={Styles.MapContainer}>
                                        <MiniStaticMap coordinate={coordinate} />
                                    </div></>
                            ) : (
                                <span>-</span>
                            )}



                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}