import * as types from '../../type'

const initialstate = {
  dataAllDrafts: [],
  dataAlldDraftById: {},
  loadingDataDraft: false,
  errorMessageDraft: {}
};

const draftsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_DRAFT_BEGIN: {
      return {
        ...state,
        loadingDataDraft: true
      };
    }
    case types.FETCH_ALL_DRAFT_SUCCESS: {
      return {
        ...state,
        loadingDataDraft: false,
        dataAllDrafts: action.payload,
      };
    }
    case types.FETCH_ALL_DRAFT_ERROR: {
      return {
        ...state,
        loadingDataDraft: false,
        errorMessageDraft: action.payload
      };
    }
    case types.FETCH_DRAFT_BY_ID: {
      return {
        ...state,
        dataAllFaqById: action.payload,
      };
    }
    default:
      return state;
  }
};

export default draftsReducer;
