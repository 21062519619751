import { IconButton } from '@mui/material';
import Styles from './ModalFeaturePreview.module.css';
import { Close } from '@mui/icons-material';
import PREVIEW_KASBON from '../../../../assets/images/preview-kasbon.png'
import PREVIEW_GADAI from '../../../../assets/images/preview-gadai.png'

export default function ModalFeaturePreview({ type, setModal }) {
    return (
        <div className={Styles.Modal}>
            <div className={Styles.ModalContainer}>
                <div className={Styles.ModalBody}>
                    <div className={Styles.Title}>
                        <span>Preview Fitur MyWorkSpace</span>
                        <IconButton onClick={() => setModal(null)}>
                            <Close />
                        </IconButton>
                    </div>
                    <div className={Styles.Body}>
                        <div className={Styles.Component}>
                            {type === 'Kasbon' && 
                                <img src={PREVIEW_KASBON} alt='preview_kasbon'/>
                            }
                            {type === 'Gadai' && 
                                <img src={PREVIEW_GADAI} alt='preview_gadai'/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
