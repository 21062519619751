/* eslint-disable no-unused-vars */
import { AddCircleOutline } from '@mui/icons-material';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import { deleteBank } from '../../../../redux/actions/SuperAdmin/bankActions';
import LoadingAnimation from '../../../utils/LoadingAnimation/LoadingAnimation';
import SearchbarSA from '../../../utils/SearchbarSA/SearchbarSA';
import CustomTablePagination from '../../../_pagination/CustomTablePagination';
import Scrollbar from '../../_user/TableUsers/Scrollbar';
import Styles from './BankTable.module.css';
import DeleteBankModal from './DeleteBankModal/DeleteBankModal';


const TABLE_HEAD = [
    { id: 'name', numeric: false, label: 'Bank Name', alignRight: false },
    { id: 'code', numeric: false, label: 'Bank ID Code', alignRight: false },
    { id: 'swiftCode', numeric: false, label: 'Swift Code', alignRight: false },
]

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
        deleteMode
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                "& tr": {
                    backgroundColor: "#F0F0F0",
                },
            }}
        >
            <TableRow>


                {TABLE_HEAD.map((headCell, index) => (
                    <TableCell
                        key={headCell.id + index}
                        align="left"
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className={Styles.TableSortLabel}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {deleteMode && (
                    <TableCell></TableCell>
                )}

            </TableRow>
        </TableHead>
    );
}

export default function BankTable({ banks, isLoading }) {
    const history = useHistory()
    const dispatch = useDispatch()
    const token = localStorage.getItem("accessToken");
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("clientCode");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [listDataBanks, setListDataBanks] = useState("");
    const [deleteMode, setDeleteMode] = useState(false)
    const [selectedBank, setSelectedBank] = useState(null)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSearch = (event) => {
        setPage(1)
        setListDataBanks(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = (id) => {
        dispatch(deleteBank(token, id))
    }


    return (
        <div className={Styles.Container}>
            <div className={Styles.HeaderWrapper}>
                <div className={Styles.Searchbar}>
                    <SearchbarSA
                        value={listDataBanks}
                        onChange={handleSearch}
                        searchWidth="379px"
                        placeholder="Search"
                    />
                </div>
                <div className={Styles.ButtonWrapper}>
                    <Button onClick={() => setDeleteMode(!deleteMode)} variant="outlined" className={Styles.EditButton}>{!deleteMode ? 'Edit' : 'Exit Edit Mode'}</Button>
                    <Button onClick={() => history.push('/superadmin/add-bank')} startIcon={<AddCircleOutline />} variant="outlined" className={Styles.AddButton}>Add Bank Data</Button>
                </div>
            </div>
            {
                isLoading ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 50,
                        }}
                    >
                        <div style={{ width: 200 }}>
                            <LoadingAnimation />
                        </div>
                    </div>
                ) : (
                    <div className={Styles.TableWrapper}>
                        <Scrollbar>
                            <TableContainer className={Styles.TableContainer}>
                                <Table id="emp-table" className={Styles.Table}>
                                    <colgroup>
                                        <col style={{ width: '50%' }} />
                                        <col style={{ width: '25%' }} />
                                        <col style={{ width: '25%' }} />
                                        {deleteMode && <col style={{ width: '5%' }} />}
                                    </colgroup>
                                    <EnhancedTableHead
                                        numSelected={banks.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        values={banks}
                                        deleteMode={deleteMode}
                                    />

                                    <TableBody>
                                        {stableSort(banks, getComparator(order, orderBy))
                                            .filter((el) =>
                                                el.name?.toLowerCase().includes(listDataBanks.toLowerCase())
                                                ||
                                                el?.code.toLowerCase().includes(listDataBanks.toLowerCase())
                                                ||
                                                el?.swiftCode.toLowerCase().includes(listDataBanks.toLowerCase())
                                            )
                                            .slice((page - 1)* rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                                            .map((each, i) => {
                                                return (
                                                    <TableRow hover key={'tablebody' + i} tabIndex={-1}>


                                                        <TableCell component="th" scope="row" sx={{ textTransform: "capitalize" }} className={Styles.TableCell}>
                                                            <Link
                                                                to={{
                                                                    pathname: `/superadmin/detail-bank/${each._id}`,
                                                                    state: { data: each }
                                                                }}
                                                            >
                                                                {each?.name ? each?.name : '-'}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell align="left" className={Styles.TableCell}>
                                                            {each?.code ? each?.code : "-"}
                                                        </TableCell>
                                                        <TableCell align="left" className={Styles.TableCell}>
                                                            {each?.swiftCode ? each?.swiftCode : '-'}
                                                        </TableCell>
                                                        {deleteMode && (
                                                            <TableCell className={Styles.DeleteCell}>
                                                                <button onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setSelectedBank(each)
                                                                }}>
                                                                    <img src={DELETE_ICON} alt="delete" />
                                                                </button>
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Scrollbar>
                        <CustomTablePagination
                            limit={rowsPerPage}
                            setLimit={setRowsPerPage}
                            page={page}
                            setPage={setPage}
                            totalPage={Math.ceil(banks.filter((el) =>
                                el.name?.toLowerCase().includes(listDataBanks.toLowerCase())
                                ||
                                el?.code.toLowerCase().includes(listDataBanks.toLowerCase())
                                ||
                                el?.swiftCode.toLowerCase().includes(listDataBanks.toLowerCase())
                            ).length / rowsPerPage)}
                        />

                    </div>
                )
            }

            {selectedBank && (
                <DeleteBankModal
                    active={selectedBank}
                    onOpen={setSelectedBank}
                    description="Are you sure you want to delete this bank?"
                    label="Delete Bank Data"
                    pathName="/superadmin/database"
                />
            )}
        </div>
    )
}