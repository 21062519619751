import React, { useState } from "react";
import "./MasterCompetitor.css";
import SearchbarFMCG from "../../SearchbarFMCG/SearchbarFMCG";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import { useHistory } from "react-router";
import TrashIcon from "../../../../assets/images/trash-2.png";
import EditIcon2 from "../../../../assets/images/new-edit-icon.png";
import ModalDeleteFMCG from "../../ModalDeleteFMCG/ModalDeleteFMCG";
import LoadingFMCG from "../../../utils/LoadingFMCG/LoadingFMCG";

const TABLE_HEAD = [
  { id: "CompetitorName", label: "Competitor Name", alignRight: false },
  { id: "Code", label: "Code", alignRight: false },
  { id: "Status", label: "Status", alignRight: false },
  { id: "" },
];

const data = [
  { id: 1, CompetitorName: "Green Valley ", Code: "1", Status: "Active" },
  { id: 2, CompetitorName: "Green Valley ", Code: "20", Status: "Active" },
  { id: 3, CompetitorName: "Green Valley ", Code: "31", Status: "Active" },
  { id: 4, CompetitorName: "Green Valley ", Code: "25", Status: "Active" },
  { id: 5, CompetitorName: "Green Valley ", Code: "19", Status: "Active" },
  { id: 6, CompetitorName: "Green Valley ", Code: "10", Status: "Active" },
  { id: 7, CompetitorName: "Green Valley ", Code: "20", Status: "Active" },
  { id: 8, CompetitorName: "Green Valley ", Code: "22", Status: "Active" },
  { id: 9, CompetitorName: "Green Valley ", Code: "10", Status: "Active" },
  { id: 10, CompetitorName: "Green Valley ", Code: "41", Status: "Active" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& th": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        <TableCell padding="none" style={{ paddingLeft: 0 }}>
          {/* <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              disabled={rowCount !== values?.users?.length}
              sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
            /> */}
        </TableCell>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const MasterCompetitor = () => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  // const [selected, setSelected] = useState("");
  const [orderBy, setOrderBy] = useState("StoreName");
  // const [selectedPosition, setSelectedPosition] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listCompetitor, setListCompetitor] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [openModalDelete, setOpenModalDelete] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const handleSearch = (event) => {
    setListCompetitor(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="ContainerMasterCompetitor">
      <div className="WrapperMasterCompetitor">
        <div className="HeaderMasterCompetitor">
          <div className="SearchbarMasterCompetitor">
            <SearchbarFMCG
              value={listCompetitor}
              onChange={handleSearch}
              searchWidth="379px"
              placeholder="Search Competitor"
            />
          </div>
          <div className="ButtonUploadAction">
            <Button
              style={{
                textTransform: "none",
                width: 129,
                height: 40,
                background: "#FFFFFF",
                border: "1px solid #D3C9C9",
                borderRadius: 8,
                fontWeight: 500,
                fontSize: 13,
                color: "#1571DE",
              }}
              startIcon={
                <i className="ri-upload-line" style={{ fontSize: 20 }} />
              }
            >
              Upload Excel
            </Button>
            <Button
              style={{
                textTransform: "none",
                width: 148,
                height: 40,
                background: "#FFFFFF",
                border: "1px solid #D3C9C9",
                borderRadius: 8,
                fontWeight: 500,
                fontSize: 13,
                color: "#1571DE",
              }}
              startIcon={
                <i className="ri-download-line" style={{ fontSize: 20 }} />
              }
            >
              Download Excel
            </Button>
          </div>
          <div className="ButtonAddCompetitor">
            <Button
              startIcon={<AddIcon style={{ fontSize: 20 }} />}
              style={{
                textTransform: "none",
                fontSize: 13,
                fontWeight: 500,
                color: "#FFFFFF",
                backgroundColor: "#1571DE",
                borderRadius: 5,
                height: 40
              }}
              // onClick={ () => history.push('/admin-fmcg/add-competitor')}
              onClick={() =>
                history.push({
                  pathname: `/admin-fmcg/setup/competitor/add`,
                  state: {
                    location: "addCompetitorFMCG",
                  },
                })
              }
            >
              Competitor
            </Button>
          </div>
        </div>

        <div className="ContainerTableMasterCompetitor">
          <Card>
            {loading ? (
              <div className="ContainerAttendanceFMCGLoading">
                <LoadingFMCG width={800} height={200} />
              </div>
            ) : (
              <Scrollbar>
                <TableContainer>
                  <Table
                    sx={{
                      width: "100%",
                      maxHeight: "100%",
                    }}
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: 5,
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <EnhancedTableHead
                      // numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      // onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      // values={data}
                      rowCount={
                        data?.filter(
                          (el) =>
                            el.CompetitorName?.toLowerCase().includes(
                              listCompetitor.toLowerCase()
                            ) ||
                            el.Code?.toLowerCase().includes(
                              listCompetitor.toLowerCase()
                            ) ||
                            el.Status?.toLowerCase().includes(
                              listCompetitor.toLowerCase()
                            )
                        ).length
                      }
                    />

                    <TableBody>
                      {stableSort(data, getComparator(order, orderBy))
                        .filter(
                          (el) =>
                            el.CompetitorName?.toLowerCase().includes(
                              listCompetitor.toLowerCase()
                            ) ||
                            el.Code?.toLowerCase().includes(
                              listCompetitor.toLowerCase()
                            ) ||
                            el.Status?.toLowerCase().includes(
                              listCompetitor.toLowerCase()
                            )
                        )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((each, ind) => {
                          // const isItemSelected = isSelected(employee?._id);
                          const labelId = `enhanced-table-checkbox-${ind}`;
                          return (
                            <TableRow
                              hover
                              key={ind}
                              tabIndex={-1}
                              id={labelId}
                              sx={{
                                "&.MuiTableRow-root:hover": {
                                  backgroundColor: "#eef6ff",
                                },
                              }}
                            >
                              <TableCell
                                padding="none"
                                style={{ paddingLeft: 10, marginRight: 10 }}
                              ></TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                //   padding="none"
                              >
                                {/* <Link
                                to={{
                                  pathname: `/admin-fmcg/details-promo-fmcg/1`,
                                  state: { location: "detailsPromoFMCG" },
                                }}
                              > */}
                                {/* <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                   
                                </Stack> */}
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {each.CompetitorName
                                    ? each.CompetitorName.toLowerCase()
                                    : "-"}
                                </Typography>
                                {/* </Link> */}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {each.Code ? each.Code : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {each.Status === "Active" ? (
                                  <Typography
                                    style={{
                                      backgroundColor: "#E4FFE6",
                                      color: "#17B51E",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 8,
                                      height: 26,
                                      width: 58,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#17B51E",
                                      }}
                                    >
                                      Active
                                    </Typography>
                                  </Typography>
                                ) : (
                                  <Typography
                                    style={{
                                      backgroundColor: "#FFDFDD",
                                      // color: "#17B51E",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 8,
                                      height: 26,
                                      width: 74,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#B52117",
                                      }}
                                    >
                                      Unactive
                                    </Typography>
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell align="left">
                                <Stack
                                  direction="row-reverse"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography
                                    style={{
                                      width: 30,
                                      height: 30,
                                      backgroundColor: "#ECF5FF",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 5,
                                    }}
                                    component="div"
                                  >
                                    <img
                                      src={TrashIcon}
                                      alt=""
                                      style={{
                                        width: 20,
                                        height: 20,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => setOpenModalDelete(true)}
                                    />
                                  </Typography>
                                  <Typography
                                    style={{
                                      width: 30,
                                      height: 30,
                                      backgroundColor: "#ECF5FF",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 5,
                                    }}
                                    component="div"
                                  >
                                    <img
                                      src={EditIcon2}
                                      alt=""
                                      style={{
                                        width: 20,
                                        height: 20,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        history.push({
                                          pathname: `/admin-fmcg/edit-competitor-fmcg/1`,
                                          state: {
                                            location: "editCompetitorFMCG",
                                          },
                                        })
                                      }
                                    />
                                  </Typography>
                                </Stack>
                              </TableCell>
                              {/* <TableCell align="right">
                              <Typography
                                style={{
                                  width: 30,
                                  height: 30,
                                  backgroundColor: "#ECF5FF",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: 5,
                                  marginLeft: "auto",
                                }}
                                component="div"
                                onClick={() => setOpenModalDelete(true)}
                              >
                                <img
                                  src={TrashIcon}
                                  alt=""
                                  style={{
                                    width: 20,
                                    height: 20,
                                    cursor: "pointer",
                                  }}
                                />
                              </Typography>
                            </TableCell> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            )}

            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={
                data?.filter(
                  (el) =>
                    el.CompetitorName?.toLowerCase().includes(
                      listCompetitor.toLowerCase()
                    ) ||
                    el.Code?.toLowerCase().includes(
                      listCompetitor.toLowerCase()
                    ) ||
                    el.Status?.toLowerCase().includes(
                      listCompetitor.toLowerCase()
                    )
                ).length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </div>
      </div>

      {openModalDelete && (
        <ModalDeleteFMCG
          modalDelete={openModalDelete}
          setModalDelete={setOpenModalDelete}
        />
      )}
    </div>
  );
};

export default MasterCompetitor;
