import { Button, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
// import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import getClientNameExternal from "../../../async/client/getClientNameExt";
import LocationDetailExternal from "../../../components/External/Location/LocationDetail/LocationDetail";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
import { fetchLocationById } from "../../../redux/actions/External/locationActions";

export default function LocationDetail() {
  const token = localStorage.getItem('accessToken');
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { locationById, isLoadingLocationById } = useSelector((state) => state.locationsExternal);


  const[clientName, setClientName] = useState("")

  useEffect(() => {
    (async () => {
      try {
        const name = await getClientNameExternal(token, locationById?.clientCode);
        setClientName(name)
      } catch (error) {
        console.log()
      }
    }
    )()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationById])



  useEffect(() => {
    dispatch(fetchLocationById(token, id))
  }, [dispatch, token, id])


  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Location</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/admin/location-external")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Location</span>
            </Button>
            <span style={{ fontSize: 20, color: "#255bfc" }}>{locationById?.name}</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <LocationDetailExternal location={locationById} clientName={clientName} locationId={id} isLoading={isLoadingLocationById} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
