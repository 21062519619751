import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../../hook/useDebounce";
import { useEffect } from "react";
import { fetchAllEBPKKB } from "../../../../redux/actions/Business/ebpBusinessActions";
import { Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { LayoutListEBPKKB } from "../../../../components/Business/_ebp";

export default function EBPKkb(){
    const token = localStorage.getItem("accessToken");
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const debounceSearh = useDebounce(searchTerm, 500)
    const { allDataEBPKKB, loadingDataEBP } = useSelector((state) => state.ebpBusiness)

    useEffect(() => {
        dispatch(fetchAllEBPKKB(token, page, limit, debounceSearh))
    }, [dispatch, token, page, limit, debounceSearh])
    
    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>MyWorkSpace | EBP KKB</title>
            </Helmet>
            <div className="wrapper-client-SA">
              <h2 className="page-header" style={{ fontWeight: 500, color: '#0A0A0A', fontSize: 40 }}>
                  KKB
              </h2>
          </div>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <LayoutListEBPKKB
                    data={allDataEBPKKB?.data}
                    loading={loadingDataEBP}
                    page={page}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    totalPage={allDataEBPKKB?.totalPages || 1}
                    totalData={allDataEBPKKB?.totalData || 1}
                    token={token}
                />
            </Grid>
          </Grid>
        </Container>
    )
}