import { useMemo } from "react";

export const DOTS = '...'

export const usePagination = ({
    totalPage,
    // totalCount,
    // pageSize,
    siblingCount = 1,
    page
}) => {
    const paginationRange = useMemo(() => {
        // Our implementation logic will go here 
        // const totalPageCount = Math.ceil(totalCount / pageSize);
        const totalPageCount = totalPage;
        const currentPage = page;
        const range = (start, end) => {
            let length = end - start + 1;
            /*
                Create an array of certain length and set the elements within it from
              start value to end value.
            */
            return Array.from({ length }, (_, idx) => idx + start);
        };

        const totalPageNumbers = siblingCount + 5;
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPageCount
        );

        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {

            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }

    }, [totalPage, siblingCount, page]);
    return paginationRange;
};