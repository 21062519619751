import React from "react";
import Styles from "./ModalSuccessEmployee.module.css";
import FILE_TRANSFER from "../../../../../assets/images/file-transfer.png";
export default function ModalSuccessEmployee({ onOpen, isActive }) {
  const handleClick = () => {
    onOpen(false);
  };
  return (
    <div className={Styles.Background}>
      <div className={Styles.Container}>
        <img src={FILE_TRANSFER} alt="file-transfer" />
        <div className={Styles.Desc}>
          <span>Tersimpan</span>
          <span>Sisa cuti karyawan berhasil tersimpan</span>
        </div>
        <button className={Styles.OkButton} onClick={handleClick}>
          Ok
        </button>
      </div>
    </div>
  );
}
