/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./index.css";
import { Helmet } from "react-helmet";
import logo_MyWorkSpace from "../../../../assets/images/my-workspace.png";
import { useHistory } from "react-router";
import ExportCSV from "../../../../components/SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import DownloadIcon from "@mui/icons-material/Download";
import DateRangePickerComp from "../../../../components/SuperAdmin/_attendance/date-range-picker/DateRangePicker";
import { visuallyHidden } from "@mui/utils";
import {
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Stack,
  Avatar,
} from "@mui/material";

function createData(
  coordinatePoint,
  storeName,
  owner,
  newCustomer,
  employee,
  product,
  totalPenjualan
) {
  return {
    coordinatePoint,
    storeName,
    owner,
    newCustomer,
    employee,
    product,
    totalPenjualan,
  };
}

const rows = [
  createData("1.11.3345.133", "Rp. 12.000", "99 Karton", ""),
  createData("1.11.3345.133", "Rp. 11.000", "100 Karton"),
  createData("1.11.3345.133", "Rp. 10.000", "40 Karton"),
  createData("1.11.3345.133", "Rp. 9.000", "70 Karton"),
  createData("1.11.3345.133", "Rp. 8.000", "150 Karton"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "coordinatePoint",
    numeric: false,
    disablePadding: false,
    label: "Coordinate Point",
  },
  {
    id: "storeName",
    numeric: true,
    disablePadding: false,
    label: "Store Name",
  },
  {
    id: "owner",
    numeric: true,
    disablePadding: false,
    label: "Owner",
  },
  {
    id: "newCustomer",
    numeric: true,
    disablePadding: false,
    label: "New Customer",
  },
  {
    id: "employee",
    numeric: true,
    disablePadding: false,
    label: "Employee",
  },
  {
    id: "product",
    numeric: true,
    disablePadding: false,
    label: "Product",
  },
  {
    id: "totalPenjualan",
    numeric: true,
    disablePadding: false,
    label: "Total Penjualan",
  },
  // {
  //   id: "",
  // },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const fontStyle = {
  main: {
    label: {
      fontSize: 14,
      fontWeight: 400,
      color: "#000000",
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: "#000000",
  },
  span : {
    fontSize: 12,
    fontWeight: 400,
    color: "#616161",
  },
  penjualan : {
    fontSize: 16,
    fontWeight: 500,
    color: "#000000",
  }
};

export default function StoreReport() {
  // eslint-disable-next-line no-unused-vars
  const token = localStorage.getItem("accessToken");
  const history = useHistory();
  const startDate = new Date(1648141200000);
  const endDate = new Date(1649869200000);
  const [valueDate, setValueDate] = useState([startDate, endDate]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("productName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const handleChangeDate = (newValue) => {
  //   setValueDate(newValue);
  //   dispatch(
  //     fetchAllDataEmployeeByRange(
  //       token,
  //       new Date(valueDate[0]).getTime(),
  //       new Date(valueDate[1]).getTime()
  //     )
  //   );
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, coordinatePoint) => {
    const selectedIndex = selected.indexOf(coordinatePoint);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, coordinatePoint);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const isSelected = (productName) => selected.indexOf(productName) !== -1;

  return (
    <div className="store__report-container">
      <Helmet>
        <title>MyWorkSpace | Store Report</title>
      </Helmet>
      <div className="store__report-wrapper">
        <div className="store__report-item-wrapper">
          <div
            className="store__report-item-left"
            onClick={() => history.push(`/admin/store-external`)}
          >
            <img src={logo_MyWorkSpace} alt="" />
            <span>MyWorkSpace</span>
          </div>
          <div className="store__report-item">
            <span>Store Report</span>
          </div>
          <div className="store__report-download">
            <ExportCSV
              // data={
              // dataToExport
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              // .map((el , ind ) => ({
              // "No. ": ind + 1,
              // Nama: el.Name,
              // Position: el.Position,
              // Nrk: el.Nrk,
              // 'Total Attend': el.totalPresence,
              // 'Total Sick': el.totalSickPermit,
              // 'Total Leave': el.totalAnnualLeave,
              // }))
              // }
              filename={`Attendance Report ${new Date().toLocaleDateString()}`}
              initial="Download"
              endIcon={<DownloadIcon />}
            />
          </div>
        </div>
        <div className="store__report-date-picker">
          <div className="wrapper__date-picker">
            <DateRangePickerComp
              valueDate={valueDate}
              onChangeDate={() => {}}
            />
          </div>
        </div>
        <div className="store__report-company-name">
          <div className="company__name-title">
            <label htmlFor="">Company</label>
            <span>PT Permata Indonesia</span>
          </div>
        </div>
        <div className="store__report-featured">
          <div className="featured__item">
            <label htmlFor="">Total Store</label>
            <span>230 Store</span>
          </div>
          <div className="featured__item">
            <label htmlFor="">Best Store</label>
            <span>Indomaret </span>
          </div>{" "}
          <div className="featured__item">
            <label htmlFor="">Total Customer</label>
            <span>1200 Cust</span>
          </div>{" "}
          <div className="featured__item">
            <label htmlFor="">Total New Customer</label>
            <span>30 Store</span>
          </div>
        </div>
        <div className="store__report-container-table">
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", backgroundColor: "#FAFAFA" }}>
              <TableContainer>
                <Table sx={{ width: "100%" }} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              handleClick(event, row.coordinatePoint)
                            }
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell></TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  alt={row.coordinatePoint}
                                  src=""
                                  variant="square"
                                />
                                <Typography variant="subtitle2" noWrap style={fontStyle.main}>
                                  {row.coordinatePoint}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Typography style={fontStyle.main}>Giant sumatera utara</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography style={fontStyle.main}>Anastasya Priscillia</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Stack
                                direction="column"
                              >
                                <Typography style={ fontStyle.label }>Salsabila Alexandria</Typography>
                                <Typography style={ fontStyle.span }>Sales Promotion Girl</Typography>
                              </Stack>
                              <Stack
                                direction="column"
                              >
                                <Typography style={ fontStyle.label }>Salsabila Alexandria</Typography>
                                <Typography style={ fontStyle.span }>
                                  Sales Promotion Girl
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Stack
                                direction="column"
                              >
                                <Typography style={ fontStyle.label }>Salsabila alexandra</Typography>
                                <Typography style={ fontStyle.span }>Sales Promotion Girl</Typography>
                              </Stack>
                              <Stack
                                direction="column"
                              >
                                <Typography style={ fontStyle.label }>Salsabila alexandra</Typography>
                                <Typography style={ fontStyle.span }>Sales Promotion Girl</Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                            <Stack
                                direction="column"
                              >
                                <Typography style={ fontStyle.label }>Susu Bendera</Typography>
                                <Typography style={ fontStyle.span }>230 Carton available</Typography>
                              </Stack>
                              <Stack
                                direction="column"
                              >
                                <Typography style={ fontStyle.label }>Susu Bendera</Typography>
                                <Typography style={ fontStyle.span }>230 Carton available</Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Typography style={ fontStyle.penjualan }> Rp. 140.000.000</Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </div>
      </div>
    </div>
  );
}
