import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { Stack, Typography } from "@mui/material";
import "./index.css";
import { Typography } from "@mui/material";

export default function DateRangePickerComponent({ valueStartDate, valueEndDate, handleChangeStartDate, handleChangeEndDate }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="wrapper-date-range-picker">
        {/* <Stack direction="row" spacing={0.5}> */}
          <DatePicker
            value={valueStartDate}
            onChange={handleChangeStartDate}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: 150 }}
                size="small"
                autoComplete="off"
              />
            )}
          />
          <div className="wrapper-text-date-range-picker">
            <Typography>To</Typography>
          </div>
          <DatePicker
            value={valueEndDate}
            onChange={handleChangeEndDate}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: 150 }}
                size="small"
                autoComplete="off"
              />
            )}
          />
        {/* </Stack> */}
      </div>
    </LocalizationProvider>
  );
}
