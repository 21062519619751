import axios from "axios";
import { useEffect, useState } from "react";
import {API} from "../../../redux/api";

function UseGetAllBank() {
  const [bankName, setBankName] = useState([]);
  const token = localStorage.getItem("accessToken");
  useEffect(() => {
    axios
      .get(`${API}/bank`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => setBankName(res.data.response))
      .catch((err) => setBankName([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return bankName;
}

export default UseGetAllBank;
