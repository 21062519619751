import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab  } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import "./StoreSetupFMCG.css";
import SPGStore from "./SPG-Store/SPGStore";
import MDStore from "./MD-Store/MDStore";
import StoreAccountGroup from "./StoreAccountGroup/StoreAccountGroup";
import Area from "./Area/Area";
// import SPGShifting from "./SPGShifting/SPGShifting";
// import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  TabStyle: {
    padding: 0,
    marginTop: 30,
  },
}));

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

function LabelSpgStore() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        columnGap: 5,
      }}
    >
      <i className="ri-store-3-line" style={{ fontSize: 22 }} />
      <span style={{ fontSize: 16, fontWeight: 600 }}>SPG Store</span>
    </div>
  );
}

function LabelMDStore() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        columnGap: 5,
      }}
    >
      <i className="ri-store-3-line" style={{ fontSize: 22 }} />
      <span style={{ fontSize: 16, fontWeight: 600 }}>MD Store</span>
    </div>
  );
}

function LabelStoreAccountGroup() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        columnGap: 5,
      }}
    >
      <i className="ri-store-2-fill" style={{ fontSize: 22 }} />
      <span style={{ fontSize: 16, fontWeight: 600 }}>Store Account Group</span>
    </div>
  );
}

function LabelArea() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        columnGap: 5,
      }}
    >
      <i className="ri-map-2-fill" style={{ fontSize: 22 }} />
      <span style={{ fontSize: 16, fontWeight: 600 }}>Area</span>
    </div>
  );
}

// function LabelSetting() {
//   return (
//     <div
//       style={{
//         display: "flex",
//         alignItems: "center",
//         textTransform: "none",
//         columnGap: 5,
//       }}
//     >
//       <i className="ri-user-settings-fill" style={{ fontSize: 22 }} />
//       <span style={{ fontSize: 16, fontWeight: 600 }}>Shifting</span>
//     </div>
//   );
// }

const StoreSetupFMCG = () => {
  const classes = useStyles();
  // const location = useLocation();
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="StoreSetupMaxHeight">
      <TabContext value={value}>
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px  solid #E9E9E9",
          }}
        >
          <TabList
            onChange={handleChange}
            sx={{
              "& button:hover": {
                color: "#1571DE",
              },
              "& button:focus": {
                color: "#1571DE",
              },
              "& button:active": {
                color: "#1571DE",
              },
            }}
          >
            <Tab
              label={<LabelSpgStore />}
              value="1"
              sx={{
                color: "#A2A9BE",
              }}
            />
            <Tab
              label={<LabelMDStore />}
              value="2"
              sx={{
                color: "#A2A9BE",
              }}
            />
            <Tab
              label={<LabelStoreAccountGroup />}
              value="3"
              sx={{
                color: "#A2A9BE",
              }}
            />
            <Tab
              label={<LabelArea />}
              value="4"
              sx={{
                color: "#A2A9BE",
              }}
            />
            {/* <Tab
              label={<LabelSetting />}
              value="5"
              sx={{
                color: "#A2A9BE",
              }}
            /> */}
          </TabList>
        </Box>
        <TabPanel value="1" className={classes.TabStyle}>
          <SPGStore />
        </TabPanel>
        <TabPanel value="2" className={classes.TabStyle}>
          <MDStore />
        </TabPanel>
        <TabPanel value="3" className={classes.TabStyle}>
          <StoreAccountGroup />
        </TabPanel>{" "}
        <TabPanel value="4" className={classes.TabStyle}>
          <Area />
        </TabPanel>{" "}
        {/* <TabPanel value="5" className={classes.TabStyle}>
          <SPGShifting />
        </TabPanel> */}
      </TabContext>
    </div>
  );
};

export default StoreSetupFMCG;
