import {
  Box,
  Typography,
  Stack,
  Autocomplete,
  TextField,
  Card,
  TablePagination,
  TableCell,
  Avatar,
  TableRow,
  TableHead,
  TableSortLabel,
  TableContainer,
  Table,
  TableBody,
} from "@mui/material";
import { styled } from "@mui/styles";
import React, { useState } from "react";
import SearchbarFMCG from "../../../SearchbarFMCG/SearchbarFMCG";
import Styles from "./DisplayReport.module.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ExportDataExcelFMCG from "../../../../utils/ExportCSVFMCG";
import DownloadIcon from "@mui/icons-material/Download";
import { visuallyHidden } from "@mui/utils";
import LoadingFMCG from "../../../../utils/LoadingFMCG/LoadingFMCG";
import Scrollbar from "../../../../SuperAdmin/_user/TableUsers/Scrollbar";
import STORE_PHOTO from "../../../../../assets/images/store-product.jpg";

// const WrapperStyle = styled(Container)(() => ({
//   paddingInline: 0,
//   width: "100%",
//   maxHeight: "100%",
//   height: "100%",
//   minHeight: "calc(100vh - 130px)",
// }));

const HeaderSelectionStyle = styled("div")(() => ({
  background: "#FFFFFF",
  borderRadius: 5,
  padding: 16,
}));

const TypographyStyle = styled(Typography)(() => ({
  color: "#0A0A0A",
  fontSize: 13,
  fontWeight: 500,
}));

const MuiAutoComplete = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
  "& .MuiInputBase-input-MuiOutlinedInput-input": {
    padding: 0,
  },
  "& input": {
    color: "#0A0A0A",
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "18.2px",
    opacity: 1,
  },
  "& input::placeholder": {
    color: "#A4A4A4",
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "18.2px",
    opacity: 1,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    // border: "1.5px solid #d3c9c9",
    // borderColor: "#d3c9c9",
    borderRadius: 5,
  },
});

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      width: "100%",
    },
    "& .MuiInputBase-input-MuiOutlinedInput-input": {
      padding: 0,
    },
    "& input::placeholder": {
      color: "#A4A4A4",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "18.2px",
      opacity: 1,
    },
    "& Svg": {
      color: "#1571DE",
    },
  },
});

const TABLE_HEAD = [
  { id: "name", label: "Employee", alignRight: false },
  { id: "leader", label: "Leader", alignRight: false },
  { id: "store", label: "Store", alignRight: false },
  { id: "storeArea", label: "Store Area", alignRight: false },
  { id: "storeAddress", label: "Store Address", alignRight: false },
  {
    id: "onShelfAvailable",
    label: "On Shelf Availabilty 1",
    alignRight: false,
  },
  {
    id: "onShelfAvailable2",
    label: "On Shelf Availabilty 2",
    alignRight: false,
  },
  { id: "display", label: "Visibility Display", alignRight: false },
  // { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr > th": {
          backgroundColor: "#F0F0F0",
          position: "sticky",
          zIndex: 2,
          top: 0,
        },
        "& tr > :first-of-type": {
          zIndex: 3,
          left: 0,
          top: 0,
          width: 250,
          borderRight: "2px solid #E0E0E0",
        },
        "& th": {
          fontSize: 15,
          fontWeight: 500,
          color: "#0A0A0A",
        },
      }}
    >
      <TableRow>
        {TABLE_HEAD?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const listArea = ["Jakarta Selatan", "Tangerang", "Bekasi"];
const listEmployee = [
  "Dog",
  "Esther Howard",
  "Theresa Webb",
  "Kathryn Murphy",
  "Devon Lane",
];
const listLeader = [
  "Howard",
  "Darlene Robertson",
  "Theresa Webb",
  "Kathryn Murphy",
  "Devon Lane",
];

const data = [
  {
    profilePicture: "",
    name: "Esther Howard",
    leader: "Darlene Robertson",
    store: "Diamond Artha Gading",
    storeArea: "Jakarta Selatan",
    storeAddress:
      "Jl. Boulevard Artha Gading No.16, RT.18/RW.8, Klp. Gading Bar., Kec. Klp. Gading, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14240, Indonesia",
    onShelfAvailable: STORE_PHOTO,
    onShelfAvailable2: STORE_PHOTO,
    display: STORE_PHOTO,
  },
  {
    profilePicture: "",
    name: "Esther Howard",
    leader: "Howard",
    store: "Diamond Artha Gading",
    storeArea: "Bekasi",
    storeAddress:
      "Jl. Boulevard Artha Gading No.16, RT.18/RW.8, Klp. Gading Bar., Kec. Klp. Gading, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14240, Indonesia",
    onShelfAvailable: STORE_PHOTO,
    onShelfAvailable1: STORE_PHOTO,
    display: STORE_PHOTO,
  },
  {
    profilePicture: "",
    name: "Theresa Webb",
    leader: "Howard",
    store: "Diamond Artha Gading",
    storeArea: "Jakarta Selatan",
    storeAddress:
      "Jl. Boulevard Artha Gading No.16, RT.18/RW.8, Klp. Gading Bar., Kec. Klp. Gading, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14240, Indonesia",
    onShelfAvailable: STORE_PHOTO,
    onShelfAvailable1: STORE_PHOTO,
    display: STORE_PHOTO,
  },
  {
    profilePicture: "",
    name: "Theresa Webb",
    leader: "Darlene Robertson",
    store: "Diamond Artha Gading",
    storeArea: "Jakarta Selatan",
    storeAddress:
      "Jl. Boulevard Artha Gading No.16, RT.18/RW.8, Klp. Gading Bar., Kec. Klp. Gading, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14240, Indonesia",
    onShelfAvailable: STORE_PHOTO,
    onShelfAvailable1: STORE_PHOTO,
    display: STORE_PHOTO,
  },
  {
    profilePicture: "",
    name: "Esther Howard",
    leader: "Darlene Robertson",
    store: "Diamond Artha Gading",
    storeArea: "Bekasi",
    storeAddress:
      "Jl. Boulevard Artha Gading No.16, RT.18/RW.8, Klp. Gading Bar., Kec. Klp. Gading, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14240, Indonesia",
    onShelfAvailable: STORE_PHOTO,
    onShelfAvailable1: STORE_PHOTO,
    display: STORE_PHOTO,
  },
  {
    profilePicture: "",
    name: "Esther Howard",
    leader: "Howard",
    store: "Diamond Artha Gading",
    storeArea: "Jakarta Selatan",
    storeAddress:
      "Jl. Boulevard Artha Gading No.16, RT.18/RW.8, Klp. Gading Bar., Kec. Klp. Gading, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14240, Indonesia",
    onShelfAvailable: STORE_PHOTO,
    onShelfAvailable1: STORE_PHOTO,
    display: STORE_PHOTO,
  },
  {
    profilePicture: "",
    name: "Theresa Webb",
    leader: "Darlene Robertson",
    store: "Diamond Artha Gading",
    storeArea: "Tangerang",
    storeAddress:
      "Jl. Boulevard Artha Gading No.16, RT.18/RW.8, Klp. Gading Bar., Kec. Klp. Gading, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14240, Indonesia",
    onShelfAvailable: STORE_PHOTO,
    onShelfAvailable1: STORE_PHOTO,
    display: STORE_PHOTO,
  },
  {
    profilePicture: "",
    name: "Dog",
    leader: "Howard",
    store: "Diamond Artha Gading",
    storeArea: "Tangerang",
    storeAddress:
      "Jl. Boulevard Artha Gading No.16, RT.18/RW.8, Klp. Gading Bar., Kec. Klp. Gading, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14240, Indonesia",
    onShelfAvailable: STORE_PHOTO,
    onShelfAvailable1: STORE_PHOTO,
    display: STORE_PHOTO,
  },
  {
    profilePicture: "",
    name: "Devon Lane",
    leader: "Darlene Robertson",
    store: "Diamond Artha Gading",
    storeArea: "Bekasi",
    storeAddress:
      "Jl. Boulevard Artha Gading No.16, RT.18/RW.8, Klp. Gading Bar., Kec. Klp. Gading, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14240, Indonesia",
    onShelfAvailable: STORE_PHOTO,
    onShelfAvailable1: STORE_PHOTO,
    display: STORE_PHOTO,
  },
  {
    profilePicture: "",
    name: "Devon Lane",
    leader: "Howard",
    store: "Diamond Artha Gading",
    storeArea: "Tangerang",
    storeAddress:
      "Jl. Boulevard Artha Gading No.16, RT.18/RW.8, Klp. Gading Bar., Kec. Klp. Gading, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14240, Indonesia",
    onShelfAvailable: STORE_PHOTO,
    onShelfAvailable1: STORE_PHOTO,
    display: STORE_PHOTO,
  },
];

export default function DisplayReport(props) {
  const {
    onSearch,
    valueTimeFrom,
    valueTimeTo,
    onChangeStartDate,
    onChangeEndDate,
    loading,
    searchValue,
  } = props;
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedInput, setSelectedInput] = useState("");
  const [selectedLeader, setSelectedLeader] = useState("");
  const [selectedInputLeader, setSelectedInputLeader] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedInputEmployee, setSelectedInputEmployee] = useState("");

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectedArea = (value) => {
    // window.sessionStorage.setItem("selectedArea", value);
    setSelectedArea(value);
  };
  const handleSelectedLeader = (value) => {
    // window.sessionStorage.setItem("selectedArea", value);
    setSelectedLeader(value);
  };
  const handleSelectedEmployee = (value) => {
    // window.sessionStorage.setItem("selectedArea", value);
    setSelectedEmployee(value);
  };

  const handleSearch = (value) => {
    setSelectedInput(value);
  };
  const handleSearchEmployee = (value) => {
    setSelectedInputEmployee(value);
  };
  const handleSearchLeader = (value) => {
    setSelectedInputLeader(value);
  };

  return (
    <div className={Styles.Body}>
      <HeaderSelectionStyle>
        <Stack direction="row" spacing={2}>
          <Stack spacing={1} direction="column" sx={{ width: "100%" }}>
            <TypographyStyle>Area</TypographyStyle>
            <Autocomplete
              disablePortal
              options={listArea}
              value={selectedArea}
              inputValue={selectedInput}
              sx={{ width: "100%" }}
              onInputChange={(e, value) =>
                value === null ? handleSearch("") : handleSearch(value)
              }
              onChange={(e, value) =>
                value === null
                  ? handleSelectedArea("")
                  : handleSelectedArea(value)
              }
              renderInput={(params) => (
                <MuiAutoComplete
                  {...params}
                  size="small"
                  placeholder="Select Area"
                />
              )}
            />
          </Stack>
          <Stack spacing={1} direction="column" sx={{ width: "100%" }}>
            <TypographyStyle>Employee</TypographyStyle>
            <Autocomplete
              disablePortal
              options={listEmployee}
              value={selectedEmployee}
              inputValue={selectedInputEmployee}
              onInputChange={(e, value) =>
                value === null
                  ? handleSearchEmployee("")
                  : handleSearchEmployee(value)
              }
              onChange={(e, value) =>
                value === null
                  ? handleSelectedEmployee("")
                  : handleSelectedEmployee(value)
              }
              renderInput={(params) => (
                <MuiAutoComplete
                  {...params}
                  size="small"
                  placeholder="Select Employee"
                />
              )}
            />
          </Stack>
          <Stack spacing={1} direction="column" sx={{ width: "100%" }}>
            <TypographyStyle>Leader</TypographyStyle>
            <Autocomplete
              disablePortal
              options={listLeader}
              value={selectedLeader}
              inputValue={selectedInputLeader}
              onInputChange={(e, value) =>
                value === null
                  ? handleSearchLeader("")
                  : handleSearchLeader(value)
              }
              onChange={(e, value) =>
                value === null
                  ? handleSelectedLeader("")
                  : handleSelectedLeader(value)
              }
              renderInput={(params) => (
                <MuiAutoComplete
                  {...params}
                  size="small"
                  placeholder="Select Leader"
                />
              )}
            />
          </Stack>
        </Stack>
      </HeaderSelectionStyle>
      <Box
        sx={{
          pt: 2,
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          width: "100%",
          gap: "12px",
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" spacing={2}>
          <SearchbarFMCG
            searchWidth="260px"
            // value={searchValue}
            onChange={onSearch}
            placeholder="Search by name"
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              components={{
                OpenPickerIcon: CalendarMonthIcon,
              }}
              required
              value={valueTimeFrom}
              onChange={onChangeStartDate}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  size="small"
                  sx={{ width: 200, backgroundColor: "#FFFFFF" }}
                />
              )}
            />
            <DatePicker
              components={{
                OpenPickerIcon: CalendarMonthIcon,
              }}
              required
              value={valueTimeTo}
              onChange={onChangeEndDate}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  size="small"
                  sx={{ width: 200, backgroundColor: "#FFFFFF" }}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <ExportDataExcelFMCG
            data={data?.filter(
                (el) =>
                  el?.name
                    ?.toLowerCase()
                    .includes(searchValue?.toLowerCase()) &&
                  (selectedEmployee ? el.name === selectedEmployee : true) &&
                  (selectedLeader ? el.leader === selectedLeader : true) &&
                  (selectedArea ? el.storeArea === selectedArea : true)
              )
              .map((el, ind) => ({
                "No. ": ind + 1,
                Nama: el.name,
                // Position: el.Position,
                // Nrk: el.Nrk,
                // "Date in": el.dateTimeIn,
                // "Date out": el.dateTimeOut,
                // "Time in": el.timeIn,
                // "Time out": el.timeOut,
                // "Coordinate Point": `${el.latitude} ${el.longitude}`,
                // "Status Absence":
                //   (el.type === "presence" && "Hadir") ||
                //   (el.type === "annualLeave" && "Cuti") ||
                //   (el.type === "permit" && "Sakit"),
              }))}
            initial="Download Excel"
            startIcon={<DownloadIcon />}
            filename={`Activity Report ${new Date().toLocaleDateString()}`}
          />
        </Box>
      </Box>
      <Card sx={{ mt: "24px" }}>
        {loading ? (
          <div className={Styles.ContainerLoading}>
            <LoadingFMCG
              width={800}
              height={200}
              className={Styles.LoadingWidth}
            />
          </div>
        ) : (
          <Scrollbar>
            <TableContainer>
              <Table sx={{ width: "200%" }}>
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                </colgroup>
                <EnhancedTableHead
                  numSelected={data?.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={
                    data?.filter(
                      (el) =>
                        el?.name
                          ?.toLowerCase()
                          .includes(searchValue?.toLowerCase()) &&
                        (selectedEmployee
                          ? el.name === selectedEmployee
                          : true) &&
                        (selectedLeader
                          ? el.leader === selectedLeader
                          : true) &&
                        (selectedArea ? el.storeArea === selectedArea : true)
                    ).length
                  }
                />
                <TableBody
                  sx={{
                    "& tr > th": {
                      position: "sticky",
                      padding: ".5rem",
                      zIndex: 2,
                      top: 0,
                      background: "#FFFFFF",
                    },
                    "& tr > :first-of-type": {
                      left: 0,
                      position: "sticky",
                      zIndex: 1,
                      background: "#FFFFFF",
                      borderRight: "2px solid #E0E0E0",
                      width: 250,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#0A0A0A",
                      textTransform: "capitalize",
                    },
                    "& td > p": {
                      fontSize: 13,
                      fontWeight: 400,
                      color: "#404040",
                      textTransform: "capitalize",
                    },
                    "& td > img": {
                      width: 80,
                      height: 40,
                    },
                  }}
                >
                  {stableSort(data, getComparator(order, orderBy))
                    .filter(
                      (el) =>
                        el?.name
                          ?.toLowerCase()
                          .includes(searchValue?.toLowerCase()) &&
                        (selectedEmployee
                          ? el.name === selectedEmployee
                          : true) &&
                        (selectedLeader
                          ? el.leader === selectedLeader
                          : true) &&
                        (selectedArea ? el.storeArea === selectedArea : true)
                    )
                    // .sort((a, b) => new Date(a.LoanTime) - new Date(b.LoanTime))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((each, idx) => {
                      // eslint-disable-next-line no-lone-blocks
                      /* const labelId = `enhanced-table-checkbox-${id}`; */
                      return (
                        <TableRow hover key={idx} tabIndex={-1}>
                          <TableCell>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                sx={{ width: 36, height: 36 }}
                                src={
                                  each?.profilePicture
                                    ? each?.profilePicture
                                    : ""
                                }
                              />
                              <Typography
                                noWrap
                                sx={{
                                  fontSize: 13,
                                  fontWeight: 500,
                                  color: "#0A0A0A",
                                  textTransform: "capitalize",
                                }}
                              >
                                {each?.name.toLowerCase()}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Typography>{each?.leader}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{each?.store}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{each?.storeArea}</Typography>
                          </TableCell>
                          <TableCell className={Styles.Address}>
                            <Typography>{each?.storeAddress}</Typography>
                          </TableCell>
                          <TableCell>
                            <img src={each?.onShelfAvailable} alt="" />
                          </TableCell>
                          <TableCell>
                            <img src={each?.onShelfAvailable2} alt="" />
                          </TableCell>
                          <TableCell>
                            <img src={each?.display} alt="" />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 30]}
          component="div"
          count={
            data?.filter(
              (el) =>
                el?.name?.toLowerCase().includes(searchValue?.toLowerCase()) &&
                (selectedEmployee ? el.name === selectedEmployee : true) &&
                (selectedLeader ? el.leader === selectedLeader : true) &&
                (selectedArea ? el.storeArea === selectedArea : true)
            ).length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </div>
  );
}
