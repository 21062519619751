import React  from "react";
import "./SidebarFMCG.css";
import SubnavFMCG from "./SubnavFMCG";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/my-workspace.png";
import { Drawer } from "@mui/material";
import { useRouteMatch } from "react-router";
import useWindowDimensions from "../../SuperAdmin/_utils/useDimensions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export const SidebarData = [
  {
    display_name: "Dashboard",
    route: "",
    icon: "bx bxs-dashboard",
  },
  {
    display_name: "Employees",
    route: "/employees",
    icon: "bx bxs-user",
  },
  {
    display_name: "Attendance",
    route: "/attendances",
    icon: "bx bxs-calendar",
  },
  {
    display_name: "Setup",
    route: "/setup/store",
    icon: "bx bxs-customize",
    iconClosed: <ExpandMoreIcon />,
    iconOpened: <ExpandLessIcon />,
    subNav: [
      {
        display_name: "Store",
        route: "/setup/store",
      },
      {
        display_name: "Products",
        route: "/setup/products",
      },
      {
        display_name: "Promo",
        route: "/setup/promo",
      },
      {
        display_name: "Competitor",
        route: "/setup/competitor",
      },
    ],
  },

  {
    display_name: "Report",
    route: "/report/spg",
    icon: "bx bxs-layer",
    iconClosed: <ExpandMoreIcon />,
    iconOpened: <ExpandLessIcon />,
    subNav: [
      {
        display_name: "SPG",
        route: "/report/spg",
      },
      {
        display_name: "MD",
        route: "/report/md",
      },
      {
        display_name: "Leader",
        route: "/report/leader",
      },
    ],
  },
];

const SidebarFMCG = ({ onOpenSidebar, onCloseSidebar, ...props }) => {
  let { url } = useRouteMatch();
  const { width } = useWindowDimensions();
  // eslint-disable-next-line no-unused-vars
  const activeItem = SidebarData.findIndex(
    (item) => `${url}` + item.route === props.location.pathname
  );

  // console.log(width > 1024, onOpenSidebar)

  const isActive = (data) => {
    if (data.split("/").length < 1) {
      return true;
    } else if (data.split("/")[1] === props.location.pathname.split("/")[2]) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Drawer
        anchor="left"
        open={width > 1200 || onOpenSidebar}
        onClose={onCloseSidebar}
        variant={width > 1200 ? "permanent" : "temporary"}
        PaperProps={{ sx: { width: 281, position: "unset" } }}
      >
        <div className={`sidebar ${!onOpenSidebar && "close"}`}>
          <Link to="/admin-fmcg">
            <div className="sidebar__logo">
              <img
                src={logo}
                alt="company logo"
                style={{ width: 41, height: 35 }}
              />
              <span>MyWorkSpace</span>
            </div>
          </Link>
          {SidebarData.map((item, index) => {
            return (
              <SubnavFMCG
                item={item}
                key={index}
                // active={activeItem === index}
                active={isActive(item.route)}
              />
            );
          })}
        </div>
      </Drawer>
      {/* <div className="sidebar">
        <Link to="/dashboard">
          <div className="sidebar__logo">
            <img src={logo} alt="company logo" />
            <span>MyWorkSpace</span>
          </div>
        </Link>

        {SidebarData.map((item, index) => {
          return (
            <SubMenu item={item} key={index} active={index === activeItem} />
          );
        })}
      </div> */}
    </>
  );
};

export default SidebarFMCG;

// {
//   display_name: "Profile",
//   route: "/profile",
//   icon: "bx bxs-user-rectangle",
// },
// {
//   display_name: "Settings",
//   route: "/settings",
//   icon: "bx bx-cog",
// },
