// import { FETCH_ALL_BPJS_DATA, FETCH_BPJS_DATA_BY_ID } from "../../type";
import * as types from '../../type'
const initialstate = {
  allBpjs: [],
  bpjsById: [],
  loadingDataBpjs: false,
  errorMessageBpjs: {}
};

const bpjsReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_BPJS_DATA_BEGIN: {
      return {
        ...state,
        loadingDataBpjs: true
      };
    }
    case types.FETCH_ALL_BPJS_DATA_SUCCESS: {
      return {
        ...state,
        allBpjs: action.payload,
        loadingDataBpjs: false
      };
    }
    case types.FETCH_ALL_BPJS_DATA_ERROR: {
      return {
        ...state,
        loadingDataBpjs: false
      };
    }
    case types.FETCH_BPJS_DATA_BY_ID: {
      return {
        ...state,
        bpjsById: action.payload,
      };
    }
    default:
      return state;
  }
};

export default bpjsReducer;
