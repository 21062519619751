/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import BPJS_PROFILE_IMAGE from "../../../../../assets/images/circle-image-bpjs.png";
import BPJS_IMAGE from "../../../../../assets/images/bpjs-image.png";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import { Avatar, Button } from "@mui/material";
import DownloadImage from "../../../../utils/DownloadImage";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchBpjsDataById } from "../../../../../redux/actions/SuperAdmin/bpjsActions";
// moment.locale("en-gb");

const useStyles = makeStyles((theme) => ({
  ItemModalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .dummy": {
      display: "hidden",
      fontSize: 0,
    },
    "& .textAbsences": {
      color: "#404040",
      fontSize: 24,
      fontWeight: 500,
    },
    "& .icon": {
      backgroundColor: "transparent",
      border: "none",
      fontSize: 25,
      cursor: "pointer",
    },
  },
  ProfileInfo: {
    display: "flex",
    justifyContent: "space-between",
    "& .date": {
      display: "flex",
      flexDirection: "column",
      rowGap: 5,
    },
    "& .labelDate": {
      color: "#616161",
      fontSize: 17,
      fontWeight: 500,
    },
    "& .textDate": {
      color: "#404040",
      fontSize: 20,
      fontWeight: 500,
    },
    "& .boxPromoType": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 10,
      height: 30,
      backgroundColor: "#599EDB",
      borderRadius: 50,
    },
    "& .textPromo": {
      fontSize: 12,
      fontWeight: 500,
      color: "#FFFFFF",
    },
  },
  BpjsInfo: {
    display: "flex",
    justifyContent: "space-between",
  },
  InfoLeft: {
    flex: 1,
  },
  DetailInfo: {
    display: "flex",
    columnGap: 10,

    "& .ProfileInfo": {
      display: "flex",
      flexDirection: "column",
    },
    "& .textName": {
      color: "#0A0A0A",
      fontSize: "1.5rem",
      fontWeight: 500,
      width: 280
    },

    "& .textPosition": {
      color: "#757575",
      fontSize: "0.75rem",
      fontWeight: 500,
    },
    "& .textClient": {
      color: "#404040",
      fontSize: "1rem",
      fontWeight: 500,
    },
  },
  BpjsImages: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    rowGap: 20,
    paddingTop: 10,
  },
  BoxImageBPJS: {
    display: "flex",
    justifyContent: "center",
    borderRadius: 8,
    padding: 10,
    backgroundColor: " #FAFAFA",
    position: 'relative',
    "& .ImageBPJS": {
      maxInlineSize: "100%",
      width: "100%",
      height: "100%",
    },
    "& .TextCardNumber": {
      position: 'absolute',
      top: '36%',
      left: '34%',
      fontSize: 11
    },
    "& .TextCardName": {
      position: 'absolute',
      top: '39.8%',
      left: '34%',
      fontSize: 11
    },
    "& .TextCardAddress": {
      position: 'absolute',
      top: '44.2%',
      left: '34%',
      fontSize: 11,
      lineHeight: 1,
    },
    "& .TextCardDOB": {
      position: 'absolute',
      top: '50.5%',
      left: '34%',
      fontSize: 11
    },
    "& .TextCardNIK": {
      position: 'absolute',
      top: '54%',
      left: '34%',
      fontSize: 11
    },
    "& .TextCardFaskes": {
      position: 'absolute',
      top: '58%',
      left: '34%',
      fontSize: 11
    },
  },
  ButtonDownload: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& .ButtonDownloadBpjs": {
      textTransform: "none",
      backgroundColor: "#1571DE",
      color: "#ffffff",
      width: 124,
      height: 40,
    },
  },
  InfoRight: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export default function ModalBPJS({ datas, setBpjsOpen }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const data = useSelector((state) => state.bpjs.bpjsById);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  //   const totalLate = Math.floor(show.Late / 60);
  const handleClose = () => {
    setBpjsOpen(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Boolean(datas)}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className={classes.ItemModalTop}>
            <h6 className="dummy">dummy</h6>
            <div className="textAbsences">
              <span className="textAbsences">BPJS Detail</span>
            </div>
            <button onClick={handleClose} className="icon">
              <CloseIcon
                style={{ fontSize: 30, marginTop: 10, paddingRight: 5 }}
              />
            </button>
          </div>
          <div>
          </div>
        </DialogTitle>
        <hr style={{ width: "100%", color: "#ECE6E6", padding: 0, margin: 0 }} />
        <DialogContent>
          <div className={classes.BpjsImages}>
            {datas?.bpjs !== []
              ? datas?.bpjs[0]?.health?.map((item, index) => (
                <div className={classes.BoxImageBPJS} key={index}>
                  <img src={BPJS_IMAGE} alt="" className="ImageBPJS" />
                  <div className="TextCardNumber">
                    {item?.number}
                  </div>
                  <div className="TextCardName">
                    {item?.name}
                  </div>
                  <div className="TextCardAddress">
                    {item?.address ? item?.address : '-'}
                  </div>
                  <div className="TextCardDOB">
                    {item?.birthDate}
                  </div>
                  <div className="TextCardNIK">
                    {item?.idNumber}
                  </div>
                  <div className="TextCardFaskes">
                    {item?.healthFacility ? item?.healthFacility : '-'}
                  </div>
                  {/* <span>{item?.CardNumber}</span> */}
                </div>
              ))
              : null}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
