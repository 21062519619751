import React from "react";
import { Button, Dialog, DialogContent, IconButton } from "@mui/material";
import Styles from "./LeaveDetailModal.module.css";
import { Close } from "@mui/icons-material";

export default function LeaveDetailModal({
  open,
  setOpen,
  data,
  isControlled,
}) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <div className={Styles.Container}>
          <div className={Styles.HeaderImage}>
            <i className="bx bxs-calendar-star"></i>
          </div>
          <div className={Styles.HeaderTitle}>
            <p>Leave Setting</p>
          </div>
          <div className={Styles.ModalBody}>
            <div className={Styles.DescriptionControl}>
              <p>Leave Amount</p>
              <p>{data.dayoff}</p>
            </div>
            <div className={Styles.DescriptionControl}>
              <p>Period</p>
              <p>
                {data.startPeriod} - {data.endPeriod}
              </p>
            </div>
          </div>
          {isControlled && (
            <div className={Styles.WrapperButtonModal}>
              <Button
                style={{
                  border: "1px solid #D42701",
                  borderRadius: 5,
                  textTransform: "capitalize",
                  color: "#D42701",
                  width: "48%",
                  height: 40,
                  fontSize: 13,
                  fontWeight: 500,
                }}
                onClick={() => {
                  setOpen("delete");
                }}
              >
                Delete Scheme
              </Button>
              <Button
                style={{
                  border: "1px solid #1571DE",
                  textTransform: "capitalize",
                  color: "#1571DE",
                  width: "48%",
                  height: 40,
                  fontSize: 13,
                  fontWeight: 500,
                }}
                onClick={() => setOpen("edit")}
              >
                Edit Leave Setting
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
