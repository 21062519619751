/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import Styles from "./ClientProfileMenu.module.css";
// import "./ClientProfileMenu.css";
// eslint-disable-next-line no-unused-vars
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import CALENDAR_ICON from "../../../../assets/images/calendar-primary.png";
import EDIT_ICON from "../../../../assets/images/create.png";
import MAP_ICON from "../../../../assets/images/map-dark-grey.png";
import MONEY_ICON from "../../../../assets/images/money-primary.png";
import BUILDING_ICON from "../../../../assets/images/single-building-dark-grey.png";

// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useDispatch, useSelector } from "react-redux";
import fetchClientAttendanceScheme from "../../../../async/client/fetchClientAttendanceScheme";
import fetchClientLoanScheme from "../../../../async/client/fetchClientLoanScheme";
import { updateClient } from "../../../../redux/actions/SuperAdmin/adminActions";
import { fetchAllEmployees } from "../../../../redux/actions/SuperAdmin/employeesActions";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import CustomTablePagination from "../../../_pagination/CustomTablePagination";
import Scrollbar from "../../_user/TableUsers/Scrollbar";
import EditInfoModal from "./EditInfoModal/EditInfoModal";
import fetchLeavePlan from "../../../../async/leavePlan/fetchLeavePlan";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id + index}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Employee Name",
  },
  {
    id: "employeeId",
    numeric: false,
    disablePadding: false,
    label: "NRK",
  },
  {
    id: "jobTitle",
    numeric: false,
    disablePadding: false,
    label: "Position",
  },
  {
    id: "division",
    numeric: true,
    disablePadding: false,
    label: "Division",
  },
  {
    id: "subordinates",
    numeric: true,
    disablePadding: false,
    label: "Subordinates",
  },
  {
    id: "loanApprovers",
    numeric: true,
    disablePadding: false,
    label: "Approver",
  },
];

export default function ClientProfileMenu({ client, loadingClientById }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const history = useHistory();
  const { employees, loadingAllEmployee, totalPage } = useSelector(
    (state) => state.employees
  );
  const imageRef = useRef();
  const [selectedClient, setSelectedClient] = useState({});
  const [selectedClientEdit, setSelectedClientEdit] = useState({});
  const [addMaintainer, setAddMaintainer] = useState(false);
  const [editMaintainer, setEditMaintainer] = useState(false);
  const [deleteMaintainer, setDeleteMaintainer] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [selected, setSelected] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listDataEmployee, setListDataEmployee] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterName, setFilterName] = useState("");
  const [image, setImage] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [savedSet, setSavedSet] = useState(false);
  const [pendingLoad, setPendingLoad] = useState(false);
  const [loanScheme, setLoanScheme] = useState(null);
  const [attendanceScheme, setAttendanceScheme] = useState(null);
  const [leaveSchema, setLeaveSchema] = useState(null);
  const [whatsappPlan, setWhatsappPlan] = useState(null);
  const [onActiveEdit, setOnActiveEdit] = useState(false);

  const [limitBM, setLimitBM] = useState(10);

  // console.log(attendanceScheme)

  const [successEdit, setSuccessEdit] = useState(false);

  const { loadingUpdateClient } = useSelector((state) => state.client);

  const [searchEmployee, setSearchEmployee] = useState("");

  const query = `clientCode=${client?.code}&limit=${limit}&pageNumber=${page}&name=${filterName}`;

  const handleGetLeaveSchema = async () => {
    try {
      const data = await fetchLeavePlan(token);
      setLeaveSchema(
        data.find((el) => el._id === client?.feature?.leavePlanId)
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetLeaveSchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    setWhatsappPlan(client);
  }, [client]);

  useEffect(() => {
    if (pendingLoad === false) {
      dispatch(fetchAllEmployees(token, query));
    }
  }, [token, dispatch, query, pendingLoad]);

  useEffect(() => {
    (async () => {
      if (
        client &&
        client?.feature?.staffLoanPlanId !== null &&
        client?.feature?.staffLoanPlanId !== undefined
      ) {
        try {
          const { response } = await fetchClientLoanScheme(
            token,
            client?.feature?.staffLoanPlanId
          );
          // setDataClientCode(response?.map((el) => el?.name));
          setLoanScheme(response);
        } catch (error) {
          console.log(error);
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    (async () => {
      if (
        client &&
        client?.feature?.attendancePlanId !== null &&
        client?.feature?.attendancePlanId !== undefined
      ) {
        try {
          const { response } = await fetchClientAttendanceScheme(
            token,
            client?.feature?.attendancePlanId
          );
          // setDataClientCode(response?.map((el) => el?.name));
          // setAttendanceScheme(response[0]);
          setAttendanceScheme(response);
        } catch (error) {
          console.log(error);
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditButton = () => {
    setEditMaintainer(true);
    setAnchorEl(null);
  };

  const handleDeleteButton = () => {
    setDeleteMaintainer(true);
    setAnchorEl(null);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImage({
        image: URL.createObjectURL(img),
      });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (e) => {};

  const handleSearchName = (e) => {
    setPendingLoad(true);
    setFilterName(e.target.value);
    setTimeout(() => {
      setPendingLoad(false);
    }, 1000);
    clearTimeout();
  };

  const handleSwitchGadai = (e) => {
    e.preventDefault();

    let body = {
      feature: {
        pawnPlan: !client?.feature?.pawnPlan,
        attendancePlanId: client?.feature?.attendancePlanId,
        staffLoanPlanId: client?.feature?.staffLoanPlanId,
        carLoanPlanId: client?.feature?.carLoanPlanId,
        mdPlanId: client?.feature?.mdPlanId,
        spgPlanId: client?.feature?.spgPlanId,
        activityPlanId: client?.feature?.activityPlanId,
        promotorPlanId: client?.feature?.promotorPlanId,
        leavePlanId: client?.feature?.leavePlanId,
        privateWa: {
          code: "",
          auth: "",
        },
      },
    };

    dispatch(updateClient(token, client?._id, body));
  };

  const handleSwitchLoan = (e) => {
    e.preventDefault();

    if (client?.feature?.staffLoanPlanId !== null) {
      let body = {
        feature: {
          pawnPlan: client?.feature?.pawnPlan,
          attendancePlanId: client?.feature?.attendancePlanId,
          staffLoanPlanId: null,
          carLoanPlanId: client?.feature?.carLoanPlanId,
          mdPlanId: client?.feature?.mdPlanId,
          spgPlanId: client?.feature?.spgPlanId,
          activityPlanId: client?.feature?.activityPlanId,
          promotorPlanId: client?.feature?.promotorPlanId,
          leavePlanId: client?.feature?.leavePlanId,
        },
      };

      dispatch(updateClient(token, client?._id, body));
    } else {
      history.push({
        pathname: `/superadmin/set-loan-scheme/${client?._id}`,
        data: { data: client },
      });
    }
  };
  const handleSwitchAttendance = (e) => {
    e.preventDefault();

    if (client?.feature?.attendancePlanId !== null) {
      let body = {
        feature: {
          pawnPlan: client?.feature?.pawnPlan,
          attendancePlanId: null,
          staffLoanPlanId: client?.feature?.staffLoanPlanId,
          carLoanPlanId: client?.feature?.carLoanPlanId,
          mdPlanId: client?.feature?.mdPlanId,
          spgPlanId: client?.feature?.spgPlanId,
          activityPlanId: client?.feature?.activityPlanId,
          promotorPlanId: client?.feature?.promotorPlanId,
          leavePlanId: client?.feature?.leavePlanId,
        },
      };

      dispatch(updateClient(token, client?._id, body));
    } else {
      history.push({
        pathname: `/superadmin/set-attendance-scheme/${client?._id}`,
        data: { data: client },
      });
    }
  };

  const handleSwitchLeavePlan = (e) => {
    e.preventDefault();

    if (client?.feature?.leavePlanId !== null) {
      let body = {
        feature: {
          pawnPlan: client?.feature?.pawnPlan,
          attendancePlanId: client?.feature?.attendancePlanId,
          staffLoanPlanId: client?.feature?.staffLoanPlanId,
          carLoanPlanId: client?.feature?.carLoanPlanId,
          mdPlanId: client?.feature?.mdPlanId,
          spgPlanId: client?.feature?.spgPlanId,
          activityPlanId: client?.feature?.activityPlanId,
          promotorPlanId: client?.feature?.promotorPlanId,
          leavePlanId: null,
        },
      };

      dispatch(updateClient(token, client?._id, body));
    } else {
      history.push({
        pathname: `/superadmin/set-leave-scheme/${client?._id}`,
        data: { data: client },
      });
    }
  };

  const handleSwitchPrivateWAPlan = (e) => {
    e.preventDefault();

    if (client?.feature?.privateWa?.auth !== null) {
      let body = {
        feature: {
          pawnPlan: client?.feature?.pawnPlan,
          attendancePlanId: client?.feature?.attendancePlanId,
          staffLoanPlanId: client?.feature?.staffLoanPlanId,
          carLoanPlanId: client?.feature?.carLoanPlanId,
          mdPlanId: client?.feature?.mdPlanId,
          spgPlanId: client?.feature?.spgPlanId,
          activityPlanId: client?.feature?.activityPlanId,
          promotorPlanId: client?.feature?.promotorPlanId,
          leavePlanId: client?.feature?.leavePlanId,
          privateWa: {
            code: null,
            auth: null,
          },
        },
      };

      dispatch(updateClient(token, client?._id, body));
    } else {
      // history.push({
      //   pathname: `/superadmin/set-leave-scheme/${client?._id}`,
      //   data: { data: client },
      // });
    }
  };

  function truncate(str) {
    return str?.split(" ")?.splice(0, 2)?.join(" ");
  }

  const capitalizedClientName = (clientName) => {
    return clientName
      ?.toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .replace("Pt", "PT")
      .replace("Cv", "CV");
  };

  const onSendData = (e) => {
    e.preventDefault();
    let body = {
      feature: {
        pawnPlan: client?.feature?.pawnPlan,
        attendancePlanId: client?.feature?.attendancePlanId,
        staffLoanPlanId: client?.feature?.staffLoanPlanId,
        carLoanPlanId: client?.feature?.carLoanPlanId,
        mdPlanId: client?.feature?.mdPlanId,
        spgPlanId: client?.feature?.spgPlanId,
        activityPlanId: client?.feature?.activityPlanId,
        promotorPlanId: client?.feature?.promotorPlanId,
        leavePlanId: client?.feature?.leavePlanId,
        privateWa: {
          code: whatsappPlan?.feature?.privateWa?.code,
          auth: whatsappPlan?.feature?.privateWa?.auth,
        },
      },
    };
    dispatch(updateClient(token, client?._id, body));
    setOnActiveEdit(false);
  history.push({
        pathname: `/superadmin/profile-clients/${client?._id}`,
        data: { data: client },
      });
  };

  console.log(whatsappPlan, "<<<");

  return (
    <div className={Styles.Container}>
      {(loadingUpdateClient || loadingClientById) && (
        <div className={Styles.LoadingBackground}>
          <div style={{ width: 200 }} className={Styles.LoadingContainer}>
            <LoadingAnimation />
          </div>
        </div>
      )}
      <div className={Styles.BannerContainer}>
        <div className={Styles.ID}>
          <div className={Styles.IDDesc}>
            <span>{capitalizedClientName(client?.name)}</span>
          </div>
        </div>
        <div className={Styles.BannerButtonWrapper}>
          <button onClick={() => setEditMode(true)}>
            <img src={EDIT_ICON} alt="edit" />
          </button>
        </div>
      </div>
      <div className={Styles.ContentWrapper}>
        <div className={Styles.InfoContainer}>
          <div className={Styles.InfoProfile}>
            <span>Company Information</span>
            <hr />
            <div className={Styles.BoxProfile}>
              <div className={Styles.BoxLeft}>
                <img src={BUILDING_ICON} alt="company" />
                <span>Group</span>
              </div>
              <div className={Styles.BoxRight}>
                <span>{client?.group || "No Group"}</span>
              </div>
            </div>
            <div className={Styles.BoxProfile}>
              <div className={Styles.BoxLeft}>
                <img src={MAP_ICON} alt="address" />
                <span>Address</span>
              </div>
              <div className={Styles.BoxRight}>
                <span>{client?.address || "No Address"}</span>
              </div>
            </div>
          </div>
          <div className={Styles.InfoScheme}>
            <span>Scheme</span>
            <hr />
            <div className={Styles.SchemeContainer}>
              <div className={Styles.SchemeCard}>
                <div className={Styles.CardHeader}>
                  <div className={Styles.HeaderLeft}>
                    <div className={Styles.CardLogo}>
                      <img src={MONEY_ICON} alt="loan" />
                    </div>
                    <span>Loan Scheme</span>
                  </div>
                  <Switch
                    checked={client?.feature?.staffLoanPlanId !== null || false}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={handleSwitchLoan}
                  />
                </div>
                <div className={Styles.CardContent}>
                  <span>View setting for user yours client</span>
                  <span>
                    {client?.feature?.staffLoanPlanId !== null
                      ? loanScheme?.name
                      : "not set yet"}
                  </span>
                </div>
                <div className={Styles.CardFooter}>
                  <Link
                    to={{
                      pathname: `/superadmin/set-loan-scheme/${client?._id}`,
                      data: { data: client },
                    }}
                    className={Styles.CardLink}
                  >
                    <span>Set Scheme Setting</span>
                    <ArrowRightAltIcon
                      style={{ color: "#1571DE", marginLeft: 8 }}
                    />
                  </Link>
                </div>
              </div>
              <div className={Styles.SchemeCard}>
                <div className={Styles.CardHeader}>
                  <div className={Styles.HeaderLeft}>
                    <div className={Styles.CardLogo}>
                      <img src={CALENDAR_ICON} alt="loan" />
                    </div>
                    <span>Attendance Scheme</span>
                  </div>
                  <Switch
                    checked={
                      client?.feature?.attendancePlanId !== null || false
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={handleSwitchAttendance}
                  />
                </div>
                <div className={Styles.CardContent}>
                  <span>View setting for user yours client</span>
                  <span>
                    {client?.feature?.attendancePlanId !== null
                      ? attendanceScheme?.name
                      : "not set yet"}
                  </span>
                </div>
                <div className={Styles.CardFooter}>
                  <Link
                    to={{
                      pathname: `/superadmin/set-attendance-scheme/${client?._id}`,
                      data: { data: client },
                    }}
                    className={Styles.CardLink}
                  >
                    <span>Set Scheme Setting</span>
                    <ArrowRightAltIcon
                      style={{ color: "#1571DE", marginLeft: 8 }}
                    />
                  </Link>
                </div>
              </div>
              <div className={Styles.SchemeCard}>
                <div className={Styles.CardHeader}>
                  <div className={Styles.HeaderLeft}>
                    <div className={Styles.CardLogo}>
                      <img src={MONEY_ICON} alt="loan" />
                    </div>
                    <span>Gadai</span>
                  </div>
                  <Switch
                    checked={client?.feature?.pawnPlan || false}
                    onChange={handleSwitchGadai}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                <div>
                  <span className={Styles.GadaiDesc}>Scheme for pawn</span>
                </div>
              </div>
              {/* Leave Schema */}
              <div className={Styles.SchemeCard}>
                <div className={Styles.CardHeader}>
                  <div className={Styles.HeaderLeft}>
                    <div className={Styles.CardLogo}>
                      <i className="bx bxs-calendar-star"></i>
                    </div>
                    <span>Leave Scheme</span>
                  </div>
                  <Switch
                    checked={client?.feature?.leavePlanId !== null || false}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={handleSwitchLeavePlan}
                  />
                </div>
                <div className={Styles.CardContent}>
                  {client?.feature?.leavePlanId === null ? (
                    <span>not set yet</span>
                  ) : (
                    <div className={Styles.LeaveCardContent}>
                      <span>{`${leaveSchema?.periodStart} - ${leaveSchema?.periodNext}`}</span>
                      <span>{leaveSchema?.initialBalance} Days</span>
                    </div>
                  )}
                </div>
                <div className={Styles.CardFooter}>
                  <Link
                    to={{
                      pathname: `/superadmin/set-leave-scheme/${client?._id}`,
                      data: { data: client },
                    }}
                    className={Styles.CardLink}
                  >
                    <span>Set Scheme Setting</span>
                    <ArrowRightAltIcon
                      style={{ color: "#1571DE", marginLeft: 8 }}
                    />
                  </Link>
                </div>
              </div>

              <div className={Styles.SchemeCard}>
                <div className={Styles.CardHeader}>
                  <div className={Styles.HeaderLeft}>
                    <div className={Styles.CardLogo}>
                      <i className="bx bx-photo-album"></i>
                    </div>
                    <span>Private Whatsapp</span>
                  </div>
                  <Switch
                    checked={client?.feature?.privateWa?.auth || false}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={handleSwitchPrivateWAPlan}
                  />
                </div>
                <div className={Styles.CardContent}>
                  {client?.feature?.privateWa?.auth === null ? (
                    <div className={Styles.EditPhone}>
                      <div className={Styles.FormSubmit}>
                        <span>Phone Code</span>
                        <input
                          type="text"
                          name="code"
                          value={whatsappPlan?.feature?.privateWa?.auth || ""}
                          onChange={(e) =>
                            setWhatsappPlan((prev) => ({
                              ...prev,
                              feature: {
                                ...prev.feature,
                                privateWa: {
                                  ...prev.feature?.privateWa,
                                  auth: e.target.value,
                                },
                              },
                            }))
                          }
                        />
                      </div>
                      <div className={Styles.ButtonSubmit}>
                        <Button onClick={ onSendData }>Submit</Button>
                      </div>
                    </div>
                  ) : (
                    <div className={Styles.WACardContent}>
                      {/* {onActiveEdit ? (
                    <div className={Styles.EditPhone}>
                      <div className={Styles.FormSubmit}>
                        <span>Phone Code</span>
                        <input
                          type="text"
                          name="code"
                          value={whatsappPlan?.feature?.privateWa?.auth || ""}
                          onChange={(e) =>
                            setWhatsappPlan((prev) => ({
                              ...prev,
                              feature: {
                                ...prev.feature,
                                privateWa: {
                                  ...prev.feature?.privateWa,
                                  auth: e.target.value,
                                },
                              },
                            }))
                          }
                        />
                      </div>
                      <div className={Styles.ButtonSubmit}>
                        <Button onClick={ onSendData }>Submit</Button>
                      </div>
                    </div>
                      )
                       : (
                        <div className={Styles.ItemWA}>
                          <span>Phone code :</span>
                          <span>{client?.feature?.privateWa?.auth}</span>
                        </div>
                      )} */}

                      {onActiveEdit ? (
                        <div className={Styles.EditPhone}>
                          <div className={Styles.FormSubmit}>
                            <span>Phone Code</span>
                            <input
                              type="text"
                              name="code"
                              value={
                                whatsappPlan?.feature?.privateWa?.auth || ""
                              }
                              onChange={(e) =>
                                setWhatsappPlan((prev) => ({
                                  ...prev,
                                  feature: {
                                    ...prev.feature,
                                    privateWa: {
                                      ...prev.feature?.privateWa,
                                      auth: e.target.value,
                                    },
                                  },
                                }))
                              }
                            />
                          </div>
                          <div className={Styles.ButtonSubmit}>
                            <Button onClick={ onSendData }>Submit</Button>
                          </div>
                        </div>
                      ) : (
                        <>
                        <div className={Styles.ItemWA}>
                          <span>Phone code :</span>
                          <span>{client?.feature?.privateWa?.auth}</span>
                        </div>
                          <img src={EDIT_ICON} alt="edit" onClick={ () => setOnActiveEdit(true)} />
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div className={Styles.CardFooter}>
                  {/* <Link
                    to={{
                      pathname: `/superadmin/set-leave-scheme/${client?._id}`,
                      data: { data: client },
                    }}
                    className={Styles.CardLink}
                  >
                    <span>Set Scheme Setting</span>
                    <ArrowRightAltIcon
                      style={{ color: "#1571DE", marginLeft: 8 }}
                    />
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.BMWrapper}>
          <span>Business Manager</span>
          <div className={Styles.BMContainer}>
            {client?.businessManagers?.slice(0, limitBM)?.map((bm, index) => (
              <div key={index} className={Styles.BMCard}>
                <Avatar className={Styles.BMPP} src={bm?.profilePicture} />
                <div className={Styles.BMID}>
                  <span>{truncate(bm?.name)?.toLowerCase()}</span>
                  <span>Business Manager</span>
                </div>
              </div>
            ))}
          </div>
          {client?.businessManagers?.length > 10 && (
            <div className={Styles.BMController}>
              {client?.businessManagers?.length > limitBM && (
                <div className={Styles.ViewMore}>
                  <button onClick={() => setLimitBM(limitBM + 5)}>
                    View More
                  </button>
                </div>
              )}
              {limitBM > 10 && client?.businessManagers?.length > limitBM && (
                <span>|</span>
              )}
              {client?.businessManagers?.length <= limitBM &&
                client?.businessManagers?.length > 10 && (
                  <div className={Styles.ViewMore}>
                    <button onClick={() => setLimitBM(limitBM - 5)}>
                      View Less
                    </button>
                  </div>
                )}
            </div>
          )}
        </div>
        <div className={Styles.EmployeeListWrapper}>
          <span>Employee List</span>
          <div className={Styles.Searchbar}>
            <SearchbarSA
              value={filterName}
              onChange={handleSearchName}
              // onSubmit={handleSubmitFilter}
              searchWidth="434px"
              placeholder="Search employee"
            />
          </div>
          {loadingAllEmployee ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 100,
              }}
            >
              <div style={{ width: 200 }}>
                <LoadingAnimation />
              </div>
            </div>
          ) : (
            <div className={Styles.TableWrapper}>
              <Scrollbar>
                <TableContainer className={Styles.TableContainer}>
                  <Table id="emp-table">
                    <EnhancedTableHead
                      numSelected={employees.length}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      values={employees}
                    />
                    <TableBody>
                      {stableSort(employees, getComparator(order, orderBy))
                        .filter((el) =>
                          el?.name
                            ?.toLowerCase()
                            .includes(filterName.toLowerCase())
                        )
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((each, i) => {
                          const labelId = `enhanced-table-checkbox-${i}`;
                          return (
                            <TableRow
                              hover
                              key={i}
                              tabIndex={-1}
                              id={labelId}
                              style={{
                                background: each?.statusPending
                                  ? "#FFF9E9"
                                  : "#fff",
                              }}
                            >
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                              >
                                <Link
                                  to={{
                                    pathname: `/superadmin/profile-employee/${each?.employeeId}`,
                                    state: { data: each },
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Avatar
                                      src={
                                        each?.profilePicture
                                          ? each.profilePicture
                                          : null
                                      }
                                      className={Styles.Avatar}
                                    />
                                    <Typography
                                      variant="subtitle2"
                                      noWrap
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      {each.name
                                        ? each.name.toLowerCase()
                                        : "-"}
                                    </Typography>
                                  </Stack>
                                </Link>
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {each.employeeId
                                  ? each.employeeId.toLowerCase()
                                  : "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {each.jobTitle
                                  ? each.jobTitle.toLowerCase()
                                  : "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {each?.division
                                  ? each?.division.toLowerCase()
                                  : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {each?.subordinates?.length !== 0 && (
                                  <Grid container>
                                    <Grid item xs={0}>
                                      <AvatarGroup
                                        key={i}
                                        max={4}
                                        classes={{ avatar: Styles.Avatar }}
                                      >
                                        {each?.subordinates?.map((sub, i) => (
                                          <Avatar
                                            key={i}
                                            className={Styles.Avatar}
                                          >
                                            {sub?.name?.charAt(0)}
                                          </Avatar>
                                        ))}
                                      </AvatarGroup>
                                    </Grid>
                                  </Grid>
                                )}

                                {each?.subordinates?.length === 0 && (
                                  <span>-</span>
                                )}
                              </TableCell>
                              <TableCell align="left">
                                <Grid container>
                                  {each?.loanApprovers?.length > 0 && (
                                    <Grid container>
                                      <Grid item xs={0}>
                                        <AvatarGroup
                                          key={i}
                                          max={4}
                                          classes={{ avatar: Styles.Avatar }}
                                        >
                                          {each?.loanApprovers?.map(
                                            (sub, i) => (
                                              <Avatar
                                                key={i}
                                                src={
                                                  sub?.profilePicture
                                                    ? sub?.profilePicture
                                                    : "/assets/images/logo-user.png"
                                                }
                                              />
                                            )
                                          )}
                                        </AvatarGroup>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <CustomTablePagination
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                totalPage={totalPage}
              />
            </div>
          )}
        </div>
      </div>

      {editMode && (
        <EditInfoModal
          setSuccessEdit={setSuccessEdit}
          setEditMode={setEditMode}
          data={client}
          dispatch={dispatch}
          token={token}
        />
      )}

      {/* {successEdit && (
            <EditProfileSuccessModal active={successEdit} onOpen={setSuccessEdit} desc="Client profile successfully updated." />
          )} */}
    </div>
  );
}
