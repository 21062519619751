import * as types from "../../typeExternal";

const initialstate = {
  employeesExternal: [],
  employeesExternalAttendanceData: [],
  loadingEmployeesExternalAttendanceData: false,
  employeeByIdExternal: {},
  employeesExternalAddLocation: [],
  totalPageEmployeeExternalAddLocation: 0,
  isLoading: false,
  totalPage: 0,
  errorMessage: {},
};

const employeesExternalReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_EXTERNAL_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.FETCH_EMPLOYEE_EXTERNAL_SUCCESS: {
      return {
        ...state,
        employeesExternal: action.payload.response,
        isLoading: false,
        totalPage: action.payload.totalPage
      };
    }
    case types.FETCH_EMPLOYEE_EXTERNAL_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };
    }
    case types.FETCH_EMPLOYEE_EXTERNAL_BY_ID: {
      return {
        ...state,
        // employeeByIdExternal: {...state.employeeByIdExternal, [action.payload._id]: action.payload}
        employeeByIdExternal: action.payload.response,
      };
    }
    case types.FETCH_EMPLOYEE_EXTERNAL_ATTENDANCE_DATA_BEGIN: {
      return {
        ...state,
        loadingEmployeesExternalAttendanceData: true,
      };
    }
    case types.FETCH_EMPLOYEE_EXTERNAL_ATTENDANCE_DATA_SUCCESS: {
      return {
        ...state,
        employeesExternalAttendanceData: action.payload,
        loadingEmployeesExternalAttendanceData: false,
      };
    }
    case types.FETCH_EMPLOYEE_EXTERNAL_ATTENDANCE_DATA_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        loadingEmployeesExternalAttendanceData: false,
      };
    }
    case types.FETCH_EMPLOYEE_ADD_LOCATION_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.FETCH_EMPLOYEE_ADD_LOCATION_SUCCESS: {
      return {
        ...state,
        employeesExternalAddLocation: action.payload.data,
        isLoading: false,
        totalPageEmployeeExternalAddLocation: action.payload.totalPage
      };
    }
    case types.FETCH_EMPLOYEE_ADD_LOCATION_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default employeesExternalReducer;
