import React, { useState } from "react";
import "./layout.css";
import Sidebar from "../sidebar/Sidebar";
import { Route } from "react-router-dom";
import RoutesLink from "../routes";
import TopNavExternal from "../topNav/TopNavExternal";
import MiniScreen from "../utils/MiniScreen";
import { Redirect } from "react-router";
import SnackbarComponent from "../utils/Snackbar/SnackbarComponent";
const Layout = () => {
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("accessToken");

  if (!token) {
    return <Redirect to="/admin/employee-external" />;
  }

  return (
    <Route
      render={(props) => (
        <>
          <div className="layout">
            <Sidebar
              {...props}
              onOpenSidebar={open}
              onCloseSidebar={() => setOpen(false)}
            />
            <div className="layout__content">
              <TopNavExternal onOpenSidebar={() => setOpen(true)} />
              <div className="layout__content-main">
                <SnackbarComponent />
                <RoutesLink />
              </div>
            </div>
          </div>
          <div className="mini__screen-layout">
            <MiniScreen />
          </div>
        </>
      )}
    />
  );
};

export default Layout;
