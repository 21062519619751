import React, { useEffect, useMemo, useState } from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import {
  DeleteModalLayout,
  InductionFormEditLayout,
} from "../../../../components/SuperAdmin/_induction";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import fetchInductionById from "../../../../async/induction/fetchInductionById";
import Styles from "./InductionFormEdit.module.css";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";

export default function InductionFormEdit() {
  const token = localStorage.getItem("accessToken");
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState({});

  // const dataInduction = useMemo(() => {
  //   return location?.state?.data;
  // }, [location]);

  const inductionById = useMemo(() => {
    return location?.state?.data;
  }, [location]);

  useEffect(() => {
    (async () => {
      try {
        const result = await fetchInductionById(token, id);
        setData(result);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [id, token]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Induction</title>
      </Helmet>

      <div className={Styles.Spacer}>
        <Breadcrumb>
          <Button
            onClick={() => history.push("/superadmin/induction")}
            style={{ textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>Induction</span>
          </Button>
          <span style={{ fontSize: 20, color: "#255bfc" }}>Edit Induction</span>
        </Breadcrumb>

        <div className={Styles.ButtonAction}>
          <Button
            onClick={(event) => {
              event.preventDefault();
              setIsModalOpen(inductionById);
              // setSelectedInformation(information);
            }}
          >
            <span>Delete</span>
            <img src={DELETE_ICON} alt="delete" />
          </Button>
        </div>
      </div>

      {isModalOpen?._id && (
        <DeleteModalLayout
          active={isModalOpen}
          onOpen={setIsModalOpen}
          description="Are you sure you want to delete this induction?"
          label="Delete Induction"
          pathName="/superadmin/induction"
        />
      )}

      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <InductionFormEditLayout data={ data } id={id} />
        </Grid>
      </Grid>
    </Container>
  );
}
