import axios from "axios";
import * as types from "../../type";
import { setSnackbar } from "./snackbarActions";
import {API} from "../../api";

// FETCH ALL EMPLOYEES
export const fetchAllEmployees = (token, query) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_ALL_EMPLOYEE_BEGIN });
    axios
      .get(`${API}/user/list/client?${query}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_EMPLOYEE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.FETCH_ALL_EMPLOYEE_ERROR,
          payload: err.response,
        });
        console.log(err);
      });
  };
};

// FETCH EMPLOYEE BY ID
export const fetchAllEmployeeById = (token, employeeId, query) => {
  return (dispatch) => {
    axios
      .get(`${API}/user/admin/employee/${employeeId}?${query}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data, 'datasss')
        dispatch({
          type: types.FETCH_ALL_EMPLOYEE_BY_ID,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.error,
          })
        );
      });
  };
};

// UPDATE EMPLOYEE BY ID
export const updateEmployeeById = (token, id, body) => {
  return (dispatch) => {
    axios
      .patch(`${API}/profile/admin/${id}`, body, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.UPDATE_EMPLOYEE_BY_ID,
          payload: res.data.data,
        });
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Employee updated successfully",
          })
        );
        return Promise.resolve();
      })
      .then(() => {
        dispatch(fetchAllEmployees(token))
      })
      .catch((err) => {
        // console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.message,
          })
        );
        return Promise.reject(err);
      });
  };
};

export const updateSPVorAPV = (token, id, body, query) => {
  return (dispatch) => {
    axios
      .patch(`${API}/contract/admin/${id}`, body, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.UPDATE_EMPLOYEE_BY_ID,
          payload: res.data.response,
        });
        return Promise.resolve();
      })
      .then(() => {
        dispatch(fetchAllEmployeeById(token, id, query))
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.error,
          })
        );
        return Promise.reject(err);
      });
  };
}

// UPDATE EMPLOYEE WORK TIME AND SPV
export const updateWorkTimeAndSpv = (token, id, body, clientName) => {
  return (dispatch) => {
    axios
      .patch(`${API}/auth/update/${id}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.UPDATE_EMPLOYEE_BY_ID,
          payload: res.data.data,
        });
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Employee updated successfully",
          })
        );
        return Promise.resolve();
      })
      .then(() => {
        dispatch(fetchAllEmployees(token, clientName));
      })
      .catch((err) => {
        // console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.message,
          })
        );
        return Promise.reject(err);
      });
  };
};

// DELETE EMPLOYEE BY ID
export const deleteEmployeeById = (token, id, clientName) => {
  return (dispatch) => {
    axios
      .delete(`${API}/auth/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.DELETE_EMPLOYEE_BY_ID,
          payload: res.data.data,
        });
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Employee deleted successfully",
          })
        );
      })
      .then(() => {
        dispatch(fetchAllEmployees(token, clientName));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
