/* eslint-disable no-unused-vars */
import React from "react";
import "./sidebar.css";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/my-workspace.png";
import useWindowDimensions from "../../SuperAdmin/_utils/useDimensions";
import { Drawer } from "@mui/material";
import { useRouteMatch } from "react-router";
import HELP_DESK_IMAGE from "../../../assets/images/help-desk.png";
import WHATSAPP_IMAGE from "../../../assets/images/whatsapp-logo.png";
import moment from "moment";

const dataSidebar = [
  {
    display_name: "Employee",
    route: "employee",
    icon: "bx bxs-user-badge",
  },
];

const SidebarItem = (props) => {
  const active = props.active ? "active" : "";

  return (
    <div className="sidebar__item">
      <div className={`sidebar__item-inner ${active}`}>
        <i className={props.icon}></i>
        <span>{props.title}</span>
      </div>
    </div>
  );
};

const userName = localStorage.getItem("userName");
const clientName = localStorage.getItem("clientName");
const employeeId = localStorage.getItem("employeeId");
const joinDate = localStorage.getItem("joinDate");
const salaryPayDay = localStorage.getItem("salaryPayDay");

let preFillMessage = `
Hallo Nina, saya ingin bertanya nih, ini data-data saya yaa:

Nama: ${userName}
NRK: ${employeeId}
Client: ${clientName}
Join Date: ${moment(joinDate).format("YYYY-MM-DD")}
Tanggal Gaji (Setiap Bulan): ${salaryPayDay}
Kendala:
`;

const handleSendMessage = () => {
  const phoneNumber = "6282310997063";
  const encodedMessage = encodeURIComponent(preFillMessage);
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  window.open(whatsappLink, "_blank");
};

const SidebarSupervisor = ({ onOpenSidebar, onCloseSidebar, ...props }) => {
  const isLoan = false;
  const isLocation = true;
  const isAttendance = true;
  let { url } = useRouteMatch();
  const { width } = useWindowDimensions();

  const activeItem = dataSidebar.findIndex((item) =>
    props.location.pathname.includes(item.route)
  );

  console.log();

  // const activeItem = sidebar_items.findIndex(
  //   (item) => `${url}` + item.route === props.location.pathname
  // );

  return (
    <>
      <Drawer
        anchor="left"
        open={width > 1200 || onOpenSidebar}
        onClose={onCloseSidebar}
        variant={width > 1200 ? "permanent" : "temporary"}
        PaperProps={{
          sx: { width: 281, position: "unset" },
        }}
      >
        <div className={`sidebar ${!onOpenSidebar && "close"}`}>
          <Link
            to="/admin-spv/employee"
            // to="/admin/employee-external"
          >
            <div className="sidebar__logo">
              <img src={logo} alt="company logo" />
              <span>MyWorkSpace</span>
            </div>
          </Link>
          {/* {sidebar_items.map((item, index) => (
            <Link to={`/admin` + item.route} key={index}>
              <SidebarItem
                title={item.display_name}
                icon={item.icon}
                active={index === activeItem}
              />
            </Link>
          ))} */}
          {/* {isLoan
            ? dataWithLoan.map((item, index) => (
                <Link to={`/admin` + item.route} key={index}>
                  <SidebarItem
                    title={item.display_name}
                    icon={item.icon}
                    active={index === activeItem}
                  />
                </Link>
              ))
            : 
            dataNoLoan.map((item, index) => (
                <Link to={`/admin` + item.route} key={index}>
                  <SidebarItem
                    title={item.display_name}
                    icon={item.icon}
                    active={index === activeItemWithoutLoan}
                  />
                </Link>
              ))
              } */}
          <div className="wrapper__sidebar">
            <div className="item__container">
              {dataSidebar.map((item, index) => (
                <Link to={`/admin-spv/` + item.route} key={index}>
                  <SidebarItem
                    title={item.display_name}
                    icon={item.icon}
                    active={index === activeItem}
                  />
                </Link>
              ))}
            </div>
            <div className="helpdesk__container">
              <div className="helpdesk__wrapper">
                <div className="helpdesk__image-wrapper">
                  <img src={HELP_DESK_IMAGE} alt="help-desk" />
                </div>
                <div
                  className="helpdesk__item-content"
                  onClick={handleSendMessage}
                >
                  <div className="helpdesk__container-box">
                    <div className="helpdesk__circle-box">
                      <img src={WHATSAPP_IMAGE} alt="whatsapp logo" />
                    </div>
                    <div className="helpdesk__content-text">
                      <span>IT Help Desk</span>
                      <span>Perlu bantuan kami ?</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SidebarSupervisor;
