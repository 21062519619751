import React, {useState } from "react";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";

import { AddClientsIdEmployeeMenu  } from "../../../../components/SuperAdmin/_client";
export default function AddClientsIdEmployee() {
  const [ clientName, setClientName ] = useState()
  const pull_data = (data) => {
    setClientName(data)
  }
  // console.log(clientName, "addclientidEMp");

  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Client Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <span style={{ paddingRight: 10 }}> Client</span>
          <ArrowForwardIosIcon style={{ fontSize: 20, color: "#255bfc" }} />

          <span style={{ paddingLeft: 10 }}> Add Client </span>
          <ArrowForwardIosIcon style={{ fontSize: 20, color: "#255bfc" , marginRight: 10, marginLeft: 10}} />
          <span>{clientName}</span>
          <ArrowForwardIosIcon style={{ fontSize: 20, color: "#255bfc" , marginRight: 10, marginLeft: 10}} />
          <span> Layout Settings </span>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AddClientsIdEmployeeMenu   
            func = {pull_data}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
