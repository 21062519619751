/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from "react";
import Styles from "./EmployeeAssignStoreFMCG.module.css";
import AdminNavFMCG from "../../AdminNavFMCG/AdminNavFMCG";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import SEARCH_ICON from "../../../../assets/images/search-dark.png";
import SEARCH_ICON2 from "../../../../assets/images/search-light.png";
import SEARCH_ICON3 from "../../../../assets/images/search-primary.png";
import AddCircle from "@mui/icons-material/AddCircle";
import Close from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Button,
  Checkbox,
  Stack,
  Avatar,
  Typography,
  Autocomplete,
  TextField,
  TablePagination,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import LoadingFMCG from "../../../utils/LoadingFMCG/LoadingFMCG";
import { useSelector } from "react-redux";
import ModalDeleteWorkOn from "../../../External/WorkOn/Modal/ModalDeleteWorkOn";
import { styled } from "@mui/styles";
import CustomTablePagination from "../../../_pagination/CustomTablePagination";

const links = [
  {
    path: "/admin-fmcg/employees",
    label: "Employee",
  },
  {
    label: "Assign Store",
  },
];

const filteredUser = [
  {
    name: "Satoru Iguchi1",
    employeeId: "12312312312312313",
    position: "Leader",
    profilePicture: "",
  },
  {
    name: "Satoru Iguchi2",
    employeeId: "123123123123asdsadas",
    position: "Leader",
    profilePicture: "",
  },
  {
    name: "Satoru Iguchi3",
    employeeId: "123123123123sasdlamsd",
    position: "Leader",
    profilePicture: "",
  },
  {
    name: "Satoru Iguchi4",
    employeeId: "1231231231233213",
    position: "Leader",
    profilePicture: "",
  },
  {
    name: "Satoru Iguchi5",
    employeeId: "12312312312sfdsf3",
    position: "Leader",
    profilePicture: "",
  },
  {
    name: "Satoru Iguchi6",
    employeeId: "123123123123asdasd",
    position: "Leader",
    profilePicture: "",
  },
  {
    name: "Satoru Iguchi7",
    employeeId: "123123123asdasd123",
    position: "Leader",
    profilePicture: "",
  },
  {
    name: "Satoru Iguchi8",
    employeeId: "1231231weqweq23123",
    position: "Leader",
    profilePicture: "",
  },
  {
    name: "Satoru Iguchi9",
    employeeId: "1231231cvcv23123",
    position: "Leader",
    profilePicture: "",
  },
  {
    name: "Satoru Iguchi10",
    employeeId: "123123aSAs23123",
    position: "Leader",
    profilePicture: "",
  },
];

const MuiAutoComplete = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 5,
    },
  },
  "& .MuiInputBase-input-MuiOutlinedInput-input": {
    padding: 0,
  },
  "& input::placeholder": {
    color: "#A4A4A4",
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "18.2px",
    opacity: 1,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    // border: "1.5px solid #d3c9c9",
    // borderColor: "#d3c9c9",
    borderRadius: 5,
  },
});

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "employeeId", label: "NRK", alignRight: false },
  { id: "jobTitle", label: "Position", alignRight: false },
  // { id: "" }
];

const locationArr = ["Indomaret Gading Serpong", "Khong guan Mayestik", "Superindo"];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, addMode, editMode } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        {addMode && <TableCell></TableCell>}
        {editMode && <TableCell></TableCell>}
        {TABLE_HEAD?.map((headCell, index) => (
          <TableCell
            key={headCell.id + index}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className={Styles.TableSortLabel}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {editMode && <TableCell></TableCell>}
      </TableRow>
    </TableHead>
  );
}

export default function EmployeeAssignLeaderFMCG() {
  const [showSearch, setShowSearch] = useState(false);
  const [showAddAttendees, setShowAddAttendees] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchAddInput, setSearchAddInput] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [selected, setSelected] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [loading, setLoading] = useState(false);


  const handleCloseSearch = () => {
    setSearchMode(false);
    setSearchInput("");
  };

  const handleSubmitArray = (e) => {
    // dispatch(setLocation(token, location?._id, { employeeId: selected }))
    setAddMode(false);
    // dispatch(getLocationById(token, location?._id))
  };

  const handleUnsetUser = (idToDelete) => {
    // let body = {
    //   locationId: location?._id,
    //   employeeId: idToDelete,
    // };
    // dispatch(deleteUserFromLocation(token, body));
  };

  const handleDeleteData = () => {
    // handleDelete(openDeleteModal, selected);
    setOpenDeleteModal(false);
  };

  return (
    <div className={Styles.Container}>
      <div className={Styles.Heading}>
        <AdminNavFMCG links={links}></AdminNavFMCG>
      </div>

      <div className={Styles.BoxGroup}>
        <div className={Styles.WorkTime}>
          <div className={Styles.Header}>
            <span>Assign Store</span>
            <div className={Styles.ActionButtonWrapper}>
              {searchMode ? (
                <div className={Styles.SearchModeHeader}>
                  <div className={Styles.SearchMode}>
                    <img src={SEARCH_ICON3} alt="search" />
                    <input
                      type="text"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                  <button
                    onClick={() => {
                      setSearchMode("");
                      setSearchInput("");
                    }}
                  >
                    <Close sx={{ fontSize: 16 }} />
                  </button>
                </div>
              ) : (
                <>
                  {addMode ? (
                    <div className={Styles.AddMode}>
                      <div className={Styles.AddModeSearch}>
                        <img src={SEARCH_ICON2} alt="search" />
                        <input
                          type="text"
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                      </div>
                      <Button
                        onClick={() => {
                          // handleSetWorkOn(selected, each._id);
                          setAddMode("");
                        }}
                      >
                        Add
                      </Button>

                      <IconButton
                        onClick={() => {
                          setAddMode(null);
                          setSelected([]);
                        }}
                      >
                        <Close sx={{ fontSize: 16, padding: 0 }} />
                      </IconButton>
                    </div>
                  ) : (
                    <>
                      {editMode ? (
                        <div className={Styles.AddMode}>
                          <div className={Styles.AddModeSearch}>
                            <img src={SEARCH_ICON2} alt="search" />
                            <input
                              type="text"
                              value={searchInput}
                              onChange={(e) => setSearchInput(e.target.value)}
                            />
                          </div>
                          <img
                            onClick={() => setOpenDeleteModal(true)}
                            src={DELETE_ICON}
                            alt="delete"
                            className={Styles.DeleteIcon}
                          />
                          <IconButton
                            onClick={() => {
                              setSelected([]);
                              setSearchInput("");
                              setEditMode(null);
                            }}
                          >
                            <Close sx={{ fontSize: 16, padding: 0 }} />
                          </IconButton>
                        </div>
                      ) : (
                        <>
                          {filteredUser.length > 0 && (
                            <>
                              <button
                                onClick={() => {
                                  setSearchMode(true);
                                  setAddMode(null);
                                  setSearchInput("");
                                }}
                              >
                                <img
                                  src={SEARCH_ICON}
                                  alt="search"
                                  className={Styles.SearchIcon}
                                />
                              </button>
                              <button
                                onClick={() => {
                                  setEditMode(true);
                                  setSelected([]);
                                }}
                              >
                                <Edit
                                  sx={{
                                    color: editMode ? "#1571DE" : "#000",
                                    fontSize: "1rem",
                                  }}
                                />
                              </button>
                            </>
                          )}
                          <button
                            onClick={() => {
                              setAddMode(true);
                              setEditMode(null);
                              setSearchInput("");
                              setSearchMode("");
                            }}
                          >
                            <AddCircle
                              sx={{ color: "#000", fontSize: "1rem" }}
                            />
                          </button>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div className={Styles.SelectionLeader}>
            <span>Store</span>
            <Autocomplete
              disablePortal
              options={locationArr}
              value={selectedLocation}
              inputValue={selectedLocation}
              onInputChange={(e, value) => setSelectedLocation(value)}
              renderInput={(params) => (
                <MuiAutoComplete
                  {...params}
                  size="small"
                  placeholder="Store"
                />
              )}
            />
          </div>

          {filteredUser.length > 0 ? (
            <AttendeesTable
              setSelectedAttendee={setSelected}
              // handleUnsetUser={handleUnsetUser}
              searchInput={searchInput}
              selected={selected}
              setSelected={setSelected}
              // token={token}
              // clientCode={location?.clientCode}
              // dispatch={dispatch}
              searchMode={searchMode}
              attendees={filteredUser}
              datas={filteredUser}
              editMode={editMode}
              addMode={addMode}
              setOpenDeleteModal={setOpenDeleteModal}
              // workOnId={each._id}
              loading={ loading }
            />
          ) : (
            <div>
              {addMode ? (
                <AttendeesTable
                  setSelectedAttendee={setSelected}
                  setOpenDeleteModal={setOpenDeleteModal}
                  // handleUnsetUser={handleUnsetUser}
                  // workOnId={each._id}
                  searchInput={searchInput}
                  selected={selected}
                  setSelected={setSelected}
                  // token={token}
                  // clientCode={location?.clientCode}
                  // dispatch={dispatch}
                  searchMode={searchMode}
                  attendees={filteredUser}
                  editMode={editMode}
                  addMode={addMode}
                  datas={filteredUser}
              loading={ loading }

                />
              ) : (
                <div className={Styles.EmptyBox}>
                  <span>no employees has been assigned</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {openDeleteModal && (
        <ModalDeleteWorkOn
          description="Are you sure you want to remove this employee list?"
          label="Remove"
          setOpen={setOpenDeleteModal}
          handleDelete={() => handleDeleteData()}
        />
      )}
    </div>
  );
}

const AttendeesTable = ({
  attendees,
  searchInput,
  editMode,
  addMode,
  clientCode,
  token,
  dispatch,
  selected,
  setSelected,
  searchAddInput,
  setOpenDeleteModal,
  handleUnsetUser,
  setSelectedAttendee,
  searchMode,
  loading
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [listDataContracts, setListDataContracts] = useState("");
  // const [searchTerm, setSearchTerm] = useState("");
  const { employees } = useSelector((state) => state.employees);
  // const [fixedEmployees, setFixedEmployees] = useState(fixedEmployee)
  // const [checkedAdd, setCheckedAdd] = useState()

  // const fixedEmployee = useMemo(() => {
  //   return employees?.filter((el) => {
  //     return !attendees?.find((element) => {
  //       return element?.employeeId === el?.employeeId;
  //     });
  //   });
  // }, [employees, attendees]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (employeeName) => selected?.indexOf(employeeName) !== -1;

  const handleClick = (event, employeeId) => {
    const selectedIndex = selected?.indexOf(employeeId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, employeeId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <div className={Styles.TableWrapper}>
    {
      loading ? (
        <div className={ Styles.ContainerLoading }>
          <LoadingFMCG width={800} height={200} />
        </div>
      ) : (
        <>
        <Scrollbar>
        <TableContainer className={Styles.TableContainer}>
          <Table id="emp-table" className={Styles.Table}>
            {addMode && (
              <colgroup>
                <col style={{ width: "5%" }} />
                <col style={{ width: "50%" }} />
                <col style={{ width: "45%" }} />
              </colgroup>
            )}
            {editMode && (
              <colgroup>
                <col style={{ width: "5%" }} />
                <col style={{ width: "45%" }} />
                <col style={{ width: "45%" }} />
                <col style={{ width: "5%" }} />
              </colgroup>
            )}
            <EnhancedTableHead
              numSelected={attendees?.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              values={attendees}
              addMode={addMode}
              editMode={editMode}
            />
            {addMode && (
              <TableBody>
                {stableSort(filteredUser, getComparator(order, orderBy))
                  .filter(
                    (el) =>
                      el.name
                        ?.toLowerCase()
                        .includes(searchInput.toLowerCase()) ||
                      el.nrk?.toLowerCase().includes(searchInput.toLowerCase())
                  )
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((each, i) => {
                    const isItemSelected = isSelected(each?.employeeId);
                    return (
                      <TableRow
                        hover
                        key={"tablebody" + i}
                        tabIndex={-1}
                        className={Styles.TableRow}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={Styles.TableCell}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(e) => handleClick(e, each.employeeId)}
                          />
                        </TableCell>
                        <TableCell className={Styles.TableCell}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar
                              src={each?.profilePicture}
                              // className={Styles.Avatar}
                            />
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ textTransform: "capitalize" }}
                            >
                              {each.name ? each.name.toLowerCase() : "-"}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell className={Styles.TableCell}>
                          {each.employeeId}
                        </TableCell>
                        <TableCell className={Styles.TableCell}>
                          {each.position}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
            {editMode && (
              <TableBody>
                {stableSort(attendees, getComparator(order, orderBy))
                  .filter(
                    (el) =>
                      el.name
                        ?.toLowerCase()
                        .includes(searchInput.toLowerCase()) ||
                      el.nrk?.toLowerCase().includes(searchInput.toLowerCase())
                  )
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((each, i) => {
                    const isItemSelected = isSelected(each?.employeeId);
                    return (
                      <TableRow
                        hover
                        key={"tablebody" + i}
                        tabIndex={-1}
                        className={Styles.TableRow}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={Styles.TableCell}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(e) => handleClick(e, each.employeeId)}
                          />
                        </TableCell>
                        <TableCell className={Styles.TableCell}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar
                              src={each?.profilePicture}
                              // className={Styles.Avatar}
                            />
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ textTransform: "capitalize" }}
                            >
                              {each.name ? each.name.toLowerCase() : "-"}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell className={Styles.TableCell}>
                          {each.employeeId}
                        </TableCell>
                        <TableCell className={Styles.TableCell}>
                          {each.position}
                        </TableCell>
                        <TableCell className={Styles.TableCell}>
                          <img
                            onClick={() => {
                              setOpenDeleteModal(each);
                              setSelected([each.employeeId]);
                            }}
                            src={DELETE_ICON}
                            alt="delete"
                            className={Styles.DeleteIcon}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
            {!addMode && !editMode && (
              <TableBody>
                {stableSort(attendees, getComparator(order, orderBy))
                  .filter((el) =>
                    addMode || searchMode
                      ? el.name
                          ?.toLowerCase()
                          .includes(searchInput.toLowerCase())
                      : el
                  )
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((each, i) => {
                    return (
                      <TableRow
                        hover
                        key={"tablebody" + i}
                        tabIndex={-1}
                        className={Styles.TableRow}
                      >
                        <TableCell className={Styles.TableCell}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar
                              src={each?.profilePicture}
                              // className={Styles.Avatar}
                            />
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ textTransform: "capitalize" }}
                            >
                              {each.name ? each.name.toLowerCase() : "-"}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell
                          className={`${Styles.TableCell} ${Styles.DeleteCell}`}
                        >
                          <span>{each.employeeId}</span>
                        </TableCell>
                        <TableCell>{each.position}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <CustomTablePagination
        limit={rowsPerPage}
        setLimit={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalPage={Math.ceil(
          !addMode
            ? attendees?.filter(
                (el) =>
                  el.name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                  el.employeeId
                    ?.toLowerCase()
                    .includes(searchInput?.toLowerCase())
              ).length / rowsPerPage
            : filteredUser?.filter(
                (el) =>
                  el.name
                    ?.toLowerCase()
                    .includes(searchAddInput?.toLowerCase()) ||
                  el.employeeId
                    ?.toLowerCase()
                    .includes(searchAddInput?.toLowerCase())
              ).length / rowsPerPage
        )}
      />
        </>
      )
    }

    </div>
  );
};
