import * as types from '../../type'

const initialstate = {
  employees: [],
  employeeById: {},
  loadingAllEmployee: false,
  totalPage: 0,
  errorMessage: {}
};

const employeesReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_EMPLOYEE_BEGIN: {
      return {
        ...state,
        loadingAllEmployee: true
      };
    }
    case types.FETCH_ALL_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employees: action.payload.response,
        loadingAllEmployee: false,
        totalPage: action.payload.totalPage
      };
    }
    case types.FETCH_ALL_EMPLOYEE_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        loadingAllEmployee: false
      };
    }
    case types.FETCH_ALL_EMPLOYEE_BY_ID: {
      return {
        ...state,
        employeeById: action.payload.response,
      };
    }
    default:
      return state;
  }
};

export default employeesReducer;
