import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../../hooks/useDebounce";
import { fetchAllEBPEducation } from "../../../../redux/actions/Business/ebpBusinessActions";
import { Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { LayoutListEBPEducation } from "../../../../components/Business/_ebp";

export default function EBPEducation(){
    const token = localStorage.getItem("accessToken");
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const debounceSearh = useDebounce(searchTerm, 500)
    const { allDataEBPEducation, loadingDataEBP } = useSelector((state) => state.informations)

    useEffect(() => {
        dispatch(fetchAllEBPEducation(token, page, limit, debounceSearh))
    }, [dispatch, token, page, limit, debounceSearh])
    
    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>MyWorkSpace | EBP Education</title>
            </Helmet>
            <div className="wrapper-client-SA">
              <h2 className="page-header" style={{ fontWeight: 500, color: '#0A0A0A', fontSize: 40 }}>
                  Education
              </h2>
          </div>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <LayoutListEBPEducation
                    data={allDataEBPEducation}
                    loading={loadingDataEBP}
                    page={page}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    totalPage={allDataEBPEducation?.totalPages || 1}
                    totalData={allDataEBPEducation?.totalData || 1}
                    token={token}
                />
            </Grid>
          </Grid>
        </Container>
    )
}