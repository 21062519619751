/* eslint-disable no-unused-vars */
import React from "react";
import "./sidebarBusiness.css";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/my-workspace.png";

import { Drawer, Icon, SvgIcon } from "@mui/material";
import { useRouteMatch } from "react-router";
import HELP_DESK_IMAGE from "../../../assets/images/help-desk.png";
import WHATSAPP_IMAGE from "../../../assets/images/whatsapp-logo.png";
import moment from 'moment';
import useWindowDimensions from "../../SuperAdmin/_utils/useDimensions";

const dataRoute = [
  //PAK LEO
  {
    display_name: "Information",
    route: "/information",
    icon: "bx bx-info-circle",
  },
  {
    display_name: "KKB",
    route: "/ebp-kkb",
    icon: (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.1726 12.35C9.80055 12.3507 9.44395 12.4988 9.18082 12.7618C8.91769 13.0248 8.76947 13.3813 8.76859 13.7533V16.6233C8.76859 16.9957 8.91651 17.3528 9.17981 17.6161C9.44311 17.8794 9.80022 18.0273 10.1726 18.0273C10.545 18.0273 10.9021 17.8794 11.1654 17.6161C11.4287 17.3528 11.5766 16.9957 11.5766 16.6233V16.5947V16.596V13.754C11.5759 13.3821 11.4279 13.0256 11.1651 12.7625C10.9022 12.4994 10.5458 12.3511 10.1739 12.35H10.1726Z" fill="#0A0A0A"/>
        <path d="M15.26 3.754H12.558C12.2306 2.72933 11.2866 2 10.172 2C9.0573 2 8.11397 2.72933 7.7913 3.736L7.78664 3.754H5.11997C4.93379 3.754 4.75523 3.82796 4.62358 3.95961C4.49193 4.09126 4.41797 4.26982 4.41797 4.456C4.41797 4.64218 4.49193 4.82074 4.62358 4.95239C4.75523 5.08404 4.93379 5.158 5.11997 5.158H7.82197C8.00064 5.75733 8.38864 6.248 8.89864 6.55533L8.9093 6.56133C6.9953 7.13933 5.6233 8.88133 5.6113 10.946V14.6313C5.61408 14.9375 5.73689 15.2303 5.95331 15.4469C6.16973 15.6634 6.46249 15.7864 6.76864 15.7893H7.71664V13.7893C7.71664 13.1286 7.97911 12.4949 8.44633 12.0277C8.91355 11.5605 9.54723 11.298 10.208 11.298C10.8687 11.298 11.5024 11.5605 11.9696 12.0277C12.4368 12.4949 12.6993 13.1286 12.6993 13.7893V15.7893H13.6466C13.9532 15.7872 14.2466 15.6646 14.4634 15.4479C14.6802 15.2312 14.803 14.9379 14.8053 14.6313V10.982C14.8049 9.99788 14.4868 9.04019 13.8983 8.25139C13.3099 7.46258 12.4825 6.88481 11.5393 6.604L11.5073 6.596C12.0288 6.2851 12.4151 5.79051 12.5906 5.20933L12.5953 5.192H15.2973C15.4833 5.19147 15.6616 5.11734 15.7931 4.98581C15.9246 4.85427 15.9988 4.67602 15.9993 4.49C15.9919 4.2971 15.912 4.1141 15.7756 3.97754C15.6391 3.84098 15.4562 3.76091 15.2633 3.75333H15.262L15.26 3.754ZM10.172 5.89333C9.89428 5.89333 9.62283 5.81099 9.39195 5.65672C9.16106 5.50244 8.98111 5.28317 8.87484 5.02662C8.76858 4.77007 8.74077 4.48778 8.79495 4.21543C8.84912 3.94308 8.98284 3.69291 9.17919 3.49656C9.37554 3.3002 9.62571 3.16648 9.89806 3.11231C10.1704 3.05814 10.4527 3.08594 10.7093 3.19221C10.9658 3.29847 11.1851 3.47843 11.3394 3.70931C11.4936 3.9402 11.576 4.21165 11.576 4.48933L11.5766 4.52933C11.5766 5.28267 10.966 5.89333 10.2126 5.89333L10.1706 5.89267L10.172 5.89333Z" fill="#0A0A0A"/>
      </svg>),
    iconSVG: true
  },
  {
    display_name: "Biaya Pendidikan",
    route: "/ebp-education",
    icon: (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.3487 14.166L10.832 16.066V18.3327H4.9987C4.0737 18.3327 3.33203 17.591 3.33203 16.666V3.33268C3.33203 2.89065 3.50763 2.46673 3.82019 2.15417C4.13275 1.84161 4.55667 1.66602 4.9987 1.66602H5.83203V7.49935L7.91536 6.24935L9.9987 7.49935V1.66602H14.9987C15.4407 1.66602 15.8646 1.84161 16.1772 2.15417C16.4898 2.46673 16.6654 2.89065 16.6654 3.33268V10.4493L15.4154 9.76602L7.3487 14.166ZM19.9987 14.166L15.4154 11.666L10.832 14.166L15.4154 16.666L19.9987 14.166ZM12.4987 15.9077V17.5743L15.4154 19.166L18.332 17.5743V15.9077L15.4154 17.4993L12.4987 15.9077Z" fill="#0A0A0A"/>
      </svg>),
    iconSVG: true
  },
  {
    display_name: "Notifikasi",
    route: "/notification",
    icon: "bx bxs-news",
  }
];

const SidebarItem = (props) => {
  const active = props.active ? "active" : "";
  return (
    <div className="sidebar__item">
      <div className={`sidebar__item-inner ${active}`}>
        {props?.iconSVG ? (
          <SvgIcon>
            {props.icon}
          </SvgIcon>
          ) : ( <i className={props.icon}></i> )
        }
        <span>{props.title}</span>
      </div>
    </div>
  );
};

const userName = localStorage.getItem("userName");
const clientName = localStorage.getItem("clientName");
const employeeId = localStorage.getItem("employeeId");
const joinDate = localStorage.getItem("joinDate");
const salaryPayDay = localStorage.getItem("salaryPayDay");

let preFillMessage = 
`
Hallo Nina, saya ingin bertanya nih, ini data-data saya yaa:

Nama: ${userName}
NRK: ${employeeId}
Client: ${clientName}
Join Date: ${moment(joinDate).format("YYYY-MM-DD")}
Tanggal Gaji (Setiap Bulan): ${salaryPayDay}
Kendala:
`;

const handleSendMessage = () => {
  const phoneNumber = "6282310997063";
  const encodedMessage = encodeURIComponent(preFillMessage);
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  window.open(whatsappLink, "_blank");
};

const SidebarBusiness = ({ onOpenSidebar, onCloseSidebar, ...props }) => {
  const isLoan = false;
  const isLocation = true;
  const isAttendance = true;
  let { url } = useRouteMatch();
  const { width } = useWindowDimensions();
  const activeItem = dataRoute.findIndex(
    (item) => `${url}` + item.route === props.location.pathname
  );

  return (
    <>
      <Drawer
        anchor="left"
        open={width > 1200 || onOpenSidebar}
        onClose={onCloseSidebar}
        variant={width > 1200 ? "permanent" : "temporary"}
        PaperProps={{
          sx: { width: 281, position: "unset" },
        }}
      >
        <div className={`sidebar ${!onOpenSidebar && "close"}`}>
          <Link
            to="/admin"
          >
            <div className="sidebar__logo">
              <img src={logo} alt="company logo" />
              <span>MyWorkSpace</span>
            </div>
          </Link>
          <div className="wrapper__sidebar">
            <div className="item__container">
              {dataRoute.map((item, index) => (
                <Link to={`/admin-business` + item.route} key={index}>
                  <SidebarItem
                    title={item.display_name}
                    icon={item.icon}
                    iconSVG={item?.iconSVG || false}
                    active={index === activeItem}
                  />
                </Link>
              ))}
            </div>
            {/* <div className="helpdesk__container">
              <div className="helpdesk__wrapper">
                <div className="helpdesk__image-wrapper">
                  <img src={HELP_DESK_IMAGE} alt="help-desk" />
                </div>
                <div
                  className="helpdesk__item-content"
                  onClick={handleSendMessage}
                >
                  <div className="helpdesk__container-box">
                    <div className="helpdesk__circle-box">
                      <img src={WHATSAPP_IMAGE} alt="whatsapp logo" />
                    </div>
                    <div className="helpdesk__content-text">
                      <span>IT Help Desk</span>
                      <span>Perlu bantuan kami ?</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SidebarBusiness;
