import React, { useEffect, useMemo } from "react";
import { Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import ActivityMenu from "../../../components/SuperAdmin/_activity/ActivityMenu/ActivityMenu";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientByCodeExternal } from "../../../redux/actions/External/clientExternalActions";

import MAINTENANCE_IMAGE from "../../../assets/images/UnderMaintenance.png";

// import UnderMaintenance from "../../../components/_underMaintenancePage";

export default function ActivitySA() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const clientCode =
    window.sessionStorage.getItem("selectedClientCodeExternal") ||
    window.localStorage.getItem("clientCode");

  const { clientExternalByCode: dataClientExternal } = useSelector(
    (state) => state.clientExternal
  );
  // const isMaintenance = true;
  // const token = localStorage.getItem('accessToken');
  // const clientCode = localStorage.getItem('clientCode')
  // const dispatch = useDispatch();
  // const { activities } = useSelector(state => state?.activity);
  // const [isLimit, setIsLimit] = useState({
  //   limit: 5
  // });

  // useEffect(() => {
  //   dispatch(fetchAllActivity(token, clientCode, isLimit?.limit))
  // },[token, dispatch, clientCode, isLimit?.limit])

  const isActivityPlan = useMemo(() => {
    return dataClientExternal?.activityPlan;
  }, [dataClientExternal]);

  useEffect(() => {
    dispatch(fetchClientByCodeExternal(token, clientCode));
  }, [dispatch, token, clientCode]);

  console.log(dataClientExternal, 'datas')


  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Activity</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          Activity
        </h2>
      </div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          {/* { isMaintenance ? <UnderMaintenance /> : <ActivityMenu />} */}

          {isActivityPlan === true ? (
            <ActivityMenu
              activityPlan={isActivityPlan}
              // data={ activities } limit={ isLimit?.limit } setLimit={ setIsLimit }
            />
          ) : (
            <div className="maintenance-container">
              <div className="maintenance-main">
                <div className="maitenance-image">
                  <img src={MAINTENANCE_IMAGE} alt="" />
                </div>
                <div className="maintenance-text">
                  <div className="maintenance-label">
                    <span>Ooops!</span>
                  </div>
                  <div className="maintenance-text-main">
                    <span>Sorry this feature is not available yet</span>
                  </div>
                  <div className="maintenance-text-last">
                    <span>
                      Activity feature is not available for this client
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
