import Styles from './ModalPreview.module.css';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import { IPhoneMockup } from 'react-device-mockup';
import moment from 'moment';
import info_default_banner from '../../../../../assets/images/info_default_banner.jpg';
import MWSLOGO from '../../../../../assets/images/my-workspace.png';
import WallpaperMock from '../../../../../assets/images/wallpaper-preview.png';

export default function ModalPreview({ value, setModal }) {
    return (
        <div className={Styles.Modal}>
            <div className={Styles.ModalContainer}>
                <div className={Styles.ModalBody}>
                    <div className={Styles.Title}>
                        <span>Preview Notifikasi & Detail</span>
                        <IconButton onClick={() => setModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className={Styles.Body}>
                        <div className={Styles.Component}>
                            <span>Tampilan Notifikasi</span>
                            <IPhoneMockup
                                screenWidth={250}
                                screenType={'notch'}
                                frameColor={'#000'}
                                hideStatusBar
                                transparentNavBar>
                                <div
                                    className={Styles.MockContainer}
                                    style={{
                                        backgroundImage: `url(${WallpaperMock})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        borderRadius: '25px',
                                    }}>
                                    <div className={Styles.MockNotification}>
                                        {value?.pushNotif?.length > 0 ? (
                                            value.pushNotif.map((item, index) => (
                                                <div className={Styles.MockNotificationCard} key={`notif_${index}`}>
                                                    <img src={MWSLOGO} alt="mws-logo" style={{ width: '18px' }} />
                                                    <div className={Styles.MockNotifText}>
                                                        <div className={Styles.MockNotifTitle}>
                                                            <span>MyWorkSpace</span>
                                                            <span>now</span>
                                                        </div>
                                                        <div className={Styles.MockNotifDesc}>{item?.description}</div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className={Styles.MockNotificationCard}>
                                                <img src={MWSLOGO} alt="mws-logo" style={{ width: '18px' }} />
                                                <div className={Styles.MockNotifText}>
                                                    <div className={Styles.MockNotifTitle}>
                                                        <span>MyWorkSpace</span>
                                                        <span>now</span>
                                                    </div>
                                                    <div className={Styles.MockNotifDesc}>
                                                        <p>{value?.content}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </IPhoneMockup>
                        </div>
                        <div className={Styles.Component}>
                            <span>Detail Information Update</span>
                            <IPhoneMockup
                                screenWidth={250}
                                screenType={'notch'}
                                frameColor={'#000'}
                                hideStatusBar
                                transparentNavBar>
                                <div className={Styles.MockContainer}>
                                    <div className={Styles.MockHeader}>
                                        <div className={Styles.MockBack}>
                                            <ArrowBackIosNewRoundedIcon fontSize="4px" />
                                        </div>
                                        <span>Detail Informasi Update</span>
                                    </div>
                                    <div className={Styles.MockBanner}>
                                        <img
                                            src={value?.banner || info_default_banner}
                                            alt="mock-banner"
                                            style={{ maxWidth: '220px', borderRadius: '4px' }}
                                        />
                                    </div>
                                    <div className={Styles.MockTitle}>
                                        <span>{value?.title || '-'}</span>
                                        <span>{moment().format('DD MMM - HH:mm')}</span>
                                    </div>
                                    <div className={Styles.MockContent}>
                                        <span>Deskripsi</span>
                                        <p>{value?.content || '-'}</p>
                                    </div>
                                    <div className={Styles.MockButtonLayout}>
                                        {value?.contact?.whatsapp?.enable && (
                                            <div className={Styles.MockButtonContained}>
                                                <span>Chat Kami Melalui Whatsapp</span>
                                                <WhatsAppIcon fontSize="12px" />
                                            </div>
                                        )}
                                        {value?.contact?.whatsapp?.enable && value?.contact?.email?.enable && (
                                            <div className={Styles.MockDivider}>
                                                <div className={Styles.Divider} />
                                                <span> Atau </span>
                                                <div className={Styles.Divider} />
                                            </div>
                                        )}
                                        {value?.contact?.email?.enable && (
                                            <div className={Styles.MockButtonOutlined}>
                                                <span>Kirim Email</span>
                                                <EmailIcon fontSize="12px" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </IPhoneMockup>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
