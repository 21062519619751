import axios from "axios";
import {API} from "../../redux/api";

const getClientCodeExternal = (token, clientName) =>
    new Promise(async (resolve, reject) => {
        try {
            const { data } = await axios.get(`${API}/user/user-client`, {
                headers: {
                    Authorization: token
                }
            });
            const { response } = data;
            const selected = response.filter(item => item.name_client === clientName);
            resolve(selected[0]?.code_client);
        } catch (error) {
            reject(error);
        }
    });

export default getClientCodeExternal;