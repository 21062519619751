import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { AddNewPromoFMCG } from "../../../../components/FMCG";

export default function AddNewPromo() {
  const location = useLocation();
  const addNewPromoPath = location?.state?.location;

  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Setup</title>
      </Helmet>
      <AddNewPromoFMCG location={addNewPromoPath} />
    </div>
  );
}
