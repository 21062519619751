import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Styles from "./AddLeaveModal.module.css";

export default function AddLeaveModal({
  open,
  setOpen,
  value,
  setValue,
  handleSubmit,
}) {
  const handleChange = (name, value) => {
    setValue((prev) => ({ ...prev, [name]: value }));
  };

  const disabled = value.dayoff === "" || value.endPeriod === "";

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogContent>
        <div className={Styles.Container}>
          <div className={Styles.HeaderImage}>
            <i className="bx bxs-calendar-star"></i>
          </div>
          <div className={Styles.HeaderTitle}>
            <p>Leave Setting</p>
          </div>
          <div className={Styles.InputContainer}>
            <div className={Styles.InputControl}>
              <p>Leave Amount</p>
              <TextField
                size="small"
                type="number"
                placeholder="0"
                fullWidth
                value={value.dayoff}
                onChange={(e) => handleChange("dayoff", e.target.value)}
              />
            </div>
            <div className={Styles.MultiInputContainer}>
              <div className={Styles.MultiLabel}>
                <p>Leave Period</p>
                <p>
                  {value.startPeriod} - {value.endPeriod}
                </p>
              </div>
              <div className={Styles.MultiInput}>
                <TextField
                  size="small"
                  placeholder="0"
                  sx={{ width: "48%" }}
                  disabled
                  value="January"
                />

                <FormControl sx={{ width: "48%" }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value.endPeriod}
                    onChange={(e) => handleChange("endPeriod", e.target.value)}
                    displayEmpty
                    placeholder="month"
                    size="small"
                  >
                    <MenuItem value="" disabled>
                      Select Month...
                    </MenuItem>
                    {month.map((el, idx) => (
                      <MenuItem key={idx} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={Styles.SwitchInput}>
                <p>Next Year</p>
                <Switch
                  checked={value.nextYear}
                  onChange={(e) => handleChange("nextYear", e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
            <div className={Styles.WrapperButtonModal}>
              <Button
                style={{
                  border: "1px solid #333333",
                  backgroundColor: "#FFFFFF",
                  borderRadius: 5,
                  textTransform: "none",
                  color: "#333333",
                  width: "48%",
                  height: 38,
                  fontSize: 13,
                  fontWeight: 500,
                }}
                onClick={() => {
                  setOpen(false);
                  setValue({
                    dayoff: "",
                    startPeriod: "January",
                    endPeriod: "",
                    nextYear: true,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  backgroundColor: disabled ? "#C2C2C2" :"#1571DE",
                  textTransform: "none",
                  color: "#FFFFFF",
                  width: "48%",
                  height: 38,
                  fontSize: 13,
                  fontWeight: 500,
                }}
                disabled={disabled}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
