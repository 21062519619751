/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppProfileEmployeeMenu } from "../../../../components/SuperAdmin/_employee";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { fetchAllEmployeeById } from "../../../../redux/actions/SuperAdmin/employeesActions";

export default function ProfileEmployeeSA() {
  const location = useLocation();
  const employeeInfo = location?.state?.data;
  const token = localStorage.getItem('accessToken');
  const { employeeById } = useSelector((state) => state.employees);
  const {id} = useParams()
  const [dataEmployee, setDataEmployee] = useState()
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllEmployeeById(token, id, `clientCode=${employeeInfo?.clientCode}` ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setDataEmployee(employeeById)
  },[employeeById])


  // if (!employeeById) {
  //   return null;
  // }
  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Employee Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/employee")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>
                Employee
              </span>
            </Button>
            <span style={{ fontSize: 20, color: "#255bfc", textTransform:'capitalize' }}>{employeeInfo?.user?.name?.toLowerCase() || employeeInfo?.name.toLowerCase()}</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppProfileEmployeeMenu employeeId={id} employee={dataEmployee} id={id} setData={setDataEmployee} employeeInfo={employeeInfo}/>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
