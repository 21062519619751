import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  // pdf,
  // PDFDownloadLink,
  Image,
  Font,
} from "@react-pdf/renderer";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataPayslipById } from "../../../../redux/actions/SuperAdmin/slipPaymentActions";
import { useCalendar } from "../../../utils/hooks";
import { Helmet } from "react-helmet";
import PermataLogo from "../../../../assets/images/permata-logo-pdf.png";

import PoppinsFontRegular from '../../../../assets/font/poppins/Poppins-Regular.ttf';
import PoppinsFont600 from  '../../../../assets/font/poppins/Poppins-Medium.ttf';
import PoppinsFont500 from '../../../../assets/font/poppins/Poppins-SemiBold.ttf';
import PoppinsFont500Italic from '../../../../assets/font/poppins/Poppins-SemiBoldItalic.ttf';

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: PoppinsFontRegular,
    },
    {
      src: PoppinsFont500,
    },
    {
      src: PoppinsFont500Italic,
      fontStyle: 'italic'
    },
    {
      src: PoppinsFont600,
    },
  ],
});

const Styles = StyleSheet.create({
  Body: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 35,
  },
  Heading: {
    borderTop: "5px solid #203D7F",
  },
  HeadingText: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 20,
  },
  Image: {
    width: 142,
    height: 41,
  },
  TextHeading: {
    color: "#333333",
    fontSize: 15,
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  DetailEmployee: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 50,
  },
  DetailLeft: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  TextItemDetailLeft : {
    color: '#333333',
    fontWeight: 600,
    fontSize: 13,
    fontFamily: "Poppins"
  },
  TextItemDetailRight : {
    color: '#333333',
    fontSize: 13,
    fontFamily: "Poppins",
    textTransform: 'capitalize'
  },
  DetailRight: {
    flex: 1,
    marginLeft: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  BoxIncome: {
    paddingTop: 50,
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between"
  },  
  BoxIncomeLeft: {
    flex: 1
  },
  BoxIncomeRight: {
    flex: 1,
    marginLeft: 50,
  },
  BoxIncomeTop : {
    width: 150,
    height: 41,
    backgroundColor: '#203D7F',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  BoxIncomeBottom : {
    width: 210,
    height: 41,
    backgroundColor: '#203D7F',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginLeft: 'auto',
    paddingRight: 15

  },
  TextInsideBox : {
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "Poppins",
    color: '#FFFFFF'
  },
  ItemDetailBox : {
    borderTop: "5px solid #203D7F",
    backgroundColor: '#F1F1F1', 
    padding: 15,
    // display: 'flex',
    // flexDirection: "row",
    // justifyContent: 'space-between',

  },
  ContainerIncome :{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  ItemBoxLeft: {
    flex: 1,

  },
  ItemBoxRight: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end'
  },
  ContainerBottom: {
    borderBottom: "5px solid #203D7F",
    marginTop: 'auto'
  },
  Info : {
    display: 'flex',
    flexDirection: 'row',
    justifyContent:"space-between",
    paddingBottom: 60
  },
  InfoLeft: {
    flex: 1,
  },
  InfoRight: {
    flex: 1,
    marginLeft: 50
  },
  TextNotes : {
    color: "#333333",
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Poppins"
  },
  TextNotesContent : {
    color: "#333333",
    fontSize: 12,
    fontWeight: 500,
    fontStyle: "italic",
    fontFamily: "Poppins"
  },
  ContainerNotes: {
    backgroundColor: "#f1f1f1",
    padding: 15
  },
  BoxInfoTop : {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  TextHidden : {
    fontSize: 0,
    fontFamily: "Poppins",
    color: '#FFFFFF'
  },
  TextBoxInfoTop: {
    color: '#000000',
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: 16
  },
  BoxInfoBottom : {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  BoxInfoLeft: {
    flex: 1,
  },
  BoxInfoRight: {
    flex: 1,
    display: "flex",
    alignItems: 'flex-end'
  }
});

function formatNpwp(value) {
  if (value) {
    value = value?.replace(/[A-Za-z\W\s_]+/g, '');
    let split = 6;
    const dots = [];

    for (let i = 0, len = value.length; i < len; i += split) {
      split = i >= 2 && i <= 6 ? 3 : i >= 8 && i <= 12 ? 4 : 2;
      dots.push(value.substr(i, split));
    }

    const temp = dots.join('.');
    return temp.length > 12
      ? `${temp.substr(0, 12)}-${temp.substr(12, 7)}`
      : temp;
  }
}

const PDFDataPayslip = () => {
  const token = localStorage.getItem("accessToken");
  const { id } = useParams();
  const dataPayslip = useSelector((state) => state.payslip.dataPayslipById);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataPayslipById(token, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return dataPayslip ? (
    //   <PDFDownloadLink
    //   document={<DataToView data={dataPayslip} />}
    //   fileName="fee_acceptance.pdf"
    // >
    //   {({ blob, url, loading, error }) =>
    //     loading ? "Loading document..." : "Download now!"
    //   }
    // </PDFDownloadLink>
    <PDFViewer style={{ width: window.innerWidth, height: "100vh" }}>
      <DataToView data={dataPayslip} />
    </PDFViewer>
  ) : // <PDFDownloadLink
  //   document={<DataToView data={dataPayslip} />}
  //   fileName="fee_acceptance.pdf"
  // >
  //   {({ blob, url, loading, error }) =>
  //     loading ? "Loading document..." : "Download now!"
  //   }
  // </PDFDownloadLink>
  null;
};

const DataToView = ({ data }) => {
  const calendar = useCalendar();
  let month = calendar.months.eng.full[data?.PayrollMonth?.split(" ")[0] - 1];
  let year = data?.PayrollMonth?.split(" ")[2];
  function numberWithPoint(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return (
    <Document>
      <Helmet>
        <title>MyWorkSpace | Payslip Report</title>
      </Helmet>
      <Page style={Styles.Body} size="A3">
        <View style={Styles.Heading}>
          <View style={Styles.HeadingText}>
            <Image src={PermataLogo} alt="" style={Styles.Image} />
            <Text style={Styles.TextHeading}>Payment Statement</Text>
          </View>
        </View>
        <View style={Styles.DetailEmployee}>
          <View style={Styles.DetailLeft}>
            <View style={Styles.ItemDetailLeft}>
              <Text style={[Styles.TextItemDetailLeft]}>Name</Text>
              <Text style={[Styles.TextItemDetailLeft]}>NIP</Text>
              <Text style={Styles.TextItemDetailLeft}>Position</Text>
              <Text style={Styles.TextItemDetailLeft}>Organization</Text>
              <Text style={Styles.TextItemDetailLeft}>Location</Text>
            </View>
            <View style={Styles.ItemDetailRight}>
              <Text style={Styles.TextItemDetailRight}>{data?.Name ? data?.Name?.toLowerCase() : "-"}</Text>
              <Text style={[Styles.TextItemDetailRight]}>{data?.NIP ? data?.NIP : "-"}</Text>
              <Text style={Styles.TextItemDetailRight}>{data?.Position ?  data?.Position?.toLowerCase() : "-"}</Text>
              <Text style={Styles.TextItemDetailRight}>{data?.Client ? data?.Client : "-"}</Text>
              <Text style={Styles.TextItemDetailRight}>{data?.Location ?  data?.Location?.toLowerCase() : "-"}</Text>
            </View>
          </View>
          <View style={Styles.DetailRight}>
            <View style={Styles.ItemDetail}>
              <Text style={[Styles.TextItemDetailLeft]}>Month</Text>
              <Text style={[Styles.TextItemDetailLeft]}>Join Date</Text>
              <Text style={Styles.TextItemDetailLeft}>Tax Material Status</Text>
              <Text style={Styles.TextItemDetailLeft}>Employement Status</Text>
              <Text style={Styles.TextItemDetailLeft}>NPWP</Text>
            </View>
            <View style={Styles.ItemDetailRight}>
              <Text style={Styles.TextItemDetailRight}>{data?.PayrollMonth ? `${month} ${year}` : "-"}</Text>
              <Text style={[Styles.TextItemDetailRight]}>{data?.JoinDate ? data?.JoinDate : "-"}</Text>
              <Text style={Styles.TextItemDetailRight}>{data?.Status ?  data?.Status : "-"}</Text>
              <Text style={Styles.TextItemDetailRight}>{data?.EmploymentStatus ? data?.EmploymentStatus : "-"}</Text>
              <Text style={Styles.TextItemDetailRight}>{data?.NPWP?  formatNpwp(data?.NPWP) : "-"}</Text>
            </View>
          </View>
        </View>
        <View style={Styles.BoxIncome}>
          <View style={Styles.BoxIncomeLeft}>
          <View style={Styles.BoxIncomeTop}>
            <Text style={Styles.TextInsideBox}>INCOME (A)</Text>
          </View>
          <View style={Styles.ItemDetailBox}>
            {Object.keys(data?.Income || {}).map((key,index) => (
            <View style={Styles.ContainerIncome} key={index}>
            <View style={Styles.ItemBoxLeft}>
              <Text style={Styles.TextItemDetailRight}>{key}</Text>
            </View>
            <View style={Styles.ItemBoxRight}>
              <Text style={Styles.TextItemDetailRight}>Rp.{data?.Income[key] ? numberWithPoint(data?.Income[key]) : 0}</Text>
            </View>
            </View>
            ))}
          </View>
          <View style={Styles.BoxIncomeBottom}>
            <Text style={Styles.TextInsideBox}>Rp.{data?.TotalIncome ? numberWithPoint(data?.TotalIncome) : 0}</Text>
          </View>
          </View>
          <View style={Styles.BoxIncomeRight}>
          <View style={Styles.BoxIncomeTop}>
            <Text style={Styles.TextInsideBox}>DEDUCTION (B)</Text>
          </View>
          <View style={Styles.ItemDetailBox}>
          {Object.keys(data?.Deduction || {}).map((key, index) => (
            <View style={Styles.ContainerIncome} key={index}>
            <View style={Styles.ItemBoxLeft}>
              <Text style={Styles.TextItemDetailRight}>{key}</Text>
            </View>
            <View style={Styles.ItemBoxRight}>
              <Text style={Styles.TextItemDetailRight}>Rp.{data?.Deduction[key] ? numberWithPoint(data?.Deduction[key]) : 0}</Text>
            </View>
            </View>
            ))}
          </View>
          <View style={Styles.BoxIncomeBottom}>
            <Text style={Styles.TextInsideBox}>Rp.{data?.TotalDeduction ? numberWithPoint(data?.TotalDeduction) : 0}</Text>
          </View>
          </View>
        </View>
        <View style={Styles.ContainerBottom}>
          <View style={Styles.Info}>
            <View style={Styles.InfoLeft}>
              <Text style={Styles.TextNotes}>Notes</Text>
              <View style={Styles.ContainerNotes}>
                <Text style={Styles.TextNotesContent}>Payment statment ini sudah di generate sistem tidak di perlukan tanda tangan</Text>
              </View>
            </View>
            <View style={Styles.InfoRight}>
              <Text style={[Styles.TextHidden]}>Right</Text>
              <View style={Styles.BoxInfoTop}>
                <Text style={Styles.TextBoxInfoTop}>TAKE HOME PAY (A - B)</Text>
                <Text style={Styles.TextBoxInfoTop}>Rp.{data?.TakeHomePay ? numberWithPoint(data?.TakeHomePay) : 0}</Text>
              </View>
              <View style={Styles.BoxInfoBottom}>
              <View style={Styles.BoxInfoLeft}>
                <Text style={{ fontSize: 10, fontWeight: 400, fontFamily: "Poppins"}}>Bank Name</Text>
                <Text style={{ fontSize: 10, fontWeight: 400, fontFamily: "Poppins"}}>Transfer Account Number</Text>
              </View>
              <View style={Styles.BoxInfoRight}>
                <Text style={{ fontSize: 10, fontWeight: 400, fontFamily: "Poppins"}}>{data?.BankName ? data?.BankName : "-"}</Text>
                <Text style={{ fontSize: 10, fontWeight: 400, fontFamily: "Poppins"}}>{data?.AccountNumber ? data?.AccountNumber : "-"}</Text>
              </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFDataPayslip;
