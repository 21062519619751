import axios from 'axios'
import * as types from '../../type'
import {API} from '../../api'

export const fetchAllUsers = (token) => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_ALL_USERS_BEGIN })
        axios
            .get(`${API}/user/user-admin`, {
                headers: {
                    Authorization: token,
                }
            })
            .then((res) => {
                dispatch({
                    type: types.FETCH_ALL_USERS_SUCCESS,
                    payload: res.data.response
                })
            })
            .catch((err) => {
                dispatch({
                    type: types.FETCH_ALL_USERS_ERROR,
                    payload: err.response.data.error
                })
                console.log(err)
            })
    }
}

export const registerUserAdmin = (token, body) => {
    return (dispatch) => {
        axios
            .patch(`${API}/user/admin`, body, {
                headers: {
                    Authorization: token
                }
            })
            .then((res) => {
                dispatch({
                    type: types.REGISTER_USER_ADMIN_SUCCESS,
                    payload: res.data.response
                })
            })
            .then(() => {
                dispatch(fetchAllUsers(token))
                return Promise.resolve()
            })
            .catch((err) => {
                console.log(err)
                return Promise.reject()
            })
    }
}

export const getUserAdminById = (token, id) => {
    return (dispatch) => {
        axios
            .get(`${API}/user/user-admin/${id}`, {
                headers: {
                    Authorization: token
                }
            })
            .then((res) => {
                dispatch({
                    type: types.GET_USER_ADMIN_BY_ID,
                    payload: res.data.response
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }
}

export const updateUserAdmin = (token, id, body, paramsId) => {
    return (dispatch) => {
        axios
            .patch(`${API}/user/user-admin/${id}`, body, {
                headers: {
                    Authorization: token
                }
            })
            .then((res) => {
                dispatch({
                    type: types.UPDATE_USER_ADMIN,
                    payload: res.data.response
                })
            })
            .then(() => {
                dispatch(getUserAdminById(token, paramsId))
                return Promise.resolve()
            })
            .catch((err) => {
                console.log(err)
                return Promise.reject()
            })
    }
}

export const deleteUserAdmin = (token, id) => {
    return (dispatch) => {
        axios
            .delete(`${API}/user/user-admin/${id}`, {
                headers: {
                    Authorization: token
                }
            })
            .then((res) => {
                dispatch({
                    type: types.DELETE_USER_ADMIN,
                    payload: res.data.response
                })
            })
            .then(() => dispatch(fetchAllUsers(token)))
            .catch((err) => {
                console.log(err)
            })
    }
}
