import { Button, Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import UserSettingDetails from "../../../../components/SuperAdmin/_user/UserDetailSA/UserDetail";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import UnderMaintenance from "../../../../components/_underMaintenancePage";
import { getUserAdminById } from "../../../../redux/actions/SuperAdmin/userAdmin";
// import {} from '../../../../components/SuperAdmin/_user/TableUsers'


export default function DetailUserSA() {
    // const [selectedUserType, setSelectedUserType] = useState("All");
    const dispatch = useDispatch()
    const { id } = useParams()
    const history = useHistory()

    const { userById } = useSelector(state => state.userAdmin)

    const token = localStorage.getItem("accessToken")
    const isMaintenance = false;


    useEffect(() => {
        dispatch(getUserAdminById(token, id))
    }, [dispatch, token, id])

    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>MyWorkSpace | User Settings</title>
            </Helmet>
            <Breadcrumb>
                <Button
                    onClick={() => history.push("/superadmin/user")}
                    style={{ textTransform: "none" }}
                >
                    <span style={{ fontSize: 20, color: "#255bfc" }}>User Settings</span>
                </Button>
                <span style={{ fontSize: 20, color: "#255bfc", textTransform:'capitalize' }}>{userById[0]?.name?.toLowerCase()}</span>
            </Breadcrumb>

            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    {/* <TableUserMenu /> */}
                    {isMaintenance ? <UnderMaintenance /> : <UserSettingDetails userData={userById[0]} />}

                    {/* {isMaintenance ?  <UnderMaintenance /> : <TableUserMenu selectedUserType={selectedUserType} setSelectedUserType={setSelectedUserType}/>} */}
                </Grid>
            </Grid>
        </Container>
    );
}
