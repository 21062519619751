import React from "react";
import Styles from './ModalChangeStatus.module.css'

import CHANGE_ICON from "../../../../assets/images/change-status-induction.png";
import { Button } from "@mui/material";
import updateInduction from "../../../../async/induction/updateInduction";
import { useHistory } from "react-router";

export default function ModalChangeStatus({ active, onOpen, label, description, value, setValue, checked, token, id }) {
  const history = useHistory();
  
  const handleSave = () => {
    setValue((prev) => ({
      ...prev,
      isPublish: checked
    }))
    const data = {
      isPublish: checked
    }
    updateInduction(token, id, data)
    onOpen(false);
    history.push("/superadmin/induction");
  };
  return (
    <div className={Styles.ModalDeleteBackground}>
      <div className={Styles.ModalContainerContent}>
        <div className={Styles.WrapperItemModalDelete}>
          <div className={Styles.ItemTopModalDelete}>
            <img src={ CHANGE_ICON} alt="delete-icon" />
          </div>
        </div>
        <div className={Styles.ContentModalDelete}>
          <div className={Styles.TextModalDelete}>
            <span>{label}</span>
            <span>{description}</span>
          </div>
          <div className={Styles.WrapperButtonModalDelete}>
            <Button
              style={{
                border: "1px solid #333333",
                backgroundColor: "#FFFFFF",
                borderRadius: 8,
                textTransform: "none",
                color: "#333333",
                width: 171,
                height: 38,
                fontSize: 13,
                fontWeight: 500,
              }}
              onClick={() => {
                onOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                borderRadius: 8,
                backgroundColor: "#1571DE",
                textTransform: "none",
                color: "#FFFFFF",
                width: 171,
                height: 38,
                fontSize: 13,
                fontWeight: 500,
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
