import axios from "axios";
import {API} from "../../api";
// import { FETCH_ALL_BPJS_DATA, FETCH_BPJS_DATA_BY_ID } from "../../type";
import * as types from '../../type'
// ALL BPJS DATA
export const fetchAllBpjsData = (token, clientName) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_ALL_BPJS_DATA_BEGIN})
    axios
      .get(`${API}/auth/tko/${clientName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_BPJS_DATA_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_ALL_BPJS_DATA_BEGIN, payload: err.response})
        console.log(err);
      });
  };
};

// BPJS DATA BY ID
export const fetchBpjsDataById = (token, id) => {
  return (dispatch) => {
    axios
      .get(`${API}/auth/bpjs/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_BPJS_DATA_BY_ID,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
