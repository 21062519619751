import React, { useState } from "react";
import "./AddClientsIdEmployee.css";
import Checkbox from "@mui/material/Checkbox";
import { useLocation } from "react-router";
export default function AddClientsIdEmployee(props) {
  const [checkbox1, setCheckbox1] = useState();
  const [checkbox2, setCheckbox2] = useState();
  const handleCheckbox1 = (e) => {
    setCheckbox1(e.target.checked);
  };
  const handleCheckbox2 = (e) => {
    setCheckbox2(e.target.checked);
  };
  // const client = useSelector((state) => state.client.clientNotSet);
  const location = useLocation();
  const name = location.state.name;
  props.func(name);
  // console.log(name, "cek");
  return (
    <div className="container-ACIE">
      <div className="wrapper-ACIE">
        <div className="title-ACIE">
          <span>Layout Settings</span>
        </div>
        <hr
          style={{ width: "100%", border: "1px solid #ECE6E6", marginTop: 8 }}
        />
        <div className="items-ACIE">
          <div className="title-main-ACIE">
            <span>Leader Feature</span>
          </div>
          <div className="checkbox-item-ACIE">
            <div className="item-1-ACIE">
              <Checkbox value={checkbox1} onChange={handleCheckbox1} />
              <span>Approve History</span>
            </div>
            <div className="item-1-ACIE">
              <Checkbox value={checkbox2} onChange={handleCheckbox2} />
              <span>My Suboordinate</span>
            </div>
          </div>
        </div>
        <div className="btn-ACIE">
          <button>Save</button>
        </div>
      </div>
    </div>
  );
}
