import React from "react";
import { SPGStoreFMCG } from "../../../../components/FMCG";

export default function Spg() {
  return (
    <div style={{ height: "100vh" }}>
      <SPGStoreFMCG />
    </div>
  );
}
