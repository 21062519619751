import { Button, Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

/* eslint-disable no-unused-vars */

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { AppBankDetailSA } from "../../../../components/SuperAdmin/_database";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { getBankById } from "../../../../redux/actions/SuperAdmin/bankActions";

export default function BankDetailSA() {
  const token = localStorage.getItem("accessToken");
  const params = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const bankData = useSelector((state) => location?.state?.data);
  const history = useHistory();
  const { bankById } = useSelector(state => state.banks);

    useEffect(() => {
      dispatch(getBankById(token, params?.id))
    }, [dispatch, token, params.id])

  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Detail Bank</title>
        </Helmet>
        <Breadcrumb>
          <Button
            onClick={() => history.push("/superadmin/database")}
            style={{ textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>Master Data</span>
          </Button>
          <span style={{ fontSize: 20, color: "#255bfc" }}>{bankData?.name || bankById?.name}</span>
        </Breadcrumb>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} style={{ padding: '20px 0' }}>
            <AppBankDetailSA bank={bankById} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
