import axios from "axios";
import {API} from "../../redux/api";

const fetchPreRegisteredEmployees = (token, query) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data} = await axios.get(`${API}/user/list/client?${query}`, {
        headers : {
          Authorization: token
        }
      });
      resolve(data );
    } catch (error) {
      reject(error);
    }
  });

export default fetchPreRegisteredEmployees;
