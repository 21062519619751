import React, { useState } from "react";
import { useHistory } from "react-router";
import "./DetailsProductCompetitor.css";
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Breadcrumb from "../../../utils/Breadcrumbs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/styles";
import { makeStyles } from "@mui/styles";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 5,
    },
    "& .MuiInputBase-input-MuiOutlinedInput-input": {
      padding: 0,
    },
    "& input::placeholder": {
      color: "#A4A4A4",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "18.2px",
      opacity: 1,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 5,
    },
    "& .MuiSelect-select": {
      color: "black",
      fontSize: 13,
      fontWeight: 400,
    },
    // "& input::placeholder": {
    //   color: "#A4A4A4",
    //   fontSize: 13,
    //   fontWeight: 400,
    //   lineHeight: "18.2px",
    //   opacity: 1,
    // },
  },
  adorment: {
    "& .MuiTypography-root ": {
      color: "#A4A4A4",
      fontSize: 15,
      fontWeight: 400,
      lineHeight: "18.2px",
    },
  },
}));

const statusArr = ["Active", "Non Active"];

const DetailsProductCompetitor = ({ location }) => {
  const classes = useStyles();
  const history = useHistory();
  const [values, setValues] = useState({});
  // const [page, setPage] = useState(0);
  // const [order, setOrder] = useState("asc");
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState("");
  // const [orderBy, setOrderBy] = useState("productName");
  // const [listProductFMCG, setListProductFMCG] = useState("");
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [disabledButton, setDisabledButton] = useState(true);
  const [selectedInput, setSelectedInput] = useState({
    competitorName: "",
    competitorCode: "",
    competitorSize: "",
    status: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (event.target.value.length >= 1) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (location === "addProductCompetitorFMCG") {
      // const data = {
      //   ProductName: values.ProductName,
      //   ProductCode: values.ProductCode,
      //   Size: values.ProductSize,
      //   Brand: values.Brand,
      //   Price: +values.Price,
      //   ProductCategory: selectedInput.productCategory,
      //   Status: selectedInput.productStatus,
      // };

      try {
        console.log("data submitted");

        history.push(`/admin-fmcg/setup/competitor`);
      } catch (error) {
        console.log("data error");
      }
    }

    if (location === "editProductCompetitorFMCG") {
      // const data = {
      //   ProductName: values.ProductName,
      //   ProductCode: values.ProductCode,
      //   Size: values.ProductSize,
      //   Brand: values.Brand,
      //   ProductCategory: selectedInput.productCategory,
      //   Status: selectedInput.productStatus,
      // };

      try {
        console.log("data submitted edit ");

        history.push(`/admin-fmcg/setup/competitor`);
      } catch (error) {
        console.log("data error edit");
      }
    }
  };

  return (
    <div className="ContainerProductCompetitor">
      <div className="WrapperProductCompetitor">
        <div className="WrapperDetailsProductCompetitor">
          <div className="ButtonRouteBack" onClick={() => history.go(-1)}>
            <IconButton>
              <ArrowBackIcon sx={{ color: "#202020", width: 24, height: 24 }} />
            </IconButton>
          </div>
          <div className="WrapperBreadCrumbs">
            {location === "addProductCompetitorFMCG" ? (
              <Breadcrumb>
                <Button disabled style={{ textTransform: "none" }}>
                  <span className="BreadCrumbsTextGrey">
                    Product Competitor
                  </span>
                </Button>
                <Button disabled style={{ textTransform: "none" }}>
                  <span className="BreadCrumbsTextBlue">
                    Add Product Competitor Detail
                  </span>
                </Button>
              </Breadcrumb>
            ) : (
              <Breadcrumb>
                <Button disabled style={{ textTransform: "none" }}>
                  <span className="BreadCrumbsTextGrey">
                    Product Competitor
                  </span>
                </Button>
                <Button disabled style={{ textTransform: "none" }}>
                  <span className="BreadCrumbsTextBlue">
                    Edit Product Competitor Detail
                  </span>
                </Button>
              </Breadcrumb>
            )}
          </div>
        </div>

        <div className="WrapperContentProductCompetitor">
          {location === "addProductCompetitorFMCG" ? (
            <form
              className="WrapperFormProductCompetitor"
              onSubmit={handleSubmit}
            >
              <div className="WrapperItemsInputDetailsProductCompetitor">
                <div className="ItemsInputDetailsProductCompetitor">
                  <div className="ItemInputDetailsProductCompetitor">
                    <span>Competitor Name</span>
                    <MuiTextField
                      size="small"
                      placeholder="Competitor Name"
                      value={values.competitorName || ""}
                      onChange={handleChange("competitorName")}
                      type="text"
                      required
                    />
                  </div>
                  <div className="ItemInputDetailsProductCompetitor">
                    <span>Competitor Code</span>
                    <MuiTextField
                      size="small"
                      placeholder="Competitor Code"
                      value={values.competitorCode || ""}
                      onChange={handleChange("competitorCode")}
                      type="number"
                      required
                    />
                  </div>
                </div>
                <div className="ItemsInputDetailsProductCompetitor">
                  <div className="ItemInputDetailsProductCompetitor">
                    <span>Competitor Size</span>
                    <MuiTextField
                      size="small"
                      placeholder="Competitor Size"
                      value={values.competitorSize || ""}
                      onChange={handleChange("competitorSize")}
                      type="text"
                      required
                    />
                  </div>
                  <div className="ItemInputDetailsProductCompetitor">
                    <span>Status</span>
                    <FormControl fullWidth size="small">
                      <Select
                        className={classes.selectBorder}
                        value={selectedInput.status || ""}
                        displayEmpty
                        onChange={(e) =>
                          setSelectedInput({
                            ...selectedInput,
                            status: e.target.value,
                          })
                        }
                        required
                      >
                        <MenuItem value="" disabled>
                          <Typography
                            component="span"
                            style={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#A4A4A4",
                            }}
                          >
                            Status
                          </Typography>
                        </MenuItem>
                        {statusArr.sort().map((option, el) => (
                          <MenuItem
                            key={el}
                            onClick={() =>
                              setSelectedInput({
                                ...selectedInput,
                                status: option,
                              })
                            }
                            value={option || ""}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className="ButtonActionDetailsPromo">
                <Button
                  style={{
                    background: "#FFFFF",
                    borderRadius: 5,
                    border: "1px solid #818181",
                    width: 79,
                    height: 34,
                    textTransform: "none",
                    color: "#818181",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  onClick={() => history.go(-1)}
                >
                  Cancel
                </Button>
                {location === "addProductCompetitorFMCG" ? (
                  <Button
                    disabled={disabledButton}
                    style={{
                      backgroundColor:
                        (disabledButton === true && "#B2C5F4") ||
                        (disabledButton === false && "#1571DE"),
                      borderRadius: 5,
                      width: 65,
                      height: 34,
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                    onClick={ handleSubmit }
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    style={{
                      borderRadius: 5,
                      width: 128,
                      height: 34,
                      textTransform: "none",
                      color: "#1571DE",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                    onClick={ handleSubmit }
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </form>
          ) : (
            <form
              className="WrapperFormProductCompetitor"
              onSubmit={handleSubmit}
            >
              <div className="WrapperItemsInputDetailsProductCompetitor">
                <div className="ItemsInputDetailsProductCompetitor">
                  <div className="ItemInputDetailsProductCompetitor">
                    <span>Competitor Name</span>
                    <MuiTextField
                      size="small"
                      placeholder="Competitor Name"
                      value={values.competitorName || ""}
                      onChange={handleChange("competitorName")}
                      type="text"
                      required
                    />
                  </div>
                  <div className="ItemInputDetailsProductCompetitor">
                    <span>Competitor Code</span>
                    <MuiTextField
                      size="small"
                      placeholder="Competitor Code"
                      value={values.competitorCode || ""}
                      onChange={handleChange("competitorCode")}
                      type="number"
                      required
                    />
                  </div>
                </div>
                <div className="ItemsInputDetailsProductCompetitor">
                  <div className="ItemInputDetailsProductCompetitor">
                    <span>Competitor Size</span>
                    <MuiTextField
                      size="small"
                      placeholder="Competitor Size"
                      value={values.competitorSize || ""}
                      onChange={handleChange("competitorSize")}
                      type="text"
                      required
                    />
                  </div>
                  <div className="ItemInputDetailsProductCompetitor">
                    <span>Status</span>
                    <FormControl fullWidth size="small">
                      <Select
                        className={classes.selectBorder}
                        value={selectedInput.status || ""}
                        displayEmpty
                        onChange={(e) =>
                          setSelectedInput({
                            ...selectedInput,
                            status: e.target.value,
                          })
                        }
                        required
                      >
                        <MenuItem value="" disabled>
                          <Typography
                            component="span"
                            style={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#A4A4A4",
                            }}
                          >
                            Status
                          </Typography>
                        </MenuItem>
                        {statusArr.sort().map((option, el) => (
                          <MenuItem
                            key={el}
                            onClick={() =>
                              setSelectedInput({
                                ...selectedInput,
                                status: option,
                              })
                            }
                            value={option || ""}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className="ButtonActionDetailsPromo">
                <Button
                  style={{
                    background: "#FFFFF",
                    borderRadius: 5,
                    border: "1px solid #818181",
                    width: 79,
                    height: 34,
                    textTransform: "none",
                    color: "#818181",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  onClick={() => history.go(-1)}
                >
                  Cancel
                </Button>
                {location === "addProductCompetitorFMCG" ? (
                  <Button
                    disabled={disabledButton}
                    style={{
                      backgroundColor:
                        (disabledButton === true && "#B2C5F4") ||
                        (disabledButton === false && "#1571DE"),
                      borderRadius: 5,
                      width: 65,
                      height: 34,
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                    onClick={ handleSubmit }
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    style={{
                      backgroundColor: "#1571DE",
                      borderRadius: 5,
                      width: 128,
                      height: 34,
                      textTransform: "none",
                      color: "#FFFFFF",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                    onClick={ handleSubmit }
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsProductCompetitor;
