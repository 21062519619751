import axios from "axios";
import {API} from "../../redux/api";

const approvalPawn = (token, ebpId, request) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { response },
      } = await axios.patch(`${API}/ebp/admin/${ebpId}`, request, {
        headers: {
          Authorization: token,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export default approvalPawn;
