import axios from "axios";
import * as types from '../../type'
import { setSnackbar } from "./snackbarActions";
import {API} from "../../api";
// let API = "http://122.50.7.190:5003/api/mws";
// let API ="https://myworkspace.id/api/mws";

export const fetchAllClients = (token) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_ALL_CLIENTS_BEGIN })
    axios
      .get(`${API}/client`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_CLIENTS_SUCCESS,
          payload: res.data.response
        })
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_ALL_CLIENTS_ERROR, payload: err.response })
        console.log(err);
      })
  }
}

export const fetchAllClientNotSet = (token) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_CLIENT_NOT_SET_BEGIN })
    axios
      .get(`${API}/client/notset`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_CLIENT_NOT_SET_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_CLIENT_NOT_SET_ERROR, payload: err.response })
        console.log(err);
      });
  };
};

export const fetchAllClientSet = (token) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_CLIENT_SET_BEGIN })
    axios
      .get(`${API}/client/set`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_CLIENT_SET_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_CLIENT_SET_ERROR })
        console.log(err);
      });
  };
};

// GET CLIENT BY ID
export const getClientById = (token, id) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_CLIENT_BY_ID_BEGIN })
    axios
      .get(`${API}/client/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_CLIENT_BY_ID_SUCCESS,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_CLIENT_BY_ID_ERROR, payload: err.data.message})
        console.log(err);
      });
  };
};

export const setLoanScheme = (token, id, body) => {
  return (dispatch) => {
    axios
      .patch(`${API}/client/staffLoan/${id}`, body, {
        headers: {
          Authorization: token,
        }
      })
      .then((res) => {
        dispatch({
          type: types.SET_LOAN_SCHEME,
          payload: res.data.response,
        })
      })
      .then(() => {
        dispatch(getClientById(token, id))
      })
      .catch((err) => {
        console.log(err);
      })
  }
}
export const setAttendanceScheme = (token, id, body) => {
  return (dispatch) => {
    axios
      .patch(`${API}/client/attendPlan/${id}`, body, {
        headers: {
          Authorization: token,
        }
      })
      .then((res) => {
        dispatch({
          type: types.SET_ATTENDANCE_SCHEME,
          payload: res.data.response,
        })
      })
      .then(() => {
        dispatch(getClientById(token, id))
      })
      .catch((err) => {
        console.log(err);
      })
  }
}


export const addClient = (client, token) => {
  return (dispatch) => {
    axios
      .post(
        `${API}/client`,
        {
          Name: client.Name,
          Address: client.Address,
          PhoneNumber: client.PhoneNumber,
          Group: client.Group,
          // JoinDate: client.JoinDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        dispatch({
          type: types.ADD_CLIENT,
          //   payload: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateClient = (token, id, body) => {
  return (dispatch) => {
    dispatch({ type: types.EDIT_CLIENT_BEGIN })
    axios
      .patch(`${API}/client/${id}`, body, {
        headers: {
          Authorization: token,
        }
      })
      .then((res) => {
        dispatch({
          type: types.EDIT_CLIENT_SUCCESS,
          payload: res.data.response,
        })
      })
      .then(() => {
        dispatch(getClientById(token, id))
      })
      // .then(()=> {
      //   dispatch({ type: types.EDIT_CLIENT_SUCCESS })
      // })
      .catch((err) => {
        dispatch({ type: types.EDIT_CLIENT_ERROR, payload: err.response.data.message })
        console.log(err);
      })

  }
}

// export const updateClient = (client, token) => {
//   return (dispatch) => {
//     axios
//       .patch(
//         `${API}/client/${client.id}`,
//         {
//           ...client,
//           id: undefined,
//           // Group: client.Group ? client.Group : undefined,
//           // Name: client.Name,
//           // Address: client.Address,
//           // PhoneNumber: client.PhoneNumber,
//           // Group: client.Group,
//           // SchemaAttendanceId: client.SchemaAttendanceId,
//           // SchemaAttendance: client.SchemaAttendance,
//           // StatusAttendance: client.StatusAttendance
//           // JoinDate: new Date(client.JoinDate).getTime(),
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//       .then((res) => {
//         dispatch({
//           type: types.EDIT_CLIENT,
//         });
//       })
//       .then(() => {
//         dispatch(getClientById(client.id, token));
//       })
//       .then(() => {
//         dispatch(fetchAllClientNotSet(token));
//       })
//       // .then(() => {
//       //   dispatch(
//       //     setSnackbar({
//       //       snackbarOpen: true,
//       //       snackbarType: "success",
//       //       snackbarMessage: "Data success updated",
//       //     })
//       //   );
//       // })
//       .catch((err) => {
//         console.log(err);
//         // dispatch(
//         //   setSnackbar({
//         //     snackbarOpen: true,
//         //     snackbarType: "error",
//         //     snackbarMessage: err.response.data.message,
//         //   })
//         // );
//       });
//   };
// };

export const updateClientWithSnackbar = (client, token) => {
  return (dispatch) => {
    axios
      .patch(
        `${API}/client/${client.id}`,
        {
          ...client,
          id: undefined,
          // Group: client.Group ? client.Group : undefined,
          // Name: client.Name,
          // Address: client.Address,
          // PhoneNumber: client.PhoneNumber,
          // Group: client.Group,
          // SchemaAttendanceId: client.SchemaAttendanceId,
          // SchemaAttendance: client.SchemaAttendance,
          // StatusAttendance: client.StatusAttendance
          // JoinDate: new Date(client.JoinDate).getTime(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: types.EDIT_CLIENT_SUCCESS,
        });
      })
      .then(() => {
        dispatch(getClientById(client.id, token));
      })
      .then(() => {
        dispatch(fetchAllClientNotSet(token));
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Data success updated",
          })
        );
        return Promise.resolve(); // kondisi jika berhasil
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.message,
          })
        );
        return Promise.reject(err);
      });
  };
};
export const updateSwitchController = (client, token) => {
  return (dispatch) => {
    axios
      .patch(
        `${API}/client/${client.id}`,
        {
          ...client,
          id: undefined,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: types.EDIT_CLIENT_SUCCESS,
        });
      })
      .then(() => {
        dispatch(getClientById(client.id, token));
      })
      .catch((err) => {
        console.log(err);
        // return Promise.reject(err);
      });
  };
};

export const editUser = (client) => {
  return {
    type: types.EDIT_CLIENT_SUCCESS,
    payload: client,
  };
};

export const deleteClient = (token, id) => {
  return (dispatch) => {
    axios
      .delete(`${API}/client/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch({
          type: types.DELETE_CLIENT,
        });
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Client deleted successfully",
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// ------------ Update Set Schema
export const setSchemaAttendances = (name, token, clientName) => {
  return (dispatch) => {
    axios
      .patch(
        `${API}/client/setSchemaAttendance`,
        {
          name: name,
          clientName: clientName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        dispatch({
          type: types.SET_SCHEMA_ATTENDANCES,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//---------- Set Active
export const setActiveClient = (token, clientName) => {
  return (dispatch) => {
    axios
      .patch(
        `${API}/client/active`,
        {
          clientName: clientName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        dispatch({
          type: types.SET_ACTIVE_CLIENT,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
