import React, { useEffect } from "react";
import "../ClientSettings.css";
import {
    Grid,
    Container,
    Button,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { getClientById } from "../../../../redux/actions/SuperAdmin/adminActions";
import { fetchAllSchemaLoan } from "../../../../redux/actions/SuperAdmin/loanActions";
import SetLoan from "../../../../components/SuperAdmin/_client/ClientSettings/SetLoan/SetLoan";

export default function SetLoanSA() {
    const token = localStorage.getItem("accessToken");
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { clientById } = useSelector((state) => state.client);

    const { loanSchema, loadingLoanSchema } = useSelector((state) => state.loan);

    useEffect(() => {
        dispatch(getClientById(token, id));
        dispatch(fetchAllSchemaLoan(token))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const capitalizedClientName = (clientName) => {
        return clientName?.toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
            .replace('Pt', 'PT')
            .replace('Cv', 'CV')
    }

    return (
        <Container maxWidth="xl">
            <div className="container-client-settings">
                <Helmet>
                    <title>MyWorkSpace | Client Settings</title>
                </Helmet>
                <div className="top__items-settings">
                    <Breadcrumb aria-label="breadcrumb">
                        <Button
                            onClick={() => history.push("/superadmin/client")}
                            style={{ border: "none", textTransform: "none", width: '100%' }}
                        >
                            <span style={{ fontSize: 20, color: '#255bfc' }}>Client Settings</span>
                        </Button>
                        <Button
                            onClick={() => history.push("/superadmin/profile-clients/" + id)}
                            style={{ border: "none", textTransform: "none", width: '100%' }}
                        >
                            <span style={{ fontSize: 20, color: '#255bfc' }}>{capitalizedClientName(clientById?.name)}</span>
                        </Button>
                        <span style={{ fontSize: 20, color: '#255bfc' }}>Loan Scheme</span>
                    </Breadcrumb>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <SetLoan loanSchemes={loanSchema} loading={loadingLoanSchema} client={clientById} />
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}
