import * as types from '../../type'

const initialState = {
    allContracts: [],
    contractById: {},
    updatedContract: {},
    loadingDataContracts: false,
    errorMessageContracts: {}
}

const contractReducer = (state=initialState, action) => {
    switch(action.type) {
        case types.FETCH_ALL_CONTRACTS_BEGIN: {
            return {
                ...state,
                loadingDataContracts: true
            }
        }
        case types.FETCH_ALL_CONTRACTS_SUCCESS: {
            return {
                ...state,
                loadingDataContracts: false,
                allContracts: action.payload,
            }
        }
        case types.FETCH_ALL_CONTRACTS_ERROR: {
            return {
                ...state,
                loadingDataContracts: false,
                errorMessageContracts: action.payload
            }
        }
        default:
            return state
    }
}

export default contractReducer;