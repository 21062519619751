import axios from "axios";
import { API } from "../../redux/api";

const fetchLeaveHistory = (token, employeeId, clientCode, year) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(
        `${API}/attendance/leave-history/web?employeeId=${employeeId}&clientCode=${clientCode}&year=${year}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export default fetchLeaveHistory;
