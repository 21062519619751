/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { filter } from "lodash";
import "./AddClientMenu2.css";
import company_logo from "../../../../assets/images/LogoClient.png";
import bm_pict from "../../../../assets/images/bm-pict.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import { Typography, Button, Stack, TablePagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { fetchAllClientNotSet } from "../../../../redux/actions/SuperAdmin/adminActions";
import UserListHead from "../../_user/TableUsers/UserListHead";
import Scrollbar from "../../_user/TableUsers/Scrollbar";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";

const TABLE_HEAD = [
  { id: "Name", label: "Clients Name", alignRight: false },
  { id: "BusinessManagerName", label: "Business Manager", alignRight: false },
  { id: "Regional", label: "Regional", alignRight: false },
  { id: "Group", label: "Group", alignRight: false },
  // { id: "password", label: "Password", alignRight: false },
  // { id: "status", label: "Admin Type", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AddClientMenu2() {
  const location = useLocation();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const { loadingDataClientNotSet } = useSelector((state) => state.client);
  const clientNotSet = useSelector((state) => state.client.clientNotSet);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [listDataClient, setListDataClient] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredClients = applySortFilter(
    clientNotSet,
    getComparator(order, orderBy)
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = clientNotSet.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleFilterByName = (event) => {
    setListDataClient(event.target.value);
  };
  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchQuery(listDataClient);
  };

  useEffect(() => {
    dispatch(fetchAllClientNotSet(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <div className="container-client-menu-2">
      <div className="wrapper-client-menu-2">
        <SearchbarSA
          value={listDataClient}
          onChange={handleFilterByName}
          onSubmit={handleSubmitFilter}
        />
      </div>
      {loadingDataClientNotSet ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 100,
          }}
        >
          <div style={{ width: 200 }}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className="container-table-client-menu-2">
          <div className="wrapper-table-client-menu-2">
            {clientNotSet.length > 0 ? (
              <>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 600 }}>
                    <Table
                      sx={{
                        width: "100%",
                        maxHeight: "100%",
                        backgroundColor: "#fafafa",
                        borderRadius: 4,
                      }}
                    >
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        rowCount={clientNotSet.length}
                        numSelected={selected.length}
                        headLabel={TABLE_HEAD}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />

                      <TableBody>
                        {stableSort(clientNotSet, getComparator(order, orderBy))
                          .filter(
                            (el) =>
                              el.Name.toLowerCase().includes(
                                listDataClient.toLowerCase()
                              ) ||
                              el.Group.toLowerCase().includes(
                                listDataClient.toLowerCase()
                              )
                          )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, ind) => {
                            const { id } = row;
                            const labelId = `enhanced-table-checkbox-${ind}`;

                            let schemaAttendance, loanSchema, workspaceSchema;
                            if (row.SchemaAttendance) {
                              schemaAttendance = true;
                            } else if (!row.SchemaAttendance) {
                              schemaAttendance = false;
                            }
                            if (row.LoanSchema) {
                              loanSchema = true;
                            } else if (!row.LoanSchema) {
                              loanSchema = false;
                            }
                            if (row.WorkspaceSchema) {
                              workspaceSchema = true;
                            } else if (!row.WorkspaceSchema) {
                              workspaceSchema = false;
                            }
                            return (
                              <TableRow
                                key={row._id}
                                hover
                                tabIndex={-1}
                                id={labelId}
                              >
                                <TableCell></TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                >
                                  <Link
                                    to={{
                                      pathname: `/superadmin/profile-clients/${row._id}`,
                                      state: {
                                        id: row._id,
                                        name: row.Name,
                                        address: row.Address,
                                        contact: row.PhoneNumber,
                                        group: row.Group,
                                      },
                                    }}
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                      style={{ width: 300 }}
                                    >
                                      <Avatar
                                        alt={row.Name}
                                        src={company_logo}
                                        variant="square"
                                      />
                                      <Typography variant="subtitle2" noWrap>
                                        {row.Name}
                                      </Typography>
                                    </Stack>
                                  </Link>
                                </TableCell>
                                <TableCell align="left">
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Avatar alt={row.Name} src="" />
                                    <Typography variant="subtitle2" noWrap>
                                      Business Manager Name
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell>
                                  Jakarta, Medan, Bandung, Yogyakarta
                                </TableCell>
                                <TableCell>{row.Group}</TableCell>
                                <TableCell align="right">
                                  <Link
                                    // to="/add-clients-id"
                                    to={{
                                      pathname: `/superadmin/add-clients-id/${row._id}`,
                                      state: {
                                        name: row.Name,
                                        schemaAttendance,
                                        loanSchema,
                                        workspaceSchema,
                                        schema: row.SchemaAttendance,
                                        id: row._id,
                                        schemaId: row.SchemaAttendanceId,
                                      },
                                    }}
                                  >
                                    <Button
                                      style={{
                                        textTransform: "none",
                                        width: 53,
                                        height: 36,
                                        backgroundColor: "#1571de",
                                        color: "white",
                                      }}
                                    >
                                      {" "}
                                      Set
                                    </Button>
                                  </Link>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={clientNotSet.filter(
                    (el) =>
                      el.Name.toLowerCase().includes(
                        listDataClient.toLowerCase()
                      ) ||
                      el.Group.toLowerCase().includes(
                        listDataClient.toLowerCase()
                      )
                  ).length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <div>No clients need to set</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
