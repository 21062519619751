import React, { useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import "./StoreAccountGroup.css";
import { useHistory } from "react-router";
import SearchbarFMCG from "../../SearchbarFMCG/SearchbarFMCG";
import AddIcon from "@mui/icons-material/Add";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import BuildingsImageTable from "../../../../assets/images/Buildings-Image-Table.png";
import EditIcon2 from "../../../../assets/images/new-edit-icon.png";
import TrashIcon from "../../../../assets/images/trash-2.png";
import ModalDeleteFMCG from "../../ModalDeleteFMCG/ModalDeleteFMCG";
import LoadingFMCG from "../../../utils/LoadingFMCG/LoadingFMCG";

const TABLE_HEAD = [
  { id: "StoreName", label: "Store Account Group", alignRight: false },
  { id: "TotalStore", label: "Total Store", alignRight: false },
  { id: "TotalEmployee", label: "TotalEmployee", alignRight: false },
  { id: "DateAdd", label: "Date Added", alignRight: false },
  { id: "Status", label: "Status", alignRight: false },
  { id: "" },
];

const data = [
  {
    StoreName: "Permata ",
    TotalStore: "12",
    TotalEmployee: "122",
    DateAdd: "10 Agustus 2022",
    Status: true,
    _id: "asdkakslahdlkj123123",
  },
  {
    StoreName: "Aeon",
    TotalStore: "12",
    TotalEmployee: "122",
    DateAdd: "10 Agustus 2022",
    Status: true,
    _id: "lkshdo1hdon1owh",
  },
  {
    StoreName: "All Fresh",
    TotalStore: "12",
    TotalEmployee: "122",
    DateAdd: "10 Agustus 2022",
    Status: false,
    _id: "lksh12k31i231238012",
  },
  {
    StoreName: "Diamond",
    TotalStore: "12",
    TotalEmployee: "122",
    DateAdd: "10 Agustus 2022",
    Status: true,
    _id: "okjdalksjk123jp12j3",
  },
  {
    StoreName: "Farmer Market",
    TotalStore: "12",
    TotalEmployee: "122",
    DateAdd: "10 Agustus 2022",
    Status: false,
    _id: "1l2n3j12h301238098",
  },
  {
    StoreName: "Food Hall",
    TotalStore: "12",
    TotalEmployee: "122",
    DateAdd: "10 Agustus 2022",
    Status: false,
    _id: "1l2n3j12h301238098",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& th": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        <TableCell padding="none" style={{ paddingLeft: 0 }}>
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={rowCount !== values?.users?.length}
            sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
          /> */}
        </TableCell>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StoreAccountGroup = () => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState("");
  const [orderBy, setOrderBy] = useState("StoreName");
  // const [selectedPosition, setSelectedPosition] = useState("");
  const [listStoreFMCG, setListStoreFMCG] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setListStoreFMCG(event.target.value);
  };

  return (
    <div className="ContainerStoreAccountGroup">
      <div className="WrapperStoreAccountGroup">
        <div className="HeaderStoreAccountGroup">
          <div className="SearchbarAccountGroup">
            <SearchbarFMCG
              value={listStoreFMCG}
              onChange={handleSearch}
              searchWidth="379px"
              placeholder="Search store group"
            />
          </div>
          <div className="ButtonUploadAction">
            <Button
              style={{
                textTransform: "none",
                width: 129,
                height: 40,
                background: "#FFFFFF",
                border: "1px solid #D3C9C9",
                borderRadius: 8,
                fontWeight: 500,
                fontSize: 13,
                color: "#1571DE",
              }}
              startIcon={
                <i className="ri-upload-line" style={{ fontSize: 20 }} />
              }
            >
              Upload Excel
            </Button>
            <Button
              style={{
                textTransform: "none",
                width: 148,
                height: 40,
                background: "#FFFFFF",
                border: "1px solid #D3C9C9",
                borderRadius: 8,
                fontWeight: 500,
                fontSize: 13,
                color: "#1571DE",
              }}
              startIcon={
                <i className="ri-download-line" style={{ fontSize: 20 }} />
              }
            >
              Download Excel
            </Button>
          </div>
          <div className="ButtonAddAccountGroup">
            <Button
              startIcon={<AddIcon />}
              style={{
                textTransform: "none",
                backgroundColor: "#1571DE",
                width: 131,
                height: 40,
                color: "#FFFFFF",
              }}
              onClick={() =>
                history.push({
                  pathname: `/admin-fmcg/setup/store/add-account-group`,
                  state: { location: "addStoreAccountGroup" },
                })
              }
            >
              Store Group
            </Button>
          </div>
        </div>
        {loading ? (
          <div className="ContainerAttendanceFMCGLoading">
            <LoadingFMCG width={800} height={200} />
          </div>
        ) : (
          <div className="ContainerTableStoreAccountGroup">
            <Card>
              <Scrollbar>
                <TableContainer>
                  <Table
                    sx={{
                      width: "100%",
                      maxHeight: "100%",
                    }}
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: 5,
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      // onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      // values={data}
                      rowCount={
                        data?.filter(
                          (el) =>
                            el.StoreName?.toLowerCase().includes(
                              listStoreFMCG.toLowerCase()
                            ) ||
                            el.TotalStore?.toLowerCase().includes(
                              listStoreFMCG.toLowerCase()
                            ) ||
                            el.TotalEmployee?.toLowerCase().includes(
                              listStoreFMCG.toLowerCase()
                            ) ||
                            el.DateAdd?.toLowerCase().includes(
                              listStoreFMCG.toLowerCase()
                            )
                          // el.Status?.toLowerCase().includes(
                          //   listStoreFMCG.toLowerCase()
                          // )
                        ).length
                      }
                    />
                    <TableBody>
                      {stableSort(data, getComparator(order, orderBy))
                        .filter(
                          (el) =>
                            el.StoreName?.toLowerCase().includes(
                              listStoreFMCG.toLowerCase()
                            ) ||
                            el.TotalStore?.toLowerCase().includes(
                              listStoreFMCG.toLowerCase()
                            ) ||
                            el.TotalEmployee?.toLowerCase().includes(
                              listStoreFMCG.toLowerCase()
                            ) ||
                            el.DateAdd?.toLowerCase().includes(
                              listStoreFMCG.toLowerCase()
                            )
                          // el.Status?.toLowerCase().includes(
                          //   listStoreFMCG.toLowerCase()
                          // )
                          // &&(selectedPosition
                          // ? el.Position === selectedPosition
                          // : true)
                        )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((store, ind) => {
                          // const isItemSelected = isSelected(employee?._id);
                          const labelId = `enhanced-table-checkbox-${ind}`;
                          return (
                            <TableRow
                              hover
                              key={ind}
                              tabIndex={-1}
                              id={labelId}
                              sx={{
                                "&.MuiTableRow-root:hover": {
                                  backgroundColor: "#eef6ff",
                                },
                              }}
                            >
                              <TableCell
                                padding="none"
                                style={{ paddingLeft: 10, marginRight: 10 }}
                              ></TableCell>
                              <TableCell component="th" scope="row">
                                {/* <Link
                                to={{
                                  pathname: `/admin-fmcg/details-spg-store/lkshdo1hdon1owh`,
                                  state: { location: "detailsSpgStore" },
                                }}
                              > */}
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Avatar
                                    src={BuildingsImageTable}
                                    variant="square"
                                    style={{ width: 30, height: 30 }}
                                  />
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{ textTransform: "capitalize" }}
                                  >
                                    {store.StoreName
                                      ? store.StoreName.toLowerCase()
                                      : "-"}
                                  </Typography>
                                </Stack>
                                {/* </Link> */}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {store.TotalStore ? store.TotalStore : "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {store.TotalEmployee
                                  ? store.TotalEmployee
                                  : "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {store.DateAdd ? store.DateAdd : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {store.Status === true ? (
                                  <Typography
                                    style={{
                                      backgroundColor: "#E4FFE6",
                                      color: "#17B51E",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 8,
                                      height: 26,
                                      width: 58,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#17B51E",
                                      }}
                                    >
                                      Active
                                    </Typography>
                                  </Typography>
                                ) : (
                                  <Typography
                                    style={{
                                      backgroundColor: "#FFDFDD",
                                      // color: "#17B51E",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 8,
                                      height: 26,
                                      width: 74,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#B52117",
                                      }}
                                    >
                                      Unactive
                                    </Typography>
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell align="left" variant="square">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography
                                    style={{
                                      width: 30,
                                      height: 30,
                                      backgroundColor: "#ECF5FF",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 5,
                                    }}
                                    component="div"
                                  >
                                    <img
                                      src={EditIcon2}
                                      alt=""
                                      style={{
                                        width: 20,
                                        height: 20,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        history.push({
                                          pathname: `/admin-fmcg/setup/store/edit-account-group/asdkakslahdlkj123123`,
                                          state: {
                                            location: "editStoreAccountGroup",
                                          },
                                        })
                                      }
                                    />
                                  </Typography>
                                  <Typography
                                    style={{
                                      width: 30,
                                      height: 30,
                                      backgroundColor: "#ECF5FF",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 5,
                                    }}
                                    component="div"
                                  >
                                    <img
                                      src={TrashIcon}
                                      alt=""
                                      style={{
                                        width: 20,
                                        height: 20,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => setOpenModalDelete(true)}
                                    />
                                  </Typography>
                                </Stack>

                                {/* <Typography
                                component="div"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: 30,
                                  height: 30,
                                  backgroundColor: '#ECF5FF'
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    history.push({
                                      pathname: `/admin-fmcg/details-spg-store/lkshdo1hdon1owh`,
                                      state: { location: "editSpgStore" },
                                    });
                                  }}
                                >
                                  <EditIcon />
                                </Button>
                              </Typography> */}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={
                  data?.filter(
                    (el) =>
                      el.StoreName?.toLowerCase().includes(
                        listStoreFMCG.toLowerCase()
                      ) ||
                      el.TotalStore?.toLowerCase().includes(
                        listStoreFMCG.toLowerCase()
                      ) ||
                      el.TotalEmployee?.toLowerCase().includes(
                        listStoreFMCG.toLowerCase()
                      ) ||
                      el.DateAdd?.toLowerCase().includes(
                        listStoreFMCG.toLowerCase()
                      )
                    // el.Status?.toLowerCase().includes(
                    //   listStoreFMCG.toLowerCase()
                    // )
                    // &&(selectedPosition ? el.Position === selectedPosition : true)
                  ).length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </div>
        )}
      </div>
      {openModalDelete && (
        <ModalDeleteFMCG
          modalDelete={openModalDelete}
          setModalDelete={setOpenModalDelete}
        />
      )}
    </div>
  );
};

export default StoreAccountGroup;
