/* eslint-disable no-unused-vars */

import {
  Alert,
  Autocomplete,
  Button,
  FormControl,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import EDIT_ICON from "../../../../../assets/images/create.png";
import fetchSupervisor from "../../../../../async/supervisor/fetchSupervisor";
import { updateSPVorAPV } from "../../../../../redux/actions/SuperAdmin/employeesActions";
import Styles from "./ModalEditSupervisor.module.css";
import { Style } from "@mui/icons-material";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      border: "1px solid #d9d9d9",
      color: "#0a0a0a",
    },
  },
});

const useStyles = makeStyles({
  select: {
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
  },
  icon: {
    fill: "white",
  },
  root: {
    color: "white",
  },
});

export default function ModalEditSupervisor({
  changeSupervisor,
  setChangeSupervisor,
  employeeId,
  clientCode,
  spvToChange,
}) {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const [isInternal, setIsInternal] = useState("internal");
  const [isPhoneNumber, setIsPhoneNumber] = useState("phoneNumber");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [alert, setAlert] = useState(null);
  const [dataSPV, setDataSPV] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchSupervisor(token, clientCode);
        const { response: responseBackup } = await fetchSupervisor(
          token,
          "PIS"
        );

        if (response.length > 0) {
          setDataSPV(response);
        } else {
          setDataSPV(responseBackup);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [clientCode, token]);

  // const {employees} = useSelector(state => state.employees)
  const [formInternal, setFormInternal] = useState({
    supervisorId: "",
  });

  const [formExternal, setFormExternal] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  });

  const spvNames = useMemo(() => {
    return dataSPV.map((el) => el?.name);
  }, [dataSPV]);

  const [spvName, setSpvName] = useState("");

  const spvCodes = useMemo(() => {
    return dataSPV.map((el) => el?._id);
  }, [dataSPV]);

  const [spvCode, setSpvCode] = useState("");

  const handleChangeSpv = (newValue) => {
    setSpvName(newValue);
  };

  let formToDispatch = isInternal === "internal" ? formInternal : formExternal;
  const handleSubmit = (e) => {
    const dataPhoneNumber = {
      name: formExternal?.name,
      phoneNumber: formExternal?.phoneNumber,
    }
    const dataEmail = {
      name: formExternal?.name,
      email: formExternal?.email
    }
    e.preventDefault();
    if (formToDispatch === formExternal) {
      if(isPhoneNumber === 'phoneNumber') {
        if (
          dataPhoneNumber?.phoneNumber >= 11 &&
          dataPhoneNumber?.phoneNumber.startsWith("62")
        ) {
          dispatch(
            updateSPVorAPV(
              token,
              employeeId,
              dataPhoneNumber,
              `clientCode=${clientCode}`
            )
          );
          setChangeSupervisor(false);
        } else {
          setAlert("Nomor telepon yang anda masukan salah");
        }
      } else {
      if (dataEmail?.email?.includes("@")) {
        dispatch(
          updateSPVorAPV(
            token,
            employeeId,
            dataEmail,
            `clientCode=${clientCode}`
          )
        );
        setChangeSupervisor(false);
      } else {
        setAlert("Email yang anda masukan salah");
      }
      }
    } else {
      dispatch(
        updateSPVorAPV(
          token,
          employeeId,
          formToDispatch,
          `clientCode=${clientCode}`
        )
      );
      setChangeSupervisor(false);
    }
  };

  const handleSubmit2 =  (e) => {
    const dataPhoneNumber = {
      name: formExternal?.name,
      phoneNumber: formExternal?.phoneNumber,
    }
    const dataEmail = {
      name: formExternal?.name,
      email: formExternal?.email
    }
    if(isInternal === "external") {
      if (formExternal?.phoneNumber >= 11 &&formExternal?.phoneNumber.startsWith("62")) {
        dispatch(
          updateSPVorAPV(
            token,
            employeeId,
            dataPhoneNumber,
            `clientCode=${clientCode}`
          )
        );
        setChangeSupervisor(false);
      } else {
        setAlert("Invalid Phone Number");
      } 

      if (formExternal?.email?.includes("@")) {
        dispatch(
          updateSPVorAPV(
            token,
            employeeId,
            dataEmail,
            `clientCode=${clientCode}`
          )
        );
        setChangeSupervisor(false);
      } else {
        setAlert("Please fill with correct email");
      }

    } else {
      dispatch(
        updateSPVorAPV(
          token,
          employeeId,
          formInternal,
          `clientCode=${clientCode}`
        )
      );
      setChangeSupervisor(false);
    }
  }

  const handleChangeFormExternal = (e) => {
    setFormExternal((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    let selectedSpv = dataSPV.find((el) => el?.name === spvName);
    setSpvCode(selectedSpv?.employeeId);

    if (isInternal === "internal") {
      setFormInternal((prev) => ({
        ...prev,
        supervisorId: spvCode,
      }));
    }
  }, [dataSPV, isInternal, spvName, spvCode]);

  return (
    <div className={Styles.Background}>
      <div className={Styles.Container}>
        <div className={Styles.CardHeader}>
          <img src={EDIT_ICON} alt="edit" />
          <span>Edit Supervisor</span>
        </div>
        {alert && (
          <Alert sx={{ marginBottom: "20px" }} severity="error">
            {alert}
          </Alert>
        )}
        <div className={Styles.FormContainer}>
          <form className={Styles.FormControl} onSubmit={handleSubmit}>
            <FormControl className={Styles.BoxFormWrapper} fullWidth>
              <label htmlFor="isInternal">External or Internal</label>
              <Select
                id="isInternal"
                value={isInternal}
                onChange={(e) => setIsInternal(e.target.value)}
                className={Styles.Select}
                sx={{
                  color: "#0a0a0a",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#d9d9d9",
                  },
                }}
              >
                <MenuItem value={"internal"}>Internal</MenuItem>
                <MenuItem value={"external"}>External</MenuItem>
              </Select>
            </FormControl>
            <div className={Styles.BoxFormWrapper}>
              <label htmlFor="supervisor">Supervisor</label>
              {isInternal === "internal" ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={spvName}
                  options={spvNames}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option + Math.random()}>
                        {option}
                      </li>
                    );
                  }}
                  onChange={(event, newValue) => handleChangeSpv(newValue)}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      size="small"
                      sx={{ width: "100%", background: "var(--main-bg)" }}
                    />
                  )}
                />
              ) : (
                <input
                  type="text"
                  name="name"
                  value={formExternal?.name}
                  onChange={handleChangeFormExternal}
                  required={isInternal === "external"}
                />
              )}
            </div>
            {isInternal === "external" && (
              <div className={Styles.BoxFormWrapper}>
                <span>Kontak</span>
                <FormControl className={Styles.BoxFormWrapper} fullWidth>
                  <Select
                    value={isPhoneNumber}
                    onChange={(e) => setIsPhoneNumber(e.target.value)}
                    className={Styles.Select}
                    sx={{
                      color: "#0a0a0a",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#d9d9d9",
                      },
                    }}
                  >
                    <MenuItem value={"phoneNumber"}>Phone Number</MenuItem>
                    <MenuItem value={"email"}>Email</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}

            {isInternal === "external" && isPhoneNumber === "phoneNumber" && (
              <div className={Styles.BoxFormWrapper}>
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formExternal?.phoneNumber}
                  onChange={handleChangeFormExternal}
                  type="number"
                  placeholder="628..."
                  required={isInternal === "external"}
                />
              </div>
            )}
            {isInternal === "external" && isPhoneNumber === "email" && (
              <div className={Styles.BoxFormWrapper}>
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  name="email"
                  value={formExternal?.email}
                  onChange={handleChangeFormExternal}
                  type="text"
                  placeholder="permata@....."
                  required={isInternal === "external"}
                />
              </div>
            )}
            <div className={Styles.ButtonsWrapper}>
              <Button type="submit" variant="contained">
                Save
              </Button>
              <Button
                variant="outlined"
                onClick={() => setChangeSupervisor(false)}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
