/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import "./LoanAddSchemeMenu.css";
import { Link } from "react-router-dom";
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createSchemaLoan,
  updateSchemaLoan,
} from "../../../../redux/actions/SuperAdmin/loanActions";
import InputText from "../../../utils/Input/Input";
import LoanTenorForm from "../LoanTenorForm/LoanTenorForm";
import { useHistory, useParams } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import LoanTenorForms from "../LoanTenorForm/LoanTenorForms";
import Styles from "./LoanAddScheme.module.css";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import { styled } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModalLoanSASuccess from "../../_client/ModalLoanSASuccess/ModalLoanSASuccess";
import { fetchSchemaLoanById } from "../../../../redux/actions/SuperAdmin/loanActions";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import Stack from "@mui/material/Stack";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    "& fieldset": {
      borderRadius: 8,
      border: "1px solid #d9d9d9",
    },
    "&:hover fieldset": {
      borderColor: "#d9d9d9",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #d9d9d9",
    },
    "& .MuiInputBase-input-MuiOutlinedInput-input": {
      padding: 0,
      background: "#ffffff",
    },
    "& input::placeholder": {
      color: "#A4A4A4",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "18.2px",
      opacity: 1,
    },
  },
});

const contract = ["PKWT", "PKWTT", "PKM"];

export default function LoanAddSchemeMenu() {
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const params = useParams();
  const [valueContract, setValueContract] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [morningTimeIn, setMorningTimeIn] = useState(new Date());
  const { loanSchemaById } = useSelector((state) => state.loan);

  const [dataLoan, setDataLoan] = useState({
    schemeName: "",
    description: "",
    TanggalGajian: 0,
  });

  const [dataSchemaLoan, setDataSchemaLoan] = useState({
    name: "",
    remark: "",
    activeLoanDate: {
      daysBeforePayroll: "",
      daysAfterPayroll: "",
    },
    minAge: "",
    maxAge: "",
    // permittedContractType: [],
  });

  const [loanRules, setLoanRules] = useState([
    {
      tenor: 0,
      minPlafond: "",
      maxPlafond: "",
      rate: "",
      dbr: "",
      adminFee: {
        baseLine: "",
        additional: {
          multiplyFactor: "",
          factorCost: "",
          percentage: "",
        },
      },
      minLengthOfJobInMonth: "",
      minContractRemainInMonth: "",
      permittedContractType: [],
    },
  ]);

  useEffect(() => {
    if (params.id) {
      dispatch(fetchSchemaLoanById(token, params.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params?.id) {
      setLoanRules(loanSchemaById?.loanRules);
      setDataSchemaLoan(loanSchemaById);
      // setValueContract(loanSchemaById?.permittedContractType);
    }
  }, [params?.id, loanSchemaById]);

  // const letRuleDelete = useMemo(() => {
  //   if(loanRules.length > 1) {
  //     return true
  //   }

  //   return false
  // },[loanRules])

  // const handleAddTenor = (index) => {
  //   setDataTenor((prev) => ({
  //     ...prev,
  //     Tenor: [
  //       ...prev.Tenor,
  //       {
  //         idTenor: index + 1,
  //         TenorMonth: "",
  //         LoanMax: 0,
  //         TanggalGajian: prev.Tenor[0].TanggalGajian,
  //         RangeDate: [
  //           {
  //             idRangeDate: 1,
  //             From: 0,
  //             To: 0,
  //             AdminFee: "",
  //             TransferFee: 0,
  //           },
  //         ],
  //       },
  //     ],
  //   }));
  // };
  // const handleDeleteTenor = (id) => {
  //   setDataTenor((prev) => ({
  //     ...prev,
  //     Tenor: prev.Tenor.filter((el) => el.idTenor !== id),
  //   }));
  // };
  // const handleAddRangeDate = (idTenor, index) => {
  //   setDataTenor((prev) => ({
  //     ...prev,
  //     Tenor: prev.Tenor.map((el) =>
  //       el.idTenor === idTenor
  //         ? {
  //             ...el,
  //             RangeDate: [
  //               ...el.RangeDate,
  //               {
  //                 idRangeDate: prev.Tenor[index].RangeDate.length + 1,
  //                 idRangeDate: el.RangeDate.length + 1,
  //                 From: 0,
  //                 To: 0,
  //                 AdminFee: "",
  //                 TransferFee: 0,
  //               },
  //             ],
  //           }
  //         : el
  //     ),
  //   }));
  // };
  // const handleDeleteRangeDate = (id, idRangeDate) => {
  //   setDataTenor((prev) => ({
  //     ...prev,
  //     Tenor: prev.Tenor.map((el) =>
  //       el.idTenor === id
  //         ? {
  //             ...el,
  //             RangeDate: el.RangeDate.filter(
  //               (i) => i.idRangeDate !== idRangeDate
  //             ),
  //           }
  //         : el
  //     ),
  //   }));
  // };

  const handleAddRule = () => {
    setLoanRules([
      ...loanRules,
      {
        // id: loanRules.length + 1,
        tenor: "",
        minPlafond: "",
        maxPlafond: "",
        rate: "",
        dbr: "",
        adminFee: {
          baseLine: "",
          additional: { multiplyFactor: "", factorCost: "", percentage: "" },
        },
        permittedContractType: [],
      },
    ]);
  };

  const handleDeleteRule = (ruleIndex) => {
    setLoanRules((prev) => prev?.filter((el, idx) => idx !== ruleIndex));
  };

  const onChangeTenorMonth = (e, key) => {
    e.preventDefault();
    setLoanRules((prev) =>
      prev.map((each, idx) =>
        key === idx
          ? {
              ...each,
              tenor: +e.target.value,
            }
          : each
      )
    );
  };

  const onChangeLoanAmount = (e, key, label) => {
    e.preventDefault();
    let dataLoanAmount = e.target.value.split(",").join("");
    setLoanRules((prev) =>
      prev.map((each, idx) =>
        key === idx
          ? {
              ...each,
              [label]: +dataLoanAmount,
            }
          : each
      )
    );
  };

  const onChangeRateAndDBR = (e, key, label) => {
    e.preventDefault();
    setLoanRules((prev) =>
      prev.map((each, idx) =>
        key === idx
          ? {
              ...each,
              [label]: +e.target.value,
            }
          : each
      )
    );
  };

  const onChangeMinMaxContract = (e, key, label) => {
    e.preventDefault();
    setLoanRules((prev) =>
      prev.map((each, idx) =>
        key === idx
          ? {
              ...each,
              [label]: +e.target.value,
            }
          : each
      )
    );
  };

  const onChangeBaselineFee = (e, key) => {
    e.preventDefault();
    let dataBaseLine = e.target.value.split(",").join("");
    setLoanRules((prev) =>
      prev.map((each, idx) =>
        key === idx
          ? {
              ...each,
              adminFee: {
                ...each.adminFee,
                baseLine: +dataBaseLine,
              },
            }
          : each
      )
    );
  };

  const onChangeAdditional = (e, key, label) => {
    e.preventDefault();
    let dataAdditional = e.target.value.split(",").join("");
    setLoanRules((prev) =>
      prev.map((each, idx) =>
        key === idx
          ? {
              ...each,
              adminFee: {
                ...each.adminFee,
                additional: {
                  ...each.adminFee.additional,
                  [label]: +dataAdditional,
                },
              },
            }
          : each
      )
    );
  };

  const onChangePermittedContractType = (e, key, val) => {
    e.preventDefault();
    setLoanRules((prev) =>
      prev.map((each, idx) =>
        key === idx
          ? {
              ...each,
              permittedContractType: val,
            }
          : each
      )
    );
  };

  // const onChangeDayPayroll = (e, key, label) => {
  //   e.preventDefault();
  //   setLoanRules((prev) => (
  //     prev.map((each, idx) => key === idx ? (
  //       {
  //       ...each,
  //       [ label ] : +e.target.value
  //       }
  //     ) :
  //     each
  //      )
  //   ))
  // }

  const handleSubmit =  (e) => {
    e.preventDefault();
    if (params.id) {
      const data = {
        name: dataSchemaLoan?.name,
        remark: dataSchemaLoan?.remark,
        loanRules: loanRules,
        // activeLoanDate: dataSchemaLoan?.activeLoanDate,
        daysBeforePayroll: dataSchemaLoan?.activeLoanDate?.daysBeforePayroll,
        daysAfterPayroll: dataSchemaLoan?.activeLoanDate?.daysAfterPayroll,
        minAge: dataSchemaLoan?.minAge,
        maxAge: dataSchemaLoan?.maxAge,
        // permittedContractType: valueContract,
      };
      setIsModalOpen(true);
      dispatch(updateSchemaLoan(token, params.id, data));
    } else {
      const data = {
        name: dataSchemaLoan?.name,
        remark: dataSchemaLoan?.remark,
        loanRules: loanRules,
        activeLoanDate: dataSchemaLoan?.activeLoanDate,
        minAge: dataSchemaLoan?.minAge,
        maxAge: dataSchemaLoan?.maxAge,
        // permittedContractType: valueContract,
      };
      setIsModalOpen(true);
      dispatch(createSchemaLoan(token, data));
    }
  };

  return (
    <div className={Styles.Container}>
      <form onSubmit={handleSubmit}>
        <div className={Styles.FormControl}>
          <div className={Styles.LabelControl}>
            <span>Detail Schema</span>
          </div>
          <div className={Styles.Value}>
            <div className={Styles.ValueControl}>
              <label>Scheme Name</label>
              <input
                value={dataSchemaLoan?.name}
                onChange={(e) =>
                  setDataSchemaLoan((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
                type="text"
                placeholder="Scheme Name"
                required
              />
            </div>
          </div>
          <div className={Styles.Value}>
            <div className={Styles.ValueControl}>
              <label>Description</label>
              <textarea
                value={dataSchemaLoan?.remark}
                onChange={(e) =>
                  setDataSchemaLoan((prev) => ({
                    ...prev,
                    remark: e.target.value,
                  }))
                }
                type="text"
                placeholder="Description"
                required
              />
            </div>
          </div>
        </div>
        <div className={Styles.LineBreak}>
          <hr />
        </div>

        {loanRules?.map((each, idx) => (
          <div className={Styles.FormControl} key={idx}>
            <div
              className={`${Styles.LabelControl} ${
                idx !== 0 && Styles.LabelControlHidden
              }`}
            >
              <span>Loan Rules</span>
            </div>
            <div className={Styles.RulesNameLabelControl}>
              {loanRules?.length === 1 ? (
                <span>Rule 1</span>
              ) : (
                <span>Rule {idx + 1}</span>
              )}

              {idx !== 0 ? (
                <img
                  src={DELETE_ICON}
                  alt="delete-icon"
                  onClick={() => handleDeleteRule(idx)}
                />
              ) : null}
            </div>

            <div className={Styles.Value}>
              <div className={Styles.ValueControl}>
                <label>Tenor</label>
                <select
                  placeholder="Select Tenor"
                  required
                  value={each?.tenor || ""}
                  onChange={(e) => onChangeTenorMonth(e, idx)}
                >
                  <option disabled value="">
                    Select Tenor
                  </option>
                  <option value="1">1 Month</option>
                  <option value="3">3 Month</option>
                  <option value="6">6 Month</option>
                  <option value="12">12 Month</option>
                  <option value="24">24 Month</option>
                </select>
              </div>
            </div>
            <div className={Styles.Value}>
              <div className={Styles.ValueControl}>
                <label>Maximum Loan Amount</label>
                <div className={Styles.InputContainer}>
                  <div className={Styles.InputAdornment}>
                    <span>Rp</span>
                  </div>
                  <input
                    required
                    type="text"
                    placeholder="0"
                    value={each?.maxPlafond?.toLocaleString()}
                    onChange={(e) => onChangeLoanAmount(e, idx, "maxPlafond")}
                  />
                </div>
              </div>
            </div>
            <div className={Styles.Value}>
              <div className={Styles.ValueControl}>
                <label>Minimum Loan Amount</label>
                <div className={Styles.InputContainer}>
                  <div className={Styles.InputAdornment}>
                    <span>Rp</span>
                  </div>
                  <input
                    required
                    type="text"
                    placeholder="0"
                    value={each?.minPlafond?.toLocaleString()}
                    onChange={(e) => onChangeLoanAmount(e, idx, "minPlafond")}
                  />
                </div>
              </div>
            </div>
            <div className={Styles.Value}>
              <div className={Styles.ValueControl}>
                <label>Rate Interest</label>
                <div className={Styles.InputContainer}>
                  <input
                    required
                    type="number"
                    placeholder="0"
                    value={each?.rate}
                    onChange={(e) => onChangeRateAndDBR(e, idx, "rate")}
                  />
                  <div className={Styles.InputAdornment}>
                    <span>%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.Value}>
              <div className={Styles.ValueControl}>
                <label>DBR (Debt Burden Ratio)</label>
                <div className={Styles.InputContainer}>
                  <input
                    required
                    type="number"
                    placeholder="0"
                    value={each?.dbr}
                    onChange={(e) => onChangeRateAndDBR(e, idx, "dbr")}
                  />
                  <div className={Styles.InputAdornment}>
                    <span>%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.Value}>
              <div className={Styles.ValueControl}>
                <label>Minimum expire before contract end</label>
                <input
                  required
                  type="number"
                  placeholder="0"
                  value={each?.minContractRemainInMonth || ""}
                  onChange={(e) =>
                    onChangeMinMaxContract(e, idx, "minContractRemainInMonth")
                  }
                />
              </div>
            </div>
            <div className={Styles.Value}>
              <div className={Styles.ValueControl}>
                <label>Minimum loan active after contract</label>
                <input
                  required
                  type="number"
                  placeholder="0"
                  value={each?.minLengthOfJobInMonth || ""}
                  onChange={(e) =>
                    onChangeMinMaxContract(e, idx, "minLengthOfJobInMonth")
                  }
                />
              </div>
            </div>
            <div className={Styles.ValueAdminFee}>
              <div className={Styles.ValueControlAdminFee}>
                <label>Admin Fee</label>
                <div className={Styles.InputAdminFee}>
                  <label>Baseline</label>
                  <div className={Styles.InputContainer}>
                    <div className={Styles.InputAdornment}>
                      <span>Rp</span>
                    </div>
                    <input
                      required
                      type="text"
                      placeholder="0"
                      value={each?.adminFee?.baseLine.toLocaleString()}
                      onChange={(e) => onChangeBaselineFee(e, idx)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.ValueAdminFee}>
              <div className={Styles.ValueControlAdminFee}>
                <label></label>
                <div className={Styles.InputAdminFee}>
                  <label>Multiplier Factor</label>
                  <div className={Styles.WrapperInputFee}>
                    <div className={Styles.InputContainer}>
                      <div className={Styles.InputAdornment}>
                        <span>Rp</span>
                      </div>
                      <input
                        required
                        type="text"
                        placeholder="0"
                        value={each?.adminFee?.additional?.multiplyFactor.toLocaleString()}
                        onChange={(e) =>
                          onChangeAdditional(e, idx, "multiplyFactor")
                        }
                      />
                    </div>
                    <div className={Styles.InputContainer}>
                      <div className={Styles.InputAdornment}>
                        <span>Rp</span>
                      </div>
                      <input
                        required
                        type="text"
                        placeholder="0"
                        value={each?.adminFee?.additional?.factorCost.toLocaleString()}
                        onChange={(e) =>
                          onChangeAdditional(e, idx, "factorCost")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.ValueAdminFee}>
              <div className={Styles.ValueControlAdminFee}>
                <label></label>
                <div className={Styles.InputAdminFee}>
                  <label></label>
                  <div className={Styles.WrapperInputFee}>
                    <div className={Styles.TextAbove}>
                      <span>*Amount Multiplier</span>
                    </div>
                    <div className={Styles.TextAbove}>
                      <span>*Admin fee amount</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.ValueAdminFee}>
              <div className={Styles.ValueControlAdminFee}>
                <label></label>
                <div className={Styles.InputAdminFee}>
                  <label>Percentage</label>
                  <div className={Styles.WrapperInputFee}>
                    <div className={Styles.InputContainer}>
                      <input
                        required
                        type="number"
                        placeholder="0"
                        value={each?.adminFee?.additional?.percentage}
                        onChange={(e) =>
                          onChangeAdditional(e, idx, "percentage")
                        }
                      />
                      <div className={Styles.InputAdornment}>
                        <span>%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={Styles.Value}>
              <div className={Styles.ValueControl}>
                <label>Permitted Contracts</label>
                <Autocomplete
                  required
                  fullWidth
                  multiple
                  id="multiple-limit-tags"
                  options={contract}
                  value={each?.permittedContractType || []}
                  inputValue={inputValue}
                  onInputChange={(e, val) => setInputValue(val)}
                  onChange={(e, val) =>
                    val === null ? onChangePermittedContractType(e, idx , "") : onChangePermittedContractType(e, idx , val)
                  }
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      size="small"
                      placeholder="Contract"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        ))}

        <div className={Styles.ButtonAddNewRules} onClick={handleAddRule}>
          <Button startIcon={<AddCircleIcon sx={{ width: 24, height: 24 }} />}>
            Loan Rule
          </Button>
        </div>
        <div className={Styles.LineBreak}>
          <hr />
        </div>

        <div className={Styles.FormControl}>
          <div className={Styles.LabelControl}>
            <span>Other Information</span>
          </div>
          <div className={Styles.Value}>
            <div className={Styles.ValueControl}>
              <label>Day before payroll</label>
              <input
                type="text"
                placeholder="0"
                required
                value={dataSchemaLoan?.activeLoanDate?.daysBeforePayroll}
                onChange={(e) =>
                  setDataSchemaLoan((prev) => ({
                    ...prev,
                    activeLoanDate: {
                      ...prev.activeLoanDate,
                      daysBeforePayroll: +e.target.value,
                    },
                  }))
                }
              />
            </div>
          </div>
          <div className={Styles.Value}>
            <div className={Styles.ValueControl}>
              <label>Day after payroll</label>
              <input
                type="text"
                placeholder="0"
                required
                value={dataSchemaLoan?.activeLoanDate?.daysAfterPayroll}
                onChange={(e) =>
                  setDataSchemaLoan((prev) => ({
                    ...prev,
                    activeLoanDate: {
                      ...prev.activeLoanDate,
                      daysAfterPayroll: +e.target.value,
                    },
                  }))
                }
              />
            </div>
          </div>
          <div className={Styles.Value}>
            <div className={Styles.ValueControl}>
              <label>Maximum age</label>
              <input
                type="text"
                placeholder="0"
                required
                value={dataSchemaLoan?.maxAge}
                onChange={(e) =>
                  setDataSchemaLoan((prev) => ({
                    ...prev,
                    maxAge: +e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className={Styles.Value}>
            <div className={Styles.ValueControl}>
              <label>Minimum Age</label>
              <input
                type="number"
                placeholder="0"
                required
                value={dataSchemaLoan?.minAge}
                onChange={(e) =>
                  setDataSchemaLoan((prev) => ({
                    ...prev,
                    minAge: +e.target.value,
                  }))
                }
              />
            </div>
          </div>
          {/* <div className={Styles.Value}>
            <div className={Styles.ValueControl}>
              <label>Permitted Contracts</label>
              <Autocomplete
              required
                fullWidth
                multiple
                id="multiple-limit-tags"
                options={contract}
                value={valueContract || []}
                inputValue={inputValue}
                onInputChange={(e, val) => setInputValue(val)}
                onChange={(e, val) =>
                  val === null ? setValueContract("") : setValueContract(val)
                }
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    size="small"
                    placeholder="Contract"
                  />
                )}
              />
            </div>
          </div> */}
          <div className={Styles.ButtonSave}>
            <Button type="submit">Save</Button>
            <Button onClick={() => history.push("/superadmin/loan")}>
              Cancel
            </Button>
          </div>
        </div>

        {/* <div className="wrapper-loan-add-scheme">
          <div className="row-item-loan-add-scheme">
            <span>Scheme Name</span>
            <InputText
              type="text"
              name="schemeName"
              value={dataLoan.schemeName || ""}
              onChange={(e) =>
                setDataLoan({ ...dataLoan, schemeName: e.target.value })
              }
              style={{ paddingLeft: 10 }}
              required
            />
          </div>
          <div className="row-item-loan-add-scheme-desc">
            <span>Description</span>
            <InputText
              name="description"
              type="text"
              value={dataLoan.description || ""}
              onChange={(e) =>
                setDataLoan({ ...dataLoan, description: e.target.value })
              }
              style={{ paddingLeft: 10, maxWidth: 700 }}
              required
            />
          </div>
        </div>
        {dataTenor ? dataTenor?.Tenor.map((value, index) => {
              return (
                <LoanTenorForms
                  key={index}
                  index={index}
                  dataLoan={dataLoan}
                  setDataLoan={setDataLoan}
                  dataTenor={value}
                  setDataTenor={setDataTenor}
                  hideButtonAddTenor={dataTenor.Tenor.length - 1 === index + 1}
                  hideDeleteTenor={index !== 0}
                  hideButtonAddRangeDate={dataTenor.Tenor.length - 1 === index + 1}
                  onAddTenor={() => handleAddTenor(index)}
                  onAddRangeDate={() =>handleAddRangeDate(value.idTenor, index + 1)}
                  onDeleteTenor={() => handleDeleteTenor(value.idTenor)}
                  onDeleteRangeDate={handleDeleteRangeDate}
                />
              );
            })
          : null}
        <div className="add-more-default-time">
          <Button
            startIcon={<AddIcon sx={{ fontSize: 20, color: "#1571DE" }} />}
            onClick={() => handleAddTenor(dataTenor.Tenor.length)}
          >
            Add tenor
          </Button>
        </div>
        <div className="button-save-loan-add-scheme">
          <div className="buton-save-rectangle">
            <Button
              style={{
                color: "white",
                textTransform: "none",
                backgroundColor: "#1571de",
              }}
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
          <div className="button-cancel-rectangle">
            <Link to="/superadmin/loan">
              <Button
                style={{
                  color: "#1571de",
                  textTransform: "none",
                  border: "1px solid #1571DE",
                }}
                onClick={() => history.go(-1)}
              >
                Cancel
              </Button>
            </Link>
          </div>
        </div> */}
      </form>

      {/* {
        params.id 
      } */}

      {isModalOpen && (
        <ModalLoanSASuccess
          active={isModalOpen}
          onOpen={setIsModalOpen}
          desc={`${
            params?.id
              ? "One Scheme updated successfully "
              : "One Scheme added to the Loan"
          }`}
        />
      )}
    </div>
  );
}
