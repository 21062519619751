import React, { useEffect, useState } from "react";
import Styles from "./LeaveSchemaFormDetail.module.css";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
import DELETE_ICON from "../../../assets/images/delete-rules-icon.png";
import EDIT_ICON from "../../../assets/images/create.png";
import HolidayTableList from "../../../components/SuperAdmin/_holidaySetting/holidayTable/HolidayTableList";
import { HolidayTable } from "../../../components/SuperAdmin/_holidaySetting";
import { useLocation, useParams } from "react-router";
import fetchLeavePlanById from "../../../async/leavePlan/fetchLeavePlanById";
import { DeleteModalLayout } from "../../../components/SuperAdmin/_induction";
import deleteLeavePlan from "../../../async/leavePlan/deleteLeavePlan";


function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export default function LeaveSchemaFormDetail() {
  const token = localStorage.getItem("accessToken");
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState();
  const [isModalOpen, setIsModalOpen] = useState({});

  useEffect(() => {
    const refetchData = async () => {
      try {
        setIsLoading(true);
        const result = await fetchLeavePlanById(token, id);
        setData(result);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsError("Error");
        setIsLoading(false);
      }
    };
    refetchData();
  }, [id, token]);


  const handleDeleteLeavePlan = async () => {
    try {
      setIsLoading(true);
      await deleteLeavePlan(token, id);
      history.push(`/superadmin/leave`)
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Leave Scheme</title>
        </Helmet>
        <div className={Styles.Spacer}>
          <Breadcrumb aria-label="breadcrumb">
            <Button
              onClick={() => history.go(-1)}
              style={{ border: "none", textTransform: "none", width: "100%" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>
                Leave Scheme
              </span>
            </Button>
            <span style={{ fontSize: 20, color: "#255bfc" }}>View { data?.name }</span>
          </Breadcrumb>

          <div className={Styles.ButtonAction}>
            <Button
              onClick={() => {
                history.push({
                  pathname: `/superadmin/leave-scheme-edit/${id}`,
                });
              }}
            >
              <span>Edit</span>
              <img src={EDIT_ICON} alt="edit" />
            </Button>
            <Button
              onClick={(event) => {
                event.preventDefault();
                setIsModalOpen(data);
              }}
            >
              <span>Delete</span>
              <img src={DELETE_ICON} alt="delete" />
            </Button>
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <section className={Styles.Container}>
              <div className={Styles.Body}>
                <div className={Styles.Header}>Detail Scheme</div>
                <div className={Styles.Value}>
                  <div className={Styles.ValueControl}>
                    <span>Scheme name</span>
                    <span>{ data?.name }</span>
                  </div>
                  <div className={Styles.ValueControl}>
                    <span>Initial balance</span>
                    <span>{ data?.initialBalance }</span>
                  </div>
                  <div className={Styles.ValueControl}>
                    <span>Period method</span>
                    <span>{ capitalizeFirstLetter(data?.periodMethod ) }</span>
                  </div>
                  <div className={Styles.ValueControl}>
                    <span>Leave period</span>
                    <span>{ `${data?.periodStart} - ${data?.periodNext }`}</span>
                  </div>
                </div>

                <hr />

                <div className={Styles.HolidayTable}>
                  <HolidayTable datas={ data?.holiday || [] } id={id} loading={isLoading} mode="detail" isEdit="detail"/>
                </div>
              </div>
            </section>
          </Grid>

          {isModalOpen?._id && (
            <DeleteModalLayout
              active={isModalOpen}
              onOpen={setIsModalOpen}
              description="Are you sure you want to delete this scheme?"
              label="Delete Scheme"
              // pathName="/superadmin/leave"
              onDeleteScheme={handleDeleteLeavePlan}
            />
          )}
        </Grid>
      </div>
    </Container>
  );
}
