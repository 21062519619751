import React, { useEffect, useMemo, useState } from "react";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
import { GadaiDetails } from "../../../components/SuperAdmin/_loanData/LoanTabs";
import fetchPawnById from "../../../async/employeeeBenefit/fetchPawnById";
export default function GadaiDetail() {
  const token = localStorage.getItem('accessToken')
  const history = useHistory();
  const location = useLocation();
  // const loanDataById = location.state?.dataLoan;
  const { id } = useParams();
  const [pawn, setPawn] = useState();

  const loanDataById = useMemo(() => {
    return location?.state?.data
  },[location])

  useEffect(() => {
    ( async () => {
      try {
        const data = await fetchPawnById(token,id )
        setPawn(data)
      } catch (error) {
        console.log(error)
      }
    })()
  },[id, token])

  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Employee Benefits Program</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.go(-1)}
              style={{ border: "none", textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Gadai</span>
            </Button>
            <span style={{ fontSize: 20 }}>Submitter Detail</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <GadaiDetails
              data={ loanDataById }
              id={id}
              dataById={pawn}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
