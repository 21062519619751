/* eslint-disable no-unused-vars*/

import React, { useEffect, useState } from "react";
import { Grid, Container  } from "@mui/material";
import { Helmet } from "react-helmet";
import { InductionTableLayout } from "../../../components/SuperAdmin/_induction";
import fetchInductions from "../../../async/induction/fetchInductions";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllInduction } from "../../../redux/actions/SuperAdmin/inductionActions";


export default function Induction() {
  const token = localStorage.getItem("accessToken");
  const [inductions, setInductions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { allInduction, loadingDataInduction } = useSelector((state) => state.inductions)


  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await fetchInductions(token);
        setLoading(false);
        setInductions(data);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [token]);

  useEffect(() => {
    dispatch(fetchAllInduction(token));
  },[dispatch, token])

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Induction</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          Induction
        </h2>
      </div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <InductionTableLayout loading={ loading } datas={ allInduction }/>
        </Grid>
      </Grid>
    </Container>
  );
}
