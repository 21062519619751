/* eslint-disable no-unused-vars */

import React, { useEffect } from "react";
import Styles from "./ModalPawnReject.module.css";

import CHANGE_ICON from "../../../../assets/images/reject-icon-pawn.png";
import { Backdrop, Button, Fade, Modal } from "@mui/material";
import { useHistory } from "react-router";
import { Box } from "@mui/system";
import approvalPawn from "../../../../async/employeeeBenefit/approvalPawn";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "@media screen and (max-width: 478px)": {
    bottom: 0,
    top: "auto",
    transform: "translate(-50%, 0)",
  },
};

const modalStyle = {
  "@media screen and (max-width: 950px)": {
    display: "none",
  },
};

export default function ModalPawnReject({
  active,
  onOpen,
  label,
  desc,
  onClose,
  setOnSuccess,
  token,
  onSuccess,
  id,
  type,
}) {
  const history = useHistory();
  const handleClose = () => {
    onOpen(false);
  };

  const handleReject = async (e) => {
    try {
      e.preventDefault();
      onOpen(false);
      approvalPawn(token, id, { status: "REJECTED" });
      setOnSuccess(true);
      await new Promise((resolve, reject) => setTimeout(() => resolve(true), 1500))
      history.push("/admin-mws/pawn");
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (onSuccess === true || type === "Reject")  {
  //     onOpen(false);
  //     setOnSuccess(true);
  //     history.push('/admin-mws/pawn')
  //   }
  // },[onSuccess, history, type, onOpen, setOnSuccess]);

  return (
    <Modal
      open={active}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={modalStyle}
    >
      <Fade in={active}>
        <Box sx={style}>
          <div className={Styles.ModalContainerContent}>
            <div className={Styles.WrapperItemModalDelete}>
              <div className={Styles.ItemTopModalDelete}>
                <img src={CHANGE_ICON} alt="delete-icon" />
              </div>
            </div>
            <div className={Styles.ContentModalDelete}>
              <div className={Styles.TextModalDelete}>
                <span>{label}</span>
                <span>{desc}</span>
              </div>
              <div className={Styles.WrapperButtonModalDelete}>
                <Button
                  style={{
                    border: "1px solid #333333",
                    backgroundColor: "#FFFFFF",
                    borderRadius: 8,
                    textTransform: "none",
                    color: "#333333",
                    width: 171,
                    height: 38,
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    onOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    borderRadius: 8,
                    backgroundColor: "#1571DE",
                    textTransform: "none",
                    color: "#FFFFFF",
                    width: 171,
                    height: 38,
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  onClick={handleReject}
                >
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
