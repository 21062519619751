/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import Styles from "./PawnDetail.module.css";
import { Avatar, Button, Checkbox } from "@mui/material";
import PermataLogo from "../../../../assets/images/logo-permata.png";
import Receipt from "../../../../assets/images/Subtract.png";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  ModalApprovePawnLayout,
  ModalRejectPawnLayout,
  ModalSuccessPawnLayout,
} from "../../index";
import moment from "moment";
import approvalPawn from "../../../../async/employeeeBenefit/approvalPawn";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function PawnDetail(props) {
  const { data, dataById, token, id } = props;
  const history = useHistory();
  const [onReject, setOnReject] = useState(false);
  const [onApprove, setOnApprove] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false);
  const [storedData, setStoredData] = useState({});
  const [type, setType] = useState("");

  useEffect(() => {
    if (onApprove) {
      setType("Approve");
    }

    if (onReject) {
      setType("Reject");
    }
  }, [type, onApprove, onReject]);

  useEffect(() => {
    setStoredData({
      Name: data?.name,
      NRK: dataById?.employeeId,
      // "Birth Place": dataById?.birthPlace,
      "Birth Date": dataById?.birthOfDate,
      "ID Number": dataById?.user?.idNumber,
      "Survey Address": dataById?.address,
      "Contract type": dataById?.contractType,
      "Product brand": dataById?.brand,
      "Product variant": dataById?.productType,
      "Product issue year ": dataById?.productYear,
      "Product Color": dataById?.productColor,
      "Phone Number" : dataById?.user?.phoneNumber
    });
  }, [data, dataById]);


  return (
    <div className={Styles.Container}>
      <div className={Styles.Wrapper}>
        <div className={Styles.GroupControl}>
          <div className={Styles.Group}>
            <Avatar
              sx={{ width: 70, height: 70 }}
              src={data?.profilePicture ? data.profilePicture : ""}
            />
            <div className={Styles.GroupDetail}>
              <span>{data?.employeeName}</span>
              <span>Product Design</span>
              <span>{data?.client}</span>
            </div>
          </div>
          <div className={Styles.Group}>
            <div
              className={Styles.StatusControl}
              style={{
                backgroundColor:
                  (data?.status === "APPROVED" && "#498E0F") ||
                  (data?.status === "FINISHED" && "#1571DE") ||
                  (data?.status === "REJECTED" && "#F02C01") ||
                  (data?.status === "PENDING" && "#C1992F"),
              }}
            >
              <span>
                {(data?.status === "APPROVED" && "Approved") ||
                  (data?.status === "FINISHED" && "Finished") ||
                  (data?.status === "REJECTED" && "Rejected") ||
                  (data?.status === "PENDING" && "Pending")}
              </span>
            </div>
          </div>
        </div>

        <div className={Styles.Breaker} />

        <div className={Styles.GroupControlDetails}>
          <div className={Styles.Label}>
            <span>Pawn Detail</span>
          </div>

          <div className={Styles.WrapperGroup}>
            <div className={Styles.Details}>
              <div className={Styles.Heading}>
                <div className={Styles.Profile}>
                  <span>Employee Benefit Program</span>
                  <span>Gadai</span>
                  <span>{`${data?.division} - ${data?.client}`}</span>
                </div>

                <div className={Styles.Logo}>
                  <img src={PermataLogo} alt="permata-logo" />
                </div>
              </div>
              <div className={Styles.Breaker} />

              <div className={Styles.PawnCodeBox}>
                <div className={Styles.Keys}>
                  <span>Gadai Code</span>
                </div>

                <div className={Styles.Values}>
                  <span>{data.code}</span>
                </div>
              </div>

              {Object.keys(storedData)?.map((key, idx) => (
                <div className={Styles.ObjectGroup} key={idx}>
                  <div className={Styles.Keys}>
                    <span>{key}</span>
                  </div>

                  <div className={Styles.Values}>
                    <span>{storedData[key]}</span>
                  </div>
                </div>
              ))}
            </div>

            {data?.status === "REJECTED" ||
            data?.status === "PENDING" ? null : (
              <div className={Styles.Others}>
                <div className={Styles.Disbursed}>
                  <img src={Receipt} alt="receipt" />
                  {/* <span className={Styles.LeftCropper}></span> */}
                  <span className={Styles.Divider}></span>
                  {/* <span className={Styles.RightCropper}></span> */}

                  <div className={Styles.DisbursedDetail}>
                    <div className={Styles.DisbursedHeading}>
                      <span>Disbursed Detail</span>
                    </div>
                    <div className={Styles.BreakerReceipt} />
                    <div className={Styles.Info}>
                      <div className={Styles.InfoValue}>
                        <span>Tenor</span>
                        <span>{dataById?.staffLoan[0]?.tenor}</span>
                      </div>
                      <div className={Styles.InfoValue}>
                        <span>Type</span>
                        <span>{dataById?.productType}</span>
                      </div>
                      <div className={Styles.InfoValue}>
                        <span>Apply Amount</span>
                        <span>
                          Rp.
                          {dataById?.staffLoan[0]?.amount?.apply?.toLocaleString()}
                        </span>
                      </div>
                      <div className={Styles.InfoValue}>
                        <span>Monthly Installment</span>
                        <span>
                          Rp.
                          {dataById?.staffLoan[0]?.amount?.installment?.toLocaleString()}
                        </span>
                      </div>
                    </div>

                    <div className={Styles.InfoTotal}>
                      <div className={Styles.InfoValueTotal}>
                        <span>Disbursed Amount</span>
                        <span>
                          Rp.
                          {dataById?.staffLoan[0]?.amount?.disburse?.toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {data?.status === "REJECTED" ||
                data?.status === "PENDING" ? null : (
                  <div className={Styles.StepperList}>
                    {dataById?.staffLoan[0]?.installments?.map((each, idx) => (
                      <div className={Styles.Itinerary} key={idx}>
                        <div className={Styles.ItineraryProcess}>
                          {each.paidDate ? (
                            <Checkbox
                              disabled
                              icon={
                                <CheckCircleIcon sx={{ color: "#1571DE" }} />
                              }
                            />
                          ) : (
                            <Checkbox
                              disabled
                              icon={
                                <RadioButtonUnchecked
                                  sx={{ color: "#c2c2c2" }}
                                />
                              }
                            />
                          )}
                          <div
                            className={`${
                              idx ===
                              dataById?.staffLoan[0]?.installments?.length - 1
                                ? Styles.NoDashedProgress
                                : Styles.DashedProgress
                            }`}
                          />
                        </div>
                        <div className={`${Styles.ItineraryDetails}`}>
                          <div className={Styles.DetailValue}>
                            <span>Bulan {idx + 1}</span>
                            <span>
                              {moment(new Date(each.dueDate)).format("LL")}
                            </span>
                          </div>
                          {each?.paidDate ? (
                            <div className={Styles.DetailStatus}>
                              <span>Lunas</span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {data?.status === "PENDING" && (
          <div className={Styles.ActionButton}>
            <Button onClick={() => setOnReject(true)}>Reject</Button>
            <Button onClick={() => setOnApprove(true)}>Approve</Button>
          </div>
        )}
      </div>

      {onApprove && (
        <ModalApprovePawnLayout
          active={onApprove}
          onOpen={setOnApprove}
          onSuccess={onSuccess}
          setOnSuccess={setOnSuccess}
          token={token}
          id={id}
        />
      )}

      {onReject && (
        <ModalRejectPawnLayout
          active={onReject}
          onOpen={setOnReject}
          label="Reject"
          desc="Are you sure reject this request?"
          onSuccess={onSuccess}
          setOnSuccess={setOnSuccess}
          token={token}
          id={id}
          type={type}
        />
      )}

      {onSuccess && (
        <ModalSuccessPawnLayout
          onSuccess={onSuccess}
          setOnSuccess={setOnSuccess}
          type={type}
          desc={
            type === "Approve"
              ? "This request has been approved"
              : "This request has been rejected"
          }
          label={type === "Approve" ? "Approve" : "Reject"}
        />
      )}
    </div>
  );
}
