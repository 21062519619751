import React from "react";
import { Route, Switch } from "react-router-dom";
import { useRouteMatch} from 'react-router-dom';
import DashboardFMCG from "../../../pages/FMCG/Dashboard/DashboardFMCG";
import NotFound404 from "../../_pageNotFound/NotFound404";
import EmployeesFMCG from "../../../pages/FMCG/Employees/EmployeesFMCG";
import EmployeesDetailsFMCG from "../../../pages/FMCG/Employees/EmployeesDetailsFMCG";
import Merchandiser from "../../../pages/FMCG/Store/MD/Merchandiser";
import Spg from "../../../pages/FMCG/Store/SPG/Spg";
import EmployeesFormFMCG from "../../../pages/FMCG/Employees/EmployeesFormFMCG";
import StoreSetup from "../../../pages/FMCG/Store/StoreSetup/StoreSetup";
import DetailsStore from "../../../pages/FMCG/Store/DetailsStore/DetailsStore";
import AddNewStoreSPG from "../../../pages/FMCG/Store/AddNewStoreSPG/AddNewStoreSPG";
import EditStoreSPG from "../../../pages/FMCG/Store/EditStoreSPG/EditStoreSPG";
// import AddNewStoreMD from "../../../pages/FMCG/Store/AddNewStoreMD/AddNewStoreMD";
import EditStoreMD from "../../../pages/FMCG/Store/EditStoreMD/EditStoreMD";
import AddNewStoreAccountGroup from "../../../pages/FMCG/Store/AddNewStoreAccountGroup/AddNewStoreAccountGroup";
import EditStoreAccountGroup from "../../../pages/FMCG/Store/EditStoreAccountGroup/EditStoreAccountGroup";
import TableProducts from "../../../pages/FMCG/Products/TableProducts/TableProducts";
import AddNewProducts from "../../../pages/FMCG/Products/AddNewProducts/AddNewProducts";
import EditProducts from "../../../pages/FMCG/Products/EditProducts/EditProducts";
import TableAttendances from "../../../pages/FMCG/Attendance/TableAttendances/TableAttendances";
import DetailAttendanceFMCG from "../../../pages/FMCG/Attendance/DetailAttendance/DetailAttendance";
import DetailAttendancePerDay from "../../../pages/FMCG/Attendance/DetailAttendancePerDay/DetailAttendancePerDay";
import ListProductToSet from "../../../pages/FMCG/Products/ListProductToSet/ListProductToSet";
import TablePromo from "../../../pages/FMCG/Promo/TablePromo/TablePromo";
import AddNewPromo from "../../../pages/FMCG/Promo/AddNewPromo/AddNewPromo";
import AddProductPromo from "../../../pages/FMCG/Promo/AddProductPromo/AddProductPromo";
import CompetitorSetup from "../../../pages/FMCG/Competitor/CompetitorSetup/CompetitorSetup";
import CompetitorAddForm from "../../../pages/FMCG/Competitor/CompetitorAddForm/CompetitorAddForm";
import EditPromo from "../../../pages/FMCG/Promo/EditPromo/EditPromo";
import EditProductPromo from "../../../pages/FMCG/Promo/EditProductPromo/EditProductPromo";
import CompetitorEditForm from "../../../pages/FMCG/Competitor/CompetitorEditForm/CompetitorEditForm";
import ListProductToSetCompetitor from "../../../pages/FMCG/Competitor/ListProductToSetCompetitor/ListProductToSetCompetitor";
import ProductCompetitorAddForm from "../../../pages/FMCG/Competitor/ProductCompetitorAddForm/ProductCompetitorAddForm";
import ProductCompetitorEditForm from "../../../pages/FMCG/Competitor/ProductCompetitorEditForm/ProductCompetitorEditForm";
import ReportSPG from "../../../pages/FMCG/Report/ReportSPG/ReportSPG";
import EmployeesAssignLeaderFMCG from "../../../pages/FMCG/Employees/EmployeesAssignLeaderFMGC";
import EmployeesAssignStoreFMCG from "../../../pages/FMCG/Employees/EmployeAssignStoreFMCG";


const RoutesFMCG = () => {
  const { path } = useRouteMatch();
  // const token = localStorage.getItem('accessToken');
  return (
    <div>
      <Switch>
        <Route exact path={`${path}`} component={DashboardFMCG} />

        {/* Employees */}
        <Route exact path={`${path}/employees`} component={EmployeesFMCG} />
        {/* <Route exact path={`${path}/employees/add`} component={EmployeesFormFMCG}/> */}
        <Route exact path={`${path}/employees/edit/:id`} component={EmployeesFormFMCG}/>
        <Route exact path={`${path}/employees/detail/:id`} component={EmployeesDetailsFMCG}/>
        <Route exact path={`${path}/employees/assign-leader`} component={EmployeesAssignLeaderFMCG}/>
        <Route exact path={`${path}/employees/assign-store`} component={EmployeesAssignStoreFMCG}/>
        {/* Employees */}

        {/* Attendance */}
        <Route exact path={`${path}/attendances`} component={TableAttendances} />
        <Route exact path={`${path}/attendances/detail/:id`} component={DetailAttendanceFMCG} />
        <Route exact path={`${path}/attendances/detail/perday/:id`} component={DetailAttendancePerDay} />
        {/* Attendance */}

        {/* Store */}
        <Route exact path={`${path}/setup/store`} component={StoreSetup} />
        <Route exact path={`${path}/setup/store/details-spg/:id`} component={DetailsStore} />
        <Route exact path={`${path}/setup/store/details-md/:id`} component={DetailsStore} />
        <Route exact path={`${path}/setup/store/add-spg`} component={AddNewStoreSPG} />
        <Route exact path={`${path}/setup/store/edit-spg/:id`} component={EditStoreSPG} />
        <Route exact path={`${path}/setup/store/add-md`} component={AddNewStoreSPG} />
        <Route exact path={`${path}/setup/store/edit-md/:id`} component={EditStoreMD} />
        <Route exact path={`${path}/md-store`} component={Merchandiser} />
        <Route exact path={`${path}/spg-store`} component={Spg} />
        <Route exact path={`${path}/setup/store/add-account-group`} component={AddNewStoreAccountGroup} />
        <Route exact path={`${path}/setup/store/edit-account-group/:id`} component={EditStoreAccountGroup} />
        {/* Store */}

        {/* Products */}
        <Route exact path={`${path}/setup/products`} component={TableProducts} />
        <Route exact path={`${path}/setup/products/add`} component={AddNewProducts} />
        <Route exact path={`${path}/setup/products/edit/:id`} component={EditProducts} />
        <Route exact path={`${path}/setup/list-set-product-fmcg`} component={ListProductToSet} />
        {/* Products */}

        {/* Promo */}
        <Route exact path={`${path}/setup/promo`} component={TablePromo} />
        <Route exact path={`${path}/setup/promo/add`} component={AddNewPromo} />
        <Route exact path={`${path}/setup/promo/edit/:id`} component={EditPromo} />
        <Route exact path={`${path}/setup/promo/add-product-promo`} component={AddProductPromo} />
        <Route exact path={`${path}/setup/promo/edit-product-promo/:id/add`} component={EditProductPromo} />

        {/* Promo */}

        {/* Competitor */}
        <Route exact path={`${path}/setup/competitor`} component={CompetitorSetup} />
        <Route exact path={`${path}/setup/competitor/add`} component={CompetitorAddForm} />
        <Route exact path={`${path}/setup/competitor/edit/:id`} component={CompetitorEditForm} />
        <Route exact path={`${path}/setup/competitor/add-product-competitor`} component={ListProductToSetCompetitor} />
        <Route exact path={`${path}/setup/competitor/edit-product-competitor/:id/add`} component={ListProductToSetCompetitor} />

        <Route exact path={`${path}/setup/product-competitor/add`} component={ProductCompetitorAddForm} />
        <Route exact path={`${path}/setup/product-competitor/edit/:id`} component={ProductCompetitorEditForm} />
        {/* Competitor */}


        {/* Report  */}
        <Route exact path={`${path}/report/spg`} component={ReportSPG} />

        {/* Report  */}
        <Route path="*" component={NotFound404}>
        </Route>
      </Switch>
    </div>
  );
};

export default RoutesFMCG;
