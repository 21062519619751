import * as types from '../../type'

const initialstate = {
    schemaLocations: [],
    loadingDataLocations: false,
    errorDataLocations: {},
    schemaLocation: {},
    updatedLocation: {}
  };


  const locationsReducer = (state = initialstate, action)=> {
    switch (action.type) {
        case types.FETCH_ALL_LOCATIONS_BEGIN: {
            return {
                ...state,
                loadingDataLocations: true,
            }
        }
        case types.FETCH_ALL_LOCATIONS_SUCCESS: {
            return {
                ...state,
                loadingDataLocations: false,
                schemaLocations: action.payload,
            }
        }
        case types.FETCH_ALL_LOCATIONS_ERROR: {
            return {
                ...state,
                loadingDataLocations: false,
                errorDataLocations: action.payload
            }
        }
        case types.GET_LOCATION_BY_ID: {
            return {
                ...state,
                schemaLocation: action.payload
            }
        }   
        default: 
            return state;
    }

  }

  export default locationsReducer;
  