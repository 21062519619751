import {
  Avatar,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import "./NewCustomer.css";

function createData(name, product, store, date) {
  return { name, product, store, date };
}

const rows = [
  createData(
    "Putra Setiawan",
    "Susu Bendera",
    "UD Sinar Jaya",
    "21 December 2022"
  ),
  createData(
    "Putri Setiawan",
    "Susu Bendera",
    "UD Sinar Jaya",
    "21 December 2022"
  ),
  createData(
    "Putras Setiawan",
    "Susu Bendera",
    "UD Sinar Jaya",
    "21 December 2022"
  ),
];

const NewCustomer = () => {
  return (
    <div className="ContainerNewCustomer">
      <div className="WrapperNewCustomer">
        <div className="HeaderNewCustomer">
          <span>New Customer</span>
        </div>
        <div className="TableNewCustomer">
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 700,
                minHeight: 100,
                padding: 0,
                margin: 0,
                backgroundColor: "#FFFFFF",
              }}
              elevation={0}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Customer Name</TableCell>
                  <TableCell align="left">Product Name</TableCell>
                  <TableCell align="left">Store</TableCell>
                  <TableCell align="left">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Avatar src="" sx={{ width: 33, height: 33 }} />
                        <Typography
                          noWrap
                          style={{
                            textTransform: "capitalize",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#000000",
                          }}
                        >
                          {row.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">{row.product}</TableCell>
                    <TableCell align="left">{row.store}</TableCell>
                    <TableCell align="left">{row.date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default NewCustomer;
