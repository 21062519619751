/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './topNavBusiness.css';
import user_image from '../../../../src/assets/images/User.png';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
// import { Logout } from "../../../redux/actions/SuperAdmin/userActions";
import { IconButton, Tooltip, Menu, MenuItem, Avatar } from '@mui/material';

import Dropdown from '../../dropdown/Dropdown';
import SearchbarSA from '../../utils/SearchbarSA/SearchbarSA';
const curr_user = {
    display_name: 'Emil Fitriansyah',
    position: 'Admin',
    image: user_image,
};

const renderNotificationsItem = (item, index) => (
    <div className="notification-item" key={index}>
        <i className={item.icon}></i>
        <span>{item.content}</span>
    </div>
);

export default function TopNavBusiness({ onOpenSidebar }) {
    const [anchorLogout, setAnchorLogout] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleLogout = () => {
        localStorage.clear();
        window.location.href = '/';
        dispatch({ type: 'SET_AUTHENTICATED' });
    };
    const handleClickSettings = () => {
        history.push('/settings');
    };

    const handleOpenLogoutMenu = (event) => {
        setAnchorLogout(event.currentTarget);
    };
    const handleCloseLogout = () => {
        setAnchorLogout(null);
    };
    const user = useSelector((state) => state.user);

    return (
        <div className="topnav">
            <div className="topnav__icon">
                <i className="bx bx-menu" onClick={onOpenSidebar} style={{ cursor: 'pointer' }}></i>
            </div>

            <div className="topnav__search"></div>
            <div className="topnav__right">
                <div className="topnav__right-item">
                    {/* <Dropdown
            icon="bx bx-bell"
            badge="2"
            renderItems={(item, index) => renderNotificationsItem(item, index)}
          /> */}
                </div>
                <div className="topnav__right-item">
                    <div className="topnav__right-user">
                        <div className="topnav__right-user__image">
                            <Tooltip title="">
                                <Avatar
                                    sx={{ color: 'white', backgroundColor: 'black', cursor: 'pointer' }}
                                    onClick={handleOpenLogoutMenu}
                                />
                            </Tooltip>
                        </div>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorLogout}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorLogout)}
                            onClose={handleCloseLogout}>
                            {/* <div className={Styles.NotificationItem}>
                                    <i className="bx bx-cog bx-sm"></i>
                                    <span>Account Settings</span>
                                    <i className="bx bx-log-out-circle bx-rotate-180"></i>
                                    <span>Logout</span>
                                </div> */}
                            {/* <MenuItem onClick={handleClickSettings}>
                                    <i
                                    className="bx bx-cog bx-sm"
                                    style={{ fontSize: 16, marginRight: 20 }}
                                    ></i>
                                    <span style={{ fontSize: 16, font: "Poppins" }}>Settings</span>
                                </MenuItem> */}
                            <MenuItem onClick={handleLogout}>
                                <i
                                    className="bx bx-log-out-circle bx-rotate-180"
                                    style={{ fontSize: 24, marginRight: 20 }}></i>
                                <span style={{ fontSize: 16, font: 'Poppins' }}>Logout</span>
                            </MenuItem>
                        </Menu>
                        <div className="topnav__right-user_name">
                            <span className="topnav__right-user_name-main">{localStorage.getItem('userName')}</span>
                            <span className="topnav__right-user_name-sub">{localStorage.getItem('clientName')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
