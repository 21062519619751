import React from "react";
import { Helmet } from "react-helmet";
import { CompetitorSetupFMCG } from "../../../../components/FMCG";


export default function CompetitorSetup() {
  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Setup</title>
      </Helmet>
        <CompetitorSetupFMCG />
    </div>
  );
}
