import { Backdrop, Fade, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Styles from "./ModalPawnSuccess.module.css";
import CHECKED_BLUE from "../../../../assets/images/success-icon-add-scheme.png";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "@media screen and (max-width: 478px)": {
    bottom: 0,
    top: "auto",
    transform: "translate(-50%, 0)",
  },
};

const modalStyle = {
  "@media screen and (max-width: 950px)": {
    display: "none",
  },
};

export default function ModalPawnSuccess({ onSuccess, setOnSuccess, desc, label, type }) {

  const handleClose = () => {
    setOnSuccess(false);
  };

  console.log(type)

  return (
    <Modal
      open={onSuccess}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={modalStyle}
    >
      <Fade in={onSuccess}>
        <Box sx={style}>
          <div className={Styles.ModalContainerContent}>
            <div className={Styles.WrapperItemModalSuccess}>
              <div className={Styles.Image}>
                <img src={CHECKED_BLUE} alt="checked-success" />
              </div>
              <div className={Styles.Content}>
                <label>{label} succeed</label>
                <span>{desc}</span>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
