/* eslint-disable no-unused-vars*/

import {
  Autocomplete,
  Avatar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import ExportDataExcel from "../../../SuperAdmin/_attendance/tabs/DataAttendance/ExportDataExcel";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import Styles from "./SalaryAdvanceTable.module.css";
import CustomTablePagination from "../../../_pagination/CustomTablePagination";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import fetchClientCode from "../../../../async/client/fetchClientCode";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchDataOnGoingLoanByRangeDate } from "../../../../redux/actions/SuperAdmin/loanActions";
import DateRangePickerComponent from "../../../utils/DateRangePicker/DateRangePicker";

const datas = [
  {
    employeeName: "Jane Cooper",
    employeeId: 1,
    company: "PT. Arla",
    address: "Mayestik",
    placeOfBirth: "12 Januarty 2022",
    uniqueId: "12345678",
    product: "GADAI",
    productType: "MOTOR",
    productColor: "Merah",
    productYear: "2022",
    scheme: "Scheme 1",
    remark: "Gadai",
    brand: "Yamaha",
    clientCode: "PIS",
    status: "PENDING",
    applyDate: "21 Desember 2021",
    // createdAt: new Date(),
  },
  {
    employeeName: "John Doe",
    employeeId: 2,
    scheme: "Scheme 1",

    company: "PT. Arla",
    address: "Mayestik",
    placeOfBirth: "12 January 2022",
    uniqueId: "12345678",
    product: "GADAI",
    productType: "MOTOR",
    productColor: "Merah",
    productYear: "2022",
    remark: "Gadai",
    brand: "Yamaha",
    clientCode: "PIS",
    status: "FINISHED",
    applyDate: "21 Desember 2021",
    region: "Jakarta Region",
    installments: [
      {
        no: 1,
        dueDate: "25 January 2023",
        paidDate: "06 February 2023",
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
      {
        no: 2,
        dueDate: "25 January 2023",
        paidDate: "06 February 2023",
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
      {
        no: 3,
        dueDate: "25 January 2023",
        paidDate: "06 February 2023",
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
      {
        no: 4,
        dueDate: "25 January 2023",
        paidDate: "06 February 2023",
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
    ],
    // applyDate: new Date(),
    // createdAt: new Date(),
  },
  {
    employeeName: "Jane Cooper",
    employeeId: 3,
    scheme: "Scheme 1",

    company: "PT. Arla",
    address: "Mayestik",
    placeOfBirth: "12 January 2022",
    uniqueId: "12345678",
    product: "GADAI",
    productType: "MOTOR",
    productColor: "Merah",
    productYear: "2022",
    remark: "Gadai",
    brand: "Yamaha",
    clientCode: "PIS",
    status: "ACTIVE",
    applyDate: "21 Desember 2021",
    region: "Jakarta Region",
    installments: [
      {
        no: 1,
        dueDate: "25 January 2023",
        paidDate: "06 February 2023",
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
      {
        no: 2,
        dueDate: "25 January 2023",
        paidDate: "06 February 2023",
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
      {
        no: 3,
        dueDate: "24 Februari 2023",
        paidDate: null,
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
      {
        no: 4,
        dueDate: "24 March 2023",
        paidDate: null,
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
    ],
    // applyDate: new Date(),
    // createdAt: new Date(),
  },
  {
    employeeName: "Jane Cooper",
    employeeId: 4,
    scheme: "Scheme 1",
    company: "PT. Arla",
    address: "Mayestik",
    placeOfBirth: "12 January 2022",
    uniqueId: "12345678",
    product: "GADAI",
    productType: "MOTOR",
    productColor: "Merah",
    productYear: "2022",
    remark: "Gadai",
    brand: "Yamaha",
    clientCode: "PIS",
    status: "FAILED",
    applyDate: "21 Desember 2021",
    region: "Jakarta Region",
    // applyDate: new Date(),
    // createdAt: new Date(),
  },
  {
    employeeName: "Jane Cooper",
    employeeId: 5,
    scheme: "Scheme 1",
    company: "PT. Arla",
    address: "Mayestik",
    placeOfBirth: "12 January 2022",
    uniqueId: "12345678",
    product: "GADAI",
    productType: "MOTOR",
    productColor: "Merah",
    productYear: "2022",
    remark: "Gadai",
    brand: "Yamaha",
    clientCode: "PIS",
    status: "FAILED",
    applyDate: "21 Desember 2021",
    region: "Jakarta Region",
    // applyDate: new Date(),
    // createdAt: new Date(),
  },
  {
    employeeName: "Jane Cooper",
    employeeId: 6,
    scheme: "Scheme 1",
    company: "PT. Arla",
    address: "Mayestik",
    placeOfBirth: "12 January 2022",
    uniqueId: "12345678",
    product: "GADAI",
    productType: "MOTOR",
    productColor: "Merah",
    productYear: "2022",
    remark: "Gadai",
    brand: "Yamaha",
    clientCode: "PIS",
    status: "FAILED",
    applyDate: "21 Desember 2021",
    region: "Jakarta Region",
    // applyDate: new Date(),
    // createdAt: new Date(),
  },
  {
    employeeName: "Jane Cooper",
    employeeId: 7,
    scheme: "Scheme 1",
    company: "PT. Arla",
    address: "Mayestik",
    placeOfBirth: "12 January 2022",
    uniqueId: "12345678",
    product: "GADAI",
    productType: "MOTOR",
    productColor: "Merah",
    productYear: "2022",
    remark: "Gadai",
    brand: "Yamaha",
    clientCode: "PIS",
    status: "FAILED",
    applyDate: "21 Desember 2021",
    region: "Jakarta Region",
    // applyDate: new Date(),
    // createdAt: new Date(),
  },
  {
    employeeName: "Jane Cooper",
    employeeId: 8,
    scheme: "Scheme 1",
    company: "PT. Arla",
    address: "Mayestik",
    placeOfBirth: "12 January 2022",
    uniqueId: "12345678",
    product: "GADAI",
    productType: "MOTOR",
    productColor: "Merah",
    productYear: "2022",
    remark: "Gadai",
    brand: "Yamaha",
    clientCode: "PIS",
    status: "FAILED",
    applyDate: "21 Desember 2021",
    region: "Jakarta Region",
    // applyDate: new Date(),
    // createdAt: new Date(),
  },
  {
    employeeName: "Jane Cooper",
    employeeId: 9,
    scheme: "Scheme 1",
    company: "PT. Arla",
    address: "Mayestik",
    placeOfBirth: "12 January 2022",
    uniqueId: "12345678",
    product: "GADAI",
    productType: "MOTOR",
    productColor: "Merah",
    productYear: "2022",
    remark: "Gadai",
    brand: "Yamaha",
    clientCode: "PIS",
    status: "FAILED",
    applyDate: "21 Desember 2021",
    region: "Jakarta Region",
    // applyDate: new Date(),
    // createdAt: new Date(),
  },
  {
    employeeName: "Jane Cooper",
    employeeId: 10,
    scheme: "Scheme 1",
    company: "PT. Arla",
    address: "Mayestik",
    placeOfBirth: "12 January 2022",
    uniqueId: "12345678",
    product: "GADAI",
    productType: "MOTOR",
    productColor: "Merah",
    productYear: "2022",
    remark: "Gadai",
    brand: "Yamaha",
    clientCode: "PIS",
    status: "FAILED",
    applyDate: "21 Desember 2021",
    region: "Jakarta Region",
    // applyDate: new Date(),
    // createdAt: new Date(),
  },
];

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const TABLE_HEAD = [
  { id: "name", label: "Employee Name", alignRight: false },
  { id: "company", label: "Company", alignRight: false },
  { id: "applyDate", numeric: true, label: "Apply Date", alignRight: false },
  // { id: "scheme", numeric: true, label: "Scheme", alignRight: false },
  { id: "isPublish", numeric: true, label: "Status", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// eslint-disable-next-line no-unused-vars
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        {TABLE_HEAD.map((headCell, index) => (
          <TableCell
            key={headCell.id + index}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function SalaryAdvanceTableAdminMWS() {
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const clientName = localStorage.getItem("clientName");
  const dataOnGoingLoan = useSelector((state) => state.loan.onGoingLoan);
  const { loadingDataOngoingLoan } = useSelector((state) => state.loan);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("employeeName");
  const [selectedInput, setSelectedInput] = useState("");
  const [selected, setSelected] = useState(clientName);
  const [dataClientCode, setDataClientCode] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const [employee, setEmployee] = useState("");
  const [modal, setModal] = useState(false);
  const [arrayData, setArrayData] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [listDataLoan, setListDataLoan] = useState("");
  const options2 = ["All", "Active", "Finished"];
  const options3 = ["All", "Permata Indo Sejahtera", "Skills.id"];
  const [showBox, setShowBox] = useState(false);
  const [valueStartDate, setValueStartDate] = useState(moment().format("LL"));
  const [valueEndDate, setValueEndDate] = useState(moment().format("LL"));

  const clientNames = useMemo(() => {
    return dataClientCode.map((el) => el?.name);
  }, [dataClientCode]);

  const persistClientName = useMemo(() => {
    let persistClient = window.sessionStorage.getItem("selectedClientLoan");
    let clientName;

    if (persistClient) {
      clientName = persistClient;
    } else {
      clientName = selected;
    }

    return clientName;
  }, [selected]);

  const dateValueStart = useMemo(() => {
    let sessionStartDate = window.sessionStorage.getItem("startDateLoan");
    let firstDate;

    if (sessionStartDate) {
      firstDate = sessionStartDate;
    } else {
      firstDate = valueStartDate;
    }

    return firstDate;
  }, [valueStartDate]);

  const dateValueEnd = useMemo(() => {
    let sessionEndDate = window.sessionStorage.getItem("endDateLoan");
    let secondDate;

    if (sessionEndDate) {
      secondDate = sessionEndDate;
    } else {
      secondDate = valueEndDate;
    }

    return secondDate;
  }, [valueEndDate]);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCode(response);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname === "/admin-mws/salary-advance") {
      setSelected(persistClientName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistClientName]);

  useEffect(() => {
    dispatch(
      fetchDataOnGoingLoanByRangeDate(
        token,
        moment(dateValueStart).format("YYYY-MM-DD"),
        moment(dateValueEnd).format("YYYY-MM-DD"),
        // new Date(dateValueStart).getTime(),
        // new Date(dateValueEnd).getTime(),
        persistClientName
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    arrayData,
    dateValueEnd,
    dateValueStart,
    persistClientName,
    dispatch,
    token,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCode(response?.map((el) => el?.name));
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleSearchInput = (event) => {
    setListDataLoan(event.target.value);
  };

  const handleSearch = (value) => {
    setSelectedInput(value);
  };

  const handleSelectedClient = (value) => {
    window.sessionStorage.setItem("selectedClientLoan", value);
    setSelected(value);
    // setArrayData(false);
    setListDataLoan("");
  };
  const debouncedResults = useMemo(() => {
    return debounce(handleSearchInput, 500);
  }, []);

  const handleChangeStartDate = (newValue) => {
    setArrayData(true);
    setValueStartDate(newValue);
    window.sessionStorage.setItem("startDateLoan", newValue);
  };

  const handleChangeEndDate = (newValue) => {
    setArrayData(true);
    setValueEndDate(newValue);
    window.sessionStorage.setItem("endDateLoan", newValue);
  };

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  // console.log(dataOnGoingLoan, "<<<<");
  return (
    <div className={Styles.Container}>
      <div className={Styles.Header}>
        <div className={Styles.Selection}>
          <SearchbarSA
            searchWidth="379px"
            placeholder="Search by name"
            value={listDataLoan}
            onChange={handleSearchInput}
          />
          <DateRangePickerComponent
            valueStartDate={dateValueStart}
            valueEndDate={dateValueEnd}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
          />
          {/* <Autocomplete
            disablePortal
            options={dataClientCode.map((el => el.name))}
            sx={{ backgroundColor: "#FFFFFF" }}
            options={clientNames}
            value={persistClientName}
            inputValue={selectedInput}
            onInputChange={(e, value) =>
              value === null ? handleSearch("") : handleSearch(value)
            }
            onChange={(e, value) =>
              value === null
                ? handleSelectedClient("")
                : handleSelectedClient(value)
            }
            renderInput={(params) => (
              <MuiTextField
                {...params}
                size="small"
                sx={{ width: "250px" }}
                placeholder="All Company"
              />
            )}
          /> */}

          <Autocomplete
            disablePortal
            options={dataClientCode}
            value={persistClientName}
            inputValue={selectedInput}
            sx={{ width: 300 }}
            onInputChange={(e, value) =>
              value === null ? handleSearch("") : handleSearch(value)
            }
            onChange={(e, value) =>
              value === null
                ? handleSelectedClient("")
                : handleSelectedClient(value)
            }
            renderInput={(params) => <MuiTextField {...params} size="small" />}
          />

          <div className={Styles.Other}>
            <ExportDataExcel
              data={dataOnGoingLoan?.map((el, ind) => ({
                "No.": ind + 1,
                "Employee Name": el.employeeName,
                Company: el.company,
                "Apply Date": el.applyDate ? el?.applyDate : null,
                Tenor: el.tenor,
                Status:
                  (el?.status === "ACTIVE" && "Active") ||
                  (el?.status === "FINISHED" && "Finished") ||
                  (el?.status === "FAILED" && "Reject") ||
                  (el?.status === "PENDING" && "Pending"),
              }))}
              filename={`Loan Report ${new Date().toLocaleDateString()}`}
              initial="Download Excel"
            />
          </div>
        </div>
        {/* <div className={Styles.Other}>
          <ExportDataExcel
            data={dataOnGoingLoan?.map((el, ind) => ({
              "No.": ind + 1,
              "Employee Name": el.employeeName,
              Company: el.company,
              "Apply Date": el.applyDate ? el?.applyDate : null,
              Tenor: el.tenor,
              Status:
                (el?.status === "ACTIVE" && "Active") ||
                (el?.status === "FINISHED" && "Finished") ||
                (el?.status === "FAILED" && "Reject") ||
                (el?.status === "PENDING" && "Pending"),
            }))}
            filename={`Loan Report ${new Date().toLocaleDateString()}`}
            initial="Download Excel"
          />
        </div> */}
      </div>

      {loadingDataOngoingLoan ? (
        <div className={Styles.Loading}>
          <div className={Styles.LoadingWidth}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={Styles.TableWrapper}>
          <Scrollbar>
            <TableContainer className={Styles.TableContainer}>
              <Table id="emp-table" className={Styles.Table}>
                <colgroup>
                  <col style={{ width: "30%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "10%" }} />
                </colgroup>
                <EnhancedTableHead
                  numSelected={dataOnGoingLoan.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  values={dataOnGoingLoan}
                  rowCount={
                    dataOnGoingLoan.filter(
                      (el) =>
                        el.employeeName
                          ?.toLowerCase()
                          .includes(listDataLoan?.toLowerCase()) ||
                        el.company
                          ?.toLowerCase()
                          .includes(listDataLoan?.toLowerCase()) ||
                        el.status
                          ?.toLowerCase()
                          .includes(listDataLoan?.toLowerCase())
                    ).length
                  }
                />
                <TableBody>
                  {stableSort(dataOnGoingLoan, getComparator(order, orderBy))
                    .filter(
                      (el) =>
                        el?.employeeName
                          ?.toLowerCase()
                          .includes(listDataLoan?.toLowerCase()) ||
                        el?.company
                          ?.toLowerCase()
                          .includes(listDataLoan?.toLowerCase()) ||
                        el?.status
                          ?.toLowerCase()
                          .includes(listDataLoan?.toLowerCase())
                      /* (el.tenor + " Month")?.toString().toLowerCase().includes(listDataLoan?.toLocaleLowerCase()) ||
                          moment(el.DateResponse).format("L").toLocaleLowerCase().includes(listDataLoan?.toLocaleLowerCase()) */
                    )
                    .slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    )
                    .map((data, ind) => {
                      return (
                        <TableRow hover key={"tablebody" + ind} tabIndex={-1}>
                          <TableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                src={
                                  data.profilePicture ? data.profilePicture : ""
                                }
                              >
                                {data?.employeeName.substr(0, 1)}
                              </Avatar>
                              <Typography
                                sx={{ cursor: "pointer" }}
                                variant="subtitle2"
                                noWrap
                                style={{ textTransform: "capitalize" }}
                                onClick={() =>
                                  history.push({
                                    pathname: `/admin-mws/salary-advance/1`,
                                    state: { data: data },
                                  })
                                }
                              >
                                {data?.employeeName.toLowerCase()}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{data?.company}</TableCell>
                          <TableCell align="left">{data?.applyDate}</TableCell>
                          {/* <TableCell align="left">{data?.scheme}</TableCell> */}
                          <TableCell align="left">
                            <Typography
                              className={Styles.Status}
                              style={{
                                fontSize: 14,
                                fontWeight: 500,
                                backgroundColor:
                                  (data.status === "ACTIVE" && "#F4FFF2") ||
                                  (data.status === "FINISHED" && "#DEFEFF") ||
                                  (data.status === "FAILED" && "#FFF4F2") ||
                                  (data.status === "PENDING" && "#FFF9E9"),
                                color:
                                  (data.status === "ACTIVE" && "#498E0F") ||
                                  (data.status === "FINISHED" && "#1571DE") ||
                                  (data.status === "FAILED" && "#D52701") ||
                                  (data.status === "PENDING" && "#C1992F"),
                                borderColor:
                                  (data.status === "ACTIVE" && "#22970F") ||
                                  (data.status === "FINISHED" && "#1571DE") ||
                                  (data.status === "FAILED" && "#D52701") ||
                                  (data.status === "PENDING" && "#C1992F"),
                              }}
                            >
                              {(data?.status === "ACTIVE" && "Approved") ||
                                (data?.status === "FINISHED" && "Finished") ||
                                (data?.status === "FAILED" && "Rejected") ||
                                (data?.status === "PENDING" && "Pending")}
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="right">
                                <Link to={{pathname: `/admin-mws/ebp/1`, state: { data: data } }}>
                                  <span style={{ color: "#1571DE" }}>
                                    Lihat Detail
                                  </span>
                                </Link>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          {/* <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={
                dataOnGoingLoan.filter(
                  (el) =>
                    el.employeeName
                      ?.toLowerCase()
                      .includes(listDataLoan?.toLowerCase()) ||
                    el.company
                      ?.toLowerCase()
                      .includes(listDataLoan?.toLowerCase()) ||
                    el.status
                      ?.toLowerCase()
                      .includes(listDataLoan?.toLowerCase())
                ).length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          <CustomTablePagination
            limit={rowsPerPage}
            setLimit={setRowsPerPage}
            page={page}
            setPage={setPage}
            totalPage={Math.ceil(
              dataOnGoingLoan.filter(
                (el) =>
                  el.employeeName
                    ?.toLowerCase()
                    .includes(listDataLoan?.toLowerCase()) ||
                  el.company
                    ?.toLowerCase()
                    .includes(listDataLoan?.toLowerCase()) ||
                  el.status?.toLowerCase().includes(listDataLoan?.toLowerCase())
              ).length / rowsPerPage
            )}
          />
        </div>
      )}
    </div>
  );
}
