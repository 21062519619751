import axios from "axios";
import {API} from "../../api";
import *  as types from '../../type';

// FETCH SLIP PAYMENT
export const fetchSlipPaymentById = (token, id) => {
  // console.log(id, 'cek token actions')
  return (dispatch) => {
    axios
      .get(`${API}/epayslip/all/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_SLIP_PAYMENT_BY_ID,
          payload: res.data.data,
        });
        // console.log(res.data.data, 'cek data resp')
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// DATA PAYSLIP BY ID FOR ADMIN
export const fetchDataPayslipById = (token, id) => {
  // console.log(id, 'cek token actions')
  return (dispatch) => {
    axios
      .get(`${API}/payment-slip/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data.response, 'from actions')
        dispatch({
          type: types.FETCH_SLIP_PAYMENT_BY_ID,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// ALL PAYSLIP
export const fetchAllPayslip = (token, year, month, clientName) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_ALL_PAYSLIP_BEGIN})
    axios
    .get(`${API}/payment-slip/admin?year=${year}&month=${month}&clientName=${clientName}`, {
      headers: {
        Authorization: token,
      },
    })
      // .get(`https://02e3-125-160-236-232.ap.ngrok.io/admin?code=${clientCode}&year=${year}`, {
      //   mode: 'cors',
      //   headers: {
      //     Authorization: token,
      //   },
      // })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_PAYSLIP_SUCCESS,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_ALL_PAYSLIP_ERROR, payload: err.response})
        console.log(err);
      });
  };
};

// ALL PAYSLIP BY YEAR
export const fetchAllPayslipByYear = (token, year, nrkNumber) => {
  return (dispatch) => {
    axios
      .get(`${API}/epayslip/user/${year}?nrk=${nrkNumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_PAYSLIP_BY_YEAR,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// ALL PAYSLIP BY MONTH 
export const fetchAllPayslipByMonth = (token, month, year, clientName) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_ALL_PAYSLIP_BY_MONTH_BEGIN})
    axios
    .get(`${API}/payment-slip/admin?month=${month}&year=${year}&clientName=${clientName}`, {
      headers: {
        Authorization: token,
      },
    })
      // .get(`${API}/epayslip/epayslip-by-month/${month}?client=${clientName}`, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_PAYSLIP_BY_MONTH_SUCCESS,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_ALL_PAYSLIP_ERROR})
        console.log(err);
      });
  };
};


// UPLOAD DATA PAYSLIP 
export const uploadDataPayslip = (token, year, clientName, body) => {
  return (dispatch) => {
    axios
      .post(`${API}/epayslip/upload-epayslip?client=${clientName}&payrollMonth=${year}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.UPLOAD_DATA_PAYSLIP,
          payload: res.data.data,
        });
        console.log(res)
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
