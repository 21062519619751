/* eslint-disable no-unused-vars */
// /* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from "react";
// import "./DataAttendance.css";
import Styles from "./DataAttendance.module.css";
import {
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Autocomplete,
  TextField,
  styled,
  Card,
  Menu,
  MenuItem,
  Fade,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Scrollbar from "../../../_user/TableUsers/Scrollbar";
import UserListHead from "../../../_user/TableUsers/UserListHead";
import USERLIST from "../../../_user/TableUsers/_mocks/user";
import SearchbarSA from "../../../../utils/SearchbarSA/SearchbarSA";
import ExportCSV from "./ExportData";
import { fetchAllDataEmployeeByRange } from "../../../../../redux/actions/SuperAdmin/dataEmployeeInternalActions";
import DateRangePickerComp from "../../date-range-picker/DateRangePicker";
import moment from "moment";
import RangePicker from "../../../../utils/DateRangePicker/DateRangePicker";
import DateRangePickerComponent from "../../../../utils/DateRangePicker/DateRangePicker";
import { alpha, Box } from "@mui/system";
import { UseCompanyName } from "../../../../utils/hooks";
import LoadingAnimation from "../../../../utils/LoadingAnimation/LoadingAnimation";
import { UseGetAllArea } from "../../../../utils/hooks";
import axios from "axios";
import { fs } from "fs";
import { API, API_IMAGE } from "../../../../../redux/api";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import fetchClientCode from "../../../../../async/client/fetchClientCode";
import toHourAndMinute from "../../../../utils/ToHoursAndMinute/toHoursAndMinute";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import fetchGroupData from "../../../../../async/client/fetchGroupData";
import fetchPositions from "../../../../../async/groupData/fetchPositions";
import fetchAttendanceOvertime from "../../../../../async/attendance/fetchOvertime";

const ExcelJS = require("exceljs");

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 170,
    display: "flex",
    justifyContent: "flex-start",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiList-root": {
      width: "100%",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

var start = new Date("20/01/2023");
var end = new Date("19/02/2023");
let dateArr = [];

var loop = new Date(start);
while (loop <= end) {
  dateArr.push(moment(loop).format("L"));
  var newDate = loop.setDate(loop.getDate() + 1);
  loop = new Date(newDate);
}

const TABLE_HEAD = [
  { id: "name", label: "Employee Name", alignRight: false },
  { id: "jobTitle", label: "Position", alignRight: false },
  {
    id: "workTotal",
    numeric: true,
    label: "Jumlah Hari Kerja",
    alignRight: false,
  },
  {
    id: "effectiveDay",
    numeric: true,
    label: "Hari Efektif",
    alignRight: false,
  },
  { id: "revision", numeric: true, label: "Revisi", alignRight: false },
  { id: "annualLeave", numeric: true, label: "Cuti", alignRight: false },
  { id: "alfa", numeric: true, label: "Alfa", alignRight: false },
  { id: "permit", numeric: true, label: "Izin", alignRight: false },
  { id: "late", numeric: true, label: "Terlambat", alignRight: false },
  { id: "sick", numeric: true, label: "Sakit-SKD", alignRight: false },
  // { id: "attend", numeric: true, label: "Attend", alignRight: false },
  // { id: "annualLeave", numeric: true, label: "Leave", alignRight: false },
  // { id: "remain", numeric: true, label: "Remain", alignRight: false },
  // { id: "sick", numeric: true, label: "Sick", alignRight: false },
  // { id: "permit", numeric: true, label: "Permit", alignRight: false },
  // { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      // sx={{
      //   "& tr": {
      //     backgroundColor: "#F0F0F0",
      //   },
      // }}
      sx={{
        "& tr > th": {
          backgroundColor: "#F0F0F0",
          position: "sticky",
          zIndex: 2,
          top: 0,
        },
        "& tr > :first-of-type": {
          zIndex: 3,
          left: 0,
          top: 0,
          width: 250,
          borderRight: "2px solid #E0E0E0",
        },
        "& th": {
          fontSize: 13,
          fontWeight: 500,
          color: "#0A0A0A",
        },
      }}
    >
      <TableRow>
        {/* <TableCell padding="none" style={{ paddingLeft: 0 }}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={rowCount !== values?.users?.length}
            sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
          />
        </TableCell> */}
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const toDataURL = (url) => {
  const promise = new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });

  return promise;
};

function DataAttendance() {
  const { loadingAllDataEmployeeByRange, dataEmployeeInternalAll } =
    useSelector((state) => state.employeeInternal);
  // const employeeArea = UseGetAllArea();
  // const getCompany = UseCompanyName();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const clientCode = localStorage.getItem("clientCode");
  const clientName = localStorage.getItem("clientName");
  const [selected, setSelected] = useState(clientName);
  const [allEmployeeDatas, setAllEmployeeDatas] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedInput, setSelectedInput] = useState("");
  const [coordinate, setCoordinate] = useState({});
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listDataAttendance, setListDataAttendance] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [valueDate, setValueDate] = useState({});
  const [valueStartDate, setValueStartDate] = useState(moment().format("L"));
  const [valueEndDate, setValueEndDate] = useState(moment().format("L"));
  const valueStart = new Date(valueStartDate).getTime();
  const valueEnd = new Date(valueEndDate).getTime();
  const [dataClientCode, setDataClientCode] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState("");
  const open = Boolean(anchorEl);
  const [isDownload, setIsDownload] = useState(false);
  const [data, setData] = useState([]);
  //
  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState([]);
  const [dataOvertime, setDataOvertime] = useState([]);
  const [loadingOvertime, setLoadingOvertime] = useState(false);

  // function capitalizeFirstLetter(string) {
  //   return string?.charAt(0)?.toUpperCase() + string?.slice(1).toLowerCase();
  // }

  function capitalizeFirstLetter(str) {
    var words = str?.toLowerCase().split(" ");
    for (var i = 0; i < words?.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words?.join(" ");
  }

  const modifiedData = useMemo(() => {
    return dataEmployeeInternalAll.map((employee) => ({
      ...employee,
      profilePicture: employee?.profilePicture?.replace(
        `https://myworkspace.id/api/image/`,
        `${API_IMAGE}`
      ),
    }));
  }, [dataEmployeeInternalAll]);

  const modifiedDataFiltered = useMemo(() => {
    return dataEmployeeInternalAll.filter(
      (el) =>
        (el?.name?.toLowerCase().includes(listDataAttendance?.toLowerCase()) ||
          el?.jobTitle
            ?.toLowerCase()
            .includes(listDataAttendance?.toLowerCase())) &&
        (selectedRegion ? el?.division === selectedRegion : true) &&
        (position ? el?.jobTitle === position : true)
    );
  }, [dataEmployeeInternalAll, listDataAttendance, position, selectedRegion]);

  // console.log({ valueStartDate, valueEndDate }, 'woyy2')

  const dateValueStart = useMemo(() => {
    let sessionStartDate = window.sessionStorage.getItem("startDate");
    let firstDate;

    if (sessionStartDate) {
      firstDate = sessionStartDate;
    } else {
      firstDate = valueStartDate;
    }

    return firstDate;
  }, [valueStartDate]);

  const dateValueEnd = useMemo(() => {
    let sessionEndDate = window.sessionStorage.getItem("endDate");
    let secondDate;

    if (sessionEndDate) {
      secondDate = sessionEndDate;
    } else {
      secondDate = valueEndDate;
    }

    return secondDate;
  }, [valueEndDate]);

  // var end = new Date(dateValueEnd);
  // var dateRange = [];
  // for (var d = new Date(dateValueStart); d <= end; d.setDate(d.getDate() + 1)) {
  //   dateRange.push(new Date(d));
  // }

  // console.log(dateRange)

  const dateRange = useMemo(() => {
    let range = [];
    for (
      var d = new Date(dateValueStart);
      d <= new Date(dateValueEnd);
      d.setDate(d.getDate() + 1)
    ) {
      range.push(moment(d).format("L"));
    }
    return range;
  }, [dateValueStart, dateValueEnd]);

  const persistPosition = useMemo(() => {
    let persistPosition = window.sessionStorage.getItem("selectedPosition");
    let positionResult;

    if (persistPosition) {
      positionResult = persistPosition;
    } else {
      positionResult = position;
    }

    return positionResult;
  }, [position]);

  const persistClientName = useMemo(() => {
    let persistClient = window.sessionStorage.getItem("selectedClient");
    let clientName;

    if (persistClient) {
      clientName = persistClient;
    } else {
      clientName = selected;
    }

    return clientName;
  }, [selected]);

  const clientCodes = useMemo(() => {
    return dataClientCode.map((el) => el?.codes);
  }, [dataClientCode]);

  const clientNames = useMemo(() => {
    return dataClientCode.map((el) => el?.name);
  }, [dataClientCode]);

  const selectedIndexCodes = clientNames.findIndex((el) => el === clientCodes);

  const formatGetTime = (time) => {
    return new Date(time)?.getTime() - 7 * 60 * 60 * 1000;
  };

  const dataTest = () => {
    const array = [];
    for (let i = 0; i < dataEmployeeInternalAll?.length; i++) {
      const absences = dataEmployeeInternalAll[i]?.Absences;
      for (let j = 0; j < absences?.length; j++) {
        // const isRevisionWithRemark = absences[j].remark?.includes("REVISION") ;
        array.push({
          ...dataEmployeeInternalAll[i],
          // timeIn: moment(formatGetTime(absences[j]?.startTime)).format(
          //   "HH:mm:ss"
          // ),
          // timeOut: moment(formatGetTime(absences[j]?.endTime)).format(
          //   "HH:mm:ss"
          // ),
          // dateTimeIn: moment(formatGetTime(absences[j]?.startTime)).format("L"),
          // dateTimeOut: moment(formatGetTime(absences[j]?.endTime)).format("L"),
          timeIn: absences[j].startTime
            ? moment(absences[j].startTime).format("HH:mm:ss")
            : "-",
          timeOut:
            absences[j].endTime && absences[j].endTime !== "-"
              ? moment(absences[j].endTime).format("HH:mm:ss")
              : "-",
          dateTimeIn: moment(absences[j].startTime).format("L"),
          dateTimeOut:
            absences[j].endTime && absences[j].endTime !== "-"
              ? moment(absences[j].endTime).format("L")
              : "-",
          longitude: absences[j]?.location?.longitude
            ? absences[j]?.location?.longitude
            : "-",
          latitude: absences[j]?.location?.latitude
            ? absences[j]?.location?.latitude
            : "-",
          type: absences[j]?.type,
          month: moment(absences[j].startTime).format("M"),
          year: moment(absences[j].startTime).format("YYYY"),
          isLate: absences[j]?.isLate,
          totalWorkHour: toHourAndMinute(absences[j]?.accumulate?.workInMinute),
          region: absences[j]?.area,
          remark: absences[j]?.remark?.includes("::")
            ? capitalizeFirstLetter(absences[j]?.remark.split("::")[2])
            : capitalizeFirstLetter(absences[j]?.remark),
          requestTime: absences[j]?.applyDate
            ? moment(absences[j].applyDate).format("HH:mm:ss")
            : "-",
          requestDate: absences[j]?.applyDate
            ? moment(absences[j].applyDate).format("L")
            : "",
          status:
            // (absences[j].type === "ATTEND" && "Hadir") ||
            // (absences[j].type === "ANNUAL-LEAVE" && "Cuti") ||
            // (absences[j].type === "PERMIT" && "Izin") ||
            // (absences[j].type === "SICK-PERMIT" && "Izin") ||
            // (absences[j].type === "MARRIAGE-PERMIT" && "Izin") ||
            // (absences[j].type === "MISCARIAGE-PERMIT" && "Izin") ||
            // (absences[j].type === "BIRTH-MISCARIAGE-PERMIT" && "Izin") ||
            // (absences[j].type === "RELIGION-PERMIT" && "Izin") ||
            // (absences[j].type === "CHILD-MARRIAGE-PERMIT" && "Izin") ||
            // (absences[j].type === "DEATH-PERMIT" && "Izin ") ||
            // (absences[j].type === "BIRTH-LEAVE" && "Cuti") ||
            // (absences[j].type === "MISCARIAGE-LEAVE" && "Cuti") ||
            // (absences[j].type === "RELIGION-LEAVE" && "Cuti"),
            (absences[j].type === "ATTEND" && "Hadir") ||
            (absences[j].type === "ANNUAL-LEAVE" && "Cuti Tahunan") ||
            (absences[j].type === "PERMIT" && "Izin") ||
            (absences[j].type === "SICK-PERMIT" && "Izin Sakit") ||
            (absences[j].type === "MARRIAGE-PERMIT" && "Izin Menikah") ||
            (absences[j].type === "MISCARIAGE-PERMIT" && "Izin Menikah") ||
            (absences[j].type === "BIRTH-MISCARIAGE-PERMIT" &&
              "Izin Istri Melahirakan/Keguguran") ||
            (absences[j].type === "RELIGION-PERMIT" &&
              "Izin acara keagamaan") ||
            (absences[j].type === "CHILD-MARRIAGE-PERMIT" &&
              "Izin Pernikahan Anak") ||
            (absences[j].type === "DEATH-PERMIT" &&
              "Izin Kematian Anggota Keluarga") ||
            (absences[j].type === "BIRTH-LEAVE" && "Cuti Melahirkan") ||
            (absences[j].type === "MISCARIAGE-LEAVE" &&
              "Cuti Keguguran Kandungan") ||
            (absences[j].type === "RELIGION-LEAVE" && "Cuti Keagamaan"),
          imageAttendance: absences[j].images[0]?.uri
            ? absences[j].images[0]?.uri
            : "-",
          isRevision: absences[j].remark?.includes("REVISION")
            ? absences[j]?.revision?.remark
            : (absences[j].remark === "Check In" && "-") ||
              (absences[j].remark === "Check Out" && "-") ||
              capitalizeFirstLetter(absences[j].remark),
          // imageAttendance : toDataURL(`${absences[j]?.images[0]?.uri}`)
          // imageAttendance: toDataURL(absences[j]?.images[0]?.uri)
        });
      }

      // for (let j = 0; j < userName.length; j++) {
      //   array.push({
      //     ...dataEmployeeInternalAll[i],
      //     name: userName[j]?.name,
      //   });
      // }
    }
    return array;
  };

  const dataToExport = dataTest();

  const dataAbsenceAccumulation = () => {
    const rawArr = [];
    const dataEmployeeInternalAll = modifiedDataFiltered;
    for (let i = 0; i < dataEmployeeInternalAll?.length; i++) {
      const absences = dataEmployeeInternalAll[i]?.Absences;
      const data = dataEmployeeInternalAll[i];
      let attendanceArr = [];
      // for (let j = 0; j < dateRange.length; j++) {
      //   const dateExist = absences.some(
      //     (el) => moment(el.startTime).format("L") === dateRange[j]
      //   );
      //   const absenceIndex = absences.findIndex(
      //     (el) => moment(el.startTime).format("L") === dateRange[j]
      //   );

      //   attendanceArr.push({
      //     date: dateRange[j],
      //     type: dateExist
      //       ? (absences[absenceIndex]?.type === "ATTEND" && "Hadir") ||
      //         (absences[absenceIndex]?.type === "ANNUAL-LEAVE" && "Cuti") ||
      //         (absences[absenceIndex]?.type === "SICK-PERMIT" && "Sakit") ||
      //         (absences[absenceIndex]?.type === "PERMIT" && "Izin")
      //       : "-",
      //   });
      // }
      // for (let j = 0; j < absences?.length; j++){
      rawArr.push({
        ...dataEmployeeInternalAll[i],
        name: dataEmployeeInternalAll[i]?.name,
        attendances: attendanceArr,
        client: dataEmployeeInternalAll[i].client,
        jobTitle: dataEmployeeInternalAll[i].jobTitle,
        employeeId: dataEmployeeInternalAll[i].employeeId,
        month: absences[0]?.startTime ? absences[0]?.startTime : "-",
        year: absences[0]?.startTime ? absences[0]?.startTime : "-",
        totalWorkingDays: dataEmployeeInternalAll[i].totalWorkingDays,
        effectiveDay: dataEmployeeInternalAll[i].effectiveDay,
        revision: dataEmployeeInternalAll[i].revision,
        annualLeave: dataEmployeeInternalAll[i].annualLeave,
        alfa: dataEmployeeInternalAll[i].alfa,
        permit: dataEmployeeInternalAll[i].permit,
        late: dataEmployeeInternalAll[i].late,
        sick: dataEmployeeInternalAll[i].sick,
        // month: moment(absences[j].startTime).format("M"),
        // year: moment(absences[j].startTime).format("YYYY"),
      });
      // }
    }
    return rawArr;
  };

  const dataAttAccumulation = dataAbsenceAccumulation();

  const dataAccumulation = useMemo(() => {
    const datas = [];

    for (let i = 0; i < dataAttAccumulation?.length; i++) {
      const attendances = dataAttAccumulation[i]?.attendances;
      const eachData = {
        No: i + 1,
        Name: dataAttAccumulation[i]?.name,
        Company: dataAttAccumulation[i]?.client,
        Position: dataAttAccumulation[i].jobTitle,
        Nrk: dataAttAccumulation[i].employeeId,
        // Month: moment(dataAccumulation[i].month).format("M"),
        // Year: moment(dataAccumulation[i].year).format("YYYY"),
        "Jumlah hari kerja": dataAttAccumulation[i].totalWorkingDays,
        "Hari efektif": dataAttAccumulation[i].effectiveDay,
        Revisi: dataAttAccumulation[i].revision,
        Cuti: dataAttAccumulation[i].annualLeave,
        Alfa: dataAttAccumulation[i].alfa,
        Izin: dataAttAccumulation[i].permit,
        Terlambat: dataAttAccumulation[i].late,
        "Sakit-SKD": dataAttAccumulation[i].sick,
      };
      // for (let j = 0; j < attendances?.length; j++) {
      //   eachData[attendances[j].date] = attendances[j].type;
      // }
      datas.push(eachData);
    }
    return datas;
  }, [dataAttAccumulation]);

  const addDays = (days, newDate) => {
    // let date = new Date(this.valueOf());
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  };

  const range = useMemo(() => {
    const dateArray = [];
    let currentDate = moment(new Date(dateValueStart).getTime()).format("L");
    while (currentDate <= new Date(dateValueEnd)) {
      dateArray.push(new Date(currentDate));
      currentDate = addDays(1, currentDate);
    }
    return dateArray;
  }, [dateValueEnd, dateValueStart]);

  const dataCutOff = useMemo(() => {
    const datas = [];
    for (let i = 0; i < dataToExport?.length; i++) {
      const absences = dataToExport[i]?.Absences;
      const eachName = {
        No: i + 1,
        Name: dataToExport[i].name,
      };
      for (let j = 0; j < absences?.length; j++) {
        eachName[`${moment(absences[j].startTime).format("L")}`] = absences[j]
          .type
          ? (absences[j].type === "ATTEND" && "Hadir") ||
            (absences[j].type === "ANNUAL-LEAVE" && "Cuti") ||
            (absences[j].type === "SICK-PERMIT" && "Sakit") ||
            (absences[j].type === "PERMIT" && "Izin")
          : "Off";
      }
      datas.push(eachName);
    }

    return datas;
  }, [dataToExport]);

  // console.log(dataCutOff, 'Check')

  useEffect(() => {
    const refetchData = async () => {
      try {
        setLoadingOvertime(true);
        const { response } = await fetchAttendanceOvertime(
          token,
          persistClientName,
          moment(new Date(dateValueStart).getTime()).format("YYYY-MM-DD"),
          moment(new Date(dateValueEnd).getTime()).format("YYYY-MM-DD")
        );
        if (response) {
          setLoadingOvertime(false);
          setDataOvertime(response);
        }
      } catch (error) {
        setLoadingOvertime(false);
        console.log(error, "error");
      }
    };
    refetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistClientName, dateValueStart, dateValueEnd]);

  useEffect(() => {
    dispatch(
      fetchAllDataEmployeeByRange(
        token,
        moment(new Date(dateValueStart).getTime()).format("YYYY-MM-DD"),
        moment(new Date(dateValueEnd).getTime()).format("YYYY-MM-DD"),
        persistClientName
      )
    );
  }, [
    dateValueStart,
    dateValueEnd,
    token,
    dispatch,
    clientCode,
    selectedIndexCodes,
    clientNames,
    persistClientName,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await fetchPositions(token, clientName);
        setPositions(data?.position);
        // setRegions(data?.employeeArea);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [clientName, token]);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        // setDataClientCode(response?.map((el) => el?.name));
        setDataClientCode(response);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = await fetchGroupData(token, persistClientName);
        setGroupData(data?.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [persistClientName, token]);

  // --------------------------------
  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 80;

    sheet.getRow(1).font = {
      name: "Poppins",
      size: 16,
      bold: true,
    };

    sheet.columns = [
      {
        header: "No",
        key: "no",
        width: 10,
      },
      { header: "Nama", key: "name", width: 32 },
      {
        header: "Company",
        key: "company",
        width: 20,
      },
      {
        header: "Position",
        key: "position",
        width: 20,
      },
      {
        header: "Nrk",
        key: "nrk",
        width: 15,
      },
      {
        header: "Month",
        key: "month",
        width: 10,
      },
      {
        header: "Year",
        key: "year",
        width: 30,
      },
      {
        header: "Date In",
        key: "dateIn",
        width: 30,
      },
      {
        header: "Date Out",
        key: "dateOut",
        width: 30,
      },
      {
        header: "Time In",
        key: "timeIn",
        width: 30,
      },
      {
        header: "Time Out",
        key: "timeOut",
        width: 30,
      },
      {
        header: "Coordinate Point",
        key: "coordinatePoint",
        width: 30,
      },
      {
        header: "Late",
        key: "late",
        width: 30,
      },
      {
        header: "Total Work Hour",
        key: "workHour",
        width: 30,
      },
      {
        header: "Region",
        key: "region",
        width: 30,
      },
      {
        header: "Status Absence",
        key: "status",
        width: 30,
      },
      {
        header: "Remark",
        key: "remark",
        width: 30,
      },
      {
        header: "Photo",
        key: "photo",
        width: 30,
      },
    ];

    const promise = Promise.all(
      dataToExport.map(async (el, index) => {
        const rowNumber = index + 1;
        sheet.addRow({
          no: index + 1,
          name: el?.name,
          company: el?.client,
          position: el.jobTitle,
          nrk: el?.employeeId,
          month: el.month,
          year: el.year,
          dateIn: el.dateTimeIn,
          dateOut: el.dateTimeOut,
          timeIn: el.timeIn,
          timeOut: el.timeOut,
          coordinatePoint: `${el.latitude} ${el.longitude}`,
          late: el?.isLate,
          workHour: `${el?.totalWorkHour?.hours} Hours ${el?.totalWorkHour?.minutes} Minutes`,
          region: el?.region,
          status: el.status,
          remark: el.remark,
        });
        const result = await toDataURL(el?.imageAttendance);
        const splitted = el?.imageAttendance?.split(".");
        const extName = splitted[splitted?.length - 1];

        const imageId2 = workbook.addImage({
          base64: result.base64Url,
          extension: extName,
        });

        sheet.addImage(imageId2, {
          tl: { col: 17, row: rowNumber },
          ext: { width: 100, height: 100 },
        });
      })
    );

    promise.then(() => {
      const priceCol = sheet.getColumn(17);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        // if (cellValue > 50 && cellValue < 1000) {
        //   sheet.getCell(cell?.address).fill = {
        //     type: "pattern",
        //     pattern: "solid",
        //     fgColor: { argb: "FF0000" },
        //   };
        // }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `Attendance Report ${new Date().toLocaleDateString()}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };
  // ---------------------------------

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInput = (event) => {
    setListDataAttendance(event.target.value);
  };

  const handleSelectedRegion = (value) => {
    setSelectedRegion(value);
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchTerm(listDataAttendance);
  };

  const handleChangeStartDate = (newValue) => {
    setValueStartDate(newValue);
    window.sessionStorage.setItem("startDate", newValue);
  };

  const handleChangeEndDate = (newValue) => {
    setValueEndDate(newValue);
    window.sessionStorage.setItem("endDate", newValue);
  };

  const handleSearch = (value) => {
    setSelectedInput(value);
  };

  const handleChangeDate = (newValue) => {
    // setValueDate((prev) => ({
    //   ...prev,
    //   [e.target.name]: e.target.value
    // }))
    setValueDate(newValue);
  };

  const handleDownloadOvertime = async () => {
    const url = `${API}/attendance/admin/overtime?clientName=${persistClientName}&startDate=${moment(
      new Date(dateValueStart).getTime()
    ).format("YYYY-MM-DD")}&endDate=${moment(
      new Date(dateValueEnd).getTime()
    ).format("YYYY-MM-DD")}`;
    const headers = { "Content-Type": "blob", Authorization: token };
    const config = { method: "GET", url, responseType: "arraybuffer", headers };

    try {
      setLoading(true);
      const response = await axios(config);
      const outputFilename = `Attendance Report ${new Date().toLocaleDateString()}.xlsx`;

      const url = URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();

      fs?.writeFileSync(outputFilename, response?.data);
    } catch (error) {
      throw Error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadDetailedOvertime = async () => {
    const url = `${API}/attendance/admin/download/overtime?clientName=${persistClientName}&startDate=${moment(
      new Date(dateValueStart).getTime()
    ).format("YYYY-MM-DD")}&endDate=${moment(
      new Date(dateValueEnd).getTime()
    ).format("YYYY-MM-DD")}`;
    const headers = { "Content-Type": "blob", Authorization: token };
    const config = { method: "GET", url, responseType: "arraybuffer", headers };

    try {
      setLoading(true);
      const response = await axios(config);
      const outputFilename = `Attendance Report ${new Date().toLocaleDateString()}.xlsx`;

      const url = URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();

      fs?.writeFileSync(outputFilename, response?.data);
    } catch (error) {
      throw Error(error);
    } finally {
      setLoading(false);
    }
  };

  const onToDetailAttendance = (employeeId) => {
    history.push({
      pathname: `/superadmin/attendance-detail-attendance/${employeeId}`,
      state: {
        startDate: dateValueStart,
        endDate: dateValueEnd,
      },
    });
  };

  const handleSelectedClient = (value) => {
    window.sessionStorage.setItem("selectedClient", value);
    setSelected(value);
    // setArrayData(false);
    // setListDataPayslip("");
  };

  const handleSetPosition = (e, newVal) => {
    if (newVal !== null) {
      window.sessionStorage.setItem("selectedPosition", newVal);
      setPosition(newVal);
    } else {
      window.sessionStorage.setItem("selectedPosition", "");
      setPosition("");
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dataAbsenceRaw = () => {
    const rawArr = [];
    const dataEmployeeInternalAll = modifiedDataFiltered;
    for (let i = 0; i < dataEmployeeInternalAll?.length; i++) {
      const absences = dataEmployeeInternalAll[i]?.Absences;
      let attendanceArr = [];
      for (let j = 0; j < dateRange.length; j++) {
        const dateExist = absences.some(
          (el) => moment(el.startTime).format("L") === dateRange[j]
        );
        const absenceIndex = absences.findIndex(
          (el) => moment(el.startTime).format("L") === dateRange[j]
        );

        attendanceArr.push({
          date: dateRange[j],
          type: dateExist
            ? (absences[absenceIndex]?.type === "ATTEND" && "Hadir") ||
              (absences[absenceIndex]?.type === "ANNUAL-LEAVE" && "Cuti") ||
              (absences[absenceIndex]?.type === "SICK-PERMIT" && "Sakit") ||
              (absences[absenceIndex]?.type === "PERMIT" && "Izin")
            : "-",
        });
      }
      rawArr.push({
        name: dataEmployeeInternalAll[i]?.name,
        attendances: attendanceArr,
        client: dataEmployeeInternalAll[i].client,
        jobTitle: dataEmployeeInternalAll[i].jobTitle,
        employeeId: dataEmployeeInternalAll[i].employeeId,
        // month: dataEmployeeInternalAll[i].month,
        // year: dataEmployeeInternalAll[i].year,
        totalWorkingDays: dataEmployeeInternalAll[i].totalWorkingDays,
        effectiveDay: dataEmployeeInternalAll[i].effectiveDay,
        revision: dataEmployeeInternalAll[i].revision,
        annualLeave: dataEmployeeInternalAll[i].annualLeave,
        alfa: dataEmployeeInternalAll[i].alfa,
        permit: dataEmployeeInternalAll[i].permit,
        late: dataEmployeeInternalAll[i].late,
        sick: dataEmployeeInternalAll[i].sick,
        // month: moment(absences[absencesIndex].startTime).format("M"),
        // year: moment(absences[absencesIndex].startTime).fabsencesIndexrmat("YYYY"),
      });
    }
    return rawArr;
  };

  const dataToExport2 = dataAbsenceRaw();

  const dataCutOff2 = useMemo(() => {
    const datas = [];
    for (let i = 0; i < dataToExport2?.length; i++) {
      const attendances = dataToExport2[i]?.attendances;
      const eachData = {
        No: i + 1,
        Name: dataToExport2[i]?.name,
        Nrk: dataToExport2[i]?.employeeId,
      };
      for (let j = 0; j < attendances?.length; j++) {
        eachData[attendances[j].date] = attendances[j].type;
      }
      datas.push(eachData);
    }
    return datas;
  }, [dataToExport2]);

  // console.log(dataToExport, '<<<')

  // console.log(moment(new Date(dateValueStart).getTime()).format("YYYY-MM-DD"), '<<<,')

  // console.log(
  //   modifiedData.filter(
  //     (el) =>
  //       (el?.name?.toLowerCase().includes(listDataAttendance?.toLowerCase()) ||
  //         el?.jobTitle
  //           ?.toLowerCase()
  //           .includes(listDataAttendance?.toLowerCase())) &&
  //       (selectedRegion ? el?.division === selectedRegion : true) &&
  //       (position ? el?.jobTitle === position : true)
  //   ),
  //   "datas"
  // );

  if (isDownload) {
    <div
      className=""
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span>Loading .....</span>
    </div>;
  }

  console.log("dataToExport", dataToExport);

  return (
    <div className={Styles.Container}>
      <div className={Styles.Wrapper}>
        <div className={Styles.Searchbar}>
          <SearchbarSA
            value={listDataAttendance}
            onChange={handleSearchInput}
            onSubmit={handleSubmitFilter}
            searchWidth="100%"
            placeholder="Search by name"
          />
          <div className={Styles.Selection}>
            <Autocomplete
              disablePortal
              // options={dataClientCode.map((el => el.name))}
              disabled={loadingAllDataEmployeeByRange}
              options={clientNames}
              value={persistClientName}
              inputValue={selectedInput}
              sx={{ width: "100%", backgroundColor: "#FFFFFF" }}
              onInputChange={(e, value) =>
                value === null ? handleSearch("") : handleSearch(value)
              }
              onChange={(e, value) =>
                value === null
                  ? handleSelectedClient("")
                  : handleSelectedClient(value)
              }
              renderInput={(params) => (
                <MuiTextField {...params} size="small" />
              )}
            />
            {/* <Autocomplete
              disablePortal
              options={dataClientCode.map((el => el.name))}
              options={clientNames}
              value={persistClientName}
              inputValue={selectedInput}
              sx={{ width: 300 }}
              sx={{ width: "100%", backgroundColor: "#FFFFFF" }}
              onInputChange={(e, value) =>
                value === null ? handleSearch("") : handleSearch(value)
              }
              onChange={(e, value) =>
                value === null
                  ? handleSelectedClient("")
                  : handleSelectedClient(value)
              }
              renderInput={(params) => (
                <MuiTextField {...params} size="small" />
              )}
            /> */}
          </div>
        </div>
        <div className={Styles.Others}>
          <div className={Styles.OthersDownload}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className={Styles.RangeDate}>
                <DatePicker
                  sx={{ width: "100%" }}
                  components={{
                    OpenPickerIcon: CalendarMonthIcon,
                  }}
                  disabled={loadingAllDataEmployeeByRange}
                  required
                  value={dateValueStart}
                  onChange={handleChangeStartDate}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      size="small"
                      sx={{ width: "100%", backgroundColor: "#FFFFFF" }}
                    />
                  )}
                />
              </div>
              <div className={Styles.RangeDate}>
                <DatePicker
                  sx={{ width: "100%" }}
                  components={{
                    OpenPickerIcon: CalendarMonthIcon,
                  }}
                  disabled={loadingAllDataEmployeeByRange}
                  required
                  value={dateValueEnd}
                  onChange={handleChangeEndDate}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      size="small"
                      sx={{ width: "100%", backgroundColor: "#FFFFFF" }}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
            <Button
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableElevation
              onClick={handleClick}
              endIcon={<ExpandMoreIcon />}
              sx={{
                border: "1px solid #E0E0E0",
                borderRadius: "8px",
                textTransform: "none",
                color: "#757575",
                fontSize: 14,
                fontWeight: 400,
                width: 170,
                height: 40,
                backgroundColor: "#FFFFFF",
              }}
            >
              <span>Download</span>
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {/* {persistClientName === "PT. CITRA NUSA INSAN CEMERLANG" ? (
                <ExportCSV
                  data={dataToExport
                    .filter(
                      (el) =>
                        (el?.name
                          ?.toLowerCase()
                          .includes(listDataAttendance.toLowerCase()) ||
                          el?.jobTitle
                            ?.toLowerCase()
                            .includes(listDataAttendance.toLowerCase())) &&
                        (selectedRegion
                          ? el?.division === selectedRegion
                          : true) &&
                        (position ? el?.jobTitle === position : true)
                    )
                    .map((el, ind) => ({
                      "No. ": ind + 1,
                      Nama: el?.name,
                      Company: el?.client,
                      Position: el.jobTitle,
                      Nrk: el?.employeeId,
                      Month: el.month,
                      Year: el.year,
                      "Remark" : el?.status,
                      "Date in": el.dateTimeIn,
                      "Date out": el.dateTimeOut,
                      "Time in": el.timeIn,
                      "Time out": el.timeOut,
                      "Coordinate Point": `${el.latitude} ${el.longitude}`,
                      Late: el?.isLate,
                      "Total Work Hour": `${el?.totalWorkHour?.hours} Hours ${el?.totalWorkHour?.minutes} Minutes`,
                      Region: el?.region,
                      "Status Absence": el.status,
                      Description: el?.isRevision,
                    }))}
                  filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                  initial="Default"
                /> */}
              <ExportCSV
                data={dataToExport
                  .filter(
                    (el) =>
                      (el?.name
                        ?.toLowerCase()
                        .includes(listDataAttendance?.toLowerCase()) ||
                        el?.jobTitle
                          ?.toLowerCase()
                          .includes(listDataAttendance?.toLowerCase())) &&
                      (selectedRegion
                        ? el?.division === selectedRegion
                        : true) &&
                      (position ? el?.jobTitle === position : true)
                  )
                  .map((el, ind) => ({
                    "No. ": ind + 1,
                    Nama: el?.name,
                    Company: el?.client,
                    Position: el.jobTitle,
                    Nrk: el?.employeeId,
                    Month: el.month,
                    Year: el.year,
                    "Date in": el.dateTimeIn,
                    "Date out": el.dateTimeOut,
                    "Time in": el.timeIn,
                    "Time out": el.timeOut,
                    "Coordinate Point": `${el.latitude} ${el.longitude}`,
                    Region: el.region,
                    "Status Absence": el.status,
                    Description: el?.isRevision,
                    "Request Date": el?.requestDate,
                    "Request Time": el?.requestTime,
                  }))}
                filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                initial="Default"
              />
              <ExportCSV
                data={dataAccumulation}
                filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                initial="Accumulation"
              />
              <Button
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  textTransform: "none",
                  color: "#404040",
                  fontSize: 14,
                  fontWeight: 400,
                }}
                onClick={exportExcelFile}
              >
                Detailed
              </Button>
              <ExportCSV
                data={dataCutOff2}
                filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                initial="Summary"
              />
              <ExportCSV
                data={dataOvertime?.map((el, ind) => ({
                  "No. ": ind + 1,
                  NAMA: el?.NAMA,
                  NRK: el?.NRK,
                  LOKASI: el?.LOKASI,
                  REGION: el?.REGION,
                  STATUS: el?.STATUS,
                  "JOIN DATE": el?.["JOINT DATE"],
                  "END DATE": el?.["END DATE"],
                  NPWP: el?.NPWP,
                  "NO REK": el?.["NO.REK"],
                  BANK: el?.BANK,
                  "HARI KERJA": el?.["HARI KERJA"],
                  // OT: ,
                  "POINT OT": el?.POINT,
                  1.5: el?.satuLima,
                  "2.0": el?.dua,
                  "3.0": el?.tiga,
                  "4.0": el?.empat,
                  "JML JAM OT": el?.["JML JAM OT"],
                  // 'JML LEMBUR' : ,
                  // 'JUMLAH JAM OT': ''
                }))}
                filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                initial="Overtime"
              />
              {/* <Button
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  textTransform: "none",
                  color: "#404040",
                  fontSize: 14,
                  fontWeight: 400,
                }}
                onClick={handleDownloadOvertime}
              >
                Overtime
              </Button> */}
              <Button
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  textTransform: "none",
                  color: "#404040",
                  fontSize: 14,
                  fontWeight: 400,
                }}
                onClick={handleDownloadDetailedOvertime}
              >
                Detailed Overtime
              </Button>
            </StyledMenu>
          </div>
          <div className={Styles.OthersRangeDate}>
            <Autocomplete
              disablePortal
              options={
                groupData?.employeeArea?.filter(
                  (area) =>
                    area !== null &&
                    area !== "NULL" &&
                    area !== "" &&
                    area !== "DUMMY REGION"
                ) || []
              }
              value={selectedRegion}
              disabled={loadingAllDataEmployeeByRange}
              inputValue={selectedRegion}
              sx={{ width: "100%", backgroundColor: "#FFFFFF" }}
              onInputChange={(e, value) => setSelectedRegion(value)}
              renderInput={(params) => (
                <MuiTextField {...params} size="small" placeholder="Region" />
              )}
            />
            <Autocomplete
              disablePortal
              value={persistPosition || ""}
              options={
                positions?.filter(
                  (title) => title !== null && title !== "NULL" && title !== ""
                ) || []
              }
              onInputChange={handleSetPosition}
              disabled={loadingAllDataEmployeeByRange}
              onChange={handleSetPosition}
              sx={{ width: "100%", backgroundColor: "#FFFFFF" }}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  size="small"
                  sx={{ background: "var(--main-bg)" }}
                  placeholder="Position"
                />
              )}
            />
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className={Styles.RangeDate}>
                <DatePicker
                  sx={{ width: "100%" }}
                  components={{
                    OpenPickerIcon: CalendarMonthIcon,
                  }}
                  required
                  value={dateValueStart}
                  onChange={handleChangeStartDate}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      size="small"
                      sx={{ width: "100%", backgroundColor: "#FFFFFF" }}
                    />
                  )}
                />
              </div>
              <div className={Styles.RangeDate}>
                <DatePicker
                  sx={{ width: "100%" }}
                  components={{
                    OpenPickerIcon: CalendarMonthIcon,
                  }}
                  required
                  value={dateValueEnd}
                  onChange={handleChangeEndDate}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      size="small"
                      sx={{ width: "100%", backgroundColor: "#FFFFFF" }}
                    />
                  )}
                />
              </div>
            </LocalizationProvider> */}
            {/* <DateRangePickerComponent
              valueStartDate={dateValueStart}
              valueEndDate={dateValueEnd}
              handleChangeStartDate={handleChangeStartDate}
              handleChangeEndDate={handleChangeEndDate}
            /> */}
          </div>
        </div>
        {/* <div className="data-attendance-menu-right">
          <DateRangePickerComponent
            valueStartDate={dateValueStart}
            valueEndDate={dateValueEnd}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
          />
        </div> */}
        {/* <div className="">
          <Autocomplete
            disablePortal
            // options={dataClientCode.map((el => el.name))}
            options={clientNames}
            value={persistClientName}
            inputValue={selectedInput}
            sx={{ width: 300 }}
            onInputChange={(e, value) =>
              value === null ? handleSearch("") : handleSearch(value)
            }
            onChange={(e, value) =>
              value === null
                ? handleSelectedClient("")
                : handleSelectedClient(value)
            }
            renderInput={(params) => <MuiTextField {...params} size="small" />}
          />
        </div> */}
      </div>
      {loadingAllDataEmployeeByRange ? (
        <div className={Styles.LoadingContainer}>
          <div className={Styles.LoadingWidth}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <Card sx={{ marginTop: 2 }}>
          <Scrollbar>
            <TableContainer>
              <Table
                // sx={{
                //   width: "100%",
                //   maxHeight: "100%",
                // }}
                sx={{
                  width: "120%",
                  borderCollapse: "separate",
                }}
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: 5,
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                }}
              >
                <EnhancedTableHead
                  numSelected={modifiedData?.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  values={modifiedData}
                  rowCount={
                    modifiedData?.filter(
                      (el) =>
                        (el?.name
                          ?.toLowerCase()
                          .includes(listDataAttendance?.toLowerCase()) ||
                          el?.jobTitle
                            ?.toLowerCase()
                            .includes(listDataAttendance?.toLowerCase())) &&
                        (selectedRegion
                          ? el.division === selectedRegion
                          : true) &&
                        (position ? el?.jobTitle === position : true)
                    )?.length
                  }
                />
                <TableBody
                  sx={{
                    "& tr > th": {
                      position: "sticky",
                      padding: ".5rem",
                      zIndex: 2,
                      top: 0,
                      background: "#FFFFFF",
                    },
                    "& tr > :first-of-type": {
                      left: 0,
                      position: "sticky",
                      zIndex: 1,
                      background: "#FFFFFF",
                      borderRight: "2px solid #E0E0E0",
                      width: 250,
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#0A0A0A",
                      textTransform: "capitalize",
                    },
                    "& td > p": {
                      fontSize: 13,
                      fontWeight: 400,
                      color: "#404040",
                      textTransform: "capitalize",
                    },
                  }}
                >
                  {stableSort(modifiedData, getComparator(order, orderBy))
                    .filter(
                      (el) =>
                        (el?.name
                          ?.toLowerCase()
                          .includes(listDataAttendance?.toLowerCase()) ||
                          el?.jobTitle
                            ?.toLowerCase()
                            .includes(listDataAttendance?.toLowerCase())) &&
                        (selectedRegion
                          ? el?.division === selectedRegion
                          : true) &&
                        (position ? el?.jobTitle === position : true)
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((employee, ind) => {
                      // eslint-disable-next-line no-lone-blocks
                      {
                        /* const labelId = `enhanced-table-checkbox-${ind}`; */
                      }
                      return (
                        <TableRow
                          hover
                          key={ind}
                          tabIndex={-1}
                          sx={{
                            "&.MuiTableRow-root:hover": {
                              backgroundColor: "#eef6ff",
                            },
                          }}
                        >
                          {/* <TableCell></TableCell> */}
                          <TableCell
                            // component="th"
                            // scope="row"
                            // padding="none"
                            // id={labelId}
                            onClick={() => {
                              history.push({
                                pathname: `/superadmin/attendance-detail-attendance/${employee?.employeeId}`,
                                state: {
                                  startDate: dateValueStart,
                                  endDate: dateValueEnd,
                                  summary: employee,
                                },
                              });
                            }}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar src={employee?.profilePicture} />
                              <Typography
                                noWrap
                                sx={{
                                  fontSize: 13,
                                  fontWeight: 500,
                                  color: "#0A0A0A",
                                  textTransform: "capitalize",
                                }}
                              >
                                {employee?.name?.toLowerCase()}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Typography style={{ textTransform: "capitalize" }}>
                              {employee?.jobTitle?.toLowerCase()}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {employee?.totalWorkingDays
                              ? employee?.totalWorkingDays
                              : 0}
                          </TableCell>
                          <TableCell>
                            {employee?.effectiveDay
                              ? employee?.effectiveDay
                              : 0}
                          </TableCell>
                          <TableCell>
                            {employee?.revision ? employee?.revision : 0}
                          </TableCell>
                          <TableCell>
                            {employee?.annualLeave ? employee?.annualLeave : 0}
                          </TableCell>
                          <TableCell>
                            {employee?.alfa ? employee?.alfa : 0}
                          </TableCell>
                          <TableCell>
                            {employee?.permit ? employee?.permit : 0}
                          </TableCell>
                          <TableCell>
                            {employee?.late ? employee?.late : 0}
                          </TableCell>
                          <TableCell>
                            {employee?.sick ? employee?.sick : 0}
                          </TableCell>
                          {/* <TableCell align="right">
                            <Button
                              id="button-see-detail"
                              style={{
                                textTransform: "none",
                                fontSize: 16,
                                fontWeight: 500,
                                color: "#1571DE",
                              }}
                              onClick={() => {
                                history.push({
                                  pathname: `/superadmin/attendance-detail-attendance/${employee?.employeeId}`,
                                  state: {
                                    startDate: dateValueStart,
                                    endDate: dateValueEnd,
                                    summary: employee,
                                  },
                                });
                              }}
                            >
                              See Detail
                            </Button>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={
              modifiedData?.filter(
                (el) =>
                  (el?.name
                    ?.toLowerCase()
                    .includes(listDataAttendance?.toLowerCase()) ||
                    el?.jobTitle
                      ?.toLowerCase()
                      .includes(listDataAttendance?.toLowerCase())) &&
                  (selectedRegion ? el.division === selectedRegion : true)
              )?.length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      )}
    </div>
  );
}

export default DataAttendance;
