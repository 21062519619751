import * as types from '../../type'

const initialState = {
  authenticated: false,
  // credential ini jika di test pake postman maka isinya nama,email,uid dll,..cuma didalam object credential
  credentials: {},
  bio: {},
  showLoading: false,
  isError: {}
  // username: "",
  // role: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case types.SET_UNAUTHENTICATED: 
      return {
        initialState,
        authenticated: false,
        credentials: {},
      };
    case types.SET_USER:
      return {
        authenticated: true,
        ...action.payload,
      };
    case types.SET_USERNAME:
      return {
        bio: action.payload,
      };
      case types.SET_USERNAME_FAILED:
        return {
          ...state,
          isError: action.payload
        };
    // case types.LOADING_TOGGLE_ACTION:
    //   return {
    //     ...state,
    //     showLoading: action.payload
    //   };
    default:
      return state;
  }
}
