/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import BEACH_ICON from "../../../../assets/images/beach-brown.png";
import BRIEFCASE_ICON from "../../../../assets/images/briefcase-brown.png";
import CALENDAR_ICON from "../../../../assets/images/calendar-brown.png";
import SICK_ICON from "../../../../assets/images/sick-brown.png";
import Styles from "./Style.module.css";

import { Avatar } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import { useLocation } from "react-router";
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import moment from "moment";
import "moment/min/locales";
// import ExportCSV from "../../../SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
// moment.locale("id");
import toHoursAndMinutes from "../../../utils/ToHoursAndMinute/toHoursAndMinute";
import AbsenceDetailModal from "./AbsenceDetailModal/AbsencenceDetailModal";
import SummaryCard from "./SummaryCard/SummaryCard";
import getClientInfo from "../../../../async/client/getClientInfo";
import getClientCodeExternal from "../../../../async/client/getClientCodeExt";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllDataEmployeeByRangeExternal,
  fetchEmployeeAttendanceByRangeExternal,
} from "../../../../redux/actions/External/dataEmployeeExternalActions";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import axios from "axios";
import { API } from "../../../../redux/api";
import setOvertime from "../../../../async/attendance/setOvertime";
import DESCRIPTION_ICON from "../../../../assets/images/info-attend.png";
import CALENDAR_ATTENDANCE from "../../../../assets/images/calendar-attendance.png";
import LEAVE_ATTENDANCE from "../../../../assets/images/deliverable.png";
import SICK_ATTENDANCE from "../../../../assets/images/doctor-appointment.png";
import AnnualLeaveModal from "./AnnualLeaveModal/AnnualLeaveModal";
import fetchRemainAnnualLeave from "../../../../async/attendance/fetchRemainAnnualLeave";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SummaryCardSA from "../../../SuperAdmin/_attendance/attendance-detail-sa/SummaryCardSA/SummaryCardSA";
import ConfirmationModalSA from "../../../SuperAdmin/_attendance/attendance-detail-sa/ConfirmationModalSA";
import cancelAnnualLeave from "../../../../async/attendance/cancelAnnualLeave";
import SummaryCardExternal from "./SummaryCardSA/SummaryCardSA";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function EmployeeAttendanceDetail() {
  const location = useLocation();
  const { id: employeeId } = useParams();
  const dateFrom = location?.state?.startDate;
  const dateTo = location?.state?.endDate;
  const attendanceSummary = location?.state?.summary;
  const clientName = location?.state?.clientName;
  const wrappedFunction = [attendanceSummary].flat();
  const [clientCode, setClientCode] = useState("");
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const [switchId, setSwitchId] = useState(null);
  const [dataAnnualLeave, setDataAnnualLeave] = useState([]);

  const { dataEmployeeAttendanceExternal, loadingEmployeeAttendance } =
    useSelector((state) => state.employeeExternal);


  useEffect(() => {
    dispatch(
      fetchEmployeeAttendanceByRangeExternal(
        token,
        moment(dateFrom).format("YYYY-MM-DD"),
        moment(dateTo).format("YYYY-MM-DD"),
        clientName,
        attendanceSummary?.employeeId
      )
    );
  }, [
    dispatch,
    dateFrom,
    dateTo,
    clientName,
    token,
    switchId,
    attendanceSummary,
  ]);

  const summary = useMemo(() => {
    return dataEmployeeAttendanceExternal[0];
  }, [dataEmployeeAttendanceExternal]);

  const [selectedAbsence, setSelectedAbsence] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [isOvertimeEligible, setIsOvertimeEligible] = useState(false);
  const [isCancelAnnual, setIsCancelAnnual] = useState(false);
  const [selectedAttendanceId, setSelectedAttendanceId] = useState();
  const [hasMounted, setHasMounted] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const code = await getClientCodeExternal(
          token,
          attendanceSummary?.client
        );
        setClientCode(code);
      } catch (error) {
        console.log();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clientCode !== "") {
      (async () => {
        try {
          const info = await getClientInfo(token, clientCode);
          setIsOvertimeEligible(info?.attendancePlan?.overtime?.eligible);
        } catch (error) {
          console.log();
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientCode, hasMounted]);

  useEffect(() => {
    if (isCancelAnnual) {
      setSelectedAbsence(null);
    }
  }, [isCancelAnnual]);

  useEffect(() => {
    const refetchData = async () => {
      try {
        const data = await fetchRemainAnnualLeave(token, employeeId);
        setDataAnnualLeave(data.response);
      } catch (err) {
        console.log(err);
      }
    };
    refetchData();
  }, [token, employeeId, hasMounted]);

  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  const dateRange = useMemo(() => {
    let range = [];
    for (
      var d = new Date(dateFrom);
      d <= new Date(dateTo);
      d.setDate(d.getDate() + 1)
    ) {
      range.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
    }
    return range;
  }, [dateFrom, dateTo]);

  const dataAbsenceRaw = () => {
    const rawArr = [];
    const absences = summary?.Absences;
    let attendanceArr = [];
    for (let j = 0; j < dateRange.length; j++) {
      const dateExist = absences?.some(
        (el) =>
          moment(el.startTime).format("L") === moment(dateRange[j]).format("L")
      );
      const absenceIndex = absences?.findIndex(
        (el) =>
          moment(el.startTime).format("L") === moment(dateRange[j]).format("L")
      );
      const monthIndex = new Date(dateRange[j]).getMonth();
      const duration = dateExist
        ? toHoursAndMinutes(absences[absenceIndex]?.accumulate?.workInMinute)
        : null;
      attendanceArr.push({
        name: attendanceSummary.name,
        client: attendanceSummary.client,
        jobTitle: attendanceSummary.jobTitle,
        employeeId: attendanceSummary.employeeId,
        month: months[monthIndex],
        year: dateRange[j].getFullYear(),
        date: dateRange[j],
        dateIn: dateExist
          ? moment(absences[absenceIndex]?.startTime)?.format("LL")
          : null,
        dateOut: dateExist
          ? moment(absences[absenceIndex]?.endTime)?.format("LL")
          : null,
        timeIn: dateExist
          ? moment(absences[absenceIndex]?.startTime)?.format("HH:mm")
          : null,
        timeOut: dateExist
          ? moment(absences[absenceIndex]?.endTime)?.format("HH:mm")
          : null,
        coordinatePoint: dateExist
          ? absences[absenceIndex]?.location?.latitude +
            "," +
            absences[absenceIndex]?.location?.longitude
          : null,
        isLate: dateExist ? absences[absenceIndex]?.isLate : null,
        duration: dateExist
          ? `${duration?.hours} hours ${duration?.minutes} minutes`
          : null,
        region: attendanceSummary?.division,
        type: dateExist
          ? (absences[absenceIndex]?.type === "ATTEND" && "Hadir") ||
            (absences[absenceIndex]?.type === "ANNUAL-LEAVE" && "Cuti") ||
            (absences[absenceIndex]?.type === "SICK-PERMIT" && "Sakit") ||
            (absences[absenceIndex]?.type === "PERMIT" && "Izin") ||
            "-"
          : "-",
        remark: dateExist ? absences[absenceIndex]?.remark : null,
        imageAttendance: dateExist
          ? absences[absenceIndex].images[0]?.uri || null
          : null,
      });
    }
    rawArr.push(attendanceArr);

    return rawArr;
  };
  const dataAbsenceClean = () => {
    const rawArr = [];
    const absences = summary?.Absences;
    let attendanceArr = [];
    for (let j = 0; j < dateRange.length; j++) {
      const dateExist = absences?.some(
        (el) =>
          moment(el.startTime).format("L") === moment(dateRange[j]).format("L")
      );
      const absenceIndex = absences?.findIndex(
        (el) =>
          moment(el.startTime).format("L") === moment(dateRange[j]).format("L")
      );
      const monthIndex = new Date(dateRange[j]).getMonth();
      const duration = dateExist
        ? toHoursAndMinutes(absences[absenceIndex]?.accumulate?.workInMinute)
        : null;
      dateExist &&
        attendanceArr.push({
          name: attendanceSummary.name,
          client: attendanceSummary.client,
          jobTitle: attendanceSummary.jobTitle,
          employeeId: attendanceSummary.employeeId,
          month: months[monthIndex],
          year: dateRange[j].getFullYear(),
          date: dateRange[j],
          dateIn: moment(absences[absenceIndex]?.startTime)?.format("LL"),
          dateOut: moment(absences[absenceIndex]?.endTime)?.format("LL"),
          timeIn: moment(absences[absenceIndex]?.startTime)?.format("HH:mm"),
          timeOut: moment(absences[absenceIndex]?.endTime)?.format("HH:mm"),
          coordinatePoint:
            absences[absenceIndex]?.location?.latitude +
            "," +
            absences[absenceIndex]?.location?.longitude,
          isLate: absences[absenceIndex]?.isLate,
          duration: `${duration?.hours} hours ${duration?.minutes} minutes`,
          region: attendanceSummary?.division,
          type:
            (absences[absenceIndex]?.type === "ATTEND" && "Hadir") ||
            (absences[absenceIndex]?.type === "ANNUAL-LEAVE" && "Cuti") ||
            (absences[absenceIndex]?.type === "SICK-PERMIT" && "Sakit") ||
            (absences[absenceIndex]?.type === "PERMIT" && "Izin") ||
            "-",
          remark: absences[absenceIndex]?.remark,
          imageAttendance: absences[absenceIndex].images[0]?.uri || null,
          requestTime: absences[absenceIndex]?.applyDate
            ? moment(absences[absenceIndex].applyDate).format("HH:mm:ss")
            : "-",
          requestDate: absences[absenceIndex]?.applyDate
            ? moment(absences[absenceIndex].applyDate).format("L")
            : "",
        });
    }
    rawArr.push(attendanceArr);

    return rawArr;
  };

  const totalLeave = useMemo(() => {
    let leave = attendanceSummary?.annualLeave;
    let permit = attendanceSummary?.permit;
    return leave + permit;
  }, [attendanceSummary]);

  const detailAttendance = useMemo(() => {
    return [
      {
        title: "Presence",
        iconTitle: CALENDAR_ATTENDANCE,
        value: attendanceSummary?.attend,
        icon: DESCRIPTION_ICON,
      },
      {
        title: "Leave",
        iconTitle: LEAVE_ATTENDANCE,
        value: totalLeave,
        icon: DESCRIPTION_ICON,
      },
      {
        title: "Sick",
        iconTitle: SICK_ATTENDANCE,
        value: attendanceSummary?.sick,
        icon: DESCRIPTION_ICON,
      },
    ];
  }, [totalLeave, attendanceSummary]);

  const cleanData = dataAbsenceClean();
  const rawData = dataAbsenceRaw();

  if (!attendanceSummary?.employeeId) {
    return null;
  }

  const handleChangeOvertime = async (id) => {
    setSelectedAbsence(null);
    setSwitchId(id + Math.random());
    try {
      await setOvertime(token, id);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpenModalCancel = async () => {
    setSelectedAbsence(null);
    // setSwitchId(employeeId + Math.random());
    setIsCancelAnnual(true);
  };

  const handleOpenDetailModal = (selectedRow) => {
    setSelectedAttendanceId(selectedRow);
    setSelectedAbsence(selectedRow);
  };

  const handleCancelPermission = async () => {
    try {
      const data = await cancelAnnualLeave(token, selectedAttendanceId?._id, {
        endDate: moment.utc(selectedAttendanceId?.endDate).format("YYYY-MM-DD"),
      });
      setIsCancelAnnual(false);
      dispatch(
        fetchEmployeeAttendanceByRangeExternal(
          token,
          moment(dateFrom).format("YYYY-MM-DD"),
          moment(dateTo).format("YYYY-MM-DD"),
          clientName,
          attendanceSummary?.employeeId
        )
      );
    } catch (error) {
      setIsCancelAnnual(false);
      console.log(error);
    }
  };

  return (
    <div className={Styles.Container}>
      <div className={Styles.ProfileCard}>
        <div className={Styles.ProfileLeft}>
          <Avatar
            src={attendanceSummary?.profilePicture || ""}
            className={Styles.ProfilePicture}
          />
          <div className={Styles.ProfileDesc}>
            <span>{attendanceSummary?.name}</span>
            <span>{attendanceSummary?.jobTitle}</span>
            <span>
              Remaining Leaves :{" "}
              <label>{attendanceSummary?.remainAnnualLeave}</label>
            </span>
          </div>
        </div>
        <div className={Styles.ProfileRight}>
          {detailAttendance?.map((each, idx) => (
            <div className={Styles.BoxAmount} key={idx}>
              <div className={Styles.AmountIcon}>
                <span>{each?.title}</span>
                <img src={each?.iconTitle} alt="icon" />
              </div>
              <div className={Styles.AmountDesc}>
                <span>{each?.value}</span>
                <img
                  src={each?.icon}
                  alt="icon-description"
                  onClick={() => setDetailModal(true)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {detailModal && (
        <AnnualLeaveModal
          detailModal={detailModal}
          setDetailModal={setDetailModal}
          data={attendanceSummary}
          dataAnnualLeave={dataAnnualLeave}
        />
      )}
      {isCancelAnnual && (
        <ConfirmationModalSA
          active={Boolean(isCancelAnnual)}
          onOpen={setIsCancelAnnual}
          type="cancelAnnualLeave"
          textType="Confirm Cancel"
          description="Are you sure you want to cancel your leave permission?"
          handleCancelPermission={handleCancelPermission}
        />
      )}
      {loadingEmployeeAttendance ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 100,
            }}
          >
            <div style={{ width: 200 }}>
              <LoadingAnimation />
            </div>
          </div>
        </>
      ) : (
        <div className={Styles.SummaryCard}>
          <SummaryCardExternal
            startDate={new Date(dateFrom)}
            endDate={new Date(dateTo)}
            attendanceSummary={summary}
            cleanData={cleanData[0]}
            rawData={rawData[0]}
            setSelectedAbsence={setSelectedAbsence}
            isDownloading={isDownloading}
            setIsDownloading={setIsDownloading}
            overtimeEligibility={isOvertimeEligible}
            handleChangeOvertime={handleChangeOvertime}
            handleOpenDetailModal={handleOpenDetailModal}
            handleOpenModalCancel={handleOpenModalCancel}
            setDetailModal={setDetailModal}
          />
        </div>
      )}
      {selectedAbsence && (
        <AbsenceDetailModal
          selectedAbsence={selectedAbsence}
          setSelectedAbsence={setSelectedAbsence}
        />
      )}
    </div>
  );
}
