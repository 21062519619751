/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import Styles from "./AttendanceDetailMenu.module.css";
import image_company from "../../../../assets/images/Company.png";
import DatePicker from "../date-picker/DatePicker";
import { Avatar, Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import BigCalendar from "./BigCalendar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
// import { fetchAllDataEmployeeByRangeDateId } from "../../../../redux/actions/dataEmployeeInternalActions";
import dataEmployeeByRangeDateId from "../../../../components/_async/index";
import ExportCSV from "../tabs/DataAttendance/ExportData";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import moment from "moment";
import "moment/min/locales";
import fetchAttendanceById from "../../../../async/attendance/fetchAttendanceById";
import { fetchAllDataEmployeeByRangeDateId } from "../../../../redux/actions/SuperAdmin/dataEmployeeInternalActions";
import toHoursAndMinutes from "../../../utils/ToHoursAndMinute/toHoursAndMinute";
import ExportDataExcel from "../tabs/DataAttendance/ExportDataExcel";
import SummaryCardSA from "./SummaryCardSA/SummaryCardSA";
import setOvertime from "../../../../async/attendance/setOvertime";
import DESCRIPTION_ICON from "../../../../assets/images/info-attend.png";
import CALENDAR_ATTENDANCE from "../../../../assets/images/calendar-attendance.png";
import LEAVE_ATTENDANCE from "../../../../assets/images/deliverable.png";
import SICK_ATTENDANCE from "../../../../assets/images/doctor-appointment.png";
import AnnualLeaveModal from "../../../External/Attendance/EmployeeAttendanceDetail/AnnualLeaveModal/AnnualLeaveModal";
import AbsenceDetailModal from "../../../External/Attendance/EmployeeAttendanceDetail/AbsenceDetailModal/AbsencenceDetailModal";
import ConfirmationModalSA from "./ConfirmationModalSA";
import fetchRemainAnnualLeave from "../../../../async/attendance/fetchRemainAnnualLeave";
import cancelAnnualLeave from "../../../../async/attendance/cancelAnnualLeave";
import fetchAttendanceByEmployeeId from "../../../../async/attendance/fetchAttendanceByEmployeeId";
// import AnnualLeaveModal from "./AnnualLeaveModal/AnnualLeaveModal";
// moment.locale("id");

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function AttendanceDetailMenu() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: employeeId } = useParams();
  const token = localStorage.getItem("accessToken");
  const clientCode = localStorage.getItem("clientCode");
  const getPersistClient = sessionStorage.getItem("selectedClient");
  // const dataAttendance = useSelector((state) => state.employeeInternal.dataEmployeeInternalByRangeDateId[0]);
  const [data, setData] = useState(null);
  const [employeeData, setEmployeeData] = useState(null);
  // const dateFrom = location?.state?.startDate;
  // const dateTo = location?.state?.endDate;
  const attendanceSummary2 = location?.state?.summary;
  const clientName = localStorage.getItem("clientName");

  const [selectedAbsence, setSelectedAbsence] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [switchId, setSwitchId] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const [isCancelAnnual, setIsCancelAnnual] = useState(false);

  const [isOvertimeEligible, setIsOvertimeEligible] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [dataAnnualLeave, setDataAnnualLeave] = useState([]);
  const [selectedAttendanceId, setSelectedAttendanceId] = useState();
  const [hasMounted, setHasMounted] = useState(false);
  const [selected, setSelected] = useState(clientName);
  const [fetchedAttendance, setFetchedAttendance] = useState();
  const [isLoadingFetched, setIsLoadingFetched] = useState(false);

  const [persistAttendanceSummary, setPersistAttendanceSummary] = useState();

  const formatGetTime = (time) => {
    return new Date(time)?.getTime() - 7 * 60 * 60 * 1000;
  };

  useEffect(() => {
    if (attendanceSummary2) {
      setPersistAttendanceSummary(attendanceSummary2);
    }
  }, [attendanceSummary2]);

  const dateFrom = useMemo(() => {
    return location?.state?.startDate;
  }, [location?.state?.startDate]);

  const dateTo = useMemo(() => {
    return location?.state?.endDate;
  }, [location?.state?.endDate]);

  // const persistAttendanceSummary = useMemo(() => {
  //   let historyAttendance = location?.state?.summary;
  //   let attendanceSummary;

  //   if(fetchedAttendance) {
  //     attendanceSummary = fetchedAttendance;
  //   } else {
  //     attendanceSummary = historyAttendance
  //   }

  //   return attendanceSummary
  // },[location?.state?.summary, fetchedAttendance])

  const wrappedFunction = [persistAttendanceSummary].flat();

  const dateRange = useMemo(() => {
    let range = [];
    for (
      var d = new Date(dateFrom);
      d <= new Date(dateTo);
      d.setDate(d.getDate() + 1)
    ) {
      range.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
    }
    return range;
  }, [dateFrom, dateTo]);

  console.log(location.state);

  // console.log(persistAttendanceSummary, 'dataa');
  // console.log(attendanceSummary2, 'location')

  const dataAbsenceRaw = () => {
    const rawArr = [];
    const absences = persistAttendanceSummary?.Absences;
    let attendanceArr = [];
    for (let j = 0; j < dateRange.length; j++) {
      const dateExist = absences?.some(
        (el) =>
          moment(el.startTime).format("L") === moment(dateRange[j]).format("L")
      );
      const absenceIndex = absences?.findIndex(
        (el) =>
          moment(el.startTime).format("L") === moment(dateRange[j]).format("L")
      );
      const monthIndex = new Date(dateRange[j]).getMonth();
      const duration = dateExist
        ? toHoursAndMinutes(absences[absenceIndex]?.accumulate?.workInMinute)
        : null;
      attendanceArr.push({
        name: persistAttendanceSummary?.name,
        client: persistAttendanceSummary?.client,
        jobTitle: persistAttendanceSummary?.jobTitle,
        employeeId: persistAttendanceSummary?.employeeId,
        month: months[monthIndex],
        year: dateRange[j].getFullYear(),
        date: dateRange[j],
        dateIn: dateExist
          ? moment(absences[absenceIndex]?.startTime)?.format("LL")
          : null,
        dateOut: dateExist
          ? moment(absences[absenceIndex]?.endTime)?.format("LL")
          : null,
        timeIn: dateExist
          ? moment(absences[absenceIndex]?.startTime)?.format("HH:mm")
          : null,
        timeOut: dateExist
          ? moment(absences[absenceIndex]?.endTime)?.format("HH:mm")
          : null,
        coordinatePoint: dateExist
          ? absences[absenceIndex]?.location?.latitude +
            "," +
            absences[absenceIndex]?.location?.longitude
          : null,
        isLate: dateExist ? absences[absenceIndex]?.isLate : null,
        duration: dateExist
          ? `${duration?.hours} hours ${duration?.minutes} minutes`
          : null,
        region: persistAttendanceSummary?.division,
        type: dateExist
          ? (absences[absenceIndex]?.type === "ATTEND" && "Hadir") ||
            (absences[absenceIndex]?.type === "ANNUAL-LEAVE" && "Cuti") ||
            (absences[absenceIndex]?.type === "SICK-PERMIT" && "Sakit") ||
            (absences[absenceIndex]?.type === "PERMIT" && "Izin") ||
            "-"
          : "-",
        remark: dateExist ? absences[absenceIndex]?.remark : null,
        imageAttendance: dateExist
          ? absences[absenceIndex].images[0]?.uri || null
          : null,
      });
    }
    rawArr.push(attendanceArr);

    return rawArr;
  };
  const dataAbsenceClean = () => {
    const rawArr = [];
    const absences = persistAttendanceSummary?.Absences;
    let attendanceArr = [];
    for (let j = 0; j < dateRange.length; j++) {
      const dateExist = absences?.some(
        (el) =>
          moment(el.startTime).format("L") === moment(dateRange[j]).format("L")
      );
      const absenceIndex = absences?.findIndex(
        (el) =>
          moment(el.startTime).format("L") === moment(dateRange[j]).format("L")
      );
      const monthIndex = new Date(dateRange[j]).getMonth();
      const duration = dateExist
        ? toHoursAndMinutes(absences[absenceIndex]?.accumulate?.workInMinute)
        : null;
      dateExist &&
        attendanceArr.push({
          name: persistAttendanceSummary.name,
          client: persistAttendanceSummary.client,
          jobTitle: persistAttendanceSummary.jobTitle,
          employeeId: persistAttendanceSummary.employeeId,
          month: months[monthIndex],
          year: dateRange[j].getFullYear(),
          date: dateRange[j],
          dateIn: moment(absences[absenceIndex]?.startTime)?.format("LL"),
          dateOut: moment(absences[absenceIndex]?.endTime)?.format("LL"),
          timeIn: moment(absences[absenceIndex]?.startTime)?.format("HH:mm"),
          timeOut: moment(absences[absenceIndex]?.endTime)?.format("HH:mm"),
          coordinatePoint:
            absences[absenceIndex]?.location?.latitude +
            "," +
            absences[absenceIndex]?.location?.longitude,
          isLate: absences[absenceIndex]?.isLate,
          duration: `${duration?.hours} hours ${duration?.minutes} minutes`,
          region: persistAttendanceSummary?.division,
          type:
            (absences[absenceIndex]?.type === "ATTEND" && "Hadir") ||
            (absences[absenceIndex]?.type === "ANNUAL-LEAVE" && "Cuti") ||
            (absences[absenceIndex]?.type === "SICK-PERMIT" && "Sakit") ||
            (absences[absenceIndex]?.type === "PERMIT" && "Izin") ||
            "-",
          remark: absences[absenceIndex]?.remark,
          imageAttendance: absences[absenceIndex].images[0]?.uri || null,
          requestTime: absences[absenceIndex]?.applyDate
          ? moment(absences[absenceIndex].applyDate).format("HH:mm:ss")
          : "-",
        requestDate: absences[absenceIndex]?.applyDate
          ? moment(absences[absenceIndex].applyDate).format("L")
          : "",
        });
    }
    rawArr.push(attendanceArr);

    return rawArr;
  };

  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  useEffect(() => {
    const refetchData = async () => {
      try {
        const data = await fetchRemainAnnualLeave(token, employeeId);
        setDataAnnualLeave(data.response);
      } catch (err) {
        console.log(err);
      }
    };
    refetchData();
  }, [token, employeeId]);

  // useEffect(() => {
  //   dispatch(
  //     fetchAllDataEmployeeByRangeDateId(
  //       token,
  //       moment(dateFrom).format("MM-DD-YYYY"),
  //       moment(dateTo).format("MM-DD-YYYY"),
  //       clientCode)
  //   )
  // },[location, dateFrom, dateTo, token, dispatch,clientCode])

  const dataTest = () => {
    const array = [];
    for (let i = 0; i < wrappedFunction?.length; i++) {
      const absences = wrappedFunction[i]?.Absences;
      for (let j = 0; j < absences?.length; j++) {
        array.push({
          ...wrappedFunction[i],
          // timeIn: moment(formatGetTime(absences[j]?.startTime)).format("HH:mm:ss"),
          // timeOut: moment(formatGetTime(absences[j]?.endTime)).format("HH:mm:ss"),
          // dateTimeIn: moment(formatGetTime(absences[j]?.startTime)).format("L"),
          // dateTimeOut: moment(formatGetTime(absences[j]?.endTime)).format("L"),
          timeIn: absences[j].startTime
            ? moment(absences[j].startTime).format("HH:mm:ss")
            : "-",
          timeOut:
            absences[j].endTime && absences[j].endTime !== "-"
              ? moment(absences[j].endTime).format("HH:mm:ss")
              : "-",
          dateTimeIn: moment(absences[j].startTime).format("L"),
          dateTimeOut:
            absences[j].endTime && absences[j].endTime !== "-"
              ? moment(absences[j].endTime).format("L")
              : "-",
          // longitude: absences[j]?.location?.longitude ? absences[j]?.location?.longitude : "-",
          // latitude: absences[j]?.location?.latitude ? absences[j]?.location?.latitude : "-",
          longitude: absences[j]?.location?.longitude
            ? absences[j]?.location?.longitude
            : "-",
          latitude: absences[j]?.location?.latitude
            ? absences[j]?.location?.latitude
            : "-",
          type: absences[j]?.type,
          month: moment(absences[j].startTime).format("M"),
          year: moment(absences[j].endTime).format("YYYY"),
          isLate: absences[j]?.isLate,
          totalWorkHour: toHoursAndMinutes(
            absences[j]?.accumulate?.workInMinute
          ),
          region: absences[j]?.area,
          // typeAttendance: absences[j].type ? absences[j].type : "-",
          // month: moment(absences[j].startTime).format("M"),
          // year: moment(absences[j].startTime).format("YYYY"),
          remark: absences[j]?.remark?.includes("::")
            ? capitalizeFirstLetter(absences[j]?.remark.split("::")[2])
            : capitalizeFirstLetter(absences[j]?.remark),
          status:
            (absences[j].type === "ATTEND" && "Hadir") ||
            (absences[j].type === "ANNUAL-LEAVE" && "Cuti") ||
            (absences[j].type === "SICK-PERMIT" && "Sakit"),
        });
      }
    }
    return array;
  };
  const dataToExport = dataTest();

  const handleChangeOvertime = async (id) => {
    setSelectedAbsence(null);
    setDetailModal(null);
    setSwitchId(id + Math.random());
    try {
      await setOvertime(token, id);
    } catch (err) {
      console.error(err);
      setErrorModal(true);
    }
  };

  // const totalLeave = useMemo(() => {
  //   let leave = attendanceSummary?.annualLeave;
  //   let permit = attendanceSummary?.permit;
  //   return leave + permit;
  // }, [attendanceSummary]);

  const detailAttendance = useMemo(() => {
    return [
      {
        title: "Presence",
        iconTitle: CALENDAR_ATTENDANCE,
        value: persistAttendanceSummary?.attend,
        icon: DESCRIPTION_ICON,
      },
      {
        title: "Leave",
        iconTitle: LEAVE_ATTENDANCE,
        value: persistAttendanceSummary?.annualLeave,
        icon: DESCRIPTION_ICON,
      },
      {
        title: "Permit",
        iconTitle: SICK_ATTENDANCE,
        value: persistAttendanceSummary?.permit,
        icon: DESCRIPTION_ICON,
      },
    ];
  }, [persistAttendanceSummary]);

  const persistClientName = useMemo(() => {
    let persistClient = window.sessionStorage.getItem("selectedClient");
    let clientName;

    if (persistClient) {
      clientName = persistClient;
    } else {
      clientName = selected;
    }

    return clientName;
  }, [selected]);

  useEffect(() => {
    if (isCancelAnnual) {
      setSelectedAbsence(null);
    }
  }, [isCancelAnnual]);

  const refetchData = async () => {
    try {
      setIsLoadingFetched(true);
      const data = await fetchAttendanceByEmployeeId(
        token,
        employeeId,
        moment(new Date(dateFrom).getTime()).format("YYYY-MM-DD"),
        moment(new Date(dateTo).getTime()).format("YYYY-MM-DD"),
        persistClientName
      );
      setPersistAttendanceSummary(data?.response[0]);
      history.replace({
        ...location,
        state: { ...location.state, summary: data?.response[0] },
      });
      // console.log(data?.response[0], 'babi')
      setIsLoadingFetched(false);
    } catch (error) {
      console.log(error);
      setIsLoadingFetched(false);
    }
  };

  const handleCancelPermission = async () => {
    try {
      const data = await cancelAnnualLeave(token, selectedAttendanceId?._id, {
        endDate: moment
          .utc(selectedAttendanceId?.date)
          .add(1, "days")
          .format("YYYY-MM-DD"),
      });
      setIsCancelAnnual(false);
      setHasMounted(true);
      refetchData();
      dispatch(
        fetchAllDataEmployeeByRangeDateId(
          token,
          moment(dateFrom).format("MM-DD-YYYY"),
          moment(dateTo).format("MM-DD-YYYY"),
          clientCode
        )
      );
    } catch (error) {
      setIsCancelAnnual(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (hasMounted) {
      refetchData();
    }
  }, [dateFrom, dateTo, employeeId, persistClientName, hasMounted, token]);
  // console.log(hasMounted, 'turu')
  // console.log(persistAttendanceSummary, 'fetchedddd')

  const handleOpenDetailModal = (selectedRow) => {
    setSelectedAttendanceId(selectedRow);
    setSelectedAbsence(selectedRow);
  };

  const handleOpenModalCancel = async () => {
    setSelectedAbsence(null);
    setSwitchId(employeeId + Math.random());
    setIsCancelAnnual(true);
  };

  const cleanData = dataAbsenceClean();
  const rawData = dataAbsenceRaw();

  // if (!persistAttendanceSummary) {
  //   return null;
  // }

  return (
    <div className={Styles.Container}>
      <Breadcrumb>
        <Button
          onClick={() => history.push("/superadmin/attendance-data")}
          style={{ border: "none", textTransform: "none" }}
        >
          <span style={{ fontSize: 20, color: "#255bfc" }}>Attendance</span>
        </Button>
        <span style={{ fontSize: 20 }}>Detail Attendance</span>
      </Breadcrumb>
      <div className={Styles.ProfileCard}>
        <div className={Styles.ProfileLeft}>
          <Avatar
            src={persistAttendanceSummary?.profilePicture || ""}
            className={Styles.ProfilePicture}
          />
          <div className={Styles.ProfileDesc}>
            <span>{persistAttendanceSummary?.name}</span>
            <span>{persistAttendanceSummary?.jobTitle}</span>
            <span>
              Remaining Leaves :{" "}
              <label>{persistAttendanceSummary?.remainAnnualLeave}</label>
            </span>
          </div>
        </div>
        <div className={Styles.ProfileRight}>
          {detailAttendance?.map((each, idx) => (
            <div className={Styles.BoxAmount} key={idx}>
              <div className={Styles.AmountIcon}>
                <span>{each?.title}</span>
                <img src={each?.iconTitle} alt="icon" />
              </div>
              <div className={Styles.AmountDesc}>
                <span>{each?.value}</span>
                <img
                  src={each?.icon}
                  alt="icon-description"
                  onClick={() => setDetailModal(true)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div>
          <div className="container-profile-att-detail-menu">
            <div className="profile-att-detail-menu">
              <div className="contaiener-image-att-detail-menu">
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  src={ attendanceSummary?.profilePicture ? attendanceSummary?.profilePicture: "" }
                />
              </div>
              <div className="text-profile-detail-menu">
                <label>{attendanceSummary?.name}</label>
                <span>{attendanceSummary?.jobTitle}</span>
                <div className="company-profile-detail-menu">
                  <img src={image_company} alt="" />
                  <span>{attendanceSummary?.client}</span>
                </div>
              </div>
            </div>
            <div className="profile-att-summary">
              <div className="title-profile-att-summary">
                <span>Attendance Summary</span>
              </div>
              <div className="wrapper-item-row-att-summary">
                <div className="item-presence">
                  <div className="title-item-presence">
                    <span>Presence</span>
                  </div>
                  <div className="item-sub-presence">
                    <label htmlFor="">
                    {attendanceSummary?.attend} Days
                    </label>
                  </div>
                </div>
                <div className="item-leave">
                  <div className="title-item-leave">
                    <span>Leave</span>
                  </div>
                  <div className="item-sub-presence">
                    <label htmlFor="">
                    {attendanceSummary?.annualLeave} Days
                    </label>
                  </div>
                </div>
                <div className="item-sick">
                  <div className="title-item-sick">
                    <span>Sick</span>
                  </div>
                  <div className="item-sub-presence">
                    <label htmlFor="">
                    {attendanceSummary?.sick} Days
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {
              getPersistClient === "PT. CITRA NUSA INSAN CEMERLANG" ? (
            <div className="button-profile-att-summary">
            <ExportDataExcel
              data={dataToExport
              // .filter(
              //   (el) =>
              //     (
              //       el?.name?.toLowerCase().includes(listDataAttendance?.toLowerCase()) ||
              //       el.jobTitle?.toLowerCase().includes(listDataAttendance?.toLowerCase()) 
              //       ) 
              // )
              .map((el, ind) => ({
                "No. ": ind + 1,
                Nama: el?.name,
                Company: el?.client,
                Position: el.jobTitle,
                Nrk: el?.employeeId,
                Month: el.month,
                Year: el.year,
                "Date in": el.dateTimeIn,
                "Date out": el.dateTimeOut,
                "Time in": el.timeIn,
                "Time out": el.timeOut,
                "Coordinate Point": `${el.latitude} ${el.longitude}`,
                "Late" : el?.isLate,
                "Total Work Hour" : `${el?.totalWorkHour?.hours} Hours ${el?.totalWorkHour?.minutes} Minutes`,
                Region: el?.region,
                "Status Absence": el.status,
                "Remark": el.remark 
              }))}
            filename={`Attendance Report ${new Date().toLocaleDateString()}`}
            initial="Download Excel"
            />
            </div>
              ) : (
            <div className="button-profile-att-summary">
            <ExportDataExcel
              data={dataToExport
              .map((el, ind) => ({
                "No. ": ind + 1,
                Nama: el?.name,
                Company: el?.client,
                Position: el.jobTitle,
                Nrk: el?.employeeId,
                Month: el.month,
                Year: el.year,
                "Date in": el.dateTimeIn,
                "Date out": el.dateTimeOut,
                "Time in": el.timeIn,
                "Time out": el.timeOut,
                "Coordinate Point": `${el.latitude} ${el.longitude}`,
                "Late" : el?.isLate,
                "Total Work Hour" : `${el?.totalWorkHour?.hours} Hours ${el?.totalWorkHour?.minutes} Minutes`,
                Region: el?.region,
                "Status Absence": el.status,
                "Remark": el.remark 
              }))}

              
            filename={`Attendance Report ${new Date().toLocaleDateString()}`}
            initial="Download Excel"
            />
            </div>
              )
            }
          </div>
        </div> */}
      {/* <div style={{ color: "#C2C2C2F", paddingTop: 20 }}>
          <hr />
        </div> */}
      <SummaryCardSA
        startDate={new Date(dateFrom)}
        endDate={new Date(dateTo)}
        attendanceSummary={persistAttendanceSummary}
        cleanData={cleanData[0]}
        rawData={rawData[0]}
        setSelectedAbsence={setSelectedAbsence}
        isDownloading={isDownloading}
        setIsDownloading={setIsDownloading}
        overtimeEligibility={isOvertimeEligible}
        handleChangeOvertime={handleChangeOvertime}
        setDetailModal={setDetailModal}
        handleOpenModalCancel={handleOpenModalCancel}
        handleOpenDetailModal={handleOpenDetailModal}
        isLoading={isLoadingFetched}
      />
      {/* <BigCalendar
          startDate={new Date(dateFrom)}
          endDate={new Date(dateTo)}
          attendanceSummary={attendanceSummary}
        /> */}
      {detailModal && (
        <AnnualLeaveModal
          detailModal={detailModal}
          setDetailModal={setDetailModal}
          data={persistAttendanceSummary}
          dataAnnualLeave={dataAnnualLeave}
        />
      )}
      {isCancelAnnual && (
        <ConfirmationModalSA
          active={Boolean(isCancelAnnual)}
          onOpen={setIsCancelAnnual}
          type="cancelAnnualLeave"
          textType="Confirm Cancel"
          description="Are you sure you want to cancel your leave permission?"
          handleCancelPermission={handleCancelPermission}
        />
      )}
      {selectedAbsence && (
        <AbsenceDetailModal
          selectedAbsence={selectedAbsence}
          setSelectedAbsence={setSelectedAbsence}
        />
      )}

      {errorModal && (
        <ConfirmationModalSA
          active={Boolean(errorModal)}
          onOpen={setErrorModal}
          type="overtimeError"
          textType="Failed"
          description="Sorry can't do overtime because it's not overtime yet"
        />
      )}
    </div>
  );
}
