import React, { useState } from "react";
import Styles from "./SettingLoanMenu.module.css";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import EDIT_ICON from "../../../../assets/images/create.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ModalLoanSA from "../../_client/ModalLoanSA/ModalLoanSA";
import { useHistory } from "react-router";
export default function SettingLoanMenu({ data }) {
  const [isModalOpen, setIsModalOpen] = useState({});
  const history = useHistory();

  return (
    <div className={Styles.Container}>
      <div className={Styles.GroupControl}>
        <div className={Styles.ItemTop}>
          <div className={Styles.TextHeading}>
            <span>Detail Schema</span>
          </div>
          <div className={Styles.ButtonsHelper}>
            <div
              className={Styles.ButtonEdit}
              onClick={() => {
                history.push({
                  pathname: `/superadmin/loan-edit-scheme/${data?._id}`,
                  state: {
                    dataLoan: data,
                  },
                });
              }}
            >
              <span>Edit</span>
              <img src={EDIT_ICON} alt="edit-icon" />
            </div>

            <div
              className={Styles.ButtonDelete}
              onClick={() => setIsModalOpen(data)}
            >
              <span>Delete</span>
              <img src={DELETE_ICON} alt="delete-icon" />
            </div>
          </div>
        </div>

        <div className={Styles.BoxControl}>
          <div className={Styles.TextLabelTop}>
            <span>Name & Descriptions</span>
          </div>

          <div className={Styles.ValueTop}>
            <label>Scheme Name</label>
            <span>{data?.name}</span>
          </div>

          <div className={Styles.ValueTop}>
            <label>Description</label>
            <span>{data?.remark}</span>
          </div>
        </div>

        <div className={Styles.BoxRulesContainer}>
          <div className={Styles.LabelControl}>
            <span>Loan Rules</span>
          </div>

          <div className={Styles.BoxControlGroup}>
            {data?.loanRules?.map((each, idx) => (
              <div className={Styles.BoxGroup} key={idx}>
                <div className={Styles.Textlabel}>
                  <span>Rule {idx + 1}</span>
                </div>
                <div className={Styles.ValueGroup}>
                  <div className={Styles.Value}>
                    <label>Tenor</label>
                    <span>{each?.tenor} Month</span>
                  </div>
                  <div className={Styles.Value}>
                    <label>Maximum loan amount</label>
                    {each?.maxPlafond?.includes("%") ? (
                      <span>{each?.maxPlafond}</span>
                    ) : (
                      <>
                        {each?.maxPlafond === null ? (
                          <span>%</span>
                        ) : (
                          <span>
                            Rp.{parseFloat(each?.maxPlafond).toLocaleString()}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  <div className={Styles.Value}>
                    <label>Minimum loan amount</label>
                    <span>
                      Rp.{parseFloat(each?.minPlafond).toLocaleString()}
                    </span>
                  </div>
                  <div className={Styles.Value}>
                    <label>Rate Interest</label>
                    <span>{each?.rate} %</span>
                  </div>
                  <div className={Styles.Value}>
                    <label>DBR (Debt Burden Ratio)</label>
                    <span>{each?.dbr} %</span>
                  </div>
                  <div className={Styles.Value}>
                    <label>Minimum expiry before contract end</label>
                    <span>{each?.minContractRemainInMonth} %</span>
                  </div>
                  <div className={Styles.Value}>
                    <label>Minimum loan active after contract</label>
                    <span>{each?.minLengthOfJobInMonth} Month</span>
                  </div>

                  <div className={Styles.ValueAdminFee}>
                    <div className={Styles.Value}>
                      <label>Admin Fee</label>
                      <span></span>
                    </div>
                    <div className={Styles.Value}>
                      <div className={Styles.LeftContent}>
                        <ul>
                          <li>Baseline</li>
                          <li>Multiplier factor</li>
                          <li>Percentage</li>
                        </ul>
                      </div>
                      <div className={Styles.RightContent}>
                        <ul>
                          <li>
                            Rp.{each?.adminFee?.baseLine?.toLocaleString()}
                          </li>
                          <li>
                            Rp.
                            {each?.adminFee?.additional?.multiplyFactor?.toLocaleString()}{" "}
                            <ArrowForwardIcon
                              sx={{ width: 12, height: 12, color: "#616161" }}
                            />{" "}
                            Rp.
                            {each?.adminFee?.additional?.factorCost?.toLocaleString()}
                          </li>
                          <li>{each?.adminFee?.additional?.percentage} %</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={Styles.Value}>
                    <label>Permitted Cotracts</label>
                    <span>{each?.permittedContractType?.join(", ")}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={Styles.BoxControl}>
          <div className={Styles.TextLabel}>
            <span>Other Information</span>
          </div>

          <div className={Styles.Value}>
            <label>Active day before payroll</label>
            <span>{data?.activeLoanDate?.daysBeforePayroll}</span>
          </div>

          <div className={Styles.Value}>
            <label>Active day after payroll</label>
            <span>{data?.activeLoanDate?.daysAfterPayroll}</span>
          </div>
          <div className={Styles.Value}>
            <label>Maximum age</label>
            <span>{data?.maxAge}</span>
          </div>
          <div className={Styles.Value}>
            <label>Minimum age</label>
            <span>{data?.minAge}</span>
          </div>
          {/* <div className={Styles.Value}>
            <label>Permitted contracts</label>
            <span>{data?.permittedContractType?.join(",")}</span>
          </div> */}
        </div>
      </div>
      {isModalOpen._id && (
        <ModalLoanSA
          active={isModalOpen}
          onOpen={setIsModalOpen}
          description="Apakah anda yakin ingin menghapus skema ini?"
          label="Delete Schema"
          pathName="/superadmin/loan"
        />
      )}
    </div>
  );
}
