/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
// moment.locale("en-gb");

const useStyles = makeStyles((theme) => ({
  Modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 468,
    // height: 965,
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    border: "none",
    // boxShadow: 24,
    padding: 10,
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  ItemModalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .dummy": {
      display: "hidden",
      fontSize: 0,
    },
    "& .textAbsences": {
      color: "#404040",
      fontSize: 24,
      fontWeight: 500,
    },
    "& .icon": {
      backgroundColor: "transparent",
      border: "none",
      fontSize: 25,
      cursor: "pointer",
    },
  },
  ProfileInfo: {
    display: "flex",
    justifyContent: "space-between",
    "& .date": {
      display: "flex",
      flexDirection: "column",
      rowGap: 5,
    },
    "& .labelDate": {
      color: "#616161",
      fontSize: 17,
      fontWeight: 500,
    },
    "& .textDate": {
      color: "#404040",
      fontSize: 20,
      fontWeight: 500,
    },
    "& .boxPromoType": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 10,
      height: 30,
      backgroundColor: "#599EDB",
      borderRadius: 50,
    },
    "& .textPromo": {
      fontSize: 12,
      fontWeight: 500,
      color: "#FFFFFF",
    },
  },
  Wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  WrapperRight: {
    paddingRight: 30,
  },
  ClockInfo: {
    display: "flex",
    alignItems: "center",
    columnGap: 18,
    paddingTop: 24,
    "& .clockIn": {
      display: "flex",
      flexDirection: "column",
    },

    "& .lateTime": {
      display: "flex",
      flexDirection: "column",
    },
    "& .clockOut": {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
    },
    "& .label": {
      color: "#616161",
      fontSize: 17,
      fontWeight: 500,
    },
    "& .textClock": {
      color: "#404040",
      fontSize: 20,
      fontWeight: 500,
    },
    "& .labelLateTime": {
      color: "#D1421A",
      fontSize: 17,
      fontWeight: 500,
    },
    "& .textLateTime": {
      color: "#D1421A",
      fontSize: 20,
      fontWeight: 500,
    },
    "& .textHourLate": {
      color: "#D1421A",
      fontSize: 17,
      fontWeight: 500,
    },
  },
  PhotoInfo: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 24,
    rowGap: 24,
    "& .label": {
      color: "#404040",
      fontSize: 17,
      fontWeight: 500,
    },
    "& .image": {
      // height: 183,
      width: 224,
      display: "block",
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
  ReasonInfo: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 24,
    rowGap: 12,
  },
  MapInfo: {
    paddingTop: 24,
  },
  itemRow: {
    display: "flex",
    alignItems: "center",
    paddingTop: 12,
    paddingBottom: 12,
  },
}));

export default function ModalInformation({ isModalOpen, setIsModalOpen }) {
  //   const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  //   const totalLate = Math.floor(show.Late / 60);
  const handleClose = () => {
    setIsModalOpen(false);
  };
  // console.log(show, 'cek')
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Boolean(isModalOpen)}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className={classes.ItemModalTop}>
            <h6 className="dummy">dummy</h6>
            <div className="textAbsences">
              <span className="textAbsences">Detail Promo</span>
            </div>
            <button onClick={handleClose} className="icon">
              <CloseIcon
                style={{ fontSize: 30, marginTop: 10, paddingRight: 5 }}
              />
            </button>
          </div>
          {/* <div>
            <hr style={{ width: "100%", color: "#ECE6E6" }} />
          </div> */}
        </DialogTitle>
        <DialogContent>
          <div className={classes.ProfileInfo}>
            <div className={classes.WrapperLeft}>
              <div className="date">
                <label className="labelDate">Title</label>
                <span className="textDate">Promo Tahun Baru</span>
              </div>
              <div className="date" style={{ paddingTop: 20 }}>
                <label className="labelDate">Promo Category</label>
                <span className="textDate">Beli 2 Gratis 1</span>
              </div>
              <div className="date" style={{ paddingTop: 20}}>
                <label className="labelDate">Product</label>
                <span className="textDate">Mie Sedap Goreng</span>
              </div>
            </div>
            <div className={classes.WrapperRight}>
              <div className="date">
                <label className="labelDate">Promo Type</label>
                <div className="boxPromoType">
                  <span className="textPromo">Food Promo</span>
                </div>
              </div>{" "}
              <div className="date" style={{ paddingTop: 20}}>
                <label className="labelDate">Period</label>
                <span className="textDate">21 December 2021</span>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
