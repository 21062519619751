/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./ModalAddEmployee.css";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
  Avatar,
  Paper,
  Checkbox,
  Toolbar,
  TablePagination,
  Box,
  Tooltip,
  TableSortLabel,
} from "@mui/material";
import SearchbarSA from "../../../../utils/SearchbarSA/SearchbarSA";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { useHistory } from "react-router";
import { id } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllDataEmployeeInternal } from "../../../../../redux/actions/SuperAdmin/dataEmployeeInternalActions";


function createData(name, position, division) {
  return { name, position, division };
}
const rows = [
  createData("Bagus Setiawan", "Marketing Executive", "Marketing"),
  createData("Uzumaki Hinata", "Marketing Executive", "Marketing"),
  createData("Ilham Kusuma", "Marketing Executive", "Marketing"),
  createData("Pradipto Mark", "Marketing Executive", "Marketing"),
  createData("Valentina Sarjito", "Marketing Executive", "Marketing"),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Employee Name",
  },
  {
    id: "position",
    numeric: true,
    disablePadding: false,
    label: "Position",
  },
  {
    id: "division",
    numeric: true,
    disablePadding: false,
    label: "Division",
  },
  {
    id: "",
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const history = useHistory();
  // console.log(props.employeeInfo, 'cek from tabletoolbar')
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Add Staff
        </Typography>
      )}

      {
        numSelected > 0 ? (
          <Tooltip title="Add Selected">
            <Button
              style={{
                width: 150,
                height: 36,
                backgroundColor: "#1571DE",
                color: "#FFFFFF",
                textTransform: "none",
              }}
              onClick={() => history.push(`/profile-employee/${id}`)}
            >
              Add Marked
            </Button>
          </Tooltip>
        ) : null
      }
    </Toolbar>
  );
};

export default function ModalAddEmployee({ setModalAddEmployee, employeeInfo }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem('accessToken');
  const employee = useSelector((state) => state.employeeInternal.dataEmployeeInternal);
  const [selectedDropdown, setSelectedDropdown] = useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [newName, setNewName] = useState("");
  const [filterName, setFilterName] = useState("");
  const [filterBM, setFilterBM] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const wrappedEmployee = [employee].flat();

  useEffect(() => {
    dispatch(fetchAllDataEmployeeInternal(token))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setFilterBM(event.target.value)
  };
  const handleSubmitFilter = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event, name) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, Name) => {
    const selectedIndex = selected.indexOf(Name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, Name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (Name) => selected.indexOf(Name) !== -1;

  return (
    <div className="modalBackground-PEM">
      <div className="modalContainer-PEM">
        <div className="titleCloseBtn-SA">
          <div className="items__fill-SA">dummy</div>
          <div className="text__main-SA">
            <span>Add Staff</span>
          </div>
          <div className="item__button-right-SA">
            <button
              onClick={() => {
                setModalAddEmployee(false);
              }}
            >
              <CloseIcon
                style={{ fontSize: 30, marginTop: 4, paddingRight: 5 }}
              />
            </button>
          </div>
        </div>
        <div>
          <hr style={{ width: "100%", color: "#ECE6E6" }} />
        </div>
        <div className="body-modal-SEM">
          <div className="wrapper-item-top-SEM">
            {/* <div className="wrapper-user-detail-employee"></div> */}
          </div>
        </div>
        <div className="table-list-employee-PEM">
          <div className="item-top-modal-PEM">
            <SearchbarSA value={filterName} onChange={handleFilterByName} />
          </div>
          <div className="item-table-modal-PEM" style={{ paddingTop: 20, paddingBottom: 20}}>
          <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", backgroundColor: "#FAFAFA" }}>
            <EnhancedTableToolbar numSelected={selected.length} employeeInfo={employeeInfo} />
            <TableContainer>
              <Table
                sx={{ width: "100%" }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(employee, getComparator(order, orderBy))
                      .filter(
                        (el) =>
                          (el.Name?.toLowerCase().includes(
                            filterName.toLowerCase()
                          ) 
                          || el.Position?.toLowerCase().includes(filterName.toLowerCase()))
                          // && (selected ? el.Group === selected : true)
                      )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.Name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, row.Name)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // align="right"
                            // padding="none"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                alt={row.Name}
                                src=""
                                // variant="square"
                              />
                              <Typography variant="subtitle2" noWrap>
                                {row.Name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{row.Position}</TableCell>
                          <TableCell align="left">{row.Department}</TableCell>
                          <TableCell align="right">
                            <Button
                              style={{
                                width: 53,
                                height: 36,
                                backgroundColor: "#1571DE",
                                color: "#FFFFFF",
                                textTransform: "none",
                              }}
                            >
                              Add
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[3, 5]}
              component="div"
              count={wrappedEmployee.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
        </div>
        </div>
        {/* <div className="footer-modal-add-user-SA">
          <div className="wrapper-button-modal-add-user-SA">
            <Button
              style={{
                width: 59,
                height: 40,
                background: "#1571DE",
                borderRadius: 4,
                color: "white",
              }}
            >
              {" "}
              Save
            </Button>
            <Button
              style={{
                width: 75,
                height: 40,
                border: "1px solid #1571DE",
                borderRadius: 4,
                color: "#1571DE",
              }}
              onClick={() => {
                setModalAddEmployee(false);
              }}
            >
              {" "}
              Cancel
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
}
