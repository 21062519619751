/* eslint-disable no-unused-vars */
import React from "react";
import { Route, Switch } from "react-router-dom";
import AddClients from "../../pages/SuperAdmin/AddClients/AddClients";
import AttendanceAddScheme from "../../pages/SuperAdmin/AttendanceSA/Attendance-AddScheme/AttendanceAddScheme";
import AttendanceSA from "../../pages/SuperAdmin/AttendanceSA/AttendanceSA";
import AttendanceSettings from "../../pages/SuperAdmin/AttendanceSettings/AttendanceSettings";
import ClientSA from "../../pages/SuperAdmin/ClientSA/ClientSA";
import ClientSettings from "../../pages/SuperAdmin/ClientSettings/ClientSettings";
import ClientUserSettings from "../../pages/SuperAdmin/ClientUserSettings/ClientUserSettings";
import DashboardSA from "../../pages/SuperAdmin/DashboardSA";
import EditClient from "../../pages/SuperAdmin/EditClients/EditClients";
import LoanAddScheme from "../../pages/SuperAdmin/LoanAddScheme/LoanAddScheme";
import LoanSA from "../../pages/SuperAdmin/LoanSA/LoanSA";
import LoanSettings from "../../pages/SuperAdmin/LoanSettings/LoanSettings";
import ClientProfile from "../../pages/SuperAdmin/ClientsProfileSA/ClientProfile";
import LoanSettingsView from "../../pages/SuperAdmin/LoanSettingsView/LoanSettingsView";
import AttendanceSettingsView from "../../pages/SuperAdmin/AddClients/AddClientIdView/AttendanceSettingView/AttendanceSettingView";
import SettingViewLoan from "../../pages/SuperAdmin/LoanSettingMenu-Loan/SettingViewLoan";
import LoanEditScheme from "../../pages/SuperAdmin/LoanEditScheme/LoanEditScheme";
import SettingsAttendanceView from "../../pages/SuperAdmin/SettingAttendanceView/SettingsAttendanceView";
import AttendanceEditScheme from "../../pages/SuperAdmin/AttendanceSA/AttendanceEditScheme/AttendanceEditScheme";
// import WorkspaceSettingsClient from "../../pages/SuperAdmin/WorkspaceSettingsClient/WorkspaceSettingsClient";
// import WorkspaceSettings from "../../pages/SuperAdmin/Workspace/WorkspaceSettings/WorkspaceSettings";
// import AddWorkspaceScheme from "../../pages/SuperAdmin/Workspace/AddWorkspaceScheme/AddWorkspaceScheme";
import EditWorkspaceScheme from "../../pages/SuperAdmin/AddClients/AddClientIdView/EditWorkspaceScheme/EditWorkspaceScheme";
import ViewWorkspaceScheme from "../../pages/SuperAdmin/AddClients/AddClientIdView/ViewWorkspaceScheme/ViewWorkspaceSchem";
import UserSA from "../../pages/SuperAdmin/User/UserSA/UserSA";
import AddUserSA from "../../pages/SuperAdmin/User/AddUserSA/AddUserSA";
import EmployeeSA from "../../pages/SuperAdmin/Employee/EmployeeSA/EmployeeSA";
import AddClientsId from "../../pages/SuperAdmin/AddClients/AddClientsId/AddClientsId";
import AddClientsIdEmployee from "../../pages/SuperAdmin/AddClients/AddClientsId/AddClientsidEmployee";
import AddClientsIdLoan from "../../pages/SuperAdmin/AddClients/AddClientsId/AddClientsIdLoan";
import AddClientsIdLoanView from "../../pages/SuperAdmin/AddClients/AddClientIdView/AddClientsIdLoanView";
import AddClientsIdAttendance from "../../pages/SuperAdmin/AddClients/AddClientsId/AddClientsIdAttendance";
import AddClientsIdWorskpace from "../../pages/SuperAdmin/AddClients/AddClientsId/AddClientsIdWorskpace";
// import ViewWorkspaceAdmin from "../../pages/SuperAdmin/Workspace/ViewWorkspaceAdmin/ViewWorkspaceAdmin";
import AddEmployeeSA from "../../pages/SuperAdmin/AddClientsIdEmployee/AddEmployeeSA";
import AttendanceDetailMenu from "./_attendance/attendance-detail-sa/AttendanceDetailMenu";
import LoanData from "../../pages/SuperAdmin/LoanData/LoanData";
import AttendanceData from "../../pages/SuperAdmin/AttendanceSA/AttendanceData/AttendanceData";
import ProfileEmployeeSA from "../../pages/SuperAdmin/Employee/ProfileEmployeeSA/ProfileEmployeeSA";
import EditEmployeeSA from "../../pages/SuperAdmin/Employee/EditEmployeeSA/EditEmployeeSA";
import BPJSSA from "../../pages/SuperAdmin/BPJS/BPJSSA/BpjsSA";
import PaySlipSA from "../../pages/SuperAdmin/Payslip/PaySlipSA/PaySlipSA";
import PaySlipDetailSA from "../../pages/SuperAdmin/Payslip/PaySlipDetailSA/PaySlipDetailSA";
import FAQSA from "../../pages/SuperAdmin/FAQ/FAQSA";
import EditFAQSA from "../../pages/SuperAdmin/FAQ/EditFAQSA/EditFAQSA";
import AddFAQSA from "../../pages/SuperAdmin/FAQ/AddFAQSA/AddFAQSA";
import DetailFAQSA from "../../pages/SuperAdmin/FAQ/DetailFAQSA/DetailFAQSA";
import InformationSA from "../../pages/SuperAdmin/Information/InformationSA";
import AddInformation from "../../pages/SuperAdmin/Information/AddInformation/AddInformationSA";
import DetailInformation from "../../pages/SuperAdmin/Information/DetailInformation/DetailInformation";
import ProfileSA from "../../pages/SuperAdmin/ProfileSA/ProfileSA";
import EditProfileSA from "../../pages/SuperAdmin/ProfileSA/EditProfile/EditProfileSA";
import SettingsSA from "../../pages/SuperAdmin/Settings/SettingsSA";
import ClientDirSA from "../../pages/SuperAdmin/ClientDirSA/ClientDirSA";
import EditClientIdView from "../../pages/SuperAdmin/AddClients/EditClientIdView/EditClientIdView";
import AttendanceClientChoosePage from "../../pages/SuperAdmin/AddClients/AddClientsId/AttendanceClientChoose";
import EditDraftFAQSA from "../../pages/SuperAdmin/FAQ/EditDraftFAQSA/EditDraftFAQSA";
import DetailDraftSA from "../../pages/SuperAdmin/FAQ/DetailDraftSA/DetailDraftSA";
import LoanDataDetail from "../../pages/SuperAdmin/LoanDataDetails/LoanDataDetail";
import DetailEmployeePayslipSA from "../../pages/SuperAdmin/Employee/DetailEmployeePayslipSA/DetailEmployeePayslipSA";
import ApprovalLoan from "../../pages/SuperAdmin/ApprovalLoan/ApprovalLoan";
// import { RejectedLoanMenu } from "../_approval-rejected-page";
// import RejectedPageMenu from "../../pages/SuperAdmin/RejectedPage/RejectedPage";
// import SnackbarComponent from "../utils/Snackbar/SnackbarComponent";
import { useRouteMatch } from 'react-router-dom';
import PayslipPersonDetail from "../../pages/SuperAdmin/Payslip/PayslipPersonDetail/PayslipPersonDetail";
import EditInformation from "../../pages/SuperAdmin/Information/EditInformation/EditInformation";
// import ModalBPJS from "./_bpjs/BPJSMenu/ModalBPJS/ModalBPJS";
import PayslipUploadMenu from "../../pages/SuperAdmin/Payslip/PayslipUploadMenu/PayslipUploadMenu";
import NotFound404 from "../_pageNotFound/NotFound404";
import AddClientsIdLoanChoosePage from "../../pages/SuperAdmin/AddClients/AddClientsId/AddClientsIdLoanChoose";
import RejectedPage from "../../pages/SuperAdmin/RejectedPage/RejectedPage";
import AddWorkspaceScheme from "../../pages/SuperAdmin/AddClients/AddClientIdView/AddWorkspaceSetting/AddWorkspaceSetting";
import WorkspaceClientChoose from "../../pages/SuperAdmin/AddClients/AddClientsId/WorkspaceClientChoose";
import Employee from "../../pages/External/Employee/Employee";
import DetailInformationSA from "./_information/DetailInformationSA/DetailInformationSA";
import { AppAttendanceDetailMenu, AppAttendanceSettingView } from "./_attendance";
import AttendanceViewScheme from "../../pages/SuperAdmin/AttendanceSA/AttendanceViewScheme/AttendanceViewScheme";
import DatabaseSA from "../../pages/SuperAdmin/Database/DatabaseSA";
import AddLocationSA from "../../pages/SuperAdmin/Database/AddLocationSA/AddLocationSA";
import ActivitySA from "../../pages/SuperAdmin/Activity/ActivitySA";
import LocationDetailSA from "../../pages/SuperAdmin/Database/LocationDetailSA/LocationDetailSA";
import EditLocationSA from "../../pages/SuperAdmin/Database/EditLocation/EditLocationSA";
import AddBankSA from "../../pages/SuperAdmin/Database/AddBankSA/AddBankSA";
import BankDetailSA from "../../pages/SuperAdmin/Database/BankDetailSA/BankDetailSA";
import EditBankSA from "../../pages/SuperAdmin/Database/EditBankSA/EditBankSA";
import Induction from "../../pages/SuperAdmin/Induction/Induction";
import InductionDetail from "../../pages/SuperAdmin/Induction/InductionDetail/InductionDetail";
import InductionForm from "../../pages/SuperAdmin/Induction/InductionForm/InductionForm";
import InductionFormEdit from "../../pages/SuperAdmin/Induction/InductionFormEdit/InductionFormEdit";
import GadaiDetail from "../../pages/SuperAdmin/DetailGadai/DetailGadai";
import ViewRequestSA from "../../pages/SuperAdmin/Employee/ViewRequestSA/ViewRequestSA";
import SetLoanSA from "../../pages/SuperAdmin/ClientSettings/SetLoan/SetLoan";
import SetAttendanceSA from "../../pages/SuperAdmin/ClientSettings/SetAttendance/SetAttendance";
import ClientSetLoanView from "../../pages/SuperAdmin/ClientSettings/LoanView/LoanView";
import ClientSetAttendanceView from "../../pages/SuperAdmin/ClientSettings/AttendanceView/AttendanceView";
import DetailUserSA from "../../pages/SuperAdmin/User/DetailUserSA/DetailUserSA";
import HolidaySettingsPage from "../../pages/SuperAdmin/HolidaySettings/HolidaySettings";
import LeaveSchema from "../../pages/SuperAdmin/LeaveSchema/LeaveSchema";
import SetLeaveSchema from "./_client/ClientSettings/setLeaveSchema/SetLeaveSchema";
import TableWorkOn from "../../pages/SuperAdmin/TableWorkOn/TableWorkOn";
import AddLeaveSchema from "../../pages/SuperAdmin/LeaveSchemaForm/LeaveSchemaForm";
import LeaveSchemaForm from "../../pages/SuperAdmin/LeaveSchemaForm/LeaveSchemaForm";
import LeaveSchemaFormDetail from "../../pages/SuperAdmin/LeaveSchemaFormDetail/LeaveSchemaFormDetail";
import LeaveSchemaFormEdit from "../../pages/SuperAdmin/LeaveSchemaFormEdit/LeaveSchemaFormEdit";

// import TopNavSA from "./TopNavSA/TopNavSA";
// import { useLocation } from "react-router";
// import { useParams } from "react-router";

const RoutesSA = () => {
  const { path } = useRouteMatch();
  const token = localStorage.getItem('acessToken');
  return (
    <div>
      <Switch>
        <Route exact path={`${path}`} component={DashboardSA} />
        <Route path={`${path}/client`} component={ClientSA} />
        <Route path={`${path}/user-settings`} component={ClientUserSettings} />
        <Route path={`${path}/client-settings/:id`} component={ClientSettings} />
        <Route path={`${path}/edit-clients/:id`} component={EditClient} />
        <Route path={`${path}/profile-clients/:id`} component={ClientProfile} />
        <Route path={`${path}/add-clients`} component={AddClients} />
        <Route path={`${path}/add-clients-id/:id`} component={AddClientsId} />
        <Route path={`${path}/add-clients-id-employee`} component={AddClientsIdEmployee} />
        <Route path={`${path}/set-loan-scheme/:id`} component={SetLoanSA} />
        <Route path={`${path}/set-leave-scheme/:id`} component={SetLeaveSchema} />
        <Route path={`${path}/set-attendance-scheme/:id`} component={SetAttendanceSA} />
        <Route path={`${path}/client-set/:clientId/loan-view/:id`} component={ClientSetLoanView} />
        <Route path={`${path}/client-set/:clientId/attendance-view/:id`} component={ClientSetAttendanceView} />


        {/*
        un-comment after wiring 
         <Route path={`${path}/add-clients-id-attendances/:id`} component={AddClientsIdAttendance}/>
        <Route path={`${path}/add-clients-id-att-view/:clientId/:attendanceId`} component={AttendanceSettingsView}/>
        <Route path={`${path}/add-clients-id-att-edit/:clientId/:attendanceId`} component={EditClientIdView}/>
        <Route path={`${path}/add-clients-id-workspace/:id`} component={AddClientsIdWorskpace}/>
        <Route path={`${path}/add-clients-id-workspace-addScheme/:clientId`} component={AddWorkspaceScheme}/>
        <Route path={`${path}/add-clients-id-workspace-view/:clientId/:workspaceId`} component={ViewWorkspaceScheme}/>
        <Route path={`${path}/add-clients-id-workspace-edit/:clientId/:workspaceId`} component={EditWorkspaceScheme}/>
        <Route path={`${path}/add-clients-id-workspace-not-set/:id`} component={WorkspaceClientChoose}/>
        <Route path={`${path}/add-clients-id-attendances-not-set/:id`} component={AttendanceClientChoosePage}/>
        <Route path={`${path}/client-directory/:id`} component={ClientDirSA} /> */}


        {/* users */}
        <Route path={`${path}/user`} component={UserSA} />
        <Route path={`${path}/user-detail/:id`} component={DetailUserSA} />
        {/* <Route path={`${path}/add-user`} component={AddUserSA} /> */}
        {/* <Route path={`${path}/attendance-settings`} component={AttendanceSettings} /> */}


        {/* <Route
          path={`${path}`/attendance-setting-view"
          component={AttendanceSettingsView}
        /> */}

        {/* 
        un-comment after wiring 
        <Route path={`${path}/setting-attendance-view/:id`} component={SettingsAttendanceView}/>
        <Route path={`${path}/attendance-edit-scheme/:id`} component={AttendanceEditScheme}/> */}


        {/* <Route path={`${path}/workspace`} component={WorkspaceSettings} />
        <Route path={`${path}/workspace-settings`} component={WorkspaceSettingsClient} />
        <Route path={`${path}/add-workspace-scheme`} component={AddWorkspaceScheme} />
        <Route path={`${path}/edit-workspace-scheme`} component={EditWorkspaceScheme} />
        <Route path={`${path}/view-workspace-scheme`} component={ViewWorkspaceScheme} />
        <Route path={`${path}/view-workspace-scheme-admin`} component={ViewWorkspaceAdmin}/> */}

        {/* Attendance */}

        <Route path={`${path}/attendance`} component={AttendanceSA} />
        <Route path={`${path}/attendance-add-scheme`} component={AttendanceAddScheme} />
        <Route path={`${path}/attendance-edit-scheme/:id`} component={AttendanceEditScheme} />
        <Route path={`${path}/setting-attendance-view/:id`} component={AttendanceViewScheme} />
        <Route path={`${path}/attendance-detail-attendance/:id`} component={AttendanceDetailMenu} />
        <Route path={`${path}/attendance-data`} component={AttendanceData} />

        {/* database */}
        <Route path={`${path}/database`} component={DatabaseSA} />
        <Route path={`${path}/add-location`} component={AddLocationSA} />
        <Route path={`${path}/detail-location/:id`} component={LocationDetailSA} />
        <Route path={`${path}/edit-location/:id`} component={EditLocationSA} />
        <Route path={`${path}/add-bank`} component={AddBankSA} />
        <Route path={`${path}/detail-bank/:id`} component={BankDetailSA} />
        <Route path={`${path}/edit-bank/:id`} component={EditBankSA} />




        {/* employee */}

        {/* un-comment after wiring  */}
        <Route path={`${path}/employee`} component={EmployeeSA} />
        <Route path={`${path}/add-employee`} component={AddEmployeeSA} />
        <Route path={`${path}/profile-employee/:id`} component={ProfileEmployeeSA} />
        <Route path={`${path}/edit-employee/:id`} component={EditEmployeeSA} />
        <Route path={`${path}/employee-payslip-detail/:id`} component={DetailEmployeePayslipSA} />
        <Route path={`${path}/view-request/:id`} component={ViewRequestSA} />

        {/* bpjs */}
        {/* 
        un-comment after wiring 
        <Route path={`${path}/bpjs`} component={BPJSSA} /> */}


        {/* <Route path={`${path}/bpjs/:id`} component={ModalBPJS} /> */}

        {/* payslip */}
        <Route path={`${path}/payslip`} component={PaySlipSA} />
        <Route path={`${path}/payslip-detail/:id`} component={PaySlipDetailSA} />
        <Route path={`${path}/payslip-person-detail/:id`} component={PayslipPersonDetail} />
        <Route path={`${path}/payslip-upload-menu`} component={PayslipUploadMenu} />

        {/* activity */}
        <Route path={`${path}/activity`} component={ActivitySA} />


        {/* FAQ */}
        <Route path={`${path}/faq`} component={FAQSA} />
        <Route path={`${path}/edit-faq/:id`} component={EditFAQSA} />
        <Route path={`${path}/edit-faq-draft/:id`} component={EditDraftFAQSA} />
        <Route path={`${path}/add-faq`} component={AddFAQSA} />
        <Route path={`${path}/detail-faq/:id`} component={DetailFAQSA} />
        <Route path={`${path}/detail-draft/:id`} component={DetailDraftSA} />

        {/* Loan */}
        <Route path={`${path}/loan`} component={LoanSA} />
        {/* <Route path={`${path}/loan-settings-view`} component={LoanSettingsView} /> */}
        <Route path={`${path}/loan-setting-view/:id`} component={SettingViewLoan} />
        <Route path={`${path}/loan-edit-scheme/:id`} component={LoanEditScheme} />
        <Route path={`${path}/ebp`} component={LoanData} />
        <Route path={`${path}/loan-settings`} component={LoanSettings} />
        <Route path={`${path}/add-clients-id-loan/:id`} component={AddClientsIdLoan} />
        <Route path={`${path}/add-clients-id-loan-not-set/:id`} component={AddClientsIdLoanChoosePage} />
        <Route path={`${path}/add-clients-id-loan-view/:id/:loanId`} component={AddClientsIdLoanView} />
        <Route path={`${path}/loan-add-scheme`} component={LoanAddScheme} />
        <Route path={`${path}/loan-data-details/:id`} component={LoanDataDetail} />
        <Route path={`${path}/pawn-detail/:id`} component={GadaiDetail} />


        {/* { token ? (
        <Route path={`${path}/approved-first-respondent`} component={ApprovalLoan}/>
        ) : (
        <Route path={`${path}/approved-first-respondent`} component={ApprovalLoan}/>
        )}
        { token ? (
        <Route path={`${path}/rejected`} component={RejectedPage}/>
        ) : (
        <Route path={`${path}/rejected`} component={RejectedPage}/>
        )} */}

        {/* Information */}

        <Route path={`${path}/information`} component={InformationSA} />
        <Route path={`${path}/add-information`} component={AddInformation} />
        <Route path={`${path}/edit-information/:id`} component={EditInformation} />
        <Route path={`${path}/detail-information/:id`} component={DetailInformation} />


        {/* Induction  */}
        <Route path={`${path}/induction`} component={Induction} />
        <Route path={`${path}/detail-induction/:id`} component={InductionDetail} />
        <Route path={`${path}/edit-induction/:id`} component={InductionFormEdit} />
        <Route path={`${path}/add-induction`} component={InductionForm} />

        {/* Holiday Settings */}
        <Route path={`${path}/holiday-settings`} component={HolidaySettingsPage} />


        {/* Work On  */}
        <Route path={`${path}/work-on`} component={TableWorkOn} />

        {/* Leave Schema */}
        <Route path={`${path}/leave`} component={LeaveSchema}/>
        <Route path={`${path}/add-leave-scheme`} component={LeaveSchemaForm}/>
        <Route path={`${path}/leave-scheme-view/:id`} component={LeaveSchemaFormDetail}/>
        <Route path={`${path}/leave-scheme-edit/:id`} component={LeaveSchemaForm}/>



        {/* Profile  */}
        {/* 
        un-comment after wiring 
        <Route path={`${path}/profile`} component={ProfileSA} />
        <Route path={`${path}/edit-profile`} component={EditProfileSA} /> */}

        {/* Settings */}
        {/* 
        un-comment after wiring 
        <Route path={`${path}/settings`} component={SettingsSA} /> */}

        <Route path="*" component={NotFound404}>
        </Route>
      </Switch>
    </div>
  );
};

export default RoutesSA;
