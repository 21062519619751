import React from "react";
import "../../../../src/assets/css/storePages.css";
import { Grid, Container,Button } from "@mui/material";
import { Helmet } from "react-helmet";
// import {
//   AppRecentStore,
// } from "../../../components/_store/index";
import { AppStoreWithDownload } from '../../../components/External/'
import { useHistory } from 'react-router'

const Store = () => {
  const history = useHistory();
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Store</title>
      </Helmet>
      <div className="wrapperPageStore">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#000000",fontSize: 40 }}
        >
          Store
        </h2>
        <div className="wrapperPagesStoreReport">
          <Button className="wrapperPagesStoreReport" onClick={() => history.push('/store-report-external/')}>
            <span className="textReportStore">Report</span>
            <i className="bx bxs-printer"></i>
          </Button>
        </div>
      </div>

      <Grid>
        {/* <Grid item xs={12} md={12} lg={12}>
          <AppRecentStore />
        </Grid> */}
        <Grid item xs={12} md={12} lg={12}>
          <AppStoreWithDownload />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Store;
