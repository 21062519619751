/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { filter } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import circle_image_bpjs from "../../../../assets/images/circle-image-bpjs.png";
import logo_bpjs_modal from "../../../../assets/images/bpjs.png";
import {
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Select,
  FormControl,
  MenuItem,
  styled,
  Autocomplete,
  TextField
} from "@mui/material";

import USERLIST from "../../../SuperAdmin/_user/TableUsers/_mocks/user";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import { UseCompanyName } from "../../../utils/hooks";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllBpjsData, fetchBpjsDataById } from "../../../../redux/actions/SuperAdmin/bpjsActions";
import { useHistory, useParams } from "react-router";
import LoadingAnimation from '../../../utils/LoadingAnimation/LoadingAnimation'
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import UserListHead from "../../../SuperAdmin/_user/TableUsers/UserListHead";
import ModalBPJS from "../../../SuperAdmin/_bpjs/BPJSMenu/ModalBPJS/ModalBPJS";
import { fetchAllBpjsDataExternal } from "../../../../redux/actions/External/bpjsExternalActions";

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
  },
});

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8
    },
  },
});

const TABLE_HEAD = [
  { id: "Name", label: "Employee Name", alignRight: false },
  { id: "Position", label: "Position", alignRight: false },
  { id: "Client", label: "Company", alignRight: false },
  { id: "" },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function BPJSMenuSA() {
  const { id } = useParams();
  const history =useHistory();
  const clientName = localStorage.getItem('client');
  const { allBpjsExternal, isLoadingBpjs } = useSelector((state) => state.bpjsExternal);
//   const dataBpjs = useSelector((state) => state.bpjs.allBpjs);
//   const { loadingDataBpjs, errorMessageBpjs } = useSelector((state) => state.bpjs)
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [selected, setSelected] = useState("PT. Permata Indo Sejahtera");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [bpjsOpen, setBpjsOpen] = useState({ });
  const [listDataBpjs, setListDataBpjs] = useState("");

  // useEffect(() => {
  //   dispatch(fetchAllBpjsDataExternal(token, clientName));
  // }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );

  const handleSearch = (event) => {
    setListDataBpjs(event.target.value);
  };

  return (
    <div className="container-bpjs-menu">
      <div className="wrapper-bpjs-menu">
        <div className="wrapper-box-top-bpjs">
          <div className="box-search-bpjs-menu">
            <SearchbarSA value={listDataBpjs} onChange={handleSearch} />
          </div>
          <div className="year-picker-bpjs" style={{ width: 176 }}>
          {/* <Autocomplete
              disablePortal
              options={getCompany}
              value={selected}
              inputValue={selected}
              onInputChange={(e, value) => setSelected(value)}
              renderInput={(params) => <MuiTextField {...params} size="small" sx={{ width: 230}}/>}
            /> */}
            {/* <FormControl fullWidth size="small" style={{ width: "100%" }}>
              <Select
                className={classes.selectBorder}
                value={selected}
                displayEmpty
                onChange={(e) => setSelected(e.target.value)}
              >
                <MenuItem value="">Select Group</MenuItem>
                {getCompany.map((option, el) => (
                  <MenuItem
                    key={el}
                    onClick={() => setSelectedGroup(option)}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
        </div>
        { isLoadingBpjs ? (
          <div style={{ display:"flex", alignItems: 'center', justifyContent: 'center', marginTop: 100}}>
            <div style={{ width: 200}}>
              <LoadingAnimation />
            </div>
          </div>
        ) : (
          <div className="wrapper-table-box-search-bpjs-menu">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table
                sx={{
                  width: "100%",
                  maxHeight: "100%",
                  backgroundColor: "#fafafa",
                  borderRadius: 4,
                }}
              >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={allBpjsExternal.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(allBpjsExternal, getComparator(order, orderBy))
                    .filter(
                      (el) =>
                        el.Name?.toLowerCase().includes(
                          listDataBpjs?.toLowerCase()
                        ) ||
                        el.Client?.toLowerCase().includes(
                          listDataBpjs?.toLocaleLowerCase()
                        ) ||
                        el.StatusRequested?.toLowerCase().includes(
                          listDataBpjs?.toLocaleLowerCase()
                        )
                    )
                    .slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                    .map((data, id) => {
                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell></TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                src={
                                  data.ProfilePicture ? data.ProfilePicture : ""
                                }
                              >
                                {data?.Name.substr(0, 1)}
                              </Avatar>
                              <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize'}}>
                                {data.Name ? data?.Name.toLowerCase() : '-'}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                          <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize'}}>
                              {data.Position ? data?.Position.toLowerCase() : "-"}
                              </Typography>
                            </TableCell>
                          <TableCell align="left">{data.Client ? data?.Client : ""}</TableCell>
                          <TableCell align="right">
                            <Button
                              style={{
                                textTransform: "none",
                                fontSize: 16,
                                fontWeight: 500,
                              }}
                              onClick={() => setBpjsOpen(data)}
                              // onClick={() => history.push({
                              //   dispatch(fetchBpjsDataById(token, data._id))
                              // })}
                            >
                              See Details
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          {bpjsOpen._id && (
            <ModalBPJS datas={bpjsOpen} setBpjsOpen={setBpjsOpen}/>
          )}
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={allBpjsExternal.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        )}
       
      </div>
    </div>
  );
}
