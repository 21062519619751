import React, { useEffect } from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppEditDraftFAQ } from "../../../../components/SuperAdmin/_faq";
import { fetchDraftById } from "../../../../redux/actions/SuperAdmin/draftActions";
import { useHistory, useLocation, useParams } from "react-router";
import { useDispatch } from "react-redux";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
export default function EditDraftFAQSA() {
  const history = useHistory();
  const location = useLocation();
  const draftById = location.state.dataDrafts;
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchDraftById(token, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(draftById, 'cek draft by id')
  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | FAQ</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/faq")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>FAQ</span>
            </Button>
            <span style={{ fontSize: 20 }}>Edit FAQ</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppEditDraftFAQ draftById={draftById} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
