import { useHistory, useParams } from 'react-router-dom';
import Styles from './LayoutDetailNotification.module.css';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import fetchClientCode from '../../../../async/client/fetchClientCode';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { AddCircle, Business, ControlPoint, Delete } from '@mui/icons-material';
import NOTIFICATION_IMG from "../../../../assets/images/notification.png"
import CustomTable from '../../../CustomTable/CustomTable';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ModalFeaturePreview from '../ModalFeaturePreview/ModalFeaturePreview';
import { updateNotificationById } from '../../../../redux/actions/SuperAdmin/informationActions';

export default function LayoutDetailNotification({data, notifInformation, page, setPage, limit, setLimit, totalPage, setTotalPage, setTrigger}) {
    const token = localStorage.getItem('accessToken');
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [dataClientCodes, setDataClientCodes] = useState([]);

    const [selectedPushNotification, setSelectedPushNotification] = useState(null);
    const [modalPreview, setModalPreview] = useState(null);
    const [pushNotification, setPushNotification] = useState([]);
    const [error, setError] = useState("")
    const [lastID, setLastID] = useState(1)
    const [deletedSchedule, setDeleteSchedule] = useState([])

    useEffect(() => {
        if (notifInformation?.length > 0) {
            const mapNotif = notifInformation.map((value, index) => {
                return {
                    ...value,
                    id: index + 1,
                    date: value?.date ? dayjs(value?.date) : null,
                    time: value?.date ? moment(new Date(value?.date)).format('HH:mm:ss') : null,
                };
            });
            setDeleteSchedule([])
            setPushNotification(mapNotif);
            setLastID(notifInformation?.length > 0 ? notifInformation.length + 1 : 1)
        }
    }, [notifInformation]);

    const rowSchedule = useMemo(() => {
        if (pushNotification?.length > 0){
            return pushNotification.map((value, index) => ({
                ...value,
                no: limit * (page - 1) + (index + 1),
            }))
        }
    }, [pushNotification, page, limit])

    const fixedClients = useMemo(() => {
        let pre = [
            { codes: 'ALL', name: 'ALL' },
            { codes: 'Tes', name: 'Client Tester' },
        ];
        return pre.concat(dataClientCodes);
    }, [dataClientCodes]);

    const dataClients = useMemo(() => {
        return fixedClients.filter((el) => {
            return data?.clientCode?.find((element) => {
                return element === el?.codes;
            });
        });
    }, [data, fixedClients]);

    useEffect(() => {
        (async () => {
            try {
                const { response } = await fetchClientCode(token);
                setDataClientCodes(response);
            } catch (error) {
                console.log(error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const entryHour = (timeString) => {
        const today = new Date();
        const arrEntryHour = timeString?.split(':');
        today.setUTCHours(+arrEntryHour[0] - 7, +arrEntryHour[1], +arrEntryHour[2], 0);
        return today;
    };

    const capitalizedClientName = (clientName) => {
        return clientName
            ?.toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
            .replace('Pt', 'PT')
            .replace('Cv', 'CV');
    };

    const handleItemSchedule = useCallback((typeHandle, valueSch) => {
        if (typeHandle === 'ADD') {
            setPushNotification((prev) => {
                return [
                    ...prev,
                    {
                        id: lastID,
                        date: null,
                        time: null,
                        // date: dayjs(),
                        // time: new Date().toLocaleTimeString('en', { hour12: false }),
                        status: 'Waiting',
                        action: 'ADD'
                    },
                ];
            });
            setLastID(lastID + 1)
        } else if (typeHandle === 'EDIT') {
            setPushNotification((prev) => {
                const idx = prev?.findIndex((f) => f?.id === valueSch?.id)
                prev[idx] = {
                    ...valueSch,
                    action: valueSch?.action === 'ADD' ? 'ADD' : 'UPDATE'
                };
                return [...prev]
            });
        } else if (typeHandle === 'DELETE') {
            if (valueSch?._id){
                setDeleteSchedule((prev) => {
                    return [...prev, {
                        ...valueSch,
                        action: 'DELETE'
                    }]
                })
            }
            setPushNotification((prev) => {
                const arr = prev?.filter((f) => f?.id !== valueSch?.id);
                return [...arr]
            });
        }
    }, [lastID]);

    const activeButtonLayout = useMemo(() => {
        if (pushNotification?.length > 0){
            const existAction = pushNotification.some((f) => f?.action === "ADD" || f?.action === "UPDATE")
            
            if (!existAction && deletedSchedule?.length > 0){
                return true
            } else {
                return existAction
            }
        } else {
            return false
        }
    }, [pushNotification, deletedSchedule])

    const handleSubmit = () => {
        // CHECK
        const currentDate = new Date();

        for (let item of pushNotification){
            if (!item?.date || !item?.time){
                setError(`Select the date/time${item?.no ? ` for schedule number ${item?.no} ` : ''}.`)
                return
            }
            const inputDate = new Date(item?.date);    
            const [inputHour, inputMinute, inputSecond] = item?.time?.split(":").map(Number);
            if (
                item?.status === "Waiting" &&
                inputDate.toDateString() === currentDate.toDateString() &&
                (inputHour < currentDate.getHours() || 
                (inputHour === currentDate.getHours() && inputMinute < currentDate.getMinutes()) || 
                (inputHour === currentDate.getHours() && inputMinute === currentDate.getMinutes() && inputSecond < currentDate.getSeconds()))
            ) {
                setError(`Cannot select a date/time in the past${item?.no ? ` for schedule number ${item?.no} ` : ''}!`);
                return;
            }
        }

        setError("")

        //FORM
        const submitData = {
            clientCode: data?.clientCode || [],
            title: data?.title,
            description: data?.description,
            type: data?.type
        }

        let submitSchedule = []

        if (pushNotification?.length > 0) {
            const notif = pushNotification?.filter((f) => f?.action === 'ADD' || f?.action === 'UPDATE')
            notif?.forEach((scheduleItem, indexSchedule) => {
                const [hours, minutes, seconds] = scheduleItem?.time?.split(':').map(Number);
                const fixedDate = new Date(scheduleItem?.date);

                fixedDate.setHours(hours);
                fixedDate.setMinutes(minutes);
                fixedDate.setSeconds(seconds);

                let scheduleData = {
                    date: fixedDate?.toISOString()
                }
                
                if (scheduleItem?.action === 'UPDATE'){
                    scheduleData = {
                        ...scheduleData,
                        _id: scheduleItem?._id,
                        action: "UPDATE"
                    }
                }
                submitSchedule.push(scheduleData)
            });
        }
        if (deletedSchedule?.length > 0){
            deletedSchedule?.forEach((scheduleItem, indexSchedule) => {
                const [hours, minutes, seconds] = scheduleItem?.time?.split(':').map(Number);
                const fixedDate = new Date(scheduleItem?.date);

                fixedDate.setHours(hours);
                fixedDate.setMinutes(minutes);
                fixedDate.setSeconds(seconds);

                submitSchedule.push({
                    date: fixedDate?.toISOString(),
                    _id: scheduleItem?._id,
                    action: "DELETE"
                })
            })
        }
        
        dispatch(updateNotificationById(token, id, {...submitData, schedule: submitSchedule}));
    }

    const tableHead = useMemo(() => {
        return[
            { id: "no", label: "No", alignRight: false, width: '10%' },
            { id: "date", label: "Tanggal", alignRight: false, width: '30%',
                renderData: (row) => (
                    <div className={Styles.TableRowComponent}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={row?.date}
                                    onChange={(e) => (handleItemSchedule("EDIT", {...row, date: e}))}
                                    disabled={row?.status === "Published"}
                                    renderInput={(params) => (
                                        <TextField 
                                            {...params} 
                                            size="small"    
                                            autoComplete="off" 
                                            fullWidth
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: 'Pilih Tanggal'
                                            }} 
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                  border: !row?.date && error ? "1px solid #df2222" : "none",
                                                  padding: 0,
                                                  display: "flex",
                                                  flexDirection: "row-reverse",
                                                  "& fieldset": {
                                                    display: "none", // Menghilangkan border
                                                  },
                                                },
                                                "& .MuiInputAdornment-root": {
                                                  marginRight: 0,
                                                  marginLeft: 0
                                                },
                                            }}
                                        />
                                    )}
                                    minDate={dayjs()}
                                />
                            </LocalizationProvider>
                        </div>
                )
            },
            { id: "time", label: "Jam", alignRight: false, width: '30%',
                renderData: (row) => (
                    <div className={Styles.TableRowComponent}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    disabled={row?.status === "Published"}
                                    ampm={false}
                                    value={row?.time ? entryHour(row?.time) : null}
                                    onChange={(e) => {
                                        const temp = new Date(e).toLocaleTimeString('en', { hour12: false });
                                        handleItemSchedule("EDIT", {...row, time: temp})}
                                    }
                                    minTime={row?.date && row?.date?.isSame(dayjs(), 'day') ? dayjs() : null}
                                    renderInput={(params) => (
                                        <TextField 
                                            {...params} 
                                            size="small" 
                                            fullWidth 
                                            aria-readonly
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: 'Pilih Waktu'
                                            }} 
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                  border: !row?.time && error ? "1px solid #df2222" : "none",
                                                  padding: 0,
                                                  display: "flex",
                                                  flexDirection: "row-reverse",
                                                  "& fieldset": {
                                                    display: "none", // Menghilangkan border
                                                  },
                                                },
                                                "& .MuiInputAdornment-root": {
                                                  marginRight: 0,
                                                  marginLeft: 0
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                )
            },
            { id: "status", label: "Status", alignRight: false, width: '10%',
              renderData: (row) => (
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 400,
                    }}
                    >
                    {row?.status
                        ? <span className={Styles[row.status]}>{row.status}</span>
                        : <span></span>}
                    </Typography>
              )
             },
            { 
              id: "", 
              width: '10%', 
              renderData: (row) => {
                return (
                  <div>
                      <IconButton
                            aria-label="add-notif"
                            color="error"
                            sx={{
                                gap: '10px',
                                cursor: 'pointer',
                                fontSize: '14px',
                                fontWeight: '500',
                            }}
                            disabled={row?.status === "Published"}
                            onClick={(e) => (handleItemSchedule("DELETE", row))}
                            >
                            <Delete fontSize="small" />
                        </IconButton>
                  </div>
                )
              }},
          ];
    }, [handleItemSchedule, error])

    return (
        <div className={Styles.Container}>
            <div className={Styles.Wrapper}>
                <div className={Styles.ClientsLabelContainer}>
                    {dataClients.map((each, i) => (
                        <div key={i} className={Styles.ClientsLabel}>
                            <Business className={Styles.ClientIcon} />
                            <span>{capitalizedClientName(each?.name)}</span>
                        </div>
                    ))}
                </div>

                <div className={Styles.ArticleHeader}>
                    <span>{data?.title}</span>
                </div>
                <div className={Styles.Content}>
                    <span>{data?.description}</span>
                </div>
            </div>
            {(data?.type === 'Kasbon' || data?.type === 'Gadai') && (
                <>
                    <div className={Styles.TitleContent}>
                        <span>Fitur di MyWorkSpace</span>
                    </div>
                    <div className={Styles.Wrapper}>
                        <div className={Styles.FeatureWrapper}>
                            <span>{data?.type}</span>
                            <div
                                className={Styles.TextButton}
                                onClick={() => {
                                    setModalPreview(data?.type);
                                }}>
                                <span>Lihat Preview</span>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className={Styles.TitleContent}>
                <span>Jadwal Notifikasi</span>
                <div className={Styles.SetupPushNotificationButtonWrapper}>
                    {pushNotification?.length > 0 && (
                        <IconButton
                            aria-label="add-notif"
                            color="primary"
                            sx={{
                                gap: '10px',
                                cursor: 'pointer',
                                fontSize: '14px',
                                fontWeight: '500',
                            }}
                            disableRipple={true}
                            onClick={() => handleItemSchedule('ADD')}>
                            <AddCircle fontSize="small" />
                            <span>Tambah Jadwal</span>
                        </IconButton>
                    )}
                </div>
            </div>
            <div>
                <div className={Styles.ItemBoxSetupPushNotification}>
                    <CustomTable
                        bodyData={rowSchedule}
                        tableHead={tableHead}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={limit}
                        setRowsPerPage={setLimit}
                        totalPage={totalPage}
                        customEmpty={() => {
                            return (
                                <div className={Styles.CenterEmptySetupPushNotification}>
                                    <div className={Styles.EmptySetupPushNotification}>
                                        <img
                                            src={NOTIFICATION_IMG}
                                            alt="notif-img"
                                            style={{ height: '70px', width: '100px' }}
                                        />
                                        <br />
                                        <span>Belum ada jadwal notifikasi ditambahkan</span>
                                        <IconButton
                                            aria-label="add-notif"
                                            color="primary"
                                            sx={{
                                                gap: '10px',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                            }}
                                            disableRipple={true}
                                            onClick={() => handleItemSchedule('ADD')}>
                                            <ControlPoint fontSize="small" />
                                            <span>Tambah Jadwal</span>
                                        </IconButton>
                                    </div>
                                </div>
                            );
                        }}
                    />
                </div>
            </div>
            {error && <div className={Styles.ErrorText}>{error}</div>}

            {activeButtonLayout && (
                <div className={Styles.ButtonLayout}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            const randomNumber = Math.floor(Math.random() * 100) + 1;
                            setTrigger(randomNumber);
                            setError('');
                        }}>
                        Batal
                    </Button>
                    <Button variant="contained" onClick={handleSubmit}>
                        Simpan
                    </Button>
                </div>
            )}
            
            {modalPreview && (
                <ModalFeaturePreview
                    type={data?.type}
                    setModal={setModalPreview}
                />
            )}
        </div>
    );
}
