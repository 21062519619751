import axios from "axios";
import {API} from "../../api";
import * as types from '../../type'
//import { setSnackbar } from "./snackbarActions";

// ALL INFORMATION
export const fetchAllInformation = (token, page, limit, search) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_ALL_INFORMATION_BEGIN })
    axios
      .get(`${API}/information?page=${page}&limit=${limit}&search=${search}`, {

        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_INFORMATION_SUCCESS,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_ALL_INFORMATION_ERROR, payload: err.response })
        console.log(err);
      });
  };
};

//INFORMATION BY ID
export const fetchInformationById = (token, id) => {
  // console.log(token)
  return (dispatch) => {
    axios
      .get(`${API}/information/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_INFORMATION_BY_ID,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

//FETCH NOTIF INFORMATION BY INFORMATION ID
export const fetchNotifInformationById = (token, id, page, limit) => {
  // console.log(token)
  return (dispatch) => {
    axios
      .get(`${API}/information/notif-information/${id}?page=${page}&limit=${limit}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_NOTIF_INFORMATION_BY_ID,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};


// ADD INFORMATION
export const addInformation = (token, body) => {
  return (dispatch) => {
    axios
      .post(`${API}/information`, body, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.ADD_INFORMATION,
          payload: res.data.response,
        });
      })
      .then(() => {
        dispatch(fetchAllInformation(token))
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  };
};

// UPDATE INFORMATION
export const updateInformationById = (token, id, body) => {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_INFORMATION_BEGIN })
    axios
      .patch(`${API}/information/${id}`, body, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.UPDATE_INFORMATION,
          payload: res.data.response,
        });
      })
      .then(() => {
        dispatch(fetchInformationById(token, id))
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  };
};

//DELETE INFORMATION

export const deleteInformation = (token, id) => {
  return (dispatch) => {
    axios
      .delete(`${API}/information/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.DELETE_INFORMATION,
          payload: res.data.response,
        });
      })
      // .then(() => {
      //   dispatch(
      //     setSnackbar({
      //       snackbarOpen: true,
      //       snackbarType: "success",
      //       snackbarMessage: "Information deleted successfully",
      //     })
      //   );
      // })
      .then(() => {
        dispatch(fetchAllInformation(token));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// export const deleteInformation = (token, id) => {
//   return (dispatch) => {
//     axios
//       .delete(`${API}/notification/deleteNotif/${id}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then((res) => {
//         dispatch({
//           type: DELETE_INFORMATION,
//           payload: res.data.data,
//         });
//         // return Promise.resolve(); 
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };

// FETCH All NOTIFICATION
export const fetchAllNotification = (token, page, limit, search, clientCode) => {
  return (dispatch) => {
    dispatch({ type: types.NOTIFICATION_BEGIN })
    axios
      .get(`${API}/notif-information?page=${page}&limit=${limit}&search=${search}&clientCode=${clientCode}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.ALL_NOTIFICATION,
          payload: res.data.response
        });
      })
      .catch((err) => {
        dispatch({ 
          type: types.NOTIFICATION_ERROR, 
          payload: err.response.data 
        })
        console.log('NOTIFICATION ERROR', err);
      });
  };
};

// NOTIFICATION BY ID
export const fetchNotificationById = (token, id) => {
  return (dispatch) => {
    dispatch({type: types.NOTIFICATION_BEGIN})
    axios
    .get(`${API}/notif-information/${id}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      dispatch({
        type: types.DETAIL_NOTIFICATION,
        payload: res.data.data.notifInformation
      })
    })
    .catch((err) => {
      dispatch({
        type: types.NOTIFICATION_ERROR,
        payload: err.response.data
      })
      console.log('NOTIFICATION ERROR', err);
    })
  }
}

// ADD NOTIFICATION
export const addNotification = (token, body) => {
  return (dispatch) => {
    axios
      .post(`${API}/notif-information`, body, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.ADD_NOTIFICATION,
          payload: res.data.response,
        });
      })
      // .then(() => {
      //   dispatch(fetchAllInformation(token))
      //   return Promise.resolve();
      // })
      .catch((err) => {
        dispatch({
          type: types.NOTIFICATION_ERROR,
          payload: err.response.data
        })
        console.log(err);
        return Promise.reject(err);
      });
  };
};

// UPDATE NOTIFICATION
export const updateNotificationById = (token, id, body) => {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_INFORMATION_BEGIN })
    axios
      .patch(`${API}/notif-information/${id}`, body, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.UPDATE_NOTIFICATION,
          payload: res.data.response,
        });
      })
      // .then(() => {
      //   dispatch(fetchNotifInformationById(token, id, page, limit))
      //   return Promise.resolve();
      // })
      .catch((err) => {
        dispatch({
          type: types.NOTIFICATION_ERROR,
          payload: err.response.data
        })
        console.log(err);
        return Promise.reject(err);
      });
  };
};

// DELETE NOTIFICATION
export const deleteNotification = (token, id) => {
  return (dispatch) => {
    axios
      .delete(`${API}/notif-information/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.DELETE_NOTIFICATION,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.NOTIFICATION_ERROR,
          payload: err.response.data
        })
        console.log(err);
      });
  };
};

// RESET FIELD
export const resetFieldNotification = () => {
  return (dispatch) => {
    dispatch({ type: types.RESET_NOTIFICATION })
  }
}