import React, { useEffect, useState } from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppDetailFAQ } from "../../../../components/SuperAdmin/_faq";
// import { fetchFaqById } from "../../../../redux/actions/SuperAdmin/faqsActions";
import { useHistory, useParams } from "react-router";
// import { useDispatch } from "react-redux";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import EDIT_ICON from "../../../../assets/images/create.png";
import { fetchOneFAQ } from "../../../../async/faq";
import Styles from './DetailFAQSA.module.css';
import ModalDeleteFAQ from "../../../../components/SuperAdmin/_faq/FAQSettingsTabs/Published/ModalDeleteFaq/ModalDeleteFaq";
export default function DetailFAQSA() {
  // const location = useLocation();
  // const faqById = location.state.dataFaqs;
  // const token = localStorage.getItem("accessToken");
  // const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState({});

  // useEffect(() => {
  //   dispatch(fetchFaqById(token, id));
  // }, []);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const result = await fetchInductionByIdAsync(token, id);
  //       setData(result);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, [id, token]);

  
  //   useEffect(()=> {
  //   dispatch(fetchInductionById(token, id))
  // }, [token, dispatch, id])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchOneFAQ(id);
        setData(data?.response)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  },[id])


  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | FAQ</title>
        </Helmet>
        {/* <Breadcrumb>
          <Button
            onClick={() => history.push("/superadmin/faq")}
            style={{ textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>FAQ</span>
          </Button>
          <span style={{ fontSize: 20 }}>Detail FAQ</span>
        </Breadcrumb> */}

        <div className={Styles.Spacer}>
        <Breadcrumb>
          <Button
            onClick={() => history.push("/superadmin/faq")}
            style={{ textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>FAQ</span>
          </Button>
          <span style={{ fontSize: 20, color: "#255bfc" }}>
            FAQ Detail
          </span>
        </Breadcrumb>

        <div className={Styles.ButtonAction}>
          <Button
            onClick={() => {
              history.push({
                pathname: `/superadmin/edit-faq/${id}`,
                state: {
                  data: data,
                },
              });
            }}
          >
            <span>Edit</span>
            <img src={EDIT_ICON} alt="edit" />
          </Button>
          <Button
            onClick={(event) => {
              event.preventDefault();
              setIsModalOpen(data?.response);
              // setSelectedInformation(information);
            }}
          >
            <span>Delete</span>
            <img src={DELETE_ICON} alt="delete" />
          </Button>
        </div>
      </div>

      {isModalOpen?._id && (
        <ModalDeleteFAQ
          active={isModalOpen}
          onOpen={setIsModalOpen}
          description="Are you sure you want to delete this faq?"
          label="Delete FAQ"
          pathName="/superadmin/faq"
        />
      )}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppDetailFAQ data={data} id={id}/>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
