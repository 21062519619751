import axios from "axios";
import {API} from "../../redux/api";

const fetchPawnById = (token, ebpId) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data: { response }}  = await axios.get(`${API}/ebp/${ebpId}`, {
        headers : {
          Authorization: token
        }
      });
      resolve(response);
      // console.log(response)
    } catch (error) {
      reject(error);
    }
  });

export default fetchPawnById;
