import faker from "faker";
import { sample } from "lodash";
// utils
import { mockImgAvatar } from "../_utils/_mockImages";

// ----------------------------------------------------------------------
// let today =  new Date("2022-01-05T22:00:00.000Z")
// console.log(today,'<<<<');
const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  payment: sample(["Paid", "Unpaid"]),
  avatarUrl: mockImgAvatar(index + 1),
  date: sample(["22 Days", "4 Days", "5 Days", "6 Days", "8 Days"]),
  attend: sample(["Attend", "   "]),
  onleave: sample(["On Leave", " "]),
  sick: sample(["Sick", ""]),
  permit: sample(["On Permit", ""]),
  name: sample([
    "Jaka Saputra",
    "Jack Empire",
    "Layla Clapton",
    "Layla Clapton",
    "Putri Tsunade",
    "Jaka Saputra",
    "Jack Empire",
    "Layla Clapton",
    "Layla Clapton",
    "Putri Tsunade",
  ]),
  //   name: faker.name.findName(),
  company: sample([
    "PT OPPO Indonesia",
    "PT Intel Indonesia",
    "PT Indosat Oredoo",
    "Hyundai Motor",
    "Kia Motor",
    "PT OPPO Indonesia",
    "PT Intel Indonesia",
    "PT Indosat Oredoo",
    "Hyundai Motor",
    "Kia Motor",
  ]),
  //   company: faker.company.companyName(),
  isVerified: faker.datatype.boolean(),
  status: sample(["Internal", "Eksternal"]),
  status2: sample(["Active", "Finished", "Rejected"]),
  status3: sample(["Approved", "Waiting", "Denied"]),
  scheme: sample(["Scheme 1", "Scheme 2", "Scheme 3"]),
  period: sample(["3 Months", "3 Months", "3 Months"]),
  remainingPayment: sample(["3 Months Left", "2 Months Left", "Paid Off"]),

  role: sample([
    "Leader",
    "Hr Manager",
    "UI Designer",
    "UX Designer",
    "UI/UX Designer",
    "Project Manager",
    "Backend Developer",
    "Full Stack Designer",
    "Front End Developer",
    "Full Stack Developer",
  ]),
  role2: sample([
    "Product Designer",
    "Software Engineer",
    "UI Designer",
    "UX Designer",
    "UI/UX Designer",
    "Project Manager",
    "Backend Developer",
    "Full Stack Designer",
    "Front End Developer",
    "Full Stack Developer",
  ]),
  apply: faker.date.past().toLocaleDateString("en-US"),
  username: sample([
    "putrafitria",
    "ridwansaputra",
    "putribianca",
    "jenny",
    "intanviviana",
  ]),
  password: sample([
    "12488473727",
    "12488473727",
    "12488473727",
    "12488473727",
    "12488473727",
  ]),
  division: sample(["Product", "Engineer", "Marketing", "Engineer", "Product"]),
}));

export default users;
