import React, { useState } from "react";
import "./LayoutSA.css";
import { Route } from "react-router-dom";
import SidebarSA from "../../SuperAdmin/SidebarSA/SidebarSA";
import TopNavSA from "../../SuperAdmin/TopNavSA/TopNavSA";
import RoutesSA from "../../SuperAdmin/RoutesSA";
import SnackbarComponent from "../../utils/Snackbar/SnackbarComponent";
import { Redirect } from "react-router";
import MiniScreen from "../../utils/MiniScreen";

const LayoutSA = () => {
  const token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);

  if (!token) {
    return <Redirect to="/" />;
  }
  return (
    <Route
      render={(props) => (
        <>
        <div className="LayoutSA">
          <SidebarSA
            {...props}
            onOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
          <div className={`LayoutSA_content ${!open && "close"}`}>
            <TopNavSA onOpenSidebar={() => setOpen(true)} />
            <div className="LayoutSA_content-main">
              <SnackbarComponent />
              <RoutesSA />
            </div>
          </div>
        </div>
        <div className="mini__screen-layout">
          <MiniScreen />
        </div>
        </>
      )}
    />
  );
};

export default LayoutSA;
