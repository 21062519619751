import React, { useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./SubnavFMCG.css";

const SubnavFMCG = ({ item, active }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);
  const active1 = active ? "active" : "";
  const location = useLocation();

  let pathname = window.location.pathname;

  console.log(pathname)

  const isActive = (data) => {
    return data.split("/")[2] === location.pathname.split("/")[3];
  };

  const handleSubNav = () => {
    if (!item.subNav) {
      return (
        <div className={`sidebar__item-inner ${active1}`}>
          <i className={item.icon} />
          <span className="SidebarLabelFMCG">{item.display_name}</span>
        </div>
      );
    } else if (item.subNav) {
      return (
        <div className={`sidebar__item-inner`}>
          <i className={item.icon} />
          <span className="SidebarLabelFMCG">{item.display_name}</span>
        </div>
      );
    }
  };

  return (
    <div>
      <Link
        className="SidebarLinkFMCG"
        to={`/admin-fmcg` + item.route}
        onClick={item.subNav && showSubnav}
      >
        {handleSubNav()}
        <div style={{ paddingTop: 8 }}>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </Link>
      <div
        className=""
        style={{
          borderLeft: "2px solid #DBDBDB",
          marginLeft: 40,
          transform: "translateY(-19px)",
          // zIndex: 1,
          paddingBottom: 2,
        }}
      >
        {subnav &&
          item?.subNav?.map((item, index) => (
            <div
              className=""
              key={index}
              // style={{ borderLeft: '2px solid #DBDBDB', marginLeft: 40, transform: 'translateY(-22px)'}}
            >
              {isActive(item.route) ? (
                // <div style={{ borderLeft: '1px solid black', marginLeft: 40}}>
                <div
                  className="WrapperSubNavItem"
                  style={{ transform: "translateY(19px)" }}
                >
                  <hr
                    style={{
                      position: "absolute",
                      width: 10,
                      border: "1px solid #DBDBDB",
                      top: 8,
                      borderTopRightRadius: 8,
                      borderBottomRightRadius: 8,
                    }}
                  />
                  <Link
                    to={`/admin-fmcg` + item.route}
                    key={index}
                    className={`SubnavItemInnerFMCG active`}
                  >
                    <span className="SidebarLabelFMCG" style={{ fontSize: 14 }}>
                      {item.display_name}
                    </span>
                    <KeyboardArrowRightIcon
                      style={{ margin: 0, width: 20, height: 20 }}
                    />
                  </Link>
                </div>
              ) : (
                /* </div> */
                <div
                  className="WrapperSubNavItem"
                  style={{ transform: "translateY(19px)" }}
                >
                  <hr
                    style={{
                      position: "absolute",
                      width: 10,
                      border: "1px solid #DBDBDB",
                      top: 8,
                      borderTopRightRadius: 8,
                      borderBottomRightRadius: 8,
                    }}
                  />
                  <Link
                    to={`/admin-fmcg` + item.route}
                    key={index}
                    className={`SubnavItemInnerFMCG`}
                    style={{ paddingLeft: "2rem" }}
                  >
                    <span className="SidebarLabelFMCG" style={{ fontSize: 14 }}>
                      {item.display_name}
                    </span>
                  </Link>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
export default SubnavFMCG;
