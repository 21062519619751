import { Button } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SalaryAdvanceDetailAdminMWSLayout } from "../../../../components/AdminMWS";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";

export default function SalaryAdvanceDetails() {
  const history = useHistory();
  const location = useLocation();
  const dataInfo = location.state.data;
  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Salary Advance</title>
      </Helmet>
      <div className="top__items-settings">
        <Breadcrumb>
          <Button
            onClick={() => history.go(-1)}
            style={{ border: "none", textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>
              Salary Advance
            </span>
          </Button>
          <span style={{ fontSize: 20 }}>Submitter Detail</span>
        </Breadcrumb>
      </div>
      <SalaryAdvanceDetailAdminMWSLayout dataLoan={dataInfo} />
    </div>
  );
}
