import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  // IconButton,
  // Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import SearchbarFMCG from "../../SearchbarFMCG/SearchbarFMCG";
import "./Area.css";
import AddIcon from "@mui/icons-material/Add";
// import { useHistory } from "react-router";
import LocationPinImage from "../../../../assets/images/Location-Pin.png";
import EditIcon from "../../../../assets/images/new-edit-icon.png";
import TrashIcon from "../../../../assets/images/trash-2.png";
import ModalDeleteFMCG from "../../ModalDeleteFMCG/ModalDeleteFMCG";
// import InputText from "../../../utils/Input/Input";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/styles";
// import Edit from "@mui/icons-material/Edit";

const data = [
  { name: "Jakarta 1", id: "1123123123123" },
  { name: "Jakarta 2", id: "asdkajsdhaksdj123123" },
  { name: "Jakarta 3", id: "asdkajsdhaksdj123123123" },
  { name: "Jakarta 4", id: "asdkajsdhaksdj1123123123" },
  { name: "Jakarta 5", id: "asdkajsdhaksdj1231231233" },
];

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 5,
    },
    "& .MuiInputBase-input-MuiOutlinedInput-input": {
      padding: 0,
    },
    "& input::placeholder": {
      color: "#A4A4A4",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "18.2px",
      opacity: 1,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  ItemModalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& > span": {
      color: "#333333",
      fontSize: 20,
      fontWeight: 600,
    },
  },
  Content: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
    paddingTop: 20,
  },
  ProfileInput: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    "& > span": {
      fontSize: 13,
      color: "#0A0A0A",
      fontWeight: 500,
    },
    "& > textarea": {
      width: "100%",
      height: 40,
      border: "1.5px solid #d3c9c9",
      borderRadius: 8,
      paddingLeft: 10,
      paddingTop: 10,
      resize: "vertical",

      "&:hover": {
        borderColor: "black",
      },
    },
  },
  Button: {
    display: "flex",
    alignSelf: "flex-end",
    gap: 10,
    "& > button:nth-of-type(1)": {
      outline: "none",
      background: "#FFFFFF",
      border: "1px solid #333333",
      borderRadius: 5,
      color: "#333333",
      width: 95,
      height: 34,
      cursor: "pointer",
    },
    "& > button:nth-of-type(2)": {
      outline: "none",
      background: "#1571DE",
      borderRadius: 5,
      color: "#FFFFFF",
      width: 81,
      height: 34,
    },
  },
}));

function AddNewArea(props) {
  // eslint-disable-next-line no-unused-vars
  const { classes, openModal, setOpenModal, data } = props;
  const [values, setValues] = useState({
    AreaName: "",
  });
  const [disabledButton, setDisabledButton] = useState(true);

  // useEffect(() => {
  //   if (values?.AreaName.length > 5) {
  //     setDisabledButton(false);
  //   } else {
  //     setDisabledButton(true);
  //   }
  // }, [values?.AreaName?.length]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (event.target.value.length >= 5) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  };

  const onHandleSubmit = () => {
    console.log("submitted");
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={Boolean(openModal)}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        sx={{ backgroundColor: "#F6F6F6" }}
      >
        <div className={classes.ItemModalTop}>
          <span>Add Area</span>
          <CloseIcon
            sx={{ width: 24, height: 24, cursor: "pointer" }}
            onClick={handleClose}
          />
        </div>
      </DialogTitle>

      <DialogContent>
        <form className={classes.Content} onSubmit={onHandleSubmit}>
          <div className={classes.ProfileInput}>
            <span>Area Name</span>
            <MuiTextField
              size="small"
              placeholder="Enter Area Name"
              value={values.AreaName || ""}
              onChange={handleChange("AreaName")}
              type="text"
              required
            />
          </div>
          <div className={classes.Button}>
            <button onClick={handleClose}>Cancel</button>
            <button
              disabled={disabledButton}
              style={{
                backgroundColor:
                  (disabledButton === true && "#B2C5F4") ||
                  (disabledButton === false && "#1571DE"),
              }}
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

function EditArea(props) {
  // eslint-disable-next-line no-unused-vars
  const { classes, openModalEdit, setOpenModalEdit, data } = props;
  const [values, setValues] = useState({
    AreaName: "",
  });

  const handleClose = () => {
    setOpenModalEdit(false);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onHandleSubmit = () => {
    console.log("submitted");
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={Boolean(openModalEdit)}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        sx={{ backgroundColor: "#F6F6F6" }}
      >
        <div className={classes.ItemModalTop}>
          <span>Edit Area</span>
          <CloseIcon
            sx={{ width: 24, height: 24, cursor: "pointer" }}
            onClick={handleClose}
          />
        </div>
      </DialogTitle>

      <DialogContent>
        <form className={classes.Content} onSubmit={onHandleSubmit}>
          <div className={classes.ProfileInput}>
            <span>Area Name</span>
            <MuiTextField
              size="small"
              placeholder="Enter Area Name"
              value={values.AreaName || ""}
              onChange={handleChange("AreaName")}
              type="text"
              required
            />
          </div>
          <div className={classes.Button}>
            <button onClick={handleClose}>Cancel</button>
            <button type="submit">Save</button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const Area = () => {
  const classes = useStyles();
  // const history = useHistory();
  // const [selected, setSelected] = useState("");
  // const [selectedPosition, setSelectedPosition] = useState("");
  const [listAreaStore, setListAreaStore] = useState("");
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalArea, setOpenModalArea] = useState(false);

  const handleSearch = (event) => {
    setListAreaStore(event.target.value);
  };

  // console.log(listAreaStore);
  return (
    <div className="ContainerAreaStore">
      <div className="WrapperAreaStore">
        <div className="HeaderAreaStore">
          <SearchbarFMCG
            value={listAreaStore}
            onChange={handleSearch}
            searchWidth="379px"
            placeholder="Search area name"
          />
          <Button
            startIcon={<AddIcon />}
            style={{
              textTransform: "none",
              backgroundColor: "#1571DE",
              width: 117,
              height: 40,
              color: "#FFFFFF",
            }}
            onClick={() => setOpenModalArea(true)}
          >
            New Area
          </Button>
        </div>
        <div className="WrapperContentAreaStore">
          {data
            ? data
                .filter((store) =>
                  store.name
                    ?.toLowerCase()
                    .includes(listAreaStore.toLowerCase())
                )
                .map((el, idx) => {
                  return (
                    <div className="WrapperItemAreaStore" key={idx}>
                      <div className="TextItemAreaStore">
                        <img src={LocationPinImage} alt="" />
                        <span>{el.name}</span>
                      </div>
                      <div className="ButtonActionItemAreaStore">
                        <div className="ButtonItemAreaStore">
                          <img
                            src={EditIcon}
                            alt="EditIcon"
                            style={{ width: 24, height: 24 }}
                            onClick={() => setOpenModalEdit(true)}
                          />
                        </div>
                        <div className="ButtonItemAreaStore">
                          <img
                            src={TrashIcon}
                            style={{ width: 24, height: 24 }}
                            alt="TrashIcon"
                            onClick={() => setOpenModalDelete(true)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
            : null}
        </div>
      </div>
      {openModalDelete && (
        <ModalDeleteFMCG
          modalDelete={openModalDelete}
          setModalDelete={setOpenModalDelete}
        />
      )}
      {openModalArea && (
        <AddNewArea
          openModal={openModalArea}
          setOpenModal={setOpenModalArea}
          data={data}
          classes={classes}
        />
      )}
      {openModalEdit && (
        <EditArea
          openModalEdit={openModalEdit}
          setOpenModalEdit={setOpenModalEdit}
          data={data}
          classes={classes}
        />
      )}
    </div>
  );
};

export default Area;
