/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import "./ProductDetails.css";
import product_image from "../../../../../assets/images/product-details-photo.jpg";
import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import store_product_image from "../../../../../assets/images/store-product.jpg";
import { useHistory } from "react-router";

export default function ProductDetails() {
  const history =  useHistory();
  const [productCode, setProductCode] = useState("");
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [stock, setStock] = useState("");
  const [socialMedia, setSocialMedia] = useState("");

  return (
    <div className="product__details-container">
      <div className="product__details-wrapper">
        <div className="product__detail-img">
          <img src={product_image} alt="product-image" />
        </div>
        <div className="wrapper__detail-desc">
          <div className="item__product-detail">
            <div className="desc__detail">
              <label>Product Code</label>
              <input
                placeholder="KD3372"
                type="text"
                value={productCode}
                onChange={(e) => setProductCode(e.target.value)}
              />
            </div>
            <div className="desc__detail">
              <label>Product Code</label>
              <input
                placeholder="Tea Celup Sosro"
                type="text"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>
          </div>
          <div className="item__product-detail-2">
            <div className="desc__detail">
              <label>Price</label>
              <input
                placeholder="Rp. 12.000"
                type="text"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            <div className="desc__detail">
              <label>Stock</label>
              <input
                placeholder="100 Karton"
                type="text"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
              />
            </div>
          </div>
          <div className="item__product-detail-3">
            <div className="desc__detail">
              <label>Social Media</label>
              <input
                placeholder="@sinarjaya.id"
                type="text"
                value={socialMedia}
                onChange={(e) => setSocialMedia(e.target.value)}
              />
            </div>
            <div className="desc__detail-status">
              <div className="box__status-detail">
                <label>Sedang Promo</label>
              </div>
            </div>
          </div>
          <div className="item__product-button">
            <Button
              endIcon={<EditIcon style={{ color: "#1571DE" }} />}
              style={{
                border: "1px solid #1571DE",
                borderRadius: 4,
                width: 183,
                height: 36,
                textTransform: "none",
              }}
            >
              Edit
            </Button>
            <Button
              endIcon={<DeleteOutlineIcon style={{ color: "#D1421A" }} />}
              style={{
                border: "1px solid #D1421A",
                borderRadius: 4,
                width: 183,
                height: 36,
                textTransform: "none",
                color: " #D1421A",
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
      <div className="store__available-container">
        <label htmlFor="">Available at</label>
        <div className="items__store-flex" style={{ paddingTop:12 }}>
          <div className="item__store">
            <img src={store_product_image} alt="" />
            <div className="item__store-desc">
              <label htmlFor="">Indomaret</label>
              <span>12 Karton Available</span>
            </div>
          </div>
          <div className="item__store">
            <img src={store_product_image} alt="" />
            <div className="item__store-desc">
              <label htmlFor="">Indomaret</label>
              <span>12 Karton Available</span>
            </div>
          </div>{" "}
          <div className="item__store">
            <img src={store_product_image} alt="" />
            <div className="item__store-desc">
              <label htmlFor="">Indomaret</label>
              <span>12 Karton Available</span>
            </div>
          </div>
          <div className="item__store">
            <img src={store_product_image} alt="" />
            <div className="item__store-desc">
              <label htmlFor="">Indomaret</label>
              <span>12 Karton Available</span>
            </div>
          </div>
          <div className="item__add-store">
            <div className="item__add-icon">
              <IconButton onClick={() => history.push('/admin/add-store-product-external')}>
                <AddIcon style={{ width: 44, height: 44, color: "#3072B8" }} />
              </IconButton>
              <span>Store</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
