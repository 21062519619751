import React, { useEffect, useState } from "react";
import Styles from "./TableWorkOn.module.css";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import SEARCH_ICON from "../../../../assets/images/search-dark.png";
import SEARCH_ICON2 from "../../../../assets/images/search-light.png";
import SEARCH_ICON3 from "../../../../assets/images/search-primary.png";
import AddCircle from "@mui/icons-material/AddCircle";
import Close from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Button,
  Checkbox,
  Stack,
  Avatar,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import CustomTablePagination from "../../../_pagination/CustomTablePagination";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import ModalDeleteWorkOn from "../Modal/ModalDeleteWorkOn";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
// import LoanDataExternal from "../../../../pages/External/LoanDataExternal/LoanDataExternal";

// const datas = [
//   {
//     id: 1,
//     name: "PIS 1",
//     nrk: "111",
//     profilePicture: "",
//   },
//   {
//     id: 2,
//     name: "PIS 1",
//     nrk: "111",
//     profilePicture: "",
//   },
//   {
//     id: 3,
//     name: "PIS 1",
//     nrk: "111",
//     profilePicture: "",
//   },
//   {
//     id: 4,
//     name: "PIS 1",
//     nrk: "111",
//     profilePicture: "",
//   },
//   {
//     id: 5,
//     name: "PIS 1",
//     nrk: "111",
//     profilePicture: "",
//   },
// ];

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "employeeId", label: "NRK", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, addMode, editMode } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        {addMode && <TableCell></TableCell>}
        {editMode && <TableCell></TableCell>}
        {TABLE_HEAD?.map((headCell, index) => (
          <TableCell
            key={headCell.id + index}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className={Styles.TableSortLabel}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {editMode && <TableCell></TableCell>}
      </TableRow>
    </TableHead>
  );
}

export default function TableWorkOn(props) {
  const {
    datas,
    workHoursData,
    // clientCode,
    // detailClient,
    loadingWorkOn,
    handleDelete,
    handleSetWorkOn,
  } = props;
  const token = localStorage.getItem("accessToken");
  const [addMode, setAddMode] = useState({});
  const [searchMode, setSearchMode] = useState({});
  const [editMode, setEditMode] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [workHours, setWorkHours] = useState(workHoursData);
  const [selected, setSelected] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    setWorkHours(workHoursData);
  }, [workHoursData]);

  useEffect(() => {
  },[workHours])

  const handleDeleteData = () => {
    handleDelete(openDeleteModal, selected);
    setOpenDeleteModal(false);
  };

  if (!workHours)
    return (
      <div className={Styles.Container}>
        <div className={Styles.BoxControlGroup}>
          <div className={Styles.BoxGroup}>
            <div className={Styles.TextLabel}>
              <div className={Styles.Icon}>
                <i className="bx bxs-time" />
              </div>
              <span>Working Time </span>
            </div>
            <div className={Styles.WorkTime}>
              <span>There are no selected attendance</span>
            </div>
          </div>
        </div>
      </div>
    );


  return (
    <div className={Styles.Container}>
      {loadingWorkOn ? (
        <div className={Styles.Loading}>
          <div className={Styles.LoadingWidth}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={Styles.BoxControlGroup}>
          {workHours?.map((each, index) => (
            <div className={Styles.BoxGroup} key={index}>
              <div className={Styles.TextLabel}>
                <div className={Styles.Icon}>
                  <i className="bx bxs-time" />
                </div>
                <span>Working Time {index + 1}</span>
              </div>
              <div className={Styles.Details}>
                <div className={Styles.DetailValue}>
                  <span>Start time</span>
                  <span>{each?.startTime}</span>
                </div>
                <div className={Styles.DetailValue}>
                  <span>Working minutes</span>
                  <span>{each?.workInMinute}</span>
                </div>
                <div className={Styles.DetailValue}>
                  <span>Break time</span>
                  <span>{each?.breakDurationInMinute}</span>
                </div>
              </div>
              <div className={Styles.WorkTime}>
                <div className={Styles.Header}>
                  <span>
                    {addMode === each._id ? "Add Employee" : editMode === each._id ? "Edit Employee" : "Set Working Time"}
                  </span>
                  <div className={Styles.ActionButtonWrapper}>
                    {searchMode === each._id ? (
                      <div className={Styles.SearchModeHeader}>
                        <div className={Styles.SearchMode}>
                          <img src={SEARCH_ICON3} alt="search" />
                          <input
                            type="text"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                        </div>
                        <button
                          onClick={() => {
                            setSearchMode("");
                            setSearchInput("");
                          }}
                        >
                          <Close sx={{ fontSize: 16 }} />
                        </button>
                      </div>
                    ) : (
                      <>
                        {addMode === each._id ? (
                          <div className={Styles.AddMode}>
                            <div className={Styles.AddModeSearch}>
                              <img src={SEARCH_ICON2} alt="search" />
                              <input
                                type="text"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                              />
                            </div>
                            <Button
                              onClick={() => {
                                handleSetWorkOn(selected, each._id);
                                setAddMode("");
                              }}
                            >
                              Add
                            </Button>

                            <IconButton
                              onClick={() => {
                                setAddMode(null);
                                setSelected([]);
                              }}
                            >
                              <Close sx={{ fontSize: 16, padding: 0 }} />
                            </IconButton>
                          </div>
                        ) : (
                          <>
                            {editMode === each._id ? (
                              <div className={Styles.AddMode}>
                                <div className={Styles.AddModeSearch}>
                                  <img src={SEARCH_ICON2} alt="search" />
                                  <input
                                    type="text"
                                    value={searchInput}
                                    onChange={(e) =>
                                      setSearchInput(e.target.value)
                                    }
                                  />
                                </div>
                                <img
                                  onClick={() => setOpenDeleteModal(each._id)}
                                  src={DELETE_ICON}
                                  alt="delete"
                                  className={Styles.DeleteIcon}
                                />
                                <IconButton
                                  onClick={() => {
                                    setSelected([]);
                                    setSearchInput("");
                                    setEditMode(null);
                                  }}
                                >
                                  <Close sx={{ fontSize: 16, padding: 0 }} />
                                </IconButton>
                              </div>
                            ) : (
                              <>
                                {each?.users?.length > 0 && (
                                  <>
                                    <button
                                      onClick={() => {
                                        setSearchMode(each._id);
                                        setAddMode(null);
                                        setSearchInput("");
                                      }}
                                    >
                                      <img
                                        src={SEARCH_ICON}
                                        alt="search"
                                        className={Styles.SearchIcon}
                                      />
                                    </button>
                                    <button
                                      onClick={() => {
                                        setEditMode(each._id);
                                        setSelected([]);
                                      }}
                                    >
                                      <Edit
                                        sx={{
                                          color:
                                            editMode === each._id
                                              ? "#1571DE"
                                              : "#000",
                                          fontSize: "1rem",
                                        }}
                                      />
                                    </button>
                                  </>
                                )}
                                <button
                                  onClick={() => {
                                    setAddMode(each._id);
                                    setEditMode(null);
                                    setSearchInput("");
                                    setSearchMode("");
                                  }}
                                >
                                  <AddCircle
                                    sx={{ color: "#000", fontSize: "1rem" }}
                                  />
                                </button>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {each.users?.length > 0 ? (
                  <AttendeesTable
                    setSelectedAttendee={setSelected}
                    // handleUnsetUser={handleUnsetUser}
                    searchInput={searchInput}
                    selected={selected}
                    setSelected={setSelected}
                    token={token}
                    // clientCode={location?.clientCode}
                    // dispatch={dispatch}
                    searchMode={searchMode === each._id}
                    attendees={each.users}
                    datas={datas}
                    editMode={editMode === each._id}
                    addMode={addMode === each._id}
                    setOpenDeleteModal={setOpenDeleteModal}
                    workOnId={each._id}
                  />
                ) : (
                  <div>
                    {addMode === each._id ? (
                      <AttendeesTable
                        setSelectedAttendee={setSelected}
                        setOpenDeleteModal={setOpenDeleteModal}
                        // handleUnsetUser={handleUnsetUser}
                        workOnId={each._id}
                        searchInput={searchInput}
                        selected={selected}
                        setSelected={setSelected}
                        token={token}
                        // clientCode={location?.clientCode}
                        // dispatch={dispatch}
                        searchMode={searchMode === each._id}
                        attendees={each.users}
                        editMode={editMode === each._id}
                        addMode={addMode === each._id}
                        datas={datas}
                      />
                    ) : (
                      <div className={Styles.EmptyBox}>
                        <span>no attendees has been assigned</span>
                      </div>
                    )}
                  </div>
                )}
                {/* {selectedAttendee && (
                <DeleteAttendeeModal
                  active={selectedAttendee}
                  onOpen={setSelectedAttendee}
                  locationId={location?._id}
                  description="Are you sure you want to delete this attendee?"
                  label="Delete Attendee Data"
                  pathName={`/detail-location/${location?._id}`}
                />
              )} */}
              </div>
            </div>
          ))}
        </div>
      )}

      {openDeleteModal && (
        <ModalDeleteWorkOn
          description="Are you sure you want to remove this employee list?"
          label="Remove"
          setOpen={setOpenDeleteModal}
          handleDelete={() => handleDeleteData()}
        />
      )}
    </div>
  );
}

const AttendeesTable = (props) => {
  const {
    addMode,
    editMode,
    selected,
    setSelected,
    datas,
    attendees,
    searchInput,
    searchMode,
    setOpenDeleteModal,
    workOnId,
  } = props;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = datas
  //       .filter(
  //         (el) =>
  //           el.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
  //           el.nrk?.toLowerCase().includes(searchInput.toLowerCase())
  //       )
  //       .map((n) => n?._id);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleClick = (event, Name) => {
    const selectedIndex = selected.indexOf(Name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, Name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (employeeName) => selected.indexOf(employeeName) !== -1;

  return (
    <div className={Styles.TableWrapper}>
      <Scrollbar>
        <TableContainer className={Styles.TableContainer}>
          <Table id="emp-table" className={Styles.Table}>
            {addMode && (
              <colgroup>
                <col style={{ width: "5%" }} />
                <col style={{ width: "50%" }} />
                <col style={{ width: "45%" }} />
              </colgroup>
            )}
            {editMode && (
              <colgroup>
                <col style={{ width: "5%" }} />
                <col style={{ width: "45%" }} />
                <col style={{ width: "45%" }} />
                <col style={{ width: "5%" }} />
              </colgroup>
            )}
            <EnhancedTableHead
              numSelected={datas?.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              values={datas}
              addMode={addMode}
              editMode={editMode}
            />
            {addMode && (
              <TableBody>
                {stableSort(datas, getComparator(order, orderBy))
                  .filter(
                    (el) =>
                      el.name
                        ?.toLowerCase()
                        .includes(searchInput.toLowerCase()) ||
                      el.nrk?.toLowerCase().includes(searchInput.toLowerCase())
                  )
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((each, i) => {
                    const isItemSelected = isSelected(each?.employeeId);
                    return (
                      <TableRow
                        hover
                        key={"tablebody" + i}
                        tabIndex={-1}
                        className={Styles.TableRow}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={Styles.TableCell}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(e) => handleClick(e, each.employeeId)}
                          />
                        </TableCell>
                        <TableCell className={Styles.TableCell}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar
                              src={each?.profilePicture}
                              // className={Styles.Avatar}
                            />
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ textTransform: "capitalize" }}
                            >
                              {each.name ? each.name.toLowerCase() : "-"}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell className={Styles.TableCell}>
                          {each.employeeId}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
            {editMode && (
              <TableBody>
                {stableSort(attendees, getComparator(order, orderBy))
                  .filter(
                    (el) =>
                      el.name
                        ?.toLowerCase()
                        .includes(searchInput.toLowerCase()) ||
                      el.nrk?.toLowerCase().includes(searchInput.toLowerCase())
                  )
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((each, i) => {
                    const isItemSelected = isSelected(each?.employeeId);
                    return (
                      <TableRow
                        hover
                        key={"tablebody" + i}
                        tabIndex={-1}
                        className={Styles.TableRow}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={Styles.TableCell}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(e) => handleClick(e, each.employeeId)}
                          />
                        </TableCell>
                        <TableCell className={Styles.TableCell}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar
                              src={each?.profilePicture}
                              // className={Styles.Avatar}
                            />
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ textTransform: "capitalize" }}
                            >
                              {each.name ? each.name.toLowerCase() : "-"}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell className={Styles.TableCell}>
                          {each.employeeId}
                        </TableCell>
                        <TableCell className={Styles.TableCell}>
                          <img
                            onClick={() => {
                              setOpenDeleteModal(workOnId);
                              setSelected([each.employeeId]);
                            }}
                            src={DELETE_ICON}
                            alt="delete"
                            className={Styles.DeleteIcon}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
            {!addMode && !editMode && (
              <TableBody>
                {stableSort(attendees, getComparator(order, orderBy))
                  .filter((el) =>
                    addMode || searchMode
                      ? el.name
                          ?.toLowerCase()
                          .includes(searchInput.toLowerCase())
                      : el
                  )
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((each, i) => {
                    return (
                      <TableRow
                        hover
                        key={"tablebody" + i}
                        tabIndex={-1}
                        className={Styles.TableRow}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={Styles.TableCell}
                        >
                          {each.name}
                        </TableCell>
                        <TableCell
                          className={`${Styles.TableCell} ${Styles.DeleteCell}`}
                        >
                          <span>{each.employeeId}</span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <CustomTablePagination
        limit={rowsPerPage}
        setLimit={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalPage={Math.ceil(
          !addMode
            ? attendees?.filter((el) =>
                addMode || searchMode
                  ? el.name?.toLowerCase().includes(searchInput.toLowerCase())
                  : el
              ).length / rowsPerPage
            : datas?.filter(
                (el) =>
                  el.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
                  el.employeeId
                    ?.toLowerCase()
                    .includes(searchInput.toLowerCase())
              ).length / rowsPerPage
        )}
      />
    </div>
  );
};
