import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
// import UnderMaintenance from "../../../components/_underMaintenancePage";
import fetchClientCodeExternal from "../../../async/client/fetchClientCodeExternal";
import { LocationExternalMenu } from "../../../components/External/Location";
import useQuery from "../../../components/utils/hooks/useQuery";
// import UnderMaintenance from "../../../components/_underMaintenancePage";
import { fetchAllLocationExternal } from "../../../redux/actions/External/locationActions";
// import "./Maintenance.css";
import MAINTENANCE_IMAGE from "../../../assets/images/UnderMaintenance.png";

export default function LocationExternal() {
  // const isMaintenance = false;

  const token = localStorage.getItem("accessToken");
  const defaultClientCode = localStorage.getItem("clientCode");
  const defaultClientName = localStorage.getItem("clientName");
  const defaultClient = {
    code_client: defaultClientCode,
    name_client: defaultClientName,
  };
  const [selectedClient, setSelectedClient] = useState(defaultClient);

  const [dataClientCodes, setDataClientCodes] = useState([]);
  const dispatch = useDispatch();

  const query = useQuery();

  const clientName =
    query.get("client_name") ||
    window.sessionStorage.getItem("selectedClientNameExternal") ||
    localStorage.getItem("clientName");

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCodeExternal(token);
        setDataClientCodes(response);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { allLocationsExternal } = useSelector(
    (state) => state.locationsExternal
  );
  const codeQuery = `code=${
    window.sessionStorage.getItem("selectedClientCodeExternal") ||
    localStorage.getItem("clientCode")
  }`;

  useEffect(() => {
    dispatch(fetchAllLocationExternal(token, codeQuery));
  }, [token, codeQuery, dispatch]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Location</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          Location
        </h2>
      </div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          {!Boolean(allLocationsExternal) ? (
            <div className="maintenance-container">
              <div className="maintenance-main">
                <div className="maitenance-image">
                  <img src={MAINTENANCE_IMAGE} alt="" />
                </div>
                <div className="maintenance-text">
                  <div className="maintenance-label">
                    <span>Ooops!</span>
                  </div>
                  <div className="maintenance-text-main">
                    <span>Sorry this feature is not available yet</span>
                  </div>
                  <div className="maintenance-text-last">
                    <span>
                      location feature is not available for this client
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <LocationExternalMenu
              dataClientCodes={dataClientCodes}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              clientName={clientName}
              defaultClient={defaultClient}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
