import React from "react";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { AppStoreExternal } from '../../../components/External'
import Breadcrumb from "../../../components/utils/Breadcrumbs";

export default function AddStore() {
  const history = useHistory();
  return (
    <Container maxWidth="xl">
      {/* <Breadcrumbs /> */}
      <div className="">
        <Helmet>
          <title>MyWorkSpace | Store Details</title>
        </Helmet>
        <div className="">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/admin/store-external")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Store</span>
            </Button>
            <span style={{ fontSize: 20 }}>Add Store</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppStoreExternal />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
