/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./BPJSReport.css";
import { filter } from "lodash";
import { Helmet } from "react-helmet";
import logo_MyWorkSpace from "../../../../assets/images/my-workspace.png";
import DownloadIcon from "@mui/icons-material/Download";
// import PrintIcon from "@mui/icons-material/Print";
import {
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import USERLIST from "../../../../components/SuperAdmin/_user/TableUsers/_mocks/user";
import DatePickerBPJS from "../DatePickerMonth/DatePickerBPJS";
import DropdownReportBpjs from "../DropdownBpjsReport";
import Scrollbar from "../../../../components/SuperAdmin/_user/TableUsers/Scrollbar";
import UserListHead from "../../../../components/SuperAdmin/_user/TableUsers/UserListHead";
import { useHistory } from "react-router";
import { makeStyles } from "@mui/styles";
import ExportCSV from "../../../../components/SuperAdmin/_attendance/tabs/DataAttendance/ExportData";

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
  },
});

const CompanyGroup = [
  { name: "PT Permata Indonesia", code: "AF" },
  { name: "PT Samsung", code: "AF" },
  { name: "PT Telkom Indonesia", code: "AF" },
  { name: "PT Sinar Dunia", code: "AF" },
];

const TABLE_HEAD = [
  { id: "name", label: "Employee Name", alignRight: false },
  { id: "position", label: "Position", alignRight: false },
  { id: "client", label: "Division", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  // { id: "reaminingPayment", label: "Last Payment Date", alignRight: false },
  //   { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function BPJSReport() {
  const classes = useStyles();
  const history = useHistory();
  const [groupValue, setGroupValue] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedGroup, setSelectedGroup] = useState("");
  const option = [
    "PT Permata Indonesia",
    "PT Samsung",
    "PT Telkom Indonesia",
    "PT Sinar Dunia",
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const navigateBack = () => history.go(-1);
  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );

  return (
    <div className="container-bpjs-report">
      <Helmet>
        <title>MyWorkSpace | BPJS Report</title>
      </Helmet>
      <div className="wrapper-bpjs-report">
        <div className="item-top-bpjs-report">
          <div className="item-left-bpjs-report" onClick={navigateBack}>
            <img src={logo_MyWorkSpace} alt="" />
            <span>MyWorkSpace</span>
          </div>
          <div className="item-mid-bpjs-report">
            <span>BPJS Report</span>
          </div>
          <div className="item-right-bpjs-report">
          <ExportCSV
              // data={dataToExport
              //   .map((el, ind) => ({
              //     "No. ": ind + 1,
              //     Nama: el.Name,
              //     Position: el.Position,
              //     Nrk: el.Nrk,
              //     "Total Attend": el.totalPresence,
              //     "Total Sick": el.totalSickPermit,
              //     "Total Leave": el.totalAnnualLeave,
              //   }))}
              filename={`BPJS Report ${new Date().toLocaleDateString()}`}
              initial="Download"
              endIcon={<DownloadIcon />}
            />
            {/* <Button endIcon={<DownloadIcon />}>
              <span>Download</span>
            </Button>
            <Button endIcon={<PrintIcon />}>
              <span>Print</span>
            </Button> */}
          </div>
        </div>
        <div className="datepicker-bpjs-report">
          <DatePickerBPJS />
        </div>
        <div className="company-bpjs-report">
          <div className="title-company-bpjs-report">
            <label htmlFor="">Group</label>
            <FormControl fullWidth size="small" style={{ width: "100%" }}>
              <Select
                className={classes.selectBorder}
                value={selectedGroup}
                displayEmpty
                onChange={(e) => setSelectedGroup(e.target.value)}
                required
              >
                <MenuItem value="">Select Group</MenuItem>
                {option.map((option, el) => (
                  <MenuItem
                    key={el}
                    onClick={() => setSelectedGroup(option)}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <DropdownReportBpjs
              groups={CompanyGroup}
              groupname="Group Name"
              groupValue={groupValue}
              onChange={(val) => setGroupValue(val)}
            /> */}
            {/* <span>PT Permata Indonesia</span> */}
          </div>
        </div>
        <hr />
        <div className="desc-item-bpjs-report">
          <div className="mini-item-left-bpjs">
            <label>Total Employee</label>
            <span>100 Employee</span>
          </div>
          <div className="border-seperator"></div>
          <div className="mini-item-middle-bpjs">
            <label>Total Paid</label>
            <span>10 Employee</span>
          </div>
        </div>
        <div className="loan-progress-container">
          {/* <div className="title-loan-progress">
            <span>Loan Progress</span>
          </div> */}
          <div className="table-loan-progress">
            <Scrollbar>
              <TableContainer sx={{ minWidth: 600 }}>
                <Table
                  sx={{
                    width: "100%",
                    maxHeight: "100%",
                    backgroundColor: "#fafafa",
                    borderRadius: 4,
                  }}
                >
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          id,
                          name,
                          role,
                          avatarUrl,
                          payment,
                          division,
                        } = row;
                        return (
                          <TableRow hover key={id} tabIndex={-1}>
                            <TableCell></TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar alt={name} src={avatarUrl} />
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{role}</TableCell>
                            <TableCell align="left">{division}</TableCell>
                            <TableCell align="left">{payment}</TableCell>
                            {/* <TableCell align="left">31 November 2021</TableCell> */}
                            {/* <TableCell align="left">
                              {remainingPayment}
                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
