import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import InformationSA from "../../../pages/SuperAdmin/Information/InformationSA";
import AddInformation from "../../../pages/SuperAdmin/Information/AddInformation/AddInformationSA";
import EditInformation from "../../../pages/SuperAdmin/Information/EditInformation/EditInformation";
import DetailInformation from "../../../pages/SuperAdmin/Information/DetailInformation/DetailInformation";
import NotificationAdmin from "../../../pages/Business/Notification/NotificationAdmin";
import DetailNotification from "../../../pages/Business/Notification/DetailNotification/DetailNotification";
import AddNotification from "../../../pages/Business/Notification/AddNotification/AddNotification";

import EBPKkb from "../../../pages/Business/EBP/EBPKkb/EBPKkb";
import EBPEducation from "../../../pages/Business/EBP/EBPEducation/EBPEducation";
import DetailEBP from "../../../pages/Business/EBP/DetailEBP/DetailEBP";

const RouterBusiness = () => {
    const { path } = useRouteMatch();
    const token = localStorage.getItem('acessToken');
    return (
        <div>
            <Switch>
                {/* INFORMATION */}
                <Route exact path={`${path}/information`} component={InformationSA}/>
                <Route path={`${path}/add-information`} component={AddInformation} />
                <Route path={`${path}/edit-information/:id`} component={EditInformation} />
                <Route path={`${path}/detail-information/:id`} component={DetailInformation} />

                {/* Notification */}
                <Route path={`${path}/notification`} component={NotificationAdmin}/>
                <Route path={`${path}/notification-detail/:id`} component={DetailNotification}/>
                <Route path={`${path}/notification-add`} component={AddNotification}/>
                <Route path={`${path}/notification-edit/:id`} component={AddNotification}/>

                {/* EBP KKB */}
                <Route path={`${path}/ebp-kkb`} component={EBPKkb}/>

                {/* EBP Education */}
                <Route path={`${path}/ebp-education`} component={EBPEducation}/>

                {/* EBP Detail */}
                <Route path={`${path}/ebp-detail/:id`} component={DetailEBP}/>
            </Switch>
        </div>
    )
}

export default RouterBusiness