import axios from "axios";
import {API} from "../../redux/api";

const fetchUserClients = (token) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data : { response } } = await axios.get(`${API}/user/user-client`, {
        headers : {
          Authorization: token
        }
      });
      resolve(response );
    } catch (error) {
      reject(error);
    }
  });

export default fetchUserClients;
