import React, { useMemo, useState } from "react";
import { Grid, Container, Button  } from "@mui/material";
import { Helmet } from "react-helmet";
// import {
//   DeleteModalLayout,
//   InductionFormEditLayout,
// } from "../../../../components/SuperAdmin/_induction";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
// import fetchInductionById from "../../../../async/induction/fetchInductionById";
import Styles from "./EditFAQSA.module.css";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { AppEditFAQ } from "../../../../components/SuperAdmin/_faq";
// import { fetchOneFAQ } from "../../../../async/faq";
import ModalDeleteFAQ from "../../../../components/SuperAdmin/_faq/FAQSettingsTabs/Published/ModalDeleteFaq/ModalDeleteFaq";

export default function FAQFormEdit() {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  // const [data, setData] = useState();
  const [isModalOpen, setIsModalOpen] = useState({});

  const dataFaq = useMemo(() => {
    return location?.state?.data;
  }, [location]);

  // const inductionById = useMemo(() => {
  //   return location?.state?.data;
  // }, [location]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const data = await fetchOneFAQ(id);
  //       console.log(data?.response, 'woy')
  //       setData(data?.response);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, [id]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | FAQ</title>
      </Helmet>

      <div className={Styles.Spacer}>
        <Breadcrumb>
          <Button
            onClick={() => history.push("/superadmin/faq")}
            style={{ textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>FAQ</span>
          </Button>
          <span style={{ fontSize: 20, color: "#255bfc" }}>Edit FAQ</span>
        </Breadcrumb>

        <div className={Styles.ButtonAction}>
          <Button
            onClick={(event) => {
              event.preventDefault();
              setIsModalOpen(dataFaq);
              // setSelectedInformation(information);
            }}
          >
            <span>Delete</span>
            <img src={DELETE_ICON} alt="delete" />
          </Button>
        </div>
      </div>

      {isModalOpen?._id && (
        <ModalDeleteFAQ
          active={isModalOpen}
          onOpen={setIsModalOpen}
          description="Are you sure you want to delete this faq?"
          label="Delete FAQ"
          pathName="/superadmin/faq"
        />
      )}

      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <AppEditFAQ data={dataFaq} id={id}/>
          {/* <InductionFormEditLayout data={ data } id={id} /> */}
        </Grid>
      </Grid>
    </Container>
  );
}
