import React from "react";
import { Helmet } from "react-helmet";
// import fetchPawns from "../../../../async/employeeeBenefit/fetchPawns";
import { PawnAdminMWSLayout } from "../../../../components/AdminMWS";

export default function Pawn() {
  // const token = localStorage.getItem("accessToken");
  // const clientName  = localStorage.getItem("clientName")
  // const [pawns, setPawns] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       setIsLoading(true);
  //       const data = await fetchPawns(token, clientName);
  //       setPawns(data);
  //       setIsLoading(false);
  //     } catch (error) {
  //       setIsLoading(false);
  //       console.log(error);
  //     }
  //   })();
  // }, [token, clientName]);

  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Pawn</title>
      </Helmet>
      <PawnAdminMWSLayout 
      // pawns={ pawns } loading={ isLoading }

      />
    </div>
  );
}
