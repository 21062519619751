import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '../../../hook/useDebounce';
import { Container, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { fetchAllNotification } from '../../../redux/actions/SuperAdmin/informationActions';
import { ListNotification } from '../../../components/Business/_notification';

export default function NotificationAdmin() {
    const token = localStorage.getItem('accessToken');
    const dispatch = useDispatch();
    const { allNotification, loadingNotification } = useSelector((state) => state.informations);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const debounceSearh = useDebounce(searchTerm, 500);
    const [selectedClients, setSelectedClients] = useState([]);
    
    useEffect(() => {
        const clientCode = selectedClients.map(item => item.codes).join(",");
        dispatch(fetchAllNotification(token, page, rowsPerPage, debounceSearh, clientCode));
    }, [dispatch, token, page, rowsPerPage, debounceSearh, selectedClients]);

    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>MyWorkSpace | Notification</title>
            </Helmet>
            <div className="wrapper-client-SA">
                <h2 className="page-header" style={{ fontWeight: 500, color: '#0A0A0A', fontSize: 40 }}>
                    Notification
                </h2>
            </div>
            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    <ListNotification
                        informations={allNotification?.data}
                        loading={loadingNotification}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        totalPage={allNotification?.totalPages}
                        totalData={allNotification?.totalData}
                        selectedClients={selectedClients}
                        setSelectedClients={setSelectedClients}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
