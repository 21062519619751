/* eslint-disable no-unused-vars */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import moment from "moment";
// moment.locale("en-gb");

const useStyles = makeStyles((theme) => ({
  Modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 468,
    // height: 965,
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    border: "none",
    // boxShadow: 24,
    padding: 10,
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  ItemModalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .dummy": {
      display: "hidden",
      fontSize: 0,
    },
    "& .textAbsences": {
      color: "#404040",
      fontSize: 24,
      fontWeight: 500,
    },
    "& .icon": {
      backgroundColor: "transparent",
      border: "none",
      fontSize: 25,
      cursor: "pointer",
    },
  },
  ProfileInfo: {
    "& .cutiAwal": {
      display: "flex",
      flexDirection: "column",
      rowGap: 12,
    },
    "& .labelCuti": {
      color: "#616161",
      fontSize: 17,
      fontWeight: 500,
    },
    "& .textTanggal": {
      color: "#0A0A0A",
      fontSize: 20,
      fontWeight: 400,
    },
    "& .cutiAkhir": {
      display: "flex",
      flexDirection: "column",
      rowGap: 12,
      paddingTop: 24,
    },
  },
  ReasonInfo: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 24,
    rowGap: 12,
    "& .textReason": {
      color: "#616161",
      fontSize: 17,
      fontWeight: 500,
    },
    "& .labelReason": {
      color: "#404040",
      fontSize: 20,
      fontWeight: 400,
    },
  },
}));

export default function DialogLeave({ showLeave, setShowLeave }) {
  //   const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setShowLeave(true);
  };
  const handleClose = () => {
    setShowLeave(false);
  };

  console.log(showLeave, 'cek data leave')
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Boolean(showLeave)}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className={classes.ItemModalTop}>
            <h6 className="dummy">dummy</h6>
            <div className="textAbsences">
              <span className="textAbsences">Leave Details</span>
            </div>
            <button onClick={handleClose} className="icon">
              <CloseIcon
                style={{ fontSize: 30, marginTop: 10, paddingRight: 5 }}
              />
            </button>
          </div>
          <div>
            <hr style={{ width: "100%", color: "#ECE6E6" }} />
          </div>
        </DialogTitle>

        <DialogContent>
          <div className={classes.ProfileInfo}>
            <div className="cutiAwal">
              <label className="labelCuti">Tanggal Awal Cuti</label>
              <span className="textTanggal">{ moment(showLeave?.startTime).format('LL')}</span>
            </div>
            <div className="cutiAkhir">
              <label className="labelCuti">Tanggal Akhir Cuti</label>
              <span className="textTanggal">{ showLeave?.endTime ? moment(showLeave?.endTime).format('LL') : "-"} </span>
            </div>
          </div>
          <div className={classes.ReasonInfo}>
            <label className="labelReason">Alasan</label>
            <span className="textReason">
              {
                showLeave?.remark ? showLeave?.remark : 'Tidak ada alasan'
              }
            </span>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
