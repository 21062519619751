import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { CompetitorEditFormFMCG } from "../../../../components/FMCG";

export default function CompetitorEditForm() {
  const location = useLocation();
  const editCompetitorPath = location?.state?.location;

  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Setup</title>
      </Helmet>
      <CompetitorEditFormFMCG location={ editCompetitorPath } />
    </div>
  );
}
