import React from "react";
import Styles from "./Style.module.css";
import { Backdrop, Button, Fade, Modal } from "@mui/material";
import { Box } from "@mui/system";
import REJECTED_ICON from "../../../../../assets/images/close-confirmation.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "@media screen and (max-width: 478px)": {
    bottom: 0,
    top: "auto",
    transform: "translate(-50%, 0)",
  },
};

// const modalStyle = {
//   "@media screen and (max-width: 950px)": {
//     display: "none",
//   },
// };

export default function ConfirmationModalSA(props) {
  const { active, onOpen, textType, description, type, handleCancelPermission } = props;
  // const [text, setText] = useState("");

  const handleClose = () => {
    onOpen(false);
  };

  //   const handleApproveRejection = async () => {
  //     onOpen(false);
  //     await axios
  //       .patch(`${API}/resign/web/${dataResign?._id}`, { status: "REJECT", remark: text })
  //       .then((res) => {
  //         console.log(res);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //     await new Promise((resolve) => setTimeout(() => resolve(true), 1000));
  //     window.location.reload(false);
  //   };
  return (
    <Modal
      open={active}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      //   sx={modalStyle}
    >
      <Fade in={active}>
        <Box sx={style}>
          <div className={Styles.ModalDeleteBackground}>
            <div className={Styles.ModalContainerContent}>
              <div className={Styles.WrapperItemModalSuccess}>
                <div className={Styles.WrapperImage}>
                  <div className={Styles.Image}>
                    <img src={REJECTED_ICON} alt="rejected-icon" />
                  </div>
                </div>
                <div className={Styles.Content}>
                  <span>{textType}</span>
                  <span>{description}</span>
                </div>
                {type === "cancelAnnualLeave" && (
                  <div className={Styles.ButtonAction}>
                    <Button onClick={() => onOpen(false)}>Cancel</Button>
                    <Button onClick={handleCancelPermission}>Yes, I'm Sure</Button>
                  </div>
                )}
                {type === "overtimeError" && (
                  <div className={Styles.ButtonAction}>
                    <Button onClick={() => onOpen(false)}>Back</Button>
                    <Button style={{ display: "none" }}></Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
