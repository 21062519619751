import * as types from '../../typeBusiness';

const initialstate = {
    allDataEBPEducation: {},
    allDataEBPKKB: {},
    detailDataEBP: {},
    loadingDataEBP: false,
    errorEBP: {},
};

const ebpBusinessReducer = (state = initialstate, action) => {
    switch (action.type) {
        case types.EBP_BEGIN: {
            return {
                ...state,
                loadingDataEBP: true,
            };
        }
        case types.EBP_ERROR: {
            return {
                ...state,
                loadingDataEBP: false,
                errorEBP: action.payload,
            };
        }
        case types.FETCH_ALL_EBP_KKB: {
            return {
                ...state,
                loadingDataEBP: false,
                allDataEBPKKB: action.payload,
            };
        }
        case types.FETCH_ALL_EBP_EDUCATION: {
            return {
                ...state,
                loadingDataEBP: false,
                allDataEBPEducation: action.payload,
            };
        }
        case types.FETCH_DETAIL_EBP: {
            return {
                ...state,
                loadingDataEBP: false,
                detailDataEBP: action.payload,
            };
        }
        default:
            return state;
    }
};

export default ebpBusinessReducer;
