/* eslint-disable no-unused-vars */
import { AccessTime } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import Styles from './SettingAttendanceView.module.css';
export function SettingAttendanceView({ dataSchema }) {
  // const attendanceSkema = useSelector((state) => state.attendances.schemaAttendance);
  const token = localStorage.getItem("accessToken");
  const location = useLocation();
  const dispatch = useDispatch();
// 
  // const dataSchema = location?.state?.dataSchema;
  const name = location.state.dataSchema.Name;

  const [dataView, setDataView] = useState(dataSchema);
  const [paidLeave, setPaidLeave] = useState(dataSchema?.PaidLeave);
  const [description, setDescription] = useState();


  const entryHour = (timeString) => {
    const today = new Date();
    const arrEntryHour = timeString?.split(":");
    today.setUTCHours(
      +arrEntryHour[0] - 7,
      +arrEntryHour[1],
      +arrEntryHour[2],
      0
    );
    return today;
  };

  return (
    <div className={Styles.Container}>
      <div className={Styles.Wrapper}>
        <span> Name & Description</span>
        <div className={Styles.BoxForm}>
          <span>Scheme Name</span>
          <span>{dataSchema?.name}</span>
        </div>
        <div className={Styles.Desc}>
          <span>Description</span>
          <span>{dataSchema?.remark}</span>
        </div>
      </div>
      <hr />
      {dataSchema?.workHours?.map((item, index) => {
        return (
          <div key={index}>
            <div className={Styles.BodyWrapper}>
              <span>Working Time {dataSchema.workHours.length > 1 && index + 1}</span>
              <div className={Styles.ShiftWrapper}>
                <span>
                  {item?.isShiftHour ? "Shift Hours" : "Normal Hours"}
                </span>

              </div>
            </div>

            <div className="">
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className={Styles.BoxForm}>
                    <span>Start Time</span>
                    <div className={Styles.BoxInputWrapper}>
                      <span>Hours</span>
                      <span className={Styles.BoxInput}>
                        <AccessTime className={Styles.ClockIcon2} />
                        <input type="text" value={item?.startTime?.slice(0, 5)} className={Styles.Duration2} readOnly />
                      </span>
                    </div>
                  </div>
                </LocalizationProvider>
                <div className={Styles.BoxForm}>
                  <span>Working Minutes</span>
                  <div className={Styles.BoxInputWrapper}>
                    <span>Minutes</span>
                    <span className={Styles.BoxInput}>
                      <AccessTime className={Styles.ClockIcon}/>
                      <input type="number" value={item?.workInMinute || ""} className={Styles.Duration} readOnly />
                    </span>

                  </div>
                </div>
                <div className={Styles.BoxForm}>
                  <span>Break Minutes</span>
                  <div className={Styles.BoxInputWrapper}>
                    <span>Minutes</span>
                    <span className={Styles.BoxInput}>
                      <AccessTime className={Styles.ClockIcon} />
                      <input type="number" value={item?.breakDurationInMinute || ""} className={Styles.Duration}readOnly />
                    </span>
                  </div>
                </div>


                <hr />

              </div>
            </div>
          </div>

        )
      })}

      <div className={Styles.Wrapper}>
        <div className={Styles.OvertimeWrapper}>
          <span>Set Overtime - After</span>
          <span className={Styles.BoxInputWrapper}>
            <span>Minutes</span>
            <span className={Styles.BoxInput}>
              <AccessTime className={Styles.ClockIcon}/>
              <input type="number" value={dataSchema?.overtime?.startAfterWorkInMin } className={Styles.Duration} readOnly />
            </span>
          </span>
        </div>
      </div>
      <hr />

      <div className={Styles.Wrapper}>
        <span>Location</span>
        <div className={Styles.BoxForm}>
          <span>Work From Home</span>
          <span>{dataSchema?.canWorkFromHome ? "Yes" : "No"}</span>
        </div>
        <div className={Styles.BoxForm}>
          <span>Lock Location Check In</span>
          <span>{dataSchema?.isLockedLocation ? "Yes" : "No"}</span>
        </div>
        <div className={Styles.BoxForm}>
          <span>Lock Location Check Out</span>
          <span>{dataSchema?.isCheckoutLockedLocation ? "Yes" : "No"}</span>
        </div>
      </div>

      <hr />

      <div className={Styles.Wrapper}>
        <span>Cut Off Date</span>
        <div className={Styles.BoxForm2}>
          <span>Payroll Date Details</span>
          <div className={Styles.BoxInputWrapper}>
            <span>Every Month - Day</span>
            <input
              value={dataSchema?.cutOff?.startDate || ""}
              className={Styles.CutOff}
              type="number"
              readOnly
            />
            <span>to</span>
            <input
              value={dataSchema?.cutOff?.endDate || ""}
              className={Styles.CutOff}
              type="number"
              readOnly
            />
          </div>
        </div>
      </div>
      
      <hr />

      <div className={Styles.Wrapper}>
        <div className={Styles.BoxForm}>
          <span>Notify to email</span>
          <span>{dataSchema?.notificationToEmail ? "Yes" : "No"}</span>
        </div>
      </div>
    </div>
  );
}

export default SettingAttendanceView;
