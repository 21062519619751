import React from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { AppInformationExternal } from "../../../components/External/Information";

const Information = () => {
  const history = useHistory();
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Information</title>
      </Helmet>
      <div className="wrapperPageStore">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#000000", fontSize: 40 }}
        >
          Information
        </h2>
        <div className="wrapperPagesStoreReport">
          <Button
            className="wrapperPagesStoreReport"
            onClick={() => history.push("/information-report-external/")}
          >
            <span className="textReportStore">Report</span>
            <i className="bx bxs-printer"></i>
          </Button>
        </div>
      </div>
      <Grid>
        <Grid item xs={12} md={12} lg={12}>
          <AppInformationExternal />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Information;
