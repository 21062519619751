import React, { useEffect } from "react";
import { useParams } from "react-router";
import { EmployeeDetailsFMCG } from "../../../components/FMCG";

export default function EmployeesDetailsFMCG() {
  const { id } = useParams();
  // const location = useLocation();

  useEffect(() => {}, [id]);

  return (
    <div>
      <EmployeeDetailsFMCG />
    </div>
  );
}
