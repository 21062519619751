import axios from "axios";
import { API } from "../../redux/api";

const createLeavePlan = (token,dataToSend) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data: { response } } = await axios({
        method: "post",
        headers: { Authorization: `${token}` },
        url: `${API}/leave-plan`,
        data:dataToSend
      });
      resolve(response);
      // console.log(response)
    } catch (error) {
      reject(error);
    }
  });

export default createLeavePlan;
