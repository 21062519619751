import React from "react";
// import "./LoanAddScheme.css";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
// import { LoanEditSchemeMenu } from "../../../components/SuperAdmin/_loan";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
import { useHistory, useLocation } from "react-router";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchSchemaLoanById } from "../../../redux/actions/SuperAdmin/loanActions";
import { AppAddLoanScheme } from "../../../components/SuperAdmin/_loan";
export default function LoanEditScheme() {
  const location = useLocation();
  const history = useHistory();
  const loanId = location.state.dataLoan;
  // const dispatch = useDispatch();
  // const token = localStorage.getItem("blogToken");
  // const { id } = useParams();
  // const { loanSchemaById, loadingLoanSchema } = useSelector((state) => state.loan);


  // useEffect(() => {
  //   dispatch(fetchSchemaLoanById(token, id));
  // },[]);

  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace| Loan Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/loan")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Loan</span>
            </Button>
            <Button
              onClick={() => history.go(-1)}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Edit Scheme</span>
            </Button>
            <span style={{ fontSize: 20 }}>{loanId.name}</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppAddLoanScheme />
            {/* <LoanEditSchemeMenu loanId={ loanId }/> */}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
