import Styles from './ChangePublishModal.module.css'
import PUBLISH_ICON from "../../../../../assets/images/changePublish.png";
import { useMemo } from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateInformationById } from '../../../../../redux/actions/SuperAdmin/informationActions';


export default function ChangePublishModal({
    active,
    setChangePublish,
    setModalPublish
}) {

    const dispatch = useDispatch();
    const token = localStorage.getItem('accessToken');

    const statusChangeTo = useMemo(() => {
        let changeTo;
        // let temp = active?.isPublish;
        let temp = active?.status;

        if (temp === "Published") {
            changeTo = 'unpublish'
        } else {
            changeTo = 'publish'
        }

        return changeTo
    }, [active])

    const handleChange = (e) => {
        e.preventDefault()

        const newStatus = statusChangeTo

        const formData = new FormData();
        formData.set("isPublish", true);
        formData.set("status", newStatus === "publish" ? "Published" : "Unpublish");

        dispatch(updateInformationById(token, active._id, formData));
        setChangePublish(false)

        // if (!active?.isPublish === true) {
        if (newStatus === "publish") {
            setModalPublish(true)
        }

    }
    return (
        <div className={Styles.Background}>
            <div className={Styles.Container}>
                <div className={Styles.ImageWrapper}>
                    <img src={PUBLISH_ICON} alt="publish" />
                </div>
                <div className={Styles.TitleWrapper}>
                    <span>
                        Change Information
                        Status
                    </span>
                </div>
                <div className={Styles.ContentWrapper}>
                    <span>{`Are you sure you want to change the status to ${statusChangeTo}?`}</span>
                </div>
                <div className={Styles.ButtonWrapper}>
                    <Button variant='outlined' onClick={()=> setChangePublish(false)} className={Styles.CancelButton}>Cancel</Button>
                    <Button variant='contained' onClick={handleChange} className={Styles.SaveButton} >Save</Button>
                </div>
            </div>
        </div>
    )
}