import React from "react";
import SearchbarSA from "../../../../utils/SearchbarSA/SearchbarSA";
import "./AddStoreProducts.css";
import {
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  Tooltip,
  Stack,
  Avatar,
  Button,
  AvatarGroup,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { alpha } from "@mui/material/styles";

function createData(coordinatePoint, storeName, owner, phoneNumber, employee) {
  return {
    coordinatePoint,
    storeName,
    owner,
    phoneNumber,
    employee,
  };
}

const rows = [
  createData(
    "1.11.3345.133",
    "UD Sinar Jaya",
    "Anastasia Prissia",
    "0812-8828-8282",
    ""
  ),
  createData(
    "1.11.3345.133",
    "UD Sinar Jaya",
    "Anastasia Prissia",
    "0812-8828-8282",
    ""
  ),
  createData(
    "1.11.3345.133",
    "UD Sinar Jaya",
    "Anastasia Prissia",
    "0812-8828-8282",
    ""
  ),
  createData(
    "1.11.3345.133",
    "UD Sinar Jaya",
    "Anastasia Prissia",
    "0812-8828-8282",
    ""
  ),
  createData(
    "1.11.3345.133",
    "UD Sinar Jaya",
    "Anastasia Prissia",
    "0812-8828-8282",
    ""
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "coordinatePoint",
    numeric: false,
    disablePadding: false,
    label: "Coordinate Point",
  },
  {
    id: "storeName",
    numeric: true,
    disablePadding: false,
    label: "Store Name",
  },
  {
    id: "owner",
    numeric: true,
    disablePadding: false,
    label: "Owner",
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "employee",
    numeric: true,
    disablePadding: false,
    label: "Employee",
  },
  {
    id: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Add Store
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Add Selected">
          <Button
            style={{
              width: 150,
              height: 36,
              backgroundColor: "#1571DE",
              color: "#FFFFFF",
              textTransform: "none",
            }}
          >
            Add Marked
          </Button>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
};

export default function AddStoreProducts() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("coordinatePoint");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.coordinatePoint);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, coordinatePoint) => {
    const selectedIndex = selected.indexOf(coordinatePoint);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, coordinatePoint);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (coordinatePoint) =>
    selected.indexOf(coordinatePoint) !== -1;

  return (
    <div className="add__store-products">
      <div className="store__products-item-top">
        <SearchbarSA />
      </div>
      <div className="store__products-table">
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", backgroundColor: "#FAFAFA" }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table sx={{ width: "100%" }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.coordinatePoint);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, row.coordinatePoint)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.coordinatePoint}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                alt={row.coordinatePoint}
                                src=""
                                variant="square"
                              />
                              <Typography variant="subtitle2" noWrap>
                                {row.coordinatePoint}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{row.storeName}</TableCell>
                          <TableCell align="left">{row.owner}</TableCell>
                          <TableCell align="left">{row.phoneNumber}</TableCell>
                          <TableCell align='left'>
                            <Stack alignItems="center" direction="row">
                              <AvatarGroup total={4}>
                                {/* {render employee photo here} */}
                                <Avatar />
                                <Avatar />
                                <Avatar />
                                <Avatar />
                              </AvatarGroup>
                            </Stack>
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              style={{
                                width: 53,
                                height: 36,
                                backgroundColor: "#1571DE",
                                color: "#FFFFFF",
                                textTransform: "none",
                              }}
                              onClick={() => {
                                console.log("clicked");
                              }}
                            >
                              Add
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>
    </div>
  );
}
