/*eslint-disable no-unused-vars*/

import React, { useEffect, useState } from "react";
import Styles from "./DetailGadai.module.css";
import { Avatar, Checkbox } from "@mui/material";
import PermataLogo from "../../../../../assets/images/logo-permata.png";
import Receipt from "../../../../../assets/images/Subtract.png";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";

export default function DetailGadai(props) {
  const { data, dataById } = props;
  const [storedData, setStoredData] = useState({});

  // console.log(data)

  // const definedPawn = useMemo(() => {
  //   return data?.map(each => ({
  //     Name: each?.employeeName,
  //     NRK: '201211159',
  //     "Birth Place":  "Tegar Panyang",
  //     "Birth Date" : "17 Mei 1995",
  //     "ID Number" : "1115011105970001",
  //     Address: 'Mampang Prapatan, Jakarta Selatan',
  //     'Contract type': "PKWT",
  //     'Product brand' : "Yamaha",
  //     'Product variant' : "Nmax - 150",
  //     'Product issue year ' : "2017",
  //     'Product Color': 'Abu-abu Hitam'
  //   }))
  //   return setStoredData({
  //     Name: data?.employeeName,
  //     NRK: '201211159',
  //     "Birth Place":  "Tegar Panyang",
  //     "Birth Date" : "17 Mei 1995",
  //     "ID Number" : "1115011105970001",
  //     Address: 'Mampang Prapatan, Jakarta Selatan',
  //     'Contract type': "PKWT",
  //     'Product brand' : "Yamaha",
  //     'Product variant' : "Nmax - 150",
  //     'Product issue year ' : "2017",
  //     'Product Color': 'Abu-abu Hitam',
  //     status: data?.status
  //   })
  // },[data])

  useEffect(() => {
    setStoredData({
      Name: data?.name,
      NRK: dataById?.employeeId,
      // "Birth Place": dataById?.birthPlace,
      "Birth Date": dataById?.birthOfDate,
      "ID Number": dataById?.user?.idNumber,
      "Survey Address": dataById?.address,
      "Contract type": dataById?.contractType,
      "Product Brand": dataById?.brand,
      "Product Variant": dataById?.productVariant,
      "Product issue year ": dataById?.productYear,
      "Product Color": dataById?.productColor,
      "Owner": dataById?.owner,
      "Product Tax": dataById?.productTax,
      "Plate Number" : dataById?.plateNumber
    });
  }, [data, dataById]);

  return (
    <div className={Styles.Container}>
      <div className={Styles.Wrapper}>
        <div className={Styles.GroupControl}>
          <div className={Styles.Group}>
            <Avatar
              sx={{ width: 70, height: 70 }}
              src={data?.profilePicture ? data.profilePicture : ""}
            />
            <div className={Styles.GroupDetail}>
              <span>{data?.name}</span>
              <span>Product Design</span>
              <span>{data?.title}</span>
            </div>
          </div>
          <div className={Styles.Group}>
            <div
              className={Styles.StatusControl}
              style={{
                backgroundColor:
                  (data?.status === "APPROVED" && "#498E0F") ||
                  (data?.status === "FINISHED" && "#1571DE") ||
                  (data?.status === "REJECTED" && "#F02C01") ||
                  (data?.status === "PENDING" && "#C1992F"),

                  // REJECTED PENDING APPROVED FINISHED
              }}
            >
              <span>
                {(data?.status === "APPROVED" && "Active") ||
                  (data?.status === "FINISHED" && "Finished") ||
                  (data?.status === "REJECTED" && "Reject") ||
                  (data?.status === "PENDING" && "Pending")}
              </span>
            </div>
          </div>
        </div>

        <div className={Styles.Breaker} />

        <div className={Styles.GroupControlDetails}>
          <div className={Styles.Label}>
            <span>Pawn Detail</span>
          </div>

          <div className={Styles.WrapperGroup}>
            <div className={Styles.Details}>
              <div className={Styles.Heading}>
                <div className={Styles.Profile}>
                  <span>Employee Benefit Program</span>
                  <span>Gadai</span>
                  <span>{`${data?.division} - ${data?.client}`}</span>
                </div>

                <div className={Styles.Logo}>
                  <img src={PermataLogo} alt="permata-logo" />
                </div>
              </div>
              <div className={Styles.Breaker} />

              <div className={Styles.PawnCodeBox}>
                <div className={Styles.Keys}>
                  <span>Gadai Code</span>
                </div>

                <div className={Styles.Values}>
                  <span>{dataById?.uniqueId}</span>
                </div>
              </div>

              {Object.keys(storedData)?.map((key, idx) => (
                <div className={Styles.ObjectGroup} key={idx}>
                  <div className={Styles.Keys}>
                    <span>{key}</span>
                  </div>

                  <div className={Styles.Values}>
                    <span>{storedData[key]}</span>
                  </div>
                </div>
              ))}
            </div>

            {data?.status === "REJECTED" || data?.status === "PENDING" ? null : (
              <div className={Styles.Others}>
                <div className={Styles.Disbursed}>
                  <img src={Receipt} alt="receipt" />
                  {/* <span className={Styles.LeftCropper}></span> */}
                  <span className={Styles.Divider}></span>
                  {/* <span className={Styles.RightCropper}></span> */}

                  <div className={Styles.DisbursedDetail}>
                    <div className={Styles.DisbursedHeading}>
                      <span>Disbursed Detail</span>
                    </div>
                    <div className={Styles.BreakerReceipt} />
                    <div className={Styles.Info}>
                      <div className={Styles.InfoValue}>
                        <span>Tenor</span>
                        <span>{ dataById?.staffLoan[0]?.tenor }</span>
                      </div>
                      <div className={Styles.InfoValue}>
                        <span>Type</span>
                        <span>{ dataById?.productType }</span>
                      </div>
                      <div className={Styles.InfoValue}>
                        <span>Apply Amount</span>
                        <span>Rp.{dataById?.staffLoan[0]?.amount?.apply?.toLocaleString()}</span>
                      </div>
                      <div className={Styles.InfoValue}>
                        <span>Monthly Installment</span>
                        <span>Rp.{dataById?.staffLoan[0]?.amount?.installment?.toLocaleString()}</span>
                      </div>
                    </div>

                    <div className={Styles.InfoTotal}>
                      <div className={Styles.InfoValueTotal}>
                        <span>Disbursed Amount</span>
                        <span>Rp.{dataById?.staffLoan[0]?.amount?.disburse?.toLocaleString()}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Styles.StepperList}>
                  {dataById?.staffLoan[0]?.installments?.map((each, idx) => (
                    <div className={Styles.Itinerary} key={idx}>
                      <div className={Styles.ItineraryProcess}>
                        {each.paidDate ? (
                          <Checkbox
                            disabled
                            icon={<CheckCircleIcon sx={{ color: "#1571DE" }} />}
                          />
                        ) : (
                          <Checkbox
                            disabled
                            icon={
                              <RadioButtonUnchecked sx={{ color: "#c2c2c2" }} />
                            }
                          />
                        )}
                        <div
                          className={`${
                            idx === dataById?.staffLoan[0]?.installments?.length - 1
                              ? Styles.NoDashedProgress
                              : Styles.DashedProgress
                          }`}
                        />
                      </div>
                      <div className={`${Styles.ItineraryDetails}`}>
                        <div className={Styles.DetailValue}>
                          <span>Bulan { idx + 1 }</span>
                          <span>{ moment(new Date( each.dueDate )).format("LL")}</span>
                        </div>
                        {each?.paidDate ? (
                          <div className={Styles.DetailStatus}>
                            <span>Lunas</span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
