import { Button, Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { AppAttendanceEditMenu } from "../../../../components/SuperAdmin/_attendance";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { fetchAttendanceById } from "../../../../redux/actions/SuperAdmin/attendancesActions";
import Styles from './AttendanceEditScheme.module.css';

export default function AttendanceEditScheme() {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const {schemaAttendance} = useSelector(state => state.attendances);

  // const attendanceId = useSelector((state) => state.attendances.schemaAttendance[id])
  useEffect(() => {
    dispatch(fetchAttendanceById(token, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container maxWidth="xl">
      <div className={Styles.ContainerSetting}>
        <Helmet>
          <title>MyWorkSpace | Attendance Settings</title>
        </Helmet>
        <div className={Styles.HeaderSetting}>
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/attendance")}
              style={{ textTransform: "none" }}
            >
              <span>Attendance</span>
            </Button>
            <span>Edit {schemaAttendance?.[id]?.name || "Scheme"}</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppAttendanceEditMenu attendanceData={schemaAttendance} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
