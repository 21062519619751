import axios from "axios";
import * as types from '../../type'
import {API} from "../../api";

export const fetchAllDataEmployeeInternal = (token) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_ALL_DATA_EMPLOYEE_BEGIN})
    axios
      .get(`${API}/auth/tko`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_DATA_EMPLOYEE_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_ALL_DATA_EMPLOYEE_ERROR, payload: err.response})
        console.log(err);
      });
  };
};

// export const fetchAllDataEmployeeByRange = (token, clientName, startDate, endDate) => {
//   return (dispatch) => {
//     dispatch({ type: types.FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_BEGIN})
//     axios
//       .get(`${API}/absence/totalAll?name=${clientName}&start=${startDate}&end=${endDate}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then((res) => {
//         dispatch({
//           type: types.FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_SUCCESS,
//           payload: res.data.data,
//         });
//       })
//       .catch((err) => {
//         dispatch({ type: types.FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_ERROR, payload: err.response})
//         console.log(err);
//       });
//   };
// };


// https://dev.myworkspace.id/api/v3/attendance/admin/downloadAttendance?startDate=2022-12-15&endDate=2022-12-18&code=PIS
export const fetchAllDataEmployeeByRange = (token, startDate, endDate, clientName) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_BEGIN})
    axios
    .get(`${API}/attendance/admin/downloadAttendance?startDate=${startDate}&endDate=${endDate}&clientName=${clientName}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_SUCCESS,
          // payload: res?.data?.response?.users,
          payload: res?.data?.response,
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_ERROR, payload: err.response})
        console.log(err);
      });
  };
};

export const fetchAllDataEmployeeByRangeDateId = (token,startDate, endDate, clientCode) => {
  return (dispatch) => {
    axios
    .get(`${API}/attendance/admin/client?clientCode=${clientCode}&startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        Authorization: token,
      },
    })
      // .get(`${API}/attendance/admin/${employeeId}?startDate=${startDate}&endDate=${endDate}`, {
      //   headers: {
      //     Authorization: `${token}`,
      //   },
      // })
      .then((res) => {
        // console.log(res?.data?.response?.users?.attendance)
        dispatch({
          type: types.FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_DATE_ID,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
// api for download excel
export const downloadDataEmployeeInternal = (token) => {
  return (dispatch) => {
    axios
      .get(
        `${API}/absence/downloadExcelRange`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        { responseType: "blob" }
      )
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_DATA_EMPLOYEE,
          payload: res.data.data,
        });
        // const url = window.URL.createObjectURL(new Blob([res.data.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", `${Date.now()}.xlsx`);
        // document.body.appendChild(link); 
        // link.click(); //
        // document.body.removeChild(link); 
        // window.URL.revokeObjectURL(url); 
      })

      // to download pdf 
      // .then(() => response.blob())
      // .then((blob) => {
        // Create blob link to download
        // const url = window.URL.createObjectURL(new Blob([blob]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", `FileName${Date.now()}.pdf`);
        // Append to html link element page
        // document.body.appendChild(link);

        // Start download
        // link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
      // })
      // 
      .catch((err) => {
        console.log(err);
      });
  };
};

// DATA ATTENDANCE REPORT 
export const fetchAllDataAttendanceReport = (token, clientName, startDate, endDate) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_DATA_ATTENDANCE_REPORT_BEGIN})
    axios
      .get(`${API}/absence/totalAll?name=${clientName}&start=${startDate}&end=${endDate}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_DATA_ATTENDANCE_REPORT_SUCCESS,
          payload: res.data,
        });
        // console.log(res.data, 'cek <<<')
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_DATA_ATTENDANCE_REPORT_ERROR, payload: err.response})
        console.log(err);
      });
  };
};
