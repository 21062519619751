/* eslint-disable no-unused-vars */

import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
// import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { filter } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import fetchClientCode from "../../../../../async/client/fetchClientCode";
import fetchPawns from "../../../../../async/employeeeBenefit/fetchPawns";
import DateRangePickerComponent from "../../../../utils/DateRangePicker/DateRangePicker";
import LoadingAnimation from "../../../../utils/LoadingAnimation/LoadingAnimation";
import SearchbarSA from "../../../../utils/SearchbarSA/SearchbarSA";
// import ExportCSV from "../../../_attendance/tabs/DataAttendance/ExportData";
import ExportDataExcel from "../../../_attendance/tabs/DataAttendance/ExportDataExcel";
import Scrollbar from "../../../_user/TableUsers/Scrollbar";
import Styles from "./Gadai.module.css";

const datas = [
  {
    employeeName: "Jane Cooper",
    employeeId: 1,
    company: "PT. Arla",
    address: "Mayestik",
    placeOfBirth: "12 Januarty 2022",
    uniqueId: "12345678",
    product: "GADAI",
    productType: "MOTOR",
    productColor: "Merah",
    productYear: "2022",
    remark: "Gadai",
    brand: "Yamaha",
    clientCode: "PIS",
    status: "PENDING",
    applyDate: "21 Desember 2021",
    region: "JAKARTA REGION",
    // createdAt: new Date(),
  },
  {
    employeeName: "John Doe",
    employeeId: 2,
    company: "PT. Arla",
    address: "Mayestik",
    placeOfBirth: "12 January 2022",
    uniqueId: "12345678",
    product: "GADAI",
    productType: "MOTOR",
    productColor: "Merah",
    productYear: "2022",
    remark: "Gadai",
    brand: "Yamaha",
    clientCode: "PIS",
    status: "FINISHED",
    applyDate: "21 Desember 2021",
    region: "Jakarta Region",
    installments: [
      {
        no: 1,
        dueDate: "25 January 2023",
        paidDate: "06 February 2023",
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
      {
        no: 2,
        dueDate: "25 January 2023",
        paidDate: "06 February 2023",
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
      {
        no: 3,
        dueDate: "25 January 2023",
        paidDate: "06 February 2023",
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
      {
        no: 4,
        dueDate: "25 January 2023",
        paidDate: "06 February 2023",
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
    ],
    // applyDate: new Date(),
    // createdAt: new Date(),
  },
  {
    employeeName: "Jane Cooper",
    employeeId: 3,
    company: "PT. Arla",
    address: "Mayestik",
    placeOfBirth: "12 January 2022",
    uniqueId: "12345678",
    product: "GADAI",
    productType: "MOTOR",
    productColor: "Merah",
    productYear: "2022",
    remark: "Gadai",
    brand: "Yamaha",
    clientCode: "PIS",
    status: "ACTIVE",
    applyDate: "21 Desember 2021",
    region: "Jakarta Region",
    installments: [
      {
        no: 1,
        dueDate: "25 January 2023",
        paidDate: "06 February 2023",
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
      {
        no: 2,
        dueDate: "25 January 2023",
        paidDate: "06 February 2023",
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
      {
        no: 3,
        dueDate: "24 Februari 2023",
        paidDate: null,
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
      {
        no: 4,
        dueDate: "24 March 2023",
        paidDate: null,
        principalAmount: 716667,
        rate: 92450,
        total: 809117,
      },
    ],
    // applyDate: new Date(),
    // createdAt: new Date(),
  },
  {
    employeeName: "Jane Cooper",
    employeeId: 4,
    company: "PT. Arla",
    address: "Mayestik",
    placeOfBirth: "12 January 2022",
    uniqueId: "12345678",
    product: "GADAI",
    productType: "MOTOR",
    productColor: "Merah",
    productYear: "2022",
    remark: "Gadai",
    brand: "Yamaha",
    clientCode: "PIS",
    status: "FAILED",
    applyDate: "21 Desember 2021",
    region: "Jakarta Region",
    // applyDate: new Date(),
    // createdAt: new Date(),
  },
];

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const TABLE_HEAD = [
  {
    id: "employeeName",
    numeric: false,
    label: "Employee Name",
    alignRight: false,
  },
  { id: "company", numeric: false, label: "Company", alignRight: false },
  { id: "applyDate", numeric: false, label: "Apply Date", alignRight: false },
  { id: "code", numeric: false, label: "Gadai Code", alignRight: false },
  { id: "productType", numeric: true, label: "Type", alignRight: false },
  { id: "status", numeric: false, label: "Status", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
// eslint-disable-next-line no-unused-vars
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        <TableCell padding="none" style={{ paddingLeft: 0 }}>
          {/* <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              disabled={rowCount !== values?.users?.length}
              sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
            /> */}
        </TableCell>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function Gadai() {
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const clientName = localStorage.getItem("clientName");
  const [selected, setSelected] = useState(clientName);
  const [page, setPage] = useState(0);
  const [pawns, setPawns] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selectedInput, setSelectedInput] = useState("");
  const [listGadai, setListGadai] = useState("");
  const [dataClientCode, setDataClientCode] = useState([]);
  const [valueStartDate, setValueStartDate] = useState(moment().format("LL"));
  const [valueEndDate, setValueEndDate] = useState(moment().format("LL"));
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  const persistClientName = useMemo(() => {
    let persistClient = window.sessionStorage.getItem("selectedClientGadai");
    let clientName;

    if (persistClient) {
      clientName = persistClient;
    } else {
      clientName = selected;
    }

    return clientName;
  }, [selected]);

  const dateValueStart = useMemo(() => {
    let sessionStartDate = window.sessionStorage.getItem("startDateGadai");
    let firstDate;

    if (sessionStartDate) {
      firstDate = sessionStartDate;
    } else {
      firstDate = valueStartDate;
    }

    return firstDate;
  }, [valueStartDate]);

  const dateValueEnd = useMemo(() => {
    let sessionEndDate = window.sessionStorage.getItem("endDateGadai");
    let secondDate;

    if (sessionEndDate) {
      secondDate = sessionEndDate;
    } else {
      secondDate = valueEndDate;
    }

    return secondDate;
  }, [valueEndDate]);

  // let query = `?startDate=${moment(dateValueStart).format("YYYY-MM-DD")}&endDate=${moment(dateValueEnd).format("YYYY-MM-DD")}&client=${persistClientName}`;

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCode(response?.map((el) => el?.name));
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await fetchPawns(
          token,
          moment(dateValueStart).format("YYYY-MM-DD"),
          moment(dateValueEnd).format("YYYY-MM-DD"),
          persistClientName
        );
        setLoading(false);
        setPawns(data);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [persistClientName, dateValueStart, dateValueEnd, token]);

  const handleChangeStartDate = (newValue) => {
    // setArrayData(true);
    setValueStartDate(newValue);
    window.sessionStorage.setItem("startDateGadai", newValue);
  };

  const handleChangeEndDate = (newValue) => {
    // setArrayData(true);
    setValueEndDate(newValue);
    window.sessionStorage.setItem("endDateGadai", newValue);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInput = (event) => {
    setListGadai(event.target.value);
  };

  const handleSearch = (value) => {
    setSelectedInput(value);
  };

  const handleSelectedClient = (value) => {
    window.sessionStorage.setItem("selectedClientGadai", value);
    setSelected(value);
    // setArrayData(false);
    setListGadai("");
  };

  const formatMoment = (date) => {
    return moment(new Date(date).getTime()).format("DD MMMM YYYY");
  };
  return (
    <div className={Styles.Container}>
      <div className={Styles.Header}>
        <SearchbarSA
          value={listGadai}
          onChange={handleSearchInput}
          searchWidth="254px"
          placeholder="Search by name"
        />
        <DateRangePickerComponent
          valueStartDate={dateValueStart}
          valueEndDate={dateValueEnd}
          handleChangeStartDate={handleChangeStartDate}
          handleChangeEndDate={handleChangeEndDate}
        />
        <Autocomplete
          disablePortal
          options={dataClientCode}
          value={persistClientName}
          inputValue={selectedInput}
          sx={{ width: 300 }}
          onInputChange={(e, value) =>
            value === null ? handleSearch("") : handleSearch(value)
          }
          onChange={(e, value) =>
            value === null
              ? handleSelectedClient("")
              : handleSelectedClient(value)
          }
          renderInput={(params) => <MuiTextField {...params} size="small" />}
        />
        <ExportDataExcel
          data={pawns?.map((el, ind) => ({
            "No.": ind + 1,
            "Employee Name": el.name,
            Company: el?.client,
            Type: el?.type,
            "Apply Date": el.applyDate
              ? moment(el?.applyDate).format("L")
              : null,
            Status:
              (el?.status === "APPROVED" && "Active") ||
              (el?.status === "FINISHED" && "Finished") ||
              (el?.status === "REJECTED" && "Reject") ||
              (el?.status === "PENDING" && "Pending"),
          }))}
          filename={`Pawn Report ${new Date().toLocaleDateString()}`}
          initial="Download Excel"
        />
      </div>

      {loading ? (
        <div className={Styles.Loading}>
          <div className={Styles.LoadingWidth}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={Styles.ContainerTable}>
          <Card sx={{ marginTop: 2 }}>
            <div className="wrapper-table-data-loan">
              <Scrollbar>
                <TableContainer>
                  <Table
                    sx={{
                      width: "100%",
                      maxHeight: "100%",
                    }}
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: 5,
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <EnhancedTableHead
                      numSelected={pawns?.length}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      // values={pawns}
                      rowCount={
                        pawns?.filter(
                          (el) =>
                            el?.name
                              ?.toLowerCase()
                              .includes(listGadai?.toLowerCase()) ||
                            el?.client
                              ?.toLowerCase()
                              .includes(listGadai?.toLowerCase()) ||
                            el?.status
                              ?.toLowerCase()
                              .includes(listGadai?.toLowerCase())
                        ).length
                      }
                    />
                    {/* <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={dataOnGoingLoan.length}
                onRequestSort={handleRequestSort}
              /> */}
                    <TableBody>
                      {stableSort(pawns, getComparator(order, orderBy))
                        .filter(
                          (el) =>
                            el?.name
                              ?.toLowerCase()
                              .includes(listGadai?.toLowerCase()) ||
                            el?.client
                              ?.toLowerCase()
                              .includes(listGadai?.toLowerCase()) ||
                            el?.status
                              ?.toLowerCase()
                              .includes(listGadai?.toLowerCase())
                          /* (el.tenor + " Month")?.toString().toLowerCase().includes(listDataLoan?.toLocaleLowerCase()) ||
                          moment(el.DateResponse).format("L").toLocaleLowerCase().includes(listDataLoan?.toLocaleLowerCase()) */
                        )
                        // .sort((a, b) => new Date(a.LoanTime) - new Date(b.LoanTime))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((each, idx) => {
                          // eslint-disable-next-line no-lone-blocks
                          {
                            /* const labelId = `enhanced-table-checkbox-${id}`; */
                          }
                          return (
                            <TableRow hover key={idx} tabIndex={-1}>
                              <TableCell></TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Avatar
                                    src={
                                      each?.profilePicture
                                        ? each?.profilePicture
                                        : ""
                                    }
                                  />
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {each?.name.toLowerCase()}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: "#0A0A0A",
                                  }}
                                >
                                  {each?.client}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                {formatMoment(each.applyDate)}
                              </TableCell>
                              <TableCell>{each?.code}</TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize " }}
                              >
                                {each.product
                                  ? each?.product?.toLowerCase()
                                  : "-"}
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  className="status__styleSA"
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    backgroundColor:
                                      (each.status === "APPROVED" &&
                                        "#F4FFF2") ||
                                      (each.status === "FINISHED" &&
                                        "#DEFEFF") ||
                                      (each.status === "REJECTED" &&
                                        "#FFF4F2") ||
                                      (each.status === "PENDING" && "#FFF9E9"),
                                    color:
                                      (each.status === "APPROVED" &&
                                        "#498E0F") ||
                                      (each.status === "FINISHED" &&
                                        "#1571DE") ||
                                      (each.status === "REJECTED" &&
                                        "#D52701") ||
                                      (each.status === "PENDING" && "#C1992F"),
                                    borderColor:
                                      (each.status === "APPROVED" &&
                                        "#22970F") ||
                                      (each.status === "FINISHED" &&
                                        "#1571DE") ||
                                      (each.status === "REJECTED" &&
                                        "#D52701") ||
                                      (each.status === "PENDING" && "#C1992F"),
                                  }}
                                >
                                  {(each?.status === "APPROVED" &&
                                    "Approved") ||
                                    (each?.status === "FINISHED" &&
                                      "Finished") ||
                                    (each?.status === "REJECTED" && "Reject") ||
                                    (each?.status === "PENDING" && "Pending")}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Button
                                  style={{
                                    textTransform: "none",
                                    fontSize: 16,
                                    fontWeight: 500,
                                  }}
                                  onClick={() => {
                                    history.push({
                                      pathname: `/superadmin/pawn-detail/${each.code}`,
                                      state: { data: each },
                                    });
                                  }}
                                >
                                  See Detail
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={
                  pawns?.filter(
                    (el) =>
                      el?.name
                        ?.toLowerCase()
                        .includes(listGadai?.toLowerCase()) ||
                      el?.client
                        ?.toLowerCase()
                        .includes(listGadai?.toLocaleLowerCase()) ||
                      el?.status
                        ?.toLowerCase()
                        .includes(listGadai?.toLocaleLowerCase())
                  ).length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Card>
        </div>
      )}
    </div>
  );
}
