/* eslint-disable no-unused-vars */


import { Button, Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { AppLocationEditSA } from "../../../../components/SuperAdmin/_database";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { getLocationById } from "../../../../redux/actions/SuperAdmin/locationActions";

export default function EditLocationSA() {
    const location = useLocation()
    const locationData= useSelector((state) => location?.state?.data)
    const clientName= useSelector((state) => location?.state?.clientName)
    const dispatch = useDispatch()
    const token = localStorage.getItem('accessToken')
    const { schemaLocation } = useSelector((state) => state.locations)
    const history = useHistory();
    const {id} = useParams()



    useEffect(() => {
        dispatch(getLocationById(token, id))
    }, [dispatch, id, token])

    return (
        <Container maxWidth="xl">
            <div className="container-client-settings">
                <Helmet>
                    <title>MyWorkSpace | Edit Location Data</title>
                </Helmet>
                <Breadcrumb>
                    <Button
                        onClick={() => history.push("/superadmin/database")}
                        style={{ textTransform: "none" }}
                    >
                        <span style={{ fontSize: 20, color: "#255bfc" }}>Master Data</span>
                    </Button>
                    <span style={{ fontSize: 20, color: "#255bfc" }}>Edit Location Data</span>
                </Breadcrumb>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} style={{ padding: '20px 0' }}>
                        <AppLocationEditSA clientName={clientName} locationData={locationData} id={id} />
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}
