/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import "./Attendance-Detail.css";
import { Button, Container, Grid, } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppAttendanceSettingView } from "../../../../components/SuperAdmin/_attendance";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { fetchAttendanceById } from "../../../../redux/actions/SuperAdmin/attendancesActions";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import EDIT_ICON from "../../../../assets/images/create.png";
import ModalDeleteAttendance from "../../../../components/SuperAdmin/_attendance/tabs/ModalTabs/ModalTabs";


export default function AttendanceViewScheme() {
  const location = useLocation();
  const attendanceId = location.state.dataSchema;
  const token = localStorage.getItem("accessToken");
  const [selectedAttendance, setSelectedAttendance] = useState({}); //
  const {schemaAttendance} = useSelector(state => state.attendances);  
  
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchAttendanceById(token, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Attendance Settings</title>
        </Helmet>
        <div className="top__items-settings" style={{justifyContent: 'space-between'}}>
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/attendance")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Attendance</span>
            </Button>
            <span style={{ fontSize: 20, color: "#255bfc" }}>View {schemaAttendance?.[id]?.name}</span>
          </Breadcrumb>
          <div className="item-edit-delete-settings" style={{display: 'flex', gap : 10}}>
            <Button
              sx={{ "&:hover": { backgroundColor: 'transparent' }, padding: 0 }}
              onClick={() => {
                dispatch(
                  fetchAttendanceById(token, id)
                );
                history.push({
                  pathname: `/superadmin/attendance-edit-scheme/${id}`,
                  state: {
                    dataSchema: attendanceId,
                  },
                });
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 6,
                cursor: 'pointer',

              }}>
              <span style={{
                fontSize: 14,
                color: "#1571DE",
                textTransform: 'capitalize'
              }}>Edit</span>
              <img src={EDIT_ICON} alt="edit" style={{ height: '17px', width: '15px' }} />
            </Button>
            <Button
              sx={{ "&:hover": { backgroundColor: 'transparent' } , padding: 0}}
              onClick={(event) => {
                event.preventDefault();
                setSelectedAttendance(attendanceId);
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 6,
                cursor: 'pointer',
                marginRight: 8
              }}
            >
              <span style={{
                fontSize: 14,
                color: "#D1421A",
                textTransform: 'capitalize'
              }}>Delete</span>
              <img src={DELETE_ICON} alt="delete" style={{ height: '17px', width: '15px' }} />
            </Button>

          </div>
          {selectedAttendance._id && (
            <ModalDeleteAttendance
              active={selectedAttendance}
              onOpen={setSelectedAttendance}
              description="Are you sure you want to delete this scheme?"
              label="Delete Scheme"
              pathName="/superadmin/attendance"
            />
          )}
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppAttendanceSettingView dataSchema={schemaAttendance} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
