import React, { useEffect } from "react";
import "./LoanSA.css";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import { LoanSASettings } from "../../../components/SuperAdmin/_client";
import { fetchAllSchemaLoan } from "../../../redux/actions/SuperAdmin/loanActions";
export default function LoanSA() {
  const dispatch = useDispatch();
  const token = localStorage.getItem('accessToken');
  const { loanSchema, loadingLoanSchema } = useSelector((state) => state.loan);

  useEffect(() => {
      dispatch(fetchAllSchemaLoan(token))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Loan</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          Loan
        </h2>
      </div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <LoanSASettings dataLoan={ loanSchema } loading={ loadingLoanSchema }/>
        </Grid>
      </Grid>
    </Container>
  );
}
