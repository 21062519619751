/* eslint-disable no-unused-vars */
import {
  Document,
  Page,
  PDFViewer,
  Text,
  Image,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useMemo, useState } from "react";
import Styles from "./ResignFormViewStyle.jsx";
import Style from "./ResignFormView.module.css";
import COMPANY_ICON from "../../../../assets/images/employee-client.png";
import BRIEFCASE_ICON from "../../../../assets/images/employee-job.png";
import USER_ICON from "../../../../assets/images/employee-user.png";
import NRK_ICON from "../../../../assets/images/nrk-employee.png";
import PHONE_ICON from "../../../../assets/images/phone-icon.png";
// import LOCATION_ICON from "../../../../assets/images/location-icon.png";
import CALENDAR_ICON from "../../../../assets/images/calendar-icon-request.png";
import ALIGN_LEFT_ICON from "../../../../assets/images/align-left-icon-request.png";
// import DOWNLOAD_ICON from "../../../../assets/images/Download.png";
import PDF_ICON from "../../../../assets/images/pdf-download.png";
import moment from "moment";
import LoadingFMCG from "../../../utils/LoadingFMCG/LoadingFMCG.jsx";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation.jsx";
import { reject } from "lodash";
// import pdfjsLib from "pdfjs-dist";

export default function ResignFormViewByPdf(props) {
  const { data } = props;
  const PDFJS = window.pdfjsLib;
  const [pdf, setPdf] = useState("");
  const [pdfRendering, setPdfRendering] = useState("");
  const [pageRendering, setPageRendering] = useState(0);
  const [width, setWidth] = useState(0);
  const [images, setImages] = useState([]);
  const [height, setHeight] = useState(0);

  const [isFetched, setIsFetched] = useState(0);

  const [pdf2, setPdf2] = useState("");
  const [pdfRendering2, setPdfRendering2] = useState("");
  const [pageRendering2, setPageRendering2] = useState("");
  const [width2, setWidth2] = useState(0);
  const [images2, setImages2] = useState([]);
  const [height2, setHeight2] = useState(0);

  const [pdf3, setPdf3] = useState("");
  const [pdfRendering3, setPdfRendering3] = useState("");
  const [pageRendering3, setPageRendering3] = useState("");
  const [width3, setWidth3] = useState(0);
  const [images3, setImages3] = useState([]);
  const [height3, setHeight3] = useState(0);

  let urlResignLetter = useMemo(() => {
    return data?.resignLetter?.url;
  }, [data]);

  let urlExitClearanceLetter = useMemo(() => {
    return data?.exitClearance?.file?.url;
  }, [data]);

  let urlExitInterview = useMemo(() => {
    return data?.exitInterview?.file?.url;
  }, [data]);

  async function renderPage() {
    setPageRendering(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");
    // let canv = document.querySelector(".canv");
    for (let i = 1; i <= pdf.numPages; i++) {
      var page = await pdf.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };
      setWidth(viewport.width);
      setHeight(viewport.height);
      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages(imagesList);
    setPageRendering(false);
    setIsFetched((prev) => prev + 1);
  }

  async function showPdf(url) {
    try {
      setPdfRendering(true);
      var _PDF_DOC = await PDFJS.getDocument({
        url: urlResignLetter,
      });
      setPdf(_PDF_DOC);
      setPdfRendering(false);
      // document.getElementById("file-to-upload").value = "";
    } catch (error) {
      alert(error.message);
    }
  }

  async function renderPage2() {
    setPageRendering2(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");

    for (let i = 1; i <= pdf2.numPages; i++) {
      var page = await pdf2.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };
      setWidth2(viewport.width);
      setHeight2(viewport.height);
      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages2(imagesList);
    setPageRendering2(false);

    setIsFetched((prev) => prev + 1);
  }

  async function showPdf2(url) {
    try {
      setPdfRendering2(true);
      var _PDF_DOC = await PDFJS.getDocument({
        url: urlExitInterview,
      });
      setPdf2(_PDF_DOC);
      setPdfRendering2(false);
    } catch (error) {
      alert(error.message);
    }
  }

  async function renderPage3() {
    setPageRendering3(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");

    for (let i = 1; i <= pdf3.numPages; i++) {
      var page = await pdf3.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };
      setWidth3(viewport.width);
      setHeight3(viewport.height);
      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages3(imagesList);
    setPageRendering3(false);
    setIsFetched((prev) => prev + 1);
  }

  async function showPdf3(url) {
    try {
      setPdfRendering3(true);
      var _PDF_DOC = await PDFJS.getDocument({
        url: urlExitClearanceLetter,
      });
      setPdf3(_PDF_DOC);
      setPdfRendering3(false);
    } catch (error) {
      alert(error.message);
    }
  }


  useEffect(() => {
    if (data?.resignLetter?.url) showPdf();
    if (data?.exitInterview?.file?.url) showPdf2();
    if (data?.exitClearance?.file?.url) showPdf3();
    // if (null) showPdf3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    pdf && renderPage();
    // eslint-disable-next-line
  }, [pdf]);

  useEffect(() => {
    pdf2 && renderPage2();
    // eslint-disable-next-line
  }, [pdf2]);
  useEffect(() => {
    pdf3 && renderPage3();
    // eslint-disable-next-line
  }, [pdf3]);


  let arr = [data?.resignLetter?.url, data?.exitInterview?.file?.url, data?.exitClearance?.file?.url].filter(el => el !== null).length;

  return data && (isFetched === arr) ? (
    <PDFViewer style={{ width: window.innerWidth, height: "100vh" }}>
      <DataToView
        data={data}
        resignLetter={images[0]}
        exitInterviewFile={images2[0]}
        exitClearanceFile={images3[0]}
      />
    </PDFViewer>
  ) : (
    <div className={Style.LoadingContainer}>
      <div className={Style.LoadingWidth}>
        <LoadingAnimation />
      </div>
    </div>
  );
}

const DataToView = ({
  data,
  resignLetter,
  exitInterviewFile,
  exitClearanceFile,
}) => {
  // console.log(data, "pdf view");

  let dateExitClearanceHC = data?.exitClerance?.HC?.exitInterview?.date;

  return (
    <Document>
      <Page style={Styles.Body} size="A4">
        <View style={Styles.Heading}>
          <Text style={Styles.TextHeading}>Resignation Letter</Text>
        </View>
        <View style={Styles.ControlGroup}>
          <View style={Styles.ValueGroup}>
            <View style={Styles.ValueLeft}>
              <Image src={USER_ICON} style={Styles.Icon} />
              <View style={Styles.ValueText}>
                <Text style={Styles.TextKeys}>Name</Text>
                <Text style={Styles.TextValue}>{data?.name}</Text>
              </View>
            </View>
            <View style={Styles.ValueRight}>
              <Image src={NRK_ICON} style={Styles.Icon} />
              <View style={Styles.ValueText}>
                <Text style={Styles.TextKeys}>NRK</Text>
                <Text style={Styles.TextValue}>{data?.employeeId}</Text>
              </View>
            </View>
          </View>
          <View style={[Styles.ValueGroup, { paddingTop: 12 }]}>
            <View style={Styles.ValueLeft}>
              <Image src={BRIEFCASE_ICON} style={Styles.Icon} />
              <View style={Styles.ValueText}>
                <Text style={Styles.TextKeys}>Position</Text>
                <Text style={Styles.TextValue}>{data?.jobTitle}</Text>
              </View>
            </View>
            <View style={Styles.ValueRight}>
              <Image src={COMPANY_ICON} style={Styles.Icon} />
              <View style={Styles.ValueText}>
                <Text style={Styles.TextKeys}>Division</Text>
                <Text style={Styles.TextValue}>{data?.region}</Text>
              </View>
            </View>
          </View>
          <View style={[Styles.ValueGroup, { paddingTop: 12 }]}>
            <View style={Styles.ValueLeft}>
              <Image src={PHONE_ICON} style={Styles.Icon} />
              <View style={Styles.ValueText}>
                <Text style={Styles.TextKeys}>Phone Number</Text>
                <Text style={Styles.TextValue}>{data?.phoneNumber}</Text>
              </View>
            </View>
            <View style={Styles.ValueRight}>
              <Image src={CALENDAR_ICON} style={Styles.Icon} />
              <View style={Styles.ValueText}>
                <Text style={Styles.TextKeys}>Resignation Date</Text>
                <Text style={Styles.TextValue}>
                  {data?.resignDate
                    ? moment(new Date(data?.resignDate).getTime()).format(
                        "DD MMM YYYY"
                      )
                    : "-"}
                </Text>
              </View>
            </View>
          </View>
          <View style={[Styles.ValueGroup, { paddingTop: 12 }]}>
            <View style={Styles.ValueLeft}>
              <Image src={CALENDAR_ICON} style={Styles.Icon} />
              <View style={Styles.ValueText}>
                <Text style={Styles.TextKeys}>Join Date</Text>
                <Text style={Styles.TextValue}>
                  {data?.joinDate
                    ? moment(new Date(data?.joinDate).getTime()).format(
                        "DD MMM YYYY"
                      )
                    : "-"}
                </Text>
              </View>
            </View>
            <View style={Styles.ValueRight}>
              <Image src={CALENDAR_ICON} style={Styles.Icon} />
              <View style={Styles.ValueText}>
                <Text style={Styles.TextKeys}>Request Date</Text>
                <Text style={Styles.TextValue}>
                  {data?.applyDate
                    ? moment(new Date(data?.applyDate).getTime()).format(
                        "DD MMM YYYY"
                      )
                    : "-"}
                </Text>
              </View>
            </View>
          </View>
          <View style={[Styles.ValueGroup, { paddingTop: 12 }]}>
            <View style={Styles.ValueLeft}>
              <Image src={ALIGN_LEFT_ICON} style={Styles.Icon} />
              <View style={Styles.ValueText}>
                <Text style={Styles.TextKeys}>Reasons for resigning</Text>
                <Text style={Styles.TextValue}>
                  {data?.remark ? data?.remark : "-"}
                </Text>
              </View>
            </View>
            <View style={Styles.ValueRight}></View>
          </View>
        </View>

        <View style={Styles.ContainerApproval}>
          <View style={Styles.WrapperApproval}>
            <View style={Styles.HeadingApproval}>
              <Text style={Styles.TextHeading}>Approval</Text>
              <Text style={[Styles.TextKeysRight, { paddingTop: 5 }]}>
                {moment(data?.applyDate).format("LL")}
              </Text>
            </View>

            <View style={Styles.ItemApproval}>
              <Text style={Styles.TextKeys}>Date of filling</Text>
              <Text style={Styles.TextKeysRight}>
                {moment(data?.applyDate).format("LL")}
              </Text>
            </View>
            <View style={Styles.ItemApproval}>
              <Text style={Styles.TextKeys}>Date Approved</Text>
              <Text style={Styles.TextKeysRight}>
                {moment(data?.status?.date).format("LL")}
              </Text>
            </View>
            <View style={Styles.ItemApproval}>
              <Text style={Styles.TextKeys}>Approved by</Text>
              <Text style={Styles.TextKeysRight}>{data?.approveBy?.name}</Text>
            </View>
            <View style={Styles.ItemApproval}>
              <Text style={Styles.TextKeys}>Phone</Text>
              <Text style={Styles.TextKeysRight}>
                {data?.approveBy?.phoneNumber}
              </Text>
            </View>
          </View>
        </View>
      </Page>

      {data?.resignLetter?.url && resignLetter && (
        <Page style={Styles.BodyPage2} size="A4">
          <View style={Styles.HeaderPDF}>
            <View style={Styles.CircleImage}>
              <Image src={PDF_ICON} style={Styles.HeaderImage} />
            </View>
            <Text style={Styles.TextHeaderPDF}>Resign Letter</Text>
          </View>

          <View>
            <Image src={resignLetter} alt="to pdf" />
          </View>
        </Page>
      )}

      {data?.exitInterview?.remark && (
        <Page style={Styles.Body} size="A4">
          <View style={Styles.Heading}>
            <Text style={Styles.TextHeading}>Exit Interview</Text>
          </View>

          <View style={Styles.ContainerExitInterview}>
            {data?.exitInterview?.remark && (
              <View style={Styles.InputGroup}>
                <Text style={[Styles.TextKeysRight, { paddingBottom: 5 }]}>
                  Alasan Keluar
                </Text>

                <View style={Styles.ValueInput}>
                  <Text style={Styles.TextValue}>
                    {data?.exitInterview?.remark}
                  </Text>
                </View>
              </View>
            )}

            {data?.exitInterview?.inputToCompany && (
              <View style={[Styles.InputGroup, { paddingTop: 10 }]}>
                <Text style={[Styles.TextKeysRight, { paddingBottom: 5 }]}>
                  Masukan bagi perusahaan (atasan, bawahan, rekan kerja,
                  peraturan/kebijakan perusahaan, dll)
                </Text>
                <View style={Styles.ValueInput}>
                  <Text style={Styles.TextValue}>
                    {data?.exitInterview?.inputToCompany}
                  </Text>
                </View>
              </View>
            )}

            {data?.exitInterview?.afterResign && (
              <View style={[Styles.InputGroup, { paddingTop: 10 }]}>
                <Text style={[Styles.TextKeysRight, { paddingBottom: 5 }]}>
                  Apa yang anda lakukan setelah resign ?{" "}
                </Text>
                <View style={Styles.ValueInput}>
                  <Text style={Styles.TextValue}>
                    {data?.exitInterview?.afterResign}
                  </Text>
                </View>
              </View>
            )}

            {data?.exitInterview?.pendingRights && (
              <View style={[Styles.InputGroup, { paddingTop: 10 }]}>
                <Text style={[Styles.TextKeysRight, { paddingBottom: 5 }]}>
                  Alasan Keluar
                </Text>
                <View style={Styles.ValueInput}>
                  <Text style={Styles.TextValue}>
                    {data?.exitInterview?.pendingRights}
                  </Text>
                </View>
              </View>
            )}
          </View>
        </Page>
      )}

      {data?.exitInterview?.file?.url && exitInterviewFile && (
        <Page style={Styles.BodyPage2} size="A4" fixed>
          <View style={Styles.HeaderPDF}>
            <View style={Styles.CircleImage}>
              <Image src={PDF_ICON} style={Styles.HeaderImage} />
            </View>
            <Text style={Styles.TextHeaderPDF}>Exit Interview File</Text>
          </View>
          <View fixed>
            <Image
              src={exitInterviewFile}
              alt="to pdf"
              style={Styles.PDFImage}
            />
          </View>
        </Page>
      )}

      <Page style={Styles.Body} size="A4">
        <View style={Styles.Heading}>
          <Text style={Styles.TextHeading}>Exit Clearance</Text>
        </View>
        <View style={Styles.WrapperApprovalClearance}>
          <View style={Styles.ItemApproval}>
            <Text style={Styles.TextKeys}>Exit Status</Text>
            <Text style={Styles.TextKeysRight}>
              {data?.exitClearance?.status}
            </Text>
          </View>
          <View style={[Styles.Heading, { paddingTop: 10 }]}>
            <Text
              style={[Styles.TextHeading, { paddingTop: 10, fontSize: 16 }]}
            >
              Human Capital
            </Text>
          </View>
          <View style={[Styles.ItemApproval, { paddingTop: "-10px" }]}>
            <Text style={Styles.TextKeys}>Exit Interview</Text>
            <Text style={Styles.TextKeysRight}>
              {data?.exitClearance?.HC?.exitInterview?.date
                ? moment(
                    new Date(
                      data?.exitClearance?.HC?.exitInterview?.date
                    ).getTime()
                  ).format("LL")
                : "Tidak ada"}
            </Text>
          </View>
          <View style={Styles.ItemApproval}>
            <Text style={Styles.TextKeys}>Debt to Company </Text>
            <Text style={Styles.TextKeysRight}>
              {dateExitClearanceHC
                ? moment(new Date(dateExitClearanceHC).getTime()).format("LL")
                : "Tidak ada"}
            </Text>
          </View>
          <View style={[Styles.Heading, { paddingTop: 10 }]}>
            <Text
              style={[Styles.TextHeading, { paddingTop: 10, fontSize: 16 }]}
            >
              Company Aset
            </Text>
          </View>
          <View style={[Styles.ItemApproval, { paddingTop: "-10px" }]}>
            <Text style={Styles.TextKeys}>Kendaraan</Text>
            <Text style={Styles.TextKeysRight}>
              {data?.exitClearance?.assets?.vehicle?.date
                ? moment(
                    new Date(
                      data?.exitClearance?.assets?.vehicle?.date
                    ).getTime()
                  ).format("LL")
                : "Tidak ada"}
            </Text>
          </View>
          <View style={[Styles.ItemApproval, { paddingTop: "10px" }]}>
            <Text style={Styles.TextKeys}>ID Card</Text>
            <Text style={Styles.TextKeysRight}>
              {data?.exitClearance?.assets?.idCard?.date
                ? moment(
                    new Date(
                      data?.exitClearance?.assets?.idCard?.date
                    ).getTime()
                  ).format("LL")
                : "Tidak ada"}
            </Text>
          </View>
          <View style={[Styles.ItemApproval, { paddingTop: "10px" }]}>
            <Text style={Styles.TextKeys}>Kalkulator</Text>
            <Text style={Styles.TextKeysRight}>
              {data?.exitClearance?.assets?.calculator?.date
                ? moment(
                    new Date(
                      data?.exitClearance?.assets?.calculator?.date
                    ).getTime()
                  ).format("LL")
                : "Tidak ada"}
            </Text>
          </View>
          <View style={[Styles.ItemApproval, { paddingTop: "10px" }]}>
            <Text style={Styles.TextKeys}>Kunci ruang/meja kerja</Text>
            <Text style={Styles.TextKeysRight}>
              {data?.exitClearance?.assets?.keys?.date
                ? moment(
                    new Date(data?.exitClearance?.assets?.keys?.date).getTime()
                  ).format("LL")
                : "Tidak ada"}
            </Text>
          </View>
          <View style={[Styles.ItemApproval, { paddingTop: "10px" }]}>
            <Text style={Styles.TextKeys}>Laptop</Text>
            <Text style={Styles.TextKeysRight}>
              {data?.exitClearance?.assets?.laptop?.date
                ? moment(
                    new Date(
                      data?.exitClearance?.assets?.laptop?.date
                    ).getTime()
                  ).format("LL")
                : "Tidak ada"}
            </Text>
          </View>
          <View style={[Styles.ItemApproval, { paddingTop: "10px" }]}>
            <Text style={Styles.TextKeys}>User ID/password</Text>
            <Text style={Styles.TextKeysRight}>
              {data?.exitClearance?.assets?.userId?.date
                ? moment(
                    new Date(
                      data?.exitClearance?.assets?.userId?.date
                    ).getTime()
                  ).format("LL")
                : "Tidak ada"}
            </Text>
          </View>
          <View style={[Styles.ItemApproval, { paddingTop: "10px" }]}>
            <Text style={Styles.TextKeys}>Seragam</Text>
            <Text style={Styles.TextKeysRight}>
              {data?.exitClearance?.assets?.uniform?.date
                ? moment(
                    new Date(
                      data?.exitClearance?.assets?.uniform?.date
                    ).getTime()
                  ).format("LL")
                : "Tidak ada"}
            </Text>
          </View>
          <View style={[Styles.ItemApproval, { paddingTop: "10px" }]}>
            <Text style={Styles.TextKeys}>Serah terima jabatan</Text>
            <Text style={Styles.TextKeysRight}>
              {data?.exitClearance?.assets?.handoverOffice?.date
                ? moment(
                    new Date(
                      data?.exitClearance?.assets?.handoverOffice?.date
                    ).getTime()
                  ).format("LL")
                : "Tidak ada"}
            </Text>
          </View>
          <View style={[Styles.ItemApproval, { paddingTop: "10px" }]}>
            <Text style={Styles.TextKeys}>Lainnya</Text>
            <Text style={Styles.TextKeysRight}>
              {data?.exitClearance?.assets?.others?.remark
                ? data?.exitClearance?.assets?.others?.remark
                : "Tidak ada"}
            </Text>
          </View>
        </View>
      </Page>

      {data?.exitClearance?.file?.url && exitClearanceFile && (
        <Page style={Styles.BodyPage2} size="A4" fixed>
          <View style={Styles.HeaderPDF}>
            <View style={Styles.CircleImage}>
              <Image src={PDF_ICON} style={Styles.HeaderImage} />
            </View>
            <Text style={Styles.TextHeaderPDF}>Exit Interview File</Text>
          </View>
          <View fixed>
            <Image src={exitClearanceFile} fixed alt="to pdf" />
          </View>
        </Page>
      )}
    </Document>
  );
};
