/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./AttendanceReport.css";
import { filter } from "lodash";
import { Helmet } from "react-helmet";
import DownloadIcon from "@mui/icons-material/Download";
import DropdownAttReport from "./DropdownAttReport/DropdownAttReport";
import logo_MyWorkSpace from "../../../../../assets/images/my-workspace.png";
import {
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  styled,
} from "@mui/material";
import USERLIST from "../../../../../components/SuperAdmin/_user/TableUsers/_mocks/user";
import Scrollbar from "../../../../../components/SuperAdmin/_user/TableUsers/Scrollbar";
import UserListHead from "../../../../../components/SuperAdmin/_user/TableUsers/UserListHead";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllDataAttendanceReport,
  fetchAllDataEmployeeByRange,
} from "../../../../../redux/actions/SuperAdmin/dataEmployeeInternalActions";
import ExportCSV from "../../../../../components/SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import DateRangePickerComponent from "../../../../../components/utils/DateRangePicker/DateRangePicker";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import "moment/min/locales";
import { UseCompanyName } from "../../../../../components/utils/hooks";
import LoadingAnimation from "../../../../../components/utils/LoadingAnimation/LoadingAnimation";
// moment.locale("id");

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
  },
});

const TABLE_HEAD = [
  { id: "Name", label: "Employee Name", alignRight: false },
  { id: "Position", label: "Position", alignRight: false },
  { id: "totalAttendendance", label: "Attend", alignRight: false },
  { id: "totalSickPermit", label: "Sick", alignRight: false },
  { id: "totalLeave", label: "Leave", alignRight: false },
  // { id: "other", label: "Other", alignRight: false },
  //   { id: "" },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(
//       array,
//       (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
//     );
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export default function AttendanceReport() {
  const reportData = useSelector((state) => state.employeeInternal.dataAttendanceReport);
  const { loadingDataReport } = useSelector((state) => state.employeeInternal);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const getCompany = UseCompanyName();
  const token = localStorage.getItem("accessToken");
  const [groupValue, setGroupValue] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [selected, setSelected] = useState("PT. Permata Indo Sejahtera");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const startDate = moment().format("LL");
  const endDate = moment().format("LL");
  // const startDate = new Date(1648141200000);
  // const endDate = new Date(1649869200000);
  const [valueStartDate, setValueStartDate] = useState(startDate);
  const [valueEndDate, setValueEndDate] = useState(endDate);
  const dataEmployee = reportData?.data;
  const wrappedFunction = [reportData?.data].flat();
  const wrappedData = reportData?.data;

  const option = [
    "PT Permata Indonesia",
    "PT Samsung",
    "PT Telkom Indonesia",
    "PT Sinar Dunia",
  ];

  useEffect(() => {
    dispatch(
      fetchAllDataAttendanceReport(
        token,
        selected,
        new Date(valueStartDate).getTime(),
        new Date(valueEndDate).getTime()
      )
    );
    // console.log(valueDate.startDate.getTime(), 'cek date')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, valueStartDate, valueEndDate]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeStartDate = (newValue) => {
    setValueStartDate(newValue);
  };
  const handleChangeEndDate = (newValue) => {
    setValueEndDate(newValue);
  };
  const dataTest = () => {
    const array = [];
    for (let i = 0; i < wrappedFunction?.length; i++) {
      const absences = wrappedFunction[i]?.Absences;
      for (let j = 0; j < absences?.length; j++) {
        array.push({
          ...wrappedFunction[i],
          timeIn: moment(absences[j].DateTimeIn).format("HH:mm:ss"),
          timeOut:
            absences[j].DateTimeOut && absences[j].DateTimeOut !== "-"
              ? moment(absences[j].DateTimeOut).format("HH:mm:ss")
              : "-",
          dateTimeIn: moment(absences[j].DateTimeIn).format("L"),
          // dateTimeOut: moment(absences[j].DateTimeOut).format('L'),
          dateTimeOut:
            absences[j].DateTimeOut && absences[j].DateTimeOut !== "-"
              ? moment(absences[j].DateTimeOut).format("L")
              : "-",
          longitude: absences[j].Longitude ? absences[j].Longitude : "-",
          latitude: absences[j].Latitude ? absences[j].Latitude : "-",
        });
      }
    }
    return array;
  };
  const dataToExport = dataTest();
  return (
    <div className="container-att-report">
      <Helmet>
        <title>MyWorkSpace | Attendance Report</title>
      </Helmet>
      <div className="wrapper-att-report">
        <div className="item-top-att-report">
          <div
            className="item-left-att-report"
            onClick={() => history.push("/superadmin/attendance-data")}
          >
            <img src={logo_MyWorkSpace} alt="" />
            <span>MyWorkSpace</span>
          </div>
          <div className="item-mid-att-report">
            <span>Attendance Report</span>
          </div>
          <div className="item-right-att-report">
            <ExportCSV
              data={dataToExport.map((el, ind) => ({
                "No. ": ind + 1,
                Nama: el.Name,
                Position: el.Position,
                Nrk: el.Nrk,
                "Total Attend": el.totalPresence,
                "Total Sick": el.totalSickPermit,
                "Total Leave": el.totalAnnualLeave,
              }))}
              filename={`Attendance Report ${new Date().toLocaleDateString()}`}
              initial="Download"
              endIcon={<DownloadIcon />}
            />
          </div>
        </div>
        <div className="date-picker-shown-att-report">
          <div className="wrapper-date-picker-shown-att-report">
            <DateRangePickerComponent
              valueStartDate={valueStartDate}
              valueEndDate={valueEndDate}
              handleChangeStartDate={handleChangeStartDate}
              handleChangeEndDate={handleChangeEndDate}
            />
          </div>
        </div>
        <div className="company-att-report">
          <div className="title-company-att-report">
            <label htmlFor="">Group</label>
            <Autocomplete
              disablePortal
              options={getCompany}
              value={selected}
              inputValue={selected}
              onInputChange={(e, value) => setSelected(value)}
              renderInput={(params) => (
                <MuiTextField {...params} size="small"/>
              )}
            />
            {/* <FormControl fullWidth size="small" style={{ width: "100%" }}>
              <Select
                className={classes.selectBorder}
                value={selected}
                displayEmpty
                onChange={(e) => setSelected(e.target.value)}
              >
                <MenuItem value="">Select Group</MenuItem>
                {getCompany.map((option, el) => (
                  <MenuItem
                    key={el}
                    onClick={() => setSelectedGroup(option)}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
        </div>
        <hr />
        <div className="desc-item-loan-report">
          <div className="mini-item-left-att-report">
            <label>Attendance Rate</label>
            <span>{reportData?.attandanceRate} Employee / Day</span>
          </div>
          <div className="border-seperator"></div>
          <div className="mini-item-middle-att-report">
            <label>Total Sick</label>
            <span>{reportData?.totalSick} Employee</span>
          </div>
          <div className="border-seperator"></div>
          <div className="mini-item-right-att-report">
            <label>Total Leave</label>
            <span>{reportData?.totalLeave} Employee</span>
          </div>
        </div>
        {loadingDataReport ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 40,
            }}
          >
            <div style={{ width: 200 }}>
              <LoadingAnimation />
            </div>
          </div>
        ) : (
          <div className="table-att-report">
            <Scrollbar>
              <TableContainer sx={{ minWidth: 600 }}>
                <Table
                  sx={{
                    width: "100%",
                    maxHeight: "100%",
                    backgroundColor: "#fafafa",
                    borderRadius: 4,
                  }}
                >
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={wrappedFunction?.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(wrappedFunction, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((employee, id) => {
                        const labelId = `enhanced-table-checkbox-${id}`;
                        return (
                          <TableRow hover key={id}>
                            <TableCell>{id + 1}</TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              id={labelId}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  alt={employee?.Name}
                                  src={
                                    employee?.ProfilePicture
                                      ? employee?.ProfilePicture
                                      : ""
                                  }
                                />
                                <Typography variant="subtitle2" noWrap>
                                  {employee?.Name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              {employee?.Position}
                            </TableCell>
                            <TableCell align="left">
                              {employee?.totalPresence} Days
                            </TableCell>
                            <TableCell align="left">
                              {employee?.totalAnnualLeave} Days
                            </TableCell>
                            <TableCell align="left">
                              {employee?.totalSickPermit} Days
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {/* {wrappedFunction
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((employee, id) => {
                    const labelId = `enhanced-table-checkbox-${id}`;
                      return (
                        <TableRow hover key={id}>
                          <TableCell>{id + 1}</TableCell>
                          <TableCell component="th" scope="row" padding="none" id={labelId}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                alt={employee.Name}
                                src={
                                  employee.ProfilePicture
                                    ? employee.ProfilePicture
                                    : ""
                                }
                              />
                              <Typography variant="subtitle2" noWrap>
                                {employee.Name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {employee.Position}
                          </TableCell>
                          <TableCell align="left">
                            {employee.totalPresence} Days
                          </TableCell>
                          <TableCell align="left">
                            {employee.totalAnnualLeave} Days
                          </TableCell>
                          <TableCell align="left">
                            {employee.totalSickPermit} Days
                          </TableCell>
                        </TableRow>
                      );
                    })} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 35, 45]}
              component="div"
              count={wrappedFunction?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </div>
    </div>
  );
}
