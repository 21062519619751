/* eslint-disable no-unused-vars */
import React, { useState, useRef } from "react";
import "./UserDetailMenuAdmin.css";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import { Grid } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AvatarGroup from "@mui/material/AvatarGroup";

import logo_user from "../../../../assets/images/logo-user.png";
// material
import TestButton from "./testButton";

let EMPLOYEE = [],
  // STATUSES = ["Internal", "Eksternal"];
  NAMES = [
    "Jaka Saputra",
    "Jack Empire",
    "Layla Clapton",
    "Layla Clapton",
    "Putri Tsunade",
  ],
  //   CLIENT = [
  //     "Pt Arla",
  //     "PT Loreal",
  //     "PT Samsung Indonesia",
  //     "PT Vission Plus",
  //     "PT Sabang Subur",
  //   ],
  //   USERNAME = [
  //     "putrafitria",
  //     "ridwansaputra",
  //     "putribianca",
  //     "jenny",
  //     "intanviviana",
  //   ],
  //   PASSWORD = [
  //     "12488473727",
  //     "12488473727",
  //     "12488473727",
  //     "12488473727",
  //     "12488473727",
  //   ],
  //   STATUSES = ["Internal", "Eksternal"],
  POSITION = [
    "Product Designer",
    "Product Designer",
    "Front end Engineer",
    "Back End Engineer",
    "Product Manager",
  ],
  DIVISION = ["Product", "Engineer", "Marketing", "Engineer", "Product"],
  IMAGE = [
    "assets/images/logo-user.png",
    "assets/images/logo-user.png",
    "assets/images/logo-user.png",
  ];

// (POSITION = [
//   "Product Designer",
//   "Product Designer",
//   "Front end Engineer",
//   "Back End Engineer",
//   "Product Manager",
// ]),
// (ATTEND = ["22 Days", "22 Days", "22 Days", "22 Days", "20Days"]),
// (LEAVE = ["0 Days", "0 Days", "0 Days", "0 Days", "2 Days"]),
// (SICK = ["0 Days", "0 Days", "0 Days", "0 Days", "0 Days"]),
// (OFF = ["0 Days", "0 Days", "0 Days", "0 Days", "0 Days"]),
// (RATE = ["90%", "90 %", "90 %", "90 %", "90 %"]);

for (let i = 0; i < 5; i++) {
  EMPLOYEE[i] = {
    name: NAMES[i],
    position: POSITION[i],
    division: DIVISION[i],
    image: IMAGE[i],
    // client: CLIENT[i],
    // username: USERNAME[i],
    // password: PASSWORD[i],
    // status: STATUSES[Math.floor(Math.random() * STATUSES.length)],
  };
}
// const options = ["Assign Staff", "Assign Leader"];

export default function UserDetailMenuAdmin({ selected, setSelected }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isActive, setIsActive] = useState(false);
  const options = ["All", "Type", "Client"];
  const [selectedAddUserModalComp, setSelectedAdduserModalComp] = useState("");


  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //   const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="container__user-detail-menu">
      <div className="wrapper-user-detail-menu-download">
        <div className="user__detail-left">
          <input
            type="text"
            placeholder="Search"
            // onChange={(e) => {
            //   setSearchTerm(e.target.value);
            // }}
          />
          <i className="bx bx-search"></i>
        </div>
        <div className="data-attendance-menu-right">
          <div className="box-dropdown-user-detail-menu">
            <div
              className="dropdown-btn-user-detail"
              onClick={(e) => setIsActive(!isActive)}
            >
              <span value="All">{selected}</span>
              <KeyboardArrowDownIcon />
            </div>
            {isActive && (
              <div className="dropdown-content-user-detail">
                {options.map((option) => (
                  <div
                    onClick={(e) => {
                      setSelected(option);
                      setIsActive(false);
                    }}
                    className="dropdown-item-user-detail"
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <Link to="/add-user" className="data-attendance-menu-right-download">
          <div className="data-attendance-menu-right-download">
            <Button
              startIcon={<AddIcon style={{ color: "white" }} />}
              //   className="button__add-store-main-client"
              style={{
                backgroundColor: "#1571DE",
                textTransform: "none",
                width: 150,
              }}
            >
              <span style={{ fontSize: 14, color: "white" }}>Add Employee</span>
            </Button>
          </div>
        </Link>
      </div>
      <div className="wrapper-table-user-detail-menu">
        <Table
          sx={{
            width: "100%",
            maxHeight: "100%",
            backgroundColor: "#fafafa",
            borderRadius: 4,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Emloyee Name</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Division</TableCell>
              <TableCell>Staff</TableCell>
              <TableCell>Leader</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {EMPLOYEE
              // .filter((val) => {
              //   if (searchTerm === "") {
              //     return val;
              //   } else if (
              //     val.name.toLowerCase().includes(searchTerm.toLowerCase())
              //   ) {
              //     return val;
              //   }
              // })
              .map((row) => (
                <TableRow key={row.name}>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={2} md={3}>
                        <img
                          src={logo_user}
                          alt={row.name}
                          sx={{ width: 26, height: 26 }}
                        />
                      </Grid>
                      <Grid item xs={0} style={{ paddingTop: 10 }}>
                        {row.name}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>{row.position}</TableCell>
                  <TableCell>{row.division}</TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={0}>
                        <AvatarGroup max={3}>
                          {/* <Avatar>{row.image}</Avatar> */}
                          <Avatar src="/assets/images/logo-user.png" />
                          <Avatar src="/assets/images/logo-user.png" />
                          <Avatar src="/assets/images/logo-user.png" />
                          <Avatar src="/assets/images/logo-user.png" />
                          <Avatar src="/assets/images/logo-user.png" />
                        </AvatarGroup>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={0}>
                        <AvatarGroup max={3}>
                          {/* <Avatar>{row.image}</Avatar> */}
                          <Avatar src="/assets/images/logo-user.png" />
                          <Avatar src="/assets/images/logo-user.png" />
                          <Avatar src="/assets/images/logo-user.png" />
                          <Avatar src="/assets/images/logo-user.png" />
                          <Avatar src="/assets/images/logo-user.png" />
                        </AvatarGroup>
                      </Grid>
                    </Grid>
                  </TableCell>
                  {/* <TableCell>{row.password}</TableCell> */}
                  {/* <TableCell>
                    <Typography
                      className="status__style-user-detail"
                      style={{
                        backgroundColor:
                          (row.status === "Internal" && "#255BFC") ||
                          (row.status === "Eksternal" && "#498E0F"),
                      }}
                    >
                      {row.status}
                    </Typography>
                  </TableCell> */}

                  <TableCell align="right">
                    <TestButton />
                    <Button>
                      <SettingsIcon style={{ color: "#757575" }} />
                    </Button>
                    <Button>
                      <EditIcon />
                    </Button>
                    <Button
                    // onClick={() => setModalDeleteClient(true)}
                    >
                      <DeleteOutlineIcon style={{ color: "#D1421A" }} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
