import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { UseCompanyName } from '../../../utils/hooks';
import React, { useEffect, useMemo, useState } from 'react';
import Styles from './ListNotification.module.css';
import SearchbarSA from '../../../utils/SearchbarSA/SearchbarSA';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Typography,
} from '@mui/material';
import { Add, Cancel, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import LoadingAnimation from '../../../utils/LoadingAnimation/LoadingAnimation';
import Scrollbar from '../../../SuperAdmin/_user/TableUsers/Scrollbar';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CustomTablePagination from '../../../_pagination/CustomTablePagination';
import ModalDeleteNotification from '../ModalDeleteNotification/ModalDeleteNotification';
import Dropdown from '../../../dropdown/Dropdown';
import fetchClientCode from '../../../../async/client/fetchClientCode';
import { styled } from '@mui/styles';

const TABLE_HEAD = [
    { id: 'no', label: 'No', alignRight: false },
    { id: 'clientCode', label: 'Client', alignRight: false },
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'nextSchedule', numeric: true, label: 'Jadwal Selanjutnya', alignRight: false },
    { id: '' },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// eslint-disable-next-line no-unused-vars
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                '& tr': {
                    backgroundColor: '#F0F0F0',
                },
            }}>
            <TableRow>
                {TABLE_HEAD.map((headCell, index) => (
                    <TableCell
                        key={headCell.id + index}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const MuiTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: 8,
            border: '1px solid #c0c0c0',
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: '1.5px solid #d3c9c9',
        borderColor: '#d3c9c9',
        borderRadius: 8,
        //   width: "100%",
    },
});

export default function ListNotification({
    informations,
    loading,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    searchTerm,
    setSearchTerm,
    totalPage,
    totalData,
    selectedClients,
    setSelectedClients
}) {
    const token = localStorage.getItem('accessToken');
    const history = useHistory();
    const getCompany = UseCompanyName();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('Name');
    const [selectedInfo, setSelectedInfo] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [openedPopover, setOpenedPopover] = useState(null);
    const [dataClientCode, setDataClientCode] = useState([]);
    

    var sorted_informations = informations
        ?.sort((a, b) => {
            return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        })
        .reverse();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleClickAdd = (e) => {
        e.preventDefault();
        history.push('/admin-business/notification-add');
    };

    const selectedUser = (userId) => {
        let client;
        if (userId !== 'ALL' && userId !== 'Tes') {
            let temp = getCompany.find((u) => u.code === userId);
            if (temp) {
                client = temp;
            } else {
                client = { name: 'Unlisted Client' };
            }
        } else if (userId === 'ALL') {
            client = { name: 'All Clients' };
        } else {
            client = { name: 'Client Tester' };
        }
        return client;
    };

    const selectedUsersNames = (clientCodes) => {
        var clients = clientCodes?.map((clientCode) => {
            let user = selectedUser(clientCode);
            return user?.name;
        });
        return clients;
    };

    const handleClientsPopover = (e, id) => {
        setAnchorEl(e.currentTarget);
        setOpenedPopover(id);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenedPopover(null);
    };
    const isSelected = (option) => selectedClients?.indexOf(option) !== -1;
    const capitalizedClientName = (clientName) => {
        return clientName
            ?.toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
            .replace('Pt', 'PT')
            .replace('Cv', 'CV');
    };
    const handleDeleteClient = (value) => {
        const x = selectedClients.filter((item) => item !== value);
        setSelectedClients(x)
    }

    const handleSelectedClient = (e, value) => {
        e.preventDefault();

        const isExist = selectedClients.find((item) => item?.name === value?.name);
        const doesContainAll = selectedClients.some((item) => item?.codes === 'All');
        let isAll = Boolean(value?.codes === 'All' && value?.name === 'All');
        const isEmpty = selectedClients.length === 0;

        if (!isExist && !isAll && !doesContainAll) {
            let temp = selectedClients.concat(value);
            setSelectedClients(temp);
        } else if (isExist) {
            const x = selectedClients.filter((item) => item !== value);
            setSelectedClients(x);
        } else if (isEmpty && isAll) {
            let temp = selectedClients.concat(value);
            setSelectedClients(temp);
        } else if (!isExist && doesContainAll) {
            setSelectedClients(selectedClients);
        }
    };

    const fixedClients = useMemo(() => {
        let pre = [
            { codes: 'ALL', name: 'ALL' },
            { codes: 'Tes', name: 'Client Tester' },
        ];
        return pre.concat(dataClientCode);
    }, [dataClientCode]);

    useEffect(() => {
        (async () => {
            try {
                const { response } = await fetchClientCode(token);
                setDataClientCode(response);
            } catch (error) {
                console.log(error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNextSchedule = (schedules) => {
        const upcomingSchedules = schedules
            .filter((schedule) => schedule.status === "Waiting")
            .sort((a, b) => new Date(a.date) - new Date(b.date)); // Urutkan berdasarkan tanggal

        if (upcomingSchedules.length > 0) {
            const nextSchedule = upcomingSchedules[0];
            return moment(nextSchedule.date).format('DD MMMM YYYY, HH:mm');
        }

        return 'Tidak ada jadwal mendatang';
    };

    return (
        <div className={Styles.Container}>
            <div className="wrapper-information">
                <div className={Styles.Header}>
                    <div className={Styles.LeftHeader}>
                    <div className={Styles.Searchbar}>
                        <SearchbarSA
                            value={searchTerm}
                            onChange={handleSearch}
                            searchWidth="300px"
                            placeholder="Search by title"
                        />
                    </div>
                    <div className={Styles.Dropdown}>
                        <FormControl size="small" fullWidth>
                            <Autocomplete
                                disablePortal
                                value={null}
                                blurOnSelect={true}
                                disableCloseOnSelect
                                options={fixedClients}
                                getOptionLabel={(option) => option?.name}
                                sx={{ width: '300px' }}
                                onChange={(e, value) =>
                                    value === null ? handleSelectedClient(e, null) : handleSelectedClient(e, value)
                                }
                                renderOption={(props, option) => {
                                    const isItemSelected = isSelected(option);
                                    return (
                                        <li
                                            {...props}
                                            onClick={(e) => e.preventDefault()}
                                            key={option?.codes}
                                            style={{ borderBottom: '1px solid #c2c2c2', padding: '12px' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                }}>
                                                <span className={Styles.ClientOption}>
                                                    {capitalizedClientName(option?.name)}
                                                </span>
                                                <Checkbox
                                                    onClick={(e) => handleSelectedClient(e, option)}
                                                    sx={{ margin: 0, padding: 0 }}
                                                    checked={isItemSelected}
                                                    icon={<RadioButtonUnchecked sx={{ color: '#c2c2c2' }} />}
                                                    checkedIcon={<RadioButtonChecked sx={{ color: '#1571DE' }} />}
                                                />
                                            </div>
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <MuiTextField
                                        {...params}
                                        size="small"
                                        sx={{ background: 'var(--main-bg)' }}
                                        placeholder="Filter by client"
                                    />
                                )}
                            />
                        </FormControl>
                    </div>
                    </div>
                    <div>
                        <Button
                            startIcon={<Add style={{ fontSize: 16 }} />}
                            className={Styles.ButtonAdd}
                            onClick={handleClickAdd}>
                            <span className={Styles.AddText}>Tambah Notifikasi</span>
                        </Button>
                    </div>
                </div>
                {selectedClients?.length > 0 && (
                    <div className={Styles.ClientsChipContainer}>
                        {selectedClients.map((client, i) => (
                            <div key={i} className={Styles.ClientsChip}>
                                <span>{capitalizedClientName(client?.name)}</span>
                                <Cancel
                                    className={Styles.CancelIcon}
                                    onClick={() => handleDeleteClient(client)}
                                />
                            </div>
                        ))}
                    </div>
                )}
                {loading ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 50,
                        }}>
                        <div style={{ width: 200 }}>
                            <LoadingAnimation />
                        </div>
                    </div>
                ) : (
                    <div className={Styles.TableWrapper}>
                        <Scrollbar>
                            <TableContainer className={Styles.TableContainer}>
                                <Table id="emp-table" className={Styles.Table}>
                                    <colgroup>
                                        <col style={{ width: '10%%' }} />
                                        <col style={{ width: '30%' }} />
                                        <col style={{ width: '30%' }} />
                                        <col style={{ width: '15%' }} />
                                        <col style={{ width: '15%' }} />
                                    </colgroup>
                                    <EnhancedTableHead
                                        numSelected={informations?.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        values={informations}
                                    />
                                    <TableBody>
                                        {stableSort(sorted_informations || [], getComparator(order, orderBy)).map(
                                            (data, ind) => {
                                                const client = selectedUser(data?.clientCode[0]);
                                                const clients = selectedUsersNames(data?.clientCode);
                                                return (
                                                    <TableRow hover key={'tablebody' + ind} tabIndex={-1}>
                                                        <TableCell>{(page - 1) * rowsPerPage + (ind + 1)}</TableCell>
                                                        <TableCell className={Styles.ClientCell}>
                                                            <span>{capitalizedClientName(client?.name)}</span>
                                                            {data?.clientCode?.length > 1 && (
                                                                <span
                                                                    onMouseEnter={(e) =>
                                                                        handleClientsPopover(e, data?._id)
                                                                    }
                                                                    onMouseLeave={handleClose}
                                                                    className={Styles.Plus}>
                                                                    + {data?.clientCode?.length - 1}
                                                                </span>
                                                            )}
                                                            <Popover
                                                                id={data?._id}
                                                                sx={{
                                                                    pointerEvents: 'none',
                                                                }}
                                                                open={openedPopover === data?._id}
                                                                anchorEl={anchorEl}
                                                                onClose={handleClose}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}>
                                                                <div className={Styles.PopoverDiv}>
                                                                    {clients?.slice(1)?.map((client, index) => (
                                                                        <span key={index}>
                                                                            {index === clients?.length - 2 &&
                                                                                clients?.length !== 2 &&
                                                                                '& '}
                                                                            {capitalizedClientName(client)}
                                                                            {index !== clients?.length - 2
                                                                                ? ', '
                                                                                : '.'}{' '}
                                                                        </span>
                                                                    ))}
                                                                </div>
                                                            </Popover>
                                                        </TableCell>
                                                        <TableCell>{data?.title ? data?.title : ''}</TableCell>
                                                        <TableCell>
                                                            {getNextSchedule(data?.schedule)}
                                                        </TableCell>
                                                        {/* <TableCell>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 12,
                                                                    fontWeight: 400,
                                                                }}>
                                                                {data?.status ? (
                                                                    <span className={Styles[data.status]}>
                                                                        {data.status}
                                                                    </span>
                                                                ) : (
                                                                    <span className={Styles.Draft}>Draft</span>
                                                                )}
                                                            </Typography>
                                                        </TableCell> */}
                                                        <TableCell align="right">
                                                            <Link
                                                                to={{
                                                                    pathname:`/admin-business/notification-detail/${data._id}`,
                                                                    state: { data: data },
                                                                }}>
                                                                <span style={{ color: '#1571DE' }}>Lihat Detail</span>
                                                            </Link>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <CustomTablePagination
                            limit={rowsPerPage}
                            setLimit={setRowsPerPage}
                            page={page}
                            setPage={setPage}
                            totalPage={totalPage || 1}
                        />
                    </div>
                )}

                {selectedInfo._id && (
                    <ModalDeleteNotification
                        active={selectedInfo}
                        onOpen={setSelectedInfo}
                        description="Apakah kamu yakin ingin menghapus notifikasi ini?"
                        label="Hapus Notifikasi"
                        pathName={'/admin-business/notification'}
                    />
                )}
            </div>
        </div>
    );
}
