import React  from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
// import { useLocation } from "react-router";
import { PayslipPersonDetailExternalMenu } from "../../../components/External/Payslip";
import { useLocation } from "react-router";
import Breadcrumb from "../../../components/utils/Breadcrumbs";

export default function PayslipPersonDetailExternal() {
  const history = useHistory();
  const location = useLocation();
  const dataPayslip = location?.state;
  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Payslip</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/admin/payslip-external")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Payslip</span>
            </Button>
            <Button
              onClick={() => history.go(-1)}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>
              {dataPayslip?.name ? dataPayslip?.name : ""}
              </span>
            </Button>
            <span style={{ fontSize: 20 }}>Detail Pay Slip</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <PayslipPersonDetailExternalMenu data={ dataPayslip }/>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
