import React from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { InductionFormLayout } from "../../../../components/SuperAdmin/_induction";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { useHistory  } from "react-router-dom/cjs/react-router-dom.min";

export default function InductionForm() {
  const history = useHistory();
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Induction</title>
      </Helmet>
      <Breadcrumb>
        <Button
          onClick={() => history.push("/superadmin/induction")}
          style={{ textTransform: "none" }}
        >
          <span style={{ fontSize: 20, color: "#255bfc" }}>Induction</span>
        </Button>
        <span style={{ fontSize: 20, color: "#255bfc" }}>Add New Induction</span>
      </Breadcrumb>

      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <InductionFormLayout />
        </Grid>
      </Grid>
    </Container>
  );
}
