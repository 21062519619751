import * as types from '../../type'

const initialstate = {
  schemaAttendances: [],
  loadingDataAttendances: false,
  errorDataAttendance: {},
  schemaAttendance: {},
};

const schemaAttendancesReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_ATTENDANCES_BEGIN: {
      return {
        ...state,
        loadingDataAttendances: true
      };
    }
    case types.FETCH_ALL_ATTENDANCES_SUCCESS: {
      return {
        ...state,
        loadingDataAttendances: false,
        schemaAttendances: action.payload,
      };
    }
    case types.FETCH_ALL_ATTENDANCES_ERROR: {
      return {
        ...state,
        loadingDataAttendances: false,
        errorDataAttendance: action.payload
      };
    }
    case types.FETCH_SCHEMA_ATTENDANCE_BY_ID: {
      return {
        ...state,
        schemaAttendance: action.payload
      };
    }
    // case "DELETE_ATTENDANCE": {
    //   return {
    //     ...state,
    //     schemaAttendance: action.payload,
    //   };
    // }
    // case "UPDATE_SCHEMA_ATTENDANCE_BY_ID": {
    //   return {
    //     ...state,
    //     schemaAttendance: action.payload,
    //   };
    // }
    default:
      return state;
  }
};

export default schemaAttendancesReducer;
