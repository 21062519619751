import React, { useState } from "react";
import SearchbarFMCG from "../../../SearchbarFMCG/SearchbarFMCG";
import Styles from "./ActivityReport.module.css";
// import { makeStyles } from "@mui/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
// import LoadingFMCG from "../../../utils/LoadingFMCG/LoadingFMCG";
import {
  Avatar,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import DownloadIcon from "@mui/icons-material/Download";
import ExportDataExcelFMCG from "../../../../utils/ExportCSVFMCG";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingFMCG from "../../../../utils/LoadingFMCG/LoadingFMCG";
import Scrollbar from "../../../../SuperAdmin/_user/TableUsers/Scrollbar";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      width: "100%",
    },
    "& .MuiInputBase-input-MuiOutlinedInput-input": {
      padding: 0,
    },
    "& input::placeholder": {
      color: "#A4A4A4",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "18.2px",
      opacity: 1,
    },
    "& Svg": {
      color: "#1571DE",
    },
  },
});

const TABLE_HEAD = [
  { id: "name", label: "Employee", alignRight: false },
  { id: "leader", label: "Leader", alignRight: false },
  { id: "stock", label: "Stock", alignRight: false },
  { id: "store", label: "Store", alignRight: false },
  { id: "sellOut", label: "Sell Out", alignRight: false },
  { id: "competitor", label: "Competitor", alignRight: false },
  { id: "customer", label: "Customer", alignRight: false },
  { id: "promo", label: "Promo", alignRight: false },
  { id: "sampling", label: "Sampling", alignRight: false },
  { id: "display", label: "Display", alignRight: false },
  { id: "percentage", label: "Percentage", alignRight: false },
  // { id: "" },
];

const data = [
  {
    profilePicture: "",
    name: "Esther Howard",
    leader: "Howard",
    store: "Indomaret",
    stock: 10,
    sellOut: 2,
    competitor: 5,
    customer: 2,
    promo: 3,
    sampling: 4,
    display: 2,
    percentage: 20,
  },
  {
    profilePicture: "",
    name: "Esther",
    leader: "Howard",
    store: "Indomaret",
    stock: 10,
    sellOut: 2,
    competitor: 5,
    customer: 2,
    promo: 3,
    sampling: 4,
    display: 2,
    percentage: 20,
  },
  {
    profilePicture: "",
    name: "Es Howard",
    leader: "Howard",
    store: "Indomaret",
    stock: 10,
    sellOut: 2,
    competitor: 5,
    customer: 2,
    promo: 3,
    sampling: 4,
    display: 2,
    percentage: 20,
  },
  {
    profilePicture: "",
    name: "Vladimir",
    leader: "Howard",
    store: "Indomaret",
    stock: 10,
    sellOut: 2,
    competitor: 5,
    customer: 2,
    promo: 3,
    sampling: 4,
    display: 2,
    percentage: 20,
  },
  {
    profilePicture: "",
    name: "Howard Bros",
    leader: "Howard",
    store: "Indomaret",
    stock: 10,
    sellOut: 2,
    competitor: 5,
    customer: 2,
    promo: 3,
    sampling: 4,
    display: 2,
    percentage: 20,
  },
  {
    profilePicture: "",
    name: "Howard Bros",
    leader: "Howard",
    store: "",
    stock: 10,
    sellOut: 2,
    competitor: 5,
    customer: 2,
    promo: 3,
    sampling: 4,
    display: 2,
    percentage: 20,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr > th": {
          backgroundColor: "#F0F0F0",
          position: "sticky",
          zIndex: 2,
          top: 0,
        },
        "& tr > :first-of-type": {
          zIndex: 3,
          left: 0,
          top: 0,
          width: 250,
          borderRight: "2px solid #E0E0E0",
        },
        "& th": {
          fontSize: 15,
          fontWeight: 500,
          color: "#0A0A0A",
        },
      }}
    >
      <TableRow>
        {TABLE_HEAD?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ActivityReport(props) {
  const {
    onSearch,
    valueTimeFrom,
    valueTimeTo,
    onChangeStartDate,
    onChangeEndDate,
    loading,
    searchValue,
  } = props;

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const tableBodies = data.map((each) => [
  //   {
  //     node: (
  //       <Stack direction="row" alignItems="center" spacing={2}>
  //         <Avatar src={each?.profilePicture} />
  //         <Typography noWrap style={{ textTransform: "capitalize" }}>
  //           {each?.name?.toLowerCase()}
  //         </Typography>
  //       </Stack>
  //     ),
  //   },
  //   {
  //     node: <Typography>{each.leader}</Typography>,
  //   },
  //   {
  //     node: <Typography>{each.stock}</Typography>,
  //   },
  //   {
  //     node: <Typography>{each.store}</Typography>,
  //   },
  //   {
  //     node: <Typography>{each.sellOut}</Typography>,
  //   },
  //   {
  //     node: <Typography>{each.competitor}</Typography>,
  //   },
  //   {
  //     node: <Typography>{each.customer}</Typography>,
  //   },
  //   {
  //     node: <Typography>{each.promo}</Typography>,
  //   },
  //   {
  //     node: <Typography>{each.sampling}</Typography>,
  //   },
  //   {
  //     node: <Typography>{each.display}</Typography>,
  //   },
  //   {
  //     node: (
  //       <Stack direction="row" alignItems="center" spacing={3}>
  //         <CircularProgress
  //           variant="determinate"
  //           value={each.percentage}
  //           sx={{ color: "red" }}
  //         />
  //         <Typography>{each.percentage}%</Typography>
  //       </Stack>
  //     ),
  //   },
  // ]);
  return (
    <div className={Styles.Body}>
      <div className={Styles.Header}>
        <div className={Styles.Selection}>
          <SearchbarFMCG
            searchWidth="260px"
            // value={searchValue}
            onChange={onSearch}
            placeholder="Search by name"
          />
          <div className={Styles.WrapperDateSelection}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className={Styles.DateFrom}>
                <DatePicker
                  components={{
                    OpenPickerIcon: CalendarMonthIcon,
                  }}
                  required
                  value={valueTimeFrom}
                  onChange={onChangeStartDate}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      size="small"
                      sx={{ width: 200, backgroundColor: "#FFFFFF" }}
                    />
                  )}
                />
              </div>
              <div className={Styles.DateTo}>
                <DatePicker
                  components={{
                    OpenPickerIcon: CalendarMonthIcon,
                  }}
                  required
                  value={valueTimeTo}
                  onChange={onChangeEndDate}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      size="small"
                      sx={{ width: 200, backgroundColor: "#FFFFFF" }}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
          </div>
        </div>
        <div className={Styles.Other}>
          <ExportDataExcelFMCG
            data={data?.map((el, ind) => ({
              "No. ": ind + 1,
              Nama: el.name,
              Leader: el.leader,
              Stock: el.stock,
              Store: el.store === "" ? "Not Assigned" : el.store,
              "Sell Out": el.sellOut,
              Competitor: el.competitor,
              Customer: el.customer,
              Promo: el.promo,
              Sampling: el.sampling,
              Display: el.display,
              Percentage: `${el.percentage} %`,
            }))}
            initial="Download Excel"
            startIcon={<DownloadIcon />}
            filename={`Activity Report ${new Date().toLocaleDateString()}`}
          />
        </div>
      </div>

      <div className={Styles.Content}>
        <Card>
          {loading ? (
            <div className={Styles.ContainerLoading}>
              <LoadingFMCG
                width={800}
                height={200}
                className={Styles.LoadingWidth}
              />
            </div>
          ) : (
            <Scrollbar>
              <TableContainer>
                <Table sx={{ width: "200%", borderCollapse: 'separate' }}>
                  <EnhancedTableHead
                    numSelected={data?.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={
                      data?.filter((el) =>
                        el?.name
                          ?.toLowerCase()
                          .includes(searchValue?.toLowerCase())
                      ).length
                    }
                  />
                  <TableBody
                    sx={{
                      "& tr > th": {
                        position: "sticky",
                        padding: ".5rem",
                        zIndex: 2,
                        top: 0,
                        background: "#FFFFFF",
                      },
                      "& tr > :first-of-type": {
                        left: 0,
                        position: "sticky",
                        zIndex: 1,
                        background: "#FFFFFF",
                        borderRight: "2px solid #E0E0E0",
                        width: 250,
                        fontSize: 14,
                        fontWeight: 500,
                        color: "#0A0A0A",
                        textTransform: "capitalize",
                      },
                      "& td > p": {
                        fontSize: 13,
                        fontWeight: 400,
                        color: "#404040",
                        textTransform: "capitalize",
                      },
                    }}
                  >
                    {stableSort(data, getComparator(order, orderBy))
                      .filter((el) =>
                        el?.name
                          ?.toLowerCase()
                          .includes(searchValue?.toLowerCase())
                      )
                      // .sort((a, b) => new Date(a.LoanTime) - new Date(b.LoanTime))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((each, idx) => {
                        // eslint-disable-next-line no-lone-blocks
                        /* const labelId = `enhanced-table-checkbox-${id}`; */
                        return (
                          <TableRow hover key={idx} tabIndex={-1}>
                            <TableCell>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  sx={{ width: 36, height: 36 }}
                                  src={
                                    each?.profilePicture
                                      ? each?.profilePicture
                                      : ""
                                  }
                                />
                                <Typography
                                  noWrap
                                  sx={{
                                    fontSize: 13,
                                    fontWeight: 500,
                                    color: "#0A0A0A",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {each?.name.toLowerCase()}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Typography>{each?.leader}</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography>{each?.stock}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                className={`${ each?.store === "" ? Styles.Store : "" }`}
                                style={{ color : each?.store === "" && "#d52701"}}
                              >
                                {each?.store === "" ? "Not Assigned" : each?.store}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{each?.sellOut}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{each?.competitor}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{each?.customer}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{each?.promo}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{each?.sampling}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{each?.display}</Typography>
                            </TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={3}
                              >
                                <CircularProgress
                                  variant="determinate"
                                  value={each.percentage}
                                />
                                <Typography
                                  sx={{
                                    fontSize: 13,
                                    fontWeight: 400,
                                    color: "#404040",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {each?.percentage}%
                                </Typography>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={
              data?.filter((el) =>
                el?.name?.toLowerCase().includes(searchValue?.toLowerCase())
              ).length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
    </div>
  );
}
