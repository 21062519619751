import React from "react";
import Styles from "./ModalAddLeave.module.css";
import { Button } from "@mui/material";

export default function ModalAddLeave({
  onOpen,
  onAddLeave,
  onSetLeave,
  value,
  setValue,
  mode,
  isErrorSetLeave,
  isErrorAddLeave,
}) {
  return (
    <div className={Styles.Background}>
      <div className={Styles.Container}>
        <div className={Styles.Header}>
          {mode === "edit" ? "Cuti Sebelumnya" : "Tambah Cuti Karyawan"}
        </div>
        {mode === "edit" ? null : (
          <div className={Styles.Title}>Cuti Pengganti hari Libur</div>
        )}
        <form
          className={Styles.InputWrapper}
          onSubmit={mode === "edit" ? onSetLeave : onAddLeave}
        >
          <div className={Styles.Input}>
            <span>Jumlah Cuti</span>
            <input
              required
              placeholder="0"
              type="number"
              value={value?.totalLeave}
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  totalLeave: e.target.value,
                }))
              }
            />
          </div>
          {/* {mode === "edit" ? null : ( */}
          <div className={Styles.Description}>
            <span>Deskripsi</span>
            <textarea
              required
              placeholder="Masukkan deskripsi"
              type="text"
              value={value?.description || ""}
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
            {isErrorSetLeave && (
              <div className={Styles.ErrorMessage}>* {isErrorSetLeave}</div>
            )}
            {isErrorAddLeave && (
              <div className={Styles.ErrorMessage}>* {isErrorAddLeave}</div>
            )}
          </div>

          {/* )} */}
          <div className={Styles.ButtonWrapper}>
            <Button variant="outlined" onClick={() => onOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
