import axios from "axios";
import {API} from "../../redux/api";

const fetchPKSbyClient = (clientCodes) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(`${API}/client/pks?code=${clientCodes}`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export default fetchPKSbyClient;
