import React, { useEffect, useMemo, useState } from "react";
import Styles from "./HolidayTableList.module.css";
import {
  Button,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Card,
  TableContainer,
  Table,
  TableBody,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import Scrollbar from "../../_user/TableUsers/Scrollbar";
import moment from "moment";
import CustomTablePagination from "../../../_pagination/CustomTablePagination";
import { AddCircleOutline } from "@mui/icons-material";
import CALENDAR_ICON from "../../../../assets/images/calendar-employee.png";
import EDIT_ICON from "../../../../assets/images/create.png";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const TABLE_HEAD = [
  { id: "date", label: "Tanggal", alignRight: false },
  { id: "holidayType", label: "Tipe Libur", alignRight: false },
  { id: "remark", label: "Keterangan", alignRight: false },
  { id: "", label: "Action", alignRight: true },
];

const TABLE_HEAD_ADD = [
  { id: "date", label: "Tanggal", alignRight: false },
  { id: "holidayType", label: "Tipe Libur", alignRight: false },
  { id: "remark", label: "Keterangan", alignRight: false },
];

const TABLE_HEAD_DETAIL = [
  { id: "date", label: "Tanggal", alignRight: false },
  { id: "holidayType", label: "Tipe Libur", alignRight: false },
  { id: "remark", label: "Keterangan", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    isEdit,
    setIsEdit,
    isLeavePlan,
    dataId,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  useEffect(() => {
    if (isLeavePlan?.length < 1) {
      setIsEdit("noId");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLeavePlan?.length]);

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      {isEdit === "edit" && (
        <TableRow>
          {TABLE_HEAD?.map((headCell, index) => (
            <TableCell
              key={headCell.id + index}
              align="left"
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      )}

      {isEdit === "add" && (
        <TableRow>
          {TABLE_HEAD_ADD?.map((headCell, index) => (
            <TableCell
              key={headCell.id + index}
              align="left"
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      )}

      {isEdit === "detail" && (
        <TableRow>
          {TABLE_HEAD_DETAIL?.map((headCell, index) => (
            <TableCell
              key={headCell.id + index}
              align="left"
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      )}

      {isEdit === "noId" && (
        <TableRow>
          {TABLE_HEAD_DETAIL?.map((headCell, index) => (
            <TableCell
              key={headCell.id + index}
              align="left"
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      )}
    </TableHead>
  );
}

export default function HolidayTableList(props) {
  const {
    loading,
    datas,
    addDayoff,
    id,
    mode,
    isEdit,
    setIsEdit,
    editModal,
    setEditModal,
    setDeleteModal,
    newItem,
    editHoliday,
    setEditHoliday,
    deleteHoliday,
    setDeleteHoliday,
    onDeleteHoliday,
    onEditHoliday,
  } = props;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { id: dataId } = useParams();

  let tableData = datas?.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const containId = useMemo(() => {
    const data = datas?.map((item) => item);
    return data.filter((res) => res.leavePlanId);
  }, [datas]);

  const isContainId = containId === [];

  const isLeavePlan = datas.map((el) => el).filter((res) => res.leavePlanId);

  return (
    <div className={Styles.Container}>
      <div className={Styles.Wrapper}>
        <div className={Styles.Header}>
          <span className={Styles.HeaderTitle}>Holiday list</span>
          {id ? null : (
            <Button
              onClick={() => addDayoff()}
              startIcon={<AddCircleOutline />}
              variant="outlined"
              className={Styles.AddButton}
            >
              Create new date
            </Button>
          )}
        </div>

        {/* <div className={Styles.Placeholder}>
          <img src={CALENDAR_ICON} alt="placeholder-calendar" />
          <span>Holiday List Still Empty</span>
        </div> */}

        {loading ? (
          <div className={Styles.Loading}>
            <div className={Styles.LoadingWidth}>
              <LoadingAnimation />
            </div>
          </div>
        ) : (
          <div className={Styles.ContainerTable}>
            {datas?.length === 0 ? (
              <Card sx={{ height: "300px" }}>
                <div className={Styles.Placeholder}>
                  <img src={CALENDAR_ICON} alt="placeholder-calendar" />
                  <span>Holiday List Still Empty</span>
                </div>
              </Card>
            ) : (
              <TableContainer className={Styles.TableContainer}>
                <Table>
                  {/* <colgroup>
                    <col style={{ width: "40%" }} />
                    <col style={{ width: "60%" }} />
                  </colgroup> */}
                  <EnhancedTableHead
                    numSelected={datas?.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    values={datas}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    isLeavePlan={isLeavePlan}
                    dataId={dataId}
                  />
                  <TableBody>
                    {stableSort(tableData, getComparator(order, orderBy))
                      ?.slice(
                        (page - 1) * rowsPerPage,
                        (page - 1) * rowsPerPage + rowsPerPage
                      )
                      ?.map((each, ind) => {
                        const labelId = `enhanced-table-checkbox-${ind}`;
                        return (
                          <TableRow
                            hover
                            key={ind}
                            tabIndex={-1}
                            id={labelId}
                            sx={{
                              "&.MuiTableRow-root:hover": {
                                backgroundColor: "#eef6ff",
                              },
                            }}
                          >
                            <TableCell>
                              <span>
                                {moment(each.date).format("DD MMMM YYYY")}
                              </span>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ textTransform: "capitalize" }}
                            >
                              { each.type}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ textTransform: "capitalize" }}
                            >
                              { each.description}
                            </TableCell>
                            {/* {mode === "add" ? (
                              <TableCell></TableCell>
                            ) : (
                              <TableCell>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <img
                                    src={EDIT_ICON}
                                    alt="edit"
                                    className={Styles.ActionButton}
                                    onClick={{}}
                                  />
                                  <img
                                    src={DELETE_ICON}
                                    alt="delete"
                                    className={Styles.ActionButton}
                                    onClick={{}}
                                  />
                                </Stack>
                              </TableCell>
                            )} */}

                            {isEdit === "add" && <TableCell></TableCell>}

                            {isEdit === "edit" && (
                              <TableCell>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <img
                                    src={EDIT_ICON}
                                    alt="edit"
                                    className={Styles.ActionButton}
                                    // onClick={ }
                                    onClick={() => setEditModal(each)}
                                  />
                                  <img
                                    src={DELETE_ICON}
                                    alt="delete"
                                    className={Styles.ActionButton}
                                    onClick={() => setDeleteModal(each)}
                                  />
                                </Stack>
                              </TableCell>
                            )}

                            {isLeavePlan?.length === 0 && null}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {datas?.length === 0 ? null : (
              <CustomTablePagination
                limit={rowsPerPage}
                setLimit={setRowsPerPage}
                page={page}
                setPage={setPage}
                totalPage={Math.ceil(datas?.length / rowsPerPage)}
              />
            )}

            {/* <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={datas.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
          </div>
        )}
      </div>
    </div>
  );
}
