// import { SET_SNACKBAR } from "../reducers/snackbarReducer";
import { SET_SNACKBAR } from "../../reducers/SuperAdmin/snackbarReducer";


export const setSnackbar = (payload = {}) => {
  return (dispatch) => {
    dispatch({
      type: SET_SNACKBAR,
      payload,
    });
  };
};
