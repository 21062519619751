/* eslint-disable no-unused-vars*/
import React from "react";
import "./SidebarSA.css";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/my-workspace.png";
import { SidebarData } from "./SidebarDropdownSA/SidebarData";
import SubMenu from "./SidebarDropdownSA/SubMenu";
import { Drawer } from "@mui/material";
import useWindowDimensions from "../_utils/useDimensions";
import { useRouteMatch } from "react-router";
import HELP_DESK_IMAGE from "../../../assets/images/help-desk.png";
import WHATSAPP_IMAGE from "../../../assets/images/whatsapp-logo.png";
import moment from "moment";

const SidebarSA = ({ onOpenSidebar, onCloseSidebar, ...props }) => {
  let { url } = useRouteMatch();
  const { width } = useWindowDimensions();
  const activeItem = SidebarData.findIndex(
    (item) => `${url}` + item.route === props.location.pathname
  );

  const isActive = (data) => {
    if (data.split("/").length < 1) {
      return true;
    } else if (data.split("/")[1] === props.location.pathname.split("/")[2]) {
      return true;
    }
    return false;
  };

  const userName = localStorage.getItem("userName");
  const clientName = localStorage.getItem("clientName");
  const employeeId = localStorage.getItem("employeeId");
  const joinDate = localStorage.getItem("joinDate");
  const salaryPayDay = localStorage.getItem("salaryPayDay");

  let preFillMessage = 
`
Hallo Nina, saya ingin bertanya nih, ini data-data saya yaa:

Nama: ${userName}
NRK: ${employeeId}
Client: ${clientName}
Join Date: ${moment(joinDate).format("YYYY-MM-DD")}
Tanggal Gaji (Setiap Bulan): ${salaryPayDay}
Kendala:
`;

  const handleSendMessage = () => {
    const phoneNumber = "6282310997063";
    const encodedMessage = encodeURIComponent(preFillMessage);
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappLink, "_blank");
  };
  return (
    <>
      <Drawer
        anchor="left"
        open={width > 1200 || onOpenSidebar}
        onClose={onCloseSidebar}
        variant={width > 1200 ? "permanent" : "temporary"}
        PaperProps={{ sx: { width: 281, position: "unset" } }}
      >
        <div className={`sidebar ${!onOpenSidebar && "close"}`}>
          <Link to="/superadmin" className="sidebar__logo">
            {/* <div> */}
            <img src={logo} alt="company logo" />
            <span>MyWorkSpace</span>
            {/* </div> */}
          </Link>
          <div className="wrapper__sidebar">
            <div className="item__container">
              {SidebarData.map((item, index) => {
                return (
                  <SubMenu
                    item={item}
                    key={index}
                    // active={isActive(item.route)}
                    active={index === activeItem}
                  />
                );
              })}
            </div>
            <div className="helpdesk__container">
              <div className="helpdesk__wrapper">
                <div className="helpdesk__image-wrapper">
                  <img src={HELP_DESK_IMAGE} alt="help-desk" />
                </div>
                <div
                  className="helpdesk__item-content"
                  onClick={handleSendMessage}
                >
                  <div className="helpdesk__container-box">
                    <div className="helpdesk__circle-box">
                      <img src={WHATSAPP_IMAGE} alt="whatsapp logo" />
                    </div>
                    <div className="helpdesk__content-text">
                      <span>IT Help Desk</span>
                      <span>Perlu bantuan kami ?</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      {/* <div className="sidebar">
        <Link to="/dashboard">
          <div className="sidebar__logo">
            <img src={logo} alt="company logo" />
            <span>MyWorkSpace</span>
          </div>
        </Link>

        {SidebarData.map((item, index) => {
          return (
            <SubMenu item={item} key={index} active={index === activeItem} />
          );
        })}
      </div> */}
    </>
  );
};

export default SidebarSA;

// {
//   display_name: "Profile",
//   route: "/profile",
//   icon: "bx bxs-user-rectangle",
// },
// {
//   display_name: "Settings",
//   route: "/settings",
//   icon: "bx bx-cog",
// },
