import React from "react";
import "./Input.css";

export default function InputText({
  name,
  onChange,
  label,
  type,
  value,
  placeholder,
  style,
  width,
  required,
  readOnly,
  maxLength,
  disabled,
}) {
  return (
    <>
      <input
        width={width}
        name={name}
        onChange={onChange}
        label={label}
        type={type}
        value={value}
        placeholder={placeholder}
        style={style}
        className="input__text"
        required={required}
        readOnly={readOnly}
        maxLength={maxLength}
        disabled={disabled}
      />
    </>
  );
}
