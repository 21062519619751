import React, { useState } from "react";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import "./index.css";
import InputText from "../../../utils/Input/Input";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import { useDropzone } from "react-dropzone";
import PublishIcon from "@mui/icons-material/Publish";

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
  },
});
export default function AddEmployeeManual() {
  const classes = useStyles();
  const options = ["MD", "Sales Promotion Girl", "Sales Promotion Boy"];
  const optionsStore = ["Indomaret", "Giant", "Alfamidi", "Hero"];
  const optionsLeader = ["Anastasia", "Sujini", "Alkatri", "Aisyah Dara"];
  const optionsTime = ["WIB", "WITA", "WIT"];
  const [employeeName, setEmployeeName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedLeader, setSelectedLeader] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [address, setAddress] = useState("");
  const [files, setFiles] = useState([]);
  const history = useHistory();

  const navigateBack = () => history.go(-1);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      Employee: employeeName,
      PhoneNumber: phoneNumber,
      Store: selectedStore,
      Position: selectedPosition,
      Leader: selectedLeader,
      WorkTime: selectedTime,
      Address: address,
      Photo: files[0]?.path,
    };
    console.log(data, "cek data");
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });


  const images = files.map((file, ind) => (
    <div key={ind}>
      <div>
        <img
          src={file.preview}
          style={{ width: 300, height: 170 }}
          alt="preview"
        />
      </div>
    </div>
  ));
  return (
    <div className="employee__manual-container">
    <form className="employee__manual-box">
        <div className="employee__items-desc">
          <div className="employee__external-wrapper">
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Employee Name</label>
              <InputText
                value={employeeName}
                placeholder=""
                onChange={(e) => setEmployeeName(e.target.value)}
                type="text"
                required
              />
            </div>
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Position</label>
              <FormControl fullWidth size="small" style={{ width: "100%" }}>
                <Select
                  className={classes.selectBorder}
                  value={selectedPosition}
                  displayEmpty
                  onChange={(e) => setSelectedPosition(e.target.value)}
                  required
                >
                  {/* <MenuItem value="">None</MenuItem> */}
                  {options.map((option, el) => (
                    <MenuItem
                      key={el}
                      onClick={() => setSelectedPosition(option)}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div
            className="employee__external-wrapper"
            style={{ paddingTop: 20 }}
          >
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Store</label>
              <FormControl fullWidth size="small">
                <Select
                  className={classes.selectBorder}
                  value={selectedStore}
                  displayEmpty
                  onChange={(e) => setSelectedStore(e.target.value)}
                //   required
                >
                  {optionsStore.map((option, el) => (
                    <MenuItem
                      key={el}
                      onClick={() => setSelectedStore(option)}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Phone Number</label>
              <InputText
                value={phoneNumber}
                placeholder=""
                onChange={(e) => setPhoneNumber(e.target.value)}
                type="number"
                required
              />
            </div>
          </div>
          <div
            className="employee__external-wrapper"
            style={{ paddingTop: 20 }}
          >
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Leader</label>
              <FormControl fullWidth size="small">
                <Select
                  className={classes.selectBorder}
                  value={selectedLeader}
                  displayEmpty
                  onChange={(e) => setSelectedLeader(e.target.value)}
                  required
                >
                  {optionsLeader.map((option, el) => (
                    <MenuItem
                      key={el}
                      onClick={() => setSelectedLeader(option)}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Work Time</label>
              <FormControl fullWidth size="small">
                <Select
                  className={classes.selectBorder}
                  value={selectedTime}
                  displayEmpty
                  onChange={(e) => setSelectedTime(e.target.value)}
                  required
                >
                  {optionsTime.map((option, el) => (
                    <MenuItem
                      key={el}
                      onClick={() => setSelectedTime(option)}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div
            className="employee__external-wrapper"
            style={{ paddingTop: 20 }}
          >
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Address</label>
              <InputText
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                type="text"
                placeholder=""
                required
              />
            </div>
            <div
              className="employee__external-text-input"
            //   style={{ display: "hidden" }}
            >
              <span></span>
            </div>
          </div>
          <div className="add__store-container-map">
            {images.length ? (
              <div {...getRootProps()}>
                {images}
                <input type="text" {...getInputProps()} />
              </div>
            ) : (
              <div className="container__upload-image">
                <div className="wrapper__upload-image" {...getRootProps()}>
                  <input type="text" {...getInputProps()} />
                  <div className="box__input-image">
                    <span>Upload Image Here</span>
                    <div className="box__input-file">
                      <span>File must JPG or PNG</span>
                    </div>
                    <div className="box__input-or">
                      <span> OR </span>
                    </div>
                    <div className="container__input-image">{images}</div>
                    <div className="box__input-button">
                      <Button>
                        <span>Browse File</span>
                        <PublishIcon />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
      <div className="add__employee-manual-btn">
        <Button
          className="add__store-btn-save"
          style={{
            background: "#1571DE",
            color: "#FFFFFF",
            fontSize: 14,
            fontWeight: 500,
          }}
          onClick={handleSubmit}
          // type="submit"
        >
          Save
        </Button>
        <Button
          className="add__store-btn-cancel"
          style={{
            border: "1px solid #1571DE",
            color: "#1571DE",
            fontSize: 14,
            fontWeight: 500,
          }}
          onClick={navigateBack}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
