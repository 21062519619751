import React from "react";
import { Helmet } from "react-helmet";
import { DetailListAttendanceFMCG } from "../../../../components/FMCG";

export default function DetailAttendanceFMCG() {
  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Attendance</title>
      </Helmet>
      <DetailListAttendanceFMCG />
    </div>
  );
}
