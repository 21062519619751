/* eslint-disable no-unused-vars */

import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { AppBankEdit } from "../../../../components/SuperAdmin/_database";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";

export default function EditBankSA() {
  const token = localStorage.getItem("accessToken");
  const params = useParams()
  const location = useLocation()
  // const locationData = useSelector((state) => location.state.data);
  const bankData = useSelector((state) => location.state.data);
  const history = useHistory();


  const dispatch = useDispatch()

//   useEffect(() => {
//     dispatch(getLocationById(token, params.id))
//   }, [dispatch, token, params.id])

  console.log(bankData)



  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Edit Bank</title>
        </Helmet>
        <Breadcrumb>
          <Button
            onClick={() => history.push("/superadmin/database")}
            style={{ textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>Master Data</span>
          </Button>
          <span style={{ fontSize: 20, color: "#255bfc" }}>Edit Bank Data</span>
        </Breadcrumb>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} style={{ padding: '20px 0' }}>
            <AppBankEdit bank={bankData} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
