import { Close } from '@mui/icons-material';
import { useState } from 'react';
import { updateClient } from '../../../../../redux/actions/SuperAdmin/adminActions';
import Styles from './EditInfoModal.module.css';

export default function EditInfoModal({ setEditMode, setSuccessEdit, data, dispatch, token }) {
    const [form, setForm] = useState({
        'name': data?.name,
        'group': data?.group,
        'address': data?.address,
    })

    const handleChange = (e) => {
        setForm((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateClient(token, data?._id, form))
        setSuccessEdit(true)
        setEditMode(false)
    }

    const handleReset = (e) => {
        e.preventDefault();
        setForm({
            'name': data?.name,
            'group': data?.group || '',
            'address': data?.address || '',
        })
    }


    return (
        <div className={Styles.Background}>
            <div className={Styles.Container}>
                <div className={Styles.Header}>
                    <span>Edit Company Information</span>
                    <button onClick={() => setEditMode(false)}><Close /></button>
                </div>
                <div className={Styles.Content}>
                    <form className={Styles.Form} onSubmit={handleSubmit}>
                        <div className={Styles.BoxForm}>
                            <label>Client Name</label>
                            <input type="text" name="name" value={form?.name || ''} onChange={handleChange} />
                        </div>
                        <div className={Styles.BoxForm}>
                            <label>Group</label>
                            <input type="text" name="group" value={form?.group || ''} onChange={handleChange} />
                        </div>
                        <div className={Styles.BoxForm}>
                            <label>Address</label>
                            <textarea name="address" value={form?.address || ''} onChange={handleChange} />
                        </div>
                        <div className={Styles.ButtonWrapper}>
                            <button type="submit">Save</button>
                            <button onClick={handleReset} type="reset">Reset</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}