/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { filter } from "lodash";

import {
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import USERLIST from "../TableUsers/_mocks/user";
import UserListHead from "../TableUsers/UserListHead";
import Scrollbar from "../TableUsers/Scrollbar";
import ModalSuccessEmployee from "./modal-success-employee/ModalSuccessEmployee";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";

const TABLE_HEAD = [
  { id: "name", label: "Employee Name", alignRight: false },
  { id: "client", label: "Position", alignRight: false },
  { id: "division", label: "Division", alignRight: false },
  { id: "" },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserAddEmployee({
  employee,
  selectedAddEmployee,
  setSelectedAddEmployee,
}) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const options = ["All", "Position"];
  const [isActive, setIsActive] = useState(false);
  const [addEmployee, setAddEmployee] = useState(false);
  //   const [addStaff, setAddStaff] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(
    employee,
    getComparator(order, orderBy),
    filterName
  );

  // console.log(employee, "cek employee add");
  return (
    <div className="container-user-detail-employee">
      <div className="wrapper-user-detail-employee">
        <div className="wrapper-searchbox-detail-employee">
          <SearchbarSA />
        </div>
        <div className="data-attendance-menu-right">
          <div>
            <FormControl>
              <Select
                value={selectedAddEmployee}
                displayEmpty
                // onChange={(e) => handleOpen(e.target.value)}
                SelectDisplayProps={{
                  style: {
                    paddingTop: 8,
                    paddingBottom: 8,
                    width: 100,
                    backgroundColor: "#fbfbfb",
                  },
                }}
              >
                {options.map((option, el) => (
                  <MenuItem
                    key={el}
                    onClick={() => setSelectedAddEmployee(option)}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="wrapper-table-detail-employee">
        <Scrollbar>
          <TableContainer>
            <Table
              sx={{
                width: "100%",
                maxHeight: "100%",
                backgroundColor: "#fafafa",
                borderRadius: 4,
              }}
            >
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={USERLIST.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, ind) => {
                    const { id, name, role2, avatarUrl, division } = row;
                    // const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1}>
                        <TableCell>
                          {/* <Checkbox
                    checked={isItemSelected}
                    onChange={(event) => handleClick(event, name)}
                  /> */}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt="IMAGE" src="" />
                            <Typography variant="subtitle2" noWrap>
                              {row.Name ? row.Name : "-"}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {row.Position ? row.Position : "-"}
                        </TableCell>
                        <TableCell align="left">
                          {row.Department ? row.Department : "-"}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            className="button-add-user-menu"
                            style={{
                              textTransform: "none",
                              width: 53,
                              height: 36,
                              backgroundColor: "#1571de",
                              color: "white",
                            }}
                            onClick={() => setAddEmployee(true)}
                          >
                            <span style={{ fontSize: 14 }}>Add</span>
                          </Button>
                          {addEmployee && (
                            <ModalSuccessEmployee
                              addEmployee={addEmployee}
                              setAddEmployee={setAddEmployee}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={USERLIST.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
