import React from 'react';
import Styles from './ModalViewFile.module.css'

export default function ModalViewFile({ file, setFile }) {
    return (
        <div className={Styles.Background}>
            <div className={Styles.Container}>
                {file !== "" ? (
                    <>
                        <span onClick={() => setFile(null)}></span>
                        <img src={file[0]?.url} alt="file" />
                    </>
                ) : (
                    <div className={Styles.EmptyContainer}>
                        <span onClick={() => setFile(null)}></span>
                        <div>No such file</div>
                    </div>

                )}

            </div>
        </div>
    )
}