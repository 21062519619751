import React from "react";
import SearchbarSA from "../../../../utils/SearchbarSA/SearchbarSA";
import "./StockCard.css";
import product_image from "../../../../../assets/images/product-image-1.jpg";

export default function StockCard() {
  return (
    <div className="product__list-container">
      <div className="product__list-search">
        <SearchbarSA />
      </div>
      <div className="product__list-items">
        <div className="product__items">
          <img src={product_image} alt="" />
          <div className="product__desc">
            <label htmlFor="">Nestle Dancow 3 Plus</label>
            <span>Last Update: 80 box</span>
          </div>
        </div>
        <div className="product__items">
          <img src={product_image} alt="" />
          <div className="product__desc">
            <label htmlFor="">Nestle Dancow 3 Plus</label>
            <span>Last Update: 80 box</span>
          </div>
        </div>  <div className="product__items">
          <img src={product_image} alt="" />
          <div className="product__desc">
            <label htmlFor="">Nestle Dancow 3 Plus</label>
            <span>Last Update: 80 box</span>
          </div>
        </div>  <div className="product__items">
          <img src={product_image} alt="" />
          <div className="product__desc">
            <label htmlFor="">Nestle Dancow 3 Plus</label>
            <span>Last Update: 80 box</span>
          </div>
        </div>  <div className="product__items">
          <img src={product_image} alt="" />
          <div className="product__desc">
            <label htmlFor="">Nestle Dancow 3 Plus</label>
            <span>Last Update: 80 box</span>
          </div>
        </div>
      </div>
    </div>
  );
}
