import React from 'react'
import { Grid, Container} from "@mui/material";
import { Helmet } from 'react-helmet';
import { RejectedPageMenu } from '../../../components/_approval-rejected-page/'

export default function RejectedAttendace() {
  return (
    <Container maxWidth="xl">
    <Helmet>
      <title>MyWorkSpace | Approval</title>
    </Helmet>
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <RejectedPageMenu title="Request Rejected" description="Sorry, your request have been rejected"/>
      </Grid>
    </Grid>
  </Container>
  )
}
