import React, { useState } from "react";
import "./index.css";
import ExportCSV from "../../../../SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import Scrollbar from "../../../../SuperAdmin/_user/TableUsers/Scrollbar";
import SearchbarSA from "../../../../utils/SearchbarSA/SearchbarSA";
import { Avatar, Button, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from "@mui/material";
import UserListHead from "../../../../SuperAdmin/_user/TableUsers/UserListHead";
import { useHistory } from "react-router";
import USERLIST from "../../../../SuperAdmin/_user/TableUsers/_mocks/user";

const TABLE_HEAD = [
  { id: "Name", label: "Customer Name", alignRight: false },
  { id: "Date Buy", label: "Date Buy", alignRight: false },
  { id: "Product Buy", label: "Product Buy", alignRight: false },
  { id: "Type Customer", label: "Type Customer", alignRight: false },
  { id: "" },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const AllCustomerList = () => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  // const [selected, setSelected] = useState([]);
  // const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");
  const [listDataCustomer, setListDataCustomer] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setListDataCustomer(event.target.value);
  };
  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchTerm(listDataCustomer);
  };

  return (
    <div className="container-all-customer-table">
    <div className="wrapper-item-top-customer">
      <div className="item-left-customer">
        <SearchbarSA 
          value={listDataCustomer}
          onChange={handleSearch}
          onSubmit={handleSubmitFilter}
         />
      </div>
      <div className="item-right-customer">
        <ExportCSV initial="Download Excel" />
      </div>
    </div>
    <div className="table-customer-bottom">
        <Scrollbar>
          <TableContainer sx={{ minWidth: 600, backgroundColor: "#fafafa" }}>
            <Table
              id="emp-table"
              sx={{
                width: "100%",
                maxHeight: "100%",
                backgroundColor: "#fafafa",
                borderRadius: 4,
              }}
            >
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                // rowCount={dataEmployee.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(USERLIST, getComparator(order, orderBy))
                //   .filter(
                //     (el) =>
                //       el.Name?.toLowerCase().includes(
                //         listDataCustomer?.toLowerCase()
                //       ) ||
                //       el.Position?.toLowerCase().includes(
                //         listDataCustomer?.toLocaleLowerCase()
                //       )
                //   )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((customer, ind) => {
                    const labelId = `enhanced-table-checkbox-${ind}`;
                    return (
                      <TableRow hover key={ind} tabIndex={-1}>
                        <TableCell></TableCell>
                        <TableCell component="th" scope="row" padding="none" id={labelId}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar  />
                            <Typography noWrap>Pandi Purnama</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>21 December 2021</TableCell>
                        <TableCell>Susu Bendera</TableCell>
                        <TableCell>Sampling</TableCell>
                        <TableCell align="right">
                            <Button sx={{ textTransform: 'none', fontSize: '1rem'}} onClick={() => history.push({
                                pathname: `/admin/customer-detail/`,
                                // state: customer
                            })}>
                                See Detail
                            </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 35, 45]}
          component="div"
          count={USERLIST.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>

  );
};

export default AllCustomerList;
