import React, { useEffect, useMemo, useState } from "react";
import SearchbarFMCG from "../../SearchbarFMCG/SearchbarFMCG";
import Styles from "./TableAttendanceFMCG.module.css";
import DownloadIcon from "@mui/icons-material/Download";
import {
  TableCell,
  TableHead,
  TableRow,
  styled,
  TableSortLabel,
  TextField,
  Card,
  TableContainer,
  Table,
  TableBody,
  Stack,
  Avatar,
  Typography,
  TablePagination,
  Autocomplete,
} from "@mui/material";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
// import { makeStyles } from "@mui/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import { Link } from "react-router-dom";
import LoadingFMCG from "../../../utils/LoadingFMCG/LoadingFMCG";
import { debounce } from "lodash";
import ExportDataExcelFMCG from "../../../utils/ExportCSVFMCG";
import moment from "moment";
import eyeIcon from "../../../../assets/images/eye.svg";
import AbsenceDetailModal from "../AttendaceDetailModal/AttendaceDetailModal";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      width: "100%",
    },
    "& .MuiInputBase-input-MuiOutlinedInput-input": {
      padding: 0,
    },
    "& input::placeholder": {
      color: "#A4A4A4",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "18.2px",
      opacity: 1,
    },
    "& Svg": {
      color: "#1571DE",
    },
  },
});

const MuiAutoComplete = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
  "& .MuiInputBase-input-MuiOutlinedInput-input": {
    padding: 0,
  },
  "& input::placeholder": {
    color: "#A4A4A4",
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "18.2px",
    opacity: 1,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    // border: "1.5px solid #d3c9c9",
    // borderColor: "#d3c9c9",
    borderRadius: 5,
  },
});

// const useStyles = makeStyles((theme) => ({
//   selectBorder: {
//     "& .MuiOutlinedInput-root": {
//       borderRadius: 8,
//     },
//     "& .MuiOutlinedInput-notchedOutline": {
//       borderRadius: 8,
//     },
//     "& .MuiSelect-select": {
//       color: "black",
//       fontSize: 13,
//       fontWeight: 400,
//     },
//     // "& input::placeholder": {
//     //   color: "#A4A4A4",
//     //   fontSize: 13,
//     //   fontWeight: 400,
//     //   lineHeight: "18.2px",
//     //   opacity: 1,
//     // },
//   },
//   adorment: {
//     "& .MuiTypography-root ": {
//       color: "#A4A4A4",
//       fontSize: 15,
//       fontWeight: 400,
//       lineHeight: "18.2px",
//     },
//   },
// }));

const TABLE_HEAD = [
  { id: "Name", label: "Employee", alignRight: false },
  { id: "Store", label: "Store", alignRight: false },
  { id: "Leader", label: "Leader", alignRight: false },
  { id: "Area", label: "Area", alignRight: false },
  { id: "startTime", label: "Time In", alignRight: false },
  { id: "endTime", label: "Time Out", alignRight: false },
  { id: "type", label: "Status", alignRight: false },
  { id: "image", label: "Foto", alignRight: false },
  // { id: "" },
];

const positionArr = [
  "MD",
  "MD Leader",
  "Sales Promotion Girl",
  "Leader",
  "SPG Leader",
];
const leaderArr = ["Cameron Williamson", "Wade Warren", "Robert Fox"];
const statusArr = ["all", "Sick", "Attend", "Leave"];

const data = [
  {
    Name: "Liko",
    Position: "Sales Promotion Girl",
    Leader: "Wade Warren",
    Area: "Jakarta",
    date: new Date(),
    startTime: moment(new Date()).format("hh:mm"),
    endTime: moment(new Date()).format("hh:mm"),
    type: "Attend",
    image: "https://api.lorem.space/image/face?w=154&h=224",
    id: 1,
  },
  {
    Name: "Amanda",
    Position: "Sales Promotion Girl",
    Leader: "Wade Warren",
    date: new Date(),
    Area: "Jakarta",
    startTime: moment(new Date()).format("hh:mm"),
    endTime: moment(new Date()).format("hh:mm"),
    type: "Sick",
    image: "https://api.lorem.space/image/face?w=153&h=223",
    id: 2,
  },
  {
    Name: "Sisca",
    Position: "Sales Promotion Girl",
    Leader: "Wade Warren",
    Area: "Jakarta",
    date: new Date(),
    startTime: moment(new Date()).format("hh:mm"),
    endTime: moment(new Date()).format("hh:mm"),
    type: "Leave",
    image: "https://api.lorem.space/image/face?w=151&h=221",
    id: 3,
  },
  {
    Name: "James",
    Position: "Sales Promotion Girl",
    Leader: "Wade Warren",
    Area: "Jakarta",
    date: new Date(),
    startTime: moment(new Date()).format("hh:mm"),
    endTime: moment(new Date()).format("hh:mm"),
    type: "Attend",
    image: "https://api.lorem.space/image/face?w=152&h=222",
    id: 4,
  },
  {
    Name: "Karen",
    Position: "Sales Promotion Girl",
    Leader: "Wade Warren",
    date: new Date(),
    Area: "Jakarta",
    startTime: moment(new Date()).format("hh:mm"),
    endTime: moment(new Date()).format("hh:mm"),
    type: "Attend",
    image: "https://api.lorem.space/image/face?w=155&h=225",
    id: 5,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      // sx={{
      //   "& tr": {
      //     backgroundColor: "#F0F0F0",
      //   },
      // }}
      sx={{
        "& tr > th": {
          backgroundColor: "#F0F0F0",
          position: "sticky",
          zIndex: 2,
          top: 0,
        },
        "& tr > :first-of-type": {
          zIndex: 3,
          left: 0,
          top: 0,
          width: 250,
          borderRight: "2px solid #E0E0E0",
        },
        "& tr > :last-of-type": {
          zIndex: 3,
          right: 0,
          top: 0,
          width: 90,
          borderLeft: "2px solid #E0E0E0",
        },
        "& th": {
          fontSize: 15,
          fontWeight: 500,
          color: "#0A0A0A",
        },
      }}
    >
      <TableRow>
        {/* <TableCell padding="none" style={{ paddingLeft: 0 }}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={rowCount !== values?.users?.length}
            sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
          />
        </TableCell> */}
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          align="left"
          // align={headCell.numeric ? "right" : "left"}
        >
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const TableAttendance = () => {
  // const classes = useStyles();
  const [listEmployeesFMCG, setListEmployeesFCMG] = useState("");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState("");
  const [orderBy, setOrderBy] = useState("Name");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedLeader, setSelectedLeader] = useState("");
  const [selectedAbsence, setSelectedAbsence] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  // const [selectedInput, setSelectedInput] = useState({
  //   position: "",
  //   type: "",
  //   workArea: "",
  //   workTime: "",
  //   store: "",
  //   level: "",
  // });
  const [valueTimeFrom, setValueTimeFrom] = useState(null);
  const [valueTimeTo, setValueTimeTo] = useState(null);

  useEffect(() => {}, [selectedPosition, selectedLeader]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e) => {
    setListEmployeesFCMG(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <div className={Styles.ContainerTableAttendanceFMCG}>
      <div className={Styles.WrapperTableAttendanceFMCG}>
        <div className={Styles.BoxFilterTableAttendancesFMCG}>
          <div className={Styles.WrapperPosition}>
            <span>Position</span>
            <Autocomplete
              disablePortal
              options={positionArr}
              value={selectedPosition}
              inputValue={selectedPosition}
              onInputChange={(e, value) => setSelectedPosition(value)}
              renderInput={(params) => (
                <MuiAutoComplete
                  {...params}
                  size="small"
                  placeholder="Position"
                />
              )}
            />
          </div>
          <div className={Styles.WrapperPosition}>
            <span>Attend Status</span>
            <Autocomplete
              disablePortal
              options={statusArr}
              value={selectedStatus}
              inputValue={selectedStatus}
              onInputChange={(e, value) => setSelectedStatus(value)}
              renderInput={(params) => (
                <MuiAutoComplete
                  {...params}
                  size="small"
                  placeholder="Attend Status"
                />
              )}
            />
          </div>
          <div className={Styles.WrapperLeader}>
            <span>Leader</span>
            <Autocomplete
              disablePortal
              options={leaderArr}
              value={selectedLeader}
              inputValue={selectedLeader}
              onInputChange={(e, value) => setSelectedLeader(value)}
              renderInput={(params) => (
                <MuiAutoComplete
                  {...params}
                  size="small"
                  placeholder="Leader"
                />
              )}
            />
          </div>
        </div>
        <div className={Styles.HeaderTableAttendanceFMCG}>
          <div className={Styles.SearchWrapper}>
            <SearchbarFMCG
              searchWidth="379px"
              // value={listEmployeesFMCG}
              onChange={debouncedResults}
              placeholder="Search by name"
            />
            <div className={Styles.WrapperItemsRangeDate}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className={Styles.ItemRangeDateFrom}>
                  <DatePicker
                    components={{
                      OpenPickerIcon: CalendarMonthIcon,
                    }}
                    required
                    value={valueTimeFrom}
                    onChange={setValueTimeFrom}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        size="small"
                        sx={{ width: 200 }}
                      />
                    )}
                  />
                </div>
                <div className={Styles.ItemRangeDateTo}>
                  <DatePicker
                    components={{
                      OpenPickerIcon: CalendarMonthIcon,
                    }}
                    required
                    value={valueTimeTo}
                    onChange={setValueTimeTo}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        size="small"
                        sx={{ width: 200 }}
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </div>
          </div>
          <ExportDataExcelFMCG
            // data={dataToExport?.map((el, ind) => ({
            //   "No. ": ind + 1,
            //   Nama: el.Name,
            //   Position: el.Position,
            //   Nrk: el.Nrk,
            //   "Date in": el.datestartTime,
            //   "Date out": el.dateendTime,
            //   "Time in": el.startTime,
            //   "Time out": el.endTime,
            //   "Coordinate Point": `${el.latitude} ${el.longitude}`,
            //   "type Absence":
            //     (el.type === "presence" && "Hadir") ||
            //     (el.type === "annualLeave" && "Cuti") ||
            //     (el.type === "permit" && "Sakit"),
            // }))}
            initial="Download Excel"
            startIcon={<DownloadIcon />}
            // filename={`Attendance Report ${new Date().toLocaleDateString()}`}
          />
        </div>
        <div className={Styles.ContainerAttendanceFMCG}>
          <Card>
            {loading ? (
              <div className={Styles.ContainerAttendanceFMCGLoading}>
                <LoadingFMCG width={800} height={200} />
              </div>
            ) : (
              <Scrollbar>
                <TableContainer>
                  <Table
                    sx={{
                      width: "120%",
                      borderCollapse: "separate",

                      // maxHeight: "100%",
                    }}
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: 5,
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      // onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      values={data}
                      rowCount={
                        data?.filter(
                          (el) =>
                            el.Name?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                            el.Position?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                            el.type
                              ?.toLowerCase()
                              .includes(listEmployeesFMCG.toLowerCase()) ||
                            el.Leader?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                            el.Area?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            )
                        ).length
                      }
                    />
                    <TableBody
                      sx={{
                        "& tr > th": {
                          position: "sticky",
                          padding: ".5rem",
                          zIndex: 2,
                          top: 0,
                          background: "#FFFFFF",
                        },
                        "& tr > :first-of-type": {
                          left: 0,
                          position: "sticky",
                          zIndex: 1,
                          background: "#FFFFFF",
                          borderRight: "2px solid #E0E0E0",
                          width: 250,
                          fontSize: 14,
                          fontWeight: 500,
                          color: "#0A0A0A",
                          textTransform: "capitalize",
                          padding: "16px",
                        },
                        "& tr > :last-of-type": {
                          right: 0,
                          position: "sticky",
                          zIndex: 1,
                          background: "#FFFFFF",
                          borderLeft: "2px solid #E0E0E0",
                          width: 90,
                          fontSize: 14,
                          fontWeight: 500,
                          color: "#0A0A0A",
                          textTransform: "capitalize",
                        },
                        "& td > p": {
                          fontSize: 13,
                          fontWeight: 400,
                          color: "#404040",
                          textTransform: "capitalize",
                        },
                      }}
                    >
                      {stableSort(data, getComparator(order, orderBy))
                        .filter(
                          (el) =>
                            (el.Employee?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                              el.Position?.toLowerCase().includes(
                                listEmployeesFMCG.toLowerCase()
                              ) ||
                              el.type
                                ?.toLowerCase()
                                .includes(listEmployeesFMCG.toLowerCase()) ||
                              el.Leader?.toLowerCase().includes(
                                listEmployeesFMCG.toLowerCase()
                              ) ||
                              el.Area?.toLowerCase().includes(
                                listEmployeesFMCG.toLowerCase()
                              )) &&
                            (selectedPosition
                              ? el.Position === selectedPosition
                              : true) &&
                            (selectedLeader
                              ? el.Leader === selectedLeader
                              : true)
                        )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((employee, ind) => {
                          // const isItemSelected = isSelected(employee?._id);
                          const labelId = `enhanced-table-checkbox-${ind}`;
                          return (
                            <TableRow
                              hover
                              key={ind}
                              tabIndex={-1}
                              id={labelId}
                              sx={{
                                "&.MuiTableRow-root:hover": {
                                  backgroundColor: "#eef6ff",
                                },
                              }}
                            >
                              <TableCell
                                component="td"
                                scope="row"
                                padding="none"
                              >
                                <Link
                                  to={{
                                    pathname: `/admin-fmcg/attendances/detail/1`,
                                    // state: { location: "detailsSpgStore" },
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Avatar
                                      variant="square"
                                      style={{ width: 30, height: 30 }}
                                    />
                                    <Typography
                                      variant="subtitle2"
                                      noWrap
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      {employee.Name
                                        ? employee.Name.toLowerCase()
                                        : "-"}
                                    </Typography>
                                  </Stack>
                                </Link>
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {employee.Position ? employee.Position : "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {employee.Leader ? employee.Leader : "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {employee.Area ? employee.Area : "-"}
                              </TableCell>

                              <TableCell align="left">
                                {employee?.startTime}
                              </TableCell>
                              <TableCell align="left">
                                {employee?.endTime}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {employee?.type}
                              </TableCell>
                              <TableCell align="left">
                                <img
                                  style={{ width: "45px", height: "58px" }}
                                  src={employee.image}
                                  alt={employee.Name}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <div className={Styles.IconWrapper}>
                                  <div className={Styles.IconContainer}>
                                    <img
                                      alt="eyeIcon"
                                      src={eyeIcon}
                                      onClick={() =>
                                        setSelectedAbsence(employee)
                                      }
                                    />
                                  </div>
                                </div>
                              </TableCell>
                              {/* <TableCell align="left" variant="square">
                                <Typography
                                  style={{
                                    width: 30,
                                    height: 30,
                                    backgroundColor: "#ECF5FF",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: 5,
                                  }}
                                  component="div"
                                >
                                  <img
                                    src={EditIcon2}
                                    alt=""
                                    style={{
                                      width: 20,
                                      height: 20,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      history.push({
                                        pathname: `/admin-fmcg/edit-store-spg/${store._id}`,
                                        state: { location: "editStoreSpg" },
                                      })
                                    }
                                  />
                                </Typography>
                                <Typography
                                  component="div"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: 30,
                                    height: 30,
                                    backgroundColor: '#ECF5FF'
                                  }}
                                >
                                  <Button
                                    onClick={() => {
                                      history.push({
                                        pathname: `/admin-fmcg/details-spg-store/lkshdo1hdon1owh`,
                                        state: { location: "editSpgStore" },
                                      });
                                    }}
                                  >
                                    <EditIcon />
                                  </Button>
                                </Typography>
                              </TableCell> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            )}
            {!loading && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 30]}
                component="div"
                count={
                  data?.filter(
                    (el) =>
                      (el.Name?.toLowerCase().includes(
                        listEmployeesFMCG.toLowerCase()
                      ) ||
                        el.Position?.toLowerCase().includes(
                          listEmployeesFMCG.toLowerCase()
                        ) ||
                        el.Leader?.toLowerCase().includes(
                          listEmployeesFMCG.toLowerCase()
                        ) ||
                        el.Area?.toLowerCase().includes(
                          listEmployeesFMCG.toLowerCase()
                        )) &&
                      (selectedPosition
                        ? el.Position === selectedPosition
                        : true) &&
                      (selectedLeader ? el.Leader === selectedLeader : true)
                  ).length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Card>
        </div>
      </div>
      {selectedAbsence && (
        <AbsenceDetailModal
          selectedAbsence={selectedAbsence}
          setSelectedAbsence={setSelectedAbsence}
        />
      )}
    </div>
  );
};

export default TableAttendance;
