/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles, StylesContext } from "@mui/styles";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import ImageOffice from "../../../../../assets/images/Office.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  TextField,
  styled,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  Autocomplete,
  Tab,
  Typography,
  Tabs,
  TablePagination,
  Button,
  TableContainer,
  Table,
  TableBody,
  Stack,
  Paper,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddIcon from "@mui/icons-material/Add";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircle from "@mui/icons-material/CheckCircle";
import TrashIcon from "../../../assets/images/trash-2.png";
// import EditIcon from "../../../../assets/images/edit.png";
import EditIcon from "../../../assets/images/edit.png";
import InputText from "../../utils/Input/Input";
import SearchbarSA from "../../utils/SearchbarSA/SearchbarSA";
import { UseGroupName } from "../../utils/hooks";
import { fetchAllEmployeesExternal } from "../../../redux/actions/External/employeesExternalActions";
// moment.locale("en-gb");

const MuiTextField = styled(TextField)({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
    "& .MuiSelect-select": {
      fontSize: 16,
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
    // "& .MuiOutlinedInput-notchedOutline": {
    //   border: "1.5px solid #d3c9c9",
    //   borderColor: "#d3c9c9",
    //   borderRadius: 8,
    // },
});

const useStyles = makeStyles((theme) => ({
  ItemModalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& > span": {
      color: "#333333",
      fontSize: 16,
      fontWeight: 600,
    },
  },
  ItemModalTopLeft: {
    "& > span": {
      color: "#333333",
      fontSize: 16,
      fontWeight: 600,
    },
  },
  Content: {
    display: "flex",
    // flexDirection: "column",
    gap: 15,
    padding: 20,
  },
  WrapperToolHead: {
    display: "flex",
    // alignItems: "center",
    // columnGap: 10,
    // width: "100%",
  },
  ItemToolTop: {
    width: "100%",
    display: "flex",
    columnGap: 10,
    paddingInline: 28,
    paddingBottom: 24,
    paddingTop: 10,
  },
  Button: {
    display: "flex",
    alignSelf: "flex-end",
    gap: 10,
    "& > button:nth-of-type(1)": {
      outline: "none",
      background: "#FFFFFF",
      border: "1px solid #333333",
      borderRadius: 5,
      color: "#333333",
      width: 95,
      height: 34,
      cursor: "pointer",
    },
    "& > button:nth-of-type(2)": {
      outline: "none",
      background: "#1571DE",
      borderRadius: 5,
      color: "#FFFFFF",
      width: 81,
      height: 34,
      cursor: "pointer",
    },
  },
  ProfileInput: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    width: "100%",
    "& > span": {
      fontSize: 13,
      color: "#0A0A0A",
      fontWeight: 500,
    },
    "& > textarea": {
      width: "100%",
      height: 40,
      background: "#fbfbfb",
      border: "1.5px solid #d3c9c9",
      borderRadius: 8,
      paddingLeft: 10,
      paddingTop: 10,
      resize: "vertical",
    },
    "& > ::placeholder": {
      color: "#616161",
      fontStyle: "italic",
    },
  },
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
    },
    "& .MuiSelect-select": {
      fontSize: 16,
    },
  },
}));

const TABLE_HEAD = [
  { id: "Name", label: "Leader Name", alignRight: false },
  { id: "Position", label: "Position", alignRight: false },
  { id: "Client", label: "Client", alignRight: false },
  { id: "Nrk", label: "Nrk", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={rowCount !== values?.users?.length}
            sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
          />
        </TableCell>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component="div" sx={{ width: "100%" }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `indicator-${index}`,
    "aria-controls": `indicator-${index}`,
  };
}

function LabelListLeader() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        columnGap: 5,
      }}
    >
      <FormatListBulletedIcon sx={{ width: 22, height: 22 }} />
      <span style={{ fontSize: 13, fontWeight: 500 }}>List Leader</span>
    </div>
  );
}

function LabelAddLeader() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        columnGap: 5,
      }}
    >
      <AddIcon sx={{ width: 22, height: 22 }} />
      <span style={{ fontSize: 13, fontWeight: 500 }}>Add Leader</span>
    </div>
  );
}

export default function ModalTabListFMCG({
  data,
  description,
  openModal,
  setOpenModal,
  modal,
  modalListLeader,
  setModalListLeader,
}) {
  const { isLoading, employeesExternal, employeeByIdExternal, errorMessage } =
    useSelector((state) => state.employeesExternal);
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const groupName = localStorage.getItem("group");
  const getClientName = UseGroupName();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [values, setValues] = useState({});
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [orderBy, setOrderBy] = useState("Name");
  const [filterName, setFilterName] = useState("");
  const [listEmployees, setListEmployees] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [listDataEmployee, setListDataEmployee] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedAddClient, setSelectedAddClient] = useState("");

  useEffect(() => {
    dispatch(fetchAllEmployeesExternal(token, groupName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [openModal, setOpenModal] =useState(false);

  // useEffect(() => {
  //   if(description === 'Edit Leader') {
  //     setEditLeader(true)
  //   } else {
  //     setModalAddLeader(true)
  //   }
  // },[])

  const handleClose = () => {
    setModalListLeader(false);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleClick = (event) => {
    // setValue(event);
    // console.log(event);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSearch = (event) => {
    setListDataEmployee(event.target.value);
  };
  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchTerm(listDataEmployee);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // setOrderBy(property);
  };

  //   const onHandleSubmit = () => {
  //     const data = {
  //       id: client._id,
  //       ...values,
  //     };
  //   };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Boolean(modalListLeader)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className={classes.ItemModalTop}>
            <div className={classes.ItemModalTopLeft}></div>
            <div className={classes.ItemModalTopLeft}>
              <span>List Leader</span>
            </div>
            <div className={classes.ItemModalTopLeft}>
              <CloseIcon
                sx={{ cursor: "pointer", width: 24, height: 24, marginTop: 1 }}
                onClick={() => setModalListLeader(false)}
              />
            </div>
          </div>
        </DialogTitle>

        <DialogContent sx={{ padding: 0 }}>
          <div className={classes.WrapperToolHead}>
            {/* <TabContext value={value}> */}
            <Box sx={{ width: "100%" }}>
              <Box sx={{ bgcolor: "background.paper" }}>
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="indicator example"
                  TabIndicatorProps={{
                    title: "indicator",
                    hidden: true,
                  }}
                  sx={{
                    "& button": { borderRadius: 2, backgroundColor: "#F6F6F6" },
                    "& button:hover": {
                      backgroundColor: "#1571DE",
                      color: "white",
                    },
                    "& button:focus": {
                      backgroundColor: "#1571DE",
                      color: "white",
                    },
                    "& button:active": {
                      backgroundColor: "#1571DE",
                      color: "white",
                    },
                    "& .MuiTabs-flexContainer": {
                      justifyContent: "flex-end",
                      paddingRight: 2,
                      columnGap: 2,
                    },
                  }}
                >
                  <Tab
                    onClick={handleClick}
                    label={<LabelListLeader />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    onClick={handleClick}
                    label={<LabelAddLeader />}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <TabPanel
                value={value}
                index={0}
              >
                <div className={classes.ItemToolTop}>
                  <SearchbarSA
                    value={listDataEmployee}
                    onChange={handleSearch}
                    onSubmit={handleSubmitFilter}
                  />
                  <Autocomplete
                    disablePortal
                    options={getClientName}
                    value={selectedClient}
                    inputValue={selectedClient}
                    onInputChange={(e, value) => setSelectedClient(value)}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        size="small"
                        placeholder="Client"
                        sx={{ width: 300 }}
                      />
                    )}
                  />
                </div>
                <Paper sx={{ width: "100%", backgroundColor: "#FFFFFF" }}>
                  <TableContainer>
                    <Table
                      sx={{ width: "100%" }}
                      // sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      // size={dense ? "small" : "medium"}
                    >
                      <EnhancedTableHead
                        numSelected={data}
                        order={order}
                        orderBy={orderBy}
                        // onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        // rowCount={values.users?.length}
                        values={data}
                        rowCount={data.filter(
                          (el) =>
                            el.Name?.toLowerCase().includes(
                              listDataEmployee.toLowerCase()
                            ) ||
                            el.Position?.toLowerCase().includes(
                              listDataEmployee.toLowerCase()
                            ) ||
                            el.Client?.toLowerCase().includes(
                              listDataEmployee.toLowerCase()
                            ) ||
                            el.Nrk?.toLowerCase().includes(
                              listDataEmployee.toLowerCase() &&
                                (selectedClient
                                  ? el?.Client === selectedClient
                                  : true)
                            )
                        )}
                      />
                      <TableBody>
                        {stableSort(data, getComparator(order, orderBy))
                          .filter(
                            (el) =>
                              el.Name?.toLowerCase().includes(
                                listDataEmployee.toLowerCase()
                              ) ||
                              el.Position?.toLowerCase().includes(
                                listDataEmployee.toLowerCase()
                              ) ||
                              el.Client?.toLowerCase().includes(
                                listDataEmployee.toLowerCase()
                              ) ||
                              el.Nrk?.toLowerCase().includes(
                                listDataEmployee.toLowerCase()
                              )
                          )
                          .filter((el) =>
                            selectedClient
                              ? el?.Client === selectedClient
                              : true
                          )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            // const isItemSelected = row?.Locations.some((el) => el._id === id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                // onClick={(event) =>
                                //   handleClick(event, row.employeeName)
                                // }
                                // role="checkbox"
                                // aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row?.Name}
                                // selected={isItemSelected}
                              >
                                <TableCell padding="none">
                                  <Checkbox
                                    // onClick={(event) => handleClick(event, row?._id)}
                                    color="primary"
                                    // checked={isItemSelected}
                                    inputProps={{ "aria-labelledby": labelId }}
                                    icon={
                                      <RadioButtonUncheckedIcon
                                        sx={{ color: "#000000" }}
                                      />
                                    }
                                    checkedIcon={
                                      <CheckCircle sx={{ color: "#1571DE" }} />
                                    }
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        width: 24,
                                        height: 24,
                                        borderRadius: "50%",
                                      },
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                >
                                  <Stack
                                    direction="column"
                                    alignItems="left"
                                    spacing={0}
                                  >
                                    <Typography
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {row?.Name?.toLowerCase()}
                                    </Typography>
                                    <Typography
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {row?.PhoneNumber
                                        ? row?.PhoneNumber
                                        : "-"}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">
                                  {row.Position}
                                </TableCell>
                                <TableCell>{row?.Client}</TableCell>
                                <TableCell align="left">
                                  {row?.Nrk ? row?.Nrk : "-"}
                                </TableCell>
                                <TableCell align="left" padding="none">
                                  <Stack direction="row" spacing={2}>
                                    <img
                                      src={EditIcon}
                                      alt=""
                                      style={{
                                        height: 24,
                                        weight: 24,
                                        cursor: "pointer",
                                      }}
                                      onClick={(event) => {
                                        setOpenModal(row);
                                        setModalListLeader(false);
                                      }}
                                      // onClick={() => showEditModal(row)}
                                    />
                                    <img
                                      src={TrashIcon}
                                      alt=""
                                      style={{ height: 24, weight: 24 }}
                                      // onClick={() => }
                                    />
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={
                      data.filter(
                        (el) =>
                          el.Name?.toLowerCase().includes(
                            listDataEmployee.toLowerCase()
                          ) ||
                          el.Position?.toLowerCase().includes(
                            listDataEmployee.toLowerCase()
                          ) ||
                          el.Client?.toLowerCase().includes(
                            listDataEmployee.toLowerCase()
                          ) ||
                          el.PhoneNumber?.toLowerCase().includes(
                            listDataEmployee.toLowerCase() &&
                              (selectedClient
                                ? el?.Client === selectedClient
                                : true)
                          )
                      ).length
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className={classes.Content}>
                  <div className={classes.ProfileInput}>
                    <span>Name</span>
                    <InputText
                      value={values?.Name}
                      onChange={handleChange("Name")}
                      placeholder="Leader Name"
                      type="text"
                    />
                  </div>
                  <div className={classes.ProfileInput}>
                    <span>Phone</span>
                    <InputText
                      placeholder="081*****"
                      value={values?.Phone}
                      onChange={handleChange("Phone")}
                      type="number"
                    />
                  </div>
                  <div className={classes.ProfileInput}>
                    <span>Client</span>
                    <FormControl
                      fullWidth
                      size="small"
                      style={{ width: "100%" }}
                    >
                      <Select
                        className={classes.selectBorder}
                        value={selectedAddClient || ""}
                        displayEmpty
                        onChange={(e) => setSelectedAddClient(e.target.value)}
                        sx={{

                        }}
                      >
                        {/* <MenuItem value="">Select Position</MenuItem> */}
                        {getClientName.sort().map((option, el) => (
                          <MenuItem
                            key={el}
                            onClick={() => setSelectedAddClient(option)}
                            value={option || ""}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </TabPanel>
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.Button}>
            <button onClick={handleClose}>Cancel</button>
            <button>Save</button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
