/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles, StylesContext } from "@mui/styles";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import ImageOffice from "../../../../../assets/images/Office.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Checkbox, DialogActions } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { UseGetAllLeader } from "../../../utils/hooks";
// moment.locale("en-gb");

const useStyles = makeStyles((theme) => ({
  ItemModalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& > span": {
      color: "#333333",
      fontSize: 16,
      fontWeight: 600,
    },
  },
  Content: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  Searchbar: {
    width: "100%",
  },
  FormInput: {
    position: "relative",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",

    "& > input": {
      border: "1.5px solid #d3c9c9",
      background: "#fbfbfb",
      height: 40,
      width: "100%",
      paddingLeft: 10,
      paddingRight: 40,
      borderRadius: 8,
      color: "#455560",
    },
    "& > i": {
      fontSize: 18,
      position: "absolute",
      right: 20,
    },
  },
  WrapperLists: {
    // display: "flex",
    // alignItems: 'center',
    // justifyContent: 'space-between'
    overflow: "auto",
  },
  ListLeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ECE6E6",

    "& > span": {
      fontWeight: 400,
      color: "#000000",
      fontSize: 14,
    },
  },
  Button: {
    display: "flex",
    alignSelf: "flex-end",
    gap: 10,
    "& > button:nth-of-type(1)": {
      outline: "none",
      background: "#FFFFFF",
      border: "1px solid #333333",
      borderRadius: 5,
      color: "#333333",
      width: 95,
      height: 34,
      cursor: "pointer",
    },
    "& > button:nth-of-type(2)": {
      outline: "none",
      background: "#1571DE",
      borderRadius: 5,
      color: "#FFFFFF",
      width: 81,
      height: 34,
      cursor: "pointer",
    },
  },
}));

export default function ModalAssignLeaderExternal({
  modalAssignLeader,
  setModalAssignLeader,
  data,
}) {
  const getLeader = UseGetAllLeader();
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const theme = useTheme();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [values, setValues] = useState({});
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    setModalAssignLeader(false);
  };

  // const handleChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };

  //   const onHandleSubmit = () => {
  //     const data = {
  //       id: client._id,
  //       ...values,
  //     };
  //   };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Boolean(modalAssignLeader)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className={classes.ItemModalTop}>
            <span>Select Leader</span>
          </div>
        </DialogTitle>

        <DialogContent>
          <div className={classes.Content}>
            <div className={classes.Searchbar}>
              <form className={classes.FormInput}>
                <input
                  type="search"
                  // value={value}
                  // onChange={onChange}
                  placeholder="Search"
                />
                <i className="bx bx-search" />
              </form>
            </div>
            <div className={classes.WrapperLists}>
              {getLeader.map((el, idx) => (
                <div className={classes.ListLeader} key={idx}>
                  <span>{el?.Name}</span>
                  <Checkbox
                    // checked={checked}
                    onChange={handleChange}
                    icon={
                      <RadioButtonUncheckedIcon sx={{ color: "#000000" }} />
                    }
                    checkedIcon={<CheckCircle sx={{ color: "#1571DE" }} />}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                      },
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.Button}>
            <button onClick={handleClose}>Cancel</button>
            <button>Save</button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
