import React from "react";
import { Button } from "@mui/material";
import { useHistory } from "react-router";
import success_added from "../../../../assets/images/success-added.png";

export default function ClientModalSuccess({
  modalSuccess,
  setModalSuccess,
  pathName,
  client,
}) {
  const history = useHistory();
  const handlePushPage = () => {
    setModalSuccess(false);
    // history.push({ pathname: pathName, state: client });
    history.push(pathName);

  };
  return (
    <div className="modalBackground-modal-tabs-delete-client">
      <div className="modalContainer-modal-tabs-delete-client">
        <div className="titleCloseBtn-ePay">
          <div className="items__fill">dummy</div>
          <div className="text__main-ePay">
            {/* <span>Delete Client</span> */}
          </div>
          <div className="item__button-right">
            <button
              onClick={() => {
                setModalSuccess(false);
              }}
            >
              {/* <CloseIcon style={{ fontSize: 30 }} /> */}
            </button>
          </div>
        </div>
        <div>{/* <hr style={{ width: "100%", color: "#ECE6E6" }} /> */}</div>
        <div className="body-attendance-modal">
          <div className="img-item-main">
            <img src={success_added} alt="" />
          </div>
          <div className="text-main-modal-tabs-settings">
            <span>Success Added</span>
          </div>
          <div className="button-wrapper-modal-tabs-settings">
            <div className="button-delete-modal-tabs-settings">
              <Button
                style={{ backgroundColor: "#1571de" }}
                onClick={handlePushPage}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
