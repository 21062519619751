import React, { useEffect, useMemo } from "react";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppEditEmployeeMenuV2 } from "../../../../components/SuperAdmin/_employee/";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEmployeeById } from "../../../../redux/actions/SuperAdmin/employeesActions";
// import { UseGetAllLeader, UseGetAllPosition, UseGetAllRegion } from "../../../../components/utils/hooks";
// import UseGetWorkTime from "../../../../components/utils/hooks/useGetWorkTime";

export default function EditEmployeeSA() {
  // const getPosition = UseGetAllPosition();
  // const getRegional = UseGetAllRegion();
  // const getLevel = UseGetAllLevel();
  // const getLeader = UseGetAllLeader();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const token = localStorage.getItem('accessToken')
  const employeeInfo = location?.state?.data;
  const { employeeById } = useSelector((state) => state.employees);
  // const employeeInfo = location?.state?.data;
  // const getLevel= ['STAFF', "LEADER"]

  const dataEmployee = useMemo(() => {
    // return location?.state?.user
    return employeeById[0]?.user
  },[employeeById])

  useEffect(() => {
    dispatch(fetchAllEmployeeById(token, employeeInfo?.employeeId, `clientCode=${employeeInfo?.clientCode}` ))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // console.log(location.state?.data, 'cek location data state');
  // useEffect(() => {
  //   dispatch(fetchAllEmployeeById(token))
  // },[])

  if (!employeeInfo?.employeeId) {
    return null;
  }

  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Employee Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/employee")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Employee</span>
            </Button>
            <Button
              onClick={() => history.go(-1)}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>{ dataEmployee?.name }</span>
            </Button>
            <span style={{ fontSize: 20,color: "#255bfc" }}>Edit Employee</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppEditEmployeeMenuV2 
            data={ dataEmployee } 
            token={ token } 
            />
            {/* <AppEditEmployeeMenu employeeData={employeeInfo} position={ getPosition } regional={ getRegional } level={ getLevel } leader={ getLeader } /> */}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
