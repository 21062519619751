/* eslint-disable no-unused-vars */
import React from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { AttendanceExternal } from "../../../components/External/Attendance";
import { UseGroupName } from '../../../components/utils/hooks/index';
import UseGetAllPositionExternal from "../../../components/utils/hooks/useGetAllPositionExternal";

const Attendance = () => {

  const history = useHistory();
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Attedance</title>
      </Helmet>
      <div className="wrapperPageStore">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#000000", fontSize: 40 }}
        >
          Attendance
        </h2>
      </div>
      <Grid>
        <Grid item xs={12} md={12} lg={12}>
          <AttendanceExternal />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Attendance;
