/* eslint-disable no-unused-vars*/

import AddIcon from "@mui/icons-material/Add";
import {
  Button, Card, Popover, Table,
  TableBody, TableCell, TableContainer, TableHead,
  TableRow,
  TableSortLabel, Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { UseCompanyName } from "../../../utils/hooks";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import CustomTablePagination from "../../../_pagination/CustomTablePagination";
import Scrollbar from "../../_user/TableUsers/Scrollbar";
import Styles from "./InductionTable.module.css";

// const data = [
//   {
//     company: "PT. PERMATA INDO SEJAHTERA",
//     title: "Dokumen Perkenalan Permata lorem ipsum dolor sit amet",
//     createdAt: "21 Juni 2021",
//     type: "Video",
//     isPublish: false,
//     status: "Draft",
//   },
//   {
//     company: "PT. PERMATA INDO SEJAHTERA",
//     title: "Dokumen Perkenalan Permata",
//     createdAt: "21 Juni 2021",
//     type: "Video",
//     isPublish: true,
//     status: "Published",
//   },
//   {
//     company: "PT. PERMATA INDO SEJAHTERA",
//     title: "Dokumen Perkenalan Permata",
//     createdAt: "21 Juni 2021",
//     type: "Article",
//     isPublish: false,
//     status: "Unpublished",
//   },
// ];

const TABLE_HEAD = [
  { id: "clientCode", label: "Client", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "createdAt", label: "Created Date", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "isPublish", label: "Status", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        {TABLE_HEAD.map((headCell, index) => (
          <TableCell
            key={headCell.id + index}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function InductionTable(props) {
  const { loading, datas } = props;
  const getCompany = UseCompanyName();
  const history = useHistory();
  const [listInduction, setListInduction] = useState("");
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState("");
  const [orderBy, setOrderBy] = useState("Name");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopover, setOpenedPopover] = useState(null);

  let inductionsData = datas
    .sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    })
    .reverse();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleSearch = (e) => {
    setListInduction(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 500);
  }, []);

  const selectedUser = (userId) => {
    let client;
    if (userId !== "ALL" && userId !== "Tes") {
      let temp = getCompany.find((u) => u.code === userId);
      if (temp) {
        client = temp;
      } else {
        client = { name: "Unlisted Client" };
      }
    } else if (userId === "ALL") {
      client = { name: "All Clients" };
    } else {
      client = { name: "Client Tester" };
    }
    return client;
  };

  const selectedUsersNames = (clientCodes) => {
    let clients = clientCodes?.map((clientCode) => {
      let user = selectedUser(clientCode);
      return user?.name;
    });
    return clients;
  };

  const handleClientsPopover = (e, id) => {
    setAnchorEl(e.currentTarget);
    setOpenedPopover(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopover(null);
  };

  const capitalizedClientName = (clientName) => {
    return clientName
      ?.toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .replace("Pt", "PT")
      .replace("Cv", "CV");
  };

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <div className={Styles.Container}>
      <div className={Styles.Wrapper}>
        <div className={Styles.Header}>
          <SearchbarSA
            value={listInduction}
            onChange={handleSearch}
            searchWidth="379px"
            placeholder="Search by title"
          />
          <Button
            startIcon={<AddIcon />}
            onClick={() =>
              history.push({ pathname: `/superadmin/add-induction` })
            }
          >
            Add Induction
          </Button>
        </div>

        {loading ? (
          <div className={Styles.Loading}>
            <div className={Styles.LoadingWidth}>
              <LoadingAnimation />
            </div>
          </div>
        ) : (
          <div className={ Styles.TableWrapper }>
            <Scrollbar>
              <TableContainer className={Styles.TableContainer}>
                <Table className={Styles.Table}>
                  <colgroup>
                    <col style={{ width: "30%%" }} />
                    <col style={{ width: "25%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "15%" }} />
                  </colgroup>
                  <EnhancedTableHead
                    numSelected={datas.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    values={datas}
                    // rowCount={datas}
                    // rowCount={
                    //   inductionsData?.filter(
                    //     (el) =>
                    //       el?.title
                    //         ?.toLowerCase()
                    //         .includes(listInduction.toLowerCase()) ||
                    //       el?.type
                    //         ?.toLowerCase()
                    //         .includes(listInduction.toLowerCase())
                    //   ).length
                    // }
                  />
                  <TableBody>
                    {stableSort(inductionsData, getComparator(order, orderBy))
                      .filter((el) =>
                        el.title
                          ?.toLowerCase()
                          .includes(listInduction.toLowerCase())
                      )
                      .slice(
                        (page - 1) * rowsPerPage,
                        (page - 1) * rowsPerPage + rowsPerPage
                      )
                      .map((each, ind) => {
                        const labelId = `enhanced-table-checkbox-${ind}`;
                        const client = selectedUser(each?.clientCode[0]);
                        const clients = selectedUsersNames(each?.clientCode);
                        return (
                          <TableRow
                            hover
                            key={ind}
                            tabIndex={-1}
                            id={labelId}
                            sx={{
                              "&.MuiTableRow-root:hover": {
                                backgroundColor: "#eef6ff",
                              },
                            }}
                          >
                            <TableCell className={Styles.ClientCell}>
                              <span>{capitalizedClientName(client?.name)}</span>
                              {each?.clientCode?.length > 1 && (
                                <span
                                  onMouseEnter={(e) =>
                                    handleClientsPopover(e, each?._id)
                                  }
                                  onMouseLeave={handleClose}
                                  className={Styles.Plus}
                                >
                                  + {each?.clientCode?.length - 1}
                                </span>
                              )}
                              <Popover
                                id={each?._id}
                                sx={{
                                  pointerEvents: "none",
                                }}
                                open={openedPopover === each?._id}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <div className={Styles.PopoverDiv}>
                                  {clients?.slice(1)?.map((client, index) => (
                                    <span key={index}>
                                      {index === clients?.length - 2 &&
                                        clients?.length !== 2 &&
                                        "& "}
                                      {capitalizedClientName(client)}
                                      {index !== clients?.length - 2
                                        ? ", "
                                        : "."}{" "}
                                    </span>
                                  ))}
                                </div>
                              </Popover>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ textTransform: "capitalize" }}
                            >
                              {each?.title ? each?.title : "-"}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ textTransform: "capitalize" }}
                            >
                              {each?.createdAt
                                ? moment(each?.createdAt).format("D MMMM YYYY")
                                : "-"}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ textTransform: "capitalize" }}
                            >
                              {each?.type ? each?.type : "-"}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ textTransform: "capitalize" }}
                            >
                              {each?.isPublish ? (
                                <span className={Styles.Published}>
                                  Published
                                </span>
                              ) : (
                                <span className={Styles.Draft}>Draft</span>
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Link
                                to={{
                                  pathname: each.isPublish
                                    ? `/superadmin/detail-induction/${each?._id}`
                                    : `/superadmin/edit-induction/${each?._id}`,
                                  state: { data: each },
                                }}
                              >
                                <Typography sx={{ color: "#1571DE" }}>
                                  Lihat Detail
                                </Typography>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <CustomTablePagination
              limit={rowsPerPage}
              setLimit={setRowsPerPage}
              page={page}
              setPage={setPage}
              totalPage={Math.ceil(
                datas.filter((el) =>
                  el.type?.toLowerCase().includes(listInduction.toLowerCase())
                ).length / rowsPerPage
              )}
            />
            {/* <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={inductionsData.filter((el) =>
                  el.title.toLowerCase().includes(listInduction.toLowerCase())
                ).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
          </div>
        )}
      </div>
    </div>
  );
}
