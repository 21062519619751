/* eslint-disable no-unused-vars */
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router";
import "./AccountTabs.css";
import InputText from "../../../utils/Input/Input";

const BoxInput = styled(OutlinedInput)(({ theme }) => ({
  width: 402,
  height: 40,
  borderRadius: 8,
  marginTop: 10,
}));
export default function AccountTabs() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const history = useHistory();
  const handleCancel = () => {
    history.push("/settings");
  };
  // const handleChange = (e) => {
  //   setPassword(e.target.value);
  // };
  const handleClickShowPassword = () => {
    setShowPassword({
      showPassword: !showPassword.showPassword,
    });
  };
 
  // const handleMouseDownPassword = (e) => {
  //   e.preventDefault();
  // };
  // console.log(showPassword.showPassword);
  return (
    <div className="container-AT">
      <div className="form-group-TA">
        <div className="form-control-TA">
          <label>Username</label>
          <InputText
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="emilfitrianshah"
          />
        </div>
        <div className="form-control-TA">
          <label>Email</label>
          <InputText
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="emilfitrianshah@gmail.com"
          />
        </div>
      </div>
      <form className="form-group-change-password">
        <div className="title-change-password-TA">
          <span>Change Password</span>
        </div>
        <div className="form-group-TA-change-password">
          <div className="form-control-TA">
            <label>Old Password</label>
            <BoxInput
              size="small"
              type={showPassword.showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              // className="form-control-nested"
              // placeholder="1 1 1 1 1 1 1"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <div className="form-control-TA">
            <label>New Password</label>
            <BoxInput
              size="small"
              type={showPassword.showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              // className="form-control-nested"
              // placeholder="1 1 1 1 1 1 1"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
        </div>
        <div className="button-helper-TA">
          <Button
            style={{
              textTransform: "none",
              width: 161,
              height: 40,
              backgroundColor: "#1571DE",
              borderRadius: 4,
              color: "white",
            }}
            type="submit"
          >
            Change password
          </Button>
          {/* <Button
            style={{
              textTransform: "none",
              width: 75,
              height: 40,
              border: "1px solid #1571DE",
              borderRadius: 4,
              color: "#1571DE",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button> */}
        </div>
      </form>
    </div>
  );
}
