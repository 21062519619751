/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./UserTables.css";
import { filter } from "lodash";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
  styled,
  TextField,
  Autocomplete,
} from "@mui/material";
//

import USERLIST from "./_mocks/user";
import UserListHead from "./UserListHead";
import Scrollbar from "./Scrollbar";
import ModalSettingUser from "../modalAddUserSA/modal-setting-user/ModalSettingUser";
import ModalDeleteUser from "../modalAddUserSA/modal-delete-user/ModalDeleteUser";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import UnderMaintenance from "../../../_underMaintenancePage";
import ModalAddUserSA from "../modalAddUserSA/ModalAddUserSA/ModalAddUserSA";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const TABLE_HEAD = [
  { id: "name", label: "Employee Name", alignRight: false },
  { id: "client", label: "Client", alignRight: false },
  { id: "username", label: "Username", alignRight: false },
  { id: "password", label: "Password", alignRight: false },
  { id: "statusType", label: "Role", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User({ users, loading }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState("");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalDeleteUser, setModalDeleteUser] = useState(false);
  const [modalSettingUser, setModalSettingUser] = useState(false);
  const [selectedAddUserModalComp, setSelectedAdduserModalComp] = useState("");
  const [statusAdmin, setStatusAdmin] = useState();
  const [isActive, setIsActive] = useState(false);
  const options = ["Admin", "Viewer"];
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );

  return (
    <div className="container__user-detail-menu">
      <div className="wrapper-user-tables-menu-top">
        <div className="user__detail-left">
          <SearchbarSA />
        </div>
        <div className="data-user-menu-right">
          <div className="">
            <Autocomplete
              disablePortal
              options={options}
              value={selected}
              inputValue={selected}
              onInputChange={(e, value) => setSelected(value)}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  size="small"
                  sx={{ width: 200 }}
                  placeholder="Role"
                />
              )}
            />
          </div>
        </div>
        <div className="user-tables-menu-download">
          {/* <Link to="/superadmin/add-user" className="user-tables-menu-download"> */}
            <Button
              startIcon={<AddIcon style={{ color: "white" }} />}
              style={{
                backgroundColor: "#1571DE",
              }}
            >
              <span style={{ fontSize: 14, color: "white" }} onClick={() => setOpenModal(true)}>Add User</span>
            </Button>
          {/* </Link> */}
        </div>
      </div>
      <div className="wrapper-table-user-detail-menu">
        <Scrollbar>
          <TableContainer sx={{ minWidth: 600 }}>
            <Table
              sx={{
                width: "100%",
                maxHeight: "100%",
                backgroundColor: "#fafafa",
                borderRadius: 4,
              }}
            >
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={USERLIST.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      id,
                      name,
                      role,
                      status,
                      company,
                      avatarUrl,
                      username,
                      password,
                      isVerified,
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1}>
                        <TableCell></TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={name} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{company}</TableCell>
                        <TableCell align="left">{username}</TableCell>
                        <TableCell align="left">{password}</TableCell>
                        <TableCell align="left">
                          <Typography
                            className="status__style"
                            style={{
                              backgroundColor:
                                (status === "Eksternal" && "#498E0F") ||
                                (status === "Internal" && "#1B45D9"),
                            }}
                          >
                            {status}
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          <Button onClick={() => setOpenEditModal(true)}>
                            <SettingsIcon style={{ color: "#757575" }} />
                          </Button>
                          {/* {modalSettingUser && (
                            <ModalSettingUser
                              modalSettingUser={modalSettingUser}
                              setModalSettingUser={setModalSettingUser}
                              selectedAddUserModalComp={
                                selectedAddUserModalComp
                              }
                              setSelectedAdduserModalComp={
                                setSelectedAdduserModalComp
                              }
                            />
                          )} */}
                          <Button
                            // onClick={() => setModalDeleteClient(true)}
                            onClick={() => setModalDeleteUser(true)}
                          >
                            <DeleteOutlineIcon style={{ color: "#D1421A" }} />
                          </Button>
                          {/* {modalDeleteUser && (
                            <ModalDeleteUser
                              modalDeleteUser={modalDeleteUser}
                              setModalDeleteUser={setModalDeleteUser}
                              onClick={() => removeClient(row._id)}
                            />
                          )} */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={USERLIST.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      {
        openModal && (
          <ModalAddUserSA openModal={openModal} setOpenModal={setOpenModal} description="Add User"/>
        )
      }
      {
        openEditModal && (
          <ModalAddUserSA openModal={openEditModal} setOpenModal={setOpenEditModal} description="Edit User"/>
        )
      }
    </div>
  );
}
