/* eslint-disable no-unused-vars*/

import { Autocomplete, Button, FormControl, TextField } from "@mui/material";
import { styled } from "@mui/styles";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import fetchClientCode from "../../../../async/client/fetchClientCode";
import Styles from "./EditInduction.module.css";
import VIDEO_TYPE from "../../../../assets/images/video-player-1.png";
import ARTICLE_TYPE from "../../../../assets/images/file-type.png";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import updateInduction from "../../../../async/induction/updateInduction";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonChecked from "@mui/icons-material/RadioButtonChecked";
import COMPANY_ICON from "../../../../assets/images/client-blue-induction.png";
import Cancel from "@mui/icons-material/Cancel";
import { useDropzone } from "react-dropzone";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import PublishIcon from "@mui/icons-material/Publish";
import ModalSuccessInduction from "../ModalSuccessInduction/ModalSuccessInduction";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      border: "1px solid #e0e0e0",
    },
    "& input::placeholder": {
      fontSize: "14px",
      fontWeight: 400,
      color: "black",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1.5px solid #d3c9c9",
    borderColor: "#d3c9c9",
    borderRadius: 8,
    //   width: "100%",
  },
});

const createState = (text) => {
  return EditorState.createWithContent(ContentState?.createFromText(text));
};

// const getHtml = (editorState) =>
//   draftToHtml(convertToRaw(editorState.getCurrentContent()));

export default function EditInduction(props) {
  const { data, id } = props;
  const location = useLocation();
  const token = localStorage.getItem("accessToken");
  const history = useHistory();
  const dataInfo = location.state.data;
  // const [selected, setSelected] = useState([]);
  // const [selectedCode, setSelectedCode] = useState([]);
  const [dataClientCode, setDataClientCode] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [active, setActive] = useState("");
  const [editorState, setEditorState] = useState({
    valueEditor: EditorState.createEmpty(),
  });
  //   const [editorState, setEditorState] = useState({
  //     valueEditor: null,
  //   });
  const [value, setValue] = useState({
    title: dataInfo.title,
    content: dataInfo.content,
    clientCode: dataInfo.clientCode,
    image: null,
    imageVideo: null,
    isPublish: dataInfo.isPublish,
    url: dataInfo.url,
  });

  const inputFileRef = useRef();
  const [files, setFiles] = useState([]);
  const [preview, setPreview] = useState(
    dataInfo?.thumbnail?.uri ? dataInfo?.thumbnail?.uri : null
  );
  const [previewName, setPreviewName] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);
  const inputFileRefImageVideo = useRef();
  const [previewImageVideo, setPreviewImageVideo] = useState(
    dataInfo?.thumbnail?.uri ? dataInfo?.thumbnail?.uri : null
  );
  const [previewNameImageVideo, setPreviewNameImageVideo] = useState("");

  const fixedClients = useMemo(() => {
    let pre = [{ codes: "ALL", name: "ALL" }];
    return pre.concat(dataClientCode);
  }, [dataClientCode]);

  const preClients = useMemo(() => {
    const temp = fixedClients.filter((el) => {
      return dataInfo?.clientCode.some((f) => {
        return f === el.codes;
      });
    });
    return temp;
  }, [fixedClients, dataInfo]);

  useEffect(() => {
    setSelectedClients(preClients);
  }, [preClients]);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCode(response);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // setValue(data);
    setActive(dataInfo?.type);
    // setSelectedClients(data?.clientCode);

    setEditorState((prev) => ({
      ...prev,
      valueEditor: EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(dataInfo?.content))
      ),
    }));

    // if (dataInfo?.content === null) {
    //   setEditorState((prev) => ({
    //     ...prev,
    //     valueEditor: EditorState.createEmpty(),
    //   }));
    // } else {
    //   setEditorState((prev) => ({
    //     ...prev,
    //     valueEditor: createState(dataInfo?.content),
    //   }));
    // }
  }, [dataInfo]);

  const handleSelectedClient = (e, value) => {
    e.preventDefault();

    const isExist = selectedClients.find((item) => item?.name === value?.name);
    const doesContainAll = selectedClients.some(
      (item) => item?.codes === "ALL"
    );
    let isAll = Boolean(value?.codes === "ALL" && value?.name === "ALL");
    const isEmpty = selectedClients.length === 0;

    if (!isExist && !isAll && !doesContainAll) {
      let temp = selectedClients.concat(value);
      setSelectedClients(temp);
    } else if (isExist) {
      const x = selectedClients.filter((item) => item !== value);
      setSelectedClients(x);
    } else if (isEmpty && isAll) {
      let temp = selectedClients.concat(value);
      setSelectedClients(temp);
    } else if (!isExist && doesContainAll) {
      setSelectedClients(selectedClients);
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      if (active === "Video") {
        setValue((prev) => ({
          ...prev,
          // eslint-disable-next-line no-useless-computed-key
          ["imageVideo"]: acceptedFiles,
        }));

        setPreviewImageVideo(URL.createObjectURL(acceptedFiles[0]));
        setPreviewNameImageVideo(acceptedFiles[0].name);
      }

      if (active === "Article") {
        setValue((prev) => ({
          ...prev,
          // eslint-disable-next-line no-useless-computed-key
          ["image"]: acceptedFiles,
        }));

        setPreview(URL.createObjectURL(acceptedFiles[0]));
        setPreviewName(acceptedFiles[0].name);
      }
    },
    [active]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
    multiple: false,
  });

  const handleChangeImageVideo = (e) => {
    setValue((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "file" ? e.target.files : e.target.value,
    }));
    if (e.target.files && e.target.files[0]) {
      let img = URL.createObjectURL(e.target.files[0]);
      setPreviewImageVideo(img);
      setPreviewNameImageVideo(e.target.files[0].name);
    }
  };

  const handleChangeImage = (e) => {
    setValue((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "file" ? e.target.files : e.target.value,
    }));
    if (e.target.files && e.target.files[0]) {
      let img = URL.createObjectURL(e.target.files[0]);
      setPreview(img);
      setPreviewName(e.target.files[0].name);
    }
  };

  // const onChangeValue = (e) => {
  //   setValue((prev) => ({
  //     ...prev,
  //     [e.target.name]: e.target.value,
  //   }));
  // };

  const onChangeEditor = (editorState) => {
    setEditorState((prev) => ({
      ...prev,
      valueEditor: editorState,
    }));
  };

  let refinedData = `${draftToHtml(
    convertToRaw(editorState?.valueEditor?.getCurrentContent())
  )}`;

  const handleDeleteImage = (e) => {
    inputFileRef.current.value = null;
    setPreview(null);
    setPreviewName("");
    setValue((prev) => ({
      ...prev,
      // eslint-disable-next-line no-useless-computed-key
      ["image"]: null,
    }));
  };

  const handleDeleteImageVideo = (e) => {
    inputFileRefImageVideo.current.value = null;
    setPreviewImageVideo(null);
    setPreviewNameImageVideo("");
    setValue((prev) => ({
      ...prev,
      // eslint-disable-next-line no-useless-computed-key
      ["imageVideo"]: null,
    }));
  };

  const handleDeleteClient = (value) => {
    const x = selectedClients.filter((item) => item !== value);
    setSelectedClients(x);
  };

  const handleEditData = (e) => {
    setValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    // if (e.target.type === "file") {
    //   let url = URL.createObjectURL(e.target.files[0]);
    //   setPreview(url);
    //   setPreviewName(e.target.files[0].name);
    // }
  };

  const handleSubmit = async (e) => {
    if (active === "Video") {
      try {
        e.preventDefault();
        const formData = new FormData();
        formData.append("title", value?.title);
        // formData.append("content", "");
        formData.append("url", value?.url);
        formData.append("isPublish", true);
        for (let i = 0; i < selectedClients.length; i++) {
          formData.append("clientCode", selectedClients[i].codes);
        }
        // formData.append('clientCode', JSON.stringify(selectedClients.map((el) => el.codes )));
        formData.append("type", active);

        if (
          value?.imageVideo !== null &&
          value?.imageVideo !== undefined &&
          value?.imageVideo !== ""
        ) {
          formData.set(
            "images",
            value?.imageVideo[0],
            value?.imageVideo[0].name
          );
        }

        for (const pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1], "data");
        }

        await updateInduction(token, id, formData);
        setModalSuccess(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        e.preventDefault();
        const formData = new FormData();
        formData.append("title", value?.title);
        formData.append("content", refinedData);
        formData.append("url", "");
        formData.append("isPublish", true);
        for (let i = 0; i < selectedClients.length; i++) {
          formData.append("clientCode", selectedClients[i].codes);
        }
        // formData.append('clientCode', JSON.stringify(selectedClients.map((el) => el.codes )));
        formData.append("type", active);
        if (
          value?.image !== null &&
          value?.image !== undefined &&
          value?.image !== ""
        ) {
          formData.set("images", value?.image[0], value?.image[0].name);
        }
        // formData.append("images", preview);
        await updateInduction(token, id, formData);
        setModalSuccess(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSaveToDraft = async (e) => {
    if (active === "Video") {
      try {
        e.preventDefault();
        const formData = new FormData();
        formData.append("title", value?.title);
        // formData.append("content", "");
        formData.append("url", value?.url);
        formData.append("isPublish", false);
        for (let i = 0; i < selectedClients.length; i++) {
          formData.append("clientCode", selectedClients[i].codes);
        }
        // formData.append('clientCode', JSON.stringify(selectedClients.map((el) => el.codes )));
        if (
          value?.imageVideo !== null &&
          value?.imageVideo !== undefined &&
          value?.imageVideo !== ""
        ) {
          formData.set(
            "images",
            value?.imageVideo[0],
            value?.imageVideo[0].name
          );
        }
        await updateInduction(token, id, formData);
        setModalSuccess(true);
        for (const pair of formData.entries()) {
          console.log(pair[0] + ", " + pair[1], "data");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        e.preventDefault();
        const formData = new FormData();
        formData.append("title", value?.title);
        formData.append("content", refinedData);
        // formData.append('url', value?.url);
        formData.append("isPublish", false);
        for (let i = 0; i < selectedClients.length; i++) {
          formData.append("clientCode", selectedClients[i].codes);
        }
        // formData.append('clientCode', JSON.stringify(selectedClients.map((el) => el.codes )));
        if (
          value?.image !== null &&
          value?.image !== undefined &&
          value?.image !== ""
        ) {
          formData.set("images", value?.image[0], value?.image[0].name);
        }
        // formData.append("images", preview);
        await updateInduction(token, id, formData);
        setModalSuccess(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const isSelected = (option) => selectedClients?.indexOf(option) !== -1;

  const capitalizedClientName = (clientName) => {
    return clientName
      ?.toLowerCase()
      ?.split(" ")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ")
      ?.replace("Pt", "PT")
      ?.replace("Cv", "CV");
  };

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div className={Styles.Container}>
      <div className={Styles.Content}>
        <div className={Styles.ValueControl}>
          <div className={Styles.Value}>
            <span>Client</span>
            <FormControl size="small" fullWidth>
              <Autocomplete
                required
                disablePortal
                value={null}
                blurOnSelect={true}
                disableCloseOnSelect
                options={fixedClients}
                getOptionLabel={(option) => option?.name}
                sx={{ width: "100%" }}
                onChange={(e, value) =>
                  value === null
                    ? handleSelectedClient(e, null)
                    : handleSelectedClient(e, value)
                }
                renderOption={(props, option) => {
                  const isItemSelected = isSelected(option);
                  return (
                    <li
                      {...props}
                      onClick={(e) => e.preventDefault()}
                      key={option?.codes}
                      style={{
                        borderBottom: "1px solid #c2c2c2",
                        padding: "12px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <span className={Styles.ClientOption}>
                          {capitalizedClientName(option?.name)}
                        </span>
                        <Checkbox
                          onClick={(e) => handleSelectedClient(e, option)}
                          sx={{ margin: 0, padding: 0 }}
                          checked={isItemSelected}
                          icon={
                            <RadioButtonUnchecked sx={{ color: "#c2c2c2" }} />
                          }
                          checkedIcon={
                            <RadioButtonChecked sx={{ color: "#1571DE" }} />
                          }
                        />
                      </div>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    size="small"
                    sx={{ background: "var(--main-bg)", maxWidth: 520 }}
                    placeholder="Select company"
                  />
                )}
              />
            </FormControl>
          </div>

          <div className={Styles.Value}>
            <span>Title</span>
            <input
              type="text"
              name="title"
              value={value?.title}
              onChange={handleEditData}
              placeholder="Type title here"
            />
          </div>
        </div>

        {selectedClients?.length > 0 && (
          <div className={Styles.CompanyControl}>
            {selectedClients?.map((each, idx) => (
              <div className={Styles.CompanyValue} key={idx}>
                <img src={COMPANY_ICON} alt="icon-client" />
                <span>{each?.name}</span>
                <Cancel
                  sx={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() => handleDeleteClient(each)}
                />
              </div>
            ))}
          </div>
        )}

        {/* {selectedClients === "ALL" ? (
          <div className={Styles.CompanyControl}>
            <div className={Styles.CompanyValue}>
              <img src={COMPANY_ICON} alt="icon-client" />
              <span>{selectedClients}</span>
              <Cancel
                sx={{ fontSize: 14, cursor: "pointer" }}
                onClick={() => handleDeleteClient("ALL")}
              />
            </div>
          </div>
        ) : (
          <div className={Styles.CompanyControl}>
            {selectedClients?.map((each, idx) => (
              <div className={Styles.CompanyValue} key={idx}>
                <img src={COMPANY_ICON} alt="icon-client" />
                <span>{each?.name}</span>
                <Cancel
                  sx={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() => handleDeleteClient(each)}
                />
              </div>
            ))}
          </div>
        )} */}

        {/* {active === "Article" && (
          <div className={Styles.ValueControlContent}>
            <div className={Styles.Value}>
              <span>Content</span>
              <textarea
                type="text"
                name="content"
                onChange={onChangeValue}
                placeholder="Description of content"
              />
            </div>
          </div>
        )} */}

        {/* {selectedClients?.length > 0 && (
          <div className={Styles.CompanyControl}>
            {selectedClients.map((each, idx) => (
              <div className={Styles.CompanyValue} key={idx}>
                <img src={COMPANY_ICON} alt="icon-client" />
                <span>{each?.name}</span>
                <Cancel
                  sx={{ fontSize: 14, cursor: "pointer" }}
                  onClick={() => handleDeleteClient(each)}
                />
              </div>
            ))}
          </div>
        )} */}

        {/* <div className={Styles.ValueControlContent}>
          <div className={Styles.Value}>
            <span>Content</span>
            <textarea
              type="text"
              name="content"
              onChange={ onChangeValue }
              placeholder="Description of content"
            />
          </div>
        </div> */}

        <div className={Styles.ValueInductionControl}>
          <div className={Styles.Value}>
            <span>Select Induction Type</span>
          </div>

          <div className={Styles.Selection}>
            <div
              onClick={() => setActive("Video")}
              className={`${active === "Video" && data?.type === "Video"
                  ? Styles.Active
                  : Styles.Type
                }`}
            >
              <img src={VIDEO_TYPE} alt="video-type" />
              <span>Create Induction by Movie</span>
            </div>

            <div
              onClick={() => setActive("Article")}
              className={`${active === "Article" ? Styles.Active : Styles.Type
                }`}
            >
              <img src={ARTICLE_TYPE} alt="article-type" />
              <span>Create Induction by Article</span>
            </div>
          </div>
        </div>

        {active === "Article" && (
          <div className={Styles.ContainerUploadImage}>
            <div {...getRootProps()} className={Styles.WrapperUploadImage}>
              <input
                {...getInputProps()}
                type="file"
                ref={inputFileRef}
                name="image"
                onChange={handleChangeImage}
                hidden
              />
              {preview ? (
                <div
                  style={{
                    width: "100%",
                    margin: 0,
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: 30,
                  }}
                >
                  <img
                    src={preview}
                    style={{
                      width: "245px",
                      height: "158px",
                      objectFit: "cover",
                      borderRadius: 8,
                    }}
                    alt={preview}
                    onClick={() => inputFileRef.current.click()}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                        fontSize: "14px",
                      }}
                    >
                      <span>{previewName}</span>
                      <Button
                        type="button"
                        style={{
                          border: "1px solid #1571DE",
                          color: "#1571DE",
                          width: 120,
                          height: 40,
                          padding: 10,
                          borderRadius: 8,
                          fontSize: "14px",
                        }}
                        onClick={() => inputFileRef.current.click()}
                      >
                        Change File
                      </Button>
                    </span>
                    <img
                      src={DELETE_ICON}
                      onClick={handleDeleteImage}
                      alt="delete"
                      style={{
                        width: "18px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={Styles.BoxInputImage}>
                  <span>Drag & Drop File Here</span>
                  <div className={Styles.BoxInputFile}>
                    <span>File must JPG or PNG</span>
                  </div>
                  <div className={Styles.BoxInputOr}>
                    <span> OR </span>
                  </div>
                  <div className={Styles.ContainerInputImage}>{ }</div>
                  <div className={Styles.BoxInputButton}>
                    <Button onClick={() => inputFileRef.current.click()}>
                      <span>Browse File</span>
                      <PublishIcon />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {active === "Video" && (
          <div className={Styles.ContainerUploadImage}>
            <div {...getRootProps()} className={Styles.WrapperUploadImage}>
              <input
                {...getInputProps()}
                type="file"
                ref={inputFileRefImageVideo}
                name="imageVideo"
                onChange={handleChangeImageVideo}
                hidden
              />
              {previewImageVideo ? (
                <div
                  style={{
                    width: "100%",
                    margin: 0,
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: 30,
                  }}
                >
                  <img
                    src={previewImageVideo}
                    style={{
                      width: "245px",
                      height: "158px",
                      objectFit: "cover",
                      borderRadius: 8,
                    }}
                    alt={preview}
                    onClick={() => inputFileRefImageVideo.current.click()}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                        fontSize: "14px",
                      }}
                    >
                      <span>{previewNameImageVideo}</span>
                      <Button
                        type="button"
                        style={{
                          border: "1px solid #1571DE",
                          color: "#1571DE",
                          width: 120,
                          height: 40,
                          padding: 10,
                          borderRadius: 8,
                          fontSize: "14px",
                        }}
                        onClick={() => inputFileRefImageVideo.current.click()}
                      >
                        Change File
                      </Button>
                    </span>
                    <img
                      src={DELETE_ICON}
                      onClick={handleDeleteImageVideo}
                      alt="delete"
                      style={{
                        width: "18px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={Styles.BoxInputImage}>
                  <span>Drag & Drop File Here</span>
                  <div className={Styles.BoxInputFile}>
                    <span>File must JPG or PNG</span>
                  </div>
                  <div className={Styles.BoxInputOr}>
                    <span> OR </span>
                  </div>
                  <div className={Styles.ContainerInputImage}>{ }</div>
                  <div className={Styles.BoxInputButton}>
                    <Button
                      onClick={() => inputFileRefImageVideo.current.click()}
                    >
                      <span>Browse File</span>
                      <PublishIcon />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {active === "Video" ? (
          <div className={Styles.GroupControl}>
            <span>Link URL</span>
            <input
              type="text"
              value={value?.url}
              name="url"
              onChange={handleEditData}
              required
            />
          </div>
        ) : (
          <div className={Styles.GroupDraft}>
            <Editor
              editorState={editorState.valueEditor}
              defaultEditorState={editorState?.valueEditor}
              wrapperClassName="demo-wrapper"
              onEditorStateChange={onChangeEditor}
              toolbarClassName="toolbarClassName"
              editorClassName={Styles.EditorClassName}
            />
            <textarea
              disabled
              value={draftToHtml(
                convertToRaw(editorState?.valueEditor?.getCurrentContent())
              )}
              hidden
            />
          </div>
        )}

        {/* {(active === "Article" || data?.type === "Article") && (
          <div className={Styles.GroupDraft}>
            <Editor
              defaultEditorState={editorState.valueEditor}
              wrapperClassName="demo-wrapper"
              onEditorStateChange={onChangeEditor}
              toolbarClassName="toolbarClassName"
              editorClassName={Styles.EditorClassName}
            />
            <textarea
              disabled
              value={draftToHtml(
                convertToRaw(editorState?.valueEditor?.getCurrentContent())
              )}
              hidden
            />
          </div>
        )} */}

        {data?.isPublish === true ? (
          <div className={Styles.ActionButton}>
            <Button onClick={handleSaveToDraft}>Save to draft</Button>
            {/* <Button onClick={handleSubmit}>Publish</Button> */}
            <Button onClick={() => history.push(`/superadmin/induction`)}>
              Cancel
            </Button>
          </div>
        ) : (
          <div className={Styles.ActionButton}>
            <Button onClick={handleSubmit}>Publish</Button>
            <Button onClick={() => history.push(`/superadmin/induction`)}>
              Cancel
            </Button>
          </div>
        )}

        {/* <div className={Styles.ActionButton}>
          <Button onClick={handleSaveToDraft}>Save to draft</Button>
          <Button onClick={handleSubmit}>Publish</Button>
          <Button onClick={() => history.push(`/superadmin/induction`)}>
            Cancel
          </Button>
        </div> */}
      </div>

      {modalSuccess && (
        <ModalSuccessInduction
          history={history}
          onOpen={setModalSuccess}
          title="Success Published"
          text="You have send one information to the client"
        />
      )}
    </div>
  );
}
