import axios from "axios";
import * as types from '../../type';
import { setSnackbar } from "./snackbarActions";
import {API} from "../../api";

// FETCH ALL ATTENDANCE
export const fetchAllAttendances = (token) => {
  return (dispatch) => {
    dispatch({type: types.FETCH_ALL_ATTENDANCES_BEGIN })
    axios
      .get(`${API}/attendance-plan`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_ATTENDANCES_SUCCESS,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        dispatch({type: types.FETCH_ALL_ATTENDANCES_ERROR, payload: err.response })
        console.log(err);
      });
  };
};

// FETCH ATTENDANCE BY ID
export const fetchAttendanceById = (token, id) => {
  return (dispatch) => {
    axios
      .get(`${API}/attendance-plan/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_SCHEMA_ATTENDANCE_BY_ID,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// UDPATE ATTENDANCE
export const updateAttendanceById = (token, id, body) => {
  return (dispatch) => {
    axios
      .patch(`${API}/attendance-plan/${id}`, body, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.UPDATE_ATTENDANCES,
          payload: res.data.response,
        });
      })
      // .then(() => {
      //   dispatch(fetchAllAttendances(token));
      // })
      .catch((err) => {
        console.log(err);
      });
  };
};

// ADD ATTENDANCE
export const createAttendances = (token, attendances) => {
  return (dispatch) => {
    return axios
      .post(`${API}/attendance-plan`, attendances, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.CREATE_ATTENDANCES,
          payload: res.data.response,
        });
        return Promise.resolve(); // kondisi jika berhasil
      })
      .catch((err) => {
        // console.log(err.response, "cek error");
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.message,
          })
        );
        //
        return Promise.reject(err);
      });
  };
};

// DELETE ATTENDANCES
export const deleteAttendances = (token, id) => {
  return (dispatch) => {
    axios
      .delete(`${API}/attendance-plan/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.DELETE_ATTENDANCES,
          payload: res.data.response,
        });
      })
      // .then(() => {
      //   dispatch(
      //     setSnackbar({
      //       snackbarOpen: true,
      //       snackbarType: "success",
      //       snackbarMessage: "Attendance deleted successfully",
      //     })
      //   );
      // })
      .then(() => {
        dispatch(fetchAllAttendances(token));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
