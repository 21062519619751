/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import ReactModal from "react-modal";
import "./AddUserSAMenu.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, Button } from "@mui/material";
import logo_user from "../../../../assets/images/logo-add-user.png";
import ModalAddUser from "../modalAddUserSA/ModalAddUser";
import ModalSuccessAdded from "../modalAddUserSA/modal-success-added/ModalSuccessAdded";

let EMPLOYEE = [],
  Name = [
    "Jaka Saputra",
    "Jack Empire",
    "Layla Clapton",
    "Layla Clapton",
    "Putri Tsunade",
  ],
  POSITION = [
    "Product Designer",
    "Product Designer",
    "Front end Engineer",
    "Back End Engineer",
    "Product Manager",
  ],
  CLIENT = [
    "PT OPPO Indonesia",
    "PT Intel Indonesia",
    "PT Indosat Oredoo",
    "Hyundai Motor",
    "Kia Motor",
  ];

for (let i = 0; i < 5; i++) {
  EMPLOYEE[i] = {
    name: Name[i],
    position: POSITION[i],
    client: CLIENT[i],
  };
}
const CustomModal = (props) => {
  const { isOpen } = props;
  // If we only put the modal in the render tree when it's open, multiple modals
  // will open in the expected order
  return isOpen ? <ReactModal {...props} /> : null;
};

export default function AddUserSAMenu() {
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedAddUserModalComp, setSelectedAdduserModalComp] = useState("");
  const [modalAddedUser, setModalAddedUser] = useState(false);
  const [useCustomModal, setUseCustomModal] = React.useState(false);
  const [showModal1, setShowModal1] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);

  const ModalComponent = useCustomModal ? CustomModal : ReactModal;

  return (
    <div className="container-add-user-menu">
      <div className="wrapper-search-menu">
        <div className="add-user__menu-left" style={{ marginRight: "auto" }}>
          <input type="text" placeholder="Search..." />
          <i className="bx bx-search"></i>
        </div>
      </div>
      <div className="container__table-add-user">
        <div className="wrapper__table-add-user">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Employee Name</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Client</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {EMPLOYEE.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={0} style={{ paddingRight: 10 }}>
                        <img src={logo_user} alt={row.name} />
                      </Grid>
                      <Grid item xs={0} style={{ paddingTop: 10 }}>
                        {row.name}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell> {row.position}</TableCell>
                  <TableCell>{row.client}</TableCell>
                  <TableCell align="right">
                    <Button
                      className="button-add-user-menu"
                      style={{
                        textTransform: "none",
                        width: 53,
                        height: 36,
                        backgroundColor: "#1571de",
                        color: "white",
                      }}
                      onClick={() => setOpenPopup(true)}
                      // onClick={() => setShowModal2(true)}
                    >
                      <span style={{ fontSize: 14 }}>Set</span>
                    </Button>
                    {openPopup && (
                      <ModalAddUser
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                        selectedAddUserModalComp={selectedAddUserModalComp}
                        setSelectedAdduserModalComp={
                          setSelectedAdduserModalComp
                        }
                        setSucceeded={setModalAddedUser}
                      />
                    )}
                    {modalAddedUser && (
                      <ModalSuccessAdded
                        modalAddedUser={modalAddedUser}
                        setModalAddedUser={setModalAddedUser}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      {/* <ModalAddUser openPopup={openPopup} setOpenPopup={setOpenPopup} /> */}
    </div>
  );
}
