import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router";
import "./DetailsAccountGroup.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumb from "../../../utils/Breadcrumbs";
import BuildingsImage from "../../../../assets/images/Buildings-Image-Table-Blue.png";
import { styled } from "@mui/styles";
import { makeStyles } from "@mui/styles";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 5,
    },
    "& .MuiInputBase-input-MuiOutlinedInput-input": {
      padding: 0,
    },
    "& input::placeholder": {
      color: "#A4A4A4",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "18.2px",
      opacity: 1,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 5,
    },
    "& .MuiSelect-select": {
      color: "black",
      fontSize: 13,
      fontWeight: 400,
    },
    // "& input::placeholder": {
    //   color: "#A4A4A4",
    //   fontSize: 13,
    //   fontWeight: 400,
    //   lineHeight: "18.2px",
    //   opacity: 1,
    // },
  },
  adorment: {
    "& .MuiTypography-root ": {
      color: "#A4A4A4",
      fontSize: 15,
      fontWeight: 400,
      lineHeight: "18.2px",
    },
  },
}));

const storeStatusArr = ["Active", "Unactive"];

const DetailsAccountGroup = ({ location }) => {
  const classes = useStyles();
  const history = useHistory();
  const [values, setValues] = useState({});
  const [selectedInput, setSelectedInput] = useState({
    storeStatus: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = () => {
    console.log("submitted");
    history.push('/admin-fmcg/setup/store')
  };
  return (
    <div className="ContainerDetailsAccountGroup">
      <div className="WrapperDetailsAccountGroup">
        <div className="HeaderDetailsAccountGroup">
          <div
            className="ButtonRouteBack"
            onClick={() => history.go(-1)}
            // onClick={() => history.push("/admin-fmcg/store-setup")}
          >
            <IconButton>
              <ArrowBackIcon sx={{ color: "#202020", width: 24, height: 24 }} />
            </IconButton>
          </div>
          <div className="WrapperBreadCrumbs">
            {location === "addStoreAccountGroup" ? (
              <Breadcrumb>
                <Button disabled style={{ textTransform: "none" }}>
                  <span className="BreadCrumbsTextGrey">Store</span>
                </Button>
                <Button disabled style={{ textTransform: "none" }}>
                  <span className="BreadCrumbsTextBlue">
                    Add New Store Account Group
                  </span>
                </Button>
              </Breadcrumb>
            ) : (
              <Breadcrumb>
                <Button disabled style={{ textTransform: "none" }}>
                  <span className="BreadCrumbsTextGrey">Store</span>
                </Button>
                <Button disabled style={{ textTransform: "none" }}>
                  <span className="BreadCrumbsTextBlue">
                    Edit Store Account Group
                  </span>
                </Button>
              </Breadcrumb>
            )}
          </div>
        </div>
        <div className="WrapperContentDetailsAccountGroup">
          <div className="WrapperItemHeaderDetailsAccountGroup">
            <div className="BorderImageDetailsAccountGroup">
              <img src={BuildingsImage} alt="" />
            </div>
            <span>New Store Group</span>
          </div>
          <form className="WrapperFormDetailsAccountGroup" onSubmit={handleSubmit}>
            {location === "addStoreAccountGroup" ? (
              // Add Account Store Group
              <div className="WrapperItemsInputDetailsAccountGroup">
                <div className="ItemsInputDetailsAccountGroup">
                  <div className="ItemInputDetailsAccountGroup">
                    <span>Store Account Group Name</span>
                    <MuiTextField
                      size="small"
                      placeholder="Group Name"
                      value={values.GroupName || ""}
                      onChange={handleChange("GroupName")}
                      type="text"
                      required
                    />
                  </div>
                  <div className="ItemInputDetailsAccountGroup">
                    <span>Status</span>
                    <FormControl fullWidth size="small">
                      <Select
                        className={classes.selectBorder}
                        value={selectedInput.storeStatus || ""}
                        displayEmpty
                        onChange={(e) =>
                          setSelectedInput({
                            ...selectedInput,
                            storeStatus: e.target.value,
                          })
                        }
                        required
                      >
                        <MenuItem value="" disabled>
                          <Typography
                            component="span"
                            style={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#A4A4A4",
                            }}
                          >
                            Store Status
                          </Typography>
                        </MenuItem>
                        {storeStatusArr.sort().map((option, el) => (
                          <MenuItem
                            key={el}
                            onClick={() =>
                              setSelectedInput({
                                ...selectedInput,
                                storeStatus: option,
                              })
                            }
                            value={option || ""}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            ) : (
              // Edit Account Store Group
              <div className="WrapperItemsInputDetailsAccountGroup">
                <div className="ItemsInputDetailsAccountGroup">
                  <div className="ItemInputDetailsAccountGroup">
                    <span>Store Account Group Name</span>
                    <MuiTextField
                      size="small"
                      placeholder="Group Name"
                      value={values.GroupName || ""}
                      onChange={handleChange("GroupName")}
                      type="text"
                      required
                    />
                  </div>
                  <div className="ItemInputDetailsAccountGroup">
                    <span>Status</span>
                    <FormControl fullWidth size="small">
                      <Select
                        required
                        className={classes.selectBorder}
                        value={selectedInput.storeStatus || ""}
                        displayEmpty
                        onChange={(e) =>
                          setSelectedInput({
                            ...selectedInput,
                            storeStatus: e.target.value,
                          })
                        }
                      >
                        <MenuItem value="" disabled>
                          <Typography
                            component="span"
                            style={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#A4A4A4",
                            }}
                          >
                            Store Status
                          </Typography>
                        </MenuItem>
                        {storeStatusArr.sort().map((option, el) => (
                          <MenuItem
                            key={el}
                            onClick={() =>
                              setSelectedInput({
                                ...selectedInput,
                                storeStatus: option,
                              })
                            }
                            value={option || ""}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            )}

            <div className="ButtonActionDetailsStoreAccountGroup">
              <Button
                style={{
                  background: "#FFFFF",
                  borderRadius: 5,
                  border: "1px solid #818181",
                  width: 79,
                  height: 34,
                  textTransform: "none",
                  color: "#818181",
                  fontSize: 13,
                  fontWeight: 500,
                }}
                onClick={() => history.push("/admin-fmcg/setup/store")}
              >
                Cancel
              </Button>
              {location === "addStoreAccountGroup" ? (
                <Button
                  style={{
                    background: "#1571DE",
                    borderRadius: 5,
                    width: 65,
                    height: 34,
                    textTransform: "none",
                    color: "#FFFFFF",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  type="submit"
                >
                  Save
                </Button>
              ) : (
                <Button
                  style={{
                    background: "#1571DE",
                    borderRadius: 5,
                    width: 128,
                    height: 34,
                    textTransform: "none",
                    color: "#FFFFFF",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  type="submit"
                >
                  Save Changes
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DetailsAccountGroup;
