// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import LoanReport from "../LoanSA/LoanReportSA/LoanReport";

export default function LoanSAReport() {
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Loan Report</title>
      </Helmet>

      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <LoanReport />
        </Grid>
      </Grid>
    </Container>
  );
}
