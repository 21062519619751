import React from "react";
import logo_delete from "../../../../assets/images/delete-confirmation.png";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { useHistory } from "react-router";

export default function ModalDeleteStore({
  setDeleteStore,
  // deleteStore,
  pathName,
}) {
  // const token = localStorage.getItem("accessToken");
  // const dispatch = useDispatch()
  const history = useHistory()

  const handleDeleteStore = () => {
    setDeleteStore({})
    // dispatch(deleteClient(token, selectedClient._id))
    history.push(pathName)
  }
  return (
    <div className="modalBackground-modal-tabs-delete-client">
      <div className="modalContainer-modal-tabs-delete-client">
        <div className="titleCloseBtn-ePay">
          <div className="items__fill" style={{ paddingRight: 20 }}>
            dummy
          </div>
          <div className="text__main-ePay">
            <span>Delete Store</span>
          </div>
          <div className="item__button-right">
            <button
              onClick={() => {
                setDeleteStore(false);
              }}
            >
              <CloseIcon style={{ fontSize: 30 }} />
            </button>
          </div>
        </div>
        <div>
          <hr style={{ width: "100%", color: "#ECE6E6" }} />
        </div>
        <div className="body-attendance-modal" style={{ paddingTop: 24 }}>
          <div className="img-item-main">
            <img src={logo_delete} alt="" />
          </div>
          <div className="text-main-modal-tabs-settings">
            <span>Are you sure want to delete this store?</span>
          </div>
          <div className="button-wrapper-modal-tabs-settings">
            <div className="button-delete-modal-tabs-settings">
              <Button
                style={{ backgroundColor: "#1571de" }}
                onClick={handleDeleteStore}
              >
                Delete
              </Button>
            </div>
            <div className="button-delete-modal-tabs-settings">
              <Button
                style={{ border: "1px solid #1571DE", color: "#1571de" }}
                onClick={() => {
                  setDeleteStore(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
