import React from "react";
import { EmployeeFMCG } from "../../../components/FMCG";

export default function EmployeesFMCG() {
  return (
    <div>
      <EmployeeFMCG />
    </div>
  );
}
