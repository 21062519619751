import { Avatar, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import BRIEFCASE_ICON from "../../../../../assets/images/briefcase.png";
import BUILDING_ICON from "../../../../../assets/images/building.png";
import CARD_ICON from "../../../../../assets/images/card.png";
import fetchClientCode from '../../../../../async/client/fetchClientCode';
import Scrollbar from '../../../_user/TableUsers/Scrollbar';
import Styles from './ViewRequest.module.css';


export default function ViewRequest({
    dataInfo,
    currentData,
    requestData
}) {
    const token = localStorage.getItem("accessToken");
    const [dataClientCode, setDataClientCode] = useState([]);

    console.log(dataClientCode)



    const selectedClient = (clientCode) => {
        let client;
        if (clientCode !== '') {
            client = dataClientCode.find((u) => u.codes === clientCode)
            return client?.name
        } else {
            return null
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const { response } = await fetchClientCode(token);
                // setDataClientCode(response?.map((el) => el?.name));
                setDataClientCode(response);
            } catch (error) {
                console.log(error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const capitalizedClientName = (clientName) => {
        return clientName?.toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
          .replace('Pt', 'PT')
          .replace('Cv', 'CV')
      }
    return (
        <div className={Styles.Container}>
            <div className={Styles.Wrapper}>
                <div className={Styles.Left}>
                    <Avatar
                        src={
                            dataInfo?.user?.profilePicture !== ""
                                ? dataInfo?.user?.profilePicture
                                : ""
                        }
                        className={Styles.MainAvatar}
                    />
                </div>
                <div className={Styles.Right}>
                    <div className={Styles.ID}>
                        <div className={Styles.IDHeader}>
                            <span>{dataInfo?.user?.name?.toLowerCase()}</span>
                        </div>
                        <hr />
                        <div className={Styles.DataInfo}>
                            <div className={Styles.BoxData}>
                                <div className={Styles.BoxTitle}>
                                    <img src={CARD_ICON} alt="nrk" />
                                    <span>NRK</span>
                                </div>
                                <div className={Styles.BoxDesc}>
                                    <span>{dataInfo?.employeeId}</span>
                                </div>
                            </div>
                            <div className={Styles.BoxData}>
                                <div className={Styles.BoxTitle}>
                                    <img src={BRIEFCASE_ICON} alt="position" />
                                    <span>Position</span>
                                </div>
                                <div className={Styles.BoxDesc}>
                                    <span>{dataInfo?.jobTitle}</span>
                                </div>
                            </div>
                            <div className={Styles.BoxData}>
                                <div className={Styles.BoxTitle}>
                                    <img src={BUILDING_ICON} alt="client" />
                                    <span>Client</span>
                                </div>
                                <div className={Styles.BoxDesc}>
                                    <span>{capitalizedClientName(selectedClient(dataInfo?.clientCode))}</span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className={Styles.DataCompare}>
                            <Scrollbar>
                                <TableContainer>
                                    <Table sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            borderBottom: "none"
                                        }
                                    }} size="small"
                                    >
                                        <TableHead>
                                            <TableRow
                                                className={Styles.TableHeaderRow}
                                                sx={{
                                                    "& th": {
                                                        fontFamily: 'Poppins',
                                                        fontSize: "16px",
                                                        color: "#0a0a0a",
                                                        fontWeight: 600,
                                                    }
                                                }}
                                            >
                                                <TableCell padding="none">
                                                    Data
                                                </TableCell>
                                                <TableCell>
                                                    Previous Data
                                                </TableCell>
                                                <TableCell>
                                                    New Data
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className={Styles.TDTitle}>KTP</TableCell>
                                                <TableCell className={Styles.TDData}>{currentData?.idNumber}</TableCell>
                                                <TableCell className={Styles.TDData}>{requestData?.idNumber || '-'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={Styles.TDTitle}>Email</TableCell>
                                                <TableCell className={Styles.TDData}>{currentData?.email || '-'}</TableCell>
                                                <TableCell className={Styles.TDData}>{requestData?.email || '-'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={Styles.TDTitle}>Phone Number</TableCell>
                                                <TableCell className={Styles.TDData}>{currentData?.phoneNumber || '-'}</TableCell>
                                                <TableCell className={Styles.TDData}>{requestData?.phoneNumber || '-'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={Styles.TDTitle}>Date of Birth</TableCell>
                                                <TableCell className={Styles.TDData}>{currentData?.birthDate || '-'}</TableCell>
                                                <TableCell className={Styles.TDData}>{requestData?.birthDate || '-'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={Styles.TDTitle}>Marital Status</TableCell>
                                                <TableCell className={Styles.TDData}>{currentData?.maritalStatus || '-'}</TableCell>
                                                <TableCell className={Styles.TDData}>{requestData?.maritalStatus || '-'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={Styles.TDTitle}>NPWP</TableCell>
                                                <TableCell className={Styles.TDData}>{currentData?.taxId || '-'}</TableCell>
                                                <TableCell className={Styles.TDData}>{requestData?.taxId || '-'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={Styles.TDTitle}>Bank Name</TableCell>
                                                <TableCell className={Styles.TDData}>{currentData?.account?.bankName || '-'}</TableCell>
                                                <TableCell className={Styles.TDData}>{requestData?.bankName || '-'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={Styles.TDTitle}>Bank Account Number</TableCell>
                                                <TableCell className={Styles.TDData}>{currentData?.account?.number || '-'}</TableCell>
                                                <TableCell className={Styles.TDData}>{requestData?.accountNumber || '-'}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}