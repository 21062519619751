import * as types from "../../type";

const initialState = {
  activities: [],
  loadingActivities: false,
  errorMessageActivities: {},
};

const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ALL_ACTIVITY_BEGIN: {
      return {
        ...state,
        loadingActivities: true,
      };
    }
    case types.FETCH_ALL_ACTIVITY_SUCCESS: {
      return {
        ...state,
        loadingActivities: false,
        activities: action.payload,
      };
    }
    case types.FETCH_ALL_ACTIVITY_ERROR: {
      return {
        ...state,
        loadingActivities: false,
        errorMessageActivities: action.payload,
      };
    }
    default:
      return state;
  }
};

export default activityReducer;
