import React from "react";
import { EmployeeAssignLeader } from "../../../components/FMCG";

export default function EmployeesAssignLeaderFMCG() {
  return (
    <div>
      <EmployeeAssignLeader />
    </div>
  );
}
