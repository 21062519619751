import { Button, Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Breadcrumb from '../../../../components/utils/Breadcrumbs';
import { fetchNotificationById } from '../../../../redux/actions/SuperAdmin/informationActions';
import DELETE_ICON from '../../../../assets/images/delete-rules-icon.png';
import ModalDeleteNotification from '../../../../components/Business/_notification/ModalDeleteNotification/ModalDeleteNotification';
import { LayoutAddNotification } from '../../../../components/Business/_notification';

export default function AddNotification() {
    const token = localStorage.getItem('accessToken');
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    //   const information = useSelector((state) => location.state.data)
    const [selectedInformation, setSelectedInformation] = useState(null);

    const { notificationById, loadingNotification } = useSelector((state) => state.informations);

    useEffect(() => {
        if (selectedInformation) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [selectedInformation]);

    useEffect(() => {
        if (id) {
            dispatch(fetchNotificationById(token, id));
        }
    }, [dispatch, id, token]);

    // console.log(information, 'cek')
    return (
        <Container maxWidth="xl">
            <div className="container-client-settings">
                <Helmet>
                    <title>MyWorkSpace | {id ? 'Edit Notification' : 'Tambah Notification'}</title>
                </Helmet>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Breadcrumb>
                        <Button
                            onClick={() => {
                                history.push('/admin-business/notification');
                            }}
                            style={{ textTransform: 'none' }}>
                            <span style={{ fontSize: 20, color: '#255bfc' }}>Notification</span>
                        </Button>
                        <span style={{ fontSize: 20, color: '#255bfc' }}>
                            {id ? 'Edit Notification' : 'Tambah Notification'}
                        </span>
                    </Breadcrumb>
                    {id && (
                        <div className="item-edit-delete-settings" style={{ display: 'flex', gap: 10 }}>
                            <Button
                                sx={{ '&:hover': { backgroundColor: 'transparent' }, padding: 0 }}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setSelectedInformation(notificationById);
                                }}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 6,
                                    cursor: 'pointer',
                                    marginRight: 8,
                                }}>
                                <span
                                    style={{
                                        fontSize: 14,
                                        color: '#D1421A',
                                        textTransform: 'capitalize',
                                    }}>
                                    Delete
                                </span>
                                <img src={DELETE_ICON} alt="delete" style={{ height: '17px', width: '15px' }} />
                            </Button>
                        </div>
                    )}
                </div>

                {selectedInformation && (
                    <ModalDeleteNotification
                        active={selectedInformation}
                        onOpen={setSelectedInformation}
                        description="Apakah kamu yakin ingin menghapus notifikasi ini?"
                        label="Hapus Notifikasi"
                        pathName={'/admin-business/notification'}
                    />
                )}
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <LayoutAddNotification 
                            notification={notificationById} 
                            type={id ? "EDIT" : "ADD"}
                        />
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}
