import { Container, Grid } from "@mui/material";
import Styles from './style.module.css';
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { AppBankSA, AppLocationSA } from "../../../components/SuperAdmin/_database";
import { fetchAllBanks } from "../../../redux/actions/SuperAdmin/bankActions";
import { fetchAllLocations } from "../../../redux/actions/SuperAdmin/locationActions";

export default function DatabaseSA() {
    const token = localStorage.getItem("accessToken");
    const dispatch = useDispatch();
    const [active, setActive] = useState('location');
    const { schemaLocations, loadingDataLocations } = useSelector((state) => state.locations)
    const { allBanks, loadingDataBanks } = useSelector((state) => state.banks)

    const persistMode = useMemo(() => {
        let mode = window.sessionStorage.getItem("selectedMode");
        let result;

        if (mode) {
            result = mode
        } else {
            result = active
        }
        return result;
    }, [active])

    const handleModeChange = (e, newMode) => {
        e.preventDefault()
        window.sessionStorage.setItem("selectedMode", newMode)
        setActive(newMode)
    }

    useEffect(() => {
        dispatch(fetchAllLocations(token));
        dispatch(fetchAllBanks(token));
    }, [token, dispatch])

    useEffect(()=>{
        setActive(persistMode)
    }, [persistMode])


    return (
        <Container maxWidth="xl">
            <Helmet>
                <title>MyWorkSpace | Master Data</title>
            </Helmet>
            <div className={Styles.HeaderWrapper}>
                <h2
                    className={Styles.Header}
                >
                    Master Data
                </h2>
                <div className={Styles.ButtonWrapper}>
                    <button onClick={(e) => handleModeChange(e, 'location')} className={persistMode === 'location' ? Styles.ActiveButton : ''}>Location</button>
                    <button onClick={(e) => handleModeChange(e, 'bank')} className={persistMode === 'bank' ? Styles.ActiveButton : ''}>Bank</button>
                </div>
            </div>
            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    {/* <AppInformationSA informations={allInformation} loading={loadingDataInformation} /> */}
                    {persistMode === 'location'
                        ?
                        <AppLocationSA locations={schemaLocations} isLoading={loadingDataLocations} />
                        :
                        <AppBankSA banks={allBanks} isLoading={loadingDataBanks} />
                    }
                </Grid>
            </Grid>
        </Container>
    );
}
