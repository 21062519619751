/* eslint-disable no-unused-vars */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Autocomplete,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import EDIT_ICON from "../../../../assets/images/create.png";
import EXCEL_ICON from "../../../../assets/images/excel.png";
import "../../../SuperAdmin/_user/UserDetailMenuAdmin/UserDetailEmployee.css";
import Styles from "./Style.module.css";
// import EmployeeAssignButton from "./testButton";
// import ModalSettingsEmployeeMenu from "./modal-employee-detail-menu/modal-settings-employee-menu/ModalSettingsEmployeeMenu";
// import ModalAddStaff from "./modal-add-staff/ModalAddStaff";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { makeStyles } from "@mui/styles";
import { alpha, Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import fetchPositions from "../../../../async/groupData/fetchPositions";
import {
  fetchAllDataEmployeeByRangeExternal,
  fetchAllDataEmployeeByRangeSpv,
} from "../../../../redux/actions/External/dataEmployeeExternalActions";
import {
  fetchAllEmployeesExternal,
  fetchAllEmployeesSupervisor,
} from "../../../../redux/actions/External/employeesExternalActions";
import ExportCSV from "../../../SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import { UseGetListPositionExternal, UseGroupName } from "../../../utils/hooks";
import useQuery from "../../../utils/hooks/useQuery";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import toHoursAndMinutes from "../../../utils/ToHoursAndMinute/toHoursAndMinute";
import CustomTablePagination from "../../../_pagination/CustomTablePagination";
import ModalAssignLeaderExternal from "../ModalAssignLeaderExternal/ModalAssignLeaderExternal";
import ModalTabListExternal from "../ModalListLeaderExternal/ModalTabListExternal";
import axios from "axios";
import { API, API_IMAGE } from "../../../../redux/api";
import { fs } from "fs";
import fetchGroupData from "../../../../async/client/fetchGroupData";
import fetchAttendanceOvertime from "../../../../async/attendance/fetchOvertime";
import { debounce } from "lodash";
import { useDebounce } from "../../../../hooks/useDebounce";

const ExcelJS = require("exceljs");

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 170,
    display: "flex",
    justifyContent: "flex-start",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiList-root": {
      width: "100%",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
  },
});

const TABLE_HEAD = [
  { id: "name", label: "Employee Name", alignRight: false },
  { id: "jobTitle", label: "Position", alignRight: false },
  { id: "clientCode", label: "Client", alignRight: false },
  { id: "nrk", label: "NRK", alignRight: false },
  { id: "leader", label: "Leader", alignRight: false },
  { id: "loanApprovers", label: "Approver", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

const TABLE_HEAD_PROFILE = [
  { id: "name", label: "Employee Name", alignRight: false },
  { id: "employeeId", label: "NRK", alignRight: false },
  { id: "jobTitle", label: "Position", alignRight: false },
  { id: "division", label: "Region", alignRight: false },
  { id: "supervisor", label: "Leader", alignRight: false },
];

const TABLE_HEAD_ATTENDANCE = [
  { id: "name", label: "Employee Name", alignRight: false },
  { id: "jobTitle", label: "Position", alignRight: false },
  { id: "division", label: "Region", alignRight: false },
  { id: "attend", label: "Attend", alignRight: false, align: "center" },
  { id: "annualLeave", label: "Leave", alignRight: false, align: "center" },
  { id: "sick", label: "Sick", alignRight: false, align: "center" },
];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, mode } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCell = useMemo(() => {
    if (mode === "profile") {
      return TABLE_HEAD_PROFILE;
    }
    return TABLE_HEAD_ATTENDANCE;
  }, [mode]);

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        {headCell?.map((headCell, index) => (
          <TableCell
            key={headCell.id + index}
            align={headCell?.align || "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              borderRight:
                index === 0 && mode === "profile"
                  ? "1px solid rgba(0, 0, 0, 0.12)"
                  : "0",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

const toDataURL = (url) => {
  const promise = new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });

  return promise;
};

export default function EmployeeExternalBackup() {
  const query = useQuery();
  const pksClient = localStorage.getItem("clientPks");
  const isSuper = localStorage.getItem("isSuper");
  const isSpv = localStorage.getItem("isSPV");
  const isType = localStorage.getItem("type");
  // const dataClient = JSON.parse(localStorage.getItem('clientPks'));
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pendingLoad, setPendingLoad] = useState(false);
  const { isLoading, employeesExternal, totalPage } = useSelector(
    (state) => state.employeesExternal
  );
  const { dataEmployeeExternalAll, loadingDataEmployee } = useSelector(
    (state) => state.employeeExternal
  );
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const pks = pksClient?.split(",");
  const selectedPksClient = Object.assign([], pks);
  // const [pksClientExternal, setPksClientExternal] = useState(selectedPksClient);
  const [order, setOrder] = useState("asc");
  const [selectedDropdown, setSelectedDropdown] = useState("");
  const [typedDropdown, setTypedDropdown] = useState("");
  const [orderBy, setOrderBy] = useState("Name");
  const [attendanceDataPage, setAttendanceDataPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPageAttendance, setRowsPerPageAttendance] = useState(10);
  const [positions, setPositions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [name, setName] = useState("");
  const [nameAttendance, setNameAttendance] = useState("");
  const [mode, setMode] = useState("profile");
  const [list, setList] = useState("");
  const [statusPending, setStatusPending] = useState(false);
  const [status, setStatus] = useState("all");
  const [position, setPosition] = useState("");
  const [position2, setPosition2] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [region, setRegion] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [region2, setRegion2] = useState("");
  const [c2cFilter, setC2cFilter] = useState("");
  const [dateFrom, setDateFrom] = useState(moment().format("L"));
  const [dateTo, setDateTo] = useState(moment().format("L"));
  const clientCode =
    query.get("client_code") ||
    window.sessionStorage.getItem("selectedClientCodeExternal") ||
    localStorage.getItem("clientCode");
  const clientName =
    query.get("client_name") ||
    window.sessionStorage.getItem("selectedClientNameExternal") ||
    localStorage.getItem("clientName");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);

  const [dataOvertime, setDataOvertime] = useState([]);
  const [loadingOvertime, setLoadingOvertime] = useState(false);
  // const [newDataEmployee, setNewDataEmployee] = useState(dataEmployeeExternalAll);

  const attendanceArr = () => {
    const array = [];
    for (let i = 0; i < dataEmployeeExternalAll?.length; i++) {
      const absences = dataEmployeeExternalAll[i]?.Absences;
      for (let j = 0; j < absences?.length; j++) {
        array.push({
          ...dataEmployeeExternalAll[i],
          timeIn: absences[j].startTime
            ? moment(absences[j].startTime).format("HH:mm:ss")
            : "-",
          timeOut:
            absences[j].endTime && absences[j].endTime !== "-"
              ? moment(absences[j].endTime).format("HH:mm:ss")
              : "-",
          dateTimeIn: moment(absences[j].startTime).format("L"),
          dateTimeOut:
            absences[j].endTime && absences[j].endTime !== "-"
              ? moment(absences[j].endTime).format("L")
              : "-",
          longitude: absences[j]?.location?.longitude
            ? absences[j]?.location?.longitude
            : "-",
          latitude: absences[j]?.location?.latitude
            ? absences[j]?.location?.latitude
            : "-",
          type: absences[j]?.type,
          month: moment(absences[j].startTime).format("M"),
          year: moment(absences[j].startTime).format("YYYY"),
          isLate: absences[j]?.isLate,
          totalWorkHour: toHoursAndMinutes(
            absences[j]?.accumulate?.workInMinute
          ),
          region: absences[j]?.area,
          remark: absences[j]?.remark?.includes("::")
            ? capitalizeFirstLetter(absences[j]?.remark.split("::")[2])
            : capitalizeFirstLetter(absences[j]?.remark),
          status:
            (absences[j].type === "ATTEND" && "Hadir") ||
            (absences[j].type === "ANNUAL-LEAVE" && "Cuti") ||
            (absences[j].type === "SICK-PERMIT" && "Sakit"),
          imageAttendance: absences[j].images[0]?.uri
            ? absences[j].images[0]?.uri?.replace(
                `https://myworkspace.id/api/image/`,
                `${API_IMAGE}`
              )
            : "-",
          requestTime: absences[j]?.applyDate
            ? moment(absences[j].applyDate).format("HH:mm:ss")
            : "-",
          requestDate: absences[j]?.applyDate
            ? moment(absences[j].applyDate).format("L")
            : "",
        });
      }
    }
    return array;
  };

  // new data with replaced api employee external
  const modifiedData = useMemo(() => {
    return dataEmployeeExternalAll.map((employee) => ({
      ...employee,
      profilePicture: employee?.profilePicture?.replace(
        `https://myworkspace.id/api/image/`,
        `${API_IMAGE}`
      ),
    }));
  }, [dataEmployeeExternalAll]);

  const modifiedDataProfile = useMemo(() => {
    return employeesExternal.map((employee) => ({
      ...employee,
      profilePicture: employee?.profilePicture?.replace(
        `https://myworkspace.id/api/image/`,
        `${API_IMAGE}`
      ),
      supervisor: {
        ...employee.supervisor,
        profilePicture: employee?.supervisor?.profilePicture?.replace(
          `https://myworkspace.id/api/image/`,
          `${API_IMAGE}`
        ),
      },
    }));
  }, [employeesExternal]);

  const dataToExport = attendanceArr();
  const persistName = window.sessionStorage.getItem("persistNameExt");

  const dateRange = useMemo(() => {
    let range = [];
    for (
      var d = new Date(dateFrom);
      d <= new Date(dateTo);
      d.setDate(d.getDate() + 1)
    ) {
      range.push(moment(d).format("L"));
    }
    return range;
  }, [dateFrom, dateTo]);

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 80;

    sheet.getRow(1).font = {
      name: "Poppins",
      size: 16,
      bold: true,
    };

    sheet.columns = [
      {
        header: "No",
        key: "no",
        width: 10,
      },
      { header: "Nama", key: "name", width: 32 },
      {
        header: "Company",
        key: "company",
        width: 20,
      },
      {
        header: "Position",
        key: "position",
        width: 20,
      },
      {
        header: "Nrk",
        key: "nrk",
        width: 15,
      },
      {
        header: "Month",
        key: "month",
        width: 10,
      },
      {
        header: "Year",
        key: "year",
        width: 30,
      },
      {
        header: "Date In",
        key: "dateIn",
        width: 30,
      },
      {
        header: "Date Out",
        key: "dateOut",
        width: 30,
      },
      {
        header: "Time In",
        key: "timeIn",
        width: 30,
      },
      {
        header: "Time Out",
        key: "timeOut",
        width: 30,
      },
      {
        header: "Coordinate Point",
        key: "coordinatePoint",
        width: 30,
      },
      {
        header: "Late",
        key: "late",
        width: 30,
      },
      {
        header: "Total Work Hour",
        key: "workHour",
        width: 30,
      },
      {
        header: "Region",
        key: "region",
        width: 30,
      },
      {
        header: "Status Absence",
        key: "status",
        width: 30,
      },
      {
        header: "Remark",
        key: "remark",
        width: 30,
      },
      {
        header: "Photo",
        key: "photo",
        width: 30,
      },
    ];

    const promise = Promise.all(
      dataToExport
        ?.filter(
          (el) =>
            el?.name?.toLowerCase().includes(nameAttendance?.toLowerCase()) &&
            (region2 ? el?.division === region2 : true) &&
            (position2 ? el?.jobTitle === position2 : true)
        )
        ?.map(async (el, index) => {
          const rowNumber = index + 1;
          sheet.addRow({
            no: index + 1,
            name: el?.name,
            company: el?.client,
            position: el.jobTitle,
            nrk: el?.employeeId,
            month: el.month,
            year: el.year,
            dateIn: el.dateTimeIn,
            dateOut: el.dateTimeOut,
            timeIn: el.timeIn,
            timeOut: el.timeOut,
            coordinatePoint: `${el.latitude} ${el.longitude}`,
            late: el?.isLate,
            workHour: `${el?.totalWorkHour?.hours} Hours ${el?.totalWorkHour?.minutes} Minutes`,
            region: el?.region,
            status: el.status,
            remark: el.remark,
          });
          const result = await toDataURL(el?.imageAttendance);
          const splitted = el?.imageAttendance?.split(".");
          const extName = splitted[splitted?.length - 1];

          const imageId2 = workbook.addImage({
            base64: result.base64Url,
            extension: extName,
          });

          sheet.addImage(imageId2, {
            tl: { col: 17, row: rowNumber },
            ext: { width: 100, height: 100 },
          });
        })
    );

    promise.then(() => {
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `Attendance Report ${new Date().toLocaleDateString()}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  const persistMode = useMemo(() => {
    let temp = window.sessionStorage.getItem("mode");
    if (temp) {
      return temp;
    } else {
      return mode;
    }
  }, [mode]);

  const handleSwitchMode = (newMode) => {
    setMode(newMode);
    window.sessionStorage.setItem("mode", newMode);
  };

  useEffect(() => {
    setMode(persistMode);
  }, [persistMode]);

  // const persistName = useMemo(() => {

  //   if (temp) {
  //     return temp;
  //   } else {
  //     return name;
  //   }
  // }, [name]);

  const debouncedName = useDebounce(name, 700);

  useEffect(() => {
    setName(persistName || "");
  }, []);

  const persistPosition = useMemo(() => {
    let persistPosition = window.sessionStorage.getItem("selectedPosition");
    let positionResult;

    if (persistPosition) {
      positionResult = persistPosition;
    } else {
      positionResult = position;
    }

    return positionResult;
  }, [position]);

  const persistPosition2 = useMemo(() => {
    let persistPosition = window.sessionStorage.getItem("selectedPosition2");
    let positionResult;

    if (persistPosition) {
      positionResult = persistPosition;
    } else {
      positionResult = position2;
    }

    return positionResult;
  }, [position2]);

  const persistRegion = useMemo(() => {
    let persistRegion = window.sessionStorage.getItem("selectedRegion");
    let regionResult;

    if (persistRegion) {
      regionResult = persistRegion;
    } else {
      regionResult = region;
    }

    return regionResult;
  }, [region]);

  const persistRegion2 = useMemo(() => {
    let persistRegion = window.sessionStorage.getItem("selectedRegion2");
    let regionResult;

    if (persistRegion) {
      regionResult = persistRegion;
    } else {
      regionResult = region2;
    }

    return regionResult;
  }, [region2]);

  const persistC2c = useMemo(() => {
    let persistC2cData = window.sessionStorage.getItem("c2cFilter");
    let c2cResult;

    if (persistC2c) {
      c2cResult = persistC2cData;
    } else {
      c2cResult = c2cFilter;
    }

    return c2cResult;
  }, [c2cFilter]);

  const persistDateFrom = useMemo(() => {
    let temp = window.sessionStorage.getItem("dateFrom");
    if (temp) {
      return temp;
    } else {
      return dateFrom;
    }
  }, [dateFrom]);

  const persistDateTo = useMemo(() => {
    let temp = window.sessionStorage.getItem("dateTo");

    if (temp) {
      return temp;
    } else {
      return dateTo;
    }
  }, [dateTo]);

  const handleSetDateFrom = (newVal) => {
    setDateFrom(newVal);
    window.sessionStorage.setItem("dateFrom", newVal);
  };

  const handleSetDateTo = (newVal) => {
    setDateTo(newVal);
    window.sessionStorage.setItem("dateTo", newVal);
  };

  // const queryString = pksClient?.length < 1 ?  `pks_number[0]=${encodeURIComponent(pksClient)}&` : pksClient?.map((pksNumber, index) => `pks_number[${index}]=${encodeURIComponent(pksNumber)}`).join('&');

  // use this after migration data using pks !!!!
  const queryString = selectedPksClient
    ?.map(
      (pksNumber, index) =>
        `pks_number[${index}]=${encodeURIComponent(pksNumber)}`
    )
    .join("&");

  const persistListMode = useMemo(() => {
    let persistMode = window.sessionStorage.getItem("selectedListMode");
    let mode;
    if (persistMode) {
      mode = persistMode;
    } else {
      mode = status;
    }
    return mode;
  }, [status]);

  const persistClientName =
    window.sessionStorage.getItem("selectedClientNameExternal") ||
    localStorage.getItem("clientName");

  useEffect(() => {
    if (persistMode !== "profile") {
      if (isSuper === "true") {
        // const fetchQueryExternal = `${queryString}&limit=${limit}&pageNumber=${page}&position=${persistPosition}&name=${persistName}&status=${persistListMode || ""}`;
        const fetchQuery = `startDate=${moment(persistDateFrom).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(persistDateTo).format(
          "YYYY-MM-DD"
        )}&clientName=${clientName}`;
        dispatch(fetchAllDataEmployeeByRangeExternal(token, fetchQuery));
        // console.log(fetchQuery,' absen 1')
      }
      if (isSpv === "true") {
        const fetchQuery = `startDate=${moment(persistDateFrom).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(persistDateTo).format(
          "YYYY-MM-DD"
        )}&clientName=${clientName}&clientCode=${clientCode}`;
        dispatch(fetchAllDataEmployeeByRangeSpv(token, fetchQuery));
      } else {
        const fetchQueryExternal = `${queryString}&startDate=${moment(
          persistDateFrom
        ).format("YYYY-MM-DD")}&endDate=${moment(persistDateTo).format(
          "YYYY-MM-DD"
        )}&clientName=${clientName}`;
        // const fetchQuery = `clientCode=${clientCode}&limit=${limit}&pageNumber=${page}&position=${persistPosition}&name=${persistName}&status=${persistListMode || ""}`;
        dispatch(
          fetchAllDataEmployeeByRangeExternal(token, fetchQueryExternal)
        );
        // console.log(fetchQueryExternal,' absen 2')
      }
    }
    // persistMode !== "profile" &&
    //   dispatch(
    //     fetchAllDataEmployeeByRangeExternal(
    //       token,
    //       moment(persistDateFrom).format("YYYY-MM-DD"),
    //       moment(persistDateTo).format("YYYY-MM-DD"),
    //       clientName
    //     )
    //   );
  }, [
    persistMode,
    dispatch,
    token,
    persistDateFrom,
    persistDateTo,
    clientName,
    clientCode,
    isSuper,
    limit,
    page,
    persistListMode,
    persistPosition,
    queryString,
    isSpv,
  ]);

  useEffect(() => {
    setDateFrom(persistDateFrom);
  }, [persistDateFrom]);

  useEffect(() => {}, [selectedPksClient]);

  useEffect(() => {
    // const fetchQueryExternal = `${queryString}&limit=${limit}&pageNumber=${page}&position=${persistPosition}&name=${persistName}&status=${persistListMode || ""}`;
    // const fetchQuery = `clientCode=${clientCode}&limit=${limit}&pageNumber=${page}&position=${persistPosition}&name=${persistName}&status=${persistListMode || ""}`;
    // const fetchQuery = `clientCode=${clientCode}&limit=${limit}&pageNumber=${page}&position=${persistPosition}&name=${persistName}&division=${persistRegion}`;
    // persistMode === "profile" && pendingLoad === false && dispatch(fetchAllEmployeesExternal(token, fetchQuery))
    // persistMode === "profile" && pendingLoad === false && dispatch(fetchAllEmployeesExternal(token, fetchQueryExternal))
    if (isSuper === "true") {
      // const fetchQueryExternal = `${queryString}&limit=${limit}&pageNumber=${page}&position=${persistPosition}&name=${persistName}&status=${persistListMode || ""}`;
      const fetchQuery = `clientCode=${clientCode}&limit=${limit}&pageNumber=${page}&position=${persistPosition}&name=${name}&status=${
        persistListMode || ""
      }`;
      dispatch(fetchAllEmployeesExternal(token, fetchQuery));
      // console.log(fetchQuery, '111')
    } else if (isSpv === "true") {
      const fetchQuery = `clientCode=${clientCode}&limit=${limit}&pageNumber=${page}&position=${persistPosition}&name=${name}&status=${
        persistListMode || ""
      }`;
      dispatch(fetchAllEmployeesSupervisor(token, fetchQuery));
    } else {
      const fetchQueryExternal = `${queryString}&limit=${limit}&pageNumber=${page}&position=${persistPosition}&name=${name}&status=${
        persistListMode || ""
      }`;
      dispatch(fetchAllEmployeesExternal(token, fetchQueryExternal));
      // console.log(fetchQueryExternal, '222')
    }
    // if(persistMode === "profile" && pendingLoad === false && isSuper === true) {
    // const fetchQueryExternal = `${queryString}&limit=${limit}&pageNumber=${page}&position=${persistPosition}&name=${persistName}&status=${persistListMode || ""}`;
    // const fetchQuery = `clientCode=${clientCode}&limit=${limit}&pageNumber=${page}&position=${persistPosition}&name=${persistName}&status=${persistListMode || ""}`;

    // dispatch(fetchAllEmployeesExternal(token, fetchQuery))
    // }

    // if(persistMode === "profile" && pendingLoad === false && isSuper === false) {
    // const fetchQueryExternal = `${queryString}&limit=${limit}&pageNumber=${page}&position=${persistPosition}&name=${persistName}&status=${persistListMode || ""}`;
    // const fetchQuery = `clientCode=${clientCode}&limit=${limit}&pageNumber=${page}&position=${persistPosition}&name=${persistName}&status=${persistListMode || ""}`;
    // dispatch(fetchAllEmployeesExternal(token, fetchQueryExternal))
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    persistMode,
    token,
    dispatch,
    clientCode,
    limit,
    page,
    persistPosition,
    persistRegion,
    persistListMode,
    queryString,
    isSuper,
    isSpv,
    debouncedName,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await fetchPositions(token, clientName);
        setPositions(data?.position);
        setRegions(data?.employeeArea);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [clientName, token]);

  useEffect(() => {
    const refetchData = async () => {
      try {
        setLoadingOvertime(true);
        const { response } = await fetchAttendanceOvertime(
          token,
          persistClientName,
          moment(new Date(persistDateFrom).getTime()).format("YYYY-MM-DD"),
          moment(new Date(persistDateTo).getTime()).format("YYYY-MM-DD")
        );
        if (response) {
          setLoadingOvertime(false);
          setDataOvertime(response);
        }
      } catch (error) {
        setLoadingOvertime(false);
        console.log(error, "error");
      }
    };
    refetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistClientName, persistDateFrom, persistDateTo]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const data = await fetchGroupData(token, persistClientName);
  //       setGroupData(data?.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, [persistClientName, token]);

  useEffect(() => {
    setStatus(persistListMode);
  }, [persistListMode]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setName(event.target.value);
  };
  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const filteredEmployees = stableSort(
    employeesExternal,
    getComparator(order, orderBy)
  );

  const handleSetPosition = (e, newVal) => {
    setPendingLoad(true);
    if (newVal !== null) {
      window.sessionStorage.setItem("selectedPosition", newVal);
      setPosition(newVal);
    } else {
      window.sessionStorage.setItem("selectedPosition", "");
      setPosition("");
    }
    setTimeout(() => {
      setPendingLoad(false);
    }, 1000);
    clearTimeout();
  };

  const handleSetPosition2 = (e, newVal) => {
    if (newVal !== null) {
      window.sessionStorage.setItem("selectedPosition2", newVal);
      setPosition2(newVal);
    } else {
      window.sessionStorage.setItem("selectedPosition2", "");
      setPosition2("");
    }
  };

  const handleSetRegion = (e, newVal) => {
    setPendingLoad(true);
    if (newVal !== null) {
      window.sessionStorage.setItem("selectedRegion", newVal);
      setRegion(newVal);
    } else {
      window.sessionStorage.setItem("selectedRegion", "");
      setRegion("");
    }
    setTimeout(() => {
      setPendingLoad(false);
    }, 1000);
    clearTimeout();
  };

  const handleSetRegion2 = (e, newVal) => {
    if (newVal !== null) {
      window.sessionStorage.setItem("selectedRegion2", newVal);
      setRegion2(newVal);
    } else {
      window.sessionStorage.setItem("selectedRegion2", "");
      setRegion2("");
    }
  };

  const handleC2cFilter = (e, newVal) => {
    if (newVal !== null) {
      window.sessionStorage.setItem("c2cFilter", newVal);
      setC2cFilter(newVal);
    } else {
      window.sessionStorage.setItem("c2cFilter", "");
      setC2cFilter("");
    }
  };

  useEffect(() => {
    setPosition(persistPosition);
  }, [setPosition, persistPosition]);

  const handleSearchName = (e) => {
    setPendingLoad(true);
    window.sessionStorage.setItem("persistNameExt", e.target.value);
    setName(e.target.value);
    setTimeout(() => {
      setPendingLoad(false);
    }, 2000);
    clearTimeout();
  };

  const handleDownloadOvertime = async () => {
    const url = `${API}/attendance/admin/overtime?clientName=${persistClientName}&startDate=${moment(
      new Date(persistDateFrom).getTime()
    ).format("YYYY-MM-DD")}&endDate=${moment(
      new Date(persistDateTo).getTime()
    ).format("YYYY-MM-DD")}`;
    const headers = { "Content-Type": "blob", Authorization: token };
    const config = { method: "GET", url, responseType: "arraybuffer", headers };

    try {
      // setLoading(true);
      const response = await axios(config);
      const outputFilename = `Attendance Report ${new Date().toLocaleDateString()}.xlsx`;

      const url = URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();

      fs?.writeFileSync(outputFilename, response?.data);
    } catch (error) {
      throw Error(error);
    } finally {
      // setLoading(false);
    }
  };

  const handleDownloadDetailedOvertime = async () => {
    const url = `${API}/attendance/admin/download/overtime?clientName=${persistClientName}&startDate=${moment(
      new Date(persistDateFrom).getTime()
    ).format("YYYY-MM-DD")}&endDate=${moment(
      new Date(persistDateTo).getTime()
    ).format("YYYY-MM-DD")}`;
    const headers = { "Content-Type": "blob", Authorization: token };
    const config = { method: "GET", url, responseType: "arraybuffer", headers };

    try {
      setLoading(true);
      const response = await axios(config);
      const outputFilename = `Attendance Report ${new Date().toLocaleDateString()}.xlsx`;

      const url = URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();

      fs?.writeFileSync(outputFilename, response?.data);
    } catch (error) {
      throw Error(error);
    } finally {
      setLoading(false);
    }
  };

  const capitalizedClientName = (clientName) => {
    return clientName
      ?.toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .replace("Pt", "PT")
      .replace("Cv", "CV");
  };

  const handleSearchNameAttendance = (e) => {
    setNameAttendance(e.target.value);
  };

  const handleListStatus = (event) => {
    setList(event.target.value);
    window.sessionStorage.setItem("selectedListMode", event.target.value);
    setStatusPending(true);
    setStatus(event.target.value);
    // if (event.target.value === 'edit') {
    //     window.sessionStorage.setItem('selectedListMode', 'edit')
    //     setStatusPending(true)
    //     setStatus('edit')
    // } else if (event.target.value === 'inactive') {
    //     window.sessionStorage.setItem('selectedListMode', 'inactive')
    //     setStatusPending(true)
    //     setStatus('inactive')
    // }
    // else {
    //     window.sessionStorage.setItem('selectedListMode', 'all')
    //     setStatusPending(false)
    //     setStatus('all')
    // }
  };

  const dataAbsenceRaw = () => {
    const rawArr = [];
    const dataEmployeeExternalAll = modifiedData.filter(
      (el) =>
        el.name?.toLowerCase().includes(nameAttendance?.toLowerCase()) &&
        (persistRegion2
          ? el?.division?.toLowerCase() === persistRegion2?.toLowerCase()
          : true) &&
        (persistPosition2
          ? el?.jobTitle?.toLowerCase() === persistPosition2?.toLowerCase()
          : true)
    );
    for (let i = 0; i < dataEmployeeExternalAll?.length; i++) {
      const absences = dataEmployeeExternalAll[i]?.Absences;
      let attendanceArr = [];
      for (let j = 0; j < dateRange.length; j++) {
        const dateExist = absences.some(
          (el) => moment(el.startTime).format("L") === dateRange[j]
        );
        const absenceIndex = absences.findIndex(
          (el) => moment(el.startTime).format("L") === dateRange[j]
        );

        attendanceArr.push({
          date: dateRange[j],
          type: dateExist
            ? (absences[absenceIndex]?.type === "ATTEND" && "Hadir") ||
              (absences[absenceIndex]?.type === "ANNUAL-LEAVE" && "Cuti") ||
              (absences[absenceIndex]?.type === "SICK-PERMIT" && "Sakit") ||
              (absences[absenceIndex]?.type === "PERMIT" && "Izin")
            : "-",
        });
      }
      rawArr.push({
        name: dataEmployeeExternalAll[i]?.name,
        attendances: attendanceArr,
        employeeId: dataEmployeeExternalAll[i]?.employeeId,
      });
    }
    return rawArr;
  };

  const dataToExport2 = dataAbsenceRaw();

  const dataCutOff2 = useMemo(() => {
    const datas = [];
    for (let i = 0; i < dataToExport2?.length; i++) {
      const attendances = dataToExport2[i]?.attendances;
      const eachData = {
        No: i + 1,
        Name: dataToExport2[i]?.name,
        Nrk: dataToExport2[i]?.employeeId,
      };
      for (let j = 0; j < attendances?.length; j++) {
        eachData[attendances[j].date] = attendances[j].type;
      }
      datas.push(eachData);
    }
    return datas;
  }, [dataToExport2]);

  const [selectedIndicator, setSelectedIndicator] = useState("");
  const [isLoadingIndicator, setIsLoadingIndicator] = useState(false);

  const handleSelectedIndicator = (id) => {
    try {
      if (selectedIndicator === id) {
        setSelectedIndicator("");
      } else {
        setIsLoadingIndicator(true);
        setSelectedIndicator(id);
        //await

        setIsLoadingIndicator(false);
      }
    } catch (error) {
      console.log(error);
      setSelectedIndicator("");
      setIsLoadingIndicator(false);
    }
  };

  return (
    <div className={Styles.Container}>
      <div className={Styles.Controlbar}>
        <div
          className={`${Styles.Mode} ${
            mode === "profile" && Styles.ActiveMode
          }`}
          onClick={() => handleSwitchMode("profile")}
        >
          <span>Profile</span>
        </div>
        <div
          className={`${Styles.Mode} ${
            mode === "attendance" && Styles.ActiveMode
          }`}
          onClick={() => handleSwitchMode("attendance")}
        >
          <span>Attendance</span>
        </div>
        {/* <div
          className={`${Styles.Mode} ${mode === "c2c" && Styles.ActiveMode}`}
          onClick={() => handleSwitchMode("c2c")}
        >
          <span>C2C Pending</span>
        </div> */}
        <div className={Styles.BorderBottom}></div>
      </div>
      <div className={Styles.ControlOptionsContainer}>
        {/*  */}
        {mode === "profile" && (
          <div className={Styles.ProfileControlWrapper}>
            <div className={Styles.ProfileControlLeft}>
              <SearchbarSA
                value={name}
                onChange={handleSearchName}
                searchWidth="309px"
                placeholder="Search by name"
              />
              <Autocomplete
                disablePortal
                value={persistPosition || ""}
                disabled={isLoading}
                options={
                  positions?.filter(
                    (title) =>
                      title !== null && title !== "NULL" && title !== ""
                  ) || []
                }
                onInputChange={handleSetPosition}
                onChange={handleSetPosition}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    size="small"
                    sx={{ width: 210, background: "var(--main-bg)" }}
                    placeholder="Position"
                  />
                )}
              />
              <div className={Styles.SelectBox}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={persistListMode || ""}
                  onChange={handleListStatus}
                  className={Styles.Select}
                  disabled={isLoading}
                >
                  <MenuItem value="all">All List</MenuItem>
                  <MenuItem value="PENDING">Edit Request List</MenuItem>
                  <MenuItem value="INACTIVE">Resigned</MenuItem>
                </Select>
              </div>
            </div>

            <div className={Styles.SubHeader}>
              <span style={{ textTransform: "capitalize" }}>
                All {capitalizedClientName(persistClientName)} Employees
              </span>
              <div className={Styles.EditIndicator}>
                <div
                  className={`${Styles.RequestEdit} ${
                    selectedIndicator == "RequestEdit" ? Styles.active : ""
                  }`}
                  onClick={() => handleSelectedIndicator("RequestEdit")}
                >
                  <div></div>
                  <span>Request Edit</span>
                </div>
                <div
                  className={`${Styles.Resign} ${
                    selectedIndicator == "Resign" ? Styles.active : ""
                  }`}
                  onClick={() => handleSelectedIndicator("Resign")}
                >
                  <div></div>
                  <span>Resigned</span>
                </div>
              </div>
            </div>

            {/* <div className={Styles.ProfileControlRight}>
              <Autocomplete
                disablePortal
                value={persistRegion || ""}
                options={
                  regions?.filter(
                    (area) => area !== null && area !== "NULL" && area !== ""
                  ) || []
                }
                onInputChange={handleSetRegion}
                onChange={handleSetRegion}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    size="small"
                    sx={{ width: 210, background: "var(--main-bg)" }}
                    placeholder="Region"
                  />
                )}
              />
              <Autocomplete
                disablePortal
                value={persistPosition || ""}
                options={
                  positions?.filter(
                    (title) =>
                      title !== null && title !== "NULL" && title !== ""
                  ) || []
                }
                onInputChange={handleSetPosition}
                onChange={handleSetPosition}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    size="small"
                    sx={{ width: 210, background: "var(--main-bg)" }}
                    placeholder="Position"
                  />
                )}
              />
            </div> */}
          </div>
        )}

        {mode === "attendance" && (
          <div className={Styles.AttendanceControlWrapper}>
            <div className={Styles.AttendanceControlTop}>
              <SearchbarSA
                value={nameAttendance}
                onChange={handleSearchNameAttendance}
                searchWidth="316px"
                placeholder="Search by name"
              />
              <Autocomplete
                disablePortal
                // options={groupData?.employeeArea || []}
                options={
                  regions?.filter(
                    (area) =>
                      area !== null &&
                      area !== "NULL" &&
                      area !== "" &&
                      area !== "DUMMY REGION"
                  ) || []
                }
                disabled={loadingDataEmployee}
                value={persistRegion2 || ""}
                onInputChange={handleSetRegion2}
                onChange={handleSetRegion2}
                // onInputChange={(e, value) => setSelectedRegion(value)}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    size="small"
                    sx={{ width: 210, backgroundColor: "#FFFFFF" }}
                    placeholder="Region"
                  />
                )}
              />
              {/* <Autocomplete
                disablePortal
                value={persistRegion || ""}
                options={
                  regions?.filter(
                    (area) => area !== null && area !== "NULL" && area !== ""
                  ) || []
                }
                onInputChange={handleSetRegion2}
                onChange={handleSetRegion2}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    size="small"
                    sx={{ width: 210, background: "var(--main-bg)" }}
                    placeholder="Region"
                  />
                )}
              /> */}
              <Autocomplete
                disablePortal
                disabled={loadingDataEmployee}
                value={persistPosition2 || ""}
                options={
                  positions?.filter(
                    (title) =>
                      title !== null && title !== "NULL" && title !== ""
                  ) || []
                }
                onInputChange={handleSetPosition2}
                onChange={handleSetPosition2}
                sx={{ width: "193px", backgroundColor: "#FFFFFF" }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    size="small"
                    sx={{ background: "var(--main-bg)" }}
                    placeholder="Position"
                  />
                )}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className={Styles.RangeDate}>
                  <DatePicker
                    value={persistDateFrom || dateFrom}
                    views={["year", "month", "day"]}
                    onChange={handleSetDateFrom}
                    format="DD-MM-YYYY"
                    disabled={loadingDataEmployee}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        sx={{ background: "var(--main-bg)" }}
                        size="small"
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className={Styles.RangeDate}>
                  <DatePicker
                    // sx={{ width: "50%" }}
                    value={persistDateTo || dateTo}
                    views={["year", "month", "day"]}
                    disabled={loadingDataEmployee}
                    onChange={handleSetDateTo}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        sx={{ background: "var(--main-bg)" }}
                        size="small"
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>

              <div className={Styles.ActionButton}>
                <Button
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  endIcon={<ExpandMoreIcon />}
                  className={Styles.DownloadExcelButton}
                  sx={{ width: "66px !important" }}
                >
                  <img
                    src={EXCEL_ICON}
                    alt="excel-icon"
                    className={Styles.ImageButton}
                  />
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                >
                  {clientName === "PT. CITRA NUSA INSAN CEMERLANG" ? (
                    <ExportCSV
                      data={dataToExport
                        .filter(
                          (el) =>
                            el?.name
                              ?.toLowerCase()
                              .includes(nameAttendance?.toLowerCase()) &&
                            (region2 ? el?.division === region2 : true) &&
                            (position2 ? el?.jobTitle === position2 : true)
                        )
                        .map((el, ind) => ({
                          "No. ": ind + 1,
                          Nama: el?.name,
                          Company: el?.client,
                          Position: el.jobTitle,
                          Nrk: el?.employeeId,
                          Month: el.month,
                          Year: el.year,
                          "Date in": el.dateTimeIn,
                          "Date out": el.dateTimeOut,
                          "Time in": el.timeIn,
                          "Time out": el.timeOut,
                          "Coordinate Point": `${el.latitude} ${el.longitude}`,
                          Late: el?.isLate,
                          "Total Work Hour": `${el?.totalWorkHour?.hours} Hours ${el?.totalWorkHour?.minutes} Minutes`,
                          Region: el?.region,
                          "Status Absence": el.status,
                          Remark: el.remark,
                          "Request Date": el?.requestDate,
                          "Request Time": el?.requestTime,
                        }))}
                      filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                      initial="Default"
                    />
                  ) : (
                    <ExportCSV
                      data={dataToExport
                        .filter(
                          (el) =>
                            el?.name
                              ?.toLowerCase()
                              .includes(nameAttendance?.toLowerCase()) &&
                            (region2 ? el?.division === region2 : true) &&
                            (position2 ? el?.jobTitle === position2 : true)
                        )
                        .map((el, ind) => ({
                          "No. ": ind + 1,
                          Nama: el?.name,
                          Company: el?.client,
                          Position: el.jobTitle,
                          Nrk: el?.employeeId,
                          Month: el.month,
                          Year: el.year,
                          "Date in": el.dateTimeIn,
                          "Date out": el.dateTimeOut,
                          "Time in": el.timeIn,
                          "Time out": el.timeOut,
                          "Coordinate Point": `${el.latitude} ${el.longitude}`,
                          Region: el.region,
                          "Status Absence": el.status,
                          Remark: el.remark,
                          "Request Date": el?.requestDate,
                          "Request Time": el?.requestTime,
                        }))}
                      filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                      initial="Default"
                    />
                  )}
                  <Button
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                      textTransform: "none",
                      color: "#404040",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                    onClick={exportExcelFile}
                  >
                    Detailed
                  </Button>
                  <ExportCSV
                    data={dataCutOff2}
                    filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                    initial="Summary"
                  />
                  <ExportCSV
                    data={dataOvertime?.map((el, ind) => ({
                      "No. ": ind + 1,
                      NAMA: el?.NAMA,
                      NRK: el?.NRK,
                      LOKASI: el?.LOKASI,
                      REGION: el?.REGION,
                      STATUS: el?.STATUS,
                      "JOIN DATE": el?.["JOINT DATE"],
                      "END DATE": el?.["END DATE"],
                      NPWP: el?.NPWP,
                      "NO REK": el?.["NO.REK"],
                      BANK: el?.BANK,
                      "HARI KERJA": el?.["HARI KERJA"],
                      // OT: ,
                      "POINT OT": el?.POINT,
                      1.5: el?.satuLima,
                      "2.0": el?.dua,
                      "3.0": el?.tiga,
                      "4.0": el?.empat,
                      "JML JAM OT": el?.["JML JAM OT"],
                    }))}
                    filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                    initial="Overtime"
                  />
                  <Button
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                      textTransform: "none",
                      color: "#404040",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                    onClick={handleDownloadDetailedOvertime}
                  >
                    Detailed Overtime
                  </Button>
                </StyledMenu>
              </div>
            </div>
          </div>
        )}
        {/* {mode === "c2c" && (
          <div className={Styles.C2CControlWrapper}>
            <div className={Styles.C2CControlTop}>
              <SearchbarSA
                value={c2cSearch}
                onChange={(e) => setC2cSearch(e.target.value)}
                searchWidth="316px"
                placeholder="Search by name"
              />
              <div>
                <Button
                  disableElevation
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  endIcon={<ExpandMoreIcon />}
                  className={Styles.DownloadExcelButton}
                >
                  Download Excel
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                >
                  {clientName === "PT. CITRA NUSA INSAN CEMERLANG" ? (
                    <ExportCSV
                      data={dataToExport
                        .filter(
                          (el) =>
                            el?.name
                              ?.toLowerCase()
                              .includes(nameAttendance.toLowerCase()) &&
                            (region2 ? el?.division === region2 : true) &&
                            (position2 ? el?.jobTitle === position2 : true)
                        )
                        .map((el, ind) => ({
                          "No. ": ind + 1,
                          Nama: el?.name,
                          Company: el?.client,
                          Position: el.jobTitle,
                          Nrk: el?.employeeId,
                          Month: el.month,
                          Year: el.year,
                          "Date in": el.dateTimeIn,
                          "Date out": el.dateTimeOut,
                          "Time in": el.timeIn,
                          "Time out": el.timeOut,
                          "Coordinate Point": `${el.latitude} ${el.longitude}`,
                          Late: el?.isLate,
                          "Total Work Hour": `${el?.totalWorkHour?.hours} Hours ${el?.totalWorkHour?.minutes} Minutes`,
                          Region: el?.region,
                          "Status Absence": el.status,
                          Remark: el.remark,
                        }))}
                      filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                      initial="Default"
                    />
                  ) : (
                    <ExportCSV
                      data={dataToExport
                        .filter(
                          (el) =>
                            el?.name
                              ?.toLowerCase()
                              .includes(nameAttendance.toLowerCase()) &&
                            (region2 ? el?.division === region2 : true) &&
                            (position2 ? el?.jobTitle === position2 : true)
                        )
                        .map((el, ind) => ({
                          "No. ": ind + 1,
                          Nama: el?.name,
                          Company: el?.client,
                          Position: el.jobTitle,
                          Nrk: el?.employeeId,
                          Month: el.month,
                          Year: el.year,
                          "Date in": el.dateTimeIn,
                          "Date out": el.dateTimeOut,
                          "Time in": el.timeIn,
                          "Time out": el.timeOut,
                          "Coordinate Point": `${el.latitude} ${el.longitude}`,
                          Region: el.region,
                          "Status Absence": el.status,
                          Remark: el.remark,
                        }))}
                      filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                      initial="Default"
                    />
                  )}
                  <Button
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                      textTransform: "none",
                      color: "#404040",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                    onClick={exportExcelFile}
                  >
                    Detailed
                  </Button>
                  <ExportCSV
                    data={dataCutOff2}
                    filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                    initial="Summary"
                  />
                )}
              />
            </div>
          </div>
        )} */}
      </div>
      <div className={Styles.TableWrapper}>
        {mode === "profile" && (
          <>
            {isLoading || isLoadingIndicator ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 100,
                }}
              >
                <div style={{ width: 200 }}>
                  <LoadingAnimation />
                </div>
              </div>
            ) : (
              <>
                <Scrollbar>
                  <TableContainer className={Styles.TableContainer}>
                    <Table id="emp-table">
                      <colgroup>
                        <col style={{ width: "30%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "30%" }} />
                      </colgroup>
                      <EnhancedTableHead
                        mode={mode}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        values={modifiedDataProfile}
                      />

                      <TableBody>
                        {stableSort(
                          modifiedDataProfile,
                          getComparator(order, orderBy)
                        )?.map((each, index) => {
                          return (
                            <TableRow
                              hover
                              key={index}
                              tableindex={-1}
                              style={{
                                background: each?.statusPending
                                  ? "#FFF9E9"
                                  : each?.status === "INACTIVE"
                                  ? "#FFF4F2"
                                  : "#fff",
                              }}
                            >
                              <TableCell
                                style={{
                                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                              >
                                <Link
                                  to={{
                                    pathname: `${
                                      isSpv ? "/admin-spv" : "/admin"
                                    }/details-employee-external/${
                                      each?.employeeId
                                    }`,
                                    state: { data: each },
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Avatar
                                      src={
                                        each?.profilePicture
                                          ? each?.profilePicture
                                          : null
                                      }
                                      className={Styles.Avatar}
                                    />
                                    <Typography
                                      variant="subtitle2"
                                      noWrap
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      {each?.name
                                        ? each?.name?.toLowerCase()
                                        : "-"}
                                    </Typography>
                                  </Stack>
                                </Link>
                              </TableCell>
                              <TableCell>{each?.employeeId}</TableCell>
                              <TableCell>{each?.jobTitle}</TableCell>
                              <TableCell>
                                {each?.division || "no region"}
                              </TableCell>
                              <TableCell align="right">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Avatar
                                    src={
                                      each?.supervisor?.profilePicture
                                        ? each?.supervisor?.profilePicture
                                        : null
                                    }
                                    className={Styles.Avatar}
                                  />
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{ textTransform: "capitalize" }}
                                  >
                                    {each?.supervisor
                                      ? each?.supervisor?.name?.toLowerCase()
                                      : "-"}
                                  </Typography>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <CustomTablePagination
                  limit={limit}
                  setLimit={setLimit}
                  page={page}
                  setPage={setPage}
                  totalPage={totalPage}
                />
              </>
            )}
          </>
        )}
        {mode === "attendance" && (
          <>
            {loadingDataEmployee ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 100,
                }}
              >
                <div style={{ width: 200 }}>
                  <LoadingAnimation />
                </div>
              </div>
            ) : (
              <>
                <Scrollbar>
                  <TableContainer className={Styles.TableContainer}>
                    <Table id="emp-table">
                      <EnhancedTableHead
                        mode={mode}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        values={modifiedData}
                      />
                      <TableBody>
                        {stableSort(modifiedData, getComparator(order, orderBy))
                          ?.filter(
                            (el) =>
                              el.name
                                ?.toLowerCase()
                                .includes(nameAttendance?.toLowerCase()) &&
                              (persistRegion2
                                ? el?.division?.toLowerCase() ===
                                  persistRegion2?.toLowerCase()
                                : true) &&
                              (persistPosition2
                                ? el?.jobTitle?.toLowerCase() ===
                                  persistPosition2?.toLowerCase()
                                : true)
                          )
                          ?.slice(
                            (attendanceDataPage - 1) * rowsPerPageAttendance,
                            (attendanceDataPage - 1) * rowsPerPageAttendance +
                              rowsPerPageAttendance
                          )
                          ?.map((each, index) => {
                            return (
                              <TableRow hover key={index} tableindex={-1}>
                                <TableCell>
                                  <Link
                                    to={{
                                      pathname: `${
                                        isSpv ? "/admin-spv" : "/admin"
                                      }/employee-attendance-details/${
                                        each?.employeeId
                                      }`,
                                      state: {
                                        startDate: dateFrom,
                                        endDate: dateTo,
                                        summary: each,
                                        clientName: clientName,
                                      },
                                    }}
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Avatar
                                        src={
                                          each?.profilePicture
                                            ? each?.profilePicture
                                            : null
                                        }
                                        className={Styles.Avatar}
                                      />
                                      <Typography
                                        variant="subtitle2"
                                        noWrap
                                        sx={{ textTransform: "capitalize" }}
                                      >
                                        {each?.name
                                          ? each?.name?.toLowerCase()
                                          : "-"}
                                      </Typography>
                                    </Stack>
                                  </Link>
                                </TableCell>
                                <TableCell>{each?.jobTitle}</TableCell>
                                <TableCell>
                                  {each?.division || "no region"}
                                </TableCell>
                                <TableCell align="center">
                                  {each?.attend}
                                </TableCell>
                                <TableCell align="center">
                                  {each?.annualLeave}
                                </TableCell>
                                <TableCell align="center">
                                  {each?.sick}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <CustomTablePagination
                  limit={rowsPerPageAttendance}
                  setLimit={setRowsPerPageAttendance}
                  page={attendanceDataPage}
                  setPage={setAttendanceDataPage}
                  totalPage={Math.ceil(
                    dataEmployeeExternalAll?.filter((el) =>
                      el.name
                        ?.toLowerCase()
                        .includes(
                          nameAttendance?.toLowerCase() &&
                            (persistRegion2
                              ? el?.division?.toLowerCase() ===
                                persistRegion2?.toLowerCase()
                              : true) &&
                            (persistPosition2
                              ? el?.jobTitle?.toLowerCase() ===
                                persistPosition2?.toLowerCase()
                              : true)
                        )
                    ).length / rowsPerPageAttendance
                  )}
                />
              </>
            )}
          </>
        )}
        {/* {mode === "c2c" && (
          <>
            {c2cData === null ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 100,
                }}
              >
                <div style={{ width: 200 }}>
                  <LoadingAnimation />
                </div>
              </div>
            ) : (
              <div>
                <C2cTableExternal
                  setPage={setC2cPage}
                  page={c2cPage}
                  data={c2cData}
                />
              </div>
            )}
          </>
        )} */}
      </div>
    </div>
  );
}
