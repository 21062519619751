import React from "react";
import "../../../../src/assets/css/storePages.css";
import { Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import { BpjsExternalMenu } from '../../../components/External/Bpjs/'
import UnderMaintenance from "../../../components/_underMaintenancePage";
const BpjsExternal = () => {
  const isMaintenance = true;
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Bpjs</title>
      </Helmet>
      <div className="wrapperPageStore">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#000000", fontSize: 40 }}
        >
          BPJS
        </h2>
      </div>
      <Grid>
        <Grid item xs={12} md={12} lg={12}>
          { isMaintenance ? <UnderMaintenance /> : <BpjsExternalMenu />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default BpjsExternal;
