/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import {
  AppEditInformationSA,
} from "../../../../components/SuperAdmin/_information";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import { useHistory, useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchInformationById } from "../../../../redux/actions/SuperAdmin/informationActions";
import ModalDeleteInformation from "../../../../components/SuperAdmin/_information/InformationTable/DeleteInformation/ModalDeleteInformation";
export default function EditInformation() {
  const token = localStorage.getItem("accessToken");
  const location = useLocation()
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const information = useSelector((state) => location.state.data)
  const [selectedInformation, setSelectedInformation] = useState(null);

  const {informationById} = useSelector((state) => state.informations);

  useEffect(() => {
    if (selectedInformation) {
        document.body.style.overflow = 'hidden'
    } else {
        document.body.style.overflow = 'auto'
    }
}, [selectedInformation])

  useEffect(() => {
    dispatch(fetchInformationById(token, id));
  }, [dispatch, id, token]);

  // console.log(information, 'cek')
  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | {information?.isPublish ? 'Edit Information' : 'Draft'}</title>
        </Helmet>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Breadcrumb>
            <Button
              onClick={() => {
                if (localStorage.getItem("type") === "BUSINESS"){
                  history.push("/admin-business/information")
                } else if (localStorage.getItem("type") === "SUPER"){
                  history.push("/superadmin/information")
                }
              }}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Information</span>
            </Button>
            <span style={{ fontSize: 20, color: "#255bfc" }}>{information?.isPublish ? 'Edit Information' : 'Draft'}</span>
          </Breadcrumb>
          <div className="item-edit-delete-settings" style={{ display: 'flex', gap: 10 }}>
            <Button
              sx={{ "&:hover": { backgroundColor: 'transparent' }, padding: 0 }}
              onClick={(event) => {
                event.preventDefault();
                setSelectedInformation(information);
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 6,
                cursor: 'pointer',
                marginRight: 8
              }}
            >
              <span style={{
                fontSize: 14,
                color: "#D1421A",
                textTransform: 'capitalize'
              }}>Delete</span>
              <img src={DELETE_ICON} alt="delete" style={{ height: '17px', width: '15px' }} />
            </Button>

          </div>
        </div>

        {selectedInformation && (
          <ModalDeleteInformation
            active={selectedInformation}
            onOpen={setSelectedInformation}
            description="Are you sure you want to delete this information?"
            label="Delete Information"
            pathName={localStorage.getItem("type") === "BUSINESS" ? "/admin-business/information"  : "/superadmin/information"}
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppEditInformationSA information={informationById?.[0]} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
