import axios from "axios";
import { useEffect, useState } from "react";
import {API} from "../../../redux/api";

function useGroupData(clientCode, clientName) {
  const [groupData, setGroupData] = useState([]);
  const token = localStorage.getItem("accessToken");
  useEffect(() => {
    axios
      .get(`${API}/user/group-data?code=${clientCode}&clientName=${clientName}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => 
    //   console.log(res?.data?.data?.client, 'testtt')
      setGroupData(res.data)
      )
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return groupData;
}

export default useGroupData;
