/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ImageOffice from "../../../../../assets/images/Office.png";
import MapsComponent from "../../../../SuperAdmin/_attendance/attendance-detail-sa/google-maps-react/index";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { API_IMAGE } from "../../../../../redux/api";
// moment.locale("en-gb");

const useStyles = makeStyles((theme) => ({
  Modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 468,
    // height: 965,
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    border: "none",
    // boxShadow: 24,
    padding: 10,
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  ItemModalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .dummy": {
      display: "hidden",
      fontSize: 0,
    },
    "& .textAbsences": {
      color: "#404040",
      fontSize: 24,
      fontWeight: 500,
    },
    "& .icon": {
      backgroundColor: "transparent",
      border: "none",
      fontSize: 25,
      cursor: "pointer",
    },
  },
  ProfileInfo: {
    display: "flex",
    justifyContent: "space-between",
    "& .date": {
      display: "flex",
      flexDirection: "column",
      //   paddingTop: 15,
    },
    "& .labelDate": {
      color: "#616161",
      fontSize: 17,
      fontWeight: 500,
    },
    "& .textDate": {
      color: "#404040",
      fontSize: 20,
      fontWeight: 500,
    },
  },
  ClockInfo: {
    display: "flex",
    alignItems: "center",
    columnGap: 18,
    paddingTop: 24,
    "& .clockIn": {
      display: "flex",
      flexDirection: "column",
    },

    "& .lateTime": {
      display: "flex",
      flexDirection: "column",
    },
    "& .clockOut": {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
      // marginRight: 80
    },
    "& .label": {
      color: "#616161",
      fontSize: 17,
      fontWeight: 500,
    },
    "& .textClock": {
      color: "#404040",
      fontSize: 20,
      fontWeight: 500,
    },
    "& .labelLateTime": {
      color: "#D1421A",
      fontSize: 17,
      fontWeight: 500,
    },
    "& .textLateTime": {
      color: "#D1421A",
      fontSize: 20,
      fontWeight: 500,
    },
    "& .textHourLate": {
      color: "#D1421A",
      fontSize: 17,
      fontWeight: 500,
    },
  },
  PhotoInfo: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 24,
    rowGap: 24,
    "& .label": {
      color: "#404040",
      fontSize: 17,
      fontWeight: 500,
    },
    "& .image": {
      // height: 183,
      width: 224,
      display: "block",
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
  ReasonInfo: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 24,
    rowGap: 12,
  },
  MapInfo: {
    paddingTop: 24,
  },
  itemRow: {
    display: "flex",
    alignItems: "center",
    paddingTop: 12,
    paddingBottom: 12,
  },
}));
export default function DialogMaps({ show, setShow, dataAbsence }) {
  //   const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const totalLate = Math.floor(show.Late / 60);
  const handleClose = () => {
    setShow(false);
  };

  const newURI = useMemo(() => {
    return show?.images[0]?.uri?.replace(`https://myworkspace.id/api/image/`, `${API_IMAGE}`)
  },[show])

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Boolean(show)}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className={classes.ItemModalTop}>
            <h6 className="dummy">dummy</h6>
            <div className="textAbsences">
              <span className="textAbsences">Absences Details</span>
            </div>
            <button onClick={handleClose} className="icon">
              <CloseIcon
                style={{ fontSize: 30, marginTop: 10, paddingRight: 5 }}
              />
            </button>
          </div>
          <div>
            <hr style={{ width: "100%", color: "#ECE6E6" }} />
          </div>
        </DialogTitle>

        <DialogContent>
          <div className={classes.ProfileInfo}>
            <div className="date">
              <label className="labelDate">Date In</label>
              <span className="textDate">
                {/* { moment(new Date(show.startTime).getTime() - 7 * 60 * 60).format("LL")}  */}
                {moment(show.startTime).format("LL")}
              </span>
            </div>
            <div className="date">
              <label className="labelDate">Date Out</label>
              <span className="textDate">
                {moment(show.endTime).format("LL")}
              </span>
            </div>
          </div>
          <div className={classes.ClockInfo}>
          {show.isLate ? (
              <>
                <div className="clockIn">
                  <label className="label">Clock In</label>
                  <span className="textClock">
                  { moment(new Date(show.startTime).getTime()).format("LT")} 
                    {/* {moment(show?.startTime).format("LT")} */}
                  </span>
                </div>
                <ArrowForwardIcon />
                <div className="lateTime">
                  <label className="labelLateTime">Late Time</label>
                  <p className="textLateTime">
                    <span className="textHourLate">
                      {show?.accumulate?.latenessInMinute} Minute Late
                    </span>
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="clockIn">
                  <label className="label">Clock In</label>
                  <span className="textClock">
                  {/* { moment(new Date(show.startTime).getTime() - 7 * 60 * 60 * 1000).format("LT")}  */}
                  { moment(new Date(show.startTime).getTime()).format("LT")} 
                  </span>
                </div>
              </>
            )}
            {/* {show.isLate ? (
              <>
                <div className="clockIn">
                  <label className="label">Clock In</label>
                  <span className="textClock">
                    {moment(show.startTime).format("LT")}
                  </span>
                </div>
                <ArrowForwardIcon />
                <div className="lateTime">
                  <label className="labelLateTime">Late Time</label>
                  <p className="textLateTime">
                    <span className="textHourLate">
                      {show?.totalLate} Minute Late
                    </span>
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="clockIn">
                  <label className="label">Clock In</label>
                  <span className="textClock">
                    {moment(show?.startTime).format("LT")}
                  </span>
                </div>
              </>
            )} */}
            <div className="clockOut">
              <label className="label">Clock Out</label>
              <span className="textClock">
              { moment(new Date(show.endTime).getTime()).format("LT")} 
                {/* {show.DateTimeOut === "-" ? "-": moment(show.ateTimeOut).format("LT")} */}
              </span>
            </div>
          </div>

          <div className={classes.PhotoInfo}>
            <label className="label">Foto</label>
            <img
              src={show.images ? newURI : "https://via.placeholder.com/400"}
              className="image"
            />
          </div>
          {show.Alasan ? (
            <div className={classes.ReasonInfo}>
              <label className="labelReason">Alasan</label>
              <span className="textReason">
                {show.Alasan ? show.Alasan : "Tidak ada alasan"}
              </span>
            </div>
          ) : (
            <div></div>
          )}

          <div className={classes.MapInfo}>
            <label htmlFor="">Location</label>
            <div className={classes.itemRow}>
              <img src={ImageOffice} alt="" />
              <span>{show.Wfh ? "Work From Home" : "Work From Office"}</span>
            </div>
            <MapsComponent
              show={show}
              Lat={show?.location?.latitude}
              Long={show.location?.longitude}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
