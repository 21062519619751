import axios from "axios";
// import { API } from "../../redux/api";
import { API } from "../../redux/api";

export function login(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(`${API}/user/admin`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function resendOtp(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(`${API}/user/admin/resend/${payload}`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function verifyOtp(hash, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(
        `${API}/user/admin/verify/${hash}`,
        payload
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function verifyOtpMws(otp) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(`${API}/authentication/validate`, {
        code: otp,
        appName: "MyWorkSpace",
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
