import React, { useEffect } from "react";
import "./ClientSettings.css";
import {
  Grid,
  Container,
  Button,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { ClientSettingMenu } from "../../../components/SuperAdmin/_client";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getClientById } from "../../../redux/actions/SuperAdmin/adminActions";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
export default function ClientSettings() {
  const token = localStorage.getItem("accessToken");
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const client = useSelector((state) => state.client.clientById[id]);
  useEffect(() => {
    dispatch(getClientById(id, token));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Client Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb aria-label="breadcrumb">
            <Button
              onClick={() => history.push("/superadmin/client")}
              style={{ border: "none", textTransform: "none" }}
            >
              <span style={{ fontSize: 20 , color: '#255bfc'}}>Client</span>
            </Button>
            <span style={{ fontSize: 20 }}>{client?.Name}</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <ClientSettingMenu client={client} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
