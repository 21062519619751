import React from "react";
import { Helmet } from "react-helmet";
import { ReportSPGFMCG } from "../../../../components/FMCG";

export default function ReportSPG() {
  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Report</title>
      </Helmet>
      <ReportSPGFMCG />
    </div>
  );
}
