/* eslint-disable no-unused-vars */

import AddCircle from '@mui/icons-material/AddCircle';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from '@mui/utils';
import React, { useEffect, useMemo, useState } from 'react';
import Geocode from "react-geocode";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import EDIT_ICON from "../../../../assets/images/create.png";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import SEARCH_ICON from "../../../../assets/images/search-dark.png";
import SEARCH_ICON2 from "../../../../assets/images/search-light.png";
import SEARCH_ICON3 from "../../../../assets/images/search-primary.png";
import { fetchAllEmployees } from '../../../../redux/actions/SuperAdmin/employeesActions';
import { deleteUserFromLocation, getLocationById, setLocation } from '../../../../redux/actions/SuperAdmin/locationActions';
import MiniStaticMap from '../../../_mapWithAutoComplete/MiniMap/MiniMap';
import Scrollbar from '../../_user/TableUsers/Scrollbar';
import AddAttendeesModal from './AddAttendeesModal/AddAttendeesModal';
import Styles from './LocationDetail.module.css';
import DeleteAttendeeModal from './DeleteAttendeeModal/DeleteAttendeeModal';
import CustomTablePagination from '../../../_pagination/CustomTablePagination';


const TABLE_HEAD = [
    { id: "name", label: "Name", alignRight: false },
    { id: "employeeId", label: "NRK", alignRight: false }
]

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
        addMode
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                "& tr": {
                    backgroundColor: "#F0F0F0",
                },
            }}
        >
            <TableRow>
                {addMode && <TableCell></TableCell>}
                {TABLE_HEAD?.map((headCell, index) => (
                    <TableCell
                        key={headCell.id + index}
                        align="left"
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className={Styles.TableSortLabel}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


export default function LocationDetailSA({ clientName }) {
    const { schemaLocation } = useSelector((state) => state.locations)
    const location = schemaLocation
    const query = `clientCode=${location?.clientCode}`
    const [coordinate, setCoordinateAdd] = useState({});
    const dispatch = useDispatch();
    const token = localStorage.getItem('accessToken');
    const [address, setAddress] = useState({});
    const [mapAddresss, setMapAddress] = useState();
    const [showSearch, setShowSearch] = useState(false);
    const [showAddAttendees, setShowAddAttendees] = useState(false);
    const [searchMode, setSearchMode] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [searchAddInput, setSearchAddInput] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [addMode, setAddMode] = useState(false)
    const [selected, setSelected] = useState([]);
    // const [idToDelete, setIdToDelete] = useState(null);
    const [selectedAttendee, setSelectedAttendee] = useState(null);

    let filteredUser = location?.users?.filter((v,i,a)=>a.findLastIndex(v2=>(v2.employeeId === v.employeeId))===i)

    // const filteredArr = location?.users?.reduce((acc, current) => {
    //     const x = acc.find(item => item.employeeId === current.employeeId);
    //     if (!x) {
    //       return acc.concat([current]);
    //     } else {
    //       return acc;
    //     }
    //   }, []);


    //   console.log(filteredUser, '<<<<')

    const dataUsers = useMemo(() => {
        return filteredUser
    }, [filteredUser])


    useEffect(() => {
        dispatch(fetchAllEmployees(token, query))
    }, [token, query, dispatch])


    const [lat, setLat] = useState(null)
    const [lng, setLng] = useState(null)
    const [status, setStatus] = useState(null)

    const getLocation = () => {
        if (!navigator.geolocation) {
            setStatus("Geolocation is not supported by your browser");
        } else {
            setStatus('Locating...')
            navigator.geolocation.getCurrentPosition((position) => {
                setStatus(null)
                setLat(position.coords.latitude)
                setLng(position.coords.longitude)
            }, () => {
                setStatus('Unable to get your location')
            })
        }
    }


    useEffect(() => {
        Geocode.fromLatLng(location?.latitude, location?.longitude).then(
            (response) => {
                const address = response.results[0].formatted_address;
                setMapAddress(address);
            },
            (error) => {
                console.error(error);
            }
        );
        setCoordinateAdd({
            Latitude: parseFloat(location?.latitude),
            Longitude: parseFloat(location?.longitude)
        })
    }, [location?.latitude, location?.longitude, lat, lng])



    const handleCloseSearch = () => {
        setSearchMode(false)
        setSearchInput('')
    }

    const handleSubmitArray = (e) => {
        dispatch(setLocation(token, location?._id, { employeeId: selected }))
        setAddMode(false)
        // dispatch(getLocationById(token, location?._id))
    }

    const handleUnsetUser = (idToDelete) => {
        let body = {
            locationId: location?._id,
            employeeId: idToDelete
        }
        dispatch(deleteUserFromLocation(token, body))
    }

    // console.log(location, '<<<<')
    return (
        <div className={Styles.Container}>


            <div className={Styles.Wrapper}>
                <div className={Styles.BoxWrapper}>
                    <div className={Styles.BoxHeader}>
                        <span>Location Details</span>
                        <Link
                            to={{
                                pathname: `/superadmin/edit-location/${location._id}`,
                                state: { data: location, clientName: clientName }
                            }}
                        >
                            <img src={EDIT_ICON} alt="edit" />
                        </Link>
                    </div>
                    <div className={Styles.ContentWrapper}>
                        <div className={Styles.Top}>
                            <div className={Styles.BoxContent}>
                                <span>Location Name</span>
                                <span>{location?.name}</span>
                            </div>
                            <div className={Styles.BoxContent}>
                                <span>Client Name</span>
                                <span>{clientName || location?.clientCode}</span>
                            </div>
                            <div className={Styles.BoxContent}>
                                <span>Address</span>
                                <span>{location?.address}</span>
                            </div>
                            <div className={Styles.BoxContent}>
                                <span>Range</span>
                                <span>{location?.radiusInMeter} Metres</span>
                            </div>
                        </div>
                        <div className={Styles.MapWrapper}>
                            <div className={Styles.BoxContent}>
                                <span>Map Location</span>
                                <div className={Styles.MapContainer}>
                                    <MiniStaticMap coordinate={coordinate} setCoordinate={setCoordinateAdd} showSearch={showSearch} setShowSearch={setShowSearch} setAddress={setAddress} />
                                </div>
                            </div>
                        </div>
                        <div className={Styles.Bottom}>
                            <div className={`${Styles.BoxContent} ${Styles.MapDetails}`}>
                                <span>Address Details</span>
                                <span>{mapAddresss ? mapAddresss : location?.address} Metres</span>
                            </div>
                            <div className={Styles.CoordinatesWrapper}>
                                <div className={`${Styles.BoxContent} ${Styles.MapDetails}`}>
                                    <span>Latitude</span>
                                    <span>{location?.latitude}</span>
                                </div>
                                <div className={`${Styles.BoxContent} ${Styles.MapDetails}`}>
                                    <span>Longitude</span>
                                    <span>{location?.longitude}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={Styles.BoxWrapper}>
                    <div className={Styles.BoxHeader}>
                        <span>{addMode ? 'Add Attendee' : 'Attendees'}</span>
                        <div className={Styles.ActionButtonWrapper}>
                            {searchMode ? (
                                <div className={Styles.SearchMode}>
                                    <div className={Styles.SearchModeSearch}>
                                        <input type="text" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                                        <img src={SEARCH_ICON3} alt="search" />
                                    </div>
                                    <button onClick={handleCloseSearch}><Close sx={{ fontSize: 16 }} /></button>
                                </div>
                            ) : (
                                <>
                                    {
                                        addMode ? (
                                            <div className={Styles.AddMode}>
                                                <button onClick={handleSubmitArray}>Add</button>
                                                <div className={Styles.AddModeSearch}>
                                                    <input type="text" value={searchAddInput} onChange={(e) => setSearchAddInput(e.target.value)} />
                                                    <img src={SEARCH_ICON2} alt="search" />
                                                </div>
                                                <IconButton onClick={() => {
                                                    setAddMode(false)
                                                    setSelected([])
                                                }}><Close sx={{ fontSize: 16, padding: 0 }} /></IconButton>
                                            </div>
                                        ) : (
                                            <>
                                                {filteredUser?.length > 0 && (
                                                    <>
                                                        <button onClick={() => setSearchMode(true)}><img src={SEARCH_ICON} alt="search" /></button>
                                                        <button onClick={() => {
                                                            setEditMode(!editMode)
                                                            setSelected([])
                                                        }}><Edit sx={{ color: editMode ? "#1571DE" : "#000" }} /></button>
                                                    </>
                                                )}

                                                <button onClick={() => {
                                                    setAddMode(true)
                                                    setEditMode(false)
                                                }}><AddCircle sx={{ color: '#000' }} /></button>
                                            </>
                                        )
                                    }
                                </>

                            )}

                        </div>
                    </div>
                    {dataUsers?.length > 0 ? (
                        <AttendeesTable setSelectedAttendee={setSelectedAttendee} handleUnsetUser={handleUnsetUser} searchAddInput={searchAddInput} selected={selected} setSelected={setSelected} token={token} clientCode={location?.clientCode} dispatch={dispatch} attendees={dataUsers} searchInput={searchInput} editMode={editMode} addMode={addMode} />
                    ) : (
                        <div >
                            {addMode ? (
                                <AttendeesTable setSelectedAttendee={setSelectedAttendee} handleUnsetUser={handleUnsetUser} searchAddInput={searchAddInput} selected={selected} setSelected={setSelected} token={token} clientCode={location?.clientCode} dispatch={dispatch} attendees={dataUsers} searchInput={searchInput} editMode={editMode} addMode={addMode} />

                            ) : (
                                <div className={Styles.EmptyBox}>
                                    <span>no attendees has been assigned</span>
                                </div>
                            )}
                        </div>
                    )}


                </div>
            </div>


            {
                showAddAttendees && (
                    <AddAttendeesModal attendees={filteredUser} clientCode={location?.clientCode} showAddAttendees={showAddAttendees} setShowAddAttendees={setShowAddAttendees} locationId={location?._id} />
                )
            }
            {
                selectedAttendee && (
                    <DeleteAttendeeModal
                        active={selectedAttendee}
                        onOpen={setSelectedAttendee}
                        locationId={location?._id}
                        description="Are you sure you want to delete this attendee?"
                        label="Delete Attendee Data"
                        pathName={`/detail-location/${location?._id}`}
                    />
                )
            }

        </div>
    )
}

const AttendeesTable = ({
    attendees,
    searchInput,
    editMode,
    addMode,
    clientCode,
    token,
    dispatch,
    selected,
    setSelected,
    searchAddInput,
    handleUnsetUser,
    setSelectedAttendee
}) => {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [listDataContracts, setListDataContracts] = useState("");
    // const [searchTerm, setSearchTerm] = useState("");
    const { employees } = useSelector((state) => state.employees)
    // const [fixedEmployees, setFixedEmployees] = useState(fixedEmployee)
    // const [checkedAdd, setCheckedAdd] = useState()


    const fixedEmployee = useMemo(() => {
        return employees?.filter(el => {
            return !attendees?.find(element => {
                return element?.employeeId === el?.employeeId;
            })
        })
    }, [employees, attendees])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (employeeName) => selected?.indexOf(employeeName) !== -1;


    const handleClick = (event, employeeId) => {
        const selectedIndex = selected?.indexOf(employeeId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, employeeId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };



    return (
        <div className={Styles.TableWrapper}>
            <Scrollbar>
                <TableContainer className={Styles.TableContainer}>
                    <Table id="emp-table" className={Styles.Table}>
                        {addMode && (
                            <colgroup>
                                <col style={{ width: '5%' }} />
                                <col style={{ width: '50%' }} />
                                <col style={{ width: '45%' }} />
                            </colgroup>
                        )}
                        <EnhancedTableHead
                            numSelected={attendees?.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            values={attendees}
                            addMode={addMode}
                        />
                        {
                            addMode ? (
                                <TableBody>
                                    {
                                        stableSort(fixedEmployee, getComparator(order, orderBy))
                                            .filter((el) => el.name?.toLowerCase().includes(searchAddInput.toLowerCase()) ||
                                                el.employeeId?.toLowerCase().includes(searchAddInput.toLowerCase())
                                            )
                                            .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                                            .map((each, i) => {
                                                const isItemSelected = isSelected(each?.employeeId);
                                                return (
                                                    <TableRow hover key={'tablebody' + i} tabIndex={-1} className={Styles.TableRow}>
                                                        <TableCell component="th" scope='row' className={Styles.TableCell}>
                                                            <Checkbox
                                                                checked={isItemSelected}
                                                                onClick={(e) => handleClick(e, each.employeeId)}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={Styles.TableCell}>
                                                            {each.name}
                                                        </TableCell>
                                                        <TableCell className={Styles.TableCell}>
                                                            {each.employeeId}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {
                                        stableSort(attendees, getComparator(order, orderBy))
                                            .filter((el) => el.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
                                                el.employeeId?.toLowerCase().includes(searchInput.toLowerCase())
                                            )
                                            .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                                            .map((each, i) => {
                                                return (
                                                    <TableRow hover key={'tablebody' + i} tabIndex={-1} className={Styles.TableRow}>
                                                        <TableCell component="th" scope='row' className={Styles.TableCell}>
                                                            {each.name}
                                                        </TableCell>
                                                        <TableCell className={`${Styles.TableCell} ${Styles.DeleteCell}`}>
                                                            <span>{each.employeeId}</span>
                                                            {editMode && (
                                                                <img onClick={() => setSelectedAttendee(each)} src={DELETE_ICON} alt="delete" className={Styles.DeleteIcon} />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                    }
                                </TableBody>
                            )
                        }


                    </Table>
                </TableContainer>
            </Scrollbar>
            <CustomTablePagination
                limit={rowsPerPage}
                setLimit={setRowsPerPage}
                page={page}
                setPage={setPage}
                totalPage={
                    Math.ceil(!addMode ? attendees?.filter((el) => el.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
                        el.employeeId?.toLowerCase().includes(searchInput.toLowerCase())
                    ).length / rowsPerPage : fixedEmployee?.filter((el) => el.name?.toLowerCase().includes(searchAddInput.toLowerCase()) ||
                        el.employeeId?.toLowerCase().includes(searchAddInput.toLowerCase())
                    ).length / rowsPerPage)
                }
            />
        </div>
    )
}