/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./storeWithDownload.css";
import {
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  Paper,
  TableHead,
  AvatarGroup,
} from "@mui/material";
import Modal from "../../../modals/Modal";
// import logo_table from "../../../assets/images/items_table.png";
// import employee_icon from "../../../assets/images/employee-image.png";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import rows from "../../../_dashboard/appNewCustBottom/dataDummy";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import { useHistory, useRouteMatch } from "react-router";
import ModalDeleteStore from "../ModalDeleteStore/ModalDeleteStore";

// import NestedMenuItem from "material-ui-nested-menu-item";
// import { spacing } from "@mui/system";

function StoreWithDownload() {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState("");
  const [filterName, setFilterName] = useState("");
  const [filterBM, setFilterBM] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fileData, setFileData] = useState([])
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteStore, setDeleteStore] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="storeDownload">
      <div className="wrapper__store-download">
        <div className="wrapper__left">
          <SearchbarSA />
        </div>
        <div className="wrapper__right">
          <Button
            onClick={() => {
              setModalOpen(true);
            }}
            endIcon={
              <i className="bx bxs-printer" style={{ fontSize: 14 }}></i>
            }
            className="button__right-store "
          >
            <span className="text__download-right">Upload</span>
          </Button>
          {modalOpen && <Modal setOpenModal={setModalOpen} setFileData={setFileData}/>}
        </div>
        <div className="wrapper__right">
          <Button
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            className="button__right-store"
          >
            <span className="text__download-right">Export</span>
          </Button>
          <Menu
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            elevation={1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem>Export as Pdf</MenuItem>
            <MenuItem>Export as Xls</MenuItem>
          </Menu>
        </div>
        <div className="button__add-store">
          <Link to="/admin/add-store-external">
            <Button startIcon={<AddIcon />} style={{ color: 'white', textTransform: 'none'}}>
              Add Store
            </Button>
          </Link>
        </div>
      </div>
      <Paper
        className="container__table-customer-external"
        style={{ backgroundColor: "#FAFAFA" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Coordinate Point</TableCell>
              <TableCell>Store Name</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Employee</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(({ id }) => (
              <TableRow key={id}>
                <TableCell align="left">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar variant="square" sx={{ bgColor: "grey" }} />
                    <Typography variant="subtitle2" noWrap>
                      1.11.3345.133
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>UD Sinar Jaya</TableCell>
                <TableCell>Anastasia Prissia</TableCell>
                <TableCell>0812-8828-8282</TableCell>
                <TableCell>
                  <Stack alignItems="center" direction="row">
                    <AvatarGroup total={10}>
                      {/* {render employee photo here} */}
                      <Avatar />
                      <Avatar />
                      <Avatar />
                      <Avatar />
                    </AvatarGroup>
                  </Stack>
                </TableCell>
                <TableCell alignitems="right">
                  <Button
                    style={{
                      textTransform: "none",
                      color: "#1571DE",
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                    onClick={() => history.push('/admin/edit-store-external')}
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    style={{
                      textTransform: "none",
                      color: "#D1421A",
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                    onClick={() => setDeleteStore(true)}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {deleteStore && ( <ModalDeleteStore deleteStore={deleteStore} setDeleteStore={setDeleteStore } pathName="/admin-store-external" />)}
    </div>
  );
}

export default StoreWithDownload;
