import {
  Avatar,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import AddIcon from "@mui/icons-material/Add";
import "./index.css";
// import { useHistory } from "react-router";
// import moment from "moment";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import UserListHead from "../../../SuperAdmin/_user/TableUsers/UserListHead";
import USERLIST from "../../../../components/SuperAdmin/_user/TableUsers/_mocks/user";
import PDF_IMAGE from "../../../../assets/images/PDF.png";
import ModalInformation from "../ModalInformation/ModalInformation";

const TABLE_HEAD = [
  { id: "Promo Category", label: "Promo Category", alignRight: false },
  { id: "PromoType", label: "PromoType", alignRight: false },
  { id: "Product", label: "Product", alignRight: false },
  { id: "Title", label: "Title", alignRight: false },
  { id: "Period", label: "Period", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const Promo = () => {
//   const history = useHistory();
  // const dispatch = useDispatch();
  // const token = localStorage.getItem("accessToken");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState([]);
//   const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");
//   const startDate = moment().format("LL");
//   const endDate = moment().format("LL");
//   const [valueDate, setValueDate] = useState({});
//   const [valueStartDate, setValueStartDate] = useState(startDate);
//   const [valueEndDate, setValueEndDate] = useState(endDate);
  const [listDataPromo, setListDataPromo] = useState("");
  const [isModalOpen ,setIsModalOpen] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setListDataPromo(event.target.value);
  };
  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchTerm(listDataPromo);
  };
  return (
    <div className="container-promo">
      <div className="wrapper-items-promo">
        <div className="items-top-promo">
          <div className="item-promo-left">
            <SearchbarSA
              value={listDataPromo}
              onChange={handleSearch}
              onSubmit={handleSubmitFilter}
            />
          </div>
          <div className="item-promo-right">
            <Button startIcon={<AddIcon sx={{ color: "#FFFFFF" }} />}>
              Add Competitor
            </Button>
          </div>
        </div>
        <div className="container-table-promo">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 600, backgroundColor: "#fafafa" }}>
              <Table
                id="emp-table"
                sx={{
                  width: "100%",
                  maxHeight: "100%",
                  backgroundColor: "#fafafa",
                  borderRadius: 4,
                }}
              >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  // rowCount={dataEmployee.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(USERLIST, getComparator(order, orderBy))
                    //   .filter(
                    //     (el) =>
                    //       el.Name?.toLowerCase().includes(
                    //         listDataCustomer?.toLowerCase()
                    //       ) ||
                    //       el.Position?.toLowerCase().includes(
                    //         listDataCustomer?.toLocaleLowerCase()
                    //       )
                    //   )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((information, ind) => {
                      const labelId = `enhanced-table-checkbox-${ind}`;
                      return (
                        <TableRow hover key={ind} tabIndex={-1}>
                          <TableCell></TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            id={labelId}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar variant="rounded" src={PDF_IMAGE}/>
                                {/* <InsertDriveFileIcon sx={{ color: 'red'}}/> */}
                              {/* </Avatar> */}
                              <Typography noWrap>Beli 2 Gratis 1</Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>Food Promo</TableCell>
                          <TableCell>Indomie Karie Ayam</TableCell>
                          <TableCell>Promo Tahun Baru</TableCell>
                          <TableCell>21 December 2022</TableCell>
                          <TableCell align="right">
                            <Button
                              sx={{ textTransform: "none", fontSize: "1rem" }}
                                onClick={() => setIsModalOpen(true)}
                            //   onClick={() =>
                            //     history.push({
                            //       pathname: `/admin/information-detail/`,
                            //     })
                            //   }
                            >
                              See Detail
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          { isModalOpen && (
              <ModalInformation isModalOpen={isModalOpen} setIsModalOpen={ setIsModalOpen }/>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 35, 45]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Promo;
