/* eslint-disable no-unused-vars */
import { AddCircleOutline } from '@mui/icons-material';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import fetchClientCode from '../../../../async/client/fetchClientCode';
import LoadingAnimation from '../../../utils/LoadingAnimation/LoadingAnimation';
import SearchbarSA from '../../../utils/SearchbarSA/SearchbarSA';
import CustomTablePagination from '../../../_pagination/CustomTablePagination';
import Scrollbar from "../../_user/TableUsers/Scrollbar";
import DeleteLocationModal from './DeleteLocationModal/DeleteLocationModal';
import Styles from './LocationTable.module.css';


const TABLE_HEAD = [
    { id: 'clientCode', label: 'Client Name', alignRight: false },
    { id: 'name', label: 'Location Name', alignRight: false },
    { id: 'address', label: 'Address', alignRight: false },
    { id: 'radiusInMeter', label: 'Range', alignRight: false },
    { id: 'latitude', label: 'Map', alignRight: false },
]

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
        deleteMode
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                "& tr": {
                    backgroundColor: "#F0F0F0",
                },
            }}
        >
            <TableRow>

                {TABLE_HEAD.map((headCell, index) => (
                    <TableCell
                        key={headCell.id + index}
                        align="left"
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className={Styles.TableSortLabel}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {deleteMode && (
                    <TableCell></TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}


export default function LocationTable({ locations, isLoading }) {
    const history = useHistory()
    const dispatch = useDispatch()
    const [dataClientCode, setDataClientCode] = useState([]);
    const token = localStorage.getItem("accessToken");
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("clientCode");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [listDataLocations, setListDataLocations] = useState("");
    const [deleteMode, setDeleteMode] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSearch = (event) => {
        setListDataLocations(event.target.value);
    };



    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const selectedClient = (clientCode) => {
        let client;
        if (clientCode !== '') {
            client = dataClientCode.find((u) => u.codes === clientCode)
            return client?.name
        } else {
            return null
        }
    }

    const capitalizedClientName = (clientName) => {
        return clientName?.toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
            .replace('Pt', 'PT')
            .replace('Cv', 'CV')
    }


    useEffect(() => {
        (async () => {
            try {
                const { response } = await fetchClientCode(token);
                setDataClientCode(response);
            } catch (error) {
                console.log(error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDelete = (id) => {

    }


    return (
        <div className={Styles.Container}>
            <div className={Styles.HeaderWrapper}>
                <div className={Styles.Searchbar}>
                    <SearchbarSA
                        value={listDataLocations}
                        onChange={handleSearch}
                        searchWidth="379px"
                        placeholder="Search by address or client name"
                    />
                </div>
                <div className={Styles.LocationButtonWrapper}>
                    <Button onClick={() => setDeleteMode(!deleteMode)} variant="outlined" className={Styles.EditButton}>{!deleteMode ? 'Edit' : 'Exit Edit Mode'}</Button>
                    <Button onClick={() => history.push('/superadmin/add-location')} startIcon={<AddCircleOutline />} variant="outlined" className={Styles.AddButton}>Add Location Data</Button>
                </div>
            </div>
            {
                isLoading ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 50,
                        }}
                    >
                        <div style={{ width: 200 }}>
                            <LoadingAnimation />
                        </div>
                    </div>
                ) : (
                    <div className={Styles.TableWrapper}>
                        <Scrollbar>
                            <TableContainer className={Styles.TableContainer}>
                                <Table id="emp-table" className={Styles.Table}>
                                    <colgroup>
                                        <col style={{ width: '25%' }} />
                                        <col style={{ width: '25%' }} />
                                        <col style={{ width: '30%' }} />
                                        <col style={{ width: '10%' }} />
                                        <col style={{ width: '10%' }} />
                                        {deleteMode && <col style={{ width: '5%' }} />}
                                    </colgroup>
                                    <EnhancedTableHead
                                        numSelected={locations.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        values={locations}
                                        deleteMode={deleteMode}
                                    />
                                    <TableBody>
                                        {stableSort(locations, getComparator(order, orderBy))
                                            .filter((el) =>
                                                el.address?.toLowerCase().includes(listDataLocations.toLowerCase())
                                                ||
                                                el.name?.toLowerCase().includes(listDataLocations.toLowerCase())
                                                ||
                                                selectedClient(el?.clientCode).toLowerCase().includes(listDataLocations.toLowerCase()))
                                            .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                                            .map((each, i) => {
                                                const clientName = selectedClient(each?.clientCode)
                                                return (
                                                    <TableRow hover key={'tablebody' + i} tabIndex={-1}>
                                                        <TableCell component="th" scope="row" sx={{ textTransform: "capitalize" }} className={Styles.TableCell}>
                                                            <Link
                                                                to={{
                                                                    pathname: `/superadmin/detail-location/${each._id}`,
                                                                    state: { data: each, clientName }
                                                                }}
                                                            >
                                                                {clientName !== null
                                                                    ? capitalizedClientName(clientName)
                                                                    : "Loading client name..."}
                                                            </Link>

                                                        </TableCell>
                                                        <TableCell align="left" className={Styles.TableCell}>
                                                            <Link
                                                                to={{
                                                                    pathname: `/superadmin/detail-location/${each._id}`,
                                                                    state: { data: each, clientName }
                                                                }}
                                                            >
                                                                <span>{each.name ? each.name : "-"}</span>
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell align="left" className={`${Styles.TableCell} ${Styles.Address}`}>
                                                            <span>{each.address ? each.address : "-"}</span>
                                                        </TableCell>
                                                        <TableCell align="left" className={Styles.TableCell}>
                                                            {each.radiusInMeter ? each.radiusInMeter : '-'} m
                                                        </TableCell>
                                                        <TableCell align="left" className={Styles.PinLabel}>
                                                            <span>
                                                                {each.latitude ? 'Pinned' : "-"}
                                                            </span>
                                                        </TableCell>
                                                        {deleteMode && (
                                                            <TableCell className={Styles.DeleteCell}>
                                                                <button onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setSelectedLocation(each)
                                                                }}>
                                                                    <img src={DELETE_ICON} alt="delete" />
                                                                </button>
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                        <CustomTablePagination
                            limit={rowsPerPage}
                            setLimit={setRowsPerPage}
                            page={page}
                            setPage={setPage}
                            totalPage={Math.ceil(locations.filter((el) =>
                                el.address?.toLowerCase().includes(listDataLocations.toLowerCase())
                                ||
                                el.name?.toLowerCase().includes(listDataLocations.toLowerCase())
                                ||
                                selectedClient(el?.clientCode).toLowerCase().includes(listDataLocations.toLowerCase())).length / rowsPerPage)}
                        />

                    </div>
                )
            }

            {selectedLocation && (
                <DeleteLocationModal
                    active={selectedLocation}
                    onOpen={setSelectedLocation}
                    description="Are you sure you want to delete this location?"
                    label="Delete Location Data"
                    pathName="/superadmin/database"
                />
            )}
        </div >
    )
}