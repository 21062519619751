import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export const SidebarData = [
  {
    display_name: "Dashboard",
    route: "",
    icon: "bx bxs-dashboard",
  },
  {
    display_name: "CLIENT SETTINGS",
    route: "/client",
    iconClosed: <ExpandMoreIcon />,
    iconOpened: <ExpandLessIcon />,

    subNav: [
      {
        display_name: "Client Settings",
        route: "/client",
        icon: "bx bxs-store",
      },
      {
        display_name: "User Settings",
        route: "/user",
        icon: "bx bx-user",
      },
      {
        display_name: "Loan Schema",
        route: "/loan",
        icon: "bx bxs-offer",
      },
      {
        display_name: "Attendance Schema",
        route: "/attendance",
        icon: "bx bx-notepad",
      },
      {
        display_name: "Leave Schema",
        route: "/leave",
        icon: "bx bxs-calendar-star",
      },
    ],
  },
  {
    display_name: "Master Data",
    route: "/database",
    icon: "bx bxs-folder-open",
  },
  {
    display_name: "Activity",
    route: "/activity",
    icon: "bx bxs-photo-album",
  },
  {
    display_name: "Information",
    route: "/information",
    icon: "bx bx-info-circle",
  },
  {
    display_name: "Induction",
    route: "/induction",
    icon: "bx bxs-layer",
  },
  {
    display_name: "FAQ",
    route: "/faq",
    icon: "bx bx-help-circle",
  },
  // {
  //   display_name: "Holiday Settings",
  //   route: "/holiday-settings",
  //   icon: "bx bxs-calendar-edit",
  // },
  {
    display_name: "Work On",
    route: "/work-on",
    icon: "bx bxs-time",
  },
  {
    display_name: "VIEW DATA",
    route: "/attendance-data",
    iconClosed: <ExpandMoreIcon />,
    iconOpened: <ExpandLessIcon />,

    subNav: [
      {
        display_name: "Attendance",
        route: "/attendance-data",
        icon: "bx bx-notepad",
      },
      {
        display_name: "Employee",
        route: "/employee",
        icon: "bx bxs-user-account",
      },
      {
        display_name: "Pay Slip",
        route: "/payslip",
        icon: "bx bx-calendar-event",
      },
      {
        display_name: "EBP",
        route: "/ebp",
        icon: "bx bxs-donate-heart",
      },
      {
        display_name: "BPJS",
        route: "/bpjs",
        icon: "bx bxs-credit-card",
      },
    ],
  },
];
