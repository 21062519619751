import React from "react";
import "./ModalSuccessEmployee.css";
import { Button } from "@mui/material";
import success_added from "../../../../../assets/images/success-added.png";

export default function ModalSuccessEmployee({ setAddEmployee }) {
  return (
    <div className="modal-MSE">
      <div className="modalContainer-modal-tabs-delete-client">
        <div className="titleCloseBtn-ePay">
          <div className="items__fill">dummy</div>
          <div className="text__main-ePay">
            {/* <span>Delete Client</span> */}
          </div>
          <div className="item__button-right">
            <button
              onClick={() => {
                setAddEmployee(false);
              }}
            >
              {/* <CloseIcon style={{ fontSize: 30 }} /> */}
            </button>
          </div>
        </div>
        <div>{/* <hr style={{ width: "100%", color: "#ECE6E6" }} /> */}</div>
        <div className="body-attendance-modal">
          <div className="img-item-main">
            <img src={success_added} alt="" />
          </div>
          <div className="text-main-modal-tabs-settings">
            <span style={{ fontSize: 20, fontWeight: 500, color: "#0a0a0a" }}>
              Staff Assigned
            </span>
          </div>
          <div className="text-main-modal-tabs-settings">
            <span style={{ fontSize: 12, fontWeight: 400, color: "#757575" }}>
              You Have Added Employee to the list
            </span>
          </div>
          <div className="button-wrapper-modal-tabs-settings">
            <div className="button-delete-modal-tabs-settings">
              <Button
                style={{ backgroundColor: "#1571de" }}
                onClick={() => {
                  setAddEmployee(false);
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
