import React from "react";
import { Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import AttendanceReport from "../AttendanceSA/AttendanceData/AttendaceReportSA/AttendanceReport";

export default function AttendanceSAReport() {
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Attendance Report</title>
      </Helmet>

      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <AttendanceReport />
        </Grid>
      </Grid>
    </Container>
  );
}
