import React, { useState } from "react";
import Styles from "./DetailPublished.module.css";
import { Switch } from "@mui/material";
// import COMPANY_ICON from "../../../../../../assets/images/client-blue-induction.png";
// import FILE_ICON from "../../../../assets/images/mask-group.png";
// import DUMMIES_IMAGE from "../../../../assets/images/office-image-induction.png";
// import ModalChangeStatus from "../ModalChangeStatus/ModalChangeStatus";
import moment from "moment";
import ReactPlayer from "react-player";
import { API_IMAGE } from "../../../../../../redux/api";
import ModalChangeStatusFaq from "../ModalChangeStatusFAQ/ModalChangeStatusFaq";
// import fetchClientCode from "../../../../../async/client/fetchClientCode";

let widthPlayer = "100%";

export default function DetailPublishedFAQ(props) {
  const { data, id } = props;
  // const isVideo = true;
  console.log(data, 'detail')
  const token = localStorage.getItem("accessToken");
  const [value, setValue] = useState({
    isPublish: true,
  });
  const [checkedStatus, setCheckedStatus] = useState(Boolean);
  const [isModalOpenStatus, setIsModalOpenStatus] = useState(false);
  // const [dataClientCodes, setDataClientCodes] = useState([]);

  // const refinedClientName = useMemo(() => {
  //   return data?.clientCode?.map((el) => el.name)
  // },[data])

  // const fixedClients = useMemo(() => {
  //   let pre = [{ codes: "ALL", name: "ALL" }, { codes: 'Tes', name: 'Client Tester' }]
  //   return pre.concat(dataClientCodes);
  // }, [dataClientCodes])

  // const dataClients = useMemo(() => {
  //   return fixedClients.filter(el => {
  //     return data?.clientCode?.find(element => {
  //       return element === el?.codes
  //     })
  //   })
  // }, [data, fixedClients])

  // useEffect(() => {
  //   setValue({
  //     isPublish: data?.isPublish,
  //   });
  // }, [data]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const { response } = await fetchClientCode(token);
  //       setDataClientCodes(response);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();

  // }, []);

  const handleChangeSwitch = (event) => {
    setIsModalOpenStatus(true);
    setCheckedStatus(event.target.checked);
  };

  // const handlePublish = (e) => {
  //   setChangePublish(true)
  //   setActive(data)
  // }

  return (
    <div className={Styles.Container}>
      <div className={Styles.CardForm}>
        <div className={Styles.ValueControl}>
          <div className={Styles.StatusControl}>
            <div className={Styles.Desc}>
              <span>Publish Status</span>
              <span>Change Publish Status</span>
            </div>
            <Switch
              checked={data?.isPublish || false}
              size="large"
              onChange={handleChangeSwitch}
            />
          </div>

          {/* <div className={Styles.CompanyControl}>
              {dataClients?.map((each, idx) => (
                <div className={Styles.CompanyValue} key={idx}>
                  <img src={COMPANY_ICON} alt="icon-client" />
                  <span>{each?.name}</span>
                </div>
              ))}
            </div> */}


          {/* {data?.clientCode === "ALL" ? (
            <div className={Styles.CompanyControl}>
            <div className={Styles.CompanyValue}>
              <img src={COMPANY_ICON} alt="icon-client" />
              <span>{data?.clientCode}</span>
            </div>
            </div>
          ) : (
            <div className={Styles.CompanyControl}>
              {data?.clientCode?.map((each, idx) => (
                <div className={Styles.CompanyValue} key={idx}>
                  <img src={COMPANY_ICON} alt="icon-client" />
                  <span>{each?.name}</span>
                </div>
              ))}
            </div>
          )} */}

          <div className={Styles.VideoControl}>
            {data?.url ? (
              <div className={Styles.Player}>
                <ReactPlayer
                  url={data?.url}
                  controls={true}
                  playing={true}
                  width={widthPlayer}
                  style={{ borderRadius: 8 }}
                />
              </div>
            ) : (
              <div className={Styles.Content}>
                <img src={ data?.thumbnail?.uri?.replace(`https://myworkspace.id/api/image/`, `${API_IMAGE}`)  }  alt="file-icon" />
              </div>
            )}

            <div className={Styles.DescPlayer}>
              <div className={Styles.Heading}>
                <span>{data?.title}</span>
                <span>
                  {data?.createdAt
                    ? moment(new Date(data?.createdAt)).format("D MMMM YYYY")
                    : data?.createdAt}
                </span>
              </div>
              {/* <div
                className={Styles.ContentControl}
                dangerouslySetInnerHTML={{ __html: data?.content }}
              /> */}
              {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p> */}
            </div>
          </div>
        </div>
      </div>

      {isModalOpenStatus && (
        <ModalChangeStatusFaq
          token={token}
          id={id}
          checked={checkedStatus}
          value={value}
          setValue={setValue}
          active={isModalOpenStatus}
          onOpen={setIsModalOpenStatus}
          description="Are you sure you want to change the status to unpublish?"
          label="Change Status"
        />
      )}
    </div>
  );
}


// export default function DetailPublished({ faqById = {} }) {
//   return (
//     <div className="container-DP">
//       <div className="wrapper-DP">
//         <div className="wrapper-text-DP">
//           <span>{faqById?.Title}</span>
//           <label>{faqById?.FAQTime}</label>
//         </div>
//         <div className="input-DP">
//             <span>
//               {faqById?.Content}
//             </span>
//         </div>
//       </div>
//     </div>
//   );
// }
