import React from "react"
import { Helmet } from "react-helmet";
import SnackbarComponentLogin from "../../components/utils/Snackbar/SnackbarComponentLogin";
import { LoginComponent } from "../../components/_auth/index";
export default function Login() {
  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Sign in</title>
      </Helmet>
      <SnackbarComponentLogin />
      <LoginComponent />
    </div>
  );
}
