import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import dummy_image from "../../../../assets/images/dummy-person.png";
import permata_image from "../../../../assets/images/logo-permata.png";
import CheckedImage from "../../../../assets/images/check_circle_blue.png";
// import CheckedImage from "../../../../../../assets/images/check_circle_blue.png";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Helmet } from "react-helmet";
import { fetchPaymentLoanById, fetchSchemaLoanByIdUser } from "../../../../redux/actions/SuperAdmin/loanActions";

const Styles = StyleSheet.create({
  Body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  Heading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 10,
    borderBottom: "1px solid #C2C2C2",
  },
  ItemLeft: {
    flex: 1,
    display: "flex",
  },
  Control: {
    display: "flex",
    flexDirection: "row",
  },
  Image: {
    width: 35,
    height: 35,
  },
  DataDesc: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
  },
  TextLabel: {
    color: "#0a0a0a",
    fontSize: 12,
    fontWeight: 500,
    paddingBottom: 4,
  },
  TextSpan: {
    color: "#757575",
    fontSize: 12,
    fontWeight: 500,
    paddingBottom: 4,
  },
  TextSubSpan: {
    color: "#404040",
    fontSize: 8,
    fontWeight: 500,
    paddingBottom: 4,
  },
  ItemRight: {
    flex: 1,
    alignItems: "flex-end",
  },
  BoxStatus: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    width: 50,
    height: 25,
    backgroundColor: "#498e0f",
    borderRadius: 50,
  },
  TextStatus: {
    color: "#fafafa",
    fontSize: 10,
  },
  Main: {
    paddingTop: 20,
  },
  Container: {
    paddingTop: 20,
    backgroundColor: "#fafafa",
    borderRadius: 8,
    padding: 10,
    marginTop: 10,

    // borderBottom: '1px solid #C2C2C2',
  },
  InfoLeft: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  InfoRight: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  Divider: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #C2C2C2",
    paddingBottom: 10,
  },
  SubMain: {
    display: "flex",
    justifyContent: "space-between",
  },
  SubMainContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
  },
  WrapperText: {
    display: "flex",
    flexDirection: "column",
  },
  LabelSub: {
    color: "#404040",
    fontSize: 10,
    fontWeight: 500,
    paddingBottom: 4,
  },
  SpanSub: {
    color: "#0a0a0a",
    fontSize: 12,
    fontWeight: 500,
    paddingBottom: 4,
  },
  Footer: {
    paddingTop: 20,
    width: "100%",
  },
  ItemsFooter: {
    paddingBottom: 5,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    // justifyContent: "space-between",
    width: "100%",
  },
  ItemFooter: {
    width: 100,
    backgroundColor: "#fafafa",
    borderRadius: 8,
    padding: 10,
    display: "flex",
    marginTop: 10
  },
  TextTotal: {
    color: "#0a0a0a",
    fontSize: 8,
    fontWeight: 500,
  },
  WrapperStatus: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 5,
  },
  Icon: {
    width: 10,
    height: 10,
  },
  Text: {
    margin: 12,
    fontSize: 14,
    // textAlign: "justify",
  },
});

const PDFDataLoanExternal = () => {
  const token  = localStorage.getItem('accessToken');
  const { id } = useParams();
  const dataLoan = useSelector((state) => state.loan.loanDataByUserId);
  const paymentData = useSelector((state) => state.loan.paymentLoanFromIos);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchSchemaLoanByIdUser(token,id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    dispatch(fetchPaymentLoanById(token,id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    dataLoan && paymentData ? (
    <PDFViewer style={{ width: window.innerWidth, height: "100vh" }}>
      <DataToView dataLoan={ dataLoan } payment={ paymentData } />
    </PDFViewer>
    ) : null
  );
};

const DataToView = ({ datas, dataLoan, payment  }) => {
  function numberWithPoint(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return (
    <Document>
       <Helmet>
        <title>MyWorkSpace | Loan Report</title>
      </Helmet>
      <Page style={Styles.Body} size="A4">
        <View style={Styles.Heading}>
          <View style={Styles.ItemLeft}>
            <View style={Styles.Control}>
              <Image src={ dataLoan?.ProfilePicture ? dataLoan?.ProfilePicture : dummy_image } style={Styles.Image} />
              <View style={Styles.DataDesc}>
                <Text style={Styles.TextLabel}>{ dataLoan.Name }</Text>
                <Text style={Styles.TextSubSpan}>{ dataLoan.Client }</Text>
              </View>
            </View>
          </View>
          <View style={Styles.ItemRight}>
            <View
              style={[
                Styles.BoxStatus,
                {backgroundColor: 
                (dataLoan?.StatusRequested === "active" && "#498E0F") ||
                (dataLoan?.StatusRequested === "finished" && "#1B45D9"),
                }
              ]}
            >
              <Text style={Styles.TextStatus}>{dataLoan?.StatusRequested === "finished" ? "Finished" : "Active"}
              </Text>
            </View>
          </View>
        </View>
        <View style={Styles.Main}>
          <Text style={Styles.TextLabel}>Info Pinjaman</Text>
          <View style={Styles.Container}>
            <View style={Styles.Divider}>
              <View style={Styles.InfoLeft}>
                <Text style={Styles.TextLabel}>Simple Credit</Text>
                <Text style={Styles.TextSpan}>Cash Loan</Text>
                <Text style={Styles.TextSubSpan}>{ dataLoan?.Client ? dataLoan.Client : '-' }</Text>
              </View>
              <View style={Styles.InfoRight}>
                <Image src={permata_image} style={Styles.Image} />
              </View>
            </View>
            <View style={Styles.SubMain}>
              <View style={Styles.SubMainContainer}>
                <View>
                  <Text style={Styles.LabelSub}>Tangal Pengajuan</Text>
                  <Text style={Styles.SpanSub}>{dataLoan?.DateResponse ? moment(dataLoan?.DateResponse).format("LL"): "-"}</Text>
                </View>
                <View>
                  <Text style={[Styles.LabelSub, { paddingRight : 20 }]}>Jumlah Pengajuan</Text>
                  <Text style={Styles.SpanSub}>Rp {dataLoan?.LoanAmount?.Amount ? numberWithPoint(dataLoan?.LoanAmount?.Amount) : "-"} </Text>
                </View>
              </View>
              <View style={Styles.SubMainContainer}>
                <View>
                  <Text style={Styles.LabelSub}>Biaya</Text>
                  <Text style={Styles.SpanSub}>Rp { dataLoan?.LoanAmount?.AdminFee ? numberWithPoint(dataLoan?.LoanAmount?.AdminFee) : '-' }</Text>
                </View>
                <View style={{ paddingRight: 20 }}>
                  <Text style={Styles.LabelSub}>Jangka Waktu</Text>
                  <Text style={[Styles.SpanSub , { paddingRight: 42 }]}>{ dataLoan?.LoanAmount?.Tenor ?  dataLoan?.LoanAmount?.Tenor : "-" } Bulan</Text>
                </View>
              </View>
              <View style={Styles.SubMainContainer}>
                <View>
                  <Text style={Styles.LabelSub}>Nominal Diterima</Text>
                  <Text style={Styles.SpanSub}>Rp { dataLoan?.LoanAmount?.AcceptedNominal ? numberWithPoint(dataLoan?.LoanAmount?.AcceptedNominal) : "-" }</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={Styles.Footer}>
          <Text style={Styles.TextLabel}>Payment History</Text>
          <View style={[Styles.ItemsFooter]}>
            { payment.some((element) => element.status === true ) ? (
              payment.map((el, index) => 
                el.status && (
                  <View style={[Styles.ItemFooter, { marginRight: 10 }]} key={index} >
                  <Text style={[Styles.TextSubSpan, { paddingBottom : 3 }]}>{ el.bulan ? el.bulan : '-' }</Text>
                  { el.History.map((data, sIndex) => (
                    <Text style={[Styles.TextTotal]} key={ sIndex }>Rp { data.besar_pinjaman ? numberWithPoint(data.besar_pinjaman) : '-' }</Text>
                  ))}
                    <View style={Styles.WrapperStatus}>
                      <Image src={CheckedImage} style={Styles.Icon} />
                      <Text style={[Styles.TextTotal, { paddingLeft: 2 }]}>
                        Sudah Dibayar
                      </Text>
                    </View>
                </View>
                )
              )
            ) : null}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFDataLoanExternal;
