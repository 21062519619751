import {
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min";
import NotFound404 from "../../_pageNotFound/NotFound404";
import Employee from "../../../pages/External/Employee/Employee";
import AddEmployee from "../../External/Store/AddEmployeeExternal/AddEmployee";
import { AddEmployeeExternal } from "../../External";
import DetailsEmployee from '../../../pages/External/Employee/EmployeeDetails';
import EditEmployeeExternal from '../../../pages/External/Employee/EditEmployee';
import ViewRequestExternal from "../../../pages/External/Employee/ViewRequest/ViewRequest";
import EmployeeAttendanceDetail from "../../../pages/External/Attendance/EmployeeAttendanceDetail";


const RoutesSupervisor = () => {
  const { path } = useRouteMatch();
  // const token = localStorage.getItem('accessToken');
  return (
    <div>
      <Switch>
        <Route
          exact
          path={`${path}`}
          component={
            <div>
              <p>ada</p>
            </div>
          }
        />
        <Route path={`${path}/employee`} component={Employee} />
        <Route path={`${path}/add-employee`} component={AddEmployee} />
        <Route path={`${path}/add-employee-external`} component={AddEmployeeExternal} />
        <Route path={`${path}/details-employee-external/:id`} component={DetailsEmployee} />
        <Route path={`${path}/edit-employee-external/:id`} component={EditEmployeeExternal} />
        <Route path={`${path}/view-request/:id`} component={ViewRequestExternal} />
        <Route path={`${path}/employee-attendance-details`} component={EmployeeAttendanceDetail} />
        <Route path="*" component={NotFound404}></Route>
      </Switch>
    </div>
  );
};

export default RoutesSupervisor;
