import React from "react";
import Styles from './SalaryAdvanceDetail.module.css';
import permata_image from "../../../../assets/images/logo-permata.png";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import { Avatar } from "@mui/material";

export default function SalaryAdvanceDetailAdminMWS(props) {
  const { dataLoan } = props;

  function numberWithPoint(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return (
    <div className={ Styles.Container }>
      <div className={ Styles.DetailsDesc }>
        <div className={ Styles.Profile }>
          <div className={ Styles.ProfileDescWrapper }>
            <Avatar
              sx={{ width: 70, height: 70 }}
              src={dataLoan?.profilePicture ? dataLoan.profilePicture : ""}
            />
            <div className={ Styles.ProfileDesc }>
              <label>{dataLoan?.employeeName}</label>
              <span>{dataLoan?.company}</span>
            </div>
          </div>
          <div className={ Styles.Status }>
            <div
              className={ Styles.Box }
              style={{
                backgroundColor:
                  (dataLoan?.status === "ACTIVE" && "#498E0F") ||
                  (dataLoan?.status === "FINISHED" && "#1571DE") ||
                  (dataLoan?.status === "FAILED" && "#F02C01") ||
                  (dataLoan?.status === "PENDING" && "#C1992F"),
              }}
            >
              <span>
                {(dataLoan?.status === "ACTIVE" && "Active") ||
                  (dataLoan?.status === "FINISHED" && "Finished") ||
                  (dataLoan?.status === "FAILED" && "Reject") ||
                  (dataLoan?.status === "PENDING" && "Pending")}
              </span>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: 24 }}>
          <hr />
        </div>
        <div className={ Styles.LoanDetails }>
          <div className={ Styles.Label }>
            <span>Loan Information</span>
          </div>
          <div className={ Styles.DetailLoanBox }>
            <div className={ Styles.LoanInfo }>
              <div className={ Styles.LoanInfoText }>
                <label htmlFor="">Employee Benefit Program</label>
                <span>Salary Advance</span>
                <span>
                  {dataLoan?.region} - {dataLoan?.company}
                </span>
              </div>
              <div className={ Styles.Logo }>
                <img src={permata_image} alt="" />
              </div>
            </div>
            <div style={{ paddingTop: 24, width: "100%" }} className={ Styles.Divider }>
              <hr />
            </div>
            <div className={ Styles.DateSubmission }>
              <div className={ Styles.WrapperDateSubmission }>
                <div className={ Styles.DateDetails }>
                  <label>Tangal Pengajuan</label>
                  <span>{dataLoan?.applyDate}</span>
                </div>
                <div className={ Styles.DateDetails }>
                  <label>Jumlah Pengajuan</label>
                  <span>
                    Rp{" "}
                    {dataLoan?.amount
                      ? numberWithPoint(dataLoan?.amount?.installmentTotal)
                      : "-"}
                  </span>
                </div>
              </div>
              <div
                className={ Styles.WrapperDateSubmission }
                style={{ paddingTop: 24 }}
              >
                <div className={ Styles.DateDetails }>
                  <label>Angsuran Bulanan</label>
                  <span>
                    Rp{" "}
                    {dataLoan?.amount
                      ? numberWithPoint(dataLoan.amount?.installment)
                      : "-"}
                  </span>
                </div>
                <div className={ Styles.DateDetails }>
                  <label>Biaya Admin dan Transfer</label>
                  <span>
                    Rp{" "}
                    {dataLoan?.amount?.adminFee
                      ? numberWithPoint(dataLoan?.amount?.adminFee?.total)
                      : "-"}
                  </span>
                </div>
              </div>
              <div
                className={ Styles.WrapperDateSubmission }
                style={{ paddingTop: 24 }}
              >
                <div className={ Styles.DateDetails }>
                  <label>Tenor</label>
                  <span>{dataLoan?.tenor} Bulan</span>
                </div>
                <div className={ Styles.DateDetails }>
                  <label>Jangka Waktu</label>
                  <span>{dataLoan?.tenor} Bulan</span>
                </div>
              </div>
              <div
                className={ Styles.WrapperDateSubmission }
                style={{ paddingTop: 24 }}
              >
                {dataLoan?.status === "ACTIVE" ||
                  (dataLoan?.status === "FINISHED" && (
                    <>
                      <div className={ Styles.DateDetails }>
                        <label>Tanggal Dicairkan</label>
                        <span>{dataLoan?.applyDate}</span>
                      </div>
                      <div
                        className={ Styles.DateDetails }
                        style={{ display: "none" }}
                      >
                        <span></span>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className={ Styles.DetailHistory }>
        {
          dataLoan?.status === 'PENDING' || dataLoan?.status === "FAILED" ? (
          <div className={ Styles.Lablel }>
            <span></span>
          </div>
          ) : (
          <div className={ Styles.Label }>
            <span>Payment History</span>
          </div>
          )
        }
          <div className={ Styles.ItemsDetailHistory }>
            {dataLoan?.installments ? (
              dataLoan?.installments?.map((each, idx) => (
                <div className={ Styles.ItemPaymentHistory } key={idx}>
                  <div className={ Styles.ItemPaymentWrapper }>
                    <label>{each?.dueDate}</label>
                    <span>Rp.{each?.total.toLocaleString("id")}</span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 6,
                      }}
                    >
                      {each?.paidDate === null ? (
                        <>
                          <CancelIcon style={{ color: "#D1421A" }} />
                          <span>Belum Dibayar</span>
                        </>
                      ) : (
                        <>
                          <CheckCircleRoundedIcon
                            style={{ color: "#255BFC" }}
                          />
                          <span>Sudah Dibayar</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <span>There's no data payment yet</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}