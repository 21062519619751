import axios from 'axios';
import {API} from '../../api';
import * as types from '../../type';

export const fetchAllLocations = (token) => {
    return (dispatch) => {
        dispatch({ type: types.FETCH_ALL_LOCATIONS_BEGIN })
        axios
            .get(`${API}/location`, {
                headers: {
                    Authorization: token,
                },
            })
            .then((res) => {
                dispatch({
                    type: types.FETCH_ALL_LOCATIONS_SUCCESS,
                    payload: res.data.response,
                });
            })
            .catch((err) => {
                dispatch({ type: types.FETCH_ALL_LOCATIONS_ERROR, payload: err.response })
                console.log(err);
            });
    }
}

export const getLocationById = (token, id) => {
    return (dispatch) => {
        axios
            .get(`${API}/location/admin/${id}`, {
                headers: {
                    Authorization: token,
                }
            })
            .then((res) => {
                dispatch({
                    type: types.GET_LOCATION_BY_ID,
                    payload: res.data.response,
                })
            })
            .catch((err) => {
                console.log(err);
            })
    }
}

export const addLocation = (token, body) => {
    return (dispatch) => {
        axios.post(`${API}/location`, body, {
            headers: {
                Authorization: token,
            }
        })
            .then((res) => {
                dispatch({
                    type: types.ADD_LOCATION,
                    payload: res.data.response,
                })
            })
            .then(() => {
                dispatch(fetchAllLocations(token))
                return Promise.resolve()
            })
            .catch((err) => {
                console.log(err)
                return Promise.reject(err)
            })
    }
}

export const updateLocation = (token, id, body) => {
    return (dispatch) => {
        axios
            .patch(`${API}/location/${id}`, body, {
                headers: {
                    Authorization: token,
                }
            })
            .then((res) => {
                dispatch({
                    type: types.UPDATE_LOCATION,
                    payload: res.data.response,
                })
            })
            .then(() => {
                dispatch(getLocationById(token, id))
                return Promise.resolve()
            })
            .catch((err) => {
                console.log(err)
                return Promise.reject(err)
            })
    }
}



export const setLocation = (token, id, body) => {
    return (dispatch) => {
        axios
            .patch(`${API}/location/admin/${id}`, body, {
                headers: {
                    Authorization: token,
                }
            })
            .then((res) => {
                dispatch({
                    type: types.SET_EMPLOYEE_LOCATION,
                    payload: res.data.response,
                })
            })
            .then(() => {
                dispatch(getLocationById(token, id))
            })
            .catch((err) => {
                console.error(err)
            })
    }
}

export const deleteUserFromLocation = (token, body) => {
    return (dispatch) => {
        axios
            .delete(`${API}/location/user`, {
                headers: {
                    Authorization: token,
                },
                data: body
            })
            .then((res) => {
                dispatch({
                    type: types.DELETE_USER_FROM_LOCATION,
                    payload: res.data.response,
                })
            })
            .then(() => {
                dispatch(getLocationById(token, body?.locationId))
            })
            .catch((err) => {
                console.log(err)
            })
    }
}

export const deleteLocation = (token, id) => {
    return (dispatch) => {
        axios
            .delete(`${API}/location/${id}`, {
                headers: {
                    Authorization: token,
                }
            })
            .then((res) => {
                dispatch({
                    type: types.DELETE_LOCATION,
                    payload: res.data.response
                })
            })
            .then(() => {
                dispatch(fetchAllLocations(token))
            })
            .catch((err) => {
                console.log(err)
            })
    }
}