import React from "react";
import "./LoanSettingsMenu.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import Radio from "@mui/material/Radio";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
export default function LoanSettingsMenu() {
  // const [modalLoanSettingsOpen, setModalLoanSettingOpen] = useState(false);
  return (
    <div className="client-setting-menu">
      <div className="wrapper-client-setting">
        <div className="card-setting-menu">
          <div className="items-setting-radio">
            <span> Scheme 1</span>
            {/* <Switch /> */}
            <Radio />
          </div>
          <span className="items__sub-setting">
            View setting for user <br />
            yours clients
          </span>

          <div
            className="items-setting-footer-loan"
            style={{ cursor: "pointer" }}
          >
            <Link to="/loan-settings-view">
              <Button
                className="items-setting-footer-loan"
                style={{ textTransform: "none" }}
              >
                <span>View Settings</span>
                <ArrowRightAltIcon
                  style={{ color: "#1571DE", marginLeft: 15 }}
                />
              </Button>
            </Link>
          </div>
        </div>
        <div className="card-setting-menu">
          <div className="items-setting-radio">
            <span> Scheme 2</span>
            <Radio />

            {/* <Switch /> */}
          </div>
          <span className="items__sub-setting">
            View setting for user <br />
            yours clients
          </span>

          <div
            className="items-setting-footer-loan"
            style={{ cursor: "pointer" }}
          >
            <Link to="/loan-settings-view">
              <Button
                className="items-setting-footer-loan"
                style={{ textTransform: "none" }}
              >
                <span>View Settings</span>
                <ArrowRightAltIcon
                  style={{ color: "#1571DE", marginLeft: 15 }}
                />
              </Button>
            </Link>
          </div>
        </div>{" "}
        <div className="card-setting-menu">
          <div className="items-setting-radio">
            <span> Scheme 3</span>
            <Radio />

            {/* <Switch /> */}
          </div>
          <span className="items__sub-setting">
            View setting for user <br />
            yours clients
          </span>

          <div
            className="items-setting-footer-loan"
            style={{ cursor: "pointer" }}
          >
            <Link to="/loan-settings-view">
              <Button
                className="items-setting-footer-loan"
                style={{ textTransform: "none" }}
              >
                <span>View Settings</span>
                <ArrowRightAltIcon
                  style={{ color: "#1571DE", marginLeft: 15 }}
                />
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
