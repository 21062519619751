import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataPayslipById } from "../../../../redux/actions/SuperAdmin/slipPaymentActions";
import { useCalendar } from "../../../utils/hooks";
import { Helmet } from "react-helmet";

const Styles = StyleSheet.create({
  Body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  Heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 10,
    borderBottom: "1px solid #C2C2C2",
  },
  TextHeading: {
    color: "#616161",
    fontSize: 18,
    fontWeight: 500,
  },
  PayslipContainer: {
    paddingTop: 10,
    borderBottom: "1px solid #C2C2C2",
  },
  ItemSlip: {},
  ItemLeft: {
    flex: 1,
    display: "flex",
  },
  ContainerRutin: {
    paddingTop: 10,
    borderBottom: "1px solid #C2C2C2",
  },
  ContainerTidakRutin: {
    paddingTop: 10,
    borderBottom: "1px solid #C2C2C2",
  },
  ContainerPotongan: {
    paddingTop: 10,
    borderBottom: "1px solid #C2C2C2",
  },
  ContainerTotal: {
    paddingTop: 10,
  },
  ItemRutin: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  Control: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  Image: {
    width: 35,
    height: 35,
  },
  DataDesc: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
  },
  TextLabel: {
    color: "#616161",
    fontSize: 8,
    fontWeight: 500,
    paddingBottom: 4,
  },
  TextSpan: {
    color: "#0A0A0A",
    fontSize: 10,
    fontWeight: 500,
    paddingBottom: 4,
  },
  TextSubSpan: {
    color: "#404040",
    fontSize: 8,
    fontWeight: 500,
    paddingBottom: 4,
  },
  ItemRight: {
    flex: 1,
    alignItems: "flex-end",
  },
  BoxStatus: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    width: 50,
    height: 25,
    backgroundColor: "#498e0f",
    borderRadius: 50,
  },
  TextStatus: {
    color: "#fafafa",
    fontSize: 10,
  },
  Main: {
    paddingTop: 20,
  },
  Container: {
    paddingTop: 20,
    backgroundColor: "#fafafa",
    borderRadius: 8,
    padding: 10,
    marginTop: 10,

    // borderBottom: '1px solid #C2C2C2',
  },
  InfoLeft: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  InfoRight: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  Divider: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #C2C2C2",
    paddingBottom: 10,
  },
  SubMain: {
    display: "flex",
    justifyContent: "space-between",
  },
  SubMainContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
  },
  WrapperText: {
    display: "flex",
    flexDirection: "column",
  },
  LabelSub: {
    color: "#404040",
    fontSize: 10,
    fontWeight: 500,
    paddingBottom: 4,
  },
  SpanSub: {
    color: "#0a0a0a",
    fontSize: 12,
    fontWeight: 500,
    paddingBottom: 4,
  },
  Footer: {
    paddingTop: 20,
    width: "100%",
  },
  ItemsFooter: {
    paddingBottom: 5,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    // justifyContent: "space-between",
    width: "100%",
  },
  ItemFooter: {
    width: 100,
    backgroundColor: "#fafafa",
    borderRadius: 8,
    padding: 10,
    display: "flex",
    marginTop: 10,
  },
  TextTotal: {
    color: "#0a0a0a",
    fontSize: 12,
    fontWeight: 500,
  },
  WrapperStatus: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 5,
  },
  Icon: {
    width: 10,
    height: 10,
  },
  Text: {
    margin: 12,
    fontSize: 14,
    // textAlign: "justify",
  },
});

const PDFDataPayslipExternal = () => {
  const token = localStorage.getItem("accessToken");
  const { id } = useParams();
  const dataPayslip = useSelector((state) => state.payslip.dataPayslipById);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataPayslipById(token, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return dataPayslip ? (
    <PDFViewer style={{ width: window.innerWidth, height: "100vh" }}>
      <DataToView data={dataPayslip} />
    </PDFViewer>
  ) : null;
};

const DataToView = ({ data }) => {
  const calendar = useCalendar();
  let month = calendar.months.eng.full[data?.PayrollMonth?.split(" ")[0] - 1];
  let year = data?.PayrollMonth?.split(" ")[2];
  function numberWithPoint(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return (
    <Document>
      <Helmet>
        <title>MyWorkSpace | Payslip Report</title>
      </Helmet>
      <Page style={Styles.Body} size="A4">
        <View style={Styles.Heading}>
          <Text style={Styles.TextHeading}>Epay Slip</Text>
        </View>
        <View style={Styles.PayslipContainer}>
          <View style={Styles.ItemSlip}>
            <Text style={Styles.TextLabel}>Payslip Date</Text>
            <Text style={Styles.TextSpan}>{`${month} ${year}`}</Text>
          </View>
          <View style={[Styles.ItemSlip, { paddingTop: 5 }]}>
            <Text style={Styles.TextLabel}>Payslip Date</Text>
            <Text style={Styles.TextSpan}>{data.Name}</Text>
          </View>
          <View style={[Styles.ItemSlip, { paddingTop: 5, paddingBottom: 5 }]}>
            <Text style={Styles.TextLabel}>Position</Text>
            <Text style={Styles.TextSpan}>{data.Position}</Text>
          </View>
        </View>
        <View style={Styles.ContainerRutin}>
          <Text style={[Styles.TextSpan]}>Income</Text>
          {Object.keys(data?.Income || {} ).map((key, index) => (
            <View style={[Styles.ItemRutin, { paddingTop: 5 }]} key={index}>
              <View style={Styles.ItemLeft}>
                <Text style={Styles.TextSpan}>{key ? key : '-'}</Text>
              </View>
              <View style={Styles.ItemRight}>
                <Text style={Styles.TextSpan}>
                  Rp.
                  {data?.Income[key] ? numberWithPoint(data?.Income[key]) : "-"}
                </Text>
              </View>
            </View>
          ))}
          <View style={[Styles.ItemRutin, { paddingTop: 5 }]}>
            <View style={Styles.ItemLeft}>
              <Text style={Styles.TextSpan}>Total Income</Text>
            </View>
            <View style={Styles.ItemRight}>
              <Text style={Styles.TextSpan}>
                Rp.
                {data?.TotalIncome ? numberWithPoint(data?.TotalIncome) : "-"}
              </Text>
            </View>
          </View>
        </View>
        <View style={Styles.ContainerPotongan}>
          <Text style={[Styles.TextSpan]}>Potongan</Text>
          {Object.keys(data?.Deduction || {}).map((key, index) => (
            <View style={[Styles.ItemRutin, { paddingTop: 5 }]} key={index}>
              <View style={Styles.ItemLeft}>
                <Text style={Styles.TextSpan}>{key}</Text>
              </View>
              <View style={Styles.ItemRight}>
                <Text style={Styles.TextSpan}>
                  Rp.{data?.Deduction[key] ? numberWithPoint(data?.Deduction[key]) : "-"}
                </Text>
              </View>
            </View>
          ))}
          <View style={[Styles.ItemRutin, { paddingTop: 5, paddingBottom: 5 }]}>
            <View style={Styles.ItemLeft}>
              <Text style={Styles.TextSpan}>Total Deduction</Text>
            </View>
            <View style={Styles.ItemRight}>
              <Text style={Styles.TextSpan}>
                Rp.{data?.TotalDeduction ? numberWithPoint(data?.TotalDeduction) : "-"}
              </Text>
            </View>
          </View>
        </View>
        <View style={Styles.ContainerTotal}>
          <View style={[Styles.ItemRutin, { paddingTop: 5 }]}>
            <View style={Styles.ItemLeft}>
              <Text style={Styles.TextTotal}>Take Home Pay</Text>
            </View>
            <View style={Styles.ItemRight}>
              <Text style={Styles.TextTotal}>
                Rp.
                {data?.TakeHomePay ? numberWithPoint(data?.TakeHomePay) : "-"}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFDataPayslipExternal;
