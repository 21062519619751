import React from "react";
import { Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import {
  FeaturedInfoExternal,
  // AppAttendances,
  // SalesOverview,
  // AppNewCustCircleCharts,
  // AppStoreBest,
  // AppRecentProductPromo,
  // AppProductBest,
  // AppBestSpg,
  // AppNewCustBottom,
} from "../../../components/_dashboard";
import UnderMaintenance from "../../../components/_underMaintenancePage";

const DashboardExternal = () => {
  const isMaintenance = true;
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Dashboard</title>
      </Helmet>
      <h2 className="page-header" style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}>
        Dashboard
      </h2>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          {isMaintenance ? <UnderMaintenance/> : <FeaturedInfoExternal/>}
        </Grid>
        {/* <Grid item xs={12} md={7} lg={8}>
          <SalesOverview />  
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <AppAttendances />
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <AppNewCustCircleCharts />
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <AppStoreBest />
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <AppNewCustBottom />
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <AppProductBest />
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <AppRecentProductPromo />
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <AppBestSpg />
        </Grid> */}
        {/* <Grid item xs={12} md={7} lg={8}>
          <AppLastEpaySlip />
        </Grid> */}
        {/* <Grid item xs={12} md={5} lg={4}>
          <AppRecentLoan />
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default DashboardExternal;
