import React from "react";
import "./LoanAddScheme.css";
import { Button, Container, Grid} from "@mui/material";
import { Helmet } from "react-helmet";
import { AppAddLoanScheme } from "../../../components/SuperAdmin/_loan";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
import { useHistory } from "react-router";
export default function LoanAddScheme() {
  const history = useHistory();
  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace| Edit Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/loan")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Loan</span>
            </Button>
            <span style={{ fontSize: 20 }}>Add Scheme</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppAddLoanScheme />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
