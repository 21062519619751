import React, { useRef } from "react";
import "./ModalDeleteFMCG.css";
// import logo_account_delete from "../../../assets/images/account-delete.png";
// import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import DeleteIcon from "../../../assets/images/delete-icon-for-modal.png";
// import { useHistory } from "react-router";
// import { useDispatch } from "react-redux";
// import { deleteEmployeeById } from "../../../../../redux/actions/SuperAdmin/employeesActions";

const clickOutsideRef = (content_ref, toggle_ref) => {
  document.addEventListener("mousedown", (e) => {
    // user click toggle
    if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
      content_ref.current.classList.toggle("active");
    } else {
      // user click outside toggle and content
      if (content_ref.current && !content_ref.current.contains(e.target)) {
        content_ref.current.classList.remove("active");
      }
    }
  });
};

export default function ModalDeleteFMCG({ modalDelete, setModalDelete, description }) {
  const dropdown_toggle_el = useRef(null);
  const dropdown_content_el = useRef(null);

  clickOutsideRef(dropdown_content_el, dropdown_toggle_el);
  //   const token = localStorage.getItem('accessToken');
  //   const dispatch = useDispatch();
  //   const history = useHistory();
  //   const clientName = "PT. Permata Indo Sejahtera"

  //   const handleDeleteEmployee = () => {
  //     setSelectedEmployee({})
  //     dispatch(deleteEmployeeById(token, selectedEmployee._id , clientName))
  //     history.push(pathName)
  //   }
  return (
    <div className="ModalDeleteBackground">
      <div className="ModalContainerContent">
        <div className="WrapperItemModalDelete">
          <div className="ItemTopModalDelete"> 
            <img src={DeleteIcon} alt="" />
          </div>
        </div>
        <div className="ContentModalDelete" style={{ paddingTop: 24 }}>
          <div className="TextModalDelete">
            <span>Hapus Data</span>
            <span>Apakah anda yakin ingin menghapus data?</span>
          </div>
          <div className="WrapperButtonModalDelete">
            <Button
              style={{
                border: "1px solid #333333",
                backgroundColor: "#FFFFFF",
                borderRadius: 5,
                textTransform: "none",
                color: "#333333",
                width: 171,
                height: 38,
                fontSize: 13,
                fontWeight: 500,
              }}
              onClick={() => {
                setModalDelete(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#1571DE",
                textTransform: "none",
                color: "#FFFFFF",
                width: 171,
                height: 38,
                fontSize: 13,
                fontWeight: 500,
              }}
              //   onClick={handleDeleteEmployee}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
