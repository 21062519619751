/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import "./LoanReport.css";
import { filter } from "lodash";
import { Helmet } from "react-helmet";
import logo_MyWorkSpace from "../../../../assets/images/my-workspace.png";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  styled,
} from "@mui/material";
import USERLIST from "../../../../components/SuperAdmin/_user/TableUsers/_mocks/user";
import Scrollbar from "../../../../components/SuperAdmin/_user/TableUsers/Scrollbar";
import UserListHead from "../../../../components/SuperAdmin/_user/TableUsers/UserListHead";
import { useHistory } from "react-router";
import DateRangePickerComponent from "../../../../components/utils/DateRangePicker/DateRangePicker";
import { makeStyles } from "@mui/styles";
import ExportCSV from "../../../../components/SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllApprovedHistoryLoan,
  fetchAllOnGoingLoan,
  fetchDataLoanReport,
} from "../../../../redux/actions/SuperAdmin/loanActions";
import { UseCompanyName } from "../../../../components/utils/hooks";
import moment from "moment";
import LoadingAnimation from "../../../../components/utils/LoadingAnimation/LoadingAnimation";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
  },
});

const CompanyGroup = [
  { name: "PT Permata Indonesia", code: "AF" },
  { name: "PT Samsung", code: "AF" },
  { name: "PT Telkom Indonesia", code: "AF" },
  { name: "PT Sinar Dunia", code: "AF" },
];

// const TABLE_HEAD = [
//   { id: "Name", label: "Employee Name", alignRight: false },
//   { id: "Position", label: "Position", alignRight: false },
//   { id: "Client", label: "Client", alignRight: false },
//   { id: "ApplyDate", label: "Apply Date", alignRight: false },
//   { id: "Status", label: "Status", alignRight: false },
//   { id: "PeriodTime", label: "Period Time", alignRight: false },
//   { id: "RemainingPayment", label: "Remaining Payment", alignRight: false },
//   { id: "StatusDate", label: "Status Date", alignRight: false },
//   { id: "LastApprovedBy", label: "Last Approved By", alignRight: false },
// ];

const TABLE_HEAD = [
  { id: "Name", label: "Employee Name", alignRight: false },
  { id: "Client", label: "Company", alignRight: false },
  { id: "NotificationTime", label: "Apply Date", alignRight: false },
  { id: "TenorMonth", numeric: true, label: "Tenor", alignRight: false },
  { id: "StatusRequested", label: "Status", alignRight: false },
  // { id: "NotificationTime", label: "Status Date", alignItems: "center" },
  // { id: "action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function LoanReport() {
  const reportData = useSelector((state) => state.loan.dataLoanReport);
  const { loadingDataReport } = useSelector((state) => state.loan);
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [groupValue, setGroupValue] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [selected, setSelected] = useState("PT. Permata Indo Sejahtera");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedCompany, setSelectedCompany] = useState();
  const startDate = moment().format("LL");
  const endDate = moment().format("LL");
  const [valueStartDate, setValueStartDate] = useState(startDate);
  const [valueEndDate, setValueEndDate] = useState(endDate);
  const getCompany = UseCompanyName();
  const wrappedFunction = [reportData?.data].flat();
  const wrappedData = reportData?.data;

  const subLoan = useMemo(() => {
    let dataSubLoan = [];
    for (let i = 0; i < wrappedData?.length; i++) {
      const dataLoan = wrappedData[i].Loans;
      if (dataLoan.length > 0) {
        for (let j = 0; j < dataLoan?.length; j++) {
          const el = wrappedData[i];
          dataSubLoan.push({
            NRK: el.Nrk,
            Nama: el.Name ? el.Name : "-",
            "Tanggal Pengajuan": el.Loans[j]?.DateResponse
              ? moment(el.Loans[j]?.DateResponse).format("L")
              : "-",
            "Besar Pinjaman":
              el.Loans[j].LoanAmount?.Amount.toLocaleString("id"),
            "Admin Fee": el.Loans[j].LoanAmount?.AdminFee.toLocaleString("id"),
            "Jumlah Pinjaman Diterima":
              el.Loans[j].LoanAmount?.AcceptedNominal.toLocaleString("id"),
            "Status Pinjaman": el.Loans[j].StatusRequested,
            "Tanggal Approve": moment(el.Loans[j]?.DateResponse).format("L"),
            // "Region Input": "Test Region",
            Client: el?.Client,
          });
        }
      }
    }

    return dataSubLoan.map((el, idx) => ({
      "No.": idx + 1,
      ...el,
    }));
  }, [wrappedData]);

  useEffect(() => {
    dispatch(
      fetchDataLoanReport(
        token,
        selected,
        new Date(valueStartDate).getTime(),
        new Date(valueEndDate).getTime()
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, valueStartDate, valueEndDate]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeStartDate = (newValue) => {
    setValueStartDate(newValue);
  };
  const handleChangeEndDate = (newValue) => {
    setValueEndDate(newValue);
  };
  
  console.log(subLoan)
  return (
    <div className="container-loan-report">
      <Helmet>
        <title>MyWorkSpace | Loan Report</title>
      </Helmet>
      <div className="wrapper-loan-report">
        <div className="item-top-loan-report">
          <div className="item-left-loan-report" onClick={() => history.go(-1)}>
            <img src={logo_MyWorkSpace} alt="" />
            <span>MyWorkSpace</span>
          </div>
          <div className="item-mid-loan-report">
            <span>Loan Report</span>
          </div>
          <div className="item-right-loan-report">
            <ExportCSV
              data={subLoan}
              initial="Download"
              endIcon={<DownloadIcon />}
              filename={`Loan Report ${new Date().toLocaleDateString()}`}
            />
          </div>
        </div>
        <div className="date-picker-shown">
          <div className="wrapper-date-picker-shown">
            <DateRangePickerComponent
              valueStartDate={valueStartDate}
              valueEndDate={valueEndDate}
              handleChangeStartDate={handleChangeStartDate}
              handleChangeEndDate={handleChangeEndDate}
            />
          </div>
        </div>
        <div className="company-loan-report">
          <div className="title-company-report">
            <label htmlFor="">Group</label>
            <Autocomplete
              disablePortal
              options={getCompany}
              value={selected}
              inputValue={selected}
              onInputChange={(e, value) => setSelected(value)}
              renderInput={(params) => (
                <MuiTextField {...params} size="small" />
              )}
            />
            {/* <FormControl fullWidth size="small" style={{ width: "100%" }}>
              <Select
                className={classes.selectBorder}
                value={selected}
                displayEmpty
                onChange={(e) => setSelected(e.target.value)}
              >
                <MenuItem value="">Select Group</MenuItem>
                {getCompany.map((option, el) => (
                  <MenuItem
                    key={el}
                    onClick={() => setSelectedGroup(option)}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
        </div>
        <hr className="line-loan-report" />
        <div className="desc-item-loan-report">
          <div className="mini-item-left">
            <label>Loan Apply</label>
            <span>{reportData?.loanApply}</span>
          </div>
          <div className="border-seperator"></div>
          <div className="mini-item-middle">
            <label>Loan Approved</label>
            <span>{reportData?.loanApproved}</span>
          </div>
          <div className="border-seperator"></div>
          <div className="mini-item-right">
            <label>Loan Pending</label>
            <span>{reportData?.loanPending}</span>
          </div>
        </div>
        {loadingDataReport ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 100,
            }}
          >
            <div style={{ width: 200 }}>
              <LoadingAnimation />
            </div>
          </div>
        ) : (
          <div className="loan-progress-container">
            <div className="table-loan-progress">
              <Scrollbar>
                <TableContainer sx={{ minWidth: 600 }}>
                  <Table
                    sx={{
                      width: "100%",
                      maxHeight: "100%",
                      backgroundColor: "#fafafa",
                      borderRadius: 4,
                    }}
                  >
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={wrappedFunction.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {stableSort(
                        wrappedFunction,
                        getComparator(order, orderBy)
                      )
                        .filter((el) =>
                          selected ? el?.Client === selected : true
                        )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((sData, ind) => {
                          const labelId = `enhanced-table-checkbox-${ind}`;
                          // console.log(sData, 'sDataaaa')
                          return (
                            <TableRow hover key={ind} tabIndex={-1}>
                              <TableCell></TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                                id={labelId}
                                key={ind}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Avatar
                                    alt={sData?.Name}
                                    src={sData?.ProfilePicture}
                                  />
                                  <Typography variant="subtitle2" noWrap>
                                    {sData?.Name ? sData.Name : "-"}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                {sData?.Client ? sData.Client : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {sData?.Loans[0]?.DateResponse ? moment(sData?.Loans[0]?.DateResponse).format("L"): "-"}
                              </TableCell>
                              <TableCell>
                              {sData?.Loans[0]?.LoanAmount.Tenor ? sData?.Loans[0]?.LoanAmount?.Tenor + " Month": "-"}
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  className="status__style"
                                  style={{
                                    backgroundColor:
                                      (sData?.Loans[0]?.StatusRequested === "active" &&
                                        "#498E0F") ||
                                      (sData?.Loans[0]?.StatusRequested === "pending" &&
                                        "#F1A073") ||
                                      (sData?.Loans[0]?.StatusRequested === "rejected" &&
                                        "#D1421A"),
                                  }}
                                >
                                  {(sData?.Loans[0]?.StatusRequested === "active" &&
                                    "Approved") ||
                                    (sData?.Loans[0]?.StatusRequested === "pending" &&
                                      "Pending") ||
                                    (sData?.Loans[0]?.StatusRequested === "rejected" &&
                                      "Rejected")}
                                </Typography>
                              </TableCell>
                              {/* <TableCell align="left">
                                {sData?.Loans[0]?.LoanAmount?.Tenor
                                  ? sData?.Loans[0]?.LoanAmount?.Tenor
                                  : "-"}
                              </TableCell> */}
                              {/* <TableCell align="left">-</TableCell> */}
                              {/* <TableCell>
                                {sData?.Loans[0]?.DateResponse
                                  ? moment(sData?.Loans[0]?.DateResponse).format(
                                      "L"
                                    )
                                  : "-"}
                              </TableCell> */}
                              {/* <TableCell>
                                {sData?.Loans[0]?.LastApproval
                                  ? sData?.Loans[0]?.LastApproval
                                  : "-"}
                              </TableCell> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={wrappedFunction.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
