// import React from "react";
// import "./SearchbarSA.css";
// export default function SearchbarSA({ value, onChange, onSubmit}) {
//   return (
//     <form className="SearchbarSA">
//       <input
//         type="search"
//         value={value}
//         onChange={onChange}
//         placeholder="Search"
//       />
//       <i className="bx bx-search" />
//     </form>
//   );
// }

import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import React from "react";

const SearchbarSA = ({ value, onChange, searchWidth, placeholder  }) => {
  return (
    <>
      <FormControl variant="outlined">
        <OutlinedInput
          id="outlined-adornment-weight"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          endAdornment={
            <InputAdornment position="end">
              <IconButton>
                <i className="bx bx-search" />
              </IconButton>
            </InputAdornment>
          }
          style={{
            borderRadius: 8,
            padding: 0,
            height: 40,
            width: searchWidth,
            backgroundColor: "white",
          }}
        />
      </FormControl>
    </>
  );
};

export default SearchbarSA;

