/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./UserDetailEmployee.css";
import { filter } from "lodash";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import {
  Table,
  Stack,
  Avatar,
  Grid,
  AvatarGroup,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Select,
  FormControl,
  MenuItem,
  styled,
  Autocomplete,
  TextField,
} from "@mui/material";
import USERLIST from "../TableUsers/_mocks/user";
import UserListHead from "../TableUsers/UserListHead";
import Scrollbar from "../TableUsers/Scrollbar";
import EmployeeAssignButton from "./testButton";
import ModalSettingsEmployeeMenu from "./modal-employee-detail-menu/modal-settings-employee-menu/ModalSettingsEmployeeMenu";
import ModalAddStaff from "./modal-add-staff/ModalAddStaff";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import { useDispatch, useSelector } from "react-redux";
import {
  UseCompanyName,
  UseGetAllPosition,
  UseGetAllRegion,
} from "../../../utils/hooks";
import { fetchAllEmployees } from "../../../../redux/actions/SuperAdmin/employeesActions";
import { makeStyles } from "@mui/styles";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
  },
});

const TABLE_HEAD = [
  { id: "Name", label: "Employee Name", alignRight: false },
  { id: "Position", label: "Position", alignRight: false },
  { id: "Nrk", label: "NRK", alignRight: false },
  { id: "staff", label: "Staff", alignRight: false },
  { id: "leader", label: "Leader", alignRight: false },
  { id: "Status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const useDebouncedCallback = (func, wait) => {
  const timeout = useRef();

  return useCallback(
    (...args) => {
      const later = () => {
        clearTimeout(timeout.current);
        func(...args);
      };

      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
    },
    [func, wait]
  );
};

export default function UserDetailEmployee() {
  const getCompany = UseCompanyName();
  const location = useLocation();
  const clientName = localStorage.getItem('client');
  const Employees = useSelector((state) => state.employees.employees);
  const { loadingAllEmployee, errorMessage } = useSelector((state) => state.employees);
  const employees = Employees;
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState(clientName);
  const [selectedInput, setSelectedInput] = useState("");
  const [selectedDropdown, setSelectedDropdown] = useState("");
  const [orderBy, setOrderBy] = useState("Name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalSettingsEmployee, setModalSettingsEmployee] = useState(false);
  const options = ["Position"];
  const [listEmployees, setListEmployees] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");

  const persistClientName = useMemo(() => {
    let persistClient = window.sessionStorage.getItem('clientNameEmployee');
    let clientName;

    if (persistClient) {
      clientName = persistClient;
    } else {
      clientName = selected
    }

    return clientName;
  },[selected]);

  useEffect(() => {
    if(location.pathname === '/superadmin/employee') {
      setSelected(persistClientName);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    dispatch(fetchAllEmployees(token, persistClientName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, persistClientName]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredEmployees = stableSort(
    employees,
    getComparator(order, orderBy)
  );

  const handleSelectedClient = (value) => {
    setSelected(value);
    window.sessionStorage.setItem('clientNameEmployee', value)
  };

  const handleSearch = (value) => {
    setSelectedInput(value);
  };

  const handleSearchInput = (event) => {
    setListEmployees(event.target.value);
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchTerm(listEmployees);
  };
  
  return (
    <div className="container-user-detail-employee">
      <div className="wrapper-user-detail-employee-data">
        <div className="wrapper-searchbox-detail-employee">
          {/* <SearchbarSA
            value={listEmployees}
            onChange={handleSearchInput}
            onSubmit={handleSubmitFilter}
          /> */}
           <SearchbarSA
              value={listEmployees}
              onChange={handleSearchInput}
              onSubmit={handleSubmitFilter}
              searchWidth="379px"
              placeholder="Search by name"
            />
        </div>
        <div className="data-attendance-menu-right" style={{ width: 114 }}>
          <Autocomplete
            disablePortal
            options={getCompany}
            value={persistClientName}
            inputValue={selectedInput}
            onInputChange={(e, value) => value === null ? handleSearch("") : handleSearch(value)}
            onChange={(e, value) => value === null ? handleSelectedClient("") : handleSelectedClient(value)}
            renderInput={(params) => (
              <MuiTextField {...params} size="small" sx={{ width: 114, background: '#fff' }} label="Position" />
            )}
          />
        </div>
        <div className="data-employee-menu-right-download">
          {/* <Button
            startIcon={<AddIcon style={{ color: "white" }} />}
            style={{
              backgroundColor: "#1571DE",
              textTransform: "none",
              width: 150,
              height: 40,
              borderRadius: 4,
            }}
            onClick={() => history.push("/superadmin/add-employee")}
          >
            <span style={{ fontSize: 14, color: "white" }}>Add Employee</span>
          </Button> */}
        </div>
      </div>
      {loadingAllEmployee ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 100
          }}
        >
          <div style={{ width: 200 }}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div
          className="wrapper-table-detail-employee"
          style={{ marginTop: 24 }}
        >
          <Scrollbar>
            <TableContainer>
              <Table
                sx={{
                  width: "100%",
                  maxHeight: "100%",
                  backgroundColor: "#fafafa",
                  borderRadius: 4,
                }}
              >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={employees.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(employees, getComparator(order, orderBy))
                    .filter(
                      (el) =>
                        el.Nrk?.toLowerCase().includes(listEmployees?.toLowerCase()) ||
                        el.Name?.toLowerCase().includes(listEmployees?.toLowerCase()) ||
                        (el.Position?.toLowerCase().includes(listEmployees?.toLocaleLowerCase()
                        ) &&
                          (selectedDropdown
                            ? el.Position === selectedDropdown
                            : true))
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((employee, ind) => {
                      const labelId = `enhanced-table-checkbox-${ind}`;
                      return (
                        <TableRow hover key={ind} tabIndex={-1} id={labelId}>
                          <TableCell></TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Link
                              to={{
                                pathname: `/superadmin/profile-employee/${employee._id}`,
                                state: { data: employee },
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  src={
                                    employee?.ProfilePicture
                                      ? employee.ProfilePicture
                                      : null
                                  }
                                />
                                <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize'}}>
                                  {employee.Name ? employee.Name.toLowerCase() : "-"}
                                </Typography>
                              </Stack>
                            </Link>
                          </TableCell>
                          <TableCell align="left" sx={{ textTransform: 'capitalize'}}>
                            {employee.Position ? employee.Position.toLowerCase() : "Other"}
                          </TableCell>
                          <TableCell align="left">
                            {employee?.Nrk ? employee?.Nrk : "-"}
                          </TableCell>
                          <TableCell align="left">
                            <Grid container>
                              <Grid item xs={0}>
                                <AvatarGroup max={3}></AvatarGroup>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align="left">
                            <Grid container>
                              <Grid item xs={0}>
                                <AvatarGroup max={3}>
                                  <Avatar />
                                </AvatarGroup>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align="left">
                          {employee.Status === true ? 'Active' : 'Non Active'}
                          </TableCell>
                          <TableCell align="right">
                            <Link
                              to={{
                                pathname: `/superadmin/edit-employee/${employee._id}`,
                                state: { data: employee },
                              }}
                            >
                              <Button>
                                <EditIcon />
                              </Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={employees.filter(
              (el) =>
                el.Name?.toLowerCase().includes(
                  listEmployees?.toLowerCase()
                ) ||
                (el.Position?.toLowerCase().includes(
                  listEmployees?.toLocaleLowerCase()
                ) &&
                  (selectedDropdown
                    ? el.Position === selectedDropdown
                    : true))
            ).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </div>
  );
}
