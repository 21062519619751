/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import "@reach/combobox/styles.css";
import {
    GoogleMap, Marker, useLoadScript
} from "@react-google-maps/api";
import React, { useCallback, useRef, useState } from "react";
// import "./index.css";
const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "120px",
  borderRadius: '8px',
  border: '1px solid #d9d9d9',
};


export default function MiniStaticMap({
  setCoordinate,
  coordinate,
  setShowSearch,
  showSearch,
  setAddress,
}) {
  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB0Gy-BuZ0XyKlvhNtuhRPJh4RGdfQOQgE",
    libraries,
  });
  const [markers, setMarkers] = useState(null);

  const mapRef = useRef();

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current?.panTo({ lat, lng });
    mapRef.current?.setZoom(14);
    // zooming the map
    setMarkers({
      lat,
      lng,
      time: new Date(),
    });
  }, []);

  const onMapLoad = useCallback(
    (map) => {
      if (coordinate?.Latitude) {
        // panTo({ lat: +coordinate?.Latitude, lng: +coordinate?.Longitude })
        navigator.geolocation.getCurrentPosition(
          (position) =>
            panTo({ lat: +coordinate?.Latitude, lng: +coordinate?.Longitude }),
          () => null
        );
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) =>
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }),
          () => null
        );
      }
      mapRef.current = map;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [panTo]
  );

  const center = {
    lat: coordinate?.Latitude,
    lng: coordinate?.Longitude,
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading maps";
  // console.log(coordinate, 'cek')
  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        center={center}
        onLoad={onMapLoad}
      >
        <Marker
          key={markers?.time.toISOString()}
          position={{ lat: coordinate?.Latitude, lng: coordinate?.Longitude }}
          onClick={() => {
           
          }}
          disabled
        />
      </GoogleMap>
    </div>
  );
}

function Locate({ panTo }) {
  return (
    <button>
      <img src="" />
    </button>
  );
}

// function Search({
//   panTo,
//   setCoordinate,
//   setValues,
//   setAddress,
//   setSelectedAddress,
//   coordinate,
//   setMarkers,
//   showSearch,
// }) {
//   const {
//     ready,
//     value,
//     suggestions: { status, data },
//     setValue,
//     clearSuggestions,
//   } = usePlacesAutocomplete({
//     requestOptions: {
//       // location: { lat: () => -6.2, lng: () => 106.816666 },
//       // radius: 200 * 1000,
//     },
//   });

//   useEffect(() => {
//     setValue(coordinate.Address);
//     setSelectedAddress(coordinate.Address);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

 

//   return (
//     <div className="search__combobox" style={{ zIndex: "2" }}>
//       <Combobox
//         onSelect={async (address) => {
//           // set the value when selected
//           setValue(address, false);
//           clearSuggestions();
//           try {
//             const results = await getGeocode({ address });
//             const { lat, lng } = await getLatLng(results[0]);
//             panTo({ lat, lng });

//             setCoordinate((prev) => ({
//               ...prev,
//               Latitude: lat,
//               Longitude: lng,
//             }));
//             setAddress((prev) => ({
//               ...prev,
//               Address: results[0].formatted_address,
//             }));
//             setSelectedAddress(results[0].formatted_address);
//           } catch (error) {
//             console.log(error);
//           }
//         }}
//       >
//         <ComboboxInput
//           value={value}
//           onChange={(e) => {
//             setValue(e.target.value);
//           }}
//           disabled={!ready}
//           placeholder="Enter Address"
//         />
//         <ComboboxPopover>
//           {status === "OK" &&
//             React.Children.toArray(
//               data.map(({ description }) => (
//                 <ComboboxOption value={description} />
//               ))
//             )}
//         </ComboboxPopover>
//       </Combobox>
//     </div>
//   );
// }
