import axios from "axios";
import {API} from "../../redux/api";

export function fetchFAQ() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(`${API}/faq`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      }); 
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchOneFAQ(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(`${API}/faq/${id}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      }); 
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}


export function addFAQ(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data : { response }} = await axios.post(`${API}/faq` ,payload , {
        headers : {
          Authorization: localStorage.getItem("accessToken"),
        }
      })
      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
}

export function updateFAQ(id,payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data : { response }} = await axios.patch(`${API}/faq/${id}` ,payload , {
        headers : {
          Authorization: localStorage.getItem("accessToken"),
        }
      })
      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
}

export function deleteFAQ(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data : { response }} = await axios.delete(`${API}/faq/${id}`  , {
        headers : {
          Authorization: localStorage.getItem("accessToken"),
        }
      })
      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
}
