import * as types from "../../typeExternal";

const initialstate = {
dataEmployeeExternalAll: [],
dataEmployeeAttendanceExternal: {},
loadingDataEmployee: false,
loadingEmployeeAttendance: false,
errorDataEmployee: {}, 
errorEmployeeAttendance: {} 
};

const dataEmployeeExternalReducer = (state = initialstate, action) => {
  switch (action.type) {
    // case types.FETCH_ALL_DATA_EMPLOYEE_BEGIN: {
    //   return {
    //     ...state,
    //     loadingAllDataEmployee: true,
    //   };
    // }
    // case types.FETCH_ALL_DATA_EMPLOYEE_SUCCESS: {
    //   return {
    //     ...state,
    //     dataEmployeeInternal: action.payload,
    //     loadingAllDataEmployee: false,
    //   };
    // }
    // case types.FETCH_ALL_DATA_EMPLOYEE_ERROR: {
    //   return {
    //     ...state,
    //     errorMessage: action.payload,
    //     loadingAllDataEmployee: false,
    //   };
    // }
    //
    case types.FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_BEGIN: {
      return {
        ...state,
        loadingDataEmployee: true,
      };
    }
    case types.FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_SUCCESS: {
      return {
        ...state,
        loadingDataEmployee: false,
        dataEmployeeExternalAll: action.payload
      };
    }
    case types.FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_ERROR: {
      return {
        ...state,
        loadingDataEmployee: false,
        errorDataEmployee: action.payload,
      };
    }
    case types.FETCH_EMPLOYEE_ATTENDANCE_BEGIN: {
      return {
        ...state,
        loadingEmployeeAttendance: true,
      };
    }
    case types.FETCH_EMPLOYEE_ATTENDANCE_SUCCESS: {
      return {
        ...state,
        loadingEmployeeAttendance: false,
        dataEmployeeAttendanceExternal: action.payload
      };
    }
    case types.FETCH_EMPLOYEE_ATTENDANCE_ERROR: {
      return {
        ...state,
        loadingDataEmployee: false,
        errorEmployeeAttendance: action.payload,
      };
    }
    //
    // case types.FETCH_DATA_ATTENDANCE_REPORT_BEGIN: {
    //   return {
    //     ...state,
    //     loadingDataReport: true,
    //   };
    // }
    // case types.FETCH_DATA_ATTENDANCE_REPORT_SUCCESS: {
    //   return {
    //     ...state,
    //     loadingDataReport: false,
    //     dataAttendanceReport: action.payload,
    //   };
    // }
    // case types.FETCH_DATA_ATTENDANCE_REPORT_ERROR: {
    //   return {
    //     ...state,
    //     loadingDataReport: false,
    //     erorrMessageByReport: action.payload,
    //   };
    // }
    // case types.FETCH_DATA_EMPLOYEE_BY_ID: {
    //   return {
    //     ...state,
    //     dataEmployeeInternalById: action.payload,
    //   };
    // }
    // case types.FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_DATE_ID: {
    //   return {
    //     ...state,
    //     dataEmployeeInternalByRangeDateId: action.payload,
    //   };
    // }
    default:
      return state;
  }
};

export default dataEmployeeExternalReducer;
