/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./Draft.css";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import ModalDeleteDraft from "./DeleteDraft/ModalDeleteDraft";
import SearchbarSA from "../../../../utils/SearchbarSA/SearchbarSA";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllDrafts,
  fetchDraftById,
} from "../../../../../redux/actions/SuperAdmin/draftActions";
import LoadingAnimation from '../../../../utils/LoadingAnimation/LoadingAnimation';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFAFA",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: '#FAFAFA',
  // },
  // "&:nth-of-type(even)": {
  //   backgroundColor: '#FAFAFA',
  // },
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
}));

function createData(title, description) {
  return { title, description };
}
const rows = [
  createData(
    "Apa Itu Aplikasi MyWorkSpace",
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit..."
  ),
  createData(
    "Apa Itu Aplikasi MyWorkSpace2",
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit..."
  ),
  createData(
    "Apa Itu Aplikasi MyWorkSpace3",
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit..."
  ),
  createData(
    "Apa Itu Aplikasi MyWorkSpace4",
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit..."
  ),
  createData(
    "Apa Itu Aplikasi MyWorkSpace5",
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit..."
  ),
  createData(
    "Apa Itu Aplikasi MyWorkSpace6",
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit..."
  ),
  createData(
    "Apa Itu Aplikasi MyWorkSpace6",
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit..."
  ),
  createData(
    "Apa Itu Aplikasi MyWorkSpace6",
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit..."
  ),
  createData(
    "Apa Itu Aplikasi MyWorkSpace6",
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit..."
  ),
];

export default function Published({ dataDrafts }) {
  const { loadingDataDraft } = useSelector((state) => state.drafts)
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const [modalDeleteDraft, setModalDeleteDraft] = useState(false);
  const [page, setPage] = useState(0); // set the page from index 0
  const [rowsPerPage, setRowsPerPage] = useState(5); // set deafult row per page
  const [listDrafts, setListDrafts] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDraft, setSelectedDraft] = useState({});

  const handleSearch = (event) => {
    setListDrafts(event.target.value);
  };
  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchTerm(listDrafts);
  };
  // handle untuk pindah ke pagination sebelum atau sesudah
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // handle untuk pindah ke pagination sebelum atau sesudah
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // console.log(handleChangeRowsPerPage, 'cekk row per page')
  // handling the empty rows
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleClickAdd = (e) => {
    e.preventDefault();
    history.push("/superadmin/add-faq");
  };
  const handleEditDraftFAQ = (e) => {
    e.preventDefault();
    history.push("/superadmin/edit-faq-draft");
  };

  const handleDetailFAQ = (e) => {
    e.preventDefault();
    history.push("/superadmin/detail-faq");
  };

  useEffect(() => {
    dispatch(fetchAllDrafts(token));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(dataDrafts[1]._id, "cek data draftss");
  return (
    <div className="container-published">
      <div className="wrapper-published">
        <div className="item-top-published">
          <div className="item-top-published-left">
            <SearchbarSA
              value={listDrafts}
              onChange={handleSearch}
              onSubmit={handleSubmitFilter}
            />
          </div>
          <div className="item-top-published-right">
            <Button
              style={{ backgroundColor: "#1571de" }}
              startIcon={<AddIcon />}
              onClick={handleClickAdd}
            >
              Add FAQ
            </Button>
          </div>
        </div>
        { loadingDataDraft ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 100}}>
        <div style={{ width: 200}}>
          <LoadingAnimation />
        </div>
        </div>
        ) : (
          <div className="" style={{ paddingTop: 20 }}>
          {dataDrafts.length > 0 ? (
            <TableContainer>
              <Table style={{ backgroundColor: "#FAFAFA" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Title</StyledTableCell>
                    <StyledTableCell align="left">Description</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataDrafts
                    .filter((el) =>
                      el.Title.toLowerCase().includes(listDrafts.toLowerCase())
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((draft, index) => (
                      <StyledTableRow key={draft.Title}>
                        <TableCell
                          component="th"
                          scope="row"
                          onClick={() => {
                            dispatch(fetchDraftById(token, draft._id));
                            history.push({
                              pathname: `/superadmin/detail-draft/${draft._id}`,
                              state: {
                                dataDrafts: draft,
                              },
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {draft.Title}
                        </TableCell>
                        <TableCell align="left">{draft.Content}</TableCell>
                        <TableCell align="left">
                          <Typography
                            style={{ color: "#9E9E9E", fontSize: 14 }}
                          >
                            {draft.FAQTime}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            onClick={() => {
                              dispatch(fetchDraftById(token, draft._id));
                              history.push({
                                pathname: `/superadmin/edit-faq-draft/${draft._id}`,
                                state: {
                                  dataDrafts: draft,
                                },
                              });
                            }}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            onClick={(event) => {
                              event.preventDefault();
                              setSelectedDraft(draft);
                            }}
                          >
                            <DeleteIcon style={{ color: "#D1421A" }} />
                          </Button>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          ) : (
            <div>There's no drafts to be saved</div>
          )}
        </div>
        )}
        {selectedDraft._id && (
          <ModalDeleteDraft
            setSelectedDraft={setSelectedDraft}
            pathName="/superadmin/faq"
            selectedDraft={selectedDraft}
          />
        )}
      </div>
    </div>
  );
}
