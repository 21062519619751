import React, { useEffect, useState } from "react";
import "./AddClientsIdLoanMenu.css";
import Radio from "@mui/material/Radio";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Button } from "@mui/material";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { fetchSchemaLoanById } from "../../../../../redux/actions/SuperAdmin/loanActions";
import { updateClient } from "../../../../../redux/actions/SuperAdmin/adminActions";

export default function AddClientsIdLoanMenu({ schemaLoan, client = {} }) {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedLoan, setSelectedLoan] = useState(schemaLoan?._id);

  useEffect(() => {
    setSelectedLoan(client.SchemaLoanId);
  }, [client.SchemaLoanId]);

  const handleSelectedSchema = (loanId) => {
    setSelectedLoan(loanId);
  };

  const handleSave = () => {
    const dataIdNotSet = {
      SchemaLoanId: selectedLoan,
      SchemaLoan: schemaLoan?.find((el) => el._id === selectedLoan).Name,
      id: client._id,
    };
    dispatch(updateClient(dataIdNotSet, token));
    history.push({
      pathname: `/superadmin/client-settings/${client._id}`,
      state: { client: client },
    });
  };

  return (
    <div className="container-ACIEL">
      <div className="wrapper-ACIEL">
        <div className="title-ACIEL">
          <span>Loan Settings</span>
        </div>
        <hr
          style={{ width: "100%", border: "1px solid #ECE6E6", marginTop: 8 }}
        />
        <div className="wrapper-items-selecting-loan">
          {schemaLoan.map((el, index) => {
            return (
              <div className="box-settings-ACIEL" key={index}>
                <div className="item-ACIEL-top">
                  <span>{el.Name}</span>
                  <Radio
                    checked={selectedLoan === el._id}
                    onChange={() => handleSelectedSchema(el._id)}
                  />
                </div>
                <div className="item-ACIEL-main">
                  <span>
                    {el.Description
                      ? el.Description
                      : "Settings view for your clients"}
                  </span>
                </div>
                <div
                  className="item-button-helper"
                  style={{ cursor: "pointer" }}
                >
                  <Button
                    className="item-helper-bottom"
                    style={{ textTransform: "none", paddingBottom: 10 }}
                    onClick={() => {
                      dispatch(fetchSchemaLoanById(token, schemaLoan._id));
                      history.push({
                        pathname: `/superadmin/add-clients-id-loan-view/${client._id}/${el._id}`,
                        state: {
                          dataSchema: schemaLoan,
                        },
                      });
                    }}
                  >
                    <span>View Settings</span>
                    <ArrowRightAltIcon
                      style={{ color: "#1571DE", marginLeft: 8 }}
                    />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="button-bottom-ACIEL">
        <Button
          style={{
            width: 97,
            height: 40,
            background: "#1571de",
            borderRadius: 4,
            color: "white",
            textTransform: "none",
          }}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

