import React, { useEffect, useState } from "react";
import { Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import getDayOffList from "../../../async/dayoff/getDayOff";
import { HolidayTable } from "../../../components/SuperAdmin/_holidaySetting";
import ModalDayOff from "../../../components/SuperAdmin/_holidaySetting/ModalAddDayoff/AddDayoffModal";
import createDayOff from "../../../async/dayoff/createDayOff";

export default function HolidaySettingsPage() {
  const token = localStorage.getItem("accessToken");
  const [dayoffList, setDayoffList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dataToSend, setDataToSend] = useState({
    date: new Date(),
    remark: "",
  });

  const handleGetData = async () => {
    try {
      setLoading(true);
      const data = await getDayOffList(token);
      setLoading(false);
      setDayoffList(
        data.map((el) => ({ ...el, date: new Date(el.date).getTime() }))
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleCreateData = async () => {
    try {
      await createDayOff(token, dataToSend);
      handleGetData();
      setOpenModal(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // const handleSubmit = () => {

  //   handleCreateData();
  // };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Holiday Setup</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          Holiday Setup
        </h2>
      </div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <HolidayTable
            loading={loading}
            datas={dayoffList}
            addDayoff={() => setOpenModal(true)}
          />
        </Grid>
      </Grid>
      <ModalDayOff
        open={openModal}
        setOpen={setOpenModal}
        value={dataToSend}
        setValue={setDataToSend}
        handleSubmit={handleCreateData}
      />
    </Container>
  );
}
