import {
  Button,
  Card,
  Checkbox,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router";
import Breadcrumb from "../../../utils/Breadcrumbs";
import "./AddProductsPromoFMCG.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchbarFMCG from "../../SearchbarFMCG/SearchbarFMCG";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";

const dataProducts = [
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "1",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "2",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "3",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "4",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "5",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "6",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "7",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "8",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "9",
  },
  {
    ProductName: "Butter Salted",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "10",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "11",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "12",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "13",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "14",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "15",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "16",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "17",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "18",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "19",
  },
  {
    ProductName: "Cheese Spread",
    Label: "CSA 24X140 Gr/Ctn",
    Size: "20 x 200",
    Price: "24.200",
    Status: "active",
    Brand: "Arla",
    id: "20",
  },
];

const TABLE_HEAD_PRODUCT = [
  { id: "ProductName", label: "Product Name", alignRight: false },
  { id: "Size", label: "Size", alignRight: false },
  { id: "Brand", label: "Brand", alignRight: false },
  { id: "Price", label: "Price", alignRight: false },
  { id: "Status", label: "Status", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {TABLE_HEAD_PRODUCT.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { numSelected, setListDataProduct, listDataProduct, setSearchTerm } =
    props;

  const handleSearch = (event) => {
    setListDataProduct(event.target.value);
  };

  // const handleSubmitFilter = (e) => {
  //   e.preventDefault();
  //   setSearchTerm(listDataProduct);
  // };

  return (
    <Toolbar
      style={{ padding: 0 }}
      sx={{
        justifyContent: "space-between",
        // ...(numSelected > 0 && {
        //   bgcolor: (theme) =>
        //     alpha(
        //       theme.palette.primary.main,
        //       theme.palette.action.activatedOpacity
        //     ),
        // }),
      }}
    >
      {numSelected > 0 ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <SearchbarFMCG
              value={listDataProduct}
              onChange={handleSearch}
              // onSubmit={handleSubmitFilter}
              placeholder="Search product"
              searchWidth="379px"
            />
          </Typography>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <SearchbarFMCG
              value={listDataProduct}
              onChange={handleSearch}
              // onSubmit={handleSubmitFilter}
              placeholder="Search product"
              searchWidth="379px"
            />
          </Typography>
        </div>
      )}

      {
        numSelected > 0 ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Add Selected">
              <Button
                style={{
                  //   width: 150,
                  height: 36,
                  backgroundColor: "#FAFAFA;",
                  border: "1px solid #D3C9C9",
                  color: "#1571DE",
                  textTransform: "none",
                  borderRadius: 8,
                }}
              >
                {numSelected} Product Selected
              </Button>
              {/* <IconButton>
                    <AddIcon />
                  </IconButton> */}
            </Tooltip>
          </div>
        ) : null
        // <Tooltip title="Add Selected">
        //   <IconButton>
        //     <AddIcon />
        //   </IconButton>
        // </Tooltip>
      }
    </Toolbar>
  );
};

const ListProductsPromoFMCG = ({ location }) => {
  const history = useHistory();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ProductName");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listDataProduct, setListDataProduct] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [values, setValues] = useState(dataProducts);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // setOrderBy(property);
  };

  //   const handleSelectAllClick = (event) => {
  //     setValues((prev) => ({
  //       ...prev,
  //       users: prev?.users?.map((el) => ({
  //         ...el,
  //         Locations: event.target.checked ?
  //         el.Locations.filter((locs) => locs._id !== id).concat(
  //         [
  //           // ...el.Locations,
  //           {
  //             LocationName: values.LocationName,
  //             _id: id
  //           }
  //         ]
  //         )
  //         : el.Locations.filter((loc) => loc._id !== id)
  //       }))
  //     }))
  //   }

  //   const handleClick = (event, locationId) => {
  //     setValues((prev) => ({
  //       ...prev,
  //       users: prev.users.map((el) => el._id === locationId ? {
  //         ...el,
  //         Locations: event.target.checked ? [
  //           ...el.Locations,
  //           {
  //             LocationName: values.LocationName,
  //             _id: id,
  //           }
  //         ] : el.Locations.filter((loc) =>  loc._id !== id),
  //         status: event.target.checked
  //       } : el )
  //     }))
  //   }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataProducts
        .filter(
          (el) =>
            el.ProductName?.toLowerCase().includes(
              listDataProduct.toLowerCase()
            ) || el.Label?.toLowerCase().includes(listDataProduct.toLowerCase())
        )
        .map((n) => n?.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, Name) => {
    const selectedIndex = selected.indexOf(Name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, Name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (location === "addProductPromo") {
      const data = {
        values: values,
      };

      try {
        console.log(data, "add product promo submitted");
        history.push({
          pathname: `/admin-fmcg/setup/promo/add`,
          // state: { location: "addProductPromo" },
        })
      } catch (error) {
        console.log("add promo error");
      }
    }

    if (location === "editProductPromo") {
      const data = {
        values: values,
      };

      try {
        console.log(data, "edit product promo submitted");
        history.push({
          pathname: `/admin-fmcg/setup/promo/edit/1`,
          // state: { location: "editProductPromo" },
        })
      } catch (error) {
        console.log("edit product promo error");
      }
    }
  };

  const isSelected = (ProductName) => selected.indexOf(ProductName) !== -1;
  return (
    <div className="ContainerListProductToSet">
      <div className="WrapperListProductToSet">
        <div className="HeaderListProductToSet">
          <div className="ButtonRouteBack" onClick={() => history.go(-1)}>
            <IconButton>
              <ArrowBackIcon sx={{ color: "#202020", width: 24, height: 24 }} />
            </IconButton>
          </div>
          <div className="WrapperBreadCrumbs">
            <Breadcrumb>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextGrey">Product</span>
              </Button>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextBlue">Add Product</span>
              </Button>
            </Breadcrumb>
          </div>
        </div>
        <div className="HeaderItemTopProductToSet">
          <EnhancedTableToolbar
            numSelected={selected.length}
            listDataProduct={listDataProduct}
            setListDataProduct={setListDataProduct}
            setSearchTerm={setSearchTerm}
          />
        </div>
        {location === "addProductPromo" ? (
          <div className="ContainerTableListProductToSet">
            <Box sx={{ width: "100%" }}>
              <Card>
                <TableContainer>
                  <Table sx={{ width: "100%" }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={
                        dataProducts.filter(
                          (el) =>
                            el.ProductName?.toLowerCase().includes(
                              listDataProduct.toLowerCase()
                            ) ||
                            el.Label?.toLowerCase().includes(
                              listDataProduct.toLowerCase()
                            )
                        ).length
                      }
                    />
                    <TableBody>
                      {stableSort(dataProducts, getComparator(order, orderBy))
                        .filter(
                          (el) =>
                            el.ProductName?.toLowerCase().includes(
                              listDataProduct.toLowerCase()
                            ) ||
                            el.Label?.toLowerCase().includes(
                              listDataProduct.toLowerCase()
                            )
                          //     &&
                          //   (selectedRegion
                          //     ? el.EmployeeArea === selectedRegion
                          //     : true)
                        )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row?.id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row?.Name}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  onClick={(event) =>
                                    handleClick(event, row?.id)
                                  }
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                // align="right"
                                // padding="none"
                              >
                                <Stack
                                  direction="column"
                                  alignItems="left"
                                  spacing={0}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      textTransform: "capitalize",
                                      fontSize: 13,
                                      fontWeight: 500,
                                      color: "#333333",
                                    }}
                                    component="span"
                                  >
                                    {row.ProductName
                                      ? row.ProductName.toLowerCase()
                                      : "-"}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      textTransform: "capitalize",
                                      fontSize: 11,
                                      fontWeight: 400,
                                      color: "#979797",
                                    }}
                                    component="span"
                                  >
                                    {row.Label ? row.Label.toLowerCase() : "-"}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {row?.Size?.toLowerCase()}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {row.Brand ? row.Brand : "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {row.Price ? row.Price : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {row.status === "active" ? (
                                  <Typography
                                    style={{
                                      backgroundColor: "#E4FFE6",
                                      color: "#17B51E",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 8,
                                      height: 26,
                                      width: 58,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#17B51E",
                                      }}
                                    >
                                      Active
                                    </Typography>
                                  </Typography>
                                ) : (
                                  <Typography
                                    style={{
                                      backgroundColor: "#FFDFDD",
                                      // color: "#17B51E",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 8,
                                      height: 26,
                                      width: 74,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#B52117",
                                      }}
                                    >
                                      Unactive
                                    </Typography>
                                  </Typography>
                                )}
                                {/* <Button
                                      onClick={() => {
                                        history.push({
                                          pathname: `/admin-fmcg/edit-employee-fmcg/lkshdo1hdon1owh`,
                                          state: { location: "editEmployee" },
                                        });
                                      }}
                                    >
                                      <EditIcon />
                                    </Button> */}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {/* {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: (dense ? 33 : 53) * emptyRows,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )} */}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography
                  component="div"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      display: "flex",
                      flex: 1,
                    }}
                  >
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      component="div"
                      count={
                        dataProducts.filter(
                          (el) =>
                            el.ProductName?.toLowerCase().includes(
                              listDataProduct.toLowerCase()
                            ) ||
                            el.Label?.toLowerCase().includes(
                              listDataProduct.toLowerCase()
                            )
                        ).length
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Typography>

                  <Typography
                    component="div"
                    style={{
                      display: "flex",
                      flex: 1,
                      columnGap: 10,
                      alignItems: "center",
                      justifyContent: " flex-end",
                      paddingRight: 20,
                    }}
                  >
                    <Button
                      style={{
                        background: "#FFFFF",
                        borderRadius: 5,
                        border: "1px solid #818181",
                        width: 79,
                        height: 34,
                        textTransform: "none",
                        color: "#818181",
                        fontSize: 13,
                        fontWeight: 500,
                      }}
                      onClick={() => history.go(-1)}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        background: "#1571DE",
                        borderRadius: 5,
                        width: 65,
                        height: 34,
                        textTransform: "none",
                        color: "#FFFFFF",
                        fontSize: 13,
                        fontWeight: 500,
                      }}
                      onClick={ handleSubmit }
                    >
                      Save
                    </Button>
                  </Typography>
                </Typography>
              </Card>
            </Box>
            <Card>
              {/* <TableContainer>
                <Table sx={{ width: "100%" }} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={
                      dataProducts.filter(
                        (el) =>
                          el.ProductName?.toLowerCase().includes(
                            listDataProduct.toLowerCase()
                          ) ||
                          el.Label?.toLowerCase().includes(
                            listDataProduct.toLowerCase()
                          )
                      ).length
                    }
                  />
                  <TableBody>
                    {stableSort(dataProducts, getComparator(order, orderBy))
                      .filter(
                        (el) =>
                          el.Name?.toLowerCase().includes(
                            listDataProduct.toLowerCase()
                          ) ||
                          el.Position?.toLowerCase().includes(
                            listDataProduct.toLowerCase()
                          )
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row?._id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row?._id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                onClick={(event) =>
                                  handleClick(event, row?._id)
                                }
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Typography
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {row?.ProductName?.toLowerCase()}
                                </Typography>
                                <Typography
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {row?.Label?.toLowerCase()}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ textTransform: "capitalize" }}
                            >
                              {row?.Client?.toLowerCase()}
                            </TableCell>
                            <TableCell align="left">{row?.Size}</TableCell>
                            <TableCell align="left">{row?.Brand}</TableCell>
                            <TableCell align="left">{row?.Price}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer> */}
              {/* <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={
                  dataProducts.filter(
                    (el) =>
                      el.ProductName?.toLowerCase().includes(
                        listDataProduct.toLowerCase()
                      ) ||
                      el.Label?.toLowerCase().includes(
                        listDataProduct.toLowerCase()
                      )
                  ).length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            </Card>
          </div>
        ) : (
          <div className="ContainerTableListProductToSet">
            <Box sx={{ width: "100%" }}>
              <Card>
                <TableContainer>
                  <Table sx={{ width: "100%" }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={
                        dataProducts.filter(
                          (el) =>
                            el.ProductName?.toLowerCase().includes(
                              listDataProduct.toLowerCase()
                            ) ||
                            el.Label?.toLowerCase().includes(
                              listDataProduct.toLowerCase()
                            )
                        ).length
                      }
                    />
                    <TableBody>
                      {stableSort(dataProducts, getComparator(order, orderBy))
                        .filter(
                          (el) =>
                            el.ProductName?.toLowerCase().includes(
                              listDataProduct.toLowerCase()
                            ) ||
                            el.Label?.toLowerCase().includes(
                              listDataProduct.toLowerCase()
                            )
                          //     &&
                          //   (selectedRegion
                          //     ? el.EmployeeArea === selectedRegion
                          //     : true)
                        )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row?.id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  onClick={(event) =>
                                    handleClick(event, row?.id)
                                  }
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                // align="right"
                                // padding="none"
                              >
                                <Stack
                                  direction="column"
                                  alignItems="left"
                                  spacing={0}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      textTransform: "capitalize",
                                      fontSize: 13,
                                      fontWeight: 500,
                                      color: "#333333",
                                    }}
                                    component="span"
                                  >
                                    {row.ProductName
                                      ? row.ProductName.toLowerCase()
                                      : "-"}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      textTransform: "capitalize",
                                      fontSize: 11,
                                      fontWeight: 400,
                                      color: "#979797",
                                    }}
                                    component="span"
                                  >
                                    {row.Label ? row.Label.toLowerCase() : "-"}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {row?.Size?.toLowerCase()}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {row.Brand ? row.Brand : "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {row.Price ? row.Price : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {row.status === "active" ? (
                                  <Typography
                                    style={{
                                      backgroundColor: "#E4FFE6",
                                      color: "#17B51E",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 8,
                                      height: 26,
                                      width: 58,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#17B51E",
                                      }}
                                    >
                                      Active
                                    </Typography>
                                  </Typography>
                                ) : (
                                  <Typography
                                    style={{
                                      backgroundColor: "#FFDFDD",
                                      // color: "#17B51E",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 8,
                                      height: 26,
                                      width: 74,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#B52117",
                                      }}
                                    >
                                      Unactive
                                    </Typography>
                                  </Typography>
                                )}
                                {/* <Button
                                      onClick={() => {
                                        history.push({
                                          pathname: `/admin-fmcg/edit-employee-fmcg/lkshdo1hdon1owh`,
                                          state: { location: "editEmployee" },
                                        });
                                      }}
                                    >
                                      <EditIcon />
                                    </Button> */}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {/* {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: (dense ? 33 : 53) * emptyRows,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )} */}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography
                  component="div"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      display: "flex",
                      flex: 1,
                    }}
                  >
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      component="div"
                      count={
                        dataProducts.filter(
                          (el) =>
                            el?.ProductName?.toLowerCase().includes(
                              listDataProduct.toLowerCase()
                            ) ||
                            el?.Label?.toLowerCase().includes(
                              listDataProduct.toLowerCase()
                            )
                        ).length
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Typography>

                  <Typography
                    component="div"
                    style={{
                      display: "flex",
                      flex: 1,
                      columnGap: 10,
                      alignItems: "center",
                      justifyContent: " flex-end",
                      paddingRight: 20,
                    }}
                  >
                    <Button
                      style={{
                        background: "#FFFFF",
                        borderRadius: 5,
                        border: "1px solid #818181",
                        width: 79,
                        height: 34,
                        textTransform: "none",
                        color: "#818181",
                        fontSize: 13,
                        fontWeight: 500,
                      }}
                      onClick={() => history.go(-1)}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        background: "#1571DE",
                        borderRadius: 5,
                        width: 65,
                        height: 34,
                        textTransform: "none",
                        color: "#FFFFFF",
                        fontSize: 13,
                        fontWeight: 500,
                      }}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </Typography>
                </Typography>
              </Card>
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListProductsPromoFMCG;
