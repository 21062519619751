import * as types from '../../type'

const initialstate = {
  clients: [],
  clientSet: [],
  clientNotSet: [],
  clientById: {},
  loadingDataClient: false,
  loadingClientsData: false,
  loadingDataClientNotSet: false,
  error: {},
  loadingUpdateClient: false,
  loadingGetClientById: false,
};

const clientReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_CLIENTS_BEGIN: {
      return {
        ...state,
        loadingClientsData: true
      };
    }
    case types.FETCH_ALL_CLIENTS_SUCCESS: {
      return {
        ...state,
        loadingClientsData: false,
        clients: action.payload,
      };
    }
    case types.FETCH_ALL_CLIENTS_ERROR: {
      return {
        ...state,
        loadingClientsData: false,
        error: action.payload
      };
    }
    case types.FETCH_CLIENT_NOT_SET_BEGIN: {
      return {
        ...state,
        loadingDataClientNotSet: true
      };
    }
    case types.FETCH_CLIENT_NOT_SET_SUCCESS: {
      return {
        ...state,
        loadingDataClientNotSet: false,
        clientNotSet: action.payload,
      };
    }
    case types.FETCH_CLIENT_NOT_SET_ERROR: {
      return {
        ...state,
        loadingDataClientNotSet: false,
        error: action.payload
      };
    }
    case types.FETCH_CLIENT_SET_BEGIN: {
      return {
        ...state,
        loadingDataClient: true
      };
    }
    case types.FETCH_CLIENT_SET_SUCCESS: {
      return {
        ...state,
        loadingDataClient: false,
        clientSet: action.payload,
      };
    }
    case types.FETCH_CLIENT_SET_ERROR: {
      return {
        ...state,
        error: action.payload,
        loadingDataClient: false
      };
    }
    case types.SET_CLIENT_ATTENDANCES: {
      return {
        ...state,
      };
    }
    case types.SET_ACTIVE_CLIENT: {
      return {
        ...state,
      };
    }
    case types.FETCH_CLIENT_BY_ID_BEGIN: {
      return {
        ...state,
        loadingGetClientById: true
      }
    }
    case types.FETCH_CLIENT_BY_ID_SUCCESS: {
      return {
        ...state,
        clientById: action.payload,
        loadingGetClientById: false,
      };
    }
    case types.FETCH_CLIENT_BY_ID_ERROR: {
      return {
        ...state,
        loadingGetClientById: false,
      }
    }

    case types.EDIT_CLIENT_BEGIN: {
      return {
        ...state,
        loadingUpdateClient: true
      }
    }
    case types.EDIT_CLIENT_SUCCESS: {
      return {
        ...state,
        loadingUpdateClient: false
      }
    }
    case types.EDIT_CLIENT_ERROR: {
      return {
        ...state,
        loadingUpdateClient: false
      }
    }
    // case "EDIT_CLIENT":
    //   const updatedClientDetail = action.payload;
    //   const updatedClient = state.client.map((user) => {
    //     if (user.id === updatedClientDetail.id) {
    //       return updatedClientDetail;
    //     } else {
    //       return user;
    //     }
    //   });
    //   return { users: updatedClient };

    // case "REMOVE_CLIENT":
    //   return {
    //     ...state,
    //   };
    default:
      return state;
  }
};

export default clientReducer;
