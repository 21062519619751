import React from "react";
import success_published from "../../../../assets/images/email-send.png";
import { Button } from "@mui/material";

export default function ModalSuccessUpload({ modalUpload, setModalUpload  ,title, text}) {
  const handleClose = () => {
    setModalUpload(false);
    window.location.reload();
  }
  return (
    <div className="modal-MSS">
      <div className="modalContainer-modal-tabs-delete-client">
        <div className="titleCloseBtn-ePay">
          <div className="items__fill">dummy</div>
          <div className="text__main-ePay"></div>
          <div className="item__button-right">
            <button
              onClick={() => {
                setModalUpload(false);
              }}
            ></button>
          </div>
        </div>
        <div>{/* <hr style={{ width: "100%", color: "#ECE6E6" }} /> */}</div>
        <div className="body-attendance-modal">
          <div className="img-item-main">
            <img src={success_published} alt="" />
          </div>
          <div
            className="text-main-modal-tabs-settings"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span
              style={{
                fontSize: 20,
                fontWeight: 500,
                color: "#0a0a0a",
                paddingTop: 0,
              }}
            >
              {title}
            </span>
            <div className="text-main-modal-tabs-settings">
              <span style={{ fontSize: 12, fontWeight: 400, color: "#757575" }}>
                {text}
              </span>
            </div>
          </div>
          <div className="button-wrapper-modal-tabs-settings">
            <div className="button-delete-modal-tabs-settings">
              <Button
                style={{ backgroundColor: "#1571de" }}
                onClick={handleClose}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
