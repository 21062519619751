import React from "react";
import Styles from"./Attendance-AddScheme.module.css";
import {  Button,  Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppAddScheme } from "../../../../components/SuperAdmin/_attendance";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { useHistory } from "react-router";
export default function AttendanceAddScheme() {
  const history = useHistory();
  return (
    <div maxWidth="xl" className={Styles.Container}>
      <div className={Styles.ContainerSetting}>
        <Helmet>
          <title>MyWorkSpace | Attendance</title>
        </Helmet>
        <div className={Styles.HeaderSetting}>
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/attendance")}
              style={{ textTransform: "none" }}
            >
              <span>Attendance</span>
            </Button>
            <span>Add Scheme</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppAddScheme />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
