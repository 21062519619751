let id = 0;
const createData = (CustomerName, ProductName, Store, Date) => {
  id += 1;
  return { id, CustomerName, ProductName, Store, Date };
};

const rows =  [
  createData("Putra Setiawan", "Teh Botol", "UD Sinar Jaya", "31 Desember 2021"),
  createData("Putra Setiawan", "Teh Botol", "UD Sinar Jaya", "31 Desember 2021"),
  createData("Putra Setiawan", "Teh Botol", "UD Sinar Jaya", "31 Desember 2021"),
  createData("Putra Setiawan", "Teh Botol", "UD Sinar Jaya", "31 Desember 2021"),
  createData("Putra Setiawan", "Teh Botol", "UD Sinar Jaya", "31 Desember 2021"),
];

export default rows;
