import * as types from '../../type'

const initialstate = {
  users: [],
  userById: {},
  loadingAllUsers: false,
  loadingRegister: false,
  errorMessage: {},
};

const userAdminReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_USERS_BEGIN: {
      return {
        ...state,
        loadingAllUsers: true
      };
    }
    case types.FETCH_ALL_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload,
        loadingAllUsers: false,
      };
    }
    case types.FETCH_ALL_USERS_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        loadingAllUsers: false
      };
    }
    case types.GET_USER_ADMIN_BY_ID: {
        return {
            ...state,
            userById: action.payload
        }
    }
    // case types.REGISTER_USER_ADMIN_ERROR : {
    //   return {
    //     errorMessageRegister : action.payload,
    //     loadingRegister: false
    //   }
    // }
    // case types.FETCH_ALL_EMPLOYEE_BY_ID: {
    //   return {
    //     ...state,
    //     employeeById: action.payload.response,
    //   };
    // }
    default:
      return state;
  }
};

export default userAdminReducer;
