import axios from "axios";
import {API} from "../../redux/api";

const fetchPawns = (token, startDate, endDate) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data: { response }}  = await axios.get(`${API}/ebp/admin?startDate=${startDate}&endDate=${endDate}`, {
        headers : {
          Authorization: token
        }
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export default fetchPawns;
