/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useState } from "react";
import {API} from "../../../redux/api";

function UseGroupName() {
  const [groupName, setGroupName] = useState([]);
  const token = localStorage.getItem('accessToken');
  // useEffect(() => {
  //   axios
  //   .get(`${API}/auth/list-filter`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((res) => setGroupName(res.data.data.client))
  //     .catch((err) => console.log(err));
  // }, []);
  return groupName;
}

export default UseGroupName;
