/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./UserDetailMenu.css";
import logo_user from "../../../../assets/images/logo-user.png";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ModalDeleteUser from "../modalAddUserSA/modal-delete-user/ModalDeleteUser";
import ModalSettingUser from "../modalAddUserSA/modal-setting-user/ModalSettingUser";

let EMPLOYEE = [],
  // STATUSES = ["Internal", "Eksternal"];
  NAMES = [
    "Jaka Saputra",
    "Jack Empire",
    "Layla Clapton",
    "Layla Clapton",
    "Putri Tsunade",
  ],
  CLIENT = [
    "Pt Arla",
    "PT Loreal",
    "PT Samsung Indonesia",
    "PT Vission Plus",
    "PT Sabang Subur",
  ],
  USERNAME = [
    "putrafitria",
    "ridwansaputra",
    "putribianca",
    "jenny",
    "intanviviana",
  ],
  PASSWORD = [
    "12488473727",
    "12488473727",
    "12488473727",
    "12488473727",
    "12488473727",
  ],
  STATUSES = ["Internal", "Eksternal"];

// (POSITION = [
//   "Product Designer",
//   "Product Designer",
//   "Front end Engineer",
//   "Back End Engineer",
//   "Product Manager",
// ]),
// (ATTEND = ["22 Days", "22 Days", "22 Days", "22 Days", "20Days"]),
// (LEAVE = ["0 Days", "0 Days", "0 Days", "0 Days", "2 Days"]),
// (SICK = ["0 Days", "0 Days", "0 Days", "0 Days", "0 Days"]),
// (OFF = ["0 Days", "0 Days", "0 Days", "0 Days", "0 Days"]),
// (RATE = ["90%", "90 %", "90 %", "90 %", "90 %"]);

for (let i = 0; i < 5; i++) {
  EMPLOYEE[i] = {
    name: NAMES[i],
    client: CLIENT[i],
    username: USERNAME[i],
    password: PASSWORD[i],
    status: STATUSES[Math.floor(Math.random() * STATUSES.length)],
  };
}

export default function UserDetailMenu({ selected, setSelected }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [modalDeleteUser, setModalDeleteUser] = useState(false);
  const [modalSettingUser, setModalSettingUser] = useState(false);
  const [selectedAddUserModalComp, setSelectedAdduserModalComp] = useState("");

  const options = ["All", "Type", "Client"];

  return (
    <div className="container__user-detail-menu">
      <div className="wrapper-user-detail-menu-download">
        <div className="user__detail-left">
          <input
            type="text"
            placeholder="Search"
            // onChange={(e) => {
            //   setSearchTerm(e.target.value);
            // }}
          />
          <i className="bx bx-search"></i>
        </div>
        <div className="data-attendance-menu-right">
          <div className="box-dropdown-user-detail-menu">
            <div
              className="dropdown-btn-user-detail"
              onClick={(e) => setIsActive(!isActive)}
            >
              <option>{selected}</option>
              <KeyboardArrowDownIcon />
            </div>
            {isActive && (
              <div className="dropdown-content-user-detail">
                {options.map((option) => (
                  <div
                    onClick={(e) => {
                      setSelected(option);
                      setIsActive(false);
                    }}
                    className="dropdown-item-user-detail"
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <Link to="/add-user" className="data-attendance-menu-right-download">
          <div className="data-attendance-menu-right-download">
            <Button
              startIcon={<AddIcon style={{ color: "white" }} />}
              //   className="button__add-store-main-client"
              style={{
                backgroundColor: "#1571DE",
                textTransform: "none",
              }}
            >
              <span style={{ fontSize: 14, color: "white" }}>Add User</span>
            </Button>
          </div>
        </Link>
      </div>
      <div className="wrapper-table-user-detail-menu">
        <Table
          sx={{
            width: "100%",
            maxHeight: "100%",
            backgroundColor: "#fafafa",
            borderRadius: 4,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Emloyee Name</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Password</TableCell>
              <TableCell>Admin Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {EMPLOYEE.filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.name.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              }
            }).map((row) => (
              <TableRow key={row.name}>
                <TableCell>
                  <Grid container>
                    <Grid item xs={2} md={4}>
                      <img
                        src={logo_user}
                        alt={row.name}
                        sx={{ width: 26, height: 26 }}
                      />
                    </Grid>
                    <Grid item xs={6} md={8} style={{ paddingTop: 10 }}>
                      {row.name}
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>{row.client}</TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>{row.password}</TableCell>
                <TableCell>
                  <Typography
                    className="status__style-user-detail"
                    style={{
                      backgroundColor:
                        (row.status === "Internal" && "#255BFC") ||
                        (row.status === "Eksternal" && "#498E0F"),
                    }}
                  >
                    {row.status}
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  {/* <Button
                    style={{
                      textTransform: "none",
                      fontSize: 16,
                      fontWeight: 500,
                      color: "#1571DE",
                    }}
                  >
                    Lihat Detail
                  </Button> */}
                  <Button onClick={() => setModalSettingUser(true)}>
                    <SettingsIcon style={{ color: "#757575" }} />
                  </Button>
                  {modalSettingUser && (
                    <ModalSettingUser
                      modalSettingUser={modalSettingUser}
                      setModalSettingUser={setModalSettingUser}
                      selectedAddUserModalComp={selectedAddUserModalComp}
                      setSelectedAdduserModalComp={setSelectedAdduserModalComp}
                      // onClick={() => removeClient(row._id)}
                    />
                  )}
                  <Button
                    // onClick={() => setModalDeleteClient(true)}
                    onClick={() => setModalDeleteUser(true)}
                  >
                    <DeleteOutlineIcon style={{ color: "#D1421A" }} />
                  </Button>
                  {modalDeleteUser && (
                    <ModalDeleteUser
                      modalDeleteUser={modalDeleteUser}
                      setModalDeleteUser={setModalDeleteUser}
                      // onClick={() => removeClient(row._id)}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
