import React from "react";
import "./HeaderItem.css";
export default function HeaderItem({ initial }) {
  return (
    <div className="header__item">
      <div className="box__yellow"></div>
      <span>{initial}</span>
    </div>
  );
}
