import * as types from "../../type";

const initialstate = {
  dataEmployeeInternal: [],
  dataEmployeeInternalById: {},
  dataEmployeeInternalAll: [],
  dataEmployeeInternalByRangeDateId: [],
  dataAttendanceReport: [],
  loadingAllDataEmployee: false,
  loadingAllDataEmployeeByRange: false,
  loadingDataReport: false,
  errorMessage: {},
  errorMessageByRange: {},
  erorrMessageByReport: {}
};

const dataEmployeeInternalReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_DATA_EMPLOYEE_BEGIN: {
      return {
        ...state,
        loadingAllDataEmployee: true,
      };
    }
    case types.FETCH_ALL_DATA_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        dataEmployeeInternal: action.payload,
        loadingAllDataEmployee: false,
      };
    }
    case types.FETCH_ALL_DATA_EMPLOYEE_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        loadingAllDataEmployee: false,
      };
    }
    //
    case types.FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_BEGIN: {
      return {
        ...state,
        loadingAllDataEmployeeByRange: true,
        // dataEmployeeInternalReducer: []
      };
    }
    case types.FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_SUCCESS: {
      return {
        ...state,
        dataEmployeeInternalAll: action.payload,
        loadingAllDataEmployeeByRange: false,
      };
    }
    case types.FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_ERROR: {
      return {
        ...state,
        errorMessageByRange: action.payload,
        loadingAllDataEmployeeByRange: false,
      };
    }
    //
    case types.FETCH_DATA_ATTENDANCE_REPORT_BEGIN: {
      return {
        ...state,
        loadingDataReport: true,
      };
    }
    case types.FETCH_DATA_ATTENDANCE_REPORT_SUCCESS: {
      return {
        ...state,
        loadingDataReport: false,
        dataAttendanceReport: action.payload,
      };
    }
    case types.FETCH_DATA_ATTENDANCE_REPORT_ERROR: {
      return {
        ...state,
        loadingDataReport: false,
        erorrMessageByReport: action.payload,
      };
    }
    case types.FETCH_DATA_EMPLOYEE_BY_ID: {
      return {
        ...state,
        dataEmployeeInternalById: action.payload,
      };
    }
    case types.FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_DATE_ID: {
      return {
        ...state,
        dataEmployeeInternalByRangeDateId: action.payload,
      };
    }
    case types.CLEAR_DATA : {
      return {
        ...state,
      }
    }
    default:
      return state;
  }
};

export default dataEmployeeInternalReducer;
