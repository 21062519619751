export const SET_SNACKBAR = "SET_SNACKBAR";

const initialState = {
  snackbarOpen: false,
  snackbarType: "success",
  snackbarMessage: "",
};

const snackbarReducer =   (state = initialState, action) => {
  switch (action.type) {
    case SET_SNACKBAR:
      // const { snackbarOpen, snackbarMessage, snackbarType } = action.payload || { };
      return {
        ...state,
        ...action.payload,
        // snackbarOpen,
        // snackbarType,
        // snackbarMessage
      };
    default:
      return state;
  }
};

export default snackbarReducer;
