import { Backdrop, Fade, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Styles from "./ActivityModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import DESCRIPTION_ICON from "../../../../assets/images/modal-activity-description.png";
import DATE_ICON from "../../../../assets/images/modal-activity-calendar.png";
import CLOCK_ICON from "../../../../assets/images/modal-activity-clock.png";
import COMPANY_ICON from "../../../../assets/images/modal-activity-client.png";
import USER_ICON from "../../../../assets/images/modal-activity-user.png";
import LOCATION_ICON from "../../../../assets/images/modal-activity-location.png";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "@media screen and (max-width: 478px)": {
    bottom: 0,
    top: "auto",
    transform: "translate(-50%, 0)",
  },
};

const modalStyle = {
  "@media screen and (max-width: 1000px)": {
    display: "none",
  },
};

export default function ActivityModal(props) {
  const { isActive, onClose, data } = props;
  return (
    <Modal
      open={isActive}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={modalStyle}
    >
      <Fade in={isActive}>
        <Box sx={style}>
          <div className={Styles.Body}>
            <div className={Styles.Heading}>
              <span>Activity Detail</span>
              <CloseIcon sx={{ fontSize: 24, cursor: 'pointer' }} onClick={ () => onClose(false) }/>
            </div>
            <div className={Styles.Desc}>
              <div className={Styles.ValueControl}>
                <div className={Styles.ValueLeft}>
                  <img src={DESCRIPTION_ICON} alt="description" />
                  <span>Description</span>
                </div>
                <div className={Styles.ValueRight}>
                  <span>
                    { data.remark ? data.remark : null }
                  </span>
                </div>
              </div>

              <div className={Styles.ValueControl}>
                <div className={Styles.ValueLeft}>
                  <img src={DATE_ICON} alt="description" />
                  <span>Date</span>
                </div>
                <div className={Styles.ValueRight}>
                  <span>
                    { data.createdAt ? moment( new Date(data.createdAt).getTime()).format('DD MMMM YYYY') : null }
                  </span>
                </div>
              </div>

              <div className={Styles.ValueControl}>
                <div className={Styles.ValueLeft}>
                  <img src={CLOCK_ICON} alt="description" />
                  <span>Time</span>
                </div>
                <div className={Styles.ValueRight}>
                  <span>
                  { data.createdAt ? moment( new Date(data.createdAt).getTime()).format('LT') : null }
                  </span>
                </div>
              </div>

              <div className={Styles.ValueControl}>
                <div className={Styles.ValueLeft}>
                  <img src={COMPANY_ICON} alt="description" />
                  <span>Client Name</span>
                </div>
                <div className={Styles.ValueRight}>
                  <span>
                  { data.participant ?  data.participant?.clientName : null }
                  </span>
                </div>
              </div>

              <div className={Styles.ValueControl}>
                <div className={Styles.ValueLeft}>
                  <img src={USER_ICON} alt="description" />
                  <span>User client</span>
                </div>
                <div className={Styles.ValueRight}>
                  <span>
                    { data.participant ?  data.participant?.userName : null }
                  </span>
                </div>
              </div>

              <div className={Styles.ValueControl}>
                <div className={Styles.ValueLeft}>
                  <img src={LOCATION_ICON} alt="description" />
                  <span>Location</span>
                </div>
                <div className={Styles.ValueRight}>
                  <span>
                    { data.address ? data.address : null }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
