import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import Styles from "./ReportSPG.module.css";
import { makeStyles } from "@mui/styles";
import ActivityReport from "./ActivityReport/ActivityReport";
import DisplayReport from "./DisplayReport/DisplayReport";
import PromoReport from "./PromoReport/PromoReport";
import CustomerReport from "./CustomerReport/CustomerReport";
import SellOutReport from "./SellOutReport/SellOutReport";
import SamplingReport from "./SamplingReport/SamplingReport";
import ServiceLevelReport from "./ServiceLevelReport/ServiceLevelReport";
import { debounce } from "lodash";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  TabStyle: {
    padding: 0,
    marginTop: 30,
  },
}));

export default function ReportSPG() {
  const classes = useStyles();
  // const location = useLocation();
  const [value, setValue] = useState("1");
  const [listEmployeesFMCG, setListEmployeesFMCG] = useState("");
  const [valueStartDate, setValueStartDate] = useState(moment().format("L"));
  const [valueEndDate, setValueEndDate] = useState(moment().format("L"));
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  const dateValueStart = useMemo(() => {
    let sessionStartDate = window.sessionStorage.getItem("startDate");
    let firstDate;

    if (sessionStartDate) {
      firstDate = sessionStartDate;
    } else {
      firstDate = valueStartDate;
    }

    return firstDate;
  }, [valueStartDate]);

  const dateValueEnd = useMemo(() => {
    let sessionEndDate = window.sessionStorage.getItem("endDate");
    let secondDate;

    if (sessionEndDate) {
      secondDate = sessionEndDate;
    } else {
      secondDate = valueEndDate;
    }

    return secondDate;
  }, [valueEndDate]);

  const handleSearch = (e) => {
    console.log(e.target.value);
    setListEmployeesFMCG(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const persistValue = useMemo(() => {
    let selected = window.sessionStorage.getItem("selectedValue");
    let result;

    if (selected) {
      result = selected;
    } else {
      result = value;
    }
    return result;
  }, [value]);

  useEffect(() => {
    setValue(persistValue);
  }, [persistValue]);

  useEffect(() => {
    setValueStartDate(
      moment(new Date(dateValueStart).getTime()).format("YYYY-MM-DD")
    );
    setValueEndDate(
      moment(new Date(dateValueEnd).getTime()).format("YYYY-MM-DD")
    );
  }, [dateValueStart, dateValueEnd]);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    window.sessionStorage.setItem("selectedValue", newValue);
  };

  const handleChangeStartDate = (newValue) => {
    setValueStartDate(newValue);
    window.sessionStorage.setItem("startDate", newValue);
  };

  const handleChangeEndDate = (newValue) => {
    setValueEndDate(newValue);
    window.sessionStorage.setItem("endDate", newValue);
  };

  return (
    <div className={Styles.Container}>
      <TabContext value={value}>
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px  solid #E9E9E9",
          }}
        >
          <TabList
            onChange={handleChange}
            sx={{
              "& button:hover": {
                color: "#1571DE",
              },
              "& button:focus": {
                color: "#1571DE",
              },
              "& button:active": {
                color: "#1571DE",
              },
            }}
          >
            <Tab
              label={<span className={Styles.Tab}>Activity</span>}
              value="1"
              sx={{
                color: "#A2A9BE",
              }}
            />
            <Tab
              label={<span className={Styles.Tab}>Display</span>}
              value="2"
              sx={{
                color: "#A2A9BE",
              }}
            />
            <Tab
              label={<span className={Styles.Tab}>Promo</span>}
              value="3"
              sx={{
                color: "#A2A9BE",
              }}
            />
            <Tab
              label={<span className={Styles.Tab}>Customer</span>}
              value="4"
              className={Styles.Tab}
              sx={{
                color: "#A2A9BE",
              }}
            />
            <Tab
              label={<span className={Styles.Tab}>SellOut</span>}
              value="5"
              sx={{
                color: "#A2A9BE",
              }}
            />
            <Tab
              label={<span className={Styles.Tab}>Sampling</span>}
              value="6"
              sx={{
                color: "#A2A9BE",
              }}
            />
            <Tab
              label={<span className={Styles.Tab}>Service Level</span>}
              value="7"
              sx={{
                color: "#A2A9BE",
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="1" className={classes.TabStyle}>
          <ActivityReport
            searchValue={listEmployeesFMCG}
            onSearch={debouncedResults}
            valueTimeFrom={dateValueStart}
            valueTimeTo={dateValueEnd}
            onChangeStartDate={handleChangeStartDate}
            onChangeEndDate={handleChangeEndDate}
            loading={isLoading}
          />
        </TabPanel>
        <TabPanel value="2" className={classes.TabStyle}>
          <DisplayReport
            searchValue={listEmployeesFMCG}
            onSearch={debouncedResults}
            valueTimeFrom={dateValueStart}
            valueTimeTo={dateValueEnd}
            onChangeStartDate={handleChangeStartDate}
            onChangeEndDate={handleChangeEndDate}
            loading={isLoading}
          />
        </TabPanel>{" "}
        <TabPanel value="3" className={classes.TabStyle}>
          <PromoReport />
        </TabPanel>{" "}
        <TabPanel value="4" className={classes.TabStyle}>
          <CustomerReport />
        </TabPanel>{" "}
        <TabPanel value="5" className={classes.TabStyle}>
          <SellOutReport />
        </TabPanel>
        <TabPanel value="6" className={classes.TabStyle}>
          <SamplingReport />
        </TabPanel>{" "}
        <TabPanel value="7" className={classes.TabStyle}>
          <ServiceLevelReport />
        </TabPanel>
      </TabContext>
    </div>
  );
}
