/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { filter } from "lodash";
import { Helmet } from "react-helmet";
import DownloadIcon from "@mui/icons-material/Download";
import logo_MyWorkSpace from "../../../../assets/images/my-workspace.png";
import {
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import USERLIST from "../../../../components/SuperAdmin/_user/TableUsers/_mocks/user";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ExportCSV from "../../../../components/SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import DateRangePickerComp from "../../../../components/SuperAdmin/_attendance/date-range-picker/DateRangePicker";
import DropdownAttReport from "../../../SuperAdmin/AttendanceSA/AttendanceData/AttendaceReportSA/DropdownAttReport/DropdownAttReport";
import Scrollbar from "../../../../components/SuperAdmin/_user/TableUsers/Scrollbar";
import UserListHead from "../../../../components/SuperAdmin/_user/TableUsers/UserListHead";
import { fetchAllDataEmployeeByRange } from "../../../../redux/actions/SuperAdmin/dataEmployeeInternalActions";
import moment from "moment";
import "moment/min/locales";
import DateRangePickerComponent from "../../../../components/utils/DateRangePicker/DateRangePicker";
import { UseCompanyName } from "../../../../components/utils/hooks";
import { makeStyles } from "@mui/styles";
// moment.locale("id");

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
  },
});

const CompanyGroup = [
  { name: "PT Permata Indonesia", code: "AF" },
  // { name: "PT Samsung", code: "AF" },
  // { name: "PT Telkom Indonesia", code: "AF" },
  // { name: "PT Sinar Dunia", code: "AF" },
];

const TABLE_HEAD = [
  { id: "name", label: "Employee Name", alignRight: false },
  { id: "position", label: "Position", alignRight: false },
  { id: "attend", label: "Attend", alignRight: false },
  { id: "sick", label: "Sick", alignRight: false },
  { id: "leave", label: "Leave", alignRight: false },
  // { id: "other", label: "Other", alignRight: false },
  //   { id: "" },
];
// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AttendanceReport() {
  const dispatch = useDispatch();
  const getCompany = UseCompanyName();
  const classes = useStyles();
  // const location = useLocation();
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const [groupValue, setGroupValue] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState("");
  const [filterName, setFilterName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const startDate = new Date(1648141200000);
  const endDate = new Date(1649869200000);
  const [valueDate, setValueDate] = useState({});
  const [valueStartDate, setValueStartDate] = useState(startDate);
  const [valueEndDate, setValueEndDate] = useState(endDate);
  const employeeInternal = useSelector((state) => state.employeeInternal.dataEmployeeInternalAll);
  const dataEmployee = employeeInternal;
  // console.log(employeeInternal, 'cek employe internal')

  useEffect(() => {
    dispatch(
      fetchAllDataEmployeeByRange(
        token,
        selected,
        new Date(valueStartDate).getTime(),
        new Date(valueEndDate).getTime()
      )
    );
    // console.log(valueDate.startDate.getTime(), 'cek date')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueStartDate, valueEndDate]);

  const handleChangeStartDate = (newValue) => {
    setValueStartDate(newValue);
  };
  const handleChangeEndDate = (newValue) => {
    setValueEndDate(newValue);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  // function formatAMPM(date) {
  //   let hours = date.getHours();
  //   let minutes = date.getMinutes();
  //   let ampm = hours >= 12 ? 'pm' : 'am';
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'
  //   minutes = minutes < 10 ? '0'+minutes : minutes;
  //   let strTime = hours + ':' + minutes + ' ' + ampm;
  //   return strTime;
  // }

  const dataTest = () => {
    const array = [];
    for (let i = 0; i < dataEmployee?.length; i++) {
      const absences = dataEmployee[i]?.Absences;
      for (let j = 0; j < absences?.length; j++) {
        array.push({
          ...dataEmployee[i],
          timeIn: moment(absences[j].DateTimeIn).format("HH:mm:ss"),
          timeOut:
            absences[j].DateTimeOut && absences[j].DateTimeOut !== "-"
              ? moment(absences[j].DateTimeOut).format("HH:mm:ss")
              : "-",
          dateTimeIn: moment(absences[j].DateTimeIn).format("L"),
          // dateTimeOut: moment(absences[j].DateTimeOut).format('L'),
          dateTimeOut:
            absences[j].DateTimeOut && absences[j].DateTimeOut !== "-"
              ? moment(absences[j].DateTimeOut).format("L")
              : "-",
          longitude: absences[j].Longitude ? absences[j].Longitude : "-",
          latitude: absences[j].Latitude ? absences[j].Latitude : "-",
        });
      }
    }
    return array;
  };
  const dataToExport = dataTest();
  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );
  return (
    <div className="container-att-report">
      <Helmet>
        <title>MyWorkSpace | Attendance Report</title>
      </Helmet>
      <div className="wrapper-att-report">
        <div className="item-top-att-report">
          <div
            className="item-left-att-report"
            onClick={() => history.push("/admin/attendance-external")}
          >
            <img src={logo_MyWorkSpace} alt="" />
            <span>MyWorkSpace</span>
          </div>
          <div className="item-mid-att-report">
            <span>Attendance Report</span>
          </div>
          <div className="item-right-att-report">
            <ExportCSV
              data={dataToExport
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((el, ind) => ({
                  "No. ": ind + 1,
                  Nama: el.Name,
                  Position: el.Position,
                  Nrk: el.Nrk,
                  "Total Attend": el.totalPresence,
                  "Total Sick": el.totalSickPermit,
                  "Total Leave": el.totalAnnualLeave,
                }))}
              filename={`Attendance Report ${new Date().toLocaleDateString()}`}
              initial="Download"
              endIcon={<DownloadIcon />}
            />
          </div>
        </div>
        <div className="date-picker-shown-att-report">
          <div className="wrapper-date-picker-shown-att-report">
            <DateRangePickerComponent
              // valueStartDate={valueStartDate}
              // valueEndDate={valueEndDate}
              // handleChangeStartDate={handleChangeStartDate}
              // handleChangeEndDate={handleChangeEndDate}
            />
          </div>
        </div>
        <div className="company-att-report">
          <div className="title-company-att-report">
            <label htmlFor="">Group</label>
            <FormControl fullWidth size="small" style={{ width: "100%" }}>
              <Select
                className={classes.selectBorder}
                value={selected}
                displayEmpty
                onChange={(e) => setSelected(e.target.value)}
              >
                <MenuItem value="">Select Group</MenuItem>
                {getCompany.map((option, el) => (
                  <MenuItem
                    key={el}
                    onClick={() => setSelectedGroup(option)}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <hr />
        <div className="desc-item-loan-report">
          <div className="mini-item-left-att-report">
            <label>Attendance Rate</label>
            <span>90 Employee / Day</span>
          </div>
          <div className="border-seperator"></div>
          <div className="mini-item-middle-att-report">
            <label>Total Sick</label>
            <span>5 Employee</span>
          </div>
          <div className="border-seperator"></div>
          <div className="mini-item-right-att-report">
            <label>Total Leave</label>
            <span>5 Employee</span>
          </div>
          <div className="border-seperator"></div>
          <div className="mini-item-right-att-report">
            <label>Total Alpha</label>
            <span>0 Employee</span>
          </div>
        </div>
        <div className="table-att-report">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table
                sx={{
                  width: "100%",
                  maxHeight: "100%",
                  backgroundColor: "#fafafa",
                  borderRadius: 4,
                }}
              >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  // rowCount={USERLIST.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(USERLIST, getComparator(order,orderBy))
                     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                     .map((employee, id) => {
                       return (
                         <TableRow hover key={id}>
                           <TableCell></TableCell>
                           <TableCell component="th" scope="row" padding="none">
                             <Stack
                               direction="row"
                               alignItems="center"
                               spacing={2}
                             >
                               <Avatar
                                 alt={employee.Name}
                                 src={
                                   employee.ProfilePicture
                                     ? employee.ProfilePicture
                                     : ""
                                 }
                               />
                               <Typography variant="subtitle2" noWrap>
                                 {employee.Name}
                               </Typography>
                             </Stack>
                           </TableCell>
                           <TableCell align="left">
                             {employee.Position}
                           </TableCell>
                           <TableCell align="left">
                             {employee.totalPresence} Days
                           </TableCell>
                           <TableCell align="left">
                             {employee.totalAnnualLeave} Days
                           </TableCell>
                           <TableCell align="left">
                             {employee.totalSickPermit} Days
                           </TableCell>
                           {/* <TableCell align="left">0 Days</TableCell> */}
                         </TableRow>
                       );
                     })
                  }
                  {/* {dataEmployee
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((employee, id) => {
                      return (
                        <TableRow hover key={id}>
                          <TableCell>{id + 1}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                alt={employee.Name}
                                src={
                                  employee.ProfilePicture
                                    ? employee.ProfilePicture
                                    : ""
                                }
                              />
                              <Typography variant="subtitle2" noWrap>
                                {employee.Name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {employee.Position}
                          </TableCell>
                          <TableCell align="left">
                            {employee.totalPresence} Days
                          </TableCell>
                          <TableCell align="left">
                            {employee.totalAnnualLeave} Days
                          </TableCell>
                          <TableCell align="left">
                            {employee.totalSickPermit} Days
                          </TableCell>
                        </TableRow>
                      );
                    })} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 35, 45]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
}
