import axios from "axios";
import {API} from "../../redux/api";

const deleteLeavePlan = (token,id) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { response },
      } = await axios({
        method: "delete",
        headers: { Authorization: `${token}` },
        url: `${API}/leave-plan/${id}`,
      });
      resolve(response);
      // console.log(response)
    } catch (error) {
      reject(error);
    }
  });

export default deleteLeavePlan;
