/*eslint-disable no-unused-vars*/

import { Button } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import fetchPawnById from "../../../../async/employeeeBenefit/fetchPawnById";
import { PawnDetailAdminMWSLayout } from "../../../../components/AdminMWS";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";

export default function PawnDetail() {
  const token = localStorage.getItem("accessToken");
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dataInfo = location?.state?.data;
  const [pawn, setPawn]= useState();
  const loanDataById = useMemo(() => {
    return location?.state?.data;
  }, [location]);

  useEffect(() => {
    (async () => {
      try {
        const data = await fetchPawnById(token, id);
        setPawn(data);
      } catch (error) {
        console.log(error)
      }
    })()
  },[token, id])

  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Pawn</title>
      </Helmet>
      <div className="top__items-settings">
        <Breadcrumb>
          <Button
            onClick={() => history.go(-1)}
            style={{ border: "none", textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>
              Salary Advance
            </span>
          </Button>
          <span style={{ fontSize: 20 }}>Submitter Detail</span>
        </Breadcrumb>
      </div>
      <PawnDetailAdminMWSLayout data={loanDataById} dataById={ pawn } token={ token } id={ id}/>
    </div>
  );
}
