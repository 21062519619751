/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./LoanSASettings.css";
import Styles from "./LoanSASettings.module.css";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import MONEY_ICON from "../../../../assets/images/money.png";
import DELETE_ICON from "../../../../assets/images/delete.png";
import EDIT_ICON from "../../../../assets/images/create.png";
import ModalLoanSA from "../ModalLoanSA/ModalLoanSA";

export default function LoanSASettings(props) {
  const { dataLoan, loading, active, onOpen } = props;
  // const token = localStorage.getItem("accessToken");
  const history = useHistory();
  const dispatch = useDispatch();
  // const [selectedLoan, setSelectedLoan] = useState({});
  const [listLoans, setListLoans] = useState("");
  const [isModalOpen, setIsModalOpen] = useState({})
  const handleSearch = (event) => {
    setListLoans(event.target.value);
  };
  return (
    <div className={Styles.Container}>
      <div className={Styles.Header}>
        <div className={Styles.Searchbar}>
          <SearchbarSA
            value={listLoans}
            onChange={handleSearch}
            searchWidth="379px"
            placeholder="Search by name"
          />
        </div>
        <div className={Styles.ButtonAddLoan}>
          <Link to="/superadmin/loan-add-scheme">
            <Button
              startIcon={<AddIcon style={{ color: "white", fontSize: 16 }} />}
              className={Styles.AddButton}
            >
              <span>Add Scheme</span>
            </Button>
          </Link>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 50,
          }}
        >
          <div style={{ width: 200 }}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={Styles.WrapperCard}>
          {
            dataLoan.filter((el) =>
              el?.name.toLowerCase().includes(listLoans.toLowerCase())
            ).map((each, idx) => (
              <div className={Styles.CardContainer} key={idx}>
                <div className={Styles.HeaderCard}>
                  <div className={Styles.Icon}>
                    <img src={MONEY_ICON} alt="money-icon" />
                  </div>
                  <div className={Styles.Text}>
                    <span>{each?.name}</span>
                  </div>
                </div>
                <div className={Styles.Desc}>
                  <span>{each?.remark}</span>
                </div>
                <div className={Styles.Settings}>
                  <div className={Styles.HeadingText}>
                    <span
                      onClick={() => {
                        // dispatch(fetchSchemaLoanById(token, loan._id));
                        history.push({
                          pathname: `/superadmin/loan-setting-view/${each._id}`,
                          state: {
                            dataLoan: each,
                          },
                        });
                      }}
                    >
                      View Settings
                    </span>
                    <ArrowRightAltIcon
                      style={{ color: "#1571DE", marginLeft: 8 }}
                    />
                  </div>
                  <div className={Styles.ButtonHelper}>
                    {/* <img src={EDIT_ICON} alt="edit-icon" 
                    onClick={() => {
                    history.push({
                      pathname: `/superadmin/loan-edit-scheme/${each._id}`,
                      state: {
                        dataLoan: each,
                      },
                    });
                  }}
                />
                <img src={DELETE_ICON} alt="delete-icon" 
                  onClick={() => setIsModalOpen(each)}
                /> */}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        /* <div className="wrapper-loan-sa-setting">
          {dataLoan?.filter((el) =>
              el.Name.toLowerCase().includes(listLoans.toLowerCase())
            )
            .map((loan, ind) => {
              return (
                <div className={ Styles.CardContainer } key={ ind }>
                  <div className={ Styles.HeaderCard }>
                    <div className={ Styles.Icon }>
                      <img src={ MONEY_ICON } alt="money-icon" />
                    </div>
                  </div>
                </div>
              );
            })}
        </div> */
      )}
      {
        isModalOpen._id && (
          <ModalLoanSA active={isModalOpen} onOpen={setIsModalOpen} description="Apakah anda yakin ingin menghapus skema ini?" label="Delete Schema" pathName="/superadmin/loan" />
        )
      }

      {/* {selectedLoan._id && (
        <LoanModalSetting
          selectedLoan={selectedLoan}
          setSelectedLoan={setSelectedLoan}
          pathName="/superadmin/loan"
        />
      )} */}
    </div>
  );
}