import axios from "axios";
import {API} from "../../redux/api";

const fetchDetailClientExternalAsync = (token, attendancePlanId, clientCode) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data : { response } } = await axios.get(`${API}/attendance-plan/detail/${attendancePlanId}?code=${clientCode}`, {
        headers : {
          Authorization: token
        }
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export default fetchDetailClientExternalAsync;