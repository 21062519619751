import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
// import { useDispatch } from "react-redux";
import { fetchFAQ } from "../../../async/faq";
// import { fetchAllFaqs } from "../../../redux/actions/SuperAdmin/faqsActions";
// import { useSelector } from "react-redux";
import FaqTable from "../../../components/SuperAdmin/_faq/FaqTable/FaqTable";
import UnderMaintenance from "../../../components/_underMaintenancePage";

export default function FAQSA() {
  // const dispatch = useDispatch();
  // const token = localStorage.getItem("accessToken");
  const [datas, setDatas] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const dataFaqs = useSelector((state) => state.faqs.dataAllFaqs);
  // const dataDrafts = useSelector((state) => state.drafts.dataAllDrafts)

  // useEffect(() => {
  //   dispatch(fetchAllFaqs(token));
  // }, []);

  useEffect(() => {
    const refetchData = async () => {
      try {
        setIsLoading(true);
        const { response } = await fetchFAQ();
        setDatas(response)
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
    refetchData()
  },[])

  // console.log(datas, 'faq')
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | FAQ</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          FAQ
        </h2>
      </div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <FaqTable loading={ isLoading } datas={datas || []} />
          {/* <AppSettingsTabsFAQ dataFaqs={dataFaqs} dataDrafts={dataDrafts}/> */}
          {/* <UnderMaintenance /> */}
        </Grid>
      </Grid>
    </Container>
  );
}
