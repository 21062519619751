import React, { useEffect, useState } from "react";
import "./ModalPassword.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";

export default function ModalPassword({
  showModal,
  setModal,
  setArrayData,
  arrayData,
  validPassword,
}) {
  
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
    correctPassword: validPassword,
  });

  const [error, setError] = useState(false);

  useEffect(() => {
    setArrayData(false);
  }, [setArrayData]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleSubmit = () => {
    if (values.password === values.correctPassword) {
      setArrayData(true);
      setModal(false);
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <Dialog open={showModal} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>
            Insert correct password to access
          </DialogContentText>
          <div className="" style={{ paddingTop: 20 }}>
            <OutlinedInput
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              error={error}
              placeholder="•••••••••••"
              sx={{
                height: 44,
                fontSize: 12,
                fontWeight: 400,
                color: "#000000",
              }}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values?.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          {error && (
            <div className="text-error-payslip">
              <span>Invalid password</span>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button style={{ textTransform: "none" }} type="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
