import React, { useState } from "react";
import "./NewUserChart.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        {...props}
        style={{
          width: "152px",
          height: "152px",
          borderRadius: "100px",
          boxShadow: "inset 0 0 0 11px #D3E2FF",
          backgroundColor: "transparent",
        }}
        thickness={3}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          component="span"
          sx={{ color: "#0A0A0A", fontSize: 24, fontWeight: 500 }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const NewUserChart = () => {
  // eslint-disable-next-line no-unused-vars
  const [progress, setProgress] = useState(70);

  //   useEffect(() => {
  //     const timer = setInterval(() => {
  //       setProgress((prevProgress) =>
  //         prevProgress >= 100 ? 0 : prevProgress + 100
  //       );
  //     }, 1200);

  //     return () => {
  //       clearInterval(timer);
  //     };
  //   }, []);

  return (
    <div className="ContainerNewUserChart">
      <div className="WrapperNewUserChart">
        <div className="HeaderNewUserChart">
          <span>New Customer</span>
        </div>
        <div className="NewUserProgressBar">
          <div className="WrapperProgressBar">
            <CircularProgressWithLabel value={progress} />
          </div>
          <div className="ReportNewUserChart">
            <div className="TodaysReportNewUser">
              <span className="TodaysTextReport">Today</span>
              <span className="NumberTextReport">5 / 10</span>
            </div>
            <div className="PercentageReportNewUser">
              <span>
                <ArrowUpwardIcon
                  sx={{ width: 18, height: 18, color: "#1B45D9" }}
                  className="IconSalesOverview"
                />
                <span>41,8%</span>
              </span>
              <span className='YesterdayTextReport'>From Yesterday</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUserChart;
