import React from "react";
import { MDStoreFMCG } from "../../../../components/FMCG";

export default function Merchandiser() {
  return (
    <div>
      <MDStoreFMCG />
    </div>
  );
}
