/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Avatar, Button, IconButton, Menu, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import EDIT_ICON from "../../../../assets/images/create.png";
import FILE_ICON from "../../../../assets/images/file-icon.png";
import CALENDAR_ICON from "../../../../assets/images/calendar-employee.png";
import CALENDAR_LEAVE_ICON from "../../../../assets/images/calendar-leave.png";
import getEditStatus from "../../../../async/editStatus/getEditStatus";
import { useCalendar } from "../../../utils/hooks/";
import ModalBPJS from "../../_bpjs/BPJSMenu/ModalBPJS/ModalBPJS";
import ModalEditApprover from "./ModalEditApprover";
import ModalEditSupervisor from "./ModalEditSupervisor";
import ModalEpaySlip from "./ModalEpaySlip";
import ModalViewFile from "./ModalViewFile";
import Styles from "./ProfileEmployeeMenu.module.css";
import { API_IMAGE } from "../../../../redux/api";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ModalSetLeave from "./ModalSetLeave/ModalSetLeave";
import ModalSuccessEmployee from "./ModalSuccessEmployee/ModalSuccessEmployee";
import ModalAddLeave from "./ModalAddLeave/ModalAddLeave";
import fetchLeaveHistory from "../../../../async/employee/fetchLeaveHistory";
import moment from "moment";
import addLeaveHistory from "../../../../async/employee/addLeaveHistory";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import { fetchAllEmployeeById } from "../../../../redux/actions/SuperAdmin/employeesActions";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const data = [
  {
    id: 1,
    title: "Cuti Tahunan",
    startDate: "30 Agustus 2023",
    endDate: "02 September 2023",
    description:
      "Ada keperluan keluarga mendadak yang tidak bisa dilewatkan sehingga mau tidak mau harus cuti bos",
    total: "-2 hari",
    createdAt: "14 Agustus 2023",
  },
  {
    id: 2,
    title: "Cuti Istri Melahirkan",
    startDate: "16 Mei 2023",
    endDate: "18 Mei 2023",
    description: "Istri saya melahirkan buah hati saya",
    total: "3 hari",
    createdAt: "14 Agustus 2023",
  },
  {
    id: 3,
    title: "Cuti Bersama",
    startDate: "30 Agustus 2023",
    endDate: "",
    total: "-2 hari",
    createdAt: "01 Agustus 2023",
  },
  {
    id: 4,
    title: "Cuti Tahunan",
    startDate: "30 Agustus 2023",
    endDate: "02 September 2023",
    description:
      "Ada keperluan keluarga mendadak yang tidak bisa dilewatkan sehingga mau tidak mau harus cuti bos",
    total: "-2 hari",
    createdAt: "05 Agustus 2023",
  },
  {
    id: 5,
    title: "Cuti Tahunan",
    startDate: "30 Agustus 2023",
    endDate: "02 September 2023",
    description:
      "Ada keperluan keluarga mendadak yang tidak bisa dilewatkan sehingga mau tidak mau harus cuti bos",
    total: "-2 hari",
    createdAt: "28 Agustus 2023",
  },
  // {
  //   id: 6,
  //   title: "Cuti Tahunan",
  //   startDate: "30 Agustus 2023",
  //   endDate: "02 September 2023",
  //   description:
  //     "Ada keperluan keluarga mendadak yang tidak bisa dilewatkan sehingga mau tidak mau harus cuti bos",
  //   total: "-2 hari",
  //   createdAt: "14 Maret 2023",
  // },
  // {
  //   id: 7,
  //   title: "Cuti Tahunan",
  //   startDate: "30 Agustus 2023",
  //   endDate: "02 September 2023",
  //   description:
  //     "Ada keperluan keluarga mendadak yang tidak bisa dilewatkan sehingga mau tidak mau harus cuti bos",
  //   total: "-2 hari",
  //   createdAt: "10 Agustus 2023",
  // },
  // {
  //   id: 8,
  //   title: "Cuti Bersama",
  //   startDate: "30 Agustus 2023",
  //   endDate: "",
  //   description:
  //     "Ada keperluan keluarga mendadak yang tidak bisa dilewatkan sehingga mau tidak mau harus cuti bos",
  //   total: "2 hari",
  //   createdAt: "01 Januari 2023",
  // },
  // {
  //   id: 9,
  //   title: "Cuti Tahunan",
  //   startDate: "30 Agustus 2023",
  //   endDate: "02 September 2023",
  //   description:
  //     "Ada keperluan keluarga mendadak yang tidak bisa dilewatkan sehingga mau tidak mau harus cuti bos",
  //   total: "-2 hari",
  //   createdAt: "10 Januari 2023",
  // },
  // {
  //   id: 10,
  //   title: "Cuti Tahunan",
  //   startDate: "30 Agustus 2023",
  //   endDate: "02 September 2023",
  //   description:
  //     "Ada keperluan keluarga mendadak yang tidak bisa dilewatkan sehingga mau tidak mau harus cuti bos",
  //   total: "-2 hari",
  //   createdAt: "12 Februari 2023",
  // },
  // {
  //   id: 11,
  //   title: "Cuti Tahunan",
  //   startDate: "30 Agustus 2023",
  //   endDate: "02 September 2023",
  //   description:
  //     "Ada keperluan keluarga mendadak yang tidak bisa dilewatkan sehingga mau tidak mau harus cuti bos",
  //   total: "-2 hari",
  //   createdAt: "18 Januari 2023",
  // },
  // {
  //   id: 12,
  //   title: "Cuti Tahunan",
  //   startDate: "30 Agustus 2023",
  //   endDate: "02 September 2023",
  //   description:
  //     "Ada keperluan keluarga mendadak yang tidak bisa dilewatkan sehingga mau tidak mau harus cuti bos",
  //   total: "-2 hari",
  //   createdAt: "19 September 2023",
  // },
];

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className={Styles.Description}>
      {/* { isReadMore && text.length < 20 ? text?.slice(0, 50) : text } */}
      {isReadMore ? text.slice(0, 50) : text}
      <span
        onClick={toggleReadMore}
        className={`${text.length > 50 ? Styles.Read : Styles.Hide}`}
      >
        {/* {isReadMore ? "... Selengkapnya" : "... Lebih Sedikit"} */}
        {isReadMore && "... Selengkapnya"}
      </span>
    </p>
  );
};

export default function ProfileEmployeeMenu({
  employee,
  payslip,
  employeeId,
  setData,
  id,
}) {
  // let clientCode = localStorage.getItem("clientCode");
  let clientCode = window.sessionStorage.getItem("selectedClientCode");
  const calendar = useCalendar();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const [file, setFile] = useState(null);
  const [ePaySlip, setEPaySlip] = useState(null);
  const [changeSupervisor, setChangeSupervisor] = useState(false);
  const [changeApprover, setChangeApprover] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const d = new Date();
  const [selectedYear, setSelectedYear] = useState(d);
  const year = String(selectedYear)?.split(" ")[3];
  const [fixedYear, setFixedYear] = useState(year);
  const [spvToChange, setSPVtoChange] = useState(null);
  const [apvToChange, setAPVtoChange] = useState(null);
  const [editStatus, setEditStatus] = useState(null);
  const [bpjsOpen, setBpjsOpen] = useState(false);

  const [ktp, setKTP] = useState(false);
  const [contract, setContract] = useState(false);

  const [limit, setLimit] = useState(8);
  const [loading, setLoading] = useState(false);
  const [leaveModal, setLeaveModal] = useState(false);
  const [modalSuccess, setIsModalSuccess] = useState(false);
  const [addLeaveModal, setAddLeaveModal] = useState(false);
  const [leaveHistory, setLeaveHistory] = useState([]);
  const [leaveHistoryYear, setLeaveHistoryYear] = useState(2024);
  let haveAnnualLeave = true;

  let employeeInfo = employee?.[0];
  const [isErrorSetLeave, setIsErrorSetLeave] = useState();
  const [isErrorAddLeave, setIsErrorAddLeave] = useState();
  const [defaultTotal, setDefaultTotal] = useState(5);
  const [valueAddLeave, setValueAddLeave] = useState({
    totalLeave: null,
    description: "",
  });

  // const modifiedData = useMemo(() => {
  //   return employee?.map((employee) => ({
  //     ...employee,
  //     user : {
  //       ...employee.user,
  //       profilePicture: employee?.user?.profilePicture?.replace(`https://myworkspace.id/api/image/`, `${API_IMAGE}`)
  //     }
  //   }))
  // },[employee]);

  useEffect(() => {
    setEmployeeName(employeeInfo?.SPV ? employeeInfo?.SPV[0]?.SPV_1?.Name : "");
    setFixedYear(String(selectedYear)?.split(" ")[3]);
  }, [payslip, employeeInfo, selectedYear]);

  function truncate(str) {
    return str?.split(" ")?.splice(0, 2)?.join(" ");
  }

  useEffect(() => {
    (async () => {
      try {
        const { profileChange } = await getEditStatus(token, employeeId);
        setEditStatus(profileChange);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [employeeId, token]);

  useEffect(() => {
    let documents = employeeInfo?.document;
    let ktptemp = documents?.filter((doc) => doc.docType === "KTP");
    let contractTemp = documents?.filter((doc) => doc.docType === "KONTRAK");
    setKTP(ktptemp);
    setContract(contractTemp);
  }, [employeeInfo?.document]);

  useEffect(() => {
    const refetchData = async () => {
      try {
        setLoading(true);
        const data = await fetchLeaveHistory(
          token,
          employeeId,
          clientCode,
          leaveHistoryYear
        );
        setLeaveHistory(data?.response);
        // console.log(data.response, "datas");
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    refetchData();
  }, [clientCode, employeeId, token, leaveHistoryYear]);

  const handleGetData = async () => {
    try {
      setLoading(true);
      const data = await fetchLeaveHistory(
        token,
        employeeId,
        clientCode,
        leaveHistoryYear
      );
      setLeaveHistory(data?.response);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenContract = (e) => {
    e.preventDefault();
    if (contract.length > 0) {
      window.open(contract[0]?.url, "_blank");
    } else {
      setFile("");
    }
  };

  const handleSetLeave = () => {
    setLeaveModal(true);
  };

  const handleAddLeave = () => {
    setAddLeaveModal(true);
  };

  const onSetLeave = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        balance: `${valueAddLeave?.totalLeave}`,
        employeeId: employeeId,
        description: valueAddLeave?.description,
        clientCode: clientCode,
      };
      // const data = defaultTotal;
      // const data = await addLeaveHistory(token, payload);
      const query = `leave-additional/set-leave`;
      const data = await addLeaveHistory(token, payload, query);
      dispatch(
        fetchAllEmployeeById(
          token,
          id,
          `clientCode=${employeeInfo?.clientCode}`
        )
      );
      setData((prev) => ({
        ...prev,
        leaveDetail: {
          initialBalance: employeeInfo?.leaveDetail?.initialBalance,
          remain: employeeInfo?.leaveDetail?.remain,
        },
      }));
      setLeaveModal(false);
      await new Promise((resolve) => resolve(data));
      setIsModalSuccess(true);
      await new Promise((resolve) => setTimeout(() => resolve(true), 1500));
      handleGetData();
      setValueAddLeave({
        totalLeave: null,
        description: "",
      });
    } catch (error) {
      console.log(error);
      if (
        error.response.data.error ===
        "[REQUEST ERROR] LeavePlan in client not found"
      ) {
        setIsErrorSetLeave(
          "Mohon maaf, harap hubungi admin untuk set up skema pada client anda."
        );
      }
    }
  };

  const onAddLeave = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        balance: `${valueAddLeave?.totalLeave}`,
        employeeId: employeeId,
        description: valueAddLeave?.description,
        clientCode: clientCode,
      };
      const query = `leave-additional/bonus`;
      const data = await addLeaveHistory(token, payload, query);
      dispatch(
        fetchAllEmployeeById(
          token,
          id,
          `clientCode=${employeeInfo?.clientCode}`
        )
      );
      setData((prev) => ({
        ...prev,
        leaveDetail: {
          initialBalance: employeeInfo?.leaveDetail?.initialBalance,
          remain: employeeInfo?.leaveDetail?.remain,
        },
      }));
      setAddLeaveModal(false);
      await new Promise((resolve) => resolve(data));
      setIsModalSuccess(true);
      await new Promise((resolve) => setTimeout(() => resolve(true), 1500));
      handleGetData();
      setValueAddLeave({
        totalLeave: null,
        description: "",
      });
    } catch (error) {
      console.log(error.response.data.error);
      if (
        error.response.data.error ===
        "[REQUEST ERROR] LeavePlan in client not found"
      ) {
        setIsErrorAddLeave(
          "Mohon maaf, harap hubungi admin untuk set up skema pada client anda."
        );
      }
    }
  };

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = (value) => {
    setLeaveHistoryYear(value);
    setAnchorEl(null);
  };

  const yearsOption = Array.from(
    { length: 4 },
    (_, index) => new Date().getFullYear() - index
  );

  return (
    <div className={Styles.Container}>
      <div className={Styles.Wrapper}>
        <div className={Styles.Left}>
          <Avatar
            // src={ employeeInfo?.user?.profilePicture !== "" ? employeeInfo?.user?.profilePicture : "" }
            src={employeeInfo?.user?.profilePicture?.replace(
              `https://myworkspace.id/api/image/`,
              `${API_IMAGE}`
            )}
            className={Styles.MainAvatar}
          />
          <Link
            to={{
              pathname: `/superadmin/edit-employee/${employeeInfo?.employeeId}`,
              state: { data: employeeInfo },
            }}
          >
            <Button className={Styles.Edit}>
              <span>Edit Details</span>
              <img src={EDIT_ICON} alt="edit" />
            </Button>
          </Link>
        </div>
        <div className={Styles.Right}>
          <div className={Styles.ID}>
            <div className={Styles.IDHeader}>
              <span className={Styles.Capitalized}>
                {employeeInfo?.user?.name.toLowerCase()}
              </span>
              <div className={Styles.RequestWrapper}>
                <Link
                  to={{
                    pathname: `/superadmin/view-request/${employeeInfo?.employeeId}`,
                    state: {
                      currentData: employeeInfo?.user,
                      requestData: editStatus?.newUpdate,
                      data: employeeInfo,
                    },
                  }}
                >
                  <span hidden={!editStatus}>View Request</span>
                </Link>
                <span
                  className={
                    editStatus !== null ? Styles.Pending : Styles.NoRequest
                  }
                >
                  {editStatus === null ? "No Request" : "Request Pending"}
                </span>
              </div>
            </div>
            <hr />
            <div className={Styles.Content}>
              <div className={Styles.BoxContent}>
                <span>NRK</span>
                <span>{employeeInfo?.user?.employeeId}</span>
              </div>
              <div className={Styles.BoxContent}>
                <span>KTP</span>
                <span>
                  {employeeInfo?.user?.idNumber
                    ? employeeInfo.user.idNumber
                    : "-"}
                </span>
              </div>
              <div className={Styles.BoxContent}>
                <span>NPWP</span>
                <span>
                  {employeeInfo?.user?.taxId ? employeeInfo.user.taxId : "-"}
                </span>
              </div>
              <div className={Styles.BoxContent}>
                <span>Email</span>
                <span>
                  {employeeInfo?.user?.email !== null ? (
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location.href = `mailto:${employeeInfo?.user?.email}`;
                        e.preventDefault();
                      }}
                    >
                      {employeeInfo?.user?.email}
                    </Link>
                  ) : (
                    "-"
                  )}
                </span>
              </div>
              <div className={Styles.BoxContent}>
                <span>Phone Number</span>
                <span>
                  {employeeInfo?.user?.phoneNumber
                    ? employeeInfo.user.phoneNumber
                    : "-"}
                </span>
              </div>
              <div className={Styles.BoxContent}>
                <span>Date of Birth</span>
                <span>
                  {employeeInfo?.user?.birthDate
                    ? employeeInfo.user.birthDate
                    : "-"}
                </span>
              </div>
              <div className={Styles.BoxContent}>
                <span>Marital Status</span>
                <span>
                  {employeeInfo?.user?.maritalStatus === "TK"
                    ? "Single"
                    : "Married"}
                </span>
              </div>
              <div className={Styles.BoxContent}>
                <span>Bank Name</span>
                <span>
                  {employeeInfo?.user?.account?.bankName
                    ? employeeInfo.user.account.bankName
                    : "-"}
                </span>
              </div>
              <div className={Styles.BoxContent}>
                <span>Bank Account Number</span>
                <span>
                  {employeeInfo?.user?.account?.number
                    ? employeeInfo.user.account.number
                    : "-"}
                </span>
              </div>
            </div>
          </div>
          <div className={Styles.AnnualLeave}>
            <div className={Styles.Setter}>
              <div className={Styles.AnnualLeaveInfo}>
                <div className={Styles.SectionText}>
                  {" "}
                  <p>Leave • {leaveHistoryYear}</p>
                  <IconButton
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <CalendarTodayIcon />
                  </IconButton>
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {yearsOption.map((el, idx) => (
                    <MenuItem onClick={() => handleClickMenuItem(el)} key={idx}>
                      {el}
                    </MenuItem>
                  ))}
                </Menu>
                <div className={Styles.LeaveBox}>
                  <span>Jatah Cuti</span>
                  <span>
                    {employeeInfo?.leaveDetail?.initialBalance
                      ? employeeInfo?.leaveDetail?.initialBalance
                      : "0"}
                  </span>
                </div>
                <div className={Styles.LeaveBox}>
                  <span>Sisa Cuti</span>
                  <span>
                    {employeeInfo?.leaveDetail?.remain
                      ? employeeInfo?.leaveDetail?.remain
                      : "0"}
                  </span>
                </div>
              </div>
              <div className={Styles.ButtonWrapper}>
                <div className={Styles.ButtonAction}>
                  <Button
                    startIcon={<ModeEditIcon sx={{ fontSize: 20 }} />}
                    onClick={handleSetLeave}
                  >
                    Atur Cuti Sebelumnya
                  </Button>
                </div>
                <div className={Styles.ButtonAction}>
                  <Button
                    startIcon={<AddCircleOutlineIcon sx={{ fontSize: 20 }} />}
                    onClick={handleAddLeave}
                  >
                    Tambah Cuti
                  </Button>
                </div>
              </div>
            </div>

            <div className={Styles.AnnualLeaveContent}>
              {leaveHistory?.length > 0 ? (
                <div className={Styles.HaveAnnual}>
                  {leaveHistory.map((item, index) => (
                    <div className={Styles.ContainerEachAnnual} key={index}>
                      <div className={Styles.EachAnnual}>
                        <div className={Styles.Icon}>
                          <img
                            src={CALENDAR_LEAVE_ICON}
                            alt="calendar-employee"
                          />
                        </div>
                        <div className={Styles.ValueLeave}>
                          <div className={Styles.TitleContainer}>
                            <div className={Styles.Title}>
                              <div className={Styles.TextTitle}>
                                {item?.title}
                              </div>
                              <div
                                className={`${
                                  item.balance?.toString().includes("-")
                                    ? Styles.TotalBox
                                    : Styles.TotalBoxDefault
                                }`}
                              >
                                {item?.balance} hari
                              </div>
                            </div>
                            <div className={Styles.CreatedAt}>
                              {moment(item.createdAt).format("DD MMMM YYYY")}
                            </div>
                          </div>
                          <div className={Styles.Date}>
                            <span>
                              {/* Tanggal Cuti:{" "}{`${moment(item.startTime).format("DD MMM YYYY")} ${moment(item.endTime).format("DD MMM YYYY")? "-": ""} ${moment(item.endTime).format("DD MMM YYYY")}`}{" "} */}
                              {item?.startTime && item?.endTime ? (
                                <>
                                  Tanggal Cuti:{" "}
                                  {`${moment(item.startTime).format(
                                    "DD MMM YYYY"
                                  )} ${
                                    moment(item.endTime).format("DD MMM YYYY")
                                      ? "-"
                                      : ""
                                  } ${moment(item.endTime).format(
                                    "DD MMM YYYY"
                                  )}`}{" "}
                                </>
                              ) : null}
                              {/* <b>
                                •{" "}
                                {`${
                                  item.balance?.toString().includes("-")
                                    ? item.balance?.toString().replace("-", "")
                                    : item.balance
                                }`}{" "}
                                hari
                              </b> */}
                            </span>
                          </div>
                          {item?.description ? (
                            <div>
                              <ReadMore>{`Deskripsi : ${item.description}`}</ReadMore>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={Styles.WithoutAnnual}>
                  <img src={CALENDAR_ICON} alt="calendar" />
                  <span>Belum Ada Riwayat Cuti</span>
                </div>
              )}
            </div>
          </div>
          <div className={Styles.Files}>
            <span>Files</span>
            <div className={Styles.CardContainer}>
              <div
                className={`${Styles.Card} ${Styles.Pointer}`}
                onClick={() => (ktp?.length > 0 ? setFile(ktp) : setFile(""))}
              >
                <img src={FILE_ICON} alt="file" />
                <span>ID Card</span>
              </div>
              <div
                className={`${Styles.Card} ${Styles.Pointer}`}
                onClick={() => {
                  if (employeeInfo?.bpjs?.length > 0) {
                    setBpjsOpen(true);
                  } else {
                    setFile("");
                  }
                }}
              >
                <img src={FILE_ICON} alt="file" />
                <span>BPJS</span>
              </div>
              <div
                className={`${Styles.Card} ${Styles.Pointer}`}
                onClick={handleOpenContract}
              >
                <img src={FILE_ICON} alt="file" />
                <span>Contract</span>
              </div>
            </div>
          </div>
          {/* <div className={Styles.EpaySlips}>
            <div className={Styles.EpaySlipsHeader}>
              <span>ePay Slip History</span>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={["year"]}
                  value={selectedYear}
                  onChange={(newValue) => {
                    setSelectedYear(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={null}
                      size="small"
                      style={{ width: 115 }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            {employeeInfo?.paymentSlips?.filter((el) => el?.year === fixedYear)
              .length === 0 && (
                <span className={Styles.NoSlip}>
                  no Epay Slip history for year {fixedYear}
                </span>
              )}
            <div className={Styles.CardContainer}>
              {employeeInfo?.paymentSlips
                ?.filter((el) => el?.year === fixedYear)
                .sort((a, b) => (a.month > b.month ? 1 : -1))
                .map((each, i) => {
                  let month =
                    calendar.months.eng.full[each?.month.split(" ")[0] - 1];
                  //           let year = el.PayrollMonth.split(" ")[2];
                  return (
                    <div
                      key={i}
                      className={`${Styles.Card} ${Styles.Pointer}`}
                      onClick={() => setEPaySlip(each)}
                    >
                      <img src={FILE_ICON} alt="file" />
                      <span>
                        {month} {each?.year}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div> */}
          <div className={Styles.Teams}>
            <span>My Team</span>
            <div className={Styles.OrganizersWrapper}>
              <div className={Styles.Organizers}>
                <div className={Styles.TeamsCardWrapper}>
                  <div className={Styles.TeamsSubheader}>
                    <span>Supervisor</span>
                    <img
                      onClick={() => {
                        setSPVtoChange(employeeInfo?.supervisor);
                        setChangeSupervisor(true);
                      }}
                      src={EDIT_ICON}
                      alt="edit spv"
                    />
                  </div>
                  <div className={Styles.TeamsCard}>
                    <Avatar
                      className={Styles.TeamsCardImg}
                      src={employeeInfo?.supervisor?.profilePicture || ""}
                    />
                    <span className={Styles.Capitalized}>
                      {employeeInfo?.supervisor?.name
                        ? truncate(
                            employeeInfo?.supervisor?.name?.toLowerCase()
                          )
                        : "not assigned yet"}
                    </span>
                    <span>
                      {employeeInfo?.supervisor
                        ? employeeInfo?.supervisor?.jobTitle
                        : ""}
                    </span>
                  </div>
                </div>
                <div className={Styles.TeamsCardWrapper}>
                  <div className={Styles.TeamsSubheader}>
                    <span>Approver</span>
                    <img
                      onClick={() => {
                        setAPVtoChange(employeeInfo?.loanApprover);
                        setChangeApprover(true);
                      }}
                      src={EDIT_ICON}
                      alt="edit spv"
                    />
                  </div>
                  <div className={Styles.TeamsCard}>
                    <Avatar
                      className={Styles.TeamsCardImg}
                      src={employeeInfo?.loanApprover?.profilePicture || ""}
                    />
                    <span className={Styles.Capitalized}>
                      {employeeInfo?.loanApprovers?.length !== 0
                        ? truncate(
                            employeeInfo?.loanApprovers[0]?.name?.toLowerCase()
                          )
                        : "not assigned yet"}
                    </span>
                    <span>
                      {employeeInfo?.loanApprovers?.length !== 0
                        ? employeeInfo?.loanApprovers[0]?.jobTitle
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {employeeInfo?.subordinates?.length !== 0 && (
              <div className={Styles.SubordinatesWrapper}>
                <div className={Styles.SubordinatesSubheader}>
                  <span>Staff</span>
                </div>
                <div className={Styles.Subordinates}>
                  {employeeInfo?.subordinates
                    ?.slice(0, limit)
                    ?.filter(
                      (v, i, a) =>
                        a.findLastIndex(
                          (v2) => v2.employeeId === v.employeeId
                        ) === i
                    )
                    ?.map((each, i) => (
                      <div key={i} className={Styles.TeamsCard}>
                        <Avatar
                          className={Styles.TeamsCardImg}
                          src={each?.user?.profilePicture || ""}
                        />
                        <span className={Styles.Capitalized}>
                          {truncate(each?.user?.name?.toLowerCase())}
                        </span>
                        <span className={Styles.JobTitle}>
                          {each?.jobTitle}
                        </span>
                      </div>
                    ))}
                </div>
                {employeeInfo?.subordinates?.length > 8 && (
                  <div className={Styles.StaffController}>
                    {employeeInfo?.subordinates?.length > limit && (
                      <div className={Styles.ViewMore}>
                        <button onClick={() => setLimit(limit + 8)}>
                          View More
                        </button>
                      </div>
                    )}
                    {limit > 8 &&
                      employeeInfo?.subordinates?.length > limit && (
                        <span>|</span>
                      )}
                    {limit > 8 && (
                      <div className={Styles.ViewMore}>
                        <button onClick={() => setLimit(limit - 8)}>
                          View Less
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {changeSupervisor && (
        <ModalEditSupervisor
          spvToChange={spvToChange}
          employeeId={employeeInfo?.employeeId}
          clientCode={employeeInfo?.clientCode}
          changeSupervisor={changeSupervisor}
          setChangeSupervisor={setChangeSupervisor}
        />
      )}

      {changeApprover && (
        <ModalEditApprover
          apvToChange={apvToChange}
          employeeId={employeeInfo?.employeeId}
          clientCode={employeeInfo?.clientCode}
          changeApprover={changeApprover}
          setChangeApprover={setChangeApprover}
        />
      )}

      {file !== null && <ModalViewFile file={file} setFile={setFile} />}

      {/* {ePaySlip !== null && (
        <ModalEpaySlip ePaySlip={ePaySlip} setEPaySlip={setEPaySlip} employeeInfo={employeeInfo}/>
      )} */}
      {bpjsOpen && <ModalBPJS datas={employeeInfo} setBpjsOpen={setBpjsOpen} />}

      {leaveModal && (
        <ModalAddLeave
          isActive={leaveModal}
          onOpen={setLeaveModal}
          value={valueAddLeave}
          setValue={setValueAddLeave}
          onSetLeave={onSetLeave}
          mode="edit"
          isErrorSetLeave={isErrorSetLeave}
        />
      )}

      {addLeaveModal && (
        <ModalAddLeave
          isActive={addLeaveModal}
          onOpen={setAddLeaveModal}
          isSuccess={modalSuccess}
          onSuccess={setIsModalSuccess}
          value={valueAddLeave}
          setValue={setValueAddLeave}
          onAddLeave={onAddLeave}
          mode="add"
          isErrorAddLeave={isErrorAddLeave}
        />
      )}

      {modalSuccess && (
        <ModalSuccessEmployee
          isActive={modalSuccess}
          onOpen={setIsModalSuccess}
        />
      )}
    </div>
  );
}
