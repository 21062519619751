import axios from "axios";
import {API} from "../../redux/api";

const fetchAttendanceByEmployeeId = (token, employeeId, startDate, endDate, clientName) =>
// {{url}}/attendance/admin/fetch/${employeeId}?startDate=2023-07-01&endDate=2023-08-10&clientName=PT. PERMATA INDO SEJAHTERA
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(`${API}/attendance/admin/fetch/${employeeId}?startDate=${startDate}&endDate=${endDate}&clientName=${clientName}`,{
          headers: {
            Authorization: token,
          },
        }
      );
      resolve( data );
    } catch (error) {
      reject(error);
    }
  });

export default fetchAttendanceByEmployeeId;