/* eslint-disable no-unused-vars */

import styled from '@emotion/styled'
import { AccessTime, Download } from '@mui/icons-material'
import { alpha, Button, Menu, Switch } from '@mui/material'
import { differenceInMonths, differenceInYears } from 'date-fns'
import moment from 'moment'
import ExportCSV from "../../../../SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import React, { useEffect, useMemo, useState } from 'react'
import Styles from './Style.module.css'
import setOvertime from '../../../../../async/attendance/setOvertime'
import getAttendanceById from '../../../../../async/attendance/getAttendanceById'
import axios from 'axios'
import {API} from '../../../../../redux/api'

const ExcelJS = require("exceljs");

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme?.spacing(1),
        minWidth: 170,
        display: "flex",
        justifyContent: "flex-start",
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiList-root": {
            width: "100%",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

const toDataURL = (url) => {
    const promise = new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.readAsDataURL(xhr.response);
            reader.onloadend = function () {
                resolve({ base64Url: reader.result });
            };
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
    });

    return promise;
};




export default function SummaryCard(props) {
    const { language = 'eng', startDate, endDate, yearValue, monthValue, attendanceSummary, rawData, cleanData, setSelectedAbsence, isDownloading, setIsDownloading, overtimeEligibility, handleChangeOvertime } = props
    const [year, setYear] = useState(new Date().getFullYear())
    const clientName = localStorage.getItem('clientName');


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const token = localStorage.getItem('accessToken')

    const [statusData, setStatusData] = useState([])


    // useEffect(()=> {}, [statusData])



    const months = useMemo(() => {
        switch (language) {
            case 'eng':
                return {
                    short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    long: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    totalDays: [31, +year % 4 === 0 ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
                }
            default:
                return {
                    short: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Ags', 'Sep', 'Okt', 'Nov', 'Des'],
                    long: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
                    totalDays: [31, +year % 4 === 0 ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
                }
        }
    }, [year, language])


    const days = useMemo(() => {
        switch (language) {
            case 'eng':
                return {
                    short: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                    long: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                }
            default:
                return {
                    short: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
                    long: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
                }
        }
    }, [language])

    const days2 = useMemo(() => {
        switch (language) {
            case 'eng':
                return {
                    short: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    long: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
                }
            default:
                return {
                    short: ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'],
                    long: ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu']
                }
        }
    }, [language])

    const manualMonths = (year) => {
        switch (language) {
            case 'eng':
                return {
                    short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    long: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    totalDays: [31, +year % 4 === 0 ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
                }
            default:
                return {
                    short: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Ags', 'Sep', 'Okt', 'Nop', 'Des'],
                    long: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'Nopember', 'Desember'],
                    totalDays: [31, +year % 4 === 0 ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
                }
        }
    }

    const dateRange = useMemo(() => {
        let range = [];
        for (
            var d = new Date(startDate);
            d <= new Date(endDate);
            d.setDate(d.getDate() + 1)
        ) {
            range.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
        }
        return range;
    }, [startDate, endDate]);


    const monthRange = useMemo(() => {
        const arr = []
        const difference = differenceInMonths(endDate, startDate);
        const startMonth = new Date(startDate).getMonth()
        for (let i = 0; i <= difference; i++) {
            if (arr[i - 1]?.index < 11) {
                arr.push({
                    index: arr[i - 1]?.index + 1,
                    month: months.long[arr[i - 1]?.index + 1]
                })
            } else if (i === 0) {
                arr.push({
                    index: startMonth + i,
                    month: months.long[startMonth]
                })
            } else {
                arr.push({
                    index: 0,
                    month: months.long[0]
                })
            }
        }
        return arr;
    }, [startDate, endDate, months])

    const yearRange = useMemo(() => {
        const arr = []
        const difference = differenceInYears(endDate, startDate)
        const startYear = new Date(startDate).getFullYear()
        for (let i = 0; i <= difference + 1; i++) {
            arr.push(startYear + i)
        }
        return arr
    }, [startDate, endDate])

    const yearMonthRange = useMemo(() => {
        const arr = []
        for (let i = 0; i < yearRange.length; i++) {
            for (let j = 0; j < monthRange.length; j++) {
                const isExistiInDateRange = (dateRange.find(date => ((date.getFullYear() === yearRange[i])) && (date.getMonth() === monthRange[j].index)))
                if (isExistiInDateRange) {

                    arr.push({
                        month: monthRange[j].month,
                        year: yearRange[i],
                        monthIndex: monthRange[j].index,
                    })
                }
            }
        }
        return arr
    }, [dateRange, monthRange, yearRange])


    // console.log(monthRange)
    // console.log(yearRange)

    const dataToMapBackup = useMemo(() => {
        const arr = []
        const absences = attendanceSummary?.Absences
        for (let i = 0; i < yearMonthRange.length; i++) {
            const attendanceArr = []
            let fixedMonth;
            let fixedYear
            for (let j = 0; j < absences?.length; j++) {

                const isMatch = Boolean(new Date(absences[j].startTime).getMonth() === yearMonthRange[i].monthIndex &&
                    new Date(absences[j].startTime).getFullYear() === yearMonthRange[i].year
                )
                if (isMatch) {
                    attendanceArr.push(absences[j])
                    fixedYear = yearMonthRange[i].year
                    fixedMonth = yearMonthRange[i].month
                } else {
                    fixedYear = yearMonthRange[i].year
                    fixedMonth = yearMonthRange[i].month
                }
            }
            arr.push({
                month: fixedMonth,
                year: fixedYear,
                attendances: attendanceArr
            })
        }
        return arr
    }, [attendanceSummary, yearMonthRange])


    const dataToMap = useMemo(() => {
        const arr = []
        const absences = attendanceSummary?.Absences
        for (let i = 0; i < yearMonthRange.length; i++) {
            const perMonth = []
            for (let j = 0; j < dateRange.length; j++) {
                const isDateMatch = Boolean(new Date(dateRange[j]).getMonth() === yearMonthRange[i].monthIndex)
                const dateExist = absences?.some(
                    (el) => moment(el.startTime).format("L") === moment(dateRange[j]).format("L")
                );
                const absenceIndex = absences?.findIndex(
                    (el) => moment(el.startTime).format("L") === moment(dateRange[j]).format("L")
                );
                isDateMatch && (
                    dateExist ? perMonth.push({
                        _id: absences[absenceIndex]?._id,
                        date: dateRange[j],
                        type: (absences[absenceIndex]?.type === "ATTEND" && "Attend") ||
                            (absences[absenceIndex]?.type === "ANNUAL-LEAVE" && "Leave") ||
                            (absences[absenceIndex]?.type === "SICK-PERMIT" && "Sick") ||
                            (absences[absenceIndex]?.type === "PERMIT" && "Permit") || '-',
                        startTime: moment(absences[absenceIndex]?.startTime).format("HH:mm"),
                        endTime: moment(absences[absenceIndex]?.endTime).format("HH:mm"),
                        overtime: {
                            approverId: absences[absenceIndex].overtime.approverId,
                            durationInMin: absences[absenceIndex].overtime.durationInMin,
                            indicator: absences[absenceIndex].overtime.approverId ? true : false
                        },
                        location: absences[absenceIndex]?.location,
                        image: absences[absenceIndex]?.images[0]?.uri,
                        isWorkFromHome: absences[absenceIndex]?.isWorkFromHome
                    }) : perMonth.push({
                        id: null,
                        date: dateRange[j],
                        type: '-',
                        startTime: null,
                        endTime: null,
                        overtime: {
                            approverId: null,
                            durationInMin: 0,
                            indicator: false
                        },
                        location: {
                            latitude: null,
                            langitude: null,
                            remark: null
                        },
                        images: null,
                        isWorkFromHome: false
                    })
                )
            }
            arr.push({
                year: yearMonthRange[i].year,
                month: yearMonthRange[i].month,
                attendances: perMonth
            })
        }
        return arr
    }, [dateRange, yearMonthRange, attendanceSummary])



    const handleDownloadOld = () => {
        setIsDownloading(true)
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("My Sheet");
        sheet.properties.defaultRowHeight = 80;

        sheet.getRow(1).font = {
            name: "Poppins",
            size: 16,
            bold: true,
        };

        sheet.columns = [
            {
                header: "No",
                key: "no",
                width: 10,
            },
            { header: "Name", key: "name", width: 32 },
            {
                header: "Company",
                key: "company",
                width: 20,
            },
            {
                header: "Position",
                key: "position",
                width: 20,
            },
            {
                header: "Nrk",
                key: "nrk",
                width: 15,
            },
            {
                header: "Month",
                key: "month",
                width: 10,
            },
            {
                header: "Year",
                key: "year",
                width: 30,
            },
            {
                header: "Date In",
                key: "dateIn",
                width: 30,
            },
            {
                header: "Date Out",
                key: "dateOut",
                width: 30,
            },
            {
                header: "Time In",
                key: "timeIn",
                width: 30,
            },
            {
                header: "Time Out",
                key: "timeOut",
                width: 30,
            },
            {
                header: "Coordinate Point",
                key: "coordinatePoint",
                width: 30,
            },
            {
                header: "Region",
                key: "region",
                width: 30,
            },
            {
                header: "Status Absence",
                key: "status",
                width: 30,
            },
            {
                header: "Remark",
                key: "remark",
                width: 30,
            },
            {
                header: "Photo",
                key: "photo",
                width: 30,
            },
        ];

        const promise = Promise.all(
            cleanData?.map(async (el, index) => {
                const rowNumber = index + 1;
                sheet.addRow({
                    no: index + 1,
                    name: el?.name,
                    company: el?.client,
                    position: el.jobTitle,
                    nrk: el?.employeeId,
                    month: el?.month,
                    year: el?.year,
                    dateIn: el?.dateIn,
                    dateOut: el?.dateOut,
                    timeIn: el?.timeIn,
                    timeOut: el?.timeOut,
                    coordinatePoint: el?.coordinatePoint,
                    region: el?.region,
                    status: el.type,
                    remark: el.remark,
                });
                if (el?.imageAttendance !== null) {
                    const result = await toDataURL(el?.imageAttendance);
                    const splitted = el?.imageAttendance?.split(".");
                    const extName = splitted[splitted?.length - 1];


                    const imageId2 = workbook.addImage({
                        base64: result.base64Url,
                        extension: extName,
                    });

                    sheet.addImage(imageId2, {
                        tl: { col: 15, row: rowNumber },
                        ext: { width: 100, height: 100 },
                    });
                }
            })
        );

        promise.then(() => {
            workbook.xlsx.writeBuffer().then(function (data) {
                const blob = new Blob([data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = `Attendance Report ${cleanData[0]?.name} ${new Date().toLocaleDateString()}.xlsx`;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        })
            .then(() => setIsDownloading(false))
    };
    
    const handleDownload = () => {
        setIsDownloading(true)
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("My Sheet");
        sheet.properties.defaultRowHeight = 80;

        sheet.getRow(1).font = {
            name: "Poppins",
            size: 16,
            bold: true,
        };

        sheet.columns = [
            {
                header: "No",
                key: "no",
                width: 10,
            },
            { header: "Name", key: "name", width: 32 },
            {
                header: "Company",
                key: "company",
                width: 20,
            },
            {
                header: "Position",
                key: "position",
                width: 20,
            },
            {
                header: "Nrk",
                key: "nrk",
                width: 15,
            },
            {
                header: "Month",
                key: "month",
                width: 10,
            },
            {
                header: "Year",
                key: "year",
                width: 30,
            },
            {
                header: "Date In",
                key: "dateIn",
                width: 30,
            },
            {
                header: "Date Out",
                key: "dateOut",
                width: 30,
            },
            {
                header: "Time In",
                key: "timeIn",
                width: 30,
            },
            {
                header: "Time Out",
                key: "timeOut",
                width: 30,
            },
            {
                header: "Coordinate Point",
                key: "coordinatePoint",
                width: 30,
            },
            {
                header: "Late",
                key: "late",
                width: 30,
            },
            {
                header: "Total Work Hour",
                key: "workHour",
                width: 30,
            },
            {
                header: "Region",
                key: "region",
                width: 30,
            },
            {
                header: "Status Absence",
                key: "status",
                width: 30,
            },
            {
                header: "Remark",
                key: "remark",
                width: 30,
            },
            {
                header: "Photo",
                key: "photo",
                width: 30,
            },
        ];
        const promise = Promise.all(
            cleanData?.map(async (el, index) => {
                const rowNumber = index + 1;
                sheet.addRow({
                    no: index + 1,
                    name: el?.name,
                    company: el?.client,
                    position: el.jobTitle,
                    nrk: el?.employeeId,
                    month: el?.month,
                    year: el?.year,
                    dateIn: el?.dateIn,
                    dateOut: el?.dateOut,
                    timeIn: el?.timeIn,
                    timeOut: el?.timeOut,
                    coordinatePoint: el?.coordinatePoint,
                    late: el?.isLate,
                    workHour: el?.duration,
                    region: el?.region,
                    status: el.type,
                    remark: el.remark,
                });
                if (el?.imageAttendance !== null) {
                    const result = await toDataURL(el?.imageAttendance);
                    const splitted = el?.imageAttendance?.split(".");
                    const extName = splitted[splitted?.length - 1];


                    const imageId2 = workbook.addImage({
                        base64: result.base64Url,
                        extension: extName,
                    });

                    sheet.addImage(imageId2, {
                        tl: { col: 17, row: rowNumber },
                        ext: { width: 100, height: 100 },
                    });
                }
            })
        );

        promise.then(() => {
            workbook.xlsx.writeBuffer().then(function (data) {
                const blob = new Blob([data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = `Attendance Report ${cleanData[0]?.name} ${new Date().toLocaleDateString()}.xlsx`;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        })
            .then(() => setIsDownloading(false))
    };

    const makePreFiller = (num) => {
        const arr = []
        for (let i = 0; i < num; i++) {
            arr.push("")
        }
        return arr
    }

    // 30 + 2 => 3

    const makePostFiller = (preNum, length) => {
        const arr = []
        const modulus = ((preNum + length) % 7)
        const counter = 7 - modulus
        if (modulus > 0) {
            for (let i = 0; i < counter; i++) {
                arr.push("")
            }
        }
        return arr
    }

    const dataCutOff = useMemo(() => {
        const datas = []
        const eachData = {
            No: 1,
            Name: rawData[0]?.name,
        }
        for (let i = 0; i < rawData?.length; i++) {
            eachData[moment(rawData[i]?.date).format("L")] = rawData[i].type;
        }
        datas.push(eachData)
        return datas
    }, [rawData])

    const handleClickDownload = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const changeSwitch = (i, ia) => {
        let datas = [...statusData]
        let data = statusData[i][ia]
        data = true
        datas[i][ia] = data
        setStatusData(datas)
    }

    const handleChangeOvertimeBackup = async (e, id, i, ia) => {
        // console.log(e.target.checked, '<<<')
        setSelectedAbsence(null)
        if (overtimeStatus[i][ia] !== true) {
            try {
                const { data } = await axios.patch(`${API}/attendance/approval-overtime/${id}`, null,
                    {
                        headers: {
                            Authorization: token,
                        },
                    }
                );
                if (data.response.overtime.approverId) {
                    changeSwitch(i, ia)
                }
            } catch (err) {
                console.error(err)
            }
        } else {
            console.log('already set')
        }
    }

    const overtimeStatus = useMemo(() => {
        const arr = []
        for (let i = 0; i < dataToMap?.length; i++) {
            const status = []
            const attendances = dataToMap[i].attendances
            for (let j = 0; j < attendances.length; j++) {
                if (attendances[j]?.overtime?.approverId !== null) {
                    status.push(true)
                } else {
                    status.push(false)
                }
            }
            arr.push(status)
        }
        return arr
    }, [dataToMap])


    useEffect(() => {
        setStatusData(overtimeStatus)
    }, [overtimeStatus])

    return (
        <div className={Styles.SummaryCardContainer}>
            <div className={Styles.SCHeader}>
                <span>Attendance Summary</span>
                <button onClick={handleClickDownload} disabled={isDownloading}>
                    <Download />
                    <span>
                        {isDownloading ? 'Downloading Absence...' : 'Download Absence'}
                    </span>
                </button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                >
                    <ExportCSV
                        data={cleanData?.map((el, i) => ({
                            "No. ": i + 1,
                            Name: el?.name,
                            Company: el?.client,
                            Position: el.jobTitle,
                            Nrk: el?.employeeId,
                            Month: el.month,
                            Year: el.year,
                            "Date in": el.dateIn,
                            "Date out": el.dateOut,
                            "Time in": el.timeIn,
                            "Time out": el.timeOut,
                            "Coordinate Point": el.coordinatePoint,
                            Region: el?.region,
                            "Late" : el?.isLate,
                            "Total Work Hour" : el?.duration,            
                            "Status Absence": el?.type,
                            Remark: el.remark,
                        }))}
                        filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                        initial="Default"
                    />
                    <Button
                        sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-start",
                            textTransform: "none",
                            color: "#404040",
                            fontSize: 14,
                            fontWeight: 400,
                        }}
                        onClick={handleDownload}
                    >
                        Report with Picture
                    </Button>
                    <ExportCSV
                        data={dataCutOff}
                        filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                        initial="Summary"
                    />
                    <ExportCSV
                        data={rawData}
                        filename={`Attendance Report ${new Date().toLocaleDateString()}`}
                        initial="Overtime Report"
                    />
                </StyledMenu>
            </div>
            <div className={Styles.SCContent}>
                {dataToMap?.map((each, i) => {
                    const firstDay = each?.attendances[0]?.date?.getDay() - 1
                    const preFiller = makePreFiller(firstDay !== -1 ? firstDay : 6)
                    const postFiller = makePostFiller(preFiller?.length, each?.attendances?.length)
                    return (
                        <div key={i} className={Styles.CalendarCard}>
                            <div className={Styles.CalendarHeader}>
                                <span>{each?.month} {each?.year}</span>
                            </div>
                            <div className={Styles.Daysbar}>
                                {days2?.long?.map((each, i) => {
                                    return (
                                        <div key={i} className={`${Styles.EachDay} ${(i === days2.long.length - 1 || i === days2.long.length - 2) && Styles.Weekend}`}><span>{each}</span></div>
                                    )
                                })}
                            </div>

                            <div className={Styles.AbsencesContainer}>
                                {preFiller?.map((fill, iF) => {
                                    return <div key={iF} className={Styles.AbsenceBox}></div>
                                })}
                                {each?.attendances?.map((eachA, ia) => {
                                    const isWeekend = eachA?.date?.getDay() === 0 || eachA?.date?.getDay() === 6
                                    const fixedDate = eachA?.date?.getDate()?.toString()?.length === 1 ? "0" + String(eachA?.date?.getDate()) : eachA?.date?.getDate()
                                    return (
                                        <div key={ia} className={`${Styles.AbsenceBox} ${isWeekend && Styles.Weekend}`} onClick={() => setSelectedAbsence(eachA)}>
                                            <div className={Styles.ABTop}>
                                                <div className={Styles.ABTopLeft}>
                                                    <span>{fixedDate}</span>
                                                    {(isWeekend && eachA?.type === '-') && <span>Weekend</span>}
                                                    {(isWeekend && eachA?.type !== '-') && <span>{eachA?.type}</span>}
                                                    {(!isWeekend) && <span>{eachA?.type}</span>}
                                                </div>
                                                {eachA?.type === "Attend" && <span>&#128516;</span>}
                                                {eachA?.type === "Permit" && <span>&#128188;</span>}
                                                {eachA?.type === "Leave" && <span>&#127958;</span>}
                                                {eachA?.type === "Sick" && <span>&#129314;</span>}
                                                {(eachA?.type === "-" && !isWeekend) && <span> &#128123;</span>}
                                                {(eachA?.type === "-" && isWeekend) && <span>&#127958;</span>}
                                            </div>
                                            <div className={Styles.ABCenter}>
                                                <AccessTime className={Styles.ClockIcon} />
                                                {eachA?.startTime && <span>{eachA?.startTime}</span>}
                                                <span>-</span>
                                                {eachA?.endTime !== 'Invalid date' ? <span>{eachA?.endTime}</span> : <span>...</span>}
                                            </div>
                                            {(eachA?.startTime) && (
                                                <div className={Styles.ABBottom}>
                                                    <span>OT</span>
                                                    <Switch size="small" onChange={(e) => handleChangeOvertime(eachA?._id)} 
                                                    checked={eachA?.overtime?.approverId ? true : false} 
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                                {postFiller?.map((fill, iF) => {
                                    return <div key={iF} className={`${Styles.AbsenceBox} ${(iF === postFiller.length-1 || iF === postFiller.length - 2) && Styles.Weekend}`}></div>
                                })}
                            </div>
                        </div>
                    )
                })}

            </div>
            <div className={Styles.Closing}></div>
        </div>
    )
}