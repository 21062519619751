import { Breadcrumbs } from "@mui/material";
import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function Breadcrumb({ children }) {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={
        <NavigateNextIcon fontSize="medium" style={{ color: "#6F6F70" }} />
      }
    >
      {children}
    </Breadcrumbs>
  );
}
