import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { DetailsStoreWithMaps } from "../../../../components/FMCG";

export default function DetailsStore() {
  const location = useLocation();
  const locationPath = location.state.location;

  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Setup</title>
      </Helmet>
      <DetailsStoreWithMaps location={locationPath} />
    </div>
  );
}
