import React, { useState } from "react";
import "./LayoutFMCG.css";
import { Route } from "react-router-dom";
import SnackbarComponent from "../../utils/Snackbar/SnackbarComponent";
import { Redirect } from "react-router";
import MiniScreen from "../../utils/MiniScreen";
import SidebarFMCG from "../../FMCG/SidebarFMCG/SidebarFMCG";
import TopNavFMCG from "../../FMCG/TopNavFMCG/TopNavFMCG";
import RoutesFMCG from "../../FMCG/Routes/RoutesFMCG";

const LayoutFMCG = () => {
  const token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);

  if (!token) {
    return <Redirect to="/" />;
  }
  return (
    <Route
      render={(props) => (
        <>
          <div className="LayoutFMCG" 
          // style={{ height: '100vh'}}
          >
            <SidebarFMCG
              {...props}
              onOpenSidebar={open}
              onCloseSidebar={() => setOpen(false)}
            />
            <div className={`LayoutFMCG_content ${!open && "close"}`}>
              <TopNavFMCG onOpenSidebar={() => setOpen(true)} />
              <div className="LayoutFMCG_content-main">
                <SnackbarComponent />
                <RoutesFMCG />
              </div>
            </div>
          </div>
          <div className="mini__screen-layout">
            <MiniScreen />
          </div>
        </>
      )}
    />
  );
};

export default LayoutFMCG;
