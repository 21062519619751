/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Styles from "./Setting.module.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ModalTabs from "../ModalTabs/ModalTabs";
import SearchbarSA from "../../../../utils/SearchbarSA/SearchbarSA";
import {
  fetchAllAttendances,
  fetchAttendanceById,
} from "../../../../../redux/actions/SuperAdmin/attendancesActions";
import LoadingAnimation from "../../../../utils/LoadingAnimation/LoadingAnimation";

export default function Setting({ attendances, loading }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const [dataAttendances, setDataAttendances] = useState(attendances);
  const [selectedAttendance, setSelectedAttendance] = useState({}); //
  const [anchorEl, setAnchorEl] = useState(null);
  const [listAttendance, setListAttendances] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const handleSearch = (event) => {
    setListAttendances(event.target.value);
  };
  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchTerm(listAttendance);
  };
 
  useEffect(() => {
    dispatch(fetchAllAttendances(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickAdd = (e) => {
    e.preventDefault();
    history.push("/superadmin/attendance-add-scheme");
  }

  return (
    <div className={Styles.Container}>
      <div className={Styles.Header}>
        <div className={Styles.Searchbar}>
          <SearchbarSA
            value={listAttendance}
            onChange={handleSearch}
            onSubmit={handleSubmitFilter}
            searchWidth="379px"
            placeholder="Search by name"
          />
        </div>
        <div className={Styles.ButtonAddAttendance}>
            <Button
              startIcon={<AddIcon style={{ color: "white", fontSize: 16 }} />}
              className={Styles.AddButton}
              onClick={handleClickAdd}
            >
              <span>Add Scheme</span>
            </Button>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 50,
          }}
        >
          <div style={{ width: 200 }}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={Styles.WrapperCard}>
          {
            attendances
              .filter((el) =>
                el.name.toLowerCase().includes(listAttendance.toLowerCase())
              )
              .map((attendance, index) => {
                return (
                  <div className={Styles.Card} key={index}>
                    <div className={Styles.CardHeader}>
                      <span className={Styles.CardHeaderIcon}>
                        <CalendarMonthIcon style={{ color: '#1571DE' }} />
                      </span>
                      <span className={Styles.CardHeaderTitle}>{attendance.name}</span>
                    </div>
                    <div className={ Styles.CardBody }>
                      <span>
                      {attendance.remark
                        ? attendance.remark
                        : "Settings view for your clients"}
                      </span>
                    </div>
                    <div className={Styles.CardFooter}>
                      <div className={Styles.CardFooterSetting} onClick={() => {
                        dispatch(fetchAttendanceById(token, attendance._id));
                        history.push({
                          pathname: `/superadmin/setting-attendance-view/${attendance._id}`,
                          state: {
                            dataSchema: attendance,
                          },
                        });
                      }}>
                        <span>
                          View Settings
                        </span>
                        <ArrowRightAltIcon
                          style={{ color: "#1571DE", marginLeft: 8 }}
                        />

                      </div>
                    </div>

                  </div>
                );
              })}
        </div>
      )}

      {selectedAttendance._id && (
        <ModalTabs
          setSelectedAttendance={setSelectedAttendance}
          pathName="/superadmin/attendance"
          attendance={selectedAttendance}
        />
      )}
    </div>
  );
}
