// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import image_company from "../../../../assets/images/Company.png";
import { Avatar } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import { useLocation, useParams } from "react-router";
// eslint-disable-next-line no-unused-vars
import dataEmployeeByRangeDateId from "../../../../components/_async/index";
// eslint-disable-next-line no-unused-vars
import { fetchAllDataEmployeeByRangeDateId } from "../../../../redux/actions/SuperAdmin/dataEmployeeInternalActions";
import moment from "moment";
import "moment/min/locales";
// import ExportCSV from "../../../SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import BigCalendar from "../../../SuperAdmin/_attendance/attendance-detail-sa/BigCalendar";
// moment.locale("id");
import toHoursAndMinute from '../../../../components/utils/ToHoursAndMinute/toHoursAndMinute'
import ExportDataExcel from "../../../SuperAdmin/_attendance/tabs/DataAttendance/ExportDataExcel";

export default function AttendanceDetail() {
  const location = useLocation();
  const dateFrom = location?.state?.startDate;
  const dateTo = location?.state?.endDate;
  const attendanceSummary = location?.state?.summary;
  const wrappedFunction = [attendanceSummary].flat();
  const clientName = localStorage.getItem('clientName');

  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  // const formatGetTime = (time) => {
  //   return new Date(time)?.getTime() - 7 * 60 * 60 * 1000;
  // }

  useEffect(() => {
  },[clientName])

  const attendanceArr = () => {
    const array = [];
    for (let i = 0; i < wrappedFunction?.length; i++) {
      const absences = wrappedFunction[i]?.Absences;
      for (let j = 0; j < absences?.length; j++) {
        array.push({
          ...wrappedFunction[i],
          // timeIn: moment(formatGetTime(absences[j]?.startTime)).format(
          //   "HH:mm:ss"
          // ),
          // timeOut: moment(formatGetTime(absences[j]?.endTime)).format(
          //   "HH:mm:ss"
          // ),
          // dateTimeIn: moment(formatGetTime(absences[j]?.startTime)).format("L"),
          // dateTimeOut: moment(formatGetTime(absences[j]?.endTime)).format("L"),
          timeIn: absences[j].startTime ? moment(absences[j].startTime).format("HH:mm:ss"): "-",
          timeOut: absences[j].endTime && absences[j].endTime !== "-"? moment(absences[j].endTime).format("HH:mm:ss"): "-",
          dateTimeIn: moment(absences[j].startTime).format("L"),
          dateTimeOut: absences[j].endTime && absences[j].endTime !== "-" ? moment(absences[j].endTime).format("L"): "-",
          longitude: absences[j]?.location?.longitude
            ? absences[j]?.location?.longitude
            : "-",
          latitude: absences[j]?.location?.latitude
            ? absences[j]?.location?.latitude
            : "-",
          type: absences[j]?.type,

          month: moment(absences[j].startTime).format("M"),
          year: moment(absences[j].startTime).format("YYYY"),
          isLate: absences[j]?.isLate ,
          totalWorkHour: toHoursAndMinute(absences[j]?.accumulate?.workInMinute),
          region: absences[j]?.area,
          remark: absences[j]?.remark?.includes("::") ? capitalizeFirstLetter(absences[j]?.remark.split("::")[2]) : capitalizeFirstLetter(absences[j]?.remark) ,
          status:
            (absences[j].type === "ATTEND" && "Hadir") ||
            (absences[j].type === "ANNUAL-LEAVE" && "Cuti") ||
            (absences[j].type === "SICK-PERMIT" && "Sakit"), 
        });
      }
    }
    return array;
  }
  const dataToExport = attendanceArr();
  
  if (!attendanceSummary?.employeeId) {
    return null;
  }

  if ( clientName === "PT. CITRA NUSA INSAN CEMERLANG") {
    return (
      <div className="container-att-detail-menu">
      <div className="wrapper-att-detail-menu">
        <div>
          <div className="container-profile-att-detail-menu">
            <div className="profile-att-detail-menu">
              <div className="contaiener-image-att-detail-menu">
                <Avatar
                  src={
                    attendanceSummary.profilePicture
                      ? attendanceSummary.profilePicture
                      : ""
                  }
                />
              </div>
              <div className="text-profile-detail-menu">
                <label>{attendanceSummary?.name}</label>
                <span>{attendanceSummary?.jobTitle}</span>
                <div className="company-profile-detail-menu">
                  <img src={image_company} alt="" />
                  <span>{attendanceSummary?.company}</span>
                </div>
              </div>
            </div>
            <div className="profile-att-summary">
              <div className="title-profile-att-summary">
                <span>Attendance Summary</span>
              </div>
              <div className="wrapper-item-row-att-summary">
                <div className="item-presence">
                  <div className="title-item-presence">
                    <span>Presence</span>
                  </div>
                  <div className="item-sub-presence">
                    <label htmlFor="">
                    {attendanceSummary?.attend} Days
                    </label>
                  </div>
                </div>
                <div className="item-leave">
                  <div className="title-item-leave">
                    <span>Leave</span>
                  </div>
                  <div className="item-sub-presence">
                    <label htmlFor="">
                    {attendanceSummary?.annualLeave} Days
                    </label>
                  </div>
                </div>
                <div className="item-sick">
                  <div className="title-item-sick">
                    <span>Sick</span>
                  </div>
                  <div className="item-sub-presence">
                    <label htmlFor="">
                    {attendanceSummary?.sick} Days
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="button-profile-att-summary">
            <ExportDataExcel
              data={dataToExport
              // .filter(
              //   (el) =>
              //     (
              //       el?.name?.toLowerCase().includes(listDataAttendance?.toLowerCase()) ||
              //       el.jobTitle?.toLowerCase().includes(listDataAttendance?.toLowerCase()) 
              //       ) 
              // )
              .map((el, ind) => ({
                "No. ": ind + 1,
                Nama: el?.name,
                Company: el?.client,
                Position: el.jobTitle,
                Nrk: el?.employeeId,
                Month: el.month,
                Year: el.year,
                "Date in": el.dateTimeIn,
                "Date out": el.dateTimeOut,
                "Time in": el.timeIn,
                "Time out": el.timeOut,
                "Coordinate Point": `${el.latitude} ${el.longitude}`,
                "Late" : el?.isLate,
                "Total Work Hour" : `${el?.totalWorkHour?.hours} Hours ${el?.totalWorkHour?.minutes} Minutes`,
                Region: el?.region,
                "Status Absence": el.status,
                "Remark": el.remark
              }))}
            filename={`Attendance Report ${new Date().toLocaleDateString()}`}
            initial="Download Excel"
            />
            </div>
          </div>
        </div>
        <div style={{ color: "#C2C2C2F", paddingTop: 20 }}>
          <hr />
        </div>
        <BigCalendar
          startDate={new Date(dateFrom)}
          endDate={new Date(dateTo)}
          attendanceSummary={attendanceSummary}
        />
      </div>
    </div>
    )
  }

  return (
    <div className="container-att-detail-menu">
      <div className="wrapper-att-detail-menu">
        <div>
          <div className="container-profile-att-detail-menu">
            <div className="profile-att-detail-menu">
              <div className="contaiener-image-att-detail-menu">
                <Avatar
                  src={
                    attendanceSummary.profilePicture
                      ? attendanceSummary.profilePicture
                      : ""
                  }
                />
              </div>
              <div className="text-profile-detail-menu">
                <label>{attendanceSummary?.name}</label>
                <span>{attendanceSummary?.jobTitle}</span>
                <div className="company-profile-detail-menu">
                  <img src={image_company} alt="" />
                  <span>{attendanceSummary?.company}</span>
                </div>
              </div>
            </div>
            <div className="profile-att-summary">
              <div className="title-profile-att-summary">
                <span>Attendance Summary</span>
              </div>
              <div className="wrapper-item-row-att-summary">
                <div className="item-presence">
                  <div className="title-item-presence">
                    <span>Presence</span>
                  </div>
                  <div className="item-sub-presence">
                    <label htmlFor="">
                    {attendanceSummary?.attend} Days
                    </label>
                  </div>
                </div>
                <div className="item-leave">
                  <div className="title-item-leave">
                    <span>Leave</span>
                  </div>
                  <div className="item-sub-presence">
                    <label htmlFor="">
                    {attendanceSummary?.annualLeave} Days
                    </label>
                  </div>
                </div>
                <div className="item-sick">
                  <div className="title-item-sick">
                    <span>Sick</span>
                  </div>
                  <div className="item-sub-presence">
                    <label htmlFor="">
                    {attendanceSummary?.sick} Days
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="button-profile-att-summary">
            <ExportDataExcel
              data={dataToExport
              // .filter(
              //   (el) =>
              //     (
              //       el?.name?.toLowerCase().includes(listDataAttendance?.toLowerCase()) ||
              //       el.jobTitle?.toLowerCase().includes(listDataAttendance?.toLowerCase()) 
              //       ) 
              // )
              .map((el, ind) => ({
                "No. ": ind + 1,
                Nama: el?.name,
                Company: el?.client,
                Position: el.jobTitle,
                Nrk: el?.employeeId,
                Month: el.month,
                Year: el.year,
                "Date in": el.dateTimeIn,
                "Date out": el.dateTimeOut,
                "Time in": el.timeIn,
                "Time out": el.timeOut,
                "Coordinate Point": `${el.latitude} ${el.longitude}`,
                // totalJamKerja \\ CNI
                // isLate true or false  \\ CNI
                Region: el.division,
                "Status Absence": el.status,
                "Remark": el.remark
              }))}
            filename={`Attendance Report ${new Date().toLocaleDateString()}`}
            initial="Download Excel"
            />
              {/* <ExportCSV
                data={
                  dataToExport?.map((el, ind) => ({
                  "No. ": ind + 1,
                  Nama: el.Name,
                  Position: el.Position,
                  Nrk: el.Nrk,
                  "Date in": el.dateTimeIn,
                  "Date out": el.dateTimeOut,
                  "Time in": el.timeIn,
                  "Time out": el.timeOut,
                  "Coordinate Point": `${el.latitude} ${el.longitude}`,
                  Latitude: el.latitude,
                  Longitude: el.longitude,
                  "Status Absence": (el.type === 'presence' && "Hadir") || (el.type === 'annualLeave' && "Cuti") || (el.type === 'permit' && 'Sakit'),
                }))}
                initial="Download"
                endIcon={<DownloadIcon />}
                filename={`Attendance Report ${new Date().toLocaleDateString()}`}
              /> */}
            </div>
          </div>
        </div>
        <div style={{ color: "#C2C2C2F", paddingTop: 20 }}>
          <hr />
        </div>
        <BigCalendar
          startDate={new Date(dateFrom)}
          endDate={new Date(dateTo)}
          attendanceSummary={attendanceSummary}
        />
      </div>
    </div>
  );
}
