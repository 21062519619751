/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { EmployeeExternal } from "../../../components/External/Employee";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEmployeesExternal } from "../../../redux/actions/External/employeesExternalActions";
import EmployeeExternalBackup from "../../../components/External/Employee/EmployeeExternal/EmployeeExternalBackup";
import UnderMaintenance from "../../../components/_underMaintenancePage";
import fetchClientCode from "../../../async/client/fetchClientCode";
import fetchClientCodeExternal from "../../../async/client/fetchClientCodeExternal";

const Employee = () => {
  const isMaintenance = false;
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Employee</title>
      </Helmet>
      <div className="wrapperPageStore">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#000000", fontSize: 40 }}
        >
          Employee
        </h2>
      </div>
      <Grid>
        <Grid item xs={12} md={12} lg={12}>
          {isMaintenance ? <UnderMaintenance /> :
            <EmployeeExternalBackup/>}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Employee;
