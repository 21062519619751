/* eslint-disable no-unused-vars */

import styled from "@emotion/styled";
import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { editBank } from "../../../../redux/actions/SuperAdmin/bankActions";
import Styles from './EditBank.module.css';

const MuiTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: 8,
            border: '1px solid #e0e0e0',
        },
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "1.5px solid #d3c9c9",
        borderColor: "#d3c9c9",
        borderRadius: 8,
    },
});

export default function EditBank({bank}) {
    const token = localStorage.getItem("accessToken");
    const dispatch = useDispatch();
    const history = useHistory();

    const [form, setForm] = useState({
        name: bank?.name,
        code: bank?.code,
        swiftCode: bank?.swiftCode,
    })

    const handleChange = (e) => {
        setForm((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(editBank(token,bank?._id, form));
        history.push("/superadmin/detail-bank/" + bank?._id);
    }

    const handleReset = (e) => {
        e.preventDefault();
        setForm((prev) => ({
            ...prev,
            "name": '',
            "code": '',
            "swiftCode": '',
        }))
    }



    return (
        <div className={Styles.ContainerWrapper}>
            <span>Edit Bank Data</span>
            <div className={Styles.Container}>
                <form onSubmit={handleSubmit} className={Styles.Form}>
                    <span>Bank Details</span>
                    <div className={Styles.BoxForm}>
                        <label htmlFor="name">Bank Name</label>
                        <input id="name" type="text" name="name" value={form.name} onChange={handleChange} placeholder="Enter bank name" required />
                    </div>
                    <div className={Styles.BoxForm}>
                        <label htmlFor="code">Bank Code</label>
                        <input id="code" type="text" name="code" value={form.code} onChange={handleChange} placeholder="Enter bank code" required />
                    </div>
                    <div className={Styles.BoxForm}>
                        <label htmlFor="swiftCode">Swift Code</label>
                        <input id="swiftCode" type="text" name="swiftCode" value={form.swiftCode} onChange={handleChange} placeholder="Enter Swift Code" required />
                    </div>
                    <div className={Styles.ButtonWrapper}>
                        <Button type="submit" variant="contained" className={Styles.SaveButton}>Save</Button>
                        <Button type="button" variant="outlined" onClick={handleReset} className={Styles.ResetButton}>Reset</Button>
                    </div>
                </form>
            </div>
        </div>

    )
}