// DATA EMPLOYEE  EXTERNAL 
export const FETCH_DATA_EMPLOYEE_EXTERNAL_BEGIN  = "FETCH_DATA_EMPLOYEE_EXTERNAL_BEGIN";
export const FETCH_DATA_EMPLOYEE_EXTERNAL_SUCCESS  = "FETCH_DATA_EMPLOYEE_EXTERNAL_SUCCESS";
export const FETCH_DATA_EMPLOYEE_EXTERNAL_ERROR  = "FETCH_DATA_EMPLOYEE_EXTERNAL_ERROR";

export const FETCH_EMPLOYEE_ATTENDANCE_BEGIN = "FETCH_EMPLOYEE_ATTENDANCE_BEGIN"
export const FETCH_EMPLOYEE_ATTENDANCE_SUCCESS="FETCH_EMPLOYEE_ATTENDANCE_SUCCESS"
export const FETCH_EMPLOYEE_ATTENDANCE_ERROR = "FETCH_EMPLOYEE_ATTENDANCE_ERROR"

export const FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_BEGIN  = "FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_BEGIN";
export const FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_SUCCESS  = "FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_SUCCESS";
export const FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_ERROR  = "FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_ERROR";

// EMPLOYEE EXTERNAL 
export const FETCH_EMPLOYEE_EXTERNAL_BEGIN = "FETCH_EMPLOYEE_EXTERNAL_BEGIN";
export const FETCH_EMPLOYEE_EXTERNAL_SUCCESS = "FETCH_EMPLOYEE_EXTERNAL_SUCCESS";
export const FETCH_EMPLOYEE_EXTERNAL_ERROR = "FETCH_EMPLOYEE_EXTERNAL_ERROR";

export const FETCH_EMPLOYEE_ADD_LOCATION_BEGIN = "FETCH_EMPLOYEE_ADD_LOCATION_BEGIN";
export const FETCH_EMPLOYEE_ADD_LOCATION_SUCCESS = "FETCH_EMPLOYEE_ADD_LOCATION_SUCCESS";
export const FETCH_EMPLOYEE_ADD_LOCATION_ERROR = "FETCH_EMPLOYEE_ADD_LOCATION_ERROR";

export const FETCH_EMPLOYEE_EXTERNAL_BY_ID = "FETCH_EMPLOYEE_EXTERNAL_BY_ID";

export const UPDATE_EMPLOYEE_BY_ID = "UPDATE_EMPLOYEE_BY_ID";
export const DELETE_EMPLOYEE_BY_ID = "DELETE_EMPLOYEE_BY_ID"; 

// EMPLOYEE EXTERNAL ATTENDANCE DATA
export const FETCH_EMPLOYEE_EXTERNAL_ATTENDANCE_DATA_BEGIN = "FETCH_EMPLOYEE_EXTERNAL_ATTENDANCE_DATA_BEGIN";
export const FETCH_EMPLOYEE_EXTERNAL_ATTENDANCE_DATA_SUCCESS = "FETCH_EMPLOYEE_EXTERNAL_ATTENDANCE_DATA_SUCCESS";
export const FETCH_EMPLOYEE_EXTERNAL_ATTENDANCE_DATA_ERROR = "FETCH_EMPLOYEE_EXTERNAL_ATTENDANCE_DATA_ERROR";

// BPJS 
export const FETCH_ALL_BPJS_DATA_EXTERNAL_BEGIN = "FETCH_ALL_BPJS_DATA_EXTERNAL_BEGIN";
export const FETCH_ALL_BPJS_DATA_EXTERNAL_SUCCESS = "FETCH_ALL_BPJS_DATA_EXTERNAL_SUCCESS";
export const FETCH_ALL_BPJS_DATA_EXTERNAL_ERROR = "FETCH_ALL_BPJS_DATA_EXTERNAL_ERROR";
export const FETCH_BPJS_DATA_EXTERNAL_BY_ID = "FETCH_BPJS_DATA_EXTERNAL_BY_ID";


// LOCATION 
// export const CREATE_LOCATION_BEGIN = "CREATE_LOCATION_BEGIN";
export const CREATE_LOCATION_STORE = "CREATE_LOCATION_STORE";
export const DELETE_LOCATION_STORE = "DELETE_LOCATION_STORE";
export const UPDATE_LOCATION_STORE = "UPDATE_LOCATION_STORE";

// export const CREATE_LOCATION_ERROR = "CREATE_LOCATION_ERROR";

export const GET_LOCATION_BY_ID_BEGIN = "GET_LOCATION_BY_ID_BEGIN"
export const GET_LOCATION_BY_ID_SUCCESS = "GET_LOCATION_BY_ID_SUCCESS"
export const GET_LOCATION_BY_ID_ERROR = "GET_LOCATION_BY_ID_ERROR"

export const SET_USER_TO_LOCATION = "SET_USER_TO_LOCATION"
export const UNSET_USER_FROM_LOCATION = "UNSET_USER_FROM_LOCATION"


export const FETCH_USER_BY_LOCATION_BEGIN = "FETCH_USER_BY_LOCATION_BEGIN";
export const FETCH_USER_BY_LOCATION_SUCCESS = "FETCH_USER_BY_LOCATION_SUCCESS";
export const FETCH_USER_BY_LOCATION_ERROR = "FETCH_USER_BY_LOCATION_ERROR";

export const FETCH_USER_UPDATE_BY_LOCATION_BEGIN = "FETCH_USER_UPDATE_BY_LOCATION_BEGIN";
export const FETCH_USER_UPDATE_BY_LOCATION_SUCCESS = "FETCH_USER_UPDATE_BY_LOCATION_SUCCESS";
export const FETCH_USER_UPDATE_BY_LOCATION_ERROR = "FETCH_USER_UPDATE_BY_LOCATION_ERROR";

export const FETCH_LOCATION_USER_NOT_ASSIGNED_BEGIN = "FETCH_LOCATION_USER_NOT_ASSIGNED_BEGIN";
export const FETCH_LOCATION_USER_NOT_ASSIGNED_SUCCESS = "FETCH_LOCATION_USER_NOT_ASSIGNED_SUCCESS";
export const FETCH_LOCATION_USER_NOT_ASSIGNED_ERROR = "FETCH_LOCATION_USER_NOT_ASSIGNED_ERROR";

export const FETCH_ALL_LOCATION_BEGIN = "FETCH_ALL_LOCATION_BEGIN";
export const FETCH_ALL_LOCATION_SUCCESS = "FETCH_ALL_LOCATION_SUCCESS";
export const FETCH_ALL_LOCATION_ERROR = "FETCH_ALL_LOCATION_ERROR";

export const FETCH_ALL_EMPLOYEE_WORK_ON_BEGIN = "FETCH_ALL_EMPLOYEE_WORK_ON_BEGIN";
export const FETCH_ALL_EMPLOYEE_WORK_ON_SUCCESS = "FETCH_ALL_EMPLOYEE_WORK_ON_SUCCESS";
export const FETCH_ALL_EMPLOYEE_WORK_ON_ERROR= "FETCH_ALL_EMPLOYEE_WORK_ON_ERROR";

export const FETCH_CLIENT_BY_CODE_BEGIN = "FETCH_CLIENT_BY_CODE_BEGIN"
export const FETCH_CLIENT_BY_CODE_SUCCESS = "FETCH_CLIENT_BY_CODE_SUCCESS"
export const FETCH_CLIENT_BY_CODE_ERROR = "FETCH_CLIENT_BY_CODE_ERROR"

export const FETCH_DETAIL_BY_CLIENT_BEGIN = "FETCH_DETAIL_BY_CLIENT_BEGIN"
export const FETCH_DETAIL_BY_CLIENT_SUCCESS = "FETCH_DETAIL_BY_CLIENT_SUCCESS"
export const FETCH_DETAIL_BY_CLIENT_ERROR = "FETCH_DETAIL_BY_CLIENT_ERROR"













