import * as types from '../../type'

const initialstate = {
  allInformation: [],
  informationById: {},
  notifInformationById:[],
  updatedInformation: {},
  loadingDataInformation: false,
  loadingUpdateInformation: false,
  errorMessageInformation: {},
  
  addedNotification: {},
  updatedNotification: {},
  allNotification: {},
  totalPageNotification: 0,
  loadingNotification: false,
  notificationById: {},
  errorNotification: {}
};

const informationReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_INFORMATION_BEGIN: {
      return {
        ...state,
        loadingDataInformation: true
      };
    }
    case types.FETCH_ALL_INFORMATION_SUCCESS: {
      return {
        ...state,
        loadingDataInformation: false,
        allInformation: action.payload,
      };
    } case types.FETCH_ALL_INFORMATION_ERROR: {
      return {
        ...state,
        loadingDataInformation: false,
        errorMessageInformation: action.payload
      };
    }
    case types.FETCH_ALL_INFORMATION_BY_ID: {
      return {
        ...state,
        informationById: action.payload,
      };
    }
    case types.FETCH_NOTIF_INFORMATION_BY_ID: {
      return {
        ...state,
        notifInformationById: action.payload,
      };
    }
    case types.UPDATE_INFORMATION_BEGIN: {
      return {
        ...state,
        loadingUpdateInformation: true
      };
    }
    case types.UPDATE_INFORMATION: {
      return {
        ...state,
        loadingUpdateInformation: false,
        updatedInformation: action.payload,
      };
    }
    case types.ADD_NOTIFICATION: {
      return {
        ...state,
        addedNotification: action.payload,
      };
    }
    case types.UPDATE_NOTIFICATION: {
      return {
        ...state,
        updatedNotification: action.payload,
        loadingUpdateInformation: false,
      };
    }
    case types.NOTIFICATION_BEGIN: {
      return {
        ...state,
        loadingNotification: true
      }
    }
    case types.NOTIFICATION_ERROR: {
      return {
        ...state,
        loadingNotification: false,
        errorNotification: action.payload
      }
    }
    case types.ALL_NOTIFICATION: {
      return {
        ...state,
        loadingNotification: false,
        allNotification: action.payload,
        totalPageNotification: action.totalPage
      }
    }
    case types.DETAIL_NOTIFICATION: {
      return {
        ...state,
        loadingNotification: false,
        notificationById: action.payload,
      }
    }
    case types.DELETE_NOTIFICATION: {
      return {
        ...state,
        loadingNotification: false
      }
    }
    case types.RESET_NOTIFICATION: {
      return {
        ...state,
        addedNotification: {},
        updatedNotification: {},
        errorNotification: {}
      }
    }
    default:
      return state;
  }
};

export default informationReducer;
