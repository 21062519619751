import React from "react";
import Styles from "./HeaderIntro.module.css";

export default function HeaderIntro() {
  return (
    <div className={Styles.Content}>
      <div className={Styles.Wrapper}>
        <label>Daftar Produk yang harus di Refill jumlah stok nya:</label>
        <span>Toko SPG</span>
      </div>
    </div>
  );
}
