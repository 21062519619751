import axios from "axios";
import {API} from "../../api";
import * as types from '../../typeExternal';
import { setSnackbar } from '../SuperAdmin/snackbarActions';

// FETCH ALL LOCATION 
export const fetchAllLocationExternal = (token, query) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_ALL_LOCATION_BEGIN })
    axios
      .get(`${API}/location/location-external?${query}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_LOCATION_SUCCESS,
          payload: res.data.response,
        });
        // console.log(res.data.data, 'check data')
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_ALL_LOCATION_ERROR, payload: err.response })
      });
  };
};

// GET LOCATION BY ID 
export const fetchLocationById = (token, idLocation) => {
  return (dispatch) => {
    dispatch({ type: types.GET_LOCATION_BY_ID_BEGIN })
    axios
      .get(`${API}/location/admin/${idLocation}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.GET_LOCATION_BY_ID_SUCCESS,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        console.log(err)
        dispatch({ type: types.GET_LOCATION_BY_ID_ERROR, payload: err.response })
      });
  };
};


// FETCH USER BY CLIENT 
export const fetchUserByLocationExternal = (token, clientName) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_USER_BY_LOCATION_BEGIN })
    axios
      .get(`${API}/location/user?client=${clientName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_USER_BY_LOCATION_SUCCESS,
          payload: res.data.data,
        });
        // console.log(res.data.data, 'from actions')
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_USER_BY_LOCATION_ERROR, payload: err.response })
      });
  };
};

// FETCH USER FOR UPDATE 
export const fetchUserForUpdateExternal = (token, locationId, clientName) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_USER_UPDATE_BY_LOCATION_BEGIN })
    axios
      .get(`${API}/location/user/${locationId}?client=${clientName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_USER_UPDATE_BY_LOCATION_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_USER_UPDATE_BY_LOCATION_ERROR, payload: err.response })
      });
  };
};

// CREATE LOCATION
export const createLocation = (token, body, query) => {
  return (dispatch) => {
    return axios
      .post(`${API}/location`, body, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.CREATE_LOCATION_STORE,
          payload: res.data.response,
        });
        return Promise.resolve();
      })
      // .then(() => {
      //   dispatch(
      //     setSnackbar({
      //       snackbarOpen: true,
      //       snackbarType: "success",
      //       snackbarMessage: "Location created successfully",
      //     })
      //   );
      //   return Promise.resolve();
      // })
      .then(() => {
        dispatch(fetchAllLocationExternal(token, query));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.message,
          })
        );
        return Promise.reject(err);
      });
  };
};

// UPDATE LOCATION
export const updateLocation = (token, id, body) => {
  return (dispatch) => {
    return axios
      .patch(`${API}/location/${id}`, body, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.UPDATE_LOCATION_STORE,
          payload: res.data.data,
        });
        return Promise.resolve();
      })
      // .then(() => {
      //   dispatch(
      //     setSnackbar({
      //       snackbarOpen: true,
      //       snackbarType: "success",
      //       snackbarMessage: "Location updated successfully",
      //     })
      //   );
      //   return Promise.resolve();
      // })
      .catch((err) => {
        console.log(err);
        // dispatch(
        //   setSnackbar({
        //     snackbarOpen: true,
        //     snackbarType: "error",
        //     snackbarMessage: err.response.data.message,
        //   })
        // );
        return Promise.reject(err);
      });
  };
};

export const setUserToLocation = (token, id, body) => {
  return (dispatch) => {
    axios
      .patch(`${API}/location/admin/${id}`, body, {
        headers: {
          Authorization: token,
        }
      })
      .then((res) => {
        dispatch({
          type: types.SET_USER_TO_LOCATION,
          payload: res.data.response,
        })
      })
      .then(() => {
        dispatch(fetchLocationById(token, id))
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export const unsetUserFromLocation = (token, body) => {
  return (dispatch) => {
    axios
      .delete(`${API}/location/user`, {
        headers: {
          Authorization: token,
        },
        data: body
      })
      .then((res)=> {
        dispatch({
          type: types.UNSET_USER_FROM_LOCATION,
          payload: res.data.response,
        })
      })
      .then(()=> {
        dispatch(fetchLocationById(token, body?.locationId))
      })
      .catch((err)=> {
        console.error(err)
      })
  }
}

// DELETE LOCATION
export const deleteLocation = (token, id, query) => {
  return (dispatch) => {
    axios
      .delete(`${API}/location/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.DELETE_LOCATION_STORE,
          payload: res.data.response,
        });
      })
      // .then(() => {
      //   dispatch(
      //     setSnackbar({
      //       snackbarOpen: true,
      //       snackbarType: "success",
      //       snackbarMessage: "Location deleted successfully",
      //     })
      //   );
      // })
      .then(() => {
        dispatch(fetchAllLocationExternal(token, query));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// GET LOCATION BY CLIENT NOT ASSIGNED 
export const fetchLocationClientNotAssigned = (token, locationId, clientName) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_LOCATION_USER_NOT_ASSIGNED_BEGIN })
    axios
      .get(`${API}/location/user/${locationId}?client=${clientName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_LOCATION_USER_NOT_ASSIGNED_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_LOCATION_USER_NOT_ASSIGNED_ERROR, payload: err.response })
      });
  };
};




