import React, { useRef, useEffect, useState } from "react";
import excel_logo from "../../../../../assets/images/excel-image.png";
import { Button } from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import GetAppIcon from "@mui/icons-material/GetApp";
import * as XLSX from "xlsx";

function ModalUpload({ setModal, setFileData }) {
  const modalRef = useRef();
  const [item, setItem] = useState(null);
  // const [file, setFile] = useState(null);
  const ref = useRef(null);

  const readExcel = (file) => {
    // console.log(file);
    setItem(file);
    const promise = new Promise((resolve, project) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };
      fileReader.onerror = (error) => {
        Promise.reject(error);
      };
    });

    promise.then((d) => {
      // setItem(d);
      setFileData(d);
      // setOpenModal(false);
    });
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setModal(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", closeModal);

    return () => {
      document.removeEventListener("click", closeModal);
    };
  });

  return (
    <div className="modalBackground" ref={modalRef} onClick={closeModal}>
      <div className="modalContainer">
        <div className="title">
          <span>Download Template</span>
        </div>
        <div className="body">
          <img src={excel_logo} alt="" />
          <div className="body__items-wrapper">
            <span>Template .Xls</span>
            <Button endIcon={<GetAppIcon />} className="button__download-now">
              Download Now
            </Button>
          </div>
        </div>
        <div className="footer">
          <input
            accept="file/*"
            type="file"
            id="upload-data"
            onChange={(e) => {
              const file = e.target.files[0];
              readExcel(file);
            }}
            ref={ref}
            style={{ display: "none" }}
          />
          <span>{item ? item?.name : "Already have files ?"}</span>
          {item ? (
            <>
              <label htmlFor="upload-data" style={{ marginLeft: 20 }}>
                <Button
                  // endIcon={<PublishIcon />}
                  style={{
                    backgroundColor: "#1571de",
                    color: "#FAFAFA",
                    textTransform: "none",
                  }}
                  component="span"
                  onClick={(e) => setModal(false)}
                >
                  Upload
                </Button>
              </label>
            </>
          ) : (
            <label htmlFor="upload-data" style={{ marginLeft: 20 }}>
              <Button
                endIcon={<PublishIcon />}
                style={{
                  backgroundColor: "#1571de",
                  color: "#FAFAFA",
                  textTransform: "none",
                }}
                component="span"
              >
                Upload File
              </Button>
            </label>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalUpload;
