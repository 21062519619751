import React, { useEffect }  from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
import { EditLocationExternalMenu } from "../../../components/External/Location";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router";
// eslint-disable-next-line no-unused-vars
import { fetchLocationById, fetchLocationClientNotAssigned } from "../../../redux/actions/External/locationActions";

export default function EditLocation() {
  const history = useHistory();
  const token = localStorage.getItem('accessToken');
  const location = useLocation()
  const locationData= useSelector((state) => location?.state?.data)
  const clientName= useSelector((state) => location?.state?.clientName)
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchLocationById(token, id))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])



  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Edit Location Data</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/admin/location-external")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Location</span>
            </Button>
            <span style={{ fontSize: 20, color: "#255bfc" }}>Edit Location</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <EditLocationExternalMenu 
            clientName={clientName}
            locationData={locationData}
            id={id}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
