/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import { useDropzone } from "react-dropzone";
import PublishIcon from "@mui/icons-material/Publish";
import InputText from "../../../utils/Input/Input";
import { useDispatch } from "react-redux";
import {
  updateEmployeeById,
  updateWorkTimeAndSpv,
} from "../../../../redux/actions/SuperAdmin/employeesActions";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import UseGetWorkTime from "../../../utils/hooks/useGetWorkTime";
// import { UseGetAllPosition, UseGetAllRegion } from "../../../utils/hooks";

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
    "& .MuiSelect-select": {
      fontSize: 16,
    },
  },
});

export default function AppEditEmployeeMenu({
  employeeData,
  position,
  regional,
  level,
  leader,
}) {
  const getWorkTime = UseGetWorkTime(employeeData._id);
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigateBack = () => history.go(-1);
  const optionsGmt = ["WIB", "WITA", "WIT"];
  const optionsStatus = ["Active", "Non Active"];
  const [employeeName, setEmployeeName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedLeader, setSelectedLeader] = useState({
    Name: "",
    Nrk: "",
  });
  const [selectedLeader2, setSelectedLeader2] = useState({
    Name: "",
    Nrk: "",
  });
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedGmt, setSelectedGmt] = useState("");
  const [selectedRegional, setSelectedRegional] = useState("");
  const [selectedBM, setSelectedBM] = useState({
    Name: "",
    Nrk: "",
  });
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [address, setAddress] = useState("");
  const [files, setFiles] = useState([]);
  const clientInternal = "PT. Permata Indo Sejahtera";
  let persistClient = window.sessionStorage.getItem('clientNameEmployee');

  useEffect(() => {
    if(!employeeData?.OfficeHourGroup) {
      setSelectedTime("Select Work Time")
    } else {
      setSelectedTime(
        // getWorkTime[
        //   employeeData.OfficeHourGroup === "Group3" ? 1 : employeeData.OfficeHourGroup === "Group2" ? 2 : 0
        // ]
        getWorkTime?.find((data) => data?.Name === employeeData?.OfficeHourGroup)
        // getWorkTime[getWorkTime.findIndex((data) => data.Name === employeeData?.OfficeHourGroup)]
        )
    }
    setEmployeeName(employeeData?.Name);
    setPhoneNumber(employeeData?.PhoneNumber);
    setSelectedPosition(employeeData?.Position);
    setAddress(employeeData?.Address);
    setSelectedLevel(employeeData?.Level);
    setSelectedGmt(employeeData?.TimeZone);
    setSelectedRegional(employeeData?.EmployeeArea);
    setSelectedStatus(employeeData?.Status === true ? "Active" : "Non Active");
    setSelectedLeader((prev) => ({
      ...prev,
      Name: employeeData?.SPV
        ? employeeData?.SPV[0]?.SPV_1?.Name?.toUpperCase()
        : "",
      Nrk: employeeData?.SPV ? employeeData?.SPV[0]?.SPV_1?.Nrk : "",
    }));
    setSelectedLeader2((prev) => ({
      ...prev,
      Name: employeeData?.SPV
        ? employeeData?.SPV[1]?.SPV_2?.Name?.toUpperCase()
        : "",
      Nrk: employeeData?.SPV ? employeeData?.SPV[1]?.SPV_2?.Nrk : "",
    }));
    setSelectedBM((prev) => ({
      ...prev,
      Name: employeeData?.BM ? employeeData?.BM[0]?.Name?.toUpperCase() : "",
      Nrk: employeeData?.BM ? employeeData?.BM[0]?.Nrk : "",
    }));
    // setFiles([employeeData.ProfilePicture]);
    setFiles([{ preview: employeeData?.ProfilePicture }]);
    setPhoneNumber(employeeData?.PhoneNumber)
  }, [employeeData, getWorkTime]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("Name", employeeName);
    formData.append("PhoneNumber", phoneNumber);
    formData.append("Address", address);
    formData.append("Position", selectedPosition);
    formData.append("TimeZone", selectedGmt);
    formData.append("Status", selectedStatus === "Active");
    formData.append("Level", selectedLevel);
    formData.append("EmployeeArea", selectedRegional);
    formData.append("image", files ? files[0] : files[0]);

    const data = {
      OfficeHourGroup: selectedTime?.Name,
      BM: {
        Nrk: selectedBM.Nrk,
        Name: selectedBM.Name,
      },
      // SPV: {
      SPV_1: {
        Nrk: selectedLeader.Nrk,
        Name: selectedLeader.Name,
      },
      SPV_2: {
        Nrk: selectedLeader2.Nrk,
        Name: selectedLeader2.Name,
      },
      // },
    };

    if(formData) {
      dispatch(updateEmployeeById(token, employeeData._id, formData, persistClient))
      history.push("/superadmin/employee");
    };
    // BM , SPV 1, SPV 2 , WORK TIME 
    if(Object.keys(selectedLeader.Nrk).length > 0 && Object.keys(selectedLeader2.Nrk).length > 0 && Object.keys(selectedBM.Nrk).length > 0 && selectedTime?.Name.length > 0) {
      dispatch(updateWorkTimeAndSpv(token, employeeData._id, data, persistClient));
      history.push("/superadmin/employee");
    } 
    // BM , SPV 1,  WORK TIME 
    else if (Object.keys(selectedLeader.Nrk).length > 0 && Object.keys(selectedBM.Nrk).length > 0 && selectedTime?.Name.length > 0) {
      dispatch(updateWorkTimeAndSpv(token, employeeData._id, data, persistClient));
      history.push("/superadmin/employee");
    } 
    //  SPV 1, SPV 2 , WORK TIME 
    else if (Object.keys(selectedLeader.Nrk).length > 0 && Object.keys(selectedLeader2.Nrk).length > 0 && selectedTime?.Name.length > 0) {
      dispatch(updateWorkTimeAndSpv(token, employeeData._id, data, persistClient));
      history.push("/superadmin/employee");
    } 
    // SPV 1, WORK TIME 
    else if (Object.keys(selectedLeader.Nrk).length > 0 && selectedTime?.Name.length > 0) {
      dispatch(updateWorkTimeAndSpv(token, employeeData._id, data, persistClient));
      history.push("/superadmin/employee");
    } 
    // BM , WORK TIME 
    else if (Object.keys(selectedBM.Nrk).length > 0 && selectedTime?.Name.length > 0) {
      dispatch(updateWorkTimeAndSpv(token, employeeData._id, data, persistClient));
      history.push("/superadmin/employee");
    } 
    // WORK TIME 
    else if (selectedTime?.Name.length > 0) {
      dispatch(updateWorkTimeAndSpv(token, employeeData._id, data, persistClient));
      history.push("/superadmin/employee");
    }
    // for (const pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1], "dataaaaa");
    // }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const images = files?.map((file, ind) => (
    <div key={ind}>
      <img
        src={file.preview}
        style={{ width: 170, height: 170 }}
        alt="preview"
      />
    </div>
  ));
  
  const sortedLeader = leader.sort(function (a,b) {
    if(a.Name < b.Name) {
      return -1
    } 
    return 0
  });


  if (
    getWorkTime === null ||
    position === null ||
    leader === null ||
    level === null ||
    regional === null
  ) 
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 50,
        }}
      >
        <div style={{ width: 200 }}>
          <LoadingAnimation />
        </div>
      </div>
  );
  
  return (
    <div className="employee__manual-container">
      <form className="employee__manual-box">
        <div className="employee__items-desc">
          <div className="employee__external-wrapper">
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Employee Name</label>
              <InputText
                value={employeeName || ""}
                placeholder=""
                onChange={(e) => setEmployeeName(e.target.value)}
                type="text"
              />
            </div>
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Position</label>
              <FormControl fullWidth size="small" style={{ width: "100%" }}>
                <Select
                  className={classes.selectBorder}
                  value={selectedPosition || ""}
                  displayEmpty
                  onChange={(e) => setSelectedPosition(e.target.value)}
                >
                  {/* <MenuItem value="">Select Position</MenuItem> */}
                  {position.sort().map((option, el) => (
                    <MenuItem
                      key={el}
                      onClick={() => setSelectedPosition(option)}
                      value={option || ""}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div
            className="employee__external-wrapper"
            style={{ paddingTop: 20 }}
          >
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Addres</label>
              <InputText
                value={address || ""}
                onChange={(e) => setAddress(e.target.value)}
                placeholder=""
                type="text"
              />
              {/* <FormControl fullWidth size="small">
                <Select
                  className={classes.selectBorder}
                  value={address}
                  displayEmpty
                  onChange={(e) => setAddress(e.target.value)}
                >
                  {optionsStore.map((option, el) => (
                    <MenuItem
                      key={el}
                      onClick={() => setSelectedStore(option)}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </div>
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Phone Number</label>
              <InputText
                value={phoneNumber || ""}
                placeholder=""
                onChange={(e) => setPhoneNumber(e.target.value)}
                type="number"
              />
            </div>
          </div>
          <div
            className="employee__external-wrapper"
            style={{ paddingTop: 20 }}
          >
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Regional</label>
              <FormControl fullWidth size="small">
                <Select
                  className={classes.selectBorder}
                  value={selectedRegional || ""}
                  displayEmpty
                  onChange={(e) => setSelectedRegional(e.target.value)}
                >
                  {regional.sort().map((option, el) => (
                    <MenuItem
                      key={el}
                      onClick={() => setSelectedRegional(option)}
                      value={option || ""}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Status</label>
              <FormControl fullWidth size="small">
                <Select
                  className={classes.selectBorder}
                  value={selectedStatus || ""}
                  displayEmpty
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  {optionsStatus.map((option, el) => (
                    <MenuItem
                      key={el}
                      onClick={() => setSelectedStatus(option)}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div
            className="employee__external-wrapper"
            style={{ paddingTop: 20 }}
          >
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Level</label>
              <FormControl fullWidth size="small">
                <Select
                  className={classes.selectBorder}
                  value={selectedLevel || ""}
                  displayEmpty
                  onChange={(e) => setSelectedLevel(e.target.value)}
                >
                  {/* <MenuItem value="">Select Position</MenuItem> */}
                  {level.map((option, el) => (
                    <MenuItem
                      key={el}
                      onClick={() => setSelectedLevel(option)}
                      value={option || ""}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Time Zone</label>
              <FormControl fullWidth size="small">
                <Select
                  className={classes.selectBorder}
                  value={selectedGmt || ""}
                  displayEmpty
                  onChange={(e) => setSelectedGmt(e.target.value)}
                >
                  {optionsGmt.map((option, el) => (
                    <MenuItem
                      key={el}
                      onClick={() => setSelectedGmt(option)}
                      value={option || ""}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div
            className="employee__external-wrapper"
            style={{ paddingTop: 20 }}
          >
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Leader 1</label>
              <FormControl fullWidth size="small">
                <Select
                  name="SPV_1"
                  className={classes.selectBorder}
                  value={selectedLeader || ""}
                  displayEmpty
                  // onChange={(e) =>
                  // setSelectedLeader(
                  //     { target: { name: "SPV_1", value: e.target.value } }
                  //   )
                  // }
                  onChange={(e) => setSelectedLeader(e.target.value)}
                  renderValue={(selected) => selected.Name}
                >
                  {/* <MenuItem value="">Select Leader</MenuItem> */}
                  {sortedLeader.sort().map((option, el) => (
                    <MenuItem key={el} value={option || ""}>
                      {option.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* {employeeData.UserLevel === 1 ? (
              <div className="employee__external-text-input">
                <label style={{ paddingBottom: 8 }}>Leader</label>
                <FormControl fullWidth size="small">
                  <Select
                    className={classes.selectBorder}
                    value={selectedLeader || ""}
                    displayEmpty
                    onChange={(e) => setSelectedLeader(e.target.value)}
                  >
                    <MenuItem value="">Select Leader</MenuItem>
                    {leader.map((option, el) => (
                      <MenuItem
                        key={el}
                        // onClick={() => setSelectedLeader(option)}
                        value={option || ""}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ) : null} */}
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Work Time</label>
              <FormControl fullWidth size="small">
                <Select
                  className={classes.selectBorder}
                  value={selectedTime || ""}
                  displayEmpty
                  onChange={(e) => setSelectedTime(e.target.value)}
                  input={<OutlinedInput placeholder="Select Work Time" />}
                >
                    {getWorkTime.map((option, el) => (
                        <MenuItem key={el} value={option || ""}>
                        Entry Hour : {option.EntryHour}, Break Hour :{" "}
                        {option.BreakHour}, Total Work Hour :
                        {option.TotalWorkHour}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div
            className="employee__external-wrapper"
            style={{ paddingTop: 20 }}
          >
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Leader 2</label>
              <FormControl fullWidth size="small">
                <Select
                  className={classes.selectBorder}
                  value={selectedLeader2 || ""}
                  displayEmpty
                  onChange={(e) => setSelectedLeader2(e.target.value)}
                  renderValue={(selected) => selected.Name}
                >
                  {/* <MenuItem value="">Select Leader</MenuItem> */}
                  {sortedLeader.sort().map((option, el) => (
                    <MenuItem key={el} value={option || ""}>
                      {option.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* {employeeData.UserLevel === 1 ? (
              <div className="employee__external-text-input">
                <label style={{ paddingBottom: 8 }}>Leader</label>
                <FormControl fullWidth size="small">
                  <Select
                    className={classes.selectBorder}
                    value={selectedLeader || ""}
                    displayEmpty
                    onChange={(e) => setSelectedLeader(e.target.value)}
                  >
                    <MenuItem value="">Select Leader</MenuItem>
                    {leader.map((option, el) => (
                      <MenuItem
                        key={el}
                        // onClick={() => setSelectedLeader(option)}
                        value={option || ""}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ) : null} */}
            <div className="employee__external-text-input">
              <label style={{ paddingBottom: 8 }}>Approval Loan</label>
              <FormControl fullWidth size="small">
                <Select
                  className={classes.selectBorder}
                  value={selectedBM || ""}
                  displayEmpty
                  onChange={(e) => setSelectedBM(e.target.value)}
                  input={<OutlinedInput placeholder="Select BM" />}
                  renderValue={(selected) => selected.Name}
                >
                  {sortedLeader.sort().map((option, el) => (
                    <MenuItem key={el} value={option}>
                      {option.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="add__store-container-map" style={{ paddingTop: 20 }}>
            {images ? (
              <div
                {...getRootProps()}
                style={{ width: 300, height: 170, cursor: "pointer" }}
              >
                {images}
                <input
                  type="file"
                  // accept="image/png, image/jpg"
                  {...getInputProps()}
                />
              </div>
            ) : (
              <div className="container__upload-image">
                <div className="wrapper__upload-image" {...getRootProps()}>
                  <input type="text" {...getInputProps()} />
                  <div className="box__input-image">
                    <span>Upload Image Here</span>
                    <div className="box__input-file">
                      <span>File must JPG or PNG</span>
                    </div>
                    <div className="box__input-or">
                      <span> OR </span>
                    </div>
                    <div className="container__input-image">{images}</div>
                    <div className="box__input-button">
                      <Button>
                        <span>Browse File</span>
                        <PublishIcon />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
      <div className="add__employee-manual-btn">
        <Button
          className="add__store-btn-save"
          style={{
            background: "#1571DE",
            color: "#FFFFFF",
            fontSize: 14,
            fontWeight: 500,
          }}
          onClick={handleSubmit}
          // type="submit"
        >
          Save
        </Button>
        <Button
          className="add__store-btn-cancel"
          style={{
            border: "1px solid #1571DE",
            color: "#1571DE",
            fontSize: 14,
            fontWeight: 500,
          }}
          onClick={navigateBack}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}
