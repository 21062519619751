import React from "react";
import "./StoreBestSeller.css";
import store_icon from "../../../../assets/images/best-seller.png";
const data = [
  { name: "UD Sinar Jaya", image: store_icon, sales: 1000 },
  { name: "UD Sinar Jaya", image: store_icon, sales: 1000 },
  { name: "UD Sinar Jaya", image: store_icon, sales: 1000 },
];
const StoreBestSeller = () => {
  return (
    <div className="ContainerStoreBestSeller">
      <div className="WrapperStoreBestSeller">
        <div className="HeaderStoreBestSeller">
          <span>Store Best Seller</span>
        </div>
        <div className="ContentStoreBestSeller">
          {data.map((el, idx) => (
            <div className="WrapperContentBestSeller" key={idx}>
              <div className="">
                <img src={el.image} alt="" />
              </div>
              <div className="">
                <span className="TextNameStoreBestSeller">{el.name}</span>
                <span className="TextSalesStoreBestSeller">{el.sales} Product Sales</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StoreBestSeller;
