import React from "react";
import { Helmet } from "react-helmet";
import { Grid, Container, Button } from "@mui/material";
import { useHistory } from "react-router";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
import { AddProductExternal } from "../../../components/External/Product";

function AddProducts() {
  const history = useHistory();
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Add Products</title>
      </Helmet>
      <div className="">
        <Breadcrumb>
          <Button
            onClick={() => history.push("/admin/product-external")}
            style={{ textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>Prdouct</span>
          </Button>
          <span style={{ fontSize: 20 }}>Add Product</span>
        </Breadcrumb>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <AddProductExternal />
        </Grid>
      </Grid>
    </Container>
  );
}

export default AddProducts;
