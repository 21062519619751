import axios from "axios";
import * as types from "../../type";
import {API} from "../../api";

export const fetchAllActivity = (token, clientCode, limit, startDate, endDate ) => {
  return (dispatch) => {
    axios
      .get(`${API}/activity/admin?limit=${limit}&code=${clientCode}&startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_ACTIVITY_SUCCESS,
          payload: res?.data?.response?.activities,
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_ALL_ACTIVITY_ERROR, payload: err.response });
        console.log(err);
      });
  };
};

export const fetchAllActivityNoRangedate = (token, clientCode, limit, startDate, endDate) => {
  return (dispatch) => {
    axios
      .get(`${API}/activity/admin?limit=${limit}&code=${clientCode}&startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_ACTIVITY_SUCCESS,
          payload: res?.data?.response?.activities,
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_ALL_ACTIVITY_ERROR, payload: err.response });
        console.log(err);
      });
  };
};
