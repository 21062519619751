import React, { useEffect } from "react";
import Styles from "./styles.module.css";
import APPROVAL_LOAN_IMAGE from "../../../assets/images/approved-loan.png";
import { Button } from "@mui/material";
import { useHistory, useLocation } from "react-router";
import axios from "axios";
import {API} from "../../../redux/api";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ApprovalLoanMenu({ title , description }) {
  const history = useHistory();
  let query = useQuery();
  const token = localStorage.getItem('accessToken');

  useEffect(() => {
    axios.get(`${API}/response/approved-first-respondent?id=${query.get("id")}&name=${query.get("name")}`, {
      headers: {
        Authorization : `Bearer ${token}`
      }
    })
    .then((res) => {
      console.log(res)
    })
    .catch((error) => {
      console.log(error)
    })
  })

  return (
    <div className={Styles.Container}>
      <div className={Styles.Image}>
        <img src={APPROVAL_LOAN_IMAGE} alt="Approval Loan" />
      </div>
      <div className={Styles.Wrapper}>
        <div>
          <span className={Styles.TextLabel}>{title}</span>
        </div>
        <div>
          <span className={Styles.TextMain}>
            {description}
          </span>
        </div>
      </div>
      <div className={Styles.Button}>
        <Button style={{ color: '#ffffff'}} onClick={() => history.push('/superadmin')}>Ok</Button>
      </div>
    </div>
  );
}
