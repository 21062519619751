import axios from "axios";
import {API} from "../../api";
import * as types from "../../typeBusiness"

export const fetchAllEBPKKB = (token, page, limit, search) => {
    return (dispatch) => {
        dispatch({type: types.EBP_BEGIN})
        axios.get(`${API}/ebp/kkb?page=${page}&limit=${limit}&search=${search}`, {
            headers: {
                Authorization: token,
              },
        }).then((res)=>{
            dispatch({
                type: types.FETCH_ALL_EBP_KKB,
                payload: res.data.response
            })
        }).catch((err)=>{
            dispatch({
                type: types.EBP_ERROR,
                payload: err.response.data
            })
            console.log('ERROR', err)
        })
    }
}

export const fetchAllEBPEducation = (token, page, limit, search) => {
    return (dispatch) => {
        dispatch({type: types.EBP_BEGIN})
        axios.get(`${API}/ebp/education?page=${page}&limit=${limit}&search=${search}`, {
            headers: {
                Authorization: token,
              },
        }).then((res)=>{
            dispatch({
                type: types.FETCH_ALL_EBP_EDUCATION,
                payload: res.data.response
            })
        }).catch((err)=>{
            dispatch({
                type: types.EBP_ERROR,
                payload: err.response.data
            })
            console.log('ERROR', err)
        })
    }
}

export const fetchDetailEBP = (token, id) => {
    return (dispatch) => {
        dispatch({type: types.EBP_BEGIN})
        axios.get(`${API}/ebp/${id}`, {
            headers: {
                Authorization: token,
              },
        }).then((res)=>{
            dispatch({
                type: types.FETCH_DETAIL_EBP,
                payload: res.data.response
            })
        }).catch((err)=>{
            dispatch({
                type: types.EBP_ERROR,
                payload: err.response.data
            })
            console.log('ERROR', err)
        })
    }
}
