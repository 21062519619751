import axios from "axios";
import {API} from "../../redux/api";

const getClientName = (token, clientCode) =>
    new Promise(async (resolve, reject) => {
        try {
            const { data } = await axios.get(`${API}/user/admin`, {
                headers: {
                    Authorization: token
                }
            });
            const { response } = data;
            const selected = response.filter(item => item.codes === clientCode);
            resolve(selected[0]?.name);
        } catch (error) {
            reject(error);
        }
    });

export default getClientName;
