import { Button, Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import ClientLoanSettingView from "../../../../components/SuperAdmin/_client/ClientSettings/LoanView/SettingLoanMenu";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { getClientById } from "../../../../redux/actions/SuperAdmin/adminActions";
import { fetchSchemaLoanById } from "../../../../redux/actions/SuperAdmin/loanActions";


// eslint-disable-next-line no-unused-vars


export default function ClientSetLoanView() {
    const { id, clientId } = useParams();
    const { clientById } = useSelector((state) => state.client);
    const { loanSchemaById } = useSelector((state) => state.loan);

    const dispatch = useDispatch();
    const history = useHistory();
    const token = localStorage.getItem("accessToken");

    useEffect(() => {
        dispatch(fetchSchemaLoanById(token, id));
        dispatch(getClientById(token, clientId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const capitalizedClientName = (clientName) => {
        return clientName?.toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
          .replace('Pt', 'PT')
          .replace('Cv', 'CV')
      }

    return (
        <Container maxWidth="xl">
            <div className="container-client-settings">
                <Helmet>
                    <title>MyWorkSpace | Client Settings</title>
                </Helmet>
                <div className="top__items-settings">
                    <Breadcrumb aria-label="breadcrumb">
                        <Button
                            onClick={() => history.push("/superadmin/client")}
                            style={{ border: "none", textTransform: "none", width: '100%' }}
                        >
                            <span style={{ fontSize: 20, color: '#255bfc' }}>Client Settings</span>
                        </Button>
                        <Button
                            onClick={() => history.push("/superadmin/profile-clients/" + clientId)}
                            style={{ border: "none", textTransform: "none", width: '100%' }}
                        >
                            <span style={{ fontSize: 20, color: '#255bfc' }}>{capitalizedClientName(clientById?.name)}</span>
                        </Button>
                        <Button
                            onClick={() => history.push("/superadmin/set-loan-scheme/" + clientId)}
                            style={{ border: "none", textTransform: "none", width: '100%' }}
                        >
                        <span style={{ fontSize: 20, color: '#255bfc' }}>Loan Scheme</span>
                        </Button>
                        <span style={{ fontSize: 20, color: '#255bfc' }}>{loanSchemaById?.name}</span>

                    </Breadcrumb>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <ClientLoanSettingView data={loanSchemaById} id={ id }/>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}
