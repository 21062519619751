import axios from "axios";
import * as types from '../../type'
import { fetchAllDrafts } from "./draftActions";
import { setSnackbar } from "./snackbarActions";
import {API} from "../../api";

// FETCH ALL FAQS
export const fetchAllFaqs = (token) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_ALL_FAQS_BEGIN})
    axios
      .get(`${API}/faq`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_FAQS_SUCCESS,
          payload: res.data.data,
        });
        // console.log('cek faqs', res.data)
      })
      .catch((err) => {
      dispatch({ type: types.FETCH_ALL_FAQS_ERROR, payload: err.response})
        console.log(err);
      });
  };
};

// FETCH BY ID
export const fetchFaqById = (token, id) => {
  return (dispatch) => {
    axios
      .get(`${API}/faq/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data.data, "attendances");
        dispatch({
          type: types.FETCH_FAQS_BY_ID,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// CREATE FAQ
export const createFaq = (token, data) => {
  return (dispatch) => {
    return axios
      .post(`${API}/faq`, data, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.CREATE_FAQ,
          payload: res.data.data,
        });
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Data FAQ success published",
          })
        );
        return Promise.resolve(); // kondisi jika berhasil
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.message,
          })
        );
        return Promise.reject(err);
      });
  };
};

// EDIT FAQ
export const updateFaq = (token, id, data) => {
  return (dispatch) => {
    axios
      .patch(`${API}/faq/update/${id}`, data, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.UPDATE_FAQ,
          payload: res.data.data,
        });
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Data success updated",
          })
        );
        //
        // return Promise.resolve();
      })
      .then(() => {
        dispatch(fetchAllFaqs(token));
      })
      .catch((err) => {
        console.log(err);
        // dispatch(
        //   setSnackbar({
        //     snackbarOpen: true,
        //     snackbarType: "error",
        //     snackbarMessage: err.response.data.message,
        //   })
        // );
        // return Promise.reject(err);
      });
  };
};

// DELETE FAQ
export const deleteFaq = (token, id) => {
  return (dispatch) => {
    return axios
      .delete(`${API}/faq/delete/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.DELETE_FAQ,
          payload: res.data.data,
        });
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Data success deleted",
          })
        );
        //
        return Promise.resolve(); // kondisi jika berhasil
      })
      .then(() => {
        dispatch(fetchAllFaqs(token));
      })
      .then(() => {
        dispatch(fetchAllDrafts(token));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.message,
          })
        );
        //
        return Promise.reject(err);
      });
  };
};

// EDIT DRAFT
export const updateDraftToPublish = (token, id, data) => {
  return (dispatch) => {
    axios
      .patch(`${API}/faq/publishDraft/${id}`, data, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.UPDATE_DRAFT,
          payload: res.data.data,
        });
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Data success updated",
          })
        );
        //
        // return Promise.resolve();
      })
      .then(() => {
        dispatch(fetchAllDrafts(token));
      })
      .catch((err) => {
        console.log(err);
        // dispatch(
        //   setSnackbar({
        //     snackbarOpen: true,
        //     snackbarType: "error",
        //     snackbarMessage: err.response.data.message,
        //   })
        // );
        // return Promise.reject(err);
      });
  };
};

