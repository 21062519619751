
import React from "react";
import { Box, TextField } from "@mui/material";
import { DateRangePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider} from '@mui/lab';
// import { DateRangePicker } from '@mui/x-date-pickers/DateRangePicker';
export default function DateRangePickerComp({ valueDate, onChangeDate }) {
  // const startDate = new Date(1648141200000);
  // const endDate = new Date(1649869200000);
  // const [valueDate,setValueDate] = useState([startDate, endDate]);
  // console.log(valueDate, 'cek value')
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <DateRangePicker
          startText=""
          endText=""
          value={valueDate}
          onChange={onChangeDate}
          renderInput={(startProps, endProps) => (
            <>
              <TextField
                {...startProps}
                style={{ width: 150 }}
                size="small"
                autoComplete="off"
              />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField
                {...endProps}
                style={{ width: 150 }}
                size="small"
                autoComplete="off"
              />
            </>
          )}
        />
      </Box>
    </LocalizationProvider>
  );
}
