/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { filter } from "lodash";
import {
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
  styled,
  TextField,
  Autocomplete,
  TableHead,
  TableSortLabel,
  Card,
  Box,
} from "@mui/material";

import { visuallyHidden } from "@mui/utils";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import DateRangePickerComponent from "../../../utils/DateRangePicker/DateRangePicker";
import ExportCSV from "../../../SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import UserListHead from "../../../SuperAdmin/_user/TableUsers/UserListHead";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import { UseCompanyName } from "../../../utils/hooks";
import {
  fetchDataOnGoingLoanByRangeDate,
  fetchSchemaLoanByIdUser,
} from "../../../../redux/actions/SuperAdmin/loanActions";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const TABLE_HEAD = [
  { id: "employeeName", numeric: false, label: "Employee Name", alignRight: false },
  { id: "company", numeric: false, label: "Company", alignRight: false },
  { id: "applyDate", numeric: false, label: "Apply Date", alignRight: false },
  { id: "tenor", numeric: true, label: "Tenor", alignRight: false },
  { id: "status", numeric: false, label: "Status", alignRight: false },
  { id: "" },
  { id: "" },
];

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        <TableCell padding="none" style={{ paddingLeft: 0 }}>
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={rowCount !== values?.users?.length}
            sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
          /> */}
        </TableCell>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function OngoingLoanExternal({
  dropdownSelectedStatus,
  setDropdownSelectedStatus,
  dropdownListGroup,
  setDropdownListGroup,
  dataPayment,
}) {
  const dataOnGoingLoan = useSelector((state) => state.loan.onGoingLoan);
  const { loadingDataOngoingLoan } = useSelector((state) => state.loan);
  const token = localStorage.getItem("accessToken");
  const clientName = localStorage.getItem("clientName");
  const dispatch = useDispatch();
  const history = useHistory();
  const [employee, setEmployee] = useState("");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("employeeName");
  // const [selected, setSelected] = useState("PT. Permata Indo Sejahtera");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [listDataLoan, setListDataLoan] = useState("");
  const [showBox, setShowBox] = useState(false);
  // const startDate = moment().format("LL");
  // const endDate = moment().format("LL");
  const [valueStartDate, setValueStartDate] = useState(moment().format("LL"));
  const [valueEndDate, setValueEndDate] = useState(moment().format("LL"));

  const dateValueStart = useMemo(() => {
    let sessionStartDate = window.sessionStorage.getItem("startDateLoan");
    let firstDate;

    if (sessionStartDate) {
      firstDate = sessionStartDate;
    } else {
      firstDate = valueStartDate;
    }

    return firstDate;
  }, [valueStartDate]);

  const dateValueEnd = useMemo(() => {
    let sessionEndDate = window.sessionStorage.getItem("endDateLoan");
    let secondDate;

    if (sessionEndDate) {
      secondDate = sessionEndDate;
    } else {
      secondDate = valueEndDate;
    }

    return secondDate;
  }, [valueEndDate]);

  useEffect(() => {
      dispatch(
        fetchDataOnGoingLoanByRangeDate(
          token,
          moment(dateValueStart).format('YYYY-MM-DD'),
          moment(dateValueEnd).format('YYYY-MM-DD'),
          // new Date(dateValueStart).getTime(),
          // new Date(dateValueEnd).getTime(),
          clientName
        )
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateValueEnd,
    dateValueStart,
    dispatch,
    token,
    clientName
  ]);

  const handleChangeStartDate = (newValue) => {
    setValueStartDate(newValue);
    window.sessionStorage.setItem("startDateLoan", newValue);
  };
  const handleChangeEndDate = (newValue) => {
    setValueEndDate(newValue);
    window.sessionStorage.setItem("endDateLoan", newValue);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleOpen = (option) => {
  //   if (option === "Permata Grouppp" || option === "Others") {
  //     setEmployee(option);
  //     setIsActive(false);
  //     setShowBox(true);
  //   } else if (option === "All") {
  //     setShowBox(false);
  //     setEmployee(option);
  //     setIsActive(false);
  //   } else {
  //     setIsActive(false);
  //     setEmployee(option);
  //   }
  // };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleSearch = (event) => {
    setListDataLoan(event.target.value);
  };

  return (
    <div className="container-data-loan">
      <div className="wrapper__setting-data-loan">
        <div className="setting-menu-left-data-loan">
          <SearchbarSA
            value={listDataLoan}
            onChange={handleSearch}
            searchWidth="254px"
            placeholder="Search by name"
          />
        </div>
        <div className="data-loan-menu-right">
          <DateRangePickerComponent
            valueStartDate={dateValueStart}
            valueEndDate={dateValueEnd}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
          />
        </div>
        <div className="data-attendance-menu-right">
          <div className="">
            {/* <FormControl>
              <Select
                value={employee}
                displayEmpty
                onChange={(e) => handleOpen(e.target.value)}
                SelectDisplayProps={{
                  style: {
                    paddingTop: 8,
                    paddingBottom: 8,
                    width: 100,
                    backgroundColor: "#fbfbfb",
                  },
                }}
              >
                <MenuItem value="">All</MenuItem>
                {employeeName?.map((option, ind) => (
                  <MenuItem
                    key={ind}
                    onClick={() => handleOpen(option)}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
        </div>
        <div className="data-loan-menu-right-download">
          <ExportCSV
            data={dataOnGoingLoan.map((el, ind) => ({
              "No.": ind + 1,
              "Employee Name": el.employeeName,
              Company: el.company,
              "Apply Date": el.applyDate ? el?.applyDate : null,
              Tenor: el.tenor,
              Status:
                (el?.status === "ACTIVE" && "Active") ||
                (el?.status === "FINISHED" && "Finished") ||
                (el?.status === "FAILED" && "Reject") ||
                (el?.status === "PENDING" && "Pending"),
            }))}
            filename={`Loan Report ${new Date().toLocaleDateString()}`}
            initial="Download Excel"
          />
        </div>
      </div>
      {/* {showBox ? (
        <div className="wrapper-box-show-another">
          <span className="text-box">Company</span>
          <div className="box-show-another">
            <div className="box-group-selected">
              <div className="">
                <FormControl>
                  <Select
                    value={dropdownListGroup}
                    displayEmpty
                    onChange={(e) => handleOpen(e.target.value)}
                    SelectDisplayProps={{
                      style: {
                        paddingTop: 8,
                        paddingBottom: 8,
                        width: 230,
                        backgroundColor: "#fbfbfb",
                      },
                    }}
                  >
                    {options3.map((option, el) => (
                      <MenuItem
                        key={el}
                        // onClick={() => handleOpen(option)}
                        onClick={() => setDropdownListGroup(option)}
                        value={option}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="">
              <FormControl>
                <Select
                  value={dropdownSelectedStatus}
                  displayEmpty
                  onChange={(e) => handleOpen(e.target.value)}
                  SelectDisplayProps={{
                    style: {
                      paddingTop: 8,
                      paddingBottom: 8,
                      width: 70,
                      // height: 40,
                      backgroundColor: "#fbfbfb",
                    },
                  }}
                >
                  {options2.map((option, el) => (
                    <MenuItem
                      key={el}
                      onClick={() => setDropdownSelectedStatus(option)}
                      // onClick={() => handleOpen(option)}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      ) : null} */}

      {loadingDataOngoingLoan ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 100,
          }}
        >
          <div style={{ width: 200 }}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <Card sx={{ marginTop: 2 }}>
          <div className="wrapper-table-data-loan">
            <Scrollbar>
              <TableContainer>
                <Table
                  sx={{
                    width: "100%",
                    maxHeight: "100%",
                  }}
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: 5,
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <EnhancedTableHead
                    numSelected={dataOnGoingLoan.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    values={dataOnGoingLoan}
                    rowCount={
                      dataOnGoingLoan?.filter(
                        (el) =>
                          el?.employeeName
                            ?.toLowerCase()
                            .includes(listDataLoan?.toLowerCase()) ||
                          el?.jobTitle
                            ?.toLowerCase()
                            .includes(listDataLoan?.toLowerCase())
                        // && (selectedRegion ? el.EmployeeArea === selectedRegion: true)
                      ).length
                    }
                  />
                  {/* <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={dataOnGoingLoan.length}
                    onRequestSort={handleRequestSort}
                  /> */}
                  <TableBody>
                    {stableSort(dataOnGoingLoan, getComparator(order, orderBy))
                      .filter(
                        (el) =>
                          el?.employeeName?.toLowerCase().includes(listDataLoan?.toLowerCase()) ||
                          el?.company?.toLowerCase().includes(listDataLoan?.toLocaleLowerCase()) ||
                          el?.status?.toLowerCase().includes(listDataLoan?.toLocaleLowerCase()) 
                          /* (el.tenor + " Month")?.toString().toLowerCase().includes(listDataLoan?.toLocaleLowerCase()) ||
                          moment(el.DateResponse).format("L").toLocaleLowerCase().includes(listDataLoan?.toLocaleLowerCase()) */
                      )
                      // .sort((a, b) => new Date(a.LoanTime) - new Date(b.LoanTime))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((dataLoan, id) => {
                        const labelId = `enhanced-table-checkbox-${id}`;
                        return (
                          <TableRow hover key={id} tabIndex={-1}>
                            <TableCell></TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              id={labelId}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                src={ dataLoan.profilePicture ? dataLoan.profilePicture : ""}
                                >
                                  {dataLoan?.employeeName.substr(0, 1)}
                                </Avatar>
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {dataLoan?.employeeName.toLowerCase()}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              {dataLoan?.company}
                            </TableCell>
                            <TableCell align="left">
                            {dataLoan?.applyDate}
                            </TableCell>
                            <TableCell align="left">
                            {dataLoan?.tenor} Month
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                className="status__styleSA"
                                style={{
                                  // textTransform: "capitalize",
                                  fontSize: 14,
                                  fontWeight: 500,
                                  backgroundColor:
                                    (dataLoan.status === "ACTIVE" && "#F4FFF2") ||
                                    (dataLoan.status === "FINISHED" && "#DEFEFF") || 
                                    (dataLoan.status === "FAILED" && "#FFF4F2") || 
                                    (dataLoan.status === "PENDING" && "#FFF9E9") ,
                                  color:
                                    (dataLoan.status === "ACTIVE" && "#498E0F") ||
                                    (dataLoan.status === "FINISHED" && "#1571DE") || 
                                    (dataLoan.status === "FAILED" && "#D52701") || 
                                    (dataLoan.status === "PENDING" && "#C1992F") ,
                                  borderColor:
                                    (dataLoan.status === "ACTIVE" && "#22970F") ||
                                    (dataLoan.status === "FINISHED" && "#1571DE") || 
                                    (dataLoan.status === "FAILED" && "#D52701") || 
                                    (dataLoan.status === "PENDING" && "#C1992F") ,
                                }}
                              >
                              {
                                (dataLoan?.status === "ACTIVE" && "Active") ||
                                (dataLoan?.status === "FINISHED" && "Finished") ||
                                (dataLoan?.status === "FAILED" && "Reject") ||
                                (dataLoan?.status === "PENDING" && "Pending")
                              }
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {/* {moment(dataLoan.DateResponse).format("L")} */}
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  fontWeight: 500,
                                }}
                                onClick={() => {
                                  history.push({
                                    pathname: `/admin/loan-data-details-external/${dataLoan._id}`,
                                    state: { dataLoan: dataLoan },
                                  });
                                }}
                              >
                                See Detail
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={
                dataOnGoingLoan.filter(
                  (el) =>
                    el.employeeName?.toLowerCase().includes(listDataLoan?.toLowerCase()) ||
                    el.company?.toLowerCase().includes(listDataLoan?.toLocaleLowerCase()) ||
                    el.status?.toLowerCase().includes(listDataLoan?.toLocaleLowerCase())
                ).length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Card>
      )}
    </div>
  );
}
