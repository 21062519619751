// import {
//   FETCH_ALL_PAYSLIP,
//   FETCH_ALL_PAYSLIP_BY_ID,
//   FETCH_ALL_PAYSLIP_BY_MONTH,
//   FETCH_ALL_PAYSLIP_BY_YEAR,
//   FETCH_ALL_SLIP_PAYMENT_BY_ID,
//   FETCH_SLIP_PAYMENT_BY_ID,
//   UPLOAD_DATA_PAYSLIP
// } from "../../type";
import * as types from "../../type";
const initialstate = {
  payslipById: [],
  dataPayslipById: {},
  allPayslip: [],
  loadingAllPayslip: false,
  errorMessagePayslip: {},
  loadingPayslipByMonth: false,
  errorMessagePayslipByMonth: {},
  allPayslipByYear: [],
  allPayslipByMonth: [],
  allPayslipById: [],
  uploadDataPayslip: {},
};

const slipPaymentReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_SLIP_PAYMENT_BY_ID: {
      return {
        ...state,
        payslipById: action.payload,
      };
    }
    case types.FETCH_SLIP_PAYMENT_BY_ID: {
      return {
        ...state,
        // dataayslipById: {
        //   ...state.payslipById,
        //   [action.payload._id]: action.payload,
        // },
        dataPayslipById: action.payload,
      };
    }
    //
    case types.FETCH_ALL_PAYSLIP_BEGIN: {
      return {
        ...state,
        loadingAllPayslip: true,
      };
    }
    case types.FETCH_ALL_PAYSLIP_SUCCESS: {
      return {
        ...state,
        loadingAllPayslip: false,
        allPayslip: action.payload,
      };
    }
    case types.FETCH_ALL_PAYSLIP_ERROR: {
      return {
        ...state,
        loadingAllPayslip: false,
        allPayslip: action.payload,
      };
    }
    case types.FETCH_ALL_PAYSLIP_BY_ID: {
      return {
        ...state,
        allPayslipById: action.payload,
      };
    }
    case types.FETCH_ALL_PAYSLIP_BY_YEAR: {
      return {
        ...state,
        allPayslipByYear: action.payload,
      };
    }
    case types.FETCH_ALL_PAYSLIP_BY_MONTH_BEGIN: {
      return {
        ...state,
        loadingPayslipByMonth: true,
      };
    }
    case types.FETCH_ALL_PAYSLIP_BY_MONTH_SUCCESS: {
      return {
        ...state,
        loadingPayslipByMonth: false,
        allPayslipByMonth: action.payload,
      };
    }
    case types.FETCH_ALL_PAYSLIP_BY_MONTH_ERROR: {
      return {
        ...state,
        loadingPayslipByMonth: false,
        errorMessagePayslipByMonth: action.payload,
      };
    }
    case types.UPLOAD_DATA_PAYSLIP: {
      return {
        ...state,
        uploadDataPayslip: action.payload,
      };
    }
    default:
      return state;
  }
};

export default slipPaymentReducer;
