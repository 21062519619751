/* eslint-disable no-unused-vars */
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  styled,
  InputAdornment,
  Stack,
} from "@mui/material";
import React, { useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./EmployeeFormFMCG.css";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../utils/Breadcrumbs";
import GalleryIcon from "../../../../assets/images/image-gallery.png";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import AdminNavFMCG from "../../AdminNavFMCG/AdminNavFMCG";

const links = [
  {
    path: "/admin-fmcg/employees",
    label: "Employee",
  },
  {
    path: "/admin-fmcg/employees",
    label: "Employee Details",
  },
  {
    label: "Edit",
  },
];

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 5,
    },
    "& .MuiInputBase-input-MuiOutlinedInput-input": {
      padding: 0,
    },
    "& input::placeholder": {
      color: "#A4A4A4",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "18.2px",
      opacity: 1,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 5,
    },
    "& .MuiSelect-select": {
      color: "black",
      fontSize: 13,
      fontWeight: 400,
    },
    // "& input::placeholder": {
    //   color: "#A4A4A4",
    //   fontSize: 13,
    //   fontWeight: 400,
    //   lineHeight: "18.2px",
    //   opacity: 1,
    // },
  },
  adorment: {
    "& .MuiTypography-root ": {
      color: "#A4A4A4",
      fontSize: 15,
      fontWeight: 400,
      lineHeight: "18.2px",
    },
  },
}));

const positionArr = ["MD", "MD Leader", "SPG", "Leader"];
const statusArr = ["Active", "Non Active"];
const workAreaArr = ["WIB", "WITA", "WIT"];
const storeArr = ["Alfamart", "Indomaret", "Giant"];
const levelArr = ["Leader", "Staff"];
const leaderArr = ["Sheila", "Sujiwo", "Salsabila", "Anastasia"];

const EmployeeFormFMCG = (props) => {
  const { location } = props;
  const history = useHistory();
  const classes = useStyles();
  const imageRef = useRef();
  const [values, setValues] = useState({});
  const [selectedInput, setSelectedInput] = useState({
    position: "",
    status: "",
    workArea: "",
    phone: 0,
    workTime: "",
    store: "",
    level: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = () => {
    const data = {
      Name: values.Name,
      Position: selectedInput.position,
      Status: selectedInput.status,
      Area: selectedInput.workArea,
      Phone: selectedInput.phone,
      WorkTime: selectedInput.workTime,
      Store: selectedInput.store,
      Level: selectedInput.level,
    };

    if (values.image) {
      const data = new FormData();
      const fileName = Date.now() + values.image.name;

      data.append("name", fileName);
      data.append("file", values.image);
      data.image = fileName;
      console.log(data, "imagesss");
    }

    console.log(data);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files?.[0];
      setValues({
        ...values,
        image: img,
      });
      //   setImage(img);
      // setImage({
      // image: URL.createObjectURL(img),
      // image: img,
      // });
    }
  };

  const onRemoveImage = () => {
    setValues({
      ...values,
      image: null,
    });
  };

  return (
    <div className="ContainerEmployeeFormFMCG">
      <div className="WrapperEmployeeFormFMCG">
        <AdminNavFMCG links={links}></AdminNavFMCG>
        {/* {location === "addEmployee" ? (
          <div
            className="ButtonRouteBack"
            onClick={() =>
              history.push("/admin-fmcg/employees")
            }
          >
            <IconButton>
              <ArrowBackIcon sx={{ color: "#202020", width: 24, height: 24 }} />
            </IconButton>
          </div>
        ) : (
          <div
            className="ButtonRouteBack"
            onClick={() =>
              history.push("/admin-fmcg/employees/detail/lkshdo1hdon1owh")
            }
          >
            <IconButton>
              <ArrowBackIcon sx={{ color: "#202020", width: 24, height: 24 }} />
            </IconButton>
          </div>
        )} */}
        {/* <div className="WrapperBreadCrumbs">
          {location === "addEmployee" ? (
            <Breadcrumb>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextGrey">Employee</span>
              </Button>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextBlue">
                  Add New Employee Details
                </span>
              </Button>
            </Breadcrumb>
          ) : (
            <Breadcrumb>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextGrey">Employee</span>
              </Button>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextGrey">Employee Details</span>
              </Button>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextBlue">Edit</span>
              </Button>
            </Breadcrumb>
          )}
        </div> */}
        <form className="ContentEmployeeFormFMCG" onSubmit={handleSubmit}>
          <div
            className="WrapperAddPhotoEmployeeFormFMCG"
            onClick={() => imageRef.current.click()}
          >
            {values.image ? (
              <div className="WrapperImageEmployeeFormFMCG">
                <img
                  src={URL.createObjectURL(values.image)}
                  alt=""
                  style={{
                    width: 84,
                    height: 84,
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </div>
            ) : (
              <div className="WrapperImageEmployeeFormFMCG">
                <img src={GalleryIcon} alt="" />
              </div>
            )}
            {location === "addEmployee" ? (
              <Button
                style={{
                  backgroundColor: "#1571DE",
                  borderRadius: 5,
                  height: 34,
                  color: "#FFFFFF",
                  textTransform: "none",
                  fontSize: 13,
                  fontWeight: 500,
                }}
                // onClick={() => imageRef.current.click()}
                startIcon={<AddIcon sx={{ width: 20, height: 20 }} />}
              >
                {/* <AddIcon sx={{ width: 20, height: 20 }}/> */}
                Add Photo
              </Button>
            ) : (
              <Stack direction="row" spacing={2}>
                <Button
                  style={{
                    backgroundColor: "#1571DE",
                    borderRadius: 5,
                    height: 34,
                    color: "#FFFFFF",
                    textTransform: "none",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  onClick={() => imageRef.current.click()}
                  // startIcon={<AddIcon sx={{ width: 20, height: 20 }} />}
                >
                  {/* <AddIcon sx={{ width: 20, height: 20 }}/> */}
                  Update Photo
                </Button>
                {values.image ? (
                  <Button
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 5,
                      width: 86,
                      height: 34,
                      color: "#D1421A",
                      textTransform: "none",
                      fontSize: 13,
                      fontWeight: 500,
                      border: "1px solid #D1421A",
                    }}
                    onClick={onRemoveImage}
                    // startIcon={<AddIcon sx={{ width: 20, height: 20 }} />}
                  >
                    {/* <AddIcon sx={{ width: 20, height: 20 }}/> */}
                    Remove
                  </Button>
                ) : null}
              </Stack>
            )}
          </div>
          <div style={{ display: "none" }}>
            <input
              type="file"
              name="myImage"
              ref={imageRef}
              onChange={onImageChange}
            />
          </div>
          <div className="WrapperInputEmployeeFormFMCG">
            <div className="WrapperItemsInput">
              <div className="ItemsInputEmployeeFormFMCG">
                <div className="ItemInputEmployeeFormFMCG">
                  <span>Name</span>
                  <MuiTextField
                    size="small"
                    placeholder="Employee Name"
                    value={values.Name}
                    onChange={handleChange("Name")}
                    type="text"
                  />
                </div>
                <div className="ItemInputEmployeeFormFMCG">
                  <span>Position</span>
                  <FormControl fullWidth size="small">
                    <Select
                      className={classes.selectBorder}
                      value={selectedInput.position || ""}
                      displayEmpty
                      onChange={(e) =>
                        setSelectedInput({
                          ...selectedInput,
                          position: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Position
                      </MenuItem>
                      {positionArr.sort().map((option, el) => (
                        <MenuItem
                          key={el}
                          onClick={() =>
                            setSelectedInput({
                              ...selectedInput,
                              position: option,
                            })
                          }
                          value={option || ""}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="ItemsInputEmployeeFormFMCG">
                <div className="ItemInputEmployeeFormFMCG">
                  <span>Phone</span>
                  <MuiTextField
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.adorment}
                        >
                          +62
                        </InputAdornment>
                      ),
                    }}
                    value={values.Phone}
                    onChange={handleChange("Phone")}
                    type="number"
                  />
                </div>
                <div className="ItemInputEmployeeFormFMCG">
                  <span>Status</span>
                  <FormControl fullWidth size="small">
                    <Select
                      className={classes.selectBorder}
                      value={selectedInput.status || ""}
                      displayEmpty
                      onChange={(e) =>
                        setSelectedInput({
                          ...selectedInput,
                          status: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Status
                      </MenuItem>
                      {statusArr.sort().map((option, el) => (
                        <MenuItem
                          key={el}
                          onClick={() =>
                            setSelectedInput({
                              ...selectedInput,
                              status: option,
                            })
                          }
                          value={option || ""}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="ItemsInputEmployeeFormFMCG">
                <div className="ItemInputEmployeeFormFMCG">
                  <span>Area</span>
                  <FormControl fullWidth size="small">
                    <Select
                      className={classes.selectBorder}
                      value={selectedInput.workArea || ""}
                      displayEmpty
                      onChange={(e) =>
                        setSelectedInput({
                          ...selectedInput,
                          workArea: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Status
                      </MenuItem>
                      {workAreaArr.sort().map((option, el) => (
                        <MenuItem
                          key={el}
                          onClick={() =>
                            setSelectedInput({
                              ...selectedInput,
                              workArea: option,
                            })
                          }
                          value={option || ""}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="ItemInputEmployeeFormFMCG">
                  <span>Work Time</span>
                  <FormControl fullWidth size="small">
                    <Select
                      className={classes.selectBorder}
                      value={selectedInput.workTime || ""}
                      displayEmpty
                      onChange={(e) =>
                        setSelectedInput({
                          ...selectedInput,
                          workTime: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Work Time
                      </MenuItem>
                      {workAreaArr.sort().map((option, el) => (
                        <MenuItem
                          key={el}
                          onClick={() =>
                            setSelectedInput({
                              ...selectedInput,
                              workTime: option,
                            })
                          }
                          value={option || ""}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="ItemsInputEmployeeFormFMCG">
                <div className="ItemInputEmployeeFormFMCG">
                  <span>Store</span>
                  <FormControl fullWidth size="small">
                    <Select
                      className={classes.selectBorder}
                      value={selectedInput.store || ""}
                      displayEmpty
                      onChange={(e) =>
                        setSelectedInput({
                          ...selectedInput,
                          store: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Store
                      </MenuItem>
                      {storeArr.sort().map((option, el) => (
                        <MenuItem
                          key={el}
                          onClick={() =>
                            setSelectedInput({
                              ...selectedInput,
                              store: option,
                            })
                          }
                          value={option || ""}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="ItemInputEmployeeFormFMCG">
                  <span>Level</span>
                  <FormControl fullWidth size="small">
                    <Select
                      className={classes.selectBorder}
                      value={selectedInput.level || ""}
                      displayEmpty
                      onChange={(e) =>
                        setSelectedInput({
                          ...selectedInput,
                          level: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Level
                      </MenuItem>
                      {levelArr.sort().map((option, el) => (
                        <MenuItem
                          key={el}
                          onClick={() =>
                            setSelectedInput({
                              ...selectedInput,
                              level: option,
                            })
                          }
                          value={option || ""}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="ItemsInputEmployeeFormFMCG">
                <div className="ItemInputEmployeeFormFMCG">
                  <span>Leader</span>
                  <FormControl fullWidth size="small">
                    <Select
                      className={classes.selectBorder}
                      value={selectedInput.leader || ""}
                      displayEmpty
                      onChange={(e) =>
                        setSelectedInput({
                          ...selectedInput,
                          leader: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Leader
                      </MenuItem>
                      {leaderArr.sort().map((option, el) => (
                        <MenuItem
                          key={el}
                          onClick={() =>
                            setSelectedInput({
                              ...selectedInput,
                              leader: option,
                            })
                          }
                          value={option || ""}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="ItemInputEmployeeFormFMCG"></div>
              </div>
            </div>
            <div className="ButtonActionEmployeeFormFMCG">
              <Button
                style={{
                  background: "#FFFFF",
                  borderRadius: 5,
                  border: "1px solid #818181",
                  width: 79,
                  height: 34,
                  textTransform: "none",
                  color: "#818181",
                  fontSize: 13,
                  fontWeight: 500,
                }}
                onClick={() => history.push("/admin-fmcg/employees")}
              >
                Cancel
              </Button>
              {location === "addEmployee" ? (
                <Button
                  style={{
                    background: "#1571DE",
                    borderRadius: 5,
                    width: 65,
                    height: 34,
                    textTransform: "none",
                    color: "#FFFFFF",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  type="submit"
                >
                  Save
                </Button>
              ) : (
                <Button
                  style={{
                    background: "#1571DE",
                    borderRadius: 5,
                    width: 128,
                    height: 34,
                    textTransform: "none",
                    color: "#FFFFFF",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  type="submit"
                >
                  Save Changes
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmployeeFormFMCG;
