import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { AddNewStoreForSPG } from "../../../../components/FMCG";

export default function AddNewStoreSPG() {
  const location = useLocation();
  const addNewStorePath = location?.state?.location;
  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Setup</title>
      </Helmet>
      <AddNewStoreForSPG location={addNewStorePath} />
    </div>
  );
}
