import axios from "axios";
import {API} from "../../redux/api";

const registerAdminEmployee = (token, payload) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data: { response } } = await axios.patch(`${API}/user/admin`, payload ,{
        headers: {
          Authorization: token,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export default registerAdminEmployee;
