/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./appDetailStore.css";
import employee__image from "../../../../assets/images/employee-rectangle.png";
import product__items from "../../../../assets/images/item-product-sale.png";
import employee__image_2 from "../../../../assets/images/sujini.png";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import detail_picture from "../../../../assets/images/image-store-details.png";
import rows from "../../../_dashboard/appNewCustBottom/dataDummy";
import { useHistory } from "react-router";
import {
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  Paper,
  TableHead,
  Button,
  IconButton,
} from "@mui/material";
import ModalDeleteStore from "../ModalDeleteStore/ModalDeleteStore";

function AppDetailStore() {
  const history = useHistory();
  const [coordinate, setCoordinate] = useState("");
  const [storeName, setStoreName] = useState("");
  const [owner, setOwner] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [socialMedia, setSocialMedia] = useState("");
  const [deleteStore, setDeleteStore] = useState(""); // change to useState({ }) for contain the id after fetching data 
  return (
    <div className="contaier__detail-store">
      <div className="wrapper_container-detail-top">
        <div className="container__image-detail">
          <img src={detail_picture} alt="" />
        </div>
        <div className="container__detail-store">
          <div className="items__detail-store">
            <div className="items__detail">
              <label htmlFor="">Coordinate</label>
              <input
                placeholder="12.11.98.1112"
                type="text"
                value={coordinate}
              />
            </div>
            <div className="items__detail">
              <label htmlFor="">Store Name</label>
              <input
                placeholder="UD. Sinar Jaya"
                type="text"
                value={storeName}
              />
            </div>
          </div>
          <div className="items__detail-store-2">
            <div className="items__detail">
              <label htmlFor="">Owner</label>
              <input
                placeholder="Michelle Swints"
                type="text"
                value={owner}
              />
            </div>
            <div className="items__detail">
              <label htmlFor="">Phone Number</label>
              <input
                placeholder="0812-8818-2222"
                type="text"
                value={phoneNumber}
              />
            </div>
          </div>
          <div className="items__detail-store-3">
            <div className="items__detail">
              <label htmlFor="">Social Media</label>
              <input
                placeholder="@sinarjaya.id"
                type="text"
                value={socialMedia}
              />
            </div>
            <div className="items__detail" style={{ opacity: 0 }}>
              <label htmlFor="">Social Media</label>
              <input
                placeholder="@sinarjaya.id"
                type="text"
                value={socialMedia}
              />
            </div>
          </div>
          <div className="item__store-button">
            <Button
              endIcon={<EditIcon style={{ color: "#1571DE" }} />}
              style={{
                border: "1px solid #1571DE",
                borderRadius: 4,
                width: 183,
                height: 36,
                textTransform: "none",
              }}
              onClick={() => history.push('/admin/edit-store-external')}
            >
              Edit
            </Button>
            <Button
              endIcon={<DeleteOutlineIcon style={{ color: "#D1421A" }} />}
              style={{
                border: "1px solid #D1421A",
                borderRadius: 4,
                width: 183,
                height: 36,
                textTransform: "none",
                color: " #D1421A",
              }}
              onClick={() => setDeleteStore(true)}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
      <div className="wrapper__product-sale">
        <div className="product__sale-title">
          <span>Product for Sale</span>
        </div>
        <div className="items__rectangle">
          <div className="items__product-sale">
            <img src={product__items} alt="" />
            <div className="text__wrapper-product">
              <label htmlFor="">Susu Frissian Flag</label>
              <span>12 box available</span>
            </div>
          </div>
          <div className="items__product-sale">
            <img src={product__items} alt="" />
            <div className="text__wrapper-product">
              <label htmlFor="">Susu Frissian Flag</label>
              <span>12 box available</span>
            </div>
          </div>{" "}
          <div className="items__product-sale">
            <img src={product__items} alt="" />
            <div className="text__wrapper-product">
              <label htmlFor="">Susu Frissian Flag</label>
              <span>12 box available</span>
            </div>
          </div>{" "}
          <div className="items__product-sale">
            <img src={product__items} alt="" />
            <div className="text__wrapper-product">
              <label htmlFor="">Susu Frissian Flag</label>
              <span>12 box available</span>
            </div>
          </div>
          <div className="items__dashed-box">
            <div className="text__wrapper-product-dashed">
              <IconButton onClick={() => history.push("/admin/add-products")}>
                <AddIcon style={{ width: 44, height: 44, color: "#3072B8" }} />
              </IconButton>
              <span>Product</span>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper__add-employee">
        <div className="add__employee-title">
          <span>Employee</span>
        </div>
        <div className="items__rectangle-add-employee">
          <div className="items__add-employee">
            <img src={employee__image} alt="" />
            <div className="text__wrapper-employee">
              <label htmlFor="">Anastasia</label>
              <span>Sales Promotion Girl</span>
            </div>
          </div>
          <div className="items__add-employee">
            <img src={employee__image_2} alt="" />
            <div className="text__wrapper-employee">
              <label htmlFor="">Anton Sujini</label>
              <span>MD</span>
            </div>
          </div>
          <div className="items__dashed-box">
            <div className="text__wrapper-product-dashed">
              <IconButton onClick={() => history.push("/admin/add-employee")}>
                <AddIcon style={{ width: 44, height: 44, color: "#3072B8" }} />
              </IconButton>
              <span>Employee</span>
            </div>
          </div>
        </div>
        <div className="container__store-promo">
          <div className="wrapper__title-store-promo">
            <span>Store Promo</span>
          </div>
          <Paper
            className="container__table-customer-external"
            style={{ backgroundColor: "#f3f5f7" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Promo Category</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Product Type</TableCell>
                  <TableCell>Period</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(({ id, CustomerName, ProductName, Store, Date }) => (
                  <TableRow key={id}>
                    <TableCell align="left">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar variant="square" sx={{ bgColor: "grey" }} />
                        <Typography variant="subtitle2" noWrap>
                          Food Promo
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>Saus ABC </TableCell>
                    <TableCell>Bumbu Dapur</TableCell>
                    <TableCell>21 December 2021</TableCell>
                    {/* <TableCell align="right">
                      <Button
                        style={{
                          textTransform: "none",
                          color: "#1571DE",
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        Detail
                      </Button>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
        {/* <div className="container__store-promo">
          <div className="wrapper__title-store-promo">
            <span>Store Promo</span>
          </div>
          <div className="box__store-promo-detail">
            <div className="wrapper__nav-store-detail-promo">
              <ul className="nav__store-detail-promo">
                <li>Promo Category</li>
                <li>Product Name</li>
                <li>Product Type</li>
                <li>Period</li>
              </ul>
            </div>
            <div>
              <hr style={{ width: "100%", color: "#ECE6E6" }} />
            </div>
            <div className="wrapper__product-one">
              <div className="item__text-detail">
                <img src={logo_table} alt="" />
                <span>Food Promo</span>
                <span style={{ paddingLeft: 50 }}>Saus ABC</span>
                <span style={{ paddingLeft: 105 }}>Bumbu dapur</span>
                <span style={{ paddingLeft: 65 }}>21 December</span>
              </div>
            </div>
            <div>
              <hr style={{ width: "100%", color: "#ECE6E6" }} />
            </div>
            <div className="wrapper__product-one">
              <div className="item__text-detail">
                <img src={logo_table} alt="" />
                <span>Food Promo</span>
                <span style={{ paddingLeft: 50 }}>Saus ABC</span>
                <span style={{ paddingLeft: 105 }}>Bumbu dapur</span>
                <span style={{ paddingLeft: 65 }}>21 December</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      { deleteStore && (
        <ModalDeleteStore setDeleteStore={ setDeleteStore } pathName="/admin/store-external"/>
      )}
    </div>
  );
}

export default AppDetailStore;
