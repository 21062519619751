import * as types from '../../type'

const initialstate = {
  allInduction: [],
  inductionById: {},
  updatedInformation: {},
  loadingDataInduction: false,
  errorMessageInduction: {}
};

const inductionReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_INDUCTION_BEGIN: {
      return {
        ...state,
        loadingDataInduction: true
      };
    }
    case types.FETCH_ALL_INDUCTION_SUCCESS: {
      return {
        ...state,
        loadingDataInduction: false,
        allInduction: action.payload,
      };
    } case types.FETCH_ALL_INDUCTION_ERROR: {
      return {
        ...state,
        loadingDataInduction: false,
        errorMessageInduction: action.payload
      };
    }
    case types.FETCH_INDUCTION_BY_ID: {
      return {
        ...state,
        inductionById: action.payload,
      };
    }
    case types.UPDATE_INDUCTION: {
      return {
        ...state,
        updatedInduction: action.payload,
      };
    }
    default:
      return state;
  }
};

export default inductionReducer;
