import { Button } from "@mui/material";
import React, { useEffect } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { fetchDataPayslipById } from "../../../../redux/actions/SuperAdmin/slipPaymentActions";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,Image
} from "@react-pdf/renderer";
import PermataLogo from "../../../../assets/images/permata-logo-pdf.png";

import PoppinsFontRegular from '../../../../assets/font/poppins/Poppins-Regular.ttf';
import PoppinsFont600 from  '../../../../assets/font/poppins/Poppins-Medium.ttf';
import PoppinsFont500 from '../../../../assets/font/poppins/Poppins-SemiBold.ttf';
import PoppinsFont500Italic from '../../../../assets/font/poppins/Poppins-SemiBoldItalic.ttf';
import { Helmet } from "react-helmet";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: PoppinsFontRegular,
    },
    {
      src: PoppinsFont500,
    },
    {
      src: PoppinsFont500Italic,
      fontStyle: 'italic'
    },
    {
      src: PoppinsFont600,
    },
  ],
});

const Styles = StyleSheet.create({
  Body: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 35,
  },
  Heading: {
    borderTop: "5px solid #203D7F",
  },
  HeadingText: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 20,
  },
  Image: {
    width: 142,
    height: 41,
  },
  TextHeading: {
    color: "#333333",
    fontSize: 15,
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  DetailEmployee: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 50,
  },
  DetailLeft: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  TextItemDetailLeft : {
    color: '#333333',
    fontWeight: 600,
    fontSize: 13,
    fontFamily: "Poppins"
  },
  TextItemDetailRight : {
    color: '#333333',
    fontSize: 13,
    fontFamily: "Poppins",
    textTransform: 'capitalize'
  },
  DetailRight: {
    flex: 1,
    marginLeft: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  BoxIncome: {
    paddingTop: 50,
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between"
  },  
  BoxIncomeLeft: {
    flex: 1
  },
  BoxIncomeRight: {
    flex: 1,
    marginLeft: 50,
  },
  BoxIncomeTop : {
    width: 150,
    height: 41,
    backgroundColor: '#203D7F',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  BoxIncomeBottom : {
    width: 210,
    height: 41,
    backgroundColor: '#203D7F',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginLeft: 'auto',
    paddingRight: 15

  },
  TextInsideBox : {
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "Poppins",
    color: '#FFFFFF'
  },
  ItemDetailBox : {
    borderTop: "5px solid #203D7F",
    backgroundColor: '#F1F1F1', 
    padding: 15,
    // display: 'flex',
    // flexDirection: "row",
    // justifyContent: 'space-between',

  },
  ContainerIncome :{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  ItemBoxLeft: {
    flex: 1,

  },
  ItemBoxRight: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end'
  },
  ContainerBottom: {
    borderBottom: "5px solid #203D7F",
    marginTop: 'auto'
  },
  Info : {
    display: 'flex',
    flexDirection: 'row',
    justifyContent:"space-between",
    paddingBottom: 60
  },
  InfoLeft: {
    flex: 1,
  },
  InfoRight: {
    flex: 1,
    marginLeft: 50
  },
  TextNotes : {
    color: "#333333",
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Poppins"
  },
  TextNotesContent : {
    color: "#333333",
    fontSize: 12,
    fontWeight: 500,
    fontStyle: "italic",
    fontFamily: "Poppins"
  },
  ContainerNotes: {
    backgroundColor: "#f1f1f1",
    padding: 15
  },
  BoxInfoTop : {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  TextHidden : {
    fontSize: 0,
    fontFamily: "Poppins",
    color: '#FFFFFF'
  },
  TextBoxInfoTop: {
    color: '#000000',
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: 16
  },
  BoxInfoBottom : {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  BoxInfoLeft: {
    flex: 1,
  },
  BoxInfoRight: {
    flex: 1,
    display: "flex",
    alignItems: 'flex-end'
  }
});

export default function PayslipPersonDetailExternal({ data }) {
  const token = localStorage.getItem("accessToken");
  const clientName = localStorage.getItem('clientName');
  const { id } = useParams();
  // const dataPayslip = useSelector((state) => state.payslip.dataPayslipById);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchDataPayslipById(token, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numberWithPoint = (x) => {
    return Math.round(x).toLocaleString("id");
  };
  // const loanViewTab = (url) => {
  //   const newWindow = window.open(
  //     `/data-person-payslip-external-viewer/${data._id}`,
  //     "_blank",
  //     "noopener,noreferrer"
  //   );
  //   if (newWindow) newWindow.opener = null;
  // };

  return (
    <div className="container-data-payslip">
      <div className="items-main-data-payslip">
        <div className="text-main-data-payslip">
          <span>Epay Slip</span>
        </div>
        <hr className="data-payslip-divider" />
        <div className="item-main-data-payslip">
          <div className="wrapper-item-main">
            <label>Pay Slip Date</label>
            <span>{ data?.payslips[0]?.date }</span>
          </div>
          <div className="wrapper-item-main">
            <label>Employee Name</label>
            <span>{data?.name ? data?.name : "-"}</span>
          </div>
          <div className="wrapper-item-main">
            <label>Position</label>
            <span>{data?.jobTitle ? data?.jobTitle : "-"}</span>
          </div>
        </div>
        <hr className="data-payslip-divider" />
        <div className="item-main-data-payslip">
          <span>Income</span>
          {
            data?.payslips[0]?.incomes?.map((each, index) => (
            <div className="wrapper-item-justify" key={index}>
              <div className="item-justify">
                <span>{ each.label }</span>
              </div>
              <div className="item-justify">
                <span>
                  Rp.{" "} { Math.round(each?.value).toLocaleString("id")}
                  {/* Rp.{" "}
                  {data?.Income[key] ? 
                  Math.round(data?.Income[key]).toLocaleString("id")
                  : 0} */}
                </span>
              </div>
            </div>
            ))
          }
          <div className="wrapper-item-justify">
            <div className="item-justify">
              <span>Total Income</span>
            </div>
            <div className="item-justify">              
            <span>
                Rp.{data?.payslips[0]?.totalIncome ? 
                Math.round(data?.payslips[0]?.totalIncome).toLocaleString("id") : 0}
              </span>
            </div>
          </div>
        </div>
        <hr className="data-payslip-divider" />
        <div className="item-main-data-payslip">
          <span>Deduction</span>
          {
            data?.payslips[0]?.deductions?.map((each, index) => (
            <div className="wrapper-item-justify" key={index}>
              <div className="item-justify">
                <span>{ each.label }</span>
              </div>
              <div className="item-justify">
                <span>
                  Rp.{" "} { Math.round(each?.value).toLocaleString("id")}
                  {/* Rp.{" "}
                  {data?.Income[key] ? 
                  Math.round(data?.Income[key]).toLocaleString("id")
                  : 0} */}
                </span>
              </div>
            </div>
            ))
          }
          {/* {Object.keys(data?.Deduction).map((key, index) => (
            <div className="wrapper-item-justify" key={index}>
              <div className="item-justify">
                <span>{key}</span>
              </div>
              <div className="item-justify">
                <span>
                  Rp.
                  {data?.Deduction[key]
                    ? numberWithPoint(data?.Deduction[key])
                    : "-"}
                </span>
              </div>
            </div>
          ))} */}
            <div className="wrapper-item-justify">
            <div className="item-justify">
              <span>Total Deduction</span>
            </div>
            <div className="item-justify">
            <span>
                Rp.{" "}
                {data?.payslips[0]?.totalDeduction
                  ? numberWithPoint(data?.payslips[0]?.totalDeduction)
                  : 0}
              </span>
            </div>
          </div>
        </div>
        <hr className="data-payslip-divider" />
        <div className="item-main-data-payslip">
          <div className="wrapper-item-justify">
            <div className="item-justify">
              <span style={{ color: "#0A0A0A", fontWeight: 600 }}>Take Home Pay</span>
            </div>
            <div className="item-justify">
              <span style={{ color: "#0A0A0A", fontWeight: 600 }}>
                Rp.
                {data?.payslips[0]?.takeHomePay ? numberWithPoint(data?.payslips[0]?.takeHomePay) : 0}
              </span>
            </div>
          </div>
        </div>
        <div className="button-helper-payslip-data">
          {/* <PDFDownloadLink document={<DataToView data={data} />} fileName={`${data?.name} ${month} ${year}`} className="button-helper-payslip-data"> */}
          <PDFDownloadLink document={<DataToView data={data} clientName={clientName}/>} fileName={`${data?.name} ${data?.payslips[0]?.date } `} className="button-helper-payslip-data">
            {({ blob, url, loading, error }) =>
              loading ? (
                <Button
                  endIcon={<DownloadIcon />}
                  style={{ backgroundColor: "#1571DE", color: 'white', textTransform: "none" }}
                >
                  Loading Data
                </Button>
              ) : (
                <Button
                  endIcon={<DownloadIcon />}
                  style={{ backgroundColor: "#1571DE", color: 'white', textTransform: "none" }}
                >
                  Download
                </Button>
              )
            }
          </PDFDownloadLink>
        </div>
        {/* <div className="button-helper-payslip-data">
          <Button
            onClick={loanViewTab}
            endIcon={<DownloadIcon />}
            style={{ backgroundColor: "#1571DE" }}
          >
            Download
          </Button>
        </div> */}
      </div>
    </div>
  );
}

const DataToView = ({ data, clientName }) => {
  function numberWithPoint(x) {
    // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return Math.round(x).toLocaleString("id");

  }
  return (
    <Document>
      <Helmet>
        <title>MyWorkSpace | Payslip Report</title>
      </Helmet>
      <Page style={Styles.Body} size="A3">
        <View style={Styles.Heading}>
          <View style={Styles.HeadingText}>
            <Image src={PermataLogo} alt="" style={Styles.Image} />
            <Text style={Styles.TextHeading}>Payment Statement</Text>
          </View>
        </View>
        <View style={Styles.DetailEmployee}>
          <View style={Styles.DetailLeft}>
            <View style={Styles.ItemDetailLeft}>
              <Text style={[Styles.TextItemDetailLeft]}>Name</Text>
              <Text style={[Styles.TextItemDetailLeft]}>NRK</Text>
              <Text style={Styles.TextItemDetailLeft}>Position</Text>
              <Text style={Styles.TextItemDetailLeft}>Organization</Text>
              <Text style={Styles.TextItemDetailLeft}>Location</Text>
            </View>
            <View style={Styles.ItemDetailRight}>
              <Text style={Styles.TextItemDetailRight}>{data?.name ? data?.name?.toLowerCase() : "-"}</Text>
              <Text style={[Styles.TextItemDetailRight]}>{data?.employeeId ? data?.employeeId : "-"}</Text>
              <Text style={Styles.TextItemDetailRight}>{data?.jobTitle ?  data?.jobTitle?.toLowerCase() : "-"}</Text>
              <Text style={Styles.TextItemDetailRight}>{clientName ? clientName : "-"}</Text>
              <Text style={Styles.TextItemDetailRight}>{data?.area ?  data?.area?.toLowerCase() : "-"}</Text>
            </View>
          </View>
          <View style={Styles.DetailRight}>
            <View style={Styles.ItemDetail}>
              <Text style={[Styles.TextItemDetailLeft]}>Month</Text>
              <Text style={[Styles.TextItemDetailLeft]}>Join Date</Text>
              <Text style={Styles.TextItemDetailLeft}>Tax Material Status</Text>
              <Text style={Styles.TextItemDetailLeft}>Employment Status</Text>
              <Text style={Styles.TextItemDetailLeft}>NPWP</Text>
            </View>
            <View style={Styles.ItemDetailRight}>
              {/* <Text style={Styles.TextItemDetailRight}>{data?.PayrollMonth ? `${month} ${year}` : "-"}</Text> */}
              <Text style={Styles.TextItemDetailRight}>{data?.payslips[0] ? data?.payslips[0]?.date : "-"}</Text>
              <Text style={[Styles.TextItemDetailRight]}>{data?.joinDate ? data?.joinDate : "-"}</Text>
              <Text style={Styles.TextItemDetailRight}>{data?.maritalStatus ? data?.maritalStatus : "-"}</Text>
              <Text style={Styles.TextItemDetailRight}>{data?.status ? data?.status : "-"}</Text>
              <Text style={Styles.TextItemDetailRight}>{data?.taxId ?  data?.taxId : "-"}</Text>
            </View>
          </View>
        </View>
        <View style={Styles.BoxIncome}>
          <View style={Styles.BoxIncomeLeft}>
          <View style={Styles.BoxIncomeTop}>
            <Text style={Styles.TextInsideBox}>INCOME (A)</Text>
          </View>
          <View style={Styles.ItemDetailBox}>
          {
            data?.payslips[0]?.incomes?.map((each, index) => (
            <View style={Styles.ContainerIncome} key={index}>
            <View style={Styles.ItemBoxLeft}>
              <Text style={Styles.TextItemDetailRight}>{ each.label }</Text>
            </View>
            <View style={Styles.ItemBoxRight}>
            <Text style={Styles.TextItemDetailRight}>Rp.{ each.value ? numberWithPoint(each.value) : 0}</Text>
            {/* Rp.{" "} { Math.round(each?.value).toLocaleString("id")} */}
            </View>
            </View>
            ))
          }
            {/* {Object.keys(data?.Income || {}).map((key,index) => (
            <View style={Styles.ContainerIncome} key={index}>
            <View style={Styles.ItemBoxLeft}>
              <Text style={Styles.TextItemDetailRight}>{key}</Text>
            </View>
            <View style={Styles.ItemBoxRight}>
              <Text style={Styles.TextItemDetailRight}>Rp.{data?.Income[key] ? numberWithPoint(data?.Income[key]) : 0}</Text>
            </View>
            </View>
            ))} */}
          </View>
          <View style={Styles.BoxIncomeBottom}>
            <Text style={Styles.TextInsideBox}>Rp.{data?.payslips[0]?.totalIncome ? numberWithPoint(data?.payslips[0]?.totalIncome) : 0}</Text>
          </View>
          </View>
          <View style={Styles.BoxIncomeRight}>
          <View style={Styles.BoxIncomeTop}>
            <Text style={Styles.TextInsideBox}>DEDUCTION (B)</Text>
          </View>
          <View style={Styles.ItemDetailBox}>
          {
            data?.payslips[0]?.deductions?.map((each, index) => (
            <View style={Styles.ContainerIncome} key={index}>
            <View style={Styles.ItemBoxLeft}>
              <Text style={Styles.TextItemDetailRight}>{ each.label }</Text>
            </View>
            <View style={Styles.ItemBoxRight}>
            <Text style={Styles.TextItemDetailRight}>Rp.{ each.value ? numberWithPoint(each.value) : 0}</Text>
            {/* Rp.{" "} { Math.round(each?.value).toLocaleString("id")} */}
            </View>
            </View>
            ))
          }
          {/* {Object.keys(data?.Deduction || {}).map((key, index) => (
            <View style={Styles.ContainerIncome} key={index}>
            <View style={Styles.ItemBoxLeft}>
              <Text style={Styles.TextItemDetailRight}>{key}</Text>
            </View>
            <View style={Styles.ItemBoxRight}>
              <Text style={Styles.TextItemDetailRight}>Rp.{data?.Deduction[key] ? numberWithPoint(data?.Deduction[key]) : 0}</Text>
            </View>
            </View>
            ))} */}
          </View>
          <View style={Styles.BoxIncomeBottom}>
            <Text style={Styles.TextInsideBox}>Rp.{data?.payslips[0]?.totalDeduction ? numberWithPoint(data?.payslips[0]?.totalDeduction) : 0}</Text>
          </View>
          </View>
        </View>
        <View style={Styles.ContainerBottom}>
          <View style={Styles.Info}>
            <View style={Styles.InfoLeft}>
              <Text style={Styles.TextNotes}>Notes</Text>
              <View style={Styles.ContainerNotes}>
                <Text style={Styles.TextNotesContent}>Payment statment ini sudah digenerate sistem dan tidak diperlukan tanda tangan</Text>
              </View>
            </View>
            <View style={Styles.InfoRight}>
              <Text style={[Styles.TextHidden]}>Right</Text>
              <View style={Styles.BoxInfoTop}>
                <Text style={Styles.TextBoxInfoTop}>TAKE HOME PAY (A - B)</Text>
                <Text style={Styles.TextBoxInfoTop}>Rp.{data?.payslips[0]?.takeHomePay ? numberWithPoint(data?.payslips[0]?.takeHomePay) : 0}</Text>
              </View>
              <View style={Styles.BoxInfoBottom}>
              <View style={Styles.BoxInfoLeft}>
                <Text style={{ fontSize: 10, fontWeight: 400, fontFamily: "Poppins"}}>Bank Name</Text>
                <Text style={{ fontSize: 10, fontWeight: 400, fontFamily: "Poppins"}}>Transfer Account Number</Text>
              </View>
              <View style={Styles.BoxInfoRight}>
                <Text style={{ fontSize: 10, fontWeight: 400, fontFamily: "Poppins"}}>{data?.bankName ? data?.bankName : "-"}</Text>
                <Text style={{ fontSize: 10, fontWeight: 400, fontFamily: "Poppins"}}>{data?.accountNumber ? data?.accountNumber : "-"}</Text>
              </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};