import React from "react";
import "./not-found404.css";
import NOT_FOUND_IMAGE from "../../assets/images/NotFound.png";
import { Button } from "@mui/material";
import { useHistory } from "react-router";

export default function NotFound404() {
  const history = useHistory();
  const routeBack = () => { history.push('/superadmin')}
  return (
    <div className="not-found-container">
      <div className="not-found-main">
        <div className="not-found-image">
          <img src={NOT_FOUND_IMAGE} alt="" />
        </div>
        <div className="not-found-text-container">
          <div>
            <span>404</span>
          </div>
          <div>
            <span>Ooops!</span>
            <br />
            <span>Page Not Found</span>
          </div>
          <div className="">
            <span>
              This page doesn’t exist or was removed! We suggest you back to
              dashboard
            </span>
          </div>
          <div className="button-go-back">
            <Button style={{ background: '#1571de'}} onClick={routeBack}>Back to dashboard</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
