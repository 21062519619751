import Styles from './SavedSetModal.module.css'
import FILE_TRANSFER from "../../../../../assets/images/file-transfer.png";
import { useHistory } from 'react-router-dom';


export default function SavedSetModal({ setSavedSet, id }) {
    const history = useHistory();
    const handleOk = () => {
        setSavedSet(false);
        history.push(`/superadmin/profile-clients/${id}`)
    }
    return (
        <div className={Styles.Background}>
            <div className={Styles.Container}>
                <img src={FILE_TRANSFER} alt="file-transfer" />
                <div className={Styles.Desc}>
                    <span>Setting Saved</span>
                    <span>Congratulation, you have save the setting</span>
                </div>
                <button className={Styles.OkButton} onClick={handleOk}>Ok</button>
            </div>
        </div>
    )
}