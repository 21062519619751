import axios from "axios";
import * as types from '../../type';
import { setSnackbar } from "./snackbarActions";
import {API} from "../../api";
// let API = "http://122.50.7.190:5003/api/mws";
// let API ="https://myworkspace.id/api/mws";


// FETCH ALL DRAFT 
export const fetchAllDrafts = (token) => {
    return (dispatch) => {
      dispatch({ type: types.FETCH_ALL_DRAFT_BEGIN})
      axios
        .get(`${API}/faq/draft`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch({
            type: types.FETCH_ALL_DRAFT_SUCCESS,
            payload: res.data.data,
          });
        })
        .catch((err) => {
          dispatch({ type: types.FETCH_ALL_DRAFT_ERROR, payload: err.response})
          console.log(err);
        });
    };
  };

//FETCH DRAFT BY ID 
export const fetchDraftById = (token, id) => {
    return (dispatch) => {
      axios
        .get(`${API}/faq/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
        //   console.log(res.data.data, "attendances");
          dispatch({
            type: types.FETCH_DRAFT_BY_ID,
            payload: res.data.data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
  };


// CREATE DRAFT 
export const createDraft = (token, data) => {
  return (dispatch) => {
    return axios
      .post(`${API}/faq/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.CREATE_DRAFT,
          payload: res.data.data,
        });
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Data Draft success created",
          })
        );
        return Promise.resolve(); // kondisi jika berhasil
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.message,
          })
        );
        return Promise.reject(err);
      });
  };
};

// UPDATE DRAFT 
// DELETE DRAFT 
