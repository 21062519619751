import React, { useEffect, useState } from "react";
import "./PaySlipDetail.css";
import image_receipt from "../../../../../src/assets/images/Receipt.png";
import { Avatar, Button } from "@mui/material";
import { useHistory } from "react-router";
import { useCalendar } from "../../../utils/hooks";
import DatePickerPaySlip from "../DatePickerPaySlip/DatePickerPaySlip";
import moment from "moment";
import { fetchAllPayslipByYear } from "../../../../redux/actions/SuperAdmin/slipPaymentActions";
import { useDispatch, useSelector } from "react-redux";

export default function PaySlipDetail({ dataUser, nrkNumber }) {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const calendar = useCalendar();
  const history = useHistory();
  const payslipYear = moment().format("YYYY");
  const [yearPicker, setYearPicker] = useState(payslipYear);
  // const [storedData, setStoredData] = useState([])
  const { allPayslipByYear  } = useSelector((state) => state.payslip);
  const data = allPayslipByYear;
  // const storedData = ((data) => { return grade ** 2; });
  let month = calendar.months.eng.full[dataUser?.PayrollMonth?.split(" ")[0] - 1];
  let year = dataUser?.PayrollMonth?.split(" ")[2];

  useEffect(() => {
    dispatch(fetchAllPayslipByYear(token, moment(yearPicker).format("YYYY") , nrkNumber));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearPicker]);

  // const memoizedData = useMemo(() => {
  //   if(data.length === 0){
  //     return "kosong"
  //   } else {
  //     return "ada"
  //   }
  // },[data])

  const changeYearPicker = (newValue) => {
    setYearPicker(newValue);
  };
 
  // console.log(allPayslipByYear,'cek data')
  return (
    <div className="container-payslip-detail">
      <div className="wrapper-payslip-detail">
        <div className="item-top-payslip-detail-top">
          <div className="payslip-detail-left">
            <div className="desc-payslip-left">
              <Avatar
                src={dataUser.ProfilePicture ? dataUser.ProfilePicture : ""}
                sx={{ width: 60, height: 60 }}
              />
              <div className="text-item-detail-left">
                <h1>{dataUser.Name ? dataUser.Name : "-"}</h1>
                <span>{dataUser.Position ? dataUser.Position : "-"}</span>
                <h3>{dataUser.Client ? dataUser.Client : "-"}</h3>
              </div>
            </div>
          </div>
          <div className="payslip-detail-right">
            <div className="payslip-detail-item">
              <label>Last Payment</label>
              <span>{` ${month} ${year}`}</span>
            </div>
          </div>
        </div>
        <hr />
        <div className="container-payment-history">
          <div className="wrapper-payment-history">
            <div className="title-payment-history">
              <span>Payment History</span>
            </div>
            <div className="rangepicker-payment">
              <DatePickerPaySlip
                valueYearPicker={yearPicker}
                handleYearPicker={changeYearPicker}
              />
            </div>
          </div>
          <div className="item-payment-years">
            {data.length !== 0 ? data.map((sData, sIndex) => {
                  let month =
                    calendar.months.eng.full[
                      sData?.PayrollMonth?.split(" ")[0] - 1
                    ];
                  let year = sData?.PayrollMonth?.split(" ")[2];
                  return (
                    <div className="wrapper-item-payment-years" key={sIndex}>
                      <div className="card-item-payment">
                        <img src={image_receipt} alt="" />
                      </div>
                      <div className="card-item-text">
                        <label>{`${month} ${year}`}</label>
                        <Button
                          style={{
                            color: "#1571DE",
                            textTransform: "none",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                          onClick={() =>
                            history.push({
                              pathname: `/superadmin/payslip-person-detail/${sData._id}`,
                              state: sData,
                            })
                          }
                        >
                          See Detail
                        </Button>
                      </div>
                    </div>
                  );
                })
              : <span>There's no payment data</span>}
          </div>
        </div>
      </div>
    </div>
  );
}
