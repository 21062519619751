import { IconButton } from "@mui/material";
import React from "react";
import Styles from "./ButtonRouteBack.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function ButtonRouteBack(props) {
  return (
    <div className={Styles.Body} {...props}>
      <IconButton>
        <ArrowBackIcon sx={{ color: "#202020", width: 24, height: 24 }} />
      </IconButton>
    </div>
  );
}
