/* eslint-disable no-unused-vars */

import React, { useEffect, useMemo, useState } from "react";
// eslint-disable-next-line no-unused-vars
//import "./AddInformationForm.css";
import PublishIcon from "@mui/icons-material/Publish";
import { Alert, Autocomplete, Box, Button, Checkbox, FormControl, IconButton, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import DELETE_ICON from "../../../../../assets/images/delete-rules-icon.png";
import DELETE_SVG from "../../../../../assets/images/delete.svg";
import fetchClientCode from "../../../../../async/client/fetchClientCode";
import { addInformation } from "../../../../../redux/actions/SuperAdmin/informationActions";
import ModalPublishedInfo from "../PublishedInformation/ModalPublishedInfo";
import ModalAddPushNotification from "../ModalAddPushNotification"
import Styles from "./AddInformationForm.module.css";
import { Cancel, Circle, CircleOutlined, RadioButtonChecked, RadioButtonUnchecked, MailOutline, WhatsApp, EmailOutlined, ControlPoint, Edit, AddCircle } from "@mui/icons-material";
import NOTIFICATION_IMG from "../../../../../assets/images/notification.png"
import NOTIFICATION_LOGO_IMG from "../../../../../assets/images/notification-logo.png"
import moment from "moment";
import formatPhoneNumber from "../../../../../helpers/formatPhoneNumber";
import ModalPreview from "../ModalPreview";
import Scrollbar from "../../../_user/TableUsers/Scrollbar";
import { visuallyHidden } from "@mui/utils";
import { Link } from "react-router-dom";
import CustomTablePagination from "../../../../_pagination/CustomTablePagination";
import CustomTable from "../../../../CustomTable/CustomTable";

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 5,
      width: "100%",
    },
    "& .MuiSelect-select": {
      fontSize: 16,
    },
  },
});

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      border: '1px solid #e0e0e0',
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1.5px solid #d3c9c9",
    borderColor: "#d3c9c9",
    borderRadius: 8,
    //   width: "100%",
  },
});

export default function AddInformationForm() {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const history = useHistory();
  const inputFileRef = useRef()
  const [files, setFiles] = useState([]);
  const [preview, setPreview] = useState(null);
  const [previewName, setPreviewName] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState({
    title: "",
    clientCode: [],
    content: "",
    image: null,
    isPublish: "",
  });
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalDraft, setModalDraft] = useState(false);
  const [alert, setAlert] = useState(false);

  const [selected, setSelected] = useState("All");
  const [selectedCode, setSelectedCode] = useState("All");
  const [dataClientCode, setDataClientCode] = useState([]);

  const [selectedClient, setSelectedClient] = useState({
    name: "", codes: ""
  })
  const [selectedClients, setSelectedClients] = useState([])

  const [selectedContact, setSelectedContact] = useState({
    whatsapp: {
      enable: false,
      value: ''
    },
    email: {
      enable: false,
      value: ''
    }
  })

  const [pushNotification, setPushNotification] = useState([]);
  const [modalAddNotification, setModalAddNotification] = useState(false)
  const [selectedPushNotification, setSelectedPushNotification] = useState(null)
  const [modalPreviewNotif, setModalPreviewNotif] = useState(false)

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPage, setTotalPage] = useState(1)

  const getNextSchedule = (schedule) => {
      if (!schedule || schedule.length === 0) return "-";

      const sortedSchedule = schedule.sort((a, b) => {
          const datetimeA = a.date.set('hour', a.time.split(':')[0]).set('minute', a.time.split(':')[1]);
          const datetimeB = b.date.set('hour', b.time.split(':')[0]).set('minute', b.time.split(':')[1]);
          return datetimeA - datetimeB;
      });

      const nearest = sortedSchedule[0];
      const formattedNearest = nearest.date
          .set('hour', nearest.time.split(':')[0])
          .set('minute', nearest.time.split(':')[1])
          .format('DD MMM YYYY, HH:mm');

      return formattedNearest;
  };

  const TABLE_HEAD = [
    { id: "no", label: "No", alignRight: false, width: '10%' },
    { id: "title", label: "Judul Notifikasi", alignRight: false, width: '20%' },
    { id: "description", label: "Deskripsi", alignRight: false, width: '30%' },
    { id: "schedule", label: "Jadwal selanjutnya", alignRight: false, width: '30%',
      renderData: (row) => (
        <span>{getNextSchedule(row?.schedule)}</span>
      )
     },
    { 
      id: "", 
      width: '10%', 
      renderData: (row) => {
        return (
          <div 
            style={{cursor: "pointer"}}
            onClick={() => {
              setSelectedPushNotification(row)
              setModalAddNotification(true)
            }}
          >
              <span style={{ color: '#1571DE' }}>
                  Lihat Detail
              </span>
          </div>
        )
      }},
  ];

  const rowPushNotificationData = useMemo(() => {
    if (pushNotification?.length > 0){
        const startNumber = ((page - 1) * rowsPerPage) + 1
        const lastNumber = page * rowsPerPage
        let arrSch = []
        const temp = [...pushNotification]

        for(let i = startNumber; i <= lastNumber; i++ ){
            if (temp[i-1]){
                arrSch.push({
                    ...temp[i-1],
                    no: i
                })
            }
        }

        return arrSch
    } else {
      return []
    }
    // return pushNotification?.map((value, index) => {
    //   return {
    //     ...value,
    //     no: (rowsPerPage * (page - 1)) + (index + 1)
    //   }
    // })
  }, [pushNotification, rowsPerPage, page])

  useEffect(() => {
    if (pushNotification?.length > 0){
        const size = pushNotification?.length || 0
        const total = Math.ceil(size / rowsPerPage)
        setTotalPage(total > 0 ? total : 1)
    }
}, [pushNotification, rowsPerPage])

  const handleEditData = (e) => {
    setValue((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "file" ? e.target.files : e.target.value,
    }));

    if (e.target.type === "file") {
      let url = URL.createObjectURL(e.target.files[0]);
      setPreview(url);
      setPreviewName(e.target.files[0].name);
    }
  };

  const handleDeleteImage = (e) => {
    inputFileRef.current.value = null;
    setPreview(null);
    setPreviewName("");
    setValue((prev) => ({
      ...prev,
      // eslint-disable-next-line no-useless-computed-key
      ["image"]: null
    }));
  };


  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setValue((prev) => ({
      ...prev,
      // eslint-disable-next-line no-useless-computed-key
      ["image"]: acceptedFiles
    }));

    setPreview(URL.createObjectURL(acceptedFiles[0]));
    setPreviewName(acceptedFiles[0].name);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
    multiple: false,
  });


  const fixedClients = useMemo(() => {
    let pre = [{ codes: "ALL", name: "ALL" }, { codes: 'Tes', name: 'Client Tester' }]
    return pre.concat(dataClientCode);
  }, [dataClientCode])

  const handleSelectedClient = (e, value) => {
    e.preventDefault()
    setSelected(value);

    const isExist = selectedClients.find((item) => item?.name === value?.name);
    const doesContainAll = selectedClients.some((item) => item?.codes === 'All');
    let isAll = Boolean(value?.codes === 'All' && value?.name === 'All')
    const isEmpty = selectedClients.length === 0;


    if (!isExist && !isAll && !doesContainAll) {
      let temp = selectedClients.concat(value)
      setSelectedClients(temp)
    } else if (isExist) {
      const x = selectedClients.filter((item) => item !== value);
      setSelectedClients(x)
    } else if (isEmpty && isAll) {
      let temp = selectedClients.concat(value)
      setSelectedClients(temp)
    } else if (!isExist && doesContainAll) {
      setSelectedClients(selectedClients)
    }

  };


  const handleDeleteClient = (value) => {
    const x = selectedClients.filter((item) => item !== value);
    setSelectedClients(x)
  }


  const isSelected = (option) => selectedClients?.indexOf(option) !== -1;

  const capitalizedClientName = (clientName) => {
    return clientName?.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace('Pt', 'PT')
      .replace('Cv', 'CV')
  }


  const handleSubmitPushNotification = (value, type) => {
    if (type === 'ADD'){
      setPushNotification((prev) => {
        return [
          ...prev,
          {
            ...value,
            id: prev?.length || 0
          }
        ]
      })
    } else if (type === 'EDIT'){
      setPushNotification((prev) => {
        const idx = prev?.findIndex(f => f?.id === value?.id)
        prev[idx] = value
        return [...prev]
      })
      setSelectedPushNotification(null)
    } else if (type === 'DELETE'){
      setPushNotification((prev) => {
        const arr = prev?.filter(f => f?.id !== value?.id)
        return [...arr]
      })
      setSelectedPushNotification(null)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.set("title", value.title);
    formData.set("content", value.content);
    for (let i = 0; i < selectedClients.length; i++) {
      formData.append("clientCode", selectedClients[i]?.codes);
    }
    if (
      value.image !== null &&
      value.image !== undefined &&
      value.image !== ""
    ) {
      formData.set("image", value.image[0], value.image[0].name);
    }

    if (selectedContact?.whatsapp?.enable){
      if (selectedContact?.whatsapp?.value){
        formData.append('contact[whatsappNumber]', formatPhoneNumber(selectedContact?.whatsapp?.value));
      }
    }

    if (selectedContact?.email?.enable){
      if (selectedContact?.email?.value){
        formData.append('contact[email]', selectedContact?.email?.value);
      }
    }

    if (pushNotification?.length > 0){
      pushNotification?.forEach((notif, index) => {

        formData.append(`pushNotif[${index}][title]`, notif.title);
        formData.append(`pushNotif[${index}][description]`, notif.description);

        if (notif?.schedule?.length > 0){
          notif?.schedule?.forEach((scheduleItem, indexSchedule) => {
            const [hours, minutes, seconds] = scheduleItem?.time?.split(":").map(Number);
            const fixedDate = new Date(scheduleItem?.date)

            fixedDate.setHours(hours)
            fixedDate.setMinutes(minutes)
            fixedDate.setSeconds(seconds)

            formData.append(`pushNotif[${index}][schedule][${indexSchedule}][date]`, fixedDate?.toISOString());
          })
        }

        // formData.append(`pushNotif[${index}][pushTime]`, fixedDate?.toISOString());
      });
    }

    formData.set("isPublish", true);

    // if (value.title === "" || value.content === "" || value.clientCode === "") {
    if (value.title === "" || value.content === "" || selectedClients?.length === 0) {
      setAlert(true);
    } else {
      dispatch(addInformation(token, formData));
      setModalSuccess(true);
    }
  };

  const handleClickCancel = (e) => {
    e.preventDefault();
    
    if (localStorage.getItem("type") === "BUSINESS"){
      history.push("/admin-business/information");
    } else if (localStorage.getItem("type") === "SUPER"){
      history.push("/superadmin/information");
    }
  };

  const handleSelectedContact = (e, id, action) => {
    e.preventDefault()

    if (id === 'WA'){
      if (action === 'FILL'){
        setSelectedContact((prev) => {
          return {
            ...prev,
            whatsapp: {
              ...prev?.whatsapp,
              value: e.target.value,
            }
          }
        })
      }
      else if (action === 'CHECK'){
        setSelectedContact((prev) => {
          return {
            ...prev,
            whatsapp: {
              ...prev?.whatsapp,
              enable: !prev.whatsapp.enable,
            }
          }
        })
      }
    }
    else if(id === 'EMAIL'){
      if (action === 'FILL'){
        setSelectedContact((prev) => {
          return {
            ...prev,
            email: {
              ...prev?.email,
              value: e.target.value,
            }
          }
        })
      }
      else if (action === 'CHECK'){
        setSelectedContact((prev) => {
          return {
            ...prev,
            email: {
              ...prev?.email,
              enable: !prev.email.enable,
            }
          }
        })
      }
    }
  }

  const saveToDraft = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.set("title", value.title);
    formData.set("content", value.content);
    for (let i = 0; i < selectedClients.length; i++) {
      formData.append("clientCode", selectedClients[i]?.codes);
    }
    if (
      value.image !== null &&
      value.image !== undefined &&
      value?.image !== ""
    ) {
      formData.set("image", value.image[0], value.image[0].name);
    }

    if (selectedContact?.whatsapp?.enable){
      if (selectedContact?.whatsapp?.value){
        formData.append('contact[whatsappNumber]', formatPhoneNumber(selectedContact?.whatsapp?.value));
      }
    }

    if (selectedContact?.email?.enable){
      if (selectedContact?.email?.value){
        formData.append('contact[email]', selectedContact?.email?.value);
      }
    }

    if (pushNotification?.length > 0){
      pushNotification?.forEach((notif, index) => {

        formData.append(`pushNotif[${index}][title]`, notif.title);
        formData.append(`pushNotif[${index}][description]`, notif.description);

        if (notif?.schedule?.length > 0){
          notif?.schedule?.forEach((scheduleItem, indexSchedule) => {
            const [hours, minutes, seconds] = scheduleItem?.time?.split(":").map(Number);
            const fixedDate = new Date(scheduleItem?.date)

            fixedDate.setHours(hours)
            fixedDate.setMinutes(minutes)
            fixedDate.setSeconds(seconds)

            formData.append(`pushNotif[${index}][schedule][${indexSchedule}][date]`, fixedDate?.toISOString());
          })
        }

        // formData.append(`pushNotif[${index}][pushTime]`, fixedDate?.toISOString());
      });
    }

    formData.set("isPublish", false);

    // if (value.title === "" || value.content === "" || value.clientCode === []) {
    if (value.title === "" || value.content === "" || selectedClients?.length === 0) {
      setAlert(true);
    } else {
      dispatch(addInformation(token, formData));
      setModalDraft(true);
    }
  }

  const showTime = useCallback((timeString) => {
      const temp = timeString?.split(":")
      return `${temp[0]}:${temp[1]}`
  },[])

  useEffect(() => () => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCode(response)
      } catch (error) {
        console.log(error)
      }
    }
    )()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCode(response);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  return (
      <div className={Styles.Container}>
          <div className={Styles.Wrapper}>
              <form onSubmit={handleSubmit}>
                  <div className={Styles.WrapperBox}>
                      {alert && (
                          <Alert sx={{ marginBottom: 3 }} severity="warning">
                              Client, Title, and Content are mandatories!
                          </Alert>
                      )}
                      <div style={{ display: 'flex', gap: 10 }}>
                          <div className={Styles.ItemBox}>
                              <span>To</span>
                              <FormControl size="small" fullWidth>
                                  <Autocomplete
                                      disablePortal
                                      value={null}
                                      blurOnSelect={true}
                                      disableCloseOnSelect
                                      options={fixedClients}
                                      getOptionLabel={(option) => option?.name}
                                      sx={{ width: '100%' }}
                                      onChange={(e, value) =>
                                          value === null
                                              ? handleSelectedClient(e, null)
                                              : handleSelectedClient(e, value)
                                      }
                                      renderOption={(props, option) => {
                                          const isItemSelected = isSelected(option);
                                          return (
                                              <li
                                                  {...props}
                                                  onClick={(e) => e.preventDefault()}
                                                  key={option?.codes}
                                                  style={{ borderBottom: '1px solid #c2c2c2', padding: '12px' }}>
                                                  <div
                                                      style={{
                                                          display: 'flex',
                                                          justifyContent: 'space-between',
                                                          alignItems: 'center',
                                                          width: '100%',
                                                      }}>
                                                      <span className={Styles.ClientOption}>
                                                          {capitalizedClientName(option?.name)}
                                                      </span>
                                                      <Checkbox
                                                          onClick={(e) => handleSelectedClient(e, option)}
                                                          sx={{ margin: 0, padding: 0 }}
                                                          checked={isItemSelected}
                                                          icon={<RadioButtonUnchecked sx={{ color: '#c2c2c2' }} />}
                                                          checkedIcon={<RadioButtonChecked sx={{ color: '#1571DE' }} />}
                                                      />
                                                  </div>
                                              </li>
                                          );
                                      }}
                                      renderInput={(params) => (
                                          <MuiTextField
                                              {...params}
                                              size="small"
                                              sx={{ background: 'var(--main-bg)' }}
                                              placeholder="Select company"
                                          />
                                      )}
                                  />
                              </FormControl>
                          </div>
                          <div className={Styles.ItemBox}>
                              <span className={Styles.ItemBoxSpan}>Title</span>
                              <input
                                  className={Styles.ItemBoxInput}
                                  type="text"
                                  name="title"
                                  onChange={handleEditData}
                                  placeholder="Type title here"
                              />
                          </div>
                      </div>

                      {selectedClients?.length > 0 && (
                          <div className={Styles.ClientsChipContainer}>
                              {selectedClients.map((client, i) => (
                                  <div key={i} className={Styles.ClientsChip}>
                                      <span>{capitalizedClientName(client?.name)}</span>
                                      <Cancel
                                          className={Styles.CancelIcon}
                                          onClick={() => handleDeleteClient(client)}
                                      />
                                  </div>
                              ))}
                          </div>
                      )}

                      <div className={Styles.ItemBoxContent}>
                          <span>Content</span>
                          <textarea
                              type="text"
                              name="content"
                              onChange={handleEditData}
                              placeholder="Description of content"
                          />
                      </div>
                      <div className={Styles.ContainerUploadImage}>
                          <div {...getRootProps()} className={Styles.WrapperUploadImage}>
                              <input
                                  {...getInputProps()}
                                  type="file"
                                  ref={inputFileRef}
                                  name="image"
                                  onChange={handleEditData}
                                  hidden
                              />
                              {preview ? (
                                  <div
                                      style={{
                                          width: '100%',
                                          margin: 0,
                                          padding: 0,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'start',
                                          gap: 30,
                                      }}>
                                      <img
                                          src={preview}
                                          style={{
                                              width: '245px',
                                              height: '158px',
                                              objectFit: 'cover',
                                              borderRadius: 8,
                                          }}
                                          alt={preview}
                                          onClick={() => inputFileRef.current.click()}
                                      />
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                              width: '100%',
                                          }}>
                                          <span
                                              style={{
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  gap: 20,
                                                  fontSize: '14px',
                                              }}>
                                              <span>{previewName}</span>
                                              <Button
                                                  type="button"
                                                  style={{
                                                      border: '1px solid #1571DE',
                                                      color: '#1571DE',
                                                      width: 120,
                                                      height: 40,
                                                      padding: 10,
                                                      borderRadius: 8,
                                                      fontSize: '14px',
                                                  }}
                                                  onClick={() => inputFileRef.current.click()}>
                                                  Change File
                                              </Button>
                                          </span>
                                          <img
                                              src={DELETE_ICON}
                                              onClick={handleDeleteImage}
                                              alt="delete"
                                              style={{ width: '18px', height: '20px', cursor: 'pointer' }}
                                          />
                                      </div>
                                  </div>
                              ) : (
                                  <div className={Styles.BoxInputImage}>
                                      <span>Drag & Drop File Here</span>
                                      <div className={Styles.BoxInputFile}>
                                          <span>File must JPG or PNG</span>
                                      </div>
                                      <div className={Styles.BoxInputOr}>
                                          <span> OR </span>
                                      </div>
                                      <div className={Styles.ContainerInputImage}>{}</div>
                                      <div className={Styles.BoxInputButton}>
                                          <Button onClick={() => inputFileRef.current.click()}>
                                              <span>Browse File</span>
                                              <PublishIcon />
                                          </Button>
                                      </div>
                                  </div>
                              )}
                          </div>
                      </div>

                      <div className={Styles.ItemBoxContactInformation}>
                          <span>Add Contact Information</span>
                          <div className={Styles.ContactInformationWrapper}>
                              <div className={Styles.CardContactInformation}>
                                  <div className={Styles.TopContactInformation}>
                                      <div className={Styles.TitleContactInformation}>
                                          <WhatsApp sx={{ color: '#000000' }} />
                                          <span>Whatsapp</span>
                                      </div>
                                      <Checkbox
                                          onClick={(e) => handleSelectedContact(e, 'WA', 'CHECK')}
                                          sx={{ margin: 0, padding: 0 }}
                                          checked={selectedContact?.whatsapp?.enable}
                                      />
                                  </div>
                                  <input
                                      className={Styles.ItemBoxInput}
                                      type="number"
                                      name="whatsapp"
                                      onChange={(e) => handleSelectedContact(e, 'WA', 'FILL')}
                                      placeholder="+62"
                                      value={selectedContact?.whatsapp?.value}
                                      disabled={!selectedContact?.whatsapp?.enable}
                                  />
                              </div>

                              <div className={Styles.CardContactInformation}>
                                  <div className={Styles.TopContactInformation}>
                                      <div className={Styles.TitleContactInformation}>
                                          <EmailOutlined sx={{ color: '#000000' }} />
                                          <span>Email</span>
                                      </div>
                                      <Checkbox
                                          onClick={(e) => handleSelectedContact(e, 'EMAIL', 'CHECK')}
                                          sx={{ margin: 0, padding: 0 }}
                                          checked={selectedContact?.email?.enable}
                                      />
                                  </div>
                                  <input
                                      className={Styles.ItemBoxInput}
                                      type="email"
                                      name="email"
                                      onChange={(e) => handleSelectedContact(e, 'EMAIL', 'FILL')}
                                      placeholder="example@mail.com"
                                      value={selectedContact?.email?.value}
                                      disabled={!selectedContact?.email?.enable}
                                  />
                              </div>
                          </div>
                      </div>

                      <div className={Styles.ItemBoxSetupPushNotification}>
                          <div className={Styles.TitleSetupPushNotification}>
                              <span>Setup Push Notification</span>
                              <div className={Styles.SetupPushNotificationButtonWrapper}>
                                  {pushNotification?.length > 0 && (
                                      <IconButton
                                          aria-label="add-notif"
                                          color="primary"
                                          sx={{
                                              gap: '10px',
                                              cursor: 'pointer',
                                              fontSize: '14px',
                                              fontWeight: '500',
                                          }}
                                          disableRipple={true}
                                          onClick={() => setModalAddNotification(true)}>
                                          <AddCircle fontSize="small" />
                                          <span>Tambah Jadwal</span>
                                      </IconButton>
                                  )}
                                  <div className={Styles.BoxInputButton}>
                                      <Button
                                          onClick={() => {
                                              setModalPreviewNotif(true);
                                          }}>
                                          Lihat Preview
                                      </Button>
                                  </div>
                              </div>
                          </div>
                          {/* {pushNotification?.length > 0 ? (
                              <div className={Styles.FillPushNotification}>
                                  {pushNotification.map((item, index) => (
                                      <div className={Styles.BoxPushNotification} key={`pushnotif_${index}`}>
                                          <img
                                              src={NOTIFICATION_LOGO_IMG}
                                              alt="notif-logo-img"
                                              style={{ height: '32px', width: '32px' }}
                                          />
                                          <div className={Styles.ComponentNotification} style={{ width: '50%' }}>
                                              <span>Description</span>
                                              <p>{item?.description}</p>
                                          </div>
                                          <div className={Styles.ComponentNotification} style={{ width: '20%' }}>
                                              <span>Date</span>
                                              <p>
                                                  {item?.date
                                                      ? moment(new Date(item?.date)).format('DD MMMM YYYY')
                                                      : '-'}
                                              </p>
                                          </div>
                                          <div className={Styles.ComponentNotification} style={{ width: '10%' }}>
                                              <span>Time</span>
                                              <p>{item?.time ? showTime(item?.time) : '-'}</p>
                                          </div>
                                          <div className={Styles.ActionLayout}>
                                              <IconButton
                                                  aria-label="edit-notif"
                                                  color="primary"
                                                  sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                                                  onClick={() => {
                                                      setSelectedPushNotification(item);
                                                      setModalAddNotification(true);
                                                  }}>
                                                  <Edit />
                                              </IconButton>
                                              <IconButton
                                                  aria-label="delete-notif"
                                                  sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                                                  onClick={() => {
                                                      handleSubmitPushNotification(item, 'DELETE');
                                                  }}>
                                                  <img
                                                      src={DELETE_SVG}
                                                      alt="delete"
                                                      style={{ height: '20px', width: '20px' }}
                                                  />
                                              </IconButton>
                                          </div>
                                      </div>
                                  ))}
                                  <IconButton
                                      aria-label="add-notif"
                                      color="primary"
                                      sx={{
                                          gap: '10px',
                                          cursor: 'pointer',
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          alignSelf: 'flex-start',
                                      }}
                                      disableRipple={true}
                                      onClick={() => setModalAddNotification(true)}>
                                      <ControlPoint fontSize="small" />
                                      <span>Jadwal Notifikasi</span>
                                  </IconButton>
                              </div>
                          ) : (
                              <div className={Styles.EmptySetupPushNotification}>
                                  <img
                                      src={NOTIFICATION_IMG}
                                      alt="notif-img"
                                      style={{ height: '70px', width: '100px' }}
                                  />
                                  <br />
                                  <span>Belum ada jadwal notifikasi ditambahkan</span>
                                  <IconButton
                                      aria-label="add-notif"
                                      color="primary"
                                      sx={{ gap: '10px', cursor: 'pointer', fontSize: '14px', fontWeight: '500' }}
                                      disableRipple={true}
                                      onClick={() => setModalAddNotification(true)}>
                                      <ControlPoint fontSize="small" />
                                      <span>Jadwal Notifikasi</span>
                                  </IconButton>
                              </div>
                          )} */}
                          <CustomTable
                              bodyData={rowPushNotificationData}
                              tableHead={TABLE_HEAD}
                              order={order}
                              setOrder={setOrder}
                              orderBy={orderBy}
                              setOrderBy={setOrderBy}
                              page={page}
                              setPage={setPage}
                              rowsPerPage={rowsPerPage}
                              setRowsPerPage={setRowsPerPage}
                              totalPage={totalPage}
                              customEmpty={() => {
                                  return (
                                      <div className={Styles.CenterEmptySetupPushNotification}>
                                          <div className={Styles.EmptySetupPushNotification}>
                                              <img
                                                  src={NOTIFICATION_IMG}
                                                  alt="notif-img"
                                                  style={{ height: '70px', width: '100px' }}
                                              />
                                              <br />
                                              <span>Belum ada jadwal notifikasi ditambahkan</span>
                                              <IconButton
                                                  aria-label="add-notif"
                                                  color="primary"
                                                  sx={{
                                                      gap: '10px',
                                                      cursor: 'pointer',
                                                      fontSize: '14px',
                                                      fontWeight: '500',
                                                  }}
                                                  disableRipple={true}
                                                  onClick={() => setModalAddNotification(true)}>
                                                  <ControlPoint fontSize="small" />
                                                  <span>Push Notification</span>
                                              </IconButton>
                                          </div>
                                      </div>
                                  );
                              }}
                          />
                      </div>
                  </div>
                  <div className={Styles.ButtonHelper}>
                      <Button
                          type="button"
                          style={{
                              border: '1px solid #1571DE',
                              color: '#1571DE',
                              width: 100,
                              height: 40,
                              padding: 10,
                              borderRadius: 8,
                          }}
                          onClick={handleClickCancel}>
                          Cancel
                      </Button>
                      <Button
                          type="submit"
                          style={{
                              background: '#1571DE',
                              color: 'white',
                              width: 100,
                              height: 40,
                              padding: 10,
                              borderRadius: 8,
                          }}>
                          Publish
                      </Button>
                      <span onClick={(e) => saveToDraft(e)}>Save To Draft</span>
                  </div>
              </form>
              {modalSuccess && (
                  <ModalPublishedInfo
                      setModalSuccess={setModalSuccess}
                      history={history}
                      title="Success Published"
                      text="you have send one information to the client"
                  />
              )}
              {modalDraft && (
                  <ModalPublishedInfo
                      setModalSuccess={setModalDraft}
                      history={history}
                      title="Saved to Draft"
                      text="you have saved one information to draft"
                  />
              )}
              {modalAddNotification && (
                  <ModalAddPushNotification
                      type={selectedPushNotification ? 'EDIT' : 'ADD'}
                      submit={(value) => handleSubmitPushNotification(value, selectedPushNotification ? 'EDIT' : 'ADD')}
                      value={selectedPushNotification}
                      onClose={() => {
                        setModalAddNotification(false)
                        setSelectedPushNotification(null)
                      }}
                  />
              )}
              {modalPreviewNotif && (
                  <ModalPreview
                      value={{ ...value, contact: selectedContact, pushNotif: pushNotification, banner: preview }}
                      setModal={setModalPreviewNotif}
                  />
              )}
          </div>
          <div></div>
          <div></div>
      </div>
  );
}
