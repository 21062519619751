import React, { useEffect, useState } from "react";
import "./EmployeeExternal.css";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router";
import { visuallyHidden } from "@mui/utils";
import { Link } from "react-router-dom";
import {
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Stack,
  Avatar,
  IconButton,
  FormControl,
  MenuItem,
  Select,
  AvatarGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEmployeesExternal } from "../../../../redux/actions/External/employeesExternalActions";

function createData(employeeName, position, store, staff, leader) {
  return {
    employeeName,
    position,
    store,
    staff,
    leader,
  };
}

const rows = [
  createData("Anastasia", "Sales Promotion Girl", "Not Assign"),
  createData("Anastasia", "Sales Promotion Girl", "Indomaret Jaksel"),
  createData("Anastasia", "Sales Promotion Girl", "Indomaret Jaksel"),
  createData("Anastasia", "Sales Promotion Girl", "Indomaret Jaksel"),
  createData("Anastasia", "Sales Promotion Girl", "Indomaret Jaksel"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "employeeName",
    numeric: false,
    disablePadding: false,
    label: "Employee Name",
  },
  {
    id: "position",
    numeric: true,
    disablePadding: false,
    label: "Position",
  },
  {
    id: "store",
    numeric: true,
    disablePadding: false,
    label: "Store",
  },
  {
    id: "staff",
    numeric: true,
    disablePadding: false,
    label: "Staff",
  },
  {
    id: "leader",
    numeric: true,
    disablePadding: false,
    label: "Leader",
  },
  {
    label: "",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell></TableCell> */}
        {headCells.map((headCell, ind) => (
          <TableCell
            key={ind}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


export default function EmployeeExternal() {
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const Employees = useSelector((state) => state.employees.employees);
  const clientName = localStorage.getItem("client");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("employeeName");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedDropdown, setSelectedDropdown] = useState("");
  const options = ["Position", "Other"];
  const [listEmployees, setListEmployees] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");


  useEffect(() => {
    dispatch(fetchAllEmployeesExternal(token, clientName));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, coordinatePoint) => {
    const selectedIndex = selected.indexOf(coordinatePoint);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, coordinatePoint);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setListEmployees(event.target.value);
  };
  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchTerm(listEmployees);
  };

  return (
    <div className="employee__external-container">
      <div className="employee__external-item-top">
        <div className="employee__item-search">
          <SearchbarSA
            value={listEmployees}
            onChange={handleSearch}
            onSubmit={handleSubmitFilter}
          />
        </div>
        <div className="employee__item-dropdown">
          <FormControl>
            <Select
              value={selectedDropdown}
              displayEmpty
              onChange={(e) => setSelectedDropdown(e.target.value)}
              SelectDisplayProps={{
                style: {
                  paddingTop: 8,
                  paddingBottom: 8,
                  width: 100,
                  backgroundColor: "#fbfbfb",
                },
              }}
            >
              <MenuItem value="">All</MenuItem>
              {options.map((option, el) => (
                <MenuItem
                  key={el}
                  onClick={() => setSelectedDropdown(option)}
                  value={option}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="employee__item-add">
          {/* <Button
            startIcon={<AddIcon style={{ color: "white" }} />}
            style={{
              backgroundColor: "#1571DE",
              textTransform: "none",
              width: 150,
              height: 40,
              borderRadius: 4,
            }}
            onClick={() => history.push("/admin/add-employee-external")}
          >
            <span style={{ fontSize: 14, color: "white" }}>Add Employee</span>
          </Button> */}
        </div>
      </div>
      <div className="employee__external-table">
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", backgroundColor: "#FAFAFA" }}>
            <TableContainer>
              <Table sx={{ width: "100%" }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(Employees, getComparator(order, orderBy))
                    .filter(
                      (el) =>
                        el.Name?.toLowerCase().includes(
                          listEmployees?.toLowerCase()
                        ) ||
                        (el.Position?.toLowerCase().includes(
                          listEmployees?.toLocaleLowerCase()
                        ) &&
                          (selectedDropdown
                            ? el.Position === selectedDropdown
                            : true))
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      // const { status } = row.store;

                      // eslint-disable-next-line no-unused-vars
                      let statusStore = (
                        <>
                          <Typography
                            className="status__store"
                            // style={{
                            //   backgroundColor:
                            //     status === "Not Assign" && "#b22a12",
                            //   width: status === "Not Assign" && 80,
                            //   height: status === "Not Assign" && 26,
                            //   borderRadius: status === "Not Assign" && 8,
                            // }}
                          >
                            {/* {row.store} */}
                          </Typography>
                        </>
                      );
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.Name)}
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell component="th" scope="row">
                            <Link
                              to={{
                                pathname: `/admin/details-employee-external`,
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar alt={row.Name} src="" />
                                <Typography
                                  variant="subtitle2"
                                >
                                  {row.Name}
                                </Typography>
                              </Stack>
                            </Link>
                          </TableCell>
                          <TableCell align="left">{row.Position}</TableCell>
                          <TableCell align="left">
                            {row.store === "Not Assign" ? (
                              <Typography
                                className="status__store"
                                // style={{
                                //   backgroundColor:
                                //     status === "Not Assign" && "#b22a12",
                                //   width: status === "Not Assign" && 100,
                                //   height: status === "Not Assign" && 26,
                                //   borderRadius: status === "Not Assign" && 8,
                                // }}
                              >
                                {row.store}
                              </Typography>
                            ) : (
                              <Typography>
                                {row.Store ? row.Store : "-"}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Stack alignItems="center" direction="row">
                              <AvatarGroup total={4}>
                                {/* {render employee photo here} */}
                                <Avatar />
                                <Avatar />
                                <Avatar />
                                <Avatar />
                              </AvatarGroup>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Stack alignItems="center" direction="row">
                              <AvatarGroup total={4}>
                                {/* {render employee photo here} */}
                                <Avatar />
                                <Avatar />
                                <Avatar />
                                <Avatar />
                              </AvatarGroup>
                            </Stack>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() =>
                                history.push("/admin/edit-employee-external")
                              }
                            >
                              <EditIcon style={{ color: "1571DE" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>
    </div>
  );
}
