import React from "react";
import './ProductSettingsTabs.css'
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import ProductList from "./ProductList/ProductList";
import StockCard from "./StockCard/StockCard";

export default function ProductSettingsTabs() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="product__settings-tab">
      <TabContext value={value}>
        <Box sx={{ borderColor: "#1571DE" }}>
          <TabList onChange={handleChange}>
            <Tab
              label={<span className="text__tabs">Product List</span>}
              value="1"
            />
            <Tab
              label={<span className="text__tabs">Stock Card</span>}
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel value="1" className="product__tabs">
          <ProductList />
        </TabPanel>
        <TabPanel value="2" className="product__tabs">
          <StockCard />
        </TabPanel>
      </TabContext>
    </div>
  );
}
