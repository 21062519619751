import { Breadcrumbs } from "@mui/material";
import React from "react";
import { useHistory } from "react-router";
import ButtonRouteBack from "../../utils/ButtonRouteBack";
import Styles from "./AdminNavFMCG.module.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function AdminNavFMCG(props) {
  const { links } = props;
  const history = useHistory();

  const onClickBefore = (e, path) => {
    e.preventDefault();
    history.push(path);
  };
  return (
    <div className={Styles.Body}>
      <div className={Styles.Heading}>
        <ButtonRouteBack onClick={() => history.go(-1)} />
      </div>
      <div className={Styles.Content}>
        <Breadcrumbs
          separator={
            <NavigateNextIcon fontSize="medium" style={{ color: "#6F6F70" }} />
          }
          sx={{
            "& .MuiBreadcrumbs-li" : {
              cursor: 'default'
            }
          }}
        >
          {links.map((each, key) =>
            each.path ? (
              <a
                href="/"
                onClick={(e) => onClickBefore(e, each.path)}
                // replace={true.toString()}
                key={each}
                className={ Styles.Pathname }
              >
                {each.label}
              </a>
            ) : (
              <span key={each} className={ Styles.Label }>{each.label}</span>
            )
          )}
        </Breadcrumbs>
      </div>
    </div>
  );
}
