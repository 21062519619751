import * as types from '../../type'

const initialstate = {
  loanSchema: [],
  loadingLoanSchema: false,
  errorLoanSchema: {},
  loanSchemaById: {},
  onGoingLoan: [],
  loadingDataOngoingLoan: false,
  errorMessageOngoingLoan: {},
  loanHistory: [],
  loadingDataLoanHistory: false,
  errorMessageLoanHistory: {},
  loanDataByUserId: {},
  paymentLoanFromIos: [],
  // onGoingLoanByRangeDate: []
  historyPaymentByRangeDate: [],
  dataLoanReport: [],
  loadingDataReport: false,
  errorMessageReport: {}
};

const loanReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_SCHEMA_LOAN_BEGIN: {
      return {
        ...state,
        loadingLoanSchema: true
      };
    }
    case types.FETCH_ALL_SCHEMA_LOAN_SUCCESS: {
      return {
        ...state,
        loadingLoanSchema: false,
        loanSchema: action.payload,
      };
    }
    case types.FETCH_ALL_SCHEMA_LOAN_ERROR: {
      return {
        ...state,
        loadingLoanSchema: false,
        errorLoanSchema: action.payload
      };
    }
    case types.FETCH_SCHEMA_LOAN_BY_ID: {
      return {
        ...state,
        loanSchemaById: action.payload,
      };
    }
    case types.FETCH_ALL_ON_GOING_LOAN: {
      return {
        ...state,
        onGoingLoan: action.payload,
      };
    }
    case types.FETCH_ALL_APPROVED_LOAN_HISTORY: {
      return {
        ...state,
        loanHistory: action.payload,
      };
    }
    case types.FETCH_DATA_LOAN_BY_USER_ID: {
      return {
        ...state,
        loanDataByUserId: action.payload,
      };
    }
    case types.FETCH_PAYMENT_LOAN_FROM_IOS: {
      return {
        ...state,
        paymentLoanFromIos: action.payload,
      };
    }
    case types.FETCH_DATA_ONGOING_LOAN_BY_RANGE_DATE_BEGIN: {
      return {
        ...state,
        loadingDataOngoingLoan: true
      };
    }
    case types.FETCH_DATA_ONGOING_LOAN_BY_RANGE_DATE_SUCCESS: {
      return {
        ...state,
        loadingDataOngoingLoan: false,
        onGoingLoan: action.payload,
      };
    }
    case types.FETCH_DATA_ONGOING_LOAN_BY_RANGE_DATE_ERROR: {
      return {
        ...state,
        loadingDataOngoingLoan: false,
        errorMessageOngoingLoan: action.payload
      };
    }
    case types.FETCH_DATA_HISTORY_LOAN_BY_RANGE_DATE_BEGIN: {
      return {
        ...state,
        loadingDataLoanHistory: true
      };
    }
    case types.FETCH_DATA_HISTORY_LOAN_BY_RANGE_DATE_SUCCESS: {
      return {
        ...state,
        loadingDataLoanHistory: false,
        loanHistory: action.payload,
      };
    }
    case types.FETCH_DATA_HISTORY_LOAN_BY_RANGE_DATE_ERROR: {
      return {
        ...state,
        loadingDataLoanHistory: false,
        errorMessageLoanHistory: action.payload
      };
    }
    case types.FETCH_DATA_LOAN_REPORT_BEGIN: {
      return {
        ...state,
        loadingDataReport: true
      };
    }
    case types.FETCH_DATA_LOAN_REPORT_SUCCESS: {
      return {
        ...state,
        loadingDataReport: false,
        dataLoanReport: action.payload,
      };
    }
    case types.FETCH_DATA_LOAN_REPORT_ERROR: {
      return {
        ...state,
        loadingDataReport: false,
        errorMessageReport: action.payload
      };
    }
    default:
      return state;
  }
};

export default loanReducer;
