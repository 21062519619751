import React from "react";
import './index.css';

export default function MiniScreen() {
  return (
    <div className="container__mini-screen">
      <h1>
        Maaf, layar terlalu kecil mohon untuk membesarkan tinggi atau lebar
        website
      </h1>
    </div>
  );
}
