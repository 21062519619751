/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import "./SettingsLoanTabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import DataLoan from "../DataLoan/DataLoan";
import ApprovedHistory from "../ApprovedHistory/ApprovedHistory";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  fetchAllApprovedHistoryLoan,
  fetchAllOnGoingLoan,
  fetchDataOnGoingLoanByRangeDate,
  fetchPaymentLoanById,
} from "../../../../../redux/actions/SuperAdmin/loanActions";
import Gadai from "../Gadai/Gadai";

export default function SettingsLoanTabs() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const history = useHistory();
  const { id } = useParams();
  // const dataOnGoingLoan = useSelector((state) => state.loan.onGoingLoan);
  const dataApprovedHistory = useSelector((state) => state.loan.loanHistory);
  const [value, setValue] = React.useState("1");
  const [dropdownSelected, setDropdownSelected] = React.useState("All");
  const [dropdownSelectedStatus, setDropdownSelectedStatus] = React.useState("All");
  const [dropdownSelected2, setDropdownSelected2] = React.useState("All");
  const [dropdownSelectedStatus2, setDropdownSelectedStatus2] = React.useState("All");
  const [dropdownListGroup, setDropdownListGroup] = React.useState("All");
  const startDate = new Date(1655658000000);
  const endDate = new Date(1655658000000);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="container__tab-setting">
      <div className="wrapper__tab-setting">
        <TabContext value={value}>
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px  solid #E9E9E9",
            }}
          >
            <TabList onChange={handleChange}>
              <Tab
                label={<span className="text__tabs">Salary Advance</span>}
                value="1"
              />
              <Tab
                label={<span className="text__tabs">Gadai</span>}
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: 0 }}>
            <DataLoan
              dropdownSelected={dropdownSelected}
              setDropdownSelected={setDropdownSelected}
              dropdownSelectedStatus={dropdownSelectedStatus}
              setDropdownSelectedStatus={setDropdownSelectedStatus}
              dropdownListGroup={dropdownListGroup}
              setDropdownListGroup={setDropdownListGroup}
              // dataOnGoingLoan ={ dataOnGoingLoan}
            />
          </TabPanel>
          <TabPanel value="2" sx={{ padding: 0 }}>
            <Gadai />
            {/* <ApprovedHistory
              dropdownSelected2={dropdownSelected2}
              setDropdownSelected2={setDropdownSelected2}
              dropdownSelectedStatus2={dropdownSelectedStatus2}
              setDropdownSelectedStatus2={setDropdownSelectedStatus2}
              dropdownListGroup={dropdownListGroup}
              setDropdownListGroup={setDropdownListGroup}
              dataApprovedHistory={dataApprovedHistory}
            /> */}
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
}
