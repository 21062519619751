import axios from "axios";
import { useEffect, useState } from "react";
import {API} from "../../../redux/api";

function UseCompanyName() {
  const [companyName, setCompanyName] = useState([]);
  const token = localStorage.getItem('accessToken');
  useEffect(() => {
    axios
    .get(`${API}/client`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => setCompanyName(res.data.response))
      .catch((err) => console.log(err));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return companyName;
}

export default UseCompanyName;
