import axios from "axios";
import {API} from "../../redux/api";

const getClientInfo = (token, clientCode) =>
    new Promise(async (resolve, reject) => {
        try {
            const { data } = await axios.get(`${API}/client/work-on/${clientCode}`, {
                headers: {
                    Authorization: token
                }
            });
            const { response } = data;
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });

export default getClientInfo;