import React from "react";
import { useLocation } from "react-router";
import { EmployeeFormFMCG } from "../../../components/FMCG";

export default function EmployeesFormFMCG() {
  const location = useLocation();
  const addEmployeePath = location?.state?.location;
  return (
    <div>
      <EmployeeFormFMCG location={addEmployeePath}/>
    </div>
  );
}
