// import { FETCH_ALL_BPJS_DATA, FETCH_BPJS_DATA_BY_ID } from "../../type";
import * as types from "../../typeExternal";
const initialstate = {
  allBpjsExternal: [],
  bpjsByIdExternal: [],
  isLoadingBpjs: false,
  errorMessageBpjs: {},
};

const bpjsExternalReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_BPJS_DATA_EXTERNAL_BEGIN: {
      return {
        ...state,
        isLoadingBpjs: true,
      };
    }
    case types.FETCH_ALL_BPJS_DATA_EXTERNAL_SUCCESS: {
      return {
        ...state,
        allBpjsExternal: action.payload,
        isLoadingBpjs: false,
      };
    }
    case types.FETCH_ALL_BPJS_DATA_EXTERNAL_ERROR: {
      return {
        ...state,
        isLoadingBpjs: false,
        errorMessageBpjs: action.payload,
      };
    }
    case types.FETCH_BPJS_DATA_EXTERNAL_BY_ID: {
      return {
        ...state,
        bpjsByIdExternal: action.payload,
      };
    }
    default:
      return state;
  }
};

export default bpjsExternalReducer;
