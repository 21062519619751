/*eslint-disable no-unused-vars */

import React from "react";
import { Route, Switch } from "react-router-dom";
import AddEmployee from "../pages/External/Store/AddEmployee";
import AddProductsStore from "../pages/External/Store/AddProducts";
import AddStore from "../pages/External/Store/AddStore";
import DashboardExternal from "../pages/External/Dashboard/DashboardExternal";
import EditStore from "../pages/External/Store/EditStore";
import Store from "../pages/External/Store/Store";
import StoreDetails from "../pages/External/Store/StoreDetails";
import Product from '../pages/External/Product/Product';
import AddProducts from "../pages/External/Product/AddProducts";
import EditProducts from "../pages/External/Product/EditProducts";
import DetailsProduct from "../pages/External/Product/ProductDetails";
import AddStoreProducts from "../pages/External/Product/AddStoreProducts";
import StoreReport from "../pages/External/Store/StoreReport/StoreReport";
import Employee from "../pages/External/Employee/Employee";
import AddEmployeeExternal  from "../pages/External/Employee/AddEmployee";
import AddEmployeeManualExternal from '../pages/External/Employee/AddEmployeeExManual';
import Attendance from "../pages/External/Attendance/Attendance";
import AttendanceDetail from "../pages/External/Attendance/AttendanceDetail";
import DetailsEmployee from '../pages/External/Employee/EmployeeDetails';
import EditEmployeeExternal from '../pages/External/Employee/EditEmployee';
import { useRouteMatch } from "react-router";
import Customer from "../pages/External/Customer/Customer";
import CustomerDetail from "../pages/External/Customer/CustomerDetail";
import Information from "../pages/External/Information/InformationExternal";
import BpjsExternal from "../pages/External/Bpjs/BpjsExternal";
import PayslipExternal from "../pages/External/Payslip/PayslipExternal";
import PayslipPersonDetailExternal from "../pages/External/Payslip/PayslipPersonDetailExternal";
import LoanDataExternal from "../pages/External/LoanDataExternal/LoanDataExternal";
import LoanDataDetailExternal from "../pages/External/LoanDataDetailExternal/LoanDataDetailExternal";
import LocationExternal from "../pages/External/Location/LocationExternal";
// import AddLocationExternal from "../pages/External/Location/AddLocationExternal";
// import EditLocationExternal from "../pages/External/Location/EditLocationExternal";
import AddLocation from "../pages/External/Location/AddLocationExternal";
import EditLocation from "../pages/External/Location/EditLocationExternal";
import LocationDetail from "../pages/External/Location/LocationDetailExternal";
import ViewRequestExternal from "../pages/External/Employee/ViewRequest/ViewRequest";
import WorkOnTable from "../pages/External/WorkOn/TableWorkOn/TableWorkOn";
import TableWorkOn from "../pages/External/WorkOn/TableWorkOn/TableWorkOn";
import EmployeeAttendanceDetail from "../pages/External/Attendance/EmployeeAttendanceDetail";
import ActivitySA from "../pages/SuperAdmin/Activity/ActivitySA";
// import NotFound404 from "./_pageNotFound/NotFound404";
// import { UserContext } from "../redux/UserContext";

const RoutesLink = () => {
  let { path } = useRouteMatch();
  return (
    <div>
      {/* <UserContext.Provider value="Check Message"> */}
      <Switch>
        {/* DASHBOARD */}
        <Route exact path={`${path}`} component={DashboardExternal} />
        {/* DASHBOARD */}

        {/* STORE */}
        <Route path={`${path}/store-external`} component={Store} />
        <Route path={`${path}/add-store-external`} component={AddStore} />
        <Route path={`${path}/store-details`} component={StoreDetails} />
        <Route path={`${path}/edit-store-external`} component={EditStore} />
        <Route path={`${path}/add-products`} component={AddProductsStore} />
        {/* STORE */}

        {/* PRODUCTS */}
        <Route path={`${path}/product-external`} component={Product} />
        <Route path={`${path}/add-product-external`} component={AddProducts} />
        <Route path={`${path}/edit-product-external`} component={EditProducts} />
        <Route path={`${path}/details-product-external`} component={DetailsProduct} />
        <Route path={`${path}/add-store-product-external`} component={AddStoreProducts} />
        {/* PRODUCTS */}

        {/* CUSTOMER  */}
        <Route path={`${path}/customer-external`} component={Customer} />
        <Route path={`${path}/customer-detail`} component={CustomerDetail} />
        {/* CUSTOMER  */}

        {/* EMPLOYEE */}
        <Route path={`${path}/employee-external`} component={Employee} />
        <Route path={`${path}/add-employee`} component={AddEmployee} />
        <Route path={`${path}/add-employee-external`} component={AddEmployeeExternal} />
        <Route path={`${path}/add-employee-manual-external`} component={AddEmployeeManualExternal} />
        <Route path={`${path}/details-employee-external/:id`} component={DetailsEmployee} />
        <Route path={`${path}/edit-employee-external/:id`} component={EditEmployeeExternal} />
        <Route path={`${path}/view-request/:id`} component={ViewRequestExternal} />
        <Route path={`${path}/employee-attendance-details`} component={EmployeeAttendanceDetail} />


        {/* EMPLOYEE */}

        {/* ATTENDANCE  */}
        <Route path={`${path}/attendance-external`} component={Attendance} />
        <Route path={`${path}/attendance-details`} component={AttendanceDetail} />
        {/* ATTENDANCE  */}

        {/* WORK ON */}
        <Route path={`${path}/work-on-external`} component={TableWorkOn} />
        {/* WORK ON */}

        {/*  INFORMATION */}
        <Route path={`${path}/information-external`} component={Information} />
        {/*  INFORMATION */}

        {/* REPORT */}
        <Route path="/store-report-external/" component={StoreReport} />
        <Route path="/storeDetails-external" component={StoreDetails} />
        {/* REPORT */}

        {/* LOCATION */}
        <Route path={`${path}/location-external`} component={LocationExternal} />
        <Route path={`${path}/add-location-external`} component={AddLocation} />
        <Route path={`${path}/edit-location-external/:id`} component={EditLocation} />
        <Route path={`${path}/location-details/:id`} component={LocationDetail} />
        {/* LOCATION */}

        {/* BPJS */}
        <Route path={`${path}/bpjs-external`} component={BpjsExternal}/>
        {/* BPJS */}

        {/* PAYSLIP  */}
        <Route path={`${path}/payslip-external`} component={PayslipExternal}/>
        <Route path={`${path}/payslip-person-detail-external/:id`} component={PayslipPersonDetailExternal}/>
        {/* PAYSLIP  */}

        {/* LOAN */}
        <Route path={`${path}/loan-external`} component={LoanDataExternal}/>
        <Route path={`${path}/loan-data-details-external/:id`} component={LoanDataDetailExternal} />
        {/* LOAN */}

        {/* ACTIVITY */}
        <Route path={`${path}/activity-external`} component={ActivitySA}/>

        {/* ACTIVITY */}

        {/* <Route path="*" component={NotFound404} /> */}

      </Switch>
      {/* </UserContext.Provider> */}

    </div>
  );
};

export default RoutesLink;
