import React, { useEffect, useState } from "react";
import "./EmployeesDetailsFMCG.css";
import { useHistory } from "react-router";
import CompanyBG from "../../../../assets/images/Company-Background-Grey.png";
import EmployeeIcon from "../../../../assets/images/anas-2.png";
import AreaIcon from "../../../../assets/images/area-icon.png";
import PhoneIcon from "../../../../assets/images/phone-icon.png";
import WorkTimeIcon from "../../../../assets/images/worktime-icon.png";
import PositionIcon from "../../../../assets/images/position-icon.png";
import LevelIcon from "../../../../assets/images/level-icon.png";
import StoreIcon from "../../../../assets/images/store-icon.png";
import LeaderIcon from "../../../../assets/images/leader-icon.png";
import EditIcon from "../../../../assets/images/new-edit-icon.png";
import TrashIcon from "../../../../assets/images/trash-2.png";
import ModalDeleteFMCG from "../../ModalDeleteFMCG/ModalDeleteFMCG";
import AdminNavFMCG from "../../AdminNavFMCG/AdminNavFMCG";

const links = [
  {
    path: "/admin-fmcg/employees",
    label: "Employee",
  },
  {
    label: "Employee Details",
  },
];

const EmployeesDetailsFMCG = () => {
  const history = useHistory();
  const [openModalDelete, setOpenModalDelete] = useState(false);

  useEffect(() => {
    if (openModalDelete) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModalDelete]);

  return (
    <div className="ContainerEmployeesDetailsFMCG">
      <div className="WrapperEmployeesDetailsFMCG">
      <AdminNavFMCG links={links}></AdminNavFMCG>

        {/* <div className="HeadingEmployeesDetailFMCG">
          <div
            className="ButtonRouterBack"
            onClick={() => history.push("/admin-fmcg/employees")}
          >
            <IconButton>
              <ArrowBackIcon sx={{ color: "#202020", width: 24, height: 24 }} />
            </IconButton>
          </div>
          <div className="WrapperBreadCrumbs">
            <Breadcrumb>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextGrey">Employee</span>
              </Button>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextBlue">Employee Details</span>
              </Button>
            </Breadcrumb>
          </div>
        </div> */}

        <div className="ContentEmployeesDetailsFMCG">
          <img src={CompanyBG} alt="" />
          <div className="WrapperContentFloatEmloyeesDetailsFMCG">
            <div className="ContentStatusEmployeesDetailsFMCG">
              <img src={EmployeeIcon} alt="" />
              <div className="WrapperTextEmployeesDetailsFMCG">
                <span>Anastasia Salsabila</span>
                <span>Sales Promotion Girl</span>
              </div>
              <div className="BoxStatusEmployeesDetailsFMCG">
                <span>Active</span>
              </div>
            </div>
            <div className="ContentInfoEmployeesDetailsFMCG">
              <div className="WrapperItemsEmployeesDetailsFMCG">
                <div className="ItemsEmployeesDetailsFMCG">
                  <div className="ItemsEmployeesDetailsFMCGLeft">
                    <img src={AreaIcon} alt="" />
                    <span>Area</span>
                  </div>
                  <div className="ItemsEmployeesDetailsFMCGRight">
                    <span>Jakarta</span>
                  </div>
                </div>
                <div className="ItemsEmployeesDetailsFMCG">
                  <div className="ItemsEmployeesDetailsFMCGLeft">
                    <img src={PhoneIcon} alt="" />
                    <span>Phone</span>
                  </div>
                  <div className="ItemsEmployeesDetailsFMCGRight">
                    <span>+62 81262994188</span>
                  </div>
                </div>
                <div className="ItemsEmployeesDetailsFMCG">
                  <div className="ItemsEmployeesDetailsFMCGLeft">
                    <img src={WorkTimeIcon} alt="" />
                    <span>Phone</span>
                  </div>
                  <div className="ItemsEmployeesDetailsFMCGRight">
                    <span>09:00 - 18:00</span>
                  </div>
                </div>
                <div className="ItemsEmployeesDetailsFMCG">
                  <div className="ItemsEmployeesDetailsFMCGLeft">
                    <img src={PositionIcon} alt="" />
                    <span>Position</span>
                  </div>
                  <div className="ItemsEmployeesDetailsFMCGRight">
                    <span>Sales Promotion Girl</span>
                  </div>
                </div>
                <div className="ItemsEmployeesDetailsFMCG">
                  <div className="ItemsEmployeesDetailsFMCGLeft">
                    <img src={LevelIcon} alt="" />
                    <span>Level</span>
                  </div>
                  <div className="ItemsEmployeesDetailsFMCGRight">
                    <span>Staff</span>
                  </div>
                </div>
                <div className="ItemsEmployeesDetailsFMCG">
                  <div className="ItemsEmployeesDetailsFMCGLeft">
                    <img src={StoreIcon} alt="" />
                    <span>Store</span>
                  </div>
                  <div className="ItemsEmployeesDetailsFMCGRight">
                    <span>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Corrupti delectus veritatis ipsam magnam nemo nam
                      nesciunt, aliquid, doloribus iure maiores et atque.
                      Reprehenderit sit similique magni quisquam explicabo
                      recusandae quis!
                    </span>
                  </div>
                </div>
                <div className="ItemsEmployeesDetailsFMCG">
                  <div className="ItemsEmployeesDetailsFMCGLeft">
                    <img src={LeaderIcon} alt="" />
                    <span>Leader</span>
                  </div>
                  <div className="ItemsEmployeesDetailsFMCGRight">
                    <span>Alfin Wira Yuda</span>
                  </div>
                </div>
              </div>
              <div className="ButtonActionEmployeesDetailFMCG">
                <div className="WrapperIconAction">
                  <img
                    src={EditIcon}
                    alt="EditIcon"
                    onClick={() =>
                      history.push({
                        pathname: `/admin-fmcg/employees/edit/lkshdo1hdon1owh`,
                        location: "editEmployee",
                      })
                    }
                  />
                </div>
                <div className="WrapperIconAction">
                  <img
                    src={TrashIcon}
                    alt="TrashIcon"
                    onClick={() => setOpenModalDelete(true)}
                    style={{ width: 20, height: 20 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModalDelete && (
        <ModalDeleteFMCG
          modalDelete={openModalDelete}
          setModalDelete={setOpenModalDelete}
        />
      )}
    </div>
  );
};

export default EmployeesDetailsFMCG;
