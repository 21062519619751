import React from "react";
import Lottie from "lottie-react";
import loadingFMCG from "../../../assets/JsonData/LoadinfFMCG.json";

export default function LoadingFMCG({ height, width }) {
  return (
    <Lottie
      animationData={loadingFMCG}
      style={{ width: width, height: height }}
      loop
      autoplay
    />
  );
}
