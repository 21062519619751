/* eslint-disable no-unused-vars */
import { Button, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { DetailsEmployeeExternal } from "../../../components/External/Employee";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
import {
  fetchAllEmployeesExternal,
  fetchEmployeeExternalById,
} from "../../../redux/actions/External/employeesExternalActions";
import { fetchAllEmployeeById } from "../../../redux/actions/SuperAdmin/employeesActions";

export default function EmployeeDetails() {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const employeeInfo = location?.state?.data;
  const isSpv = localStorage.getItem("isSPV");
  const { id } = useParams();
  const { employeeByIdExternal } = useSelector(
    (state) => state.employeesExternal
  );
  const [dataEmployee, setDataEmployee] = useState();

  useEffect(() => {
    dispatch(
      fetchEmployeeExternalById(
        token,
        id,
        `clientCode=${employeeInfo?.clientCode}`
      )
    );
  }, [dispatch, employeeInfo?.clientCode, id, token]);

  useEffect(() => {
    setDataEmployee(employeeByIdExternal);
  }, [employeeByIdExternal]);

  if (!employeeByIdExternal) {
    return null;
  }

  return (
    <Container maxWidth="xl">
      <div className="">
        <Helmet>
          <title>MyWorkSpace | Employee Details</title>
        </Helmet>
        <div className="">
          <Breadcrumb>
            <Button
              onClick={() =>
                history.push(
                  isSpv ? "/admin-spv/employee" : "/admin/employee-external"
                )
              }
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Employee</span>
            </Button>
            <span
              style={{
                fontSize: 20,
                color: "#255bfc",
                textTransform: "capitalize",
              }}
            >
              {employeeInfo?.name?.toLowerCase() ||
                employeeInfo?.user?.name?.toLowerCase()}
            </span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <DetailsEmployeeExternal
              employeeId={id}
              employee={employeeByIdExternal}
              employeeInfo={employeeInfo}
              setData={setDataEmployee}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
