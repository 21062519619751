import * as types from "../../typeExternal";

const initialstate = {
  allLocationsExternal: [],
  isLoading: false,
  errorMessage: {},
  locationById: {},
  isLoadingLocationById: false,
  errorMessageLocationById: {},
  userData: [],
  isLoadingUser: false,
  errorMessageUser: {},
  locationNotAssigned : {},
  isLoadingNotAssigned: false,
  errorMessageNotAssigned: {}
};

const locationsExternalReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_LOCATION_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.FETCH_ALL_LOCATION_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          allLocationsExternal: action.payload
        };
    }
    case types.FETCH_ALL_LOCATION_ERROR: {
        return {
          ...state,
          isLoading: false,
          errorMessage: action.payload
        };
    }
    case types.GET_LOCATION_BY_ID_BEGIN: {
      return {
        ...state,
        isLoadingLocationById: true
      };
    }
    case types.GET_LOCATION_BY_ID_SUCCESS: {
      return {
        ...state,
        isLoadingLocationById: false,
        locationById: action.payload
      };
    }
    case types.GET_LOCATION_BY_ID_ERROR: {
      return {
        ...state,
        isLoadingLocationById: false,
        errorMessageLocationById: action.payload
      };
    }
    case types.FETCH_USER_BY_LOCATION_BEGIN: {
      return {
        ...state,
        isLoadingUser: true
      }
    }
    case types.FETCH_USER_BY_LOCATION_SUCCESS: {
      return {
        ...state,
        isLoadingUser: false,
        userData: action.payload
      }
    }
    case types.FETCH_USER_BY_LOCATION_ERROR: {
      return {
        ...state,
        isLoadingUser: false,
        errorMessageUser: action.payload
      }
    }
    case types.FETCH_LOCATION_USER_NOT_ASSIGNED_BEGIN: {
      return {
        ...state,
        isLoadingNotAssigned: true
      }
    }
    case types.FETCH_LOCATION_USER_NOT_ASSIGNED_SUCCESS: {
      return {
        ...state,
        isLoadingNotAssigned: false,
        locationNotAssigned: action.payload
      }
    }
    case types.FETCH_LOCATION_USER_NOT_ASSIGNED_ERROR: {
      return {
        ...state,
        isLoadingNotAssigned: false,
        errorMessageNotAssigned: action.payload
      }
    }
    // case types.FETCH_LOCATION_BY_ID_BEGIN : {
    //     return {
    //         ...state,
    //         isLoading: true
    //     }
    // }
    // case types.FETCH_LOCATION_BY_ID_SUCCESS : {
    //     return {
    //         ...state,
    //         isLoading: false,
    //         locationById: action.payload
    //     }
    // }
    // case types.FETCH_LOCATION_BY_ID_ERROR : {
    //     return {
    //         ...state,
    //         isLoading: false,
    //         errorMessage: action.payload
    //     }
    // }
    // case types.FETCH_EMPLOYEE_EXTERNAL_BEGIN: {
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // }
    // case types.FETCH_EMPLOYEE_EXTERNAL_SUCCESS: {
    //   return {
    //     ...state,
    //     employeesExternal: action.payload,
    //     isLoading: false,
    //   };
    // }
    // case types.FETCH_EMPLOYEE_EXTERNAL_ERROR: {
    //   return {
    //     ...state,
    //     errorMessage: action.payload,
    //     isLoading: false,
    //   };
    // }
    // case types.FETCH_EMPLOYEE_EXTERNAL_BY_ID: {
    //   return {
    //     ...state,
    //     employeeByIdExternal: {...state.employeeByIdExternal, [action.payload._id]: action.payload}
    //     employeeByIdExternal: action.payload,
    //   };
    // }
    default:
      return state;
  }
};

export default locationsExternalReducer;
