/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Styles from "./DetailsEmployeeExternal.module.css";
// import "./index.css";
import logo_bpjs from "../../../../assets/images/bpjs-logo.png";
import logo_file from "../../../../assets/images/File.png";
import logo_receipt from "../../../../assets/images/Receipt.png";
import logo_bpjs_modal from "../../../../assets/images/bpjs.png";
import logo_photo_modal from "../../../../assets/images/pas-photo.png";
import logo_ktp_modal from "../../../../assets/images/ktp.png";
import logo_contract_modal from "../../../../assets/images/contract.png";
import detail_employee_image from "../../../../assets/images/employee-details.png";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import permata_image from "../../../../assets/images/logo-permata.png";
import EDIT_ICON from "../../../../assets/images/create.png";
import FILE_ICON from "../../../../assets/images/file-icon.png";
import CALENDAR_ICON from "../../../../assets/images/calendar-employee.png";
import CALENDAR_LEAVE_ICON from "../../../../assets/images/calendar-leave.png";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import ModalDeleteEmployee from "../ModalDeleteEmployee/ModalDeleteEmployee";
import logo_bm from "../../../../assets/images/bm-pict-big.png";
// import store_image from "../../../../assets/images/images-store.jpg";
import { useHistory } from "react-router";
import ModalAddEmployee from "../../../SuperAdmin/_employee/ProfileEmployeeMenu/ModalAddEmployee/ModalAddEmployee";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import YearPicker from "../../../utils/YearPicker/YearPicker";
import ModalEditSupervisor from "../../../SuperAdmin/_employee/ProfileEmployeeMenu/ModalEditSupervisor";
import ModalEditApprover from "../../../SuperAdmin/_employee/ProfileEmployeeMenu/ModalEditApprover";
import ModalViewFile from "../../../SuperAdmin/_employee/ProfileEmployeeMenu/ModalViewFile";
import ModalEpaySlip from "../../../SuperAdmin/_employee/ProfileEmployeeMenu/ModalEpaySlip";
import { useCalendar } from "../../../utils/hooks";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { useDispatch } from "react-redux";
import {
  fetchAllEmployeeById,
  fetchAllEmployees,
} from "../../../../redux/actions/SuperAdmin/employeesActions";
import fetchSupervisor from "../../../../async/supervisor/fetchSupervisor";
import getEditStatus from "../../../../async/editStatus/getEditStatus";
import ModalBPJS from "../../../SuperAdmin/_bpjs/BPJSMenu/ModalBPJS/ModalBPJS";
import moment from "moment";
import { Cancel, CheckCircle } from "@mui/icons-material";
import getClientNameExternal from "../../../../async/client/getClientNameExt";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import fetchLeaveHistory from "../../../../async/employee/fetchLeaveHistory";
import ModalAddLeave from "../../../SuperAdmin/_employee/ProfileEmployeeMenu/ModalAddLeave/ModalAddLeave";
import addLeaveHistory from "../../../../async/employee/addLeaveHistory";
import ModalSuccessEmployee from "../../../SuperAdmin/_employee/ProfileEmployeeMenu/ModalSuccessEmployee/ModalSuccessEmployee";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className={Styles.Description}>
      {/* { isReadMore && text.length < 20 ? text?.slice(0, 50) : text } */}
      {isReadMore ? text.slice(0, 50) : text}
      <span
        onClick={toggleReadMore}
        className={`${text.length > 50 ? Styles.Read : Styles.Hide}`}
      >
        {/* {isReadMore ? "... Selengkapnya" : "... Lebih Sedikit"} */}
        {isReadMore && "... Selengkapnya"}
      </span>
    </p>
  );
};

export default function DetailsEmployeeExternal({
  employee,
  employeeId,
  setData,
}) {
  let clientCode = sessionStorage.getItem("selectedClientCodeExternal") || localStorage.getItem("clientCode");
  const calendar = useCalendar();
  const history = useHistory();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [workTime, setWorkTime] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [deleteEmployee, setDeleteEmployee] = useState(false); // change to useState({ }) for contain the id after fetching data
  // eslint-disable-next-line no-unused-vars
  const [showStore, setShowStore] = useState(false);
  const [changeLeader, setChangeLeader] = useState(false);
  const [file, setFile] = useState(null);
  const [ePaySlip, setEPaySlip] = useState(null);
  // const [viewFile, setViewFile] = useState(false);
  const [changeSupervisor, setChangeSupervisor] = useState(false);
  const [changeApprover, setChangeApprover] = useState(false);
  // const [dataPayslip, setDataPayslip] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const d = new Date();
  const [selectedYear, setSelectedYear] = useState(d.getFullYear().toString());
  const year = String(selectedYear);
  // const year = String(selectedYear)?.split(" ")[3];
  const [fixedYear, setFixedYear] = useState(year);
  const [editStatus, setEditStatus] = useState(null);

  const [bpjsOpen, setBpjsOpen] = useState(false);

  const [ktp, setKTP] = useState(false);
  const [contract, setContract] = useState(false);

  const isSpv = localStorage.getItem("isSPV");
  const [clientName, setClientName] = useState("");

  const [isErrorSetLeave, setIsErrorSetLeave] = useState();
  const [isErrorAddLeave, setIsErrorAddLeave] = useState();
  const [loading, setLoading] = useState(false);
  const [leaveModal, setLeaveModal] = useState(false);
  const [modalSuccess, setIsModalSuccess] = useState(false);
  const [addLeaveModal, setAddLeaveModal] = useState(false);
  const [leaveHistory, setLeaveHistory] = useState([]);
  const [valueAddLeave, setValueAddLeave] = useState({
    totalLeave: null,
    description: "",
  });
  const [leaveHistoryYear, setLeaveHistoryYear] = useState(2024);

  const [limit, setLimit] = useState(8);

  let employeeInfo = employee[0];

  useEffect(() => {
    (async () => {
      try {
        const name = await getClientNameExternal(
          token,
          employeeInfo?.clientCode
        );
        setClientName(name);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeInfo]);

  useEffect(() => {
    // setDataPayslip(payslip ? payslip[0]?.Epayslips : '');
    setEmployeeName(employeeInfo?.SPV ? employeeInfo?.SPV[0]?.SPV_1?.Name : "");
    // setFixedYear(String(selectedYear)?.split(" ")[3]);
    setFixedYear(String(selectedYear));
  }, [employeeInfo, selectedYear]);

  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    dispatch(
      fetchAllEmployees(token, `clientCode=${employeeInfo?.clientCode}`)
    );
  }, [dispatch, token, employeeInfo?.clientCode]);

  function truncate(str) {
    return str?.split(" ")?.splice(0, 2)?.join(" ");
  }

  useEffect(() => {
    (async () => {
      try {
        const { profileChange } = await getEditStatus(token, employeeId);
        setEditStatus(profileChange);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [employeeId, token]);

  useEffect(() => {
    let documents = employeeInfo?.document;
    let ktptemp = documents?.filter((doc) => doc.docType === "KTP");
    let contractTemp = documents?.filter((doc) => doc.docType === "KONTRAK");
    setKTP(ktptemp);
    setContract(contractTemp);
  }, [employeeInfo?.document]);

  useEffect(() => {
    const refetchData = async () => {
      try {
        setLoading(true);
        const data = await fetchLeaveHistory(
          token,
          employeeId,
          clientCode,
          leaveHistoryYear
        );
        setLeaveHistory(data?.response);
        // console.log(data.response, "datas");
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    refetchData();
  }, [clientCode, employeeId, leaveHistoryYear, token]);

  const handleGetData = async () => {
    try {
      setLoading(true);
      const data = await fetchLeaveHistory(
        token,
        employeeId,
        clientCode,
        leaveHistoryYear
      );
      setLeaveHistory(data?.response);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenContract = (e) => {
    e.preventDefault();
    if (contract.length > 0) {
      window.open(contract[0]?.url, "_blank");
    } else {
      setFile("");
    }
  };

  const handleSetLeave = () => {
    setLeaveModal(true);
  };

  const handleAddLeave = () => {
    setAddLeaveModal(true);
  };

  const onSetLeave = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        balance: `${valueAddLeave?.totalLeave}`,
        employeeId: employeeId,
        description: valueAddLeave?.description,
        clientCode: clientCode,
      };
      // const data = defaultTotal;
      // const data = await addLeaveHistory(token, payload);
      const query = `leave-additional/set-leave`;
      const data = await addLeaveHistory(token, payload, query);
      dispatch(
        fetchAllEmployeeById(
          token,
          employeeId,
          `clientCode=${employeeInfo?.clientCode}`
        )
      );
      setData((prev) => ({
        ...prev,
        leaveDetail: {
          initialBalance: employeeInfo?.leaveDetail?.initialBalance,
          remain: employeeInfo?.leaveDetail?.remain,
        },
      }));
      setLeaveModal(false);
      await new Promise((resolve) => resolve(data));
      setIsModalSuccess(true);
      await new Promise((resolve) => setTimeout(() => resolve(true), 1500));
      handleGetData();
      setValueAddLeave({
        totalLeave: null,
        description: "",
      });
    } catch (error) {
      console.log(error);
      if (
        error.response.data.error ===
        "[REQUEST ERROR] LeavePlan in client not found"
      ) {
        setIsErrorSetLeave(
          "Mohon maaf, harap hubungi admin untuk set up skema pada client anda."
        );
      }
    }
  };

  const onAddLeave = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        balance: `${valueAddLeave?.totalLeave}`,
        employeeId: employeeId,
        description: valueAddLeave?.description,
        clientCode: clientCode,
      };
      const query = `leave-additional/bonus`;
      const data = await addLeaveHistory(token, payload, query);
      dispatch(
        fetchAllEmployeeById(
          token,
          employeeId,
          `clientCode=${employeeInfo?.clientCode}`
        )
      );
      setData((prev) => ({
        ...prev,
        leaveDetail: {
          initialBalance: employeeInfo?.leaveDetail?.initialBalance,
          remain: employeeInfo?.leaveDetail?.remain,
        },
      }));
      setAddLeaveModal(false);
      await new Promise((resolve) => resolve(data));
      setIsModalSuccess(true);
      await new Promise((resolve) => setTimeout(() => resolve(true), 1500));
      handleGetData();
      setValueAddLeave({
        totalLeave: null,
        description: "",
      });
    } catch (error) {
      console.log(error.response.data.error);
      if (
        error.response.data.error ===
        "[REQUEST ERROR] LeavePlan in client not found"
      ) {
        setIsErrorAddLeave(
          "Mohon maaf, harap hubungi admin untuk set up skema pada client anda."
        );
      }
    }
  };

  let IDR = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = (value) => {
    setLeaveHistoryYear(value);
    setAnchorEl(null);
  };

  const yearsOption = Array.from(
    { length: 4 },
    (_, index) => new Date().getFullYear() - index
  );

  const handleSelectedYearEpayslip = (value) => {
    setSelectedYear(value);
    setAnchorEl(null);
  };


  return (
    <div className={Styles.Container}>
      <div className={Styles.Wrapper}>
        <div className={Styles.Left}>
          <Avatar
            src={
              employeeInfo?.profilePicture !== ""
                ? employeeInfo?.profilePicture
                : ""
            }
            className={Styles.MainAvatar}
          />
          <Link
            to={{
              pathname: `${isSpv ? "/admin-spv" : "/admin"}/edit-employee-external/${employeeInfo?.employeeId}`,
              state: { data: employeeInfo },
            }}
          >
            <Button className={Styles.Edit}>
              <span>Edit Details</span>
              <img src={EDIT_ICON} alt="edit" />
            </Button>
          </Link>
        </div>
        <div className={Styles.Right}>
          <div className={Styles.ID}>
            <div className={Styles.IDHeader}>
              <span className={Styles.Capitalized}>
                {employeeInfo?.user?.name?.toLowerCase()}
              </span>
              <div className={Styles.RequestWrapper}>
                <Link
                  to={{
                    pathname: `${isSpv ? "/admin-spv" : "/admin"}/view-request/${employeeInfo?.employeeId}`,
                    state: {
                      currentData: employeeInfo?.user,
                      requestData: editStatus?.newUpdate,
                      data: employeeInfo,
                    },
                  }}
                >
                  <span hidden={!editStatus}>View Request</span>
                </Link>
                <span
                  className={
                    editStatus !== null ? Styles.Pending : Styles.NoRequest
                  }
                >
                  {editStatus === null ? "No Request" : "Request Pending"}
                </span>
              </div>
            </div>
            <hr />
            <div className={Styles.Content}>
              <div className={Styles.BoxContent}>
                <span>NRK</span>
                <span>{employeeInfo?.user?.employeeId}</span>
              </div>
              <div className={Styles.BoxContent}>
                <span>KTP</span>
                <span>
                  {employeeInfo?.user?.idNumber
                    ? employeeInfo.user.idNumber
                    : "-"}
                </span>
              </div>
              <div className={Styles.BoxContent}>
                <span>NPWP</span>
                <span>
                  {employeeInfo?.user?.taxId ? employeeInfo.user.taxId : "-"}
                </span>
              </div>
              <div className={Styles.BoxContent}>
                <span>Email</span>
                <span>
                  <Link
                    to="#"
                    onClick={(e) => {
                      window.location.href = `mailto:${employeeInfo?.user?.email}`;
                      e.preventDefault();
                    }}
                  >
                    {employeeInfo?.user?.email
                      ? employeeInfo?.user?.email
                      : "-"}
                  </Link>
                </span>
              </div>
              <div className={Styles.BoxContent}>
                <span>Phone Number</span>
                <span>
                  {employeeInfo?.user?.phoneNumber
                    ? employeeInfo.user.phoneNumber
                    : "-"}
                </span>
              </div>
              <div className={Styles.BoxContent}>
                <span>Date of Birth</span>
                <span>
                  {employeeInfo?.user?.birthDate
                    ? employeeInfo?.user?.birthDate
                    : "-"}
                </span>
              </div>
              <div className={Styles.BoxContent}>
                <span>Marital Status</span>
                <span>
                  {employeeInfo?.user?.maritalStatus === "TK"
                    ? "Single"
                    : "Married"}
                </span>
              </div>
              <div className={Styles.BoxContent}>
                <span>Bank Name</span>
                <span>
                  {employeeInfo?.user?.account?.bankName
                    ? employeeInfo.user?.account?.bankName
                    : "-"}
                </span>
              </div>
              <div className={Styles.BoxContent}>
                <span>Bank Account Number</span>
                <span>{employeeInfo?.user?.account?.number ? employeeInfo.user?.account?.number : '-'}</span>
              </div>
            </div>
          </div>
          <div className={Styles.AnnualLeave}>
            <div className={Styles.Setter}>
              <div className={Styles.AnnualLeaveInfo}>
                <div className={Styles.SectionText}>
                  <p>Leave • {leaveHistoryYear}</p>
                  <IconButton
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <CalendarTodayIcon/>
                  </IconButton>
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {yearsOption.map((el, idx) => (
                    <MenuItem onClick={() => handleClickMenuItem(el)} key={idx}>
                      {el}
                    </MenuItem>
                  ))}
                </Menu>
                <div className={Styles.LeaveBox}>
                  <span>Jatah Cuti</span>
                  <span>
                    {employeeInfo?.leaveDetail?.initialBalance
                      ? employeeInfo?.leaveDetail?.initialBalance
                      : "0"}
                  </span>
                </div>
                <div className={Styles.LeaveBox}>
                  <span>Sisa Cuti</span>
                  <span>
                    {employeeInfo?.leaveDetail?.remain
                      ? employeeInfo?.leaveDetail?.remain
                      : "0"}
                  </span>
                </div>
              </div>
              {/* {leaveHistory?.length > 0 ? ( */}
              <div className={Styles.ButtonWrapper}>
                <div className={Styles.ButtonAction}>
                  <Button
                    startIcon={<ModeEditIcon sx={{ fontSize: 20 }} />}
                    onClick={handleSetLeave}
                  >
                    Atur Cuti Sebelumnya
                  </Button>
                </div>
                <div className={Styles.ButtonAction}>
                  <Button
                    startIcon={<AddCircleOutlineIcon sx={{ fontSize: 20 }} />}
                    onClick={handleAddLeave}
                  >
                    Tambah Cuti
                  </Button>
                </div>
              </div>
            </div>

            <div className={Styles.AnnualLeaveContent}>
              {leaveHistory?.length > 0 ? (
                <div className={Styles.HaveAnnual}>
                  {leaveHistory.map((item, index) => (
                    <div className={Styles.ContainerEachAnnual} key={index}>
                      <div className={Styles.EachAnnual}>
                        <div className={Styles.Icon}>
                          <img
                            src={CALENDAR_LEAVE_ICON}
                            alt="calendar-employee"
                          />
                        </div>
                        <div className={Styles.ValueLeave}>
                          <div className={Styles.TitleContainer}>
                            <div className={Styles.Title}>
                              <div className={Styles.TextTitle}>
                                {item?.title}
                              </div>
                              <div
                                className={`${
                                  item.balance?.toString().includes("-")
                                    ? Styles.TotalBox
                                    : Styles.TotalBoxDefault
                                }`}
                              >
                                {item?.balance} hari
                              </div>
                            </div>
                            <div className={Styles.CreatedAt}>
                              {moment(item.createdAt).format("DD MMMM YYYY")}
                            </div>
                          </div>
                          <div className={Styles.Date}>
                            <span>
                              {/* Tanggal Cuti:{" "}{`${moment(item.startTime).format("DD MMM YYYY")} ${moment(item.endTime).format("DD MMM YYYY")? "-": ""} ${moment(item.endTime).format("DD MMM YYYY")}`}{" "} */}
                              {item?.startTime && item?.endTime ? (
                                <>
                                  Tanggal Cuti:{" "}
                                  {`${moment(item.startTime).format(
                                    "DD MMM YYYY"
                                  )} ${
                                    moment(item.endTime).format("DD MMM YYYY")
                                      ? "-"
                                      : ""
                                  } ${moment(item.endTime).format(
                                    "DD MMM YYYY"
                                  )}`}{" "}
                                </>
                              ) : null}
                              {/* <b>
                                •{" "}
                                {`${
                                  item.balance?.toString().includes("-")
                                    ? item.balance?.toString().replace("-", "")
                                    : item.balance
                                }`}{" "}
                                hari
                              </b> */}
                            </span>
                          </div>
                          {item?.description ? (
                            <div>
                              <ReadMore>{`Deskripsi : ${item.description}`}</ReadMore>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={Styles.WithoutAnnual}>
                  <img src={CALENDAR_ICON} alt="calendar" />
                  <span>Belum Ada Riwayat Cuti</span>
                </div>
              )}
            </div>
          </div>
          <div className={Styles.Files}>
            <span>Files</span>
            <div className={Styles.CardContainer}>
              <div
                className={`${Styles.Card} ${Styles.Pointer}`}
                onClick={() => (ktp?.length > 0 ? setFile(ktp) : setFile(""))}
              >
                <img src={FILE_ICON} alt="file" />
                <span>ID Card</span>
              </div>
              <div
                className={`${Styles.Card} ${Styles.Pointer}`}
                onClick={() => {
                  if (employeeInfo?.bpjs?.length > 0) {
                    setBpjsOpen(true);
                  } else {
                    setFile("");
                  }
                }}
              >
                <img src={FILE_ICON} alt="file" />
                <span>BPJS</span>
              </div>
              <div
                className={`${Styles.Card} ${Styles.Pointer}`}
                onClick={handleOpenContract}
              >
                <img src={FILE_ICON} alt="file" />
                <span>Contract</span>
              </div>
            </div>
          </div>
          {employeeInfo?.staffLoan?._id && (
            <>
              <div className={Styles.LoanInfo}>
                <div className={Styles.LoanInfoHeader}>
                  <span>Loan Information</span>
                  <span
                    className={`${Styles.LoanStatus} ${
                      employeeInfo?.staffLoan?.status === "PENDING" &&
                      Styles.Pending
                    } ${
                      employeeInfo?.staffLoan?.status === "ACTIVE" &&
                      Styles.Success
                    }`}
                  >
                    {employeeInfo?.staffLoan?.status?.toLowerCase()}
                  </span>
                </div>
                <div className={Styles.LoanInfoCard}>
                  <div className={Styles.LICHeader}>
                    <div className={Styles.LICHeaderLeft}>
                      <span>Employee Benefit Program</span>
                      <span>Salary Advance</span>
                      <span>
                        {employeeInfo?.division} - {clientName}
                      </span>
                    </div>
                    <div className={Styles.LICHeaderRight}>
                      <img src={permata_image} alt="" />
                    </div>
                  </div>
                  <div className={Styles.LICContent}>
                    <div className={Styles.LICBoxContent}>
                      <div className={Styles.LICBLeft}>
                        <span>Tanggal Pengajuan</span>
                        <span>
                          {moment(employeeInfo?.staffLoan?.applyDate).format(
                            "LL"
                          )}
                        </span>
                      </div>
                      <div className={Styles.LICBRight}>
                        <span>Jumlah Pengajuan</span>
                        <span>
                          {IDR.format(employeeInfo?.staffLoan?.amount?.apply)}
                        </span>
                      </div>
                    </div>
                    <div className={Styles.LICBoxContent}>
                      <div className={Styles.LICBLeft}>
                        <span>Angsuran Bulanan</span>
                        <span>
                          {IDR.format(
                            employeeInfo?.staffLoan?.amount?.installment
                          )}
                        </span>
                      </div>
                      <div className={Styles.LICBRight}>
                        <span>Biaya Admin dan Transfer</span>
                        <span>
                          {IDR.format(
                            employeeInfo?.staffLoan?.amount?.adminFee?.total
                          )}
                        </span>
                      </div>
                    </div>
                    <div className={Styles.LICBoxContent}>
                      <div className={Styles.LICBLeft}>
                        <span>Tenor</span>
                        <span>{employeeInfo?.staffLoan?.tenor} Bulan</span>
                      </div>
                      <div className={Styles.LICBRight}>
                        <span>Jumlah yang Dicairkan</span>
                        <span>
                          {IDR.format(
                            employeeInfo?.staffLoan?.amount?.disburse
                          )}
                        </span>
                      </div>
                    </div>
                    {employeeInfo?.staffLoan?.status !== "PENDING" && (
                      <div className={Styles.LICBoxContent}>
                        <div className={Styles.LICBLeft}>
                          <span>Tanggal Dicairkan</span>
                          <span>
                            {moment(employeeInfo?.staffLoan?.applyDate).format(
                              "LL"
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {employeeInfo?.staffLoan?.status !== "PENDING" && (
                <div className={Styles.InstallmentInfo}>
                  <span>Payment History</span>
                  <div className={Styles.InstallmentCardsContainer}>
                    {employeeInfo?.staffLoan?.installments?.map((each, i) => {
                      return (
                        <div className={Styles.InstallmentCard}>
                          <span>{moment(each?.dueDate).format("LL")}</span>
                          <span>{IDR.format(each?.total)}</span>
                          {each?.paidDate ? (
                            <div
                              className={`${Styles.StatusPayment} ${Styles.Paid}`}
                            >
                              <CheckCircle />
                              <span>Sudah Dibayar</span>
                            </div>
                          ) : (
                            <div
                              className={`${Styles.StatusPayment} ${Styles.Unpaid}`}
                            >
                              <Cancel />
                              <span>Belum Dibayar</span>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          )}
          <div className={Styles.EpaySlips}>
            <div className={Styles.EpaySlipsHeader}>
              <span>ePay Slip History</span>
              <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <CalendarTodayIcon/>
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {yearsOption.map((el, idx) => (
                  <MenuItem onClick={() => handleSelectedYearEpayslip(el)} key={idx}>
                    {el}
                  </MenuItem>
                ))}
              </Menu>
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={["year"]}
                  value={selectedYear}
                  onChange={(newValue) => {
                    setSelectedYear(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={null}
                      size="small"
                      style={{ width: 115 }}
                    />
                  )}
                />
              </LocalizationProvider> */}
            </div>

            {employeeInfo?.paymentSlips?.filter((el) => el?.year === fixedYear)
              .length === 0 && (
              <span className={Styles.NoSlip}>
                no Epay Slip history for year {fixedYear}
              </span>
            )}

            <div className={Styles.CardContainer}>
              {employeeInfo?.paymentSlips
                ?.filter((el) => el?.year === fixedYear)
                .sort((a, b) => (a.month > b.month ? 1 : -1))
                .map((each, i) => {
                  let month =
                    calendar.months.eng.full[each?.month.split(" ")[0] - 1];
                  //           let year = el.PayrollMonth.split(" ")[2];
                  return (
                    <div
                      key={i}
                      className={`${Styles.Card} ${Styles.Pointer}`}
                      onClick={() => setEPaySlip(each)}
                    >
                      <img src={FILE_ICON} alt="file" />
                      <span>
                        {month} {each?.year}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className={Styles.Teams}>
            <span>My Team</span>
            <div className={Styles.OrganizersWrapper}>
              <div className={Styles.Organizers}>
                <div className={Styles.TeamsCardWrapper}>
                  <div className={Styles.TeamsSubheader}>
                    <span>Supervisor</span>
                    <img
                      onClick={() => setChangeSupervisor(true)}
                      src={EDIT_ICON}
                      alt="edit spv"
                    />
                  </div>
                  <div className={Styles.TeamsCard}>
                    <Avatar
                      className={Styles.TeamsCardImg}
                      src={employeeInfo?.supervisor?.profilePicture || ""}
                    />
                    <span className={Styles.Capitalized}>
                      {employeeInfo?.supervisor?.name
                        ? truncate(
                            employeeInfo?.supervisor?.name?.toLowerCase()
                          )
                        : "not assigned yet"}
                    </span>
                    <span>
                      {employeeInfo?.supervisor?.phoneNumber
                        ? `Phone : ${employeeInfo?.supervisor?.phoneNumber}`
                        : `NRK : ${employeeInfo?.supervisor?.employeeId}`}
                    </span>
                  </div>
                </div>
                <div className={Styles.TeamsCardWrapper}>
                  <div className={Styles.TeamsSubheader}>
                    <span>Approver</span>
                    {/* <img onClick={() => setChangeApprover(true)} src={EDIT_ICON} alt="edit spv" /> */}
                  </div>
                  <div className={Styles.TeamsCard}>
                    <Avatar
                      className={Styles.TeamsCardImg}
                      src={employeeInfo?.loanApprover?.profilePicture || ""}
                    />
                    <span className={Styles.Capitalized}>
                      {employeeInfo?.loanApprovers?.length !== 0
                        ? truncate(
                            employeeInfo?.loanApprovers[0]?.name?.toLowerCase()
                          )
                        : "not assigned yet"}
                    </span>
                    <span>
                      NRK :{" "}
                      {employeeInfo?.loanApprovers?.length !== 0
                        ? employeeInfo?.loanApprovers[0]?.employeeId
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {employeeInfo?.subordinates?.length !== 0 && (
              <div className={Styles.SubordinatesWrapper}>
                <div className={Styles.SubordinatesSubheader}>
                  <span>Staff</span>
                </div>
                <div className={Styles.Subordinates}>
                  {employeeInfo?.subordinates
                    ?.slice(0, limit)
                    ?.map((each, i) => (
                      <div key={i} className={Styles.TeamsCard}>
                        <Avatar
                          className={Styles.TeamsCardImg}
                          src={each?.user?.profilePicture || ""}
                        />
                        <span className={Styles.Capitalized}>
                          {truncate(each?.user?.name?.toLowerCase())}
                        </span>
                        <span>{each?.jobTitle}</span>
                      </div>
                    ))}
                </div>
                {employeeInfo?.subordinates?.length > 8 && (
                  <div className={Styles.StaffController}>
                    {employeeInfo?.subordinates?.length > limit && (
                      <div className={Styles.ViewMore}>
                        <button onClick={() => setLimit(limit + 8)}>
                          View More
                        </button>
                      </div>
                    )}
                    {limit > 8 &&
                      employeeInfo?.subordinates?.length > limit && (
                        <span>|</span>
                      )}
                    {limit > 8 && (
                      <div className={Styles.ViewMore}>
                        <button onClick={() => setLimit(limit - 8)}>
                          View Less
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {changeSupervisor && (
        <ModalEditSupervisor
          employeeId={employeeInfo?.employeeId}
          clientCode={employeeInfo?.clientCode}
          changeSupervisor={changeSupervisor}
          setChangeSupervisor={setChangeSupervisor}
        />
      )}

      {changeApprover && (
        <ModalEditApprover
          employeeId={employeeInfo?.employeeId}
          clientCode={employeeInfo?.clientCode}
          changeApprover={changeApprover}
          setChangeApprover={setChangeApprover}
        />
      )}

      {file !== null && <ModalViewFile file={file} setFile={setFile} />}

      {ePaySlip !== null && (
        <ModalEpaySlip
          ePaySlip={ePaySlip}
          setEPaySlip={setEPaySlip}
          employeeInfo={employeeInfo}
        />
      )}
      {bpjsOpen && <ModalBPJS datas={employeeInfo} setBpjsOpen={setBpjsOpen} />}

      {leaveModal && (
        <ModalAddLeave
          isActive={leaveModal}
          onOpen={setLeaveModal}
          value={valueAddLeave}
          setValue={setValueAddLeave}
          onSetLeave={onSetLeave}
          mode="edit"
          isErrorSetLeave={isErrorSetLeave}
        />
      )}

      {addLeaveModal && (
        <ModalAddLeave
          isActive={addLeaveModal}
          onOpen={setAddLeaveModal}
          isSuccess={modalSuccess}
          onSuccess={setIsModalSuccess}
          value={valueAddLeave}
          setValue={setValueAddLeave}
          onAddLeave={onAddLeave}
          mode="add"
          isErrorAddLeave={isErrorAddLeave}
        />
      )}

      {modalSuccess && (
        <ModalSuccessEmployee
          isActive={modalSuccess}
          onOpen={setIsModalSuccess}
        />
      )}
    </div>
  );
}
