import axios from "axios";
import {API} from "../../redux/api";

const fetchLeavePlan = (token) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { response },
      } = await axios.get(`${API}/leave-plan`, {
        headers: {
          Authorization: token,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export default fetchLeavePlan;
