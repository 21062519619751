
import * as types from "../../typeExternal";

const initialstate = {
  allWorkOnEmployeeExternal: [],
//   bpjsByIdExternal: [],
  isLoading: false,
  isError: {},
};

const workOnExternalReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_EMPLOYEE_WORK_ON_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.FETCH_ALL_EMPLOYEE_WORK_ON_SUCCESS: {
      return {
        ...state,
        allWorkOnEmployeeExternal: action.payload,
        isLoading: false,
      };
    }
    case types.FETCH_ALL_EMPLOYEE_WORK_ON_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };
    }
    default:
      return state;
  }
};

export default workOnExternalReducer;
