// eslint-disable-next-line no-unused-vars
import React, { useMemo, useState } from "react";
import Notifications from "../../../assets/JsonData/notification.json";
import { useHistory, useLocation } from "react-router";
// eslint-disable-next-line no-unused-vars
import { useSelector, useDispatch } from "react-redux";
// import { Logout } from "../../../redux/actions/SuperAdmin/userActions";
import { IconButton, Menu, MenuItem, Avatar } from "@mui/material";
import Dropdown from "../../dropdown/Dropdown";

// eslint-disable-next-line no-unused-vars
const renderNotificationsItem = (item, index) => (
  <div className=""></div>
  // <div className="notification-item" key={index}>
  //   <i className={item.icon}></i>
  //   <span>{item.content}</span>
  // </div>
);

// const renderUserToggle = (user) => (
//   <div className="topnav__right-user">
//     <div className="topnav__right-user__image">
//       <img src={curr_user.image} alt="" />
//     </div>
//     <div className="topnav__right-user_name">
//       <span className="topnav__right-user_name-main">
//         {user.bio.username
//           ? user.bio.username
//           : localStorage.getItem("username")}
//       </span>
//       <span className="topnav__right-user_name-sub">
//         {user.bio.role ? user.bio.role : localStorage.getItem("role")}
//       </span>
//     </div>
//   </div>
// );

// const renderUserMenu = (item, index, logout, handleClickLink) => (
//   <div
//     className="notification-item"
//     onClick={
//       item.content === "Logout" ||
//       item.content === "Profile" ||
//       item.content === "Settings"
//         ? () => handleClickLink()
//         : null
//     }
//   >
//     <i className={item.icon}></i>
//     <span>{item.content}</span>
//   </div>
// );
export default function TopNavFMCG({ onOpenSidebar }) {
  const location = useLocation();
  // const user = useSelector((state) => state.user);
  const [anchorLogout, setAnchorLogout] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const LocationPathName = useMemo(() => {
    if (location.pathname === "/admin-fmcg") {
      return <span>Dashboard</span>;
    } else if (location.pathname.split("/")[2] === "attendances") {
      return <span>Attendance</span>;
    } else if (location.pathname.split("/")[2] === "employees") {
      return <span>Employees</span>;
    } else if (location.pathname.split("/")[2] === "competitor") {
      return <span>Competitor</span>;
    } else if (location.pathname.split("/")[2] === "report") {
      return <span>Report</span>;
    } else {
      return <span>Setup</span>;
    }
  }, [location?.pathname]);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
    dispatch({ type: "SET_AUTHENTICATED" });
  };
  const handleClickSettings = () => {
    history.push("/superadmin/settings");
  };

  const handleOpenLogoutMenu = (event) => {
    setAnchorLogout(event.currentTarget);
  };
  const handleCloseLogout = () => {
    setAnchorLogout(null);
  };

  return (
    <div className="topnav">
      <div className="topnav__icon">
        <i
          className="bx bx-menu"
          onClick={onOpenSidebar}
          style={{ cursor: "pointer" }}
        ></i>
      </div>

      <div className="topnav__search">
        <span>{LocationPathName.props.children}</span>
      </div>
      <div className="topnav__right">
        <div className="topnav__right-item">
          <Dropdown
            disabled="true"
            icon="bx bxs-bell"
            badge=""
            contentData={Notifications}
            renderItems={(item, index) => renderNotificationsItem(item, index)}
            // renderFooter={() => <Link to="/">View All</Link>}
          />
        </div>
        <div className="topnav__right-item">
          <div className="topnav__right-user">
            <div className="topnav__right-user__image">
              {/* <Tooltip title=""> */}
              <IconButton onClick={handleOpenLogoutMenu} sx={{ p: 0 }}>
                <Avatar sx={{ color: "white", backgroundColor: "black" }} />
                {/* <img src={curr_user.image} alt="" /> */}
              </IconButton>
              {/* </Tooltip> */}
            </div>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorLogout}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorLogout)}
              onClose={handleCloseLogout}
            >
              {/* <div className={Styles.NotificationItem}>
                            <i className="bx bx-cog bx-sm"></i>
                            <span>Account Settings</span>
                            <i className="bx bx-log-out-circle bx-rotate-180"></i>
                            <span>Logout</span>
                        </div> */}
              <MenuItem onClick={handleClickSettings}>
                <i
                  className="bx bx-cog bx-sm"
                  style={{ fontSize: 16, marginRight: 20 }}
                ></i>
                <span style={{ fontSize: 16, font: "Poppins" }}>Settings</span>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <i
                  className="bx bx-log-out-circle bx-rotate-180"
                  style={{ fontSize: 24, marginRight: 20 }}
                ></i>
                <span style={{ fontSize: 16, font: "Poppins" }}>Logout</span>
              </MenuItem>
            </Menu>
            <div className="topnav__right-user_name">
              <span className="topnav__right-user_name-main">
                {localStorage.getItem("userName")}
                {/* {user.bio.username
                  ? user.bio.username
                  : localStorage.getItem("username")} */}
              </span>
              <span className="topnav__right-user_name-sub">
                {localStorage.getItem("clientName")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
