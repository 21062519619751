/* eslint-disable no-unused-vars */
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  //   Menu,
  //   MenuItem,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useMemo, useState } from "react";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import Modal from "../../../modals/Modal";
import "./LocationExternalMenu.css";
import Styles from './LocationExternalMenu.module.css';
import ExportCSV from "../../../SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import DownloadIcon from "@mui/icons-material/Download";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import UserListHead from "../../../SuperAdmin/_user/TableUsers/UserListHead";
import rows from "../../../_dashboard/appNewCustBottom/dataDummy";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useHistory } from "react-router";
import ModalDeleteLocation from "../ModalDeleteLocation/ModalDeleteLocation";
import company_logo from "../../../../assets/images/LogoClient.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllLocationExternal } from "../../../../redux/actions/External/locationActions";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import { AddCircleOutline } from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import DeleteLocationModal from "./DeleteLocationModal/DeleteLocationModal";
import CustomTablePagination from "../../../_pagination/CustomTablePagination";
import useQuery from "../../../utils/hooks/useQuery";


// const TABLE_HEAD = [
//   { id: "CoordinatePoint", label: "Coordinate Point", alignRight: false },
//   { id: "LocationName", label: "Location Name", alignRight: false },
//   { id: "Client", label: "Client", alignRight: false },
//   { id: "Address", label: "Address", alignRight: false },
//   { id: "Phone", label: "Phone", alignRight: false },
//   // { id: "Employee", label: "Employee", alignRight: false },
//   { id: "" },
// ];

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});


const TABLE_HEAD = [
  { id: 'clientCode', label: 'Client Name', alignRight: false },
  { id: 'name', label: 'Location Name', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'radiusInMeter', label: 'Range', alignRight: false },
  { id: 'latitude', label: 'Map', alignRight: false },
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    deleteMode
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>

        {TABLE_HEAD.map((headCell, index) => (
          <TableCell
            key={headCell.id + index}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className={Styles.TableSortLabel}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {deleteMode && (
          <TableCell></TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}


export default function LocationExternalMenu({
  dataClientCodes,
  selectedClient,
  setSelectedClient,
  clientName,
  setPosition,
  name,
  setName,
}) {
  // const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem('accessToken')
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fileData, setFileData] = useState([]);
  const [listData, setListData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteLocation, setDeleteLocation] = useState({});
  const [deleteMode, setDeleteMode] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [dataClientCode, setDataClientCode] = useState([]);
  const [pendingLoad, setPendingLoad] = useState(false);


  const defaultClientCode = localStorage.getItem('clientCode')
  const defaultClientName = localStorage.getItem('clientName')
  const defaultClient = { code_client: defaultClientCode, name_client: defaultClientName }

  const [listDataLocations, setListDataLocations] = useState("");

  const { allLocationsExternal, isLoading } = useSelector(state => state.locationsExternal)

  const locations = allLocationsExternal

  const persistClientCode = useMemo(() => {
    let persistClient = window.sessionStorage.getItem('selectedClientExternal')
    let clientCode;
    if (persistClient) {
      clientCode = persistClient;
    } else {
      clientCode = selectedClient.code_client
    }
    return clientCode
  }, [selectedClient])

  const persistClientName = useMemo(() => {
    let persistClient = window.sessionStorage.getItem('selectedClientExternalName')
    let clientName;
    if (persistClient) {
      clientName = persistClient;
    } else {
      clientName = selectedClient.name_client;
    }
    return clientName
  }, [selectedClient])

  const [persistValue, setPersistValue] = useState({
    name_client: persistClientName,
    code_client: persistClientCode
  });

  // const clientCode = query.get('client_code') || window.sessionStorage.getItem('selectedClientCodeExternal') || localStorage.getItem('clientCode')
  // const clientName = query.get('client_name') || window.sessionStorage.getItem('selectedClientNameExternal') || localStorage.getItem('clientName')

  const query = `code=${window.sessionStorage.getItem('selectedClientCodeExternal') || localStorage.getItem('clientCode')}`;

  useEffect(() => {
    if (pendingLoad === false) {
      dispatch(fetchAllLocationExternal(token, query))
    }
  }, [token, dispatch, query, pendingLoad, selectedClient])


  const capitalizedClientName = (clientName) => {
    return clientName?.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace('Pt', 'PT')
      .replace('Cv', 'CV')
  }

  const handleSelectedClient = (e, newVal) => {
    setPendingLoad(true)
    if (newVal !== null) {
      window.sessionStorage.setItem('selectedClientExternal', newVal.code_client)
      window.sessionStorage.setItem('selectedClientExternalName', newVal.name_client)
      setSelectedClient(newVal)
    } else {
      setSelectedClient(selectedClient)
    }
    setTimeout(() => {
      setPendingLoad(false)
    }, 300)
    clearTimeout()
  }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (event) => {
    setListData(event.target.value);
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchTerm(listData);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (

    <div className={Styles.Container}>
      <div className={Styles.HeaderWrapper}>
        <div className={Styles.Filterer}>
          <div className={Styles.Searchbar}>
            <SearchbarSA
              value={listDataLocations}
              onChange={(e) => setListDataLocations(e.target.value)}
              searchWidth="379px"
              placeholder="Search by address or location name"
            />
          </div>
          {/* <div className={Styles.Autocomplete}>
            <Autocomplete
              disablePortal
              id="attendee"
              defaultValue={persistValue}
              getOptionLabel={(option) => option.name_client}
              options={dataClientCodes}
              onChange={(e, newVal) => newVal !== null ? handleSelectedClient(e, newVal) : handleSelectedClient(e, null)}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.code_client}>
                    {option.name_client}
                  </li>
                );
              }}
              renderInput={(params) => <MuiTextField {...params} size="small" sx={{ width: 250, background: 'var(--main-bg)' }} placeholder="Client" />
              }
            />
          </div> */}
        </div>
        <div className={Styles.ButtonWrapper}>
          <Button onClick={() => setDeleteMode(!deleteMode)} variant="outlined" className={Styles.AddButton}>{!deleteMode ? 'Edit' : 'Exit Edit Mode'}</Button>
          <Button onClick={() => history.push('/admin/add-location-external')} startIcon={<AddCircleOutline />} variant="outlined" className={Styles.AddButton}>Add Location Data</Button>
        </div>
      </div>
      {
        isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 50,
            }}
          >
            <div style={{ width: 200 }}>
              <LoadingAnimation />
            </div>
          </div>
        ) : (
          <div className={Styles.TableWrapper}>
            <Scrollbar>
              <TableContainer className={Styles.TableContainer}>
                <Table id="emp-table" className={Styles.Table}>
                  <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '15%' }} />
                    {deleteMode && <col style={{ width: '5%' }} />}
                  </colgroup>
                  <EnhancedTableHead
                    numSelected={locations.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    values={locations}
                    deleteMode={deleteMode}
                  />
                  <TableBody>
                    {stableSort(locations, getComparator(order, orderBy))
                      .filter((el) =>
                        el.address?.toLowerCase().includes(listDataLocations.toLowerCase())
                        ||
                        el.name?.toLowerCase().includes(listDataLocations.toLowerCase())
                      )
                      .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                      .map((each, i) => {
                        return (
                          <TableRow hover key={'tablebody' + i} tabIndex={-1}>
                            <TableCell component="th" scope="row" sx={{ textTransform: "capitalize" }} className={Styles.TableCell}>
                              <Link
                                to={{
                                  pathname: `/admin/location-details/${each._id}`,
                                  state: { data: each, clientName }
                                }}
                              >
                                {clientName !== null
                                  ? capitalizedClientName(clientName)
                                  : "Loading client name..."}
                              </Link>
                            </TableCell>
                            <TableCell align="left" className={Styles.TableCell}>
                              <Link
                                to={{
                                  pathname: `/admin/location-details/${each._id}`,
                                  state: { data: each, clientName }
                                }}
                              >
                                <span>{each.name ? each.name : "-"}</span>
                              </Link>
                            </TableCell>
                            <TableCell align="left" className={`${Styles.TableCell} ${Styles.Address}`}>
                              <span>{each.address ? each.address : "-"}</span>
                            </TableCell>
                            <TableCell align="left" className={Styles.TableCell}>
                              {each.radiusInMeter ? each.radiusInMeter : '-'} m
                            </TableCell>
                            <TableCell align="left" className={Styles.PinLabel}>
                              <span>
                                {each.latitude ? 'Pinned' : "-"}
                              </span>
                            </TableCell>
                            {deleteMode && (
                              <TableCell className={Styles.DeleteCell}>
                                <button onClick={(e) => {
                                  e.preventDefault()
                                  setSelectedLocation(each)
                                }}>
                                  <img src={DELETE_ICON} alt="delete" />
                                </button>
                              </TableCell>
                            )}
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <CustomTablePagination
              limit={rowsPerPage}
              setLimit={setRowsPerPage}
              page={page}
              setPage={setPage}
              totalPage={Math.ceil(locations.filter((el) =>
                el.address?.toLowerCase().includes(listDataLocations.toLowerCase())
                ||
                el.name?.toLowerCase().includes(listDataLocations.toLowerCase())
              ).length / rowsPerPage)}
            />

          </div>
        )
      }

      {selectedLocation && (
        <DeleteLocationModal
          active={selectedLocation}
          onOpen={setSelectedLocation}
          description="Are you sure you want to delete this location?"
          label="Delete Location Data"
          pathName="/admin/location-external"
          query={`code=${selectedLocation?.clientCode}`}
        />
      )}
    </div >
  );
}
