/* eslint-disable no-unused-vars */
import React from "react";
import { Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import {
  AppAttendanceOverview,
  AppLoanOverview,
  AppAverage,
  AppDataListLoan,
  AppDataAttSummary,
  AppDataAttAverage,
  AppLastPaySlipSA,
  AppLastBPJSpay,
  AppInformationUpdate,
  AppTodayAbsence,
} from "../../components/SuperAdmin/_dashboard";
import { Redirect } from "react-router";
import UnderMaintenance from "../../components/_underMaintenancePage";
import { TableUserMenu } from "../../components/SuperAdmin/_user";

// import {} from "../components/_dashboard";
const DashboardSA = () => {
  const token = localStorage.getItem('accessToken');
  const isMaintenance = true;

  if(!token) {
    return <Redirect to="/"/>
  }
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Dashboard</title>
      </Helmet>
      <h2
        className="page-header"
        style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
      >
        Dashboard
      </h2>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          { isMaintenance ? <UnderMaintenance /> : <AppAttendanceOverview />}
          {/* <AppAttendanceOverview /> */}
        </Grid>
        {/* <Grid item xs={12} md={12} lg={12}>
          <AppLoanOverview />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppAverage />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppDataAttSummary />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppDataAttAverage />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppTodayAbsence />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppDataListLoan />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppLastPaySlipSA />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppLastBPJSpay />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AppInformationUpdate />
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default DashboardSA;
