import React, { useEffect } from "react";
// import "./EditClients.css";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { ClientEditMenu } from "../../../components/SuperAdmin/_client";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getClientById } from "../../../redux/actions/SuperAdmin/adminActions";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
export default function EditClient() {
  const token = localStorage.getItem("accessToken");
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const client = useSelector((state) => state.client.clientById[id]);
  // console.log(client, "cek client <<<");
  useEffect(() => {
    dispatch(getClientById(id, token));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Edit Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/client")}
              style={{ border: "none", textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Edit Client</span>
            </Button>

            <span style={{ fontSize: 20 }}>{ client?.Name }</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <ClientEditMenu client={client} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
