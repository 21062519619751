import React from "react";
import Styles from './ModalPublishedInfo.module.css'

import success_published from "../../../../../assets/images/email-send.png";
import { Button } from "@mui/material";

export default function ModalPublishedInfo({ setModalSuccess, history, title, text }) {
  const handleClickPage = () => {
    setModalSuccess(true);
    if (localStorage.getItem("type") === "BUSINESS"){
      history.push("/admin-business/information");
    } else if (localStorage.getItem("type") === "SUPER"){
      history.push("/superadmin/information");
    }
  };
  return (
    <div className={Styles.Modal}>
      <div
        className={Styles.ModalContainer}>
        <div
          className={Styles.ModalBody}>
          <div className={Styles.ModalImageContainer}>
            <img src={success_published} alt="" />
          </div>
          <div
            className={Styles.ModalTitleContainer}
          >
            <span>
              {title}
            </span>
            <div className={Styles.ModalText}>
              <span>
                {text}
              </span>
            </div>
          </div>
          <div className={Styles.ButtonWrapper}>
            <div>
              <Button
                onClick={handleClickPage}
                className={Styles.ButtonOk}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
