import React from "react";
// eslint-disable-next-line no-unused-vars
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppDataAttendance } from "../../../../components/SuperAdmin/_attendance/";
// import {  useSelector } from "react-redux";
import { useHistory } from "react-router";
// import { fetchAllDataEmployeeByRange } from "../../../../redux/actions/SuperAdmin/dataEmployeeInternalActions";

export default function AttendanceData() {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  // const employeeInternal = useSelector((state) => state.employeeInternal.dataEmployeeInternalAll);
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Attendance</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          Attendance
        </h2>
        {/* <div className="wrapper-button-client-SA">
          <Button
            onClick={() => {
              history.push("/superadmin/attendance-report");
            }}
            style={{
              display: "flex",
              alignItems: "center",
              color: "#1571de",
              textTransform: "none",
            }}
          >
            <span className="">Report</span>
            <i  
              className="bx bxs-printer"
              style={{ fontSize: 20, paddingLeft: 10 }}
            ></i>
          </Button>
        </div> */}
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <AppDataAttendance  />
        </Grid>
      </Grid>
    </Container>
  );
}
