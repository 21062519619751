import React from "react";
import "./AddClients.css";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { AddClientMenu2 } from "../../../components/SuperAdmin/_client";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
import { useHistory } from "react-router";

export default function AddClients() {
  const history = useHistory();
  return (
    <Container maxWidth="xl">
      {/* <Breadcrumbs /> */}
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Client Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/client")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Client</span>
            </Button>
            <span style={{ fontSize: 20 }}>Add Client</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AddClientMenu2 />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
