import { Button } from "@mui/material";
import React from "react";
import "./DetailPayslipMenu.css";
import DownloadIcon from "@mui/icons-material/Download";
import { useCalendar } from "../../../../utils/hooks";

export default function DetailPayslipMenu({ data }) {
  const calendar = useCalendar();
  let month = calendar.months.eng.full[data?.PayrollMonth?.split(" ")[0] - 1];
  let year = data?.PayrollMonth?.split(" ")[2];
  const basicSalary = data?.Income?.["Basic Salary"];
  // const jht = data?.Deduction?.["JHT Dues 2%"];
  // const jp2Percent = data?.Deduction?.["BPJS JP (2%)"];
  const jp1Percent = data?.Deduction?.["BPJS JP (1%)"];
  // const pph21 = data?.Deduction?.["PPH 21 Rutin"];
  const tunjanganJabatan = data?.Income?.["Tunjangan Jabatan"];
  const bpjsKesehatan = data?.Deduction?.["BPJS Kesehatan (1%)"];
  const bpjsKetenagaKerjaan = data?.Deduction?.["BPJS Ketenagakerjaan (1%)"];
  const jht1Percent  = data?.Deduction?.["BPJS JHT (2%)"];
  const pph21Gaji = data?.Deduction?.["PPH 21 Atas Gaji"];
  const pph21Thr = data?.Deduction?.["PPH 21 Atas THR/Bonus"];
  const totalPph21 = pph21Gaji + pph21Thr;
  // console.log(totalPph21, 'sum')

  const numberWithPoint = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const loanViewTab = (url) => {
    const newWindow = window.open(
      `/data-payslip-viewer/${data._id}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  // const datePayslip = Object.entries(data.PayrollMonth);
  // console.log(data, 'cek');
  return (
    <div className="container-data-payslip">
      <div className="items-main-data-payslip">
        <div className="text-main-data-payslip">
          <span>Epay Slip</span>
        </div>
        <hr className="data-payslip-divider" />
        <div className="item-main-data-payslip">
          <div className="wrapper-item-main">
            <label>Pay Slip Date</label>
            <span>{`${month} ${year}`}</span>
          </div>
          <div className="wrapper-item-main">
            <label>Employee Name</label>
            <span>{data.Name}</span>
          </div>
          <div className="wrapper-item-main">
            <label>Position</label>
            <span>{data.Position}</span>
          </div>
        </div>
        <hr className="data-payslip-divider" />
        <div className="item-main-data-payslip">
          <span>Rutin</span>
          <div className="wrapper-item-justify">
            <div className="item-justify">
              <span>Gaji Pokok</span>
            </div>
            <div className="item-justify">
              <span>Rp.{basicSalary ? numberWithPoint(basicSalary) : "-"}</span>
            </div>
          </div>
          <div className="wrapper-item-justify">
            <div className="item-justify">
              <span>TTUM</span>
            </div>
            <div className="item-justify">
              <span>Rp.{data?.Income?.TTUM ? numberWithPoint(data?.Income?.TTUM) : '-'}</span>
            </div>
          </div>
          <div className="wrapper-item-justify">
            <div className="item-justify">
              <span>Tunjangan Jabatan</span>
            </div>
            <div className="item-justify">
              <span>Rp.{ tunjanganJabatan ? numberWithPoint(tunjanganJabatan) : "-"}</span>
            </div>
          </div>
        </div>
        <hr className="data-payslip-divider" />
        <div className="item-main-data-payslip">
          <span>Tidak Rutin</span>
          <div className="wrapper-item-justify">
            <div className="item-justify">
              <span>Lembur</span>
            </div>
            <div className="item-justify">
              <span>Rp. { data?.Income?.Lembur ? numberWithPoint(data?.Income?.Lembur) : "-"}</span>
            </div>
          </div>
          <div className="wrapper-item-justify">
            <div className="item-justify">
              <span>Bonus</span>
            </div>
            <div className="item-justify">
              <span>Rp. { tunjanganJabatan ? numberWithPoint(tunjanganJabatan) : "-"}</span>
            </div>
          </div>
        </div>
        <hr className="data-payslip-divider" />
        <div className="item-main-data-payslip">
          <span>Potongan</span>
          <div className="wrapper-item-justify">
            <div className="item-justify">
              <span>JHT 2%</span>
            </div>
            <div className="item-justify">
              <span>Rp. {jht1Percent ? numberWithPoint(jht1Percent) : "-"}</span>
            </div>
          </div>
          <div className="wrapper-item-justify">
            <div className="item-justify">
              <span>JP 1%</span>
            </div>
            <div className="item-justify">
              <span>Rp. {jp1Percent ? numberWithPoint(jp1Percent) : "-"}</span>
            </div>
          </div>
          <div className="wrapper-item-justify">
            <div className="item-justify">
              <span>BPJS Kesehatan 1%</span>
            </div>
            <div className="item-justify">
              <span>Rp. {bpjsKesehatan ? numberWithPoint(bpjsKesehatan) : "-"}</span>
            </div>
          </div>
          <div className="wrapper-item-justify">
            <div className="item-justify">
              <span>BPJS Ketenagakerjaan 1%</span>
            </div>
            <div className="item-justify">
              <span>Rp. {bpjsKetenagaKerjaan ? numberWithPoint(bpjsKetenagaKerjaan) : "-"}</span>
            </div>
          </div>
          <div className="wrapper-item-justify">
            <div className="item-justify">
              <span>Penalty Keterlambatan</span>
            </div>
            <div className="item-justify">
              <span>Rp. {data?.Deduction?.Penalty ? numberWithPoint(data?.Deduction?.Penalty) : "-"}</span>
            </div>
          </div>
          <div className="wrapper-item-justify">
            <div className="item-justify">
              <span>PPH 21</span>
            </div>
            <div className="item-justify">
              <span>Rp.{ totalPph21 ? numberWithPoint(totalPph21) : '-'}</span>
            </div>
          </div>
        </div>
        <hr className="data-payslip-divider" />
        <div className="item-main-data-payslip">
          <div className="wrapper-item-justify">
            <div className="item-justify">
              <span style={{ color: "#0A0A0A", fontWeight: 600 }}>Total</span>
            </div>
            <div className="item-justify">
              <span style={{ color: "#0A0A0A", fontWeight: 600 }}>
                Rp.{data?.TotalIncome ? numberWithPoint(data?.TotalIncome) : "-"}
              </span>
            </div>
          </div>
        </div>
        <div className="button-helper-payslip-data">
          <Button
            onClick={loanViewTab}
            endIcon={<DownloadIcon />}
            style={{ backgroundColor: "#1571DE" }}
          >
            Download
          </Button>
        </div>
      </div>
    </div>
  );
}
