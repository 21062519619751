import React from "react";
import "./index.css";
import PROFILE_IMAGE from "../../../../../assets/images/customer-detail.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import ExportCSV from "../../../../SuperAdmin/_attendance/tabs/DataAttendance/ExportData";
import DownloadIcon from "@mui/icons-material/Download";
const CustomerDetail = () => {
  return (
    <div className="container-customer-detail">
      <div className="wrapper-customer-detail">
        <div className="box-left-customer-detail">
          <div className="image-item-customer-detail">
            <img src={PROFILE_IMAGE} alt="" />
          </div>
          <div className="customer-profile-detail">
            <label className="customer-name">Bagus Indrawan</label>
          </div>
          <div className="container-profile-detail">
            <div className="item-container-profile">
              <div className="wrapper-item-icon-profile">
                <div className="icon-profile-detail">
                  <LocationOnOutlinedIcon sx={{ color: "#696969" }} />
                </div>
                <div className="detail-text-right">
                  <label>Location</label>
                  <span>Surabaya, Indonesia</span>
                </div>
              </div>
              <div
                className="wrapper-item-icon-profile"
                style={{ paddingTop: 20 }}
              >
                <div className="icon-profile-detail">
                  <LocalPhoneOutlinedIcon sx={{ color: "#696969" }} />
                </div>
                <div className="detail-text-right">
                  <label>Phone</label>
                  <span>0812-8280-8828</span>
                </div>
              </div>
              <div
                className="wrapper-item-icon-profile"
                style={{ paddingTop: 20 }}
              >
                <div className="icon-profile-detail">
                  <MailOutlineOutlinedIcon sx={{ color: "#696969" }} />
                </div>
                <div className="detail-text-right">
                  <label>Email</label>
                  <span>bagusindrawan@gmail.com</span>
                </div>
              </div>
              <div
                className="wrapper-item-icon-profile"
                style={{ paddingTop: 20 }}
              >
                <div className="icon-profile-detail">
                  <WorkOutlineOutlinedIcon sx={{ color: "#696969" }} />
                </div>
                <div className="detail-text-right">
                  <label>Profession</label>
                  <span>Pegawai Swasta</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-right-customer-detail">
          <div className="wrapper-item-top-customer-detail">
            <div className="text-header-customer-detail">
              <span>Customer Information</span>
            </div>
            <div className="container-button-customer-detail">
              <ExportCSV initial="Download" endIcon={<DownloadIcon />} />
            </div>
          </div>
          <hr className="divider-customer-detail" />
          <div className="container-items-customer-detail">
            <div className="container-item-left">
              <div className="wrapper-items-right-customer-detail">
                <div className="item-left-detail">
                  <label>Name</label>
                  <span>Bagus Indrawan</span>
                </div>
              </div>
              <div
                className="wrapper-items-right-customer-detail"
                style={{ paddingTop: 15 }}
              >
                <div className="item-left-detail">
                  <label>Customer Type</label>
                  <span>Sampling</span>
                </div>
              </div>
              <div
                className="wrapper-items-right-customer-detail"
                style={{ paddingTop: 15 }}
              >
                <div className="item-left-detail">
                  <label>Kota</label>
                  <span>Surabaya</span>
                </div>
              </div>
              <div
                className="wrapper-items-right-customer-detail"
                style={{ paddingTop: 15 }}
              >
                <div className="item-left-detail">
                  <label>Produk yang digunakan</label>
                  <span>Susu Indomilk</span>
                </div>
              </div>
            </div>
            <div className="container-item-right">
              <div className="wrapper-items-right-customer-detail">
                <div className="item-left-detail">
                  <label>Phone Number</label>
                  <span>0812-8280-8828</span>
                </div>
              </div>
              <div
                className="wrapper-items-right-customer-detail"
                style={{ paddingTop: 15 }}
              >
                <div className="item-left-detail">
                  <label>Tanggal Lahir</label>
                  <span>19 November</span>
                </div>
              </div>
              <div
                className="wrapper-items-right-customer-detail"
                style={{ paddingTop: 15 }}
              >
                <div className="item-left-detail">
                  <label>Produk yang dibeli</label>
                  <span>Susu Bendera</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetail;
