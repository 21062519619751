import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./DetailsProductFMCG.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumb from "../../../utils/Breadcrumbs";
import { useHistory } from "react-router";
import StoreImageTable from "../../../../assets/images/Store-Image-Blue.png";
import { styled } from "@mui/styles";
import { makeStyles } from "@mui/styles";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 5,
    },
    "& .MuiInputBase-input-MuiOutlinedInput-input": {
      padding: 0,
    },
    "& input::placeholder": {
      color: "#A4A4A4",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "18.2px",
      opacity: 1,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 5,
    },
    "& .MuiSelect-select": {
      color: "black",
      fontSize: 13,
      fontWeight: 400,
    },
    // "& input::placeholder": {
    //   color: "#A4A4A4",
    //   fontSize: 13,
    //   fontWeight: 400,
    //   lineHeight: "18.2px",
    //   opacity: 1,
    // },
  },
  adorment: {
    "& .MuiTypography-root ": {
      color: "#A4A4A4",
      fontSize: 15,
      fontWeight: 400,
      lineHeight: "18.2px",
    },
  },
}));

const storeStatusArr = ["Active", "Unactive"];
const storeAreaArr = ["Cheese Spread", "Arla Butter"];

const DetailsProductFMCG = ({ location }) => {
  const history = useHistory();
  const classes = useStyles();
  const [values, setValues] = useState({});
  // const [disabledButton, setDisabledButton] = useState(true);
  const [selectedInput, setSelectedInput] = useState({
    productStatus: "",
    productCategory: "",
    price: "",
  });

  useEffect(() => {
    if (location === "editProductFMCG") {
      setValues((prev) => ({
        ...prev,
        ProductName: "Cheese Spread",
        ProductCode: "001",
        ProductSize: "20 x 20 ctn",
        Brand: "Arlaaaaa",
        Price: +4500000,
      }));
      setSelectedInput((prev) => ({
        ...prev,
        productCategory: "Arla Butter",
        productStatus: "Active",
      }));
    }
  }, [location]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("submitted");
    let concatValue = "Rp" + values.Price;

    if (location === "addProductFMCG") {
      const data = {
        ProductName: values.ProductName,
        ProductCode: values.ProductCode,
        Size: values.ProductSize,
        Brand: values.Brand,
        Price: +values.Price,
        ProductCategory: selectedInput.productCategory,
        Status: selectedInput.productStatus,
      };

      try {
        console.log(data, "data submitted");

        history.push(`/admin-fmcg/setup/products`)
      } catch (error) {
        console.log(data, "data error");
      }
    }

    if (location === "editProductFMCG") {
      const data = {
        ProductName: values.ProductName,
        ProductCode: values.ProductCode,
        Size: values.ProductSize,
        Brand: values.Brand,
        ProductCategory: selectedInput.productCategory,
        Price: concatValue,
        Status: selectedInput.productStatus,
      };

      try {
        console.log(data, "data submitted edit ");

        history.push(`/admin-fmcg/setup/products`)
      } catch (error) {
        console.log(data, "data error edit");
      }
    }
  };
  return (
    <div className="ContainerDetailsProductFMCG">
      <div className="WrapperDetailsProductFMCG">
        <div className="ButtonRouteBack" onClick={() => history.go(-1)}>
          <IconButton>
            <ArrowBackIcon sx={{ color: "#202020", width: 24, height: 24 }} />
          </IconButton>
        </div>
        <div className="WrapperBreadCrumbs">
          {location === "addProductFMCG" ? (
            <Breadcrumb>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextGrey">Product</span>
              </Button>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextBlue">Add New Product</span>
              </Button>
            </Breadcrumb>
          ) : (
            <Breadcrumb>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextGrey">Product</span>
              </Button>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextBlue">Edit Product</span>
              </Button>
            </Breadcrumb>
          )}
        </div>
      </div>
      <div className="WrapperContentDetailsProductFMCG">
        <div className="WrapperItemHeaderDetailsProductFMCG">
          <div className="BorderImageDetailsProductFMCG">
            <img src={StoreImageTable} alt="" />
          </div>
          <Button
            style={{
              width: 100,
              height: 34,
              backgroundColor: "#1571DE",
              textTransform: "none",
              color: "#FFFFFF",
              fontWeight: 500,
              fontSize: 13,
            }}
          >
            Add Photo
          </Button>
        </div>
        <form
          className="WrapperItemsInputFormDetailsProduct"
          onSubmit={handleSubmit}
        >
          {location === "addProductFMCG" ? (
            // Add New Product
            <div className="WrapperItemsInputDetailsProduct">
              <div className="ItemsInputDetailsProduct">
                <div className="ItemInputDetailsProduct">
                  <span>Product Name</span>
                  <MuiTextField
                    size="small"
                    placeholder="Product Name"
                    value={values.ProductName || ""}
                    onChange={handleChange("ProductName")}
                    type="text"
                    required
                  />
                </div>
                <div className="ItemInputDetailsProduct">
                  <span>Product Code</span>
                  <MuiTextField
                    size="small"
                    placeholder="Product Code"
                    value={values.ProductCode || ""}
                    onChange={handleChange("ProductCode")}
                    type="number"
                    required
                  />
                </div>
              </div>
              <div className="ItemsInputDetailsProduct">
                <div className="ItemInputDetailsProduct">
                  <span>Size</span>
                  <MuiTextField
                    size="small"
                    placeholder="Product Size"
                    value={values.ProductSize || ""}
                    onChange={handleChange("ProductSize")}
                    type="text"
                    required
                  />
                </div>
                <div className="ItemInputDetailsProduct">
                  <span>Brand</span>
                  <MuiTextField
                    size="small"
                    placeholder="Brand"
                    value={values.Brand || ""}
                    onChange={handleChange("Brand")}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="ItemsInputDetailsProduct">
                <div className="ItemInputDetailsProduct">
                  <span>Product Category</span>
                  <FormControl fullWidth size="small">
                    <Select
                      className={classes.selectBorder}
                      value={selectedInput.productCategory || ""}
                      displayEmpty
                      onChange={(e) =>
                        setSelectedInput({
                          ...selectedInput,
                          productCategory: e.target.value,
                        })
                      }
                      required
                    >
                      <MenuItem value="" disabled>
                        <Typography
                          component="span"
                          style={{
                            fontSize: 13,
                            fontWeight: 400,
                            color: "#A4A4A4",
                          }}
                        >
                          Choose Category
                        </Typography>
                      </MenuItem>
                      {storeAreaArr.sort().map((option, el) => (
                        <MenuItem
                          key={el}
                          onClick={() =>
                            setSelectedInput({
                              ...selectedInput,
                              productCategory: option,
                            })
                          }
                          value={option || ""}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="ItemInputDetailsProduct">
                  <span>Price</span>
                  <MuiTextField
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.adorment}
                        >
                          <Typography
                            component="span"
                            style={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#A4A4A4",
                            }}
                          >
                            Rp
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    value={values.Price || ""}
                    onChange={handleChange("Price")}
                    type="number"
                    required
                  />
                </div>
              </div>
              <div className="ItemsInputDetailsProduct">
                <div className="ItemInputDetailsProduct">
                  <span>Status</span>
                  <FormControl fullWidth size="small">
                    <Select
                      className={classes.selectBorder}
                      value={selectedInput.productStatus || ""}
                      displayEmpty
                      onChange={(e) =>
                        setSelectedInput({
                          ...selectedInput,
                          productStatus: e.target.value,
                        })
                      }
                      required
                    >
                      <MenuItem value="" disabled>
                        <Typography
                          component="span"
                          style={{
                            fontSize: 13,
                            fontWeight: 400,
                            color: "#A4A4A4",
                          }}
                        >
                          Choose Category
                        </Typography>
                      </MenuItem>
                      {storeStatusArr.sort().map((option, el) => (
                        <MenuItem
                          key={el}
                          onClick={() =>
                            setSelectedInput({
                              ...selectedInput,
                              productStatus: option,
                            })
                          }
                          value={option || ""}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="ItemInputDetailsProduct"></div>
              </div>
            </div>
          ) : (
            // Edit Store MD
            <div className="WrapperItemsInputDetailsProduct">
              <div className="ItemsInputDetailsProduct">
                <div className="ItemInputDetailsProduct">
                  <span>Product Name</span>
                  <MuiTextField
                    size="small"
                    placeholder="Product Name"
                    value={values.ProductName || ""}
                    onChange={handleChange("ProductName")}
                    type="text"
                  />
                </div>
                <div className="ItemInputDetailsProduct">
                  <span>Product Code</span>
                  <MuiTextField
                    size="small"
                    placeholder="Product Code"
                    value={values.ProductCode || ""}
                    onChange={handleChange("ProductCode")}
                    type="number"
                  />
                </div>
              </div>
              <div className="ItemsInputDetailsProduct">
                <div className="ItemInputDetailsProduct">
                  <span>Size</span>
                  <MuiTextField
                    size="small"
                    placeholder="Product Size"
                    value={values.ProductSize || ""}
                    onChange={handleChange("ProductSize")}
                    type="text"
                  />
                </div>
                <div className="ItemInputDetailsProduct">
                  <span>Brand</span>
                  <MuiTextField
                    size="small"
                    placeholder="Brand"
                    value={values.Brand || ""}
                    onChange={handleChange("Brand")}
                    type="text"
                  />
                </div>
              </div>
              <div className="ItemsInputDetailsProduct">
                <div className="ItemInputDetailsProduct">
                  <span>Product Category</span>
                  <FormControl fullWidth size="small">
                    <Select
                      className={classes.selectBorder}
                      value={selectedInput.productCategory || ""}
                      displayEmpty
                      onChange={(e) =>
                        setSelectedInput({
                          ...selectedInput,
                          productCategory: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="" disabled>
                        <Typography
                          component="span"
                          style={{
                            fontSize: 13,
                            fontWeight: 400,
                            color: "#A4A4A4",
                          }}
                        >
                          Choose Category
                        </Typography>
                      </MenuItem>
                      {storeAreaArr.sort().map((option, el) => (
                        <MenuItem
                          key={el}
                          onClick={() =>
                            setSelectedInput({
                              ...selectedInput,
                              productCategory: option,
                            })
                          }
                          value={option || ""}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="ItemInputDetailsProduct">
                  <span>Price</span>
                  <MuiTextField
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.adorment}
                        >
                          <Typography
                            component="span"
                            style={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: "#A4A4A4",
                            }}
                          >
                            Rp
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    value={values.Price || ""}
                    onChange={handleChange("Price")}
                    type="number"
                    required
                  />
                </div>
              </div>
              <div className="ItemsInputDetailsProduct">
                <div className="ItemInputDetailsProduct">
                  <span>Status</span>
                  <FormControl fullWidth size="small">
                    <Select
                      className={classes.selectBorder}
                      value={selectedInput.productStatus || ""}
                      displayEmpty
                      onChange={(e) =>
                        setSelectedInput({
                          ...selectedInput,
                          productStatus: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="" disabled>
                        <Typography
                          component="span"
                          style={{
                            fontSize: 13,
                            fontWeight: 400,
                            color: "#A4A4A4",
                          }}
                        >
                          Choose Category
                        </Typography>
                      </MenuItem>
                      {storeStatusArr.sort().map((option, el) => (
                        <MenuItem
                          key={el}
                          onClick={() =>
                            setSelectedInput({
                              ...selectedInput,
                              productStatus: option,
                            })
                          }
                          value={option || ""}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="ItemInputDetailsProduct"></div>
              </div>
            </div>
          )}

          <div className="ButtonActionDetailsDetailsProduct">
            <Button
              style={{
                background: "#FFFFF",
                borderRadius: 5,
                border: "1px solid #818181",
                width: 79,
                height: 34,
                textTransform: "none",
                color: "#818181",
                fontSize: 13,
                fontWeight: 500,
              }}
              onClick={() => history.go(-1)}
              // onClick={() => history.push("/admin-fmcg/store-setup")}
            >
              Cancel
            </Button>
            {location === "addProductFMCG" ? (
              <Button
                style={{
                  background: "#1571DE",
                  borderRadius: 5,
                  width: 65,
                  height: 34,
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: 13,
                  fontWeight: 500,
                }}
                type="submit"
              >
                Save
              </Button>
            ) : (
              <Button
                style={{
                  background: "#1571DE",
                  borderRadius: 5,
                  width: 128,
                  height: 34,
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: 13,
                  fontWeight: 500,
                }}
                type="submit"
              >
                Save Changes
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default DetailsProductFMCG;
