import React, { useRef } from "react";
import Styles from "./ModalLoanSA.module.css";
import { Button } from "@mui/material";
// import DeleteIcon from "../../../assets/images/delete-icon-for-modal.png";
import DELETE_ICON from "../../../../assets/images/delete-icon-schema-v2.png";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { deleteSchemaLoan } from "../../../../redux/actions/SuperAdmin/loanActions";

const clickOutsideRef = (content_ref, toggle_ref) => {
  document.addEventListener("mousedown", (e) => {
    // user click toggle
    if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
      content_ref.current.classList.toggle("active");
    } else {
      // user click outside toggle and content
      if (content_ref.current && !content_ref.current.contains(e.target)) {
        content_ref.current.classList.remove("active");
      }
    }
  });
};

export default function ModalLoanSA(props) {
  const { active, onOpen, description, label, pathName } = props;
  const dropdown_toggle_el = useRef(null);
  const dropdown_content_el = useRef(null);

  clickOutsideRef(dropdown_content_el, dropdown_toggle_el);
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const history = useHistory();

  // console.log(active._id)

  const handleDeleteScheme = () => {
    onOpen({});
    dispatch(deleteSchemaLoan(token, active._id));
    history.push(pathName);
  };
  return (
    <div className={Styles.ModalDeleteBackground}>
      <div className={Styles.ModalContainerContent}>
        <div className={Styles.WrapperItemModalDelete}>
          <div className={Styles.ItemTopModalDelete}>
            <img src={DELETE_ICON} alt="delete-icon" />
          </div>
        </div>
        <div className={Styles.ContentModalDelete} style={{ paddingTop: 24 }}>
          <div className={Styles.TextModalDelete}>
            <span>{label}</span>
            <span>{description}</span>
          </div>
          <div className={Styles.WrapperButtonModalDelete}>
            <Button
              style={{
                border: "1px solid #333333",
                backgroundColor: "#FFFFFF",
                borderRadius: 5,
                textTransform: "none",
                color: "#333333",
                width: 171,
                height: 38,
                fontSize: 13,
                fontWeight: 500,
              }}
              onClick={() => {
                onOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#1571DE",
                textTransform: "none",
                color: "#FFFFFF",
                width: 171,
                height: 38,
                fontSize: 13,
                fontWeight: 500,
              }}
              onClick={handleDeleteScheme}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
