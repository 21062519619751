/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { AddClientsIdMenu } from "../../../../components/SuperAdmin/_client";
import { getClientById } from "../../../../redux/actions/SuperAdmin/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";

export default function AddClientsId() {
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const { id } = useParams();
  const client = useSelector((state) => state.client.clientById[id]);
  useEffect(() => {
    dispatch(getClientById(id, token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Client Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/client")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Client</span>
            </Button>
            <Button
              onClick={() => history.push("/superadmin/add-clients")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Add Client</span>
            </Button>
            <span style={{ fontSize: 20 }}>{ client?.Name }</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AddClientsIdMenu client={client} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
