import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { fetchNotificationById } from "../../../../redux/actions/SuperAdmin/informationActions";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import EDIT_ICON from "../../../../assets/images/create.png";
import ModalDeleteNotification from "../../../../components/Business/_notification/ModalDeleteNotification/ModalDeleteNotification";
import { LayoutDetailNotification } from "../../../../components/Business/_notification";

export default function DetailNotification() {
    const token = localStorage.getItem('accessToken');
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { notificationById, loadingNotification, loadingUpdateInformation } = useSelector(
        (state) => state.informations
    );
    // const information = useSelector((state) => location?.state?.data);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPage, setTotalPage] = useState(1);
    const [trigger, setTrigger] = useState()

    const [selectedInformation, setSelectedInformation] = useState({}); //

    const schedulePagination = useMemo(() => {
        if (notificationById?.schedule?.length > 0){
            const startNumber = ((page - 1) * limit) + 1
            const lastNumber = page * limit
            let arrSch = []
            const temp = [...notificationById?.schedule]
    
            for(let i = startNumber; i <= lastNumber; i++ ){
                if (temp[i-1]){
                    arrSch.push({
                        ...temp[i-1],
                        no: i
                    })
                }
            }
    
            return arrSch
        } else {
          return []
        }
    }, [notificationById?.schedule, page, limit])

    useEffect(() => {
        if (loadingUpdateInformation === false){
            dispatch(fetchNotificationById(token, id));
        }
    }, [token, dispatch, id, trigger, loadingUpdateInformation]);

    useEffect(() => {
        if (notificationById?.schedule?.length > 0){
            const size = notificationById?.schedule?.length || 0
            const total = Math.ceil(size / limit)
            setTotalPage(total > 0 ? total : 1)
        }
    }, [notificationById?.schedule, limit]);

    return (
        <Container maxWidth="xl">
            <div className="container-client-settings">
                <Helmet>
                    <title>MyWorkSpace | Detail Notification</title>
                </Helmet>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Breadcrumb>
                        <Button
                            onClick={() => {
                                history.push('/admin-business/notification');
                            }}
                            style={{ textTransform: 'none' }}>
                            <span style={{ fontSize: 20, color: '#255bfc' }}>Notification</span>
                        </Button>
                        <span style={{ fontSize: 20, color: '#255bfc' }}>Detail Notification</span>
                    </Breadcrumb>
                    <div className="item-edit-delete-settings" style={{ display: 'flex', gap: 10 }}>
                        <Button
                            sx={{ '&:hover': { backgroundColor: 'transparent' }, padding: 0 }}
                            onClick={() => {
                                history.push({
                                    pathname: `/admin-business/notification-edit/${id}`,
                                    // state: {
                                    //     data: information,
                                    // },
                                });
                            }}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 6,
                                cursor: 'pointer',
                            }}>
                            <span
                                style={{
                                    fontSize: 14,
                                    color: '#1571DE',
                                    textTransform: 'capitalize',
                                }}>
                                Edit
                            </span>
                            <img src={EDIT_ICON} alt="edit" style={{ height: '17px', width: '15px' }} />
                        </Button>
                        <Button
                            sx={{ '&:hover': { backgroundColor: 'transparent' }, padding: 0 }}
                            onClick={(event) => {
                                event.preventDefault();
                                setSelectedInformation(notificationById);
                            }}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 6,
                                cursor: 'pointer',
                                marginRight: 8,
                            }}>
                            <span
                                style={{
                                    fontSize: 14,
                                    color: '#D1421A',
                                    textTransform: 'capitalize',
                                }}>
                                Delete
                            </span>
                            <img src={DELETE_ICON} alt="delete" style={{ height: '17px', width: '15px' }} />
                        </Button>
                    </div>
                </div>
                {selectedInformation?._id && (
                    <ModalDeleteNotification
                        active={selectedInformation}
                        onOpen={setSelectedInformation}
                        description="Apakah kamu yakin ingin menghapus notifikasi ini?"
                        label="Hapus Notifikasi"
                        pathName={
                            '/admin-business/notification'
                        }
                    />
                )}

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <LayoutDetailNotification
                            data={notificationById}
                            notifInformation={schedulePagination}
                            page={page}
                            setPage={setPage}
                            totalPage={totalPage}
                            setTotalPage={setTotalPage}
                            limit={limit}
                            setLimit={setLimit}
                            setTrigger={setTrigger}
                        />
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}
