import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/boxicons-2.0.7/css/boxicons.min.css";
// import "./assets/css/grid.css";
import "./assets/css/index.css";
import 'swiper/swiper.min.css'
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import theme from "./components/utils/theme";

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
