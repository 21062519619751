/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import "./ApprovedHistory.css";
import { filter } from "lodash";
import {
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  FormControl,
  Select,
  MenuItem,
  styled,
  TextField,
  Autocomplete
} from "@mui/material";

import USERLIST from "../../../../../components/SuperAdmin/_user/TableUsers/_mocks/user";
import Scrollbar from "../../../_user/TableUsers/Scrollbar";
import UserListHead from "../../../_user/TableUsers/UserListHead";
import SearchbarSA from "../../../../utils/SearchbarSA/SearchbarSA";
import ExportCSV from "../../../_attendance/tabs/DataAttendance/ExportData";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import DateRangePickerComponent from "../../../../utils/DateRangePicker/DateRangePicker";
import { UseCompanyName } from "../../../../utils/hooks";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  approvingLoan,
  fetchDataHistoryLoanByRangeDate,
  rejectingLoan,
} from "../../../../../redux/actions/SuperAdmin/loanActions";
import LoadingAnimation from "../../../../utils/LoadingAnimation/LoadingAnimation";
import ModalPassword from "../../../_payslip/ModalPassword/ModalPassword";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8
    },
  },
});

const TABLE_HEAD = [
  { id: "Name", label: "Employee Name", alignRight: false },
  { id: "Client", label: "Company", alignRight: false },
  { id: "NotificationTime", label: "Apply Date", alignRight: false },
  { id: "TenorMonth", numeric: true, label: "Tenor", alignRight: false },
  { id: "StatusRequested", label: "Status", alignRight: false },
  // { id: "NotificationTime", label: "Status Date", alignItems: "center" },
  // { id: "action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ApprovedHistory({
  setDropdownSelectedStatus2,
  dropdownSelectedStatus2,
  dropdownListGroup,
  setDropdownListGroup,
  dataApprovedHistory,
}) {
  const { loadingDataLoanHistory } = useSelector((state) => state.loan)
  const token = localStorage.getItem("accessToken");
  const clientName = localStorage.getItem("client");
  const dispatch = useDispatch();
  const employeeName = UseCompanyName();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [selected, setSelected] = useState(clientName);
  const [selectedInput, setSelectedInput] = useState("")
  const [employee, setEmployee] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isActive, setIsActive] = useState(false);
  const options2 = ["Approved", "Waiting", "Rejected"];
  const [showBox, setShowBox] = useState(false);
  const options3 = ["All", "Permata Indo Sejahtera", "Skills.id"];
  const startDate = moment().format("LL");
  const endDate = moment().format("LL");
  const [valueStartDate, setValueStartDate] = useState(startDate);
  const [valueEndDate, setValueEndDate] = useState(endDate);
  const [listDataHistory, setListDataHistory] = useState("");
  const [modal, setModal] = useState(false);
  const [arrayData, setArrayData] = useState(false);

  const persistClientName = useMemo(() => {
    let persistClient = window.sessionStorage.getItem('selectedClientLoan');
    let clientName;
 
    if (persistClient) {
     clientName = persistClient  
    } else {
     clientName = selected
    }
 
    return clientName;
   },[selected])

  const dateValueStart = useMemo(() => {
    let sessionStartDate = window.sessionStorage.getItem('startDateLoanHistory');
    let firstDate;

    if (sessionStartDate) {
      firstDate = sessionStartDate;
    } else {
      firstDate = valueStartDate
    }
    
    return firstDate
  },[valueStartDate])


  const dateValueEnd = useMemo(() => {
    let sessionEndDate = window.sessionStorage.getItem('endDateLoanHistory');
    let secondDate;

    if (sessionEndDate) {
      secondDate = sessionEndDate;
    } else {
      secondDate = valueEndDate
    }
    
    return secondDate
  },[valueEndDate])

  useEffect(() => {
    if(arrayData) {
      dispatch(fetchDataHistoryLoanByRangeDate(
        token,
        new Date(dateValueStart).getTime(),
        new Date(dateValueEnd).getTime(),
        persistClientName
      ))
    } else {
      setModal(false);
    }
  },[arrayData, dateValueEnd, dateValueStart, persistClientName, dispatch, token])

  useEffect(() => {
    if(persistClientName === "PT. Permata Indo Sejahtera") {
      setModal(true);
    } else {
      dispatch(
        fetchDataHistoryLoanByRangeDate(
          token,
          new Date(dateValueStart).getTime(),
          new Date(valueEndDate).getTime(),
          persistClientName
        )
      );
    }

  }, [valueStartDate, valueEndDate, selected, dateValueStart,token , persistClientName, dispatch]);

  const handleChangeStartDate = (newValue) => {
    setArrayData(true);
      setValueStartDate(newValue);
      window.sessionStorage.setItem("startDateLoanHistory", newValue);
  };

  const handleChangeEndDate = (newValue) => {
    setArrayData(true);
      setValueEndDate(newValue);
      window.sessionStorage.setItem("endDateLoanHistory", newValue);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );
  const handleOpen = (option) => {
    if (option === "Internal" || option === "Eksternal") {
      setEmployee(option);
      // setDropdownSelected2(option);
      setIsActive(false);
      setShowBox(true);
    } else if (option === "All") {
      setShowBox(false);
      setEmployee(option);
      // setDropdownSelected2(option);
      setIsActive(false);
    } else {
      setIsActive(false);
      setEmployee(option);
      // setDropdownSelected2(option);
    }
  };

  const handleSearchInput = (event) => {
    setListDataHistory(event.target.value);
  };
  
  const handleApprove = (id, status) => {
    const dataApprove = {
      status,
    };
    dispatch(approvingLoan(token, id, dataApprove));
  };

  const handleReject = (id, status) => {
    const dataReject = {
      status,
    };
    dispatch(rejectingLoan(token, id, dataReject));
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSelectedClient = (value) => {
    setArrayData(false);
    setSelected(value);
    window.sessionStorage.setItem("selectedClientLoan", value);
  };

  const handleSearch = (value) => {
    setSelectedInput(value);
  };

  return (
    <div className="container-data-loan">
      <div className="wrapper__setting-data-loan">
        <div className="setting-menu-left-data-loan">
          <SearchbarSA value={listDataHistory} onChange={handleSearchInput} />
        </div>
        <div className="data-loan-menu-right">
          <DateRangePickerComponent
            valueStartDate={dateValueStart}
            valueEndDate={dateValueEnd}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
          />
        </div>
        <div className="data-attendance-menu-right">
              <Autocomplete
              disablePortal
              options={employeeName}
              value={persistClientName}
              inputValue={selectedInput}
              onInputChange={(e, value) => value === null ? handleSearch("") : handleSearch(value)}
              onChange={(e, value) => value === null ? handleSelectedClient("") : handleSelectedClient(value)}
              renderInput={(params) => <MuiTextField {...params} size="small" sx={{ width: 190}}/>}
            />
        </div>
        <div className="data-approved-history-menu-right-download">
          <ExportCSV
            data={dataApprovedHistory.map((el, ind) => ({
              "No.": ind + 1,
              "Employee Name": el.Name,
              Company: el.Client,
              "Apply Date": el.NotificationTime
                ? moment(el.NotificationTime).format("L")
                : "-",
              Tenor: el.TenorMonth ? el.TenorMonth + " Month" : "-",
              Status: el.StatusRequested
                ? capitalizeFirstLetter(el.StatusRequested)
                : "-",
              "Status Date": el.NotificationTime
                ? moment(el.NotificationTime).format("L")
                : "-",
            }))}
            filename={`Approved History Report ${new Date().toLocaleDateString()}`}
            initial="Download Excel"
          />
        </div>
      </div>
      {showBox ? (
        <div className="wrapper-box-show-another">
          <span className="text-box">Company</span>
          <div className="box-show-another">
            <div className="box-group-selected">
              <div className="">
                <FormControl>
                  <Select
                    value={dropdownListGroup}
                    displayEmpty
                    // onChange={(e) => handleOpen(e.target.value)}
                    SelectDisplayProps={{
                      style: {
                        paddingTop: 8,
                        paddingBottom: 8,
                        width: 230,
                        // height: 40,
                        backgroundColor: "#fbfbfb",
                      },
                    }}
                  >
                    {options3.map((option, el) => (
                      <MenuItem
                        key={el}
                        // onClick={() => handleOpen(option)}
                        onClick={() => setDropdownListGroup(option)}
                        value={option}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="">
              <FormControl>
                <Select
                  value={dropdownSelectedStatus2}
                  displayEmpty
                  // onChange={(e) => handleOpen(e.target.value)}
                  SelectDisplayProps={{
                    style: {
                      paddingTop: 8,
                      paddingBottom: 8,
                      width: 70,
                      // height: 40,
                      backgroundColor: "#fbfbfb",
                    },
                  }}
                >
                  {options2.map((option, el) => (
                    <MenuItem
                      key={el}
                      onClick={() => setDropdownSelectedStatus2(option)}
                      // onClick={() => handleOpen(option)}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      ) : null}

      {
         loadingDataLoanHistory ? (
          <div style={{ display: 'flex', alignItems: "center", justifyContent:'center', marginTop: 100}}>
          <div style={{ width: 200}}>
            <LoadingAnimation />
          </div>
        </div>
         ) : (
        <div className="wrapper-table-data-loan">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 600 }}>
              <Table
                sx={{
                  width: "100%",
                  maxHeight: "100%",
                  backgroundColor: "#fafafa",
                  borderRadius: 4,
                }}
              >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataApprovedHistory.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(dataApprovedHistory, getComparator(order, orderBy))
                    .filter(
                      (el) =>
                        el.Name?.toLowerCase().includes(
                          listDataHistory?.toLowerCase()
                        ) ||
                        el.Client?.toLowerCase().includes(
                          listDataHistory?.toLocaleLowerCase()
                        ) ||
                        el.StatusRequested?.toLowerCase().includes(
                          listDataHistory?.toLocaleLowerCase()
                        ) || 
                        (el.TenorMonth + " Month")?.toString().toLowerCase().includes(
                          listDataHistory?.toLocaleLowerCase()
                        ) ||
                        moment(el.NotificationTime).format("L").toLocaleLowerCase().includes(
                          listDataHistory?.toLocaleLowerCase()
                        ) 
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    // .sort((a, b) => new Date(a.NotificationTime) - new Date(b.NotificationTime))
                    .map((data, id) => {
                      const labelId = `enhanced-table-checkbox-${id}`;
                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell></TableCell>
                          <TableCell component="th" scope="row" padding="none" id={labelId}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                src={data.ProfilePicture ? data.ProfilePicture : ""}
                              >
                                {data.Name.substr(0, 1)}
                              </Avatar>
                              <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize'}}>
                                {data.Name.toLowerCase()}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{data.Client}</TableCell>
                          <TableCell align="left">
                            {moment(data.NotificationTime).format("L")}
                          </TableCell>
                          <TableCell align="left">
                            {data.TenorMonth ? data.TenorMonth + " Month": "-"}
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              className="status__style"
                              style={{
                                backgroundColor:
                                  (data.StatusRequested === "approved" &&
                                    "#498E0F") ||
                                  (data.StatusRequested === "pending" &&
                                    "#F1A073") ||
                                  (data.StatusRequested === "rejected" &&
                                    "#D1421A"),
                              }}
                            >
                              {(data.StatusRequested === "approved" &&
                                "Approved") ||
                                (data.StatusRequested === "pending" &&
                                  "Pending") ||
                                (data.StatusRequested === "rejected" &&
                                  "Rejected")}
                            </Typography>
                          </TableCell>
                          {/* {data.StatusRequested === "pending" ? (
                            <TableCell align="center">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={0}
                              >
                                <Button
                                  style={{ textTransform: "none" }}
                                  startIcon={<DoneIcon />}
                                  onClick={() =>
                                    handleApprove(data._id, "approved")
                                  }
                                >
                                  Approve
                                </Button>
                                <Button
                                  style={{
                                    textTransform: "none",
                                    color: "#D1421A",
                                  }}
                                  startIcon={<CloseIcon />}
                                  onClick={() =>
                                    handleReject(data._id, "rejected")
                                  }
                                >
                                  Rejected
                                </Button>
                              </Stack>
                            </TableCell>
                          ) : (
                            <TableCell align="center">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={0}
                              >
                                <Typography variant="body2">
                                  {moment(data.NotificationTime).format("L")}
                                </Typography>
                              </Stack>
                            </TableCell>
                          )} */}
                        </TableRow>
                      );
                    })}
                  {/* {dataApprovedHistory
                      .filter(
                        (el) =>
                          el.Name?.toLowerCase().includes(
                            listDataHistory?.toLowerCase()
                          ) ||
                          el.Client?.toLowerCase().includes(
                            listDataHistory?.toLocaleLowerCase()
                          ) || 
                          el.StatusRequested?.toLowerCase().includes(
                            listDataHistory?.toLocaleLowerCase()
                          )
                      )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .sort((a,b ) => (new Date(a.NotificationTime) - new Date(b.NotificationTime)))
                    .map((data, id) => {
                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell></TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                src={ data.ProfilePicture ? data.ProfilePicture : ""}
                              >
                              {data.Name.substr(0,1)}
                              </Avatar>
                              <Typography variant="subtitle2" noWrap>
                                {data.Name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{data.Client}</TableCell>
                          <TableCell align="left">
                            {moment(data.NotificationTime).format("L")}
                          </TableCell>
                          <TableCell align="left">
                            Tenor {data.LoanAmount?.Tenor}
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              className="status__style"
                              style={{
                                backgroundColor:
                                  (data.StatusRequested === "approved" && "#498E0F") ||
                                  (data.StatusRequested === "pending" &&"#F1A073") ||
                                  (data.StatusRequested === "rejected" &&"#D1421A"),
                              }}
                            >
                            {(data.StatusRequested === "approved" && "Approved") || (data.StatusRequested === "pending" && "Waiting") || (data.StatusRequested === "rejected" && "Rejected")}
                            </Typography>
                          </TableCell>
                          {data.StatusRequested === "pending" ? (
                            <TableCell align="center">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={0}
                              >
                                <Button
                                  style={{ textTransform: "none" }}
                                  startIcon={<DoneIcon />}
                                  onClick={() =>
                                    handleApprove(data._id, "approved")
                                  }
                                >
                                  Approve
                                </Button>
                                <Button
                                  style={{
                                    textTransform: "none",
                                    color: "#D1421A",
                                  }}
                                  startIcon={<CloseIcon />}
                                  onClick={() => handleReject(data._id, 'rejected')}
                                >
                                  Rejected
                                </Button>
                              </Stack>
                            </TableCell>
                          ) : (
                            <TableCell align="center">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={0}
                              >
                                <Typography variant="body2">
                                  {moment(data.NotificationTime).format("L")}
                                </Typography>
                                </Stack>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={dataApprovedHistory.filter(
              (el) =>
                el.Name?.toLowerCase().includes(
                  listDataHistory?.toLowerCase()
                ) ||
                el.Client?.toLowerCase().includes(
                  listDataHistory?.toLocaleLowerCase()
                ) ||
                el.StatusRequested?.toLowerCase().includes(
                  listDataHistory?.toLocaleLowerCase()
                )
            ).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
         )
      }
            {
        modal && (
          <ModalPassword showModal={modal} setModal={setModal} setArrayData={setArrayData} arrayData={arrayData} validPassword="loan_pis"/>
        )
      }
    </div>
  );
}
