import axios from "axios";
import {API} from "../../redux/api";

const updateEmployee = (token, employeeId, payload) =>
  new Promise(async (resolve, reject) => {
    try {
      const { response } = await axios.patch(`${API}/profile/admin/${employeeId}`, payload ,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      resolve( response );
    } catch (error) {
      reject(error);
    }
  });

export default updateEmployee;
