import axios from "axios";
import {API} from "../../redux/api";

const editLeavePlan = (token,dataToSend,id) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { response },
      } = await axios({
        method: "patch",
        headers: { Authorization: `${token}` },
        url: `${API}/leave-plan/${id}`,
        data:dataToSend
      });
      resolve(response);
      // console.log(response)
    } catch (error) {
      reject(error);
    }
  });

export default editLeavePlan;
