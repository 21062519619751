import React from "react";
import "./DetailDraft.css";
export default function DetailPublished({ draftById = {} }) {
  console.log(draftById.Title, 'cek faq by id')
  return (
    <div className="container-DD">
      <div className="wrapper-DD">
        <div className="wrapper-text-DD">
          <span>{draftById?.Title}</span>
          <label>{draftById?.FAQTime}</label>
        </div>
        <div className="input-DD">
            <span>
              {draftById?.Content}
            </span>
        </div>
      </div>
    </div>
  );
}
