/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "160px",
};

const center = {
  lat: -6.251865859999997,
  lng: 106.72514342999997,
};

function MapsComponent({ defaultPosition, show, Lat, Long }) {
  const [coordinates, setCoordinates] = useState({ lat: Lat, long: Long });
  const mapRef = useRef(null);
  // const obj = Object.assign({}, coordinates)
  // const defaultProps = {
  //   center: {
  //     lat: show?.Latitude,
  //     lng: show?.Longitude,
  //   },
  //   zoom: 11,
  // };
  // const [coordinates, setCoordinates] = useState([
  //   show.Latitude,
  //   show.Longitude,
  // ]);
  const [map, setMap] = React.useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    // googleMapsApiKey: "AIzaSyD7MlF48Y8h35vyENxB5HgKnjGOYfBvuw4",
    googleMapsApiKey: "AIzaSyB0Gy-BuZ0XyKlvhNtuhRPJh4RGdfQOQgE",
    // googleMapsApiKey: "AIzaSyArOQ2RTtRBLVZ9KG1Sf2hcb9eSMbiKA5c",
  });

  useEffect(() => {
    setCoordinates(coordinates);
    // console.log(coordinates?.long, "cek lat ");
  }, [Lat, Long, coordinates]);

  const onMapLoad = useCallback(
    (map) => {
      navigator.geolocation.getCurrentPosition(
        (position) =>
          panTo({
            lat: coordinates?.Lat,
            lng: coordinates?.Long,
          }),
        () => null
      );
      mapRef.current = map;
    },
    // eslint-disable-next-line no-use-before-define
    [coordinates?.Lat, coordinates?.Long, panTo]
  );

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(20);
    try {
    } catch (error) {}
  }, []);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // console.log(coordinates.Lang, 'cek');
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      // center={ center }
      center={{ lat: parseFloat(Lat), lng: parseFloat(Long) }}
      zoom={20}
      onLoad={onMapLoad}
      // onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {/* <Marker position={ center }/> */}
      <Marker position={{ lat: parseFloat(Lat), lng: parseFloat(Long)}} />
      {/* <></> */}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MapsComponent);
