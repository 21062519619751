import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Styles from './ModalAddPushNotification.module.css';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import CustomTable from '../../../../CustomTable/CustomTable';
import { Add, Delete, ControlPoint } from "@mui/icons-material";
import NOTIFICATION_IMG from "../../../../../assets/images/notification.png"

export default function ModalAddPushNotification({ type, submit, value, onClose }) {
    // const [date, setDate] = useState(dayjs());
    // const [time, setTime] = useState(new Date().toLocaleTimeString('en', { hour12: false }));
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState("")
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [totalPage, setTotalPage] = useState(1)

    const [dataSchedule, setDataSchedule] = useState([])

    const [lastID, setLastID] = useState(1)

    // const handleChangeClock = (value) => {
    //     const temp = new Date(value).toLocaleTimeString('en', { hour12: false });
    //     setTime(temp);
    // };

    // const handleChangeDate = (value) => {
    //     setDate(value);
    // };

    const entryHour = (timeString) => {
        const today = new Date();
        const arrEntryHour = timeString?.split(':');
        today.setUTCHours(+arrEntryHour[0] - 7, +arrEntryHour[1], +arrEntryHour[2], 0);
        return today;
    };

    // useEffect(() => {
    //     console.log('AIM', dataSchedule)
    // }, [dataSchedule])
    
    const handleItemSchedule = useCallback((typeHandle, valueSch) => {
        if (typeHandle === 'ADD') {
            setDataSchedule((prev) => {
                return [
                    ...prev,
                    {
                        // id: prev?.length ? prev?.length + 1 : 1,
                        id: lastID,
                        date: null,
                        time: null,
                        // date: dayjs(),
                        // time: new Date().toLocaleTimeString('en', { hour12: false }),
                        status: 'Waiting',
                    },
                ];
            });
            setLastID(lastID + 1)
        } else if (typeHandle === 'EDIT') {
            setDataSchedule((prev) => {
                const idx = prev?.findIndex((f) => f?.id === valueSch?.id)
                prev[idx] = valueSch;
                return [...prev]
            });
        } else if (typeHandle === 'DELETE') {
            setDataSchedule((prev) => {
                const arr = prev?.filter((f) => f?.id !== valueSch?.id);
                return [...arr]
            });
        }
    }, [lastID]); 

    const tableHead = useMemo(() => {
        return[
            { id: "no", label: "No", alignRight: false, width: '10%' },
            { id: "date", label: "Tanggal", alignRight: false, width: '30%',
                renderData: (row) => (
                    <div className={Styles.TableRowComponent}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={row?.date}
                                    onChange={(e) => (handleItemSchedule("EDIT", {...row, date: e}))}
                                    disabled={row?.status === "Published"}
                                    renderInput={(params) => (
                                        <TextField 
                                            {...params} 
                                            size="small"    
                                            autoComplete="off" 
                                            fullWidth
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: 'Pilih Tanggal'
                                            }} 
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                  border: !row?.date && error ? "1px solid #df2222" : "none",
                                                  padding: 0,
                                                  display: "flex",
                                                  flexDirection: "row-reverse",
                                                  "& fieldset": {
                                                    display: "none", // Menghilangkan border
                                                  },
                                                },
                                                "& .MuiInputAdornment-root": {
                                                  marginRight: 0,
                                                  marginLeft: 0
                                                },
                                            }}
                                        />
                                    )}
                                    minDate={dayjs()}
                                />
                            </LocalizationProvider>
                        </div>
                )
            },
            { id: "time", label: "Jam", alignRight: false, width: '30%',
                renderData: (row) => (
                    <div className={Styles.TableRowComponent}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    disabled={row?.status === "Published"}
                                    ampm={false}
                                    value={row?.time ? entryHour(row?.time) : null}
                                    onChange={(e) => {
                                        const temp = new Date(e).toLocaleTimeString('en', { hour12: false });
                                        handleItemSchedule("EDIT", {...row, time: temp})}
                                    }
                                    minTime={row?.date && row?.date?.isSame(dayjs(), 'day') ? dayjs() : null}
                                    renderInput={(params) => (
                                        <TextField 
                                            {...params} 
                                            size="small" 
                                            fullWidth 
                                            aria-readonly
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: 'Pilih Waktu'
                                            }} 
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                  border: !row?.time && error ? "1px solid #df2222" : "none",
                                                  padding: 0,
                                                  display: "flex",
                                                  flexDirection: "row-reverse",
                                                  "& fieldset": {
                                                    display: "none", // Menghilangkan border
                                                  },
                                                },
                                                "& .MuiInputAdornment-root": {
                                                  marginRight: 0,
                                                  marginLeft: 0
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                )
            },
            { id: "status", label: "Status", alignRight: false, width: '10%',
              renderData: (row) => (
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 400,
                    }}
                    >
                    {row?.status
                        ? <span className={Styles[row.status]}>{row.status}</span>
                        : <span></span>}
                    </Typography>
              )
             },
            { 
              id: "", 
              width: '10%', 
              renderData: (row) => {
                return (
                  <div>
                      <IconButton
                            aria-label="add-notif"
                            color="error"
                            sx={{
                                gap: '10px',
                                cursor: 'pointer',
                                fontSize: '14px',
                                fontWeight: '500',
                            }}
                            disabled={row?.status === "Published"}
                            onClick={(e) => (handleItemSchedule("DELETE", row))}>
                            <Delete fontSize="small" />
                        </IconButton>
                  </div>
                )
              }},
          ];
    }, [handleItemSchedule, error])

    const handleChange = (e, column, maxCharacter = 0) => {
        const inputValue = e.target.value

        if (column === "TITLE"){
            if (maxCharacter > 0 && inputValue?.length <= maxCharacter){
                setTitle(inputValue)
            }
        }
        else if (column === "DESCRIPTION"){
            if (maxCharacter > 0 && inputValue?.length <= maxCharacter){
                setDescription(inputValue)
            }
        }
    }
        
    const handleSubmit = () => {
        const currentDate = new Date();

        for (let item of dataSchedule){
            if (!item?.date || !item?.time){
                setError(`Select the date/time${item?.no ? ` for schedule number ${item?.no} ` : ''}.`)
                return
            }
            const inputDate = new Date(item?.date);    
            const [inputHour, inputMinute, inputSecond] = item?.time?.split(":").map(Number);
            if (
                item?.status === "Waiting" &&
                inputDate.toDateString() === currentDate.toDateString() &&
                (inputHour < currentDate.getHours() || 
                (inputHour === currentDate.getHours() && inputMinute < currentDate.getMinutes()) || 
                (inputHour === currentDate.getHours() && inputMinute === currentDate.getMinutes() && inputSecond < currentDate.getSeconds()))
            ) {
                setError(`Cannot select a date/time in the past${item?.no ? ` for schedule number ${item?.no} ` : ''}!`);
                return;
            }
        }

        if (!description){
            setError("Description can't be empty!");
            return;
        } else if (!title){
            setError("Title can't be empty!");
            return;
        }

        submit({ 
            title,
            description,
            schedule: dataSchedule,
            id: (type === 'EDIT' ? value?.id : undefined)
        });
        onClose();
    }

    useEffect(() => {
        if (value && type === 'EDIT') {
            setTitle(value?.title)
            setDescription(value?.description);
            // setDate(value?.date);
            // setTime(value?.time);
            const mapSchedule = value?.schedule?.map((value, index) => {
                return {
                    ...value,
                    id: index + 1,
                    date: value?.date,
                    time: value?.time,
                    status: value?.status || ""
                }
            })
            setLastID(value?.schedule?.length > 0 ? value?.schedule?.length + 1 : 1)
            setDataSchedule(mapSchedule)
        }
    }, [type, value]);

    useEffect(() => {
        if (dataSchedule?.length > 0){
            const size = dataSchedule?.length || 0
            const total = Math.ceil(size / rowsPerPage)
            setTotalPage(total > 0 ? total : 1)
        }
    }, [dataSchedule, rowsPerPage])

    const schedulePagination = useMemo(() => {
        if (dataSchedule?.length > 0){
            const startNumber = ((page - 1) * rowsPerPage) + 1
            const lastNumber = page * rowsPerPage
            let arrSch = []
            const temp = [...dataSchedule]

            for(let i = startNumber; i <= lastNumber; i++ ){
                if (temp[i-1]){
                    arrSch.push({
                        ...temp[i-1],
                        no: i
                    })
                }
            }

            return arrSch
        } else {
            return []
        }
    }, [dataSchedule, page, rowsPerPage])

    return (
        <div className={Styles.Modal}>
            <div className={Styles.ModalContainer}>
                <div className={Styles.ModalBody}>
                    <div className={Styles.Title}>
                        <Add />
                        <span>{type === 'EDIT' ? 'Ubah Jadwal' : 'Buat Jadwal Notifikasi'}</span>
                    </div>
                    <div className={Styles.Component}>
                        <span>Judul Notifikasi</span>
                        <input
                            placeholder="Tuliskan judul notifikasi"
                            value={title || ''}
                            style={{ paddingLeft: 5 }}
                            onChange={(e) => handleChange(e, 'TITLE', 100)}
                            name="title"
                            required
                        />
                        <div className={Styles.MaxCharacter}>{title?.length}/100</div>
                    </div>
                    <div className={Styles.Component}>
                        <span>Deskripsi</span>
                        <textarea
                            placeholder="Tuliskan deskripsi notifikasi"
                            value={description || ''}
                            style={{ paddingLeft: 5 }}
                            onChange={(e) => handleChange(e, 'DESCRIPTION', 150)}
                            name="remark"
                            required
                        />
                        <div className={Styles.MaxCharacter}>{description?.length}/150</div>
                    </div>
                    <div className={Styles.Component}>
                        <div className={Styles.TitleComponent}>
                            <span>Jadwal Notifikasi</span>
                            <IconButton
                                aria-label="add-notif"
                                color="primary"
                                sx={{
                                    gap: '4px',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                }}
                                disableRipple={true}
                                onClick={() => handleItemSchedule('ADD')}>
                                <ControlPoint fontSize="small" />
                                <span>Tambah Jadwal</span>
                            </IconButton>
                        </div>
                    </div>
                    <CustomTable
                        maxHeight="300px"
                        tableHead={tableHead}
                        // bodyData={dataSchedule}
                        bodyData={schedulePagination}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        totalPage={totalPage}
                        customEmpty={() => {
                            return (
                                <div className={Styles.CenterEmptySetupPushNotification}>
                                    <div className={Styles.EmptySetupPushNotification}>
                                        <img
                                            src={NOTIFICATION_IMG}
                                            alt="notif-img"
                                            style={{ height: '70px', width: '100px' }}
                                            />
                                        <br />
                                        <span>Belum ada jadwal notifikasi ditambahkan</span>
                                        <IconButton
                                            aria-label="add-notif"
                                            color="primary"
                                            sx={{
                                                gap: '10px',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                            }}
                                            disableRipple={true}
                                            onClick={() => handleItemSchedule('ADD')}>
                                            <ControlPoint fontSize="small" />
                                            <span>Tambah Jadwal</span>
                                        </IconButton>
                                    </div>
                                </div>
                            );
                        }}
                        />
                    

                    {error && <div className={Styles.ErrorText}>{error}</div>}

                    <div className={Styles.ButtonLayout}>
                        <Button variant="outlined" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleSubmit}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
