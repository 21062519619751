import React from 'react'
import TopNavSA from '../SuperAdmin/TopNavSA/TopNavSA'
import NotFound404 from './NotFound404'

export default function NotFoundWithHeader() {
  return (
    <>
    <TopNavSA />
    <NotFound404 />
    </>
  )
}
