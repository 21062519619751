import axios from "axios";
import * as types from '../../type'
import { setSnackbar } from "./snackbarActions";
import {API} from "../../api";

export const fetchAllSchemaLoan = (token) => {
  return (dispatch) => {
    // https://dev.myworkspace.id/api/v3/staff-loan-plan
    dispatch({ type: types.FETCH_ALL_SCHEMA_LOAN_BEGIN})
    axios
      .get(`${API}/staff-loan-plan`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_SCHEMA_LOAN_SUCCESS,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_ALL_SCHEMA_LOAN_ERROR, payload: err.response })
        console.log(err);
      });
  };
};

export const fetchSchemaLoanById = (token, id) => {
  return (dispatch) => {
    // https://dev.myworkspace.id/api/v3/staff-loan-plan/63b56846c205c73e3df95304
    axios
      .get(`${API}/staff-loan-plan/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_SCHEMA_LOAN_BY_ID,
          payload: res.data.response,
        });
        // console.log(res.data.data, 'cek from loanActions')
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const createSchemaLoan = (token, data) => {
  return (dispatch) => {
    return axios
    // localhost:5050/api/v3//staff-loan-plan
      .post(`${API}/staff-loan-plan`, data, {
        headers: {
          Authorization: token,
        },
      })
      // .post(`${API}/schemaloan/loan/`, data, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // })
      .then((res) => {
        console.log(res)
        dispatch({
          type: types.CREATE_SCHEMA_LOAN,
          payload: res.data.data,
        });
      })
      // .then(() => {
      //   dispatch(
      //     setSnackbar({
      //       snackbarOpen: true,
      //       snackbarType: "success",
      //       snackbarMessage: "Loan created successfully",
      //     })
      //   );
      //   return Promise.resolve();
      // })
      // .catch((err) => {
      //   console.log(err);
      //   dispatch(
      //     setSnackbar({
      //       snackbarOpen: true,
      //       snackbarType: "error",
      //       snackbarMessage: err.response.data.message,
      //     })
      //   );
      //   return Promise.reject(err);
      // });
  };
};

export const updateSchemaLoan = (token, id, data) => {
  return (dispatch) => {
    return axios
    .patch(`${API}/staff-loan-plan/${id}`, data, {
      headers: {
        Authorization: token,
      },
    })
      // .patch(`${API}/schemaloan/loan/${id}`, data, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // })
      .then((res) => {
        dispatch({
          type: types.UPDATE_SCHEMA_LOAN,
          payload: res.data.response,
        });
      })
      // .then(() => {
      //   dispatch(
      //     setSnackbar({
      //       snackbarOpen: true,
      //       snackbarType: "success",
      //       snackbarMessage: "Loan updated successfully",
      //     })
      //   );
      //   return Promise.resolve();
      // })
      // .catch((err) => {
      //   console.log(err);
      //   dispatch(
      //     setSnackbar({
      //       snackbarOpen: true,
      //       snackbarType: "error",
      //       snackbarMessage: err.response.data.message,
      //     })
      //   );
      //   return Promise.reject(err);
      // });
  };
};

export const deleteSchemaLoan = (token, id) => {
  return (dispatch) => {
    axios
      .delete(`${API}/staff-loan-plan/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.DELETE_SCHEMA_LOAN,
          payload: res.data.response,
        });
      })
      // .then(() => {
      //   dispatch(
      //     setSnackbar({
      //       snackbarOpen: true,
      //       snackbarType: "success",
      //       snackbarMessage: "Loan deleted successfully",
      //     })
      //   );
      // })
      .then(() => {
        dispatch(fetchAllSchemaLoan(token));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// PAYMENT LOAN FROM IOS
export const fetchPaymentLoanById = (token, id) => {
  return (dispatch) => {
    axios
      .get(`${API}/loan/paymentLoan/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_PAYMENT_LOAN_FROM_IOS,
          payload: res.data.data,
        });
        // console.log(res.data.data, 'cek data payments')
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// DATA SCHEMA LOAN BY ID USER
export const fetchSchemaLoanByIdUser = (token, id) => {
  return (dispatch) => {
    axios
      .get(`${API}/loan/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_DATA_LOAN_BY_USER_ID,
          payload: res.data.data,
        });
        // console.log(res.data.data, 'cek form actions')
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// ON GOING LOAN
export const fetchAllOnGoingLoan = (token) => {
  return (dispatch) => {
    axios
      .get(`${API}/loan`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_ON_GOING_LOAN,
          payload: res.data.data,
        });
        // console.log(res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// APPROVED HISTORY LOAN
export const fetchAllApprovedHistoryLoan = (token) => {
  return (dispatch) => {
    axios
      .get(`${API}/loan/history`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_APPROVED_LOAN_HISTORY,
          payload: res.data.data,
        });
        // console.log(res.data.data, 'cek approved history')
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// APRROVING LOAN
export const approvingLoan = (token, id, body) => {
  return (dispatch) => {
    return axios
      .patch(`${API}/loan/giveResponse/${id}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.APPROVE_LOAN,
          payload: res.data.data,
        });
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Loan approved successfully",
          })
        );
        return Promise.resolve(); // kondisi jika berhasil
      })
      .then(() => {
        dispatch(fetchAllApprovedHistoryLoan(token));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.message,
          })
        );
        return Promise.reject(err);
      });
  };
};

// REJECTING LOAN
export const rejectingLoan = (token, id, body) => {
  return (dispatch) => {
    return axios
      .patch(`${API}/loan/giveResponse/${id}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.APPROVE_LOAN,
          payload: res.data.data,
        });
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Loan rejected successfully",
          })
        );
        return Promise.resolve(); // kondisi jika berhasil
      })
      .then(() => {
        dispatch(fetchAllApprovedHistoryLoan(token));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.message,
          })
        );
        return Promise.reject(err);
      });
  };
};

// DATA LOAN BY RANGE DATE : ONGOING LOAN
export const fetchDataOnGoingLoanByRangeDate = (token, startDate, endDate, clientName) => {
  return (dispatch) => {
    // https://myworkspace.id/api/v3/staff-loan/?from=2022-10-01&to=2022-12-30&clientName=PT. PERMATA INDO SEJAHTERA
    dispatch({ type: types.FETCH_DATA_ONGOING_LOAN_BY_RANGE_DATE_BEGIN})
    axios
      .get(`${API}/staff-loan/admin?from=${startDate}&to=${endDate}&clientName=${clientName}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_DATA_ONGOING_LOAN_BY_RANGE_DATE_SUCCESS,
          payload: res.data.response.loans,
        });
      })
      .catch((err) => {
      dispatch({ type: types.FETCH_DATA_ONGOING_LOAN_BY_RANGE_DATE_ERROR, payload: err.response})
        console.log(err);
      });
  };
};

// DATA LOAN BY RANGE DATE : HISTORY LOAN
export const fetchDataHistoryLoanByRangeDate = (token, startDate, endDate, clientName) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_DATA_HISTORY_LOAN_BY_RANGE_DATE_BEGIN})
    axios
      .get(`${API}/loan/rangeApproval?start=${startDate}&end=${endDate}&client=${clientName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_DATA_HISTORY_LOAN_BY_RANGE_DATE_SUCCESS,
          payload: res.data.data,
        });
        // console.log(res.data.data, 'cek data')
      })
      .catch((err) => {
        dispatch({ type: types.FETCH_DATA_HISTORY_LOAN_BY_RANGE_DATE_ERROR, payload: err.response})
        console.log(err);
      });
  };
};

// DATA REPORT LOAN
export const fetchDataLoanReport = (token, companyName, startDate, endDate) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_DATA_LOAN_REPORT_BEGIN})
    axios
    .get(`${API}/loan/totalLoan?name=${companyName}&start=${startDate}&end=${endDate}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: types.FETCH_DATA_LOAN_REPORT_SUCCESS,
        payload: res.data
      });
      // console.log(res.data.data, 'cek data report')
    })
    .catch((err) => {
      dispatch({ type: types.FETCH_DATA_LOAN_REPORT_ERROR, payload: err.response})
      console.log(err);
    });
  };
};
