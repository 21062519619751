import axios from "axios";
import {API} from "../../redux/api";

const fetchClientCodeExternal = (token) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(`${API}/user/user-client`, {
        headers : {
          Authorization: token
        }
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export default fetchClientCodeExternal;
