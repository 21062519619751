import React  from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { useHistory } from "react-router-dom";
// import { useLocation } from "react-router";
import { PayslipUploadMenuSA } from "../../../../components/SuperAdmin/_payslip";

export default function PayslipUploadMenu() {
  const history = useHistory();
//   const location = useLocation();
//   const dataPayslip = location.state;
  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Employee Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/payslip")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Payslip</span>
            </Button>
            <span style={{ fontSize: 20 }}>Upload Pay Slip</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <PayslipUploadMenuSA />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
