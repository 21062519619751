import axios from 'axios';
import * as types from '../../type'
import {API} from "../../api";


export const fetchAllBanks = (token) => {
    return (dispatch) => {
        axios
            .get(`${API}/bank`, {
                headers: {
                    Authorization: token,
                }
            })
            .then((res) => {
                dispatch({
                    type: types.FETCH_ALL_BANKS_SUCCESS,
                    payload: res.data.response,
                });
            })
            .catch((err) => {
                dispatch({ type: types.FETCH_ALL_BANKS_ERROR, payload: err.response })
                console.log(err);
            });
    }
}

export const getBankById = (token, id) => {
    return (dispatch) => {
        axios
            .get(`${API}/bank/${id}`, {
                headers: {
                    Authorization: token,
                }
            })
            .then((res) => {
                dispatch({
                    type: types.GET_BANK_BY_ID,
                    payload: res.data.response,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }
}


export const addBank = (token, body) => {
    return (dispatch) => {
        axios
            .post(`${API}/bank`, body, {
                headers: {
                    Authorization: token,
                }
            })
            .then((res) => {
                dispatch({
                    type: types.ADD_BANK,
                    payload: res.data.response
                })
            })
            .then(() => {
                dispatch(fetchAllBanks(token))
            })
            .catch((err) => {
                console.log(err)
            })
    }
}

export const editBank = (token, id, body) => {
    return (dispatch) => {
        axios
            .patch(`${API}/bank/${id}`, body, {
                headers: {
                    Authorization: token,
                }
            })
            .then((res) => {
                dispatch({
                    type: types.EDIT_BANK,
                    payload: res.data.response
                })
            })
            .then(() => {
                dispatch(getBankById(token))
            })
            .catch((err) => {
                console.log(err)
            })
    }
}

export const deleteBank = (token, id) => {
    return (dispatch) => {
        axios
            .delete(`${API}/bank/${id}`, {
                headers: {
                    Authorization: token,
                }
            })
            .then((res) => {
                dispatch({
                    type: types.DELETE_BANK,
                    payload: res.data.response
                })
            })
            .then(() => {
                dispatch(fetchAllBanks(token))
            })
            .catch((err) => {
                console.log(err)
            })
    }
}