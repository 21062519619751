import React from "react";
// import "./EditClients.css";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
import { LoanDetails } from "../../../components/SuperAdmin/_loanData/LoanTabs";
// import { fetchPaymentLoanById, fetchSchemaLoanByIdUser } from "../../../redux/actions/SuperAdmin/loanActions";
// import { useDispatch } from "react-redux";
export default function LoanDataDetail() {
    // const token = localStorage.getItem("blogToken");
    const history = useHistory();
    // const dispatch = useDispatch();
    const location = useLocation();
    const loanDataById = location.state?.dataLoan;
    // const paymentData = useSelector((state) => state.loan.paymentLoanFromIos)
    const { id } = useParams();

    // useEffect(() => {
    //   dispatch(fetchSchemaLoanByIdUser(token, id));
    //   dispatch(fetchPaymentLoanById(token, id));
    // }, []);
  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Employee Benefits Program</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.go(-1)}
              style={{ border: "none", textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Salary Advance</span>
            </Button>
            <span style={{ fontSize: 20, }}>Submitter  Detail</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <LoanDetails 
            dataLoan={ loanDataById } 
            // paymentData={ paymentData }
             id={ id }/>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
