import moment from "moment";
import info_default_banner from "../../../../../src/assets/images/info_default_banner.jpg";
import Styles from "./DetailInformationSA.module.css";
import { AddCircle, Business, ControlPoint } from "@mui/icons-material";
import { Button, IconButton, Switch } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import fetchClientCode from "../../../../async/client/fetchClientCode";
import ChangePublishModal from "./ChangePublishModal/ChangePublishModal";
import ModalPublishedInfoDetails from "./PublishedInformation/ModalPublishedInfo";
import { useHistory, useParams } from "react-router-dom";
import { API_IMAGE } from "../../../../redux/api";
import NOTIFICATION_LOGO_IMG from "../../../../assets/images/notification-logo.png"
import NOTIFICATION_IMG from "../../../../assets/images/notification.png"
import CustomTable from "../../../CustomTable/CustomTable";
import ModalPreview from "../InformationTable/ModalPreview";
import ModalAddPushNotification from "../InformationTable/ModalAddPushNotification";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { addNotification, fetchNotifInformationById, updateInformationById, updateNotificationById } from "../../../../redux/actions/SuperAdmin/informationActions";

export default function DetailInformationSA({ data, notifInformation, page, setPage, limit, setLimit, totalPage, setTotalPage }) {
  const token = localStorage.getItem("accessToken");
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [dataClientCodes, setDataClientCodes] = useState([]);
  const [changePublish, setChangePublish] = useState(false)
  const [active, setActive] = useState(null)

  const [modalPublish, setModalPublish] = useState(false)

  const [modalAddNotification, setModalAddNotification] = useState(false)
  const [selectedPushNotification, setSelectedPushNotification] = useState(null)
  const [modalPreviewNotif, setModalPreviewNotif] = useState(false)
  const [pushNotification, setPushNotification] = useState([])

  useEffect(() => {
    if (notifInformation?.length > 0) {
        const mapNotif = notifInformation.map((value, index) => {
            return {
                ...value,
                no: (limit * (page-1)) + (index + 1),
                schedule: value?.schedule?.map((valueSch) => (
                    {
                        ...valueSch,
                        date: valueSch?.date ?  dayjs(valueSch?.date) : null,
                        time: valueSch?.date ?  moment(new Date(valueSch?.date)).format('HH:mm:ss') : null,
                    }
                )) || []
            }
        })
        setPushNotification(mapNotif)
    }
  }, [notifInformation, limit, page])

// const rowPushNotificationData = useMemo(() => {
//     if (notifInformation?.length > 0){
//         return notifInformation?.map((item, index) => (
//             {
//                 ...item,
//                 no: (limit * (page-1)) + (index + 1)
//             }
//         ))
//     } else {
//         return []
//     }
// },[notifInformation, limit, page])

  const fixedClients = useMemo(() => {
    let pre = [{ codes: "ALL", name: "ALL" }, { codes: 'Tes', name: 'Client Tester' }]
    return pre.concat(dataClientCodes);
  }, [dataClientCodes])

  const dataClients = useMemo(() => {
    return fixedClients.filter(el => {
      return data?.clientCode?.find(element => {
        return element === el?.codes
      })
    })
  }, [data, fixedClients])

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        // setDataClientCode(response?.map((el) => el?.name));
        setDataClientCodes(response);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePublish = (e) => {
    setChangePublish(true)
    setActive(data)
  }

  const capitalizedClientName = (clientName) => {
    return clientName?.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace('Pt', 'PT')
      .replace('Cv', 'CV')
  }

  const TABLE_HEAD = [
    { id: "no", label: "No", alignRight: false, width: '10%' },
    { id: "title", label: "Judul Notifikasi", alignRight: false, width: '20%' },
    { id: "description", label: "Deskripsi", alignRight: false, width: '30%' },
    { id: "schedule", label: "Sisa Jumlah Notifikasi", alignRight: false, width: '30%',
      renderData: (row) => (
        <span>{row?.schedule?.length || 0}x</span>
      )
     },
    { 
      id: "", 
      width: '10%', 
      renderData: (row) => {
        return (
          <div 
            style={{cursor: "pointer"}}
            onClick={() => {
                console.log('ROW', row)
              setSelectedPushNotification(row)
              setModalAddNotification(true)
            }}
          >
              <span style={{ color: '#1571DE' }}>
                  Lihat Detail
              </span>
          </div>
        )
      }},
  ];

  const handleSubmitPushNotification = (value, type) => {
    if (type === 'ADD'){
        const formData = new FormData();
        for (let i = 0; i < data?.clientCode?.length; i++) {
            formData.append(`pushNotif[0][clientCode]`, data?.clientCode[i]);
        }
        // formData.append(`pushNotif[0][clientCode]`, data?.clientCode || [])
        formData.append(`pushNotif[0][title]`, value?.title)
        formData.append(`pushNotif[0][description]`, value?.description)
        if (value?.schedule?.length > 0){
            value?.schedule?.forEach((scheduleItem, indexSchedule) => {
                const [hours, minutes, seconds] = scheduleItem?.time?.split(":").map(Number);
                const fixedDate = new Date(scheduleItem?.date)

                fixedDate.setHours(hours)
                fixedDate.setMinutes(minutes)
                fixedDate.setSeconds(seconds)

                formData.append(`pushNotif[0][schedule][${indexSchedule}][date]`, fixedDate?.toISOString());
            });
        }
        dispatch(updateInformationById(token, id, formData))
    } else if (type === 'EDIT'){
        let tempValueSchedule = value?.schedule || [] 

        const tempDelete = selectedPushNotification?.schedule.filter((f) => {
            return !tempValueSchedule?.some(
                scheduleItem => scheduleItem._id === f._id
            );
        });
        const tempEdit = tempValueSchedule?.filter((f) => {
            const matchingItem = selectedPushNotification?.schedule?.find(
                scheduleItem => scheduleItem?._id === f?._id
            );
            
            return matchingItem && (matchingItem.date !== f.date || matchingItem.time !== f.time);
        });
        const tempAdd = tempValueSchedule?.filter((f) => !f?._id)

        const pushNotifUpdate = []        

        tempDelete?.forEach((item) => {
            const [hours, minutes, seconds] = item?.time?.split(":").map(Number);
            const fixedDate = new Date(item?.date)

            fixedDate.setHours(hours)
            fixedDate.setMinutes(minutes)
            fixedDate.setSeconds(seconds)
            
            pushNotifUpdate.push({
                _id: item?._id,
                date: fixedDate?.toISOString(),
                action: "DELETE"
            })
        })

        tempEdit?.forEach((item) => {
            const [hours, minutes, seconds] = item?.time?.split(":").map(Number);
            const fixedDate = new Date(item?.date)

            fixedDate.setHours(hours)
            fixedDate.setMinutes(minutes)
            fixedDate.setSeconds(seconds)

            pushNotifUpdate.push({
                _id: item?._id,
                date: fixedDate?.toISOString(),
                action: "UPDATE"
            })
        })
        
        tempAdd?.forEach((item) => {
            const [hours, minutes, seconds] = item?.time?.split(":").map(Number);
            const fixedDate = new Date(item?.date)

            fixedDate.setHours(hours)
            fixedDate.setMinutes(minutes)
            fixedDate.setSeconds(seconds)

            pushNotifUpdate.push({
                date: fixedDate?.toISOString()
            })
        })

        dispatch(updateNotificationById(token, selectedPushNotification?._id, {
            clientCode: selectedPushNotification?.clientCode,
            title: value?.title,
            description: value?.description,
            schedule: pushNotifUpdate
        }))
      setSelectedPushNotification(null)
    } 
    // else if (type === 'DELETE'){
    //   setPushNotification((prev) => {
    //     const arr = prev?.filter(f => f?.id !== value?.id)
    //     return [...arr]
    //   })
    //   setSelectedPushNotification(null)
    // }
  }

  return (
      <div className={Styles.Container}>
          <div className={Styles.Wrapper}>
              <div className={Styles.StatusHeader}>
                  <div className={Styles.StatusDesc}>
                      <span>Status Information</span>
                      <span>Change the information status to publish or unpublish</span>
                  </div>
                  <div className={Styles.StatusSwitcher}>
                      <Switch
                          checked={data?.status === 'Published' ? true : false}
                          size="large"
                          onChange={handlePublish}
                      />
                  </div>
              </div>
              <div className={Styles.ClientsLabelContainer}>
                  {dataClients.map((each, i) => (
                      <div key={i} className={Styles.ClientsLabel}>
                          <Business className={Styles.ClientIcon} />
                          <span>{capitalizedClientName(each?.name)}</span>
                      </div>
                  ))}
              </div>

              {/* {data?.pushNotif?.length > 0 ? (
                  <div className={Styles.ItemBoxSetupPushNotification}>
                      <div className={Styles.TitleSetupPushNotification}>
                          <span>Push Notification</span>
                      </div>

                      <div className={Styles.FillPushNotification}>
                          {data?.pushNotif?.map((item, index) => (
                              <div className={Styles.BoxPushNotification} key={`pushnotif_${index}`}>
                                  <img
                                      src={NOTIFICATION_LOGO_IMG}
                                      alt="notif-logo-img"
                                      style={{ height: '32px', width: '32px' }}
                                  />
                                  <div className={Styles.ComponentNotification} style={{ width: '70%' }}>
                                      <span>Description</span>
                                      <p>{item?.description}</p>
                                  </div>
                                  <div className={Styles.ComponentNotification} style={{ width: '20%' }}>
                                      <span>Date</span>
                                      <p>{item?.pushTime ? moment(new Date(item?.pushTime)).format('DD MMMM YYYY') : '-'}</p>
                                  </div>
                                  <div className={Styles.ComponentNotification} style={{ width: '10%' }}>
                                      <span>Time</span>
                                      <p>{item?.pushTime ? moment(new Date(item?.pushTime)).format('HH:mm') : '-'}</p>
                                  </div>
                              </div>
                          ))}
                      </div>
                  </div>
              ) : (
                  <></>
              )} */}

              <div className={Styles.Banner}>
                  <img
                      src={
                          data?.image?.replace(`https://myworkspace.id/api/image/`, `${API_IMAGE}`) ||
                          info_default_banner
                      }
                      alt="banner"
                  />
              </div>
              <div className={Styles.ArticleHeader}>
                  <span>{data?.title}</span>
                  <span>{moment(data?.createdAt).format('LLL')}</span>
              </div>
              <div className={Styles.Content}>
                  <span>{data?.content}</span>
              </div>
          </div>
          <div className={Styles.Wrapper}>
          <div className={Styles.ItemBoxSetupPushNotification}>
                  <div className={Styles.TitleSetupPushNotification}>
                      <span>Setup Push Notification</span>
                      <div className={Styles.SetupPushNotificationButtonWrapper}>
                          {pushNotification?.length > 0 && (
                              <IconButton
                                  aria-label="add-notif"
                                  color="primary"
                                  sx={{
                                      gap: '10px',
                                      cursor: 'pointer',
                                      fontSize: '14px',
                                      fontWeight: '500',
                                  }}
                                  disableRipple={true}
                                  onClick={() => setModalAddNotification(true)}>
                                  <AddCircle fontSize="small" />
                                  <span>Tambah Jadwal</span>
                              </IconButton>
                          )}
                          <div className={Styles.BoxInputButton}>
                              <Button
                                  onClick={() => {
                                      setModalPreviewNotif(true);
                                  }}>
                                  Lihat Preview
                              </Button>
                          </div>
                      </div>
                  </div>
                  <CustomTable
                      bodyData={pushNotification}
                      tableHead={TABLE_HEAD}
                      page={page}
                      setPage={setPage}
                      rowsPerPage={limit}
                      setRowsPerPage={setLimit}
                      totalPage={totalPage}
                      customEmpty={() => {
                          return (
                              <div className={Styles.CenterEmptySetupPushNotification}>
                                  <div className={Styles.EmptySetupPushNotification}>
                                      <img
                                          src={NOTIFICATION_IMG}
                                          alt="notif-img"
                                          style={{ height: '70px', width: '100px' }}
                                      />
                                      <br />
                                      <span>Belum ada jadwal notifikasi ditambahkan</span>
                                      <IconButton
                                          aria-label="add-notif"
                                          color="primary"
                                          sx={{
                                              gap: '10px',
                                              cursor: 'pointer',
                                              fontSize: '14px',
                                              fontWeight: '500',
                                          }}
                                          disableRipple={true}
                                          onClick={() => setModalAddNotification(true)}>
                                          <ControlPoint fontSize="small" />
                                          <span>Push Notification</span>
                                      </IconButton>
                                  </div>
                              </div>
                          );
                      }}
                  />
              </div>
          </div>
          {changePublish && (
              <ChangePublishModal
                  setModalPublish={setModalPublish}
                  setChangePublish={setChangePublish}
                  active={active}
              />
          )}
          {modalPublish && (
              <ModalPublishedInfoDetails
                  setModalPublish={setModalPublish}
                  history={history}
                  title="Success Published"
                  text="you have send one information to the client"
                  id={data?._id}
              />
          )}
          {modalAddNotification && (
              <ModalAddPushNotification
                  type={selectedPushNotification ? 'EDIT' : 'ADD'}
                  submit={(value) => handleSubmitPushNotification(value, selectedPushNotification ? 'EDIT' : 'ADD')}
                  value={selectedPushNotification}
                  onClose={() => {
                      setModalAddNotification(false);
                      setSelectedPushNotification(null);
                  }}
              />
          )}
          {modalPreviewNotif && (
              <ModalPreview
                  value={{ ...data, pushNotif: data?.notifications || [], banner: data?.image }}
                  setModal={setModalPreviewNotif}
              />
          )}
      </div>
  );
}
