import React from "react";
import "./index.css";
import CustomerToday from "./CustomerToday/CustomerToday";
import AllCustomerList from "./AllCustomerList/AllCustomerList";

const CustomerExternal = () => {
  return (
    <div className="container-customer-external">
      <div className="new-customer-table-top">
        <CustomerToday />
      </div>
      <div className="new-customer-table-bottom">
        <AllCustomerList />
      </div>
    </div>
  );
};

export default CustomerExternal;
