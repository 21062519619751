import axios from "axios";
import {API} from "../../redux/api";

const fetchSupervisor = (token, clientCode) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(`${API}/user/supervisor?clientCode=${clientCode}`, {
        headers : {
          Authorization: token
        }
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export default fetchSupervisor;
