import React  from "react";
import { Grid, Container} from "@mui/material";
import { Helmet } from "react-helmet";
// eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";
import { PayslipMenuSA } from "../../../../components/SuperAdmin/_payslip/";
export default function PaySlipSA() {
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Payslip</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          Pay Slip
        </h2>
        {/* <div className="wrapper-button-client-SA">
          <Link
            to="/superadmin/payslip-report"
            style={{ display: "flex", alignItems: "center", color: "#1571de" }}
          >
            <span className="">Report</span>
            <i
              className="bx bxs-printer"
              style={{ fontSize: 20, paddingLeft: 10 }}
            ></i>
          </Link>
        </div> */}
      </div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <PayslipMenuSA />
        </Grid>
      </Grid>
    </Container>
  );
}
