/* eslint-disable no-unused-vars */
import React from "react";
import { Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import UnderMaintenance from "../../../components/_underMaintenancePage";
import OngoingLoanExternal from "../../../components/External/LoanData/OngoingLoanExternal/OngoingLoanExternal";
import { useDispatch } from "react-redux";

export default function LoanDataExternal() {
  const isMaintenance = true;
  const dispatch = useDispatch();
  const [dropdownSelected, setDropdownSelected] = React.useState("All");
  const [dropdownSelectedStatus, setDropdownSelectedStatus] = React.useState("All");
  const [dropdownListGroup, setDropdownListGroup] = React.useState("All");

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Loan Data</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          Loan
        </h2>
      </div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          {/* <OngoingLoanExternal
            dropdownSelected={dropdownSelected}
            setDropdownSelected={setDropdownSelected}
            dropdownSelectedStatus={dropdownSelectedStatus}
            setDropdownSelectedStatus={setDropdownSelectedStatus}
            dropdownListGroup={dropdownListGroup}
            setDropdownListGroup={setDropdownListGroup}
            // dataOnGoingLoan ={ dataOnGoingLoan}
          /> */}
          { isMaintenance ? <UnderMaintenance /> : <OngoingLoanExternal 
                dropdownSelected={dropdownSelected}
            setDropdownSelected={setDropdownSelected}
            dropdownSelectedStatus={dropdownSelectedStatus}
            setDropdownSelectedStatus={setDropdownSelectedStatus}
            dropdownListGroup={dropdownListGroup}
            setDropdownListGroup={setDropdownListGroup}
          />}
        </Grid>
      </Grid>
    </Container>
  );
}
