import React, { useEffect, useState } from "react";
import "./ClientSettingMenu.css";
import { Switch } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateSwitchController } from "../../../../redux/actions/SuperAdmin/adminActions";

export default function ClientSettingMenu({ client = {} }) {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const [selectedSwitch, setSelectedSwitch] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(false);

  useEffect(() => {
    setSelectedSwitch(!!client?.StatusAttendance);
    setSelectedLoan(!!client?.StatusLoan);
    setSelectedWorkspace(!!client?.MwsSetting);
  }, [client]);

  // handle update schema attendance
  const handleSwitch = (e) => {
    const result = {
      id: client._id,
      StatusAttendance: e.target.checked, // kondisi ini untuk mendapatkan hasil true / false dari switch yang terupdate
    };
    dispatch(updateSwitchController(result, token));
  };

  // handle update schema loan
  const handleSwitchLoan = (e) => {
    // setSelectedLoan(e.target.checked);
    const resultLoan = {
      id: client._id,
      StatusLoan: e.target.checked,
    };
    dispatch(updateSwitchController(resultLoan, token));
  };

  // handle update schema workspace
  const handleSwitchWorkspace = (e) => {
    setSelectedWorkspace(e.target.checked);
    const resultWorkspace = {
      id: client._id,
      MwsSetting: e.target.checked,
    };
    dispatch(updateSwitchController(resultWorkspace, token));
  };

  return (
    <div className="container-add-clients-id-menu">
      <div className="wrapper-add-client-id-menu">
        <div className="info-add-client-id-menu">
          <div className="box-settings-add-clients-id-menu">
            <div className="item-ADI-top">
              <span>Loan Settings</span>
              <Switch
                checked={Boolean(selectedLoan)}
                onChange={handleSwitchLoan}
                name="loanSettings"
              />
            </div>
            <div className="item-ADI-main">
              <span>Settings view for your clients</span>
            </div>
            <div className="item-ADI-status">
              <span>
                {client?.StatusLoan ? (
                  <span style={{ color: "green" }}>{client?.SchemaLoan}</span>
                ) : (
                  <span style={{ color: "red" }}>not set</span>
                )}
              </span>
            </div>
            <Link
              to={{
                pathname: `/superadmin/add-clients-id-loan/${client?._id}`,
              }}
              className="item-ADI-footer"
            >
              <div className="item-ADI-footer">
                <button>View Setting</button>
                <ArrowRightAltIcon style={{ color: "#1571DE" }} />
              </div>
            </Link>
          </div>
          <div className="box-settings-add-clients-id-menu">
            <div className="item-ADI-top">
              <span>Attendances Settings</span>
              <Switch
                checked={Boolean(selectedSwitch)}
                onChange={handleSwitch}
                // name="attendanceSettings"
              />
              {/* value={formData || ""} */}
            </div>
            <div className="item-ADI-main">
              <span>Settings view for your clients</span>
            </div>
            <div className="item-ADI-status">
              <span>
                {client?.SchemaAttendance ? (
                  <span style={{ color: "green" }}>
                    {client.SchemaAttendance}
                  </span>
                ) : (
                  <span style={{ color: "red" }}> not set</span>
                )}
              </span>
            </div>
            <Link
              to={{
                pathname: `/superadmin/add-clients-id-attendances/${client._id}`,
              }}
              className="item-ADI-footer"
            >
              <div className="item-ADI-footer">
                <button>View Setting</button>
                <ArrowRightAltIcon style={{ color: "#1571DE" }} />
              </div>
            </Link>
          </div>
          <div className="box-settings-add-clients-id-menu">
            <div className="item-ADI-top">
              <span>Workspace Settings</span>
              <Switch
                checked={Boolean(selectedWorkspace)}
                name="workspaceSettings"
                onChange={handleSwitchWorkspace}
              />
            </div>
            <div className="item-ADI-main">
              <span>Settings view for your clients</span>
            </div>
            <div className="item-ADI-status">
              <span>
                {client?.MwsSetting > 0 ? (
                  <span style={{ color: "green" }}>
                    {/* {client?.StatusWorkspace} */}
                    <span>set</span>
                  </span>
                ) : (
                  <span style={{ color: "red" }}>not set</span>
                )}
              </span>
            </div>
            <Link
              to={{
                pathname: `/superadmin/add-clients-id-workspace/${client._id}`,
              }}
              className="item-ADI-footer"
            >
              <div className="item-ADI-footer">
                <button>View Setting</button>
                <ArrowRightAltIcon style={{ color: "#1571DE" }} />
              </div>
            </Link>
          </div>
        </div>
        {/* <div className="button-bottom-ADI">
                    <Button style={ { width: 97, height: 40, background: "#1571de", borderRadius: 4, color: 'white'}} onClick={() => setModalSuccess(true)}>
                        Add to List
                    </Button>
                    {
                        modalSuccess && (
                            <ClientModalSuccess modalSuccess={ modalSuccess } setModalSuccess={ setModalSuccess }/>
                        )
                    }
                    <Button style={ { width: 75, height: 40, border: "1px solid #1571DE", borderRadius: 4} } onClick={routeChange}>
                        Cancel
                    </Button>
                </div> */}
      </div>
    </div>
  );
}
