import React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

export default function DatePickerPaySlip({valueYearPicker,handleYearPicker,}) {
  // const [valuePayslip, setValuePayslip] = React.useState(new Date());
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={['year']}
          value={valueYearPicker}
          onChange={handleYearPicker}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />
    </LocalizationProvider>
  );
}
