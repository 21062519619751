import {
  // Avatar,
  Button,
  // Card,
  // IconButton,
  // Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SearchbarFMCG from "../../SearchbarFMCG/SearchbarFMCG";
import "./TableProducts.css";
import AddIcon from "@mui/icons-material/Add";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useHistory } from "react-router";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import EditIcon2 from "../../../../assets/images/new-edit-icon.png";
import TrashIcon from "../../../../assets/images/trash-2.png";
import ModalDeleteFMCG from "../../ModalDeleteFMCG/ModalDeleteFMCG";
import LoadingFMCG from "../../../utils/LoadingFMCG/LoadingFMCG";

const data = [
  { name: "All Items", id: "akjsdkasdj4sadasd" },
  { name: "Arla Butter", id: "akjsdkasdj4" },
  { name: "Cheese Spread Arla", id: "asdkasdh1kj" },
  { name: "Cheese Triangle", id: "asdjnaskdn1k3n1231" },
  { name: "Cream Cheese", id: "asdknn12312312" },
  { name: "Kids Stick Arla", id: "k121nkd1w1d1" },
  { name: "Mozarella", id: "1023819232131j3" },
  { name: "Pizza Topping", id: "askodasjdn" },
  { name: "UHT", id: "1jk23lk12n3kl123" },
  { name: "Whip Cream", id: "121232askdnbaksdabs13" },
  { name: "Whip Creams", id: "12123dsfkajflkad13" },
  { name: "Whip Creams", id: "121232askdnbaksmdbna13" },
  { name: "Whip Creams", id: "1212321dhd3" },
  { name: "Whip Creamasdasd", id: "121232asdasd13" },
  { name: "Whip Cream", id: "12123gdgfgdfg213" },
  { name: "Whip Creamasdasd", id: "12123211231233" },
  { name: "Whip Cream", id: "121232asfdsgfs13" },
  { name: "Whip Cream", id: "1212321asdasdas3" },
  { name: "Whip Cream", id: "12123211232133" },
];

const dataProducts = [
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "1",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "2",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "3",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "4",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "5",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "6",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "7",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "8",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "9",
  },
  {
    productName: "Butter Salted",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "10",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "11",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "12",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "13",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "14",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "15",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "16",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "17",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "18",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "19",
  },
  {
    productName: "Cheese Spread",
    label: "CSA 24X140 Gr/Ctn",
    size: "20 x 200",
    price: "24.200",
    status: "active",
    brand: "Arla",
    id: "20",
  },
];

const TABLE_HEAD = [
  { id: "ProductName", label: "Product Name", alignRight: false },
  { id: "Size", label: "Size", alignRight: false },
  { id: "Brand", label: "Brand", alignRight: false },
  { id: "Prices", label: "Prices", alignRight: false },
  { id: "Status", label: "Status", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
        "& th:first-child": {
          borderRadius: "1em 0 0 1em",
        },
        "& th:last-child": {
          borderRadius: "0 1em 1em 0",
        },
      }}
    >
      <TableRow>
        <TableCell padding="none" style={{ paddingLeft: 0 }}>
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={rowCount !== values?.users?.length}
            sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
          /> */}
        </TableCell>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const TableProducts = () => {
  const history = useHistory();
  const [listProductsStore, setListProductsStore] = useState("");
  const [listCategory, setListCategory] = useState("");
  const [active, setActive] = useState("akjsdkasdj4sadasd");
  // const [dataDummy, setDataDummy] = useState([]);
  // const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState("");
  const [orderBy, setOrderBy] = useState("ProductName");
  const [openModalDelete, setOpenModalDelete] = useState(false);
  // const [productCategory, setProductCategory] = useState(data);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleSearchProduct = (event) => {
    setListProductsStore(event.target.value);
  };

  const handleSearchCategory = (event) => {
    setListCategory(event.target.value);
  };

  return (
    <div className="ContainerTableProducts">
      <div className="WrapperTableProducts">
        <div className="HeaderTableProducts">
          <SearchbarFMCG
            value={listProductsStore}
            onChange={handleSearchProduct}
            searchWidth="379px"
            placeholder="Search Product"
          />
          <Button
            startIcon={<AddIcon />}
            style={{
              textTransform: "none",
              backgroundColor: "#1571DE",
              width: 105,
              height: 40,
              color: "#FFFFFF",
            }}
            onClick={() =>
              history.push({
                pathname: "/admin-fmcg/setup/products/add",
                state: {
                  location: "addProductFMCG",
                },
              })
            }
          >
            Product
          </Button>
        </div>

        {loading ? (
          <div className="ContainerAttendanceFMCGLoading">
            <LoadingFMCG width={800} height={200} />
          </div>
        ) : (

        <div className="ContainerContentTableProducts">
          <div className="WrapperContentTableProducts">
            <div className="SidebarTableProducts">
              <div className="HeaderSidebarTableProducts">
                <FormatListBulletedIcon sx={{ width: 20, height: 20 }} />
                <span>Product Category</span>
              </div>
              <div className="SearchbarTableProducts">
                <SearchbarFMCG
                  value={listCategory}
                  onChange={handleSearchCategory}
                  placeholder="Search Category"
                  searchWidth="250px"
                />
              </div>
              <div className="ListProductsItems">
                {data
                  ? data
                      ?.filter((product) =>
                        product?.name
                          ?.toLowerCase()
                          .includes(listCategory.toLowerCase())
                      )
                      .map((el) => {
                        return (
                          <div className={`ItemsProductsButton`} key={el.id}>
                            <Button
                              onClick={() => setActive(el.id)}
                              className={`ItemsProductsButton ${
                                active === el.id && "active"
                              }`}
                            >
                              <i
                                className={`bx bx-package bx-sm`}
                                color={`${active === el.id && "active"}`}
                              />
                              {el.name}
                            </Button>
                          </div>
                        );
                      })
                  : null}
              </div>
            </div>
            <div className="ContentListTableProducts">
              {/* <Paper
                sx={{
                  ".MuiPaper-root" : {
                    borderRadius: '10px'
                  }
                }}
              > */}
              <Scrollbar>
                <TableContainer
                  sx={{
                    padding: "10px 15px",
                    height: 810,
                    overflowX: "hidden",
                  }}
                >
                  <Table
                    sx={{
                      width: "100%",
                      height: "max-content",
                      border: "none",
                    }}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      // onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      // values={data}
                      rowCount={
                        dataProducts?.filter(
                          (el) =>
                            el.productName
                              ?.toLowerCase()
                              .includes(listProductsStore.toLowerCase()) ||
                            el.size
                              .toLowerCase()
                              .includes(listProductsStore.toLowerCase()) ||
                            el.price
                              ?.toLowerCase()
                              .includes(listProductsStore.toLowerCase()) ||
                            el.status
                              ?.toLowerCase()
                              .includes(listProductsStore.toLowerCase())
                        ).length
                      }
                    />
                    <TableBody>
                      {stableSort(dataProducts, getComparator(order, orderBy))
                        .filter(
                          (el) =>
                            el.productName
                              ?.toLowerCase()
                              .includes(listProductsStore.toLowerCase()) ||
                            el.size
                              ?.toLowerCase()
                              .includes(listProductsStore.toLowerCase()) ||
                            el.price
                              ?.toLowerCase()
                              .includes(listProductsStore.toLowerCase()) ||
                            el.status
                              ?.toLowerCase()
                              .includes(listProductsStore.toLowerCase())
                        )
                        .map((product, ind) => {
                          // const isItemSelected = isSelected(employee?._id);
                          const labelId = `enhanced-table-checkbox-${ind}`;
                          return (
                            <TableRow
                              hover
                              key={ind}
                              tabIndex={-1}
                              id={labelId}
                              sx={{
                                "&.MuiTableRow-root:hover": {
                                  backgroundColor: "#eef6ff",
                                },
                              }}
                            >
                              <TableCell
                                padding="none"
                                style={{ paddingLeft: 10, marginRight: 10 }}
                              ></TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                // padding="none"
                              >
                                {/* <Link
                                to={{
                                  pathname: `/admin-fmcg/details-spg-store/lkshdo1hdon1owh`,
                                  state: { location: "detailsSpgStore" },
                                }}
                              > */}
                                <Stack
                                  direction="column"
                                  alignItems="left"
                                  spacing={0}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      textTransform: "capitalize",
                                      fontSize: 13,
                                      fontWeight: 500,
                                      color: "#333333",
                                    }}
                                    component="span"
                                  >
                                    {product.productName
                                      ? product.productName.toLowerCase()
                                      : "-"}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      textTransform: "capitalize",
                                      fontSize: 11,
                                      fontWeight: 400,
                                      color: "#979797",
                                    }}
                                    component="span"
                                  >
                                    {product.label
                                      ? product.label.toLowerCase()
                                      : "-"}
                                  </Typography>
                                </Stack>
                                {/* </Link> */}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: 13,
                                  fontWeight: 400,
                                  color: "#333333",
                                }}
                                component="span"
                              >
                                {product.size ? product.size : "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: 13,
                                  fontWeight: 400,
                                  color: "#333333",
                                }}
                                component="span"
                              >
                                {product.brand ? product.brand : "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: 13,
                                  fontWeight: 400,
                                  color: "#333333",
                                }}
                                component="span"
                              >
                                {product.price ? product.price : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {product.status === "active" ? (
                                  <Typography
                                    style={{
                                      backgroundColor: "#E4FFE6",
                                      color: "#17B51E",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 8,
                                      height: 26,
                                      width: 58,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#17B51E",
                                      }}
                                    >
                                      Active
                                    </Typography>
                                  </Typography>
                                ) : (
                                  <Typography
                                    style={{
                                      backgroundColor: "#FFDFDD",
                                      // color: "#17B51E",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 8,
                                      height: 26,
                                      width: 74,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#B52117",
                                      }}
                                    >
                                      Unactive
                                    </Typography>
                                  </Typography>
                                )}
                              </TableCell>
                              {/* <TableCell
                                align="left"
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: 13,
                                  fontWeight: 400,
                                  color: "#333333",
                                }}
                                component="span"
                              >
                                {product.status ? product.status : ""}
                              </TableCell> */}
                              <TableCell align="left" variant="square">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography
                                    style={{
                                      width: 30,
                                      height: 30,
                                      backgroundColor: "#ECF5FF",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 5,
                                    }}
                                    component="div"
                                  >
                                    <img
                                      src={EditIcon2}
                                      alt=""
                                      style={{
                                        width: 20,
                                        height: 20,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        history.push({
                                          pathname: `/admin-fmcg/setup/products/edit/1`,
                                          state: {
                                            location: "editProductFMCG",
                                          },
                                        })
                                      }
                                    />
                                  </Typography>
                                  <Typography
                                    style={{
                                      width: 30,
                                      height: 30,
                                      backgroundColor: "#ECF5FF",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 5,
                                    }}
                                    component="div"
                                  >
                                    <img
                                      src={TrashIcon}
                                      alt=""
                                      style={{
                                        width: 20,
                                        height: 20,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => setOpenModalDelete(true)}
                                    />
                                  </Typography>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              {/* </Paper> */}
              {/* <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={
                  data?.filter(
                    (el) =>
                      el.productName
                        ?.toLowerCase()
                        .includes(listProductsStore.toLowerCase()) ||
                      el.size
                        ?.toLowerCase()
                        .includes(listProductsStore.toLowerCase()) ||
                      el.price
                        ?.toLowerCase()
                        .includes(listProductsStore.toLowerCase()) ||
                      el.status
                        ?.toLowerCase()
                        .includes(listProductsStore.toLowerCase())
                  ).length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            </div>
          </div>
        </div>
        )}
      </div>
      {openModalDelete && (
        <ModalDeleteFMCG
          modalDelete={openModalDelete}
          setModalDelete={setOpenModalDelete}
        />
      )}
    </div>
  );
};

export default TableProducts;
