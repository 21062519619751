import { Alert, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import fetchBankData from "../../../../async/bank/fetchBankData";
import { addBank } from "../../../../redux/actions/SuperAdmin/bankActions";
import Styles from './AddBank.module.css';

// const MuiTextField = styled(TextField)({
//     "& .MuiOutlinedInput-root": {
//         "& fieldset": {
//             borderRadius: 8,
//             border: '1px solid #e0e0e0',
//         },
//     },
//     "& .MuiOutlinedInput-notchedOutline": {
//         border: "1.5px solid #d3c9c9",
//         borderColor: "#d3c9c9",
//         borderRadius: 8,
//         //   width: "100%",
//     },
// });

export default function AddBank() {
    const token = localStorage.getItem("accessToken");
    const dispatch = useDispatch();
    const history = useHistory();
    const [dataBanks, setDataBanks] = useState([]);
    const [form, setForm] = useState({
        name: '',
        code: '',
        swiftCode: '',
    })
    const [isExist, setIsExist] = useState(false);
    const [isExistCode, setIsExistCode] = useState(false);
    const [isExistSwiftCode, setIsExistSwiftCode] = useState(false);
    const [alert, setAlert] = useState(null)

    const handleChange = (e) => {
        setForm((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))

        if (e.target.name === 'name') {
            const existing = dataBanks.some(bank => bank?.name === e.target.value)
            setIsExist(existing)
        }
        if (e.target.name === 'code') {
            const existing = dataBanks.some(bank => bank?.code === e.target.value)
            setIsExistCode(existing)
        }
        if (e.target.name === 'swiftCode') {
            const existing = dataBanks.some(bank => bank?.swiftCode === e.target.value)
            setIsExistSwiftCode(existing)
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        if (isExist) {
            setAlert("Bank with this name already exists")
        } else if (isExistCode) {
            setAlert("Bank with this code already exists")
        } else if (isExistSwiftCode) {
            setAlert("Bank with this swift code already exists")

        } else {
            dispatch(addBank(token, form));
            history.push("/superadmin/database");
        }
    }

    const handleReset = (e) => {
        e.preventDefault();
        setForm((prev) => ({
            ...prev,
            "name": '',
            "code": '',
            "swiftCode": '',
        }))
    }



    useEffect(() => {
        (async () => {
            try {
                const { response } = await fetchBankData(token)
                setDataBanks(response)
            } catch (err) {
                console.log(err)
            }
        })()
    }, [token])


    return (
        <div className={Styles.ContainerWrapper}>
            <span>Add Bank Data</span>
            <div className={Styles.Container}>
                <form onSubmit={handleSubmit} className={Styles.Form}>
                    <div className={Styles.FormHeader}>
                        <span>Bank Details</span>
                        {alert &&
                            <div>
                                <Alert severity="error" fullWidth>
                                    {alert}
                                </Alert>
                            </div>
                        }
                    </div>

                    <div className={Styles.BoxForm}>
                        <label htmlFor="name">Bank Name</label>
                        <input id="name" type="text" name="name" value={form.name} onChange={handleChange} placeholder="Enter bank name" required />
                    </div>
                    <div className={Styles.BoxForm}>
                        <label htmlFor="code">Bank Code</label>
                        <input id="code" type="text" name="code" value={form.code} onChange={handleChange} placeholder="Enter bank code" required />
                    </div>
                    <div className={Styles.BoxForm}>
                        <label htmlFor="swiftCode">Swift Code</label>
                        <input id="swiftCode" type="text" name="swiftCode" value={form.swiftCode} onChange={handleChange} placeholder="Enter Swift Code" required />
                    </div>
                    <div className={Styles.ButtonWrapper}>
                        <Button type="submit" variant="contained" className={Styles.SaveButton}>Save</Button>
                        <Button type="button" variant="outlined" onClick={handleReset} className={Styles.ResetButton}>Reset</Button>

                    </div>
                </form>
            </div>
        </div>

    )
}