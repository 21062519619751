import React, { useState } from "react";
import InputText from "../../../../utils/Input/Input";
import { Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import PublishIcon from "@mui/icons-material/Publish";
import { useHistory } from "react-router";

export default function EditProductsExternal() {
  const [files, setFiles] = useState([]);
  const history = useHistory();
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const images = files.map((file) => (
    <div key={files.name}>
      <div>
        <img
          src={file.preview}
          style={{ width: 300, height: 170 }}
          alt="preview"
        />
      </div>
    </div>
  ));

  return (
    <div className="product__external-container">
      <div className="product__external-box">
        <div className="product__external-desc">
          <div className="product__external-wrapper">
            <div className="product__external-text-input">
              <label style={{ paddingBottom: 8 }}>Product Name</label>
              <InputText name="Product Name" />
            </div>
            <div className="product__external-text-input">
              <label style={{ paddingBottom: 8 }}>Product Price</label>
              <InputText name="Product Price" />
            </div>
          </div>
          <div className="product__external-wrapper" style={{ paddingTop: 20 }}>
            <div className="product__external-text-input">
              <label style={{ paddingBottom: 8 }}>Product Code</label>
              <InputText name="Product Code" />
            </div>
            <div className="product__external-text-input">
              <label style={{ paddingBottom: 8 }}>Stock</label>
              <InputText name="Stock" />
            </div>
          </div>
          <div className="add__store-container-map">
            {images.length ? (
              <div {...getRootProps()}>
                {images}
                <input type="text" {...getInputProps()} />
              </div>
            ) : (
              <div className="container__upload-image">
                <div className="wrapper__upload-image" {...getRootProps()}>
                  <input type="text" {...getInputProps()} />
                  <div className="box__input-image">
                    <span>Upload Image Here</span>
                    <div className="box__input-file">
                      <span>File must JPG or PNG</span>
                    </div>
                    <div className="box__input-or">
                      <span> OR </span>
                    </div>
                    <div className="container__input-image">{images}</div>
                    <div className="box__input-button">
                      <Button>
                        <span>Browse File</span>
                        <PublishIcon />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="add__store-button">
          <Button className="add__store-btn-save" style={{ background: '#1571DE', color: '#FFFFFF', fontSize: 14, fontWeight: 500}}>Save</Button>
          <Button className="add__store-btn-cancel" style={{ border: '1px solid #1571DE', color: '#1571DE',fontSize: 14, fontWeight: 500}} onClick={() => history.push('/admin/product-external')}>Cancel</Button>
        </div>
        </div>
      </div>
    </div>
  );
}
