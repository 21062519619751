import axios from "axios";
import {API} from "../../redux/api";

// const createDayOff = (token, dataToSend) =>
//   new Promise(async (resolve, reject) => {
//     try {
//       const {
//         data: { response },
//       } = await axios({
//         method: "post",
//         headers: { Authorization: `${token}` },
//         url: `${API}/dayoff`,
//         data: dataToSend,
//       });
//       resolve(response);
//     } catch (error) {
//       reject(error);
//     }
//   });

// export default createDayOff;


const createDayOff = (token, dataToSend) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { response },
      } = await axios({
        method: "post",
        headers: { Authorization: `${token}` },
        url: `${API}/national-day`,
        data: dataToSend,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export default createDayOff;
