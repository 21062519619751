import React from 'react';
import Styles from './LayoutDetailEBP.module.css';
import { Avatar } from '@mui/material';
import { API_IMAGE } from '../../../../redux/api';
import LOGO_PERMATA from '../../../../assets/images/logo-permata.png';
import { CancelRounded, CheckCircleRounded, PhotoOutlined, RemoveRedEye, RemoveRedEyeOutlined } from '@mui/icons-material';
import moment from 'moment';
import { formatCurrency } from '../../../../helpers/formatCurrency';

export default function LayoutDetailEBP({ data, loading, typeDetail }) {
    return (
        <div className={Styles.Container}>
            <div className={Styles.Header}>
                <div className={Styles.ProfileWrapper}>
                    <Avatar
                        alt="employee"
                        sx={{ width: 80, height: 80 }}
                        src={data?.user?.profilePicture?.replace(`https://myworkspace.id/api/image/`, `${API_IMAGE}`)}>
                        {data?.user?.name?.[0] || ''}
                    </Avatar>
                    <div className={Styles.TextProfileWrapper}>
                        <span>{data?.user?.name || '-'}</span>
                        <span>{data?.contract?.jobTitle || '-'}</span>
                        <span>{data?.contract?.clients?.name || '-'}</span>
                    </div>
                </div>
                {data?.status && <div className={Styles[data.status]}>{data.status}</div>}
            </div>
            <div className={Styles.Divider}></div>
            <div className={Styles.Body}>
                <div className={Styles.Content}>
                    <div className={Styles.TitleContent}>
                        <span>
                            Informasi{' '}
                            {typeDetail === 'KKB' ? 'KKB' : typeDetail === 'EDUCATION' ? 'Biaya Pendidikan' : ''}
                        </span>
                    </div>
                    <div className={Styles.Card}>
                        <div className={Styles.InformationHeader}>
                            <div className={Styles.TextInformationWrapper}>
                                <span>Employee Benefit Program</span>
                                <span>{data?.productType === 'MOTOR' ? 'KKB' : 'Biaya Pendidikan'}</span>
                                <span>
                                    {data?.contract?.division} - {data?.contract?.clients?.name || 'PT Indosat'}
                                </span>
                            </div>
                            <img src={LOGO_PERMATA} alt="logo_permata" />
                        </div>
                        <div className={Styles.Divider} />
                        {data?.productType === 'MOTOR' ? (
                            <div className={Styles.CardContentWrapper}>
                                <div className={Styles.CardContent}>
                                    <span>Tanggal Pengajuan</span>
                                    <span>{data?.createdAt}</span>
                                </div>
                                <div className={Styles.CardContent}>
                                    <span>Kendaraan</span>
                                    <span>{data?.brand}</span>
                                </div>
                                <div className={Styles.CardContent}>
                                    <span>Harga Kendaraan</span>
                                    <span>-</span>
                                </div>
                                <div className={Styles.CardContent}>
                                    <span>Angsuran Bulanan</span>
                                    <span>-</span>
                                </div>
                                <div className={Styles.CardContent}>
                                    <span>Tenor</span>
                                    <span>
                                        {data?.staffLoan?.[0]?.tenor ? `${data?.staffLoan?.[0]?.tenor} bulan` : '-'}
                                    </span>
                                </div>
                                <div className={Styles.CardContent}>
                                    <span>Tanggal Dicairkan</span>
                                    <span>
                                        {data?.staffLoan?.[0]?.paymentProvider?.transactionDate
                                            ? moment(data?.staffLoan?.[0]?.paymentProvider?.transactionDate).format(
                                                  'DD MMMM YYYY'
                                              )
                                            : '-'}
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className={Styles.CardContentWrapper}>
                                <div className={Styles.CardContent}>
                                    <span>Tanggal Pengajuan</span>
                                    <span>{data?.createdAt}</span>
                                </div>
                                <div className={Styles.CardContent}>
                                    <span>Jumlah Biaya Pendidikan</span>
                                    <span>-</span>
                                </div>
                                <div className={Styles.CardContent}>
                                    <span>Tenor</span>
                                    <span>
                                        {data?.staffLoan?.[0]?.tenor ? `${data?.staffLoan?.[0]?.tenor} bulan` : '-'}
                                    </span>
                                </div>
                                <div className={Styles.CardContent}>
                                    <span>Tanggal Dicairkan</span>
                                    <span>
                                        {data?.staffLoan?.[0]?.paymentProvider?.transactionDate
                                            ? moment(data?.staffLoan?.[0]?.paymentProvider?.transactionDate).format(
                                                  'DD MMMM YYYY'
                                              )
                                            : '-'}
                                    </span>
                                </div>
                                <div className={Styles.CardContent}>
                                    <span>Angsuran Bulanan</span>
                                    <span>-</span>
                                </div>
                                <div className={Styles.CardContent}>
                                    <span>Tagihan Pembayaran</span>
                                    <div className={Styles.Card} style={{padding: "8px 8px"}}>
                                        <div className={Styles.BillWrapper}>
                                            <div className={Styles.LeftBill}>
                                                <div className={Styles.RoundIcon}>
                                                    <PhotoOutlined sx={{ width: 20, height: 20 }} />
                                                </div>
                                                <div className={Styles.TextBillWrapper}>
                                                    <span>Contoh File.jpg</span>
                                                    <span>Change File</span>
                                                </div>
                                            </div>
                                            <div className={Styles.ViewButton}>
                                                <RemoveRedEye/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={Styles.Content}>
                    <div className={Styles.TitleContent}>
                        <span>Payment History</span>
                    </div>
                    {data?.staffLoan?.[0]?.installments?.length > 0 ? (
                        <div className={Styles.CardContentInstallmentWrapper}>
                            {data?.staffLoan?.[0]?.installments?.map((elem, index) => {
                                return (
                                    <div className={Styles.Card}>
                                        <div className={Styles.CardContent} key={index}>
                                            <span>
                                                {elem?.dueDate ? moment(elem?.dueDate).format('DD MMMM YYYY') : '-'}
                                            </span>
                                            <span>Rp. {elem?.total ? formatCurrency(elem.total) : 0}</span>
                                            {elem?.paidDate ? (
                                                <div className={Styles.PaymentStatusPaidText}>
                                                    <CheckCircleRounded color="#255BFC" />
                                                    <span>Sudah Dibayar</span>
                                                </div>
                                            ) : (
                                                <div className={Styles.PaymentStatusNotPaidText}>
                                                    <CancelRounded />
                                                    <span>Belum Dibayar</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className={Styles.Empty}>
                            <span>Tidak ada data</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
