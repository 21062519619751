import React, { useEffect, useState } from "react";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { UserAddEmployee } from "../../../components/SuperAdmin/_user";
import Breadcrumb from "../../../components/utils/Breadcrumbs";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEmployees } from "../../../redux/actions/SuperAdmin/employeesActions";
export default function AddEmployeeSA() {
  const history = useHistory();
  const token = localStorage.getItem('accessToken');
  const employee = useSelector((state) => state.employees.employees);
  const [selectedAddEmployee, setSelectedAddEmployee] = useState("All");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllEmployees(token))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  // console.log(employee, 'cek from add employee page')
  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Employee Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/employee")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Employee</span>
            </Button>
            <span style={{ fontSize: 20 }}>Add Employee</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <UserAddEmployee
              selectedAddEmployee={selectedAddEmployee}
              setSelectedAddEmployee={setSelectedAddEmployee}
              employee={employee}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
