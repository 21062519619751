/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import Styles from "./ModalAddUserSA.module.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles, StylesContext } from "@mui/styles";
import SEARCH_ICON from "../../../../../assets/images/search-dark-grey.png";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import ImageOffice from "../../../../../assets/images/Office.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import InputText from "../../../../utils/Input/Input";
import {
  Autocomplete,
  DialogActions,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import styled from "@emotion/styled";
import fetchPreRegisteredEmployees from "../../../../../async/userClient/fetchPreRegisteredEmployees";
import fetchClientCode from "../../../../../async/client/fetchClientCode";
import { registerUserAdmin } from "../../../../../redux/actions/SuperAdmin/userAdmin";
import fetchPKSbyClient from "../../../../../async/client/getPKSbyClient";
import axios from "axios";
import registerAdminEmployee from "../../../../../async/userClient/registerAdminEmployee";
import {useDebounce} from '../../../../../hooks/useDebounce'
// moment.locale("en-gb");

const MuiTextField = styled(TextField)({
  backgroundColor: "#fff",
  borderRadius: 8,

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      border: "1px solid #e0e0e0",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
    "& .MuiSelect-select": {
      fontSize: 16,
    },
  },
  ItemModalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& > span": {
      color: "#333333",
      fontSize: 16,
      fontWeight: 600,
    },
  },
  ItemModalTopLeft: {
    "& > span": {
      color: "#333333",
      fontSize: 16,
      fontWeight: 600,
    },
  },
  Content: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  WrapperInput: {
    display: "flex",
    alignItems: "center",
    columnGap: 24,
  },
  ProfileInput: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    width: "100%",
    "& > span": {
      fontSize: 13,
      color: "#0A0A0A",
      fontWeight: 500,
    },
    "& > textarea": {
      width: "100%",
      height: 40,
      background: "#fbfbfb",
      border: "1.5px solid #d3c9c9",
      borderRadius: 8,
      paddingLeft: 10,
      paddingTop: 10,
      resize: "vertical",
    },
    "& > ::placeholder": {
      color: "#616161",
      fontStyle: "italic",
    },
  },
  Button: {
    display: "flex",
    alignSelf: "flex-end",
    gap: 10,
    "& > button:nth-of-type(1)": {
      outline: "none",
      background: "#FFFFFF",
      border: "1px solid #333333",
      borderRadius: 5,
      color: "#333333",
      width: 95,
      height: 34,
      cursor: "pointer",
    },
    "& > button:nth-of-type(2)": {
      outline: "none",
      background: "#1571DE",
      borderRadius: 5,
      color: "#FFFFFF",
      width: 81,
      height: 34,
      cursor: "pointer",
    },
  },
}));

const pks = ["12312312312", "123123123123123", "1231231231"];

export default function ModalAddUserSA({
  openModal,
  setOpenModal,
  description,
  setSuccessAddModal,
}) {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const theme = useTheme();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [values, setValues] = useState({});
  const [selectedRole, setSelectedRole] = useState("");
  const getRole = ["Admin", "Viewer"];

  const [employees, setEmployees] = useState([]);
  const [listPKS, setListPKS] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [dataClientCode, setDataClientCode] = useState([]);
  // const [selectedClientNames, setSelectedClientNames] = useState([
  //   {
  //     name: "",
  //     pks: [],
  //   },
  // ]);
  const [selectedClientInputName, setSelectedClientInputName] = useState("")

  const [selectedClientCodes, setSelectedClientCodes] = useState([]);
  const [selectedPKS, setSelectedPKS] = useState("");
  const [alert, setAlert] = useState(null);
  const [isError, setIsError] = useState();
  const [selectedClients, setSelectedClients] = useState([]);
  const [loadingFetchName, setLoadingFetchName] = useState(false)
  const filteredPKS = useMemo(() => {
    return listPKS?.map((each) => each.pks_number);
  }, [listPKS]);

  const debounceSearch = useDebounce(selectedClientInputName,500)

  useEffect(() => {
   setLoadingFetchName(true)
  }, [selectedClientInputName])
  

  useEffect(() => {
    (async () => {
      try {
        setLoadingFetchName(true)
        const { response } = await fetchPreRegisteredEmployees(
          token,
          `clientCode=PIS&name=${selectedClientInputName}`,
          
        );
        setLoadingFetchName(false)
        // setDataClientCode(response?.map((el) => el?.name));
        setEmployees(response);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch]);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCode(response);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const { response } = await fetchPKSbyClient(selectedClientCodes);
  //       setListPKS(response);
  //     } catch (error) {
  //       console.log();
  //     }
  //   })();
  // }, [selectedClientCodes]);

  const [form, setForm] = useState({
    employeeId: "",
    type: "MWS",
    phoneNumber: "",
    clientCodes: [],
  });

  const [formExternal, setFormExternal] = useState({
    name: "",
    type: "NON-MWS",
    phoneNumber: "",
    clientCodes: [],
    client: [],
  });

  const [activeForm, setActiveForm] = useState("internal");

  // console.log(formExternal.client, "<<<<");
  // console.log(selectedClientNames, "clientNames");

  const handleSwitchForm = (e) => {
    e.preventDefault();
    if (e.target.value === "internal") {
      setActiveForm("internal");
    } else {
      setActiveForm("external");
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeExternal = (e) => {
    setFormExternal((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onHandleSubmit = () => {
    // const data = {
    //   id: client._id,
    //   ...values,
    // };
  };

  // const selectedClients = useMemo(() => {
  //   return selectedClientCodes.map(())
  // },[])

  const handleChangeEmployee = (newVal) => {
    setSelectedEmployee(newVal);
    setForm((prev) => ({
      ...prev,
      employeeId: newVal.employeeId,
    }));
  };

  const handleChangeSelectedClients = async (newValue) => {
    // console.log(newValue, '<<<<')
    setSelectedClients(newValue);
    const { response } = await fetchPKSbyClient(newValue.map((el) => el.codes));
    let action;
    let marked;

    const isSaved = formExternal?.client;

    if (isSaved.length > response.length) {
      action = "remove";
      for (let l = 0; l < isSaved.length; l++) {
        let where = response?.map((el) => el.code).indexOf(isSaved[l].code);
        if (where < 0) {
          marked = isSaved[l];
          break;
        }
      }
    } else if (isSaved.length < response.length) {
      action = "add";
      for (let l = 0; l < response.length; l++) {
        let where = isSaved?.map((el) => el.code).indexOf(response[l].code);
        if (where < 0) {
          marked = response[l];
          break;
        }
      }
    } else {
      console.log("no response");
    }
    setListPKS(response);
    setSelectedClientCodes(newValue.map((el) => el.codes));
    if (action === "remove") {
      setFormExternal((prev) => ({
        ...prev,
        client: isSaved?.filter((el) => el.code !== marked?.code),
      }));
    } else if (action === "add") {
      setFormExternal((prev) => ({
        ...prev,
        client: [
          ...isSaved,
          { ...marked, options: marked.pks_number, pks_number: [] },
        ],
      }));
    } else {
      setFormExternal((prev) => ({
        ...prev,
        client: response?.map((prev) => ({
          ...prev,
          options: prev.pks_number,
          pks_number: [],
        })),
      }));
    }
  };

  const handleChangePKS = (e, key, value) => {
    // console.log(key, value);
    e.preventDefault();
    setFormExternal((prev) => ({
      ...prev,
      client: prev.client.map((each, idx) =>
        key === idx
          ? {
              ...each,
              pks_number: value,
            }
          : each
      ),
    }));
  };

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      clientCodes: selectedClientCodes,
    }));

    setFormExternal((prev) => ({
      ...prev,
      clientCodes: selectedClientCodes,
      // client: listPKS
    }));
  }, [selectedClientCodes]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (activeForm === "internal") {
        if (
          form?.phoneNumber?.length >= 9 &&
          form?.phoneNumber?.startsWith("8")
        ) {
          const { response } = await registerAdminEmployee(token, {
            employeeId: form?.employeeId,
            type: form?.type,
            phoneNumber: "62" + form?.phoneNumber,
            clientCodes: form?.clientCodes,
          });
          // console.log(response, "submitted");
          // dispatch(
          //   registerUserAdmin(token, {
          //     employeeId: form?.employeeId,
          //     type: form?.type,
          //     phoneNumber: "62" + form?.phoneNumber,
          //     clientCodes: form?.clientCodes,
          //   })
          // );
          setSuccessAddModal(true);
          setOpenModal(false);
        } else {
          setAlert("Nomor yang anda masukkan tidak valid");
        }
      } else {
        if (
          formExternal?.phoneNumber?.length >= 9 &&
          formExternal?.phoneNumber?.startsWith("8")
        ) {
          const { response } = await registerAdminEmployee(token, {
            name: formExternal?.name,
            type: formExternal?.type,
            phoneNumber: "62" + formExternal?.phoneNumber,
            clientCodes: formExternal?.clientCodes,
            client: formExternal?.client.map((prev) => ({
              code: prev.code,
              contractNumbers: prev.pks_number,
            })),
          });

          // console.log(response, "submitted");
          // let temp = formExternal?.phoneNum
          // dispatch(
          //   registerUserAdmin(token, {
          //     name: formExternal?.name,
          //     type: formExternal?.type,
          //     phoneNumber: "62" + formExternal?.phoneNumber,
          //     clientCodes: formExternal?.clientCodes,
          //     client: formExternal?.client.map((prev) => ({
          //       code: prev.code,
          //       contractNumbers: prev.pks_number,
          //     })),
          //   })
          // );
          setSuccessAddModal(true);
          setOpenModal(false);
        } else {
          setAlert("Nomor yang anda masukkan tidak valid");
        }
      }
    } catch (error) {
      console.log(error.response.data.error);
      if (
        error.response.data.error ===
        "[REQUEST ERROR] Phone Number already exist"
      ) {
        setAlert("Nomor sudah pernah digunakan sebelumnya");
      }
    }
  };

  return (
    <div className={Styles.Background}>
      <div className={Styles.Container}>
        <div className={Styles.Header}>
          <Add sx={{ color: "#0a0a0a" }} />
          <span>Add User</span>
        </div>
        <form onSubmit={handleSubmit} className={Styles.Form}>
          <FormControl size="small" fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="type"
              value={activeForm}
              onChange={handleSwitchForm}
              style={{ borderRadius: 8 }}
            >
              <MenuItem value="internal">MWS</MenuItem>
              <MenuItem value="external">Non-MWS</MenuItem>
            </Select>
          </FormControl>
          <div className={Styles.FormDetailHeader}>
            <span>User Details</span>
            {alert && <span className={Styles.Error}>{alert}</span>}
          </div>
          {activeForm === "internal" ? (
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                id="attendee"
                getOptionLabel={(option) => option.name}
                options={employees}
                noOptionsText="No Employee Found"
                disableClearable
                loading={loadingFetchName}
                onChange={(event, newValue) => handleChangeEmployee(newValue)}
                onInputChange={(event,newValue) => setSelectedClientInputName(newValue)}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option._id}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    size="small"
                    sx={{ width: "100%", background: "var(--main-bg)" }}
                    label="Search Employee"
                    InputLabelProps={{ required: false }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            src={SEARCH_ICON}
                            alt="search"
                            style={{
                              width: "28px",
                              height: "28px",
                              marginRight: "-50px",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                )}
              />
            </FormControl>
          ) : (
            <>
              <FormControl fullWidth>
                <MuiTextField
                  size="small"
                  label="Name"
                  name="name"
                  value={formExternal?.name}
                  onChange={handleChangeExternal}
                />
              </FormControl>
            </>
          )}

          <FormControl fullWidth>
            <MuiTextField
              type="number"
              size="small"
              name="phoneNumber"
              value={
                activeForm === "internal"
                  ? form?.phoneNumber
                  : formExternal?.phoneNumber
              }
              onChange={(e) => {
                activeForm === "internal"
                  ? handleChange(e)
                  : handleChangeExternal(e);
              }}
              label="Phone Number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+62</InputAdornment>
                ),
              }}
            />
          </FormControl>
          {/* <FormControl fullWidth>
            <Autocomplete
              multiple
              id="tags-standard"
              options={dataClientCode}
              getOptionLabel={(option) => option?.name}
              onChange={(e, newValue) => setSelectedClientCodes(newValue.map((el) => el.codes))}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  size="small"
                  label="Select client"
                  variant="outlined"
                />
              )}
            />
          </FormControl> */}
          <FormControl fullWidth>
            <Autocomplete
              sx={{}}
              multiple
              id="tags-standard"
              options={dataClientCode}
              getOptionLabel={(option) => option?.name}
              value={selectedClients}
              onChange={
                (e, newValue) => handleChangeSelectedClients(newValue)
                // setSelectedClientCodes(newValue.map((el) => el.codes))
              }
              renderInput={(params) => {
                const { InputProps, ...restParams } = params;
                const { startAdornment, ...restInputProps } = InputProps;
                return (
                  <MuiTextField
                    {...restParams}
                    variant="outlined"
                    placeholder="Select client"
                    size="small"
                    InputProps={{
                      ...restInputProps,
                      startAdornment: (
                        <div
                          style={{
                            maxHeight: "150px",
                            overflowY: "auto",
                          }}
                        >
                          {startAdornment}
                        </div>
                      ),
                    }}
                  />
                );
              }}
              // renderOption={(option, { selected }) => (
              //   <div
              //     style={{
              //       backgroundColor: selected ? "lightblue" : "white",
              //       padding: "8px",
              //     }}
              //   >
              //     {option.name}
              //   </div>
              // )}
            />
          </FormControl>

          {activeForm !== "internal" && (
            <>
              {formExternal?.client
                ? formExternal?.client?.map((each, idx) => (
                    <div className={Styles.ContainerPKS} key={idx}>
                      <div
                        className={`${Styles.HeadingPKS} ${
                          idx !== 0 && Styles.HeadingPKSHidden
                        }`}
                      >
                        <span>Client PKS</span>
                      </div>
                      <div className={Styles.SelectPKSControl}>
                        <div className={Styles.PKSValue}>
                          <label>{each?.name}</label>
                          <Autocomplete
                            multiple
                            disablePortal
                            options={each?.options}
                            // getOptionLabel={(option) => option}
                            // isOptionEqualToValue={(option, value) => option.pks_number === value.pks_number}
                            inputValue={inputValue}
                            value={each?.pks_number || []}
                            onInputChange={(e, val) => setInputValue(val)}
                            sx={{ width: "100%" }}
                            onChange={(e, val) =>
                              val === null
                                ? handleChangePKS(e, idx, "")
                                : handleChangePKS(e, idx, val)
                            }
                            renderInput={(params) => (
                              <MuiTextField
                                {...params}
                                size="small"
                                placeholder="Select PKS"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </>
          )}

          <div className={Styles.ButtonWrapper}>
            <button
              onClick={() => setOpenModal(false)}
              className={Styles.CancelButton}
            >
              Cancel
            </button>
            <button type="submit" className={Styles.SaveButton}>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
