/* eslint-disable no-unused-vars */
import "./assets/boxicons-2.0.7/css/boxicons.min.css";
import "./assets/css/grid.css";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import { Switch, Route } from "react-router-dom";
import Login from "../src/pages/External/Login";
import BPJSReport from "./pages/SuperAdmin/BPJS/BPJSReportSA/BPJSReport";
import EmployeeReport from "./pages/SuperAdmin/Employee/EmloyeeReportSA/EmployeeReport";
import PayslipReportSA from "./pages/SuperAdmin/Payslip/PayslipReportSA/PayslipReportSA";
import LayoutSA from "./components/layout/LayoutSA/LayoutSA";
import AttendanceSAReport from "./pages/SuperAdmin/Report/AttendanceSAReport";
import Layout from "./components/layout/Layout";
import AdminProtectedRoutes from "./components/_auth/AdminProtectedRoutes";
// admin
import StoreReport from "./pages/External/Store/StoreReport/StoreReport";
import ProductReport from "./pages/External/Product/ProductReport/ProductReport";
import AttendanceReport from "./pages/External/Attendance/AttendanceReport/AttendanceReport";
import EmployeeReportExternal from "./pages/External/Employee/EmployeeReport/EmployeeReport";
// import MiniScreen from "./components/utils/MiniScreen";
import PDFDataLoan from "./components/SuperAdmin/_loanData/LoanTabs/DataLoan/PDFDataLoan/PDFDataLoan";
// eslint-disable-next-line no-unused-vars
import TopNav from "./components/topNav/TopNav";
// import RejectedPage from "./pages/SuperAdmin/RejectedPage/RejectedPage";
// import ApprovalLoan from './pages/SuperAdmin/ApprovalLoan/ApprovalLoan';
import SuperAdminProtectedRoutes from "./components/_auth/ProtectedRoutes";
import LoanSAReport from "./pages/SuperAdmin/Report/LoanSAReport";
import PDFDataPayslip from "./components/SuperAdmin/_payslip/PDFDataPayslip/PDFDataPayslip";
import CustomerReport from "./pages/External/Customer/CustomerReport/CustomerReport";
// import NotFound404 from "./components/_pageNotFound/NotFound404";
// import TopNavSA from "./components/SuperAdmin/TopNavSA/TopNavSA";
import NotFoundWithHeader from "./components/_pageNotFound/NotFoundWithHeader";
import { PDFDataPayslipExternalMenu } from "./components/External/Payslip";
import PDFDataLoanExternal from "./components/External/LoanData/PDFDataLoanExternal/PDFDataLoanExternal";
import { ApprovalPage } from "./components/_approval";
// import ApprovalLoanMenuSecond from "./components/_approval-rejected-page/approval/ApprovalLoanMenuSecond";
import ApprovalAttendance from "./pages/SuperAdmin/ApprovalAttendance/ApprovalAttendance";
import RejectedAttendace from "./pages/SuperAdmin/RejectedAttendance/RejectedAttendance";
import FMCGProtectedRoutes from "./components/_auth/FMCGProtectedRoutes";
import LayoutFMCG from "./components/layout/LayoutFMCG/LayoutFMCG";

import Geocode from "react-geocode";
import AdminMWSProtectedRoutes from "./components/_auth/AdminMWSProtectedRoutes";
import LayoutAdminMWS from "./components/layout/LayoutAdminMWS/LayoutAdmin";
import PrivacyAndPolicy from "./components/SuperAdmin/_privacyAndPolicy";
import LayoutSupervisor from "./components/layout/LayoutSupervisor/LayoutSupervisor";
import BusinessProtectedRoutes from "./components/_auth/BusinessProtectedRoutes";
import LayoutAdminBusiness from "./components/layout/LayoutBusiness/LayoutBusiness";

function App() {
  Geocode.setApiKey("AIzaSyB0Gy-BuZ0XyKlvhNtuhRPJh4RGdfQOQgE");

  return (
    <>
      {/* <div className="main__route"> */}
      <Provider store={store}>
        <Switch>
          {/* Internal Layout & Report  */}
          <Route exact path="/" component={Login} />
          <Route path="/superadmin/loan-report" component={LoanSAReport} />
          <Route
            path="/superadmin/attendance-report"
            component={AttendanceSAReport}
          />
          <Route
            path="/superadmin/employee-report"
            component={EmployeeReport}
          />
          <Route path="/superadmin/bpjs-report" component={BPJSReport} />
          <Route
            path="/superadmin/payslip-report"
            component={PayslipReportSA}
          />
          <Route path="/data-loan-viewer/:id" component={PDFDataLoan} />
          <Route path="/data-payslip-viewer/:id" component={PDFDataPayslip} />
          <Route
            path="/data-person-payslip-viewer/:id"
            component={PDFDataPayslip}
          />
          <SuperAdminProtectedRoutes path="/superadmin" component={LayoutSA} />
          <Route path="/privacy-and-policy" component={PrivacyAndPolicy} />

          {/* { token ? (
              <Route path="/response/approve-by-bm" component={ApprovalLoan}/>
            ) : (
              <Route path="/response/approve-by-bm" component={ApprovalLoan}/>
            )}
            { token ? (
              <Route path="/response/approve-by-hr-manager" component={ApprovalLoanMenuSecond}/>
            ): (
              <Route path="/response/approve-by-hr-manager" component={ApprovalLoanMenuSecond}/>
            )}
            { token ?  (
            <Route path="/response/rejected" component={RejectedPage}/>
            ) : (
            <Route path="/response/rejected" component={RejectedPage}/>
            )} */}

          {/* External Layout  & Report */}
          <Route path="/store-report-external" component={StoreReport} />
          <Route path="/product-report-external" component={ProductReport} />
          <Route
            path="/attendance-report-external"
            component={AttendanceReport}
          />
          <Route
            path="/employee-report-external"
            component={EmployeeReportExternal}
          />
          <Route path="/customer-report-external" component={CustomerReport} />
          <Route
            path="/data-loan-viewer-external/:id"
            component={PDFDataLoanExternal}
          />
          <Route
            path="/data-person-payslip-viewer-external/:id"
            component={PDFDataPayslipExternalMenu}
          />
          <AdminProtectedRoutes path="/admin" component={Layout} />
          <AdminMWSProtectedRoutes
            path="/admin-mws"
            component={LayoutAdminMWS}
          />
          <FMCGProtectedRoutes path="/admin-fmcg" component={LayoutFMCG} />
          <SuperAdminProtectedRoutes
            path="/admin-spv"
            component={LayoutSupervisor}
          />
          <BusinessProtectedRoutes path="/admin-business" component={LayoutAdminBusiness}/>
          {/* External Layout  & Report */}
          {/* <Route path="/superadmin" component={LayoutSA} /> */}

          {/* { token ?  (
            <Route path="/approved-page" component={ApprovalAttendance}/>
            ) : (
            <Route path="/approved-page" component={ApprovalAttendance}/>
            )}
            { token ?  (
            <Route path="/rejected-page" component={RejectedAttendace}/>
            ) : (
            <Route path="/rejected-page" component={RejectedAttendace}/>
            )} */}
          <Route path="/approved-page" component={ApprovalAttendance} />
          <Route path="/rejected-page" component={RejectedAttendace} />

          <Route path="/:type/:hash" component={ApprovalPage} />

          <Route path="*" component={NotFoundWithHeader} />
        </Switch>
      </Provider>
      {/* </div> */}
      {/* <div className="mini__screen">
        <MiniScreen />
      </div> */}
    </>
  );
}

export default App;
