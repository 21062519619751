import { Close } from "@mui/icons-material";
import Styles from "./ModalEpaySlip.module.css";
import INVOICE_ICON from "../../../../../assets/images/invoice.png";
import PermataLogo from "../../../../../assets/images/permata-logo-pdf.png";
import DownloadIcon from "@mui/icons-material/Download";

import { useCalendar } from "../../../../utils/hooks";
import { useMemo } from "react";
import { Button } from "@mui/material";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
  Image,
} from "@react-pdf/renderer";
import { Helmet } from "react-helmet";

import PoppinsFontRegular from "../../../../../assets/font/poppins/Poppins-Regular.ttf";
import PoppinsFont600 from "../../../../../assets/font/poppins/Poppins-Medium.ttf";
import PoppinsFont500 from "../../../../../assets/font/poppins/Poppins-SemiBold.ttf";
import PoppinsFont500Italic from "../../../../../assets/font/poppins/Poppins-SemiBoldItalic.ttf";
import moment from "moment";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: PoppinsFontRegular,
    },
    {
      src: PoppinsFont500,
    },
    {
      src: PoppinsFont500Italic,
      fontStyle: "italic",
    },
    {
      src: PoppinsFont600,
    },
  ],
});

const StylesView = StyleSheet.create({
  Body: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 35,
  },
  Heading: {
    borderTop: "5px solid #203D7F",
  },
  HeadingText: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 20,
  },
  Image: {
    width: 142,
    height: 41,
  },
  TextHeading: {
    color: "#333333",
    fontSize: 15,
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  DetailEmployee: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 50,
  },
  DetailLeft: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  TextItemDetailLeft: {
    color: "#333333",
    fontWeight: 600,
    fontSize: 13,
    fontFamily: "Poppins",
  },
  TextItemDetailRight: {
    color: "#333333",
    fontSize: 13,
    fontFamily: "Poppins",
    textTransform: "capitalize",
  },
  DetailRight: {
    flex: 1,
    marginLeft: 50,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  BoxIncome: {
    paddingTop: 50,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  BoxIncomeLeft: {
    flex: 1,
  },
  BoxIncomeRight: {
    flex: 1,
    marginLeft: 50,
  },
  BoxIncomeTop: {
    width: 150,
    height: 41,
    backgroundColor: "#203D7F",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  BoxIncomeBottom: {
    width: 210,
    height: 41,
    backgroundColor: "#203D7F",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    marginLeft: "auto",
    paddingRight: 15,
  },
  TextInsideBox: {
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "Poppins",
    color: "#FFFFFF",
  },
  ItemDetailBox: {
    borderTop: "5px solid #203D7F",
    backgroundColor: "#F1F1F1",
    padding: 15,
    // display: 'flex',
    // flexDirection: "row",
    // justifyContent: 'space-between',
  },
  ContainerIncome: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ItemBoxLeft: {
    flex: 1,
  },
  ItemBoxRight: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
  },
  ContainerBottom: {
    borderBottom: "5px solid #203D7F",
    marginTop: "auto",
  },
  Info: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 60,
  },
  InfoLeft: {
    flex: 1,
  },
  InfoRight: {
    flex: 1,
    marginLeft: 50,
  },
  TextNotes: {
    color: "#333333",
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  TextNotesContent: {
    color: "#333333",
    fontSize: 12,
    fontWeight: 500,
    // fontStyle: "italic",
    fontFamily: "Poppins",
  },
  ContainerNotes: {
    backgroundColor: "#f1f1f1",
    padding: 15,
  },
  BoxInfoTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  TextHidden: {
    fontSize: 0,
    fontFamily: "Poppins",
    color: "#FFFFFF",
  },
  TextBoxInfoTop: {
    color: "#000000",
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: 16,
  },
  BoxInfoBottom: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  BoxInfoLeft: {
    flex: 1,
  },
  BoxInfoRight: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
  },
});

function formatNpwp(value) {
  if (value) {
    value = value?.replace(/[A-Za-z\W\s_]+/g, '');
    let split = 6;
    const dots = [];

    for (let i = 0, len = value.length; i < len; i += split) {
      split = i >= 2 && i <= 6 ? 3 : i >= 8 && i <= 12 ? 4 : 2;
      dots.push(value.substr(i, split));
    }

    const temp = dots.join('.');
    return temp.length > 12
      ? `${temp.substr(0, 12)}-${temp.substr(12, 7)}`
      : temp;
  }
}

export default function ModalEpaySlip({ ePaySlip, setEPaySlip, employeeInfo }) {
  const calendar = useCalendar();
  const adminType = localStorage.getItem("type");
  let month = calendar.months.eng.full[ePaySlip?.month.split(" ")[0] - 1];
  let IDR = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  });

  const totalIncome = useMemo(() => {
    let temp = ePaySlip?.incomes?.map((el) => el?.value);
    return temp.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  }, [ePaySlip?.incomes]);

  const totalOutcome = useMemo(() => {
    let temp = ePaySlip?.deductions?.map((el) => el?.value);
    return temp.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  }, [ePaySlip?.deductions]);

  return (
    <div className={Styles.Background}>
      <div className={Styles.Container}>
        <div className={Styles.Top}>
          <span>
            E-Pay Slip {month} {ePaySlip?.year}
          </span>
          <Close onClick={() => setEPaySlip(null)} sx={{ cursor: "pointer" }} />
        </div>
        <div className={Styles.Middle}>
          <div className={Styles.Section}>
            <span>Income</span>
            {ePaySlip?.incomes?.map((each, i) => (
              <div key={i} className={Styles.BoxSection}>
                <span>{each?.label}</span>
                <span>{IDR.format(each?.value)}</span>
              </div>
            ))}
            <div className={Styles.LastBoxSection}>
              <span>Total Income</span>
              <span>{IDR.format(totalIncome)}</span>
            </div>
          </div>
          <hr className={Styles.Divider} />
          <div className={Styles.Section}>
            <span>Deduction</span>
            {ePaySlip?.deductions?.map((each, i) => (
              <div key={i} className={Styles.BoxSection}>
                <span>{each?.label}</span>
                <span>{IDR.format(each?.value)}</span>
              </div>
            ))}
            <div className={Styles.LastBoxSection}>
              <span>Total Outcome</span>
              <span>{IDR.format(totalOutcome)}</span>
            </div>
          </div>
        </div>
        <div className={Styles.Bottom}>
          <span className={Styles.LeftCropper}></span>
          <span className={Styles.RightCropper}></span>
          <div className={Styles.Closing}>
            <div className={Styles.Result}>
              <span>Take Home Pay</span>
              <span>{IDR.format(ePaySlip?.takeHomePay)}</span>
            </div>
            <img src={INVOICE_ICON} alt="invoice" />
          </div>
        </div>
        {adminType === "SUPER" ? null : (
          <div className={Styles.WrapperButton}>
            <PDFDownloadLink
              document={
                <DataToView data={ePaySlip} employeeInfo={employeeInfo} totalIncome={ totalIncome } totalOutcome={ totalOutcome }/>
              }
              fileName={`${employeeInfo?.user?.name} ${ePaySlip?.year} `}
              className={Styles.ButtonDownload}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <Button>Loading</Button>
                ) : (
                  <Button startIcon={<DownloadIcon sx={{ fontSize: 22 }} />}>
                    Download epay slip
                  </Button>
                )
              }
            </PDFDownloadLink>
          </div>
        )}
      </div>
    </div>
  );
}

const DataToView = ({ data, employeeInfo, totalIncome, totalOutcome }) => {
  const calendar = useCalendar();
  let month = calendar.months.eng.full[data?.month?.split(" ")[0] - 1];
  // let year = data?.year?.split(" ")[2];

  function numberWithPoint(x) {
    return Math.round(x).toLocaleString("id");
  }
  
  // const companyName = localStorage.getItem("clientName");
  const companyName= sessionStorage.getItem("selectedClientNameExternal") || localStorage.getItem("clientName");
  return (
    <Document>
      <Helmet>
        <title>MyWorkSpace | Payslip Report</title>
      </Helmet>

      <Page style={StylesView.Body} size="A3">
        <View style={StylesView.Heading}>
          <View style={StylesView.HeadingText}>
            <Image src={PermataLogo} alt="" style={StylesView.Image} />
            <Text style={StylesView.TextHeading}>Payment Statement</Text>
          </View>
        </View>
        <View style={StylesView.DetailEmployee}>
          <View style={StylesView.DetailLeft}>
            <View style={StylesView.ItemDetailLeft}>
              <Text style={[StylesView.TextItemDetailLeft]}>Name</Text>
              <Text style={[StylesView.TextItemDetailLeft]}>NRK</Text>
              <Text style={StylesView.TextItemDetailLeft}>Position</Text>
              <Text style={StylesView.TextItemDetailLeft}>Organization</Text>
              <Text style={StylesView.TextItemDetailLeft}>Location</Text>
            </View>
            <View style={StylesView.ItemDetailRight}>
              <Text style={StylesView.TextItemDetailRight}>
                {employeeInfo?.user?.name
                  ? employeeInfo?.user?.name?.toLowerCase()
                  : "-"}
              </Text>
              <Text style={[StylesView.TextItemDetailRight]}>
                {employeeInfo?.user?.employeeId
                  ? employeeInfo?.user?.employeeId
                  : "-"}
              </Text>
              <Text style={StylesView.TextItemDetailRight}>
                {data?.jobTitle ? data?.jobTitle?.toLowerCase() : "-"}
              </Text>
              <Text style={StylesView.TextItemDetailRight}>
                {companyName ? companyName : "-"}
              </Text>
              <Text style={StylesView.TextItemDetailRight}>
                {data?.area ? data?.area?.toLowerCase() : "-"}
              </Text>
            </View>
          </View>
          <View style={StylesView.DetailRight}>
            <View style={StylesView.ItemDetail}>
              <Text style={[StylesView.TextItemDetailLeft]}>Month</Text>
              <Text style={[StylesView.TextItemDetailLeft]}>Join Date</Text>
              <Text style={StylesView.TextItemDetailLeft}>
                Tax Material Status
              </Text>
              <Text style={StylesView.TextItemDetailLeft}>
                Employment Status
              </Text>
              <Text style={StylesView.TextItemDetailLeft}>NPWP</Text>
            </View>
            <View style={StylesView.ItemDetailRight}>
              <Text style={StylesView.TextItemDetailRight}>
                {data?.incomes ? `${month} ${data?.year}` : "-"}
              </Text>
              <Text style={[StylesView.TextItemDetailRight]}>
                {employeeInfo?.joinDate? moment(new Date(employeeInfo?.joinDate)).format("l"): "-"}
              </Text>
              <Text style={StylesView.TextItemDetailRight}>
                {employeeInfo?.user?.maritalStatus
                  ? employeeInfo?.user?.maritalStatus
                  : "-"}
              </Text>
              <Text style={StylesView.TextItemDetailRight}>
                {employeeInfo?.status ? employeeInfo?.status : "-"}
              </Text>
              <Text style={StylesView.TextItemDetailRight}>
                {employeeInfo?.user?.taxId ? formatNpwp(employeeInfo?.user?.taxId) : "-"}
              </Text>
            </View>
          </View>
        </View>
        <View style={StylesView.BoxIncome}>
          <View style={StylesView.BoxIncomeLeft}>
            <View style={StylesView.BoxIncomeTop}>
              <Text style={StylesView.TextInsideBox}>INCOME (A)</Text>
            </View>
            <View style={StylesView.ItemDetailBox}>
              {data?.incomes?.map((each, index) => (
                <View style={StylesView.ContainerIncome} key={index}>
                  <View style={StylesView.ItemBoxLeft}>
                    <Text style={StylesView.TextItemDetailRight}>
                      {each.label}
                    </Text>
                  </View>
                  <View style={StylesView.ItemBoxRight}>
                    <Text style={StylesView.TextItemDetailRight}>
                      Rp.{each.value ? numberWithPoint(each.value) : 0}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View style={StylesView.BoxIncomeBottom}>
              <Text style={StylesView.TextInsideBox}>
                Rp.{totalIncome ? numberWithPoint(totalIncome) : 0}
              </Text>
            </View>
          </View>
          <View style={StylesView.BoxIncomeRight}>
            <View style={StylesView.BoxIncomeTop}>
              <Text style={StylesView.TextInsideBox}>DEDUCTION (B)</Text>
            </View>
            <View style={StylesView.ItemDetailBox}>
              {data?.deductions?.map((each, index) => (
                <View style={StylesView.ContainerIncome} key={index}>
                  <View style={StylesView.ItemBoxLeft}>
                    <Text style={StylesView.TextItemDetailRight}>
                      {each.label}
                    </Text>
                  </View>
                  <View style={StylesView.ItemBoxRight}>
                    <Text style={StylesView.TextItemDetailRight}>
                      Rp.{each.value ? numberWithPoint(each.value) : 0}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View style={StylesView.BoxIncomeBottom}>
              <Text style={StylesView.TextInsideBox}>
                Rp.
                {totalOutcome
                  ? numberWithPoint(totalOutcome)
                  : 0}
              </Text>
            </View>
          </View>
        </View>
        <View style={StylesView.ContainerBottom}>
          <View style={StylesView.Info}>
            <View style={StylesView.InfoLeft}>
              <Text style={StylesView.TextNotes}>Notes</Text>
              <View style={StylesView.ContainerNotes}>
                <Text style={[StylesView.TextNotesContent]}>
                Payment statment ini sudah digenerate sistem dan tidak diperlukan tanda tangan
                </Text>
              </View>
            </View>
            <View style={StylesView.InfoRight}>
              <Text style={[StylesView.TextHidden]}>Right</Text>
              <View style={StylesView.BoxInfoTop}>
                <Text style={StylesView.TextBoxInfoTop}>
                  TAKE HOME PAY (A - B)
                </Text>
                <Text style={StylesView.TextBoxInfoTop}>
                  Rp.
                  {data?.takeHomePay
                    ? numberWithPoint(data?.takeHomePay)
                    : 0}
                </Text>
              </View>
              <View style={StylesView.BoxInfoBottom}>
                <View style={StylesView.BoxInfoLeft}>
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      fontFamily: "Poppins",
                    }}
                  >
                    Bank Name
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      fontFamily: "Poppins",
                    }}
                  >
                    Transfer Account Number
                  </Text>
                </View>
                <View style={StylesView.BoxInfoRight}>
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      fontFamily: "Poppins",
                    }}
                  >
                    {employeeInfo?.user?.account?.bankName ? employeeInfo?.user?.account?.bankName : "-"}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: 400,
                      fontFamily: "Poppins",
                    }}
                  >
                    {employeeInfo?.user?.account?.number ? employeeInfo?.user?.account?.number : "-"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
