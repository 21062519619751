import { Button, IconButton } from "@mui/material";
import React from "react";
import { useHistory } from "react-router";
import Breadcrumb from "../../../utils/Breadcrumbs";
import "./DetailAttendancePerDay.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EmployeeImage from "../../../../assets/images/anas.png";
import AreaIcon from "../../../../assets/images/area-icon.png";
import StoreIcon from "../../../../assets/images/store-icon.png";
import LeaderIcon from "../../../../assets/images/leader-icon.png";
import ArrowUpIcon from "../../../../assets/images/arrow-up-icon.png";
import LocationIcon from "../../../../assets/images/location-icon.png";
import ArrowDownIcon from "../../../../assets/images/arrow-down-icon.png";
import StatusIcon from "../../../../assets/images/status-icon.png";
import SelfieImage from "../../../../assets/images/selfie-image.png";

const DetailAttendancePerDay = () => {
  const history = useHistory();
  return (
    <div className="ContainerAttendancePerDay">
      <div className="WrapperAttendancePerDay">
        <div className="ButtonRouteBack" onClick={() => history.go(-1)}>
          <IconButton>
            <ArrowBackIcon sx={{ color: "#202020", width: 24, height: 24 }} />
          </IconButton>
        </div>
        <div className="WrapperBreadCrumbs">
          <Breadcrumb>
            <Button disabled style={{ textTransform: "none" }}>
              <span className="BreadCrumbsTextGrey">Attendance</span>
            </Button>
            <Button disabled style={{ textTransform: "none" }}>
              <span className="BreadCrumbsTextBlue">Detail Attendance</span>
            </Button>
          </Breadcrumb>
        </div>
      </div>
      <div className="ContainerBoxAttendancePerDay">
        <div className="ItemHeaderBoxAttendancePerDay">
          <div className="ItemLeftHeaderBoxAttendancePerDay">
            <img src={EmployeeImage} alt="" />
            <div className="ItemInfoHeaderBoxAttendancePerDay">
              <span>Anastasia Salsabila</span>
              <span>Sales Promotion Girl</span>
            </div>
          </div>
          <div className="ItemRightHeaderBoxAttendancePerDay">
            <CalendarMonthIcon />
            <span>24 Oktober 2022</span>
          </div>
        </div>
        <div className="WrapperInputInfoAttendancePerDay">
          <div className="ItemsInfoAttendancePerDay">
            <div className="ItemLeftInfoAttendancePerDay">
              <img src={AreaIcon} alt="" />
              <span>Regional</span>
            </div>
            <div className="ItemRightInfoAttendancePerDay">
              <span>Jakarta</span>
            </div>
          </div>
          <div className="ItemsInfoAttendancePerDay">
            <div className="ItemLeftInfoAttendancePerDay">
              <img src={StoreIcon} alt="" />
              <span>Store</span>
            </div>
            <div className="ItemRightInfoAttendancePerDay">
              <span>Indomart Cabang Mampang Prapatan</span>
            </div>
          </div>
          <div className="ItemsInfoAttendancePerDay">
            <div className="ItemLeftInfoAttendancePerDay">
              <img src={LeaderIcon} alt="" />
              <span>Leader</span>
            </div>
            <div className="ItemRightInfoAttendancePerDay">
              <span>Alfin Wira Yuda</span>
            </div>
          </div>
          <div className="ItemsInfoAttendancePerDay">
            <div className="ItemLeftInfoAttendancePerDay">
              <img src={ArrowUpIcon} alt="" />
              <span>Time In</span>
            </div>
            <div className="ItemRightInfoAttendancePerDay">
              <span>08:12</span>
            </div>
          </div>
          <div className="ItemsInfoAttendancePerDay">
            <div className="ItemLeftInfoAttendancePerDay">
              <img src={ArrowDownIcon} alt="" />
              <span>Time Out</span>
            </div>
            <div className="ItemRightInfoAttendancePerDay">
              <span>-</span>
            </div>
          </div>
          <div className="ItemsInfoAttendancePerDay">
            <div className="ItemLeftInfoAttendancePerDay">
              <img src={StatusIcon} alt="" />
              <span>Status</span>
            </div>
            <div className="ItemRightInfoAttendancePerDay">
              <span>Hadir</span>
              <img src={SelfieImage} alt="" />
            </div>
          </div>
          <div className="ItemsInfoAttendancePerDay">
            <div className="ItemLeftInfoAttendancePerDay">
              <img src={LocationIcon} alt="" />
              <span>Location</span>
            </div>
            <div className="ItemRightInfoAttendancePerDay">
              <div className="WrapperLocationAttendancePerDay">Map</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailAttendancePerDay;
