import React, { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./ApprovalModal.module.css";
import CHECKED_BLUE from "../../../assets/images/success-icon-add-scheme.png";
import CANCEL_RED from "../../../assets/images/cancel-red.png";
import axios from "axios";
import {API} from "../../../redux/api";
import CircularProgress from "@mui/material/CircularProgress";
// import { useHistory } from "react-router";

const clickOutsideRef = (content_ref, toggle_ref) => {
  document.addEventListener("mousedown", (e) => {
    // user click toggle
    if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
      content_ref.current.classList.toggle("active");
    } else {
      // user click outside toggle and content
      if (content_ref.current && !content_ref.current.contains(e.target)) {
        content_ref.current.classList.remove("active");
      }
    }
  });
};

export default function ApprovalModal(props) {
  const { onOpen, type, dataAttendance, dataEmployee, dataResign, hash, dataOvertime } = props;
  // const history = useHistory()
  const dropdown_toggle_el = useRef(null);
  const dropdown_content_el = useRef(null);
  // console.log(type, "type");

  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState()

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (type === "attendance" && dataAttendance?.isRevision === false) {
          await axios.patch(
            `${API}/attendance/web-approval/${dataAttendance?._id}`,
            {
              status: "APPROVED",
            }
          ).then((res) => {
            setResponse({status: 'success'})
            console.log(res);
          })
          .catch((error) => {
            setResponse({status: 'error', message: error.response?.data?.error});
            console.log(error);
          });
        } else if (
          type === "attendance" &&
          dataAttendance?.isRevision === true
        ) {
          await axios
            .patch(
              `${API}/attendance/web-revision-approval/${hash}`,
              { status: "APPROVED" }
            )
            .then((res) => {
              setResponse({status: 'success'})
              console.log(res);
            })
            .catch((error) => {
              setResponse({status: 'error', message: error.response?.data?.error});
              console.log(error);
            });
        } else if (type === "employee") {
          await axios
            .post(
              `${API}/profile/approval/${dataEmployee?.profileChange?._id}`,
              {
                status: "APPROVED",
              }
            )
            .then((res) => {
              setResponse({status: 'success'})
              console.log(res);
            })
            .catch((error) => {
              setResponse({status: 'error', message: error.response?.data?.error});
              console.log(error);
            });
        } else if (type === "resign") {
          await axios
            .patch(`${API}/resign/web/${dataResign?._id}`, {
              status: "APPROVE",
            })
            .then((res) => {
              setResponse({status: 'success'})
              console.log(res);
            })
            .catch((error) => {
              setResponse({status: 'error', message: error.response?.data?.error});
              console.log(error);
            });
        } else if (type === "overtime-approval-web") {
          await axios
          .patch(`${API}/attendance/overtime-approval/${dataOvertime?._id}`, {
            status: "APPROVED",
            applyDate: dataOvertime?.applyDate,
            startTime: dataOvertime?.startTime,
            endTime: dataOvertime?.endTime,
          })
          .then((res) => {
            setResponse({status: 'success'})
            console.log(res);
          })
          .catch((error) => {
            setResponse({status: 'error', message: error.response?.data?.error});
            console.log(error);
          });
        }
        setTimeout(() => {
          onOpen(false);
        }, 3000);
        window.location.reload(false);
      } catch (error) {
        setResponse({status: 'error', message: error.response?.data?.error});
        console.log(error);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if (type === "attendance") {
  //           await axios
  //       .patch(`${API}/attendance/web-approval/${dataAttendance?._id}`, {
  //         status: "APPROVED",
  //       })
  //       .then((res) => {
  //         console.log(res);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //     setTimeout(() => {
  //       onOpen(false);
  //     }, 3000);
  //     window.location.reload(false);
  //   } else if (type === "employee") {
  //     setTimeout(() => {
  //       onOpen(false);
  //     }, 3000);
  //     window.location.reload(false);
  //   }
  // }, []);

  clickOutsideRef(dropdown_content_el, dropdown_toggle_el);

  return (
      <div className={Styles.ModalDeleteBackground}>
          <div className={Styles.ModalContainerContent}>
              <div className={Styles.WrapperItemModalSuccess}>
                  {console.log('errr', response)}
                  {loading || !response ? (
                      <CircularProgress/>
                  ) : response?.status === 'error' ? (
                      <>
                          <div className={Styles.Image}>
                              <img src={CANCEL_RED} alt="error" />
                          </div>
                          <div className={Styles.Content}>
                              <span>{response?.message}</span>
                          </div>
                      </>
                  ) : (
                      <>
                          <div className={Styles.Image}>
                              <img src={CHECKED_BLUE} alt="checked-success" />
                          </div>
                          <div className={Styles.Content}>
                              <span>Process Succeed</span>
                          </div>
                      </>
                  )}
              </div>
          </div>
      </div>
  );
}
