import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
      border: '1.5px solid black',
      padding: 8,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));
