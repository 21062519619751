import { Button, IconButton } from "@mui/material";
import React from "react";
import { useHistory } from "react-router";
import "./DetailAttendance.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumb from "../../../utils/Breadcrumbs";
import EmployeeImage from "../../../../assets/images/anas.png";
import PresenceImage from "../../../../assets/images/presence-image.png";
import HolidayImage from "../../../../assets/images/holiday-image.png";
import LeaveImage from "../../../../assets/images/leave-image.png";
import SickImage from "../../../../assets/images/sick-attendance-image.png";
import DownloadIcon from "@mui/icons-material/Download";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import CalendarFMCG from "../CalendarFMCG/CalendarFMCG";

function DownloadCSV({
  data,
  filename,
  initial,
  endIcon,
  startIcon,
  className,
}) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  // const pdfExtension = '.pdf'

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      style={{
        textTransform: "none",
        backgroundColor: "#1571de",
        color: "white",
        width: 181,
        height: 40,
        borderRadius: 4,
      }}
      onClick={(e) => exportToCSV(data, filename)}
      startIcon={startIcon}
      className={className}
    >
      {initial}
    </Button>
  );
}

const DetailAttendance = () => {
  const history = useHistory();
  return (
    <div className="ContainerDetailAttendanceFMCG">
      <div className="WrapperDetailAttendanceFCMG">
        <div
          className="ButtonRouteBack"
          onClick={() => history.push("/admin-fmcg/attendances")}
        >
          <IconButton>
            <ArrowBackIcon sx={{ color: "#202020", width: 24, height: 24 }} />
          </IconButton>
        </div>
        <div className="WrapperBreadCrumbs">
          <Breadcrumb>
            <Button disabled style={{ textTransform: "none" }}>
              <span className="BreadCrumbsTextGrey">Attendance</span>
            </Button>
            <Button disabled style={{ textTransform: "none" }}>
              <span className="BreadCrumbsTextBlue">Detail Attendance</span>
            </Button>
          </Breadcrumb>
        </div>
        <div className="WrapperDetailsInfoAttendanceFMCG">
          <div className="ItemDetailsInfoEmployee">
            <img src={EmployeeImage} alt="" />
            <div className="ItemDetailsEmployee">
              <span>Anastasia Salsabila</span>
              <span>Sales Promotion Girl</span>
            </div>
          </div>
          <div className="ItemDetailsInfoEmployeeAttendance">
            <div className="InfoEmployeeAttendance">
              <img src={PresenceImage} alt="" />
              <div className="ContentEmployeeAttendance">
                <span>Presence</span>
                <span>1</span>
              </div>
            </div>
            <div className="InfoEmployeeAttendance">
              <img src={HolidayImage} alt="" />
              <div className="ContentEmployeeAttendance">
                <span>Holiday</span>
                <span>1</span>
              </div>
            </div>
            <div className="InfoEmployeeAttendance">
              <img src={LeaveImage} alt="" />
              <div className="ContentEmployeeAttendance">
                <span>Leave</span>
                <span>1</span>
              </div>
            </div>
            <div className="InfoEmployeeAttendance">
              <img src={SickImage} alt="" />
              <div className="ContentEmployeeAttendance">
                <span>Sick</span>
                <span>1</span>
              </div>
            </div>
          </div>
        </div>
        <div className="ContentAttendanceRangeDate">
          <div className="HeadingAttendanceRangeDate">
            <div className="ItemLeftAttendanceRangeDate">
              <span>Attendance Range Date</span>
              <span>21-30 Oktober 2022</span>
            </div>
            <div className="ItemRightAttendanceRangeDate">
              <DownloadCSV
                // data={dataToExport?.map((el, ind) => ({
                //   "No. ": ind + 1,
                //   Nama: el.Name,
                //   Position: el.Position,
                //   Nrk: el.Nrk,
                //   "Date in": el.dateTimeIn,
                //   "Date out": el.dateTimeOut,
                //   "Time in": el.timeIn,
                //   "Time out": el.timeOut,
                //   "Coordinate Point": `${el.latitude} ${el.longitude}`,
                //   "Status Absence":
                //     (el.type === "presence" && "Hadir") ||
                //     (el.type === "annualLeave" && "Cuti") ||
                //     (el.type === "permit" && "Sakit"),
                // }))}
                initial="Download Absence"
                startIcon={<DownloadIcon />}
                // filename={`Attendance Report ${new Date().toLocaleDateString()}`}
              />
            </div>
          </div>
          <div className="DetailAttendanceCalendar">
            <CalendarFMCG />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailAttendance;
