import React, { useState } from "react";
import "./CompetitorSetup.css";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab} from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import MasterCompetitor from "../MasterCompetitor/MasterCompetitor";
import ProductCompetitor from "../ProductCompetitor/ProductCompetitor";

const useStyles = makeStyles((theme) => ({
  TabStyle: {
    padding: 0,
    marginTop: 30,
  },
}));

function MasterCompetitorLabel() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        columnGap: 5,
      }}
    >
      <span style={{ fontSize: 16, fontWeight: 600 }}>Master Competitor</span>
    </div>
  );
}

function ProductCompetitorLabel() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        columnGap: 5,
      }}
    >
      <span style={{ fontSize: 16, fontWeight: 600 }}>Product Competitor</span>
    </div>
  );
}

const CompetitorSetupFMCG = () => {
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="ContainerCompetitorSetup">
      <TabContext value={value}>
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px  solid #E9E9E9",
          }}
        >
          <TabList
            onChange={handleChange}
            sx={{
              "& button:hover": {
                color: "#1571DE",
              },
              "& button:focus": {
                color: "#1571DE",
              },
              "& button:active": {
                color: "#1571DE",
              },
            }}
          >
            <Tab
              label={<MasterCompetitorLabel />}
              value="1"
              sx={{
                color: "#A2A9BE",
              }}
            />
            <Tab
              label={<ProductCompetitorLabel />}
              value="2"
              sx={{
                color: "#A2A9BE",
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="1" className={classes.TabStyle}>
          <MasterCompetitor />
        </TabPanel>
        <TabPanel value="2" className={classes.TabStyle}>
          <ProductCompetitor />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default CompetitorSetupFMCG;
