import React, { useState } from "react";
import "./DetailsStoreWithMaps.css";
import { Button, IconButton } from "@mui/material";
import { useHistory } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Breadcrumb from "../../../utils/Breadcrumbs";
import MapWithAutoCompleteFMCG from "../../../utils/MapAutocompleteFMCG/MapAutoCompleteFMCG";

const DetailsStoreWithMaps = ({ location }) => {
  const history = useHistory();
  const [showSearch, setShowSearch] = useState(true);
  const [address, setAddress] = useState({});
  const [coordinate, setCoordinate] = useState({});

  return (
    <div className="ContainerDetailsSPGStore">
      <div className="WrapperDetailsSPGStore">
        <div
          className="ButtonRouteBack"
          onClick={() => history.go(-1)}
        >
          <IconButton>
            <ArrowBackIcon sx={{ color: "#202020", width: 24, height: 24 }} />
          </IconButton>
        </div>
        <div className="WrapperBreadCrumbs">
          {location === "addStoreSPG" || location === "addStoreMD" ? (
            <Breadcrumb>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextGrey">Store</span>
              </Button>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextBlue">Add New Store</span>
              </Button>
            </Breadcrumb>
          ) : (
            <Breadcrumb>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextGrey">Store</span>
              </Button>
              <Button disabled style={{ textTransform: "none" }}>
                <span className="BreadCrumbsTextBlue">Edit Store</span>
              </Button>
            </Breadcrumb>
          )}
        </div>
        <div className="ContainerMapStoreSPG">
          <MapWithAutoCompleteFMCG
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            location={location}
            setAddress={setAddress}
            address={address}
            coordinate={coordinate}
            setCoordinate={setCoordinate}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsStoreWithMaps;
