/* eslint-disable no-unused-vars */

import axios from "axios";
import {API} from "../../api";
import * as types from '../../typeExternal';
// import { setSnackbar } from '../SuperAdmin/snackbarActions';

export const fetchClientByCodeExternal = (token, clientCode) => {
    return (dispatch) => {
      dispatch({ type: types.FETCH_CLIENT_BY_CODE_BEGIN })
      axios
        .get(`${API}/client/work-on/${clientCode}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          dispatch({
            type: types.FETCH_CLIENT_BY_CODE_SUCCESS,
            payload: res.data.response,
          });
        })
        .catch((err) => {
          dispatch({ type: types.FETCH_CLIENT_BY_CODE_ERROR, payload: err.response.error })
        });
    };
  };

  export const fetchDetailClientExternal = (token, attendancePlanId, clientCode) => {
    return (dispatch) => {
      dispatch({ type: types.FETCH_DETAIL_BY_CLIENT_BEGIN })
      axios
        .get(`${API}/attendance-plan/detail/${attendancePlanId}?code=${clientCode}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          dispatch({
            type: types.FETCH_DETAIL_BY_CLIENT_SUCCESS,
            payload: res.data.response,
          });
        })
        .catch((err) => {
          dispatch({ type: types.FETCH_DETAIL_BY_CLIENT_ERROR, payload: err.response.error })
        });
    };
  };