import React from "react";
import { Route, Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import NotFound404 from "../../_pageNotFound/NotFound404";
import SalaryAdvance from "../../../pages/AdminMWS/EBP/SalaryAdvance/SalaryAdvance";
import SalaryAdvanceDetails from "../../../pages/AdminMWS/EBP/SalaryAdvanceDetails/SalaryAdvanceDetails";
import Pawn from "../../../pages/AdminMWS/EBP/Pawn/PawnAdminMWS";
import PawnDetail from "../../../pages/AdminMWS/EBP/PawnDetail/PawnDetailAdminMWS";

const RoutesAdminMWS = () => {
  // const token = localStorage.getItem('accessToken');
  const { path } = useRouteMatch();
  // const token = localStorage.getItem('accessToken');

  // if (!token) {
  //   return <Redirect to="/admin-mws" />;
  // }
  return (
    <div>
      <Switch>
        {/* SALARY ADVANCE */}
        <Route exact path={`${path}/salary-advance`} component={SalaryAdvance} />
        <Route exact path={`${path}/salary-advance/1`} component={SalaryAdvanceDetails} />
        {/* SALARY ADVANCE */}


        {/* PAWN  */}
        <Route exact path={`${path}/pawn`} component={Pawn} />
        <Route exact path={`${path}/pawn/:id`} component={PawnDetail} />
        {/* PAWN  */}
        
        <Route path="*" component={NotFound404}></Route>
      </Switch>
    </div>
  );
};

export default RoutesAdminMWS;
