import { Cancel, RadioButtonChecked, RadioButtonUnchecked, ControlPoint, Edit, AddCircle } from "@mui/icons-material";
import PublishIcon from "@mui/icons-material/Publish";
import { Alert, Autocomplete, Button, Checkbox, FormControl, IconButton, styled, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import DELETE_ICON from "../../../../../assets/images/delete-rules-icon.png";
import DELETE_SVG from "../../../../../assets/images/delete.svg";
import fetchClientCode from "../../../../../async/client/fetchClientCode";
import { updateInformationById } from "../../../../../redux/actions/SuperAdmin/informationActions";
import ModalPublishedInfo from "../PublishedInformation/ModalPublishedInfo";
import Styles from './EditInformation.module.css';
import moment from "moment";
import NOTIFICATION_LOGO_IMG from "../../../../../assets/images/notification-logo.png"
import NOTIFICATION_IMG from "../../../../../assets/images/notification.png"
import ModalAddPushNotification from "../ModalAddPushNotification";
import dayjs from "dayjs";
import CustomTable from "../../../../CustomTable/CustomTable";
import ModalPreview from "../ModalPreview";

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 5,
      width: "100%",
    },
    "& .MuiSelect-select": {
      fontSize: 16,
    },
  },
});

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      border: '1px solid #e0e0e0',
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1.5px solid #d3c9c9",
    borderColor: "#d3c9c9",
    borderRadius: 8,
    //   width: "100%",
  },
});

export default function EditInformationForm({ information }) {
  const token = localStorage.getItem("accessToken");
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const dataInfo = location.state.data;
  const { id } = useParams();
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalDraft, setModalDraft] = useState(false);
  const [alert, setAlert] = useState(false);
  const [editedData, setEditedData] = useState({
    title: dataInfo?.title,
    content: dataInfo?.content,
    clientCode: dataInfo?.clientCode,
    image: null,
    isPublish: dataInfo?.isPublish,
  });
  const inputFileRef = useRef()
  const [preview, setPreview] = useState(dataInfo?.image !== null ? dataInfo?.image : null);
  const [previewName, setPreviewName] = useState(dataInfo?.title + '.jpg');

  const [dataClientCode, setDataClientCode] = useState([]);
  const [selectedClients, setSelectedClients] = useState([])

  const [pushNotification, setPushNotification] = useState([])
  const [modalAddNotification, setModalAddNotification] = useState(false)
  const [selectedPushNotification, setSelectedPushNotification] = useState(null)
  const [modalPreviewNotif, setModalPreviewNotif] = useState(false)

  const [sourcePushNotification, setSourcePushNotification] = useState([])
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    if (information?.notifications?.length > 0){
      
        const mapNotif = information.notifications.map((value, index) => {
          return {
              ...value,
              id: index,
              schedule: value?.schedule?.map((valueSch, idxSch) => (
                  {
                      ...valueSch,
                      id: idxSch,
                      date: valueSch?.date ?  dayjs(valueSch?.date) : null,
                      time: valueSch?.date ?  moment(new Date(valueSch?.date)).format('HH:mm:ss') : null,
                  }
              )) || []
          }
        })
        
        setSourcePushNotification(mapNotif)
        setPushNotification(mapNotif)
    }
  }, [information?.notifications])

  useEffect(() => {
    if (modalSuccess || modalDraft) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [modalSuccess, modalDraft])

  const rowPushNotificationData = useMemo(() => {
    if (pushNotification?.length > 0){
        const startNumber = ((page - 1) * rowsPerPage) + 1
        const lastNumber = page * rowsPerPage
        let arrSch = []
        const temp = [...pushNotification]

        for(let i = startNumber; i <= lastNumber; i++ ){
            if (temp[i-1]){
                arrSch.push({
                    ...temp[i-1],
                    no: i
                })
            }
        }

        return arrSch
    } else {
      return []
    }
  }, [pushNotification, rowsPerPage, page])

  useEffect(() => {
    if (pushNotification?.length > 0){
        const size = pushNotification?.length || 0
        const total = Math.ceil(size / rowsPerPage)
        setTotalPage(total > 0 ? total : 1)
    }
  }, [pushNotification, rowsPerPage])

  const getNextSchedule = (schedule) => {
      if (!schedule || schedule.length === 0) return '-';

      const sortedSchedule = schedule.sort((a, b) => {
          const datetimeA = a.date.set('hour', a.time.split(':')[0]).set('minute', a.time.split(':')[1]);
          const datetimeB = b.date.set('hour', b.time.split(':')[0]).set('minute', b.time.split(':')[1]);
          return datetimeA - datetimeB;
      });

      const nearest = sortedSchedule[0];
      const formattedNearest = nearest.date
          .set('hour', nearest.time.split(':')[0])
          .set('minute', nearest.time.split(':')[1])
          .format('DD MMM YYYY, HH:mm');

      return formattedNearest;
  };

  const TABLE_HEAD = [
      { id: 'no', label: 'No', alignRight: false, width: '10%' },
      { id: 'title', label: 'Judul Notifikasi', alignRight: false, width: '20%' },
      { id: 'description', label: 'Deskripsi', alignRight: false, width: '30%' },
      {
          id: 'schedule',
          label: 'Jadwal selanjutnya',
          alignRight: false,
          width: '30%',
          renderData: (row) => <span>{getNextSchedule(row?.schedule)}</span>,
      },
      {
          id: '',
          width: '10%',
          renderData: (row) => {
              return (
                  <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                          setSelectedPushNotification(row);
                          setModalAddNotification(true);
                      }}>
                      <span style={{ color: '#1571DE' }}>Lihat Detail</span>
                  </div>
              );
          },
      },
  ];

  const fixedClients = useMemo(() => {
    let pre = [{ codes: "ALL", name: "ALL" }, { codes: 'Tes', name: 'Client Tester' }]
    return pre.concat(dataClientCode);
  }, [dataClientCode])

  const preClients = useMemo(() => {
    const temp = fixedClients.filter((el) => {
      return information?.clientCode.some((f) => {
        return f === el.codes
      });
    });
    return temp
  }, [fixedClients, information])

  useEffect(() => {
    setSelectedClients(preClients)
  }, [preClients])


  const handleSelectedClient = (e, value) => {
    e.preventDefault()

    const isExist = selectedClients.find((item) => item?.name === value?.name);
    const doesContainAll = selectedClients.some((item) => item?.codes === 'All');
    let isAll = Boolean(value?.codes === 'All' && value?.name === 'All')
    const isEmpty = selectedClients.length === 0;


    if (!isExist && !isAll && !doesContainAll) {
      let temp = selectedClients.concat(value)
      setSelectedClients(temp)
    } else if (isExist) {
      const x = selectedClients.filter((item) => item !== value);
      setSelectedClients(x)
    } else if (isEmpty && isAll) {
      let temp = selectedClients.concat(value)
      setSelectedClients(temp)
    } else if (!isExist && doesContainAll) {
      setSelectedClients(selectedClients)
    }

  };

  const handleDeleteClient = (value) => {
    const x = selectedClients.filter((item) => item !== value);
    setSelectedClients(x)
  }


  const isSelected = (option) => selectedClients?.indexOf(option) !== -1;



  const handleEditData = (e) => {
    setEditedData((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "file" ? e.target.files : e.target.value
    }));
    if (e.target.type === "file") {
      let url = URL.createObjectURL(e.target.files[0]);
      setPreview(url);
      setPreviewName(e.target.files[0].name);
    }
  };

  const handleDeleteImage = (e) => {
    inputFileRef.current.value = null;
    setPreview(null);
    setPreviewName('');
    setEditedData((prev) => ({
      ...prev,
      // eslint-disable-next-line no-useless-computed-key
      ["image"]: null
    }));
  }

  const handleSubmitPushNotification = (value, type) => {
    if (type === 'ADD'){
      setPushNotification((prev) => {
        return [
          ...prev,
          {
            ...value,
            id: prev?.length || 0
          }
        ]
      })
      setSourcePushNotification((prev) => {
        return [
          ...prev,
          {
            ...value,
            id: prev?.length || 0,
            action: "ADD"
          }
        ]
      })
    } else if (type === 'EDIT'){
      setPushNotification((prev) => {
        const idx = prev?.findIndex(f => f?.id === value?.id)
        prev[idx] = {...prev[idx], ...value}
        return [...prev]
      })
      
      setSourcePushNotification((prev) => {

          if (!prev || !value) return prev;

          const idx = prev.findIndex((f) => f?.id === value?.id);

          if (idx !== -1 && selectedPushNotification?._id) {
              const tempValueSchedule = value.schedule || [];
              const existingSchedule = selectedPushNotification?.schedule || [];

              const tempDelete = existingSchedule.filter(
                  (f) => !tempValueSchedule.some((scheduleItem) => scheduleItem?._id === f?._id)
              );
              
              const tempEdit = tempValueSchedule.filter((f) => {
                  const matchingItem = existingSchedule.find((scheduleItem) => scheduleItem?._id && (scheduleItem?._id === f?._id));

                  return matchingItem && (matchingItem.date !== f.date || matchingItem.time !== f.time);
              });

              const tempDeleteID = existingSchedule.filter(
                  (f) => !tempValueSchedule.some((scheduleItem) => scheduleItem?.id === f?.id) && !f?._id
              );

              const tempEditID = tempValueSchedule.filter((f) => {
                  const matchingItem = existingSchedule.find((scheduleItem) => !f?._id && scheduleItem?.id && (scheduleItem?.id === f?.id));

                  return matchingItem && (matchingItem.date !== f.date || matchingItem.time !== f.time);
              });

              const tempAdd = tempValueSchedule.filter(
                  (f) => !f?._id && !existingSchedule.some((scheduleItem) => scheduleItem?.id === f?.id)
              );

              // Update existing schedules with actions (DELETE/UPDATE)
              const updatedSchedule = prev[idx]?.schedule?.map((itemPrev) => {
                  
                  // const deleteItem = tempDelete.find((f) => f?._id === itemPrev?._id);
                  // if (deleteItem) {
                  //     return { ...deleteItem, action: 'DELETE' };
                  // }
              
                  const editItem = tempEdit.find((f) => f?._id === itemPrev?._id);
                  if (editItem) {
                      return { ...editItem, action: 'UPDATE' };
                  }

                  const deleteItemID = tempDeleteID.find((f) => f?.id === itemPrev?.id)
                  if (deleteItemID) {
                    return undefined
                  }

                  const editItemID = tempEditID.find((f) => f?.id === itemPrev?.id);
                  if (editItemID) {
                      return { ...editItemID };
                  }

                  // const addItem = tempAdd.find((f) => f?.id === itemPrev?.id)
                  // if (addItem) {
                  //   return {...addItem, action: 'ADD'}
                  // }

                  if (itemPrev?._id || itemPrev?.action) return itemPrev

                  return undefined
              }).filter((f) => f !== undefined);

              // Delete schedules
              const deleteSchedules = tempDelete.map((item) => ({
                 ...item, action: 'DELETE' 
              }));

              // Add new schedules with the ADD action
              const newSchedules = tempAdd.map((item) => ({
                  ...item,
                  action: 'ADD',
              }));

              const combinedSchedule = [...updatedSchedule, ...deleteSchedules, ...newSchedules];

              const updatedItem = {
                  ...prev[idx],
                  ...value,
                  action: 'UPDATE',
                  schedule: combinedSchedule,
              };

              return [...prev.slice(0, idx), updatedItem, ...prev.slice(idx + 1)];
          } else if (idx !== -1 && !selectedPushNotification?._id) {
            const updatedItem = {
                ...prev[idx],
                ...value,
            };
            return [...prev.slice(0, idx), updatedItem, ...prev.slice(idx + 1)];
          } else {
              return prev;
          }
      });
      
      setSelectedPushNotification(null)
    } 
    // else if (type === 'DELETE'){
    //   setPushNotification((prev) => {
    //     const arr = prev?.filter(f => f?.id !== value?.id)
    //     return [...arr]
    //   })
    //   setSourcePushNotification((prev) => {
    //     const idx = prev?.findIndex(f => f?.id === value?.id)
    //     prev[idx] = {
    //       ...prev[idx],
    //       action: "DELETE"
    //     }
    //     return [...prev]
    //   })
    //   setSelectedPushNotification(null)
    // }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.set("title", editedData?.title);
    formData.set("content", editedData?.content);
    for (let i = 0; i < selectedClients.length; i++) {
      formData.append("clientCode", selectedClients[i]?.codes);
    }
    if (editedData?.image !== null && editedData?.image !== undefined && editedData?.image !== "") {
      formData.set("image", editedData?.image[0], editedData?.image[0].name);
    }

    if (sourcePushNotification?.length > 0){
      let indexNotif = 0
      sourcePushNotification?.forEach((notif, index) => {
        if (notif?.action){
          formData.append(`pushNotif[${indexNotif}][title]`, notif.title);
          formData.append(`pushNotif[${indexNotif}][description]`, notif.description);
          if (notif.action !== "ADD"){ 
            formData.append(`pushNotif[${indexNotif}][_id]`, notif?._id)
            formData.append(`pushNotif[${indexNotif}][action]`, notif.action)
          };

          if (notif?.schedule?.length > 0){
            let indexSchedule = 0
            notif?.schedule?.forEach((scheduleItem) => {
              if (notif.action === "UPDATE"){
                if (scheduleItem?.action){
                  const [hours, minutes, seconds] = scheduleItem?.time?.split(":").map(Number);
                  const fixedDate = new Date(scheduleItem?.date)

                  fixedDate.setHours(hours)
                  fixedDate.setMinutes(minutes)
                  fixedDate.setSeconds(seconds)

                  formData.append(`pushNotif[${indexNotif}][schedule][${indexSchedule}][date]`, fixedDate?.toISOString());
                  if (scheduleItem?.action !== "ADD"){
                    formData.append(`pushNotif[${indexNotif}][schedule][${indexSchedule}][_id]`, scheduleItem._id);
                    formData.append(`pushNotif[${indexNotif}][schedule][${indexSchedule}][action]`, scheduleItem.action);
                  }
                  indexSchedule++
                }
              } else {
                const [hours, minutes, seconds] = scheduleItem?.time?.split(":").map(Number);
                const fixedDate = new Date(scheduleItem?.date)
  
                fixedDate.setHours(hours)
                fixedDate.setMinutes(minutes)
                fixedDate.setSeconds(seconds)
  
                formData.append(`pushNotif[${indexNotif}][schedule][${indexSchedule}][date]`, fixedDate?.toISOString());
                if (scheduleItem?.action && scheduleItem?.action !== "ADD" && notif?.action !== "ADD"){
                  formData.append(`pushNotif[${indexNotif}][schedule][${indexSchedule}][_id]`, scheduleItem._id);
                  formData.append(`pushNotif[${indexNotif}][schedule][${indexSchedule}][action]`, scheduleItem.action);
                } 
                indexSchedule++
              }
            })
          }
          indexNotif++
        }
      });
    }

    formData.set("isPublish", true);
    formData.set("status", "Published");

    if (formData.title === "" || formData.content === "") {
      setAlert(true);
    } else {
      // const entries = Array.from(formData.entries());
      
      // entries.forEach(([key, value]) => {
      //   console.log(`${key}:`, value);
      // });

      dispatch(updateInformationById(token, information._id, formData));
      setModalSuccess(true);
    }
  };

  const saveToDraft = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set("title", editedData?.title);
    formData.set("content", editedData?.content);
    for (let i = 0; i < selectedClients.length; i++) {
      formData.append("clientCode", selectedClients[i]?.codes);
    }
    if (editedData?.image !== null && editedData?.image !== undefined && editedData?.image !== "") {
      formData.set("image", editedData.image[0], editedData.image[0].name);
    }
    
    if (sourcePushNotification?.length > 0){
      let indexNotif = 0
      sourcePushNotification?.forEach((notif, index) => {
        if (notif?.action){
          formData.append(`pushNotif[${indexNotif}][title]`, notif.title);
          formData.append(`pushNotif[${indexNotif}][description]`, notif.description);
          if (notif.action !== "ADD"){ 
            formData.append(`pushNotif[${indexNotif}][_id]`, notif?._id)
            formData.append(`pushNotif[${indexNotif}][action]`, notif.action)
          };

          if (notif?.schedule?.length > 0){
            let indexSchedule = 0
            notif?.schedule?.forEach((scheduleItem) => {
              if (notif.action === "UPDATE"){
                if (scheduleItem?.action){
                  const [hours, minutes, seconds] = scheduleItem?.time?.split(":").map(Number);
                  const fixedDate = new Date(scheduleItem?.date)

                  fixedDate.setHours(hours)
                  fixedDate.setMinutes(minutes)
                  fixedDate.setSeconds(seconds)

                  formData.append(`pushNotif[${indexNotif}][schedule][${indexSchedule}][date]`, fixedDate?.toISOString());
                  if (scheduleItem?.action !== "ADD"){
                    formData.append(`pushNotif[${indexNotif}][schedule][${indexSchedule}][_id]`, scheduleItem._id);
                    formData.append(`pushNotif[${indexNotif}][schedule][${indexSchedule}][action]`, scheduleItem.action);
                  }
                  indexSchedule++
                }
              } else {
                const [hours, minutes, seconds] = scheduleItem?.time?.split(":").map(Number);
                const fixedDate = new Date(scheduleItem?.date)
  
                fixedDate.setHours(hours)
                fixedDate.setMinutes(minutes)
                fixedDate.setSeconds(seconds)
  
                formData.append(`pushNotif[${indexNotif}][schedule][${indexSchedule}][date]`, fixedDate?.toISOString());
                if (scheduleItem?.action && scheduleItem?.action !== "ADD" && notif?.action !== "ADD"){
                  formData.append(`pushNotif[${indexNotif}][schedule][${indexSchedule}][_id]`, scheduleItem._id);
                  formData.append(`pushNotif[${indexNotif}][schedule][${indexSchedule}][action]`, scheduleItem.action);
                } 
                indexSchedule++
              }
            })
          }
          indexNotif++
        }
      });
    }

    formData.set("isPublish", false);
    formData.set("status", "Draft");

    if (formData.title === "" || formData.content === "") {
      setAlert(true);
    } else {
      dispatch(updateInformationById(token, information._id, formData));
      setModalDraft(true)
    }
  };

  const showTime = useCallback((timeString) => {
      const temp = timeString?.split(":")
      return `${temp[0]}:${temp[1]}`
  },[])

  const handleClickCancel = (e) => {
    e.preventDefault();

    if (localStorage.getItem("type") === "BUSINESS"){
      history.push("/admin-business/information");
    } else if (localStorage.getItem("type") === "SUPER"){
      history.push("/superadmin/information");
    }
  };

  const [files, setFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
    setEditedData((prev) => ({
      ...prev,
      // eslint-disable-next-line no-useless-computed-key
      ["image"]: acceptedFiles
    }));

    setPreview(URL.createObjectURL(acceptedFiles[0]));
    setPreviewName(acceptedFiles[0].name);
  }, []);

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    multiple: false
  });

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCode(response)
      } catch (error) {
        console.log()
      }
    }
    )()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const capitalizedClientName = (clientName) => {
    return clientName?.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace('Pt', 'PT')
      .replace('Cv', 'CV')
  }

  return (
      <div className={Styles.Container}>
          <div className={Styles.Wrapper}>
              <form onSubmit={handleSubmit}>
                  <div className={Styles.WrapperBox} style={{ paddingInline: 0 }}>
                      {alert && (
                          <Alert sx={{ marginBottom: 3 }} severity="warning">
                              Title and Content are mandatories!
                          </Alert>
                      )}
                      <div style={{ display: 'flex', gap: 10 }}>
                          <div className={Styles.ItemBox}>
                              <span>To</span>
                              <FormControl size="small" fullWidth>
                                  <Autocomplete
                                      disablePortal
                                      value={null}
                                      blurOnSelect={true}
                                      // value={selectedClient}
                                      disableCloseOnSelect
                                      options={fixedClients}
                                      getOptionLabel={(option) => option?.name}
                                      sx={{ width: '100%' }}
                                      onChange={(e, value) =>
                                          value === null
                                              ? handleSelectedClient(e, null)
                                              : handleSelectedClient(e, value)
                                      }
                                      renderOption={(props, option) => {
                                          const isItemSelected = isSelected(option);
                                          return (
                                              <li
                                                  {...props}
                                                  onClick={(e) => e.preventDefault()}
                                                  key={option?.codes}
                                                  style={{ borderBottom: '1px solid #c2c2c2', padding: '12px' }}>
                                                  <div
                                                      style={{
                                                          display: 'flex',
                                                          justifyContent: 'space-between',
                                                          alignItems: 'center',
                                                          width: '100%',
                                                      }}>
                                                      <span className={Styles.ClientOption}>
                                                          {capitalizedClientName(option?.name)}
                                                      </span>
                                                      <Checkbox
                                                          onClick={(e) => handleSelectedClient(e, option)}
                                                          sx={{ margin: 0, padding: 0 }}
                                                          checked={isItemSelected}
                                                          icon={<RadioButtonUnchecked sx={{ color: '#c2c2c2' }} />}
                                                          checkedIcon={<RadioButtonChecked sx={{ color: '#1571DE' }} />}
                                                      />
                                                  </div>
                                              </li>
                                          );
                                      }}
                                      renderInput={(params) => (
                                          <MuiTextField
                                              {...params}
                                              size="small"
                                              sx={{ background: 'var(--main-bg)' }}
                                              placeholder="Select company"
                                          />
                                      )}
                                  />
                              </FormControl>
                          </div>
                          <div className={Styles.ItemBox}>
                              <span className={Styles.ItemBoxSpan}>Title</span>
                              <input
                                  className={Styles.ItemBoxInput}
                                  type="text"
                                  name="title"
                                  value={editedData?.title}
                                  onChange={handleEditData}
                                  placeholder="Type title here"
                              />
                          </div>
                      </div>
                      {selectedClients?.length > 0 && (
                          <div className={Styles.ClientsChipContainer}>
                              {selectedClients.map((client, i) => (
                                  <div key={i} className={Styles.ClientsChip}>
                                      <span>{capitalizedClientName(client?.name)}</span>
                                      <Cancel
                                          className={Styles.CancelIcon}
                                          onClick={() => handleDeleteClient(client)}
                                      />
                                  </div>
                              ))}
                          </div>
                      )}
                      <div className={Styles.ItemBoxContent}>
                          <span>Content</span>
                          <textarea
                              type="text"
                              name="content"
                              value={editedData.content || ''}
                              onChange={handleEditData}
                          />
                      </div>
                      <div className={Styles.ContainerUploadImage}>
                          <div {...getRootProps()} className={Styles.WrapperUploadImage}>
                              <input
                                  {...getInputProps()}
                                  onChange={handleEditData}
                                  name="image"
                                  type="file"
                                  ref={inputFileRef}
                                  hidden
                              />

                              {preview ? (
                                  <div
                                      style={{
                                          width: '100%',
                                          margin: 0,
                                          padding: 0,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'start',
                                          gap: 30,
                                      }}>
                                      <img
                                          src={preview}
                                          style={{
                                              width: '245px',
                                              height: '158px',
                                              objectFit: 'cover',
                                              borderRadius: 8,
                                          }}
                                          alt={preview}
                                          onClick={() => inputFileRef.current.click()}
                                      />
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                              width: '100%',
                                          }}>
                                          <span
                                              style={{
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  gap: 20,
                                              }}>
                                              <span style={{ textAlign: 'start' }}>{previewName}</span>
                                              <Button
                                                  type="button"
                                                  style={{
                                                      border: '1px solid #1571DE',
                                                      color: '#1571DE',
                                                      width: 120,
                                                      height: 40,
                                                      padding: 10,
                                                      borderRadius: 8,
                                                  }}
                                                  onClick={() => inputFileRef.current.click()}>
                                                  Change File
                                              </Button>
                                          </span>
                                          <img
                                              src={DELETE_ICON}
                                              onClick={handleDeleteImage}
                                              alt="delete"
                                              style={{ width: '18px', height: '20px', cursor: 'pointer' }}
                                          />
                                      </div>
                                  </div>
                              ) : (
                                  <div className={Styles.BoxInputImage}>
                                      <span>Drag & Drop File Here</span>
                                      <div className={Styles.BoxInputFile}>
                                          <span>File must JPG or PNG</span>
                                      </div>
                                      <div className={Styles.BoxInputOr}>
                                          <span> OR </span>
                                      </div>
                                      <div className={Styles.ContainerInputImage}>{}</div>
                                      <div className={Styles.BoxInputButton}>
                                          <Button onClick={() => inputFileRef.current.click()}>
                                              <span>Browse File</span>
                                              <PublishIcon />
                                          </Button>
                                      </div>
                                  </div>
                              )}
                          </div>
                      </div>
                      <div className={Styles.ItemBoxSetupPushNotification}>
                          <div className={Styles.TitleSetupPushNotification}>
                              <span>Setup Push Notification</span>
                              <div className={Styles.SetupPushNotificationButtonWrapper}>
                                  {pushNotification?.length > 0 && (
                                      <IconButton
                                          aria-label="add-notif"
                                          color="primary"
                                          sx={{
                                              gap: '10px',
                                              cursor: 'pointer',
                                              fontSize: '14px',
                                              fontWeight: '500',
                                          }}
                                          disableRipple={true}
                                          onClick={() => setModalAddNotification(true)}>
                                          <AddCircle fontSize="small" />
                                          <span>Tambah Jadwal</span>
                                      </IconButton>
                                  )}
                                  <div className={Styles.BoxInputButton}>
                                      <Button
                                          onClick={() => {
                                              setModalPreviewNotif(true);
                                          }}>
                                          Lihat Preview
                                      </Button>
                                  </div>
                              </div>
                          </div>
                          {/* {pushNotification?.length > 0 ? (
                              <div className={Styles.FillPushNotification}>
                                  {pushNotification.map((item, index) => (
                                      <div className={Styles.BoxPushNotification} key={`pushnotif_${index}`}>
                                          <img
                                              src={NOTIFICATION_LOGO_IMG}
                                              alt="notif-logo-img"
                                              style={{ height: '32px', width: '32px' }}
                                          />
                                          <div className={Styles.ComponentNotification} style={{ width: '50%' }}>
                                              <span>Description</span>
                                              <p>{item?.description}</p>
                                          </div>
                                          <div className={Styles.ComponentNotification} style={{ width: '20%' }}>
                                              <span>Date</span>
                                              <p>
                                                  {item?.date
                                                      ? moment(new Date(item?.date)).format('DD MMMM YYYY')
                                                      : '-'}
                                              </p>
                                          </div>
                                          <div className={Styles.ComponentNotification} style={{ width: '10%' }}>
                                              <span>Time</span>
                                              <p>{item?.time ? showTime(item?.time) : '-'}</p>
                                          </div>
                                          <div className={Styles.ActionLayout}>
                                              <IconButton
                                                  aria-label="edit-notif"
                                                  color="primary"
                                                  sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                                                  onClick={() => {
                                                      setSelectedPushNotification(item);
                                                      setModalAddNotification(true);
                                                  }}>
                                                  <Edit />
                                              </IconButton>
                                              <IconButton
                                                  aria-label="delete-notif"
                                                  sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                                                  onClick={() => {
                                                      handleSubmitPushNotification(item, 'DELETE');
                                                  }}>
                                                  <img
                                                      src={DELETE_SVG}
                                                      alt="delete"
                                                      style={{ height: '20px', width: '20px' }}
                                                  />
                                              </IconButton>
                                          </div>
                                      </div>
                                  ))}
                                  <IconButton
                                      aria-label="add-notif"
                                      color="primary"
                                      sx={{
                                          gap: '10px',
                                          cursor: 'pointer',
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          alignSelf: 'flex-start',
                                      }}
                                      disableRipple={true}
                                      onClick={() => setModalAddNotification(true)}>
                                      <ControlPoint fontSize="small" />
                                      <span>Jadwal Notifikasi</span>
                                  </IconButton>
                              </div>
                          ) : (
                              <div className={Styles.EmptySetupPushNotification}>
                                  <img
                                      src={NOTIFICATION_IMG}
                                      alt="notif-img"
                                      style={{ height: '70px', width: '100px' }}
                                  />
                                  <br />
                                  <span>Belum ada jadwal notifikasi ditambahkan</span>
                                  <IconButton
                                      aria-label="add-notif"
                                      color="primary"
                                      sx={{ gap: '10px', cursor: 'pointer', fontSize: '14px', fontWeight: '500' }}
                                      disableRipple={true}
                                      onClick={() => setModalAddNotification(true)}>
                                      <ControlPoint fontSize="small" />
                                      <span>Jadwal Notifikasi</span>
                                  </IconButton>
                              </div>
                          )} */}
                          <CustomTable
                              bodyData={rowPushNotificationData}
                              tableHead={TABLE_HEAD}
                              page={page}
                              setPage={setPage}
                              rowsPerPage={rowsPerPage}
                              setRowsPerPage={setRowsPerPage}
                              totalPage={totalPage}
                              customEmpty={() => {
                                  return (
                                      <div className={Styles.CenterEmptySetupPushNotification}>
                                          <div className={Styles.EmptySetupPushNotification}>
                                              <img
                                                  src={NOTIFICATION_IMG}
                                                  alt="notif-img"
                                                  style={{ height: '70px', width: '100px' }}
                                              />
                                              <br />
                                              <span>Belum ada jadwal notifikasi ditambahkan</span>
                                              <IconButton
                                                  aria-label="add-notif"
                                                  color="primary"
                                                  sx={{
                                                      gap: '10px',
                                                      cursor: 'pointer',
                                                      fontSize: '14px',
                                                      fontWeight: '500',
                                                  }}
                                                  disableRipple={true}
                                                  onClick={() => setModalAddNotification(true)}>
                                                  <ControlPoint fontSize="small" />
                                                  <span>Push Notification</span>
                                              </IconButton>
                                          </div>
                                      </div>
                                  );
                              }}
                          />
                      </div>
                  </div>
                  <div className={Styles.ButtonHelper} style={{ paddingInline: 0 }}>
                      <Button
                          style={{
                              border: '1px solid #1571DE',
                              color: '#1571DE',
                              width: 100,
                              height: 40,
                              padding: 10,
                              borderRadius: 8,
                          }}
                          onClick={handleClickCancel}>
                          Cancel
                      </Button>
                      <Button
                          style={{
                              background: '#1571DE',
                              color: 'white',
                              width: 100,
                              height: 40,
                              padding: 10,
                              borderRadius: 8,
                          }}
                          type="submit"
                          // onClick={() => setModalSuccess(true)}
                      >
                          Publish
                      </Button>
                      <span style={{ cursor: 'pointer' }} onClick={saveToDraft}>
                          Save to Draft
                      </span>
                  </div>
              </form>
              {modalSuccess && (
                  <ModalPublishedInfo
                      setModalSuccess={setModalSuccess}
                      history={history}
                      title="Successfully Published"
                      text="you have send one information to the client"
                  />
              )}
              {modalDraft && (
                  <ModalPublishedInfo
                      setModalSuccess={setModalDraft}
                      history={history}
                      title="Saved to Draft"
                      text="you have saved one information to draft"
                  />
              )}
              {modalAddNotification && (
                  <ModalAddPushNotification
                      type={selectedPushNotification ? 'EDIT' : 'ADD'}
                      setModal={setModalAddNotification}
                      submit={(value) => handleSubmitPushNotification(value, selectedPushNotification ? 'EDIT' : 'ADD')}
                      value={selectedPushNotification}
                      onClose={() => {
                          setModalAddNotification(false);
                          setSelectedPushNotification(null);
                      }}
                  />
              )}
              {modalPreviewNotif && (
                  <ModalPreview
                      value={{ ...information, pushNotif: information?.notifications || [], banner: information?.image }}
                      setModal={setModalPreviewNotif}
                  />
              )}
          </div>
      </div>
  );
}
