import React, { useMemo } from "react";
import Styles from "./OvertimeForm.module.css";
import { Helmet } from "react-helmet";
import NRK_ICON from "../../../../assets/images/nrk-employee.png";
import CLOCK_ICON from "../../../../assets/images/clock-revision.png";
import CLOCK_REVISION_ICON from "../../../../assets/images/clock-edit-revision.png";
import calendar_icon from "../../../../assets/images/calendar-icon-request.png";
import clipboard_icon from "../../../../assets/images/clipboard-icon-request.png";
import align_left_icon from "../../../../assets/images/align-left-icon-request.png";
import person_icon from "../../../../assets/images/user-icon-request.png";
import { Button } from "@mui/material";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export default function OvertimeForm(props) {
  const {
    dataOvertime,
    setIsModalOpenSuccess,
    setIsModalOpenReject,
    dataOvertimeName,
  } = props;

  const definedData = useMemo(() => {
    return dataOvertime?.remark?.split("::");
  }, [dataOvertime]);


  const handleApprove = async () => {
    setIsModalOpenSuccess(true);
  }; 


  return (
    <div className={Styles.Container}>
      <Helmet>
        <title>MyWorkSpace | Overtime</title>
      </Helmet>
      <div className={Styles.Wrapper}>
        {dataOvertime?.overtime?.status  === "APPROVED" && (
          <div className={Styles.StatusController}>
            <div className={Styles.StatusText}>
              <span>Approved</span>
              <span>You have approved this request</span>
            </div>
            <CheckIcon sx={{ color: "#22970F" }} />
          </div>
        )}
        {dataOvertime?.overtime?.status  === "REJECTED" && (
          <div className={Styles.StatusControllerReject}>
            <div className={Styles.StatusTextReject}>
              <span>Rejected</span>
              <span>You have rejected this request</span>
            </div>
            <CloseIcon sx={{ color: "#D52701" }} />
          </div>
        )}
        <div className={Styles.Heading}>
          <span>Request Form</span>
          <p>You can approve or reject the request</p>
        </div>
        <div className={Styles.ContentItem}>
          {definedData?.[1] === "OVERTIME" && (
            <div className={Styles.InputGroup}>
              <img src={clipboard_icon} alt="" className={Styles.IconContent} />
              <div className={Styles.ColumnGroup}>
                <span>Requested</span>
                <p style={{ textTransform: "capitalize" }}>Overtime</p>
              </div>
            </div>
          )}
          <div className={Styles.InputGroup}>
            <img src={calendar_icon} alt="" className={Styles.IconContent} />
            <div className={Styles.ColumnGroup}>
              <span>Date request</span>
              <p>
                {moment(new Date(dataOvertime?.applyDate).getTime()).format(
                  "DD MMMM YYYY"
                )}
              </p>
            </div>
          </div>
          <div className={Styles.InputGroup}>
            <img src={person_icon} alt="" className={Styles.IconContent} />
            <div className={Styles.ColumnGroup}>
              <span>Name</span>
              <p>{dataOvertimeName?.name}</p>
            </div>
          </div>
          <div className={Styles.InputGroup}>
            <img src={NRK_ICON} alt="" className={Styles.IconContent} />
            <div className={Styles.ColumnGroup}>
              <span>NRK</span>
              <p>{dataOvertime?.employeeId}</p>
            </div>
          </div>
          <div className={Styles.InputGroup}>
            <img src={calendar_icon} alt="" className={Styles.IconContent} />
            <div className={Styles.ColumnGroup}>
              <span>Overtime Date</span>
              <p>{dataOvertime?.overtime ? moment(dataOvertime?.overtime?.startTime).format("DD/MM/YYYY") : "-"}</p>
              {/* <p>{definedData?.[2]}</p> */}
            </div>
          </div>
          <div className={Styles.InputGroup}>
            <img src={CLOCK_ICON} alt="" className={Styles.IconContent} />
            <div className={Styles.ColumnGroup}>
              <span>Start Time</span>
              <p>{dataOvertime?.overtime ? moment(dataOvertime?.overtime?.startTime).format("HH : mm") : "-"}</p>
              {/* <p>{definedData?.[3]}</p> */}
            </div>
          </div>
          {dataOvertime?.overtime?.description  && (
            <div className={Styles.InputGroup}>
              <img
                src={align_left_icon}
                alt=""
                className={Styles.IconContent}
              />
              <div className={Styles.ColumnGroup}>
                <span>Job description</span>
                <p>{ dataOvertime?.overtime?.description ? dataOvertime?.overtime?.description :  "-"}</p>
                {/* <p>{definedData?.[5]}</p> */}
              </div>
            </div>
          )}

          <div className={Styles.InputGroup}>
            <img
              src={CLOCK_REVISION_ICON}
              alt=""
              className={Styles.IconContent}
            />
            <div className={Styles.ColumnGroup}>
              <span>End Time</span>
              <p>{dataOvertime?.overtime?.endTime ? moment(dataOvertime?.overtime?.endTime).format("HH : mm") : "-"}</p>
              {/* <p>{definedData?.[4]}</p> */}
            </div>
          </div>
          {/* {definedData?.[6] === "undefined" ? null : (
            <div className={Styles.InputGroup}>
              <img
                src={align_left_icon}
                alt=""
                className={Styles.IconContent}
              />
              <div className={Styles.ColumnGroup}>
                <span>Description</span>
                <p>{definedData?.[6]}</p>
              </div>
            </div>
          )} */}
          {
            dataOvertime?.overtime?.remark && (
              <div className={Styles.InputGroup}>
              <img
                src={align_left_icon}
                alt=""
                className={Styles.IconContent}
              />
              <div className={Styles.ColumnGroup}>
                <span>Description</span>
                <p>{dataOvertime?.overtime?.remark ? dataOvertime?.overtime?.remark : "-"}</p>
                {/* <p>{definedData?.[6]}</p> */}
              </div>
            </div>
            )
          }
        </div>
        {dataOvertime?.overtime?.status  === "APPROVED" ||
        dataOvertime?.overtime?.status  === "REJECTED" ? null : (
        <div className={Styles.ButtonGroupEmployee}>
          <Button onClick={() => setIsModalOpenReject(true)}>Reject</Button>
          <Button onClick={handleApprove}>Approve</Button>
        </div>
        )}
      </div>
    </div>
  );
}
