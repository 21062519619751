import axios from "axios";
import {API} from "../../redux/api";

const setWorkOn = (token, dataToSend) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { response },
      } = await axios({
        method: "patch",
        headers: { Authorization: `${token}` },
        url: `${API}/contract/workOn`,
        data: dataToSend,
      });
      resolve(response);
      // console.log(response)
    } catch (error) {
      reject(error);
    }
  });

export default setWorkOn;
