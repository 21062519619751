import React from 'react'
import { Grid, Container} from "@mui/material";
import { Helmet } from 'react-helmet';
import { ApprovalLoanMenu } from '../../../components/_approval-rejected-page';

export default function ApprovalAttendance() {
  return (
    <Container maxWidth="xl">
    <Helmet>
      <title>MyWorkSpace | Approval</title>
    </Helmet>
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <ApprovalLoanMenu title="Request Approved" description="Your request have been approved"/>
      </Grid>
    </Grid>
  </Container>
  )
}
