/* eslint-disable no-unused-vars */
import moment from "moment";
import Styles from "./ActivityMenu.module.css";
import "./Button.css";
import React, { useEffect, useMemo, useState } from "react";
import DateRangePickerComponent from "../../../utils/DateRangePicker/DateRangePicker";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import PROFILE_PICTURE from "../../../../assets/images/picture-activity-profile.png";
import PICTURE_ACTIVITY_1 from "../../../../assets/images/picture-activity-1.png";
import PICTURE_ACTIVITY_2 from "../../../../assets/images/picture-activity-2.png";
import InfoIcon from "@mui/icons-material/Info";
// import { styled, IconButton } from "@mui/material";
import ActivityModal from "../ActivityModal/ActivityModal";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper";
import { Avatar } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllActivity,
  fetchAllActivityNoRangedate,
} from "../../../../redux/actions/SuperAdmin/activityAction";
import { datePickerValueManager } from "@mui/x-date-pickers/DatePicker/shared";

// const MuiAlbumBtn = styled(IconButton)({
//   backgroundColor: "#FFFFFF",
//   height: 36,
//   width: 36,
//   "&:hover": {
//     backgroundColor: "#FFFFFF80",
//   },
// });

const dummies = [
  {
    id: 1,
    profilePicture: PROFILE_PICTURE,
    name: "Blues Mond",
    jobTitle: "UI/UX Designer",
    activities: [
      PICTURE_ACTIVITY_1,
      PICTURE_ACTIVITY_2,
      PICTURE_ACTIVITY_1,
      PICTURE_ACTIVITY_2,
    ],
    remark:
      "Makan bersama client di resto terenak di jakarta selatan untuk nego2, cuan cuan cuan",
    posted: "5 hours ago",
    totalLikes: 2,
    date: "14 November 2022",
    time: "10:42",
    clientName: "PT Primer Fast Teknologi Utama",
    location:
      "Gedung Permata, Jl. Raya Kby. Lama No.10, RW.1, Grogol Sel., Kec. Kby. Lama, Kota Jakarta Selatan, Lampung 12230",
  },
  {
    id: 2,
    name: "John Robert",
    profilePicture: PROFILE_PICTURE,
    jobTitle: "Recruitment Officer",
    activities: [
      PICTURE_ACTIVITY_1,
      PICTURE_ACTIVITY_2,
      PICTURE_ACTIVITY_1,
      PICTURE_ACTIVITY_2,
    ],
    remark:
      "Makan bersama client di resto terenak di jakarta selatan untuk nego2, cuan cuan cuan",
    posted: "5 hours ago",
    totalLikes: 2,
    date: "14 November 2022",
    time: "10:42",
    clientName: "PT Primer Fast Teknologi Utama",
    location:
      "Gedung Permata, Jl. Raya Kby. Lama No.10, RW.1, Grogol Sel., Kec. Kby. Lama, Kota Jakarta Selatan, Lampung 12230",
  },
  {
    id: 3,
    name: "Dimitri",
    profilePicture: PROFILE_PICTURE,
    jobTitle: "Frotend Dev",
    activities: [
      PICTURE_ACTIVITY_1,
      PICTURE_ACTIVITY_2,
      PICTURE_ACTIVITY_1,
      PICTURE_ACTIVITY_2,
    ],
    remark:
      "Makan bersama client di resto terenak di jakarta selatan untuk nego2, cuan cuan cuan",
    posted: "5 hours ago",
    totalLikes: 2,
    date: "14 November 2022",
    time: "10:42",
    clientName: "PT Primer Fast Teknologi Utama",
    location:
      "Gedung Permata, Jl. Raya Kby. Lama No.10, RW.1, Grogol Sel., Kec. Kby. Lama, Kota Jakarta Selatan, Lampung 12230",
  },
];

export default function ActivityMenu(props) {
  // const { data, limit, setLimit } = props;
  const [listActivity, setListActivity] = useState("");
  const [valueStartDate, setValueStartDate] = useState(moment().format("L"));
  const [valueEndDate, setValueEndDate] = useState(moment().format("L"));
  const [current, setCurrent] = useState(0);
  const [length, setLength] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = localStorage.getItem("accessToken");
  const clientCode = localStorage.getItem("clientCode");
  const dispatch = useDispatch();
  const { activities } = useSelector((state) => state?.activity);
  const [isLimit, setIsLimit] = useState({
    limit: 5,
  });
  const [isTrigger, setIstrigger] = useState(false);

  const dateValueStart = useMemo(() => {
    let sessionStartDate = window.sessionStorage.getItem("startDate");
    let firstDate;

    if (sessionStartDate) {
      firstDate = sessionStartDate;
    } else {
      firstDate = valueStartDate;
    }

    return firstDate;
  }, [valueStartDate]);

  const dateValueEnd = useMemo(() => {
    let sessionEndDate = window.sessionStorage.getItem("endDate");
    let secondDate;

    if (sessionEndDate) {
      secondDate = sessionEndDate;
    } else {
      secondDate = valueEndDate;
    }

    return secondDate;
  }, [valueEndDate]);

  const codeQuery = `${
    window.sessionStorage.getItem("selectedClientCodeExternal") ||
    localStorage.getItem("clientCode")
  }`;

  // useEffect(() => {
  //   if(isTrigger) {
  //     dispatch(
  //       fetchAllActivity(
  //         token,
  //         codeQuery,
  //         isLimit?.limit,
  //         moment(new Date(dateValueStart).getTime()).format("YYYY-MM-DD"),
  //         moment(new Date(dateValueEnd).getTime()).format("YYYY-MM-DD")
  //       )
  //     );
  //   } else {
  //     dispatch(
  //       fetchAllActivityNoRangedate(
  //         token,
  //         codeQuery,
  //         isLimit?.limit,
  //       )
  //     );
  //   }
  // }, [token, dispatch, codeQuery, isLimit?.limit, dateValueEnd, dateValueStart, isTrigger]);

  useEffect(() => {
    dispatch(
      fetchAllActivity(
        token,
        codeQuery,
        isLimit?.limit,
        moment(new Date(dateValueStart).getTime()).format("YYYY-MM-DD"),
        moment(new Date(dateValueEnd).getTime()).format("YYYY-MM-DD")
      )
    );
  }, [
    token,
    dispatch,
    codeQuery,
    isLimit?.limit,
    dateValueEnd,
    dateValueStart,
  ]);

  const handleSearchInput = (event) => {
    setListActivity(event.target.value);
  };

  const handleChangeStartDate = (newValue) => {
    setValueStartDate(newValue);
    window.sessionStorage.setItem("startDate", newValue);
    // setIstrigger(true)
  };

  const handleChangeEndDate = (newValue) => {
    setValueEndDate(newValue);
    window.sessionStorage.setItem("endDate", newValue);
    // setIstrigger(true)
  };

  const nextSlide = () => {
    if (current < length - 1) {
      setCurrent((prevState) => prevState + 1);
    }
  };

  const prevSlide = () => {
    if (current > 0) {
      setCurrent((prevState) => prevState - 1);
    }
  };

  const fetchMoreData = async () => {
    setTimeout(() => {
      setIsLimit((prev) => ({
        ...prev,
        limit: prev.limit + 5,
      }));
    }, 1500);
  };

  if (activities === "END") {
    return (
      <div className={Styles.Container}>
        <div className={Styles.Header}>
          <SearchbarSA
            value={listActivity}
            onChange={handleSearchInput}
            searchWidth="300px"
            placeholder="Search by name"
          />
          <DateRangePickerComponent
            valueStartDate={dateValueStart}
            valueEndDate={dateValueEnd}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
          />
        </div>
        <div className={Styles.NoActivityText}>
          <span>There's no activity yet</span>
        </div>
      </div>
    );
  }

  console.log(activities, "<<<");

  return (
    <div className={Styles.Container}>
      <div className={Styles.Header}>
        <SearchbarSA
          value={listActivity}
          onChange={handleSearchInput}
          searchWidth="300px"
          placeholder="Search by name"
        />
        <DateRangePickerComponent
          valueStartDate={dateValueStart}
          valueEndDate={dateValueEnd}
          handleChangeStartDate={handleChangeStartDate}
          handleChangeEndDate={handleChangeEndDate}
        />
      </div>
      <InfiniteScroll
        dataLength={isLimit}
        next={fetchMoreData}
        hasMore={true}
        loader={activities === "END" ? null : <CircularProgress />}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {activities
          ?.filter((el) =>
            el.name.toLowerCase().includes(listActivity?.toLowerCase())
          )
          .map((each, unique) => (
            <div className={Styles.BoxSlider} key={unique}>
              <div className={Styles.Profile}>
                <Avatar
                  src={each?.profilePicture ? each.profilePicture : ""}
                  alt="avatar"
                  sx={{ width: 48, height: 48 }}
                />
                <div className={Styles.ProfileDesc}>
                  <span>{each?.name ? each?.name : null}</span>
                  <span>{each?.jobTitle ? each?.jobTitle : null}</span>
                </div>
              </div>

              <div className={Styles.SwipeableBox}>
                <Swiper
                  slidesPerView={2}
                  spaceBetween={16}
                  centeredSlides={false}
                  slidesPerGroupSkip={1}
                  grabCursor={true}
                  keyboard={{
                    enabled: true,
                  }}
                  breakpoints={{
                    640: {
                      width: 640,
                      slidesPerView: 1,
                    },
                    768: {
                      width: 768,
                      slidesPerView: 2,
                    },
                  }}
                  scrollbar={true}
                  navigation={true}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Keyboard, Scrollbar, Navigation, Pagination]}
                  style={{ marginLeft: 0 }}
                >
                  {each?.images?.map((subEach, subIdx) => (
                    <SwiperSlide key={subIdx}>
                      <img
                        src={subEach?.uri}
                        alt="slider-pict"
                        style={{ width: "100%", height: 300, borderRadius: 8 }}
                      />
                    </SwiperSlide>
                  ))}
                  {/* <SwiperSlide>
                    <img
                      src={PICTURE_ACTIVITY_1}
                      alt=""
                      style={{ width: "100%", height: 300 }}
                    />
                  </SwiperSlide> */}
                  {/* {each?.images?.map((subEach, subIdx) => (
                    <SwiperSlide key={subIdx} style={{ width: "100%" }}>
                      <img
                        src={subEach?.uri}
                        alt="slider-pict"
                        style={{ width: "100%", height: 300 }}
                      />
                    </SwiperSlide>
                  ))} */}
                  {/* {each.activities?.map((subEach, subIdx) => (
                  <img src={subEach} alt="slider-pict" key={subIdx} />
                ))} */}
                </Swiper>
                {/* <div className={Styles.Controller}>
                <MuiAlbumBtn onClick={prevSlide}>
                  <ChevronLeft sx={{ color: "#000", fontSize: 24 }} />
                </MuiAlbumBtn>
                <MuiAlbumBtn onClick={nextSlide}>
                  <ChevronRight sx={{ color: "#000", fontSize: 24 }} />
                </MuiAlbumBtn>
              </div>
              <div className={Styles.Album}>
                {each.activities?.map((subEach, subIdx) => (
                  <img src={subEach} alt="slider-pict" key={subIdx} />
                ))}
              </div> */}
              </div>

              <div className={Styles.Likes}>
                <div
                  className={Styles.LikesHeader}
                  onClick={() => setIsModalOpen(each)}
                >
                  <span>
                    {each?.likes?.length ? each?.likes?.length : null} Like
                  </span>
                  <InfoIcon
                    sx={{
                      color: "#323232",
                      width: 24,
                      height: 24,
                      cursor: "pointer",
                    }}
                  />
                </div>

                <div className={Styles.LikesDesc}>
                  <span>{each?.remark ? each.remark : null}</span>
                  <span>
                    {each?.createdAt
                      ? moment(new Date(each?.createdAt).getTime()).fromNow(
                          true
                        )
                      : null}{" "}
                    ago{" "}
                  </span>
                </div>
              </div>
            </div>
          ))}
      </InfiniteScroll>

      {isModalOpen?._id && (
        <ActivityModal
          isActive={Boolean(isModalOpen)}
          onClose={setIsModalOpen}
          data={isModalOpen}
        />
      )}
    </div>
  );
}
