// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { useHistory } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { useLocation, useParams } from "react-router";
import { PayslipPersonDetailSA } from "../../../../components/SuperAdmin/_payslip";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchDataPayslipById } from "../../../../redux/actions/SuperAdmin/slipPaymentActions";

export default function PayslipPersonDetail() {
  // const token = localStorage.getItem("accessToken");
  const history = useHistory();
  // const dispatch = useDispatch();
  // const params = useParams();
  // const dataPayslip = useSelector((state) => state.payslip.dataPayslipById);

  // useEffect(() => {
  //   dispatch(fetchDataPayslipById(token, id));
  // }, []);

  const location = useLocation();
  const dataPayslip = location?.state;


  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Payslip</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/payslip")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Payslip</span>
            </Button>
            <Button
              onClick={() => history.go(-1)}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>
                {dataPayslip?.name ? dataPayslip?.name : ""}
              </span>
            </Button> 
            <span style={{ fontSize: 20 }}>Detail Pay Slip</span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <PayslipPersonDetailSA  data={dataPayslip}/>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
