import { Avatar } from "@mui/material";
import React from "react";
import "./BestSPG.css";

const data = [
  { name: "Anastasia", sales: 2000, image: "" },
  { name: "Sheila", sales: 2000, image: "" },
  { name: "Marcella", sales: 2000, image: "" },
];

const BestSPG = () => {
  return (
    <div className="ContainerBestSPG">
      <div className="WrapperBestSPG">
        <div className="HeaderBestSPG">
          <span>Best SPG</span>
        </div>
        <div className="ContentBestSPG">
          {data.map((el, idx) => (
            <div className="WrapperContentBestSPG" key={idx}>
              <div className="ImageBestSPG">
                <Avatar src={el.image} sx={{ width: 48, height: 48 }} />
              </div>
              <div className="ContentTextBestSPG">
                <span>{el.name}</span>
                <span>{el.sales} Product Sales</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BestSPG;
