/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./MapAutoCompleteFMCG.css";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  // InfoWindow,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
// import { formatRelative } from "date-fns";
import "@reach/combobox/styles.css";
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
// import SearchbarFMCG from "../../FMCG/SearchbarFMCG/SearchbarFMCG";
import {
  Autocomplete,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useHistory } from "react-router";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "694px",
};

const center = {
  lat: 0,
  lng: 0,
};

const getClientName = [
  "Permata",
  "Aeon",
  "All Fresh",
  "Diamond",
  "Farmer Market",
  "Foodhall",
  "Grand Lucky",
  "Hero",
  "Hypermart",
  "Jakarta Fruit Market",
];

export default function MapWithAutoCompleteFMCG({
  setCoordinate,
  coordinate,
  setShowSearch,
  showSearch,
  setAddress,
  location,
}) {
  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB0Gy-BuZ0XyKlvhNtuhRPJh4RGdfQOQgE",
    libraries,
  });
  const history = useHistory();
  const [markers, setMarkers] = useState(null);
  const [selected, setSelected] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedStoreGroup, setSelectedStoreGroup] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    if (selected && selectedStoreGroup) {
      setDisabledButton(false);
    }

    if(location === "editStoreSpg" || location === "editStoreMD") {
      setSelectedStoreGroup("Aeon")
      setDisabledButton(false);
    }

  }, [selected, selectedStoreGroup, location]);

  const onMapClick = useCallback((event) => {
    setMarkers({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
      time: new Date(),
    });
    setCoordinate((prev) => ({
      ...prev,
      Latitude: event.latLng.lat(),
      Longitude: event.latLng.lng(),
    }));
    setAddress((prev) => ({
      ...prev,
      Address: selectedAddress,
    }));
    // setValues((prev) => ({
    //   ...prev,
    //   address: selectedAddress?.formatted_address
    // }))
    // setCoordinateAdd({
    //   lat: event.latLng.lat(),
    //   lng: event.latLng.lng()
    // })
    // setMarkers((current) => [
    //   ...current,
    //   {
    //     lat: event.latLng.lat(),
    //     lng: event.latLng.lng(),
    //     time: new Date(),
    //   },
    // ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapRef = useRef();
  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    // zooming the map
    mapRef.current.setZoom(14);
    setMarkers({
      lat,
      lng,
      time: new Date(),
    });
  }, []);

  const onMapLoad = useCallback(
    (map) => {
      navigator.geolocation.getCurrentPosition(
        (position) =>
          panTo({
            lat: +position.coords.latitude,
            lng: +position.coords.longitude,
          }),
        () => null
      );
      mapRef.current = map;
    },
    [panTo]
  );

  const handleSelect = async (address) => {
    setSelected(address);
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
      setMarkers({ lat, lng });
    } catch (error) {
      setMarkers(null);
    }
  };

  const handleSave = () => {
    const data = {
      Address: selected,
      Latitude: +markers?.lat,
      Longitude: +markers?.lng,
      StoreGroup: selectedStoreGroup,
    };
    console.log(data, "test");
    history.push(`/admin-fmcg/setup/store`);
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading maps";
  return (
    <div className="WrapperMapFMCG">
      <div className="WrapperSearchboxMap">
        {showSearch && (
          <SearchbarMui
            panTo={panTo}
            setCoordinate={setCoordinate}
            setAddress={setAddress}
            setSelectedAddress={setSelectedAddress}
            handleSelect={handleSelect}
            location={location}
            coordinate={coordinate}
          />
        )}
        {location === "addStoreSPG" || location === "addStoreMD" ? (
          <div className="ContentMapFMCG">
            {selected ? (
              <>
                <div className="WrapperAdressMapFMCG">
                  <span>Address</span>
                  <span>{selected}</span>
                </div>
                <div className="WrapperLongLatFMCG">
                  <div className="ContainerLongLat">
                    <span>Latitude</span>
                    <span>{markers?.lat}</span>
                  </div>
                  <div className="ContainerLongLat">
                    <span>Longitude</span>
                    <span>{markers?.lng}</span>
                  </div>
                </div>
                <div className="ContainerSelectGroup">
                  <span>Store Group</span>
                  <FormControl fullWidth size="small" style={{ width: "100%" }}>
                    <Select
                      value={selectedStoreGroup || ""}
                      displayEmpty
                      onChange={(e) => setSelectedStoreGroup(e.target.value)}
                      required
                    >
                      {getClientName.sort().map((option, el) => (
                        <MenuItem
                          key={el}
                          onClick={() => setSelectedStoreGroup(option)}
                          value={option || ""}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="ButtonSaveAddNewStore">
                  <Button
                    style={{
                      width: 310,
                      height: 34,
                      borderRadius: 5,
                      backgroundColor:
                        (disabledButton === true && "#B2C5F4") ||
                        (disabledButton === false && "#1571DE"),
                      color: "#FFFFFF",
                      fontSize: 13,
                      fontWeight: 500,
                      textTransform: "none",
                    }}
                    disabled={disabledButton}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </div>
              </>
            ) : (
              <div className="TextPinLocation">
                <span>Please pin location</span>
                <span>
                  The address will be automatically filled after you pin the
                  location
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className="ContentMapFMCG">
            <>
              <div className="WrapperAdressMapFMCG">
                <span>Address</span>
                <span>Edit page</span>
              </div>
              <div className="WrapperLongLatFMCG">
                <div className="ContainerLongLat">
                  <span>Latitude</span>
                  <span>{markers?.lat}</span>
                </div>
                <div className="ContainerLongLat">
                  <span>Longitude</span>
                  <span>{markers?.lng}</span>
                </div>
              </div>
              <div className="ContainerSelectGroup">
                <span>Store Group</span>
                <FormControl fullWidth size="small" style={{ width: "100%" }}>
                  <Select
                    value={selectedStoreGroup || ""}
                    displayEmpty
                    onChange={(e) => setSelectedStoreGroup(e.target.value)}
                    required
                  >
                    {getClientName.sort().map((option, el) => (
                      <MenuItem
                        key={el}
                        onClick={() => setSelectedStoreGroup(option)}
                        value={option || ""}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="ButtonSaveAddNewStore">
                <Button
                  style={{
                    width: 310,
                    height: 34,
                    borderRadius: 5,
                    backgroundColor:
                      (disabledButton === true && "#B2C5F4") ||
                      (disabledButton === false && "#1571DE"),
                    color: "#FFFFFF",
                    fontSize: 13,
                    fontWeight: 500,
                    textTransform: "none",
                  }}
                  disabled={disabledButton}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </>
          </div>
        )}
      </div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        center={center}
        onClick={onMapClick}
        onLoad={onMapLoad}
        // mapTypeControl={false}
        // streetViewControl={false}
      >
        {/* <Marker position={center}/> */}
        <Marker
          key={markers?.time}
          position={{ lat: +markers?.lat, lng: +markers?.lng }}
          // icon={{ url: 'svgfile.svg', scaledSize: new window.google.maps.size(30,30), origin: new window.google.maps.Point(0,0) , anchor: new window.google.maps.Point(15,15)}}
          // position={ center }
          onClick={() => {
            setSelected(markers);
            // setSelected(address);
          }}
        />
        {/* {markers.map((marker) => (
          <Marker
            key={marker.time.toISOString()}
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={() => {
              setSelected(marker);
            }}
          />
        ))} */}

        {/* {selected ? (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.lng }}
            onCloseClick={() => setSelected(null)}
          >
            <div>
              <h2>Test</h2>
              <p>Spotted {formatRelative(selected.time, new Date())}</p>
            </div>
          </InfoWindow>
        ) : null} */}
      </GoogleMap>
    </div>
  );
}

function SearchbarMui({
  panTo,
  setCoordinate,
  setValues,
  setAddress,
  setSelectedAddress,
  handleSelect,
  location,
  coordinate,
}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      // location: { lat: () => -6.2, lng: () => 106.816666 },
      // radius: 200 * 1000,
    },
  });

  const handleChangeInput = (value) => {
    if (value === "") {
      setValue("");
    } else {
      setValue(value);
    }
  };

  useEffect(() => {
    if (location === "editStoreSpg") {
      setValue("Mayestik");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="">
      <Autocomplete
        onChange={(event, newValue) => {
          newValue === null ? handleSelect("") : handleSelect(newValue);
        }}
        value={value}
        inputValue={value}
        onInputChange={(e, value) => handleChangeInput(value)}
        options={data.map(({ description }) => description)}
        // loading={status !== "OK"}
        noOptionsText="No Location Found"
        renderInput={(params) => (
          <TextField {...params} placeholder="Search place" size="medium" />
        )}
      />
    </div>
  );
}

function Search({
  panTo,
  setCoordinate,
  setValues,
  setAddress,
  setSelectedAddress,
}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      // location: { lat: () => -6.2, lng: () => 106.816666 },
      // radius: 200 * 1000,
    },
  });

  return (
    <div className="search__combobox">
      <Combobox
        onSelect={async (address) => {
          // set the value when selected
          setValue(address, false);
          clearSuggestions();
          try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            panTo({ lat, lng });
            setCoordinate((prev) => ({
              ...prev,
              Latitude: lat,
              Longitude: lng,
            }));
            // setValues((prev) => ({
            //   ...prev,
            //   address: results[0]
            // }));
            setAddress((prev) => ({
              ...prev,
              Address: results[0].formatted_address,
            }));
            setSelectedAddress(results[0].formatted_address);
            // setCoordinateAdd({ lat, lng })
            // setCoordinateAdd((prev) => ({ ...prev , lat, lng}));
            // take the first result from search
            // console.log(lat,lng);
          } catch (error) {
            console.log(error);
          }
        }}
      >
        <ComboboxInput
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          disabled={!ready}
          placeholder="Enter Address"
        />
        <ComboboxPopover>
          {status === "OK" &&
            React.Children.toArray(
              data.map(({ description }) => (
                <ComboboxOption value={description} />
              ))
            )}
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}
