import React, { useEffect } from "react";
import { Button, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import { SettingLoanView } from "../../../components/SuperAdmin/_loan/";
import { useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { fetchSchemaLoanById } from "../../../redux/actions/SuperAdmin/loanActions";
import Breadcrumb from "../../../components/utils/Breadcrumbs";


// eslint-disable-next-line no-unused-vars
const data = [
  {
    id: 1,
    name: "Scheme 1",
    remark: "Scheme for a tenor 1 and 3 Month",
    loanRules: [
      {
        id: 1,
        maxPlafond: 1,
        minPlafond: 1,
        rate: 1,
        dbr: 1,
        adminFee: {
          baseLine: 1,
          additional: {
            multiplyFactor: 1,
            factorCost: 1,
            percentage: 1,
          },
        },
        minLengthOfJobInMonth: 1,
        minContractRemainInMonth: 1,
      },
      {
        id: 2,
        maxPlafond: 1,
        minPlafond: 1,
        rate: 1,
        dbr: 1,
        adminFee: {
          baseLine: 1,
          additional: {
            multiplyFactor: 1,
            factorCost: 1,
            percentage: 1,
          },
        },
        minLengthOfJobInMonth: 1,
        minContractRemainInMonth: 1,
      },
    ],
    activeLoanDate: {
      daysBeforePayroll: 5,
      daysAfterPayroll: 5,
    },
    minAge: 18,
    maxAge: 56,
    permittedContractType: ["PKWT"],
  },
];

export default function LoanSettingsView() {
  const { id } = useParams();
  const location = useLocation();
  const loanId = location.state?.dataLoan;
  const dispatch = useDispatch();
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const { loanSchemaById } = useSelector((state) => state.loan);
    
  useEffect(() => {
    dispatch(fetchSchemaLoanById(token, id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Loan Settings</title>
        </Helmet>
        <div className="top__items-settings">
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/loan")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Loan</span>
            </Button>
            <span style={{ fontSize: 20, color: "#255bfc" }}>
              { loanId?.name }
            </span>
          </Breadcrumb>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <SettingLoanView data={loanSchemaById} id={ id }/>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
