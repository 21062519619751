import { useEffect, useMemo, useState } from "react";
import Styles from "./UserDetail.module.css";
import EDIT_ICON from "../../../../assets/images/create.png";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import fetchClientCode from "../../../../async/client/fetchClientCode";
import { Autocomplete, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { updateUserAdmin } from "../../../../redux/actions/SuperAdmin/userAdmin";
import { useParams } from "react-router-dom";
import DeleteUserModal from "../TableUsers/UserTable/DeleteUserModal/DeleteUserModal";
import fetchPKSbyClient from "../../../../async/client/getPKSbyClient";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      border: "1px solid #e0e0e0",
    },
    "&$disabled": {
      color: "red",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1.5px solid #d3c9c9",
    borderColor: "#d3c9c9",
    borderRadius: 8,
    //   width: "100%",
  },
});

export default function UserSettingDetails({ userData }) {
  const [editMode, setEditMode] = useState(false);
  const token = localStorage.getItem("accessToken");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [deleteUser, setDeleteUser] = useState(false);

  const [dataClientCodes, setDataClientCodes] = useState([]);

  const [selectedClients, setSelectedClients] = useState([]);

  const [form, setForm] = useState({
    client: "",
    password: "",
  });

  const [pksList, setPksList] = useState([]);
  const [pksNumber, setPksNumber] = useState([]);

  // console.log(userData, 'data')

  useEffect(() => {
    setForm({
      client: userData?.clientCodes,
      password: userData?.password,
    });
  }, [userData]);

  const preClients = useMemo(() => {
    const temp = dataClientCodes.filter((el) => {
      return userData?.clientCodes?.some((f) => {
        return f.code === el.codes;
      });
    });
    return temp;
  }, [dataClientCodes, userData]);

  const preClientCodes = useMemo(() => {
    return preClients.map((el) => el?.codes);
  }, [preClients]);

  const selectedClientCodes = useMemo(() => {
    return selectedClients.map((el) => el?.codes);
  }, [selectedClients]);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCodes(response);
      } catch (error) {
        console.log();
      }
    })();
  }, [token]);

  useEffect(() => {
    setSelectedClients(preClients);
  }, [preClients]);

  const handleChange = (e) => {
    e.preventDefault();
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const addedClientCode = useMemo(() => {
    return selectedClientCodes.filter(
      (item) => preClientCodes.indexOf(item) === -1
    );
  }, [selectedClientCodes, preClientCodes]);

  const removedClientCode = useMemo(() => {
    return preClientCodes.filter(
      (item) => selectedClientCodes.indexOf(item) === -1
    );
  }, [preClientCodes, selectedClientCodes]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let body;
    if (userData.type === "MWS") {
      if (form?.password === userData?.password) {
        body = {
          addClientCode: addedClientCode,
          removeClientCode: removedClientCode,
          type: userData.type,
        };
      } else {
        body = {
          password: form?.password,
          addClientCode: addedClientCode,
          removeClientCode: removedClientCode,
          type: userData.type,
        };
      }
    } else {
      if (form?.password === userData?.password) {
        body = {
          type: userData.type,
          clients: pksNumber,
        };
      } else {
        body = {
          type: userData.type,
          clients: pksNumber,
        };
      }
    }

    dispatch(updateUserAdmin(token, userData?.userId, body, id));
    setEditMode(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setForm({ client: userData?.client, password: userData?.password });
    setSelectedClients(preClients);
    setEditMode(false);
  };

  useEffect(() => {
    const handleGetPks = async () => {
      const { response } = await fetchPKSbyClient(
        selectedClients.map((el) => el.codes)
      );
      if (pksNumber.length === 0) {
        setPksNumber(
          response.map((el) => ({
            ...el,
            pks_number: userData?.pks_number.find(
              (each) => each.code === el.code
            )?.contractNumbers,
          }))
        );
      } else {
        if (pksList.length > response.length) {
          // remove one
          let removed;
          for (let i = 0; i < pksNumber.length; i++) {
            const where = response
              .map((el, idx) => el.code)
              .indexOf(pksNumber[i].code);
            if (where < 0) {
              removed = pksNumber[i];
            }
          }
          setPksNumber((prev) => prev.filter((el) => el.code !== removed.code));
        } else {
          let add;
          for (let i = 0; i < response.length; i++) {
            const where = pksNumber
              .map((el, idx) => el.code)
              .indexOf(response[i].code);
            if (where < 0) {
              add = response[i];
            }
          }
          if (add) {
            setPksNumber((prev) => [...prev, { ...add, pks_number: [] }]);
          }
        }
      }
      setPksList(response);
      //   console.log(data.response);
    };
    if (userData?.type !== "MWS" && selectedClients.length > 0) {
      handleGetPks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClients, userData]);

  const capitalizedClientName = (clientName) => {
    return clientName
      ?.toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .replace("Pt", "PT")
      .replace("Cv", "CV");
  };

  const handleFindPks = (code) => {
    return pksList.find((el) => el.code === code)?.pks_number || [];
  };

  const handleSelectPksNumber = (code, number) => {
    setPksNumber((prev) =>
      prev.map((el, idx) =>
        el.code === code ? { ...el, pks_number: number } : el
      )
    );
  };

  return (
    <div className={Styles.Container}>
      <div className={Styles.Header}>
        <span>User Settings</span>
        {!editMode && (
          <div className={Styles.HeaderButtonWrapper}>
            <button
              onClick={() => setDeleteUser(true)}
              className={Styles.DeleteButton}
            >
              <img src={DELETE_ICON} alt="delete" />
              <span>Delete</span>
            </button>
            <button
              onClick={(e) => setEditMode(true)}
              className={Styles.EditButton}
            >
              <img src={EDIT_ICON} alt="edit" />
              <span>Edit</span>
            </button>
          </div>
        )}
      </div>
      <div className={Styles.FormContainer}>
        <form className={Styles.Form}>
          <div className={Styles.BoxForm}>
            <label>Admin Type</label>
            <input
              type="text"
              name="type"
              value={userData?.type === "MWS" ? "Internal" : "External" || ""}
              disabled
            />
          </div>
          <div className={Styles.BoxForm}>
            <label>Employee Name</label>
            <input
              type="text"
              name="name"
              style={{ textTransform: "capitalize" }}
              value={userData?.name?.toLowerCase() || ""}
              disabled
            />
          </div>
          <div className={Styles.BoxForm}>
            <label>User ID</label>
            <input
              type="text"
              name="userId"
              value={userData?.userId || ""}
              disabled
            />
          </div>
          <div className={Styles.BoxForm}>
            <label>Position</label>
            <input
              type="text"
              name="jobTitle"
              value={userData?.position || ""}
              disabled
            />
          </div>
          <div className={Styles.BoxFormMultiple}>
            <label>Client</label>
            <Autocomplete
              multiple
              disabled={!editMode}
              readOnly={!editMode}
              value={selectedClients || []}
              options={dataClientCodes}
              className={
                !editMode ? Styles.AutocompleteDisabled : Styles.Autocomplete
              }
              style={{ width: "50%" }}
              onChange={(event, newValue) => setSelectedClients(newValue)}
              getOptionLabel={(option) => capitalizedClientName(option?.name)}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  size="small"
                  variant="outlined"
                  placeholder={
                    selectedClients?.length === 0 ? "Select client" : ""
                  }
                />
              )}
            />
          </div>
          {pksNumber.map((each, idx) => (
            <div className={Styles.BoxFormMultiple} key={idx}>
              <label>PKS : {each.name}</label>
              <Autocomplete
                multiple
                disabled={!editMode}
                readOnly={!editMode}
                value={each.pks_number || []}
                options={handleFindPks(each.code)}
                className={
                  !editMode ? Styles.AutocompleteDisabled : Styles.Autocomplete
                }
                style={{ width: "50%" }}
                onChange={(event, newValue) =>
                  handleSelectPksNumber(each.code, newValue)
                }
                // getOptionLabel={(option) => option?.pks_number}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    size="small"
                    variant="outlined"
                    placeholder={
                      selectedClients?.length === 0 ? "Select client" : ""
                    }
                  />
                )}
              />
            </div>
          ))}
          {/* <div className={Styles.BoxForm}>
                        <label>Account Password</label>
                        <input type="text" name="password" value={form?.password || ""} onChange={handleChange} disabled={!editMode} />
                    </div> */}
          {editMode && (
            <div className={Styles.ButtonWrapper}>
              <button
                type="button"
                onClick={handleCancel}
                className={Styles.CancelButton}
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                className={Styles.SaveButton}
              >
                Save
              </button>
            </div>
          )}
        </form>
      </div>
      {deleteUser && (
        <DeleteUserModal
          active={userData}
          onOpen={setDeleteUser}
          description="Are you sure you want to delete this user?"
          label="Delete User"
          pathName="/superadmin/user"
        />
      )}
    </div>
  );
}
