/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { formatRelative } from "date-fns";
import "@reach/combobox/styles.css";
import "./index.css";
import { Autocomplete, autocompleteClasses, InputAdornment, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";
import SEARCH_ICON from "../../../assets/images/search-dark-grey.png";
const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "320px",
  borderRadius: '8px',
  border: '1px solid #d9d9d9'
};



const MuiTextField = styled(TextField)({
  backgroundColor: "#fbfbfb",
  borderRadius: 8,
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});



const center = {
  lat: 0,
  lng: 0,
};

const options = {
  // for changing the styles of the map
  // css import
  // disableDefaultUI : true
  // zoomControl: true
};

export default function MapWithAutoComplete({
  setCoordinate,
  coordinate,
  setShowSearch,
  showSearch,
  setAddress,
}) {
  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB0Gy-BuZ0XyKlvhNtuhRPJh4RGdfQOQgE",
    libraries,
  });
  const [markers, setMarkers] = useState(null);
  const [selected, setSelected] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  // console.log(selectedAddress, 'selectedAddresssss')
  const onMapClick = useCallback((event) => {
    setMarkers({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
      time: new Date(),
    });
    setCoordinate((prev) => ({
      ...prev,
      Latitude: event.latLng.lat(),
      Longitude: event.latLng.lng(),
    }));
    setAddress((prev) => ({
      ...prev,
      Address: selectedAddress,
    }));
    // setValues((prev) => ({
    //   ...prev,
    //   address: selectedAddress?.formatted_address
    // }))
    // setCoordinateAdd({
    //   lat: event.latLng.lat(),
    //   lng: event.latLng.lng()
    // })
    // setMarkers((current) => [
    //   ...current,
    //   {
    //     lat: event.latLng.lat(),
    //     lng: event.latLng.lng(),
    //     time: new Date(),
    //   },
    // ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapRef = useRef();

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current?.panTo({ lat, lng });
    mapRef.current?.setZoom(14);
    // zooming the map
    setMarkers({
      lat,
      lng,
      time: new Date(),
    });
  }, []);

  const onMapLoad = useCallback(
    (map) => {
      if (coordinate?.Latitude) {
        // panTo({ lat: +coordinate?.Latitude, lng: +coordinate?.Longitude })
        navigator.geolocation.getCurrentPosition(
          (position) =>
            panTo({
              lat: +coordinate?.Latitude,
              lng: +coordinate?.Longitude
            }),
          () => null
        );
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) =>
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }),
          () => null
        );
      }
      // navigator.geolocation.getCurrentPosition(
      //   (position) =>
      //   panTo({ lat: +coordinate?.Latitude, lng: +coordinate?.Longitude }),
      //   () => null
      // );
      mapRef.current = map;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [panTo]
  );


  const handleSelect = async (address) => {
    setSelected(address);
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
      setMarkers({ lat, lng });
      setCoordinate((prev) => ({
        ...prev,
        Latitude: lat,
        Longitude: lng,
      }));
      setAddress((prev) => ({
        ...prev,
        Address: selectedAddress,
      }));
    } catch (error) {
      setMarkers(null);
    }
  };


  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading maps";
  return (
    <div>
      {showSearch && (
        <SearchbarMui
          panTo={panTo}
          setCoordinate={setCoordinate}
          setAddress={setAddress}
          setSelectedAddress={setSelectedAddress}
          handleSelect={handleSelect}
          coordinate={coordinate}
        />
      )}
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        center={center}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {/* <Marker position={center}/> */}
        <Marker
          key={markers?.time}
          position={{ lat: +markers?.lat, lng: +markers?.lng }}
          onClick={() => {
            setSelected(markers);
          }}
        />
      </GoogleMap>
    </div>
  );
}

function Locate({ panTo }) {
  return (
    <button>
      <img src="" />
    </button>
  );
}

function Search({
  panTo,
  setCoordinate,
  setValues,
  setAddress,
  setSelectedAddress,
  coordinate,
  setMarkers,
  showSearch,
}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      // location: { lat: () => -6.2, lng: () => 106.816666 },
      // radius: 200 * 1000,
    },
  });

  useEffect(() => {
    setValue(coordinate.Address);
    setSelectedAddress(coordinate.Address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="search__combobox" style={{ zIndex: "2" }}>
      <Combobox
        onSelect={async (address) => {
          // set the value when selected
          setValue(address, false);
          clearSuggestions();
          try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            panTo({ lat, lng });

            setCoordinate((prev) => ({
              ...prev,
              Latitude: lat,
              Longitude: lng,
            }));
            setAddress((prev) => ({
              ...prev,
              Address: results[0].formatted_address,
            }));
            setSelectedAddress(results[0].formatted_address);
          } catch (error) {
            console.log(error);
          }
        }}
      >
        <ComboboxInput
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          disabled={!ready}
          placeholder="Enter Address"
        />
        <ComboboxPopover>
          {status === "OK" &&
            React.Children.toArray(
              data.map(({ description }) => (
                <ComboboxOption value={description} />
              ))
            )}
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

function SearchbarMui({
  panTo,
  setCoordinate,
  setValues,
  setAddress,
  setSelectedAddress,
  handleSelect,
  location,
  coordinate,
}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      // location: { lat: () => -6.2, lng: () => 106.816666 },
      // radius: 200 * 1000,
    },
  });

  const handleChangeInput = (value) => {
    if (value === "") {
      setValue("");
    } else {
      setValue(value);
    }
  };

  useEffect(() => {
    if (location === "editStoreSpg") {
      setValue("Mayestik");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="search-mui">
      <Autocomplete
        value={value}
        inputValue={value}
        disableClearable
        forcePopupIcon={false}
        blurOnSelect={true}
        onChange={(event, newValue) => {
          newValue === null ? handleSelect("") : handleSelect(newValue);
        }}
        onInputChange={(e, value) => handleChangeInput(value)}
        options={data.map(({ description }) => description)}
        noOptionsText="No Location Found"
        renderInput={(params) => (
          <MuiTextField
            {...params}
            placeholder="Search place"
            size="small"
            sx={{ width: '379px'}}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <img src={SEARCH_ICON} alt="search" style={{ width: '30px', height: '30px' }} />
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </div>
  );
}