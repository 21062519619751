import React, { useMemo, useEffect, useState } from "react";
import "./CalendarFMCG.css";
import CHECK_CIRCLE_IMAGE from "../../../../assets/images/check_circle.png";
// import SICK_IMAGE from "../../../../assets/images/person-sick.png";
// import CROSS_CIRCLE_IMAGE from "../../../../assets/images/crossed-red.png";
// import LEAVE_IMAGE from "../../../../assets/images/Leave.png";
import { useHistory } from "react-router-dom";

const dateDummy = [
  ["", "", 3, 4, 5, 6, 7],
  [7, 8, 9, 10, 11, "", ""],
];

export default function CalendarFMCG(props) {
  const {
    language = "eng",
    startDate,
    endDate,
    yearValue,
    monthValue,
    // attendanceSummary,
  } = props;
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const history = useHistory();

  const months = useMemo(() => {
    switch (language) {
      case "eng":
        return {
          short: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          long: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          totalDays: [
            31,
            +year % 4 === 0 ? 29 : 28,
            31,
            30,
            31,
            30,
            31,
            31,
            30,
            31,
            30,
            31,
          ],
        };
      default:
        return {
          short: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Ags",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
          long: [
            "Januari",
            "Februari",
            "Maret",
            "April",
            "Mei",
            "Juni",
            "Juli",
            "Agustus",
            "September",
            "Oktober",
            "November",
            "Desember",
          ],
          totalDays: [
            31,
            +year % 4 === 0 ? 29 : 28,
            31,
            30,
            31,
            30,
            31,
            31,
            30,
            31,
            30,
            31,
          ],
        };
    }
  }, [year, language]);

  const days = useMemo(() => {
    switch (language) {
      case "eng":
        return {
          short: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          long: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ],
        };
      default:
        return {
          short: [
            "Minggu",
            "Senin",
            "Selasa",
            "Rabu",
            "Kamis",
            "Jumat",
            "Sabtu",
          ],
          long: [
            "Minggu",
            "Senin",
            "Selasa",
            "Rabu",
            "Kamis",
            "Jumat",
            "Sabtu",
          ],
        };
    }
  }, [language]);

  const manualMonths = (year) => {
    switch (language) {
      case "eng":
        return {
          short: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          long: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          totalDays: [
            31,
            +year % 4 === 0 ? 29 : 28,
            31,
            30,
            31,
            30,
            31,
            31,
            30,
            31,
            30,
            31,
          ],
        };
      default:
        return {
          short: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Ags",
            "Sep",
            "Okt",
            "Nop",
            "Des",
          ],
          long: [
            "Januari",
            "Februari",
            "Maret",
            "April",
            "Mei",
            "Juni",
            "Juli",
            "Agustus",
            "September",
            "Oktober",
            "Nopember",
            "Desember",
          ],
          totalDays: [
            31,
            +year % 4 === 0 ? 29 : 28,
            31,
            30,
            31,
            30,
            31,
            31,
            30,
            31,
            30,
            31,
          ],
        };
    }
  };

  const gatheredDays = useMemo(() => {
    // console.log(startDate, endDate, 'cek <<<')
    if (startDate && endDate) {
      const startMonth = startDate.getMonth();
      const endMonth = endDate.getMonth();
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      if (startYear === endYear) {
        if (startMonth === endMonth) {
          return {
            dates: Array.from(
              new Array(endDate.getDate() - startDate.getDate() + 1),
              (val, index) => startDate.getDate() + index
            ),
            months: startDate.getMonth(),
            years: startYear,
          };
        } else {
          const totalMonths = endDate.getMonth() - startDate.getMonth() + 1;
          const startMonth = startDate.getMonth();
          let middleMonths = [];
          if (totalMonths > 2) {
            middleMonths = Array.from(
              new Array(totalMonths - 2),
              (val, index) => startMonth + 1 + index
            );
          }
          let middleDates = [];
          let middleMonthsForDays = [];
          for (let i = 0; i < middleMonths.length; i++) {
            middleDates = [
              ...middleDates,
              ...Array.from(
                new Array(manualMonths(startYear).totalDays[middleMonths[i]]),
                (val, index) => index + 1
              ),
            ];
          }
          for (let i = 0; i < middleMonths.length; i++) {
            for (
              let j = 0;
              j < manualMonths(startYear).totalDays[middleMonths[i]];
              j++
            ) {
              middleMonthsForDays.push(middleMonths[i]);
            }
          }
          const startMonthDaysTotal =
            manualMonths(startYear).totalDays[startDate.getMonth()];
          const daysOnStartMonthTotal =
            startMonthDaysTotal - startDate.getDate() + 1;
          const daysOnEndMonthTotal = endDate.getDate();
          const daysOnStartMonth = Array.from(
            new Array(daysOnStartMonthTotal),
            (val, index) => startDate.getDate() + index
          );
          const startMonths = Array.from(
            new Array(daysOnStartMonthTotal),
            (val, index) => startDate.getMonth()
          );
          const daysOnEndMonth = Array.from(
            new Array(daysOnEndMonthTotal),
            (val, index) => index + 1
          );
          const endMonths = Array.from(
            new Array(daysOnEndMonthTotal),
            (val, index) => endDate.getMonth()
          );
          return {
            dates: [...daysOnStartMonth, ...middleDates, ...daysOnEndMonth],
            months: [...startMonths, ...middleMonthsForDays, ...endMonths],
            years: startYear,
          };
        }
      } else if (endYear > startYear) {
        let rangeYears = [];
        for (let i = +startYear; i <= +endYear; i++) {
          rangeYears.push(i);
        }
        let startYearMonths = Array.from(
          new Array(12 - startMonth),
          (val, index) => startMonth + index
        );
        let endYearMonths = Array.from(
          new Array(endMonth + 1),
          (val, index) => index + 12 * rangeYears.indexOf(endYear)
        );
        let middleMonths = [];

        if (rangeYears.length > 2) {
          for (let i = 1; i < rangeYears.length - 1; i++) {
            middleMonths = [
              ...middleMonths,
              ...Array.from(
                new Array(12),
                (val, index) => index + 12 * rangeYears.indexOf(rangeYears[i])
              ),
            ];
          }
        }

        const rangeMonths = [
          ...startYearMonths,
          ...middleMonths,
          ...endYearMonths,
        ];
        let resultDates = [];
        let resultMonths = [];
        let resultYears = [];
        for (let i = 0; i < rangeMonths.length; i++) {
          let steadyYear =
            startDate.getFullYear() + 1 * Math.floor(rangeMonths[i] / 12);
          if (i === 0) {
            for (
              let j = startDate.getDate();
              j <= manualMonths(steadyYear).totalDays[rangeMonths[i]];
              j++
            ) {
              resultDates.push(j);
              resultMonths.push(rangeMonths[i]);
              resultYears.push(steadyYear);
            }
          } else if (i === rangeMonths.length - 1) {
            for (let j = 1; j <= endDate.getDate(); j++) {
              resultDates.push(j);
              resultMonths.push(rangeMonths[i]);
              resultYears.push(steadyYear);
            }
          } else {
            for (
              let j = 1;
              j <= manualMonths(steadyYear).totalDays[rangeMonths[i] % 12];
              j++
            ) {
              resultDates.push(j);
              resultMonths.push(rangeMonths[i]);
              resultYears.push(steadyYear);
            }
          }
        }
        return {
          dates: resultDates,
          months: resultMonths,
          years: resultYears,
        };
      } else {
        throw new Error("Error Input End Year & Start Year");
      }
    } else {
      return {
        dates: Array.from(
          new Array(months.totalDays[monthValue || month]),
          (val, index) => index + 1
        ),
        months: monthValue || month,
        years: yearValue || year,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  // eslint-disable-next-line no-unused-vars
  const parsedGatheredDays = useMemo(() => {
    const preParsedGatheredDays = gatheredDays.dates;
    const preParsedGatheredMonths = gatheredDays.months;
    const preParsedGatheredYears = gatheredDays.years;
    switch (
      new Date(
        `${
          Array.isArray(preParsedGatheredYears)
            ? preParsedGatheredYears[0]
            : preParsedGatheredYears
        }-${
          Array.isArray(preParsedGatheredMonths)
            ? preParsedGatheredMonths[0] + 1
            : preParsedGatheredMonths + 1
        }-${preParsedGatheredDays[0]}`
      ).getDay()
    ) {
      case 0:
        for (let i = 0; i < 6; i++) {
          preParsedGatheredDays.unshift("");
          if (Array.isArray(preParsedGatheredMonths)) {
            preParsedGatheredMonths.unshift("");
          }
          if (Array.isArray(preParsedGatheredYears)) {
            preParsedGatheredYears.unshift("");
          }
        }
        break;
      case 1:
        break;
      case 2:
        for (let i = 0; i < 1; i++) {
          preParsedGatheredDays.unshift("");
          if (Array.isArray(preParsedGatheredMonths)) {
            preParsedGatheredMonths.unshift("");
          }
          if (Array.isArray(preParsedGatheredYears)) {
            preParsedGatheredYears.unshift("");
          }
        }
        break;
      case 3:
        for (let i = 0; i < 2; i++) {
          preParsedGatheredDays.unshift("");
          if (Array.isArray(preParsedGatheredMonths)) {
            preParsedGatheredMonths.unshift("");
          }
          if (Array.isArray(preParsedGatheredYears)) {
            preParsedGatheredYears.unshift("");
          }
        }
        break;
      case 4:
        for (let i = 0; i < 3; i++) {
          preParsedGatheredDays.unshift("");
          if (Array.isArray(preParsedGatheredMonths)) {
            preParsedGatheredMonths.unshift("");
          }
          if (Array.isArray(preParsedGatheredYears)) {
            preParsedGatheredYears.unshift("");
          }
        }
        break;
      case 5:
        for (let i = 0; i < 4; i++) {
          preParsedGatheredDays.unshift("");
          if (Array.isArray(preParsedGatheredMonths)) {
            preParsedGatheredMonths.unshift("");
          }
          if (Array.isArray(preParsedGatheredYears)) {
            preParsedGatheredYears.unshift("");
          }
        }
        break;
      case 6:
        for (let i = 0; i < 5; i++) {
          preParsedGatheredDays.unshift("");
          if (Array.isArray(preParsedGatheredMonths)) {
            preParsedGatheredMonths.unshift("");
          }
          if (Array.isArray(preParsedGatheredYears)) {
            preParsedGatheredYears.unshift("");
          }
        }
        break;
      default:
    }

    return {
      dates: Array.from(
        new Array(Math.ceil(preParsedGatheredDays.length / 7)),
        (val, index) => index
      ).map((el) => {
        const arrayPayload = [];
        for (let i = 0; i < 7; i++) {
          if (preParsedGatheredDays[i + el * 7])
            arrayPayload.push(preParsedGatheredDays[i + el * 7]);
          else arrayPayload.push("");
        }
        return arrayPayload;
      }),
      months: Array.isArray(preParsedGatheredMonths)
        ? Array.from(
            new Array(Math.ceil(preParsedGatheredMonths.length / 7)),
            (val, index) => index
          ).map((el) => {
            const arrayPayload = [];
            for (let i = 0; i < 7; i++) {
              if (
                preParsedGatheredMonths[i + el * 7] ||
                preParsedGatheredMonths[i + el * 7] === 0
              )
                arrayPayload.push(preParsedGatheredMonths[i + el * 7]);
              else arrayPayload.push("");
            }
            return arrayPayload;
          })
        : preParsedGatheredMonths,
      years: Array.isArray(preParsedGatheredYears)
        ? Array.from(
            new Array(Math.ceil(preParsedGatheredYears.length / 7)),
            (val, index) => index
          ).map((el) => {
            const arrayPayload = [];
            for (let i = 0; i < 7; i++) {
              if (preParsedGatheredYears[i + el * 7])
                arrayPayload.push(preParsedGatheredYears[i + el * 7]);
              else arrayPayload.push("");
            }
            return arrayPayload;
          })
        : preParsedGatheredYears,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatheredDays, days.long]);

  useEffect(() => {
    if (yearValue) setYear(yearValue);
    if (monthValue) setMonth(monthValue);
  }, [yearValue, monthValue]);

  return (
    <div className="CalendarFmcgContainer">
      <div className="CalendarHeaderSection">
        {days.long.map((el, idx) => (
          <div className="CalendarTableHeader" key={idx}>
            <span>{el}</span>
          </div>
        ))}
      </div>
      <div className="CalendarBodySection">
        {dateDummy.map((el, idx) => {
          
          return (
            <div className="CalendarBodyFullDay" key={idx}>
              {el.map((e, dateIdx) =>
                e !== "" ? (
                  <div
                    className={`CalendarDateBody hoverable`}
                    key={dateIdx}
                    onClick={() =>
                      history.push(`/admin-fmcg/attendances/detail/perday/${e}`)
                    }
                  >
                    <span className="CalendarDate">{e}</span>
                    <span className="CalendarDateLabel">Day Off</span>

                    <img
                      className="CalendarLabelImage"
                      alt="logo"
                      src={CHECK_CIRCLE_IMAGE}
                    />
                  </div>
                ) : (
                  <div className={`CalendarDateBody  `} key={idx} />
                )
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
