import React from "react";
import "./RecentProductPromo.css";
import {
  Avatar,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import promo_sales_icon from "../../../../assets/images/promo-sales-icon.png";

function createData(name, product, date, image) {
  return { name, product, date, image };
}

const rows = [
  createData(
    "Putra Setiawan",
    "Susu Bendera",
    "21 December 2022",
    promo_sales_icon
  ),
  createData(
    "Putri Setiawan",
    "Susu Bendera",
    "21 December 2022",
    promo_sales_icon
  ),
  createData(
    "Putras Setiawan",
    "Susu Bendera",
    "21 December 2022",
    promo_sales_icon
  ),
];

const RecentProductPromo = () => {
  return (
    <div className="ContainerRecentProductPromo">
      <div className="WrapperRecentProductPromo">
        <div className="HeaderRecentProductPromo">
          <span>Recent Product Promo</span>
        </div>
        <div className="TableRecentProductPromo">
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 700,
                minHeight: 100,
                padding: 0,
                margin: 0,
                backgroundColor: "#FFFFFF",
              }}
              elevation={0}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Promo Type</TableCell>
                  <TableCell align="left">Product</TableCell>
                  <TableCell align="left">Period</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Avatar
                          src={row.image}
                          variant="square"
                          sx={{ width: 47, height: 33, borderRadius: 2 }}
                        />
                        <Typography
                          noWrap
                          style={{
                            textTransform: "capitalize",
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#000000",
                          }}
                        >
                          {row.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">{row.product}</TableCell>
                    <TableCell align="left">{row.date}</TableCell>
                    <TableCell align="right">
                      <Typography
                        component="span"
                        sx={{
                          color: "#1571DE",
                          fontSize: 16,
                          fontWeight: 500,
                          cursor: "pointer",
                        }}
                      >
                        Detail
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RecentProductPromo;
