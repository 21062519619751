import * as types from '../../type'

const initialState = {
    allBanks: [],
    bankById: {},
    updatedBank: {},
    loadingDataBanks: false,
    errorMessageBanks: {}
}

const bankReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_ALL_BANKS_BEGIN: {
            return {
                ...state,
                loadingDataBanks: true
            }
        }
        case types.FETCH_ALL_BANKS_SUCCESS: {
            return {
                ...state,
                loadingDataBanks: false,
                allBanks: action.payload
            }
        }
        case types.FETCH_ALL_BANKS_ERROR: {
            return {
                ...state,
                loadingDataBanks: false,
                errorMessageBanks: action.payload
            }
        }
        case types.GET_BANK_BY_ID: {
            return {
                ...state,
                bankById: action.payload
            }
        }
        default:
            return state;
    }
}

export default bankReducer;