import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { AddProductCompetitorFMCG } from "../../../../components/FMCG";

export default function ProductCompetitorAddForm() {
  const location = useLocation();
  const addNewCompetitorPath = location?.state?.location;

  return (
    <div>
      <Helmet>
        <title>MyWorkSpace | Setup</title>
      </Helmet>
      <AddProductCompetitorFMCG location={ addNewCompetitorPath } />
    </div>
  );
}
