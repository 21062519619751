/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import Geocode from "react-geocode";

import MapWithAutoComplete from "../../../_mapWithAutoComplete/MapWithAutoComplete/MapWithAutoComplete";
import InputText from "../../../utils/Input/Input";
import Styles from './EditLocationExternal.module.css';
// import FileBase from "react-file-base64";
// import { useDropzone } from "react-dropzone";
import {
  FormControl,
  MenuItem,
  Select,
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  Tooltip,
  Stack,
  Avatar,
  Button,
  styled,
  TextField,
  Autocomplete,
  Alert,
} from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import { useHistory } from "react-router";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import { useDispatch, useSelector } from "react-redux";
import { updateLocation } from "../../../../redux/actions/External/locationActions";
import users from "../../../SuperAdmin/_user/TableUsers/_mocks/user";
import { values } from "lodash";
import fetchClientCodeExternal from "../../../../async/client/fetchClientCodeExternal";
// import UploadImageDraggable from "../../../utils/UploadImageDraggable/UploadImageDraggable";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
      "& fieldset": {
          borderRadius: 8,
          border: '1px solid #e0e0e0',
      },
  },
  "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
  },
});

// function createData(Name, Position, Location, PhoneNumber) {
//   return {
//     Name,
//     Position,
//     Location,
//     PhoneNumber,
//   };
// }

// const rows = [
//   createData(
//     "Sheila",
//     "Sales Promotion Girl",
//     "Indomaret Jaksel",
//     "0812-9929-9299"
//   ),
//   createData("Anastasya", "Sales Promotion Girl", "Alfamidi", "0828-8289-9919"),
//   createData("Anton Sujriwo", "MD", "Alfamidi", "0828-7372-9919"),
//   createData("Alkatiri", "MD", "Alfamidi", "0828-7372-7737"),
//   createData("Aisyah Dara", "Sales Promotion Girl", "Giant", "0828-7878-7737"),
//   createData(
//     "Aisyah Darahhhh",
//     "Sales Promotion Girl",
//     "Indomaret",
//     "0828-7878-7737"
//   ),
// ];

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

// const headCells = [
//   {
//     id: "Name",
//     numeric: false,
//     disablePadding: false,
//     label: "Employee Name",
//   },
//   {
//     id: "Position",
//     numeric: true,
//     disablePadding: false,
//     label: "Position",
//   },
//   {
//     id: "Location",
//     numeric: true,
//     disablePadding: false,
//     label: "Location",
//   },
//   {
//     id: "PhoneNumber",
//     numeric: true,
//     disablePadding: false,
//     label: "Phone Number",
//   },
// ];

// function EnhancedTableHead(props) {
//   const {
//     onSelectAllClick,
//     order,
//     orderBy,
//     numSelected,
//     rowCount,
//     onRequestSort,
//     values
//   } = props;
//   const createSortHandler = (property) => (event) => {
//     onRequestSort(event, property);
//   };

//   return (
//     <TableHead>
//       <TableRow>
//         <TableCell padding="checkbox">
//           <Checkbox
//             color="primary"
//             indeterminate={numSelected > 0 && numSelected < rowCount}
//             checked={rowCount > 0 && numSelected === rowCount}
//             onChange={onSelectAllClick}
//             disabled={rowCount !== values?.users?.length}
//             sx={rowCount !== values?.users?.length ? { display: 'none'} : {}}
//           />
//         </TableCell>
//         {headCells.map((headCell) => (
//           <TableCell
//             key={headCell.id}
//             align="left"
//             // align={headCell.numeric ? "right" : "left"}
//             padding={headCell.disablePadding ? "none" : "normal"}
//             sortDirection={orderBy === headCell.id ? order : false}
//           >
//             <TableSortLabel
//               active={orderBy === headCell.id}
//               direction={orderBy === headCell.id ? order : "asc"}
//               onClick={createSortHandler(headCell.id)}
//             >
//               {headCell.label}
//               {orderBy === headCell.id ? (
//                 <Box component="span" sx={visuallyHidden}>
//                   {order === "desc" ? "sorted descending" : "sorted ascending"}
//                 </Box>
//               ) : null}
//             </TableSortLabel>
//           </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//   );
// }

// const EnhancedTableToolbar = (props) => {
//   const { numSelected, setListDataEmployee, listDataEmployee, setSearchTerm } = props;
  
//     // useEffect(() => {

//     // },[numSelected])

//   const handleSearch = (event) => {
//     setListDataEmployee(event.target.value);
//   };
//   const handleSubmitFilter = (e) => {
//     e.preventDefault();
//     setSearchTerm(listDataEmployee);
//   };
//   return (
//     <Toolbar
//       sx={{
//         justifyContent: "space-between",
//         pl: { sm: 2 },
//         pr: { xs: 1, sm: 1 },
//         ...(numSelected > 0 && {
//           bgcolor: (theme) =>
//             alpha(
//               theme.palette.primary.main,
//               theme.palette.action.activatedOpacity
//             ),
//         }),
//       }}
//     >
//       {numSelected > 0 ? (
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <Typography
//             sx={{ flex: "1 1 100%" }}
//             variant="h6"
//             id="tableTitle"
//             component="div"
//           >
//             <SearchbarSA
//               value={listDataEmployee}
//               onChange={handleSearch}
//               onSubmit={handleSubmitFilter}
//             />
//           </Typography>
//         </div>
//       ) : (
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <Typography
//             sx={{ flex: "1 1 100%" }}
//             variant="h6"
//             id="tableTitle"
//             component="div"
//           >
//             <SearchbarSA
//               value={listDataEmployee}
//               onChange={handleSearch}
//               onSubmit={handleSubmitFilter}
//             />
//           </Typography>
//         </div>
//       )}

//       {numSelected > 0 ? (
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <Tooltip title="Add Selected">
//             <Button
//               style={{
//                 height: 36,
//                 backgroundColor: "#1571DE",
//                 color: "#FFFFFF",
//                 textTransform: "none",
//               }}
//             >
//               {numSelected} employee selected
//             </Button>
//           </Tooltip>
//         </div>
//       ) : null}
//     </Toolbar>
//   );
// };

export default function EditLocationExternal({ locationData, clientName, id }) {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const history = useHistory();

  const [selected, setSelected] = useState(clientName);
  const [selectedCode, setSelectedCode] = useState(locationData?.clientCode);
  const [dataClientCode, setDataClientCode] = useState([]);
  const [address, setAddress] = useState({});
  const [showSearch, setShowSearch] = useState(true);
  const [alert, setAlert] = useState(null)
  const [coordinate, setCoordinateAdd] = useState({
      Longitude: locationData?.longitude,
      Latitude: locationData?.latitude,
  });    

  const [form, setForm] = useState({
    name: locationData?.name,
    address: locationData?.address,
    longitude: coordinate?.Longitude,
    latitude: coordinate?.Latitude,
    clientCode: locationData?.clientCode,
    radiusInMeter: locationData?.radiusInMeter,
})



const handleChange = (e) => {
  setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
  }))
}

const clientNames = useMemo(() => {
  return dataClientCode.map((el) => el?.name_client);
}, [dataClientCode]);

const handleSelectedClient = (value) => {
  setSelected(value);
  let activeCode = dataClientCode.find((item) => item.name_client === value);
  setSelectedCode(activeCode?.code_client)
};

useEffect(() => {
  (async () => {
      try {
          const { response } = await fetchClientCodeExternal(token);
          setDataClientCode(response);
      } catch (error) {
          console.log(error);
      }
  })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

useEffect(() => {
  setForm((prev) => ({
      ...prev,
      "longitude": coordinate?.Longitude,
      "latitude": coordinate?.Latitude,
      "clientCode": selectedCode,
  }))
}, [coordinate?.Longitude, coordinate?.Latitude, selectedCode, address?.Address])


const handleSubmit = (e) => {
  e.preventDefault();
  if (form.latitude === undefined || form.longitude === undefined) {
      setAlert("Please pin the location on the map");
  } else {
      dispatch(updateLocation(token, id, form))
      history.push({
          pathname: `/admin/location-details/${id}`,
          state: {
              clientName: clientName
          }
      })
  }
}

const handleReset = (e) => {
  e.preventDefault();
  setForm((prev) => ({
      ...prev,
      "name": '',
      "address": '',
      "longitude": '',
      "latitude": '',
      "clientCode": '',
      "radiusInMeter": ''
  }))
  setSelected(null)
  setSelectedCode(null)
  setAddress(null)
  setCoordinateAdd(null)
}


useEffect(() => {
  Geocode.fromLatLng(form?.latitude, form?.longitude).then(
      (response) => {
          const address = response.results[0].formatted_address;
          setAddress({ Address: address });
      },
      (error) => {
          console.error(error);
      }
  );
}, [form?.latitude, form?.longitude])



  return (
    <div className={Styles.ContainerWrapper}>
            <span>Edit Location Data</span>
            <div className={Styles.Container}>
                <form onSubmit={handleSubmit}>
                    <div className={Styles.Top}>
                        <span>Location Details</span>
                        <div className={Styles.BoxForm}>
                            <label htmlFor="name">Location Name</label>
                            <input type="text" id="name" value={form?.name} name="name" onChange={handleChange} required />
                        </div>
                        <div className={Styles.BoxForm}>
                            <label htmlFor="clientName">Client Name</label>
                            <Autocomplete
                                disablePortal
                                options={clientNames}
                                value={selected}
                                sx={{ width: '100%' }}
                                onChange={(e, value) =>
                                    value === null
                                        ? handleSelectedClient("")
                                        : handleSelectedClient(value)
                                }
                                renderInput={(params) => <MuiTextField {...params} size="small" sx={{ background: 'var(--main-bg)' }} required />}
                            />
                        </div>
                        <div className={Styles.BoxForm}>
                            <label htmlFor="address">Address</label>
                            <input type="text" id="address" value={form?.address} name="address" onChange={handleChange} required />
                        </div>
                        <div className={Styles.BoxForm}>
                            <label htmlFor="radiusInMeter">Range (in metre)</label>
                            <input type="number" id="radiusInMeter" value={form?.radiusInMeter} name="radiusInMeter" onChange={handleChange} required />
                        </div>
                    </div>
                    {alert && (
                        <div className={Styles.AlertWrapper}>
                            <Alert severity="warning" onClose={() => { setAlert(null) }}>{alert}</Alert>
                        </div>
                    )}
                    <div className={Styles.MapContainer}>
                        <MapWithAutoComplete coordinate={coordinate} setCoordinate={setCoordinateAdd} showSearch={showSearch} setShowSearch={setShowSearch} setAddress={setAddress} />
                    </div>
                    <div className={Styles.Bottom}>
                        <div className={Styles.BoxAuto}>
                            <span>Address Details</span>
                            <span>{address?.Address}</span>
                        </div>
                        <div className={Styles.LastWrapper}>
                            <div className={Styles.BoxAuto}>
                                <span>Latitude</span>
                                <span>{form?.latitude ? form?.latitude : '-'}</span>
                            </div>
                            <div className={Styles.BoxAuto}>
                                <span>Longitude</span>
                                <span>{form?.longitude ? form?.longitude : '-'}</span>
                            </div>
                        </div>
                    </div>
                    <div className={Styles.ButtonWrapper}>
                        <Button type="submit" variant="contained" className={Styles.SaveButton}>Save</Button>
                        <Button type="button" variant="outlined" onClick={handleReset} className={Styles.ResetButton}>Reset</Button>
                    </div>
                </form>
            </div>
        </div>
  );
}


 // <div className="add__location-container">
    //   <div className="add__location-map">
    //     <MapWithAutoComplete
    //       coordinate={values}
    //       setCoordinate={setValues}
    //       showSearch={showSearch}
    //       setShowSearch={setShowSearch}
    //       setAddress={setValues}
    //     />
    //   </div>
    //   <div className="add__location-desc">
    //     <div className="add__location-wrapper-text">
    //       <div className="add__location-text-input">
    //         <label style={{ paddingBottom: 8 }}>Coordinate Point</label>
    //         <InputText
    //           name="Coordinate Point"
    //           type="text"
    //           value={`${values?.Latitude} ${values?.Longitude}` || ""}
    //           onChange={handleSelectedValue}
    //           disabled
    //         />
    //       </div>
    //       <div className="add__location-text-input">
    //         <label style={{ paddingBottom: 8 }}>Location Name</label>
    //         <InputText
    //           name="LocationName"
    //           type="text"
    //           value={values?.LocationName || ""}
    //           onChange={handleSelectedValue}
    //         />
    //       </div>
    //     </div>
    //     <div className="add__location-wrapper-text" style={{ paddingTop: 20 }}>
    //       <div className="add__location-text-input">
    //         <label style={{ paddingBottom: 8 }}>Address</label>
    //         <InputText
    //           name="Address"
    //           type="text"
    //           value={values.Address|| ""}
    //           // onChange={(e) => changeAddress(e)}
    //           onChange={handleSelectedValue}
    //           // onChange={(e) => setAddress(e.target.value)}
    //           disabled
    //         />
    //       </div>
    //       <div className="add__location-text-input">
    //         <label style={{ paddingBottom: 8 }}>Phone Number</label>
    //         <InputText
    //           name="PhoneNumber"
    //           type="number"
    //           value={values?.PhoneNumber || ""}
    //           onChange={handleSelectedValue}
    //         />
    //       </div>
    //     </div>
    //     <div className="add__location-wrapper-text" style={{ paddingTop: 20 }}>
    //       <div className="add__location-text-input">
    //         <label style={{ paddingBottom: 8 }}>Client</label>
    //         <InputText
    //           name="Client"
    //           type="text"
    //           value={values?.Client || ""}
    //           disabled
    //         />
    //       </div>
    //       <div className="add__location-text-input">
    //         <label style={{ paddingBottom: 8 }}>Radius / meters</label>
    //         <InputText name="Radius" type="number" 
    //         value={values?.Radius || ""} 
    //         onChange={handleSelectedValue}
    //         />
    //       </div>
    //     </div>
    //     {isLoading ? (
    //       <div
    //         style={{
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "center",
    //           marginTop: 100,
    //         }}
    //       >
    //         <div style={{ width: 200 }}>
    //           <LoadingAnimation />
    //         </div>
    //       </div>
    //     ) : (
    //       <div className="add__location-container-table">
    //         <Box sx={{ width: "100%" }}>
    //           <Paper sx={{ width: "100%", backgroundColor: "#FAFAFA" }}>
    //             <EnhancedTableToolbar
    //               numSelected={checkedEmployees}
    //               listDataEmployee={listDataEmployee}
    //               setListDataEmployee={setListDataEmployee}
    //               setSearchTerm={setSearchTerm}
    //             />
    //             <TableContainer>
    //               <Table
    //                 sx={{ width: "100%" }}
    //                 // sx={{ minWidth: 750 }}
    //                 aria-labelledby="tableTitle"
    //                 // size={dense ? "small" : "medium"}
    //               >
    //                 <EnhancedTableHead
    //                   numSelected={checkedEmployees}
    //                   order={order}
    //                   orderBy={orderBy}
    //                   onSelectAllClick={handleSelectAllClick}
    //                   onRequestSort={handleRequestSort}
    //                   // rowCount={values.users?.length}
    //                   values={values}
    //                   rowCount={values.users?.filter(
    //                     (el) =>
    //                       el.Name?.toLowerCase().includes(
    //                         listDataEmployee.toLowerCase()
    //                       ) ||
    //                       el.Position?.toLowerCase().includes(
    //                         listDataEmployee.toLowerCase()
    //                       )
    //                   ).length}
    //                 />
    //                 <TableBody>
    //                   {stableSort(values?.users, getComparator(order, orderBy))
    //                     .filter(
    //                       (el) =>
    //                         el.Name?.toLowerCase().includes(
    //                           listDataEmployee.toLowerCase()
    //                         ) ||
    //                         el.Position?.toLowerCase().includes(
    //                           listDataEmployee.toLowerCase()
    //                         )
    //                     )
    //                     .slice(
    //                       page * rowsPerPage,
    //                       page * rowsPerPage + rowsPerPage
    //                     )
    //                     .map((row, index) => {
    //                       const isItemSelected = row?.Locations.some((el) => el._id === id);
    //                       const labelId = `enhanced-table-checkbox-${index}`;

    //                       return (
    //                         <TableRow
    //                           hover
    //                           // onClick={(event) =>
    //                           //   handleClick(event, row.employeeName)
    //                           // }
    //                           role="checkbox"
    //                           aria-checked={isItemSelected}
    //                           tabIndex={-1}
    //                           key={row?.Name}
    //                           selected={isItemSelected}
    //                         >
    //                           <TableCell padding="checkbox">
    //                             <Checkbox
    //                               onClick={(event) => handleClick(event, row?._id)}
    //                               color="primary"
    //                               checked={isItemSelected}
    //                               inputProps={{ "aria-labelledby": labelId }}
    //                             />
    //                           </TableCell>
    //                           <TableCell
    //                             component="th"
    //                             id={labelId}
    //                             scope="row"
    //                             // align="right"
    //                             // padding="none"
    //                           >
    //                             <Stack
    //                               direction="row"
    //                               alignItems="center"
    //                               spacing={2}
    //                             >
    //                               <Avatar
    //                                 alt={row.Name}
    //                                 src=""
    //                               />
    //                               <Typography style={{ textTransform: "capitalize"}}>{row?.Name?.toLowerCase()}</Typography>
    //                             </Stack>
    //                           </TableCell>
    //                           <TableCell align="left">{row.Position}</TableCell>
    //                           <TableCell >
    //                             <Stack
    //                               direction="column"
    //                               spacing={0}
    //                             >
    //                               {
    //                                 React.Children.toArray(row?.Locations?.map((location) => (
    //                                   <span style={{ textTransform: 'capitalize'}}>{location?.LocationName}</span>
    //                                 )))
    //                               }
    //                             </Stack>
    //                           </TableCell>
    //                           <TableCell align="left">{row?.PhoneNumber}</TableCell>
    //                           {/* <TableCell align="right">
    //                             <Button
    //                               style={{
    //                                 width: 53,
    //                                 height: 36,
    //                                 backgroundColor: "#1571DE",
    //                                 color: "#FFFFFF",
    //                                 textTransform: "none",
    //                               }}
    //                             >
    //                               Add
    //                             </Button>
    //                           </TableCell> */}
    //                         </TableRow>
    //                       );
    //                     })}
    //                   {/* {emptyRows > 0 && (
    //                   <TableRow
    //                     style={{
    //                       height: (dense ? 33 : 53) * emptyRows,
    //                     }}
    //                   >
    //                     <TableCell colSpan={6} />
    //                   </TableRow>
    //                 )} */}
    //                 </TableBody>
    //               </Table>
    //             </TableContainer>
    //             <TablePagination
    //               rowsPerPageOptions={[10, 20, 30]}
    //               component="div"
    //               count={values.users?.filter(
    //                   (el) =>
    //                     el.Name?.toLowerCase().includes(
    //                       listDataEmployee.toLowerCase()
    //                     ) ||
    //                     el.Position?.toLowerCase().includes(
    //                       listDataEmployee.toLowerCase()
    //                     )
    //                 )?.length
    //               }
    //               rowsPerPage={rowsPerPage}
    //               page={page}
    //               onPageChange={handleChangePage}
    //               onRowsPerPageChange={handleChangeRowsPerPage}
    //             />
    //           </Paper>
    //         </Box>
    //       </div>
    //     )}
    //     <div className="add__location-button">
    //       <Button
    //         className="add__location-btn-save"
    //         style={{
    //           background: "#1571DE",
    //           color: "#FFFFFF",
    //           fontSize: 14,
    //           fontWeight: 500,
    //         }}
    //         onClick={handleSave}
    //       >
    //         Save
    //       </Button>
    //       <Button
    //         className="add__location-btn-cancel"
    //         style={{
    //           border: "1px solid #1571DE",
    //           color: "#1571DE",
    //           fontSize: 14,
    //           fontWeight: 500,
    //         }}
    //         onClick={() => history.push("/admin/location-external")}
    //       >
    //         Cancel
    //       </Button>
    //     </div>
    //   </div>
    // </div>