import React, { useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import "./SubMenu.css";

const SubMenu = ({ item, active }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);
  const active1 = active ? "active" : "";
  const location = useLocation();

  const handleSubNav = () => {
    if (!item.subNav) {
      return (
        <div className={`sidebar__item-inner ${active1}`}>
          {item.icon !== undefined && <i className={item.icon} />}
          <span className="SidebarLabel">{item.display_name}</span>
        </div>
      );
    } else if (item.subNav) {
      return (
        <div className={`sidebar__item-inner`}>
          {item.icon !== undefined && <i className={item.icon} />}
          <span className="SidebarLabel">{item.display_name}</span>
        </div>
      );
    }
  };

  return (
    <>
      <Link
        className="SidebarLink"
        to={`/superadmin` + item.route}
        onClick={item.subNav && showSubnav}
        // style={{ paddingLeft: 20}}
      >
        {handleSubNav()}
        <div style={{ paddingTop: 8 }}>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </Link>
      {subnav &&
        item.subNav.map((item, index) => {
          if (`/superadmin` + item.route === location.pathname) {
            return (
              <Link
                to={`/superadmin` + item.route}
                key={index}
                className={`SubnavItemInner active`}
                // style={{ paddingLeft: "3rem" }}
                // style={{ backgroundColor: 'red', width: 250, justifyContent: 'center', marginLeft: 35}}
              >
                <i className={item.icon} />
                <span className="SidebarLabel">{item.display_name}</span>
              </Link>
            );
          } else {
            // ketika kondisi tidak di klik , item muncul tapi tidak active
            return (
              <Link
                to={`/superadmin` + item.route}
                key={index}
                className={`SubnavItemInner`}
                style={{ paddingLeft: "3rem" }}
              >
                <i className={item.icon} />
                <span className="SidebarLabel">{item.display_name}</span>
              </Link>
            );
          }
      })}
    </>
  );
};

export default SubMenu;
