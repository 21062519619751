import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  updateDraftToPublish,
} from "../../../../../../redux/actions/SuperAdmin/faqsActions";
import "./EditDraft.css";

export default function EditDraft({ draftById = {} }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const [title, setTitle] = useState(draftById.Title);
  const [content, setContent] = useState(draftById.Content);
  // const [typeDraft, setTypeDraft] = useState()

  const handlePublish = () => {
    const result = {
      title: title,
      content: content,
      type: 'publish'
    };
    // console.log(result);
    dispatch(updateDraftToPublish(token, draftById._id ,result));
    history.push({
      pathname: "/superadmin/faq",
    });
  };

  // console.log(draftById, "cek draftByid");

  return (
    <div className="container-edit-draft">
      <div className="wrapper-edit-draft">
        <div className="backdrop-edit-draft">
          <div className="input-title-ED">
            <span>Title</span>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="input-content-ED">
            <span>Content</span>
            <textarea
              name="paragraph_text"
              cols="50"
              rows="10"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </div>
        </div>
        <div className="helper-EP">
          <Button
            style={{
              width: 75,
              height: 40,
              background: "#1571DE",
              borderRadius: 4,
              color: "white",
            }}
            onClick={handlePublish}
          >
            Publish
          </Button>
        </div>
      </div>
    </div>
  );
}
