import axios from "axios";
import {API} from "../../redux/api";

const fetchGroupData = (token, clientName) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(`${API}/user/group-data?name=${clientName}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export default fetchGroupData;
