import { useHistory, useLocation, useParams } from 'react-router-dom';
import Styles from './LayoutAddNotification.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import fetchClientCode from '../../../../async/client/fetchClientCode';
import { addNotification, resetFieldNotification, updateNotificationById } from '../../../../redux/actions/SuperAdmin/informationActions';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Autocomplete, Button, Checkbox, FormControl, IconButton, styled, TextField, Typography } from '@mui/material';
import { AddCircle, Cancel, ControlPoint, Delete, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import CustomTable from '../../../CustomTable/CustomTable';
import NOTIFICATION_IMG from '../../../../assets/images/notification.png';
import ModalFeaturePreview from '../ModalFeaturePreview/ModalFeaturePreview';

const MuiTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: 8,
            border: '1px solid #d0d0d0',
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: '1.5px solid #d3c9c9',
        borderColor: '#d3c9c9',
        borderRadius: 8,
        //   width: "100%",
    },
});

export default function LayoutAddNotification({ notification, type }) {
    const token = localStorage.getItem('accessToken');
    const { addedNotification, updatedNotification, errorNotification } = useSelector((state) => state.informations);
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [dataClientCodes, setDataClientCodes] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [selectedPushNotification, setSelectedPushNotification] = useState(null);
    const [modalPreview, setModalPreview] = useState(null);
    const [pushNotification, setPushNotification] = useState([]);
    const [error, setError] = useState('');
    const [lastID, setLastID] = useState(1);
    const [deletedSchedule, setDeleteSchedule] = useState([]);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [totalPage, setTotalPage] = useState(1);
    const [values, setValues] = useState({
        title: '',
        description: '',
        type: null,
    });

    const fixedClients = useMemo(() => {
        let pre = [
            { codes: 'ALL', name: 'ALL' },
            { codes: 'Tes', name: 'Client Tester' },
        ];
        return pre.concat(dataClientCodes);
    }, [dataClientCodes]);

    useEffect(() => {
        if (type === 'EDIT' && id) {
            setValues({
                title: notification?.title,
                description: notification?.description,
                type: notification?.type || null,
                _id: notification?._id,
            });
            if (notification?.schedule?.length > 0) {
                const mapNotif = notification?.schedule?.map((value, index) => {
                    return {
                        ...value,
                        id: index + 1,
                        date: value?.date ? dayjs(value?.date) : null,
                        time: value?.date ? moment(new Date(value?.date)).format('HH:mm:ss') : null,
                    };
                });
                setDeleteSchedule([]);
                setPushNotification(mapNotif);
                setLastID(notification?.schedule?.length > 0 ? notification?.schedule?.length + 1 : 1);
            }
            const preClients = fixedClients.filter((el) => {
                return notification?.clientCode?.some((f) => {
                    return f === el.codes;
                });
            });
            setSelectedClients(preClients);
        }
        setError('')
    }, [notification, id, type, fixedClients]);

    //setTotalPage
    useEffect(() => {
        if (pushNotification?.length > 0) {
            const size = pushNotification?.length || 0;
            const total = Math.ceil(size / limit);
            setTotalPage(total > 0 ? total : 1);
        }
    }, [pushNotification, limit]);

    const rowSchedule = useMemo(() => {
        if (pushNotification?.length > 0) {
            const startNumber = (page - 1) * limit + 1;
            const lastNumber = page * limit;
            let arrSch = [];
            const temp = [...pushNotification];

            for (let i = startNumber; i <= lastNumber; i++) {
                if (temp[i - 1]) {
                    arrSch.push({
                        ...temp[i - 1],
                        no: i,
                    });
                }
            }

            return arrSch;
        } else {
            return [];
        }
    }, [pushNotification, page, limit]);

    useEffect(() => {
        (async () => {
            try {
                const { response } = await fetchClientCode(token);
                setDataClientCodes(response);
            } catch (error) {
                console.log(error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const entryHour = (timeString) => {
        const today = new Date();
        const arrEntryHour = timeString?.split(':');
        today.setUTCHours(+arrEntryHour[0] - 7, +arrEntryHour[1], +arrEntryHour[2], 0);
        return today;
    };

    const capitalizedClientName = (clientName) => {
        return clientName
            ?.toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
            .replace('Pt', 'PT')
            .replace('Cv', 'CV');
    };

    const handleItemSchedule = useCallback(
        (typeHandle, valueSch) => {
            if (typeHandle === 'ADD') {
                setPushNotification((prev) => {
                    return [
                        ...prev,
                        {
                            id: lastID,
                            date: null,
                            time: null,
                            // date: dayjs(),
                            // time: new Date().toLocaleTimeString('en', { hour12: false }),
                            status: 'Waiting',
                            action: 'ADD',
                        },
                    ];
                });
                setLastID(lastID + 1);
            } else if (typeHandle === 'EDIT') {
                setPushNotification((prev) => {
                    const idx = prev?.findIndex((f) => f?.id === valueSch?.id);
                    prev[idx] = {
                        ...valueSch,
                        action: valueSch?.action === 'ADD' ? 'ADD' : 'UPDATE',
                    };
                    return [...prev];
                });
            } else if (typeHandle === 'DELETE') {
                if (valueSch?._id) {
                    setDeleteSchedule((prev) => {
                        return [
                            ...prev,
                            {
                                ...valueSch,
                                action: 'DELETE',
                            },
                        ];
                    });
                }
                setPushNotification((prev) => {
                    const arr = prev?.filter((f) => f?.id !== valueSch?.id);
                    return [...arr];
                });
            }
        },
        [lastID]
    );

    const handleChangeValue = (e) => {
        setValues((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleChangeValuePreview = (option) => {
        let temp = values?.type;

        if (temp === option) {
            temp = null;
        } else {
            temp = option;
        }
        setValues((prev) => ({
            ...prev,
            type: temp,
        }));
    };

    const handleSelectedClient = (e, value) => {
        e.preventDefault();
        // setSelected(value);

        const isExist = selectedClients.find((item) => item?.name === value?.name);
        const doesContainAll = selectedClients.some((item) => item?.codes === 'All');
        let isAll = Boolean(value?.codes === 'All' && value?.name === 'All');
        const isEmpty = selectedClients.length === 0;

        if (!isExist && !isAll && !doesContainAll) {
            let temp = selectedClients.concat(value);
            setSelectedClients(temp);
        } else if (isExist) {
            const x = selectedClients.filter((item) => item !== value);
            setSelectedClients(x);
        } else if (isEmpty && isAll) {
            let temp = selectedClients.concat(value);
            setSelectedClients(temp);
        } else if (!isExist && doesContainAll) {
            setSelectedClients(selectedClients);
        }
    };
    const isSelected = (option) => selectedClients?.indexOf(option) !== -1;
    const handleDeleteClient = (value) => {
        const x = selectedClients.filter((item) => item !== value);
        setSelectedClients(x);
    };

    const handleSubmit = () => {
        // CHECK
        const currentDate = new Date();

        for (let item of pushNotification) {
            if (!item?.date || !item?.time) {
                setError(`Select the date/time${item?.no ? ` for schedule number ${item?.no} ` : ''}.`);
                return;
            }
            const inputDate = new Date(item?.date);
            const [inputHour, inputMinute, inputSecond] = item?.time?.split(':').map(Number);
            if (
                item?.status === 'Waiting' &&
                inputDate.toDateString() === currentDate.toDateString() &&
                (inputHour < currentDate.getHours() ||
                    (inputHour === currentDate.getHours() && inputMinute < currentDate.getMinutes()) ||
                    (inputHour === currentDate.getHours() &&
                        inputMinute === currentDate.getMinutes() &&
                        inputSecond < currentDate.getSeconds()))
            ) {
                setError(
                    `Cannot select a date/time in the past${item?.no ? ` for schedule number ${item?.no} ` : ''}!`
                );
                return;
            }
        }

        if (selectedClients?.length < 1){
            setError("Please select a client")
            return
        }
        if (!values?.title){
            setError("Title cannot be empty")
            return
        }
        if (!values?.description){
            setError("Description cannot be empty")
            return
        }

        setError('');

        //FORM
        const submitData = {
            clientCode: selectedClients?.map((e) => e?.codes) || [],
            title: values?.title,
            description: values?.description,
            type: values?.type || null,
        };

        let submitSchedule = [];

        if (pushNotification?.length > 0) {
            const notif = pushNotification?.filter((f) => f?.action === 'ADD' || f?.action === 'UPDATE');
            notif?.forEach((scheduleItem, indexSchedule) => {
                const [hours, minutes, seconds] = scheduleItem?.time?.split(':').map(Number);
                const fixedDate = new Date(scheduleItem?.date);

                fixedDate.setHours(hours);
                fixedDate.setMinutes(minutes);
                fixedDate.setSeconds(seconds);

                let scheduleData = {
                    date: fixedDate?.toISOString(),
                };

                if (scheduleItem?.action === 'UPDATE') {
                    scheduleData = {
                        ...scheduleData,
                        _id: scheduleItem?._id,
                        action: 'UPDATE',
                    };
                }
                submitSchedule.push(scheduleData);
            });
        }
        if (deletedSchedule?.length > 0) {
            deletedSchedule?.forEach((scheduleItem, indexSchedule) => {
                const [hours, minutes, seconds] = scheduleItem?.time?.split(':').map(Number);
                const fixedDate = new Date(scheduleItem?.date);

                fixedDate.setHours(hours);
                fixedDate.setMinutes(minutes);
                fixedDate.setSeconds(seconds);

                submitSchedule.push({
                    date: fixedDate?.toISOString(),
                    _id: scheduleItem?._id,
                    action: 'DELETE',
                });
            });
        }

        console.log('SUBMIT', { ...submitData, schedule: submitSchedule });
        if (type === 'ADD'){
            dispatch(addNotification(token, {...submitData, schedule: submitSchedule}));
        } else if (type === 'EDIT'){
            dispatch(updateNotificationById(token, id, {...submitData, schedule: submitSchedule}));
        }
    };

    useEffect(() => {
        if (addedNotification?.[0]?._id || updatedNotification?.modifiedCount){
            dispatch(resetFieldNotification())
            history.push('/admin-business/notification')
        }
    }, [addedNotification, updatedNotification, dispatch, history])

    useEffect(() => {
        if (errorNotification?.error){
            setError(errorNotification?.error)
        }
    }, [errorNotification])

    const tableHead = useMemo(() => {
        return [
            { id: 'no', label: 'No', alignRight: false, width: '10%' },
            {
                id: 'date',
                label: 'Tanggal',
                alignRight: false,
                width: '30%',
                renderData: (row) => (
                    <div className={Styles.TableRowComponent}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={row?.date}
                                onChange={(e) => handleItemSchedule('EDIT', { ...row, date: e })}
                                disabled={row?.status === 'Published'}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        autoComplete="off"
                                        fullWidth
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: 'Pilih Tanggal',
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                border: !row?.date && error ? '1px solid #df2222' : 'none',
                                                padding: 0,
                                                display: 'flex',
                                                flexDirection: 'row-reverse',
                                                '& fieldset': {
                                                    display: 'none', // Menghilangkan border
                                                },
                                            },
                                            '& .MuiInputAdornment-root': {
                                                marginRight: 0,
                                                marginLeft: 0,
                                            },
                                        }}
                                    />
                                )}
                                minDate={dayjs()}
                            />
                        </LocalizationProvider>
                    </div>
                ),
            },
            {
                id: 'time',
                label: 'Jam',
                alignRight: false,
                width: '30%',
                renderData: (row) => (
                    <div className={Styles.TableRowComponent}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                disabled={row?.status === 'Published'}
                                ampm={false}
                                value={row?.time ? entryHour(row?.time) : null}
                                onChange={(e) => {
                                    const temp = new Date(e).toLocaleTimeString('en', { hour12: false });
                                    handleItemSchedule('EDIT', { ...row, time: temp });
                                }}
                                minTime={row?.date && row?.date?.isSame(dayjs(), 'day') ? dayjs() : null}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        fullWidth
                                        aria-readonly
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: 'Pilih Waktu',
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                border: !row?.time && error ? '1px solid #df2222' : 'none',
                                                padding: 0,
                                                display: 'flex',
                                                flexDirection: 'row-reverse',
                                                '& fieldset': {
                                                    display: 'none', // Menghilangkan border
                                                },
                                            },
                                            '& .MuiInputAdornment-root': {
                                                marginRight: 0,
                                                marginLeft: 0,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                ),
            },
            {
                id: 'status',
                label: 'Status',
                alignRight: false,
                width: '10%',
                renderData: (row) => (
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 400,
                        }}>
                        {row?.status ? <span className={Styles[row.status]}>{row.status}</span> : <span></span>}
                    </Typography>
                ),
            },
            {
                id: '',
                width: '10%',
                renderData: (row) => {
                    return (
                        <div>
                            <IconButton
                                aria-label="add-notif"
                                color="error"
                                sx={{
                                    gap: '10px',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                }}
                                disabled={row?.status === 'Published'}
                                onClick={(e) => handleItemSchedule('DELETE', row)}>
                                <Delete fontSize="small" />
                            </IconButton>
                        </div>
                    );
                },
            },
        ];
    }, [handleItemSchedule, error]);

    return (
        <div className={Styles.Container}>
            <div className={Styles.ItemBox}>
                <span>To <p className={Styles.ErrorText}>*</p></span>
                <FormControl size="small" fullWidth>
                    <Autocomplete
                        disablePortal
                        value={null}
                        blurOnSelect={true}
                        disableCloseOnSelect
                        options={fixedClients}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: '100%' }}
                        onChange={(e, value) =>
                            value === null ? handleSelectedClient(e, null) : handleSelectedClient(e, value)
                        }
                        renderOption={(props, option) => {
                            const isItemSelected = isSelected(option);
                            return (
                                <li
                                    {...props}
                                    onClick={(e) => e.preventDefault()}
                                    key={option?.codes}
                                    style={{ borderBottom: '1px solid #c2c2c2', padding: '12px' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}>
                                        <span className={Styles.ClientOption}>
                                            {capitalizedClientName(option?.name)}
                                        </span>
                                        <Checkbox
                                            onClick={(e) => handleSelectedClient(e, option)}
                                            sx={{ margin: 0, padding: 0 }}
                                            checked={isItemSelected}
                                            icon={<RadioButtonUnchecked sx={{ color: '#c2c2c2' }} />}
                                            checkedIcon={<RadioButtonChecked sx={{ color: '#1571DE' }} />}
                                        />
                                    </div>
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <MuiTextField
                                {...params}
                                size="small"
                                sx={{ background: 'var(--main-bg)'}}
                                placeholder="Select company"
                                error={error && selectedClients?.length < 1}
                            />
                        )}
                    />
                </FormControl>
            </div>
            {selectedClients?.length > 0 && (
                <div className={Styles.ClientsChipContainer}>
                    {selectedClients.map((client, i) => (
                        <div key={i} className={Styles.ClientsChip}>
                            <span>{capitalizedClientName(client?.name)}</span>
                            <Cancel className={Styles.CancelIcon} onClick={() => handleDeleteClient(client)} />
                        </div>
                    ))}
                </div>
            )}
            <div className={Styles.ItemBoxContent}>
                <span>Title <p className={Styles.ErrorText}>*</p></span>
                <input
                    type="text"
                    name="title"
                    onChange={handleChangeValue}
                    placeholder="Tuliskan judul notifikasi"
                    value={values?.title}
                    style={{borderColor: (error && !values?.title ? "#df2222" : '')}}
                />
            </div>
            <div className={Styles.ItemBoxContent}>
                <span>Description <p className={Styles.ErrorText}>*</p></span>
                <textarea
                    style={{borderColor: (error && !values?.description ? "#df2222" : '')}}
                    type="text"
                    name="description"
                    onChange={handleChangeValue}
                    placeholder="Tuliskan deskripsi notifikasi"
                    value={values?.description}
                />
            </div>

            <div className={Styles.ItemBoxFeature}>
                <span>Fitur di MyWorkSpace</span>
                <span>Notifikasi akan masuk pada fitur yang akan anda pilih</span>

                <div className={Styles.SelectPreviewWrapper}>
                    <div className={Styles.FeatureWrapper}>
                        <div className={Styles.FeatureText}>
                            <Checkbox
                                onClick={(e) => handleChangeValuePreview('Kasbon')}
                                sx={{ margin: 0, padding: 0 }}
                                checked={values?.type === 'Kasbon'}
                                icon={<RadioButtonUnchecked sx={{ color: '#c2c2c2' }} />}
                                checkedIcon={<RadioButtonChecked sx={{ color: '#1571DE' }} />}
                            />
                            <span>Kasbon</span>
                        </div>
                        <div
                            className={Styles.TextButton}
                            onClick={() => {
                                setModalPreview('Kasbon');
                            }}>
                            <span>Lihat Preview</span>
                        </div>
                    </div>
                    <div className={Styles.Divider} />
                    <div className={Styles.FeatureWrapper}>
                        <div className={Styles.FeatureText}>
                            <Checkbox
                                onClick={(e) => handleChangeValuePreview('Gadai')}
                                sx={{ margin: 0, padding: 0 }}
                                checked={values?.type === 'Gadai'}
                                icon={<RadioButtonUnchecked sx={{ color: '#c2c2c2' }} />}
                                checkedIcon={<RadioButtonChecked sx={{ color: '#1571DE' }} />}
                            />
                            <span>Gadai</span>
                        </div>
                        <div
                            className={Styles.TextButton}
                            onClick={() => {
                                setModalPreview('Gadai');
                            }}>
                            <span>Lihat Preview</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={Styles.TitleNotification}>
                <span>Jadwal Notifikasi</span>
                <div className={Styles.SetupPushNotificationButtonWrapper}>
                    {pushNotification?.length > 0 && (
                        <IconButton
                            aria-label="add-notif"
                            color="primary"
                            sx={{
                                gap: '10px',
                                cursor: 'pointer',
                                fontSize: '14px',
                                fontWeight: '500',
                            }}
                            disableRipple={true}
                            onClick={() => handleItemSchedule('ADD')}>
                            <AddCircle fontSize="small" />
                            <span>Tambah Jadwal</span>
                        </IconButton>
                    )}
                </div>
            </div>
            <div>
                <div className={Styles.ItemBoxSetupPushNotification}>
                    <CustomTable
                        bodyData={rowSchedule}
                        tableHead={tableHead}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={limit}
                        setRowsPerPage={setLimit}
                        totalPage={totalPage}
                        customEmpty={() => {
                            return (
                                <div className={Styles.CenterEmptySetupPushNotification}>
                                    <div className={Styles.EmptySetupPushNotification}>
                                        <img
                                            src={NOTIFICATION_IMG}
                                            alt="notif-img"
                                            style={{ height: '70px', width: '100px' }}
                                        />
                                        <br />
                                        <span>Belum ada jadwal notifikasi ditambahkan</span>
                                        <IconButton
                                            aria-label="add-notif"
                                            color="primary"
                                            sx={{
                                                gap: '10px',
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                            }}
                                            disableRipple={true}
                                            onClick={() => handleItemSchedule('ADD')}>
                                            <ControlPoint fontSize="small" />
                                            <span>Tambah Jadwal</span>
                                        </IconButton>
                                    </div>
                                </div>
                            );
                        }}
                    />
                </div>
            </div>
            {error && <div className={Styles.ErrorText}>{error}</div>}

            <div className={Styles.ButtonLayout}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        history.push('/admin-business/notification');
                    }}>
                    Batal
                </Button>
                <Button variant="contained" onClick={handleSubmit}>
                    Simpan
                </Button>
            </div>

            {modalPreview && <ModalFeaturePreview type={modalPreview} setModal={setModalPreview} />}
        </div>
    );
}
