/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles, StylesContext } from "@mui/styles";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import ImageOffice from "../../../../../assets/images/Office.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
// import InputText from "../../../utils/Input/Input";
import { useDispatch, useSelector } from "react-redux";
// import { updateClientWithSnackbar } from "../../../../redux/actions/SuperAdmin/adminActions";
import { useHistory, useParams } from "react-router";
import InputText from "../../../../utils/Input/Input";
// moment.locale("en-gb");

const useStyles = makeStyles((theme) => ({
  ItemModalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& > span": {
      color: "#333333",
      fontSize: 16,
      fontWeight: 600,
    },
  },
  Content: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  ProfileInput: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    "& > span": {
      fontSize: 13,
      color: "#0A0A0A",
      fontWeight: 500,
    },
    "& > textarea": {
      width: "100%",
      height: 40,
      background: "#fbfbfb",
      border: "1.5px solid #d3c9c9",
      borderRadius: 8,
      paddingLeft: 10,
      paddingTop: 10,
      resize: "vertical",
    },
    "& > ::placeholder": {
      color: "#616161",
      fontStyle: "italic",
    },
  },
  Button: {
    display: "flex",
    alignSelf: "flex-end",
    gap: 10,
    "& > button:nth-of-type(1)": {
      outline: "none",
      background: "#FFFFFF",
      border: "1px solid #333333",
      borderRadius: 5,
      color: "#333333",
      width: 95,
      height: 34,
      cursor: "pointer",
    },
    "& > button:nth-of-type(2)": {
      outline: "none",
      background: "#1571DE",
      borderRadius: 5,
      color: "#FFFFFF",
      width: 81,
      height: 34,
      cursor: "pointer",
    },
  },
}));

export default function AddSettingsModal({
  addSchema,
  setAddSchema,
  pathName,
  client,
  location
}) {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const theme = useTheme();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [values, setValues] = useState("");

  //   useEffect(() => {
  //     setValues(client);
  //   }, [client]);

  const handleClose = () => {
    setAddSchema(false);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push({
      pathname: `/superadmin/add-clients-id-workspace-addScheme/${client._id}`,
      state: values,
    });

    // const data = {
    //   id: client._id,
    //   ...values,
    // };
    // console.log(data);
    // dispatch(updateClientWithSnackbar(data, token))
    // history.push("/superadmin/client");
  };

  // console.log(values)
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Boolean(addSchema)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className={classes.ItemModalTop}>
            <span>Add New Schema</span>
          </div>
        </DialogTitle>

        <DialogContent>
          <div className={classes.Content}>
            <div className={classes.ProfileInput}>
              <span>Schema Setting Name</span>
              <InputText
                required
                value={values}
                onChange={(e) => setValues(e.target.value)}
                placeholder="Fill schema setting"
                type="text"
              />
            </div>
            <div className={classes.Button}>
              <button onClick={handleClose}>Cancel</button>
              <button onClick={handleSubmit}>Save</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
