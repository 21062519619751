import * as types from '../../type'

const initialstate = {
  schemaWorkspaces: [],
  isLoading: false,
  errorMessage: {},
  schemaWorkspaceById: {},
  schemaSettings: [],
  schemaSettingById : {}
};

const schemaWorkspacesReducer = (state = initialstate, action) => {
    switch (action.type) {
      case types.FETCH_ALL_WORKSPACE_SCHEMA_BEGIN: {
        return {
          ...state,
          isLoading: true
        };
      }
      case types.FETCH_ALL_WORKSPACE_SCHEMA_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          schemaWorkspaces: action.payload,
        };
      }
      case types.FETCH_ALL_WORKSPACE_SCHEMA_ERROR: {
        return {
          ...state,
          isLoading: false,
          errorMessage: action.payload
        };
      }
      case types.FETCH_SCHEMA_ATTENDANCE_BY_ID: {
        return {
          ...state,
          schemaWorkspaceById: action.payload
        //   schemaAttendance: {
        //     ...state.schemaAttendance,
        //     [action.payload._id]: action.payload,
        //   },
        };
      }
      case types.FETCH_ALL_SETTING_SCHEMA_BEGIN: {
        return {
          ...state,
          isLoading: true
        };
      }
      case types.FETCH_ALL_SETTING_SCHEMA_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          schemaSettings: action.payload
        };
      }
      case types.FETCH_ALL_SETTING_SCHEMA_ERROR: {
        return {
          ...state,
          isLoading: false,
          errorMessage: action.payload
        };
      }
      case types.GET_SETTING_SCHEMA_BY_ID: {
        return {
          ...state,
          schemaSettingById: action.payload
        //   schemaAttendance: {
        //     ...state.schemaAttendance,
        //     [action.payload._id]: action.payload,
        //   },
        };
      }
      // case "DELETE_ATTENDANCE": {
      //   return {
      //     ...state,
      //     schemaAttendance: action.payload,
      //   };
      // }
      // case "UPDATE_SCHEMA_ATTENDANCE_BY_ID": {
      //   return {
      //     ...state,
      //     schemaAttendance: action.payload,
      //   };
      // }
      default:
        return state;
    }
  };
  
  export default schemaWorkspacesReducer;