import React, { useEffect, useState } from "react";
import Styles from "./AttendanceSA.module.css";
import { Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppSetting } from "../../../components/SuperAdmin/_attendance/";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAttendances } from "../../../redux/actions/SuperAdmin/attendancesActions";
import UnderMaintenance from "../../../components/_underMaintenancePage";

export default function AttendanceSA() {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const { schemaAttendances, loadingDataAttendances } = useSelector((state) => state.attendances);
  // eslint-disable-next-line no-unused-vars
  const [isMaintenance, setIsMaintenance] = useState(false);


  useEffect(() => {
    dispatch(fetchAllAttendances(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Attendance</title>
      </Helmet>
      <div className={Styles.PageWrapper}>
        <h2 className={Styles.PageHeader}>
          Attendance
        </h2>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          {isMaintenance ? <UnderMaintenance /> : <AppSetting attendances={schemaAttendances} loading={loadingDataAttendances} />
          }
        </Grid>
      </Grid>
    </Container>
  );
}
