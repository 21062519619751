import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import userReducer from "./reducers/SuperAdmin/userReducer";
import schemaAttendancesReducer from "./reducers/SuperAdmin/attendancesReducer";
import snackbarReducer from "./reducers/SuperAdmin/snackbarReducer";
import clientReducer from "./reducers/SuperAdmin/clientReducer";
import faqsReducer from "./reducers/SuperAdmin/faqsReducer";
import draftsReducer from "./reducers/SuperAdmin/draftReducer";
import dataEmployeeInternalReducer from "./reducers/SuperAdmin/dataEmployeeInternal.Reducer";
import employeesReducer from "./reducers/SuperAdmin/employeesReducers";
import slipPaymentReducer from "./reducers/SuperAdmin/slipPaymentReducer";
import loanReducer from "./reducers/SuperAdmin/loanReducer";
import informationReducer from "./reducers/SuperAdmin/informationReducer";
import bpjsReducer from "./reducers/SuperAdmin/bpjsReducer";
import dataEmployeeExternalReducer from "./reducers/External/dataEmployeeExternalReducer";
import bpjsExternalReducer from "./reducers/External/bpjsExternalReducer";
import employeesExternalReducer from "./reducers/External/employeesExternalReducer";
import schemaWorkspacesReducer from "./reducers/SuperAdmin/workspaceReducer";
import locationsReducer from "./reducers/SuperAdmin/locationsReducer";
import bankReducer from "./reducers/SuperAdmin/bankReducer";
import contractReducer from "./reducers/SuperAdmin/contractReducer";
import activityReducer from "./reducers/SuperAdmin/activityReducer";
import locationsExternalReducer from "./reducers/External/locationsExternalReducer";
import userAdminReducer from "./reducers/SuperAdmin/userAdminReducer";
import inductionReducer from "./reducers/SuperAdmin/inductionsReducer";
import workOnExternalReducer from "./reducers/External/workOnExternalReducer";
import clientExternalReducer from "./reducers/External/clientExternalReducer";
import ebpBusinessReducer from "./reducers/Business/ebpBusinessReducer";

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  user: userReducer,
  userAdmin: userAdminReducer,
  client: clientReducer,
  attendances: schemaAttendancesReducer,
  faqs: faqsReducer,
  drafts: draftsReducer,
  employeeInternal: dataEmployeeInternalReducer,
  employees: employeesReducer,
  snackbar: snackbarReducer,
  payslip: slipPaymentReducer,
  loan: loanReducer,
  informations: informationReducer,
  bpjs: bpjsReducer,
  workspaces: schemaWorkspacesReducer,
  locations: locationsReducer,
  banks: bankReducer,
  contracts: contractReducer,
  activity : activityReducer,
  inductions : inductionReducer,

  // external
  employeeExternal: dataEmployeeExternalReducer,
  bpjsExternal: bpjsExternalReducer,
  employeesExternal: employeesExternalReducer,
  locationsExternal: locationsExternalReducer,
  workOnExternal: workOnExternalReducer,
  clientExternal: clientExternalReducer,
  //   UI: UIReducer,

  // business
  ebpBusiness: ebpBusinessReducer
});

const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
