import React, { useState } from "react";
import "./AddStore.css";
import MapWithAutoComplete from "../../../_mapWithAutoComplete/MapWithAutoComplete/MapWithAutoComplete";
import InputText from "../../../utils/Input/Input";
// import FileBase from "react-file-base64";
import { useDropzone } from "react-dropzone";
import { Button } from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import { useHistory } from "react-router";
// import UploadImageDraggable from "../../../utils/UploadImageDraggable/UploadImageDraggable";

export default function AddStore() {
  const [files, setFiles] = useState([]);
  const history = useHistory();
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const images = files.map((file) => (
    <div key={files.name}>
      <div>
        <img
          src={file.preview}
          style={{ width: 300, height: 170 }}
          alt="preview"
        />
      </div>
    </div>
  ));
  return (
    <div className="add__store-container">
      <div className="add__store-map">
        <MapWithAutoComplete />
      </div>
      <div className="add__store-desc">
        <div className="add__store-wrapper-text">
          <div className="add__store-text-input">
            <label style={{ paddingBottom: 8}}>Coordinate Point</label>
            <InputText name="Coordinate Point" />
          </div>
          <div className="add__store-text-input">
            <label style={{ paddingBottom: 8}}>Store Name</label>
            <InputText name="Store Name" />
          </div>
        </div>
        <div className="add__store-wrapper-text" style={{ paddingTop: 20 }}>
          <div className="add__store-text-input">
            <label style={{ paddingBottom: 8}}>Owner</label>
            <InputText name="Owner" />
          </div>
          <div className="add__store-text-input">
            <label style={{ paddingBottom: 8}}>Phone Number</label>
            <InputText name="Phone Number" />
          </div>
        </div>
        <div className="add__store-container-map">
          {/* <UploadImageDraggable /> */}
          {images.length ? (
            <div {...getRootProps()}>
              {images}
              <input type="text" {...getInputProps()} />
            </div>
          ) : (
            <div className="container__upload-image">
              <div className="wrapper__upload-image" {...getRootProps()}>
                <input type="text" {...getInputProps()} />
                <div className="box__input-image">
                  <span>Upload Image Here</span>
                  <div className="box__input-file">
                    <span>File must JPG or PNG</span>
                  </div>
                  <div className="box__input-or">
                    <span> OR </span>
                  </div>
                  <div className="container__input-image">{images}</div>
                  <div className="box__input-button">
                    <Button>
                      <span>Browse File</span>
                      <PublishIcon />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="add__store-button">
          <Button className="add__store-btn-save" style={{ background: '#1571DE', color: '#FFFFFF', fontSize: 14, fontWeight: 500}}>Save</Button>
          <Button className="add__store-btn-cancel" style={{ border: '1px solid #1571DE', color: '#1571DE',fontSize: 14, fontWeight: 500}} onClick={() => history.push('/admin/store-external')}>Cancel</Button>
        </div>
      </div>
    </div>
  );
}
