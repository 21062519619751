import React from "react";
import permata_image from "../../../../assets/images/logo-permata.png";
// import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Avatar } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelIcon from "@mui/icons-material/Cancel";
// import moment from "moment";

export default function LoanDetailsExternal(props) {
  const { dataLoan } = props;
  // const loanViewTab = (url) => {
  //   const newWindow = window.open(
  //     `/data-loan-viewer-external/123123123`,
  //     "_blank",
  //     "noopener,noreferrer"
  //   );
  //   if (newWindow) newWindow.opener = null;
  // };


  function numberWithPoint(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return (
    <div className="loan__details-container">
      <div className="loan__details-desc">
        <div className="loan__details-profile">
          <div className="details__profile-desc">
            <Avatar
              sx={{ width: 70, height: 70 }}
              src={dataLoan?.profilePicture ? dataLoan.profilePicture : ""}
            />
            <div className="details__data">
              <label>{dataLoan?.employeeName}</label>
              <span>{dataLoan?.company}</span>
            </div>
          </div>
          <div className="details__status">
            <div
              className="loan__box-status"
              style={{
                backgroundColor:
                  (dataLoan?.status === "ACTIVE" && "#498E0F") ||
                  (dataLoan?.status === "FINISHED" && "#1571DE") ||
                  (dataLoan?.status === "FAILED" && "#F02C01") ||
                  (dataLoan?.status === "PENDING" && "#C1992F"),
              }}
            >
              <span>
                {(dataLoan?.status === "ACTIVE" && "Active") ||
                  (dataLoan?.status === "FINISHED" && "Finished") ||
                  (dataLoan?.status === "FAILED" && "Reject") ||
                  (dataLoan?.status === "PENDING" && "Pending")}
              </span>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: 24 }}>
          <hr />
        </div>
        <div className="loan_details-desc-box">
          <div className="label__loan-text">
            <span>Loan Information</span>
          </div>
          <div className="details__loan-box">
            <div className="details__loan-info">
              <div className="details__loan-text">
                <label htmlFor="">Employee Benefit Program</label>
                <span>Salary Advance</span>
                <span>
                  {dataLoan?.region} - {dataLoan?.company}
                </span>
              </div>
              <div className="details__loan-img">
                <img src={permata_image} alt="" />
              </div>
            </div>
            <div style={{ paddingTop: 24, width: "100%" }}>
              <hr />
            </div>
            <div className="details__submission">
              <div className="wrapper__date-submission">
                <div className="date__submission">
                  <label>Tangal Pengajuan</label>
                  <span>{dataLoan?.applyDate}</span>
                </div>
                <div className="date__submission">
                  <label>Jumlah Pengajuan</label>
                  <span>
                    Rp{" "}
                    {dataLoan?.amount
                      ? numberWithPoint(dataLoan?.amount?.installmentTotal)
                      : "-"}
                  </span>
                </div>
              </div>
              <div
                className="wrapper__date-submission"
                style={{ paddingTop: 24 }}
              >
                <div className="date__submission">
                  <label>Angsuran Bulanan</label>
                  <span>
                    Rp{" "}
                    {dataLoan?.amount
                      ? numberWithPoint(dataLoan.amount?.installment)
                      : "-"}
                  </span>
                </div>
                <div className="date__submission">
                  <label>Biaya Admin dan Transfer</label>
                  <span>
                    Rp{" "}
                    {dataLoan?.amount?.adminFee
                      ? numberWithPoint(dataLoan?.amount?.adminFee?.total)
                      : "-"}
                  </span>
                </div>
              </div>
              {/* <div
                className="wrapper__date-submission"
                style={{ paddingTop: 24 }}
              >
                <div className="date__submission">
                  <label>Biaya</label>
                  <span>
                    Rp{" "}
                    {dataLoan?.LoanAmount?.AdminFee
                      ? numberWithPoint(dataLoan.LoanAmount?.AdminFee)
                      : "-"}
                  </span>
                </div>
                <div className="date__submission">
                  <label>Jangka Waktu</label>
                  <span>
                    {dataLoan?.LoanAmount?.Tenor
                      ? dataLoan.LoanAmount?.Tenor
                      : "-"}{" "}
                    Bulan
                  </span>
                </div>
              </div> */}
              <div
                className="wrapper__date-submission"
                style={{ paddingTop: 24 }}
              >
                <div className="date__submission">
                  <label>Tenor</label>
                  <span>{dataLoan?.tenor} Bulan</span>
                </div>
                <div className="date__submission">
                  <label>Jangka Waktu</label>
                  <span>{dataLoan?.tenor} Bulan</span>
                </div>
              </div>
              <div
                className="wrapper__date-submission"
                style={{ paddingTop: 24 }}
              >
                {dataLoan?.status === "ACTIVE" ||
                  (dataLoan?.status === "FINISHED" && (
                    <>
                      <div className="date__submission">
                        <label>Tanggal Dicairkan</label>
                        <span>{dataLoan?.applyDate}</span>
                      </div>
                      <div
                        className="date__submission"
                        style={{ display: "none" }}
                      >
                        <span></span>
                      </div>
                    </>
                  ))}
              </div>
              {/* <div
                className="wrapper__date-submission"
                style={{ paddingTop: 24 }}
              >
                <div className="date__submission">
                  <label>Nominal Diterima</label>
                  <span>
                    Rp{" "}
                    {dataLoan?.LoanAmount?.AcceptedNominal
                      ? numberWithPoint(dataLoan.LoanAmount?.AcceptedNominal)
                      : "-"}
                  </span>
                </div>
                <div className="date__submission" style={{ display: "none" }}>
                  <span>cek</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="details__history">
          <div className="details__history-text label__loan-text">
            <span>Payment History</span>
          </div>
          <div className="items__detail-history">
            {dataLoan?.installments ? (
              dataLoan?.installments?.map((each, idx) => (
                <div className="item__payment-history" key={idx}>
                  <div className="item__payment-wrapper">
                    <label>{each?.dueDate}</label>
                    <span>Rp.{each?.total.toLocaleString("id")}</span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 6,
                      }}
                    >
                      {each?.paidDate === "Invalid date" ? (
                        <>
                          <CancelIcon style={{ color: "#D1421A" }} />
                          <span>Belum Dibayar</span>
                        </>
                      ) : (
                        <>
                          <CheckCircleRoundedIcon
                            style={{ color: "#255BFC" }}
                          />
                          <span>Sudah Dibayar</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <span>There's no data payment yet</span>
            )}
          </div>
          {/* <div className="button-helper-loan-data-details">
            <Button
              onClick={loanViewTab}
              endIcon={<DownloadIcon />}
              style={{ backgroundColor: "#1571DE" }}
            >
              Download
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
}
