import React from "react";
import { Button } from "@mui/material";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ExportCSV = ({
  data,
  filename,
  initial,
  endIcon,
  startIcon,
  className,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  // const pdfExtension = '.pdf'

  const exportToCSV = async(csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = await XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        textTransform: "none",
        color: "#404040",
        fontSize: 14,
        fontWeight: 400,
      }}
      // style={{ textTransform: 'none', backgroundColor: '#1571de' , color: 'white' , width: 142, height: 40, borderRadius: 4}}
      onClick={(e) => exportToCSV(data, filename)}
      endIcon={endIcon}
      startIcon={startIcon}
      className={className}
    >
      {initial}
    </Button>
  );
};

export default ExportCSV;
