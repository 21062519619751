/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./ClientEditMenu.css";
import { useHistory, useLocation } from "react-router";
import { useDropzone } from "react-dropzone";
import PublishIcon from "@mui/icons-material/Publish";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateClientWithSnackbar } from "../../../../redux/actions/SuperAdmin/adminActions";
import InputText from "../../../utils/Input/Input";

export default function ClientEditMenu({ client = {} }) {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const location = useLocation();
  const clientData = location.state;
  const [clientName, setClientName] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientContact, setClientContact] = useState("");
  const [clientGroup, setClientGroup] = useState("");

  useEffect(() => {
    setClientName(client.Name);
    setClientAddress(client.Address);
    setClientContact(client.PhoneNumber);
    setClientGroup(client.Group);
  }, [client]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const images = files.map((file) => (
    <div key={files.name} className="wrapper-upload-done">
      <div>
        <img src={file.preview} className="image-upload-done" alt="preview" />
      </div>
    </div>
  ));

  const routeChangeCancel = () => {
    let path = "/superadmin/client";
    history.push(path);
  };

  const onSubmit = () => {
    const data = {
      id: client._id,
      Name: clientName,
      Address: clientAddress,
      Contact: clientContact,
      Group: clientGroup,
      // JoinDate: joinDate,
    };
    dispatch(updateClientWithSnackbar(data, token));
    history.push("/superadmin/client");
  };
  return (
    <div className="container-client-edit-menu">
      <form className="wrapper-add-menu-client">
        <div className="items-row-edit-menu-client">
          <div className="item-row-edit-menu">
            <span>Client Name</span>
            <InputText
              type="text"
              value={clientName || ""}
              onChange={(event) => setClientName(event.target.value)}
              // style={{ maxWidth: 400 }}
            />
          </div>
          <div className="item-row-edit-menu">
            <span>Group</span>
            <InputText
              type="text"
              value={clientGroup || ""}
              onChange={(event) => setClientGroup(event.target.value)}
              // style={{ maxWidth: 400 }}

            />
          </div>
        </div>
        <div className="items-row-edit-menu-client" style={{ paddingTop: 20 }}>
          <div className="item-row-edit-menu">
            <span>Address</span>
            <InputText
              type="text"
              value={clientAddress || ""}
              onChange={(event) => setClientAddress(event.target.value)}
              // style={{ maxWidth: 400 }}
            />
          </div>

          <div className="item-row-edit-menu">
            <span>Contact</span>
            <InputText
              type="text"
              value={clientContact || ""}
              onChange={(event) => setClientContact(event.target.value)}
              // style={{ maxWidth: 400 }}
            />
          </div>
        </div>
        {/* <div className="item-row-add-menu-item">
          <div className="container-row-add-menu-item">
            <span>Client Name</span>
            <InputText
              type="text"
              value={clientName || ""}
              onChange={(event) => setClientName(event.target.value)}
            />
          </div>
          <div className="container-row-add-menu-item">
            <span>Group</span>
            <InputText
              type="text"
              value={clientGroup || ""}
              onChange={(event) => setClientGroup(event.target.value)}
            />
          </div>
        </div> */}
        {/* <div className="item-row-add-menu-item"
            style={{
            paddingTop: 24,
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <div className="container-row-add-menu-item">
            <span>Address</span>
            <input
              type="text"
              value={clientAddress || ""}
              onChange={(e) => setClientAddress(e.target.value)}
            />
          </div>
          <div className="container-row-add-menu-item">
            <span>Join Date</span>
            <input
              type="date"
            />
            <span>Contact</span>
            <input
              type="text"
              style={{ width: 240 }}
              value={clientContact || ""}
              onChange={(e) => setClientContact(e.target.value)}
            />
          </div>
        </div> */}
        {images.length ? (
          <div
            {...getRootProps()}
            style={{ paddingTop: 40, paddingBottom: 20 }}
          >
            {images}
            <input type="text" {...getInputProps()} />
          </div>
        ) : (
          <div className="container__upload-image-client-menu">
            <div
              className="wrapper__upload-image-client-menu"
              {...getRootProps()}
            >
              <input type="text" {...getInputProps()} />
              <div className="box__input-image-client-menu">
                <span>Upload Image Here</span>
                <div className="box__input-file-client-menu">
                  <span>File must JPG or PNG</span>
                </div>
                <div className="box__input-or-client-menu">
                  <span> OR </span>
                </div>
                <div className="container__input-image-client-menu">
                  {images}
                </div>
                <div className="box__input-button-client-menu">
                  <Button>
                    <span>Browse File</span>
                    <PublishIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="button-item-add-menu-item-client-menu">
          <Button
            className="button__bottom-main-client-menu"
            style={{ backgroundColor: "  #1571de" }}
            onClick={onSubmit}
          >
            <span>Update</span>
          </Button>
          <Button
            className="button__cancel-main-client-menu"
            style={{ border: "1px solid #1571de", textTransform: "none" }}
            onClick={routeChangeCancel}
          >
            <span>Cancel</span>
          </Button>
        </div>
      </form>
    </div>
  );
}
