import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import MONEY_ICON from "../../../../../assets/images/money-primary.png";
import { setLoanScheme } from "../../../../../redux/actions/SuperAdmin/adminActions";
import LoadingAnimation from "../../../../utils/LoadingAnimation/LoadingAnimation";
import SavedSetModal from "../../ClientProfileMenu/SavedSetModal/SavedSetModal";
import Styles from './SetLoan.module.css';



export default function SetLoan({ loanSchemes, loading, client }) {
    const token = localStorage.getItem("accessToken");
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const [savedSet, setSavedSet] = useState(false);
    const [selectedScheme, setSelectedScheme] = useState("");

    const defaultSchemeId = useMemo(() => {
        return client?.feature?.staffLoanPlanId
    }, [client])


    useEffect(() => {
        setSelectedScheme(defaultSchemeId)
    }, [defaultSchemeId])


    const handleSave = (e) => {
        e.preventDefault()
        dispatch(setLoanScheme(token, id, {
            staffLoanPlanId: selectedScheme,
        }))
        setSavedSet(true)
        console.log(selectedScheme)
    }

    const handleCancel = (e) => {
        e.preventDefault()
        history.push("/superadmin/profile-clients/" + id)
    }


    return (
        <div className={Styles.Container}>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 50,
                    }}
                >
                    <div style={{ width: 200 }}>
                        <LoadingAnimation />
                    </div>
                </div>
            ) : (
                <>
                    <div className={Styles.CardContainer}>
                        {
                            loanSchemes.map((each, i) => (
                                <div key={i} className={Styles.Card}>
                                    <div className={Styles.CardHeader}>
                                        <div className={Styles.CardLogo}>
                                            <img src={MONEY_ICON} alt="loan" />
                                        </div>
                                        <span>{each?.name}</span>
                                    </div>
                                    <div className={Styles.CardContent}>
                                        <span>{each?.remark}</span>
                                        {/* <input
                                        type="radio"
                                        checked={each?._id === selectedScheme}
                                        value={each?._id}
                                        onChange={(e) => setSelectedScheme(e.target.value)}
                                    /> */}
                                        {
                                            selectedScheme === each?._id ? (
                                                <RadioButtonChecked sx={{ color: '#1571DE', cursor:'pointer' }} />
                                            ) : (
                                                <RadioButtonUnchecked onClick={() => setSelectedScheme(each?._id)} sx={{ color: '#c2c2c2', cursor:'pointer' }} />
                                            )
                                        }
                                    </div>
                                    <div className={Styles.CardFooter}>
                                        <span
                                            onClick={() => {
                                                // dispatch(fetchSchemaLoanById(token, loan._id));
                                                history.push({
                                                    pathname: `/superadmin/client-set/${client?._id}/loan-view/${each._id}`,
                                                    state: {
                                                        dataLoan: each,
                                                    },
                                                });
                                            }}
                                        >
                                            View Settings
                                        </span>
                                        <ArrowRightAltIcon
                                            style={{ color: "#1571DE", marginLeft: 8 }}
                                        />
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className={Styles.ButtonWrapper}>
                        <button onClick={handleSave} className={Styles.SaveButton}>Save</button>
                        <button onClick={handleCancel} className={Styles.CancelButton}>Cancel</button>
                    </div>

                </>

            )}

            {savedSet && (
                <SavedSetModal setSavedSet={setSavedSet} id={id} />
            )}
        </div>
    );
}
