import React from "react";
import "./ProductBestSeller.css";

import coca_cola_icon from "../../../../assets/images/coca-cola.png";
const data = [
  { name: "Coca Cola", image: coca_cola_icon, sales: 1000 },
  { name: "Coca Cola", image: coca_cola_icon, sales: 1000 },
  { name: "Coca Cola", image: coca_cola_icon, sales: 1000 },
];
const ProductBestSeller = () => {
  return (
    <div className="ContainerProductBestSeller">
      <div className="WrapperProductBestSeller">
        <div className="HeaderProductBestSeller">
          <span>Product Best Seller</span>
        </div>
        <div className="ContentProductBestSeller">
          {data.map((el, idx) => (
            <div className="WrapperContentProductBestSeller" key={idx}>
              <div className="ImageProductBestSeller">
                <img src={el.image} alt="" />
              </div>
              <div className="ContentTextProductBestSeller">
                <span>{el.name}</span>
                <span>{el.sales} Product Sales</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductBestSeller;
