/* eslint-disable no-unused-vars*/

import {
  Autocomplete,
  Avatar,
  Button,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import fetchClientCode from "../../../../async/client/fetchClientCode";
import ExportDataExcel from "../../../SuperAdmin/_attendance/tabs/DataAttendance/ExportDataExcel";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import CustomTablePagination from "../../../_pagination/CustomTablePagination";
import fetchPawnsMIS from "../../../../async/employeeeBenefit/fetchPawnsMIS";
import Styles from "./PawnTable.module.css";
import DateRangePickerComponent from "../../../utils/DateRangePicker/DateRangePicker";
import fetchPawns from "../../../../async/employeeeBenefit/fetchPawns";

// const datas = [
//   {
//     employeeName: "Jane Cooper",
//     employeeId: 1,
//     company: "PT. Arla",
//     address: "Mayestik",
//     placeOfBirth: "12 Januarty 2022",
//     uniqueId: "12345678",
//     product: "GADAI",
//     productType: "MOTOR",
//     productColor: "Merah",
//     productYear: "2022",
//     scheme: "Scheme 1",
//     remark: "Gadai",
//     brand: "Yamaha",
//     clientCode: "PIS",
//     status: "PENDING",
//     applyDate: "21 Desember 2021",
//     region: "Jakarta Region",
//   },
//   {
//     employeeName: "John Doe",
//     employeeId: 2,
//     scheme: "Scheme 1",
//     company: "PT. Arla",
//     address: "Mayestik",
//     placeOfBirth: "12 January 2022",
//     uniqueId: "12345678",
//     product: "GADAI",
//     productType: "MOTOR",
//     productColor: "Merah",
//     productYear: "2022",
//     remark: "Gadai",
//     brand: "Yamaha",
//     clientCode: "PIS",
//     status: "FINISHED",
//     applyDate: "21 Desember 2021",
//     region: "Jakarta Region",
//     installments: [
//       {
//         no: 1,
//         dueDate: "25 January 2023",
//         paidDate: "06 February 2023",
//         principalAmount: 716667,
//         rate: 92450,
//         total: 809117,
//       },
//       {
//         no: 2,
//         dueDate: "25 January 2023",
//         paidDate: "06 February 2023",
//         principalAmount: 716667,
//         rate: 92450,
//         total: 809117,
//       },
//       {
//         no: 3,
//         dueDate: "25 January 2023",
//         paidDate: "06 February 2023",
//         principalAmount: 716667,
//         rate: 92450,
//         total: 809117,
//       },
//       {
//         no: 4,
//         dueDate: "25 January 2023",
//         paidDate: "06 February 2023",
//         principalAmount: 716667,
//         rate: 92450,
//         total: 809117,
//       },
//     ],
//   },
//   {
//     employeeName: "Jane Cooper",
//     employeeId: 3,
//     scheme: "Scheme 1",

//     company: "PT. Arla",
//     address: "Mayestik",
//     placeOfBirth: "12 January 2022",
//     uniqueId: "12345678",
//     product: "GADAI",
//     productType: "MOTOR",
//     productColor: "Merah",
//     productYear: "2022",
//     remark: "Gadai",
//     brand: "Yamaha",
//     clientCode: "PIS",
//     status: "ACTIVE",
//     applyDate: "21 Desember 2021",
//     region: "Jakarta Region",
//     installments: [
//       {
//         no: 1,
//         dueDate: "25 January 2023",
//         paidDate: "06 February 2023",
//         principalAmount: 716667,
//         rate: 92450,
//         total: 809117,
//       },
//       {
//         no: 2,
//         dueDate: "25 January 2023",
//         paidDate: "06 February 2023",
//         principalAmount: 716667,
//         rate: 92450,
//         total: 809117,
//       },
//       {
//         no: 3,
//         dueDate: "24 Februari 2023",
//         paidDate: null,
//         principalAmount: 716667,
//         rate: 92450,
//         total: 809117,
//       },
//       {
//         no: 4,
//         dueDate: "24 March 2023",
//         paidDate: null,
//         principalAmount: 716667,
//         rate: 92450,
//         total: 809117,
//       },
//     ],
//   },
//   {
//     employeeName: "Jane Cooper",
//     employeeId: 4,
//     scheme: "Scheme 1",
//     company: "PT. Arla",
//     address: "Mayestik",
//     placeOfBirth: "12 January 2022",
//     uniqueId: "12345678",
//     product: "GADAI",
//     productType: "MOTOR",
//     productColor: "Merah",
//     productYear: "2022",
//     remark: "Gadai",
//     brand: "Yamaha",
//     clientCode: "PIS",
//     status: "FAILED",
//     applyDate: "21 Desember 2021",
//     region: "Jakarta Region",
//   },
//   {
//     employeeName: "Jane Cooper",
//     employeeId: 5,
//     scheme: "Scheme 1",
//     company: "PT. Arla",
//     address: "Mayestik",
//     placeOfBirth: "12 January 2022",
//     uniqueId: "12345678",
//     product: "GADAI",
//     productType: "MOTOR",
//     productColor: "Merah",
//     productYear: "2022",
//     remark: "Gadai",
//     brand: "Yamaha",
//     clientCode: "PIS",
//     status: "FAILED",
//     applyDate: "21 Desember 2021",
//     region: "Jakarta Region",
//     // applyDate: new Date(),
//     // createdAt: new Date(),
//   },
//   {
//     employeeName: "Jane Cooper",
//     employeeId: 6,
//     scheme: "Scheme 1",
//     company: "PT. Arla",
//     address: "Mayestik",
//     placeOfBirth: "12 January 2022",
//     uniqueId: "12345678",
//     product: "GADAI",
//     productType: "MOTOR",
//     productColor: "Merah",
//     productYear: "2022",
//     remark: "Gadai",
//     brand: "Yamaha",
//     clientCode: "PIS",
//     status: "FAILED",
//     applyDate: "21 Desember 2021",
//     region: "Jakarta Region",
//   },
//   {
//     employeeName: "Jane Cooper",
//     employeeId: 7,
//     scheme: "Scheme 1",
//     company: "PT. Arla",
//     address: "Mayestik",
//     placeOfBirth: "12 January 2022",
//     uniqueId: "12345678",
//     product: "GADAI",
//     productType: "MOTOR",
//     productColor: "Merah",
//     productYear: "2022",
//     remark: "Gadai",
//     brand: "Yamaha",
//     clientCode: "PIS",
//     status: "FAILED",
//     applyDate: "21 Desember 2021",
//     region: "Jakarta Region",
//   },
//   {
//     employeeName: "Jane Cooper",
//     employeeId: 8,
//     scheme: "Scheme 1",
//     company: "PT. Arla",
//     address: "Mayestik",
//     placeOfBirth: "12 January 2022",
//     uniqueId: "12345678",
//     product: "GADAI",
//     productType: "MOTOR",
//     productColor: "Merah",
//     productYear: "2022",
//     remark: "Gadai",
//     brand: "Yamaha",
//     clientCode: "PIS",
//     status: "FAILED",
//     applyDate: "21 Desember 2021",
//     region: "Jakarta Region",
//     // applyDate: new Date(),
//     // createdAt: new Date(),
//   },
//   {
//     employeeName: "Jane Cooper",
//     employeeId: 9,
//     scheme: "Scheme 1",
//     company: "PT. Arla",
//     address: "Mayestik",
//     placeOfBirth: "12 January 2022",
//     uniqueId: "12345678",
//     product: "GADAI",
//     productType: "MOTOR",
//     productColor: "Merah",
//     productYear: "2022",
//     remark: "Gadai",
//     brand: "Yamaha",
//     clientCode: "PIS",
//     status: "FAILED",
//     applyDate: "21 Desember 2021",
//     region: "Jakarta Region",
//   },
//   {
//     employeeName: "Jane Cooper",
//     employeeId: 10,
//     scheme: "Scheme 1",
//     company: "PT. Arla",
//     address: "Mayestik",
//     placeOfBirth: "12 January 2022",
//     uniqueId: "12345678",
//     product: "GADAI",
//     productType: "MOTOR",
//     productColor: "Merah",
//     productYear: "2022",
//     remark: "Gadai",
//     brand: "Yamaha",
//     clientCode: "PIS",
//     status: "FAILED",
//     applyDate: "21 Desember 2021",
//     region: "Jakarta Region",
//   },
// ];

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
  },
});

const TABLE_HEAD = [
  {
    id: "employeeName",
    numeric: false,
    label: "Employee Name",
    alignRight: false,
  },
  { id: "company", numeric: false, label: "Company", alignRight: false },
  { id: "applyDate", numeric: false, label: "Apply Date", alignRight: false },
  { id: "code", numeric: false, label: "Gadai Code", alignRight: false },
  { id: "productType", numeric: true, label: "Type", alignRight: false },
  { id: "status", numeric: false, label: "Status", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        <TableCell padding="none" style={{ paddingLeft: 0 }}>
          {/* <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              disabled={rowCount !== values?.users?.length}
              sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
            /> */}
        </TableCell>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function PawnTable(props) {
  // const { pawns, loading } = props;
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const clientName = localStorage.getItem("clientName");
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selectedInput, setSelectedInput] = useState("");
  const [selected, setSelected] = useState("");
  const [listGadai, setListGadai] = useState("");
  const [dataClientCode, setDataClientCode] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [type, setType] = useState("All");
  const [statusPending, setStatusPending] = useState(false);
  const [status, setStatus] = useState("");
  const [valueStartDate, setValueStartDate] = useState(moment().format("LL"));
  const [valueEndDate, setValueEndDate] = useState(moment().format("LL"));
  const [loading, setIsLoading] = useState(false);
  const [pawns, setPawns] = useState([]);

  const persistClientName = useMemo(() => {
    let persistClient = window.sessionStorage.getItem("selectedClientGadai");
    let clientName;

    if (persistClient) {
      clientName = persistClient;
    } else {
      clientName = selected;
    }

    return clientName;
  }, [selected]);

  const persistListMode = useMemo(() => {
    let persistMode = window.sessionStorage.getItem("persistListMode");
    let mode;
    if (persistMode) {
      mode = persistMode;
    } else {
      mode = type;
    }
    return mode;
  }, [type]);

  const dateValueStart = useMemo(() => {
    let sessionStartDate = window.sessionStorage.getItem("startDateGadai");
    let firstDate;

    if (sessionStartDate) {
      firstDate = sessionStartDate;
    } else {
      firstDate = valueStartDate;
    }

    return firstDate;
  }, [valueStartDate]);

  const dateValueEnd = useMemo(() => {
    let sessionEndDate = window.sessionStorage.getItem("endDateGadai");
    let secondDate;

    if (sessionEndDate) {
      secondDate = sessionEndDate;
    } else {
      secondDate = valueEndDate;
    }

    return secondDate;
  }, [valueEndDate]);

  let sortedPawns = pawns
    .sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    })
    .reverse();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        if (persistClientName) {
          const data = await fetchPawns(
            token,
            moment(dateValueStart).format("YYYY-MM-DD"),
            moment(dateValueEnd).format("YYYY-MM-DD"),
            persistClientName
          );
          setIsLoading(false);
          setPawns(data);
        } else {
          const data = await fetchPawnsMIS(
            token,
            moment(dateValueStart).format("YYYY-MM-DD"),
            moment(dateValueEnd).format("YYYY-MM-DD")
          );
          setIsLoading(false);
          setPawns(data);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    })();
  }, [dateValueStart, dateValueEnd, token, persistClientName, selected]);

  const handleListType = (e) => {
    setPage(1);
    if (e.target.value !== null) {
      window.sessionStorage.setItem("persistListMode", e.target.value);
      setType(e.target.value);
    } else {
      window.sessionStorage.setItem("persistListMode", "");
      setType("");
    }
  };

  useEffect(() => {
    setType(persistListMode);
  }, [persistListMode]);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCode(response?.map((el) => el?.name));
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearchInput = (event) => {
    setListGadai(event.target.value);
  };

  const handleSearch = (value) => {
    setSelectedInput(value);
  };

  const handleSelectedClient = (value) => {
    window.sessionStorage.setItem("selectedClientGadai", value);
    setSelected(value);
    // setArrayData(false);
    setListGadai("");
  };

  const handleChangeStartDate = (newValue) => {
    // setArrayData(true);
    setValueStartDate(newValue);
    window.sessionStorage.setItem("startDateGadai", newValue);
  };

  const handleChangeEndDate = (newValue) => {
    // setArrayData(true);
    setValueEndDate(newValue);
    window.sessionStorage.setItem("endDateGadai", newValue);
  };

  const handleListStatus = (event) => {
    setType(event.target.value);
  };

  const formatMoment = (date) => {
    return moment(new Date(date).getTime()).format("DD MMMM YYYY");
  };

  return (
    <div className={Styles.Container}>
      <div className={Styles.Header}>
        <div className={Styles.Selection}>
          <SearchbarSA
            searchWidth="379px"
            placeholder="Search by name"
            value={listGadai}
            onChange={handleSearchInput}
          />
          <DateRangePickerComponent
            valueStartDate={dateValueStart}
            valueEndDate={dateValueEnd}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
          />
          <Autocomplete
            disablePortal
            options={dataClientCode}
            value={persistClientName || ""}
            inputValue={selectedInput}
            onInputChange={(e, value) =>
              value === null ? handleSearch("") : handleSearch(value)
            }
            onChange={(e, value) =>
              value === null
                ? handleSelectedClient("")
                : handleSelectedClient(value)
            }
            sx={{ backgroundColor: "#FFFFFF" }}
            renderInput={(params) => (
              <MuiTextField
                {...params}
                size="small"
                sx={{ width: "250px" }}
                placeholder="All Company"
              />
            )}
          />
          <Select
            value={persistListMode}
            onChange={handleListType}
            className={Styles.Select}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Rejected">Rejected</MenuItem>
            <MenuItem value="Approved">Approved</MenuItem>
            <MenuItem value="Finished">Finished</MenuItem>
          </Select>
          <div className={Styles.Other}>
            <ExportDataExcel
              data={pawns?.map((el, ind) => ({
                "No.": ind + 1,
                "Employee Name": el.name,
                Company: el?.client,
                Type: el?.type,
                "Apply Date": el.applyDate
                  ? moment(el?.applyDate).format("L")
                  : null,
                Status:
                  (el?.status === "APPROVED" && "Active") ||
                  (el?.status === "FINISHED" && "Finished") ||
                  (el?.status === "REJECTED" && "Reject") ||
                  (el?.status === "PENDING" && "Pending"),
              }))}
              filename={`Pawn Report ${new Date().toLocaleDateString()}`}
              initial="Download Excel"
            />
          </div>
        </div>
      </div>

      {loading ? (
        <div className={Styles.Loading}>
          <div className={Styles.LoadingWidth}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={Styles.TableWrapper}>
          <Scrollbar>
            <TableContainer className={Styles.TableContainer}>
              <Table id="emp-table" className={Styles.Table}>
                {/* <colgroup>
                  <col style={{ width: "30%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                </colgroup> */}
                <EnhancedTableHead
                  numSelected={pawns?.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  // values={pawns}
                  rowCount={
                    pawns?.filter(
                      (el) =>
                        el?.name
                          ?.toLowerCase()
                          .includes(listGadai?.toLowerCase()) ||
                        el?.client
                          ?.toLowerCase()
                          .includes(listGadai?.toLowerCase()) ||
                        el?.status
                          ?.toLowerCase()
                          .includes(listGadai?.toLowerCase())
                    ).length
                  }
                />
                <TableBody>
                  {stableSort(sortedPawns, getComparator(order, orderBy))
                    .filter(
                      (el) =>
                        el.name
                          ?.toLowerCase()
                          .includes(listGadai.toLowerCase()) &&
                        (persistListMode !== "All"
                          ? el?.status.toLowerCase() ===
                            persistListMode.toLocaleLowerCase()
                          : true)
                    )
                    .slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    )
                    .map((data, ind) => {
                      return (
                        <TableRow hover key={ind} tabIndex={-1}>
                          <TableCell></TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            onClick={() => {
                              history.push({
                                pathname: `/admin-mws/pawn/${data?.code}`,
                                state: { data: data },
                              });
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Avatar
                                src={
                                  data?.profilePicture
                                    ? data?.profilePicture
                                    : ""
                                }
                              />
                              <Typography
                                variant="subtitle2"
                                noWrap
                                style={{ textTransform: "capitalize" }}
                              >
                                {data?.name.toLowerCase()}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#0A0A0A",
                              }}
                            >
                              {data?.client}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            {formatMoment(data?.applyDate)}
                          </TableCell>
                          <TableCell>{data?.code}</TableCell>
                          <TableCell
                            align="left"
                            sx={{ textTransform: "capitalize " }}
                          >
                            {data.product ? data?.product?.toLowerCase() : "-"}
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              className="status__styleSA"
                              style={{
                                fontSize: 14,
                                fontWeight: 500,
                                backgroundColor:
                                  (data.status === "APPROVED" && "#F4FFF2") ||
                                  (data.status === "FINISHED" && "#DEFEFF") ||
                                  (data.status === "REJECTED" && "#FFF4F2") ||
                                  (data.status === "PENDING" && "#FFF9E9"),
                                color:
                                  (data.status === "APPROVED" && "#498E0F") ||
                                  (data.status === "FINISHED" && "#1571DE") ||
                                  (data.status === "REJECTED" && "#D52701") ||
                                  (data.status === "PENDING" && "#C1992F"),
                                borderColor:
                                  (data.status === "APPROVED" && "#22970F") ||
                                  (data.status === "FINISHED" && "#1571DE") ||
                                  (data.status === "REJECTED" && "#D52701") ||
                                  (data.status === "PENDING" && "#C1992F"),
                              }}
                            >
                              {(data?.status === "APPROVED" && "Approved") ||
                                (data?.status === "FINISHED" && "Finished") ||
                                (data?.status === "REJECTED" && "Rejected") ||
                                (data?.status === "PENDING" && "Pending")}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {/* <Button
                              style={{
                                textTransform: "none",
                                fontSize: 16,
                                fontWeight: 500,
                              }}
                              onClick={() => {
                                history.push({
                                  pathname: `/superadmin/pawn-detail/${data.code}`,
                                  state: { data: data },
                                });
                              }}
                            >
                              See Detail
                            </Button> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <CustomTablePagination
            limit={rowsPerPage}
            setLimit={setRowsPerPage}
            page={page}
            setPage={setPage}
            totalPage={Math.ceil(
              pawns?.filter(
                (el) =>
                  el?.name?.toLowerCase().includes(listGadai.toLowerCase()) &&
                  (persistListMode !== "All"
                    ? el?.status.toLowerCase() === persistListMode.toLowerCase()
                    : true)
              ).length / rowsPerPage
            )}
          />
        </div>
      )}
    </div>
  );
}
