import React from "react";
import { Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
// eslint-disable-next-line no-unused-vars
// import { Link } from "react-router-dom";
// import { SettingsLoanTabs } from "../../../components/SuperAdmin/_loanData/LoanTabs";
// import DataLoan from "../../../components/SuperAdmin/_loanData/LoanTabs/DataLoan/DataLoan";
import { SettingsLoanTabs } from "../../../components/SuperAdmin/_loanData/LoanTabs";

export default function LoanData() {
  // const [dropdownSelected, setDropdownSelected] = React.useState("All");
  // const [dropdownSelectedStatus, setDropdownSelectedStatus] = React.useState("All");
  // const [dropdownListGroup, setDropdownListGroup] = React.useState("All");
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Employee Benefit Program</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          Employee Benefit Program
        </h2>
      </div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <SettingsLoanTabs />
        </Grid>
      </Grid>
    </Container>
  );
}
