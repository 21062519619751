/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { filter } from "lodash";
import { Link } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
  TextField,
  styled,
  Autocomplete,
  TableHead,
  TableSortLabel,
  Card,
} from "@mui/material";

// import USERLIST from "../../../SuperAdmin/_user/TableUsers/_mocks/user";
// import UserListHead from "../../_user/TableUsers/UserListHead";
// import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import { useCalendar, UseCompanyName } from "../../../utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPayslip, fetchAllPayslipByYear } from "../../../../redux/actions/SuperAdmin/slipPaymentActions";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import ExportCSVPayslip from "../../../utils/ButtonUpload";
import { makeStyles } from "@mui/styles";
import { DatePicker as YearPickerPayslipMenu } from "@mui/x-date-pickers/DatePicker";
// import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import ExportCSVPayslip from "../../../utils/ButtonUpload";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import UserListHead from "../../../SuperAdmin/_user/TableUsers/UserListHead";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";


const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
    },
  },
});

const TABLE_HEAD = [
  { id: "name", label: "Employee Name", alignRight: false },
  { id: "jobTitle", label: "Position", alignRight: false },
  { id: "client", label: "Company", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "" },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const YearPickerPayslip = ({ valueYearPicker, handleYearPicker }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        views={["year"]}
        value={valueYearPicker}
        onChange={handleYearPicker}
        renderInput={(params) => (
          <TextField {...params} helperText={null} size="small" />
        )}
      />
    </LocalizationProvider>
  );
};

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        <TableCell padding="none" style={{ paddingLeft: 0 }}>
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={rowCount !== values?.users?.length}
            sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
          /> */}
        </TableCell>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function PayslipExternalMenu() {
  // const classes = useStyles();
  const token = localStorage.getItem("accessToken");
  const clientName = localStorage.getItem('clientName');
  const dispatch = useDispatch();
  const history = useHistory();
  const { allPayslip, allPayslipByYear, loadingAllPayslip, errorMessagePayslip } = useSelector((state) => state.payslip);
  const payslipYear = moment().format("YYYY");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [selected, setSelected] = useState("PT. Permata Indo Sejahtera");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [yearPicker, setYearPicker] = useState(payslipYear);
  const [listDataPayslip, setListDataPayslip] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedInputMonth, setSelectedInputMonth] = useState("");
  const [selectedInputYear, setSelectedInputYear] = useState("");

  const optionsYear = ["2022", "2023"];
  const optionsMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const numberMonth = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const [monthPicker, setMonthPicker] = useState(moment( new Date()).format('MMMM'));
  // const [yearPickerPersist, setYearPickerPersist] = useState(optionsYear[1]);
  const indexMonth = optionsMonth.findIndex((el) => el === monthPicker);

  const persistYearPayslip = useMemo(() => {
    let persistYear = window.sessionStorage.getItem("persistYear");
    let selectedYear;

    if (persistYear) {
      selectedYear = persistYear;
    } else {
      selectedYear = yearPicker;
    }

    return selectedYear;
  }, [yearPicker]);

  const persistMonthPayslip = useMemo(() => {
    let persistMonth = window.sessionStorage.getItem("persistMonth");
    let selectedMonth;

    if (persistMonth) {
      selectedMonth = persistMonth;
    } else {
      selectedMonth = monthPicker;
    }

    return selectedMonth;
  }, [monthPicker]);

  useEffect(() => {
    if(clientName) {
      setMonthPicker(persistMonthPayslip)
      setYearPicker(persistYearPayslip)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[clientName])

  useEffect(() => {
      dispatch(
        fetchAllPayslip(
          token,
          persistYearPayslip,
          // yearPickerPersist,
          numberMonth[indexMonth],
          clientName
        )
      );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    dispatch,
    persistYearPayslip,
    persistMonthPayslip,
    indexMonth,
    // yearPickerPersist,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

//   const handleSelectAllClick = (event) => {
//     if (event.target.checked) {
//       const newSelecteds = USERLIST.map((n) => n.name);
//       setSelected(newSelecteds);
//       return;
//     }
//     setSelected([]);
//   };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setListDataPayslip(event.target.value);
  };
  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchTerm(listDataPayslip);
  };

  const handleSearchMonth = (value) => {
    setSelectedInputMonth(value);
  };

  const handleSearchYearPersist = (value) => {
    setSelectedInputYear(value);
  };

  const handleChangeYearPicker = (newValue) => {
    // setArrayData(false);
    setYearPicker(newValue);
    // setYearPickerPersist(newValue);
    window.sessionStorage.setItem("persistYear", newValue);
  };

  const handleChangeMonthPicker = (newValue) => {
    // setArrayData(false);
    setMonthPicker(newValue);
    window.sessionStorage.setItem("persistMonth", newValue);
  };

  const changeYearPicker = (newValue) => {
    setYearPicker(newValue);
  };
  const numberWithPoint = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const hanldeUploadPayslip = () => history.push("/superadmin/payslip-upload-menu");

  return (
    <div className="container-PMS">
      <div className="wrapper-PMS">
        <div className="wrapper-box-top-payslip">
          <div className="box-search-payslip-menu">
            <SearchbarSA
                value={listDataPayslip}
                onChange={handleSearch}
                onSubmit={handleSubmitFilter}
                searchWidth="379px"
                placeholder="Search by name"
            />
          </div>
          {/* <div className="button-download-payslip-menu">
            <Button
              onClick={hanldeUploadPayslip}
              startIcon={<FileUploadIcon />}
              style={{
                textTransform: "none",
                backgroundColor: "#1571de",
                color: "white",
                width: 142,
                height: 40,
                borderRadius: 4,
              }}
            >
              Upload Payslip
            </Button>
          </div> */}
        </div>
        <div className="box-filter-payslip-menu">
          <span className="text-box">Filter</span>
          <div className="wrapper-filter-show-another">
            <div className="box-group-selected">
              <Autocomplete
                disablePortal
                options={optionsMonth}
                // value={monthPicker}
                value={persistMonthPayslip}
                inputValue={selectedInputMonth}
                onInputChange={(e, value) =>
                  value === null
                    ? handleSearchMonth("")
                    : handleSearchMonth(value)
                }
                onChange={(e, value) => handleChangeMonthPicker(value)}
                renderInput={(params) => (
                  <MuiTextField {...params} size="small" sx={{ width: 200 }} />
                )}
              />
            </div>
            <div className="">
              <Autocomplete
                disablePortal
                options={optionsYear}
                value={persistYearPayslip}
                inputValue={selectedInputYear}
                onInputChange={(e, value) =>
                  value === null
                    ? handleSearchYearPersist("")
                    : handleSearchYearPersist(value)
                }
                onChange={(e, value) => handleChangeYearPicker(value)}
                renderInput={(params) => (
                  <MuiTextField {...params} size="small" sx={{ width: 200 }} />
                )}
              />
              {/* <FormControl>
                <YearPickerPayslip
                  valueYearPicker={persistYearPayslip}
                  handleYearPicker={handleChangeYearPicker}
                />
              </FormControl> */}
            </div>
            {/* <div className="box-group-selected">
              <Autocomplete
                disablePortal
                options={getCompany}
                value={selected}
                inputValue={selected}
                sx={{ width: 300 }}
                onInputChange={(e, value) => setSelected(value)}
                renderInput={(params) => (
                  <MuiTextField {...params} size="small" sx={{ width: 300 }} />
                )}
              />
            </div>
            <div className="">
              <FormControl>
                <YearPickerPayslip
                  valueYearPicker={yearPicker}
                  handleYearPicker={changeYearPicker}
                />
              </FormControl>
            </div> */}
            <div className="">
              {/* <ExportCSVPayslip
                data={allPayslip.map((el, ind) => ({
                  No: ind + 1,
                  "Tanggal Penggajian": `${
                    calendar.months.eng.full[
                      el?.PayrollMonth?.split(" ")[0] - 1
                    ]
                  } ${el?.PayrollMonth?.split(" ")[2]}`,
                  "Nama Client": el?.Client,
                  Tahun: el?.PayrollMonth?.split(" ")[2],
                  Bulan:
                    calendar.months.eng.full[
                      el?.PayrollMonth?.split(" ")[0] - 1
                    ],
                  NRK: el?.Nrk,
                  "Tanggal Join": el?.JoinDate,
                  Nama: el?.Name,
                  Posisi: el?.Position,
                  "Nomor NPWP": el?.NPWP,
                  "Basic Salary": el?.Income?.["Basic Salary"],
                  "Gaji Prorate": el?.Income?.["Gaji Prorate"],
                  "Tunjangan Hari Raya": el?.Income?.["Tunjangan Hari Raya"],
                  "Tunjangan Jabatan": el?.Income?.["Tunjangan Jabatan"],
                  Incentive: el?.Income?.Incentive,
                  "Honor/Komisi": el?.Income?.["Honor/Komisi"],
                  TTUM: el?.Income?.TTUM,
                  "Rapel Lainnya": el?.Income?.["Rapel Lainnya"],
                  Lembur: el?.Income?.Lembur,
                  Bonus: el?.Income?.Bonus,
                  "Tunjangan Pajak": el?.Income?.["Tunjangan Pajak"],
                  "Tunjangan HP": el?.Income?.["Tunjangan HP"],
                  "Tunjangan Grading": el?.Income?.["Tunjangan Grading"],
                  "Tunjangan Special": el?.Income?.["Tunjangan Special"],
                  "Tunjangan Keahlian": el?.Income?.["Tunjangan Keahlian"],
                  "Tunjangan Lain-lain": el?.Income?.["Tunjangan Lain-lain"],
                  "Tunjangan Operasi": el?.Income?.["Tunjangan Operasi"],
                  "Tunjangan Kehadiran": el?.Income?.["Tunjangan Kehadiran"],
                  "Transport Allowance": el?.Income?.["Transport Allowance"],
                  "Meal Allowance": el?.Income?.["Meal Allowance"],
                  "Other Allowance": el?.Income?.["Other Allowance"],
                  "Lolos OJT": el?.Income?.["Lolos OJT"],
                  "Uang Cuti": el?.Income?.["Uang Cuti"],
                  "Parkir Reimbursment": el?.Income?.["Parkir Reimbursment"],
                  Perdin: el?.Income?.Perdin,
                  "Bantuan Kesehatan": el?.Income?.["Bantuan Kesehatan"],
                  Asuransi: el?.Income?.Asuransi,
                  "Insentif Mixer & Rain Shower":
                    el?.Income?.["Insentif Mixer & Rain Shower"],
                  "Insentif Sale": el?.Income?.["Insentif Sale"],
                  "Rapel Bonus": el?.Income?.["Rapel Bonus"],
                  "Rapel BBM": el?.Income?.["Rape BBM"],
                  Pesangon: el?.Income?.Pesangon,
                  "Release Uang Seragam": el?.Income?.["Release Uang Seragam"],
                  "Pengembalian Deposit Pinjaman":
                    el?.Income?.["Pengaembalian Deposit Pinjaman"],
                  "Pengembalian BPJS 1%": el?.Income?.["Pengembalian BPJS 1%"],
                  "Kurang Bayar Bistrip": el?.Income?.["Kurang Bayar Bistrip"],
                  "Kurang Bayar Meal Allowance":
                    el?.Income?.["Kurang Bayar Meal Allowance"],
                  "BPJS Mandiri": el?.Income?.["BPJS Mandiri"],
                  "Kelebihan Potong": el?.Income?.["Kelebihan Potong"],
                  "Kegiatan Outing": el?.Income?.["Kegiatan Outing"],
                  "Pendapatan Lain-lain": el?.Income?.["Pendapatan Lain-lain"],
                  "Bonus Non Rutin": el?.Income?.["Bonus Non Rutin"],
                  "Insentif Non Rutin": el?.Income?.["Insentif Non Rutin"],
                  "Overtime Non Rutin": el?.Income?.["Overtime Non Rutin"],
                  "SPD Non Rutin": el?.Income?.["SPD Non Rutin"],
                  "Allowance Non Rutin": el?.Income?.["Allowance Non Rutin"],
                  "Rapel Kenaikan Gaji": el?.Income?.["Rapel Kenaikan Gaji"],
                  "Biaya Outing": el?.Income?.["Biaya Outing"],
                  "Rapel Gaji Bulan Lalu":
                    el?.Income?.["Rapel Gaji Bulan Lalu"],
                  "Kompensasi berakhirnya PKWT":
                    el?.Income?.["Kompensasi berakhirnya PKWT"],
                  Penalty: el?.Deduction?.Penalty,
                  Koreksi: el?.Deduction?.Penalty,
                  "Hold Uang Seragam": el?.Deduction?.["Hold Uang Seragam"],
                  "Potongan Keterlambatan":
                    el?.Deduction?.["Potongan Keterlambatan"],
                  "Potongan Deposit Pinjaman":
                    el?.Deduction?.["Potongan Deposit Pinjaman"],
                  "Potongan Pinjaman": el?.Deduction?.["Potongan Pinjaman"],
                  "Potongan BPJS Kesehatan 5%":
                    el?.Deduction?.["Potongan BPJS Kesehatan 5%"],
                  "Potongan Lain-Lain": el?.Deduction?.["Potongan Lain-lain"],
                  "Potongan Lain-Lain (non tax)":
                    el?.Deduction?.["Potongan Lain-lain"],
                  "Potongan Bistrip": el?.Deduction?.["Potongan Bistrip"],
                  "Potongan Kehadiran (Spotcheck)":
                    el?.Deduction?.["Potongan Kehadiran (Spotcheck)"],
                  "Potongan Laporan (Spotcheck)":
                    el?.Deduction?.["Potongan Laporan (Spotcheck)"],
                  "Kurang Potong": el?.Deduction?.["Kurang Potong"],
                  "Potongan Non 365": el?.Deduction?.["Potongan Non 365"],
                  "Biaya Admin Bank": el?.Deduction?.["Biaya Admin Bank"],
                  "BPJS JHT (3,7%)": el?.Deduction?.["BPJS JHT (3,7%)"],
                  "BPJS JKK (0,24%)": el?.Deduction?.["BPJS JKK (0,24%)"],
                  "BPJS JKM (0,3%)": el?.Deduction?.["BPJS JKM (0,3%)"],
                  "BPJS JP TKO": el?.Deduction?.["BPJS JP TKO"],
                  "BPJS Kesehatan TKO": el?.Deduction?.["BPJS Kesehatan TKO"],
                  "BPJS JHT (2%)": el?.Deduction?.["BPJS JHT (2%)"],
                  "BPJS JKK": el?.Deduction?.["BPJS JKK"],
                  "BPJS JKM": el?.Deduction?.["BPJS JKM"],
                  "BPJS JAMINAN PENSIUN (1%)":
                    el?.Deduction?.["BPJS JAMINAN PENSIUN (1%)"],
                  "BPJS 4%": el?.Deduction?.["4%"],
                  "BPJS KES (1%)": el?.Deduction?.["BPJS KES (1%)"],
                  "PPH 21 Atas Gaji": el?.Deduction?.["PPH 21 Atas Gaji"],
                  "PPH 21 Atas THR/Bonus":
                    el?.Deduction?.["PPH 21 Atas THR/Bonus"],
                  "PMK 44 2020": el?.Deduction?.["PMK 44 2020"],
                  "Potogan Pajak": el?.Deduction?.["Potongan Pajak"],
                  "Take Home Pay": el?.Income?.["Take Home Pay"],
                  Regional: el?.Regional,
                  "Bank Name": el?.BankName,
                  "Nomor Rekening": el?.AccountNumber,
                }))}
                startIcon={<GetAppIcon />}
                filename={`Payslip Report ${new Date().toLocaleDateString()}`}
                initial="Download by filter"
              /> */}
            </div>
          </div>
        </div>
        {
          loadingAllPayslip ? (
            <div style={{ display:"flex", alignItems: 'center' , justifyContent: 'center', marginTop: 80}}>
              <div style={{ width: 200}}>
                <LoadingAnimation />
              </div>
            </div>
          ) : (
          <Card sx={{ marginTop: 2}}>
          <div className="wrapper-table-box-search-payslip-menu">
            <Scrollbar>
              <TableContainer>
                <Table
                 id="emp-table"
                  sx={{
                    width: "100%",
                    maxHeight: "100%",
                  }}
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: 5,
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <EnhancedTableHead
                  numSelected={allPayslip.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  values={allPayslip}
                  rowCount={
                    allPayslip?.filter(
                      (el) =>
                        el?.name
                          ?.toLowerCase()
                          .includes(listDataPayslip?.toLowerCase()) ||
                        el?.jobTitle
                          ?.toLowerCase()
                          .includes(listDataPayslip?.toLowerCase())
                      // && (selectedRegion ? el.EmployeeArea === selectedRegion: true)
                    ).length
                  }
                    />
                  <TableBody>
                    {stableSort(allPayslip, getComparator(order, orderBy))
                      .filter(
                        (el) =>
                          el.name?.toLowerCase().includes(
                            listDataPayslip?.toLowerCase()
                          ) ||
                          el.jobtitle?.toLowerCase().includes(
                            listDataPayslip?.toLocaleLowerCase()
                          )
                      )
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((data, ind) => {
                        // eslint-disable-next-line no-lone-blocks
                        {/* let month =
                          calendar.months.eng.full[
                            data?.PayrollMonth?.split(" ")[0] - 1
                          ];
                        let year = data?.PayrollMonth?.split(" ")[2]; */}
                        return (
                          <TableRow hover key={ind} tabIndex={-1}>
                            <TableCell></TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  alt={data?.name}
                                  src={data?.name?.substr(0, 1)}
                                />
                                <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize'}}>
                                {data?.name?.toLowerCase()}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize'}}>{data?.jobTitle?.toLowerCase()}</Typography>
                              </TableCell>
                            <TableCell align="left">{data?.client}</TableCell>
                            <TableCell align="left">{data?.payslips[0]?.date}</TableCell>
                            <TableCell align="right">
                              <Button
                                style={{
                                  textTransform: "none",
                                  fontSize: 16,
                                  fontWeight: 500,
                                }}
                                onClick={() => {
                                  history.push({
                                    pathname: `/admin/payslip-person-detail-external/${data?.id}`,
                                    // pathname: `/admin/payslip-person-detail-external/${data._id}`,
                                    state: data,
                                  });
                                }}
                              >
                                See Details
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={
                  allPayslip?.filter(
                    (el) =>
                      el.name
                        ?.toLowerCase()
                        .includes(listDataPayslip?.toLowerCase()) ||
                      el.jobTitle
                        ?.toLowerCase()
                        .includes(listDataPayslip?.toLocaleLowerCase())
                  ).length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </div>
          </Card>
          )
        }
    
      </div>
    </div>
  );
}
