/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import "./EmployeesFMCG.css";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {
  Autocomplete,
  Button,
  TextField,
  styled,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  // Checkbox,
  TableCell,
  Stack,
  Avatar,
  Typography,
  TablePagination,
  TableHead,
  TableSortLabel,
  Card,
} from "@mui/material";
import SearchbarFMCG from "../../SearchbarFMCG/SearchbarFMCG";
import { Box } from "@mui/system";
import Scrollbar from "../../../SuperAdmin/_user/TableUsers/Scrollbar";
import { visuallyHidden } from "@mui/utils";
import { Link } from "react-router-dom";
// import EditIcon from "@mui/icons-material/Edit";
import ModalTabListFMCG from "../../ModalTabListFMCG/ModalTabListFMCG";
import { Helmet } from "react-helmet";
import LoadingFMCG from "../../../utils/LoadingFMCG/LoadingFMCG";
import { debounce } from "lodash";
import { useHistory } from "react-router";

const position = ["Sales Promotion Girl", "SPG Leader", "MD", "MD Leader"];
const data = [
  {
    Name: "Anastasia",
    Position: "Sales Promotion Girl",
    Level: "Staff",
    Leader: "",
    Area: "Jakarta",
    Store: "",
    _id: "asdkakslahdlkj123123",
    Status: true,
  },
  {
    Name: "Aisyah Dara",
    Position: "Sales Promotion Girl",
    Level: "Staff",
    Leader: "Cameron Williamson",
    Area: "Jakarta",
    Store: "Grand Lucky SCBD",
    _id: "lkshdo1hdon1owh",
    Status: true,
  },
  {
    Name: "Alkatri",
    Position: "MD Leader",
    Level: "MD",
    Leader: "Cameron Williamson",
    Area: "Jakarta",
    Store: "JFM Green Ville",
    _id: "lksh12k31i231238012",
    Status: false,
  },
  {
    Name: "Anton Sujiwo ",
    Position: "MD",
    Level: "MD",
    Leader: "Cameron Williamson",
    Area: "Jakarta",
    Store: "Grand Lucky SCBD",
    _id: "okjdalksjk123jp12j3",
    Status: true,
  },
  {
    Name: "Sheila",
    Position: "SPG Leader",
    Level: "Leader",
    Leader: "Cameron Williamson",
    Area: "Semarang",
    Store: "Grand Lucky SCBD",
    _id: "1l2n3j12h301238098",
    Status: false,
  },
  {
    Name: "Sheila",
    Position: "SPG Leader",
    Level: "Leader",
    Leader: "Cameron Williamson",
    Area: "Semarang",
    Store: "Grand Lucky SCBD",
    _id: "1l2n3j12h301238098",
    Status: false,
  },
  {
    Name: "Sheila",
    Position: "SPG Leader",
    Level: "Leader",
    Leader: "Cameron Williamson",
    Area: "Semarang",
    Store: "Grand Lucky SCBD",
    _id: "1l2n3j12h301238098",
    Status: false,
  },
  {
    Name: "Sheila",
    Position: "SPG Leader",
    Level: "Leader",
    Leader: "Cameron Williamson",
    Area: "Semarang",
    Store: "Grand Lucky SCBD",
    _id: "1l2n3j12h301238098",
    Status: false,
  },
  {
    Name: "Sheila",
    Position: "SPG Leader",
    Level: "Leader",
    Leader: "Cameron Williamson",
    Area: "Semarang",
    Store: "Grand Lucky SCBD",
    _id: "1l2n3j12h301238098",
    Status: false,
  },
  {
    Name: "Sheila",
    Position: "SPG Leader",
    Level: "Leader",
    Leader: "Cameron Williamson",
    Area: "Semarang",
    Store: "Grand Lucky SCBD",
    _id: "1l2n3j12h301238098",
    Status: false,
  },
  {
    Name: "Sheila",
    Position: "SPG Leader",
    Level: "Leader",
    Leader: "Cameron Williamson",
    Area: "Semarang",
    Store: "Grand Lucky SCBD",
    _id: "1l2n3j12h301238098",
    Status: false,
  },
];
const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    "& fieldset": {
      borderRadius: 8,
      border: "1px solid #D3C9C9",
    },
    "& .MuiInputBase-sizeSmall": {
      padding: 6,
    },
  },
  "& .MuiInputBase-input-MuiOutlinedInput-input": {
    color: "red",
  },
  // "& .MuiAutocomplete-input" : {
  //   fontSize: 13,
  //   fontWeight: 400,
  //   color: '#6F6F70',
  // }
});

const TABLE_HEAD = [
  { id: "Name", label: "Name", alignRight: false },
  { id: "Position", label: "Position", alignRight: false },
  { id: "Level", label: "Level", alignRight: false },
  { id: "Leader", label: "Leader", alignRight: false },
  { id: "Area", label: "Area", alignRight: false },
  { id: "Store", label: "Store", alignRight: false },
  { id: "Status", label: "Status", alignRight: false },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    // values,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& th": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={rowCount !== values?.users?.length}
            sx={rowCount !== values?.users?.length ? { display: "none" } : {}}
          />
        </TableCell> */}
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const EmployeesFMCG = () => {
  // eslint-disable-next-line no-unused-vars
  const token = localStorage.getItem("accessToken");
  const history = useHistory();
  const [page, setPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState("");
  const [orderBy, setOrderBy] = useState("Name");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [listEmployeesFMCG, setListEmployeesFMCG] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalListLeader, setModalListLeader] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [modalAddEmployee, setModalAddEmployee] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setListEmployeesFMCG(event.target.value);
  };
  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  // const handleSubmitFilter = (e) => {
  //   e.preventDefault();
  //   setSearchTerm(listEmployeesFMCG);
  // };

  const handleClick = (event, Name) => {
    const selectedIndex = selected.indexOf(Name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, Name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data
        .filter(
          (el) =>
            el.Name?.toLowerCase().includes(listEmployeesFMCG.toLowerCase()) ||
            el.Position?.toLowerCase().includes(listEmployeesFMCG.toLowerCase())
        )
        .map((n) => n?._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (Name) => selected.indexOf(Name) !== -1;
  return (
    <div className="ContainerEmployeesFMCG">
      <Helmet>
        <title>MyWorkSpace | Employees</title>
      </Helmet>
      <div className="WrapperEmployeesFMCG">
        <div className="HeaderEmployeesFMCG">
          <div className="ButtonReportEmployeesFMCG">
            <Button
              endIcon={<LocalPrintshopIcon sx={{ width: 18, height: 18 }} />}
            >
              Report
            </Button>
          </div>
          {/* <div className="ButtonActionEmployeesFMCG">
            <Button
              style={{
                textTransform: "none",
                width: 129,
                height: 40,
                background: "#FFFFFF",
                border: "1px solid #D3C9C9",
                borderRadius: 8,
                fontWeight: 500,
                fontSize: 13,
                color: "#1571DE",
              }}
              startIcon={
                <i className="ri-upload-line" style={{ fontSize: 20 }} />
              }
            >
              Upload Excel
            </Button>
            <Button
              style={{
                textTransform: "none",
                width: 148,
                height: 40,
                background: "#1571DE",
                borderRadius: 8,
                fontWeight: 500,
                fontSize: 13,
                color: "#FFFFFF",
              }}
              startIcon={
                <i className="ri-download-line" style={{ fontSize: 20 }} />
              }
            >
              Template Excel
            </Button>
          </div> */}
          {/* <div className="ButtonAddEmployeesFMCG">
            <Button
              startIcon={<AddIcon sx={{ width: 18, height: 18 }} />}
              style={{
                width: 150,
                height: 40,
                backgroundColor: "#1571DE",
                borderRadius: 5,
                color: "white",
                textTransform: "none",
                fontSize: 13,
                fontWeight: 500,
              }}
              onClick={() =>
                history.push({
                  pathname: `/admin-fmcg/employees/add`,
                  state: { location: "addEmployee" },
                })
              }
            >
              Add Employee
            </Button>
          </div> */}
        </div>
        <div className="ItemTopEmployeesFMCG">
          <div className="SearchbarFMCG">
            <SearchbarFMCG
              // value={listEmployeesFMCG}
              // onChange={handleSearch}
              onChange={debouncedResults}
              searchWidth="379px"
              placeholder="Search employee"
            />
          </div>
          <div className="ButtonAutoCompletePositionFMCG">
            <Autocomplete
              disablePortal
              options={position}
              value={selectedPosition}
              inputValue={selectedPosition}
              onInputChange={(e, value) => setSelectedPosition(value)}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  size="small"
                  sx={{ width: 250 }}
                  placeholder="Position"
                />
              )}
            />
          </div>
          <div className="ButtonAssignLeaderFMCG">
            <Button
              style={{
                width: 116,
                height: 40,
                borderRadius: 8,
                color: "#1571DE",
                textTransform: "none",
                fontSize: 13,
                fontWeight: 500,
                background: "#FFFFFF",
                border: "1px solid #E0E0E0",
              }}
              // disabled
              // onClick={() => setModalListLeader(true)}
              onClick={() => history.push('/admin-fmcg/employees/assign-leader')}
            >
              Assign Leader
            </Button>
          </div>
          <div className="ButtonAssignLeaderFMCG">
            <Button
              style={{
                width: 116,
                height: 40,
                borderRadius: 8,
                color: "#1571DE",
                textTransform: "none",
                fontSize: 13,
                fontWeight: 500,
                background: "#FFFFFF",
                border: "1px solid #E0E0E0",
              }}
              // disabled
              onClick={() => history.push('/admin-fmcg/employees/assign-store')}

            >
              Assign Store
            </Button>
          </div>
        </div>

        <div className="ContainerTableEmployeesFMCG">
          <Card>
            {loading ? (
              <div className="ContainerAttendanceFMCGLoading">
                <LoadingFMCG width={800} height={200} />
              </div>
            ) : (
              <Scrollbar>
                <TableContainer>
                  <Table
                    sx={{
                      width: "100%",
                      maxHeight: "100%",
                    }}
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: 5,
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      // values={data}
                      rowCount={
                        data?.filter(
                          (el) =>
                            el.Name?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                            el.Position?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                            el.Level?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                            el.Leader?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                            el.Area?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                            el.Store?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            )
                        ).length
                      }
                    />
                    <TableBody>
                      {stableSort(data, getComparator(order, orderBy))
                        .filter(
                          (el) =>
                            (el.Name?.toLowerCase().includes(
                              listEmployeesFMCG.toLowerCase()
                            ) ||
                              el.Position?.toLowerCase().includes(
                                listEmployeesFMCG.toLowerCase()
                              ) ||
                              el.Level?.toLowerCase().includes(
                                listEmployeesFMCG.toLowerCase()
                              ) ||
                              el.Leader?.toLowerCase().includes(
                                listEmployeesFMCG.toLowerCase()
                              ) ||
                              el.Area?.toLowerCase().includes(
                                listEmployeesFMCG.toLowerCase()
                              ) ||
                              el.Store?.toLowerCase().includes(
                                listEmployeesFMCG.toLowerCase()
                              )) &&
                            (selectedPosition
                              ? el.Position === selectedPosition
                              : true)
                        )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((employee, ind) => {
                          const isItemSelected = isSelected(employee?._id);
                          const labelId = `enhanced-table-checkbox-${ind}`;
                          return (
                            <TableRow
                              hover
                              key={ind}
                              tabIndex={-1}
                              id={labelId}
                              sx={{
                                "&.MuiTableRow-root:hover": {
                                  backgroundColor: "#eef6ff",
                                },
                              }}
                            >
                              {/* <TableCell padding="checkbox">
                                <Checkbox
                                  onClick={(event) =>
                                    handleClick(event, employee?._id)
                                  }
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{ "aria-labelledby": labelId }}
                                  icon={
                                    <RadioButtonUncheckedIcon sx={{ color: "#000000" }} />
                                  }
                                  checkedIcon={<CheckCircle sx={{ color: "#1571DE" }} />}
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      width: 24,
                                      height: 24,
                                      borderRadius: "50%",
                                    },
                                  }}
                                />
                              </TableCell> */}
                              <TableCell
                                component="th"
                                scope="row"
                                // padding="none"
                              >
                                <Link
                                  to={{
                                    pathname: `/admin-fmcg/employees/detail/${employee._id}`,
                                    state: { data: employee },
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Avatar
                                      src={
                                        employee?.ProfilePicture
                                          ? employee.ProfilePicture
                                          : null
                                      }
                                    />
                                    <Typography
                                      variant="subtitle2"
                                      noWrap
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      {employee.Name
                                        ? employee.Name.toLowerCase()
                                        : "-"}
                                    </Typography>
                                  </Stack>
                                </Link>
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {employee.Position ? employee.Position : "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {employee.Level ? employee.Level : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {employee.Leader === "" ? (
                                  <Typography
                                    style={{
                                      backgroundColor: "#B22A12",
                                      color: "#FBE7B3",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 4,
                                      height: 26,
                                      width: 80,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{ fontSize: 13, fontWeight: 500 }}
                                    >
                                      Not Assign
                                    </Typography>
                                  </Typography>
                                ) : (
                                  <Typography
                                    component="span"
                                    style={{ fontSize: 13, fontWeight: 500 }}
                                  >
                                    {employee.Leader}
                                  </Typography>
                                  /* <Avatar src={employee.Leader} /> */
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {employee.Area ? employee.Area : ""}
                              </TableCell>
                              <TableCell align="left">
                                {employee.Store === "" ? (
                                  <Typography
                                    style={{
                                      backgroundColor: "#B22A12",
                                      color: "#FBE7B3",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 4,
                                      height: 26,
                                      width: 80,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{ fontSize: 13, fontWeight: 500 }}
                                    >
                                      Not Assign
                                    </Typography>
                                  </Typography>
                                ) : (
                                  employee.Store
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {employee.Status === true ? (
                                  <Typography
                                    style={{
                                      backgroundColor: "#E4FFE6",
                                      color: "#17B51E",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 4,
                                      height: 26,
                                      // width: 58,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#17B51E",
                                      }}
                                    >
                                      Active
                                    </Typography>
                                  </Typography>
                                ) : (
                                  <Typography
                                    style={{
                                      backgroundColor: "#FFDFDD",
                                      // color: "#17B51E",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: 4,
                                      height: 26,
                                      // width: 74,
                                    }}
                                    component="div"
                                  >
                                    <Typography
                                      component="span"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#B52117",
                                      }}
                                    >
                                      Unactive
                                    </Typography>
                                  </Typography>
                                )}
                                {/* <Button
                                onClick={() => {
                                  history.push({
                                    pathname: `/admin-fmcg/employees/edit/lkshdo1hdon1owh`,
                                    state: { location: "editEmployee" },
                                  });
                                }}
                              >
                                <EditIcon />
                              </Button> */}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            )}
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={
                data?.filter(
                  (el) =>
                    (el.Name?.toLowerCase().includes(
                      listEmployeesFMCG.toLowerCase()
                    ) ||
                      el.Position?.toLowerCase().includes(
                        listEmployeesFMCG.toLowerCase()
                      ) ||
                      el.Level?.toLowerCase().includes(
                        listEmployeesFMCG.toLowerCase()
                      ) ||
                      el.Leader?.toLowerCase().includes(
                        listEmployeesFMCG.toLowerCase()
                      ) ||
                      el.Area?.toLowerCase().includes(
                        listEmployeesFMCG.toLowerCase()
                      ) ||
                      el.Store?.toLowerCase().includes(
                        listEmployeesFMCG.toLowerCase()
                      )) &&
                    (selectedPosition ? el.Position === selectedPosition : true)
                ).length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </div>
      </div>

      {modalListLeader && (
        <ModalTabListFMCG
          modalListLeader={modalListLeader}
          setModalListLeader={setModalListLeader}
          data={data}
          openModal={openEditModal}
          setOpenModal={setOpenEditModal}
        />
      )}
    </div>
  );
};

export default EmployeesFMCG;
