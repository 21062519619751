/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./AddClientsIdMenu.css";
import Switch from "@mui/material/Switch";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import ClientModalSuccess from "../ClientModalSuccess/ClientModalSuccess";
import {
  fetchAllClientNotSet,
  setActiveClient,
  updateClient,
  updateSwitchController,
} from "../../../../redux/actions/SuperAdmin/adminActions";

export default function AddClientsIdMenu({ client = {} }) {
  const companies = useSelector((state) => state.client.clientNotSet);
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const location = useLocation();
  const name = location.state.name;
  const dataClient = location.state;
  const [clientName, setClientName] = useState();
  const [selectedSwitchAttendance, setSelectedSwitchAttendance] = useState(false);
  const [selectedSwitchLoan, setSelectedSwitchLoan] = useState(false);
  const [selectedSwitchWorkspace, setSelectedSwitchWorkspace] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const dataCompany = companies.find((company) => company.Name === name);

  useEffect(() => {
    dispatch(fetchAllClientNotSet(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedSwitchAttendance(!!client?.StatusAttendance);
    setSelectedSwitchLoan(!!client?.StatusLoan);
    setSelectedSwitchWorkspace(!!client?.MwsSetting);
  }, [client]);

    // handle update schema attendance
    const handleSwitch = (e) => {
      const result = {
        id: client._id,
        StatusAttendance: e.target.checked, // kondisi ini untuk mendapatkan hasil true / false dari switch yang terupdate
      };
      dispatch(updateSwitchController(result, token));
    };
  
    // handle update schema loan
    const handleSwitchLoan = (e) => {
      // setSelectedLoan(e.target.checked);
      const resultLoan = {
        id: client._id,
        StatusLoan: e.target.checked,
      };
      dispatch(updateSwitchController(resultLoan, token));
    };
  
    // handle update schema workspace
    const handleSwitchWorkspace = (e) => {
      setSelectedSwitchWorkspace(e.target.checked);
      const resultWorkspace = {
        id: client._id,
        MwsSetting: e.target.checked,
      };
      dispatch(updateSwitchController(resultWorkspace, token));
    };

  // const handleCatchSwitchAttendance = (event) => {
  //   setSelectedSwitchAttendance(event.target.checked);
  // };
  // const handleCatchSwitchLoan = (event) => {
  //   setSelectedSwitchLoan(event.target.checked);
  // };

  // const handleCatchSwitchWorkspace = (event) => {
  //   setSelectedSwitchWorkspace(event.target.checked)
  // }

  const routeChange = () => {
    history.push("/superadmin/add-clients");
  };


  const handleButton = () => {
    if (
      selectedSwitchAttendance === false &&
      selectedSwitchLoan === false
      // !client?.LoanSchema &&
      // !client?.WorkspaceSchema &&
      // !client?.SchemaAttendance
    ) {
      return (
        <Button
          style={{
            width: 97,
            height: 40,
            background: "grey",
            borderRadius: 4,
            color: "white",
          }}
          disabled
        >
          Add to List
        </Button>
      );
    } else if (
      !client?.SchemaLoan &&
      !client?.WorkspaceSchema &&
      !client?.SchemaAttendance
    ) {
      return (
        <Button
          style={{
            width: 97,
            height: 40,
            background: "grey",
            borderRadius: 4,
            color: "white",
          }}
          disabled
        >
          Add to List
        </Button>
      );
    } else {
      return (
        <Button
          style={{
            width: 97,
            height: 40,
            background: "blue",
            borderRadius: 4,
            color: "white",
          }}
          onClick={handleAddToList}
        >
          Add to List
        </Button>
      );
    }
  };

  const handleButtonWithoutSettings = () => {
    return (
      <Button
        style={{
          width: 97,
          height: 40,
          background: "blue",
          borderRadius: 4,
          color: "white",
        }}
        onClick={handleAddToList}
      >
        Add to List
      </Button>
    );
  };

  const handleAddToList = () => {
    const result = {
      StatusAttendance: selectedSwitchAttendance,
      StatusLoan: selectedSwitchLoan,
      MwsSetting: selectedSwitchWorkspace,
      id: client._id,
    };
    // console.log(result);
    dispatch(updateClient(result, token));
    dispatch(setActiveClient(token, client?.Name));
    setModalSuccess(true);
  };
  console.log(client)
  return (
    <div className="container-add-clients-id-menu">
      <div className="wrapper-add-client-id-menu">
        <div className="title-add-client-id-menu">
          <span>Client Name</span>
          <input type="text" disabled value={client?.Name || ""} />
        </div>
        <div className="info-add-client-id-menu">
          {/* <div className="box-settings-add-clients-id-menu">
                        <div className="item-ADI-top">
                            <span> 
                                Layout Settings
                            </span>
                            <Switch value={ catchSwitch1 } onChange={ handleCatchSwitch1 }/>
                        </div>
                        <div className="item-ADI-main">
                            <span>
                                Settings view for your clients
                            </span>
                        </div>
                        <div className="item-ADI-status">
                            <span value={ status1 } onChange={(e) => setStatus1(e.target.value)} >
                                Not Set
                            </span>
                        </div>
                        <Link 
                        to={{ pathname: "/add-clients-id-employee", state: { name: name}}}
                        >
                        <div className="item-ADI-footer">
                            <button>
                                View Setting
                            </button>
                            <ArrowRightAltIcon style={{ color: "#1571DE"}}/>
                        </div>
                        </Link>
                    </div> */}
          <div className="box-settings-add-clients-id-menu">
            <div className="item-ADI-top">
              <span>Loan Settings</span>
              <Switch
                checked={selectedSwitchLoan}
                onChange={handleSwitchLoan}
              />
            </div>
            <div className="item-ADI-main">
              <span>Settings view for your clients</span>
            </div>
            <div className="item-ADI-status">
              <span>
                {client?.SchemaLoan ? (
                  <span style={{ color: "green" }}>{client.SchemaLoan}</span>
                ) : (
                  <span style={{ color: "red" }}>not set</span>
                )}
              </span>
            </div>
            <Link
              to={{
                pathname: `/superadmin/add-clients-id-loan-not-set/${client?._id}`,
              }}
              className="item-ADI-footer"
            >
              <div className="item-ADI-footer">
                <button>View Setting</button>
                <ArrowRightAltIcon style={{ color: "#1571DE" }} />
              </div>
            </Link>
          </div>
          <div className="box-settings-add-clients-id-menu">
            <div className="item-ADI-top">
              <span>Attendances Settings</span>
              <Switch
                checked={selectedSwitchAttendance}
                onChange={handleSwitch}
              />
            </div>
            <div className="item-ADI-main">
              <span>Settings view for your clients</span>
            </div>
            <div className="item-ADI-status">
              <span>
                {client?.SchemaAttendance ? (
                  <span style={{ color: "green" }}>
                    {client.SchemaAttendance}
                  </span>
                ) : (
                  <span style={{ color: "red" }}>not set</span>
                )}
              </span>
            </div>
            <Link
              to={{
                pathname: `/superadmin/add-clients-id-attendances-not-set/${client?._id}`,
              }}
              className="item-ADI-footer"
            >
              <div className="item-ADI-footer">
                <button>View Setting</button>
                <ArrowRightAltIcon style={{ color: "#1571DE" }} />
              </div>
            </Link>
          </div>
          <div className="box-settings-add-clients-id-menu">
            <div className="item-ADI-top">
              <span>Workspace Settings</span>
              <Switch
              checked={selectedSwitchWorkspace}
              onChange={handleSwitchWorkspace}
              />
            </div>
            <div className="item-ADI-main">
              <span>Settings view for your clients</span>
            </div>
            <div className="item-ADI-status">
              <span>
                {client?.MwsSetting?  (
                  <span style={{ color: "green" }}>set</span>
                ) : (
                  <span style={{ color: "red" }}> not set</span>
                )}
              </span>
            </div>
            <Link
              to={{
                pathname: `/superadmin/add-clients-id-workspace-not-set/${client?._id}`,
              }}
              className="item-ADI-footer"
            >
              <div className="item-ADI-footer">
                <button>View Setting</button>
                <ArrowRightAltIcon style={{ color: "#1571DE" }} />
              </div>
            </Link>
          </div>
        </div>
        <div className="button-bottom-ADI">
          {/* {handleButton()} */}
          {handleButtonWithoutSettings()}
          {modalSuccess && (
            <ClientModalSuccess
              modalSuccess={modalSuccess}
              setModalSuccess={setModalSuccess}
              pathName="/superadmin/client"
              client={client}
            />
          )}
          <Button
            style={{
              width: 75,
              height: 40,
              border: "1px solid #1571DE",
              borderRadius: 4,
            }}
            onClick={routeChange}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
