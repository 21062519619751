
import AddCircle from '@mui/icons-material/AddCircle';
import { Autocomplete, Button, styled, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationById, setLocation } from '../../../../../redux/actions/SuperAdmin/locationActions';
import Styles from './AddAttendeesModal.module.css';

const MuiTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: 8,
            border: '1px solid #d9d9d9',
            color: '#0a0a0a',
        },
    },
});

export default function AddAttendeesModal({ clientCode, showAddAttendees, setShowAddAttendees, locationId, attendees }) {
    const token = localStorage.getItem("accessToken");
    const dispatch = useDispatch();
    const { employees } = useSelector((state) => state.employees)
    const [selectedAttendee, setSelectedAttendee] = useState(null)

    const fixedEmployee = useMemo(() => {
        return employees?.filter(el => {
            return !attendees?.find(element => {
                return element.employeeId === el.employeeId;
            })
        })
    }, [employees, attendees])

    const handleChangeAttendee = (newValue) => {
        setSelectedAttendee(newValue)
    }



    const handleSubmit = (e) => {
        // e.preventDefault()
        // console.log(locationId, { employeeId: selectedAttendee?.employeeId })
        dispatch(setLocation(token, locationId, { employeeId: selectedAttendee.employeeId }))
        setShowAddAttendees(false)
        dispatch(getLocationById(token, locationId))
        // dispatch(fetchAllEmployees(token, query))
    }


    return (
        <div className={Styles.Background}>
            <div className={Styles.Container}>
                <div className={Styles.ModalHeader}>
                    <AddCircle sx={{ color: '#1571DE' }} />
                    <span>Add Attendee</span>
                </div>
                <div className={Styles.FormContainer}>
                    <form className={Styles.FormControl} onSubmit={handleSubmit}>
                        <div className={Styles.BoxFormWrapper}>
                            <label>Employee Name</label>
                            <Autocomplete
                                disablePortal
                                id="attendee"
                                getOptionLabel={(option) => option.name}
                                options={fixedEmployee}
                                onChange={(event, newValue) => handleChangeAttendee(newValue)}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option._id}>
                                            {option.name}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => <MuiTextField {...params} size="small" sx={{ width: '100%', background: 'var(--main-bg)' }} required />
                                }
                            />
                        </div>
                        <div className={Styles.BoxFormWrapper}>
                            <label>NRK</label>
                            <input type="number" value={selectedAttendee?.employeeId || ''} disabled />
                        </div>
                        <div className={Styles.ButtonsWrapper}>
                            <Button type="submit" variant="contained">Save</Button>
                            <Button variant="outlined" onClick={() => setShowAddAttendees(false)}>Cancel</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}