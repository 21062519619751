import * as types from "../../typeExternal";

const initialstate = {
  clientExternalByCode: {},
  isLoading: false,
  isError: {},

  detailClientExternal : [],
  isLoadingDetail : false,
  isErrorDetail: {}
};

const clientExternalReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_CLIENT_BY_CODE_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.FETCH_CLIENT_BY_CODE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        clientExternalByCode: action.payload
      };
    }
    case types.FETCH_CLIENT_BY_CODE_ERROR: {
        return {
          ...state,
          isLoading: false,
          isError: action.payload
        };
      }
      case types.FETCH_DETAIL_BY_CLIENT_BEGIN: {
        return {
          ...state,
          isLoadingDetail: true,
        };
      }
      case types.FETCH_DETAIL_BY_CLIENT_SUCCESS: {
        return {
          ...state,
          isLoadingDetail: false,
          detailClientExternal: action.payload
        };
      }
      case types.FETCH_DETAIL_BY_CLIENT_ERROR: {
        return {
          ...state,
          isLoadingDetail: false,
          isErrorDetail: action.payload
        };
      }
    default:
      return state;
  }
};

export default clientExternalReducer;
