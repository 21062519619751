/* eslint-disable no-unused-vars */

import React, { useEffect, useMemo, useState } from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import {
  InductionDetailLayout,
  DeleteModalLayout,
} from "../../../../components/SuperAdmin/_induction";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Styles from "./InductionDetail.module.css";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import EDIT_ICON from "../../../../assets/images/create.png";
import { useParams } from "react-router";
import fetchInductionByIdAsync from "../../../../async/induction/fetchInductionById";
import { useDispatch, useSelector } from "react-redux";
import { fetchInductionById } from "../../../../redux/actions/SuperAdmin/inductionActions";
import { API_IMAGE } from "../../../../redux/api";
import deleteInduction from "../../../../async/induction/deleteInduction";


export default function InductionDetail() {
  const token = localStorage.getItem("accessToken");
  const { inductionById } = useSelector((state) => state.inductions);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState({});
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  // const [induction, setInduction] = useState();

  const inductionByIdData = useMemo(() => {
    return location?.state?.data;
  }, [location]);

  useEffect(() => {
    (async () => {
      try {
        const result = await fetchInductionByIdAsync(token, id);
        setData(result);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [id, token]);

  
    useEffect(()=> {
    dispatch(fetchInductionById(token, id))
  }, [token, dispatch, id])

  // useEffect(() => {
  //   setInduction(inductionById)
  // },[inductionById]);


  // useEffect(() => {
  //   setInduction((prev) => ({
  //     ...prev,
  //     thumbnail : {
  //       ...prev.thumbnail,
  //       uri: prev?.thumbnail?.uri?.replace(`https://myworkspace.id/api/image/`, `${API_IMAGE}`)
  //     }
  //   }))
  // },[])

  const handleDeleteScheme = () => {
    deleteInduction(token, id)
    setIsModalOpen(false);
    history.push(`/superadmin/induction`);
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Induction</title>
      </Helmet>
      <div className={Styles.Spacer}>
        <Breadcrumb>
          <Button
            onClick={() => history.push("/superadmin/induction")}
            style={{ textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>Induction</span>
          </Button>
          <span style={{ fontSize: 20, color: "#255bfc" }}>
            Induction Detail
          </span>
        </Breadcrumb>

        <div className={Styles.ButtonAction}>
          <Button
            onClick={() => {
              history.push({
                pathname: `/superadmin/edit-induction/${id}`,
                state: {
                  data: inductionByIdData,
                },
              });
            }}
          >
            <span>Edit</span>
            <img src={EDIT_ICON} alt="edit" />
          </Button>
          <Button
            onClick={(event) => {
              event.preventDefault();
              setIsModalOpen(inductionByIdData);
              // setSelectedInformation(information);
            }}
          >
            <span>Delete</span>
            <img src={DELETE_ICON} alt="delete" />
          </Button>
        </div>
      </div>

      {isModalOpen?._id && (
        <DeleteModalLayout
          active={isModalOpen}
          onOpen={setIsModalOpen}
          description="Are you sure you want to delete this induction?"
          label="Delete Induction"
          // pathName="/superadmin/induction"
          onDeleteScheme={handleDeleteScheme}
        />
      )}

      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <InductionDetailLayout data={ inductionById } id={id} />
        </Grid>
      </Grid>
    </Container>
  );
}
