/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles, StylesContext } from "@mui/styles";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import ImageOffice from "../../../../../assets/images/Office.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import InputText from "../../../utils/Input/Input";
import { useDispatch } from "react-redux";
import { updateClientWithSnackbar } from "../../../../redux/actions/SuperAdmin/adminActions";
import { useHistory } from "react-router";
import { FormControl, MenuItem, Select } from "@mui/material";
import { UseGroupName } from "../../../utils/hooks";
// moment.locale("en-gb");

const useStyles = makeStyles((theme) => ({
  ItemModalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& > span": {
      color: "#333333",
      fontSize: 16,
      fontWeight: 600,
    },
  },
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 8,
      //   width: "100%",
    },
    "& .MuiSelect-select": {
      fontSize: 16,
    },
  },
  Content: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  ProfileInput: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    "& > span": {
      fontSize: 13,
      color: "#0A0A0A",
      fontWeight: 500,
    },
    "& > textarea": {
      width: "100%",
      height: 40,
      background: "#fbfbfb",
      border: "1.5px solid #d3c9c9",
      borderRadius: 8,
      paddingLeft: 10,
      paddingTop: 10,
      resize: "vertical",
    },
    "& > ::placeholder": {
      color: "#616161",
      fontStyle: "italic",
    },
  },
  Button: {
    display: "flex",
    alignSelf: "flex-end",
    gap: 10,
    "& > button:nth-of-type(1)": {
      outline: "none",
      background: "#FFFFFF",
      border: "1px solid #333333",
      borderRadius: 5,
      color: "#333333",
      width: 95,
      height: 34,
      cursor: "pointer",
    },
    "& > button:nth-of-type(2)": {
      outline: "none",
      background: "#1571DE",
      borderRadius: 5,
      color: "#FFFFFF",
      width: 81,
      height: 34,
      cursor: "pointer",
    },
  },
}));

export default function ModalEditLeaderExternal({
  data,
  description,
  openModal,
  setOpenModal,
}) {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("accessToken");
  const theme = useTheme();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [values, setValues] = useState({});
  const getClientName = UseGroupName();
  const [selectedAddClient, setSelectedAddClient] = useState("");
  // const [openModal, setOpenModal] =useState(false);

  useEffect(() => {
    setValues(data);
  }, [data]);

  const handleClose = () => {
    setOpenModal(false);
    // if(description === "Edit Leader") {
    //   setEditLeader(false)
    // } else {
    //   setModalAddLeader(false);
    // }
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  //   const onHandleSubmit = () => {
  //     const data = {
  //       id: client._id,
  //       ...values,
  //     };
  //   };


  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Boolean(openModal)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className={classes.ItemModalTop}>
            <span>{description}</span>
          </div>
        </DialogTitle>

        <DialogContent>
          {description === "Add Leader" ? (
            <div className={classes.Content}>
              <div className={classes.ProfileInput}>
                <span>NRK</span>
                <InputText
                  value={values?.Nrk}
                  onChange={handleChange("Nrk")}
                  placeholder="000"
                  type="number"
                />
              </div>
              <div className={classes.ProfileInput}>
                <span>Email</span>
                <InputText
                  placeholder="example@gmail.com"
                  value={values?.Email}
                  onChange={handleChange("Email")}
                />
              </div>
              <div className={classes.ProfileInput}>
                <span>Contact</span>
                <InputText
                  type="number"
                  placeholder="+62 ..."
                  value={values?.PhoneNumber}
                  onChange={handleChange("PhoneNumber")}
                />
              </div>
              <div className={classes.ProfileInput}>
                <span>User Name</span>
                <InputText
                  placeholder="Username for maintainer"
                  value={values?.Username}
                  onChange={handleChange("Username")}
                />
              </div>
              <div className={classes.ProfileInput}>
                <span>Password</span>
                <InputText
                  placeholder="***"
                  type="password"
                  value={values?.password}
                  onChange={handleChange("password")}
                />
              </div>
              <div className={classes.Button}>
                <button onClick={handleClose}>Cancel</button>
                <button>Save</button>
              </div>
            </div>
          ) : (
            <div className={classes.Content}>
              <div className={classes.ProfileInput}>
                <span>Name</span>
                <InputText
                  placeholder="Name"
                  value={values?.Name}
                  onChange={handleChange("Name")}
                />
              </div>
              <div className={classes.ProfileInput}>
                <span>NRK</span>
                <InputText
                  value={values?.Nrk}
                  onChange={handleChange("Nrk")}
                  placeholder="000"
                  type="number"
                />
              </div>
              <div className={classes.ProfileInput}>
                <span>Email</span>
                <InputText
                  placeholder="example@gmail.com"
                  value={values?.Email}
                  onChange={handleChange("Email")}
                />
              </div>
              <div className={classes.ProfileInput}>
                <span>Contact</span>
                <InputText
                  type="number"
                  placeholder="+62 ..."
                  value={values?.PhoneNumber}
                  onChange={handleChange("PhoneNumber")}
                />
              </div>
              <div className={classes.ProfileInput}>
                <span>Client</span>
                <FormControl fullWidth size="small" style={{ width: "100%" }}>
                  <Select
                    className={classes.selectBorder}
                    value={selectedAddClient || ""}
                    displayEmpty
                    onChange={(e) => setSelectedAddClient(e.target.value)}
                    sx={{}}
                  >
                    {/* <MenuItem value="">Select Position</MenuItem> */}
                    {getClientName.sort().map((option, el) => (
                      <MenuItem
                        key={el}
                        onClick={() => setSelectedAddClient(option)}
                        value={option || ""}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <InputText
                  placeholder="***"
                  type="password"
                  value={values?.password}
                  onChange={handleChange("password")}
                /> */}
              </div>
              <div className={classes.Button}>
                <button onClick={handleClose}>Cancel</button>
                <button>Save</button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
