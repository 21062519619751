import axios from "axios";
import {API} from "../../redux/api";

const fetchRemainAnnualLeave = (token, id) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(`${API}/attendance/remain-leave?employeeId=${id}`, {
          headers : {
              Authorization: token
          }
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export default fetchRemainAnnualLeave;
