import { StyleSheet } from "@react-pdf/renderer";

const Styles = StyleSheet.create({
  Body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  BodyPage2: {
    paddingTop: 10,
    // paddingBottom: 65,
    paddingHorizontal: 10,
    // height: '100%'
  },
  Heading: {
    display: "flex",
    paddingBottom: 16,
  },
  TextHeading: {
    color: "#333333",
    fontSize: 16,
    fontWeight: 600,
  },
  ControlGroup: {
    display: "flex",
    flexDirection: "column",
  },
  ValueGroup: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },

  ValueLeft: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },

  ValueRight: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  ValueText: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 5,
  },
  Icon: {
    width: 16,
    height: 16,
  },
  TextKeys: {
    fontSize: 10,
    fontWeight: 400,
    color: "#797A7B",
  },
  TextValue: {
    fontSize: 11,
    fontWeight: 500,
    color: "#333333",
    paddingTop: 5,
  },
  TextKeysRight: {
    fontSize: 11,
    fontWeight: 500,
    color: "#333333",
  },
  ContainerApproval: {
    paddingTop: 12,
  },

  WrapperApproval: {
    padding: 16,
    background: "#FFFFFF",
    border: "1px solid #E0E0E0",
    borderRadius: 8,
  },

  HeadingApproval: {
    display: "flex",
    flexDirection: "column",
  },
  TextSpan: {
    fontSize: 13,
    fontWeight: 400,
    color: "#333333",
  },

  //   ContentApproval: {
  //     display: "flex",
  //     flexDirection: "column",
  //   },

  ItemApproval: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 10,
  },

  HeaderPDF: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    background: "#FFFFFF",
    borderBottom: "1px solid #E8E8E8",
  },

  // PDFImage: {
  //   height: '60%'
  // },
  CircleImage: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    width: 53,
    height: 53,
    borderRadius: "50%",
    backgroundColor: "#F3F5F7",
    marginBottom: 10,
  },
  HeaderImage: {
    width: 27,
    height: 27,
  },

  TextHeaderPDF: {
    fontSize: 16,
    fontWeight: 500,
    color: "#000000",
    marginBottom: 10,
    paddingLeft: 10,
  },

  ContainerExitInterview: {
    display: "flex",
    flexDirection: "column",
  },

  InputGroup: {
    display: "flex",
    flexDirection: "column",
  },
  ValueInput: {
    backgroundColor: "#F3F5F7",
    borderRadius: 5,
    display: "flex",
    paddingHorizontal: 10,
    paddingBottom: 8,
  },
});

export default Styles;
