import React from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";

import { AppInformationFormSA } from "../../../../components/SuperAdmin/_information";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import { useHistory } from "react-router";
export default function AddInformation() {
  const history = useHistory();

  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Add Information</title>
        </Helmet>
        <Breadcrumb>
          <Button
            onClick={() => {
              if (localStorage.getItem("type") === "BUSINESS"){
                history.push("/admin-business/information");
              } else if (localStorage.getItem("type") === "SUPER"){
                history.push("/superadmin/information")
              }
            }}
            style={{ textTransform: "none" }}
          >
            <span style={{ fontSize: 20, color: "#255bfc" }}>Information</span>
          </Button>
          <span style={{ fontSize: 20, color: "#255bfc" }}>Add Information</span>
        </Breadcrumb>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} style={{padding: '20px 0'}}>
            <AppInformationFormSA />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
