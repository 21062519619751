import React from "react";
import Styles from './BankDetail.module.css';
import EDIT_ICON from "../../../../assets/images/create.png";
import { Link } from "react-router-dom";

export default function BankDetailSA({ bank }) {
    return (
        <div className={Styles.ContainerWrapper}>
            <span>Add Bank Data</span>
            <div className={Styles.Container}>
                <div className={Styles.Form}>
                    <div className={Styles.BoxHeader}>
                        <span>Bank Details</span>
                        <Link
                            to={{
                                pathname: `/superadmin/edit-bank/${bank?._id}`,
                                state: { data: bank },
                            }}
                        >
                            <img src={EDIT_ICON} alt="edit" />
                        </Link>
                    </div>
                    <div className={Styles.BoxForm}>
                        <span>Bank Name</span>
                        <span>{bank?.name}</span>
                    </div>
                    <div className={Styles.BoxForm}>
                        <span>Bank Code</span>
                        <span>{bank?.name}</span>
                    </div>
                    <div className={Styles.BoxForm}>
                        <span>Swift Code</span>
                        <span>{bank?.swiftCode}</span>
                    </div>
                </div>
            </div>
        </div >

    )
}