import axios from "axios";
import {API} from "../../api";
import * as types from '../../typeExternal';

// export const fetchAllDataEmployeeInternal = (token) => {
//   return (dispatch) => {
//     axios
//       .get(`${API}/auth/tko`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then((res) => {
//         dispatch({
//           type: FETCH_ALL_DATA_EMPLOYEE,
//           payload: res.data.data,
//         });
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };

export const fetchAllDataEmployeeByRangeExternal = (token, query) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_BEGIN})
    axios
    // .get(`${API}/attendance/admin/downloadAttendance?startDate=${startDate}&endDate=${endDate}&clientName=${clientName}`, {
    .get(`${API}/attendance/admin/downloadAttendance?${query}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        dispatch({
          type: types.FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_SUCCESS,
          payload: res?.data?.response,
        });
      })
      .catch((err) => {
        dispatch({ type : types.FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_ERROR, payload: err.response })
      });
  };
};

export const fetchAllDataEmployeeByRangeSpv = (token, query) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_BEGIN})
    axios
    // .get(`${API}/attendance/admin/downloadAttendance?startDate=${startDate}&endDate=${endDate}&clientName=${clientName}`, {
    .get(`${API}/attendance/spv/list?${query}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        dispatch({
          type: types.FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_SUCCESS,
          payload: res?.data?.response,
        });
      })
      .catch((err) => {
        dispatch({ type : types.FETCH_DATA_EMPLOYEE_BY_RANGE_EXTERNAL_ERROR, payload: err.response })
      });
  };
};

export const fetchEmployeeAttendanceByRangeExternal = (token, startDate, endDate, clientName, employeeId) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_EMPLOYEE_ATTENDANCE_BEGIN})
    axios
    .get(`${API}/attendance/admin/downloadAttendance/employeeId?startDate=${startDate}&endDate=${endDate}&clientName=${clientName}&employeeId=${employeeId}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        dispatch({
          type: types.FETCH_EMPLOYEE_ATTENDANCE_SUCCESS,
          payload: res?.data?.response,
        });
      })
      .catch((err) => {
        dispatch({ type : types.FETCH_EMPLOYEE_ATTENDANCE_ERROR, payload: err.response })
      });
  };
};

// export const fetchAllDataEmployeeByRangeDateId = (token, id ,startDate, endDate) => {
//   return (dispatch) => {
//     axios
//       .get(`${API}/absence/rangeUserId?id=${id}&start=${startDate}&end=${endDate}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then((res) => {
//         dispatch({
//           type: types.FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_DATE_ID,
//           payload: res.data.data,
//         });
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };

// FETCH EMPLOYEE ATTENDANCE DATA
// export const fetchEmployeeExternalAttendanceData = (token, startDate, endDate, clientName) => {
//   return (dispatch) => {
//     dispatch({ type: types.FETCH_EMPLOYEE_EXTERNAL_ATTENDANCE_DATA_BEGIN})
//     axios
//     .get(`${API}/attendance/admin/downloadAttendance?startDate=${startDate}&endDate=${endDate}&clientName=${clientName}`, {
//       headers: {
//         Authorization: token,
//       },
//     })
//       .then((res) => {
//         dispatch({
//           type: types.FETCH_EMPLOYEE_EXTERNAL_ATTENDANCE_DATA_SUCCESS,
//           payload: res?.data?.response,
//         });
//       })
//       .catch((err) => {
//         dispatch({ type: types.FETCH_EMPLOYEE_EXTERNAL_ATTENDANCE_DATA_ERROR, payload: err.response})
//         console.log(err);
//       });
//   };
// };
