import React, { useState } from "react";
import "./layoutBusiness.css";
import { Route } from "react-router-dom";

import { Redirect } from "react-router";

import TopNavBusiness from "../../topNav/Business/TopNavBusiness";
import SnackbarComponent from "../../utils/Snackbar/SnackbarComponent";
import RouterBusiness from "../../Business/Routes/RoutesBusiness";
import SidebarBusiness from "../../Business/SidebarBusiness/SidebarBusiness";
import MiniScreen from "../../utils/MiniScreen";

const LayoutAdminBusiness = () => {
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("accessToken");

  if (!token) {
    return <Redirect to="/admin-business/information" />;
  }

  return (
    <Route
      render={(props) => (
        <>
          <div className="layout">
            <SidebarBusiness
              {...props}
              onOpenSidebar={open}
              onCloseSidebar={() => setOpen(false)}
            />
            <div className="layout__content">
              <TopNavBusiness onOpenSidebar={() => setOpen(true)} />
              <div className="layout__content-main">
                <SnackbarComponent />
                <RouterBusiness />
              </div>
            </div>
          </div>
          <div className="mini__screen-layout">
            <MiniScreen />
          </div>
        </>
      )}
    />
  );
};

export default LayoutAdminBusiness;
