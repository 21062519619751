/* eslint-disable no-unused-vars */

import React, { useEffect, useMemo, useState } from "react"
// import { employees } from "../../../_user/EmployeeTable/employees";
import Styles from './ModalEditApprover.module.css'
import EDIT_ICON from "../../../../../assets/images/create.png";
import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, styled, TextField } from "@mui/material";
import { ExpandMore, Label } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEmployees, updateSPVorAPV } from "../../../../../redux/actions/SuperAdmin/employeesActions";
import fetchLoanApprovers from "../../../../../async/loanApprovers/fetchLoanApprovers";

const MuiTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: 8,
            border: '1px solid #d9d9d9',
            color: '#0a0a0a',
        },
    },
});

const useStyles = makeStyles({
    select: {
        '&:before': {
            borderColor: 'white',
        },
        '&:after': {
            borderColor: 'white',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white',
        },
    },
    icon: {
        fill: 'white',
    },
    root: {
        color: 'white',
    },
})

export default function ModalEditApprover({ changeApprover, setChangeApprover, employeeId, clientCode, apvToChange }) {
    const token = localStorage.getItem('accessToken')
    const { employees } = useSelector(state => state.employees)
    const dispatch = useDispatch()
    const [dataAPV, setDataAPV] = useState([])


    useEffect(() => {
        (async () => {
            try {
                const { response } = await fetchLoanApprovers(token, clientCode);
                setDataAPV(response);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [clientCode, token]);



    const [apvName, setApvName] = useState('')

    const [apvCode, setApvCode] = useState('')

    const handleChangeApv = (newValue) => {
        setApvName(newValue.name)
        setApvCode(newValue.employeeId)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (apvCode) {
            dispatch(updateSPVorAPV(
                token,
                employeeId,
                { loanApproversEmployeeId: apvCode },
                `clientCode=${clientCode}`
            ))
            setChangeApprover(false)
        } else {
            console.log('no apv code')
        }

    }
    
    return (
        <div className={Styles.Background}>
            <div className={Styles.Container}>
                <div className={Styles.CardHeader}>
                    <img src={EDIT_ICON} alt="edit" />
                    <span>Edit Loan Approver</span>
                </div>
                <div className={Styles.FormContainer}>
                    <form className={Styles.FormControl} onSubmit={handleSubmit}>
                        <div className={Styles.BoxFormWrapper}>
                            <Autocomplete
                                disablePortal
                                id="approver"
                                options={dataAPV}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.employeeId + Math.random()}>
                                            {option.name}
                                        </li>
                                    );
                                }}
                                onChange={(event, newValue) => handleChangeApv(newValue)}
                                renderInput={(params) => <MuiTextField {...params} size="small" sx={{ width: '100%', background: 'var(--main-bg)' }} />
                                }
                            />
                        </div>


                        <div className={Styles.ButtonsWrapper}>
                            <Button type="submit" variant="contained">Save</Button>
                            <Button variant="outlined" onClick={() => setChangeApprover(false)}>Cancel</Button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}